import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.secondary,
  },
  menu_item_selected: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

const SORT_NEWEST = 0;
const SORT_OLDEST = 1;
const SORT_MOST_RECOMMENDED = 2;
const SORT_MOST_REPLIED = 3;

class PopMenuCommentSort extends React.Component {
  render() {
    const { classes, selected, onSort } = this.props;

    return (
      <div>
        <MenuList className={classes.menu_list}>
          <MenuItem 
            className={selected === SORT_NEWEST ? classes.menu_item_selected : classes.menu_item} 
            onClick={e => onSort(SORT_NEWEST)}
          >
            Newest
          </MenuItem>
          <MenuItem 
            className={selected === SORT_OLDEST ? classes.menu_item_selected : classes.menu_item}
            onClick={e => onSort(SORT_OLDEST)}
          >
            Oldest
          </MenuItem>
          <MenuItem 
            className={selected === SORT_MOST_RECOMMENDED ? classes.menu_item_selected : classes.menu_item} 
            onClick={e => onSort(SORT_MOST_RECOMMENDED)}
          >
            Most Recommended
          </MenuItem>
          <MenuItem 
            className={selected === SORT_MOST_REPLIED ? classes.menu_item_selected : classes.menu_item} 
            onClick={e => onSort(SORT_MOST_REPLIED)}
          >
            Most Replied
          </MenuItem>
        </MenuList>
      </div>
    );
  }
}

PopMenuCommentSort.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.number,
  onSort: PropTypes.func
};

export default withStyles(styles)(PopMenuCommentSort);
