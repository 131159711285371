// import React, { Suspense } from "react";
import React from "react";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Stories from "react-insta-stories";
// import { BasicAppBar } from "components";
import { 
  MAX_CARD_WIDTH, 
} from "constants/types";
import { 
  story1, 
  story2, 
  story4,
} from "./storytemplate";
// const StoriesLazy = React.lazy(() => import("react-insta-stories"));

const contentStyle = {
  background: "#333",
  width: "100%",
  padding: 20,
  color: "white",
  height: "100%",
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_CARD_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: MAX_CARD_WIDTH,
    maxWidth: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(2),
    fontFamily: "roboto",
    color: theme.palette.text.primary,
  },
});


const stories = [
  {
    url: 'https://picsum.photos/1080/1920',
    seeMore: ({ close }) => (
        <div style={{ width: '100%', height: '100%' }}>Hello</div>
    ),
    header: {
        heading: 'Mohit Karekar',
        subheading: 'Posted 5h ago',
        profileImage: 'https://picsum.photos/1000/1000'
    }
  },
  {
      url:
          'https://fsa.zobj.net/crop.php?r=dyJ08vhfPsUL3UkJ2aFaLo1LK5lhjA_5o6qEmWe7CW6P4bdk5Se2tYqxc8M3tcgYCwKp0IAyf0cmw9yCmOviFYb5JteeZgYClrug_bvSGgQxKGEUjH9H3s7PS9fQa3rpK3DN3nx-qA-mf6XN',
      header: {
          heading: 'Mohit Karekar',
          subheading: 'Posted 32m ago',
          profileImage: 'https://picsum.photos/1080/1920'
      }
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <div dangerouslySetInnerHTML={{ __html: story1 }} />
        </div>
      );
    },
  },
  {
    url: 'https://picsum.photos/1080/1920',
    seeMore: ({ close }) => (
      <div style={{ width: '100%', height: '100%' }}>Hello</div>
    ),
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <div dangerouslySetInnerHTML={{ __html: story2 }} />
        </div>
      );
    },
  },
  {
    url: "https://picsum.photos/1080/1920",
    seeMore: ({ close }) => (
      <div
        style={{
          maxWidth: "100%",
          height: "100%",
          padding: 40,
          background: "white",
        }}
      >
        <h2>Just checking the see more feature.</h2>
        <p style={{ textDecoration: "underline" }} onClick={close}>
          Go on, close this popup.
        </p>
      </div>
    ),
  },
  {
    url:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
    type: "video",
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <div dangerouslySetInnerHTML={{ __html: story4 }} />
        </div>
      );
    },
  },
];


class VideoStories extends React.Component {
  constructor(props) {
    super(props);

    this.handleNavBack = this.handleNavBack.bind(this);
  }

  handleNavBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;

    let sc_width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    let sc_height = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight;
    let width = sc_width;
    if (sc_width > MAX_CARD_WIDTH) {
      width = MAX_CARD_WIDTH;
    }
    let height = width * 1920 / 1080;
    if (height > sc_height) {
      width = width / height * sc_height;
    }
    height = sc_height;
    
    return (
      <div className={classes.root}>
        {/* <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_CARD_WIDTH}
            title={"Video Stories"}
            onNavBack={this.handleNavBack}
          />
        </div> */}

        <div className={classes.container}>
          <Stories
            loop
            keyboardNavigation
            defaultInterval={8000}
            width={width}
            height={height}
            stories={stories}
            onStoryEnd={(s, st) => console.log("story ended", s, st)}
            onAllStoriesEnd={(s, st) => console.log("all stories ended", s, st)}
            onStoryStart={(s, st) => console.log("story started", s, st)}
            storyContainerStyles={{ overflow: "hidden" }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(VideoStories);
