import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { CommentCard } from "components";
import { logger } from "utility/logging";

const styles = (theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
  },
  collapsebtn: {
    position: "absolute",
    top: -4,
    left: -3,
    width: 24,
    height: 24,
    padding: 4,
    backgroundColor: theme.palette.background.default,
    "&:active": {
      backgroundColor: theme.palette.background.default,
    },
    zIndex: 100,
  },
  collapsed: {
    marginLeft: 8,
    paddingLeft: 18,
  },
  uncollapsed: {
    marginLeft: 8,
    paddingLeft: 16,
    borderLeft: `2px solid ${theme.palette.text.primary}`,
  },
});

class CommentChildCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
    };

    this.handleCollapse = this.handleCollapse.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      ...this.state,
      collapsed: this.props.collapsed
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.sortType !== this.props.sortType ||
      prevProps.collapsed !== this.props.collapsed) {
      logger.log("CommentChildCards sortType changed!");
      this.setState({
        ...this.state,
        collapsed: this.props.collapsed
      });
    }
  }

  handleCollapse = () => {
    const collapsed = !this.state.collapsed;
    this.setState({
      ...this.state,
      collapsed: collapsed,
    }, () => {
      this.props.onCollapsed(collapsed);
    });
  };

  render() {
    const { 
      classes, 
      width, 
      card_mode,
      children, 
      childrenCount, 
      sortType, 
      level, 
      closed,
      onNeedLogin, 
      onMemberTrial, 
      onDeleteChild, 
      onUpdateChild
    } = this.props;
    const { collapsed } = this.state;

    logger.log("comment child card collapsed :", level, collapsed, children);

    const card_width = width - 10;  // margin(8) + border(2)

    return (
      <div className={classes.root}>
        <IconButton
          className={classes.collapsebtn}
          onClick={this.handleCollapse}
          size="small"
        >
          {collapsed && <AddCircleOutlineIcon fontSize="small" />}
          {!collapsed && <RemoveCircleOutlineIcon fontSize="small" />}
        </IconButton>
        {collapsed && (
          <div className={classes.collapsed} key={children[0].id}>
            <CommentCard
              key={`comment-${children[0].id}`}
              width={card_width}
              card_mode={card_mode}
              comment={children[0]}
              sortType={sortType}
              level={level}
              collapsed={collapsed}
              commentcount={childrenCount}
              closed={closed}
              onNeedLogin={onNeedLogin}
              onMemberTrial={onMemberTrial}
              onDelete={onDeleteChild}
              onUpdate={onUpdateChild}
            />
          </div>
        )}
        {!collapsed && (
          <div className={classes.uncollapsed}>
            {children.map((child) => (
              <div key={child.id}>
                <CommentCard
                  key={`comment-${child.id}`}
                  width={card_width}
                  card_mode={card_mode}
                  comment={child}
                  sortType={sortType}
                  level={level}
                  collapsed={collapsed}
                  closed={closed}
                  onNeedLogin={onNeedLogin}
                  onMemberTrial={onMemberTrial}
                  onDelete={onDeleteChild}
                  onUpdate={onUpdateChild}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

CommentChildCards.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  card_mode: PropTypes.bool,
  children: PropTypes.array,
  childrenCount: PropTypes.number,
  sortType: PropTypes.number,
  level: PropTypes.number,
  closed: PropTypes.bool,
  collapsed: PropTypes.bool,
  onNeedLogin: PropTypes.func,
  onMemberTrial: PropTypes.func,
  onCollapsed: PropTypes.func,
  onDeleteChild: PropTypes.func,
  onUpdateChild: PropTypes.func
};

export default withStyles(styles)(CommentChildCards);
