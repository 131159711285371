import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Grid,
  IconButton,
  Menu,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  PopMenuHomeComment,
  DlgLoginConfirm,
  DlgReport,
  DlgShare,
} from "components";
import { CONF_BRANCHES, ARTICLE_BRANCH_NEWSPAPER, get_branch_name } from "constants/branches";
import {
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  THEME_MODE_DARK,
  RAVEN_PLACEHOLDER_IMAGE,
  THREAD_REPORT_COMMENTS,
  GRAPHQL_SUCCESS,
  BANNED_TYPE_1D,
  BANNED_TYPE_7D,
  BANNED_TYPE_PERM,
  FLAGGED_COMMENTS_POSTS,
} from "constants/types";
import * as ROUTES from "constants/routes";
import {
  deleteThread,
  getAuthToken,
  getFeedofSource,
  isBannedComments,
  isCommentEnabledFeed,
  updateFeedNotifications,
} from "dataapis";
import { GraphqlService } from "services";
import { render_text } from "utility/utils";
import { ToastError, ToastInfo, ToastSuccess } from "utility/toast";
import { logger } from "utility/logging";
import { isFlaggedModerationByAi } from "utility/ravenapi";
import { handleReportWithLimit } from "utility/reportlimit";
import { updateReportLimit } from "dataapis/user";

const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    borderRadius: 10,
    borderTopLeftRadius: 30,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.default,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  share: {
    position: "absolute",
    top: 12,
    right: 12,
    padding: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    backgroundColor: "#7289DA",
    zIndex: 100,
  },
  shareicon: {
    width: 16,
    height: 16,
  },
  expand: {
    position: "absolute",
    top: 28,
    right: 10,
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 48,
    height: 48,
    cursor: "pointer",
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  socialimg: {
    position: "absolute",
    top: 52,
    left: 14,
    width: 20,
    height: 20,
  },
  upvotebtn: {
    position: "absolute",
    top: 80,
    left: 12,
    width: 20,
    height: 20,
    zIndex: 100,
  },
  upvotetxt: {
    position: "absolute",
    top: 102,
    left: 16,
    fontSize: "14px",
    fontStyle: "Roboto",
  },
  actionimg: {
    width: 20,
    height: 20,
  },
  title: {
    position: "relative",
    marginTop: 8,
    marginLeft: 40,
    marginRight: 40,
  },
  feed: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontStyle: "Italic",
    lineHeight: "18px",
    marginTop: 4,
    marginLeft: 40,
    marginRight: 30,
    marginBottom: 4,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  content: {
    padding: 4,
    margin: 8,
    marginLeft: 50,
    marginRight: 10,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  detail_txt: {
    display: "inline",
    overflowWrap: "break-word",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
  },
  media: {
    display: "block",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  actions: {
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  // repostbtn: {
  //   width: 20,
  //   height: 20,
  //   zIndex: 100,
  // },
  commentbtn: {
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20,
    zIndex: 100,
  },
  comment: {
    marginLeft: theme.spacing(0.5),
    fontSize: "14px",
    fontStyle: "Roboto",
  },
});

class ThreadCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      loginDlg: false,
      reportDlg: false,
      shareDlg: false,
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.handleClickReport = this.handleClickReport.bind(this);
    this.handleCloseReport = this.handleCloseReport.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleClickShare = this.handleClickShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);
    this.handleClickUpvote = this.handleClickUpvote.bind(this);

    this.handleRemove = this.handleRemove.bind(this);
    this.handleBan1d = this.handleBan1d.bind(this);
    this.handleBan7d = this.handleBan7d.bind(this);
    this.handleBanPerm = this.handleBanPerm.bind(this);
    this.handleCloseComments = this.handleCloseComments.bind(this);
    this.handleDeleteComments = this.handleDeleteComments.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleClickShare = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      shareDlg: true,
    });
  };

  handleCloseShare = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      shareDlg: false,
    });
  };

  handleExpand = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
    this.props.onLogin();
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  handleClickUpvote = async () => {
    const { loggedIn, authUser, thread } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    const voter_result = await gqlservice.thread_voter(thread.id, authUser.uid);
    if (voter_result.status_code !== GRAPHQL_SUCCESS) {
      this.setError("Error getting thread voter");
      return;
    }

    const voter = voter_result.data.thread_voter.length > 0;
    let thread_upvote_func = null;
    if (voter) {
      thread_upvote_func = gqlservice.thread_dec_upvotes;
    } else {
      thread_upvote_func = gqlservice.thread_inc_upvotes;
    }

    this.setWaiting(true);

    thread_upvote_func(thread.id, authUser.uid)
      .then(
        (result) => {
          let updated_votes = result.data.update_threads.returning[0].upvotes;
          this.props.updateThreadUpvotes(thread.id, updated_votes);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  _removeThread = async (thread) => {
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    const { authUser } = this.props;
    const currentTime = new Date().toISOString();
    await gqlservice
      .remove_thread_from_home(thread.id, true, authUser.uid, currentTime)
      .then(
        (result) => {
          this.props.removeThread(thread.id);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleRemove = async () => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const { thread } = this.props;
    await this._removeThread(thread);
  };

  handleBan1d = async (thread, feed) => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    await this._removeThread(thread);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }

    let result = await gqlservice.feed_by_id(feed.id);
    if (result.status_code !== GRAPHQL_SUCCESS || !result.data?.feeds) {
      this.setError("Failed to get feed :", feed.name);
      return;
    }

    const thread_feed = result.data.feeds[0];

    const is_banned =
      thread_feed.banned_users.find(
        (banned) => banned.user_id === thread.poster.uid
      ) !== undefined;
    let fnBan = is_banned
      ? gqlservice.update_banned_user
      : gqlservice.insert_banned_user;

    const now = Date.now();
    const banned_at = new Date(now).toISOString();
    const banned_till = new Date(now + 86400 * 1000).toISOString();

    const { authUser } = this.props;
    const banned = {
      user_id: thread.poster.uid,
      feed_id: thread_feed.id,
      banned_at: banned_at,
      banned_till: banned_till,
      type: BANNED_TYPE_1D,
      banned_by: authUser.uid,
    };

    gqlservice.set_auth_jwt(token, true);
    await fnBan(banned)
      .then(
        (result) => {
          const new_banned = is_banned
            ? result.data.update_users_banned.returning[0]
            : result.data.insert_users_banned.returning[0];
          this.props.insertBannedUser(new_banned);
          this.setAlert(`User ${thread.poster.username} banned for a day!`);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleBan7d = async (thread, feed) => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    await this._removeThread(thread);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }

    let result = await gqlservice.feed_by_id(feed.id);
    if (result.status_code !== GRAPHQL_SUCCESS || !result.data?.feeds) {
      this.setError("Failed to get feed :", feed.name);
      return;
    }

    const thread_feed = result.data.feeds[0];

    const is_banned =
      thread_feed.banned_users.find(
        (banned) => banned.user_id === thread.poster.uid
      ) !== undefined;
    let fnBan = is_banned
      ? gqlservice.update_banned_user
      : gqlservice.insert_banned_user;

    const now = Date.now();
    const banned_at = new Date(now).toISOString();
    const banned_till = new Date(now + 86400 * 7 * 1000).toISOString();

    const { authUser } = this.props;
    const banned = {
      user_id: thread.poster.uid,
      feed_id: thread_feed.id,
      banned_at: banned_at,
      banned_till: banned_till,
      type: BANNED_TYPE_7D,
      banned_by: authUser.uid,
    };

    gqlservice.set_auth_jwt(token, true);
    await fnBan(banned)
      .then(
        (result) => {
          const new_banned = is_banned
            ? result.data.update_users_banned.returning[0]
            : result.data.insert_users_banned.returning[0];
          this.props.insertBannedUser(new_banned);
          this.setAlert(`User ${thread.poster.username} banned for 7 days!`);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleBanPerm = async (thread, feed) => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    await this._removeThread(thread);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }

    let result = await gqlservice.feed_by_id(feed.id);
    if (result.status_code !== GRAPHQL_SUCCESS || !result.data?.feeds) {
      this.setError("Failed to get feed :", feed.name);
      return;
    }

    const thread_feed = result.data.feeds[0];

    const is_banned =
      thread_feed.banned_users.find(
        (banned) => banned.user_id === thread.poster.uid
      ) !== undefined;
    let fnBan = is_banned
      ? gqlservice.update_banned_user
      : gqlservice.insert_banned_user;

    const now = Date.now();
    const banned_at = new Date(now).toISOString();
    const banned_till = new Date(now + 86400 * 36500 * 1000).toISOString();

    const { authUser } = this.props;
    const banned = {
      user_id: thread.poster.uid,
      feed_id: thread_feed.id,
      banned_at: banned_at,
      banned_till: banned_till,
      type: BANNED_TYPE_PERM,
      banned_by: authUser.uid,
    };

    gqlservice.set_auth_jwt(token, true);
    await fnBan(banned)
      .then(
        (result) => {
          const new_banned = is_banned
            ? result.data.update_users_banned.returning[0]
            : result.data.insert_users_banned.returning[0];
          this.props.insertBannedUser(new_banned);
          this.setAlert(`User ${thread.poster.username} banned permanently!`);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleCloseComments = async () => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    const { authUser, thread } = this.props;
    const currentTime = new Date().toISOString();
    await gqlservice
      .close_thread(thread.id, true, authUser.uid, currentTime)
      .then(
        (result) => {
          this.props.closeThread(thread.id);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleDeleteComments = async () => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    const { authUser, thread } = this.props;
    const currentTime = new Date().toISOString();
    await gqlservice
      .close_thread(thread.id, true, authUser.uid, currentTime)
      .then(
        (result) => {
          this.props.closeThread(thread.id);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    await gqlservice
      .deleteall_comment(thread.id)
      .then((result) => {
        return gqlservice.thread_by_id(thread.id, null);
      })
      .then(
        (result) => {
          const new_thread = result.data.threads[0];
          this.props.updateThread(new_thread);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleClickReport = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }
    this.handleCloseMenu();
    if (this._isBanned()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    this.setState({
      ...this.state,
      reportDlg: true,
    });
  };

  handleCloseReport = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      reportDlg: false,
    });
  };

  handleReport = async (reportMsg) => {
    this.handleCloseMenu();

    const message = reportMsg.trim();
    if (!message) {
      this.setError("Report shouldn't be blank. Please input your report.");
      return;
    }

    const { authUser, thread ,selected_feed } = this.props;
    const feed = this.getFeed();
    if (!feed) {
      this.setError("Can't find feed of thread");
      return;
    }

    this.setState({
      ...this.state,
      reportDlg: false,
    });

    let report = {
      thread_id: thread.id,
      report: message,
      reported_by: authUser.uid,
      type: THREAD_REPORT_COMMENTS,
    };

    //reportlimit
    const updatedAuthUser = await handleReportWithLimit(authUser);
    if(!updatedAuthUser){
      ToastError("Report limit exceeded. Please try again later.");
      return null
    }

    const isAiModerationEnabled = JSON.parse(selected_feed.ai_moderation) === null ? false :JSON.parse(selected_feed.ai_moderation).some((item)=> item === FLAGGED_COMMENTS_POSTS)
    //  const isAiModerationEnabled =true
     if(isAiModerationEnabled){
       const topSources = this.getTopSources()
 
       const params ={
         name: selected_feed.name,
         description: selected_feed.description,
         userName: authUser.name,
         userBio: authUser.biography,
         userId:authUser.uid,
         userKarmaScore: authUser?.articles_voted?.length,
         topSources:topSources,
         userPostedSources:{
           postType : "comment",
           socialMediaContent:thread?.text
         }
       }
       try{
        // const gqlservice = new GraphqlService();
        const token = await getAuthToken();
         await isFlaggedModerationByAi(params, token).then(
          async (result) => {
             if (result.status === 200) {
               if ((result.message.decision.toLowerCase()) !== "denied") {
                 this.setError("This comment can't be reported due to "+ JSON.stringify(result.message.reason));
                 await updateReportLimit(updatedAuthUser)
                 if (result.status === 200) {
                    this.props.setAuthUser(updatedAuthUser);
                 }
                 throw new Error('Approve'); // Throw an error to exit the Promise chain
               }else{
                const gqlservice = new GraphqlService();
                // const token = await getAuthToken();
                if (!token) {
                  this.handleLogin();
                  return;
                }
                gqlservice.set_auth_jwt(token, true);
                ToastSuccess(
                  "Reported the thread successfully by AI " + JSON.stringify(result.message.reason)
                  );
                  let route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
                  const location = {
                    pathname: route,
                    state: { animation: "left" },
                  };
                  this.goTo(location);
                  await deleteThread(thread);
                  await updateFeedNotifications(selected_feed);
                  await updateReportLimit(updatedAuthUser)
                  this.props.setAuthUser(updatedAuthUser);
               }
               }
               throw new Error('Error'); // Throw an error to exit the Promise chain
           },
           (reason) => {
             this.setError(reason.msg);
             throw new Error('Error'); // Throw an error to exit the Promise chain
           }
         ).catch((err) => {
           this.setError(JSON.stringify(err.Error));
           throw new Error(err); // Throw an error to exit the Promise chain
         });
       
       }catch(err){
         console.log("The source is denied",err)
         return ;
       }   
  }else{
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }

    gqlservice.set_auth_jwt(token);
    await gqlservice
      .insert_thread_report(feed.id, report)
      .then(
        (result) => {
          (async () => {
          ToastInfo("Reported the thread");
          await updateReportLimit(updatedAuthUser);
          this.props.setAuthUser(updatedAuthUser);
        })().catch((err) => {
          this.setError(JSON.stringify(err));
        });
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  }
}
  // For getting top sources
 getTopSources = () => {
  const { selected_feed  } =this.props
  let topSources = [];
  let totalCount = selected_feed?.feed_sources?.length >= 9 ? 9 : selected_feed?.feed_sources?.length;
  for (let i = 0; i <= totalCount; i++) {
    const branch_name = get_branch_name(selected_feed?.feed_sources[i]?.source?.branch);
    if (selected_feed?.feed_sources[i] && selected_feed?.feed_sources[i].source) {
        let new_top_source ={
          name:selected_feed?.feed_sources[i].source?.name ,
          description:selected_feed?.feed_sources[i].source?.description , 
          socialMediaType: branch_name
         }
         topSources.push(new_top_source);
    }
  }
  return topSources;
}
goTo = (location) => {
  // this.unregisterSubscribers();
  this.props.history.push(location);
}
  getFeed = () => {
    const { thread, feeds } = this.props;
    let feed = null;

    // if the thread is from a feed
    if (thread.feed_id) {
      feed = feeds.find((item) => item.id === thread.feed_id);
      return feed;
    }

    // if the thread is from an article
    if (thread.article) {
      feed = getFeedofSource(thread.article.source.id);
      return feed;
    }

    // the thread is from location or readings
    return feed;
  };

  getFeedofSource = (source_id) => {
    const { loggedIn, followed_feeds, feeds } = this.props;
    let feed = null;
    if (loggedIn) {
      feed = followed_feeds.find(
        (item) =>
          isCommentEnabledFeed(item) &&
          item.feed_sources.find(
            (feed_source) => feed_source.source_id === source_id
          ) !== undefined
      );
      if (feed) {
        return feed;
      }
    }

    feed = feeds.find(
      (item) =>
        isCommentEnabledFeed(item) &&
        item.feed_sources.find(
          (feed_source) => feed_source.source_id === source_id
        ) !== undefined
    );
    return feed;
  };

  _getThreadUrl = () => {
    const { thread } = this.props;
    const feed = this.getFeed();
    if (feed) {
      if (thread.article) {
        const type =
          thread.article.source.branch === ARTICLE_BRANCH_NEWSPAPER
            ? ROUTES.ARTICLE_NEWS_PREFIX
            : ROUTES.ARTICLE_PREFIX;
        return `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${thread.article.source.slug}/${type}/${thread.article.nid}`;
      } else {
        return `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.id}`;
      }
    } else {
      return `/${ROUTES.THREAD_PREFIX}/${thread.id}`;
    }
  };

  render() {
    const {
      classes,
      theme_mode,
      authUser,
      moderator,
      sources,
      thread,
      onClickThread,
      onClickSource,
      onClickFeed,
    } = this.props;
    const { anchorEl, loginDlg, reportDlg, shareDlg } = this.state;

    // get source to use name, image, etc
    let source = null;
    let branch = null;
    if (thread.article) {
      source = sources.find((item) => item.id === thread.article.source.id);
      if (!source) {
        logger.error("Error, Unknown article source!", thread.article);
        return <div style={{display: "none"}}></div>;
      }
      branch = CONF_BRANCHES.find((item) => item.value === source.branch);
    }

    // find the feed contain this thread
    let feed = this.getFeed();
    if (!feed) {
      logger.error("Error, can't find the feed!", thread);
      return <div style={{display: "none"}}></div>;
    }

    // voted, reposted by me
    let voted =
      authUser.threads_voted.find((voted) => voted.thread_id === thread.id) !==
      undefined;
    // let reposted = authUser.threads_reposted.find(reposted => reposted.thread_id === thread.id) !== undefined;

    // image
    let thread_image = null;
    if (thread.article) {
      thread_image = thread.article.image;
      const image_thumb = thread.article.image_thumb;
      if (image_thumb) {
        if (image_thumb["150"]) {
          thread_image = image_thumb["150"];
        } else if (image_thumb["240"]) {
          thread_image = image_thumb["240"];
        } else if (image_thumb["320"]) {
          thread_image = image_thumb["320"];
        } else if (image_thumb["org"]) {
          thread_image = image_thumb["org"];
        }
      }
    }

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    width -= 16;
    if (width > MAX_CARD_WIDTH - 16) width = MAX_CARD_WIDTH - 16;
    if (width < MIN_CARD_WIDTH - 16) width = MIN_CARD_WIDTH - 16;

    // get comments
    const commentsCnt = thread.comments_aggregate.aggregate.count;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    // share information
    let shareInfo = null;
    if (shareDlg) {
      let baseUrl = "";
      if (typeof window !== "undefined") {
        baseUrl = window.location.protocol + "//" + window.location.host;
      }
      let shareUrl = baseUrl + this._getThreadUrl();
      shareInfo = {
        title: "Raven Comment: " + thread.title,
        description: thread.text,
        image: "/static/images/icons/raven_logo.png",
        hashtag: "raven,comments",
        url: shareUrl,
      };
    }

    return (
      <div>
        <Card
          className={classes.card}
          style={{
            width: width,
            backgroundColor: classes.card.backgroundColor,
          }}
        >
          <div className={classes.carddiv}>
            <CardHeader
              className={classes.header}
              avatar={
                <div>
                  {source && (
                    <>
                      <Avatar
                        alt={source.name}
                        src={source.thumbnail || source.image || RAVEN_PLACEHOLDER_IMAGE}
                        className={classes.avatar}
                        classes={{ img: classes.avatar_img }}
                        onClick={(e) => onClickSource(source, feed)}
                      />
                      <img
                        alt={branch.name}
                        src={`/static/images/icons/${theme_mode}/${branch.image}`}
                        className={classes.socialimg}
                      />
                    </>
                  )}
                  {!source && feed && (
                    <Avatar
                      alt={feed.name}
                      src={feed.thumbnail || feed.image || RAVEN_PLACEHOLDER_IMAGE}
                      className={classes.avatar}
                      classes={{ img: classes.avatar_img }}
                      onClick={(e) => onClickFeed(feed)}
                    />
                  )}
                </div>
              }
              title={
                <div>
                  <Typography
                    className={classes.title}
                    variant="subtitle2"
                    onClick={(e) => onClickThread(thread, feed)}
                  >
                    {render_text(thread.title, theme_mode === THEME_MODE_DARK)}
                  </Typography>
                  {feed && ( // show only in home page
                    <Typography
                      className={classes.feed}
                      onClick={(e) => onClickFeed(feed)}
                    >
                      {feed.name}
                    </Typography>
                  )}
                </div>
              }
            />

            <div>
              <IconButton
                className={classes.upvotebtn}
                onClick={this.handleClickUpvote}
              >
                <img
                  alt={"upvote"}
                  src={
                    voted
                      ? `/static/images/icons/thumbsup.png`
                      : `/static/images/icons/${theme_mode}/thumbsup.png`
                  }
                  className={classes.actionimg}
                />
              </IconButton>
              {thread.upvotes > 0 && (
                <Typography
                  className={classes.upvotetxt}
                  style={{ left: 24 - 4 * thread.upvotes.toString().length }}
                >
                  {thread.upvotes}
                </Typography>
              )}
            </div>
            <div>
              <IconButton
                className={classes.share}
                onClick={this.handleClickShare}
              >
                <ShareIcon className={classes.shareicon} />
              </IconButton>
              <IconButton
                className={classes.expand}
                onClick={this.handleExpand}
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
            {thread.text && (
              <CardContent
                className={classes.content}
                onClick={(e) => onClickThread(thread, feed)}
              >
                <Typography className={classes.detail_txt}>
                  {render_text(thread.text, theme_mode === THEME_MODE_DARK)}
                </Typography>
              </CardContent>
            )}
            {thread_image && (
              <LazyLoadImage
                alt={""}
                src={thread_image}
                className={classes.media}
              />
            )}
            <CardActions className={classes.actions}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <div
                    style={{ marginTop: 8, marginRight: 16, cursor: "pointer" }}
                    onClick={(e) => onClickThread(thread, feed)}
                  >
                    <IconButton className={classes.commentbtn}>
                      <img
                        alt={"comment"}
                        src={`/static/images/icons/${theme_mode}/chat.png`}
                        className={classes.actionimg}
                      />
                    </IconButton>
                    {commentsCnt > 0 && (
                      <span
                        className={classes.comment}
                      >{`${commentsCnt} Comments`}</span>
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardActions>
          </div>
        </Card>

        <Menu
          id="thread-menu"
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleCloseMenu}
        >
          <PopMenuHomeComment
            theme={theme_mode}
            moderator={moderator}
            onRemove={this.handleRemove}
            onBan1d={(e) => this.handleBan1d(thread, feed)}
            onBan7d={(e) => this.handleBan7d(thread, feed)}
            onBanPerm={(e) => this.handleBanPerm(thread, feed)}
            onCloseComments={this.handleCloseComments}
            onDeleteComments={this.handleDeleteComments}
            onReport={this.handleClickReport}
            onShare={this.handleClickShare}
          />
        </Menu>

        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgReport
          open={reportDlg}
          title={"Report Comment"}
          theme={theme_mode}
          onReport={this.handleReport}
          onCancel={this.handleCloseReport}
        />
        {shareDlg && (
          <DlgShare
            open={shareDlg}
            shareInfo={shareInfo}
            onClose={this.handleCloseShare}
          />
        )}
      </div>
    );
  }
}

ThreadCard.propTypes = {
  classes: PropTypes.object,
  thread: PropTypes.object,
  moderator: PropTypes.bool,
  onLogin: PropTypes.func,
  onClickThread: PropTypes.func,
  onClickFeed: PropTypes.func,
  onClickSource: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  sources: state.dataState.sources,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ThreadCard));
