import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

class PopMenuCommentMod extends React.Component {
  render() {
    const { 
      classes, 
      theme, 
      // onEdit, 
      onDelete, 
      onShare, 
      onReport 
    } = this.props;

    return (
      <div>
        <MenuList className={classes.menu_list}>
          {/* <MenuItem className={classes.menu_item} onClick={onEdit}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"edit"}
                src={`/static/images/icons/${theme}/edit.png`}
              />
            </span>
            Edit Comment
          </MenuItem> */}
          
          <MenuItem className={classes.menu_item} onClick={onDelete}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"delete"}
                src={`/static/images/icons/${theme}/delete.png`}
              />
            </span>
            Delete Comment
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onReport}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"report"}
                src={`/static/images/icons/${theme}/report.png`}
              />
            </span>
            Report Comment
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onShare}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"share"}
                src={`/static/images/icons/${theme}/cshare.png`}
              />
            </span>
            Share Comment
          </MenuItem>
        </MenuList>
      </div>
    );
  }
}

PopMenuCommentMod.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onShare: PropTypes.func,
  onReport: PropTypes.func,
};

export default withStyles(styles)(PopMenuCommentMod);
