import {
  SUSPENSION_ALL_1D,
  SUSPENSION_ALL_7D,
  SUSPENSION_ALL_PERM,
  SUSPENSION_POSTS,
  SUSPENSION_COMMENTS,
  SUSPENSION_IMAGES,
  SUSPENSION_VIDEO,
  SUSPENSION_FEED_CREATION,
} from "constants/user";

export const have_paid = (loggedIn, authUser) => {
  if (!loggedIn) {
    return false;
  }
  if (!authUser.paid || !authUser.paid_until) {
    return false;
  } else {
    const paid_until_ts = Date.parse(authUser.paid_until);
    const now_ts = Date.now();
    return paid_until_ts >= now_ts;
  }
};

export const have_twitter = (loggedIn, authUser) => {
  if (!loggedIn) {
    return false;
  }

  const links = authUser.links;
  if (links.length === 0) {
    return false;
  }

  return links.find((link) => link.type === "twitter") !== undefined;
};

export const get_suspended_state = (loggedIn, user) => {
  let suspendedState = {
    suspended1day: false,
    suspended7day: false,
    suspendedPermanent: false,
    suspendedPosts: false,
    suspendedComments: false,
    suspendedImages: false,
    suspendedVideo: false,
    suspendedFeedCreation: false,
  };

  if (loggedIn && user.suspensions.length > 0) {
    suspendedState.suspended1day = user.suspensions.find(suspension => 
      suspension.type === SUSPENSION_ALL_1D &&
      suspension.enabled &&
      (Date.parse(suspension.till) >= Date.now())
    ) !== undefined;

    suspendedState.suspended7day = user.suspensions.find(suspension => 
      suspension.type === SUSPENSION_ALL_7D &&
      suspension.enabled && 
      (Date.parse(suspension.till) >= Date.now())
    ) !== undefined;

    suspendedState.suspended_perm = user.suspensions.find(suspension => 
      suspension.type === SUSPENSION_ALL_PERM &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_posts = user.suspensions.find(suspension => 
      suspension.type === SUSPENSION_POSTS &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_comments = user.suspensions.find(suspension => 
      suspension.type === SUSPENSION_COMMENTS &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_images = user.suspensions.find(suspension => 
      suspension.type === SUSPENSION_IMAGES &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_video = user.suspensions.find(suspension => 
      suspension.type === SUSPENSION_VIDEO &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_feedcreate = user.suspensions.find(suspension => 
      suspension.type === SUSPENSION_FEED_CREATION &&
      suspension.enabled
    ) !== undefined;
  }

  return suspendedState;
};