import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography 
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { MAX_ARTICLE_WIDTH, MAX_WINDOW_WIDTH } from 'constants/types';

const styles = theme => ({
  root: {
    position: "fixed",
    // width: theme.breakpoints.values.lg,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    height: "56px",
    zIndex: 1100,
  },
  appbar: {
    position: "absolute",
    // borderBottom: `2px solid ${theme.palette.text.secondary}`
  },
  title: {
    flexGrow: 1,
    position: 'relative',
    marginTop: 0
  },
  navbefore: {
    color: theme.palette.text.primary,
  },
  toolbox: {
    display: 'flex'
  },
  actionbtn: {
    padding: 0,
  },
  actionimg: {
    width: 24,
    height: 24,
  },
});

class UserAppBar extends React.Component {
  render() {
    const {
      classes,
      theme,
      onNavBack,
      onModerate
    } = this.props;

    return (
      <div className={classes.root} style={{width: MAX_ARTICLE_WIDTH}}>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton 
              edge="start" 
              className={classes.navbefore} 
              aria-label="nav before"
              onClick={onNavBack}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
              {"User"}
            </Typography>
            <IconButton
              className={classes.actionbtn}
              onClick={onModerate}
            >
              <img
                className={classes.actionimg}
                alt="moderate"
                src={`/static/images/icons/${theme}/gear.png`}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

UserAppBar.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  onNavBack: PropTypes.func,
  onModerate: PropTypes.func
};

export default withStyles(styles)(UserAppBar);
