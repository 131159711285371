import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { OptionToggleBtn } from "components";

const styles = (theme) => ({
  root: {
    // height: '100%',
    position: "relative",
    width: 120,
    textAlign: "center",
  },
  title: {
    textTransform: "none",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  description: {
    textTransform: "none",
    fontSize: "10px",
    lineHeight: "12px",
    color: theme.palette.text.primary,
  }
});

class Option extends React.Component {

  render() {
    const { classes, theme, buttontype, mode, title, description, onToggle } = this.props;

    return (
      <div className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.button}>
          <OptionToggleBtn
            theme={theme}
            buttontype={buttontype}
            mode={mode} 
            onToggle={onToggle}
          />
        </div>
        {description &&
          <Typography className={classes.description}>{description}</Typography>
        }
      </div>
    );
  }
}

Option.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  mode: PropTypes.bool,
  buttontype: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onToggle: PropTypes.func
};

export default withStyles(styles)(Option);
