export const QUERY_SCRAPE_REQUEST = `
query scrape_request {
  scrape_request(
    order_by: {requested_at: asc}
  ) {
    id
    name
    image
    source_id
    feed_id
    requested_by
    requested_at
    scraped_at
    status
  }
}`;

export const MUTATION_INSERT_SCRAPE_REQUEST = `
mutation insert_scrape_request(
  $name: String!,
  $image: String,
  $source_id: String!,
  $feed_id: String,
  $requested_by: String!
) {
  insert_scrape_request(
    objects: {
      name: $name,
      image: $image,
      source_id: $source_id, 
      feed_id: $feed_id,
      requested_by: $requested_by
    }
  ) {
    affected_rows
    returning {
      id
      name
      image
      source_id
      feed_id
      requested_by
      requested_at
      scraped_at
      status
    }
  }
}`;

export const MUTATION_DELETE_SCRAPE_REQUEST = `
mutation delete_scrape_request(
  $id: Int!
) {
  delete_scrape_request(
    where: {
      id: {_eq: $id}
    }
  ) {
    affected_rows
  }
}`;
