import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: '24px',
    height: '24px',
    minWidth: 180,
    lineHeight: '14px',
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
  ban_icon: {
    marginRight: 4,
  },
  divider: {
    height: 16,
  },
});

class PopMenuHomeComment extends React.Component {
  render() {
    const { 
      classes, 
      theme,
      moderator,
      onRemove, 
      onBan1d, 
      onBan7d, 
      onBanPerm, 
      onCloseComments, 
      onDeleteComments,
      onReport,
      onShare, 
    } = this.props;

    return (
      <div>
        <MenuList className={classes.menu_list}>
          {moderator &&
            <MenuItem className={classes.menu_item} onClick={onRemove}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"remove"}
                  src={`/static/images/icons/${theme}/delete.png`}
                />
              </span>
              Remove Thread
            </MenuItem>
          }

          {moderator &&
            <MenuItem className={classes.menu_item} onClick={onBan1d}>
              <span className={classes.ban_icon}>
                <AddIcon fontSize="small"
                />
              </span>
              <span>
                <img
                  className={classes.menu_img}
                  alt={"ban1d"}
                  src={`/static/images/icons/${theme}/banned.png`}
                />
              </span>
              1-Day Ban User
            </MenuItem>
          }

          {moderator &&
            <MenuItem className={classes.menu_item} onClick={onBan7d}>
              <span className={classes.ban_icon}>
                <AddIcon fontSize="small"
                />
              </span>
              <span>
                <img
                  className={classes.menu_img}
                  alt={"ban1d"}
                  src={`/static/images/icons/${theme}/banned.png`}
                />
              </span>
              7-Day Ban User
            </MenuItem>
          }

          {moderator &&
            <MenuItem className={classes.menu_item} onClick={onBanPerm}>
              <span className={classes.ban_icon}>
                <AddIcon fontSize="small"
                />
              </span>
              <span>
                <img
                  className={classes.menu_img}
                  alt={"ban1d"}
                  src={`/static/images/icons/${theme}/banned.png`}
                />
              </span>
              Permanently Ban
            </MenuItem>
          }
          
          {moderator &&
            <Divider className={classes.divider} />
          }

          {moderator &&
            <MenuItem className={classes.menu_item} onClick={onCloseComments}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"edit"}
                  src={`/static/images/icons/${theme}/close.png`}
                />
              </span>
              Close Comments
            </MenuItem>
          }

          {moderator &&
            <MenuItem className={classes.menu_item} onClick={onDeleteComments}>
              <span className={classes.ban_icon}>
                <AddIcon fontSize="small"
                />
              </span>
              <span>
                <img
                  className={classes.menu_img}
                  alt={"ban1d"}
                  src={`/static/images/icons/${theme}/delete.png`}
                />
              </span>
              Delete Comments
            </MenuItem>
          }

          {!moderator &&
            <MenuItem className={classes.menu_item} onClick={onReport}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"report"}
                  src={`/static/images/icons/${theme}/report.png`}
                />
              </span>
              Report Thread
            </MenuItem>
          }

          {!moderator &&
            <MenuItem className={classes.menu_item} onClick={onShare}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"share"}
                  src={`/static/images/icons/${theme}/cshare.png`}
                />
              </span>
              Share Thread
            </MenuItem>
          }
        </MenuList>
      </div>
    );
  }
}

PopMenuHomeComment.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  moderator: PropTypes.bool,
  onDelete: PropTypes.func,
  onBan1d: PropTypes.func,
  onBan7d: PropTypes.func,
  onBanPerm: PropTypes.func,
  onCloseComments: PropTypes.func,
  onDeleteComments: PropTypes.func,
  onReport: PropTypes.func,
  onShare: PropTypes.func,
};

export default withStyles(styles)(PopMenuHomeComment);
