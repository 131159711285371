export const QUERY_ARTICLE_BY_NID = `
query article_by_nid(
  $nid: String!
) {
  articles(
    where: {
      nid: {_eq: $nid}
    }
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    source{
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      socialtags {
        tag
        type
      }
      feed_sources{
        feed {
              id
              category_id
              name
              description
              slug
              image
              thumbnail
              tags
              followers
              created_at
              created_by
              is_default
              approved
              last_viewlog
              ai_moderation
              private
              op_comments
              op_posts
              op_members
              op_payment
              op_anonymity
              comment_conf
              ai_comment_conf
              ai_summary_conf
              tg_wallet
              tg_address
              tg_amount
              token_address
              token_amount
              channel_instagram
              channel_tiktok
              channel_twitter
              channel_youtube
              article_count
              notifications
              notif_date
              updated_at
          feed_sources{
            source{
              name
              description
              branch
            }
          }
        }
      }
    }
    home_cluster_members {
      id
      parent_id
      article_id
      article{
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        political_sentiments
        summary_status
        upvotes
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
    sponsored_articles{
      id
      article_id
      sponsorship_id
      user_id
      user{
        uid
        image
      }
      sponsorship{
        id
        source_id
        status
      }
    }
  }
}`;

export const QUERY_ARTICLES = `
query articles(
  $pagesize: Int!,
  $offset: Int!
) {
  articles(
    order_by: {published: desc},
    limit: $pagesize,
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;

export const QUERY_PROFILE_ARTICLES = `
query articles(
  $uid: String!,
  $sourceId: String!,
  $dateFrom: Int!
) {
  articles(
    where: {
      _or: [
        { branch: { _eq: 97 } },
        { branch: { _eq: 100 } },
        { source_id: { _eq: $sourceId } },
      ],
      txt_param1: { _eq: $uid },
      published: { _lt: $dateFrom }
    },
    order_by: { published: desc }
    limit: 5
  ){
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    type
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    source {
      id
    category_id
    name
    description
    slug
    image
    thumbnail
    branch
    weblink
    translate
    translateLang
    translateAPI
    followers
    upvotes
    throttle
    disableFullText
    articles_no
    created_at
    created_by
    approved
    private
    last_updated
    updated_at
    transcription_enabled
    is_bias_check
    total_bias_score
    total_biased_articles_no
    is_published
    feed_sources {
      id
      feed_id
      source_id
      created_at
      create_user {
        uid
        username
      }
      feed {
        id
    category_id
    name
    description
    slug
    image
    thumbnail
    tags
    followers
    created_at
    created_by
    approved
    last_viewlog
    private
    ai_moderation
    op_comments
    op_posts
    op_members
    op_payment
    op_anonymity
    comment_conf
    ai_comment_conf
    ai_summary_conf
    tg_wallet
    tg_address
    tg_amount
    token_address
    token_amount
    channel_instagram
    channel_tiktok
    channel_twitter
    channel_youtube
    article_count
    notifications
    notif_date
    updated_at
    feed_sources {
      id
      source_id
      keyword_filter
      approved
      created_at
      create_user {
        uid
        username
      }
      source {
        id
        category_id
        name
        description
        slug
        image
        thumbnail
        branch
        weblink
        translate
        translateLang
        translateAPI
        followers
        upvotes
        throttle
        disableFullText
        articles_no
        created_at
        created_by
        approved
        private
        last_updated
        updated_at
        transcription_enabled
        total_bias_score
        total_biased_articles_no
        is_bias_check
        is_published
        socialtags {
          tag
          type
        }
      }
    }
    feed_reports {
      id
      report
      approved
      reported_at
    }
    feed_moderators {
      id
      feed_id
      user_id
      created
      owner
      approved
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_followers {
      id
      user_id
      feed_id
      created
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_videocasts_aggregate {
      aggregate {
        count
      }
    }
    ai_persons{
      id
      user_id
      seed_phrase
      is_owner
      user{
        name
        biography
        image
        username
        }
    }
      }
    }
    socialtags {
      tag
      type
    }
    }
  }
}`;

export const QUERY_ARTICLE_REPOSTS = `
query article_repost(
  $uid: String!,
  $dateFrom: timestamptz!,
  $dateTo: timestamptz!
) {
  article_repost(
    where: {
      reposter_id: { _eq: $uid },
      created_at: {
        _lt: $dateFrom,
        _gt: $dateTo
      }
    },
    order_by: { created_at: desc }
  ) {
    created_at
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      source {
        id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      feed_sources {
        id
        feed_id
        source_id
        created_at
        create_user {
          uid
          username
        }
        feed {
          id
      category_id
      name
      description
      slug
      image
      thumbnail
      tags
      followers
      created_at
      created_by
      approved
      last_viewlog
      private
      ai_moderation
      op_comments
      op_posts
      op_members
      op_payment
      op_anonymity
      comment_conf
      ai_comment_conf
      ai_summary_conf
      tg_wallet
      tg_address
      tg_amount
      token_address
      token_amount
      channel_instagram
      channel_tiktok
      channel_twitter
      channel_youtube
      article_count
      notifications
      notif_date
      updated_at
      feed_sources {
        id
        source_id
        keyword_filter
        approved
        created_at
        create_user {
          uid
          username
        }
        source {
          id
          category_id
          name
          description
          slug
          image
          thumbnail
          branch
          weblink
          translate
          translateLang
          translateAPI
          followers
          upvotes
          throttle
          disableFullText
          articles_no
          created_at
          created_by
          approved
          private
          last_updated
          updated_at
          transcription_enabled
          total_bias_score
          total_biased_articles_no
          is_bias_check
          is_published
          socialtags {
            tag
            type
          }
        }
      }
      feed_reports {
        id
        report
        approved
        reported_at
      }
      feed_moderators {
        id
        feed_id
        user_id
        created
        owner
        approved
        user {
          uid
          name
          username
          biography
          image
          msgToken
        }
      }
      feed_followers {
        id
        user_id
        feed_id
        created
        user {
          uid
          name
          username
          biography
          image
          msgToken
        }
      }
      feed_videocasts_aggregate {
        aggregate {
          count
        }
      }
      ai_persons{
        id
        user_id
        seed_phrase
        is_owner
        user{
          name
          biography
          image
          username
          }
      }
        }
      }
      socialtags {
        tag
        type
      }
      }
    }
  }
}`;

export const QUERY_ALL_ARTICLE_REPOSTS = `
query article_repost(
  $uid: String!,
  $dateFrom: timestamptz!
) {
  article_repost(
    where: {
      reposter_id: { _eq: $uid },
      created_at: {
        _lt: $dateFrom
      }
    },
    order_by: { created_at: desc }
    limit: 5
  ) {
    created_at
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      source {
        id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      feed_sources {
        id
        feed_id
        source_id
        created_at
        create_user {
          uid
          username
        }
        feed {
          id
      category_id
      name
      description
      slug
      image
      thumbnail
      tags
      followers
      created_at
      created_by
      approved
      last_viewlog
      private
      ai_moderation
      op_comments
      op_posts
      op_members
      op_payment
      op_anonymity
      comment_conf
      ai_comment_conf
      ai_summary_conf
      tg_wallet
      tg_address
      tg_amount
      token_address
      token_amount
      channel_instagram
      channel_tiktok
      channel_twitter
      channel_youtube
      article_count
      notifications
      notif_date
      updated_at
      feed_sources {
        id
        source_id
        keyword_filter
        approved
        created_at
        create_user {
          uid
          username
        }
        source {
          id
          category_id
          name
          description
          slug
          image
          thumbnail
          branch
          weblink
          translate
          translateLang
          translateAPI
          followers
          upvotes
          throttle
          disableFullText
          articles_no
          created_at
          created_by
          approved
          private
          last_updated
          updated_at
          transcription_enabled
          total_bias_score
          total_biased_articles_no
          is_bias_check
          is_published
          socialtags {
            tag
            type
          }
        }
      }
      feed_reports {
        id
        report
        approved
        reported_at
      }
      feed_moderators {
        id
        feed_id
        user_id
        created
        owner
        approved
        user {
          uid
          name
          username
          biography
          image
          msgToken
        }
      }
      feed_followers {
        id
        user_id
        feed_id
        created
        user {
          uid
          name
          username
          biography
          image
          msgToken
        }
      }
      feed_videocasts_aggregate {
        aggregate {
          count
        }
      }
      ai_persons{
        id
        user_id
        seed_phrase
        is_owner
        user{
          name
          biography
          image
          username
          }
      }
        }
      }
      socialtags {
        tag
        type
      }
      }
    }
  }
}`;

// export const QUERY_PROFILE_ARTICLES = `
// query articles(
//   $uid: String!,
//   $sourceId: String!
// ) {
//   articles(
//     where: {
//       _or: [
//         {source_id: {_is_null: true} },
//         { source_id: { _eq: $sourceId } },
//       ],
//       txt_param1: {_eq: $uid},
//     },
//     order_by: {published: desc}
//   ) {
//     nid
//     source_id
//     title
//     author
//     summary
//     image
//     text
//     html
//     summarized_text
//     tr_title
//     tr_summary
//     tr_text
//     translated
//     branch
//     country
//     crawled_at
//     media_url
//     url
//     author_image
//     published
//     data
//     extra_data
//     param1
//     param2
//     param3
//     param4
//     param5
//     txt_param1
//     txt_param2
//     image_thumb
//     author_image_thumb
//     link_preview
//     discussion_twitter
//     discussion_reddit
//     upvotes
//     type
//     ai_summary
//     ai_transcription
//     political_sentiments
//     summary_status
//     threads {
//       id
//       comments_aggregate {
//         aggregate {
//           count
//         }
//       }
//     }
//   }
// }`;

export const QUERY_NEWSPAPERS_EQ_COUNTRY = `
query newspapers_eq_country(
  $country: Int!,
  $pagesize: Int!,
  $offset: Int!
) {
  articles(
    where: {
      country: {_eq: $country}
    },
    order_by: {published: desc},
    limit: $pagesize,
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;

export const QUERY_ARTICLES_EQ_BRANCH = `
query articles_eq_branch(
  $branch: Int!,
  $pagesize: Int!,
  $offset: Int!
) {
  articles(
    where: {
      branch: {_eq: $branch}
    },
    order_by: {published: desc},
    limit: $pagesize,
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;

export const QUERY_ARTICLES_EQ_SOURCE = `
query articles_eq_source(
  $source_id: String!,
  $pagesize: Int!,
  $offset: Int!
) {
  articles(
    where: {
      source_id: {_eq: $source_id}
    },
    order_by: {published: desc},
    limit: $pagesize,
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    source {
      id
    category_id
    name
    description
    slug
    image
    thumbnail
    branch
    weblink
    translate
    translateLang
    translateAPI
    followers
    upvotes
    throttle
    disableFullText
    articles_no
    created_at
    created_by
    approved
    private
    last_updated
    updated_at
    transcription_enabled
    is_bias_check
    total_bias_score
    total_biased_articles_no
    is_published
    feed_sources {
      id
      feed_id
      source_id
      created_at
      create_user {
        uid
        username
      }
      feed {
        id
    category_id
    name
    description
    slug
    image
    thumbnail
    tags
    followers
    created_at
    created_by
    approved
    last_viewlog
    private
    ai_moderation
    op_comments
    op_posts
    op_members
    op_payment
    op_anonymity
    comment_conf
    ai_comment_conf
    ai_summary_conf
    tg_wallet
    tg_address
    tg_amount
    token_address
    token_amount
    channel_instagram
    channel_tiktok
    channel_twitter
    channel_youtube
    article_count
    notifications
    notif_date
    updated_at
    feed_sources {
      id
      source_id
      keyword_filter
      approved
      created_at
      create_user {
        uid
        username
      }
      source {
        id
        category_id
        name
        description
        slug
        image
        thumbnail
        branch
        weblink
        translate
        translateLang
        translateAPI
        followers
        upvotes
        throttle
        disableFullText
        articles_no
        created_at
        created_by
        approved
        private
        last_updated
        updated_at
        transcription_enabled
        total_bias_score
        total_biased_articles_no
        is_bias_check
        is_published
        socialtags {
          tag
          type
        }
      }
    }
    feed_reports {
      id
      report
      approved
      reported_at
    }
    feed_moderators {
      id
      feed_id
      user_id
      created
      owner
      approved
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_followers {
      id
      user_id
      feed_id
      created
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_videocasts_aggregate {
      aggregate {
        count
      }
    }
    ai_persons{
      id
      user_id
      seed_phrase
      is_owner
      user{
        name
        biography
        image
        username
        }
    }
      }
    }
    socialtags {
      tag
      type
    }
    }
  }
}`;

export const QUERY_ARTICLES_IN_SOURCES = `
query articles_in_sources(
  $source_ids: [String!],
  $pagesize: Int!,
  $offset: Int!
) {
  articles(
    where: {
      source_id: {_in: $source_ids},
      type:{_eq: 0},
      _or: [
        {home_cluster:{parent_id: {_is_null: true}}} , 
          {home_cluster_aggregate: {count: {predicate: {_eq: 0}}}}
        ]
    }, 
    order_by: {published: desc},
    limit: $pagesize, 
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    type
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    } 
    source{
      feed_sources{
        feed{
          ai_moderation
          id
          name
          description
          feed_sources{
            source{
              name
              description
              branch
            }
          }
        }
      }
    }
    home_cluster_members {
      id
      parent_id
      article_id
      article{
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        upvotes
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
}`;


export const QUERY_ARTICLES_IN_SOURCES_NULLS_LAST = `
query articles_in_sources(
  $source_ids: [String!],
  $pagesize: Int!,
  $offset: Int!
) {
  articles(
    where: {
      source_id: {_in: $source_ids},
      type:{_eq: 0}, 
      _or: [
        {home_cluster:{parent_id: {_is_null: true}}} , 
        {home_cluster_aggregate: {count: {predicate: {_eq: 0}}}}
      ]
    }, 
    order_by: {published: desc_nulls_last},
    limit: $pagesize, 
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    type
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    source{
      feed_sources{
        feed{
          ai_moderation
          name
          id
          description
          feed_sources{
            source{
              name
              branch
              description
            }
          }
        }
      }
    }
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    home_cluster_members {
      id
      parent_id
      article_id
      article{
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        upvotes
        ai_summary
        ai_transcription
        political_sentiments
        summary_status
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
}`;


export const QUERY_ARTICLES_IN_SOURCES_OF_BRANCH = `
query articles_in_sources_of_branch(
  $branch: Int!,
  $source_ids: [String!],
  $pagesize: Int!,
  $offset: Int!
) {
  articles(
    where: {
      branch: {_eq: $branch},
      source_id: {_in: $source_ids},
      _or: [
        {home_cluster:{parent_id: {_is_null: true}}} , 
        {home_cluster_aggregate: {count: {predicate: {_eq: 0}}}}
      ]
  	}, 
    order_by: {published: desc},
    limit: $pagesize, 
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    home_cluster_members {
      id
      parent_id
      article_id
      article{
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        upvotes
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
    }
    }
  }
}`;

export const QUERY_ARTICLES_IN_SOURCES_OF_BRANCH_NULLS_LAST = `
query articles_in_sources_of_branch(
  $branch: Int!,
  $source_ids: [String!],
  $pagesize: Int!,
  $offset: Int!
) {
  articles(
    where: {
      branch: {_eq: $branch},
      source_id: {_in: $source_ids},
      _or: [
        {home_cluster:{parent_id: {_is_null: true}}} , 
        {home_cluster_aggregate: {count: {predicate: {_eq: 0}}}}
      ]
  	}, 
    order_by: {published: desc_nulls_last},
    limit: $pagesize, 
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    political_sentiments
    ai_transcription
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    home_cluster_members {
      id
      parent_id
      article_id
      article{
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        upvotes
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
}`;


// Hasura function
// CREATE FUNCTION articles_in_sources_of_branch(article_branch integer, source_ids text[])
// RETURNS SETOF articles AS $$
//     SELECT *
//     FROM articles
//     WHERE
//         branch = article_branch AND source_id = ANY (source_ids)
// $$ LANGUAGE sql STABLE;
export const QUERY_ARTICLES_IN_SOURCES_OF_BRANCH_BYFUNC = (branch, source_ids, pagesize, offset) => {
  return `
  query articles_in_sources_of_branch {
    articles_in_sources_of_branch_aggregate(
      args: {
        article_branch: ${branch},
        source_ids: "{${source_ids.join(",")}}",
      }, 
      order_by: {published: desc},
      limit: ${pagesize}, 
      offset: ${offset}
    ) {
      nodes {
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        upvotes
        ai_summary
        political_sentiments
        ai_transcription
        summary_status
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        } 
      }
    }
  }`;
};


export const QUERY_NEWSPAPERS_IN_SOURCES_OF_COUNTRY = `
query newspapers_eq_country_in_sources(
  $country: Int!,
  $source_ids: [String!],
  $pagesize: Int!,
  $offset: Int!  
) {
  articles(
    where: {
      country: {_eq: $country},
      source_id: {_in: $source_ids}
    },
    order_by: {published: desc},
    limit: $pagesize,
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    political_sentiments
    ai_transcription
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;

export const QUERY_NEWSPAPERS_IN_SOURCES_OF_COUNTRY_NULLS_LAST = `
query newspapers_eq_country_in_sources(
  $country: Int!,
  $source_ids: [String!],
  $pagesize: Int!,
  $offset: Int!  
) {
  articles(
    where: {
      country: {_eq: $country},
      source_id: {_in: $source_ids}
    },
    order_by: {published: desc_nulls_last},
    limit: $pagesize,
    offset: $offset
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;



// Hasura function
// CREATE FUNCTION articles_in_sources_of_country(article_country integer, source_ids text[])
// RETURNS SETOF articles AS $$
//     SELECT *
//     FROM articles
//     WHERE
//         country = article_country AND source_id = ANY (source_ids)
// $$ LANGUAGE sql STABLE;
export const QUERY_ARTICLES_IN_SOURCES_OF_COUNTRY_BYFUNC = (country, source_ids, pagesize, offset) => {
  return `
  query articles_in_sources_of_country {
    articles_in_sources_of_country_aggregate(
      args: {
        article_country: ${country},
        source_ids: "{${source_ids.join(",")}}",
      }, 
      order_by: {published: desc},
      limit: ${pagesize}, 
      offset: ${offset}
    ) {
      nodes {
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        upvotes
        ai_summary
        ai_transcription
        political_sentiments
        summary_status
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        } 
      }
    }
  }`;
};

export const MUTATION_DELETE_ARTICLE = `
mutation (
  $nid: String!
) {
  delete_articles(
    where: {
      nid: {_eq: $nid}
    } 
  ) {
    affected_rows
  }
}`;

export const QUERY_ARTICLE_VOTER_COUNTS_BY_USER = `
query article_voter_counts_by_user(
  $uid: String!
  $sourceId: String!
) {
  article_voter_aggregate(
    where: {
      voter_id: {_eq: $uid} 
    }
  ) {
    aggregate {
      count
    }
  }
  articles_aggregate(
    where: {
      _or: [
        { branch: { _eq: 97 } },
        { branch: { _eq: 100 } },
        { source_id: { _eq: $sourceId } },
      ],
      txt_param1: { _eq: $uid }
    }
  ) {
    aggregate {
      count
    }
  }
  article_repost_aggregate(
    where: {
      reposter_id: { _eq: $uid },
    }
  ) {
    aggregate {
      count
    }
  }
}`;

export const MUTATION_ARTICLE_INC_UPVOTES = `
mutation article_inc_upvotes(
  $article_id: String!,
  $voter_id: String!,
  $voted_username: String
) {
  update_articles(
    where: {
      nid: {_eq: $article_id}
    },
    _inc: {upvotes: 1}
  ) {
    affected_rows
    returning {
      upvotes
    }
  }

  insert_article_voter(
    objects: {
      article_id: $article_id, 
      voter_id: $voter_id,
      voted_username: $voted_username
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_ARTICLE_DEC_UPVOTES = `
mutation article_dec_upvotes(
  $article_id: String!,
  $voter_id: String!,
) {
  update_articles(
    where: {
      nid: {_eq: $article_id}
    },
    _inc: {upvotes: -1}
  ) {
    affected_rows
    returning {
      upvotes
    }
  }
  
  delete_article_voter(
    where: {
      article_id: {_eq: $article_id}, 
      voter_id: {_eq: $voter_id}
    }
  ) {
    affected_rows
  }
}`;

export const QUERY_ARTICLE_REPOST_BY_USER = `
query article_repost_by_user(
  $reposter_id: String!
) {
  article_repost(
    where: {
      reposter_id: {_eq: $reposter_id} 
    }
  ) {
    article_id
  }
}`;

export const MUTATION_ARTICLE_INC_REPOSTS = `
mutation article_inc_reposts(
  $article_id: String!,
  $reposter_id: String!
) {
  update_articles(
    where: {
      nid: {_eq: $article_id}
    },
    _inc: {reposts: 1}
  ) {
    affected_rows
    returning {
      reposts
    }
  }

  insert_article_repost(
    objects: {
      article_id: $article_id, 
      reposter_id: $reposter_id
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_ARTICLE_DEC_REPOSTS = `
mutation article_dec_reposts(
  $article_id: String!,
  $reposter_id: String!,
) {
  update_articles(
    where: {
      nid: {_eq: $article_id}
    },
    _inc: {reposts: -1}
  ) {
    affected_rows
    returning {
      reposts
    }
  }
  
  delete_article_repost(
    where: {
      article_id: {_eq: $article_id}, 
      reposter_id: {_eq: $reposter_id}
    }
  ) {
    affected_rows
  }
}`;


// insert article mutation for user post
// param1 : approved (0: non-approved(default), 1: approved)
// param2 : moderator (0: user, 1: moderator)
// param3 : region id if the post is map post
export const MUTATION_INSERT_USERPOST = `
mutation insert_userpost (
  $nid: String!,
  $source_id: String!,
  $author: String,
  $title: String,
  $summary: String,
  $text: String,
  $image: String,
  $media_url: String,
  $branch: Int!,
  $url: String,
  $author_image: String,
  $published: Int!,
  $param1: Int,
  $param2: Int,
  $param3: Int,
  $txt_param1: String,
  $link_preview: jsonb
) {
  insert_articles(
    objects: {
      nid: $nid,
      source_id: $source_id,
      author: $author,
      title: $title,
      summary: $summary,
      text: $text,
      image: $image,
      media_url: $media_url
      branch: $branch,
      url: $url,
      author_image: $author_image,
      published: $published,
      param1: $param1,
      param2: $param2,
      param3: $param3,
      txt_param1: $txt_param1,
      link_preview: $link_preview,
    }
  ) {
    affected_rows
    returning {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      political_sentiments
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}`;

export const MUTATION_UPDATE_USERPOST = `
mutation upate_userpost (
  $nid: String!,
  $summary: String!,
  $text: String!,
  $published: Int!,
  $link_preview: jsonb
) {
  update_articles(
    where: {
      nid: {_eq: $nid}
    },
    _set: {
      summary: $summary,
      text: $text,
      published: $published,
      link_preview: $link_preview
    }
  ) {
    affected_rows
    returning {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      political_sentiments
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}`;

export const MUTATION_APPROVE_USERPOST = `
mutation approve_userpost (
  $nid: String!,
  $approved: Int!
) {
  update_articles(
    where: {
      nid: {_eq: $nid}
    },
    _set: {
      param1: $approved
    }
  ) {
    affected_rows
  }
}`;

export const QUERY_AI_SUMMARY = `
query ai_summary(
  $source_ids: [String!], 
) {
  articles(
    where: {
      source_id: {_in: $source_ids},
      type:{_eq: 1},
    }, 
    order_by: {published: desc} 
    limit: 1, 
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    type
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;

export const QUERY_CLUSTER_MEMBERS_BY_ARTICLE = `
query cluster_members_by_article(
  $nid: String!
) {
  home_clusters(
    where: {
      parent_id: {_eq: $nid}
    }
  ) {
    article{
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      political_sentiments
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}`;

export const QUERY_ARTICLE_REPORTS_COUNT =`
query (
  $time: timestamptz! ,$id:String!
) {
  article_reports_aggregate(
    where: {
      _and: [
        { reported_by: {_eq: $id} }
        { reported_at: {_gt: $time} }
      ]
    }
  ) {
    aggregate {
      count
    }
  }
}`;

//sponsorship
export const QUERY_SPONSORED_ARTICLES_BY_ID = `
query sponsored_articles_by_id (
  $id: String!
) {
  sponsored_articles(
    where: { article_id: {_eq: $id} }
  ) {
    id
    article_id
    user_id
    sponsorship_id
    user_id
    credits
    user {
      uid
      image
      credits
    }
  }
}`;

export const QUERY_ARTCILES_WITH_SUMMARY = `
query articles_with_summary(
  $nid: String!,
  $url: String!,
  $videoId: String!,
  $userId: String!
) {
  articles(
    where: {
      _and: [
        { txt_param1: { _eq: $userId } },
        { branch: { _in: [103, 104] } },
        {
          _or: [
            { nid: { _eq: $nid } },
            { url: { _eq: $url } },
            { media_url: { _eq: $url } }, 
            { url: { _like: $videoId } }, 
            { media_url: { _like: $videoId } }
          ]
        }
      ]
    }
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    source{
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      socialtags {
        tag
        type
      }
      feed_sources{
        feed {
              id
              category_id
              name
              description
              slug
              image
              thumbnail
              tags
              followers
              created_at
              created_by
              is_default
              approved
              last_viewlog
              ai_moderation
              private
              op_comments
              op_posts
              op_members
              op_payment
              op_anonymity
              comment_conf
              ai_comment_conf
              ai_summary_conf
              tg_wallet
              tg_address
              tg_amount
              token_address
              token_amount
              channel_instagram
              channel_tiktok
              channel_twitter
              channel_youtube
              article_count
              notifications
              notif_date
              updated_at
          feed_sources{
            source{
              name
              description
              branch
            }
          }
        }
      }
    }
    home_cluster_members {
      id
      parent_id
      article_id
      article{
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        political_sentiments
        summary_status
        upvotes
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
    sponsored_articles{
      id
      article_id
      sponsorship_id
      user_id
      user{
        uid
        image
      }
      sponsorship{
        id
        source_id
        status
      }
    }
  }
}`;

export const QUERY_GET_ONGOING_TRANSCRIPTIONS = `
query getOnGoingTranscriptions(
  $userId: String!
) {
  summary_api_logs(
    where: { 
      status: { _in: [2, 0] },
      user_id: { _eq: $userId }
    },
    order_by: { created_at: asc }
  ) {
    article_id
    status
    user_id
    branch
    created_at
  }
}`;

export const QUERY_SUMMARY_ARTICLES = `query get_summary_articles (
  $from_date: Int!,
  $to_date: Int!
) {
  articles (
    where: {
      
      branch: {_in: [103, 104]},
      published: {_gte: $from_date, _lte: $to_date}
    }, 
    order_by: [
      {published: desc},
      {nid: desc}
    ]
  )  {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    ai_summary
    ai_transcription
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    home_cluster_members {
      id
      parent_id
      article_id
      article{
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        upvotes
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
}`