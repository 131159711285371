import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";
import { withFirebase, GraphqlService } from "services";
import MetaTags from "react-meta-tags";
import { ToastContainer } from "react-toastify";
// import { withAuthentication } from "session";
import * as ROUTES from "constants/routes";
import {
  ArticleBar,
  YoutubeView,
  TwitterView,
  InstagramView,
  TwitchView,
  MediumView,
  RedditView,
  HackernewsView,
  SlashdotView,
  PodcastView,
  RssView,
  TiktokView,
  PinterestView,
  VscoView,
  SpotifyView,
  QuoraView,
  FacebookView,
  SubstackView,
  GmailView,
  VimeoView,
  WeblinkView,
  MastodonView,
  BlueskyView,
  UserpostView,
  DiscussionButton,
  DiscussTwitterCard,
  DiscussRedditCard,
  CommentList,
  DlgSharePost,
  SummarypostView,
  SimilarArticles,
  Transcript,
  AISummary,
  WaitingSpinner,
  DlgLoginConfirm,
  GenerateSummaryView,
  ProfilepostView,
} from "components";
import {
  ARTICLE_BRANCH_YOUTUBE,
  ARTICLE_BRANCH_TWITTER,
  ARTICLE_BRANCH_INSTAGRAM,
  ARTICLE_BRANCH_TWITCH,
  ARTICLE_BRANCH_MEDIUM,
  ARTICLE_BRANCH_REDDIT,
  ARTICLE_BRANCH_HACKERNEWS,
  ARTICLE_BRANCH_SLASHDOT,
  ARTICLE_BRANCH_PODCAST,
  ARTICLE_BRANCH_RSSFEED,
  ARTICLE_BRANCH_TIKTOK,
  ARTICLE_BRANCH_PINTEREST,
  ARTICLE_BRANCH_VSCO,
  ARTICLE_BRANCH_SPOTIFY,
  ARTICLE_BRANCH_QUORA,
  ARTICLE_BRANCH_FACEBOOK,
  ARTICLE_BRANCH_SUBSTACK,
  ARTICLE_BRANCH_GMAIL,
  ARTICLE_BRANCH_VIMEO,
  ARTICLE_BRANCH_WEBSITE,
  ARTICLE_BRANCH_MASTODON,
  ARTICLE_BRANCH_BLUESKY,
  ARTICLE_BRANCH_USERPOST,
  ARTICLE_BRANCH_AISUMMARY,
  ARTICLE_BRANCH_NEWSPAPER,
  ARTICLE_BRANCH_YOUTUBE_SUMMARY,
  ARTICLE_BRANCH_PODCAST_SUMMARY,
  ARTICLE_BRANCH_PROFILEPOST,
} from "constants/branches";
import {
  THREAD_TYPE_ARTICLE,
  MAX_ARTICLE_WIDTH,
  DISCUSSION_TWITTER,
  DISCUSSION_REDDIT,
  DISCUSSION_RAVEN,
  FEED_COMMENT_MODERATED,
  GRAPHQL_ERROR,
  GRAPHQL_SUCCESS
} from "constants/types";
import { 
  getArticle, 
  getFeedInfo, 
  getMainInfo, 
  getThreadOfArticle,
  isCommentEnabledFeed,
  getSourceInfoBySlug,
  setError,
  isBannedPosts,
  upvoteArticle
} from "dataapis";
import { 
  ToastSuccess,
  ToastError,
  ToastWarning
} from "utility/toast";
import { logger } from "utility/logging";
import { createAiSummary } from "utility/ravenapi";  
import { getAuthToken} from "dataapis";
import PoliticalSentiment from "components/SharedControls/PoliticalSentiment";
import { getTranscriptionQueryHistory } from "dataapis/thread";
import Sponsorship from "components/SharedControls/Sponsorship";
import { withAuthentication } from "session";

const styles = (theme) => ({
  root: {
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  discussion: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    margin: theme.spacing(1),
  },
  discussbutton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    margin: 0,
  },
  disabletxtdiv: {
    textAlign: "center",
  },
  disabletxt: {
    margin: theme.spacing(1),
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  politicalCard:{
    width: "100%",
    borderRadius: 25,
    // borderColor: theme.palette.feedstroke.youtube,
    borderWidth: 2,
    borderStyle: "solid",
    // backgroundColor: theme.palette.feedbackground.youtube,
    padding: "8px",
    display: "flex",
    textAlign: "center",
    color: "#03C04A",
    fontSize: '15px',
    letterSpacing: '0.05px',
    fontWeight: "bold",
  }
});

class Social extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDiscussTwitter: false,
      showDiscussReddit: false,
      postShareDlg: false,
      discussShareDlg: false,
      discussShareInfo: null,
      summaryRequestStatus : false,
      loginDlg: false,
      isQueryPost: false,
      showSponsorship: false,
      expanded:false,
      ifSponsored: false,
      sponsoredBy: [],
      isDisabled:false
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this); 
    this.handleMemberTrial = this.handleMemberTrial.bind(this);
    this.handleSharePost = this.handleSharePost.bind(this);
    this.handleCloseSharePost = this.handleCloseSharePost.bind(this);

    this.handleClickDiscussion = this.handleClickDiscussion.bind(this);
    this.handleShareRedditDiscussion =
      this.handleShareRedditDiscussion.bind(this);
    this.handleShareTwitterDiscussion =
      this.handleShareTwitterDiscussion.bind(this);
      this.handleCloseShareDiscuss = this.handleCloseShareDiscuss.bind(this);
    this.handleAISummary = this.handleAISummary.bind(this);
    this.handleClickArticle = this.handleClickArticle.bind(this);
    this.toggleCancelSponsorship = this.toggleCancelSponsorship.bind(this);
    this.getSponsoredUsers = this.getSponsoredUsers.bind(this);
    this.toggleSponsorship = this.toggleSponsorship.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleClickUpvote = this.handleClickUpvote.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const { authUser, selected_article } = this.props
    const nid = this.props.match.params.id;

    this.setWaiting(true);
    this.updateIfSponsored();
    await getMainInfo();

    const category_id = this.props.match.params.category_id;
    if (category_id) {
      logger.log("category id of social article :", category_id);
      const category = this.props.categories.find(
        (item) => item.id === category_id
      );
      if (category) {
        this.props.selectCategory(category);
      }
    }
    
    const source_slug = this.props.match.params.source_slug;
     if (source_slug) {
	    const articleSource = this.props.sources.find(
	      (source) => source_slug === source.slug
	    ); 
	    if (!articleSource) {
	      const source = await getSourceInfoBySlug(source_slug);
	      this.props.addSource(source);
	    } 
    }

    const feed_slug = this.props.match.params.feed_slug;
    if (feed_slug && !this.props.selected_feed) {
      logger.log("feed slug of social article :", feed_slug);
      await getFeedInfo(feed_slug);
    }
    const historyForQueryArticles = await getTranscriptionQueryHistory(nid)
    console.log("history of the article",historyForQueryArticles)
    if(historyForQueryArticles?.length>0){
      this.props.setHistoryPrompt(historyForQueryArticles)
    }
    await Promise.all([
      getArticle(nid), 
      getThreadOfArticle(nid)
    ]);

    this.setWaiting(false);
    window.scrollTo(0, 0);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.authUser !== this.props.authUser ||
      prevProps.selected_article !== this.props.selected_article
    ) {
      this.updateIfSponsored();
    }
  }

  updateIfSponsored() {
    const { authUser, selected_article } = this.props;
    const userSponsorships = authUser.sponsorships ? authUser.sponsorships.filter(sponsorship => sponsorship.source_id === selected_article?.source_id) : [];
    const ifSponsored = userSponsorships.length > 0 ? userSponsorships[0].status === 1 : null;
    this.setState({
      ifSponsored,
    });
  }
  //sponsordetails
  getSponsoredUsers = async () => {
    const { selected_article } = this.props;
    const gqlservice = new GraphqlService();
    const source_id = selected_article.source_id;
  
    try {
      let result = await gqlservice.sponsorships_by_id(source_id);
      // console.log("sponsorships :", result);
      if (result.status_code === GRAPHQL_ERROR) {
        setError(result.msg);
        return [];
      }
      const sponsorships = result.data.sponsorships;
      return sponsorships
    } catch (error) {
      console.error("Error fetching sponsored users:", error);
      return [];
    }
  };
  
  handleNavBack = () => {
    const { article_backroute, selected_feed } = this.props;

    if (article_backroute === ROUTES.HOME) {
      this.props.selectFeedforComments(null);
    }

    if (selected_feed) {
      this.props.history.goBack();
    } else {
      const location = {
        pathname: article_backroute,
        state: { animation: "right" },
      };
      this.props.history.push(location);
    }
    this.props.clearHistoryPrompt()
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  }; 

  handleMemberTrial = () => {
    const location = {
      pathname: ROUTES.MEMBERSHIP,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleSharePost = () => {
    this.setState({
      ...this.state,
      postShareDlg: true,
    });
  };

//sponsorship
  toggleExpanded = async () => {
    const token = await getAuthToken();
    if (!token) { 
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return
    }
    this.setState({
      ...this.state,
      expanded: true
    });
  }

  handleCloseModal= () => {
    this.setState({
      ...this.state,
      expanded: false,
    })
  }

  toggleCancelSponsorship = async () => {
    this.setState({
      isDisabled:true
    })
    const { authUser, selected_article } = this.props;
    const sponsorshipsDetails = authUser.sponsorships
    const sponsoredSource = sponsorshipsDetails.find(
      (sponsorship) => sponsorship.source_id === selected_article.source_id
    )
  
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);
    this.setWaiting(true);
  
    try {
      const data = {
        id: sponsoredSource.id,
        status : 0
      }
      const cancelSponsorship = await gqlservice.update_sponsor(data);
      if (cancelSponsorship && cancelSponsorship.status_code === GRAPHQL_SUCCESS) {
        ToastSuccess("Sponsorship Cancelled Successfully!");
        this.setState({
          ...this.state,
          expanded: false,
          ifSponsored: false,
          isDisabled:false
        });
      } else {
        this.setState({
          isDisabled:false
        })
        this.setError("Error cancelling sponsorship");
      }
    } catch (error) {
      this.setState({
        isDisabled:false
      })
      this.setError("Unexpected error: " + error);
    }
    this.setWaiting(false);
  };

  toggleSponsorship = async () => {
    this.setState({
      isDisabled:true
    })
    const { authUser, selected_article } = this.props;
    if (authUser.credits > 0) {
      let existingSponsor
      try {        
        const sponsorshipsDetails = authUser.sponsorships ? authUser.sponsorships : null
        if(sponsorshipsDetails){
          existingSponsor = sponsorshipsDetails.find(
            (sponsorship) => sponsorship.source_id === selected_article.source_id
          )
        } else {
          const sponsorships = await this.getSponsoredUsers();
          existingSponsor = sponsorships.find(
            (sponsorship) => sponsorship.user_id === authUser.uid
          )
        }
        if (existingSponsor) {
          const data = {
            id: existingSponsor.id,
            status: 1
          }
          const gqlservice = new GraphqlService();
          const updateSponsor = await gqlservice.update_sponsor(data);
          if (updateSponsor && updateSponsor.status_code === GRAPHQL_SUCCESS) {
            ToastSuccess("Sponsored Successfully!");
            this.setState({
              ...this.state,
              expanded: false,
              ifSponsored: true,
              isDisabled:false
            });
          } else {
            this.setState({
              isDisabled:false
            })
            this.setError("Error inserting sponsorship.");
          }
          return;
        }
        const gqlservice = new GraphqlService();    
        const sponsor = {
          source_id: selected_article.source_id,
          uid: authUser.uid,
        };
        const result = await gqlservice.insert_sponsorship(sponsor);
        if (result) {
          this.setState({
            ...this.state,
            expanded:false, 
            ifSponsored: true,
            isDisabled:false
          });
          ToastSuccess("Sponsored Successfully!");
        } else {
          this.setState({
            isDisabled:false
          })
          this.setError("Error inserting sponsorship");
        }
      } catch (error) {
        this.setError("Unexpected error: " + error);
      } finally {
        this.setWaiting(false);
      }
    } else {
      this.setState({
        isDisabled:false,
        expanded: false,
      })
      ToastError("You don't have enough credits. Please buy some credits to sponsor.")
    }
  };
  

  handleCloseSharePost = () => {
    this.setState({
      ...this.state,
      postShareDlg: false,
    });
  };

  handleClickDiscussion = (discussion_type) => {
    let showDiscussTwitter = this.state.showDiscussTwitter;
    let showDiscussReddit = this.state.showDiscussReddit;
    if (discussion_type === DISCUSSION_TWITTER) {
      showDiscussTwitter = !showDiscussTwitter;
    } else if (discussion_type === DISCUSSION_REDDIT) {
      showDiscussReddit = !showDiscussReddit;
    }

    this.setState({
      ...this.state,
      showDiscussTwitter: showDiscussTwitter,
      showDiscussReddit: showDiscussReddit,
    });
  };

  handleShareRedditDiscussion = (comment) => {
    const { selected_article } = this.props;

    let shareTitle = "Discussion on Reddit for ";
    if (!selected_article.title) {
      shareTitle += "Raven Post";
    } else {
      shareTitle += selected_article.title;
    }
    const discussShareInfo = {
      title: shareTitle,
      description: comment.body,
      image: "",
      hashtag: "",
      url: comment.url,
    };

    this.setState({
      ...this.state,
      discussShareDlg: true,
      discussShareInfo: discussShareInfo,
    });
  };

  handleShareTwitterDiscussion = (tweet) => {
    const { selected_article } = this.props;

    let shareTitle = "Discussion on Twitter for ";
    if (!selected_article.title) {
      shareTitle += "Raven Post";
    } else {
      shareTitle += selected_article.title;
    }
    const url = `https://twitter.com/${tweet.user_scname}/status/${tweet.id}`;
    const discussShareInfo = {
      title: shareTitle,
      description: tweet.text,
      image: "",
      hashtag: "",
      url: url,
    };

    this.setState({
      ...this.state,
      discussShareDlg: true,
      discussShareInfo: discussShareInfo,
    });
  };

  handleCloseShareDiscuss = () => {
    this.setState({
      ...this.state,
      discussShareDlg: false,
      discussShareInfo: null,
    });
  };

  handleAISummary = async (articleType) => { 
    const { selected_article ,authUser,socketId } = this.props;
    const token = await getAuthToken();
    if (!token) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return
    }

    // if(authUser.summaryGenerationStatus==2){
    //   ToastWarning("Your previous request is still being processed, please wait!");
    //   return
    // }

    const gqlservice = new GraphqlService();

    const isSummaryRequests = await gqlservice.get_ongoing_transcriptions(authUser.uid)
    if (isSummaryRequests.data.length === 10) {
      ToastWarning("Your previous requests are still being processed, please wait!");
      return
    }
    this.setWaiting(true);

    /* commenting sponsorship
    const { ifSponsored } = this.state;
    if(!ifSponsored  || authUser.credits === 0 ){
      const message = !ifSponsored ? 'You are not sponsor of this source. Please Sponsor the source for Create Summary ': authUser.credits === 0 ?"You don't have enough credits to do this action ":'You are not a sponsor and enough credits'
      ToastError(message)
      return
    }*/

    this.setState({
      ...this.state,
      summaryRequestStatus: true, 
    });

    var params = JSON.stringify({
     article_id: selected_article.nid,
     socketId: socketId,
     user:authUser.uid
    });
    await createAiSummary(params, token)
      .then(
       async (result) => {
          if (result.status === 200) {
            if (!result.success) {
              ToastError(result.data.message);
            }
            return gqlservice.article_by_nid(selected_article.nid);
          } else {
            ToastError(result.data.message);
            return;
          }
        },
        (reason) => {
          console.log("reason",reason)
          //ToastError(reason.msg);
          return;
        }
      )
      .then((result) => { 
         const articles = result.data.articles;
        if (articles.length > 0) {
          this.props.updateArticle(articles[0]);
          this.setState({
            ...this.state,
            summaryRequestStatus: false, 
          });
        }
      })
      .catch((err) => {
        console.log("err",err)
        //ToastError(JSON.stringify(err));
        this.setState({
          ...this.state,
          summaryRequestStatus: false, 
        });
        return;
      });

  };

  handleClickArticle = (article) => {
    if (article.article) {
      article = article.article;
    }
    const { sources } = this.props;
    const article_source = sources.find(
      (source) => source.id === article.source_id
    );
    let path = `/${ROUTES.CATEGORY_PREFIX}/${article_source.category_id}/${ROUTES.SOURCE_PREFIX}/${article_source.slug}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };
 
  handleCickPost = async (resposne , query) => {
    const {   
      authUser, 
      selected_thread, 
      selected_feed,
      selected_article 
    } = this.props; 
    this.setState({
      ...this.state,
      isQueryPost: true,
    }); 
    
    
    //   if (!selected_feed.op_payment && !hasPaid()) {
    //   this.setState({
    //     ...this.state,
    //     dlgMember: true
    //   });
    //   return;
    //   } 
    
    // check if the user is pre-approved for this selected feed
   
    let approved =false; 
      const approved_user = authUser.preapproved?.find(item => item.feed_id === selected_feed.id);
      approved = approved_user !== undefined;
      approved = approved || !selected_feed.private || this._isFeedModerator(selected_feed);
      
    
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    // insert article to threads if it is the first comment
    let new_thread_id = selected_article.threads?.length > 0
    ? selected_article.threads[0].id
    : null
     
      if (selected_article.threads.length === 0) {
        const thread = {
          title: selected_article.title ? selected_article.title : selected_article.author,
          text: selected_article.summary ? selected_article.summary : selected_article.ai_summary?.slice(0, 50),
          type: THREAD_TYPE_ARTICLE,
          posted_by: authUser.uid,
          from: selected_article.nid,
          feed_id: selected_feed ? selected_feed.id : null,
          approved: approved,
          approved_by: approved ? authUser.uid : null,
          approved_at: approved ? new Date().toISOString() : null
        };

        await gqlservice
          .insert_thread(thread)
          .then(result => {
            const new_thread = result.data.insert_threads.returning[0];
            new_thread_id = new_thread.id;
            this.props.addThread(new_thread);
          }, reason => {
            this.setError(reason.msg);
            return;
          })
          .catch((err) => {
            this.setError(JSON.stringify(err));
            return;
          });
      }
    
    let commentText = "<b>Transcript Query : </b><p>"+query+"</p><b>Answer :</b><p>"+resposne+"</p>";
    // insert comment
    const comment = {
      parent_id: null,
      text: commentText,
      author_id: authUser.uid,
      article_id: selected_article.nid,
      reading_id:  null,
      thread_id: new_thread_id,
      approved: approved,
      approved_by: approved ? authUser.uid : null,
      approved_at: approved ? new Date().toISOString(): null
    };

    await gqlservice
      .insert_comment(comment)
      .then(
        (result) => {
          let inserted_comment = result.data.insert_comments.returning[0];
          logger.log("inserted comment :", inserted_comment);
          ToastSuccess("Query posted successfully!");
          if (selected_thread) {
            const comment_approved = selected_feed.comment_conf === FEED_COMMENT_MODERATED ? true : null;
            return gqlservice.thread_by_id(selected_thread.id, comment_approved);
          } else {
            return inserted_comment;
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .then(result => {
        if (selected_thread) {
          const new_thread = result.data.threads[0];
          this.props.updateThread(new_thread);
        }
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

     
      // update article
      await gqlservice
      .article_by_nid(selected_article.nid)
      .then(
        result => {
          const articles =  result.data.articles;
          if (articles.length > 0) {
            this.props.selectArticle(articles[0]);
          }
        },
        reason => {
          this.setError(reason.msg);
        }
      )
      .catch(err => {
        this.setError(JSON.stringify(err));
      }); 
      this.setState({
        ...this.state,
        isQueryPost: false,
      }); 
    this.setWaiting(false);
  };

  handleMemberTrial = () => {
    const location = {
      pathname: ROUTES.MEMBERSHIP,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleClickUpvote = async () => {
    const { loggedIn, selected_article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    await upvoteArticle(selected_article);
  }

  render() {
    const {
      classes,
      loggedIn,
      selected_feed,
      selected_article,
      sources,
      theme_mode 
    } = this.props; 

    const {
      postShareDlg,
      showDiscussTwitter,
      showDiscussReddit,
      // discussShareInfo,
      // discussShareDlg
      summaryRequestStatus,
      loginDlg,
      isQueryPost,
      ifSponsored,
      showSponsorship,
      authUser,
      sponsoredBy,
      expanded,
      isDisabled
    } = this.state;

    if (!selected_article) {
      return <div style={{display: "none"}}></div>;
    }

    let articleSource = sources.find(
      (source) => selected_article.source_id === source.id
    );
    if (!articleSource) {
      // console.log("articleSource..",articleSource);
      articleSource = selected_article.source;
      if (articleSource) {
        this.props.addSource(articleSource)
      }
    }

    // get share url
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    if(selected_article.branch===ARTICLE_BRANCH_YOUTUBE_SUMMARY||ARTICLE_BRANCH_PODCAST_SUMMARY){
      // console.log("article youtube branch",selected_article.branch,selected_article.nid)
       shareUrl+=`/${ROUTES.ARTICLE_PREFIX}/${selected_article.nid}`;
    }
    // if (selected_feed) {
    //   shareUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${articleSource.slug}`;
    // } else {
    //   shareUrl += `/${ROUTES.CATEGORY_PREFIX}/${articleSource?.category_id}/${ROUTES.SOURCE_PREFIX}/${articleSource?.slug}`;
    // }
    // shareUrl += `/${ROUTES.ARTICLE_PREFIX}/${selected_article.nid}`;

    // discussion
    let discussion_tweets = null;
    // exception for searched results
    if (selected_article.discussion_twitter !== undefined) {
      discussion_tweets = selected_article.discussion_twitter;
    }
    if (discussion_tweets && discussion_tweets.length > 3) {
      const sorted_discussion = discussion_tweets.sort(
        (a, b) => b.favorite_count - a.favorite_count
      );
      discussion_tweets = sorted_discussion.slice(0, 3);
    }

    let discussion_reddit = null;
    if (selected_article.discussion_reddit !== undefined) {
      discussion_reddit = selected_article.discussion_reddit;
    }

    let show_comments = null;
    if (selected_feed) {
      show_comments =
      selected_feed !== null && isCommentEnabledFeed(selected_feed);
    }
      let enableSummary = true;
      if(selected_article.branch === ARTICLE_BRANCH_PODCAST || selected_article.branch === ARTICLE_BRANCH_YOUTUBE){
        if(!selected_article.ai_transcription || !selected_article.ai_summary) {
          enableSummary = false;
        }
      }
          
    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven: ${selected_article.title}`}</title>
            <meta name="description" content={selected_article.summary} />
            <meta
              property="og:title"
              content={`Raven: ${selected_article.title}`}
            />
            <meta
              property="og:description"
              content={selected_article.summary}
            />
            <meta
              property="og:image"
              content={
                selected_article.image === null ? "" : selected_article.image
              }
            />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={shareUrl} />
            <meta
              property="twitter:title"
              content={`Raven: ${selected_article.title}`}
            />
            <meta property="twitter:site" content="Raven App" />
            <meta
              property="twitter:description"
              content={selected_article.summary}
            />
            <meta
              property="twitter:image:src"
              content={
                selected_article.image === null ? "" : selected_article.image
              }
            />
            <meta
              property="twitter:image:alt"
              content={selected_article.title}
            />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar}>
          <ArticleBar
            title={selected_article.branch===ARTICLE_BRANCH_YOUTUBE_SUMMARY||selected_article.branch===ARTICLE_BRANCH_PODCAST_SUMMARY?"Summary":"Article"}
            onNavBack={this.handleNavBack}
            onShare={this.handleSharePost}
          />
        </div>
        {selected_article.branch === ARTICLE_BRANCH_YOUTUBE && (
        <div>
            <YoutubeView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && (
          <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch} />
        )}
        </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_TWITTER && (
          <div>
            <TwitterView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_INSTAGRAM && (
          <div>
            <InstagramView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_TWITCH && (
          <div>
            <TwitchView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_MEDIUM && (
          <div>
            <MediumView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_REDDIT && (
          <div>
            <RedditView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_HACKERNEWS && (
          <div>
            <HackernewsView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_SLASHDOT && (
          <div>
            <SlashdotView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_PODCAST && (
          <div>
            <PodcastView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_RSSFEED && (
          <div>
            <RssView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_TIKTOK && (
          <div>
            <TiktokView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_PINTEREST && (
         <div>
            <PinterestView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}         </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_VSCO && (
          <div>
            <VscoView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_SPOTIFY && (
          <div>
            <SpotifyView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_QUORA && (
          <div>
            <QuoraView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_FACEBOOK && (
          <div>
            <FacebookView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_SUBSTACK && (
          <div>
            <SubstackView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_GMAIL && (
         <div>
            <GmailView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}         </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_VIMEO && (
        <div>
            <VimeoView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}        </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_WEBSITE && (
          <div>
            <WeblinkView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch} />}      </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_MASTODON && (
          <div>
            <MastodonView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}
          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_BLUESKY && (
          <div>
            <BlueskyView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}
          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_USERPOST && (
          <div>
            <UserpostView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}
          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_PROFILEPOST && (
          <div>
            <ProfilepostView article={selected_article} />
          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_AISUMMARY && (
         <div>
            <SummarypostView article={selected_article} source={articleSource} />
            {selected_article.political_sentiments && <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch}/>}
         </div>
        )}
        {selected_article.home_cluster_members &&
          selected_article.home_cluster_members.length > 0 && (
            <SimilarArticles
              article={selected_article}
              theme_mode={theme_mode}
              loggedIn={loggedIn}
              similarArticles={selected_article.home_cluster_members}
              sources={sources}
              onClickArticle={this.handleClickArticle}
              // onClickSource={this.handleClickSource}
            />
          )}
          {selected_article.branch === ARTICLE_BRANCH_YOUTUBE_SUMMARY && (
          <div>
          <GenerateSummaryView article={selected_article} onClickUpvote={this.handleClickUpvote} />
          {selected_article.political_sentiments && (
        <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch} />
      )}

      </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_PODCAST_SUMMARY  && (
          <div>
          <GenerateSummaryView article={selected_article} onClickUpvote={this.handleClickUpvote}/>
          {selected_article.political_sentiments && (
        <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch} />
      )}

      </div>
        )}
          {(selected_article.branch === ARTICLE_BRANCH_PODCAST || selected_article.branch === ARTICLE_BRANCH_YOUTUBE) &&  !enableSummary && ( 
            <div className={classes.discussion}>
            <div className={classes.discussbutton}>
              <DiscussionButton
                type={selected_article.branch}
                title={"Create Summary"}
                description={(summaryRequestStatus||selected_article.summary_status==2)?"Please wait! Summary generation in progress...":"Create a transcript and summary"}
                image={`/static/images/edit.png`}
                onClick={this.handleAISummary}
                disabled={(summaryRequestStatus||selected_article.summary_status==2)?true:undefined}
              />
            </div>
            </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_PODCAST &&  enableSummary && ( 
            <div>
              <AISummary
                disabled={false}
                ifSponsored={ifSponsored}
                title='View AI Summary'
                article={selected_article}
                aiSummary={selected_article.ai_summary}
                articleType={ARTICLE_BRANCH_PODCAST}
                theme_mode={theme_mode}
                authUser={authUser}
                sources={sources}
              />
              <Transcript
                disabled={false}
                title='View Transcript'
                ai_transcription={selected_article.ai_transcription}
                imageIcon = '/static/images/edit.png'
                parsetoHtml = {true}
                articleId={selected_article.nid}
                onPost={this.handleCickPost}
                onMemberTrial={this.handleMemberTrial}
              />
            </div>
        )}
        {(selected_article.branch === ARTICLE_BRANCH_YOUTUBE  && enableSummary) ? ( 
            <div>
              <Transcript
                disabled={false}
                title='Transcript'
                ai_transcription={selected_article.ai_transcription}
                imageIcon = '/static/images/youtube-white.png'
                parsetoHtml = {false}
                articleId={selected_article.nid}
                onPost={this.handleCickPost}
                onMemberTrial={this.handleMemberTrial}
              />
              <AISummary
                disabled={false}
                ifSponsored={ifSponsored}
                title='AI Summary by GPT-3.5'
                article={selected_article}
                aiSummary={selected_article.ai_summary}
                articleType={ARTICLE_BRANCH_YOUTUBE} 
                onSummaryUpdate={this.props.updateArticle}
                onNeedLogin={this.handleLogin}
                theme_mode={theme_mode}
                authUser={authUser}
                sources={sources}
              />
            </div>  
        ) : <></>}
        {/* {!enableSummary && */}
        { /*(selected_article.branch === ARTICLE_BRANCH_PODCAST || selected_article.branch === ARTICLE_BRANCH_YOUTUBE) ?
          (<div>
            <Sponsorship
              article={selected_article}
              authUser={authUser}
              sources={sources}
              ifSponsored={ifSponsored}
              open={showSponsorship}
              disabled={false}
              toggleSponsorship={this.toggleSponsorship}
              toggleCancelSponsorship={this.toggleCancelSponsorship}
              toggleExpanded={this.toggleExpanded}
              handleCloseModal={this.handleCloseModal}
              theme_mode={theme_mode}
              expanded={expanded}
              isDisabled={isDisabled}
            />
          </div>)
          :<></>
          */}
        {show_comments && discussion_tweets && discussion_tweets.length > 0 && (
          <div className={classes.discussion}>
            <div className={classes.discussbutton}>
              <DiscussionButton
                type={DISCUSSION_TWITTER}
                title={"Discussion on Twitter"}
                description={"Press to expand and view"}
                image={`/static/images/icons/twitter_circle.png`}
                onClick={this.handleClickDiscussion}
              />
            </div>
            {showDiscussTwitter &&
              discussion_tweets.map((tweet, index) => (
                <DiscussTwitterCard
                  key={`discussion-twitter-${index}`}
                  tweet={tweet}
                  theme_mode={theme_mode}
                  onShare={this.handleShareTwitterDiscussion}
                />
              ))}
          </div>
        )}
        {show_comments &&
          discussion_reddit !== null &&
          discussion_reddit.comments.length > 0 && (
            <div className={classes.discussion}>
              <div className={classes.discussbutton}>
                <DiscussionButton
                  type={DISCUSSION_REDDIT}
                  title={"Discussion on Reddit"}
                  description={"Press to expand and view"}
                  image={`/static/images/icons/reddit_circle.png`}
                  onClick={this.handleClickDiscussion}
                />
              </div>
              {showDiscussReddit &&
                discussion_reddit.comments.map((comment, index) => (
                  <DiscussRedditCard
                    key={`discussion-reddit-${index}`}
                    theme_mode={theme_mode}
                    number={index}
                    comment={comment}
                    onShare={this.handleShareRedditDiscussion}
                  />
                ))}
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                direction="row"
              >
                <Button
                  className={classes.actionbtn}
                  size="small"
                  color="primary"
                  target="_blank"
                  href={discussion_reddit.url}
                >
                  View original thread
                </Button>
              </Grid>
            </div>
          )}
        {show_comments && !isQueryPost && (
          <div>
            <div className={classes.discussion}>
              <DiscussionButton
                type={DISCUSSION_RAVEN}
                title={"Discussion on Raven"}
                description={"Moderated Comments"}
                image={`/static/images/icons/raven_logo.png`}
              />
            </div>
            <CommentList
              type={THREAD_TYPE_ARTICLE}
              // thread_id={selected_article.nid}
              thread_id={
                selected_article.threads?.length > 0
                  ? selected_article.threads[0].id
                  : null
              }
              onError={this.setError}
              onNeedLogin={this.handleLogin}
              onMemberTrial={this.handleMemberTrial} 
            />
          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_PODCAST_SUMMARY && (
          <div>
            <AISummary
              disabled={false}
              ifSponsored={ifSponsored}
              title='View AI Summary'
              article={selected_article}
              aiSummary={selected_article.ai_summary}
              articleType={selected_article.branch}
              theme_mode={theme_mode}
              authUser={authUser}
              sources={sources}
            />
          </div>
        )}
        {selected_article.branch === ARTICLE_BRANCH_YOUTUBE_SUMMARY && (
          <div>
            <AISummary
              disabled={false}
              ifSponsored={ifSponsored}
              title='View AI Summary'
              article={selected_article}
              aiSummary={selected_article.ai_summary}
              articleType={selected_article.branch}
              theme_mode={theme_mode}
              authUser={authUser}
              sources={sources}
            />
          </div>
        )}
        {!show_comments && (
          <div>
            <div className={classes.discussion}>
              <DiscussionButton
                disabled
                type={DISCUSSION_RAVEN}
                title={"Discussion on Raven"}
                description={"Moderated Comments"}
                image={`/static/images/icons/raven_logo.png`}
              />
            </div>
            <div className={classes.disabletxtdiv}>
              <Typography className={classes.disabletxt}>
                {"Comments are closed"}
              </Typography>
            </div>
          </div>
        )}
        {/* Post Share */}
        <DlgSharePost
          open={postShareDlg}
          post={selected_article}
          onLogin={this.handleLogin}
          onClose={this.handleCloseSharePost}
        />
        {/* Discuss Share */}
        {/* <DlgSharePost
          open={discussShareDlg}
          post={selected_article}
          shareInfo={discussShareInfo}
          onLogin={this.handleLogin}
          onClose={this.handleCloseShareDiscuss}
        /> */}
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <WaitingSpinner open={summaryRequestStatus} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  socketId: state.sessionState.socketId,
  selected_article: state.dataState.selected_article,
  categories: state.dataState.categories,
  selected_feed: state.dataState.selected_feed,
  feeds: state.dataState.feeds,
  sources: state.dataState.sources,
  newssites: state.dataState.newssites,
  article_backroute: state.uiState.article_backroute,
  theme_mode: state.uiState.theme_mode
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Social);
