import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withFirebase } from 'services';
import { withAuthentication, withAuthorization } from "session";
import { BasicAppBar, WaitingSpinner } from "components";
import { Source, Propose } from "./components";
import { GraphqlService } from "services";
import * as ROUTES from "constants/routes";
import { GRAPHQL_SUCCESS, MAX_WINDOW_WIDTH } from "constants/types";
import { NOTIFICATION_SOURCE_APPROVE } from "constants/notification";
import { 
  ACTIVITY_TYPE_FEED, 
  ACTIVITY_DELETE, 
  ACTIVITY_APPROVE 
} from "constants/activity";
import { ToastError } from "utility/toast";
import { getAuthToken, updateCache, updateFeedNotifications } from "dataapis";


const condition = (authUser) => !!authUser && authUser.uid !== "";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    marginTop: theme.spacing(1),
  },
  propose: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class SourcesProposed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      proposed: []
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleApproveSource = this.handleApproveSource.bind(this);
    this.handleDeleteSource = this.handleDeleteSource.bind(this);
    this.handleClickProposer = this.handleClickProposer.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount() {
    const { selected_feed, sources } = this.props;

    let proposed_sources = [];
    const feed_sources = selected_feed.proposed_sources;
    for (let feed_source of feed_sources) {
      let source = sources.find(item => item.id === feed_source.source_id);
      if (source) {
        source.propose = feed_source;
        proposed_sources.push(source);
      }
    }

    this.setState({
      ...this.state,
      proposed: proposed_sources,
    });
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    this.props.history.goBack();
  }

  deleteSource = (source_id) => {
    const { proposed } = this.state;
    const new_proposed = proposed.filter(source => source.id !== source_id);

    this.setState({
      ...this.state,
      proposed: new_proposed
    });
  }

  handleApproveSource = async (source) => {
    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);
    const currentTime = new Date().toISOString();

    this.setWaiting(true);

    await gqlservice
      .update_feed_source(source.propose.id, source.id, true, authUser.uid, currentTime)
      .then(result => {
        this.deleteSource(source.id);
        //this.props.updateFeedSourceApprove(source, true);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // insert notification
    const notification = {
      type: NOTIFICATION_SOURCE_APPROVE,
      object: source.id,
      in_which: selected_feed.id,
      to: source.created_by,
      created_by: authUser.uid
    }

    gqlservice.set_auth_jwt(token, false);
    await gqlservice
      .insert_notification(notification)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_APPROVE,
      object: `the source ${source.name}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    await updateFeedNotifications(selected_feed);

    this.setWaiting(false);
  }

  handleDeleteSource = async (source) => {
    const { authUser, selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .delete_feed_source(source.propose.id)
      .then(result => {
        return gqlservice.delete_source(source.id)
      })
      .then(result => {
        this.deleteSource(source.id);
        //this.props.deleteFeedSource(source);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
    
    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_DELETE,
      object: `the source ${source.name}`,
      fromto: `from the feed ${selected_feed.name}`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // insert feed source changes
    const feed_source_change = {
      feed_id: selected_feed.id,
      source_id: source.id,
      removed: true,
      created_by: authUser.uid
    };
    let result = await gqlservice.insert_feed_source_changes([feed_source_change]);
    if (result.status_code !== GRAPHQL_SUCCESS || result.data.insert_feed_source_change.affected_rows === 0) {
      this.setError("Failed to insert feed source changes!");
    }

    await updateFeedNotifications(selected_feed);

    await updateCache();

    this.setWaiting(false);
  }

  handleClickProposer = (source) => {
    const user_id = source.propose.create_user.uid;
    const path = `/${ROUTES.USER_PREFIX}/${user_id}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  render() {
    const { classes, theme_mode, requesting } = this.props;
    const { proposed } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Proposed Sources"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <Grid container className={classes.container} style={{width: window.innerWidth}}>
          {proposed.map((source, index) => (
            <div key={index}>
              <Grid item key={`source-${index}`}>
                <Source source={source} theme={theme_mode} />
              </Grid>
              <Grid
                item
                key={`proposed-${index}`}
                className={classes.propose}
              >
                <Propose
                  source={source}
                  theme_mode={theme_mode}
                  onApprove={this.handleApproveSource}
                  onDelete={this.handleDeleteSource}
                  onClickProposer={this.handleClickProposer}
                />
              </Grid>
            </div>
          ))}
        </Grid>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

SourcesProposed.propTypes = {
  className: PropTypes.string,
  theme_mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication, 
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SourcesProposed);
