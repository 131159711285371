import React from "react"; 
import { withStyles } from "@material-ui/core/styles";
import { Avatar, IconButton, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { createAiSummary } from "utility/ravenapi"; 
import { ToastSuccess, ToastError } from "utility/toast";
import { ERR_MSG_AUTH_TOKEN, getAuthToken, setError} from "dataapis";
import { GraphqlService } from "services";
import {  WaitingSpinner, DlgLoginConfirm } from "components"; 
import ReactMarkdown from 'react-markdown'
import { copy2clipboard } from "utility/utils";
import {marked} from 'marked';
import { GRAPHQL_ERROR } from "constants/types";
import _ from 'lodash';

const styles = (theme) => ({
  root: {
    width: "95%",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.card,
    borderRadius: 32,
    textTransform: "inherit",
    textAlign: "left",
    margin: 'auto',
    marginBottom: 6,
  },
  button: {
    background:'transparent',
    width:'100%',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 16px',
  },
  icon: {
    width: 48,
    height: 48,
    borderRadius:32,
    marginLeft:-8
  },
  startIcon: {
    marginLeft: -theme.spacing(1),
    marginRight: 0,
  },
  text: {
    marginLeft: theme.spacing(2),
    textAlign: 'initial',
  },
  title: {
    display: "block",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "0.66px",
  },
  description: {
    display: "block",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.66px",
  },
  contents: {
    backgroundColor: theme.palette.background.card,
    marginTop: -14,
    padding: 18,
    paddingTop: 32,
    fontSize: 16,
    fontFamily: 'Roboto, sans-serif',
    lineHeight: 1.5,
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    "& h1":  {
      fontSize: 20,
    },
    "& h2":  {
      fontSize: 16,
    },
    "@media (max-width: 300px)": {
     marginTop:-5
    },
  },
  sponsored: {
    display: 'flex'
  },
  mainContent: {
    position: 'relative'
  },
  copy_image: {
    width: '24px',
    height: '24px',
    cursor:"pointer"
  },
  image: {
    position: 'absolute',
    top: -20,
    right: 0,
    marginRight:"10px",
    zIndex: 1,
    padding: '6px',
    cursor: 'pointer'
  },
  imageSponsor: {
    marginRight: "10px",
    width: 32,
    height: 32,
    borderRadius: 32,
},
sponsors:{
  display: 'flex',
 marginLeft: "2px",
 marginTop:"6px"
},
sponsorsimg: {
  marginLeft: "4px",
  width: 22,
  height: 22,
  borderRadius: 32,
},
sponsorTitle:{
  marginTop: 6,
}
});

class AISummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      generateSummary: this.props.aiSummary ? false : true,
      summary: this.props.aiSummary ? this.props.aiSummary : '',
      requesting: false,
      loginDlg: false,
      sponsoredBy: [],
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
    
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  } 
  async toggleExpanded() {
    this.setState({
      expanded: !this.state.expanded
    });
  }
 handleLogin = () => {
  this.props.onNeedLogin();
};

handleCancelLogin = () => {
  this.setState({
    ...this.state,
    loginDlg: false,
  });
};

handleCopy = (text) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = marked(text);
  const plainText = tempElement.textContent || tempElement.innerText || '';
  copy2clipboard(plainText);
  ToastSuccess('Summary Copied to Clipboard!');
}

  render() {
    const { classes,title,disabled, ifSponsored, theme_mode, article} = this.props;
    const { expanded,summary,requesting,loginDlg} = this.state;
    const description = expanded ? 'Press to close': 'Press to expand and view'
    const sponsoredArticles = article.sponsored_articles
    const uniqueSponsoredArticles = _.uniqBy(sponsoredArticles, (sponsor) => `${sponsor.article_id}_${sponsor.user_id}`);

    return (
      <div className={classes.root}>
        <button
          variant="contained"
          className={classes.button}
          onClick={this.toggleExpanded}
          disabled={disabled || requesting}
        >
        <img className={classes.icon} alt='' src={`/static/images/icons/light/open_ai_day.png`} />
           <div className={classes.text}>
             <Typography className={classes.title}>{title}</Typography>
             <Typography className={classes.description}>
               {description}  
             </Typography>
           </div>
         </button>        
         {expanded &&
          <div className={classes.mainContent}>
              <IconButton 
                  className={classes.image}
                  onClick={e => this.handleCopy(summary)}
               >
                <img
                  className={classes.copy_image}
                  alt="copy link"
                  src={`/static/images/icons/copytext.png`}
                />
            </IconButton>
            <div className={classes.contents}>
              <ReactMarkdown>
                {summary}
              </ReactMarkdown>
              {/*commenting sponsorship
              uniqueSponsoredArticles.length > 0 ? (
                <div className={classes.sponsored}>
                  <img className={classes.imageSponsor} alt='' src={`/static/images/icons/${theme_mode}/sponsor.png`} />
                  <Typography className={classes.sponsorTitle}>
                    Sponsored by
                  </Typography>
                  <div className={classes.sponsors}>
                    {uniqueSponsoredArticles.map((user, index) => (
                      <Avatar key={index} alt="Sponsor Image" src={user.user.image} className={classes.sponsorsimg} />
                    ))}
                  </div>
                </div>
              ) : null
              */}
            </div>
          </div>        
         }
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
         <WaitingSpinner open={requesting} />
      </div>
     
      
    );
  }
}

AISummary.propTypes = {
   title: PropTypes.string,
   onSummaryUpdate: PropTypes.func,
   onNeedLogin: PropTypes.func,
   ifSponsored: PropTypes.bool,
   theme_mode: PropTypes.string,
   article: PropTypes.object,
};

export default withStyles(styles)(AISummary);