import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    display: "flex",
    cursor: "pointer",
    width: 100,
    height: 100,
    borderRadius: 16,
    borderColor: theme.palette.text.primary,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.background.default,
  },
  title_div: {
    margin: "auto",
  },
  title: {
    fontFamily: "Arial",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
    color: theme.palette.text.primary,
  }
});

class Place extends React.Component {
  render() {
    const { classes, place } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.title_div}>
          {place.place &&
            <Typography className={classes.title}>
              {place.place},
            </Typography>
          }
          {place.region &&
            <Typography className={classes.title}>
              {place.region},
            </Typography>
          }
          {place.country &&
            <Typography className={classes.title}>
              {place.country}
            </Typography>
          }
        </div>
      </div>
    );
  }
}

Place.propTypes = {
  classes: PropTypes.object,
  place: PropTypes.object,
};

export default withStyles(styles)(Place);
