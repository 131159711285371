import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router"; 
import { withStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/core";
import { 
  MapUserpostCard,
  DlgReport,
  DlgShareMapPost,
  DlgLoginConfirm,
  PopMenuMapPost,
  PopMenuMapPostMod,
} from "components";
import { ARTICLE_BRANCH_MAPPOST } from "constants/branches";
import { 
  isBannedPosts,
} from "dataapis";
import { 
  ToastError 
} from "utility/toast";
import { logger } from "utility/logging";
import { isBanned, isBannedComments, isRegionModerator } from "dataapis/user";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
});

class MapUserpost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      reportDlg: false,
      loginDlg: false,
      shareDlg: false
    };

    this.handleAnchorEl = this.handleAnchorEl.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);

    this.handleShare = this.handleShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);

    this.showReportDlg = this.showReportDlg.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleCancelReport = this.handleCancelReport.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleSuspend1d = this.handleSuspend1d.bind(this);
    this.handleSuspend7d = this.handleSuspend7d.bind(this);
    this.handleSuspendPerm = this.handleSuspendPerm.bind(this);
    this.handleCloseComments = this.handleCloseComments.bind(this);
    this.handleDeleteComments = this.handleDeleteComments.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this); 
  }

  handleShare = () => {   
    this.setState({
      ...this.state,
      shareDlg: true,
    });
  };

  handleCloseShare() {
    this.setState({
      ...this.state,
      shareDlg: false,
    });
  }

  handleAnchorEl = (anchorEl) => {
    this.setState({
      ...this.state,
      anchorEl: anchorEl,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  showReportDlg = () => {
    const { loggedIn } = this.props;

    if (loggedIn) {
      this.setState({
        ...this.state,
        reportDlg: true,
        loginDlg: false,
        anchorEl: null,
      });

      if (isBannedPosts()) {
        ToastError("You've suspended for post operations.");
        return;
      }
    } else {
      this.setState({
        ...this.state,
        reportDlg: false,
        loginDlg: true,
        anchorEl: null,
      });
    }
  };

  handleReport = (reportMsg) => {
    this.setState({
      ...this.state,
      reportDlg: false,
      anchorEl: null
    });
    
    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    const { article } = this.props;
    if (!reportMsg.trim()) {
      this.setError("Report shouldn't be blank. Please input your report.");
      return;
    }

    this.props.onReport(article, reportMsg);
  };

  handleCancelReport = () => {
    this.setState({
      ...this.state,
      reportDlg: false,
      anchorEl: null
    });
  };

  handleDelete = () => {
    const { article, loggedIn } = this.props;
    if (loggedIn) {
      this.setState({
        ...this.state,
        anchorEl: null
      });

      if (isBannedPosts()) {
        ToastError("You've suspended for post operations.");
        return;
      }

      this.props.onDelete(article);
    } else {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
    }
  }

  handleSuspend1d = () => {
    const { loggedIn, article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBanned()) {
      ToastError("You've suspended for operations.");
      return;
    }

    this.props.onSuspend1d(article);
  }

  handleSuspend7d = () => {
    const { loggedIn, article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBanned()) {
      ToastError("You've suspended for operations.");
      return;
    }

    this.props.onSuspend7d(article);
  }

  handleSuspendPerm = () => {
    const { loggedIn, article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBanned()) {
      ToastError("You've suspended for operations.");
      return;
    }

    this.props.onSuspendPerm(article);
  }

  handleCloseComments = () => {
    const { loggedIn, article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBannedComments()) {
      ToastError("You've suspended for comments operations.");
      return;
    }

    this.props.onCloseComments(article);
  }

  handleDeleteComments = () => {
    const { loggedIn, article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBannedComments()) {
      ToastError("You've suspended for comments operations.");
      return;
    }

    this.props.onDeleteComments(article);
  }

  handleClickUpvote = () => {
    const { loggedIn, article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    this.props.onClickUpvote(article);
  }

  handleClickComment = () => {
    const { article } = this.props;
    this.props.onClickComment(article);
  }

  handleClickRepost = () => {
    const { loggedIn, article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    this.props.onClickRepost(article);
  }

  handleLogin = () => {
    this.props.onLogin();
    this.setState({
      ...this.state,
      loginDlg: false,
      shareDlg: false,
      anchorEl: null
    });
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
      anchorEl: null
    });
  };

  render() {
    const { 
      classes, 
      theme, 
      loggedIn,
      authUser,
      article, 
      onClickPost, 
    } = this.props;
    const { 
      anchorEl, 
      reportDlg, 
      loginDlg, 
      shareDlg 
    } = this.state;

    // validation check
    if (article.branch !== ARTICLE_BRANCH_MAPPOST) {
      logger.error("Error, Invalid map post! :", article.nid);
      return <div style={{display: "none"}}></div>;
    }

    // popup menu position
    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    return (
      <div className={classes.root}>
        <MapUserpostCard
          article={article}
          onClickPost={onClickPost}
          onClickUpvote={this.handleClickUpvote}
          onClickComment={this.handleClickComment}
          onClickRepost={this.handleClickRepost}
          onAnchorEl={this.handleAnchorEl}
          onShare={this.handleShare}
        />
   
        <Menu
          id="source-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleCloseMenu}
        >
          {isRegionModerator(article.param3) ? (
            <PopMenuMapPostMod
              theme={theme}
              onDelete={this.handleDelete}
              onSuspend1d={this.handleSuspend1d}
              onSuspend7d={this.handleSuspend7d}
              onSuspendPerm={this.handleSuspendPerm}
              onCloseComments={this.handleCloseComments}
              onDeleteComments={this.handleDeleteComments}
            />
          ):(
            <PopMenuMapPost
              theme={theme}
              owner={loggedIn && authUser && authUser.uid === article.txt_param1}
              onReport={this.showReportDlg}
              onDelete={this.handleDelete}
              onShare={this.handleShare}
            />
          )}
        </Menu>
        <DlgReport
          open={reportDlg}
          title={"Report Post"}
          theme={theme}
          onReport={this.handleReport}
          onCancel={this.handleCancelReport}
        />
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        {shareDlg &&
          <DlgShareMapPost
            open={shareDlg}
            post={article}
            onClose={this.handleCloseShare}
          /> 
        }
      </div>
    );
  }
}

MapUserpost.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  loggedIn: PropTypes.bool,
  authUser: PropTypes.object,
  article: PropTypes.object, 
  onReport: PropTypes.func,
  onLogin: PropTypes.func,
  onClickPost: PropTypes.func, 
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
  onDelete: PropTypes.func,
  onSuspend1d: PropTypes.func,
  onSuspend7d: PropTypes.func,
  onSuspendPerm: PropTypes.func,
  onCloseComments: PropTypes.func,
  onDeleteComments: PropTypes.func,
};

const mapStateToProps = (state) => ({
  sources: state.dataState.sources,
  selected_feed: state.dataState.selected_feed,

});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withStyles(styles)
)(MapUserpost);