export const BRANCH_ALL = 999;
export const ARTICLE_BRANCH_NEWSPAPER = 0;
export const ARTICLE_BRANCH_YOUTUBE = 1;
export const ARTICLE_BRANCH_TWITTER = 2;
export const ARTICLE_BRANCH_INSTAGRAM = 3;
export const ARTICLE_BRANCH_TWITCH = 4;
export const ARTICLE_BRANCH_MEDIUM = 5;
export const ARTICLE_BRANCH_REDDIT = 6;
export const ARTICLE_BRANCH_HACKERNEWS = 7;
export const ARTICLE_BRANCH_SLASHDOT = 8;
export const ARTICLE_BRANCH_PODCAST = 9;
export const ARTICLE_BRANCH_RSSFEED = 10;
export const ARTICLE_BRANCH_TIKTOK = 11;
export const ARTICLE_BRANCH_PINTEREST = 12;
export const ARTICLE_BRANCH_VSCO = 13;
export const ARTICLE_BRANCH_SPOTIFY = 14;
export const ARTICLE_BRANCH_QUORA = 15;
export const ARTICLE_BRANCH_FACEBOOK = 16;
export const ARTICLE_BRANCH_SUBSTACK = 17;
export const ARTICLE_BRANCH_GMAIL = 18;
export const ARTICLE_BRANCH_VIMEO = 19;
export const ARTICLE_BRANCH_WEBSITE = 20;
export const ARTICLE_BRANCH_MASTODON = 21;
export const ARTICLE_BRANCH_BLUESKY = 22;
export const ARTICLE_BRANCH_THREADS = 23;
export const ARTICLE_BRANCH_MAPPOST = 96;
export const ARTICLE_BRANCH_PROFILEPOST = 97;
export const ARTICLE_BRANCH_MAPPOST_TWITTER = 98;
export const ARTICLE_BRANCH_MAPPOST_INSTAGRAM = 99;
export const ARTICLE_BRANCH_USERPOST = 100;
export const ARTICLE_BRANCH_AISUMMARY = 101;
export const ARTICLE_BRANCH_SEARCH = 102;
export const ARTICLE_BRANCH_YOUTUBE_SUMMARY = 103;
export const ARTICLE_BRANCH_PODCAST_SUMMARY=104;
export const CONF_BRANCHES = [{
        value: BRANCH_ALL,
        name: "All",
        image: ""
    },
    {
        value: ARTICLE_BRANCH_NEWSPAPER,
        name: "Newspaper Sites",
        image: "newspaper.png"
    },
    {
        value: ARTICLE_BRANCH_YOUTUBE,
        name: "Youtube",
        image: "youtube.png"
    },
    {
        value: ARTICLE_BRANCH_TWITTER,
        name: "Twitter",
        image: "twitter.png"
    },
    {
        value: ARTICLE_BRANCH_INSTAGRAM,
        name: "Instagram",
        image: "instagram.png"
    },
    {
        value: ARTICLE_BRANCH_TWITCH,
        name: "Twitch",
        image: "twitch.png"
    },
    {
        value: ARTICLE_BRANCH_MEDIUM,
        name: "Medium",
        image: "medium.png"
    },
    {
        value: ARTICLE_BRANCH_REDDIT,
        name: "Reddit",
        image: "reddit.png"
    },
    {
        value: ARTICLE_BRANCH_HACKERNEWS,
        name: "HackerNews",
        image: "hackernews.png"
    },
    {
        value: ARTICLE_BRANCH_SLASHDOT,
        name: "Slashdot",
        image: "slashdot.png"
    },
    {
        value: ARTICLE_BRANCH_PODCAST,
        name: "Podcasts",
        image: "podcast.png"
    },
    {
        value: ARTICLE_BRANCH_RSSFEED,
        name: "Rss Feeds",
        image: "rss.png"
    },
    {
        value: ARTICLE_BRANCH_TIKTOK,
        name: "Tiktok",
        image: "tiktok.png"
    },
    {
        value: ARTICLE_BRANCH_PINTEREST,
        name: "Pinterest",
        image: "pinterest.png"
    },
    {
        value: ARTICLE_BRANCH_VSCO,
        name: "Vsco",
        image: "vsco.png"
    },
    {
        value: ARTICLE_BRANCH_SPOTIFY,
        name: "Spotify",
        image: "spotify.png"
    },
    {
        value: ARTICLE_BRANCH_QUORA,
        name: "Quora",
        image: "quora.png"
    },
    {
        value: ARTICLE_BRANCH_FACEBOOK,
        name: "Facebook",
        image: "facebook.png"
    },
    {
        value: ARTICLE_BRANCH_SUBSTACK,
        name: "Substack",
        image: "substack.png"
    },
    {
        value: ARTICLE_BRANCH_GMAIL,
        name: "Email Newsletters",
        image: "gmail.png"
    },
    {
        value: ARTICLE_BRANCH_VIMEO,
        name: "Vimeo",
        image: "vimeo.png"
    },
    {
        value: ARTICLE_BRANCH_WEBSITE,
        name: "Static Websites",
        image: "weblink.png"
    },
    {
        value: ARTICLE_BRANCH_MASTODON,
        name: "Mastodon",
        image: "mastodon.png"
    },
    {
        value: ARTICLE_BRANCH_BLUESKY,
        name: "Bluesky",
        image: "bluesky.png"
    },
    {
        value: ARTICLE_BRANCH_THREADS,
        name: "Threads",
        image: "threads.png"
    },
    {
        value: ARTICLE_BRANCH_MAPPOST,
        name: "Map Post",
        image: "weblink.png"
    },
    {
        value: ARTICLE_BRANCH_MAPPOST_TWITTER,
        name: "Map Post Twitter",
        image: "twitter.png"
    },
    {
        value: ARTICLE_BRANCH_MAPPOST_INSTAGRAM,
        name: "Map Post Instagram",
        image: "instagram.png"
    },
    {
        value: ARTICLE_BRANCH_PROFILEPOST,
        name: "Userpost",
        image: "userpost.png"
    },
    {
        value: ARTICLE_BRANCH_USERPOST,
        name: "Userpost",
        image: "userpost.png"
    },
    {
        value: ARTICLE_BRANCH_AISUMMARY,
        name: "AI Summary",
        image: ""
    },
    {
        value: ARTICLE_BRANCH_SEARCH,
        name: "Search",
        image: "search.png"
    },
    {
        value: ARTICLE_BRANCH_PODCAST_SUMMARY,
        name: "Podcasts Summary",
        image: "podcast.png"
    },
     {
        value: ARTICLE_BRANCH_YOUTUBE_SUMMARY,
        name: "Youtube Summary",
        image: "youtube.png"
    }
];


export const is_exclude_branch = (branch_id) => {
    return branch_id === ARTICLE_BRANCH_TWITTER
        || branch_id === ARTICLE_BRANCH_INSTAGRAM
        || branch_id === ARTICLE_BRANCH_HACKERNEWS
        || branch_id === ARTICLE_BRANCH_SLASHDOT
        || branch_id === ARTICLE_BRANCH_PINTEREST
        || branch_id === ARTICLE_BRANCH_VSCO
        || branch_id === ARTICLE_BRANCH_SPOTIFY
        || branch_id === ARTICLE_BRANCH_QUORA
        || branch_id === ARTICLE_BRANCH_FACEBOOK
        || branch_id === ARTICLE_BRANCH_VIMEO;
}

export const get_all_branches = () => {
    return CONF_BRANCHES.filter(branch =>
        branch.value !== ARTICLE_BRANCH_MAPPOST
        && branch.value !== ARTICLE_BRANCH_MAPPOST_TWITTER
        && branch.value !== ARTICLE_BRANCH_MAPPOST_INSTAGRAM
        && branch.value !== ARTICLE_BRANCH_PROFILEPOST
        && branch.value !== ARTICLE_BRANCH_AISUMMARY
        && branch.value !== ARTICLE_BRANCH_SEARCH
        && !is_exclude_branch(branch.value)
    );
}

export const get_article_branches = () => {
    return CONF_BRANCHES.filter(branch => 
        branch.value !== BRANCH_ALL 
        && branch.value !== ARTICLE_BRANCH_MAPPOST
        && branch.value !== ARTICLE_BRANCH_MAPPOST_TWITTER
        && branch.value !== ARTICLE_BRANCH_MAPPOST_INSTAGRAM
        && branch.value !== ARTICLE_BRANCH_PROFILEPOST
        && branch.value !== ARTICLE_BRANCH_AISUMMARY
        && branch.value !== ARTICLE_BRANCH_SEARCH
        && !is_exclude_branch(branch.value)
    );
};

export const get_all_external_branches = () => {
    return CONF_BRANCHES.filter(branch => 
        branch.value !== ARTICLE_BRANCH_MAPPOST
        && branch.value !== ARTICLE_BRANCH_MAPPOST_TWITTER
        && branch.value !== ARTICLE_BRANCH_MAPPOST_INSTAGRAM
        && branch.value !== ARTICLE_BRANCH_USERPOST
        && branch.value !== ARTICLE_BRANCH_PROFILEPOST
        && branch.value !== ARTICLE_BRANCH_AISUMMARY
        && branch.value !== ARTICLE_BRANCH_SEARCH
        && !is_exclude_branch(branch.value)
    );
};

export const get_social_branches = () => {
    return CONF_BRANCHES.filter(branch => 
        branch.value !== BRANCH_ALL
        && branch.value !== ARTICLE_BRANCH_NEWSPAPER
        && branch.value !== ARTICLE_BRANCH_MAPPOST 
        && branch.value !== ARTICLE_BRANCH_MAPPOST_TWITTER
        && branch.value !== ARTICLE_BRANCH_MAPPOST_INSTAGRAM
        && branch.value !== ARTICLE_BRANCH_USERPOST
        && branch.value !== ARTICLE_BRANCH_PROFILEPOST
        && branch.value !== ARTICLE_BRANCH_AISUMMARY
        && branch.value !== ARTICLE_BRANCH_SEARCH
        && !is_exclude_branch(branch.value)
    );
};

export const get_map_branches = () => {
    return CONF_BRANCHES.filter(branch => 
        branch.value === ARTICLE_BRANCH_MAPPOST
        || branch.value === ARTICLE_BRANCH_MAPPOST_TWITTER
        || branch.value === ARTICLE_BRANCH_MAPPOST_INSTAGRAM        
    );
}

export const get_branch = (branch_id) => {
    return CONF_BRANCHES.find(item => item.value === branch_id);
};

export const get_branch_name = (branch_id) => {
    const branch = CONF_BRANCHES.find(item => item.value === branch_id);
    if (branch) {
      return branch.name.toLowerCase();
    }
    return "";
};

export const is_article_branch = (branch_id) => {
    return branch_id !== BRANCH_ALL 
        && branch_id !== ARTICLE_BRANCH_MAPPOST
        && branch_id !== ARTICLE_BRANCH_MAPPOST_TWITTER
        && branch_id !== ARTICLE_BRANCH_MAPPOST_INSTAGRAM
        && branch_id !== ARTICLE_BRANCH_PROFILEPOST
        && branch_id !== ARTICLE_BRANCH_AISUMMARY
        && branch_id !== ARTICLE_BRANCH_SEARCH
        && !is_exclude_branch(branch_id);
};

export const is_social_branch = (branch_id) => {
    return branch_id !== BRANCH_ALL
        && branch_id !== ARTICLE_BRANCH_NEWSPAPER
        && branch_id !== ARTICLE_BRANCH_MAPPOST_TWITTER
        && branch_id !== ARTICLE_BRANCH_MAPPOST_INSTAGRAM
        && branch_id !== ARTICLE_BRANCH_USERPOST
        && branch_id !== ARTICLE_BRANCH_PROFILEPOST
        && branch_id !== ARTICLE_BRANCH_AISUMMARY
        && branch_id !== ARTICLE_BRANCH_SEARCH
        && !is_exclude_branch(branch_id);
};

export const is_weblink_branch = (branch_id) => {
    return branch_id === ARTICLE_BRANCH_RSSFEED || branch_id === ARTICLE_BRANCH_PODCAST;
};

export const is_external_branch = (branch_id) => {
    return branch_id !== BRANCH_ALL
        && branch_id !== ARTICLE_BRANCH_MAPPOST
        && branch_id !== ARTICLE_BRANCH_MAPPOST_TWITTER
        && branch_id !== ARTICLE_BRANCH_MAPPOST_INSTAGRAM
        && branch_id !== ARTICLE_BRANCH_USERPOST
        && branch_id !== ARTICLE_BRANCH_PROFILEPOST
        && branch_id !== ARTICLE_BRANCH_AISUMMARY
        && branch_id !== ARTICLE_BRANCH_SEARCH
        ;
};
