import store from "store";
import { firebase } from "services";

const ERR_MSG_AUTH_TOKEN = "Failed to get authentication token";

const getAuthToken = async () => {
  const state = store.getState();

  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return null;
  }
  let token = authUser.token;
  if (Date.now() >= authUser.expiredTS) {
    const result = await firebase.refreshToken();
    if (result.error) {
      token = null;
    } else {
      token = result.token;
    }
  }
  return token;
};

export { 
  ERR_MSG_AUTH_TOKEN,
  getAuthToken 
};