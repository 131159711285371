import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  indicator: {
    backgroundColor: theme.palette.primary.contrastText
  },
  tabs: {
    padding: 0,
    margin: 0,
    minHeight: 24,
    backgroundColor: theme.palette.background.default
  },
  tab: {
    padding: 0,
    minWidth: 16,
    minHeight: 24,
    fontFamily: 'Arial',
    fontSize: 14,
    textTransform: 'inherit',
    fullWidth: false,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default
  }
});

class AlphabetToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeAlphabet = this.handleChangeAlphabet.bind(this);
  }

  handleChangeAlphabet = (event, newValue) => {
    if (newValue === null) return;
    const { selected } = this.props;
    if (selected === newValue) return;

    this.props.onChangeAlphabet(newValue);
  };

  render() {
    const {
      classes,
      alphabets,
      selected
    } = this.props;

    return (
      <div className={classes.root}>
        <Tabs
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
          value={selected}
          onChange={this.handleChangeAlphabet}
          variant="scrollable"
          scrollButtons="auto">
          {alphabets.map((alphabet, index) => (
            <Tab
              value={alphabet}
              key={`tab-index-${alphabet}`}
              className={classes.tab}
              label={alphabet}
            />
          ))}
        </Tabs>
      </div>
    );
  }
}

AlphabetToolbar.propTypes = {
  classes: PropTypes.object,
  alphabets: PropTypes.array,
  selected: PropTypes.string,
  onChangeAlphabet: PropTypes.func
};

export default withStyles(styles)(AlphabetToolbar);
