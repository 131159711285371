import { logger } from "utility/logging";
import { async } from "validate.js";

const STRIPE_URL = process.env.REACT_APP_RAVEN_STRIPE_API;

// const STRIPE_API_URL = "http://localhost:5001/stripe";
const STRIPE_API_URL = `${STRIPE_URL}`

const createPaymentIntent = (paymentType, user) => {
    const options = {
        metadata: {
            "type": paymentType,
            "userid": user.uid,
            "username": user.username,
            "email": user.email
        } 
    }

    logger.log("createPaymentIntent :", options);

    return window
      .fetch(`${STRIPE_API_URL}/create-payment-intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(options)
      })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (!data || data.error) {
          logger.error("API error:", { data });
          return data
          throw new Error("PaymentIntent API Error");
        } else {
          return data.clientSecret;
        }
      });
  };
  
  const getProductDetails = paymentType => {
    return window
      .fetch(`${STRIPE_API_URL}/product-details?type=${paymentType}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (!data || data.error) {
          logger.error("API error:", { data });
          throw Error("API Error");
        } else {
          logger.log("product detail :", data);
          return data;
        }
      });
  };
  
  const getPublicStripeKey = options => {
    return window
      .fetch(`${STRIPE_API_URL}/public-key`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (!data || data.error) {
          logger.error("API error:", { data });
          return null;
        } else {
          return data.publicKey;
        }
      });
  };
  
  const createSubscriptionIntent = async( data ,user) =>{
    let options = {
      metadata: {
          "type": data.paymentType,
          "userid": user.uid,
          "username": user.username,
          "email": user.email
      } 
  }
    logger.log("createSubscriptionIntent :", data);
    data = { ...data, ...options };
    console.log("options",data)
  
    return window
      .fetch(`${STRIPE_API_URL}/subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then(async (res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (!data || data.error) {
          logger.error("API error on subscription:", { data });
          return data
        } else {
          return data;
        }
      });
  }

  const cancelSubscription =  async (userId) =>{
    logger.log("cancel subscription :", userId);
    const data ={ userId }
  
    return window
      .fetch(`${STRIPE_API_URL}/cancel-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then(async (res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (!data || data.error) {
          logger.error("API error:", { data });
          return data
        } else {
          return data;
        }
      });
      
  }

  const requestRefund =  async (userId) =>{
    logger.log("cancel subscription :", userId);
    const data ={ userId }
  
    return window
      .fetch(`${STRIPE_API_URL}/request-refund`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then(async (res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (!data || data.error) {
          logger.error("API error:", { data });
          return data
        } else {
          return data;
        }
      });
      
  }

  const registerApplePayment = async () =>{
    return window
    .fetch(`${STRIPE_API_URL}/register-apple-payment`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then(async (res) =>{
      console.log("response of registration of apple payment ",res)
      if(res.status === 200){
        return res.json()
      }else{
        return null
      }
    })
    .then(data =>{
      if(!data || data.error){
        logger.error('API error :' ,{ data });
        return data
      }else{
        return data
      }
    })
  }
  
  const stripeService = {
    createPaymentIntent,
    getPublicStripeKey: getPublicStripeKey,
    getProductDetails: getProductDetails,
    createSubscriptionIntent:createSubscriptionIntent,
    cancelSubscription:cancelSubscription,
    requestRefund:requestRefund,
    registerApplePayment:registerApplePayment
  };
  
  export default stripeService;
  