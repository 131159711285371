import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { 
  Grid,
  List,
  ListItem,
  Button,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import * as ROUTES from "constants/routes";
import { 
  BasicAppBar,
  ThreadCard,
  WaitingSpinner
} from "components";
import { 
  withAuthentication 
} from "session";
import {
  withFirebase,
  GraphqlService,
} from "services";
import { 
  MAX_ARTICLE_WIDTH,
  TAB_FEED,
  THREAD_REPORT_COMMENTS,
} from "constants/types";
import { 
  ARTICLE_BRANCH_NEWSPAPER,
  BRANCH_ALL,
} from "constants/branches";
import { ALL } from "constants/country";
import { 
  getMainInfo,
  getAuthToken,
  isFeedModerator,
  isCategoryModerator,
  isBannedComments,
  getFeeds2show,
} from "dataapis";
import {
  ToastError, ToastInfo
} from "utility/toast";
import { logger } from "utility/logging";
import { Mixpanel } from "services";
import * as MIXPANEL_EVENTS from "constants/mixpanel";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 1100,
  },
  reportcontainer: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
  },
  report: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.text.primary,
  },
  btncontainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  actionbtn: {
    backgroundColor: theme.palette.background.card,
    borderRadius: "20px",
    padding: "4px 10px",
    marginLeft: theme.spacing(1),
    textTransform: "initial",
  },
  actionicon: {
    padding: 0,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
});

class CommentsReported extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feed2show: [],
      selected_feed: null,
    };

    this.handleNavBack = this.handleNavBack.bind(this);

    this.handleClickThread = this.handleClickThread.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);

    this.handleClickRemove = this.handleClickRemove.bind(this);
    this.handleClickDismiss = this.handleClickDismiss.bind(this);
  }

  componentDidMount = async () => {
    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      const location = {
        pathname: ROUTES.COMMENTS,
        state: { animation: "right" },
      };
      this.props.history.push(location);
      return;
    }

    this.setWaiting(true);

    await getMainInfo();
    await this._updateThreadReports();

    this.setWaiting(false);

    const feeds2show = getFeeds2show();
    this.setState({
      ...this.state,
      feeds2show: feeds2show
    });
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };
  
  _updateThreadReports = async () => {
    const gqlservice = new GraphqlService();
    await gqlservice
      .thread_reports_by_type(THREAD_REPORT_COMMENTS)
      .then(
        (result) => {
          const thread_reports = result.data.thread_reports;
          logger.log("thread reports from home :", thread_reports);
          this.props.setThreadReports(thread_reports);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  }

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.refreshThreads();
    this.props.history.push(location);
  }

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
  };

  handleClickThread = (thread, feed) => {
    this.props.selectThread(thread);
    this.props.selectFeed(feed);
    if (thread.article) {
      const type = thread.article.source.branch === ARTICLE_BRANCH_NEWSPAPER ? ROUTES.ARTICLE_NEWS_PREFIX : ROUTES.ARTICLE_PREFIX;
      const route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${thread.article.source.slug}/${type}/${thread.article.nid}`;
      const location = {
        pathname: route,
        state: { animation: "left" },
      };
      this.props.history.push(location);
      this.props.setArticleBackRoute(ROUTES.COMMENTS);
    } else {
      const route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.id}`;
      const location = {
        pathname: route,
        state: { animation: "left" },
      };
      this.props.history.push(location);
    }
  }

  handleClickFeed = (feed) => {
    const properties = {
      id: feed.id,
      name: feed.name,
      slug: feed.slug
    };
    Mixpanel.track(MIXPANEL_EVENTS.FEED_DETAILS, properties);

    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // Go to moderation page if the logged user is the moderator of the feed
    if (isFeedModerator(feed)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.setFeedBackRoute(ROUTES.COMMENTS);
    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  }

  handleClickSource = (source, feed) => {
    const properties={
      sourceId : source.id,
      name : source.name
    }
    Mixpanel.track(MIXPANEL_EVENTS.FEED_SOURCE_DETAILS, properties); 

    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  handleClickRemove = async (threadReport) => {
    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }
    this.setWaiting(true);
    await this._removeThread(threadReport.thread);
    await this._deleteThreadReport(threadReport);
    this.setWaiting(false);
  }

  handleClickDismiss = async (threadReport) => {
    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }
    this.setWaiting(true);
    await this._deleteThreadReport(threadReport);
    this.setWaiting(false);
  }

  _removeThread = async (thread) => {
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    const { authUser } = this.props;
    const currentTime = new Date().toISOString();
    await gqlservice.remove_thread_from_home(thread.id, true, authUser.uid, currentTime)
      .then(result => {
        this.props.removeThread(thread.id);
        ToastInfo("Thread removed");
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  _deleteThreadReport = async (threadReport) => {
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    await gqlservice.delete_thread_report(threadReport.id)
      .then(result => {
        this.props.deleteThreadReport(threadReport.id);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  _getFeed = (thread) => {
    const { feeds } = this.props;
    let feed = null;
    
    // if the thread is from a feed
    if (thread.feed_id) {
      feed = feeds.find(item => item.id === thread.feed_id);
      return feed;
    }

    // if the thread is from an article
    if (thread.article) {
      feed = this._getFeedofSource(thread.article.source.id);
      return feed;
    }

    // the thread is from location or readings
    return feed;
  }

  _getFeedofSource = (source_id) => {
    const { loggedIn, followed_feeds, feeds } = this.props;
    let feed = null;
    if (loggedIn) {
      feed = followed_feeds.find(
        (item) =>
          item.feed_sources.find(
            (feed_source) => feed_source.source_id === source_id
          ) !== undefined
      );
      if (feed) {
        return feed;
      }
    }

    feed = feeds.find(
      (item) =>
        item.feed_sources.find(
          (feed_source) => feed_source.source_id === source_id
        ) !== undefined
    );
    return feed;
  }

  _getThreadUrl = (thread) => {
    const feed = this._getFeed();
    if (feed) {
      if (thread.article) {
        const type = thread.article.source.branch === ARTICLE_BRANCH_NEWSPAPER ? ROUTES.ARTICLE_NEWS_PREFIX : ROUTES.ARTICLE_PREFIX;
        return `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${thread.article.source.slug}/${type}/${thread.article.nid}`;
      } else {
        return `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.id}`;
      }
    } else {
      return `/${ROUTES.THREAD_PREFIX}/${thread.id}`;
    }
  }

  render() {
    const { 
      classes, 
      thread_reports,
      requesting 
    } = this.props;

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) {
      width = MAX_ARTICLE_WIDTH;
    }

    let moderator = isCategoryModerator();

    return (
      <div className={classes.root}>

        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_ARTICLE_WIDTH}
            title={"Reported Comment Threads"}
            onNavBack={this.handleNavBack}
          />
        </div>

        <div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <List component="report-list" aria-label="report list">
                {thread_reports.map(thread_report => (
                  <ListItem className={classes.listitem} key={thread_report.id}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-end"
                    >
                      <Grid item>
                        <ThreadCard
                          thread={thread_report.thread}
                          moderator={moderator}
                          onLogin={this.handleLogin}
                          onClickThread={this.handleClickThread}
                          onClickFeed={this.handleClickFeed}
                          onClickSource={this.handleClickSource}
                        />
                      </Grid>
                      <Grid item>
                        <div className={classes.reportcontainer}>
                          <Typography className={classes.report}>
                            {thread_report.report}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.btncontainer}>
                          <Button
                            className={classes.actionbtn}
                            startIcon={
                              <img
                                className={classes.actionicon}
                                alt="remove"
                                src={`/static/images/approve.png`}
                              />
                            }
                            onClick={e => this.handleClickRemove(thread_report)}
                          >
                            Remove
                          </Button>
                          <Button
                            className={classes.actionbtn}
                            startIcon={
                              <img
                                className={classes.actionicon}
                                alt="dismiss"
                                src={`/static/images/dismiss.png`}
                              />
                            }
                            onClick={e => this.handleClickDismiss(thread_report)}
                          >
                            Dismiss
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <div style={{ width: width - 16, height: 20 }}></div>
        </div>

        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

CommentsReported.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
  newssites: state.dataState.newssites,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  sources: state.dataState.sources,
  thread_reports: state.dataState.thread_reports,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withAuthentication,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CommentsReported);
