import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { 
  Button, 
  IconButton, 
  Typography, 
  Box, 
  Grid, 
  Badge 
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withFirebase } from 'services';
import { 
  withAuthentication, 
  withAuthorization 
} from "session";
import { 
  DlgVideo,
  HelpAppBar,
  IconTextItem, 
  WaitingSpinner 
} from "components";
import { 
  ItemList,
  Place,
} from "./components";
import { BRANCH_ALL } from "constants/branches";
import * as ROUTES from "constants/routes";
import { ALL } from "constants/country";
import { GraphqlService } from "services";
import { 
  TAB_SOURCES, 
  MAX_WINDOW_WIDTH 
} from "constants/types";
import { ACTIVITY_TYPE_CATEGORY } from "constants/activity";
import { 
  getAuthToken,
  getMainInfo 
} from "dataapis";
import { ToastError } from "utility/toast";


const condition = authUser => !!authUser && authUser.uid.length > 0;


const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    zIndex: 1100
  },
  action: {
    backgroundColor: theme.palette.background.default,
    marginLeft: theme.spacing(1),
  },
  actionIcon: {
    padding: 4,
  },
  actionImg: {
    width: 24,
    height: 24,
    padding: 4,
  },
  action_description: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
    alignItems: "center",
    display: "flex",
  },
  content: {
    position: "relative",
    padding: theme.spacing(1)
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
    textTransform: "none"
  },
  button: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    textTransform: "none"
  },
  description: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "14px",
    lineHeight: "14px",
    color: theme.palette.text.secondary,
    textTransform: "none"
  },
  categories: {
    clear: "both",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  feeds: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    margin: 0,
    backgroundColor: theme.palette.background.default
  },
  places: {
    clear: "both",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  badge: {
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    backgroundColor: "red",
    color: "white",
  },
});

class Moderation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actionMode: true,
      categories: [],
      feeds: [],
      places: [],
      helpDlg: false
    };

    this.handleToggleAction = this.handleToggleAction.bind(this);
    this.handleSelectCategory = this.handleSelectCategory.bind(this);
    this.handleSelectFeed = this.handleSelectFeed.bind(this);
    this.handleSelectPlace = this.handleSelectPlace.bind(this);

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handlePlayHelp = this.handlePlayHelp.bind(this);
    this.handleCloseHelp = this.handleCloseHelp.bind(this);
  }

  componentDidMount = async () => {
    this.setWaiting(true);

    await getMainInfo();
    await this.getModerates();

    this.setWaiting(false);

    this.props.setModerate(true);
  }

  setError = message => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  getModerates = async () => {
    const { authUser, categories, feeds, regions } = this.props;

    // categories moderated
    let categories2show = [];
    const categories2moderate = authUser.categories_moderated.filter(item => item.approved);
    for (let item of categories2moderate) {
      let result = categories.find(category => category.id === item.category_id);
      if (result) {
        // let moderation_category = await this.getCategoryModerates(result);
        categories2show.push(result);
      }
    };

    // feeds moderated
    let feeds2show = [];
    for (let item of authUser.feeds_moderated) {
      let result = feeds.find(feed => feed.id === item.feed_id);
      if (result) {
        // skip if this feed is in the moderated categories
        if (categories2show.find(category => category.id === result.category_id)) {
          continue
        }
        // let moderation_feed = await this.getFeedModerates(result);
        feeds2show.push(result);
      }
    }

    // places moderated
    let places2show = [];
    for (let item of authUser.regions_moderated) {
      let result = regions.find(region => region.id === item.region_id);
      if (result) {
        // let moderation_feed = await this.getFeedModerates(result);
        places2show.push(result);
      }
    }

    this.setState({
      ...this.state,
      categories: categories2show,
      feeds: feeds2show,
      places: places2show
    });
  }

  handleToggleAction = () => {
    const actionMode = !this.state.actionMode;
    this.setState({
      ...this.state,
      actionMode: actionMode
    });
  }

  handleSelectCategory = category => {
    this.props.selectCategory(category);
    this.props.setCategoryBackRoute(ROUTES.MODERATION);
    let path = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CATEGORY_PREFIX}/${category.id}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  handleSelectFeed = feed => {
    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_SOURCES);

    let path = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setFeedBackRoute(ROUTES.MODERATION);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.showTopNavbar(true);
  };

  handleSelectPlace = place => {
    // get locations
    const locations = place.locations_aggregate.nodes;
    if (locations.length > 0) {
      this.props.setMapCenterPos(locations[0].longitude, locations[0].latitude);
    }

    const location = {
      pathname: ROUTES.CLEANAIRMAP,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.showTopNavbar(true);
  };

  handlePlayHelp = () => {
    this.setState({
      ...this.state,
      helpDlg: true
    });
  }

  handleCloseHelp = () => {
    this.setState({
      ...this.state,
      helpDlg: false
    });
  }

  getCategoryModerates = async (category) => {
    const { feeds } = this.props;

    category.proposed_feeds = [];
    category.feed_reports = [];
    category.category_logs = [];

    const feeds2moderate = feeds.filter(feed => feed.category_id === category.id && feed.approved === false);
    category.proposed_feeds = feeds2moderate;

    const category_feed_ids = feeds.filter(feed => feed.approved).map(feed => feed.id);
    if (category_feed_ids.length === 0) {
      return category;
    }

    let feed_reports = [];
    let category_logs = [];
    let mod_count = feeds2moderate.length;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    // show only previous 7 day's log
    const timestamp = new Date() - 86400 * 7 * 1000;
    const after = new Date(timestamp).toISOString();

    await gqlservice.category_moderation_fields(category.id, category_feed_ids, ACTIVITY_TYPE_CATEGORY, after)
      .then(result => {
        feed_reports = result.data.feed_reports_aggregate.nodes.slice();
        mod_count += result.data.feed_reports_aggregate.aggregate.count;
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // // feeds already approved
    // const approved_feeds = feeds.filter(feed =>
    //   feed.approved &&
    //   feed.category_id === category.id
    // );
    // if (approved_feeds.length > 0) {
    //   for (let feed of approved_feeds) {
    //     let new_feed = await this.getFeedModerates(feed);
    //     mod_count += new_feed.badgeCount;
    //   }
    // }

    category.feed_reports = feed_reports;
    category.category_logs = category_logs;
    category.badgeCount = mod_count;

    return category;
  }

  getFeedModerates = async (feed) => {

    let new_feed = feed;
    const sources2moderate = new_feed.feed_sources.filter(feed_source => feed_source.approved === false);

    const feed_source_ids = new_feed.feed_sources
      .filter(feed_source => feed_source.approved)
      .map(feed_source => feed_source.source_id);
    if (feed_source_ids.length === 0) {
      return feed;
    }

    const timestamp = new Date() - 86400 * 1000;
    const after = new Date(timestamp).toISOString();

    let badgeCount = sources2moderate.length;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    await gqlservice.feed_moderation_count(feed.id, feed_source_ids, after)
      .then(result => {
        badgeCount += result.data.feed_sources_aggregate.aggregate.count;
        badgeCount += result.data.source_reports_aggregate.aggregate.count;
        badgeCount += result.data.articles_aggregate.aggregate.count + result.data.feed_posts_aggregate.aggregate.count;
        badgeCount += result.data.article_reports_aggregate.aggregate.count;
        badgeCount += result.data.feed_moderators_aggregate.aggregate.count;
        badgeCount += result.data.moderator_reports_aggregate.aggregate.count;
        badgeCount += result.data.thread_reports_aggregate.aggregate.count;
        badgeCount += result.data.threads_aggregate.aggregate.count;
      }, reason => {
        this.setError(reason.msg);
        return feed;
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        return feed;
      });

    new_feed.badgeCount = badgeCount;

    return new_feed;
  }

  render() {
    const { 
      classes, 
      theme_mode, 
      requesting 
    } = this.props;
    const { 
      actionMode, 
      categories, 
      feeds,
      places,
      helpDlg
    } = this.state;

    // action
    const actionMode_Img = actionMode ? `/static/images/icons/${theme_mode}/public.png` : `/static/images/icons/${theme_mode}/private.png`;
    const actionMode_text = actionMode ? "Moderation actions are public" : "Moderation actions are private";

    // SlideLists width
    // const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    // const slidelist_width = width - 32; // content padding, slidelist margin

    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const count_per_row = Math.floor(width / 100);

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <HelpAppBar
            title={"Moderation"}
            onNavBack={this.handleNavBack}
            onHelp={this.handlePlayHelp}
          />
        </div>
        <Box
          display={"flex"}
          className={classes.action}
          onClick={this.handleToggleAction}
        >
          <IconButton className={classes.actionIcon}>
            <img
              className={classes.actionImg}
              alt="action"
              src={actionMode_Img}
            />
          </IconButton>
          <Typography className={classes.action_description}>
            {actionMode_text}
          </Typography>
        </Box>
        <div className={classes.content}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Grid item>
              <Button className={classes.title}>{"Categories that You Moderate"}</Button>
            </Grid>
          </Grid>
          <div className={classes.categories}>
            {/* <SlideLists
              inline_text
              badged
              width={slidelist_width}
              items={categories}
              onItemSelected={this.handleSelectCategory}
            /> */}
            <Grid
              container
              direction="row"
              justifyContent={categories.length > count_per_row ? "center" : "left"}
              alignItems="flex-start"
              spacing={1}
            >
              {categories.map((category, index) => (
                <Grid item key={index}>
                  <div onClick={e => this.handleSelectCategory(category)}>
                    {category.notifications > 0 ?
                    <Badge
                      classes={{ badge: classes.badge }}
                      badgeContent={category.notifications}
                      overlap="rectangular"
                    >
                      <IconTextItem 
                        title={category.name} 
                        image={category.image} 
                      />
                    </Badge>
                    :
                    <IconTextItem 
                      title={category.name} 
                      image={category.image} 
                    />
                    }
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Grid item>
              <Button className={classes.title}>{"Feeds You Moderated"}</Button>
            </Grid>
          </Grid>
          <div className={classes.feeds}>
            <ItemList
              badged
              items={feeds}
              onItemClicked={this.handleSelectFeed}
            />
          </div>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Grid item>
              <Button className={classes.title}>{"Places that You Moderate"}</Button>
            </Grid>
          </Grid>
          <div className={classes.places}>
            <Grid
              container
              direction="row"
              justifyContent={places.length > count_per_row ? "center" : "left"}
              alignItems="flex-start"
              spacing={1}
            >
              {places.map((place, index) => (
                <Grid item key={index}>
                  <div onClick={e => this.handleSelectPlace(place)}>
                    <Place 
                      place={place} 
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>

          <DlgVideo
            open={helpDlg}
            video={"/static/videos/Raven_Moderation.mp4"}
            onHelp={this.handleCloseHelp}
          />
          <WaitingSpinner open={requesting} />
          <ToastContainer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  categories: state.dataState.categories,
  feeds: state.dataState.feeds,
  default_feeds: state.dataState.default_feeds,
  followed_feeds: state.dataState.followed_feeds,
  regions: state.mapState.regions,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Moderation);
