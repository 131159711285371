import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { get_branch } from "constants/branches";
import {
  MIN_CARD_WIDTH,
  MAX_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
import { summarize_text } from "utility/utils";

const styles = (theme) => ({
  root: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_CARD_WIDTH,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  grid: {
    // justifyContent: "left",
    flexWrap: "inherit",
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.card,
    // margin: 3,
    borderRadius: 5,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  image: {
    objectFit: "cover",
    height: 80,
    width: 80,
    borderRadius: 10,
    // margin: theme.spacing(1),
  },
  title: {
    position: "relative",
    width: "100% - 112px",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    textTransform: "none",
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  branchimage: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    width: 16,
    height: 16,
  },
  description: {
    position: "relative",
    width: "100% - 132px",
    textAlign: "left",
    fontSize: "12px",
    fontWeight: 100,
    lineHeight: "14px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2) + 24,
  },
});

class Source extends React.Component {
  render() {
    const { classes, source, theme } = this.props;

    let branchimage = "";
    let branchname = "";
    let branchInfo = get_branch(source.branch);
    if (branchInfo) {
      branchimage = `/static/images/icons/${theme}/${branchInfo.image}`;
      branchname = branchInfo.name;
    }

    let refined_name = summarize_text(source.name, 30);
    let refined_description = summarize_text(source.description, 80);

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_CARD_WIDTH) {
      width = MAX_CARD_WIDTH;
    }

    return (
      <div className={classes.root} style={{ width: width - 16 }}>
        <img
          className={classes.branchimage}
          src={branchimage}
          alt={branchname}
        />
        <Grid container className={classes.grid}>
          <Grid item>
            <LazyLoadImage
              className={classes.image}
              src={source.thumbnail || source.image || RAVEN_PLACEHOLDER_IMAGE}
              alt={source.name}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.title}>{refined_name}</Typography>
            <Typography className={classes.description}>
              {refined_description}
            </Typography>
            <div style={{ width: width - 112 }}></div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Source.propTypes = {
  classes: PropTypes.object,
  source: PropTypes.object,
  theme: PropTypes.string,
};

export default withStyles(styles)(Source);
