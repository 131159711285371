import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Moderator } from "./components";


const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    // marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  moderator: {
    marginBottom: theme.spacing(1)
  },
  approved: {
    marginBottom: theme.spacing(1)
  },
  nonapproved: {
    marginTop: theme.spacing(2)
  }
});

class ModeratorList extends React.Component {
  constructor(props) {
    super(props);
    this.scrollFn = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollFn);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFn);
  }

  listenToScroll = (event) => {
    event.preventDefault();

    const { topNavbar } = this.props;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    if (height === 0) {
      return;
    }
    const scrolled = winScroll * 1.0  / height;
    if (scrolled === 0.0) {
      this.props.showTopNavbar(true);
    } else {
      if (topNavbar) {
        this.props.showTopNavbar(false);
      }
    }
  };

  render() {
    const { 
      classes, width, theme, fullaccess, owner, moderators, 
      onReport, onApprove, onDelete, onMakeOwner
    } = this.props;

    if (moderators.length === 0) {
      return <div style={{display: "none"}}></div>;
    }

    const approved_moderators = moderators.filter(moderator => moderator.approved);
    const nonapproved_moderators = moderators.filter(moderator => !moderator.approved);

    return (
      <div className={classes.root} style={{width: width}}>
        <Grid container className={classes.moderator} spacing={1}>
          {approved_moderators.map((moderator, index) => (
            <Grid item key={moderator.id} className={classes.approved}>
              <Moderator
                theme={theme}
                fullaccess={fullaccess}
                owner={owner}
                moderator={moderator}
                onReport={onReport}
                onApprove={onApprove}
                onDelete={onDelete}
                onMakeOwner={onMakeOwner}
              />
            </Grid>
          ))}
          {nonapproved_moderators.map((moderator, index) => (
            <Grid item key={moderator.id} className={classes.nonapproved}>
              <Moderator
                theme={theme}
                fullaccess={fullaccess}
                moderator={moderator}
                onReport={onReport}
                onApprove={onApprove}
                onDelete={onDelete}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

ModeratorList.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  theme: PropTypes.string,
  fullaccess: PropTypes.bool,
  owner: PropTypes.bool,
  moderators: PropTypes.array,
  onReport: PropTypes.func,
  onApprove: PropTypes.func,
  onDelete: PropTypes.func,
  onMakeOwner: PropTypes.func
};


const mapStateToProps = state => ({
  topNavbar: state.uiState.topNavbar
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ModeratorList);

