export const QUERY_SUSPENSIONS_BY_USER = `
query suspensions_by_user (
  $user_id: String!
) {
  user_suspensions (
    where: {
      user_id: {_eq: $user_id}
    },
    order_by: {id: desc}
  ) {
    id
    user_id
    type
    from
    till
    suspended_by
    suspended_at
    enabled
  }
}`;

export const MUTATION_INSERT_USER_SUSPENSION = `
mutation insert_user_suspension (
  $user_id: String!,
  $type: Int!,
  $from: timestamptz,
  $till: timestamptz,
  $suspended_by: String!,
  $suspended_at: timestamptz,
  $enabled: Boolean
) {
  insert_user_suspensions_one (
    object: {
      user_id: $user_id,
      type: $type,
      from: $from,
      till: $till,
      suspended_by: $suspended_by,
      suspended_at: $suspended_at,
      enabled: $enabled
    },
    on_conflict: {
      constraint: user_suspensions_pkey,
      update_columns: [
        from,
        till,
        enabled,
        suspended_at
      ]
    }
  ) {
    id
    user_id
    type
    from
    till
    suspended_by
    suspended_at
    enabled
  }
}`;
