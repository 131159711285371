import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Typography,
  Checkbox,
  colors,
} from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MAX_CARD_WIDTH, RAVEN_PLACEHOLDER_IMAGE } from "constants/types";

const styles = (theme) => ({
  root: {
    display: "-webkit-box",
    width: MAX_CARD_WIDTH,
    maxWidth: "100%",
    padding: 0,
    paddingRight: 60,
    marginTop: theme.spacing(1),
    margin: 0,
    cursor: "pointer",
  },
  avatar: {
    minWidth: 60,
  },
  image: {
    objectFit: "cover",
    height: 60,
    width: 60,
    borderRadius: 5,
  },
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: 68,
    color: theme.palette.text.primary,
  },
  name: {
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: "12px",
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
});

const CustomCheckbox = withStyles({
  root: {
    height: 18,
    color: colors.grey[500],
    "&$checked": {
      color: colors.grey[500],
    },
    padding: 0,
    marginLeft: 0,
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class FeedItem extends React.Component {
  render() {
    const { classes, feed, checked, onClicked, onToggled } = this.props;

    return (
      <ListItem className={classes.root} key={feed.id}>
        <ListItemAvatar
          className={classes.avatar}
          onClick={(event) => onClicked(feed.id)}
        >
          <div>
            <LazyLoadImage
              className={classes.image}
              alt={feed.name}
              src={feed.thumbnail || feed.image || RAVEN_PLACEHOLDER_IMAGE}
            />
          </div>
        </ListItemAvatar>
        <ListItemText
          id={feed.id}
          className={classes.text}
          primary={
            <div className={"item"}>
              <Typography className={classes.name}>{feed.name}</Typography>
              <Typography className={classes.description}>
                {feed.description}
              </Typography>
            </div>
          }
        />
        <ListItemSecondaryAction>
          <CustomCheckbox
            color="primary"
            inputProps={{ "aria-labelledby": feed.id }}
            checked={checked}
            onChange={(event) => onToggled(feed.id)}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

FeedItem.propTypes = {
  classes: PropTypes.object,
  feed: PropTypes.object,
  checked: PropTypes.bool,
  onClicked: PropTypes.func,
  onToggled: PropTypes.func,
};

export default withStyles(styles)(FeedItem);
