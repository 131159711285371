import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { MainCard, Thread } from "./components";

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  actionbtn: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 12,
    marginRight: 5,
    textTransform: "inherit",
    color: theme.palette.text.secondary
  }
});

class BlueskyView extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes, article } = this.props;

    const render_threads = () => {
      if (!article.data) {
        return <MainCard article={article} />;
      }

      let threads = article.data.sort((a, b) => a.published - b.published);

      return (
        <div>
          <MainCard article={article} />
          {threads.map((thread, index) => (
              <Thread thread={thread} isLast={index === threads.length - 1} />
          ))}
        </div>
      )
    };

    return (
      <div className={classes.root}>
        {render_threads()}
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          direction="row"
        >
          <Button
            className={classes.actionbtn}
            size="small"
            color="primary"
            target="_blank"
            href={article.url}
          >
            Read original thread on Bluesky
          </Button>
        </Grid>
      </div>
    );
  }
}

BlueskyView.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object
};

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlueskyView));
