import React, { Component } from "react";
import { Router } from "react-router-dom";
import { connect } from "react-redux"; // Import the connect function
import { createBrowserHistory } from "history";
import { socket } from './socket';
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import * as Sentry from "@sentry/react";
import { CaptureConsole, ExtraErrorData, ContextLines, HttpClient, ReportingObserver } from "@sentry/integrations";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import 'react-toastify/dist/ReactToastify.css';
import { ToastSocketSuccess, ToastError } from "utility/toast";
import Routes from "./Routes";
// import { withAuthentication } from "./session";
import { withFirebase } from "services";
 import { 
  addSocketInfo,
  deleteSocket,
  getArticle,
  summaryGenerationUserStatusUpdate,
  updateCache
} from "dataapis";
const browserHistory = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      isConnected: socket.connected,
      message :"",
      socketId : null
    };
  }
  componentDidMount = () => {
  
    const { loggedIn } = this.props; 
    socket.on('connect', this.onConnect);
    socket.on('disconnect', this.onDisconnect); 
    // Initialize Sentry
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay(), 
        new CaptureConsole(),
        new ExtraErrorData(),
        new ContextLines (),
        new HttpClient(),
        new ReportingObserver()
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    

    socket.on('transcriptsAndSummaries', async (data) => {  
      this.setState({ message: data.message });
      ToastSocketSuccess(data.message); 
      let pathName = window.location.pathname; 
      let lastElement = pathName.split('/').pop();  
      await updateCache();
      if(data.status==1 && lastElement==data.articleId){ 
        getArticle(data.articleId)
      }
    });

    socket.on('transcriptsAndSummariesError', (data) => {  
      this.setState({ message: data.message });
      ToastError(data.message)
    });
    
    socket.on('summaryGenerationStatus', (data) => {   //to prevent summary generation until credit calculation processed for old request
      summaryGenerationUserStatusUpdate(data.status)
    }); 

    if (this.props.firebase.messaging) {
      const messaging = this.props.firebase.messaging;
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((data) => {
          // console.warn("token :", data);
        });
    }
  }

  componentDidUpdate = async (prevProps) => {
    const { loggedIn, authUser } = this.props; 
    const { isConnected } = this.state; 
    if(loggedIn && isConnected){
      const targetSocketId = socket.id;
      addSocketInfo(authUser.uid,targetSocketId)
    }else{
      console.log("No authuser ...",)
    }
  }
  componentWillUnmount() {
    socket.off('connect', this.onConnect);
    socket.off('disconnect', this.onDisconnect); 
  }

  onConnect = () => {  
    this.setState({ isConnected: true ,socketId :socket.id});
  }


  onDisconnect = () => { 
    const { loggedIn, authUser } = this.props;  
    if(loggedIn ){ 
      const targetSocketId = socket.id; 
      deleteSocket(authUser.uid,targetSocketId)
    }else{
      console.log("disconnecting...." )
    }
    this.setState({ isConnected: false });
  }

  render() {
    return (
      <Router history={browserHistory}>
        <Routes />
      </Router>
    );
  }
}

// export default withAuthentication(App);
//export default withFirebase(App);
const mapStateToProps = (state) => {
  return {
    loggedIn: state.sessionState.loggedIn,
    authUser: state.sessionState.authUser, // Replace with the actual variable name from your store
  };
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
// Connect the component to the Redux store
const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default withFirebase(ConnectedApp);
