import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { 
  Dialog, 
  DialogTitle, 
  IconButton,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ShareButtonList } from "components";
import { 
  PostCard
} from "./components";
import { MIN_CARD_WIDTH } from "constants/types";
import * as ROUTES from "constants/routes";


const styles = (theme) => ({
  dialog: {
    width: MIN_CARD_WIDTH,
    // maxHeight: 210,
    margin: theme.spacing(1),
  },
  actionbutton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  container: {
    margin: theme.spacing(1),
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  buttonscontainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
});

class DlgShareMapPost extends React.Component {

  getShareInfo = (post) => {
    // share info
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.CLEANAIRMAP_PREFIX}/${ROUTES.ARTICLE_PREFIX}/${post.nid}`;

    // get share title
    let shareTitle = '';
    if (post.title) {
      shareTitle = post.title;
    }

    return {
      title: "Raven CleanAir Map Post: " + shareTitle,
      description: post.summary === null ? '' : post.summary,
      image: post.image === null ? '' : post.image,
      hashtag: '',
      url: shareUrl
    };
  }

  render() {
    const { 
      classes,
      theme_mode,
      open, 
      post,
      onClose
    } = this.props;

    const title = "Share map post";
    const shareInfo = this.getShareInfo(post);

    return (
      <Dialog
        classes={{ paper: classes.dialog }}
        open={open}
        aria-labelledby="mappost-share-dialog"
        onClose={onClose}
      >
        <DialogTitle id="mappost-share-dialog-title">
          <Typography className={classes.title}>
            {title}
          </Typography>
        </DialogTitle>

        <IconButton
          onClick={onClose}
          className={classes.actionbutton}
        >
          <CloseIcon />
        </IconButton>

        <div className={classes.container}>
          <PostCard
            post={post}
          />

          <div className={classes.buttonscontainer}>
            <ShareButtonList
              shareInfo={shareInfo}
              theme_mode={theme_mode}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

DlgShareMapPost.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  post: PropTypes.object,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DlgShareMapPost);
