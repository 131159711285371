import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import {
  BasicAppBar,
} from "components";
import { withAuthentication, withAuthorization } from "session";
import { withFirebase, GraphqlService } from "services";
import {
  MAX_WINDOW_WIDTH,
  MAX_CARD_WIDTH,
  MIN_TABLET_WIDTH,
  FEED_COMMENT_UNMODERATED,
} from "constants/types";
import {
  BRANCH_ALL,
} from "constants/branches";
import { ALL } from "constants/country";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";
import * as ROUTES from "constants/routes";
import { is_comment_enabled_feed } from "utility/feeds";
import { ThreadList } from "./components";
const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 10,
  },
  articlecontainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.default,
  },
  bottomdiv: {
    paddingBottom: theme.spacing(2),
  },
});

class ProfileComments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      threadsData: null,
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    this.handleNeedMoreThreads = this.handleNeedMoreThreads.bind(this);

    this.handleClickThread = this.handleClickThread.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);
  }

  componentDidMount = () => {
    this.handleGetThreadsByUser();
  };

  setError = (errMsg) => {
    ToastError(errMsg);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleNavBack = () => {
    // const location = {
    //   pathname: ROUTES.PROFILE,
    //   state: { animation: "right" },
    // };
    // this.props.history.push(location);
    this.props.history.goBack();
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  goTo = (location) => {
    // this.unregisterSubscribers();
    this.props.history.push(location);
  };

  handleClickThread = (threadData) => {
    this.props.selectThread(threadData);
    const route = `/${ROUTES.THREAD_PREFIX}/${threadData.id}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleClickSource = (source) => {
    this.props.selectSource(source);
    this.props.setSourceBackRoute(
      `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${source.slug}`
    );
    const path = `/${ROUTES.FEEDS_PREFIX}/${source.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.refreshArticles();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.goTo(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleNeedMoreThreads = async () => {
    const { loggedIn, authUser, selected_feed } = this.props;
    if (!is_comment_enabled_feed(selected_feed, loggedIn, authUser)) {
      return;
    }
    const { threads_last_offset, requesting } = this.props;
    if (requesting) {
      return;
    }

    this.setWaiting(true);
    await this.getThreadsInNextPage(threads_last_offset);
    this.setWaiting(false);
  };

  getThreadsInNextPage = async (last_offset) => {
    const { selected_feed } = this.props;

    let source_ids = selected_feed.feed_sources
      .filter((feed_source) => feed_source.approved)
      .map((feed_source) => feed_source.source_id);

    let approved = true;
    if (selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .threads_by_feeds(selected_feed.id, source_ids, approved, last_offset)
      .then(
        (result) => {
          const threads = result.data.threads;
          logger.log("Feed => threads(next page) :", threads);

          if (threads.length > 0) {
            this.props.appendThreads(threads, last_offset + threads.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleGetThreadsByUser = async () => {
    const aipersonaId = this.props.match.params.id;
    this.setWaiting(true);
    const gqlservice = new GraphqlService();
    await gqlservice
      .threads_by_user(aipersonaId, 0)
      .then((result) => {
        this.setState({
          ...this.state,
          threadsData: result.data.threads,
        });
        console.log("result....");
      })
      .then(
        (result) => {}
        // (reason) => {
        //   this.setError(reason.msg);
        // }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  render() {
    const { classes, theme_mode } = this.props;

    const { threadsData } = this.state;

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;

    // // layout variables
    const isDesktop = width >= MAX_WINDOW_WIDTH;
    const isTablet = width >= MIN_TABLET_WIDTH && width < MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_WINDOW_WIDTH}
            title={"Comments"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.articlecontainer}>
          {threadsData ? (
            <>
              {(isDesktop || isTablet) && (
                <Grid item>
                  <Grid direction="row">
                    <Grid item>
                      <ThreadList
                        width={MAX_CARD_WIDTH - 16}
                        theme={theme_mode}
                        threads={threadsData}
                        onClickThread={this.handleClickThread}
                        onClickSource={this.handleClickSource}
                        onLogin={this.handleLogin}
                        onNeedMore={this.handleNeedMoreThreads}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isMobile && (
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <ThreadList
                        width={MAX_CARD_WIDTH - 16}
                        theme={theme_mode}
                        threads={threadsData}
                        onClickThread={this.handleClickThread}
                        onClickSource={this.handleClickSource}
                        onLogin={this.handleLogin}
                        onNeedMore={this.handleNeedMoreThreads}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>No Comments</>
          )}
        </div>

        <div className={classes.bottomdiv}></div>
        <ToastContainer />
      </div>
    );
  }
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  withStyles(styles)
)(ProfileComments);
