import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { makeStyles } from '@material-ui/styles';
import { Grid, IconButton } from "@material-ui/core";
import { Podcast } from "components";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { reorder } from "utility/utils";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  swipe: {
    backgroundColor: theme.palette.background.default
  },
  listitem: {
    padding: 0
  },
  icon_button: {
    padding: 4,
  },
  icon: {
    padding: 4,
    width: 64,
    height: 64,
  },
}));

const PodcastList = (props) => {

  const podcasts = useSelector((state) => state.dataState.podcasts);
  const podcast_last_offset = useSelector((state) => state.dataState.podcast_last_offset)

  useEffect(() => {
    const onScroll = e => {
      e.preventDefault();

      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight - window.innerHeight;
      if (height === 0) {
        return;
      }

      const scrolled = winScroll * 1.0 / height;

      props.saveScrollPos(window.scrollX, window.scrollY);
      if (scrolled === 0.0) {
        props.showTopNavbar(true);
      } else if (scrolled === 1.0 || scrolled >= 0.99) {
        props.saveScrollPos(window.scrollX, window.scrollY);
        props.onNeedMore();
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [props]);

  const handleClick = article => {
    props.saveScrollPos(window.scrollX, window.scrollY);
    props.onSelectArticle(article);
  };

  const handleSwipeStart = () => {
  };

  const handleSwipeEnd = () => {
  };

  const handleSwipeProgress = (progress) => {
  }

  const handleOnClick = id => () => {
  };

  const handleUp = index => () => {
    if (index === 0) {
      return;
    }
    const reordered_podcasts = reorder(
      podcasts,
      index,
      index - 1,
    );
    props.setPodcasts(reordered_podcasts, podcast_last_offset);
  }

  const handleDown = index => () => {
    if (index === props.podcasts.length - 1) {
      return;
    }

    const reordered_podcasts = reorder(
      props.podcasts,
      index,
      index + 1,
    );
    props.setPodcasts(reordered_podcasts, podcast_last_offset);
  }

  const handleDelete = nid => () => {
    const new_podcasts = podcasts.filter(podcast => podcast.nid !== nid);
    props.setPodcasts(new_podcasts, podcast_last_offset);
  };

  const handlePlay = (nid) => {
    const played_podcast = podcasts.find(podcast => podcast.nid === nid);
    if (!played_podcast) {
      return;
    }
    props.selectPodcast(played_podcast);
  }

  const handlePause = (nid) => {
    const paused_podcast = podcasts.find(podcast => podcast.nid === nid);
    if (!paused_podcast) {
      return;
    }
    props.unselectPodcast(paused_podcast);
  }

  const handleEnded = (nid) => {
    const index = podcasts.findIndex(podcast => podcast.nid === nid);
    if (index === -1 || index === podcasts.length - 1) {
      return;
    }
    props.selectPodcast(podcasts[index + 1]);
  }

  const leadingActions = (index) => (
    <LeadingActions>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <SwipeAction onClick={handleUp(index)}>
            <IconButton className={classes.icon_button}>
              <img
                className={classes.icon}
                alt={"up"}
                src={`/static/images/icons/up.png`}
              />
            </IconButton>
          </SwipeAction>
        </Grid>
        <Grid item>
          <SwipeAction onClick={handleDown(index)}>
            <IconButton className={classes.icon_button}>
              <img
                className={classes.icon}
                alt={"down"}
                src={`/static/images/icons/down.png`}
              />
            </IconButton>
          </SwipeAction>
        </Grid>
      </Grid>
    </LeadingActions>
  );

  const trailingActions = (id) => (
    <TrailingActions>
      <SwipeAction destructive={true} onClick={handleDelete(id)}>
        <IconButton className={classes.icon_button}>
          <img
            className={classes.icon}
            alt={"delete"}
            src={`/static/images/icons/delete.png`}
          />
        </IconButton>
      </SwipeAction>
    </TrailingActions>
  );

  const classes = useStyles();

  const swipeBackColor = classes.swipe;

  return (
    <div className={classes.root}>
      {podcasts.length > 0 && (
        <SwipeableList
          fullSwipe={false}
          style={{swipeBackColor}}
          threshold={0.3}
          type={ListType.IOS}
        >
          {podcasts.map((podcast, index) => (
            <SwipeableListItem
              key={podcast.nid}
              leadingActions={leadingActions(index)}
              trailingActions={trailingActions(podcast.nid)}
              onSwipeEnd={handleSwipeEnd}
              onSwipeProgress={handleSwipeProgress}
              onSwipeStart={handleSwipeStart}
              onClick={handleOnClick(podcast.nid)}
            >
              <Podcast
                article={podcast}
                theme={props.theme_mode}
                handleClick={handleClick}
                onClickSource={props.onClickSource}
                onClickFeed={props.onClickFeed}
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded}
              />
            </SwipeableListItem>
          ))}
        </SwipeableList>
      )}
    </div>
  );
}

PodcastList.propTypes = {
  classes: PropTypes.object,
  onNeedMore: PropTypes.func,
  onSelectArticle: PropTypes.func,
  onClickSource: PropTypes.func,
  onClickFeed: PropTypes.func
};


const mapStateToProps = state => ({
  scrollPos: state.uiState.scrollPos,
  theme_mode: state.uiState.theme_mode,
  // podcasts: state.dataState.podcasts
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PodcastList);
