export const LANDING                = '/';
export const SIGN_UP                = '/sign-up';
export const SIGN_IN                = '/sign-in';
export const EMAIL_SIGNIN           = '/sign-in/email';
export const PHONE_SIGNIN           = '/sign-in/phone';
export const RESET_PASSWORD         = '/resetpassword';
export const PROFILE                = '/profile';
export const PROFILE_POSTS          = '/profile/posts';
export const PROFILE_FOLLOWING      = '/profile/following';
export const PROFILE_FOLLOWERS      = '/profile/followers';
export const PROFILE_NEWSLETTER     = '/profile/newsletter';
export const PROFILE_PHONE          = '/profile/phone';
export const ONBOARDING             = '/onboarding';
export const ONBOARDING_CONFIRM     = '/onboarding-confirm';
export const HOME                   = '/home';
export const NOT_FOUND              = '/not-found';
export const NOTIFICATIONS          = '/notifications';
export const ACCOUNT                = '/account';
export const MY_FEEDS               = '/myfeeds';
export const DISCOVER               = '/discover';
export const PODCASTS               = '/podcasts';
export const COMMENTS               = '/comments';
export const COMMENTS_REPORTS       = '/comments-reports';
export const CLEANAIRMAP            = '/cleanair';
export const VIDEOCASTS             = '/videocasts';
// export const BIRDTOK                = '/birdtok';
export const SETTINGS               = '/settings';
export const SAVED                  = '/saved';
export const ABOUT                  = '/about';
export const CONTACTUS              = '/contactus';
export const OUR_VALUES             = '/values';
export const UPTIME_STATS           = '/uptime';
export const TERMS_OF_SERVICE       = '/terms';
export const PRIVACY_POLICY         = '/privacy';
export const MEMBERSHIP             = '/membership';
export const CONTACTS               = '/contacts';
export const EXTERNAL_VIEW          = '/extview';
export const STORIES                = '/stories';
export const CATEGORY_PREFIX        = 'c';
export const FEEDS_PREFIX           = 'f';
export const LIST_PREFIX            = 'l';
export const SOURCE_PREFIX          = 's';
export const MODERATION_PREFIX      = 'm';
export const THREAD_PREFIX          = 't';
export const USER_PREFIX            = 'u';
export const ARTICLE_NEWS_PREFIX    = 'n';
export const ARTICLE_PREFIX         = 'p';
export const CLEANAIRMAP_PREFIX     = 'a';
export const ARTICLE_NEWS_DETAIL    = `/${ARTICLE_NEWS_PREFIX}/:id`;
export const ARTICLE_DETAIL         = `/${ARTICLE_PREFIX}/:id`;
export const CATEGORY_DETAIL        = `/${CATEGORY_PREFIX}/:id`;
export const CATEGORY_SOURCE_DETAIL = `/${CATEGORY_PREFIX}/:category_id/${SOURCE_PREFIX}/:source_slug`;
export const CATEGORY_NEWS_DETAIL   = `/${CATEGORY_PREFIX}/:category_id/${SOURCE_PREFIX}/:source_slug/n/:id`;
export const CATEGORY_POST_DETAIL   = `/${CATEGORY_PREFIX}/:category_id/${SOURCE_PREFIX}/:source_slug/p/:id`;
export const LIST_DETAIL            = `/${LIST_PREFIX}/:id`;
export const FEED_ADD               = `/${FEEDS_PREFIX}_add/`;
export const FEED_DETAIL            = `/${FEEDS_PREFIX}/:slug`;
export const FEED_JOIN              = `/${FEEDS_PREFIX}/:slug/join`;
export const FEED_EDIT              = `/${FEEDS_PREFIX}/:slug/edit`;
export const FEED_POSTS             = `/${FEEDS_PREFIX}/:slug/feed`;
export const FEED_REVIEW            = `/${FEEDS_PREFIX}/:slug/review`;
export const FEED_SOURCES           = `/${FEEDS_PREFIX}/:slug/sources`;
export const FEED_COMMENTS          = `/${FEEDS_PREFIX}/:slug/comments`;
export const FEED_MEMBERS           = `/${FEEDS_PREFIX}/:slug/members`;
export const FEED_INVITEUSER        = `/${FEEDS_PREFIX}/:slug/inviteuser`;
export const FEED_SHARE             = `/${FEEDS_PREFIX}/:slug/share`;
export const FEED_SOURCE_DETAIL     = `/${FEEDS_PREFIX}/:feed_slug/${SOURCE_PREFIX}/:source_slug`;
export const FEED_SOURCE_EDIT       = `/${FEEDS_PREFIX}/:feed_slug/${SOURCE_PREFIX}/:source_slug/edit`;
export const FEED_NEWS_DETAIL       = `/${FEEDS_PREFIX}/:feed_slug/${SOURCE_PREFIX}/:source_slug/n/:id`;
export const FEED_POST_DETAIL       = `/${FEEDS_PREFIX}/:feed_slug/${SOURCE_PREFIX}/:source_slug/p/:id`;
export const FEED_THREAD_DETAIL     = `/${FEEDS_PREFIX}/:feed_slug/${THREAD_PREFIX}/:id`;
export const SOURCE_ADD             = `/${SOURCE_PREFIX}_add/`;
export const SOURCE_SEARCH          = `/${SOURCE_PREFIX}_search/`;
export const SOURCE_DETAIL          = `/${SOURCE_PREFIX}/:slug`;
export const SEARCH_RESULT          = '/searchresult';
export const SEARCH_NEWS_BASE       = `/search/${ARTICLE_NEWS_PREFIX}/`;
export const SEARCH_NEWS_DETAIL     = `/search/${ARTICLE_NEWS_PREFIX}/:id`;
export const SEARCH_BASE            = `/search/${FEEDS_PREFIX}/`;
export const SEARCH_DETAIL          = `/search/${FEEDS_PREFIX}/:id`;
export const SEARCH_VIEW            = '/searchview';
export const MODERATION             = `/${MODERATION_PREFIX}`;
export const CATEGORY_MODERATION    = `/${MODERATION_PREFIX}/${CATEGORY_PREFIX}/:id`;
export const FLAGGED_FEEDS          = `/${MODERATION_PREFIX}/${CATEGORY_PREFIX}/:id/flaggedfeeds`;
export const PROPOSED_FEEDS         = `/${MODERATION_PREFIX}/${CATEGORY_PREFIX}/:id/proposedfeeds`;
export const CATEGORY_LOGS          = `/${MODERATION_PREFIX}/${CATEGORY_PREFIX}/:id/logs`;
export const FEED_MODERATION        = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug`;
export const FLAGGED_SOURCES        = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/flaggedsources`;
export const PROPOSED_SOURCES       = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/proposedsources`;
export const FLAGGED_POSTS          = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/flaggedposts`;
export const PROPOSED_POSTS         = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/proposedposts`;
export const FLAGGED_MODERATORS     = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/flaggedmoderators`;
export const PENDED_THREADS         = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/pendedthreads`;
export const FLAGGED_THREADS        = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/flaggedthreads`;
export const FEED_LOGS              = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/logs`;
export const FEED_SETTINGS          = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/settings`;
export const PERSONA_LIBRARY        = `/${MODERATION_PREFIX}/${FEEDS_PREFIX}/:slug/persona_library`; 
export const COMMENT_BASE           = '/comment/';
export const COMMENT                = '/comment/:id';
export const THREAD_DETAIL          = `/${THREAD_PREFIX}/:id`;
export const USER_DETAIL            = `/${USER_PREFIX}/:username`;
export const USER_FOLLOWING         = `/${USER_PREFIX}/:username/following`;
export const USER_FOLLOWERS         = `/${USER_PREFIX}/:username/followers`;
export const USER_PROFILE_POSTS     = `/${USER_PREFIX}/:username/posts`
export const CLEANAIRMAP_LEADERBOARDS = `/${CLEANAIRMAP_PREFIX}_leaderboards`;
export const CLEANAIRMAP_TUTORIAL   = `/${CLEANAIRMAP_PREFIX}_tutorial`;
export const CLEANAIRMAP_COMMENTS   = `/${CLEANAIRMAP_PREFIX}_comments`;
export const CLEANAIRMAP_ADD        = `/${CLEANAIRMAP_PREFIX}_add/`;
export const CLEANAIRMAP_POST       = `/${CLEANAIRMAP_PREFIX}/${ARTICLE_PREFIX}/:id`;
export const CLEANAIRMAP_DETAIL     = `/${CLEANAIRMAP_PREFIX}/:slug`;
export const CLEANAIRMAP_EDIT       = `/${CLEANAIRMAP_PREFIX}/:slug/edit`;
export const CLEANAIRMAP_READINGS   = `/${CLEANAIRMAP_PREFIX}/:slug/readings`;
export const CLEANAIRMAP_LOCATION_POSTS     = `/${CLEANAIRMAP_PREFIX}/:slug/posts`;
export const CLEANAIRMAP_LOCATION_POST      = `/${CLEANAIRMAP_PREFIX}/:slug/${ARTICLE_PREFIX}/:id`;
export const CLEANAIRMAP_MODERATION = `/${MODERATION_PREFIX}/${CLEANAIRMAP_PREFIX}/:slug`;
export const CLEANAIRMAP_FLAGGED_READINGS   = `/${MODERATION_PREFIX}/${CLEANAIRMAP_PREFIX}/:slug/flaggedreadings`;
export const CLEANAIRMAP_PROPOSED_READINGS  = `/${MODERATION_PREFIX}/${CLEANAIRMAP_PREFIX}/:slug/proposedreadings`;
export const CLEANAIRMAP_FLAGGED_POSTS      = `/${MODERATION_PREFIX}/${CLEANAIRMAP_PREFIX}/:slug/flaggedposts`;
export const CLEANAIRMAP_PROPOSED_POSTS     = `/${MODERATION_PREFIX}/${CLEANAIRMAP_PREFIX}/:slug/proposedposts`;
export const CLEANAIRMAP_FLAGGED_MODERATORS = `/${MODERATION_PREFIX}/${CLEANAIRMAP_PREFIX}/:slug/flaggedmoderators`;
export const CLEANAIRMAP_LOCATION_LOGS      = `/${MODERATION_PREFIX}/${CLEANAIRMAP_PREFIX}/:slug/logs`;
export const CLEANAIRMAP_LOCATION_SETTING   = `/${MODERATION_PREFIX}/${CLEANAIRMAP_PREFIX}/:slug/settings`;
export const TESTCARDS              = '/testcards';
export const SIMILAR_ARTICLE_PREFIX              = 'sm';
export const SIMILAR_ARTICLES = `/${SIMILAR_ARTICLE_PREFIX}/:id`;
export const PROFILE_COMMENTS_PATH = 'profile/comments';
export const PROFILE_COMMENTS = `/${PROFILE_COMMENTS_PATH}/:id`;
export const POPULAR ='/popular';


