import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { get_timestring, render_text } from "utility/utils";
import { CONF_COUNTRIES } from "constants/country";
import {
  MIN_CARD_WIDTH,
  MAX_ARTICLE_WIDTH,
  THEME_MODE_DARK,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";

const styles = (theme) => ({
  card: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_ARTICLE_WIDTH,
    backgroundColor: theme.palette.background.default,
  },
  carddiv: {
    margin: 3,
    marginBottom: 4,
    borderRadius: 10,
    borderColor: theme.palette.feedstroke.news,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.news,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 32,
    height: 32,
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  title: {
    position: "relative",
    marginTop: 10,
    marginLeft: 30,
    marginRight: 10,
    marginBottom: 10,
  },
  subtitle: {
    position: "relative",
    marginLeft: 30,
    marginRight: 10,
  },
  subtitle_left: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    float: "left",
    position: "relative",
    padding: 0,
  },
  subtitle_right: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    float: "right",
    marginRight: 10,
    padding: 0,
  },
  country_img: {
    position: "relative",
    width: "auto",
    float: "left",
    height: 16,
    marginTop: 2,
    marginRight: theme.spacing(1),
  },
  content: {
    padding: 4,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 8,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  summary: {
    display: "inline",
  },
  media: {
    width: 100,
    height: "auto",
    float: "right",
    borderRadius: 5,
    marginLeft: 5,
    marginTop: 10,
    marginBottom: 8,
  },
  actions: {
    position: "relative",
    width: "100%",
    padding: 0,
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1)
  },
  translated_div: {
    float: "left",
    height: 16,
    width: 250,
    marginLeft: 10,
    marginBottom: 8,
  },
  traslated_icon: {
    // position: "relative",
    float: "left",
    top: 3,
    marginLeft: 5,
    width: 16,
    height: 16,
    color: theme.palette.text.primary,
  },
  translated_txt: {
    display: "inline",
    marginLeft: 2,
    fontSize: 12,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
});

class NewsCard extends React.Component {
  getCountryInfo(countryCode) {
    for (let country of CONF_COUNTRIES) {
      if (country.value === countryCode) {
        return country;
      }
    }
    return "";
  }

  render() {
    // const { classes, source, newssite, article, search_mode } = this.props;
    const { classes, theme_mode, source, article, search_mode, onClickSource } =
      this.props;

    // let newssite_name = newssite.name;
    // newssite_name = newssite_name.replace(" Opinion", "");
    // newssite_name = newssite_name.replace(" Breaking", "");

    let country = this.getCountryInfo(article.country);

    var transMark = "";
    var title = "";
    var summary = "";
    if (source.translate && article.translated) {
      transMark = `Translated from ${source.translateLang} by GPT3.5`;

      // In search results, the headline and the summary was already replaced.
      if (search_mode) {
        title = article.headline;
        summary = article.summary;
      } else {
        title = article.tr_title;
        // omit the last character(.)
        title = title.slice(0, title.length - 1);
        summary = article.tr_summary;
      }
    } else {
      if (search_mode) {
        title = article.headline;
      } else {
        title = article.title;
      }
      summary = article.summary;
    }
    var newsTime = get_timestring(article.published);

    // image
    let article_image = article.image;
    const image_thumb = article.image_thumb;
    if (image_thumb) {
      if (window.innerWidth < 320 && image_thumb["240"] !== undefined) {
        article_image = image_thumb["240"];
      } else if (window.innerWidth < 480 && image_thumb["320"] !== undefined) {
        article_image = image_thumb["320"];
      } else if (window.innerWidth < 640 && image_thumb["480"] !== undefined) {
        article_image = image_thumb["480"];
      } else if (window.innerWidth < 800 && image_thumb["640"] !== undefined) {
        article_image = image_thumb["640"];
      } else if (image_thumb["org"] !== undefined) {
        article_image = image_thumb["org"];
      }
    }
    const hasImage = article_image !== "" && article_image !== null;

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) width = MAX_ARTICLE_WIDTH;
    if (width < MIN_CARD_WIDTH) width = MIN_CARD_WIDTH;
    width = width - 16;

    return (
      <Card className={classes.card} style={{ width: width }}>
        <div className={classes.carddiv}>
          <CardHeader
            className={classes.header}
            avatar={
              <div onClick={(e) => onClickSource(source)}>
                <Avatar
                  alt={source.name}
                  src={source.thumbnail || source.image || RAVEN_PLACEHOLDER_IMAGE}
                  className={classes.avatar}
                  classes={{ img: classes.avatar_img }}
                />
              </div>
            }
            title={
              <Typography className={classes.title} variant="subtitle1">
                {render_text(title, theme_mode === THEME_MODE_DARK)}
              </Typography>
            }
            subheader={
              <div className={classes.subtitle}>
                <img
                  alt={country.name}
                  className={classes.country_img}
                  src={country.flag}
                />
                <Typography className={classes.subtitle_left}>
                  {country.name}
                </Typography>
                <Typography className={classes.subtitle_right}>
                  {newsTime}
                </Typography>
              </div>
            }
          />
          <CardContent className={classes.content}>
            <Typography className={classes.summary} variant="body2">
              {hasImage && (
                <LazyLoadImage
                  alt={""}
                  src={article_image}
                  className={classes.media}
                />
              )}
              {render_text(summary, theme_mode === THEME_MODE_DARK)}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions}>
            {source.translate === true && (
              <div className={classes.translated_div}>
                <StarIcon className={classes.traslated_icon} />
                <Typography className={classes.translated_txt}>
                  {transMark}
                </Typography>
              </div>
            )}
          </CardActions>
        </div>
      </Card>
    );
  }
}

NewsCard.propTypes = {
  classes: PropTypes.object,
  theme_mode: PropTypes.string,
  article: PropTypes.object,
  source: PropTypes.object,
  newssite: PropTypes.object,
  search_mode: PropTypes.bool,
  onClickSource: PropTypes.func,
};

export default withStyles(styles)(NewsCard);
