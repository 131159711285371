import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { hasPaid } from "./user";
import { get_cached_item, set_cache_item } from "utility/cachemanager";
import { setError } from "./base";
import { GRAPHQL_ERROR } from "constants/types";

const getLists = async () => {
  const state = store.getState();

  const { lists } = state.dataState;
  if (lists.length > 0) {
    return;
  }

  const gqlservice = new GraphqlService();
  let result = await gqlservice.lists();
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const new_lists = result.data.lists;
  if (new_lists.length === 0) {
    return;
  }

  const { feeds, sources } = state.dataState;
  
  let new_feeds = feeds.slice();
  let new_sources = sources.slice();

  for (let list of new_lists) {
    // get feeds and its sources from the list
    let list_feeds = [];
    if (list.list_feeds.length > 0) {
      list_feeds = list.list_feeds.map(item => item.feed);
      for (let feed of list_feeds) {
        if (!new_feeds.find(item => item.id === feed.id)) {
          new_feeds.push(feed);
        }

        if (feed.feed_sources.length > 0) {
          let feed_sources = feed.feed_sources.map(item => item.source);
          for (let source of feed_sources) {
            if (!new_sources.find(item => item.id === source.id)) {
              new_sources.push(source);
            }
          }
        }
      }
    }

    // get sources from the list
    let list_sources = [];
    if (list.list_sources.length > 0) {
      list_sources = list.list_sources.map(item => item.source);
      for (let source of list_sources) {
        if (!new_sources.find(item => item.id === source.id)) {
          new_sources.push(source);
        }
      }
    }
  }

  await Promise.all([
    store.dispatch({type: ActionTypes.SET_LISTS, lists: new_lists}),
    store.dispatch({type: ActionTypes.SET_FEEDS, feeds: new_feeds}),
    store.dispatch({type: ActionTypes.SET_SOURCES, sources: new_sources}),
  ]);

  // get base data from cache
  const { loggedIn } = state.sessionState;
  let cache_name = loggedIn
    ? hasPaid()
      ? "base_data_paiduser_v1"
      : "base_data_user_v1"
    : "base_data_anonymous_v1";
  let base_data = await get_cached_item(cache_name);
  if (base_data) {
    base_data.lists = new_lists;
    base_data.feeds = new_feeds;
    base_data.sources = new_sources;
    set_cache_item(cache_name, base_data);
  }
};

export { getLists };