export const QUERY_FEED_SOURCES_BY_FEED = `
query feed_sources_by_feed(
  $feed_id: String!
) {
  feed_sources(
    where: {
      feed_id: {_eq: $feed_id}}
  ) {
    id
    feed_id
    source_id
    keyword_filter
    created_at
    created_by
    approved
    approved_at
    approved_by
  }
}`;

export const MUTATION_INSERT_FEED_SOURCES = `
mutation insert_feed_sources(
  $objects: [feed_sources_insert_input!]!
) {
  insert_feed_sources(
    objects: $objects
  ) {
    affected_rows
    returning {
      feed {
        id
        category_id
        name
        description
        slug
        image
        thumbnail
        tags
        followers
        created_at
        created_by
        approved
        op_comments
        op_posts
        op_members
        op_payment
        op_anonymity
        comment_conf
        ai_comment_conf
        ai_moderation
        ai_summary_conf
        tg_wallet
        tg_address
        tg_amount
        token_address
        token_amount
        channel_instagram
        channel_tiktok
        channel_twitter
        channel_youtube
        last_viewlog
        private
        article_count
        notifications
        notif_date
        updated_at
        feed_sources {
          id
          feed_id
          source_id
          keyword_filter
          approved
          create_user {
            uid
            username
          }
          source {
            id
            branch
            throttle
            has_articles
            articles_no
            last_updated
          }
        }
        feed_reports {
          id
          report
          approved
        }
        feed_moderators {
          user_id
          feed_id
          approved
          owner
        }
        ai_persons{
          id
          user_id
          seed_phrase
          user{
            name
            biography
            image
            }
        }
      }
    }
  }
}`;

export const MUTATION_DELETE_FEED_SOURCE = `
mutation delete_feed_source(
  $feed_id: String!,
  $source_id: String!
) {
  delete_feed_sources(
    where: {
      feed_id: {_eq: $feed_id},
      source_id: {_eq: $source_id}
    }
  ) {
    affected_rows
    returning {
      feed {
        feed_sources {
          id
          feed_id
          source_id
          keyword_filter
          approved
          create_user {
            uid
            username
          }
          source {
            id
            branch
            throttle
            has_articles
            articles_no
            last_updated
          }
        }
      }
    }
  }
}`;

export const MUTATION_UPDATE_FEED_SOURCE = `
mutation update_feed_source(
  $id: uuid!,
  $source_id: String!,
  $approved: Boolean!,
  $approved_by: String!,
  $approved_at: timestamptz!
) {
  update_feed_sources(
    where: {
      id: {_eq: $id}
    }, 
    _set: {
      approved: $approved,
      approved_by: $approved_by,
      approved_at: $approved_at
    }
  ) {
    affected_rows
  }

  update_sources(
    where: {
      id: {_eq: $source_id}
    },
    _set: {
      approved: $approved
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_FEED_SOURCE_KEYWORD = `
mutation update_feed_source_keyword(
  $feed_id: String!,
  $source_id: String!,
  $keyword: String!
) {
  update_feed_sources(
    where: {
      feed_id: {_eq: $feed_id},
      source_id: {_eq: $source_id}
    }, 
    _set: {
      keyword_filter: $keyword
    }
  ) {
    affected_rows
    returning {
      feed {
        feed_sources {
          id
          feed_id
          source_id
          keyword_filter
          approved
          create_user {
            uid
            username
          }
          source {
            id
            branch
            throttle
            has_articles
            articles_no
            last_updated
          }
        }
      }
    }
  }
}`;

export const MUTATION_INSERT_FEED_SOURCE_CHANGES = `
mutation insert_feed_source_changes(
  $objects: [feed_source_change_insert_input!]!
) {
  insert_feed_source_change(
    objects: $objects
  ) {
    affected_rows
  }
}`;