import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { copy2clipboard } from "utility/utils";
import { ToastSuccess } from "utility/toast";
import TranscriptQuery from "../TranscriptQuery";

const styles = (theme) => ({
  root: {
    width: "95%",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.card,
    borderRadius: 32,
    textTransform: "inherit",
    textAlign: "left",
    margin: 'auto',
    marginBottom: 6,
  },
  button: {
    background: 'transparent',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 16px',
  },
  icon: {
    width: 48,
    height: 48,
    borderRadius: 32,
    marginLeft: -8
  },
  startIcon: {
    marginLeft: -theme.spacing(1),
    marginRight: 0,
  },
  text: {
    marginLeft: theme.spacing(2),
    textAlign: 'initial',
  },
  title: {
    display: "block",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "0.66px",
  },
  description: {
    display: "block",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.66px",
  },
  contents: {
    backgroundColor: theme.palette.background.card,
    marginTop: -14,
    padding: 18,
    paddingTop: 32,
    fontSize: 16,
    fontFamily: 'Roboto, sans-serif',
    lineHeight: 1.5,
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    color: theme.palette.text.primary,
  },
  mainContent:{
    position:'relative'
  },
  copy_image: {
    width: '24px',
    height: '24px',
  },
  image: {
    position: 'absolute',
    top: -20,
    right: 0,
    marginRight:"10px",
    zIndex: 1,
    padding: '6px',
    cursor:'pointer'
  },
  queryTranscript: {
    width: "70%",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    borderColor: theme.palette.default,
    borderWidth: 2,
    borderStyle: "solid",
    padding: "8px",
    display: "flex",
    textAlign: "center",
    fontSize: '15px',
    letterSpacing: '0.05px',
    fontWeight: "bold",
    marginTop: "10px",
  },
  
});

class Transcript extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showTranscriptQuery: false,
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOpenQueryTranscript = this.handleOpenQueryTranscript.bind(this);
  }

  toggleExpanded() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  handleOpenQueryTranscript = () => {
    this.setState({
      ...this.state,
      showTranscriptQuery: true
    });
  }

  handleCancel = () => {
    this.setState({
      ...this.state,
      showTranscriptQuery: false,
    });
  }

  handleCopy = (text) => {
    if (text.length > 0) {
      const copiedText = text
        .map(obj => {
          if (typeof obj === 'string') {
            return obj;
          }
          const elementText = obj.props.children[1].props.children;
          return elementText;
        })
        .join('\n');
      copy2clipboard(copiedText);
      ToastSuccess('Transcript Copied to Clipboard!');
      return copiedText;
    } else if (typeof text === 'string') {
      copy2clipboard(text);
      ToastSuccess('Transcript Copied to Clipboard!');
      return text;
    } else {
      const elementText = text.props.children[1].props.children;
      copy2clipboard(elementText);
      ToastSuccess('Transcript Copied to Clipboard!');
      return elementText;
    }
  }

  render() { 
    const { classes, title, ai_transcription, disabled, imageIcon, parsetoHtml ,articleId } = this.props;
    const { expanded, showTranscriptQuery } = this.state; 
    const transcriptArray = ai_transcription?.split('\n\n'); 
    const description = expanded ? 'Press to close' : 'Press to expand and view'
    const transcription = (ai_transcription === null ||ai_transcription === undefined )?'':parse(ai_transcription);
    return (
      <div className={classes.root}> 
          <button
            variant="contained"
            className={classes.button}
            onClick={this.toggleExpanded}
            disabled={disabled}
          >
            <img className={classes.icon} alt='' src={imageIcon} />
            <div className={classes.text}>
              <Typography className={classes.title}>{title}</Typography>
              <Typography className={classes.description}>{description}</Typography>
            </div>
          </button> 

        {expanded &&
          <div className={classes.mainContent}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.banner}
            >
              <Button
                className={classes.queryTranscript}
                onClick={this.handleOpenQueryTranscript}
              >
                Query Transcript
              </Button>
            </Grid>
            <div className={classes.contents}>
              {parsetoHtml ?
                transcriptArray?.map((paragraph, index) => (

                  <div>
                    <IconButton
                      className={classes.image}
                      onClick={e => this.handleCopy(transcriptArray)}
                    >
                      <img
                        className={classes.copy_image}
                        alt="copy link"
                        src={`/static/images/icons/copytext.png`}
                      />
                    </IconButton>
                    <p> {`- ${paragraph}`}</p>
                  </div>
                )) :
               (
                <div>
                   <IconButton
                      className={classes.image}
                      onClick={e => this.handleCopy(transcription)}
                    >
                      <img
                        className={classes.copy_image}
                        alt="copy link"
                        src={`/static/images/icons/copytext.png`}
                      />
                    </IconButton>
                  <p>{transcription}</p>
                </div>
               
                )
                
              }
            </div>
          </div>
        } 
        <TranscriptQuery
            open={showTranscriptQuery}
            ai_transcription={ai_transcription}
            title={""}
            articleId={articleId}
            onCancel={this.handleCancel}
            onPost={this.props.onPost}
            onMemberTrial={this.props.onMemberTrial}
        />
      </div>
    );
  }
}
Transcript.propTypes = {
  title: PropTypes.string,
  articleId:PropTypes.string,
  onPost  : PropTypes.func,
  onMemberTrial :PropTypes.func
};
export default withStyles(styles)(Transcript);