import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from '@material-ui/core/styles';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { 
  MAX_ARTICLE_WIDTH,
} from 'constants/types';


const styles = theme => ({
  root: {
    position: "fixed",
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    height: "56px",
    zIndex: 1100,
  },
  appbar: {
    position: "absolute",
    // borderBottom: `2px solid ${theme.palette.text.secondary}`
  },
  navbefore: {
    color: theme.palette.text.primary,
  },
  toolbox: {
    display: 'flex'
  },
  titlebox: {
    display: "flex",
    alignItems: "center"
  },
  image: {
    height: 32,
    width: 32,
    padding: 4,
    borderRadius: 16,
  },
  title: {
    display: "inline",
    marginLeft: theme.spacing(1),
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: 500,
  },
});

class LeaderboardsAppBar extends React.Component {

  render() {
    const {
      classes,
      theme_mode,
      onNavBack,
    } = this.props;

    return (
      <div className={classes.root}>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton 
              edge="start" 
              className={classes.navbefore} 
              aria-label="nav before"
              onClick={onNavBack}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <div className={classes.titlebox}>
              <img
                className={classes.image}
                src={`/static/images/icons/${theme_mode}/star1.png`}
                alt="Cleanair Map"
              />
              <Typography className={classes.title} variant="h6" noWrap>
                Leaderboards
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

LeaderboardsAppBar.propTypes = {
  classes: PropTypes.object,
  onNavBack: PropTypes.func,
};

const mapStateToProps = state => ({
  theme_mode: state.uiState.theme_mode
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LeaderboardsAppBar));
