import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router"; 
import { withStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/core";
import {
  NewsCard,
  RedditCard,
  YoutubeCard,
  TwitterCard,
  InstagramCard,
  TwitchCard,
  MediumCard,
  RssCard,
  PodcastCard,
  HackernewsCard,
  SlashdotCard,
  VscoCard,
  PinterestCard,
  TiktokCard,
  SpotifyCard,
  QuoraCard,
  FacebookCard,
  SubstackCard,
  GmailCard,
  VimeoCard,
  WeblinkCard,
  MastodonCard,
  BlueskyCard,
  ProfilepostCard,
  UserpostCard,
  VideocastCard,
  DlgSharePost,
  DlgConfirm,
  DlgComment,
  PopMenuPostMod,
  SummaryCard,
  SimilarArticles,
  GenerateSummary,
  PopularCard
} from "components";
import {
  ARTICLE_BRANCH_NEWSPAPER,
  ARTICLE_BRANCH_YOUTUBE,
  ARTICLE_BRANCH_TWITTER,
  ARTICLE_BRANCH_INSTAGRAM,
  ARTICLE_BRANCH_TWITCH,
  ARTICLE_BRANCH_MEDIUM,
  ARTICLE_BRANCH_REDDIT,
  ARTICLE_BRANCH_HACKERNEWS,
  ARTICLE_BRANCH_SLASHDOT,
  ARTICLE_BRANCH_PODCAST,
  ARTICLE_BRANCH_RSSFEED,
  ARTICLE_BRANCH_TIKTOK,
  ARTICLE_BRANCH_PINTEREST,
  ARTICLE_BRANCH_VSCO,
  ARTICLE_BRANCH_SPOTIFY,
  ARTICLE_BRANCH_QUORA,
  ARTICLE_BRANCH_FACEBOOK,
  ARTICLE_BRANCH_SUBSTACK,
  ARTICLE_BRANCH_GMAIL,
  ARTICLE_BRANCH_VIMEO,
  ARTICLE_BRANCH_WEBSITE,
  ARTICLE_BRANCH_MASTODON,
  ARTICLE_BRANCH_BLUESKY,
  ARTICLE_BRANCH_PROFILEPOST,
  ARTICLE_BRANCH_USERPOST,
  ARTICLE_BRANCH_YOUTUBE_SUMMARY,
  ARTICLE_BRANCH_PODCAST_SUMMARY,
} from "constants/branches";
import * as ROUTES from "constants/routes";
import {
  isBannedPosts
} from "dataapis";
import { 
  ToastSuccess, 
  ToastError 
} from "utility/toast";
import { logger } from "utility/logging";
import {Mixpanel} from "services";
import * as MIXPANEL_EVENTS from "constants/mixpanel";
import { FEED_AI_COMMENT_FOR_CHOSEN } from "constants/types";
import { copy2clipboard } from "utility/utils";
 
const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
});

class ArticleMod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      deleteDlg: false,
      commentDlg: false,
      shareDlg: false
    };

    this.handleShare = this.handleShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);
    this.handleAnchorEl = this.handleAnchorEl.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);

    this.showDeleteDlg = this.showDeleteDlg.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.showCommentDlg = this.showCommentDlg.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDeleteSaved = this.handleDeleteSaved.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.handlePin = this.handlePin.bind(this);
    this.handleMoveTop = this.handleMoveTop.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleAIComment = this.handleAIComment.bind(this);
    this.handleClusters = this.handleClusters.bind(this);
  }

  handleShare = () => {
    const { article } = this.props;
    const properties = {
      type: 'Article',
      info: {
        nid: article.nid,
        title: article.tr_title !== '' ? article.tr_title : article.title,
        source_id: article.source_id
      }
    } 
    Mixpanel.track(MIXPANEL_EVENTS.SHARE, properties); 

    this.setState({
      ...this.state,
      shareDlg: true,
    });
  };

  handleCloseShare() {
    this.setState({
      shareDlg: false,
    });
  }

  handleAnchorEl = (anchorEl) => {
    this.setState({
      ...this.state,
      anchorEl: anchorEl,
    });
  };

  handleMenuClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  showDeleteDlg = (show) => {
    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    this.setState({
      ...this.state,
      deleteDlg: show,
      anchorEl: null,
    });
  };

  handleDelete = () => {
    const { article } = this.props;
    this.showDeleteDlg(false);
    this.props.onDelete(article);
  };

  handleEdit = () => {
    const { article } = this.props;
    this.setState({
      ...this.state,
      anchorEl: null
    });
    this.props.onEdit(article);
  }

  showCommentDlg = (show) => {
    if (isBannedPosts()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    this.setState({
      ...this.state,
      commentDlg: show,
      anchorEl: null,
    });
  };

  handleComment = (content) => {
    this.showCommentDlg(false);
    
    if (isBannedPosts()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    logger.log("comment :", content);
    const { article } = this.props;
    this.props.onComment(article, content);
  };

  handleSave = () => {
    const { article } = this.props;
    this.setState({
      ...this.state,
      anchorEl: null
    });
    this.props.onSave(article);
  }

  handleDeleteSaved = () => {
    const { article } = this.props;
    this.setState({
      ...this.state,
      anchorEl: null
    });
    this.props.onDeleteSaved(article);
  }

  handleCopyLink = () => {
    const { article } = this.props;
    
    let url = "";
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      url = `${document.location.origin}/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      url = `${document.location.origin}/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }

    copy2clipboard(url);

    this.setState({
      ...this.state,
      anchorEl: null
    });
    ToastSuccess("Copied to clipboard");
  };

  handlePin = () => {
    this.handleMenuClose();

    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    const { article } = this.props;
    this.props.onPin(article);
  }

  handleMoveTop = () => {
    this.handleMenuClose();
    
    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    const { article } = this.props;
    this.props.onMoveTop(article);
  }

  handleLogin = () => {
  }
  setError = message => {
    ToastError(message);
  };
  _getAuthToken = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    let token = authUser.token;
    if (Date.now() >= authUser.expiredTS) {
      const result = await this.props.firebase.refreshToken();
      if (result.error) {
        this.setError(result.msg);
        token = null;
      } else {
        token = result.token;
      }
    }
    return token;
  };
  handleAIComment = () => {
    this.handleMenuClose();
    const { article } = this.props;
    this.props.onCreateAIComment(article);
  };

  handleClickUpvote = () => {
    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    const { article } = this.props;
    this.props.onClickUpvote(article);
  }

  handleClickComment = () => {
    const { article } = this.props;
    this.props.onClickComment(article);
  }

  handleClickRepost = () => {
    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    const { article } = this.props;
    this.props.onClickRepost(article);
  }
  
  handleClusters = (article) => {
    if(article.article) {
      article = article.article
    } 
    this.props.handleClick(article);
  }
   
  render() {
    const { 
      classes, 
      theme,
      loggedIn,
      authUser,
      selected_feed,
      sources,
      article, 
      saved,
      handleClick, 
      handleGroupId, 
      onClickSource, 
      onClickFeed,
      userProfile,
      popular
    } = this.props;
    const { 
      anchorEl, 
      deleteDlg, 
      commentDlg,
      shareDlg 
    } = this.state;
    // popup menu position
    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    // validation check
    if (article.source_id !== null) {
      let source = sources.find((item) => item.id === article.source_id);
      if (!source) {
        if (article.source_id === `${authUser.uid}-selfpost` || userProfile) {
          source = article.source
        } else {
          logger.error("Error, Unknown article source! :", article.source_id);
          return <div style={{display: "none"}}></div>;
        }
      }
    }

    return (
      <div className={classes.root}>
        {article.type === 1 &&
          article.branch !== ARTICLE_BRANCH_YOUTUBE_SUMMARY &&
          article.branch !== ARTICLE_BRANCH_PODCAST_SUMMARY && (
          <SummaryCard
            userProfile={userProfile}
            article={article}
            search_mode={false}
            handleClick={handleClick}
            handleGroupId={handleGroupId}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_NEWSPAPER && (
          <NewsCard
            userProfile={userProfile}
            article={article}
            search_mode={false}
            handleClick={handleClick}
            handleGroupId={handleGroupId}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_REDDIT && (
          <RedditCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
            
          />
        )}
        {article.branch === ARTICLE_BRANCH_YOUTUBE && (
          <YoutubeCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
       
         {article.branch ===ARTICLE_BRANCH_YOUTUBE_SUMMARY && (
          <GenerateSummary
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
          
        )}
         {article.branch ===ARTICLE_BRANCH_PODCAST_SUMMARY &&(
            <GenerateSummary
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_TWITTER && (
          <TwitterCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_MEDIUM && (
          <MediumCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_RSSFEED && (
          <RssCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_PODCAST && (
          <PodcastCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_HACKERNEWS && (
          <HackernewsCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_SLASHDOT && (
          <SlashdotCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_TWITCH && (
          <TwitchCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_INSTAGRAM && (
          <InstagramCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_VSCO && (
          <VscoCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_PINTEREST && (
          <PinterestCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_TIKTOK && (
          <TiktokCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_SPOTIFY && (
          <SpotifyCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_QUORA && (
          <QuoraCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_FACEBOOK && (
          <FacebookCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_SUBSTACK && (
          <SubstackCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_GMAIL && (
          <GmailCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_VIMEO && (
          <VimeoCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_WEBSITE && (
          <WeblinkCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_MASTODON && (
          <MastodonCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_BLUESKY && (
          <BlueskyCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_PROFILEPOST && (
          <ProfilepostCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_USERPOST && !article.media_url && (
          <UserpostCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_USERPOST && article.media_url && (
          <VideocastCard
            userProfile={userProfile}
            article={article}
            handleClick={handleClick}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
          />
        )}
        
        {article.home_cluster_members && article.home_cluster_members.length>0 && 
          <SimilarArticles
            article= {article}
            theme_mode={theme}
            loggedIn={loggedIn}
            similarArticles={article.home_cluster_members}
            sources={sources}
            from='article'
            onClickArticle={this.handleClusters}
          />}
          
        <Menu
          id="source-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleMenuClose}
        >
          <PopMenuPostMod
            theme={theme}
            saved={saved}
            pinned={article.pinned !== undefined && article.pinned === true}
            moved={article.moved !== undefined && article.moved === true}
            owner={article.branch === ARTICLE_BRANCH_USERPOST && loggedIn && authUser && article.author === authUser.username}
            onDelete={e => this.showDeleteDlg(true)}
            onEdit={this.handleEdit}
            onComment={e => this.showCommentDlg(true)}
            onCopyLink={this.handleCopyLink}
            onPin={this.handlePin}
            onMoveTop={this.handleMoveTop}
            onSave={this.handleSave}
            onDeleteSaved={this.handleDeleteSaved}
            aiCommentConfig={selected_feed?.ai_comment_conf === FEED_AI_COMMENT_FOR_CHOSEN}
            onCreateAIComment={this.handleAIComment}
          />
        </Menu>
        <DlgConfirm
          open={deleteDlg}
          title={"Delete Post"}
          content={"Are you sure you want to delete this post?"}
          onOK={this.handleDelete}
          onCancel={e => this.showDeleteDlg(false)}
        />
        <DlgComment
          open={commentDlg}
          theme={theme}
          onClose={e => this.showCommentDlg(false)}
          onSubmit={this.handleComment}
        />
        {article.source_id&&
          <DlgSharePost
            open={shareDlg}
            post={article}
            onLogin={this.handleLogin}
            onClose={this.handleCloseShare}
          />
        }
        {article.nid&&
          <DlgSharePost
            open={shareDlg}
            post={article}
            onLogin={this.handleLogin}
            onClose={this.handleCloseShare}
          />
        }
      </div>
    );
  }
}

ArticleMod.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  loggedIn: PropTypes.bool,
  authUser: PropTypes.object,
  article: PropTypes.object,
  saved: PropTypes.bool, 
  onDelete: PropTypes.func,
  onComment: PropTypes.func,
  onPin: PropTypes.func,
  onMoveTop: PropTypes.func,
  onSave: PropTypes.func,
  onDeleteSaved: PropTypes.func,
  handleClick: PropTypes.func,
  handleGroupId: PropTypes.func,
  onClickSource: PropTypes.func, 
  onClickFeed: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
  onCreateAIComment: PropTypes.func 
};

const mapStateToProps = (state) => ({
  sources: state.dataState.sources,
  selected_feed: state.dataState.selected_feed,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withStyles(styles)
)(ArticleMod);
