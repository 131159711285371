export const QUERY_MAPLOCATION_ROOMS_BY_LOCATION = `
query location_rooms_by_location(
  $location_id: Int!
) {
  location_rooms(
    where: {
      location_id: {_eq: $location_id}}
  ) {
    id
    location_id
    name
    created_at
    created_by
  }
}`;

export const MUTATION_INSERT_MAPLOCATION_ROOM = `
mutation insert_location_room(
    $location_id: Int!,
    $name: String!,
    $created_by: String!
) {
  insert_location_rooms(
    objects: {
      location_id: $location_id, 
      name: $name,
      created_by: $created_by
    }
  ) {
    affected_rows
    returning {
      id
      location_id
      name
      created_at
      created_by
    }
  }
}`;

export const MUTATION_DELETE_MAPLOCATION_ROOM = `
mutation delete_location_room(
  $id: Int!
) {
  delete_location_rooms(
    where: {
      id: {_eq: $id}
    }
  ) {
    affected_rows
  }
}`;
