import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { withFirebase } from 'services';
import { withAuthentication } from "session";
import { ToastContainer } from "react-toastify";
import {
  BasicAppBar,
  WaitingSpinner,
} from "components";
import {
  VideocastList
} from './components';
import {
  BRANCH_ALL,
} from "constants/branches";
import * as ROUTES from "constants/routes";
import { ALL } from "constants/country";
import {
  MAX_ARTICLE_WIDTH,
} from "constants/types";
import { GraphqlService } from "services";
import { 
  getMainInfo,
  reportArticle, 
  saveArticle
} from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 10,
  },
  container: {
    marginTop: theme.spacing(1),
    margin: 0,
  },
  feedscontainer: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    margin: theme.spacing(2),
  },
  feeds_title: {
    fontSize: 14,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  articlecontainer: {
    padding: theme.spacing(1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.default,
  },
});

class Videocasts extends React.Component {
  constructor(props) {
    super(props);

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleNeedMore = this.handleNeedMore.bind(this);

    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  async componentDidMount() {
    this.setWaiting(true);

    await getMainInfo();

    // load videocasts
    if (this.props.videocasts.length === 0) {
      this.props.refreshVideocasts();
      await this.getVideocasts();
    }

    this.setWaiting(false);
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  getVideocastFeedIds2show = () => {
    const {
      loggedIn,
      default_feeds,
      followed_feeds,
    } = this.props;

    let feeds = default_feeds;
    if (loggedIn && followed_feeds.length > 0) {
      feeds = followed_feeds.slice();
    }

    const feed_ids = feeds.map(feed => feed.id);
    return feed_ids;
  };

  getVideocasts = async () => {
    const feed_ids = this.getVideocastFeedIds2show();
    if (feed_ids.length === 0) {
      return;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .videocasts_all_in_feeds(feed_ids)
      .then(
        (result) => {
          const feed_videocasts = result.data.feed_videocasts;
          if (feed_videocasts.length > 0) {
            const videocasts = feed_videocasts.map(feed_videocast => feed_videocast.article);
            logger.log("Videocasts => videocast articles in feeds(first page) :", videocasts);
            this.props.setVideocasts(videocasts, videocasts.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  }

  getVideocastsOfFirstPage = async () => {
    const feed_ids = this.getVideocastFeedIds2show();
    logger.log("videocasts feed ids :", feed_ids);
    if (feed_ids.length === 0) {
      return;
    }
    await this.getVideocastArticlesOfFirstPage(feed_ids);
  };

  handleNeedMore = async () => {
    const feed_ids = this.getVideocastFeedIds2show();
    if (feed_ids.length === 0) {
      return;
    }
    this.setWaiting(true);
    await this.getVideocastArticlesOfNextPage(feed_ids, this.props.videocast_last_offset);
    this.setWaiting(false);
  };

  getVideocastArticlesOfFirstPage = async (feed_ids) => {
    const gqlservice = new GraphqlService();
    await gqlservice
      .videocasts_in_feeds(feed_ids, 0)
      .then(
        (result) => {
          const feed_videocasts = result.data.feed_videocasts;
          if (feed_videocasts.length > 0) {
            const videocasts = feed_videocasts.map(feed_videocast => feed_videocast.article);
            logger.log("Videocasts => videocast articles in feeds(first page) :", videocasts);
            this.props.setVideocasts(videocasts, videocasts.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getVideocastArticlesOfNextPage = async (feed_ids, last_offset) => {
    const gqlservice = new GraphqlService();
    await gqlservice
      .videocasts_in_feeds(feed_ids, last_offset)
      .then(
        (result) => {
          const feed_videocasts = result.data.feed_videocasts;
          if (feed_videocasts.length > 0) {
            const videocasts = feed_videocasts.map(feed_videocast => feed_videocast.article);
            logger.log("Videocasts => videocast articles in feeds(next page) :", videocasts);
            this.props.appendVideocasts(videocasts, last_offset + videocasts.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.selectSource(null);
  };

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  };

  // empty funciton
  handleDeleteSavedArticle = (article) => {};
  // empty function

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleReportArticle = async (article, reportMsg) => {
    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };

  render() {
    const {
      classes,
      videocasts,
      requesting,
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Videocasts"}
            width={MAX_ARTICLE_WIDTH}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.articlecontainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <VideocastList
                videocasts={videocasts}
                onEndedLastVideo={this.handleNavBack}
                // onNeedMore={this.handleNeedMore}
                // onSelectArticle={this.handleSelectArticle}
                // onClickSource={this.handleClickSourceInArticle}
                // onClickFeed={this.handleClickFeed}
              />
            </Grid>
          </Grid>
        </div>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

Videocasts.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  feeds: state.dataState.feeds,
  selected_feed: state.dataState.selected_feed,
  default_feeds: state.dataState.default_feeds,
  followed_feeds: state.dataState.followed_feeds,
  videocasts: state.dataState.videocasts,
  videocast_last_offset: state.dataState.videocast_last_offset,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Videocasts);
