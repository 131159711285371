export const QUERY_MAPTAGS = `
query maptags{
  name
  maptag_categories {
    category_id
  }
}`;

export const QUERY_MAPTAG_CATEGORY_BY_CATEGORY = `
query maptag_category_by_category (
  $category_id: Int!
) {
  maptag_category (
    where: {
      category_id: {_eq: $category_id}
    }
  ) {
    id
    tag_name
    category_id
  }
}`;
