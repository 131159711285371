export const MUTATION_INSERT_AI_PERSONS = `
mutation insert_ai_persons ( 
    $user_id: String!, 
    $feed_id: String!,
    $seed_phrase : String!
    $is_owner:Boolean!
) {
  insert_ai_persons(
    objects: { 
      user_id: $user_id, 
      feed_id: $feed_id, 
      seed_phrase :$seed_phrase
      is_owner:$is_owner
    }
  ) {
    affected_rows
    returning{
      id
    }
  }
}`;

export const QUERY_GET_AI_PERSONS = `
query get_ai_persons (
  $feed_id: String!
) {
  ai_persons (
    where: {
      _and: [
        { feed_id: {_neq: $feed_id} }
        { is_owner: {_eq: true} }
      ]
    },
    order_by: {created_at: desc}
  ) {
    id
    feed_id
    seed_phrase
    user_id
    is_owner
    created_at
    user{
      name
      biography
      image
      username
    }
    feed{
      id
      category{
        emoji
        id
      }
    }
  }
}`;

export const QUERY_AI_PERSONS_BY_FEED_ID = `
query ai_persons_by_feed_id (
  $feed_id: String!
) {
  ai_persons (
    where: {
      feed_id: {_eq: $feed_id}
    },
    order_by: {created_at: desc}
  ) {
    id
    user_id
    seed_phrase
    user{
      name
      biography
      image
    }
  }
}`;

export const MUTATION_DELETE_AI_PERSON = `
mutation delete_ai_persons(
  $id: Int!
) {
  delete_ai_persons(
    where: {
      id: {_eq: $id}
    }
  ) {
    affected_rows
    returning {
      user_id
    }
  }
}`;

export const MUTATION_DELETE_ASSOCIATIVE_AI_PERSONS_FROM_ASSOCIATIVE_FEEDS =`
mutation delete_ai_persons(
  $user_id: String!
) {
  delete_ai_persons(
    where: {
      user_id: {_eq: $user_id}
    }
  ) {
    affected_rows
    returning {
      user_id
    }
  }
}`

export const MUTATION_UPDATE_AI_USER = `
mutation (
  $uid: String!,
  $name: String, 
  $biography: String,
  $image: String, 
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      name: $name, 
      biography: $biography,
      image: $image,
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image 
      created_at
    }
  }
}`;

export const MUTATION_UPDATE_AI_PERSONAS = `
mutation (
  $id: Int!,
  $seed_phrase: String!,  
) {
  update_ai_persons(
    where: {
      id: {_eq: $id}
    }, 
    _set: {
      seed_phrase: $seed_phrase,  
    }
  ) {
    affected_rows
    returning {
      id
      seed_phrase
      user_id
      user{
        name
        biography
        image
      }
    }
  }
}`;

export const  MUTATION_UPDATE_ASSOCIATE_AI_PERSONS = `
mutation (
  $user_id: String!,
  $seed_phrase: String!,  
) {
  update_ai_persons(
    where: {
      user_id: {_eq: $user_id}
    }, 
    _set: {
      seed_phrase: $seed_phrase,  
    }
  ) {
    affected_rows
    returning {
      id
      seed_phrase
      user_id
      user{
        name
        biography
        image
      }
    }
  }
}`;