import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Grid,
  Button,
  IconButton
} from "@material-ui/core";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { get_timestring, decodeHTMLEntities, render_text,int2kstring} from "utility/utils";
import {
  MIN_CARD_WIDTH,
  MAX_ARTICLE_WIDTH,
  THEME_MODE_DARK,
  
  SUMMARY_IMAGE,
} from "constants/types";
import { ARTICLE_BRANCH_YOUTUBE_SUMMARY } from "constants/branches";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  card: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_ARTICLE_WIDTH,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 10,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.default,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 50,
    height: 50,
    padding:2
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  socialimg: {
    position: "absolute",
    top: 52,
    left: 14,
    width: 20,
    height: 20,
  },
  
  titleline: {
    position: "relative",
    marginLeft: 40,
    marginRight: 10,
    margin: 10,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  upvotebtn: {
    position: "absolute",
    top: 80,
    left: 12,
    width: 20,
    height: 20,
    zIndex: 100,
  },
  buttonimg: {
    width: 20,
    height: 20,
  },
  author: {
    fontFamily: "Arial",
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  date: {
    float: "right",
    fontSize: "12px",
    color: theme.palette.text.secondary,
    marginTop: 1,
    marginBottom: 1,
    marginRight: theme.spacing(2),
  },
  media: {
    display: "block",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    margin: theme.spacing(1),
  },
  content: {
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 40,
    marginRight: 10,
    marginBottom: 2,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  detail_txt: {
    display: "inline",
    overflowWrap: "break-word",
    width: "calc(100% - 56px)",
  },
  reaction: {
    left: "50%",
    transform: "translate(-50%)",
    fontSize: 12,
    textTransform: "none",
    padding: 0,
    marginBottom: 4,
    color: theme.palette.text.primary,
  },
  reactionimg: {
    float: "left",
    width: 16,
    height: 16,
  },
  reactionvalue: {
    float: "left",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontFamily: "Arial",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  actionbtn: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 12,
    marginRight: 5,
    textTransform: "inherit",
    color: theme.palette.text.secondary,
  },
  translated_div: {
    height: 16,
    width: 250,
    marginLeft: 10,
  },
  traslated_icon: {
    float: "left",
    top: 3,
    marginLeft: 5,
    width: 16,
    height: 16,
    color: theme.palette.text.primary,
  },
  translated_txt: {
    display: "inline",
    marginLeft: 2,
    fontSize: 12,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  description_title: {
    display: "block",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "14px",
    letterSpacing: "0.66px",
    marginBottom: theme.spacing(1),
  },
});

class GenerateSummaryView extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes,article, theme_mode, loggedIn, authUser, onClickUpvote } = this.props;

    // check validity
    // let source = sources.find((item) => item.nid === article.nid);   //source_id
    // if (!source) {
    //   // logger.error("Error, Unknown article source!");
    //   return <div style={{display: "none"}}></div>;
    // }
    var title = "";
    var text = "";
    if (article.translated) {
      title = article.tr_title;
      // omit the last character(.)
      title = title.slice(0, title.length - 1);
      text = article.tr_text;
    } else {
      title = article.title;
      text = article.text;
    }

    if (!text) {
      text = article.summary;
    }
    let voted = false;
    if (loggedIn && authUser) {
      voted =
        authUser.articles_voted.find(
          (voted) => voted.article_id === article.nid
        ) !== undefined;
    }

    let image="";
    if(article.branch===ARTICLE_BRANCH_YOUTUBE_SUMMARY){
      image=`/static/images/icons/${theme_mode}/youtube.png`;
    }
    else {
      image=`/static/images/icons/${theme_mode}/podcast.png`
    }
    

    // calculate like, dislike
   const ratingCount = parseInt(article.param1);
   const ratingAverage = parseFloat(article.param2 / 100);

   let likeVal = (ratingCount * ratingAverage) / 5.0;
   likeVal = ~~likeVal;
   let dislikeVal = ratingCount - likeVal;

   const like = int2kstring(likeVal);
   const dislike = int2kstring(dislikeVal);
    // author
    let author = article.author;
    // publish time
    let published = get_timestring(article.published);
    let width =
      document.documentElement.clientWidth || document.body.clientWidth;
    if (width > MAX_ARTICLE_WIDTH) width = MAX_ARTICLE_WIDTH;
    if (width < MIN_CARD_WIDTH) width = MIN_CARD_WIDTH;

    return (
      <div className={classes.root}>
        <Card className={classes.card} style={{ width: width - 16 }}>
          <div className={classes.carddiv}>
            <CardHeader
              className={classes.header}
              avatar={
                <div>
                  <Avatar
                    alt={author}
                    src={SUMMARY_IMAGE}
                    className={classes.avatar}
                    classes={{ img: classes.avatar_img }}
                    style={{backgroundColor:"#10a37f",padding:6}}
                  />
                  <img
                    alt={"image"}
                    src={image}
                    className={classes.socialimg}
                    style={{marginTop: 4}}
                  />
                </div>
              }
              title={
                <div className={classes.titleline}>
                  <Typography className={classes.author}>
                    {decodeHTMLEntities(article.author)}
                  </Typography>
                  <Typography className={classes.description_title}
                style={{marginTop:"10px"}}>
                {/* <i>Summary of</i> */}
                </Typography>
                  <Typography className={classes.title} variant="subtitle1">
                    {render_text(title, theme_mode === THEME_MODE_DARK)}
                  </Typography>
                  <Typography className={classes.date}>{published}</Typography>
                </div>
              }
            />
                      <div>
            <IconButton className={classes.upvotebtn} onClick={onClickUpvote}>
              <img
                alt={"upvote"}
                src={
                  voted
                    ? `/static/images/icons/thumbsup.png`
                    : `/static/images/icons/${theme_mode}/thumbsup.png`
                }
                className={classes.buttonimg}
              />
            </IconButton>
           
          </div>
            <CardContent className={classes.content}>
              <Typography className={classes.detail_txt} variant="body2">
                {render_text(article.text, theme_mode === THEME_MODE_DARK)}
              </Typography>
            </CardContent>
             {article.image && (
              <LazyLoadImage
                alt={""}
                src={article.image}
                className={classes.media}
              />
            )}
            <CardActions />
            {/* <CardActions>
              <Button
                className={classes.reaction}  
                size="small"
                color="primary"
                target="_blank"
              >
                <img
                  alt={"likes"}
                  src={`/static/images/icons/${theme_mode}/like.png`}
                  className={classes.reactionimg}
                />
                <Typography className={classes.reactionvalue}>
                  {like}
                </Typography>
                <img
                  alt={"dislikes"}
                  src={`/static/images/icons/${theme_mode}/dislike.png`}
                  className={classes.reactionimg}
                />
                <Typography className={classes.reactionvalue}>
                  {dislike}
                </Typography>
              </Button>
            </CardActions> */}
           
          </div>
          
        </Card>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          direction="row"
        >
          <Button
            className={classes.actionbtn}
            size="small"
            color="primary"
            target="_blank"
            href={article.url}
          >
            {article.branch === ARTICLE_BRANCH_YOUTUBE_SUMMARY ? "View original video on Youtube" : "View original podcast"}
          </Button>
        </Grid>
      </div>
    );
  }
}

GenerateSummaryView.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
  onClickUpvote: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GenerateSummaryView));
