import localforage from "localforage";
import { logger } from "./logging";

const CACHE_TIMEOUT = 14400 // 4 hours

export const get_cached_item = async (name) => {
    try {
        const now_ts = Math.floor(Date.now() / 1000);
        const cache_value = await localforage.getItem(name);
        if (cache_value && cache_value.ts) {
            if (now_ts - cache_value.ts > CACHE_TIMEOUT) {
                logger.log("cache timeout :", cache_value)
                return null;
            } else {
                logger.log("cache :", cache_value.value);
                return cache_value.value
            }
        } else {
            logger.log("cache missing :", name);
            return null;
        }
    } catch (err) {
        logger.log("get cache error :", err);
        return null;
    }
}

export const set_cache_item = async (name, item) => {
    try {
        const now_ts = Math.floor(Date.now() / 1000);
        const cache_value = {
            value: item,
            ts: now_ts
        }
        await localforage.setItem(name, cache_value);
        logger.log("value is cached :", cache_value)
    } catch (err) {
        logger.log("set cache error :", err);
    }
}