import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import Masonry from "react-masonry-component";
import { 
  FollowedCommentCard,
  SearchArticle,
 
} from "components";
import _ from "lodash";  
import BlueskySharedPosts from "../BlueskySharedPosts/BlueskySharedPosts";


const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  article: {
    margin: 2,
    backgroundColor: theme.palette.background.default
  },
  listitem: {
    padding: 0,
  },
});

const masonryOptions = {
  transitionDuration: 0
};

class BlueskyShared extends React.Component {
  constructor(props) {
    super(props);

    
    this.listenToScroll = this.listenToScroll.bind(this);
  }

  componentDidMount() {
      window.addEventListener("scroll", this.listenToScroll);
      window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);
    }
  
    componentWillUnmount() {
      window.removeEventListener("scroll", this.listenToScroll);
    }

  
  listenToScroll(event) {
    
    event.preventDefault();
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = winScroll * 1.0 / height;

    if (scrolled === 1.0 || scrolled >= 0.99) {
      // Ensure onNeedMore is a function before calling it
      if (typeof this.props.onNeedMore === "function") {
        this.props.onNeedMore();
      }
    }
  }
  
  render() {
    
    const { 
      classes, 
      width, 
      posts,
      
    } = this.props; 
    
   
    
    return (
      <div className={classes.root} style={{ width: width }}>
        <Masonry style={{width:"auto"}} options={masonryOptions} disableImagesLoaded={false}>
          {posts?.map((posts) => (
            <div className={classes.article} key={posts.id}>
              <BlueskySharedPosts
                posts={posts}
                key={posts.id}
              />
            </div>
          ))}
        </Masonry>
      </div>
    );
  }
}

BlueskyShared.propTypes = {
  from: PropTypes.string,
  classes: PropTypes.object,
  width: PropTypes.number,
  saved: PropTypes.bool,
  onNeedMore: PropTypes.func,
};

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  scrollPos: state.uiState.scrollPos,
  theme_mode: state.uiState.theme_mode,
 
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(BlueskyShared);
