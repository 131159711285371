export const APP_ICONS = 'App Icons';
export const SHARE = 'Share';
export const ARTICLE_DETAILS = 'Article Details';
export const LOGIN = 'Login';
export const FEED_SOURCE_DETAILS = 'Feed Source Details';
export const FEED_DETAILS = 'Feed Details';
export const FOOTER_CLICKS = 'Footer clicks';
export const COUNTRY_CHANGE = 'Countrywise News Details';
export const CREATE_FEED = 'Create Feed';
export const MAP_LOCATION = 'Location Details';
export const MAP_LEADER_BOARDS = 'Leaderboards'; 
export const MAP_COMMENTS= 'Map Comments'; 
export const FEED_SETTINGS = 'Feed Settings';