import React, { Fragment } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { 
  Grid, 
  Fade, 
  Typography 
} from "@material-ui/core";
import MetaTags from "react-meta-tags";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import SwipeableViews from "react-swipeable-views";
import { ToastContainer } from "react-toastify";
import { withFirebase } from "services";
import { 
  withAuthentication, 
  withAuthorization 
} from "session";
import {
  BottomNavBar,
  InviteControl,
  WaitingSpinner,
  ArticleModList,
  ArticleModMasonry,
  DlgSuggestSource,
  DlgPost,
  DlgPostEdit,
  DlgThread,
  DlgConfirm,
  DlgWeblink,
} from "components";
import {
  MainAppBar,
  FeedsAppBar,
  FeedDetail,
  FeedTabs,
  PostButtons,
  SourceButtons,
  SourceList,
  CommentButtons,
  ThreadList,
  ModButtons,
  ModeratorList,
} from "./components";
import {
  BRANCH_ALL,
  ARTICLE_BRANCH_NEWSPAPER,
  ARTICLE_BRANCH_USERPOST,
  ARTICLE_BRANCH_YOUTUBE_SUMMARY,
  ARTICLE_BRANCH_PODCAST_SUMMARY,
} from "constants/branches";
import { ALL } from "constants/country";
import * as ROUTES from "constants/routes";
import {
  TAB_FEED,
  TAB_SOURCES,
  TAB_COMMENTS,
  TAB_MODERATORS,
  GRAPHQL_SUCCESS,
  MAX_WINDOW_WIDTH,
  MIN_TABLET_WIDTH,
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  THREAD_TYPE_ARTICLE,
  RAVEN_PLACEHOLDER_IMAGE,
  FEED_COMMENT_UNMODERATED,
  APPROVING_POSTS
} from "constants/types";
import {
  NOTIFICATION_FEED_APPROVE_MODERATOR,
} from "constants/notification";
import {
  ACTIVITY_TYPE_CATEGORY,
  ACTIVITY_TYPE_FEED,
  ACTIVITY_DELETE,
  ACTIVITY_APPROVE,
  ACTIVITY_MAKE,
  ACTIVITY_REPORT,
  ACTIVITY_PIN,
  ACTIVITY_MOVETOP,
  ACTIVITY_REMOVE,
  ACTIVITY_ADD,
} from "constants/activity";
import {
  GraphqlService,
  createCategorySubscriber,
  createFeedSubscriber,
  // createSourceSubscriber,
  createFeedModeratorSubscriber,
  createUserSubscriber,
  Mixpanel,
} from "services";
import { 
  getAuthToken,
  getMainInfo,
  isFeedModerator,
  getFeedInfo,
  getFeedModerates,
  getPinsAndMovetops,
  upvoteArticle,
  repostArticle,
  deleteArticle,
  updatePost,
  saveArticle,
  addThread,
  hasPaid,
  isBanned,
  isBannedFeedCreation,
  isBannedPosts,
  isBannedComments,
  isCommentEnabledFeed,
  approveFeed,
  getArticle,
  updateCache,
} from "dataapis";
import { 
  get_link_source,
  moderate_image,
  createAiComments,
  approveAiPosts,
  getFeedsInfo,
} from "utility/ravenapi";
import { 
  get_hash, 
  summarize_text, 
  is_source_alive, 
  copy2clipboard
} from "utility/utils";
import { resizeImageFile } from "utility/resizeimage";
import { 
  ToastSuccess, 
  ToastError, 
  ToastInfo 
} from "utility/toast";
import { logger } from "utility/logging";
import * as MIXPANEL_EVENTS from "constants/mixpanel";
import { get_cached_item, set_cache_item } from "utility/cachemanager";
import { updateFeedCache } from "dataapis/feed";
import{postToMastodon,postToBluesky}from "utility/ravenapi"
const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 1100,
  },
  maincontainer: {
    position: "sticky",
    top: "56px",
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
  },
  // invitecontainer: {
  //   marginTop: theme.spacing(1),
  //   marginBottom: theme.spacing(1),
  //   paddingRight: theme.spacing(2),
  // },
  addbutton: {
    position: "fixed",
    cursor: "pointer",
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    width: 60,
    height: 60,
    zIndex: 1200,
  },
  warningcontainer: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // textAlign: "center",
  },
  warningimg: {
    width: 48,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  warningicon: {
    width: 48,
    height: 48,
  },
  warningtitle: {
    width: 220,
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  warningtext: {
    width: 220,
    fontSize: 14,
    fontWeight: 400,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.text.secondary,
  },
  indicatoricon: {
    width: 16,
    height: 16,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  bottomspace: {
    paddingBottom: theme.spacing(10),
  },
});

class FeedModeration extends React.Component {
  constructor(props) {
    super(props);

    this.categoryConsumer = null;
    this.feedConsumer = null;
    this.sourceConsumer = null;
    this.feedModeratorConsumer = null;
    this.userConsumer = null;

    this.state = {
      searchKey: "",
      confirmDeleteDlg: false,
      confirmResignDlg: false,
      suggestSourceDlg: false,
      postDlg: false,
      webDlg:false,
      postEditDlg: false,
      article_edit: null,
      threadDlg: false,
      aiSummary: [],
      isPostsEmpty: true,
      loading:false
    };

    this.handleWheel = this.handleWheel.bind(this);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleClickBackButton = this.handleClickBackButton.bind(this);

    this.handleInviteMembers = this.handleInviteMembers.bind(this);
    this.handleClickMembers = this.handleClickMembers.bind(this);
    this.handleChangeFeedTab = this.handleChangeFeedTab.bind(this);
    this.handleSwiped = this.handleSwiped.bind(this);
    this.handleSwipScroll = this.handleSwipScroll.bind(this);

    this.handleClickSource = this.handleClickSource.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickSourceInArticle =
      this.handleClickSourceInArticle.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.handleClickUnfollowed = this.handleClickUnfollowed.bind(this);
    this.handleClickShowRetweet = this.handleClickShowRetweet.bind(this);
    this.handleCategoryIndicator = this.handleCategoryIndicator.bind(this);

    this.handleNeedMoreArticles = this.handleNeedMoreArticles.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeBranch = this.handleChangeBranch.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handlePinArticle = this.handlePinArticle.bind(this);
    this.handleCommentArticle = this.handleCommentArticle.bind(this);
    this.handleMoveTopArticle = this.handleMoveTopArticle.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);
    this.handleAICommentArticle = this.handleAICommentArticle.bind(this);

    this.handleEditFeed = this.handleEditFeed.bind(this);
    this.handleApproveFeed = this.handleApproveFeed.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.handleConfirmDeleteFeed = this.handleConfirmDeleteFeed.bind(this);
    this.handleDeleteFeed = this.handleDeleteFeed.bind(this);
    this.handleCancelDeleteFeed = this.handleCancelDeleteFeed.bind(this);
    this.handleConfirmResignFeed = this.handleConfirmResignFeed.bind(this);
    this.handleResignFeed = this.handleResignFeed.bind(this);
    this.handleCancelResignFeed = this.handleCancelResignFeed.bind(this);

    this.showAddPostDlg = this.showAddPostDlg.bind(this);
    this.showADlgWeb = this.showADlgWeb.bind(this);
    this.closePostDlg = this.closePostDlg.bind(this);
    this.handleSubmitPost = this.handleSubmitPost.bind(this);
    this.handleApprovePosts = this.handleApprovePosts.bind(this);

    this.handleEditSource = this.handleEditSource.bind(this);
    this.handleDeleteSource = this.handleDeleteSource.bind(this);
    this.handleAddSource = this.handleAddSource.bind(this);
    this.handleCancelSuggestSource = this.handleCancelSuggestSource.bind(this);
    this.goSourceAdd = this.goSourceAdd.bind(this);
    this.handleSourceSearch = this.handleSourceSearch.bind(this);

    this.handleFlaggedSources = this.handleFlaggedSources.bind(this);
    this.handleProposedSources = this.handleProposedSources.bind(this);

    this.handleFlaggedPosts = this.handleFlaggedPosts.bind(this);
    this.handleProposedPosts = this.handleProposedPosts.bind(this);

    this.handleNeedMoreThreads = this.handleNeedMoreThreads.bind(this);
    this.handlePendedThreads = this.handlePendedThreads.bind(this);
    this.handleFlaggedThreads = this.handleFlaggedThreads.bind(this);

    this.handleFlaggedModerators = this.handleFlaggedModerators.bind(this);
    this.handleReportModerator = this.handleReportModerator.bind(this);
    this.handleApproveModerator = this.handleApproveModerator.bind(this);
    this.handleDeleteModerator = this.handleDeleteModerator.bind(this);
    this.handleMakeOwner = this.handleMakeOwner.bind(this);

    this.handleViewLog = this.handleViewLog.bind(this);
    this.handleClickSettings = this.handleClickSettings.bind(this);

    this.showAddThreadDlg = this.showAddThreadDlg.bind(this);
    this.closeThreadDlg = this.closeThreadDlg.bind(this);
    this.handleSubmitThread = this.handleSubmitThread.bind(this);
    this.handleClickThread = this.handleClickThread.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    this.setState({
      ...this.state,
      loading: waiting
    }); 
  };

  handleWheel = (event) => {
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    if (height === 0) {
      this.props.showTopNavbar(true);
    }
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.goTo(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  componentDidMount = async () => {
    this.setWaiting(true);
    const { loggedIn, authUser, feeds } = this.props;
  
    if (!loggedIn) {
      const location = {
        pathname: ROUTES.SIGN_IN,
        state: { animation: "bottom" },
      };
      this.props.history.push(location);
      return;
    }

    const feed_slug = this.props.match.params.slug;
    if (feeds.length === 0) {
      await getMainInfo();
    }
    
    let feedData = []
    var params = {
      user: authUser.uid ? authUser.uid : null,
      feed: feed_slug
    }
    await getFeedsInfo(params)
    .then(result => {
      if (result.status === 200) {
        feedData = result.data;
        if (feedData.selectedFeed) {
          this.props.selectFeed(feedData.selectedFeed);
        }
        if ( feedData.articles.length === 0 &&
          feedData.feedPosts.length === 0 &&
          feedData.movetops.length === 0 &&
          feedData.pins.length === 0 &&
          feedData.searchTermSourceArticles.length === 0
          ) {
            this.setState({
              ...this.state,
              isPostsEmpty: false
            });
          }
      }
    })
    .catch(error => {
      console.error("Error fetching feed info:", error);
    });
    // await getFeedInfo(feed_slug);

    const { selected_feed } = this.props;
    if(!selected_feed){
      return
    }
    if (selected_feed.category_id === "trending") {
      this.props.history.push(ROUTES.DISCOVER);
      return;
    } else if (selected_feed.category_id === "deleted") {
      this.props.history.push(ROUTES.HOME);
      return;
    }

    if (!isFeedModerator(selected_feed)) {
      const location = {
        pathname: ROUTES.HOME,
        state: { animation: "right" },
      };
      this.props.history.push(location);
      this.setWaiting(false);
      return;
    }
    let feed = await getFeedModerates(selected_feed);
    if (!feed) {
      feed = selected_feed;
      feed.proposed_sources = [];
      feed.source_reports = [];
      feed.post_reports = [];
      feed.proposed_posts = [];
      feed.posted_posts = [];
      feed.threads = [];
      feed.thread_reports = [];
      feed.moderators = [];
      feed.moderator_reports = [];
      feed.notifications = [];
      feed.notif_date = new Date().toISOString();;
    }
    this.props.selectFeed(feed);
    //this.props.refreshThreads();

    const { categories } = this.props;
    const selectedCategory = categories.find(
      (category) => category.id === feed.category_id
    );
    this.props.selectCategory(selectedCategory);

    // this.registerSubscribers(loggedIn);

    if (this.props.feedtab === TAB_FEED) {
      const { articles_on_feed_moderation, articles_on_search_term_source } = this.props;
      // load pins & movetops
      if (feedData.pins.length > 0) {
        this.props.setArticlePins(feedData.pins)
      }
      if (feedData.movetops.length > 0) {
        this.props.setArticleMovetops(feedData.movetops)
      }
      // if (feedData.aiSummary.length > 0) {
      //   this.setState({
      //     ...this.state,
      //     aiSummary: feedData.aiSummary
      //   });
      // }
      // await getPinsAndMovetops([selected_feed?.id]);
      await this.getArticlesSummary();
      if (Object.keys(articles_on_search_term_source).filter((item)=>item.id === selected_feed.id).length === 0) {
        // await this.getSearchTermSourceArticlesOfFirstPage();
        if (feedData.searchTermSourceArticles.length > 0) {
          this.props.setArticlesOnSearchTermSource(feedData.searchTermSourceArticles, feedData.searchTermSourceArticles.length ,selected_feed?.id)
        }
      }
      if (Object.keys(articles_on_feed_moderation).filter((item)=>item.id === selected_feed.id).length === 0) {
      
        if (feedData.articles.length>0) {
          this.props.setArticlesOnFeedModeration(feedData.articles, feedData.articles.length, selected_feed.id)
        }
        if (feedData.feedPosts.length > 0) {
          this.props.setFeedPosts(feedData.feedPosts);
        }
        //   await this.getArticlesOfFirstPage();
      //   await this.getFeedPosts();
      } 
      if ((Object.keys(articles_on_search_term_source).filter((item)=>item.id === selected_feed.id).length === 0) && (Object.keys(articles_on_feed_moderation).filter((item)=>item.id === selected_feed.id).length === 0)) {
        this.props.initScrollPos();
      }else {
        window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);
      }
      this.props.showBottomNavbar(false);
    } else if (
      this.props.feedtab === TAB_COMMENTS &&
      isCommentEnabledFeed(selected_feed) &&
      this.props.threads.length === 0
    ) {
      await this.getThreadsInFirstPage();
    }

    this.setWaiting(false);
  };

  registerSubscribers = (loggedIn) => {
    const categorySubscriber = createCategorySubscriber(loggedIn);
    this.categoryConsumer = categorySubscriber.subscribe(
      (data) => {
        const categories = data.data.categories;
        this.props.setCategories(categories);
      },
      (err) => {
        let msg = "Error subscribing categories: " + err;
        logger.error(msg);
      }
    );

    const feedSubscriber = createFeedSubscriber(loggedIn);
    this.feedConsumer = feedSubscriber.subscribe(
      (data) => {
        const feeds = data.data.feeds;
        this.props.setFeeds(feeds);
      },
      (err) => {
        let msg = "Error subscribing feeds: " + err;
        logger.error(msg);
      }
    );

    // const sourceSubscriber = createSourceSubscriber(loggedIn);
    // this.sourceConsumer = sourceSubscriber.subscribe(data => {
    //   const sources = data.data.sources;
    //   logger.log("Sources updated :", sources);
    //   this.props.setSources(sources);
    // }, (err) => {
    //   let msg = "Error subscribing sources: " + err;
    //   logger.error(msg);
    // });

    const feedModeratorSubscriber = createFeedModeratorSubscriber(
      this.props.selected_feed.id
    );
    this.feedModeratorConsumer = feedModeratorSubscriber.subscribe(
      async (data) => {
        // const feedModerators = data.data.feed_moderators;
        // logger.log("Feed moderators updated :", feedModerators);

        const feed = await getFeedModerates(this.props.selected_feed);
        this.props.selectFeed(feed);
      },
      (err) => {
        let msg = "Error subscribing feed moderators: " + err;
        logger.error(msg);
      }
    );

    if (!loggedIn) {
      return;
    }
    const userSubscriber = createUserSubscriber(this.props.authUser.uid);
    this.userConsumer = userSubscriber.subscribe(
      (data) => {
        const user = data.data.users[0];
        logger.log("user updated :", user);
        this.props.setAuthUser(user);
      },
      (err) => {
        let msg = "Error subscribing user: " + err.message;
        logger.error(msg);
      }
    );
  };

  unregisterSubscribers = () => {
    if (this.categoryConsumer) {
      this.categoryConsumer.unsubscribe();
    }
    if (this.feedConsumer) {
      this.feedConsumer.unsubscribe();
    }
    if (this.sourceConsumer) {
      this.sourceConsumer.unsubscribe();
    }
    if (this.feedModeratorConsumer) {
      this.feedModeratorConsumer.unsubscribe();
    }
    if (this.userConsumer) {
      this.userConsumer.unsubscribe();
    }
  };

  goTo = (location) => {
    this.unregisterSubscribers();
    this.props.history.push(location);
  };

  getFeedPosts = async () => {
    const { selected_feed } = this.props;
    if(!selected_feed){
      return
    }
    this.props.setRequestGetFeedPosts(true);
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_posts(selected_feed.id)
      .then(
        (result) => {
          let posts = result.data.feed_posts;
          if (posts.length > 0) {
            posts = posts.map((post) => {
              post.article['feed_id'] = post.feed_id
              return post.article
            });
            logger.log("get feed posts :", posts);
            this.props.setFeedPosts(posts);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.props.setRequestGetFeedPosts(false);
        this.setError(JSON.stringify(err));
      });
    this.props.setRequestGetFeedPosts(false);
  };

  getThreadsInFirstPage = async () => {
    const { selected_feed } = this.props;

    let source_ids = selected_feed.feed_sources
      .filter((feed_source) => feed_source.approved)
      .map((feed_source) => feed_source.source_id);

    // this.setWaiting(true);

    const gqlservice = new GraphqlService();
    await gqlservice
      .threads_by_feeds(selected_feed.id, source_ids, null, 0)
      .then(
        (result) => {
          const threads = result.data.threads;
          logger.log("Feed => threads(first page) :", threads);
          if (threads.length > 0) {
            this.props.setThreads(threads, threads.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // this.setWaiting(false);
  };

  getThreadsInNextPage = async (last_offset) => {
    const { selected_feed } = this.props;

    let source_ids = selected_feed.feed_sources
      .filter((feed_source) => feed_source.approved)
      .map((feed_source) => feed_source.source_id);

    let approved = true;
    if (selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .threads_by_feeds(selected_feed.id, source_ids, approved, last_offset)
      .then(
        (result) => {
          const threads = result.data.threads;
          logger.log("Feed => threads(next page) :", threads);

          if (threads.length > 0) {
            this.props.appendThreads(threads, last_offset + threads.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleInviteMembers = () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/inviteuser`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleClickMembers = () => {
    const { selected_feed } = this.props;
    const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/members`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleChangeFeedTab = (tab_value) => {
    this.setState(
      {
        ...this.state,
        searchKey: "",
      },
      async () => {
        window.scroll(0, 0);
        this.props.selectFeedTab(tab_value);
        this.props.initScrollPos();

        const { selected_feed } = this.props

        if (tab_value === TAB_FEED) {
          await this.getArticlesSummary();
          const { articles_on_feed_moderation, articles_on_search_term_source } = this.props

          if (Object.keys(articles_on_feed_moderation).filter((item)=>item.id === selected_feed.id).length === 0) {
            await this.getArticlesOfFirstPage();
          }
          if (Object.keys(articles_on_search_term_source).filter((item)=>item.id === selected_feed.id).length === 0) {
            await this.getSearchTermSourceArticlesOfFirstPage();
          }
          
          this.props.showBottomNavbar(true);
        } else if (tab_value === TAB_SOURCES) {
          this.props.showBottomNavbar(false);
        } else if (tab_value === TAB_COMMENTS) {
          const { selected_feed } = this.props;
          if (
            isCommentEnabledFeed(selected_feed) &&
            this.props.threads.length === 0
          ) {
            await this.getThreadsInFirstPage();
          }
          this.props.showBottomNavbar(false);
        } else if (tab_value === TAB_MODERATORS) {
          this.props.showBottomNavbar(false);
        }
      }
    );
  };

  handleSwiped = (tabIndex) => {
    this.handleChangeFeedTab(tabIndex);
  };

  handleSwipScroll = (event) => {
    event.preventDefault();

    const { feedtab } = this.props;

    let element_id = "feed-swipeable-views-feeds";
    if (feedtab === TAB_SOURCES) {
      element_id = "feed-swipeable-views-sources";
    } else if (feedtab === TAB_COMMENTS) {
      element_id = "feed-swipeable-views-threads";
    } else if (feedtab === TAB_MODERATORS) {
      element_id = "feed-swipeable-views-moderators";
    }

    const domElement = document.getElementById(element_id).parentNode;
    const scrollTop = domElement.scrollTop;

    if (scrollTop > 50) {
      this.props.showTopNavbar(false);
    } else {
      this.props.showTopNavbar(true);
    }
  };

  handleClickSource = (source) => {
    const { selected_feed } = this.props;
    this.props.selectSource(source);
    this.props.setSourceBackRoute(
      `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`
    );
    const path = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.refreshArticlesOnFeedModeration();
    this.props.refreshFeedPosts();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickFeed = (feed) => {
    if (feed === this.props.selected_feed) {
      return;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setFeedBackRoute(ROUTES.HOME);
    this.props.refreshArticlesOnFeedModeration();
    this.props.refreshFeedPosts();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickSourceInArticle = (source, feed) => {
    this.props.selectSource(source);
    this.props.setSourceBackRoute(
      `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`
    );
    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.refreshArticlesOnFeedModeration();
    this.props.refreshFeedPosts();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickUpvote = async (article) => {
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }

    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  };

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  };

  handleClickRepost = async (article) => {
    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  };

  handleClickUnfollowed = async (source, unfollowed) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { authUser, selected_feed } = this.props;
    let unfollower = {
      id: uuidv4(),
      feed_id: selected_feed.id,
      source_id: source.id,
      user_id: authUser.uid,
    };

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    if (unfollowed) {
      await gqlservice
        .insert_feed_source_unfollower(unfollower)
        .then(
          (result) => {
            this.props.insertUnfollowingSource(unfollower);
          },
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });

      this.setWaiting(false);
      return;
    }

    await gqlservice
      .delete_feed_source_unfollower(selected_feed.id, source.id, authUser.uid)
      .then(
        (result) => {
          this.props.deleteUnfollowingSource(
            selected_feed.id,
            source.id,
            authUser.uid
          );
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleClickShowRetweet = async (source, showrt) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { authUser, selected_feed } = this.props;

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    if (showrt) {
      await gqlservice
        .insert_feed_source_showretweet(
          selected_feed.id,
          source.id,
          authUser.uid
        )
        .then(
          (result) => {
            this.props.insertShowRetweet(
              selected_feed.id,
              source.id,
              authUser.uid
            );
          },
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });

      this.setWaiting(false);
      return;
    }

    await gqlservice
      .delete_feed_source_showretweet(selected_feed.id, source.id, authUser.uid)
      .then(
        (result) => {
          this.props.deleteShowRetweet(
            selected_feed.id,
            source.id,
            authUser.uid
          );
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleCategoryIndicator = (category) => {
    const route = `/${ROUTES.CATEGORY_PREFIX}/${category.id}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleSearchChange = (searchKey) => {
    // this.setState({
    //   ...this.state,
    //   searchKey: searchKey
    // });
  };

  handleSearchEnter = (searchKey) => {
    this.props.updateSearchKey(searchKey);
    const location = {
      pathname: ROUTES.SEARCH_RESULT,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleNavBack = () => {
    const { feed_backroute } = this.props;
    const location = {
      pathname: feed_backroute,
      state: { animation: "right" },
    };
    this.goTo(location);

    this.props.refreshArticlesOnFeedModeration();
    this.props.refreshFeedPosts();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.selectFeed(null);
    this.props.selectSource(null);
  };

  handleClickBackButton = () => {
    this.props.initScrollPos();
    window.scrollTo(0, 0);
  };

  getArticlesSummary = async () => {
    const { selected_feed } = this.props;
    if(!selected_feed){
      return
    }
    if (selected_feed?.ai_summary_conf === 0) {
      return;
    }
    this.props.setRequestGetArticleSummary(true);
    const source_ids = this.getSourceIdsToShow();
    if (source_ids?.length === 0) {
      this.props.refreshArticlesOnFeedModeration();
      this.props.setRequestGetArticleSummary(false);
      return;
    }
    const gqlservice = new GraphqlService();
    await gqlservice
      .articles_summary(source_ids)
      .then(
        (result) => {
          const summary = result.data;
          this.setState({
            ...this.state,
            aiSummary: summary,
          });

          // this.props.setArticles(articles, articles.length);
          // this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.props.setRequestGetArticleSummary(false);
        this.setError(JSON.stringify(err));
      });
      this.props.setRequestGetArticleSummary(false);
  };

  getArticlesOfFirstPage = async () => {
    const { branch, country } = this.props;

    if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      if (country === ALL) {
        await this.getArticlesOfBranchInFirstPage(branch);
      } else {
        await this.getArticlesOfCountryInFirstPage(country);
      }
    } else {
      if (branch === BRANCH_ALL) {
        await this.getArticlesInFirstPage();
      } else {
        await this.getArticlesOfBranchInFirstPage(branch);
      }
    }
  };

  handleNeedMoreArticles = async () => {
    const { 
      selected_feed, 
      country, 
      branch, 
      articles_on_feed_moderation, 
      articles_on_search_term_source,
      requesting,
    } = this.props;

    let last_offset_on_feed_moderation = articles_on_feed_moderation[selected_feed?.id]?.last_offset_on_feed_moderation ?articles_on_feed_moderation[selected_feed?.id]?.last_offset_on_feed_moderation : 0;
    let last_offset_on_search_term_source_articles = articles_on_search_term_source[selected_feed?.id]?.last_offset_on_search_term_source_articles ?articles_on_search_term_source[selected_feed?.id]?.last_offset_on_search_term_source_articles : 0;
    if (last_offset_on_feed_moderation === 0 && last_offset_on_search_term_source_articles === 0) {
      this.props.showBottomNavbar(false);
      return;
    }
    if (requesting) {
      return;
    }

    this.setWaiting(true);

    if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      if (country === ALL) {
        await this.getArticlesOfBranchInNextPage(branch, last_offset_on_feed_moderation);
      } else {
        await this.getArticlesOfCountryInNextPage(country, last_offset_on_feed_moderation);
      }
    } else {
      if (branch === BRANCH_ALL) {
        await this.getArticlesInNextPage(last_offset_on_feed_moderation);
        // await this.getSearchTermSourceArticlesInNextPage(last_offset_on_search_term_source_articles);
      } else {
        await this.getArticlesOfBranchInNextPage(branch, last_offset_on_feed_moderation);
      }
    }

    this.setWaiting(false);
  };

  handleSelectArticle = (article) => {
    this.props.selectArticle(article);
    const { selected_feed, sources } = this.props;

    if (article.source_id !== `${selected_feed.id}-userpost`) {
      if(
        article.branch === ARTICLE_BRANCH_YOUTUBE_SUMMARY ||
        article.branch === ARTICLE_BRANCH_PODCAST_SUMMARY
    ) {
        var pathName = `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
        const location = {
          pathname: pathName,
          state: { animation: "left" },
        };
        this.goTo(location);
        // this.props.setArticleBackRoute(
        //   `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`
        // );
      }
    }

    const article_source = sources.find(
      (source) => source.id === article.source_id
    );
    if (article_source === undefined) {
      return;
    }

    let path = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${article_source.slug}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
        path += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else if (article.source_id === `${selected_feed.id}-userpost`) {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }else if (
        article.branch !== ARTICLE_BRANCH_YOUTUBE_SUMMARY &&
        article.branch !== ARTICLE_BRANCH_PODCAST_SUMMARY
    ) {
        path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setArticleBackRoute(
      `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`
    );
  };

  handleSelectGroupArticle = async (nid) => {
    this.setWaiting(true);
    await getArticle(nid);
    this.setWaiting(false);

    const location = {
      pathname: `/${ROUTES.ARTICLE_NEWS_PREFIX}/${nid}`,
      state: { animation: "left" },
    };
    this.goTo(location);

    const { selected_feed } = this.props;
    const backroute = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
    this.props.setArticleBackRoute(backroute);
  };

  handleChangeCountry = async (country) => {
    this.props.selectCountry(country);
    this.props.initScrollPos();
    this.props.showTopNavbar(true);

    const { branch } = this.props;

    this.setWaiting(true);
    // get articles of the country in first page
    if (country === ALL) {
      await this.getArticlesOfBranchInFirstPage(branch);
    } else {
      await this.getArticlesOfCountryInFirstPage(country);
    }
    this.setWaiting(false);
    window.scrollTo(0, 0);
  };

  handleChangeBranch = async (branch) => {
    this.props.selectCountry(ALL);
    this.props.selectBranch(branch);
    this.props.initScrollPos();
    this.props.showTopNavbar(true);

    this.setWaiting(true);
    // get articles of the country in first page
    if (branch === BRANCH_ALL) {
      await this.getArticlesInFirstPage();
    } else {
      await this.getArticlesOfBranchInFirstPage(branch);
    }
    this.setWaiting(false);
    window.scrollTo(0, 0);
  };

  handleDeleteArticle = async (article) => {
    const { selected_feed } = this.props;
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await deleteArticle(article);
    updateFeedCache(selected_feed.slug)
    this.setWaiting(false);
  };

  handleEditArticle = (article) => {
    this.setState({
      ...this.state,
      postEditDlg: true,
      article_edit: article,
    });
  };

  closePostEditDlg = () => {
    this.setState({
      ...this.state,
      postEditDlg: false,
      article_edit: null,
    });
  };

  handleUpdatePost = async (description, postlink) => {
    this.setState({
      ...this.state,
      postEditDlg: false,
    });

    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }

    const { article_edit } = this.state;

    this.setWaiting(true);
    updatePost(description, postlink, article_edit);
    this.setWaiting(false);

    this.setState({
      ...this.state,
      article_edit: null,
    });
  };

  handleCommentArticle = async (article, content) => {
    const { authUser, selected_feed } = this.props;

    // const thread = {
    //   title: title,
    //   text: content,
    //   type: THREAD_TYPE_FEED,
    //   posted_by: authUser.uid,
    //   feed_id: selected_feed.id,
    //   approved: true,
    //   approved_by: authUser.uid,
    //   approved_at: new Date().toISOString(),
    // };

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    // insert article to threads if it is the first comment
    let thread_id = null;
    if (article.threads.length === 0) {
      let thread = {
        title: article.title ? article.title : article.author,
        text: article.summary ? article.summary : article.text?.slice(0, 50),
        type: THREAD_TYPE_ARTICLE,
        posted_by: authUser.uid,
        feed_id: selected_feed.id,
        from: article.nid,
        approved: true,
        approved_by: authUser.uid,
        approved_at: new Date().toISOString(),
      };

      await gqlservice
        .insert_thread(thread)
        .then(
          (result) => {
            if (result.data.insert_threads.affected_rows > 0) {
              const new_thread = result.data.insert_threads.returning[0];
              thread_id = new_thread.id;
              this.props.addThread(new_thread);
            } else {
              this.setError("Failed to insert a thread.");
              return;
            }
          },
          (reason) => {
            this.setError(reason.msg);
            return;
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
          return;
        });
    } else {
      thread_id = article.threads[0].id;
    }

    // insert comment
    const comment = {
      parent_id: null,
      text: content,
      article_id: article.nid,
      reading_id: null,
      thread_id: thread_id,
      author_id: authUser.uid,
      approved: true,
      approved_by: authUser.uid,
      approved_at: new Date().toISOString(),
    };

    await gqlservice
      .insert_comment(comment)
      .then(
        (result) => {
          if (result.data.insert_comments.affected_rows > 0) {
            // let inserted_comment = result.data.insert_comments.returning[0];
            // logger.log("inserted comment :", inserted_comment);
            return gqlservice.article_by_nid(article.nid);
          } else {
            this.setError("Failed to insert comment.");
            return;
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .then((result) => {
        const articles = result.data;
        if (articles.length > 0) {
          this.props.updateArticle(articles[0]);
        }
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    // // log this activity
    // const activity = {
    //   user_id: authUser.uid,
    //   type: ACTIVITY_TYPE_FEED,
    //   type_id: selected_feed.id,
    //   action: ACTIVITY_ADD,
    //   object: `the comment ${thread.title}`,
    //   fromto: `to the feed ${selected_feed.name}`,
    //   reason: "",
    // };
    // await gqlservice
    //   .insert_activitylog(activity)
    //   .then(
    //     (result) => {
    //     },
    //     (reason) => {
    //       this.setError(reason.msg);
    //     }
    //   )
    //   .catch((err) => {
    //     this.setError(JSON.stringify(err));
    //   });

    this.setWaiting(false);
  };

  handlePinArticle = async (article) => {
    const { authUser, selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true); 
    // unpin the article
    if (article.pinned) {
      await gqlservice
        .delete_article_pin(article.nid, selected_feed.id)
        .then(
          (result) => {
            this.props.undoPinArticle(article.nid, selected_feed.id);
          },
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });

      // log this activity
      gqlservice.set_auth_jwt(token, false);
      const activity = {
        user_id: authUser.uid,
        type: ACTIVITY_TYPE_FEED,
        type_id: selected_feed.id,
        action: ACTIVITY_REMOVE,
        object: `the article ${article.title} pin`,
        fromto: `of the feed ${selected_feed.name}`,
        reason: "",
      };
      await gqlservice
        .insert_activitylog(activity)
        .then(
          (result) => {},
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });

      this.setWaiting(false);
      return;
    }

    let pin = {
      id: uuidv4(),
      article_id: article.nid,
      feed_id: selected_feed.id,
      pinned_by: authUser.uid,
    };

    // pin the article
    await gqlservice
      .insert_article_pin(pin)
      .then(
        (result) => {
          pin.article = article;
          this.props.pinArticle(pin);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(authUser.token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_PIN,
      object: `the article ${article.title}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    updateFeedCache(selected_feed.slug)
    this.setWaiting(false);
    this.props.initScrollPos();
  };

  handleAICommentArticle = async (article) => {
    const { selected_feed } = this.props;
    // this.setWaiting(true);
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }

    var params = JSON.stringify({
      feed_id: selected_feed.id,
      article_id: article.nid,
    });

    await createAiComments(params, token)
      .then(
        (result) => {
          if (result.status === 200) {
            if (result.message.status === "success") {
              ToastSuccess(result.message.data);
            } else {
              this.setError(result.message.data);
            }
            return gqlservice.article_by_nid(article.nid);
          } else {
            this.setError("Failed to generate ai comment.");
            return;
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .then((result) => {
        const articles = result.data;
        if (articles.length > 0) {
          this.props.updateArticle(articles[0]);
        }
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
    // this.setWaiting(false);
  };

  handleMoveTopArticle = async (article) => {
    const { authUser, selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    // cancel the moving
    if (article.moved) {
      await gqlservice
        .delete_article_movetop(article.nid, selected_feed.id)
        .then(
          (result) => {
            this.props.undoMovetopArticle(article.nid, selected_feed.id);
          },
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });

      // log this activity
      gqlservice.set_auth_jwt(token, false);
      const activity = {
        user_id: authUser.uid,
        type: ACTIVITY_TYPE_FEED,
        type_id: selected_feed.id,
        action: ACTIVITY_REMOVE,
        object: `the article ${article.title} move`,
        fromto: `of the feed ${selected_feed.name}`,
        reason: "",
      };
      await gqlservice
        .insert_activitylog(activity)
        .then(
          (result) => {},
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });

      this.setWaiting(false);
      return;
    }

    let movetop = {
      id: uuidv4(),
      article_id: article.nid,
      feed_id: selected_feed.id,
      moved_by: authUser.uid,
    };

    await gqlservice
      .insert_article_movetop(movetop)
      .then(
        (result) => {
          movetop.article = article;
          this.props.movetopArticle(movetop);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(authUser.token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_MOVETOP,
      object: `the article ${article.title}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
    this.props.initScrollPos();
  };

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  };

  // empty funciton
  handleDeleteSavedArticle = (article) => {};
  // empty function

  getSourceIdsToShow = () => {
    const { selected_feed, authUser } = this.props;

    const source_ids = selected_feed?.feed_sources
      .filter(
        (feed_source) =>
          feed_source.approved && 
          is_source_alive(feed_source.source) &&
          authUser.feed_sources_unfollowed.findIndex(
            (unfollowed) =>
              unfollowed.feed_id === selected_feed?.id &&
              unfollowed.source_id === feed_source.source_id
          ) === -1
      )
      .map((feed_source) => feed_source.source_id);

    return source_ids;
  };

  getArticlesInFirstPage = async () => {

    const { selected_feed } = this.props;
    if(!selected_feed){
      return
    }
    this.props.setRequestGetArticles(true);

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles(selected_feed.id, 0)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("articles in feed(first page) :", feed_articles);
          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
          
            // this.props.setArticles(articles, articles.length);
            this.props.setArticlesOnFeedModeration(articles, articles.length ,selected_feed?.id)
            this.props.showBottomNavbar(true);
          }else{
            this.props.refreshArticlesOnFeedModeration()
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.props.setRequestGetArticles(false);
        this.setError(JSON.stringify(err));
      });
      this.props.setRequestGetArticles(false);
  };

  getArticlesInNextPage = async (last_offset) => {

    const { selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles(selected_feed.id, last_offset)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("articles in sources(next page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            // this.props.appendArticles(articles, last_offset + articles.length);
            this.props.appendArticlesOnFeedModeration(articles, last_offset + articles.length , selected_feed?.id);
          } else {
            this.props.appendArticlesOnFeedModeration([], last_offset ,selected_feed?.id);
          }

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesOfBranchInFirstPage = async (branch) => {

    const { selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    this.props.setRequestGetArticlesOfBranch(true);
    await gqlservice
      .feed_articles_of_branch(selected_feed.id, branch, 0)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("feed articles of branch(first page) :", feed_articles);
          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
          
            // this.props.setArticles(articles, articles.length);
            this.props.setArticlesOnFeedModeration(articles, articles.length ,selected_feed?.id)
            this.props.showBottomNavbar(true);
          }else{
            this.props.refreshArticlesOnFeedModeration()
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.props.setRequestGetArticlesOfBranch(false);
        this.setError(JSON.stringify(err));
      });
      this.props.setRequestGetArticlesOfBranch(false);
  };

  getArticlesOfBranchInNextPage = async (branch, last_offset) => {

    const { selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_branch(selected_feed.id, branch, last_offset)
      .then((result) => {
          const feed_articles = result.data;
          logger.log("feed articles of branch(next page) :", feed_articles);
          
          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);  
            this.props.appendArticlesOnFeedModeration(articles, last_offset + articles.length , selected_feed?.id);

          } else {
            // this.props.appendArticles([], last_offset);
            this.props.appendArticlesOnFeedModeration([], last_offset,selected_feed?.id);

          }

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesOfCountryInFirstPage = async (country) => {

    const { selected_feed } = this.props;
    this.props.setRequestGetArticlesOfCountry(true);
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_country(selected_feed.id, country, 0)
      .then((result) => {
          const feed_articles = result.data;
          logger.log("feed articles of country(first page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
          
            // this.props.setArticles(articles, articles.length);
            this.props.setArticlesOnFeedModeration(articles, articles.length , selected_feed?.id)
            this.props.showBottomNavbar(true);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.props.setRequestGetArticlesOfCountry(false);
        this.setError(JSON.stringify(err));
      });
      this.props.setRequestGetArticlesOfCountry(false);
  };

  getArticlesOfCountryInNextPage = async (country, last_offset) => {

    const { selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_country(selected_feed.id, country, last_offset)
      .then((result) => {
          const feed_articles = result.data;
          logger.log("feed articles of country(next page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.appendArticlesOnFeedModeration(articles, last_offset + articles.length , selected_feed?.id) ;
          } else {
            this.props.appendArticlesOnFeedModeration([], last_offset , selected_feed?.id);
          }

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  searchSource = (sources, searchKey) => {
    var result = [];
    sources.forEach((source) => {
      if (
        source.name.toLowerCase().includes(searchKey) ||
        source.description.toLowerCase().includes(searchKey)
      ) {
        result.push(source);
      }
    });
    return result;
  };

  handleEditFeed = () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/edit`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleApproveFeed = async () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;

    this.setWaiting(true);
    await approveFeed(selected_feed);
    this.props.approveFeed(selected_feed.id);
    this.setWaiting(false);
  };

  handleCopyLink = () => {
    copy2clipboard(window.location.href);
    ToastSuccess("Copied to clipboard");
  };

  handleCancelDeleteFeed = () => {
    this.setState({
      ...this.state,
      confirmDeleteDlg: false,
    });
  };

  handleConfirmDeleteFeed = () => {
    if (isBannedFeedCreation()) {
      ToastError("You've suspended for feed creation.");
      return;
    }

    this.setState({
      ...this.state,
      confirmDeleteDlg: true,
    });
  };

  handleDeleteFeed = async () => {
    const { selected_feed, authUser, selected_category } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);
    this.setWaiting(true);

    await gqlservice
      .delete_feed(selected_feed.id)
      .then((result) => {
        return gqlservice.resign_feed_moderator(selected_feed.id, authUser.uid);
      })
      .then(
        (result) => {
          this.props.resignFeedModerator(selected_feed.id, authUser.uid);
          this.props.deleteFeed(selected_feed.id);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_CATEGORY,
      type_id: selected_category.id,
      action: ACTIVITY_DELETE,
      object: `the feed ${selected_feed.name}`,
      fromto: `from the category ${selected_category.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
    const {loggedIn } = this.props;

    let cache_name = loggedIn
      ? hasPaid()
        ? "base_data_paiduser_v1"
        : "base_data_user_v1"
      : "base_data_anonymous_v1";
    let base_data = await get_cached_item(cache_name);
    if (base_data) {
      let base_feeds = base_data.feeds;
      // base_feeds.push(feeds[0])
      const deleteFeedData = base_feeds.map((item) => {
        if (item.id === selected_feed.id) {
          item.category_id = "deleted";
        }
        return item;
      });
      base_data.feeds = deleteFeedData;
      await set_cache_item(cache_name, base_data);
    }

    const { feed_backroute } = this.props;
    const location = {
      pathname: feed_backroute,
      state: { animation: "right" },
    };
    this.goTo(location);
  };

  handleCancelResignFeed = () => {
    this.setState({
      ...this.state,
      confirmResignDlg: false,
    });
  };

  handleConfirmResignFeed = () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setState({
      ...this.state,
      confirmResignDlg: true,
    });
  };

  handleResignFeed = async () => {
    this.setState({
      ...this.state,
      confirmResignDlg: false,
    });

    const { selected_feed, authUser } = this.props;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .resign_feed_moderator(selected_feed.id, authUser.uid)
      .then(
        (result) => {
          this.props.resignFeedModerator(selected_feed.id, authUser.uid);
          //   this.props.deleteFeedModerated(selected_feed);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_DELETE,
      object: `${authUser.username} resigned`,
      fromto: `from the feed ${selected_feed.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);

    const { feed_backroute } = this.props;
    const location = {
      pathname: feed_backroute,
      state: {},
    };
    this.goTo(location);
  };

  showAddPostDlg = () => {
    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    this.setState({
      ...this.state,
      postDlg: true,
    });
  };
  showADlgWeb = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }
    this.setState({
      ...this.state,
      webDlg: true
    });
  }

  closePostDlg = () => {
    this.setState({
      ...this.state,
      postDlg: false,
      webDlg:false
    });
  };

  handleApprovePosts = async (
    imageUpload,
    videoUpload,
    description,
    postlink
  ) => {
    
    const { authUser, selected_feed } = this.props;
    const feeds = JSON.parse(selected_feed.ai_moderation) == null ? [] : JSON.parse(selected_feed.ai_moderation);
    if (feeds.includes(APPROVING_POSTS)) {
      const params = {
        name: selected_feed.name,
        description: selected_feed.description,
        userName: authUser.name,
        userBio: authUser.biography,
        userId:authUser.uid,
        userKarmaScore: authUser?.articles_voted?.length,
        postText: description,
        triggersContentFilter: false
      };
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      
      await approveAiPosts(params, token)
      .then(
        (result) => {
          if (result.status === 200) {
            if ((result.message.decision.toLowerCase()) === "approve") {
              this.handleSubmitPost(imageUpload, videoUpload, description, postlink);
            } else {
              this.setState({
                ...this.state,
                postDlg: false
              });
              this.setError(`This post is not approved by AI beacuse ${JSON.stringify(result.message.reason)}`);
            }
          } else {
            this.setError("Failed to generate ai approvement.");
            return;
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
  }
  else {
      this.handleSubmitPost(imageUpload, videoUpload, description, postlink);
    }
  };

  handleSubmitPost = async (
    imageUpload,
    videoUpload,
    description,
    postlink
  ) => {
    this.setState({
      ...this.state,
      postDlg: false,
    });

    this.setWaiting(true);

    let new_image = "";
    if (imageUpload) {
      const resized_image = await resizeImageFile(imageUpload);
      const result = await this.props.firebase.uploadImage(
        resized_image,
        "posts"
      );
      if (result.error) {
        this.setError("Failed to upload image.");
        return;
      }
      new_image = result.url;
      const modresult = moderate_image(new_image);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError(
          "Image not allowed, because it contains adults or racy content."
        );
        await this.props.firebase.deleteImage(new_image);
        return;
      }
    }

    let video = "";
    if (videoUpload) {
      const result = await this.props.firebase.uploadVideo(
        videoUpload,
        "videocasts"
      );
      if (result.error) {
        this.setError("Failed to upload video.");
        return;
      }
      video = result.url;
    }

    let preview = null;
    if (postlink) {
      preview = await get_link_source(postlink);
      logger.log("User post preview :", preview);
    }

    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();

    let nid = "";
    if (postlink) {
      nid = get_hash(authUser.uid + postlink);
    } else {
      nid = get_hash(authUser.uid + description);
    }

    // check if there is already article
    const result = await gqlservice.article_by_nid(nid);
    if (
      result.status_code === GRAPHQL_SUCCESS &&
      result.data.articles.length > 0
    ) {
      this.setError("This post was already posted");
      return;
    }

    const title = authUser.biography;
    const summary = summarize_text(description, 150);
    const source_id = `${selected_feed.id}-userpost`;
    const published = new Date().getTime() / 1000;
    const userpost = {
      nid: nid,
      source_id: source_id,
      author: authUser.username,
      title: title,
      summary: summary,
      text: description,
      image: new_image,
      media_url: video,
      branch: ARTICLE_BRANCH_USERPOST,
      url: "",
      author_image: authUser.image,
      published: Math.floor(published),
      param1: 1, // approved
      param2: 1, // moderator
      param3: -1,
      txt_param1: authUser.uid, // user id
      link_preview: preview,
    };

    const token = await getAuthToken();
    if (!token) {
      this.setWaiting(false);
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    await gqlservice
      .insert_userpost(userpost)
      .then(
        (result) => {
          const articles = result.data.insert_articles.returning;
          if (articles.length > 0) {
            this.props.addUserPost(articles[0]);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    if (videoUpload) {
      const feed_videocast = {
        feed_id: selected_feed.id,
        article_id: userpost.nid,
        posted_by: authUser.uid,
      };

      await gqlservice
        .insert_feed_videocast(feed_videocast)
        .then(
          (result) => {
            const feed_videocasts =
              result.data.insert_feed_videocasts.returning;
            if (feed_videocasts.length > 0) {
            }
          },
          (reason) => {
            this.setError(reason.msg);
            return;
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
          return;
        });
    }

    const curTs = Math.floor(Date.now() / 1000);
    const feed_article = {
      feed_id: selected_feed.id,
      article_id: userpost.nid,
      branch: ARTICLE_BRANCH_USERPOST,
      published: curTs,
      crawled_at: curTs
    }

    await gqlservice
      .insert_feed_article(feed_article)
      .then((result) => {
        ToastInfo("Posted successfully");
      }, (reason) => {
        this.setError(reason.msg);
        return;
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    const now = new Date();
    await gqlservice
      .update_source_lastupdated(source_id, now.toISOString())
      .then((result) => {
        return gqlservice.feed_by_id(selected_feed.id);
      })
      .then(
        (result) => {
          const feeds = result.data.feeds;
          if (feeds.length > 0) {
            const selected_feed = feeds[0];
            this.props.selectFeed(selected_feed);
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_ADD,
      object: `the post ${userpost.nid}`,
      fromto: `to the feed ${selected_feed.name}`,
      reason: "",
    };

    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    updateFeedCache(selected_feed.slug)
    this.setWaiting(false);
    let userpostToBluesky;
    let userpostToMastodon;
    try {
      const promises = [];
    
      if (authUser.is_mastodon_enabled === true && authUser.mastodon_username) {
        promises.push(postToMastodon(userpost, token, authUser.mastodon_access_token));
      }
    
      if (authUser.is_bluesky_enabled === true) {
        const { authUser } = this.props;
        let params = {
          text: description,
          image: new_image,
          accessJwt: authUser.bluesky_access_token
        };
    
        promises.push(postToBluesky(params, token));
      }
    
      const [userpostToMastodon, userpostToBluesky] = await Promise.all(promises);
    
    } catch (error) {
      console.error('Error posting to Mastodon or Bluesky:', error);
    }
  };

  handleEditSource = (source) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    this.props.selectSource(source);
    this.props.setSourceBackRoute(
      `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`
    );
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}/edit`,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleDeleteSource = async (source) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { authUser, selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .delete_feed_source(selected_feed.id, source.id)
      .then((result) => {
        const feed_sources =
          result.data.delete_feed_sources.returning[0].feed.feed_sources;
        const source_reports = selected_feed.source_reports.filter(
          (report) => report.source_id !== source.id
        );

        selected_feed.source_reports = source_reports;
        selected_feed.feed_sources = feed_sources;

        this.props.selectFeed(selected_feed);

        // log this activity
        gqlservice.set_auth_jwt(token, false);
        const activity = {
          user_id: authUser.uid,
          type: ACTIVITY_TYPE_FEED,
          type_id: selected_feed.id,
          action: ACTIVITY_DELETE,
          object: `the source ${source.name}`,
          fromto: `from the feed ${selected_feed.name}`,
          reason: "",
        };

        return gqlservice.insert_activitylog(activity);
      })
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // insert feed source changes
    const feed_source_change = {
      feed_id: selected_feed.id,
      source_id: source.id,
      removed: true,
      created_by: authUser.uid
    };
    let result = await gqlservice.insert_feed_source_changes([feed_source_change]);
    if (result.status_code !== GRAPHQL_SUCCESS || result.data.insert_feed_source_change.affected_rows === 0) {
      this.setError("Failed to insert feed source changes!");
    }

    await updateCache();

    this.setWaiting(false);
  };

  handleAddSource = () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setState({
      ...this.state,
      suggestSourceDlg: true,
    });
  };

  handleCancelSuggestSource = () => {
    this.setState({
      ...this.state,
      suggestSourceDlg: false,
    });
  };

  goSourceAdd = () => {
    this.setState({
      ...this.state,
      suggestSourceDlg: false,
    });
    const location = {
      pathname: ROUTES.SOURCE_ADD,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleSourceSearch = () => {
    this.setState({
      ...this.state,
      suggestSourceDlg: false,
    });
    const location = {
      pathname: ROUTES.SOURCE_SEARCH,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleFlaggedSources = () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/flaggedsources`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleProposedSources = () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/proposedsources`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleFlaggedPosts = () => {
    if (isBannedPosts()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/flaggedposts`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleProposedPosts = () => {
    if (isBannedPosts()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/proposedposts`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleNeedMoreThreads = async () => {
    const { selected_feed } = this.props;
    if (!isCommentEnabledFeed(selected_feed)) {
      return;
    }
    const { threads_last_offset, requesting } = this.props;
    if (requesting) {
      return;
    }

    this.setWaiting(true);
    await this.getThreadsInNextPage(threads_last_offset);
    this.setWaiting(false);
  };

  handlePendedThreads = () => {
    if (isBannedComments()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/pendedthreads`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleFlaggedThreads = () => {
    if (isBannedComments()) {
      ToastError("You've suspended.");
      return;
    }
    const { selected_feed } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/flaggedthreads`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleFlaggedModerators = () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/flaggedmoderators`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleReportModerator = async (moderator, reportMsg) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { authUser, selected_feed } = this.props;

    let report = {
      id: uuidv4(),
      moderator_id: moderator.user.uid,
      feed_id: selected_feed.id,
      report: reportMsg,
      reported_by: authUser.uid,
      approved: false,
    };

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }

    this.setWaiting(true);

    gqlservice.set_auth_jwt(token);
    await gqlservice
      .insert_moderator_report(report)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_REPORT,
      object: `the moderator ${moderator.user.username}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: "",
    };
    gqlservice.set_auth_jwt(token, false);
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleApproveModerator = async (moderator) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    const currentTime = new Date().toISOString();

    this.setWaiting(true);

    gqlservice.set_auth_jwt(token, true);
    await gqlservice
      .update_feed_moderator(
        moderator.id,
        true,
        authUser.uid,
        currentTime,
        moderator.owner
      )
      .then(
        (result) => {
          this.props.approveFeedModerator(
            moderator.id,
            true,
            authUser.uid,
            currentTime
          );
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // insert notification
    const notification = {
      type: NOTIFICATION_FEED_APPROVE_MODERATOR,
      object: selected_feed.id,
      in_which: null,
      to: moderator.user.uid,
      created_by: authUser.uid,
    };

    gqlservice.set_auth_jwt(token, false);
    await gqlservice
      .insert_notification(notification)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_APPROVE,
      object: `the moderator ${moderator.user.username}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
    this.forceUpdate();
  };

  handleDeleteModerator = async (moderator) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { authUser, selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .delete_feed_moderator(moderator.id)
      .then(
        (result) => {
          this.props.deleteFeedModerator(moderator.id);
          //   this.props.deleteFeedModerated(selected_feed);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_DELETE,
      object: `the moderator ${moderator.user.username}`,
      fromto: `from the feed ${selected_feed.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
    this.forceUpdate();
  };

  handleMakeOwner = async (moderator) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    const currentTime = new Date().toISOString();

    this.setWaiting(true);

    gqlservice.set_auth_jwt(token, true);
    await gqlservice
      .update_feed_moderator(
        moderator.id,
        true,
        authUser.uid,
        currentTime,
        true
      )
      .then(
        (result) => {
          this.props.makeFeedOwner(moderator.id);
          this.props.giveupFeedOwnerUser(selected_feed);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_MAKE,
      object: `the moderator ${moderator.user.username}`,
      fromto: `as an owner of the feed ${selected_feed.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
    this.forceUpdate();
  };

  handleViewLog = async () => {
    const { selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    const now = Date.now();
    const viewtime = new Date(now).toISOString();

    await gqlservice
      .update_feed_last_viewlog(selected_feed.id, viewtime)
      .then(
        (result) => {
          const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/logs`;
          const location = {
            pathname: route,
            state: { animation: "left" },
          };
          this.goTo(location);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleClickSettings = () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    Mixpanel.track(MIXPANEL_EVENTS.FEED_SETTINGS, selected_feed);
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/settings`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  showAddThreadDlg = () => {
    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    this.setState({
      ...this.state,
      threadDlg: true,
    });
  };

  closeThreadDlg = () => {
    this.setState({
      ...this.state,
      threadDlg: false,
    });
  };

  handleSubmitThread = async (title, content) => {
    const { selected_feed } = this.props;

    this.setWaiting(true);
    await addThread(title, content, selected_feed, true);
    this.setWaiting(false);

    this.closeThreadDlg();
  };

  handleClickThread = (thread) => {
    this.props.selectThread(thread);
    const route = `/${ROUTES.THREAD_PREFIX}/${thread.id}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  getSourceThrottles2show = () => {
    const { selected_feed, authUser } =
      this.props;

    const feed_sources = selected_feed.feed_sources.filter(
      (feed_source) => 
        feed_source.approved &&
        authUser.feed_sources_unfollowed.findIndex(
          (unfollowed) =>
            unfollowed.feed_id === selected_feed.id &&
            unfollowed.source_id === feed_source.source_id
        ) === -1
      );

    const throttles = feed_sources.map((feed_source) => {
      return {
        source_id: feed_source.source_id,
        throttle: feed_source.source.throttle,
        article_count: 0,
      };
    });

    return throttles;
  };

  removeArticlesByThrottle = (articles) => {
    let new_articles = [];
    if (articles.length === 0) {
      return new_articles;
    }

    let throttles = this.getSourceThrottles2show();
    if (throttles.length === 0) {
      return new_articles;
    }

    for (let article of articles) {
      let throttle = throttles.find(
        (throttle) => throttle.source_id === article.source_id
      );
      if (throttle === undefined) {
        new_articles.push(article);
        continue;
      }

      throttle.article_count++;
      switch (throttle.throttle) {
        case 100:
          new_articles.push(article);
          break;

        case 75:
          if (throttle.article_count % 4 !== 0) {
            new_articles.push(article);
          }
          break;

        case 50:
          if (throttle.article_count % 2 === 0) {
            new_articles.push(article);
          }
          break;

        case 25:
          if (throttle.article_count % 4 === 0) {
            new_articles.push(article);
          }
          break;

        default:
          break;
      }
    }

    return new_articles;
  };

  renderPostWarning = (classes, theme) => {
    return (
      <div className={classes.warningcontainer}>
        <div className={classes.warningimg}>
          <img
            className={classes.warningicon}
            alt="warning"
            src={`/static/images/icons/${theme}/warning.png`}
          />
        </div>
        <Typography className={classes.warningtitle}>
          No posts in the feed yet.
        </Typography>
        <Typography className={classes.warningtext}>
          Press
          <img
            className={classes.indicatoricon}
            alt="plus"
            src={`/static/images/icons/${theme}/add.png`}
          />
          button to post an entry or go to the <strong>sources</strong> tab and
          add a social media source
        </Typography>
      </div>
    );
  };

  renderSourcesWarning = (classes, theme) => {
    return (
      <div className={classes.warningcontainer}>
        <div className={classes.warningimg}>
          <img
            className={classes.warningicon}
            alt="warning"
            src={`/static/images/icons/${theme}/warning.png`}
          />
        </div>
        <Typography className={classes.warningtitle}>
          No Sources added yet.
        </Typography>
        <Typography className={classes.warningtext}>
          Press
          <img
            className={classes.indicatoricon}
            alt="plus"
            src={`/static/images/icons/${theme}/add.png`}
          />
          button to suggest a social media source that you think might be useful
          in the feed
        </Typography>
      </div>
    );
  };

  isCreator = () => {
    const { authUser, selected_feed } = this.props;
    return authUser.uid === selected_feed.created_by;
  };

  isOwner = () => {
    const { authUser, selected_feed } = this.props;
    return (
      selected_feed.moderators.find(
        (moderator) => moderator.user.uid === authUser.uid && moderator.owner
      ) !== undefined
    );
  };

  getSearchTermSourceArticlesOfFirstPage = async () => {
    const { selected_feed } = this.props;
    if(!selected_feed){
      return
    }
    const searchTermSourceIds =selected_feed.feed_sources.filter(
      (feed_source) => {return true
    }).map(function(item) {
      return item.source_id;
    });
    this.props.setRequestGetSearchTermSourceArticles(true);

    const gqlservice = new GraphqlService();
    await gqlservice
      .searchTermSourceArticles(searchTermSourceIds,0)
      .then(
        (result) => {
          let articles = result.data.ai_source_articles;
          if (articles.length > 0) {
            const updatedDataArray = articles.map((parsedData) => {
               _.set(parsedData, 'article.source_id', parsedData.source_id);
               _.set(parsedData,'article.source', parsedData.source);
               return parsedData;
            });
            
            articles = updatedDataArray.map((article) => {  
              article.article['feed_id'] = "edison-central"; 
                return article.article;
            });
             
            this.props.setArticlesOnSearchTermSource(articles, articles.length ,selected_feed?.id)
            this.props.showBottomNavbar(true);
          }
        },
        (reason) => {
          this.setError(reason);
        }
      )
      .catch((err) => {
        this.props.setRequestGetSearchTermSourceArticles(false);
        this.setError(err);
      });
      this.props.setRequestGetSearchTermSourceArticles(false);
  };


  // getSearchTermSourceArticlesInNextPage = async (last_offset) => {
  //   const { selected_feed } = this.props;
  //   if(!selected_feed){
  //     return
  //   }
  //   const searchTermSourceIds =selected_feed.feed_sources.filter(
  //     (feed_source) => {return true
  //   }).map(function(item) {
  //     return item.source_id;
  //   });
  
  //   const gqlservice = new GraphqlService();
  //   await gqlservice
  //     .searchTermSourceArticles(searchTermSourceIds,last_offset)
  //     .then(
  //       (result) => {
  //         let articles = result.data.ai_source_articles;
  //         if (articles.length > 0) {
  //           const updatedDataArray = articles.map((parsedData) => {
  //              _.set(parsedData, 'article.source_id', parsedData.source_id);
  //              _.set(parsedData,'article.source', parsedData.source);
  //              return parsedData;
  //           });
            
  //           articles = updatedDataArray.map((article) => {  
  //             article.article['feed_id'] = "edison-central"; 
  //               return article.article;
  //           });
  //           this.props.appendArticlesOnSearchTermSource(articles, last_offset + articles.length , selected_feed?.id);
  //         } else {
  //           this.props.appendArticlesOnSearchTermSource([], last_offset ,selected_feed?.id);
  //         }
  //       },
  //       (reason) => {
  //         this.setError(reason);
  //       }
  //     )
  //     .catch((err) => {
  //       this.setError(err);
  //     });
  // };

  render() {
    const {
      classes,
      loggedIn,
      authUser,
      newssites,
      selected_feed,
      sources,
      branch,
      country,
      articles_on_feed_moderation,
      feed_posts,
      pins,
      movetops,
      threads,
      topNavbar,
      bottomNavbar,
      theme_mode,
      requesting,
      feedtab,
      articles_on_search_term_source,
      get_feed_info_requesting,
      get_feed_moderates_requesting,
      get_article_pins_requesting,
      get_article_summary_requesting,
      get_search_term_source_articles_requesting,
      get_feed_articles_of_branch_requesting,
      get_feed_articles_of_country_requesting,
      get_feed_articles_requesting,
      get_feed_posts_requesting,
      get_main_info_requesting
    } = this.props;
    const {
      searchKey,
      confirmDeleteDlg,
      confirmResignDlg,
      suggestSourceDlg,
      postDlg,
      webDlg,
      postEditDlg,
      article_edit,
      threadDlg,
      aiSummary,
      isPostsEmpty,
      loading
    } = this.state;

    if (!newssites || newssites.length === 0 || !selected_feed) {
      return <div style={{display: "none"}}></div>;
    }

    // members
    let feed_members = selected_feed.feed_moderators.filter(
      (moderator) => moderator.approved
    );
    for (let feed_follower of selected_feed.feed_followers) {
      if (
        feed_members.find(
          (member) => member.user_id === feed_follower.user_id
        ) === undefined
      ) {
        feed_members.push(feed_follower);
      }
    }

    // get sources to show
    let sources2show = selected_feed.feed_sources
      .filter((feed_source) => feed_source.approved)
      .map((feed_source) =>
        sources.find(
          (source) =>
            source.branch !== ARTICLE_BRANCH_USERPOST &&
            source.id === feed_source.source_id
        )
      )
      .filter((source) => source !== undefined);
    if (searchKey.length > 0) {
      sources2show = this.searchSource(sources2show, searchKey);
    }

    // get pins and movetops to show
    let pins2show = [];
    let movetops2show = [];
    if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      if (country === ALL) {
        pins2show = pins.filter((pin) => pin.article.branch === branch && pin.feed_id === selected_feed.id);
        movetops2show = movetops.filter(
          (movetop) => movetop.article.branch === branch && movetop.feed_id === selected_feed.id
        );
      } else {
        pins2show = pins.filter(
          (pin) =>
            pin.article.branch === branch && pin.article.country === country && pin.feed_id === selected_feed.id
        );
        movetops2show = movetops.filter(
          (movetop) =>
            movetop.article.branch === branch &&
            movetop.article.country === country && movetop.feed_id === selected_feed.id
        );
      }
    } else {
      if (branch === BRANCH_ALL) {
        pins2show = pins.filter((pin) =>  pin.feed_id === selected_feed.id);
        movetops2show = movetops.filter((movetop) => movetop.feed_id === selected_feed.id);
      } else {
        pins2show = pins.filter((pin) => pin.article.branch === branch && pin.feed_id === selected_feed.id);
        movetops2show = movetops.filter(
          (movetop) => movetop.article.branch === branch && movetop.feed_id === selected_feed.id
          );
        }
      }
      // console.log("branch  :",branch ,"PINS TO SHOW :",pins2show)
     let selectedFeedArticles =[]

    selectedFeedArticles = articles_on_feed_moderation[selected_feed?.id]?.articles_on_feed_moderation !== undefined ? structuredClone(articles_on_feed_moderation[selected_feed?.id].articles_on_feed_moderation):[]
    // console.log(" selected articles : ",selectedFeedArticles)

    let filtered_feed_post = feed_posts.filter((feed_post)=>feed_post.feed_id === selected_feed?.id)
    
    let selectedFeedSearchTermSourceArticles =[]
    selectedFeedSearchTermSourceArticles = articles_on_search_term_source[selected_feed?.id]?.articles_on_search_term_source !== undefined ? articles_on_search_term_source[selected_feed?.id].articles_on_search_term_source:[]
    
    const all_articles = [...filtered_feed_post ,...selectedFeedArticles,...selectedFeedSearchTermSourceArticles];
    const sorted_articles = all_articles.sort(
      (a, b) => b.published - a.published
    );

    const real_articles = sorted_articles
      .filter(
        (article) => ((sources.find((item) => item.id === article.source_id)!== undefined)||(article.branch===103||article.branch===104))
      )
      .filter(
        (article) =>
          // exclude non-approved user post
          article.branch !== ARTICLE_BRANCH_USERPOST ||
          (article.branch === ARTICLE_BRANCH_USERPOST && article.param1 === 1)
      )
      .filter((article) => {
        // remove articles with no keyword filter if the source has keyword filter
        let feed_source = selected_feed.feed_sources.find(
          (item) => item.source_id === article.source_id
        );
        if (
          feed_source === undefined || // feed posts
          feed_source.keyword_filter === null ||
          feed_source.keyword_filter.trim() === "" // source with no keyword filter
        ) {
          return true;
        } else {
          let keyword = feed_source.keyword_filter.trim().toLowerCase();
          if (
            article.title?.toLowerCase().includes(keyword) ||
            article.summary?.toLowerCase().includes(keyword) ||
            article.text?.toLowerCase().includes(keyword)
          ) {
            return true;
          } else {
            return false;
          }
        }
      });

    // get articles to show(delete pins & movetops from the articles)
    let articles2show = [];
    const throttled_articles = this.removeArticlesByThrottle(real_articles);
    for (let article of throttled_articles) {
      if (
        pins2show.find((pin) => pin.article.nid === article.nid) !==
          undefined ||
        movetops2show.find((movetop) => movetop.article.nid === article.nid) !==
          undefined
      ) {
        continue;
      }
      articles2show.push(article);
    }

    // make appbar title
    let title = "Feeds: ";
    title += selected_feed.name;

    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;

    let shareInfo = {
      title: "Raven Feed: " + selected_feed.name,
      description: selected_feed.description,
      image: selected_feed.image,
      hashtag: selected_feed.tags.join(),
      url: shareUrl,
    };

    const title_height = window.innerWidth >= 600 ? 64 : 56;
    const appbar_height = topNavbar ? 206 + 32 : title_height;

    // is category moderator?
    let is_category_moderator = false;
    for (let category of authUser.categories_moderated) {
      if (category.category_id === selected_feed.category_id) {
        is_category_moderator = true;
      }
    }

    // nonapproved post reports
    let nonapproved_post_reports = [];
    if (selected_feed.post_reports) {
      nonapproved_post_reports = selected_feed.post_reports.filter(
        (report) => !report.approved
      );
    }
    // nonapproved source reports
    let nonapproved_source_reports = [];
    if (selected_feed.source_reports) {
      nonapproved_source_reports = selected_feed.source_reports.filter(
        (report) => !report.approved
      );
    }
    // nonapproved moderator reports
    let nonapproved_moderator_reports = [];
    if (selected_feed.moderator_reports) {
      nonapproved_moderator_reports = selected_feed.moderator_reports.filter(
        (report) => !report.approved
      );
    }
    // nonapproved threads
    const threads2show = threads.filter((thread) => thread.approved);
    // nonapproved threads
    let pended_threads = [];
    if (selected_feed.threads) {
      pended_threads = selected_feed.threads.filter(
        (thread) => !thread.approved
      );
    }
    // nonapproved thread reports
    let nonapproved_thread_reports = [];
    if (selected_feed.thread_reports) {
      nonapproved_thread_reports = selected_feed.thread_reports.filter(
        (report) => !report.approved
      );
    }
    // moderators except me
    let feed_moderators = [];
    if (selected_feed.moderators) {
      feed_moderators = selected_feed.moderators;
      // feed_moderators = selected_feed.moderators.filter(
      //   (moderator) => moderator.user.uid !== authUser.uid
      // );
    }
    // new feed logs
    let new_logs = [];
    if (selected_feed.feed_logs) {
      new_logs = selected_feed.feed_logs.filter(
        (log) =>
          Date.parse(log.logged_at) > Date.parse(selected_feed.last_viewlog)
      );
    }

    const contentMinHeight = window.innerHeight - appbar_height;

    // layout variables
    const width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    const height =
      document.documentElement.clientHeight ||
      document.body.clientHeight ||
      window.innerHeight;
    const isDesktop = width > MAX_WINDOW_WIDTH;
    const isTablet = width >= MIN_TABLET_WIDTH && width <= MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;
    const innerWidth = width > MAX_WINDOW_WIDTH ? MAX_WINDOW_WIDTH : width;

    logger.log("Window innerWidth :", innerWidth);

    // card width = 414 - 16, grid space
    const masonryWidth = Math.floor(innerWidth / 402) * 402 + 16;
    let srclistWidth = Math.floor(innerWidth / 418) * 418 + 16;
    if (isMobile) {
      srclistWidth = MAX_CARD_WIDTH;
    }
    if (width < MAX_CARD_WIDTH) {
      srclistWidth = width;
    }
    if (width < MIN_CARD_WIDTH) {
      srclistWidth = MIN_CARD_WIDTH;
    }

    // add button position
    let addbuttonPos = 0;
    if (isDesktop) {
      addbuttonPos = (width + MAX_WINDOW_WIDTH) / 2 - 96;
    } else if (innerWidth > MAX_CARD_WIDTH) {
      addbuttonPos = (innerWidth + MAX_CARD_WIDTH) / 2 - 96;
    } else {
      addbuttonPos = srclistWidth - 96;
    }

    // const slideContainer = {
    //   height: height,
    //   WebkitOverflowScrolling: "touch",
    // };

    let feedUrl = "";
    if (typeof window !== "undefined") {
      feedUrl = window.location.protocol + "//" + window.location.host;
    }
    feedUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
    const requestCompleteStatus =  !loading && !get_feed_info_requesting && !get_feed_moderates_requesting  && !get_article_pins_requesting  && !get_article_summary_requesting  && !get_search_term_source_articles_requesting  && !get_feed_articles_of_branch_requesting  && !get_feed_articles_of_country_requesting  && !get_feed_articles_requesting  && !get_feed_posts_requesting  && !get_main_info_requesting? true : false;

    return (
      <div className={classes.root} onWheel={this.handleWheel}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven: ${selected_feed.name}`}</title>
            <meta name="description" content={selected_feed.description} />
            <meta
              property="og:title"
              content={`Raven: ${selected_feed.name}`}
            />
            <meta
              property="og:description"
              content={selected_feed.description}
            />
            <meta
              property="og:image"
              content={selected_feed.image || RAVEN_PLACEHOLDER_IMAGE}
            />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={feedUrl} />
            <meta
              property="twitter:title"
              content={`Raven: ${selected_feed.name}`}
            />
            <meta property="twitter:site" content="Raven App" />
            <meta
              property="twitter:description"
              content={selected_feed.description}
            />
            <meta
              property="twitter:image:src"
              content={selected_feed.image || RAVEN_PLACEHOLDER_IMAGE}
            />
            <meta property="twitter:image:alt" content={selected_feed.name} />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar}>
          <MainAppBar
            show={topNavbar}
            title={title}
            share_info={shareInfo}
            feed={selected_feed}
            onNavBack={this.handleNavBack}
          />
          <FeedsAppBar
            show={!topNavbar}
            image={selected_feed.thumbnail || selected_feed.image || RAVEN_PLACEHOLDER_IMAGE}
            title={selected_feed.name}
            onClickBackButton={this.handleClickBackButton}
          />
        </div>
        {topNavbar && (
          <Fade in={topNavbar} timeout={{ enter: 300, exit: 300 }}>
            <div className={classes.maincontainer}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <FeedDetail
                    onEdit={this.handleEditFeed}
                    onApprove={this.handleApproveFeed}
                    onCopyLink={this.handleCopyLink}
                    onDelete={this.handleConfirmDeleteFeed}
                    onResign={this.handleConfirmResignFeed}
                    onLogin={this.handleLogin}
                    onClickCategoryIndicator={this.handleCategoryIndicator}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <InviteControl
                    theme_mode={theme_mode}
                    privatefeed={selected_feed.private}
                    showmembers={selected_feed.op_members}
                    members={feed_members}
                    publicOnly={!hasPaid()}
                    onClickMembers={this.handleClickMembers}
                    onInviteMembers={this.handleInviteMembers}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <FeedTabs
                    theme_mode={theme_mode}
                    onChangeFeedTab={this.handleChangeFeedTab}
                  />
                </Grid>
              </Grid>
            </div>
          </Fade>
        )}
        <SwipeableViews
          // containerStyle={feedtab !== TAB_FEED ? slideContainer : null}
          index={feedtab}
          onChangeIndex={this.handleSwiped}
          onScroll={this.handleSwipScroll}
          enableMouseEvents
        >
          <div
            style={{ minHeight: contentMinHeight }}
            id="feed-swipeable-views-feeds"
          >
            {feedtab === TAB_FEED &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                {selected_feed.approved &&
                  selected_feed.proposed_posts !== undefined && (
                    <Grid item>
                      <PostButtons
                        theme={theme_mode}
                        flagged={nonapproved_post_reports.length}
                        proposed={selected_feed.proposed_posts.length}
                        posted={selected_feed.posted_posts.length}
                        onFlagged={this.handleFlaggedPosts}
                        onProposed={this.handleProposedPosts}
                      />
                    </Grid>
                  )}
                {(isDesktop || isTablet) && (
                  <Grid item xs={12}>
                    {articles2show.length === 0 && requestCompleteStatus && !isPostsEmpty &&
                      this.renderPostWarning(classes, theme_mode)}
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        {articles2show.length > 0 && (
                          <ArticleModMasonry
                            key={`articless-index-${articles2show}`}
                            width={masonryWidth}
                            articles={articles2show}
                            aiSummary={aiSummary}
                            pins={[...pins2show]}
                            movetops={movetops2show}
                            onNeedMore={this.handleNeedMoreArticles}
                            onSelectArticle={this.handleSelectArticle}
                            onSelectGroupArticle={this.handleSelectGroupArticle}
                            onDelete={this.handleDeleteArticle}
                            onEdit={this.handleEditArticle}
                            onPin={this.handlePinArticle}
                            onComment={this.handleCommentArticle}
                            onMoveTop={this.handleMoveTopArticle}
                            onSave={this.handleSaveArticle}
                            onDeleteSaved={this.handleDeleteSavedArticle}
                            onClickSource={this.handleClickSourceInArticle}
                            onClickFeed={this.handleClickFeed}
                            onClickUpvote={this.handleClickUpvote}
                            onClickComment={this.handleClickComment}
                            onClickRepost={this.handleClickRepost}
                            onCreateAIComment={this.handleAICommentArticle}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {isMobile && (
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        {articles2show.length === 0 && requestCompleteStatus && !isPostsEmpty &&
                          this.renderPostWarning(classes, theme_mode)}
                        {articles2show.length > 0 && (
                          <ArticleModList
                            articles={articles2show}
                            aiSummary={aiSummary}
                            pins={pins2show}
                            movetops={movetops2show}
                            onNeedMore={this.handleNeedMoreArticles}
                            onSelectArticle={this.handleSelectArticle}
                            onSelectGroupArticle={this.handleSelectGroupArticle}
                            onDelete={this.handleDeleteArticle}
                            onEdit={this.handleEditArticle}
                            onPin={this.handlePinArticle}
                            onComment={this.handleCommentArticle}
                            onMoveTop={this.handleMoveTopArticle}
                            onSave={this.handleSaveArticle}
                            onDeleteSaved={this.handleDeleteSavedArticle}
                            onClickSource={this.handleClickSourceInArticle}
                            onClickFeed={this.handleClickFeed}
                            onClickUpvote={this.handleClickUpvote}
                            onClickComment={this.handleClickComment}
                            onClickRepost={this.handleClickRepost}
                            onCreateAIComment={this.handleAICommentArticle}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            }
            <div className={classes.bottomspace}></div>
          </div>

          <div
            style={{ minHeight: contentMinHeight }}
            id="feed-swipeable-views-sources"
          >
            {feedtab === TAB_SOURCES &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                {selected_feed.approved &&
                  selected_feed.proposed_sources !== undefined && (
                    <Grid item>
                      <SourceButtons
                        theme={theme_mode}
                        flagged={nonapproved_source_reports.length}
                        proposed={selected_feed.proposed_sources.length}
                        onFlagged={this.handleFlaggedSources}
                        onProposed={this.handleProposedSources}
                      />
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      {sources2show.length === 0 &&
                        !requesting &&
                        this.renderSourcesWarning(classes, theme_mode)}
                      {sources2show.length > 0 && (
                        <SourceList
                          width={srclistWidth}
                          sources={sources2show}
                          onLogin={this.handleLogin}
                          onClicked={this.handleClickSource}
                          onEdit={this.handleEditSource}
                          onDelete={this.handleDeleteSource}
                          onUnfollowed={this.handleClickUnfollowed}
                          onShowRetweet={this.handleClickShowRetweet}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            <div className={classes.bottomspace}></div>
          </div>
          <div
            style={{ minHeight: contentMinHeight }}
            id="feed-swipeable-views-threads"
          >
            {feedtab === TAB_COMMENTS &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                {selected_feed.approved && (
                  <Grid item>
                    <CommentButtons
                      theme={theme_mode}
                      pended={pended_threads.length}
                      flagged={nonapproved_thread_reports.length}
                      onPended={this.handlePendedThreads}
                      onFlagged={this.handleFlaggedThreads}
                    />
                  </Grid>
                )}
                {/* {threads2show.length > 0 && ( */}
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <ThreadList
                        width={MAX_CARD_WIDTH - 16}
                        theme={theme_mode}
                        threads={threads2show}
                        onClickThread={this.handleClickThread}
                        onClickSource={this.handleClickSource}
                        onLogin={this.handleLogin}
                        onNeedMore={this.handleNeedMoreThreads}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* )} */}
              </Grid>
            }
            <div className={classes.bottomspace}></div>
          </div>

          <div
            style={{ minHeight: contentMinHeight }}
            id="feed-swipeable-views-moderators"
          >
            {feedtab === TAB_MODERATORS &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                {selected_feed.approved &&
                  selected_feed.feed_logs !== undefined && (
                    <Grid item>
                      <ModButtons
                        theme={theme_mode}
                        flagged={nonapproved_moderator_reports.length}
                        newlogs={new_logs.length}
                        logs={selected_feed.feed_logs.length}
                        onFlagged={this.handleFlaggedModerators}
                        onViewLog={this.handleViewLog}
                        onSettings={this.handleClickSettings}
                      />
                    </Grid>
                  )}
                {feed_moderators.length > 0 && (
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <ModeratorList
                          width={srclistWidth}
                          theme={theme_mode}
                          fullaccess={is_category_moderator}
                          owner={this.isOwner()}
                          moderators={feed_moderators}
                          onReport={this.handleReportModerator}
                          onApprove={this.handleApproveModerator}
                          onDelete={this.handleDeleteModerator}
                          onMakeOwner={this.handleMakeOwner}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            }
            <div className={classes.bottomspace}></div>
          </div>
        </SwipeableViews>
        <BottomNavBar
          show={bottomNavbar && feedtab === TAB_FEED}
          selected_feeds={[selected_feed]}
          onChangeCountry={this.handleChangeCountry}
          onChangeBranch={this.handleChangeBranch}
        />
        <DlgConfirm
          open={confirmDeleteDlg}
          title={"Delete Feed"}
          content={"Are you sure to delete this feed?"}
          onOK={this.handleDeleteFeed}
          onCancel={this.handleCancelDeleteFeed}
        />
        <DlgConfirm
          open={confirmResignDlg}
          title={"Resign from Feed"}
          content={"Are you sure to resign from this feed?"}
          onOK={this.handleResignFeed}
          onCancel={this.handleCancelResignFeed}
        />
        {loggedIn && feedtab === TAB_FEED && selected_feed.op_posts && (
          <Fragment>
            <div onClick={this.showADlgWeb}>
              <img
                className={classes.addbutton}
                style={{ backgroundColor: "#10a37f", marginLeft: -65, left: addbuttonPos, bottom: 80, borderRadius: 30, padding: 6 }}
                alt={"addlink"}
                src={`/static/images/summary.png`}

              />
            </div>
            <DlgWeblink
              selected_feed={selected_feed}
              open={webDlg}
              onClose={this.closePostDlg}
            />
            <DlgPost
              open={postDlg}
              moderator={true}
              onSubmit={this.handleApprovePosts}
              onClose={this.closePostDlg}
            />
            {postEditDlg && (
              <DlgPostEdit
                open={postEditDlg}
                theme={theme_mode}
                article={article_edit}
                onSubmit={this.handleUpdatePost}
                onClose={this.closePostEditDlg}
              />
            )}
            <div onClick={this.showAddPostDlg}>
              <img
                className={classes.addbutton}
                style={{ left: addbuttonPos, bottom: 80 }}
                alt={"addpost"}
                src={`/static/images/icons/${theme_mode}/add.png`}
              />
            </div>
          </Fragment>
        )}
        {loggedIn && feedtab === TAB_SOURCES && (
          <div onClick={this.handleAddSource}>
            <img
              className={classes.addbutton}
              style={{ left: addbuttonPos, bottom: 16 }}
              alt={"addsource"}
              src={`/static/images/icons/${theme_mode}/add.png`}
            />
          </div>
        )}
        {loggedIn &&
          feedtab === TAB_COMMENTS &&
          isCommentEnabledFeed(selected_feed) && (
            <Fragment>
              <DlgThread
                open={threadDlg}
                theme={theme_mode}
                onClose={this.closeThreadDlg}
                onSubmit={this.handleSubmitThread}
              />
              <div onClick={this.showAddThreadDlg}>
                <img
                  className={classes.addbutton}
                  style={{ left: addbuttonPos, bottom: 16 }}
                  alt={"addcomment"}
                  src={`/static/images/icons/${theme_mode}/add.png`}
                />
              </div>
            </Fragment>
          )}
        {loggedIn && (
          <DlgSuggestSource
            open={suggestSourceDlg}
            theme={theme_mode}
            onCancel={this.handleCancelSuggestSource}
            onSourceAdd={this.goSourceAdd}
            onSourceSearch={this.handleSourceSearch}
          />
        )}
        <WaitingSpinner open={!requestCompleteStatus} />
        <ToastContainer />
      </div>
    );
  }
}

FeedModeration.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  categories: state.dataState.categories,
  selected_category: state.dataState.selected_category,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  selected_feed: state.dataState.selected_feed,
  articles_on_feed_moderation: state.dataState.articles_on_feed_moderation,
  feed_posts: state.dataState.feed_posts,
  pins: state.dataState.pins,
  movetops: state.dataState.movetops,
  sources: state.dataState.sources,
  feed_backroute: state.uiState.feed_backroute,
  country: state.dataState.country,
  branch: state.dataState.branch,
  threads: state.dataState.threads,
  threads_last_offset: state.dataState.threads_last_offset,
  topNavbar: state.uiState.topNavbar,
  bottomNavbar: state.uiState.bottomNavbar,
  scrollPos: state.uiState.scrollPos,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
  feedtab: state.uiState.feedtab,
  articles_on_search_term_source: state.dataState.articles_on_search_term_source,
  get_feed_info_requesting: state.uiState.get_feed_info_requesting,
  get_feed_moderates_requesting : state.uiState.get_feed_moderates_requesting,
  get_article_pins_requesting : state.uiState.get_article_pins_requesting,
  get_article_summary_requesting : state.uiState.get_article_summary_requesting,
  get_search_term_source_articles_requesting : state.uiState.get_search_term_source_articles_requesting,
  get_feed_articles_of_branch_requesting : state.uiState.get_feed_articles_of_branch_requesting,
  get_feed_articles_of_country_requesting : state.uiState.get_feed_articles_of_country_requesting,
  get_feed_articles_requesting : state.uiState.get_feed_articles_requesting,
  get_feed_posts_requesting : state.uiState.get_feed_posts_requesting,
  get_main_info_requesting : state.uiState.get_main_info_requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedModeration);
