import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MIN_CARD_WIDTH, MAX_ARTICLE_WIDTH } from "constants/types";

const styles = (theme) => ({
  card: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH - 32,
    maxWidth: MAX_ARTICLE_WIDTH - 32,
    backgroundColor: theme.palette.background.default,
  },
  carddiv: {
    margin: theme.spacing(1),
    marginTop: 0,
    borderRadius: 10,
    minHeight: 128,
    backgroundColor: theme.palette.background.main,
  },
  header: {
    padding: 0,
  },
  image: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(3),
    objectFit: "cover",
    width: 80,
    height: 80,
    borderRadius: 10,
  },
  image_back: {
    backgroundColor: "transparent",
  },
  socialimg: {
    position: "absolute",
    top: 100,
    left: theme.spacing(4),
    width: 20,
    height: 20,
  },
  titleline: {
    position: "relative",
    marginLeft: 100,
    marginRight: 50,
    margin: theme.spacing(1),
  },
  title: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  upvotesbtn: {
    position: "absolute",
    display: "block",
    paddingBottom: 12,
    padding: 0,
    minWidth: 32,
    top: 16,
    right: 16,
  },
  upvotemark: {
    height: 32,
    width: 32,
    color: theme.palette.text.secondary,
  },
  upvotes: {
    width: 36,
    fontSize: 14,
    fontWeight: 100,
    lineHeight: 0,
  },
  following: {
    position: "absolute",
    top: 64,
    right: theme.spacing(1),
    width: 48,
    height: 48,
  },
  content: {
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
});

class SourceDetail extends React.Component {
  render() {
    const {
      classes,
      title,
      description,
      image,
      link,
      logo,
      loggedIn,
      following,
      upvotes,
      theme_mode,
      onClickFollowing,
      onClickUpvote,
      onClickSource,
    } = this.props;

    const following_img = `/static/images/icons/${theme_mode}/following.png`;
    const unfollowing_img = `/static/images/icons/${theme_mode}/unfollowing.png`;

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) {
      width = MAX_ARTICLE_WIDTH;
    } else if (width < MIN_CARD_WIDTH) {
      width = MIN_CARD_WIDTH;
    }
    width -= 32;

    return (
      <Card className={classes.card}>
        <div className={classes.carddiv} style={{ width: width }}>
          <Button className={classes.upvotesbtn} onClick={onClickUpvote}>
            <ArrowDropUpIcon className={classes.upvotemark} />
            <Typography className={classes.upvotes}>{upvotes}</Typography>
          </Button>
          <CardHeader
            className={classes.header}
            avatar={
              <div>
                <LazyLoadImage
                  alt={title}
                  src={image}
                  className={classes.image}
                  classes={{ img: classes.image_back }}
                />
                {link !== null && (
                  <a target="_blank" rel="noopener noreferrer" href={link}>
                    <img
                      alt={"branch"}
                      src={logo}
                      className={classes.socialimg}
                    />
                  </a>
                )}
                {link === null && (
                  <img
                    alt={"branch"}
                    src={logo}
                    className={classes.socialimg}
                  />
                )}
              </div>
            }
            title={
              <div className={classes.titleline}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </div>
            }
            onClick={onClickSource}
          />
          {loggedIn && (
            <div onClick={onClickFollowing}>
              <img
                className={classes.following}
                alt={"following"}
                src={following ? following_img : unfollowing_img}
              />
            </div>
          )}
          <CardContent className={classes.content}>
            <div style={{ width: window.innerWidth - 16 }}></div>
          </CardContent>
        </div>
      </Card>
    );
  }
}

SourceDetail.propTypes = {
  classes: PropTypes.object,
  theme_mode: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  logo: PropTypes.string,
  following: PropTypes.bool,
  followers: PropTypes.number,
  upvotes: PropTypes.number,
  onClickFollowing: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickSource: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SourceDetail));
