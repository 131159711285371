import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MetaTags from 'react-meta-tags';
import { ToastContainer } from "react-toastify";
import { withAuthentication } from "session";
import {
  SearchAppBar,
  FollowFeedsList,
  MyFeedsList,
  DlgAlert,
  WaitingSpinner,
} from "components";
import * as ROUTES from "constants/routes";
import { BRANCH_ALL } from "constants/branches";
import { ALL } from "constants/country";
import {
  MAX_WINDOW_WIDTH,
  USE_QUOTA,
  FREE_FEED_FOLLOW_LIMIT,
  PAID_FEED_FOLLOW_LIMIT,
} from "constants/types";
import {
  getMainInfo, 
  getLists,
  hasPaid,
  isFeedModerator,
  followFeed,
  unfollowFeed
} from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
  },
  list: {
    margin: theme.spacing(2),
  },
  feeds: {
    paddingLeft: theme.spacing(2),
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.background.default,
  },
});

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: "",
      alertDlg: false,
      alertTitle: "",
      alertMsg: ""
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.handleNavBack = this.handleNavBack.bind(this);
    
    this.handleChangeFollowing = this.handleChangeFollowing.bind(this);
    this.handleReachLimit = this.handleReachLimit.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);

    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  componentDidMount = async () => {
    logger.log("List id : ", this.props.match.params.id);
    const { selected_list } = this.props;

    this.setWaiting(true);

    await getMainInfo();
    await getLists();

    this.setWaiting(false);

    var list_id = this.props.match.params.id;
    if (selected_list) {
      list_id = selected_list.id;
    } else {
      // browse this list url directly (by list link)
      let curList = this.props.lists.find(
        (list) => list.id === list_id
      );
      if (!curList) {
        return;
      }
      this.props.selectList(curList);
    }
  };

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCloseAlert = () => {
    this.setState({
      ...this.state,
      alertDlg: false,
      alertTitle: "",
      alertMsg: ""
    });
  }

  handleSearchChange = (searchKey) => {
    this.setState({
      ...this.state,
      searchKey: searchKey
    });
  };

  handleSearchEnter = (searchKey) => {};

  handleNavBack = () => {
    // const { category_backroute } = this.props;
    // this.props.history.push(category_backroute);
    this.props.history.goBack();
  };

  handleReachLimit = () => {
    if (!hasPaid()) {
      const location = {
        pathname: ROUTES.MEMBERSHIP,
        state: { animation: "bottom" },
      };
      this.props.history.push(location);
    } else { 
      this.setState({
        ...this.state,
        alertDlg: true,
        alertTitle: "Follow Limit",
        alertMsg: "You've already reached the limit to follow. Unfollow some feeds to follow another feeds."
      });
    }
  }

  handleChangeFollowing = async (following, index) => {
    const { authUser, followed_feeds } = this.props;

    // get feeds corresponding to the following (refer to render)
    let feeds2show = this.getFeeds2show();
    if (feeds2show.length === 0) {
      return;
    }

    // check if quota is exceeded
    if (USE_QUOTA) {
      if (following[index]) {
        const paidUser = hasPaid();
        if (!paidUser && followed_feeds.length + authUser.feeds_moderated.length >= FREE_FEED_FOLLOW_LIMIT) {
          this.handleReachLimit();
          return;
        }
        if (paidUser && followed_feeds.length + authUser.feeds_moderated.length >= PAID_FEED_FOLLOW_LIMIT) {
          this.handleReachLimit();
          return;
        }
      }
    }

    this.setWaiting(true);

    if (following[index]) {
      await followFeed(feeds2show[index]);
    } else {
      await unfollowFeed(feeds2show[index]);
    }

    this.setWaiting(false);
  }

  handleClickFeed = (feed) => {
    this.props.selectFeed(feed);

    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    if (isFeedModerator(feed)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    }
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setFeedBackRoute(ROUTES.DISCOVER);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.showTopNavbar(true);
  }

  handleClickSource = source => {
    const { selected_list } = this.props;
    this.props.selectSource(source);
    this.props.setSourceBackRoute(`/${ROUTES.LIST_PREFIX}/${selected_list.id}`);
    const path = `/${ROUTES.CATEGORY_PREFIX}/${source.category_id}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  }

  searchItems = (items, searchKey) => {
    var result = items.filter(item =>
      item.name.toLowerCase().includes(searchKey) ||
      item.description.toLowerCase().includes(searchKey)
    );
    return result;
  }

  getFeeds2show = () => {
    const { selected_list, feeds } = this.props;
    const { searchKey } = this.state;

    let feeds2show = selected_list.list_feeds.map(list_feed =>
      feeds.find(feed => feed.id === list_feed.feed.id && feed.private === false && feed.approved)
    );

    if (searchKey) {
      return this.searchItems(feeds2show, searchKey);
    }
    return feeds2show;
  }

  getSources2show = () => {
    const { selected_list, sources } = this.props;
    const { searchKey } = this.state;

    let sources2show = selected_list.list_sources.map(list_source =>
      sources.find(source => source.approved && source.id === list_source.source.id)
    );

    if (searchKey) {
      return this.searchItems(sources2show, searchKey);
    }
    return sources2show;
  }

  render() {
    const {
      classes,
      newssites,
      selected_list,
      followed_feeds,
      loggedIn,
      theme_mode,
      requesting
    } = this.props;
    const {
      alertDlg,
      alertTitle,
      alertMsg
    } = this.state;

    if (newssites.length === 0 || selected_list === null) {
      return <div style={{display: "none"}}></div>;
    }

    // feeds
    const feeds2show = this.getFeeds2show();
    // feeds following
    let following = [];
    if (loggedIn) {
      following = feeds2show.map((feed) => {
        let result = followed_feeds.find((element) => element.id === feed.id);
        return result === undefined ? false : true;
      });
    }

    // sources
    const sources2show = this.getSources2show();

    // url
    let url = "";
    if (typeof window !== "undefined") {
      url = window.location.protocol + "//" + window.location.host;
    }
    url += `/${ROUTES.LIST_PREFIX}/${selected_list.id}`;

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven: ${selected_list.name}`}</title>
            <meta name="description" content={selected_list.description} />
            <meta property="og:title" content={`Raven: ${selected_list.name}`} />
            <meta property="og:description" content={selected_list.description} />
            <meta property="og:image" content={selected_list.image} />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={url} />
            <meta property="twitter:title" content={`Raven: ${selected_list.name}`} />
            <meta property="twitter:site" content="Raven App" />
            <meta property="twitter:description" content={selected_list.description} />
            <meta property="twitter:image:src" content={selected_list.image} />
            <meta property="twitter:image:alt" content={selected_list.name} />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar}>
          <SearchAppBar
            title={selected_list.name}
            onSearchChange={this.handleSearchChange}
            onSearchEnter={this.handleSearchEnter}
            onNavBack={this.handleNavBack}
          />
        </div>
        {feeds2show.length > 0 && (
          <div className={classes.feeds}>
            <FollowFeedsList
              items={feeds2show}
              badged={false}
              following={following}
              onFollowingChanged={this.handleChangeFollowing}
              onItemClicked={this.handleClickFeed}
              onReachLimit={this.handleReachLimit}
            />
          </div>
        )}
        {sources2show.length > 0 && (
          <div className={classes.feeds}>
            <MyFeedsList
              items={sources2show}
              title={false}
              onItemClicked={this.handleClickSource}
            />
          </div>
        )}
        <DlgAlert
          open={alertDlg}
          title={alertTitle}
          description={alertMsg}
          theme_mode={theme_mode}
          onOK={this.handleOK}
        />
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

List.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  categories: state.dataState.categories,
  lists: state.dataState.lists,
  selected_list: state.dataState.selected_list,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(List);
