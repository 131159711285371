import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withRouter, Link } from "react-router-dom";
import { withFirebase } from "services";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Typography, Grid, Button } from "@material-ui/core";
import { isDesktop, isAndroid, isIOS } from "react-device-detect";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ToastContainer } from "react-toastify";
import {
  DlgStripePayment,
  DlgApplePayment,
  DlgGooglePayment,
  FacebookCircularProgress,
  DlgLoginConfirm,
} from "components";
import { PriceCard } from "./components";
import { MAX_ARTICLE_WIDTH, MIN_CARD_WIDTH , GRAPHQL_ERROR} from "constants/types";
import { stripeService } from "services";
import * as ROUTES from "constants/routes";
import { ToastSuccess, ToastError } from "utility/toast";
import { logger } from "utility/logging";
import { GraphqlService } from "services";
import { setError } from "dataapis";
import { withAuthentication, withAuthorization } from "session";
import DlgMembershipActions from "components/Dialogs/DlgMembershipActions";

const condition = (authUser) => !!authUser && authUser.uid !== "";



const RAVEN_MEMBERSHIP =0
const stripePromise = stripeService
  .getPublicStripeKey()
  .then((key) => loadStripe(key));

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_ARTICLE_WIDTH,
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  quitbtn: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  container: {
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  image: {
    width: "100%",
  },
  titlediv: {
    marginTop: -20,
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: "42px",
    fontFamily: "Libre Baskerville",
    lineHeight: "50px",
  },
  descdiv: {
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  checkitem: {
    marginTop: theme.spacing(0.5),
  },
  checkmark: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(2),
  },
  description: {
    fontSize: "20px",
    fontFamily: "Libre Baskerville",
    lineHeight: "24px",
  },
  pricegroup: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(1),
  },
  subscribediv: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  submitBtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "10px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  progress: {
    width: 40,
    marginLeft: "auto",
    marginRight: "auto",
  },
  success: {
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  policydiv: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  nocommit: {
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 300,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  policy: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 600,
    lineHeight: "18px",
    color: theme.palette.text.secondary,
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  price_desc: {
    fontSize: "42px",
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: "16px",
    color: theme.palette.text.primary,
  },
  price_desc1: {
    fontSize: "11px",
    fontFamily: "Roboto",
    marginTop: 10,
    fontWeight: 300,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  price_content: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  actioncontainer: {
    margin: theme.spacing(4),
  },
  actiondiv: {
    marginLeft: theme.spacing(2),
    verticalAlign: "middle",
    cursor: "pointer",
  },
  icon: {
    width: 24,
    height: 24,
  },
  actiontext: {
    display: "inline",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "24px",
    verticalAlign: 'text-bottom',
  }
});

class Membership extends React.Component {
  constructor(props) {
    super(props);

    // this.subscribeButton = React.createRef();

    this.state = {
      selected: 0,
      paid: false,
      showPaymentDlg: false,
      selectedPaymentType:0,
      prices:[],
      amount:0,
      loginDlg:false,
      status:'',
      showDialog: false, 
      actionType: "",
    };

    this.handleQuit = this.handleQuit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);

    this.handlePaymentSuccces = this.handlePaymentSuccces.bind(this);
    this.handlePaymentCancel = this.handlePaymentCancel.bind(this);
    this.handleCancelMembership = this.handleCancelMembership.bind(this);
    this.handleApprove = this.handleApprove.bind(this)
    this.handleDlgClose = this.handleDlgClose.bind(this)
  }

  handleQuit = () => {
    this.props.history.goBack();
  };

  handleSelect = (selectNo) => {
    if (selectNo === this.state.selected) {
      return;
    }
    const { prices } = this.state
    console.log("Membership selected ",prices[selectNo].payment_type)
    const amountOfSelectedPlan = prices.find((item)=>item.payment_type === selectNo)
    this.setState({
      ...this.state,
      selected: selectNo,
      selectedPaymentType:selectNo,
      amount:amountOfSelectedPlan.amount
    });
  };

  componentDidMount = async() =>{
    const isHandleLogin = this.showLoginDail()
    if(!isHandleLogin){
      return
    }
    this.currentPlanUpdations()
  }

  currentPlanUpdations = async () =>{
    const { authUser } = this.props;
    const currentPlan = JSON.parse(authUser.current_plans) ?? []  
   // const paymentSelected  = currentPlan.includes(RAVEN_MEMBERSHIP)  ?1 : 0
    const paymentSelected= RAVEN_MEMBERSHIP;
    console.log("current plans in current plans updation",currentPlan)
    console.log('paymentSelected ',paymentSelected)
    const statuToShow = currentPlan.length === 0 ? 'Free' : currentPlan.includes(RAVEN_MEMBERSHIP) ?'Member':''    

    const gqlservice = new GraphqlService();
    const product_prices =await gqlservice.get_prices()
    console.log("prricesss",product_prices)
    if(product_prices.data.status_code === GRAPHQL_ERROR){
        setError("Error getting price details")
        return
    }
    const prices_to_be_shown = product_prices.data.raven_membership_products;
    const amountOfSelectedPlan = prices_to_be_shown.find((item)=>item.payment_type === paymentSelected)
    console.log("pricesssss_to_show",prices_to_be_shown ,"------- amountOfSelectedPlan ",amountOfSelectedPlan)
    this.setState({
      ...this.state,
      prices:prices_to_be_shown,
      amount:amountOfSelectedPlan ? amountOfSelectedPlan.amount:0,
      selectedPaymentType:paymentSelected,
      status:statuToShow
    })
  }

  showLoginDail = () =>{
    const { loggedIn } = this.props
    if(!loggedIn){
      this.setState({
        loginDlg:true
      })
      return false
    }
    return true
  }

  handleSubscribe = () => {
    // this.subscribeButton.className = "subscribe_button onclic";
    this.setState({
      ...this.state,
      showPaymentDlg: true,
    });
  };

  handlePaymentSuccces = async (paymentIntent) => {
    const { authUser } = this.props
    logger.log("Payment Success :", paymentIntent);
    const { paymentType } = paymentIntent
    const currentPlan = JSON.parse(authUser.current_plans) ?? []   
    if (currentPlan.includes(paymentType)) {
      console.log("Pay-as-you-go or Subscription is  already exists for user.");
    } else {
      currentPlan.push(paymentType);
      console.log("Updated subscription for user.");
    }
    authUser.current_plans=JSON.stringify(currentPlan)
    this.props.setAuthUser(authUser)
    this.setState({
      ...this.state,
      showPaymentDlg: false
    });
    const paymentTypeToShow = paymentType === 0 ? 'You have joined Raven Membership successfully' : paymentType === 1 ? 'You have successfuly purchased Pay-as-you-go plan' : 'You have successfuly purchased Volume Discount plan'

    ToastSuccess(paymentTypeToShow);

    // update user info
    if(paymentType === RAVEN_MEMBERSHIP){
      this.props.updateUserPaid(true);
    }
    this.currentPlanUpdations()
  };

  handlePaymentError = (paymentError) => {
    this.setState({
      ...this.state,
      showPaymentDlg: false,
    });
    ToastError(paymentError.message);
  };

  handlePaymentCancel = () => {
    this.setState({
      ...this.state,
      showPaymentDlg: false,
    });
  };

  renderDescItem = (classes, description) => {
    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) width = MAX_ARTICLE_WIDTH;
    if (width < MIN_CARD_WIDTH) width = MIN_CARD_WIDTH;
    width = width - 104;
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item className={classes.checkitem}>
          <img
            className={classes.checkmark}
            alt="desc-item"
            src={"/static/images/icons/light/checkmark.png"}
          />
        </Grid>
        <Grid item style={{ width: width }}>
          <Typography className={classes.description}>{description}</Typography>
        </Grid>
      </Grid>
    );
  };

  handleCancelMembership = () => {
    const { loggedIn } = this.props
    if(!loggedIn){
      this.setState({
        loginDlg:true
      })
      return
    }
    this.setState({
      showDialog: true,
      actionType: "cancel",
    });
  }

  handleDlgClose = () => {
    this.setState({ showDialog: false, actionType: "" });
  };

  handleApprove = async ( actionType) => {
    console.log(" handle approve for cancel subscription",actionType)
    this.setState({ showDialog: false, actionType: "" });
    const { authUser } = this.props
    const currentPlan = JSON.parse(authUser.current_plans) ?? []   
    let removedSubscription 
    if(authUser.credits === 0 && actionType=== 'refund'){
      ToastError(`You have 0 credits so refund can't be initiated`)
      return
    }
    if(actionType ==='cancel'){
      const canceled = await stripeService.cancelSubscription(authUser.uid)
      console.log("canceled subscription response :" , canceled)
      if(canceled.error){
        ToastError(canceled.error.message)
        return
      }
      const isRefund = canceled.isRefund
      authUser.credits =isRefund ? 0 : authUser.credits
      if (currentPlan.includes(parseInt(canceled.paymentType))) {
        removedSubscription = currentPlan.filter((item) => item !== parseInt(canceled.paymentType))
        console.log("Pay-as-you-go or Subscription is  already exists for user.");
      } else {
        console.log("Nothing to remove.");
      }
      authUser.current_plans=JSON.stringify(removedSubscription)
      this.props.setAuthUser(authUser)
      this.currentPlanUpdations()
      ToastSuccess(canceled.message)
      return
    }else{
      const canceled = await stripeService.requestRefund(authUser.uid)
      console.log("canceled subscription response :" , canceled)
      if(canceled.error){
        ToastError(canceled.error.message)
        return
      }
      authUser.credits = 0
      if (currentPlan.includes(parseInt(canceled.paymentType))) {
        removedSubscription = currentPlan.filter((item) => item !== parseInt(canceled.paymentType))
        console.log("Removed the current plan fot the  user.");
      } else {
        console.log("Nothing to remove.");
      }
      authUser.current_plans=JSON.stringify(removedSubscription)
      this.props.setAuthUser(authUser)
      this.currentPlanUpdations()
      ToastSuccess(canceled.message)
      return
    }

  };

  render() {
    const { classes, theme_mode, authUser } = this.props;
    const currentPlan = JSON.parse(authUser.current_plans) ?? []   
    console.log("auth user inside membership page", authUser)
    const { selected, paid, showPaymentDlg ,selectedPaymentType , prices , amount ,loginDlg, status, actionType, showDialog} = this.state;
    // const prices = [
    //   {
    //     duration: "Monthly + 7-day free trial",
    //     price: "$7.50/month",
    //     description: "10 credits a month Member benefits",
    //   },
    //   {
    //     duration: "Pay-as-you-go",
    //     price: "$10/10 Credits",
    //     description: "10h of audio transcription 200 Questions",
    //   },
    // ];

    const width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;

    let quitButtonPos = 8;
    if (width > MAX_ARTICLE_WIDTH) {
      quitButtonPos = (width - MAX_ARTICLE_WIDTH) / 2 + 8;
    }

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleQuit}
          className={classes.quitbtn}
          style={{ right: quitButtonPos }}
        >
          <img
            className={classes.actionimg}
            alt="cancel"
            src="/static/images/delete.png"
          />
        </IconButton>
        <div className={classes.container}>
          <img
            className={classes.image}
            alt={"membership"}
            src={"/static/images/site/membership.png"}
          />
          <div className={classes.titlediv}>
            <Typography className={classes.title}>
              Raven
              <br />
              Membership
            </Typography>
          </div>

          <div className={classes.descdiv}>
            {this.renderDescItem(classes, "Create custom feeds")}
            {this.renderDescItem(classes, "Transcribe, translate and summarize up to 50 podcasts and videos a month")}
            {this.renderDescItem(classes, "No ads")}
            {this.renderDescItem(
              classes,
              "Join discussions and an amazing community"
            )}
          </div>
          <div className={classes.actioncontainer}>
          <div className={classes.actiondiv}>
            <img
              className={classes.icon}
              alt="join discord"
              src={`/static/images/icons/${theme_mode}/status_shield.png`}
            />
            <Typography className={classes.actiontext}>
              {` Status: ${status}`}
            </Typography>
          </div>
          </div>
          {
            !currentPlan.includes(0) ? 
            <div className={classes.pricegroup}>
            <Grid container justifyContent="center" spacing={2}>
              {prices.map((price, index) => (
                <Grid
                  item
                  key={`price-${index}`}
                  onClick={(e) => this.handleSelect(price.payment_type)}
                >
                  {/* <PriceCard
                    theme_mode={theme_mode}
                    recommended={index === 0}
                    selected={price.payment_type === selectedPaymentType}
                    duration={price.title}
                    price={price.price_to_display}
                    description={price.description}
                  /> */}
                   <div className={classes.price_content}>
		            <Typography className={classes.price_desc}>
		            {price.title}
		            </Typography>
		            <Typography className={classes.price_desc1}>
		            USD
		            </Typography>
		          </div>
                </Grid>
              ))}
            </Grid>
          </div>:
           <div className={classes.pricegroup}>
           <Grid container justifyContent="center" spacing={2}>
             {
             prices.filter((item)=>{
              if(item.payment_type !== 0){
                return { ...item}
              }
             }).map((price, index) => (
               <Grid
                 item
                 key={`price-${index}`}
                 onClick={(e) => this.handleSelect(price.payment_type)}
               >
                 <PriceCard
                   theme_mode={theme_mode}
                  //  recommended={index === 0}
                   selected={price.payment_type === selectedPaymentType}
                   duration={price.title}
                   price={price.price_to_display}
                   description={price.description}
                 />
               </Grid>
             ))}
           </Grid>
         </div>
          }

          <div className={classes.subscribediv}>
            {!showPaymentDlg && (status!="Member") && (
              <Button
                className={classes.submitBtn}
                onClick={this.handleSubscribe}
              >
                Purchase
              </Button>
            )}
            {status=="Member" && (
              <Button
                className={classes.submitBtn}
                onClick={this.handleCancelMembership}
              >
                Cancel Membership
              </Button>
            )}
            {!paid && showPaymentDlg && (
              <div className={classes.progress}>
                <FacebookCircularProgress />
              </div>
            )}
            {paid && (
              <Typography className={classes.success}>
                You have joined Raven Membership successfully
              </Typography>
            )}
            {/* <button 
              id="subscribe_button" 
              class="subscribe_button" 
              ref={element => this.subscribeButton = element}
              onClick={this.handleSubscribe}
            /> */}
          </div>
          <div className={classes.policydiv}>
            <Typography className={classes.nocommit}>
              No Commitment, Cancel Anytime
            </Typography>
          </div>
          <div className={classes.policydiv}>
            <Typography className={classes.policy}>
              <Link className={classes.link} to={ROUTES.TERMS_OF_SERVICE}>
                Terms of Use
              </Link>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link className={classes.link} to={ROUTES.PRIVACY_POLICY}>
                Privacy Police
              </Link>
            </Typography>
          </div>
        </div>
        { actionType &&
          <DlgMembershipActions
            open={showDialog}
            actionType={actionType}
            handleApprove={this.handleApprove}
            onClose={this.handleDlgClose}
            credits={authUser.credits}
          />
        }
        <Elements stripe={stripePromise}>
          {isDesktop && (
            <DlgStripePayment
              open={showPaymentDlg}
              paymentType={selectedPaymentType}
              user={authUser}
              onSuccess={this.handlePaymentSuccces}
              onError={this.handlePaymentError}
              onCancel={this.handlePaymentCancel}
            />
          )}
          {isIOS && amount!=0 && (
            <DlgApplePayment
              open={showPaymentDlg}
              paymentType={selectedPaymentType}
              user={authUser}
              onSuccess={this.handlePaymentSuccces}
              onError={this.handlePaymentError}
              onCancel={this.handlePaymentCancel}
              amount={amount}
            />
          )}
          {isAndroid && amount!=0 && (
            <DlgGooglePayment
              open={showPaymentDlg}
              paymentType={selectedPaymentType}
              user={authUser}
              onSuccess={this.handlePaymentSuccces}
              onError={this.handlePaymentError}
              onCancel={this.handlePaymentCancel}
              amount={amount}
            />
          )}
        </Elements>
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <ToastContainer />
      </div>
    );
  }
}

Membership.propTypes = {};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withAuthentication,
  withAuthorization(condition),
)(Membership);
