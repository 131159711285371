import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { logger } from "utility/logging";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: '24px',
    height: '24px',
    minWidth: 180,
    lineHeight: '14px',
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
  ban_icon: {
    marginRight: 4,
  },
  divider: {
    height: 16,
  },
});

class PopMenuThread extends React.Component {
  render() {
    const { 
      classes, 
      theme,
      type,
      isPoster,
      onDelete, 
      onReport,
      onShare,
    } = this.props;

    logger.log("thread type : ", type);

    return (
      <div>
        <MenuList className={classes.menu_list}>
          {isPoster &&
            <MenuItem className={classes.menu_item} onClick={onDelete}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"delete"}
                  src={`/static/images/icons/${theme}/delete.png`}
                />
              </span>
              Delete Thread
            </MenuItem>
          }

          {!isPoster &&
            <MenuItem className={classes.menu_item} onClick={onReport}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"report"}
                  src={`/static/images/icons/${theme}/report.png`}
                />
              </span>
              Report Thread
            </MenuItem>
          }

          <MenuItem className={classes.menu_item} onClick={onShare}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"share"}
                src={`/static/images/icons/${theme}/cshare.png`}
              />
            </span>
            Share Link
          </MenuItem>

        </MenuList>
      </div>
    );
  }
}

PopMenuThread.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  type: PropTypes.number,
  isPoster: PropTypes.bool,
  onDelete: PropTypes.func,
  onReport: PropTypes.func,
  onShare: PropTypes.func,
};

export default withStyles(styles)(PopMenuThread);
