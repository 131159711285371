import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { BasicAppBar, DlgLoginConfirm } from "components";
import * as ROUTES from "constants/routes";
import { GRAPHQL_ERROR, MAX_ARTICLE_WIDTH, THEME_MODE_DARK } from "constants/types";
import { PriceCard } from "views/Membership/components";
import { isDesktop, isAndroid, isIOS } from "react-device-detect";
import DlgMembershipActions from "components/Dialogs/DlgMembershipActions";
import { loadStripe } from "@stripe/stripe-js";
import { stripeService, withFirebase } from "services";
import {
  DlgStripePayment,
  DlgApplePayment,
  DlgGooglePayment,
  FacebookCircularProgress,
} from "components";
import { Elements } from "@stripe/react-stripe-js";
import { logger } from "utility/logging";
import { ToastError, ToastSuccess } from "utility/toast";
import { ToastContainer } from "react-toastify";
import { GraphqlService } from "services";
import { withAuthentication, withAuthorization } from "session";
import { setError } from "dataapis";

const condition = (authUser) => !!authUser && authUser.uid !== "";

const stripePromise = stripeService
  .getPublicStripeKey()
  .then((key) => loadStripe(key));

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    // marginTop: 56,
    // [theme.breakpoints.up('sm')]: {
    //   marginTop: 64,
    // },
    padding: theme.spacing(4),
    textAlign: "center",
    backgroundColor: theme.palette.background.default,
  },
  logo: {
    width: 200,
    height: 200,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  logotxt: {
    color: theme.palette.text.primary,
    fontSize: 60,
    fontFamily: "inherit",
    fontWeight: 700,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  actioncontainer: {
    margin: theme.spacing(4),
  },
  actiondiv: {
    marginLeft: theme.spacing(4),
    verticalAlign: "middle",
    cursor: "pointer",
  },
  subscribediv: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  submitBtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "10px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
  actionicon: {
    width: 24,
    height: 24,
    marginBottom: -5,
    marginRight: 3,
    "@media (max-width: 500px)": {
      width: 18,
      height: 18,
    },
  },
  actiontext: {
    display: "inline",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "24px",
    verticalAlign: 'text-bottom',
  },
  actntext:{
    display: "inline",
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "22px",
    "@media (max-width: 500px)": {
      fontSize: "17px",
    },
  },
  forwardicon: {
    fontSize: 18,
    marginTop: 8,
    color: theme.palette.text.primary,
  },
  spacediv: {
    marginTop: theme.spacing(4),
  },
  postButton: {
    width: "70%",
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: 25,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(5),
    textTransform: "initial",
    zIndex: 9999,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
    marginLeft: "50px",
    "@media (max-width: 300px)": {
      marginLeft: "0px",
    },
  },
});
const RAVEN_SUBSCRIPTION = 0;
const PAY_AS_YOU_GO = 1;
const VOLUME_DISCOUNT = 2;

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      loginDlg: false,
      actionType: "",
      selected:0,
      paid: false,
      showPaymentDlg: false,
      selectedPaymentType:0,
      prices:[],
      status:'',
      currentPlans:[],
      amount:0
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handlePurchase = this.handlePurchase.bind(this)
    this.handleCancelMembership = this.handleCancelMembership.bind(this)
    this.handleRequestRefund = this.handleRequestRefund.bind(this)
    this.handleApprove = this.handleApprove.bind(this)
    this.handleDlgClose = this.handleDlgClose.bind(this)
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.handlePaymentSuccces = this.handlePaymentSuccces.bind(this);
    this.handlePaymentCancel = this.handlePaymentCancel.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);
  }

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
      // shareDlg: false,
      // anchorEl: null
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);

  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
      // anchorEl: null
    });
  };
  
  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  }

  handleSelect = (selectNo) => {
    if (selectNo === this.state.selected) {
      return;
    }
    const { prices } = this.state
    console.log("pricess",prices)
    const amountOfSelectedPlan = prices.find((item)=>item.payment_type === selectNo)

    // const selectedPaymentType = prices.find
    this.setState({
      ...this.state,
      selected: selectNo,
      selectedPaymentType:selectNo,
      amount:amountOfSelectedPlan.amount
    });
  };

  componentDidMount = async() =>{
    const isHandleLogin = this.showLoginDail()
    if(!isHandleLogin){
      return
    }
    this.currentPlanUpdations()
  }
  
  currentPlanUpdations = async () =>{
    const { authUser } = this.props;
    const current_plans = JSON.parse(authUser.current_plans) ?? []   
    console.log("current plans :",current_plans) 
    //const statuToShow = current_plans.length === 0 ? 'Free' : current_plans.includes(RAVEN_SUBSCRIPTION) ?'Member':current_plans.includes(PAY_AS_YOU_GO) ?'Pay-as-you-go':'Volume Discount'    
    const statuToShow = current_plans.length === 0 ? 'Free' : current_plans.includes(RAVEN_SUBSCRIPTION) ?'Member':''    
    //const paymentPlanSelected = statuToShow === "Member"? PAY_AS_YOU_GO : RAVEN_SUBSCRIPTION
    const paymentPlanSelected= RAVEN_SUBSCRIPTION

    console.log("payment of selected plan ",paymentPlanSelected)
    const gqlservice = new GraphqlService();
    const product_prices =await gqlservice.get_prices()
    if(product_prices.data.status_code === GRAPHQL_ERROR){
      setError("Error getting price details")
      return
    }
    const prices_to_be_shown = product_prices.data.raven_membership_products;
    const amountOfSelectedPlan = prices_to_be_shown.find((item)=>item.payment_type === paymentPlanSelected)
    this.setState({
      ...this.state,
      prices:prices_to_be_shown,
      status:statuToShow,
      selectedPaymentType:paymentPlanSelected,
      amount:amountOfSelectedPlan ? amountOfSelectedPlan.amount:0
    })

  }

  handleSubscribe = () => {
    // this.subscribeButton.className = "subscribe_button onclic";
    const isLoggedIn = this.showLoginDail()
    if(!isLoggedIn){
      return
    }
    this.setState({
      ...this.state,
      showPaymentDlg: true,
    });
  };

  handlePurchase = () => {
  };

  handlePaymentSuccces = async (paymentIntent) => {
    logger.log("Payment Success :", paymentIntent);
    const { paymentType } = paymentIntent
    const { prices } = this.state
    const { authUser } = this.props
    const creditsAdded =  prices.reduce((accumulator, item) => {
      if (item.payment_type === paymentType) {
          return accumulator + item.credits;
      }
      return accumulator;
  }, 0)
  authUser.credits = Number((authUser.credits + creditsAdded).toFixed(2))
  const currentPlan = JSON.parse(authUser.current_plans) ?? []   
  if (currentPlan.includes(paymentType)) {
    console.log("Pay-as-you-go or Subscription is  already exists for user.");
  } else {
    currentPlan.push(paymentType);
    console.log("Updated subscription for user.");
  }
  authUser.current_plans=JSON.stringify(currentPlan)
  this.props.setAuthUser(authUser)
  this.currentPlanUpdations()

    this.setState({
      ...this.state,
      showPaymentDlg: false,
    });
    const paymentTypeToShow = paymentType === 0 ? 'You have joined Raven Membership successfully' : paymentType === 1 ? 'You have successfuly purchased Pay-as-you-go plan' : 'You have successfuly purchased Volume Discount plan'
    ToastSuccess(paymentTypeToShow);

    // update user info
    // this.props.updateUserPaid(true);
  };
  handlePaymentError = (paymentError) => {
    this.setState({
      ...this.state,
      showPaymentDlg: false,
    });
    ToastError(paymentError.message);
  };
  handleRequestRefund = () => {
    const { loggedIn } = this.props
    if(!loggedIn){
      this.setState({
        loginDlg:true
      })
      return
    }
    this.setState({
      showDialog: true,
      actionType: "refund",
    });
  }
  handlePaymentCancel = () => {
    this.setState({
      ...this.state,
      showPaymentDlg: false,
    });
  };

  handleCancelMembership = () => {
    const { loggedIn } = this.props
    if(!loggedIn){
      this.setState({
        loginDlg:true
      })
      return
    }
    this.setState({
      showDialog: true,
      actionType: "cancel",
    });
  }

  handleDlgClose = () => {
    this.setState({ showDialog: false, actionType: "" });
  };

  handleApprove = async ( actionType) => {
    console.log(" handle approve for cancel subscription",actionType)
    this.setState({ showDialog: false, actionType: "" });
    const { authUser } = this.props
    const currentPlan = JSON.parse(authUser.current_plans) ?? []   
    let removedSubscription 
    if(authUser.credits === 0 && actionType=== 'refund'){
      ToastError(`You have 0 credits so refund can't be initiated`)
      return
    }
    if(actionType ==='cancel'){
      const canceled = await stripeService.cancelSubscription(authUser.uid)
      console.log("canceled subscription response :" , canceled)
      if(canceled.error){
        ToastError(canceled.error.message)
        return
      }
      const isRefund = canceled.isRefund
      authUser.credits =isRefund ? 0 : authUser.credits
      if (currentPlan.includes(parseInt(canceled.paymentType))) {
        removedSubscription = currentPlan.filter((item) => item !== parseInt(canceled.paymentType))
        console.log("Pay-as-you-go or Subscription is  already exists for user.");
      } else {
        console.log("Nothing to remove.");
      }
      authUser.current_plans=JSON.stringify(removedSubscription)
      this.props.setAuthUser(authUser)
      this.currentPlanUpdations()
      ToastSuccess(canceled.message)
      return
    }else{
      const canceled = await stripeService.requestRefund(authUser.uid)
      console.log("canceled subscription response :" , canceled)
      if(canceled.error){
        ToastError(canceled.error.message)
        return
      }
      authUser.credits = 0
      if (currentPlan.includes(parseInt(canceled.paymentType))) {
        removedSubscription = currentPlan.filter((item) => item !== parseInt(canceled.paymentType))
        console.log("Removed the current plan fot the  user.");
      } else {
        console.log("Nothing to remove.");
      }
      authUser.current_plans=JSON.stringify(removedSubscription)
      this.props.setAuthUser(authUser)
      this.currentPlanUpdations()
      ToastSuccess(canceled.message)
      return
    }

  };

  showLoginDail = () =>{
    const { loggedIn } = this.props
    if(!loggedIn){
      this.setState({
        loginDlg:true
      })
      return false
    }
    return true
  }

  render() {
    const { classes, theme_mode  , authUser} = this.props;
    const { showDialog, actionType , amount  , showPaymentDlg ,paid ,  selectedPaymentType , prices , status , loginDlg} = this.state
    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_ARTICLE_WIDTH}
            title={"Account"}
            onNavBack={this.handleNavBack}
          />
        </div>

        <div className={classes.container}>
          <img
            alt="logo"
            src={`/static/images/site/about_logo.png`}
            className={classes.logo}
          />
          <Typography className={classes.logotxt}>
            Raven
          </Typography>
        </div>
        <div className={classes.actioncontainer}>
          <div className={classes.actiondiv} onClick={this.handleClickContactUs}>
            <img
              className={classes.icon}
              alt="balance"
              src={`/static/images/icons/${theme_mode}/dollar.png`}
            />
            <Typography className={classes.actiontext}>
              Balance: {Number(authUser?.credits?.toFixed(2))}
            </Typography>
          </div>
          <div className={classes.actiondiv} onClick={this.handleClickJoinDiscord}>
            <img
              className={classes.icon}
              alt="join discord"
              src={`/static/images/icons/${theme_mode}/status_shield.png`}
            />
            <Typography className={classes.actiontext}>
              {` Status: ${status}`}
            </Typography>
          </div>

          <div className={classes.spacediv}></div>

          {status === "Member" ? (
            <div className={classes.actiondiv} style={{ WebkitTapHighlightColor: 'transparent' }}  onClick={this.handleClickTerms}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                {prices.filter((item)=>{
                  if(item.payment_type  !== 0){
                    return {...item}
                  }
                }).
                map((price, index) => (
                  <Grid
                    item
                    key={`price-${index}`}
                    onClick={(e) => this.handleSelect(price.payment_type)}
                  >
                    <PriceCard
                      theme_mode={theme_mode}
                      // recommended={index === 1}
                      selected = {price.payment_type === selectedPaymentType}
                      duration={price.title}
                      price={price.price_to_display}
                      description={price.description}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            <div className={classes.actiondiv} style={{ WebkitTapHighlightColor: 'transparent' }} onClick={this.handleClickTerms}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                {prices.map((price, index) => (
                  <Grid
                    item
                    key={`price-${index}`}
                    onClick={(e) => this.handleSelect(price.payment_type)}
                  >
                    <PriceCard
                      theme_mode={theme_mode}
                      recommended={index === 0}
                      selected = {price.payment_type === selectedPaymentType}
                      duration={price.title}
                      price={price.price_to_display}
                      description={price.description}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
           <div className={classes.subscribediv}>
            {!paid && !showPaymentDlg && (status!="Member") && (
              <Button
                className={classes.submitBtn}
                onClick={this.handleSubscribe}
              >
                Purchase
              </Button>
            )}
            {!paid && showPaymentDlg && (
              <div className={classes.progress}>
                <FacebookCircularProgress />
              </div>
            )}
            {paid && (
              <Typography className={classes.success}>
                You have joined Raven Membership successfully
              </Typography>
            )}
            {/* <button 
              id="subscribe_button" 
              class="subscribe_button" 
              ref={element => this.subscribeButton = element}
              onClick={this.handleSubscribe}
            /> */}
          </div>
          <div >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.footer}
            >
                <div onClick={this.handleCancelMembership}>
                  <img
                    className={classes.actionicon}
                    alt="balance"
                    src={`/static/images/icons/${theme_mode}/gear.png`}
                  />
                  <Typography className={classes.actntext}>
                    Cancel Membership
                  </Typography>
                </div>
                <div onClick={this.handleRequestRefund}>
                  <img
                    className={classes.actionicon}
                    alt="balance"
                    src={`/static/images/icons/${theme_mode}/legal.png`}
                  />
                  <Typography className={classes.actntext}>
                    Request Refund
                  </Typography>
                </div>

            </Grid>
          </div>
        </div>
        { actionType &&
          <DlgMembershipActions
            open={showDialog}
            actionType={actionType}
            handleApprove={this.handleApprove}
            onClose={this.handleDlgClose}
            credits={authUser.credits}
          />
        }
          <Elements stripe={stripePromise}>
          {isDesktop && (
            <DlgStripePayment
              open={showPaymentDlg}
              paymentType={selectedPaymentType}
              user={authUser}
              onSuccess={this.handlePaymentSuccces}
              onError={this.handlePaymentError}
              onCancel={this.handlePaymentCancel}
            />
          )}
          {isIOS && (
            <DlgApplePayment
              open={showPaymentDlg}
              paymentType={selectedPaymentType}
              user={authUser}
              onSuccess={this.handlePaymentSuccces}
              onError={this.handlePaymentError}
              onCancel={this.handlePaymentCancel}
              amount={amount}
            />
          )}
          {isAndroid &&  amount!==0 &&(
            <DlgGooglePayment
              open={showPaymentDlg}
              paymentType={selectedPaymentType}
              user={authUser}
              onSuccess={this.handlePaymentSuccces}
              onError={this.handlePaymentError}
              onCancel={this.handlePaymentCancel}
              amount={amount}
            />
          )}
        </Elements>
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  theme_mode: state.uiState.theme_mode,
  authUser: state.sessionState.authUser,
  loggedIn: state.sessionState.loggedIn,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withAuthentication,
  withAuthorization(condition)
)(Account);
