export const QUERY_FEED_ARTICLES = `
query feed_articles (
  $feed_id: String!,
  $pagesize: Int!,
  $offset: Int!
) {
  feed_articles(
    where: {
      feed_id: {_eq: $feed_id}
    },
    order_by: [
      {published: desc},
      {id: desc}
    ],
    limit: $pagesize,
    offset: $offset
  ) {
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      political_sentiments
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      home_cluster_members {
        id
        parent_id
        article_id
        article{
          nid
          source_id
          title
          author
          summary
          image
          text
          html
          summarized_text
          tr_title
          tr_summary
          tr_text
          translated
          branch
          country
          crawled_at
          media_url
          url
          author_image
          published
          data
          extra_data
          param1
          param2
          param3
          param4
          param5
          txt_param1
          txt_param2
          image_thumb
          author_image_thumb
          link_preview
          discussion_twitter
          discussion_reddit
          upvotes
          threads {
            id
            comments_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
}`;



export const QUERY_FEED_ARTICLES_NULLS_LAST = `
query feed_articles_nulls_last (
  $feed_id: String!,
  $pagesize: Int!,
  $offset: Int!
) {
  feed_articles(
    where: {
      feed_id: {_eq: "anglosphere"}
    }, 
    order_by: {published: desc_nulls_last},
    limit: $pagesize, 
    offset: $offset
  ) {
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      political_sentiments
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
            home_cluster_members {
        id
        parent_id
        article_id
        article{
          nid
          source_id
          title
          author
          summary
          image
          text
          html
          summarized_text
          tr_title
          tr_summary
          tr_text
          translated
          branch
          country
          crawled_at
          media_url
          url
          author_image
          published
          data
          extra_data
          param1
          param2
          param3
          param4
          param5
          txt_param1
          txt_param2
          image_thumb
          author_image_thumb
          link_preview
          discussion_twitter
          discussion_reddit
          upvotes
          threads {
            id
            comments_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
}`;


export const QUERY_FEED_ARTICLES_OF_BRANCH = `
query feed_articles_of_branch (
  $feed_id: String!,
  $branch: Int!,
  $pagesize: Int!,
  $offset: Int!
) {
  feed_articles (
    where: {
      feed_id: {_eq: $feed_id},
      branch: {_eq: $branch},
  	}, 
    order_by: [
      {published: desc},
      {id: desc}
    ],
    limit: $pagesize, 
    offset: $offset
  ) {
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      political_sentiments
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      home_cluster_members {
        id
        parent_id
        article_id
        article{
          nid
          source_id
          title
          author
          summary
          image
          text
          html
          summarized_text
          tr_title
          tr_summary
          tr_text
          translated
          branch
          country
          crawled_at
          media_url
          url
          author_image
          published
          data
          extra_data
          param1
          param2
          param3
          param4
          param5
          txt_param1
          txt_param2
          image_thumb
          author_image_thumb
          link_preview
          discussion_twitter
          discussion_reddit
          upvotes
          threads {
            id
            comments_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
}`;


export const QUERY_FEED_ARTICLES_OF_COUNTRY = `
query feed_articles_of_country (
  $feed_id: String!,
  $country: Int!,
  $pagesize: Int!,
  $offset: Int!  
) {
  feed_articles(
    where: {
      feed_id: {_eq: $feed_id},
      country: {_eq: $country}
    },
    order_by: [
      {published: desc},
      {id: desc}
    ],
    limit: $pagesize,
    offset: $offset
  ) {
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      political_sentiments
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      home_cluster_members {
        id
        parent_id
        article_id
        article{
          nid
          source_id
          title
          author
          summary
          image
          text
          html
          summarized_text
          tr_title
          tr_summary
          tr_text
          translated
          branch
          country
          crawled_at
          media_url
          url
          author_image
          published
          data
          extra_data
          param1
          param2
          param3
          param4
          param5
          txt_param1
          txt_param2
          image_thumb
          author_image_thumb
          link_preview
          discussion_twitter
          discussion_reddit
          upvotes
          threads {
            id
            comments_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
}`;


export const MUTATION_INSERT_FEED_ARTICLE = `
mutation insert_feed_article (
  $feed_id: String!,
  $article_id: String!,
  $branch: Int!,
  $published: Int!,
  $crawled_at: Int!
) {
  insert_feed_articles(
    objects: {
      feed_id: $feed_id,
      article_id: $article_id,
      branch: $branch,
      upvotes: 0,
      reposts: 0,
      published: $published,
      crawled_at: $crawled_at
    }
  ) {
    affected_rows
    returning {
      id
      feed_id
      article_id
      branch
      country
      upvotes
      reposts
      published
      crawled_at
    }
  }
}`;


export const QUERY_FEED_ARTICLES_IN_FEEDS = `
query feed_articles_in_feeds (
  $feed_ids: [String!]!,
  $pagesize: Int!,
  $offset: Int!
) {
  feed_articles(
    where: {
      feed_id: {_in: $feed_ids}
    },
    order_by: [
      {published: desc},
      {id: desc}
    ],
    limit: $pagesize,
    offset: $offset
  ) {
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      home_cluster_members {
        id
        parent_id
        article_id
        article{
          nid
          source_id
          title
          author
          summary
          image
          text
          html
          summarized_text
          tr_title
          tr_summary
          tr_text
          translated
          branch
          country
          crawled_at
          media_url
          url
          author_image
          published
          data
          extra_data
          param1
          param2
          param3
          param4
          param5
          txt_param1
          txt_param2
          image_thumb
          author_image_thumb
          link_preview
          discussion_twitter
          discussion_reddit
          upvotes
          threads {
            id
            comments_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
}`;


export const QUERY_FEED_ARTICLES_OF_BRANCH_IN_FEEDS = `
query feed_articles_of_branch_in_feeds (
  $feed_ids: [String!]!,
  $branch: Int!,
  $pagesize: Int!,
  $offset: Int!
) {
  feed_articles (
    where: {
      feed_id: {_in: $feed_ids},
      branch: {_eq: $branch},
  	}, 
    order_by: [
      {published: desc},
      {id: desc}
    ],
    limit: $pagesize, 
    offset: $offset
  ) {
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      home_cluster_members {
        id
        parent_id
        article_id
        article{
          nid
          source_id
          title
          author
          summary
          image
          text
          html
          summarized_text
          tr_title
          tr_summary
          tr_text
          translated
          branch
          country
          crawled_at
          media_url
          url
          author_image
          published
          data
          extra_data
          param1
          param2
          param3
          param4
          param5
          txt_param1
          txt_param2
          image_thumb
          author_image_thumb
          link_preview
          discussion_twitter
          discussion_reddit
          upvotes
          threads {
            id
            comments_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
}`;


export const QUERY_FEED_ARTICLES_OF_COUNTRY_IN_FEEDS = `
query feed_articles_of_country_in_feeds (
  $feed_ids: [String!]!,
  $country: Int!,
  $pagesize: Int!,
  $offset: Int!  
) {
  feed_articles(
    where: {
      feed_id: {_in: $feed_ids},
      country: {_eq: $country},
      branch: {_eq: 0}
    },
    order_by: [
      {published: desc},
      {id: desc}
    ],
    limit: $pagesize,
    offset: $offset
  ) {
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      summary_status
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      home_cluster_members {
        id
        parent_id
        article_id
        article{
          nid
          source_id
          title
          author
          summary
          image
          text
          html
          summarized_text
          tr_title
          tr_summary
          tr_text
          translated
          branch
          country
          crawled_at
          media_url
          url
          author_image
          published
          data
          extra_data
          param1
          param2
          param3
          param4
          param5
          txt_param1
          txt_param2
          image_thumb
          author_image_thumb
          link_preview
          discussion_twitter
          discussion_reddit
          upvotes
          threads {
            id
            comments_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
}`;