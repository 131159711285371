import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { 
  Button, 
  Typography, 
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withFirebase } from 'services';
import { withAuthentication } from "session";
import { BasicAppBar, WaitingSpinner } from "components";
import * as ROUTES from "constants/routes";
import { MAX_ARTICLE_WIDTH } from "constants/types";
import {
  inviteUser,
  isBanned,
} from "dataapis";
import { 
  ToastSuccess,
  ToastError 
} from "utility/toast";
import { Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { CallNumber } from '@ionic-native/call-number';
import { SMS } from '@ionic-native/sms';
import { logger } from "utility/logging";

const { Contacts } = Plugins;


const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  content: {
    position: "relative",
    padding: theme.spacing(1)
  },
  sectiontitle: {
    display: "block",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 0,
    color: theme.palette.text.primary,
    textTransform: "none",
  },
  useritem: {
    position: "relative",
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
  },
  image: {
    width: 40,
    height: 40,
  },
  name: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  invitetext: {
    paddingRight: 92,
    color: theme.palette.text.primary
  },
  invitebtn: {
    backgroundColor: theme.palette.background.default,
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
  },
  inviteicon: {
    padding: 4,
    width: 20,
    height: 20,
    color: theme.palette.primary.contrastText,
  },
  invitedbtn: {
    backgroundColor: "#1878F3",
    borderRadius: "20px",
    padding: "4px 10px",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1878F3",
    },
  },
});


class ContactsInvite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      invited: []
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleInvite = this.handleInvite.bind(this);
  }

  setError = message => {
    ToastError(message);
  };

  setWaiting = waiting => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  componentDidMount = async () => {
    await this.loadContacts();
  }

  async loadContacts() {
    if (isPlatform('android')) {
      let permission = await Contacts.getPermissions();
      if (!permission.granted) {
        return;
      }
    }
  
    Contacts.getContacts().then(result => {
      this.setState({
        ...this.state,
        contacts: result.contacts
      });
    });
  }

  handleNavBack = () => {
    this.props.history.goBack();
  };

  handleShare = () => {

  }

  handleInvite = (contact) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      return;
    } else {
      if (isBanned()) {
        ToastError("You've suspended.");
        return;
      }

      const message = `Hey ${contact.displayName} - I have an invite to Raven and want you to join. 🙂 I added you using ${contact.phoneNumbers[0].number}, so make sure to use that number when you register. Here is the link!
      https://newsraven-beta.web.app/join`;
      this.invite(contact, message);
    }
  }

  call = (contact) => {
    CallNumber.callNumber(contact.phoneNumbers[0].number, true);
  }

  invite = async (contact, message) => {
    logger.log("sendSms :", contact.phoneNumbers[0].number, message);

    // Send Invite Message
    if (isPlatform('android')) {
      let permission = await SMS.hasPermission();
      if (!permission) {
        return;
      }
    }

    await SMS
    .send(contact.phoneNumbers[0].number, message)
    .then(result => {
      let { invited } = this.state;
      invited.push(contact);

      this.setState({
        ...this.state,
        invited: invited
      });
      ToastSuccess('Invite sent');
    })
    .catch(e => {
      ToastError('Failed to send invite message');
      return;
    });

    // insert the invite to the graphql database
    this.setWaiting(true);
    const { authUser, selected_feed } = this.props;
    await inviteUser(contact.phoneNumbers[0].number, authUser.uid, selected_feed.id, true);
    this.setWaiting(false);
  }

  isInvited = (contact) => {
    const { invited } = this.state;
    return invited.find(invited_user =>
      invited_user.phoneNumbers[0].number === contact.phoneNumbers[0].number
    ) !== undefined;
  };

  render() {
    const { classes, theme_mode, requesting } = this.props;
    const { contacts } = this.state;

    const contact_image = `/static/images/icons/${theme_mode}/contacts.png`;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Contacts"}
            width={MAX_ARTICLE_WIDTH}
            onNavBack={this.handleNavBack}
          />
        </div>

        <div className={classes.content}>
          <Button className={classes.sectiontitle}>{`${contacts.length} Contacts`}</Button>
          <List>
            {contacts.map((contact, index) => 
              <ListItem className={classes.useritem} key={`contact-${index}`}>
                <ListItemAvatar>
                  <Avatar className={classes.image} src={contact_image} />
                </ListItemAvatar>
                <ListItemText
                  id={contact.contactId}
                  className={classes.invitetext}
                  primary={
                    <div className={"item"}>
                      <Typography className={classes.name}>
                        {contact.displayName}
                      </Typography>
                      <Typography className={classes.label}>
                        {contact.phoneNumbers[0].number}
                      </Typography>
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  {!this.isInvited(contact) ? (
                    <Button
                      className={classes.invitebtn}
                      startIcon={
                      <img
                          className={classes.inviteicon}
                          alt="invite"
                          src={`/static/images/icons/${theme_mode}/plus.png`}
                      />
                      }
                      onClick={e => this.handleInvite(contact)}
                    >
                      Invite
                    </Button>
                  ): (
                    <Button className={classes.invitedbtn}>
                      Invited
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            )}        
          </List>
        </div>

        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ContactsInvite);
