import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withFirebase } from 'services';
import { withAuthentication, withAuthorization } from "session";
import { BasicAppBar, Report, WaitingSpinner } from "components";
import { Source } from "./components";
import { GraphqlService } from "services";
import * as ROUTES from "constants/routes";
import { ACTIVITY_TYPE_FEED, ACTIVITY_DELETE } from "constants/activity";
import { GRAPHQL_SUCCESS, MAX_WINDOW_WIDTH } from "constants/types";
import { getAuthToken, updateCache, updateFeedNotifications } from "dataapis";
import { ToastError } from "utility/toast";


const condition = (authUser) => !!authUser && authUser.uid !== "";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    marginTop: theme.spacing(1),
  },
  report: {
    marginLeft: theme.spacing(4),
    margin: theme.spacing(1),
  },
});

class SourcesFlagged extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flagged: []
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleDeleteReport = this.handleDeleteReport.bind(this);
    this.handleDismissReport = this.handleDismissReport.bind(this);
    this.handleClickReporter = this.handleClickReporter.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount() {
    const { selected_feed, sources } = this.props;

    let flagged_sources = [];
    for (let report of selected_feed.source_reports) {
      let flagged_source = flagged_sources.find((source) => source.id === report.source_id);
      if (!flagged_source) {
        flagged_source = sources.find(
          (source) => source.id === report.source_id
        );
        if (!flagged_source) {
          continue;
        }
        flagged_source.reports = [report];
        flagged_sources.push(flagged_source);
      } else {
        flagged_source.reports.push(report);
      }
    }

    this.setState({
      ...this.state,
      flagged: flagged_sources,
    });
  }


  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    this.props.history.goBack();
  }

  getSource = (report_id) => {
    const { flagged } = this.state;

    for (let source of flagged) {
      for (let source_report of source.reports) {
        if (report_id === source_report.id) {
          return {...source};
        }
      }
    }
    return null;
  }

  // updateReport = (report) => {
  //   const { flagged } = this.state;

  //   let flagged_sources = flagged.slice();
  //   for (let source of flagged_sources) {
  //     for (let source_report of source.reports) {
  //       if (report.id === source_report.id) {
  //         source_report = report;
  //       }
  //     }
  //   }

  //   this.setState({
  //     ...this.state,
  //     flagged: flagged_sources
  //   });
  // }

  
  deleteReport = (report_id) => {
    const { flagged } = this.state;

    const flagged_sources = [];
    for (let source of flagged) {
      let reports = source.reports.filter(report => report.id !== report_id);
      if (reports.length > 0) {
        source.reports = reports;
        flagged_sources.push(source);
      }
    }

    this.setState({
      ...this.state,
      flagged: flagged_sources
    });
  }

  deleteReports = (source_id) => {
    const { flagged } = this.state;

    const flagged_sources = flagged.filter(source => source.id !== source_id);
    this.setState({
      ...this.state,
      flagged: flagged_sources
    });
  }

  handleDeleteReport = async (report) => {
    const { authUser, selected_feed } = this.props;

    const source = this.getSource(report.id);
    if (!source) {
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice.delete_source_reports(report.source_id)
      .then(result => {
        return this.deleteReports(report.source_id);
      })
      .then(result => {
        return gqlservice.delete_feed_source(selected_feed.id, report.source_id);
      })
      .then(result => {
        return gqlservice.feed_by_id(selected_feed.id);
      })
      .then(result => {
        const feeds = result.data.feeds;
        if (feeds.length > 0) {
          this.props.selectFeed(feeds[0]);
        }
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_DELETE,
      object: `the source ${source.name}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // insert feed source changes
    const feed_source_change = {
      feed_id: selected_feed.id,
      source_id: source.id,
      removed: true,
      created_by: authUser.uid
    };
    let result = await gqlservice.insert_feed_source_changes([feed_source_change]);
    if (result.status_code !== GRAPHQL_SUCCESS || result.data.insert_feed_source_change.affected_rows === 0) {
      this.setError("Failed to insert feed source changes!");
    }

    await updateFeedNotifications(selected_feed);

    await updateCache();

    this.setWaiting(false);
  }

  handleDismissReport = async (report) => {
    const { authUser, selected_feed } = this.props;

    const source = this.getSource(report.id);
    if (!source) {
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    await gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice.delete_source_report(report.id)
      .then(result => {
        this.deleteReport(report.id);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_DELETE,
      object: `the report of source ${source.name}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
      
    await updateFeedNotifications(selected_feed);

    this.setWaiting(false);
  }

  handleClickReporter = (report) => {
    const user_id = report.reported_user.uid;
    const path = `/${ROUTES.USER_PREFIX}/${user_id}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  render() {
    const { classes, theme_mode, requesting } = this.props;
    const { flagged } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Flagged Sources"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <Grid container className={classes.container} style={{width: window.innerWidth}}>
          {flagged.map((source, index) => (
            <div key={index}>
              <Grid item key={`source-${index}`}>
                <Source source={source} theme={theme_mode} />
              </Grid>
              <div>
                {source.reports.map((report, rindex) => (
                  <Grid
                    item
                    key={`source-${index}-report-${rindex}`}
                    className={classes.report}
                  >
                    <Report
                      report={report}
                      theme_mode={theme_mode}
                      onDelete={this.handleDeleteReport}
                      onDismiss={this.handleDismissReport}
                      onClickReporter={this.handleClickReporter}
                    />
                  </Grid>
                ))}
              </div>
            </div>
          ))}
        </Grid>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

SourcesFlagged.propTypes = {
  className: PropTypes.string,
  theme_mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SourcesFlagged);
