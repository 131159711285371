import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { 
  Dialog, 
  DialogContent, 
  DialogActions,
  Grid,
  Avatar,
  IconButton,
  Typography,
} from "@material-ui/core";
import { 
  THEME_MODE_LIGHT,
} from "constants/types";

const styles = (theme) => ({
  image: {
    width: 64,
    height: 64,
  },
  title: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  actionbutton: {
    padding: 0,
    margin: 0,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
});

class DlgProject extends React.Component {

  render() {
    const { 
      classes, 
      theme, 
      open, 
      project, 
      connect, 
      onApply, 
      onClose 
    } = this.props;

    const paper_style = {
      backgroundColor: theme === THEME_MODE_LIGHT ? "white" : "black",
      paddingBottom: 8,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme === THEME_MODE_LIGHT ? "black" : "white",
    };

    return (
      <Dialog 
        open={open}
        onClose={onClose}
        PaperProps={{ style: paper_style }}
      >
        <DialogContent>
          <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              {project === "scistarter" &&
                <img
                  className={classes.image}
                  alt="scistarter"
                  src={"/static/images/scistarter.png"}
                />
              }
              {project === "librarykit" &&
                <Avatar 
                  alt="librarykit"
                  className={classes.image}
                  src={"/static/images/librarykit.png"} 
                />
              }
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
              >
                {connect ? "Connect" : "Disconnect"}
              </Typography>
              {project === "scistarter" &&
                <Typography
                  className={classes.title}
                >
                  {"SciStarter?"}
                </Typography>
              }
              {project === "librarykit" &&
                <>
                  <Typography
                    className={classes.title}
                  >
                    {"Library Kit"}
                  </Typography>
                  <Typography
                    className={classes.title}
                  >
                    {"Project"}
                  </Typography>
                </>
              }
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <IconButton
            onClick={onApply}
            className={classes.actionbutton}
            style={{marginLeft: 16}}
          >
            <img
              className={classes.actionimg}
              alt="apply"
              src="/static/images/approve.png"
            />
          </IconButton>
          <IconButton
            onClick={onClose}
            className={classes.actionbutton}
          >
            <img
              className={classes.actionimg}
              alt="dismiss"
              src="/static/images/dismiss.png"
            />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  }
}

DlgProject.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  open: PropTypes.bool,
  project: PropTypes.string,
  connect: PropTypes.bool,
  onApply: PropTypes.func,
  onClose: PropTypes.func
};

DlgProject.defaultProps = {
};

export default withStyles(styles)(DlgProject);