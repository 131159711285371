import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
} from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { get_timestring, int2kstring, decodeHTMLEntities } from "utility/utils";
import {
  MIN_CARD_WIDTH,
  MAX_ARTICLE_WIDTH,
  BLANK_USER_IMAGE,
} from "constants/types";

const styles = (theme) => ({
  card: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_ARTICLE_WIDTH,
    backgroundColor: theme.palette.background.default,
  },
  cardframe: {
    marginLeft: 16,
    margin: 0,
    borderLeftStyle: "solid",
    borderLeftColor: `${theme.palette.text.secondary}`,
    // borderLeftWidth: 0,
  },
  cardframe_noborder: {
    marginLeft: 16,
    margin: 0,
  },
  carddiv: {
    marginLeft: 24,
    marginRight: 3,
    marginBottom: theme.spacing(1),
    borderRadius: 10,
    backgroundColor: theme.palette.background.article,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 32,
    height: 32,
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  name: {
    display: "inline",
    marginRight: 4,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  screenname: {
    display: "inline",
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.text.primary,
  },
  subtitle: {
    marginRight: 20,
  },
  replydecl: {
    fontSize: "14px",
    lineHeight: "18px",
    color: theme.palette.text.secondary,
  },
  content: {
    paddingTop: 4,
    paddingBottom: 0,
    // marginLeft: 40,
    // marginRight: 10,
    marginBottom: 8,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  date: {
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
  detail_txt: {
    fontFamily: "Roboto",
    display: "inline",
    overflowWrap: "break-word",
    color: theme.palette.text.primary,
  },
  media: {
    display: "block",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    // marginBottom: theme.spacing(1),
    borderRadius: 10,
  },
  reaction: {
    left: "50%",
    transform: "translate(-50%)",
    fontSize: 12,
    marginBottom: 4,
    color: theme.palette.text.primary,
  },
  reactiondiv: {
    marginLeft: 16,
  },
  reactionimg: {
    float: "left",
    width: 12,
    height: 12,
  },
  reactionvalue: {
    float: "left",
    marginLeft: 5,
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
});

class Thread extends React.Component {
  render() {
    const { classes, thread, isLast, theme_mode } = this.props;

    // user image
    let user_image = thread.user_image;
    if (!user_image) {
      user_image = BLANK_USER_IMAGE;
    }

    // username, screenname
    const user_name = thread.name;
    const handle = thread.handle;

    let image = "";

    const replies = int2kstring(thread.replyCount);
    const reposts = int2kstring(thread.repostCount);
    const likes = int2kstring(thread.likeCount);
    const published = get_timestring(thread.published);
    if (thread.image) {
      image = thread.image;
    }

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) width = MAX_ARTICLE_WIDTH;
    if (width < MIN_CARD_WIDTH) width = MIN_CARD_WIDTH;
    width = width - 16;

    return (
      <Card className={classes.card} style={{ width: width }}>
        <div
          className={isLast ? classes.cardframe_noborder : classes.cardframe}
          // style={{ borderLeftWidth: frameborder }}
        >
          <div className={classes.carddiv}>
            <CardHeader
              className={classes.header}
              avatar={
                <Avatar
                  alt={thread.user_name}
                  src={user_image}
                  className={classes.avatar}
                  classes={{ img: classes.avatar_img }}
                />
              }
              title={
                <div className={classes.title}>
                  <Typography className={classes.name}>
                    {decodeHTMLEntities(user_name)}
                  </Typography>
                  <Typography className={classes.screenname}>
                    @{handle}
                  </Typography>
                </div>
              }
            />
            <CardContent className={classes.content}>
              <Typography className={classes.date}>{published}</Typography>
            </CardContent>
            <CardContent className={classes.content}>
              <div
                className={classes.detail_txt}
                dangerouslySetInnerHTML={{ __html: thread.text }}
              />
            </CardContent>
            {image !== "" && (
              <LazyLoadImage alt={""} src={image} className={classes.media} />
            )}
            <CardActions>
              <Button
                className={classes.reaction}
                size="small"
                color="primary"
                target="_blank"
              >
                <div>
                  <img
                    alt={"replies"}
                    src={`/static/images/icons/${theme_mode}/reply.png`}
                    className={classes.reactionimg}
                  />
                  <Typography className={classes.reactionvalue}>
                    {replies}
                  </Typography>
                </div>
                <div className={classes.reactiondiv}>
                  <img
                    alt={"reposts"}
                    src={`/static/images/icons/${theme_mode}/retweet.png`}
                    className={classes.reactionimg}
                  />
                  <Typography className={classes.reactionvalue}>
                    {reposts}
                  </Typography>
                </div>
                <div className={classes.reactiondiv}>
                  <img
                    alt={"likes"}
                    src={`/static/images/icons/${theme_mode}/favorite.png`}
                    className={classes.reactionimg}
                  />
                  <Typography className={classes.reactionvalue}>
                    {likes}
                  </Typography>
                </div>
              </Button>
            </CardActions>
          </div>
        </div>
      </Card>
    );
  }
}

Thread.propTypes = {
  classes: PropTypes.object,
  thread: PropTypes.object,
};

const mapStateToProps = (state) => ({
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Thread));
