import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { 
  Grid, 
  Typography, 
  Menu, 
  IconButton 
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { 
  FeedImage,
  PopMenuFeedMod,
} from "components";
import { 
  GraphqlService,
  withFirebase,
} from "services";
import { 
  MIN_CARD_WIDTH, 
  MAX_ARTICLE_WIDTH, 
  RAVEN_PLACEHOLDER_IMAGE 
} from "constants/types";
import { 
  followFeed, 
  getAuthToken, 
  isBanned, 
  unfollowFeed 
} from "dataapis";
import { summarize_text } from "utility/utils";
import { ToastError, ToastWarning } from "utility/toast";
import _ from "lodash";

const styles = (theme) => ({
  root: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH - 32,
    maxWidth: MAX_ARTICLE_WIDTH - 32,
    margin: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  grid: {
    justifyContent: "left",
    flexWrap: "inherit",
  },
  catindicator: {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: 20,
    fontHeight: 20,
  },
  title: {
    position: "relative",
    textAlign: "left",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "24px",
    textTransform: "none",
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  description: {
    position: "relative",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 200,
    lineHeight: "20px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  expand: {
    position: "absolute",
    top: 8,
    right: 12,
    padding: 4,
    width: 24,
    height: 24,
    color: theme.palette.text.primary,
  },
  following: {
    cursor: 'pointer',
    position: "absolute",
    top: 32,
    right: 0,
    width: 48,
    height: 48,
  },
  tags: {
    position: "relative",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 200,
    lineHeight: "20px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    paddingRight: 48,
  },
});

class FeedDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      following: true,
      showfirst: false,
      anchorEl: null
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleFollow = this.handleFollow.bind(this);
    this.handleClickSocial = this.handleClickSocial.bind(this);

    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.handleApproveFeed = this.handleApproveFeed.bind(this);
    this.handleEditFeed = this.handleEditFeed.bind(this);
    this.handleDeleteFeed = this.handleDeleteFeed.bind(this);
    this.handleResignFeed = this.handleResignFeed.bind(this);
    this.handleShowFirst = this.handleShowFirst.bind(this);
  }

  setError = (message) => {
    ToastError(message);
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount() {
    const { followed_feeds, selected_feed, authUser } = this.props;
    const following =
      followed_feeds.find((item) => item.id === selected_feed.id) !== undefined;
    const showfirst = authUser.feeds_showfirst.find(item => item.feed_id === selected_feed.id) !== undefined;
    
    this.setState({
      following: following,
      showfirst: showfirst
    });
  }

  handleExpand = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  }

  handleFollow = async () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const following = !this.state.following;
    const { selected_feed, authUser } = this.props;
    let res = null
    if (following) {
      await followFeed(selected_feed);
    } else {
      res = await unfollowFeed(selected_feed);
    }
    if(res === false){
      return
    }

    this.setState({
      ...this.state,
      following: following,
    });
  };

  handleClickSocial = (socialtype) => {
    const { selected_feed } = this.props;
    if (socialtype === "twitter") {
      window.open(selected_feed.channel_twitter, "_blank");
    } else if (socialtype === "youtube") {
      window.open(selected_feed.channel_youtube, "_blank");
    } else if (socialtype === "tiktok") {
      window.open(selected_feed.channel_tiktok, "_blank");
    } else if (socialtype === "instagram") {
      window.open(selected_feed.channel_instagram, "_blank");
    }
  }

  handleCopyLink = () => {
    this.setState({
      ...this.state,
      anchorEl: null
    });
    this.props.onCopyLink();
  }
  
  handleApproveFeed = () => {
    this.setState({
      ...this.state,
      anchorEl: null
    });
    this.props.onApprove();
  }
  
  handleEditFeed = () => {
    this.setState({
      ...this.state,
      anchorEl: null
    });
    this.props.onEdit();
  }

  handleDeleteFeed = () => {
    const { authUser, selected_feed } = this.props;
    const feedsJoined = []
    const followedFeeds = authUser.feeds_followed.map(feed => feed.feed);
    const moderatedFeeds = authUser.feeds_moderated.map(feed => feed.feed);
    const createdFeeds = authUser.feeds_created.filter(feed => feed.category_id !== "deleted");
    const feedsToShow = feedsJoined.concat(followedFeeds,moderatedFeeds,createdFeeds)
    const uniqueFeedsToShow = _.uniqBy(feedsToShow, 'id');
    if (uniqueFeedsToShow.length === 1) {
      ToastWarning("You must have at least one feed!")
      return
    }

    this.setState({
      ...this.state,
      anchorEl: null
    });
    this.props.onDelete();
  }

  handleResignFeed = () => {
    this.setState({
      ...this.state,
      anchorEl: null
    });
    this.props.onResign();
  }

  handleShowFirst = async (showfirst) => {
    this.handleMenuClose();
    
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }
    
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    const { authUser, selected_feed } = this.props;
    
    this.setWaiting(true);

    if (showfirst) {
      await gqlservice.insert_user_feed_showfirst(authUser.uid, selected_feed.id)
      .then(result => {
        this.props.addUserFeedShowFirst(selected_feed.id);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
    } else {
      await gqlservice.delete_user_feed_showfirst(authUser.uid, selected_feed.id)
      .then(result => {
        this.props.deleteUserFeedShowFirst(selected_feed.id);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
    }

    this.setWaiting(false);

    this.setState({
      ...this.state,
      showfirst: showfirst
    });
  }

  isCreator = () => {
    const { authUser, selected_feed } = this.props;
    return authUser.feeds_created.find(item => item.id === selected_feed.id) !== undefined;
  }

  isOwner = () => {
    const { authUser, selected_feed } = this.props;
    let result = authUser.categories_moderated.find(
      (moderator) =>
        moderator.approved && moderator.category_id === selected_feed.category_id
    );
    if (result) {
      return true;
    }
    return authUser.feeds_moderated.find(item => item.feed_id === selected_feed.id && item.owner) !== undefined;
  }

  render() {
    const {
      classes,
      categories,
      selected_feed,
      theme_mode,
      onClickCategoryIndicator
    } = this.props;
    const { 
      following,
      anchorEl, 
      showfirst
    } = this.state;

    const feed_category = categories.find(category => category.id === selected_feed.category_id);
    const tags = "Tags: " + selected_feed.tags.join(', ');

    const following_img = `/static/images/icons/${theme_mode}/following.png`;
    const unfollowing_img = `/static/images/icons/${theme_mode}/unfollowing.png`;

    // name, description
    const name = summarize_text(selected_feed.name, 30);
    const description = summarize_text(selected_feed.description, 60);

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }
    
    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) {
      width = MAX_ARTICLE_WIDTH;
    } else if (width < MIN_CARD_WIDTH) {
      width = MIN_CARD_WIDTH;
    }
    width -= 32;
    const infoWidth = width - (112 + 48 + 16);

    // crypto
    let top_pos = 80;
    const crypto_class = selected_feed.tg_wallet ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 32,
      height: 32,
    } : null;
    top_pos = selected_feed.tg_wallet ? top_pos + 32 : top_pos;

    // twitter
    const twitter_class = selected_feed.channel_twitter ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 24,
      height: 24,
    } : null;
    top_pos = selected_feed.channel_twitter ? top_pos + 32 : top_pos;

    // instagram
    const instagram_class = selected_feed.channel_instagram ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 24,
      height: 24,
    } : null;
    top_pos = selected_feed.channel_instagram ? top_pos + 32 : top_pos;

    // tiktok
    const tiktok_class = selected_feed.channel_tiktok ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 24,
      height: 24,
    } : null;
    top_pos = selected_feed.channel_tiktok ? top_pos + 32 : top_pos;

    // youtube
    const youtube_class = selected_feed.channel_youtube ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 24,
      height: 24,
    } : null;

    return (
      <div className={classes.root} style={{ width: width }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item>
                <FeedImage
                  image={selected_feed.thumbnail || selected_feed.image || RAVEN_PLACEHOLDER_IMAGE}
                  title={selected_feed.name}
                  highlight={selected_feed.feed_videocasts_aggregate.aggregate.count > 0}
                />
              </Grid>
              <Grid item>
                {feed_category &&
                  <Typography 
                    className={classes.catindicator}
                    onClick={e => onClickCategoryIndicator(feed_category)}
                  >
                    {feed_category.emoji}
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{width: infoWidth}}>
            <Typography className={classes.title}>{name}</Typography>
            <Typography className={classes.description}>{description}</Typography>
            {!selected_feed.approved && (
              <Typography className={classes.tags}>{tags}</Typography>
            )}
          </Grid>
        </Grid>

        <IconButton className={classes.expand} onClick={this.handleExpand}>
          <ExpandMoreIcon />
        </IconButton>
        {!this.isOwner() && !this.isCreator() &&
          <div onClick={this.handleFollow}>
            <img
              className={classes.following}
              alt={"following"}
              src={following ? following_img : unfollowing_img}
            />
          </div>
        }
        {selected_feed.tg_wallet &&
          <img
            className={crypto_class}
            alt={"crypto"}
            src={`/static/images/icons/${theme_mode}/crypto.png`}
          />
        }
        {selected_feed.channel_twitter &&
          <div onClick={e => this.handleClickSocial("twitter")}>
            <img
              style={twitter_class}
              alt={"twitter"}
              src={`/static/images/icons/${theme_mode}/twitter.png`}
            />
          </div>
        }
        {selected_feed.channel_instagram &&
          <div onClick={e => this.handleClickSocial("instagram")}>
            <img
              style={instagram_class}
              alt={"crypto"}
              src={`/static/images/icons/${theme_mode}/instagram.png`}
            />
          </div>
        }
        {selected_feed.channel_tiktok &&
          <div onClick={e => this.handleClickSocial("tiktok")}>
            <img
              style={tiktok_class}
              alt={"crypto"}
              src={`/static/images/icons/${theme_mode}/tiktok.png`}
            />
          </div>
        }
        {selected_feed.channel_youtube &&
          <div onClick={e => this.handleClickSocial("youtube")}>
            <img
              style={youtube_class}
              alt={"crypto"}
              src={`/static/images/icons/${theme_mode}/youtube.png`}
            />
          </div>
        }

        <Menu
          id="feed-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleMenuClose}
        >
          <PopMenuFeedMod
            theme={theme_mode}
            approved={selected_feed.approved}
            creator={this.isCreator()}
            owner={this.isOwner()}
            showfirst={showfirst}
            onApproveFeed={this.handleApproveFeed}
            onEditFeed={this.handleEditFeed}
            onCopyLink={this.handleCopyLink}
            onDeleteFeed={this.handleDeleteFeed}
            onResignFeed={this.handleResignFeed}
            onShowFirst={this.handleShowFirst}
          />
        </Menu>
      </div>
    );
  }
}

FeedDetail.propTypes = {
  classes: PropTypes.object,
  onEdit: PropTypes.func,
  onApprove: PropTypes.func,
  onCopyLink: PropTypes.func,
  onDelete: PropTypes.func,
  onResign: PropTypes.func,
  onLogin: PropTypes.func,
  onClickCategoryIndicator: PropTypes.func
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  categories: state.dataState.categories,
  selected_feed: state.dataState.selected_feed,
  followed_feeds: state.dataState.followed_feeds,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedDetail);
