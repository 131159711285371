export const QUERY_STATS = `
query get_uptimestats {
  uptimestats (
    where: {active: {_eq: true}}
  ) {
    branch
    trending
    stats
    updated_at
  }
}
`;