import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { setError } from "./base";
import {
  GRAPHQL_ERROR
} from "constants/types";

const addSocketInfo = async (userId, socketId) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }
  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  const now = new Date();
  const currentTime = now.toISOString();


  const socketInfo = {
    user_id: userId,
    socket_id: socketId
  };

  let existingSoket = await gqlservice.get_sockets(socketInfo)
  if (existingSoket.data.sockets.length > 0) {
    return
}
let result = await gqlservice.insert_socket(socketInfo);

if (result.status_code === GRAPHQL_ERROR) {
  setError(result.msg);
  return;
}

if (result.data.insert_sockets?.id < 0) {
  setError("Can't suspend user");
  return;
} else {
  store.dispatch({
    type: ActionTypes.ADD_SOCKET,
    socketId: socketId
  });
}
};

const deleteSocket = async (userId, socketId) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.delete_sockets(userId, socketId);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  store.dispatch({
    type: ActionTypes.DELETE_SOCKET,
    socketId: null,
  });
}
export {
  addSocketInfo,
  deleteSocket
};
