import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withFirebase } from "services";
import { withAuthentication, withAuthorization } from "session";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { LogoAppBar, WaitingSpinner } from "components";
import { TagChips } from "./components";
import * as ROUTES from "constants/routes";
import { GRAPHQL_ERROR, MAX_WINDOW_WIDTH } from "constants/types";
import { GraphqlService } from "services";
import { getAuthToken, getCategoryFeeds, getMainInfo } from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";

const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  container: {
    width: "100%",
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: theme.palette.background.default,
  },
  banner: {
    textAlign: "center",
  },
  bannertitle: {
    color: theme.palette.text.primary,
    fontSize: "24px",
    fontWeight: 500,
  },
  bannerdetail: {
    color: theme.palette.text.primary,
    fontSize: "16px",
  },
  tagchips: {
    paddingBottom: 64,
  },
  tagcontanier: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  category: {
    color: theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: 500,
  },
  button: {
    position: "fixed",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 340,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  buttontext: {
    color: "#FFF",
    fontWeight: 500,
    fontSize: 16,
  },
});

class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };

    this.handleSelectTag = this.handleSelectTag.bind(this);
    this.handleOnboarding = this.handleOnboarding.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
  }

  async componentDidMount() {
    this.setWaiting(true);
    await getMainInfo();
    this.setWaiting(false);

    const { authUser } = this.props;

    let selected_tags = [];
    if (authUser.tags?.length > 0) {
      selected_tags = authUser.tags?.map((tag) => {
        return {
          id: tag.tag.id,
          name: tag.tag.name,
        };
      });
    }
    // logger.log("selected tags :", selected_tags);

    this.setState({
      ...this.state,
      selected: selected_tags,
    });
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleSkip = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleSelectTag = async (tag, isSelected) => {
    if (this.props.requesting) {
      return;
    }
    
    const { authUser } = this.props;
    const { selected } = this.state;

    logger.log("handleSelectTag :", tag, isSelected);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    let result = null;
    if (isSelected) {
      let new_selected = selected.slice();
      new_selected.push(tag);

      // add this tag_user to the database
      const tag_user = {
        tag_id: tag.id,
        user_id: authUser.uid,
      };

      // add tag_category_user
      result = await gqlservice.add_tag_user(tag_user);
      if (result.status_code === GRAPHQL_ERROR) {
        this.setError(result.msg);
        return;
      }

      // // subscribe feeds of the tag_category
      // const tag_category_feeds = tag.tag_category_feeds.slice();
      // for (let tag_feed of tag_category_feeds) {
      //   if (authUser.feeds_subscribed.find(feed => feed.feed_id === tag_feed.feed_id) === undefined) {
      //     result = await gqlservice.insert_feed_subscriber(tag_feed.feed_id, authUser.uid);
      //   }
      // }

      // update user
      result = await gqlservice.user_by_id(authUser.uid);
      if (result.status_code === GRAPHQL_ERROR) {
        this.setError(result.msg);
        return;
      }
      if (result.data.users.length > 0) {
        this.props.setAuthUser(result.data.users[0]);
        this.setState({
          ...this.state,
          selected: new_selected,
        });
      }
    } else {
      const new_selected = selected.filter((item) => item.id !== tag.id);

      // delete this tag_category_user from the database
      const tag_user = {
        tag_id: tag.id,
        user_id: authUser.uid,
      };

      // // unsubscribe feeds of the tag_category
      // const tag_category_feeds = tag.tag_category_feeds.slice();
      // for (let tag_feed of tag_category_feeds) {
      //   if (authUser.feeds_subscribed.find(feed => feed.feed_id === tag_feed.feed_id) !== undefined) {
      //     result = await gqlservice.delete_feed_subscriber(tag_feed.feed_id, authUser.uid);
      //   }
      // }

      // update user
      result = await gqlservice.delete_tag_user(tag_user);
      if (result.status_code === GRAPHQL_ERROR) {
        this.setError(result.msg);
        return;
      }
      result = await gqlservice.user_by_id(authUser.uid);
      if (result.status_code === GRAPHQL_ERROR) {
        this.setError(result.msg);
        return;
      }

      if (result.data.users.length > 0) {
        this.props.setAuthUser(result.data.users[0]);
        this.setState({
          ...this.state,
          selected: new_selected,
        });
      }
    }

    this.setWaiting(false);
  };

  handleOnboarding = async () => {
    const { categories } = this.props;
    const { selected } = this.state;

    const selectedTagIds = selected.map((tag) => tag.id);

    const selectedCategories = categories.filter(
      (category) =>
        category.category_tags.find((category_tag) =>
          selectedTagIds.includes(category_tag.tag.id)
        ) !== undefined
    );
    logger.log("Onboarding selectedCategories :", selectedCategories);

    this.setWaiting(true);

    let onboarding_feeds = [];
    for (let category of selectedCategories) {
      let feeds = await getCategoryFeeds(category.id);
      if (!feeds || feeds.length === 0) {
        continue;
      }

      if (onboarding_feeds.length === 0) {
        onboarding_feeds = feeds.map(feed => feed);
        continue;
      }

      for (let feed of feeds) {
        if (
          onboarding_feeds.find(
            (onboarding_feed) => onboarding_feed.id === feed.id
          ) === undefined
        ) {
          onboarding_feeds.push(feed);
        }
      }
    }

    this.setWaiting(false);

    this.props.setOnboardingFeeds(onboarding_feeds);

    const location = {
      pathname: ROUTES.ONBOARDING_CONFIRM,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  _renderCategoryTagChips = (classes, category, selectedtags, onSelectTag) => {
    return (
      <div className={classes.tagcontanier}>
        <Typography className={classes.category}>
          {`${category.emoji} ${category.name}`}
        </Typography>
        <TagChips
          tags={category.category_tags}
          selectedtags={selectedtags}
          onSelect={onSelectTag}
        />
      </div>
    );
  };

  render() {
    const { classes, theme_mode, categories, requesting } = this.props;
    const { selected } = this.state;

    const categories2show = categories.filter(
      (category) => category.order >= 0
    );
    // logger.log("categories2show : ", categories2show);
    // logger.log("selectedtags :", selected);

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    const buttonPos = (width - 340) / 2;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <LogoAppBar theme_mode={theme_mode} onSkip={this.handleSkip} />
        </div>

        <div className={classes.container}>
          <div className={classes.banner}>
            <Typography className={classes.bannertitle} gutterBottom>
              Personalize your Feed
            </Typography>
            <Typography className={classes.bannerdetail}>
              Select 3 or more topics
            </Typography>
          </div>
          {categories2show.length > 0 && (
            <div className={classes.tagchips}>
              {categories2show.map((category) =>
                this._renderCategoryTagChips(
                  classes,
                  category,
                  selected,
                  this.handleSelectTag
                )
              )}
            </div>
          )}
        </div>

        {selected.length >= 3 ? (
          <Button
            className={classes.button}
            style={{ left: buttonPos }}
            disabled={requesting}
            clickable={"false"}
            onClick={this.handleOnboarding}
          >
            <Typography className={classes.buttontext}>Get Started</Typography>
          </Button>
        ) : (
          <Button className={classes.button} style={{ left: buttonPos }}>
            <Typography className={classes.buttontext}>
              Follow 3 or more Topics
            </Typography>
          </Button>
        )}

        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

Onboarding.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  categories: state.dataState.categories,
  // feeds: state.dataState.feeds,
  // tags: state.dataState.tags,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withRouter,
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Onboarding);
