import { v4 as uuidv4 } from 'uuid';
import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { setError } from "./base";
import { GRAPHQL_ERROR } from "constants/types";
import { 
  ACTIVITY_CHANGE, 
  ACTIVITY_DELETE,
  ACTIVITY_REPORT,
  ACTIVITY_TYPE_FEED 
} from "constants/activity";
import { get_link_source } from "utility/ravenapi";
import { summarize_text } from "utility/utils";
import { ARTICLE_BRANCH_USERPOST } from "constants/branches";
import { logger } from "utility/logging";

const getPinsAndMovetops = async (feed_ids) => {
  store.dispatch({ type: ActionTypes.SET_REQUEST_GET_ARTICLE_PINS_IN_FEEDS, state: true })
  const gqlservice = new GraphqlService();
  let result = await gqlservice.article_pins_in_feeds(feed_ids);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  const pins = result.data.article_pins;
  if (pins.length > 0) {
    store.dispatch({ type: ActionTypes.SET_ARTICLE_PINS, pins: pins });
  }

  result = await gqlservice.article_movetops_in_feeds(feed_ids);
  if (result.status_code === GRAPHQL_ERROR) {
    store.dispatch({ type: ActionTypes.SET_REQUEST_GET_ARTICLE_PINS_IN_FEEDS, state: false })
    setError(result.msg);
    return;
  }

  const movetops = result.data.article_movetops;
  if (movetops.length > 0) {
    store.dispatch({
      type: ActionTypes.SET_ARTICLE_MOVETOPS,
      movetops: movetops,
    });
  }
  store.dispatch({ type: ActionTypes.SET_REQUEST_GET_ARTICLE_PINS_IN_FEEDS, state: false })
};

const getArticle = async (article_id) => {
  const gqlservice = new GraphqlService();
  let result = await gqlservice.article_by_nid(article_id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const articles = result.data.articles;
  if (articles.length > 0) {
    store.dispatch({
      type: ActionTypes.SELECT_ARTICLE,
      article: articles[0],
    });
  }
}

const upvoteArticle = async (article) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const voted =
    authUser.articles_voted.find(
      (voted_article) => voted_article.article_id === article.nid
    ) !== undefined;

  gqlservice.set_auth_jwt(token);
  let result = null;
  if (voted) {
    result = await gqlservice.article_dec_upvotes(article.nid, authUser.uid);
  } else {
    const voted_username =
      article.branch === ARTICLE_BRANCH_USERPOST ? article.author : null;
    result = await gqlservice.article_inc_upvotes(
      article.nid,
      authUser.uid,
      voted_username
    );
  }

  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (voted) {
    store.dispatch({ type: ActionTypes.DELETE_ARTICLE_VOTED, article_id: article.nid });
  } else {
    store.dispatch({ type: ActionTypes.INSERT_ARTICLE_VOTED, article_id: article.nid });
  }
};

const repostArticle = async (article) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const reposted =
    authUser.articles_reposted.find(
      (reposted_article) => reposted_article.article_id === article.nid
    ) !== undefined;

  gqlservice.set_auth_jwt(token);
  let result = null;
  if (reposted) {
    result = await gqlservice.article_dec_reposts(article.nid, authUser.uid);
  } else {
    result = await gqlservice.article_inc_reposts(article.nid, authUser.uid);
  }

  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (reposted) {
    store.dispatch({ type: ActionTypes.DELETE_ARTICLE_REPOSTED, article_id: article.nid });
  } else {
    store.dispatch({ type: ActionTypes.INSERT_ARTICLE_REPOSTED, article_id: article.nid });
  }
};

const deleteArticle = async (article) => {
  const state = store.getState();

  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token, true);

  let result = await gqlservice.delete_article(article.nid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  store.dispatch({ type: ActionTypes.DELETE_ARTICLE, article_id: article.nid});

  const { selected_feed } = state.dataState;
  if (!selected_feed) {
    return;
  }

  // log this activity
  gqlservice.set_auth_jwt(token, false);
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_FEED,
    type_id: selected_feed.id,
    action: ACTIVITY_DELETE,
    object: `the article ${article.title}`,
    fromto: `from the feed ${selected_feed.name}`,
    reason: "",
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
};

const deleteSavedArticle = async (article) => {
  const state = store.getState();
  const { loggedIn } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const { saved_articles } = this.dataState;
  const saved_article = saved_articles.find(saved => saved.article_id === article.nid);
  if (!saved_article) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.delete_article_save(saved_article.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  store.dispatch({
    type: ActionTypes.DELETE_SAVED_ARTICLE,
    save_id: saved_article.id
  });
}

const saveArticle = async (article) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.article_save(article.nid, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const saved = result.data.article_save;
  if (saved.length > 0) {
    return;
  }
  
  result = await gqlservice.insert_article_save(article.nid, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
};

const reportArticle = async (article, reportMsg) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  const report = {
    id: uuidv4(),
    article_id: article.nid,
    source_id: article.source_id,
    report: reportMsg,
    reported_by: authUser.uid,
    approved: false,
  };

  let result = await gqlservice.insert_article_report(report);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const { selected_feed, followed_feeds } = state.dataState;
  let feeds = [];
  if (selected_feed) {
    feeds = [selected_feed];
  } else {
    feeds = followed_feeds.filter((feed) =>
      feed.feed_sources
        .filter((feed_source) => feed_source.approved)
        .map((feed_source) => feed_source.source_id)
        .includes(article.source_id)
    );
  }
  
  // log this activity
  let activity = null;
  for (let feed of feeds) {
    activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: feed.id,
      action: ACTIVITY_REPORT,
      object: `the article ${article.title}`,
      fromto: `of the feed ${feed.name}`,
      reason: "",
    };
    result = await gqlservice.insert_activitylog(activity);
    if (result.status_code === GRAPHQL_ERROR) {
      setError(result.msg);
      continue;
    }
  }
};

const updatePost = async (description, postlink, article_edit) => {
  const state = store.getState();

  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }
  let preview = null;
  if (postlink) {
    preview = await get_link_source(postlink);
    logger.log("User post preview :", preview);
  }

  const gqlservice = new GraphqlService();

  const summary = summarize_text(description, 150);
  const source_id = article_edit.source_id;
  const published = new Date().getTime() / 1000;
  const userpost = {
    nid: article_edit.nid,
    summary: summary,
    text: description,
    published: Math.floor(published),
    link_preview: preview,
  };

  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.update_userpost(userpost);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const articles = result.data.update_articles.returning;
  if (articles.length > 0) {
    let article = articles[0];
    if (article.link_preview) {
      article.link_preview = JSON.parse(article.link_preview);
    }
    store.dispatch({type: ActionTypes.UPDATE_USERPOST, userpost: article})
  }

  if (source_id !== null) {
    const now = new Date();
    result = await gqlservice.update_source_lastupdated(source_id, now.toISOString());
    if (result.status_code === GRAPHQL_ERROR) {
      setError(result.msg);
      return;
    }
  }

  const { selected_feed } = state.dataState;
  if (!selected_feed) {
    return;
  }

  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_FEED,
    type_id: selected_feed.id,
    action: ACTIVITY_CHANGE,
    object: `the post ${userpost.nid}`,
    fromto: source_id === null ? `to the profile` : `to the feed ${selected_feed.name}`,
    reason: "",
  };

  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
};

export { 
  getPinsAndMovetops,
  getArticle,
  upvoteArticle,
  repostArticle,
  deleteArticle,
  deleteSavedArticle,
  saveArticle,
  reportArticle,
  updatePost,
};
