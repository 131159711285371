// Socialtag
export const QUERY_SOCIALTAG = `
query socialtag(
  $tag: String!,
  $type: String!
) {
  socialtags(
    where: {
      tag: {_eq: $tag},
      type: {_eq: $type}
    }
  ) {
    id
    tag
    type
  }
}`;

export const GET_SOURCE_BY_SOCIALTAG = `
query get_source_by_socialtag(
  $tag: String!,
  $type: String!
) {
  socialtags(
    where: {
      tag: {_ilike: $tag},
      type: {_eq: $type}
    }
  ) {
    source {
      id
      category_id
      name
      description
      slug
      image
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      socialtags {
        tag
        type
      }
    }
  }
}`;

export const MUTATION_INSERT_SOCIALTAG = `
mutation(
  $id: String!,
  $tag: String!,
  $type: String!
) {
  insert_socialtags(
    objects: {
      id: $id, 
      tag: $tag, 
      type: $type
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_SOCIALTAG = `
mutation (
  $id: String!,
  $tag: String!,
  $type: String!
) {
  update_socialtags(
    where: {
      id: {_eq: $id}
    }, 
    _set: {
      tag: $tag, 
      type: $type
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_DELETE_SOCIALTAG = `
mutation (
  $id: String!
) {
  delete_socialtags(
    where: {
      id: {_eq: $id}
    } 
  ) {
    affected_rows
  }
}`;
