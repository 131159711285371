import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ToastContainer } from "react-toastify";
import * as ROUTES from "constants/routes";
import { MAX_ARTICLE_WIDTH } from "constants/types";
import { GraphqlService } from "services";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  container: {
    textAlign: "center",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  logo: {
    width: 200,
    height: 200,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 40,
    fontWeight: 500,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  description: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 400,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  nextcontainer: {
    position: "fixed",  
    bottom: theme.spacing(1),
    width: '100%',
    zIndex: 1100,
  },
  // nextdiv: {
  //   textAlign: "center",
  //   marginLeft: "auto",
  //   marginRight: "auto",
  // },
  // nextbutton: {
  //   backgroundColor: "#1878F3",
  //   color: "#FFFFFF",
  //   borderRadius: "30px",
  //   border: "3px solid #1878F3",
  //   padding: "8px 8px",
  //   width: 280,
  //   textTransform: "initial",
  //   marginTop: 10,
  //   marginBottom: 40,
  //   "&:hover": {
  //     backgroundColor: "#1878F3",
  //     color: "#FFFFFF",
  //   }
  // },
  nextbutton: {
    position: "absolute",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    }
  },
  buttontext: {
    color: "#FFF",
    fontWeight: 400,
    fontSize: 16
  },
});


class FeedJoin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enableNext: false,
      feed_slug: null
    };

    this.handleNext = this.handleNext.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const { match } = this.props;
    logger.log("Feed Join :", match.params.slug);
    
    if (match.params.slug === undefined) {
      this.setError("Invalid join link!");
      return;
    }

    // check if the feed is valid
    const feed = await this.getFeed(match.params.slug);
    if (!feed) {
      this.setError("Invalid join link!");
      return;
    }

    this.setState({
      ...this.state,
      enableNext: true,
      feed_slug: feed.slug
    });

    this.props.setFeedJoinInfo(feed.id, feed.slug);
  }

  getFeed = async (feed_slug) => {
    const gqlservice = new GraphqlService();
    const feed = await gqlservice
      .feed_by_slug(feed_slug)
      .then(
        (result) => {
          const feeds = result.data.feeds;
          if (feeds.length > 0) {
            return feeds[0];
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return null;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return null;
      });

    return feed;
  };

  handleNext = () => {
    const { feed_slug } = this.state;
    this.props.setLoginBackRoute(`/${ROUTES.FEEDS_PREFIX}/${feed_slug}`);

    const location = {
      pathname: ROUTES.PHONE_SIGNIN,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    return;
  }

  render() {
    const { classes } = this.props;
    const { enableNext } = this.state;

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const nextButtonPos = (width - 260) / 2;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <img
            alt="logo"
            src={`/static/images/logo/logo.png`}
            className={classes.logo}
          />
          <Typography className={classes.title}>
            Welcome!
          </Typography>
          <Typography className={classes.description}>
            Sign in with your phone number to verify your connection with your friend and join private groups with them!
          </Typography>
        </div>

        {/* <div className={classes.nextdiv}> */}
        <Button
          className={classes.nextbutton}
          style={{left: nextButtonPos}}
          disabled={!enableNext}
          clickable={"false"}
          endIcon={<ArrowForwardIcon style={{color: "white"}} />}
          onClick={this.handleNext}
        >
          <Typography className={classes.buttontext}>
            Next
          </Typography>
        </Button>
        {/* </div> */}

        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedJoin);
