import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import MetaTags from "react-meta-tags";
import { ToastContainer } from "react-toastify";
import { withFirebase } from 'services';
import { withAuthentication } from "session";
import {
  SlideLists,
  ArticleList,
  ArticleMasonry,
  DlgPostEdit,
  WaitingSpinner
} from "components";
import { 
  SourceAppBar, 
  SourceDetail 
} from "./components";
import {
  BRANCH_ALL,
  ARTICLE_BRANCH_NEWSPAPER,
  get_branch,
  is_social_branch,
} from "constants/branches";
import * as ROUTES from "constants/routes";
import { ALL } from "constants/country";
import {
  MAX_WINDOW_WIDTH,
  MIN_TABLET_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
  TAB_FEED,
} from "constants/types";
import { GraphqlService, Mixpanel } from "services";
import {
  deleteArticle,
  followSource,
  getArticle,
  getMainInfo,
  reportArticle,
  repostArticle,
  saveArticle,
  unfollowSource,
  updatePost,
  upvoteArticle,
  upvoteSource
} from "dataapis";
import { build_source_link } from "utility/buildlink";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";
import * as MIXPANEL_EVENTS from "constants/mixpanel";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 10,
  },
  container: {
    marginTop: theme.spacing(1),
    margin: 0,
  },
  feedscontainer: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    margin: theme.spacing(2),
  },
  feeds_title: {
    fontSize: 14,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  articlecontainer: {
    padding: theme.spacing(1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.default,
  },
});

class Source extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      following: false,
      followers: 0,
      postDlg: false,
      article_edit: null
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleSelectFeed = this.handleSelectFeed.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleNeedMore = this.handleNeedMore.bind(this);

    this.handleFollowSource = this.handleFollowSource.bind(this);
    this.handleUpvoteSource = this.handleUpvoteSource.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);
    this.handleClickSourceInArticle =
      this.handleClickSourceInArticle.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);

    this.closePostEditDlg = this.closePostEditDlg.bind(this);
    this.handleUpdatePost = this.handleUpdatePost.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  async componentDidMount() {
    await this.initialize();
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.loggedIn !== prevProps.loggedIn) {
      logger.log("auth state changed in source page");
      await this.initialize();
    }
  }

  initialize = async() => {
    const { selected_source, loggedIn, authUser } = this.props;

    this.setWaiting(true);

    await getMainInfo();

    const feed_slug = this.props.match.params.feed_slug;
    let source_id = null;
    let source_slug = this.props.match.params.source_slug;
    let current_source = selected_source;
    if (selected_source) {
      source_id = selected_source.id;
    } else {
      // browse this source url directly (by source link)
      current_source = this.props.sources.find(
        (source) => source.slug === source_slug
      );
      if (!current_source) {
        this.setWaiting(false);
        return;
      }
      source_id = current_source.id;
      let selected_feed = null;
      if (feed_slug) {
        selected_feed = this.props.feeds.find(
          (feed) => feed.slug === feed_slug
        );
      } else {
        selected_feed = this.props.feeds.find((feed) =>
          feed.feed_sources
            .map((feed_source) => feed_source.source_id)
            .includes(source_id)
        );
      }
      logger.log("===>>> selected_feed", selected_feed);
      if (!selected_feed) {
        this.setWaiting(false);
        return;
      }
      this.props.selectFeed(selected_feed);
      this.props.selectSource(current_source);
    }

    this.setState({
      ...this.state,
      followers: current_source.followers,
    });

    await this.getArticlesInFirstPage(current_source);

    if (loggedIn) {
      await this.getFollowing(source_id, authUser.uid);
    }
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  getFollowing(source_id, user_id) {
    const gqlservice = new GraphqlService();
    gqlservice
      .source_followers_by_source(source_id)
      .then(
        (result) => {
          const followers = result.data.source_followers;
          const following =
            followers.find((follower) => follower.user_id === user_id) !==
            undefined;
          this.setState({ following: following });
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  }

  getArticlesInFirstPage = async (source) => {
    this.props.refreshArticles();

    this.setWaiting(true);
    const gqlservice = new GraphqlService();
    await gqlservice
      .articles_eq_source(source.id, 0)
      .then(
        (result) => {
          const articles = result.data;
          logger.log("Source => articles (first page) :", articles);
          this.props.setArticles(articles, articles.length);
          this.props.initScrollPos();
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleNavBack = () => {
    const { source_backroute } = this.props;
    // const { selected_feed, selected_list } = this.props;
    // let route = "";
    // if (selected_feed !== null) {
    //   route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
    // } else if (selected_list !== null) {
    //   route = `/${ROUTES.LIST_PREFIX}/${selected_list.id}`;
    // } else {
    //   route = ROUTES.DISCOVER;
    // }
    // this.props.history.goBack();
    const location = {
      pathname: source_backroute,
      state: { animation: "right" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.selectSource(null);
  };

  handleNeedMore = async () => {
    const { selected_source, last_offset, requesting } = this.props;

    if (last_offset === 0) {
      this.props.showBottomNavbar(false);
      return;
    }
    if (requesting) {
      return;
    }

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    await gqlservice
      .articles_eq_source(selected_source.id, last_offset)
      .then(
        (result) => {
          const articles = result.data;
          logger.log("Source => articles (next page) :", articles);
          if (articles.length > 0) {
            this.props.appendArticles(articles, last_offset + articles.length);
          } else {
            this.props.appendArticles(articles, 0);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleFollowSource = async () => {
    const { selected_source } = this.props;

    const following = !this.state.following;
    const followers = following
      ? selected_source.followers + 1
      : selected_source.followers - 1;

    this.setState({
      ...this.state,
      following: following,
      followers: followers,
    });

    this.setWaiting(true);

    if (following) {
      followSource(selected_source);
    } else {
      unfollowSource(selected_source);
    }

    this.setWaiting(false);
  };

  handleUpvoteSource = async () => {
    if (!this.props.loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    const { selected_source } = this.props;
    await upvoteSource(selected_source);
    this.setWaiting(false);
  };

  handleClickSource = () => {
    const { selected_source } = this.props;

    // source link
    let social_link = null;
    const socialtags = selected_source.socialtags;
    if (is_social_branch(selected_source.branch) && selected_source.socialtags.length > 0) {
      const socialtag = socialtags[0];
      if (socialtag.tag.includes("http:") || socialtag.tag.includes("https:")) {
        social_link = socialtag.tag;
      } else {
        social_link = build_source_link(socialtag.type, socialtag.tag);
      }

      window.open(social_link, "_blank");
    }
  };

  handleClickSourceInArticle = (source, feed) => {
    this.props.initScrollPos();
  };

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  };

  // empty funciton
  handleDeleteSavedArticle = (article) => {};
  // empty function

  handleSelectFeed = (feed) => { 
    const properties = {id:feed.id,name:feed.name,slug:feed.slug};
    Mixpanel.track(MIXPANEL_EVENTS.FEED_DETAILS,properties); 
    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickFeed = (feed) => {
    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickUpvote = async (article) => {
    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  }

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  }

  handleClickRepost = async (article) => {
    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  }

  handleSelectArticle = (article) => {
    this.props.selectArticle(article);
    const properties ={
      author : article.author,
      source_id: article.source_id,
      title:article.title,
      url:article.url,
      nid:article.nid
    }
    Mixpanel.track(MIXPANEL_EVENTS.ARTICLE_DETAILS,properties); 
    const { selected_feed, sources } = this.props;
    const article_source = sources.find(
      (source) => source.id === article.source_id
    );

    let sourcePath = "";
    if (selected_feed) {
      sourcePath = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${article_source.slug}`;
    } else {
      sourcePath = `/${ROUTES.CATEGORY_PREFIX}/${article_source.category_id}/${ROUTES.SOURCE_PREFIX}/${article_source.slug}`;
    }
    let articlePath = "";
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      articlePath += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      articlePath += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }
    const location = {
      pathname: articlePath,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(sourcePath);
  };

  handleSelectGroupArticle = async (nid) => {
    this.setWaiting(true);
    await getArticle(nid);
    this.setWaiting(false);

    const location = {
      pathname: `/${ROUTES.ARTICLE_NEWS_PREFIX}/${nid}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(
      `/${ROUTES.SOURCE_PREFIX}/${this.props.selected_source.slug}`
    );
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleReportArticle = async (article, reportMsg) => {
    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };

  handleEditArticle = (article) => {
    this.setState({
      ...this.state,
      postDlg: true,
      article_edit: article
    });
  }

  handleDeleteArticle = async (article) => {
    this.setWaiting(true);
    await deleteArticle(article);
    this.setWaiting(false);
  }

  closePostEditDlg = () => {
    this.setState({
      ...this.state,
      postDlg: false,
      article_edit: null
    });
  };

  handleUpdatePost = async (description, postlink) => {
    this.setState({
      ...this.state,
      postDlg: false
    });

    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }

    const { article_edit } = this.state;

    this.setWaiting(true);
    await updatePost(description, postlink, article_edit);
    this.setWaiting(false);

    this.setState({
      ...this.state,
      article_edit: null
    });
  }

  getFeedsBySource = (feeds, source) => {
    var sub_feeds = [];
    if (feeds.length === 0) {
      return sub_feeds;
    }
    feeds.forEach((feed) => {
      const sources = feed.feed_sources.map(
        (feed_source) => feed_source.source_id
      );
      if (sources.includes(source.id)) {
        sub_feeds.push(feed);
      }
    });
    return sub_feeds;
  };

  render() {
    const {
      classes,
      newssites,
      feeds,
      selected_feed,
      selected_source,
      articles,
      theme_mode,
      requesting,
      source_backroute
    } = this.props;
    const { 
      following, 
      followers,
      postDlg,
      article_edit,
    } = this.state;

    const userProfile = source_backroute === "/searchresult";
    if (newssites.length === 0 || selected_source === null) {
      return <div style={{display: "none"}}></div>;
    }

    let feeds2show = this.getFeedsBySource(feeds, selected_source);

    // get branch image
    let branchimage = "";
    let branchInfo = get_branch(selected_source.branch);
    if (branchInfo) {
      branchimage = `/static/images/icons/${theme_mode}/${branchInfo.image}`;
    }

    // get share url
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    if (selected_feed) {
      shareUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${selected_source.slug}`;
    } else {
      shareUrl += `/${ROUTES.CATEGORY_PREFIX}/${selected_source.category_id}/${ROUTES.SOURCE_PREFIX}/${selected_source.slug}`;
    }

    // get share title
    let shareInfo = {
      title: "Raven Source: " + selected_source.name,
      description: selected_source.description,
      image: selected_source.image,
      hashtag: "",
      url: shareUrl,
    };

    // source link
    let social_link = null;
    const socialtags = selected_source.socialtags;
    if (selected_source.socialtags.length > 0) {
      const socialtag = socialtags[0];
      social_link = build_source_link(socialtag.type, socialtag.tag);
    }

    // pins, movetops
    const pins = [];
    const movetops = [];

    // SlideLists width
    const width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    const slidelist_width = width - 32; // feedcontainer margin

    // layout variables
    const isDesktop = width >= MAX_WINDOW_WIDTH;
    const isTablet = width >= MIN_TABLET_WIDTH && width < MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;
    const innerWidth = width > MAX_WINDOW_WIDTH ? MAX_WINDOW_WIDTH : width;
    const masonryWidth = Math.floor(innerWidth / 402) * 402 + 16;

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven: ${selected_source.name}`}</title>
            <meta name="description" content={selected_source.description} />
            <meta property="og:title" content={`Raven: ${selected_source.name}`} />
            <meta property="og:description" content={selected_source.description} />
            <meta property="og:image" content={selected_source.image} />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={shareUrl} />
            <meta property="twitter:title" content={`Raven: ${selected_source.name}`} />
            <meta property="twitter:site" content="Raven App" />
            <meta property="twitter:description" content={selected_source.description} />
            <meta property="twitter:image:src" content={selected_source.image} />
            <meta property="twitter:image:alt" content={selected_source.name} />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar}>
          <SourceAppBar
            title={"Source Feed"}
            share_info={shareInfo}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.container}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <SourceDetail
                theme_mode={theme_mode}
                title={selected_source.name}
                description={selected_source.description}
                image={selected_source.thumbnail || selected_source.image || RAVEN_PLACEHOLDER_IMAGE}
                link={social_link}
                logo={branchimage}
                following={following}
                followers={followers}
                upvotes={selected_source.upvotes}
                onClickFollowing={this.handleFollowSource}
                onClickUpvote={this.handleUpvoteSource}
                onClickSource={this.handleClickSource}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.feedscontainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            {feeds2show.length > 0 && (
              <Grid item xs={12}>
                <div>
                  <Typography
                    className={classes.feeds_title}
                    variant="subtitle2"
                  >
                    {"Feeds containing this source"}
                  </Typography>
                </div>
                <SlideLists
                  width={slidelist_width}
                  items={feeds2show}
                  onItemSelected={this.handleSelectFeed}
                />
              </Grid>
            )}
          </Grid>
        </div>
        <div className={classes.articlecontainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
              {(isDesktop || isTablet) && (
                <Grid item>
                  <ArticleMasonry
                    userProfile={userProfile}
                    width={masonryWidth}
                    articles={articles}
                    pins={pins}
                    movetops={movetops}
                    onNeedMore={this.handleNeedMore}
                    onSelectArticle={this.handleSelectArticle}
                    onSelectGroupArticle={this.handleSelectGroupArticle}
                    onNeedLogin={this.handleLogin}
                    onReport={this.handleReportArticle}
                    onEdit={this.handleEditArticle}
                    onDelete={this.handleDeleteArticle}
                    onSave={this.handleSaveArticle}
                    onDeleteSaved={this.handleDeleteSavedArticle}
                    onClickSource={this.handleClickSourceInArticle}
                    onClickFeed={this.handleClickFeed}
                    onClickUpvote={this.handleClickUpvote}
                    onClickComment={this.handleClickComment}
                    onClickRepost={this.handleClickRepost}
                  />
                </Grid>
              )}
              {isMobile && (
                <Grid item>
                  <ArticleList
                    articles={articles}
                    pins={pins}
                    movetops={movetops}
                    onNeedMore={this.handleNeedMore}
                    onSelectArticle={this.handleSelectArticle}
                    onSelectGroupArticle={this.handleSelectGroupArticle}
                    onNeedLogin={this.handleLogin}
                    onReport={this.handleReportArticle}
                    onEdit={this.handleEditArticle}
                    onDelete={this.handleDeleteArticle}
                    onSave={this.handleSaveArticle}
                    onDeleteSaved={this.handleDeleteSavedArticle}
                    onClickSource={this.handleClickSourceInArticle}
                    onClickFeed={this.handleClickFeed}
                    onClickUpvote={this.handleClickUpvote}
                    onClickComment={this.handleClickComment}
                    onClickRepost={this.handleClickRepost}
                  />
                </Grid>
              )}
          </Grid>
          {postDlg &&
            <DlgPostEdit
              open={postDlg}
              theme={theme_mode}
              article={article_edit}
              onSubmit={this.handleUpdatePost}
              onClose={this.closePostEditDlg}
            />
          }
          <WaitingSpinner open={requesting} />
        </div>
        <ToastContainer />
      </div>
    );
  }
}

Source.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  feeds: state.dataState.feeds,
  sources: state.dataState.sources,
  selected_category: state.dataState.selected_category,
  selected_feed: state.dataState.selected_feed,
  selected_list: state.dataState.selected_list,
  selected_source: state.dataState.selected_source,
  articles: state.dataState.articles,
  last_offset: state.dataState.last_offset,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
  source_backroute: state.uiState.source_backroute,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Source);
