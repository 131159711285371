import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { List, Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withAuthentication, withAuthorization } from "session";
import { GraphqlService } from "services";
import { 
  SearchAppBar,
  WaitingSpinner,
} from "components";
import { FeedItem } from "./components";
import * as ROUTES from "constants/routes";
import { BRANCH_ALL } from "constants/branches";
import { ALL } from "constants/country";
import { withFirebase } from 'services';
import { 
  TAB_FEED,
  MAX_WINDOW_WIDTH, 
  USE_QUOTA,
  FREE_NEWSLETTER_LIMIT, 
  GRAPHQL_SUCCESS
} from "constants/types";
import { NOTIFICATION_FEED_SUBSCRIBE } from "constants/notification";
import { 
  getAuthToken,
  hasPaid,
} from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const condition = authUser => !!authUser && authUser.uid.length > 0;


const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
    zIndex: 1200
  },
  feeds: {
    paddingLeft: theme.spacing(2),
    padding: 0,
    backgroundColor: theme.palette.background.default
  },
  myfeeds: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
    color: theme.palette.text.primary,
    textTransform: "none"
  },
});

class NewsletterFeeds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      my_feeds: [],
      moderated_feeds: [],
      your_feeds: [],
      checked: [],
      searchKey: ""
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  setError = message => {
    ToastError(message);
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount() {
    const { feeds, followed_feeds, authUser } = this.props;

    if (feeds.length === 0) {
      return;
    }
    
    // get subscribed feeds
    let newsletter_feeds = authUser.feeds_subscribed.map(feed => feed.feed_id);
    logger.log("newsletter feeds :", newsletter_feeds);

    // my feeds
    let checked = [];
    const my_feeds = feeds.filter(feed => feed.created_by === authUser.uid);
    for (let feed of my_feeds) {
      checked.push({
        id: feed.id,
        checked: newsletter_feeds.find(feed_id => feed_id === feed.id) !== undefined
      });
    }

    // moderated feeds
    const moderated_feeds = authUser.feeds_moderated
      .map((item) => feeds.find(feed => feed.id === item.feed_id))
      .filter(feed => feed && feed.created_by !== authUser.uid);
    for (let feed of moderated_feeds) {
      checked.push({
        id: feed.id,
        checked: newsletter_feeds.find(feed_id => feed_id === feed.id) !== undefined
      });
    }
    
    // followed feeds
    let feeds2show = followed_feeds.filter(feed => 
      feed.private === false &&
      my_feeds.find(item => item.id === feed.id) === undefined &&
      moderated_feeds.find(item => item.id === feed.id) === undefined
    );

    if (USE_QUOTA) {
      if (!hasPaid() && feeds2show.length > FREE_NEWSLETTER_LIMIT) {
        feeds2show = feeds2show.slice(0, FREE_NEWSLETTER_LIMIT);
      }
    }

    for (let feed of feeds2show) {
      checked.push({
        id: feed.id,
        checked: newsletter_feeds.find(feed_id => feed_id === feed.id) !== undefined
      });
    }

    this.setState({
      ...this.state,
      my_feeds: my_feeds,
      moderated_feeds: moderated_feeds,
      your_feeds: feeds2show,
      checked: checked
    });
  }

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.PROFILE,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleToggle = async (feed_id) => {
    const { authUser, feeds } = this.props;
    const { checked } = this.state;

    const new_checked = checked.map(item => {
      if (item.id === feed_id) {
        item.checked = !item.checked;
        return item;
      } else {
        return item;
      }
    });

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.setWaiting(false);
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    let result = null;
    const subscribed = checked.find(feed => feed.id === feed_id).checked;
    if (subscribed) {
      result = await gqlservice.insert_feed_subscriber(feed_id, authUser.uid);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        this.setError("Failed to subscribe feed :", feed_id);
      } else {
        // insert notification
        const feed = feeds.find(item => item.id === feed_id);
        const feed_owner = feed.feed_moderators.find(moderator => moderator.owner);
        if (feed_owner !== undefined) {
          const notification = {
            type: NOTIFICATION_FEED_SUBSCRIBE,
            object: feed_id,
            in_which: null,
            to: feed_owner.user_id,
            created_by: authUser.uid
          }

          result = await gqlservice.insert_notification(notification);
          if (result.status_code !== GRAPHQL_SUCCESS) {
            this.setError("Failed to send notification to feed owner");
          }
        }
      }
    } else {
      result = await gqlservice.delete_feed_subscriber(feed_id, authUser.uid);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        this.setError("Failed to unsubscribe feed :", feed_id);
      }
    }

    // update user's feeds_subscribed
    result = await gqlservice.user_by_id(authUser.uid);
    if (result.status_code !== GRAPHQL_SUCCESS) {
      this.setError(result.msg);
      return;
    }

    this.setWaiting(false);

    if (result.data.users.length > 0) {
      logger.log("feeds subscribed :", result.data.users[0].feeds_subscribed);
      this.props.updateFeedsSubscribed(result.data.users[0].feeds_subscribed);
    }

    localStorage.setItem('authUser', JSON.stringify(result.data.users[0]));

    this.setState({
      ...this.state,
      checked: new_checked
    });
  }

  handleClick = feed_id => {
    const { searchKey, my_feeds, moderated_feeds, your_feeds } = this.state;

    // filter by search key
    let feeds2show = this.searchFeeds(your_feeds, searchKey);

    const my_search_feeds = this.searchFeeds(my_feeds, searchKey);
    for (let feed of my_search_feeds) {
      feeds2show.push(feed);
    }

    const moderated_search_feeds = this.searchFeeds(moderated_feeds, searchKey);
    for (let feed of moderated_search_feeds) {
      feeds2show.push(feed);
    }

    const feed = feeds2show.find(item => item.id === feed_id);
    if (!feed) {
      return;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);

    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setFeedBackRoute(ROUTES.PROFILE_NEWSLETTER);
  };

  handleSearchChange = searchKey => {
    this.setState({
      ...this.state,
      searchKey: searchKey
    });
  };

  handleSearchEnter = searchKey => {};

  searchFeeds(feeds, searchKey) {
    if (!searchKey) {
      return feeds;
    }

    const key = searchKey.toLowerCase();
    var result = [];
    feeds.forEach(feed => {
      if (
        !feed.private && feed.approved &&
        (feed.name.toLowerCase().includes(key) || feed.description.toLowerCase().includes(key))
      ) {
        result.push(feed);
      }
    });
    return result;
  }

  render() {
    const { classes, requesting } = this.props;
    const { 
      searchKey, 
      my_feeds, 
      moderated_feeds, 
      your_feeds, 
      checked 
    } = this.state;

    let feeds2show = this.searchFeeds(your_feeds, searchKey);

    const my_feeds_searched = this.searchFeeds(my_feeds, searchKey);
    const moderated_feeds_searched = this.searchFeeds(moderated_feeds, searchKey);

    let title = "Email Newsletter";

    const hasChecked = (feed_id) => {
      const result = checked.find(item => item.id === feed_id);
      if (!result) {
        return false;
      }
      return result.checked;
    }
  
    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <SearchAppBar
            title={title}
            onSearchChange={this.handleSearchChange}
            onSearchEnter={this.handleSearchEnter}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.feeds}>
          {feeds2show.length > 0 && (
            <List>
              {feeds2show.map(feed => (
                <FeedItem
                  key={feed.id}
                  feed={feed}
                  checked={hasChecked(feed.id)}
                  onClicked={this.handleClick}
                  onToggled={this.handleToggle}
                />
              ))}
            </List>
          )}
          {my_feeds_searched.length > 0 && (
            <div className={classes.myfeeds}>
              <Button className={classes.title}>{"My Feeds"}</Button>
              <List>
                {my_feeds_searched.map(feed => (
                  <FeedItem
                    key={feed.id}
                    feed={feed}
                    checked={hasChecked(feed.id)}
                    onClicked={this.handleClick}
                    onToggled={this.handleToggle}
                  />
                ))}
              </List>
            </div>
          )}
          {moderated_feeds_searched.length > 0 && (
            <div className={classes.myfeeds}>
              <Button className={classes.title}>{"Moderated Feeds"}</Button>
              <List>
                {moderated_feeds_searched.map(feed => (
                  <FeedItem
                    key={feed.id}
                    feed={feed}
                    checked={hasChecked(feed.id)}
                    onClicked={this.handleClick}
                    onToggled={this.handleToggle}
                  />
                ))}
              </List>
            </div>
          )}
        </div>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  theme_mode: state.uiState.theme_mode,
  socialtypes: state.dataState.socialtypes,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(NewsletterFeeds);
