import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { withAuthentication } from "session";
import { ArticleBar } from "components";
// import SearchedNewsView from "./components/SearchedNewsView";
import { NewsTabs, NewsCard, NewsContent } from "./components";
import * as ROUTES from "constants/routes";
import { MAX_ARTICLE_WIDTH } from "constants/types";


const styles = theme => ({
  root: {
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  card: {
    margin: theme.spacing(1)
  }
});

class Newspaper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentType: 0 // 0:SUMMARY, 1:FULL, 2:ORIGINAL(for translated articles)
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleChangeNewsTab = this.handleChangeNewsTab.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleNavBack = () => {
    const { article_backroute } = this.props;
    const location = {
      pathname: article_backroute,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleChangeNewsTab = content_type => {
    this.setState({ contentType: content_type });
  };

  render() {
    const {
      classes,
      selectedSearchArticle,
      sources,
      newssites,
      theme_mode
    } = this.props;
    const { contentType } = this.state;

    let articleSource = sources.find(
      source => source.id === selectedSearchArticle.source_id
    );
    let newssite = newssites.find(
      item => item.id === selectedSearchArticle.source_id
    );

    // get share url
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.CATEGORY_PREFIX}/${articleSource.category_id}/${ROUTES.SOURCE_PREFIX}/${articleSource.slug}`;
    shareUrl += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${selectedSearchArticle.nid}`;

    // get share title
    let shareTitle = '';
    if (!selectedSearchArticle.title) {
      shareTitle = "Raven article";
    } else {
      shareTitle = selectedSearchArticle.title;
    }

    let shareInfo = {
      title: shareTitle,
      description: selectedSearchArticle.summary === null ? '' : selectedSearchArticle.summary,
      image: selectedSearchArticle.image === null ? '' : selectedSearchArticle.image,
      hashtag: '',
      url: shareUrl
    };

    // In search mode, the article text contains only translated.
    // Therefore, the original text can't be showed
    const show_original = false;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <ArticleBar
            title={"Article"}
            share_info={shareInfo}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.card}>
          <NewsCard
            article={selectedSearchArticle}
            source={articleSource}
            newssite={newssite}
            search_mode={true}
          />
        </div>
        <NewsTabs
          theme_mode={theme_mode}
          show_original={show_original}
          onChangeNewsTab={this.handleChangeNewsTab}
        />
        <NewsContent
          article={selectedSearchArticle}
          source={articleSource}
          content_type={contentType}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedSearchArticle: state.dataState.selectedSearchArticle,
  sources: state.dataState.sources,
  newssites: state.dataState.newssites,
  article_backroute: state.uiState.article_backroute,
  theme_mode: state.uiState.theme_mode
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Newspaper);
