import { io } from 'socket.io-client';

const RAVEN_NODE_SERVER = process.env.REACT_APP_RAVEN_NODE_BACKEND;
const RAVEN_APP = process.env.REACT_APP_RAVEN_APP;

export const socket = io(RAVEN_NODE_SERVER, {
  withCredentials: true,
  path: process.env.REACT_APP_SOCKET_PATH,
  extraHeaders: {
    "Access-Control-Allow-Origin": RAVEN_APP,
  },
});