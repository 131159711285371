import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import Masonry from "react-masonry-component";
import { 
  FollowedCommentCard,
  SearchArticle,
  // CommentCard 
} from "components";
import _ from "lodash";  


const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  article: {
    margin: 2,
    backgroundColor: theme.palette.background.default
  },
  listitem: {
    padding: 0,
  },
});

const masonryOptions = {
  transitionDuration: 0
};

class SearchArticleMasonary extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.scrollFn = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollFn);
    window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFn);
  }

  handleClick = article => {
    this.props.saveScrollPos(window.scrollX, window.scrollY);
    this.props.onSelectArticle(article);
  };

  handleGroupId = nid => {
    this.props.saveScrollPos(window.scrollX, window.scrollY);
    this.props.onSelectGroupArticle(nid);
  };

  listenToScroll = (event) => {
    event.preventDefault();

    const { bottomNavbar, topNavbar } = this.props;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;

    const scrolled = winScroll * 1.0 / height;

    this.props.saveScrollPos(window.scrollX, window.scrollY);
    if (scrolled === 0.0) {
      //this.props.saveScrollPos(window.scrollX, window.scrollY);
      this.props.showTopNavbar(true);
    } else if (scrolled === 1.0 || scrolled >= 0.99) {
      //this.props.saveScrollPos(window.scrollX, height);
      this.props.saveScrollPos(window.scrollX, window.scrollY);
      this.props.onNeedMore();
    } else {
      if (topNavbar) {
        this.props.showTopNavbar(false);
      }
      if (!bottomNavbar) {
        this.props.showBottomNavbar(true);
      }
    }
  };
  
  render() {
    const { 
      classes, 
      width, 
      loggedIn,
      authUser,
      saved,
      articles,
      // pins, 
      // movetops, 
      theme_mode, 
      onReport,
      onEdit,
      onDelete,
      onSave,
      onDeleteSaved,
      onNeedLogin,
      onClickSource,
      onClickFeed,
      onClickUpvote,
      onClickComment,
      onClickRepost,
      onClickArticle, 
      // aiSummary,
      onClickThread,
      onClickUpvoteThread
    } = this.props; 
    
    if (articles.length === 0) {
      return <div style={{display: "none"}}></div>;
    }
    // const pin_articles = pins.map(pin => {
    //   const article = pin.article;
    //   article.pinned = true;
    //   return article;
    // });
    // const movetop_articles = movetops.map(movetop => {
    //   const article = movetop.article;
    //   article.moved = true;
    //   return article;
    // });

    // let articles2show = [];
    // if (aiSummary && aiSummary.length > 0) {
    //   articles2show = articles2show.concat(aiSummary);
    // }
    // if (pin_articles && pin_articles.length > 0) {
    //   articles2show = articles2show.concat(pin_articles);
    // }
    // if (movetop_articles && movetop_articles.length > 0) {
    //   articles2show = articles2show.concat(movetop_articles);
    // }
    // articles2show = articles2show.concat(articles);
    // articles2show = _.uniqBy(articles2show, function (e) {
    //   return e.nid;
    // });
    return (
      <div className={classes.root} style={{width: width}}>
        <Masonry
          style={{width: "auto"}}
          options={masonryOptions}
          disableImagesLoaded={false}
        >
          {articles.map(article => (
              <div className={classes.article} key={article.nid} data-cy={`articles_${article.nid}`}>
                <SearchArticle
                  saved={saved !== undefined}
                  article={article}
                  width={400}
                  theme={theme_mode}
                  loggedIn={loggedIn}
                  authUser={authUser}
                  onReport={onReport}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onSave={onSave}
                  onDeleteSaved={onDeleteSaved}
                  onLogin={onNeedLogin}
                  handleClick={this.handleClick}
                  handleGroupId={this.handleGroupId}
                  onClickSource={onClickSource}
                  onClickFeed={onClickFeed}
                  onClickUpvote={onClickUpvote}
                  onClickComment={onClickComment}
                  onClickRepost={onClickRepost}
                  onClickArticle={onClickArticle}
                />
              </div>
          ))}
        </Masonry>
      </div>
    );
  }
}

SearchArticleMasonary.propTypes = {
  from: PropTypes.string,
  classes: PropTypes.object,
  width: PropTypes.number,
  saved: PropTypes.bool,
  articles: PropTypes.array,
  aiSummary:PropTypes.array,
  pins: PropTypes.array,
  movetops: PropTypes.array, 
  onNeedMore: PropTypes.func,
  onLogin: PropTypes.func,
  onReport: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onDeleteSaved: PropTypes.func,
  onSelectArticle: PropTypes.func,
  onSelectGroupArticle: PropTypes.func,
  onClickSource: PropTypes.func,
  onClickFeed: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
  onClickArticle: PropTypes.func,
  onClickThread: PropTypes.func,
};

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  scrollPos: state.uiState.scrollPos,
  bottomNavbar: state.uiState.bottomNavbar,
  topNavbar: state.uiState.topNavbar,
  theme_mode: state.uiState.theme_mode,
  followedComments:state.dataState.followedComments
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SearchArticleMasonary);
