import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withFirebase } from 'services';
import { withAuthentication } from "session";
import { 
  ArticleBar,
  CommentThreadCard, 
  CommentList,
  DlgConfirm,
  DlgShareComment,
  DlgReport,
  DlgLoginConfirm,
  PopMenuCommentUser,
  PopMenuThreadMod,
  WaitingSpinner
} from "components";
import * as ROUTES from "constants/routes";
import { 
  FEED_COMMENT_UNMODERATED, 
  BANNED_TYPE_1D,
  BANNED_TYPE_7D,
  BANNED_TYPE_PERM,
  THREAD_REPORT_FEED,
  FLAGGED_COMMENTS_POSTS,
} from "constants/types";
import { GraphqlService } from "services";
import { MAX_ARTICLE_WIDTH } from "constants/types";
import { 
  getMainInfo,
  getFeedInfo,
  isFeedModerator,
  isBanned,
  isBannedComments,
  isCommentEnabledFeed,
  deleteThread,
  banComments,
  preapproveUser,
  reportThread,
  closeThread,
  deleteAllComments,
  updateFeedNotifications,
  getAuthToken,
} from "dataapis";
import { 
  ToastError, ToastInfo, ToastSuccess
} from "utility/toast";
import { logger } from "utility/logging";
import { isFlaggedModerationByAi } from "utility/ravenapi";
import { get_branch_name } from "constants/branches";
import moment from "moment/moment";
import { updateReportLimit } from "dataapis/user";
import { handleReportWithLimit } from "utility/reportlimit";


const styles = theme => ({
  root: {
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  card: {
    margin: theme.spacing(1)
  },
});

class CommentThread extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      loginDlg: false,
      reportDlg: false,
      deleteConfirmDlg: false,
      banState: 0,
      shareDlg: false,
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleShareComment = this.handleShareComment.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);

    this.showDeleteConfirmDlg = this.showDeleteConfirmDlg.bind(this);
    this.handleDeleteThread = this.handleDeleteThread.bind(this);
    this.handleCloseComments = this.handleCloseComments.bind(this);
    this.handleDeleteComments = this.handleDeleteComments.bind(this);

    this.handleApproveUser = this.handleApproveUser.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.handleAnchorEl = this.handleAnchorEl.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);

    this.showReportDlg = this.showReportDlg.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleCancelReport = this.handleCancelReport.bind(this);

    this.handleMemberTrial = this.handleMemberTrial.bind(this);
  }

  setError = message => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };


  componentDidMount = async () => {
    logger.log("CommentThread id : ", this.props.match.params.id);
    logger.log("Feed slug : ", this.props.match.params.feed_slug);

    const thread_id = this.props.match.params.id;
    const feed_slug = this.props.match.params.feed_slug;

    this.setWaiting(true);

    await getMainInfo();
    if (feed_slug && this.props.selected_feed === null) {
      await getFeedInfo(feed_slug);
    }
    logger.log("comment thread selected feed :", this.props.selected_feed);
    await this.getThread(thread_id);

    this.setWaiting(false);
  }

  getThread = async (thread_id) => {
    const { selected_feed } = this.props;
    let approved = true;
    if (selected_feed && selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .thread_by_id(thread_id, approved)
      .then(
        result => {
          const threads = result.data.threads;
          if (threads.length > 0) {
            this.props.selectThread(threads[0]);
          }
        },
        reason => {
          this.setError(reason.msg);
        }
      )
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  handleNavBack = () => {
    this.props.history.goBack();
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  }

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  handleAnchorEl = (anchorEl, thread) => {
    this.setState({
      ...this.state,
      anchorEl: anchorEl,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleShareComment = () => {
    this.setState({
      ...this.state,
      shareDlg: true,
      anchorEl: null
    });
  };

  handleCloseShare = () => {
    this.setState({
      ...this.state,
      shareDlg: false,
      anchorEl: null
    });
  }

  showDeleteConfirmDlg = (show, banState = 0) => {
    this.setState({
      ...this.state,
      anchorEl: false,
      deleteConfirmDlg: show,
      banState: banState,
    });
  }

  handleDeleteThread = async () => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    this.setWaiting(true);

    const { selected_thread } = this.props;
    await deleteThread(selected_thread);

    const { banState } = this.state;
    if (banState !== 0) {
      let banned_type = -1;
      if (banState === 1) {
        banned_type = BANNED_TYPE_1D;
      } else if (banState === 7) {
        banned_type = BANNED_TYPE_7D;
      } else if (banState === -1) {
        banned_type = BANNED_TYPE_PERM;
      }

      await banComments(selected_thread.poster.uid, banned_type);
      ToastInfo(`User ${selected_thread.poster.username} banned!`);
    }
    
    this.setWaiting(false);

    this.handleNavBack();
  }

  handleApproveUser = async () => {
    this.handleCloseMenu();
    
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { thread, selected_feed } = this.props;
    await preapproveUser(thread.poster.uid, selected_feed);
    this.setAlert(`User ${thread.poster.username} preapproved!`);
  }

  handleCloseComments = async () => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    const { selected_thread } = this.props;
    await closeThread(selected_thread);
  }

  handleDeleteComments = async () => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    const { selected_thread } = this.props;
    await closeThread(selected_thread);
    await deleteAllComments(selected_thread);
  }

  showReportDlg = () => {
    this.handleCloseMenu();
    const { loggedIn } = this.props;
    if (loggedIn) {
      if (isBannedComments()) {
        ToastError("You've suspended for comment operations.");
        return;
      }

      this.setState({
        ...this.state,
        reportDlg: true,
        loginDlg: false,
      });
    } else {
      this.setState({
        ...this.state,
        reportDlg: false,
        loginDlg: true,
      });
    }
  };

  handleReport = async (reportMsg) => {
    this.setState({
      ...this.state,
      reportDlg: false,
      anchorEl: null
    });
    
    const message = reportMsg.trim();
    if (!message) {
      this.setError("Report shouldn't be blank. Please input your report.");
      return;
    }

    const { selected_feed, selected_thread ,authUser } = this.props;
    if (!selected_feed) {
      this.setError("Can't report outside from feeds.");
      return;
    }

    //reportlimit
    const updatedAuthUser = await handleReportWithLimit(authUser);
    if(!updatedAuthUser){
      ToastError("Report limit exceeded. Please try again later.");
      return null
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    gqlservice.set_auth_jwt(token);
     const isAiModerationEnabled = JSON.parse(selected_feed.ai_moderation) === null ? false :JSON.parse(selected_feed.ai_moderation).some((item)=> item === FLAGGED_COMMENTS_POSTS)
    //  const isAiModerationEnabled =true
     if(isAiModerationEnabled){
       const topSources = this.getTopSources()
       const currentDate = moment(); // Get the current date and time
       const date90DaysAgo = currentDate.subtract(90, 'days'); // Subtract 90 days from the current date
       console.log(date90DaysAgo.toISOString());
       const reportCount = await gqlservice.thread_reports_count(authUser.uid ,date90DaysAgo.toISOString())
       const params ={
         name: selected_feed.name,
         description: selected_feed.description,
         userName: authUser.name,
         userBio: authUser.biography,
         userId:authUser.uid,
         userKarmaScore: authUser?.articles_voted?.length,
         topSources:topSources,
         userPostedSources:{
           postType : "comment",
           socialMediaContent:selected_thread?.text,
           reportCount:reportCount?.data?.thread_reports_aggregate?.aggregate?.count
         }
       }
       try{
        // const gqlservice = new GraphqlService();
         await isFlaggedModerationByAi(params, token).then(
          async (result) => {
             if (result.status === 200) {
               if ((result.message.decision.toLowerCase()) !== "denied") {
                 console.log("Response from ai :", JSON.stringify(result.message.reason));
                 await updateReportLimit(updatedAuthUser)
                  this.props.setAuthUser(updatedAuthUser);  
                 this.setError("This comment can't be reported due to "+ JSON.stringify(result.message.reason));
                 throw new Error('Approve'); // Throw an error to exit the Promise chain
               }else{
               
                // const token = await getAuthToken();
                if (!token) {
                  this.handleLogin();
                  return;
                }
                gqlservice.set_auth_jwt(token, true);
                ToastSuccess(
                  "Reported the source successfully by AI " + JSON.stringify(result.message.reason)
                  );
                  let route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
                  const location = {
                    pathname: route,
                    state: { animation: "left" },
                  };
                  this.goTo(location);
                  await deleteThread(selected_thread);
                  await updateFeedNotifications(selected_feed);
                  await updateReportLimit(updatedAuthUser)
                  this.props.setAuthUser(updatedAuthUser);
               }
               }
               throw new Error('Error'); // Throw an error to exit the Promise chain
           },
           (reason) => {
             this.setError(reason.msg);
             throw new Error('Error'); // Throw an error to exit the Promise chain
           }
         ).catch((err) => {
           this.setError(JSON.stringify(err.Error));
           throw new Error(err); // Throw an error to exit the Promise chain
         });
       
       }catch(err){
         console.log("The source is denied",err)
         return ;
       }
 
     }else{
       await reportThread(selected_thread, message, THREAD_REPORT_FEED);
       await updateReportLimit(updatedAuthUser)
       this.props.setAuthUser(updatedAuthUser);
     }

  };
  // For getting top sources
 getTopSources = () => {
  const { selected_feed  } =this.props
  let topSources = [];
  let totalCount = selected_feed?.feed_sources?.length >= 9 ? 9 : selected_feed?.feed_sources?.length;
  for (let i = 0; i <= totalCount; i++) {
    const branch_name = get_branch_name(selected_feed?.feed_sources[i]?.source?.branch);
    if (selected_feed?.feed_sources[i] && selected_feed?.feed_sources[i].source) {
        let new_top_source ={
          name:selected_feed?.feed_sources[i].source?.name ,
          description:selected_feed?.feed_sources[i].source?.description , 
          socialMediaType: branch_name
         }
         topSources.push(new_top_source);
    }
  }
  return topSources;
}
goTo = (location) => {
  // this.unregisterSubscribers();
  this.props.history.push(location);
}
  handleCancelReport = () => {
    this.setState({
      ...this.state,
      reportDlg: false,
      anchorEl: null
    });
  };

  handleMemberTrial = () => {
    const location = {
      pathname: ROUTES.MEMBERSHIP,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  }

  _getShareInfo = () => {
    const { selected_feed, selected_thread } = this.props;

    if (!selected_feed) {
      logger.error("Can't share this thread because of non selected feed!");
      return null;
    }

    // share info
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.THREAD_PREFIX}/${selected_thread.id}`;

    let shareInfo = {
      title: "Raven Comment: " + selected_thread.title,
      description: selected_thread.text,
      image: '',
      hashtag: '',
      url: shareUrl
    };

    return shareInfo;
  }

  render() {
    const {
      classes,
      selected_thread,
      selected_feed,
      newssites,
      theme_mode,
      requesting
    } = this.props;
    const { 
      anchorEl, 
      reportDlg, 
      loginDlg, 
      deleteConfirmDlg,
      banState,
      shareDlg
    } = this.state;

    if (newssites.length === 0 || selected_feed === null || selected_thread === null) {
      return <div style={{display: "none"}}></div>;
    }

    // thread enabled(not disable & closeall)
    const enabled = isCommentEnabledFeed(selected_feed);

    // popup menu position
    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }
    logger.log("CommentThread :", enabled, menuPos, anchorEl);

    // share info
    const shareInfo = this._getShareInfo();

    const moderator = isFeedModerator();
    let banDescription = "";
    if (moderator && banState !== 0) {
      if (banState === 1) {
        banDescription = ", and ban the user of this thread for 1 day";
      } else if (banState === 7) {
        banDescription = ", and ban the user of this thread for 7 days";
      } else if (banState === -1) {
        banDescription = ", and ban the user of this thread permanently";
      }
    }

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <ArticleBar
            title={"Comment"}
            onNavBack={this.handleNavBack}
            onShare={this.handleShareComment}
          />
        </div>
        <div className={classes.card}>
          <CommentThreadCard
            thread={selected_thread}
            onAnchorEl={this.handleAnchorEl}
          />
        </div>
        <CommentList
          type={selected_thread.type}
          // thread_id={selected_thread.type === THREAD_TYPE_FEED ? selected_thread.id : selected_thread.article.nid}
          thread_id={selected_thread.id}
          onError={this.setError}
          onNeedLogin={this.handleLogin}
          onMemberTrial={this.handleMemberTrial}
          history={this.props.history}
        />
        {enabled && anchorEl && (
          <Menu
            id="comment-thread-menu"
            // anchorEl={anchorEl}
            anchorReference="anchorPosition"
            anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={anchorEl !== null}
            onClose={this.handleCloseMenu}
          >
            {moderator ? (
              <PopMenuThreadMod
                theme={theme_mode}
                type={selected_thread.type}
                onDelete={e => this.showDeleteConfirmDlg(true)}
                onBan1d={e => this.showDeleteConfirmDlg(true, 1)}
                onBan7d={e => this.showDeleteConfirmDlg(true, 7)}
                onBanPerm={e => this.showDeleteConfirmDlg(true, -1)}
                onCloseComments={this.handleCloseComments}
                onDeleteComments={this.handleDeleteComments}
                onShare={this.handleShareComment}
                onApproveUser={this.handleApproveUser}
              />
            ):(
              <PopMenuCommentUser
                theme={theme_mode}
                onReport={this.showReportDlg}
                onShare={this.handleShareComment}
              />
            )}
          </Menu>
        )}
        {enabled && (
          <DlgReport
            open={reportDlg}
            title={"Report Comment"}
            theme={theme_mode}
            onReport={this.handleReport}
            onCancel={this.handleCancelReport}
          />
        )}
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgConfirm
          open={deleteConfirmDlg}
          title={"Delete thread"}
          content={`Are you sure to delete this thread and its comments${banDescription}?`}
          onOK={this.handleDeleteThread}
          onCancel={e => this.showDeleteConfirmDlg(false)}
        />
        <DlgShareComment
          open={shareDlg && shareInfo !== null}
          comment={selected_thread}
          shareInfo={shareInfo}
          onClose={this.handleCloseShare}
        />
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  feeds: state.dataState.feeds,
  selected_thread: state.dataState.selected_thread,
  selected_article: state.dataState.selected_article,
  selected_feed: state.dataState.selected_feed,
  newssites: state.dataState.newssites,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) { 
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CommentThread);
