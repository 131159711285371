import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { withFirebase } from "services";
import { withAuthentication, withAuthorization } from "session";
import { BasicAppBar, WaitingSpinner } from "components";
import * as ROUTES from "constants/routes";
import {
  TAB_SOURCES,
  MIN_CARD_WIDTH,
  MAX_CARD_WIDTH,
  MAX_WINDOW_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
import { approveFeed, updateCache } from "dataapis";
import { CategoryController } from "controllers";
import { ToastError } from "utility/toast";

const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  container: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  itemcontainer: {
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  listitem: {
    display: "-webkit-box",
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
    padding: 0,
    paddingRight: 60,
    margin: 0,
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "#232323"
    // }
  },
  listitem_avatar: {
    minWidth: 60,
  },
  listimage: {
    objectFit: "cover",
    height: 60,
    width: 60,
    borderRadius: 5,
  },
  listitem_text: {
    paddingLeft: theme.spacing(2),
    paddingRight: 68,
    color: theme.palette.text.primary,
  },
  name: {
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: "12px",
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
  btncontainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  actionbtn: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "20px",
    padding: "4px 10px",
    marginLeft: theme.spacing(1),
    textTransform: "initial",
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  actionicon: {
    padding: 0,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
});

class FeedsProposed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      proposed: [],
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleEditFeed = this.handleEditFeed.bind(this);
    this.handleApproveFeed = this.handleApproveFeed.bind(this);
    this.handleDeleteFeed = this.handleDeleteFeed.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount() {
    const { selected_category } = this.props;

    this.setState({
      ...this.state,
      proposed: selected_category.proposed_feeds,
    });
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    const { selected_category } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}`;
    const location = {
      pathname: route,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleClickFeed = (feed) => {
    const { selected_category } = this.props;

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_SOURCES);
    this.props.setFeedBackRoute(
      `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}/proposedfeeds`
    );
    this.props.initScrollPos();

    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  handleEditFeed = (feed) => {
    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_SOURCES);

    const route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/edit`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  deleteFeed = (feed_id) => {
    const { proposed } = this.state;
    const new_proposed = proposed.filter((feed) => feed.id !== feed_id);

    this.setState({
      ...this.state,
      proposed: new_proposed,
    });
  };

  updateCategoryNotifications = async () => {
    const { selected_category, feeds } = this.props;
    const categoryController = new CategoryController();
    await categoryController.update_category_notifications(
      selected_category,
      feeds
    );
  };

  handleApproveFeed = async (feed) => {
    this.setWaiting(true);

    await approveFeed(feed);
    this.deleteFeed(feed.id);

    await this.updateCategoryNotifications();
    await updateCache();

    this.setWaiting(false);
  };

  handleDeleteFeed = async (feed) => {
    this.setWaiting(true);

    await this.deleteFeed(feed);
    this.deleteFeed(feed.id);

    await this.updateCategoryNotifications();

    this.setWaiting(false);
  };

  render() {
    const { classes, requesting } = this.props;
    const { proposed } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Proposed Feeds"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <List className={classes.container}>
          {proposed.map((feed, index) => (
            <div key={index} className={classes.itemcontainer}>
              <ListItem className={classes.listitem} key={index}>
                <ListItemAvatar
                  className={classes.listitem_avatar}
                  onClick={(e) => this.handleClickFeed(feed)}
                >
                  <div>
                    <LazyLoadImage
                      className={classes.listimage}
                      alt={feed.name}
                      src={feed.thumbnail || feed.image || RAVEN_PLACEHOLDER_IMAGE}
                    />
                  </div>
                </ListItemAvatar>
                <ListItemText
                  id={index}
                  className={classes.listitem_text}
                  primary={
                    <div>
                      <Typography className={classes.name}>
                        {feed.name}
                      </Typography>
                      <Typography className={classes.description}>
                        {feed.description}
                      </Typography>
                    </div>
                  }
                />
              </ListItem>
              <Box className={classes.btncontainer}>
                <Button
                  className={classes.actionbtn}
                  startIcon={
                    <img
                      className={classes.actionicon}
                      alt="edit"
                      src={`/static/images/edit.png`}
                    />
                  }
                  onClick={(e) => this.handleEditFeed(feed)}
                >
                  Edit
                </Button>
                <Button
                  className={classes.actionbtn}
                  startIcon={
                    <img
                      className={classes.actionicon}
                      alt="approve"
                      src={`/static/images/approve.png`}
                    />
                  }
                  onClick={(e) => this.handleApproveFeed(feed)}
                >
                  Approve
                </Button>
                <Button
                  className={classes.actionbtn}
                  startIcon={
                    <img
                      className={classes.actionicon}
                      alt="delete"
                      src={`/static/images/delete.png`}
                    />
                  }
                  onClick={(e) => this.handleDeleteFeed(feed)}
                >
                  Dismiss
                </Button>
              </Box>
            </div>
          ))}
        </List>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_category: state.dataState.selected_category,
  feeds: state.dataState.feeds,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedsProposed);
