import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { 
  Grid, 
  Typography, 
  Avatar, 
  Button 
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { 
  MAX_ARTICLE_WIDTH,
  THREAD_TYPE_FEED 
} from "constants/types";
import { 
  get_elapsed_time 
} from "utility/utils";


const styles = (theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
  },
  gridorder: {
    width: 32,
  },
  order: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  title: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    color: theme.palette.text.primary,
  },
  image: {
    width: 24,
    height: 24,
  },
  label: {
    fontSize: "14px",
    fontWeight: 100,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
  name: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "14px",
    color: theme.palette.text.primary,
  },
  gridupvotes: {
    width: 28,
  },
  upvotesbtn: {
    display: "block",
    paddingBottom: 12,
    padding: 0,
    minWidth: 28,
    width: 28,
  },
  upvotemark: {
    height: 24,
    width: 24,
    color: theme.palette.text.secondary,
  },
  upvotes: {
    fontSize: 12,
    lineHeight: 0,
  },
  avatar: {
    width: 32,
    height: 24,
  },
});


class CommentThreadItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleUpvote = this.handleUpvote.bind(this);
  }

  handleClick = () => {
    this.props.onClick(this.props.thread);
  }

  handleUpvote = () => {
    this.props.onUpvote(this.props.thread);
  }

  render() {
    const { classes, thread, index, width } = this.props;

    let fromWidth = 130;
    if (width > MAX_ARTICLE_WIDTH) {
      fromWidth = (MAX_ARTICLE_WIDTH) / 2 - 48;
    } else {
      fromWidth = (width) / 2 - 48;
    }

    let image = "";
    let posted_by = "";
    let comment_count = 0;
    if (thread.type === THREAD_TYPE_FEED) {
      image = thread.poster.image;
      posted_by = thread.poster.username;
      comment_count = thread.comments_aggregate.aggregate.count;
    } else {
      image = thread.article.source.image;
      posted_by = thread.article.source.name;
      comment_count = thread.comments_aggregate.aggregate.count;
    }
    const posted_at = "Posted " + get_elapsed_time(thread.posted_at);
    const comment_string = `${comment_count} Comments`;

    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item className={classes.gridorder}>
            <Typography className={classes.order}>
              {`${index}. `}
            </Typography>
          </Grid>
          <Grid item xs={10} onClick={this.handleClick}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  {thread.title}
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item className={classes.avatar}>
                    <Avatar className={classes.image} src={image} />
                  </Grid>
                  <Grid item style={{width: fromWidth}}>
                    <Typography className={classes.label}>{"From"}</Typography>
                    <Typography className={classes.name}>{posted_by}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.label}>{posted_at}</Typography>
                    <Typography className={classes.name}>{comment_string}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridupvotes}>
            <Button className={classes.upvotesbtn} onClick={this.handleUpvote}>
              <ArrowDropUpIcon className={classes.upvotemark} />
              <Typography className={classes.upvotes}>
                {thread.upvotes === null ? "0": thread.upvotes}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CommentThreadItem.propTypes = {
  classes: PropTypes.object,
  thread: PropTypes.object,
  index: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
  onUpvote: PropTypes.func
};

export default withStyles(styles)(CommentThreadItem);
