import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, IconButton, Menu } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  ARTICLE_BRANCH_TWITTER,
  get_branch,
  is_weblink_branch,
  ARTICLE_BRANCH_PODCAST,
  ARTICLE_BRANCH_YOUTUBE
} from "constants/branches";
import { 
  PopMenuSourceMod, 
  DlgKeyword, 
  DlgConfirm 
} from "components";
import * as ROUTES from "constants/routes";
import { GraphqlService } from "services";
import {
  MIN_CARD_WIDTH,
  MAX_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
import { 
  isBanned, 
  hasPaid, 
  upvoteSource, 
  translateSource
} from "dataapis";
import { build_source_link } from "utility/buildlink";
import { summarize_text, is_source_alive, copy2clipboard } from "utility/utils";
import { ToastSuccess, ToastError } from "utility/toast";
import { logger } from "utility/logging";
import { getAuthToken } from "dataapis";
import { get_cached_item, set_cache_item } from "utility/cachemanager"; 

const styles = (theme) => ({
  root: {
    // height: '100%',
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  root_disabled: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    opacity: 0.38,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  grid: {
    // justifyContent: "left",
    flexWrap: "inherit",
    backgroundColor: theme.palette.background.card,
    // margin: 3,
    borderRadius: 5,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  button: {
    margin: 0,
    marginRight: theme.spacing(1),
    padding: 0,
  },
  image: {
    objectFit: "cover",
    height: 80,
    width: 80,
    borderRadius: 10,
    margin: theme.spacing(1),
  },
  number: {
    position: "absolute",
    fontSize: "18px",
    fontWeight: 600,
    fontStyle: "italic",
    lineHeight: "24px",
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 100,
  },
  check: {
    position: "absolute",
    left: theme.spacing(0.5),
    top: theme.spacing(1),
    zIndex: 100,
  },
  triangle_corner: {
    position: "absolute",
    borderRight: "56px solid transparent",
    borderLeft: `56px solid ${theme.palette.background.default}`,
    borderBottom: "56px solid transparent",
    height: 0,
    width: 0,
    left: 0,
    top: 0,
    zIndex: 2,
  },
  expand: {
    position: "absolute",
    top: 0,
    right: theme.spacing(1),
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  titlediv: {
    marginTop: theme.spacing(1),
  },
  title: {
    position: "relative",
    display: "inline",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    textTransform: "none",
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  branchimage: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1) + 28,
    width: 16,
    height: 16,
  },
  aliveimage: {
    position: "absolute",
    top: theme.spacing(3.5),
    right: theme.spacing(1) + 30,
  },
  description: {
    position: "relative",
    textAlign: "left",
    fontSize: "12px",
    fontWeight: 100,
    lineHeight: "14px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1) + 36,
  },
  upvotesbtn: {
    position: "absolute",
    display: "block",
    paddingBottom: 12,
    padding: 0,
    minWidth: 32,
    top: 32,
    right: 8,
  },
  upvotemark: {
    height: 32,
    width: 32,
    color: theme.palette.text.secondary,
  },
  upvotes: {
    width: 36,
    fontSize: 14,
    fontWeight: 100,
    lineHeight: 0,
  }, 
  roboicon: {
    width: 20,
    height: 20,
  },
});

// const chkstyles = (theme) => ({
//   root: {
//     height: 18,
//     color: theme.palette.text.secondary,
//     "&$checked": {
//       color: theme.palette.text.primary,
//     },
//     padding: 0,
//     marginLeft: 0,
//   },
//   checked: {},
// });

// const CustomCheckbox = withStyles(chkstyles)((props) => <Checkbox color="default" {...props} />);

class Source extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      deleteDlg: false,
      keywordDlg: false,
      // showCheck: false,
      unfollow: false,
      showrt: false,
      showTranscription: false,
      showBiasCheck: false,
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);

    this.handleEditSource = this.handleEditSource.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.handleShowSource = this.handleShowSource.bind(this);
    this.handleTranslate = this.handleTranslate.bind(this);
    this.handleShowRetweet = this.handleShowRetweet.bind(this);
    this.handleEnableBiasCheck = this.handleEnableBiasCheck.bind(this);

    this.handleKeywordFilter = this.handleKeywordFilter.bind(this);
    this.handleApplyKeyword = this.handleApplyKeyword.bind(this);
    this.handleCloseKeywordDlg = this.handleCloseKeywordDlg.bind(this);

    this.showDeleteDlg = this.showDeleteDlg.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleClick = this.handleClick.bind(this);
    this.handleUpvote = this.handleUpvote.bind(this);
    this.openSourceLink = this.openSourceLink.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.setState({
      ...this.state,
      deleteDlg: false,
    });
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount() {
    const { source, loggedIn, authUser, selected_feed } = this.props;

    if (!loggedIn) {
      this.setState({
        ...this.state,
        unfollow: false,
      });
      return;
    }

    const unfollowed_source = authUser.feed_sources_unfollowed.find(
      (item) =>
        item.feed_id === selected_feed.id && item.source_id === source.id
    );
    const showretweet_source = authUser.feed_source_showretweets.find(
      (item) =>
        item.feed_id === selected_feed.id && item.source_id === source.id
    );
    const showTranscription = (source.branch === ARTICLE_BRANCH_PODCAST|| source.branch === ARTICLE_BRANCH_YOUTUBE) && source.transcription_enabled;
    const showBiasCheck = source.is_bias_check;
    this.setState({
      ...this.state,
      unfollow: unfollowed_source !== undefined,
      showrt: showretweet_source !== undefined,
      showTranscription : showTranscription,
      showBiasCheck : showBiasCheck,
    });
  }

  handleExpand = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  showDeleteDlg = (show) => {
    this.setState({
      ...this.state,
      deleteDlg: show,
      anchorEl: null,
    });
  };

  handleDelete = () => {
    this.showDeleteDlg(false);
    const { source } = this.props;
    this.props.onDelete(source);
  };

  handleEditSource = () => {
    this.handleCloseMenu();
    const { source } = this.props;
    this.props.onEdit(source);
  };

  handleCopyLink = () => {
    this.handleCloseMenu();
    const { selected_feed, source } = this.props;
    const url = `${document.location.origin}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    copy2clipboard(url);
    ToastSuccess("Copied to clipboard");
  };

  handleShowSource = () => {
    const { source } = this.props;
    const unfollow = !this.state.unfollow;
    this.setState({
      ...this.state,
      unfollow: unfollow,
      anchorEl: null,
    });
    this.props.onUnfollowed(source.id, unfollow);
  };

  handleKeywordFilter = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      keywordDlg: true,
    });
  };

  handleTranslate = async () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });

    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { source } = this.props;
    this.setWaiting(true);
    await translateSource(source);
    this.setWaiting(false);
  }

  handleShowRetweet = () => {
    const { source } = this.props;
    const showrt = !this.state.showrt;
    this.setState({
      ...this.state,
      showrt: showrt,
      anchorEl: null,
    });
    this.props.onShowRetweet(source.id, showrt);
  };

  handleApplyKeyword = async (keyword) => {
    this.handleCloseKeywordDlg();

    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed, source } = this.props;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    logger.log("apply keyword :", selected_feed.id, source.id, keyword);

    await gqlservice
      .update_feed_source_keyword(selected_feed.id, source.id, keyword.trim())
      .then(
        (result) => {
          logger.log("graphql feed source keyword :", result);
          const feed_sources =
            result.data.update_feed_sources.returning[0].feed.feed_sources;
          this.props.updateFeedSources(selected_feed.id, feed_sources);
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });

    this.setWaiting(false);
  };

  handleCloseKeywordDlg = () => {
    this.setState({
      ...this.state,
      keywordDlg: false,
    });
  };

  handleClick = () => {
    this.props.onClicked(this.props.source.id);
  };

  handleUpvote = async () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { source } = this.props;
    this.setWaiting(true);
    await upvoteSource(source);
    this.setWaiting(false);
  };

  openSourceLink = (source_url) => {
    if (!source_url) {
      return;
    }
    window.open(source_url, "_blank");
  };

  isStaticLink = (socialtag) => {
    if (
      socialtag.type === "youtube_static_link" ||
      socialtag.type === "twitter_static_link" ||
      socialtag.type === "tiktok_static_link" ||
      socialtag.type === "pinterest_static_link" ||
      socialtag.type === "website_static_link" ||
      socialtag.type === "podcast_channel_feed" ||
      socialtag.type === "rss_url_feed"
    ) {
      return true;
    } else {
      return false;
    }
  };
 
  handleEnableTranscription = async () => {
    this.handleCloseMenu();
    const { source, loggedIn } = this.props;
    let transcription_enabled = !source.transcription_enabled;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);
    await gqlservice
      .update_transcription_enable(source.id, transcription_enabled)
      .then(async (result) => {
        const update_source = result.data.update_sources ? result.data.update_sources.returning:[];
        if (update_source.length > 0) {
          this.props.updateSource(update_source[0]);
          this.setState({
            ...this.state, 
            showTranscription : transcription_enabled
          }); 
          let cache_name = loggedIn
            ? hasPaid()
              ? "base_data_paiduser_v1"
              : "base_data_user_v1"
            : "base_data_anonymous_v1";
          let base_data = await get_cached_item(cache_name);
          if (base_data) {
            let sources = base_data.sources;
            let source_index = sources.findIndex(src => src.id === source.id);
            if (source_index !== -1) {
              sources[source_index] = update_source[0];
            }   
            base_data.sources = sources;
            set_cache_item(cache_name, base_data);
          }
        }
        ToastSuccess("Transcription settings updated successfully!");
      },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
  }

  handleEnableBiasCheck = async () => {
    this.handleCloseMenu();
    const { source, loggedIn } = this.props;
    let is_bias_check = !source.is_bias_check;  
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);
    await gqlservice
      .update_bias_check_enable(source.id, is_bias_check)
      .then(async (result) => {
        const update_source = result.data.update_sources ? result.data.update_sources.returning : [];
        if (update_source.length > 0) {
          this.props.updateSource(update_source[0]);
          this.setState({
            ...this.state,
            showBiasCheck: is_bias_check,
          });
          let cache_name = loggedIn
            ? hasPaid()
              ? "base_data_paiduser"
              : "base_data_user"
            : "base_data_anonymous";
          let base_data = await get_cached_item(cache_name);
          if (base_data) {
            let sources = base_data.sources;
            let source_index = sources.findIndex((src) => src.id === source.id);
            if (source_index !== -1) {
              sources[source_index] = update_source[0];
            }
            base_data.sources = sources;
            set_cache_item(cache_name, base_data);
          }
        }
        ToastSuccess("Bias check settings updated successfully!");
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
  };
  

  render() {
    const { 
      classes, 
      selected_feed, 
      source, 
      order, 
      theme_mode 
    } = this.props;
    const { 
      anchorEl, 
      deleteDlg, 
      keywordDlg, 
      unfollow, 
      showrt, 
      showTranscription,
      showBiasCheck,
    } = this.state;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    // width
    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_CARD_WIDTH) width = MAX_CARD_WIDTH;
    if (width < MIN_CARD_WIDTH) width = MIN_CARD_WIDTH;
    width = width - 112;

    let branchimage = "";
    let branchname = "";
    let branchInfo = get_branch(source.branch);
    if (branchInfo) {
      branchimage = `/static/images/icons/${theme_mode}/${branchInfo.image}`;
      branchname = branchInfo.name;
    }

    const status_color = is_source_alive(source) ? "yellowgreen" : "red";

    // source link
    let social_link = null;
    let is_static_link = false;
    const socialtag = source.socialtags[0];

    if (is_weblink_branch(source.branch)) {
      if (source.weblink) {
        social_link = source.weblink;
      }
    } else {
      // check if the source is static link, then open a new window
      if(socialtag){
        is_static_link = this.isStaticLink(socialtag);
      }
    }

    if (!social_link && socialtag) {
      if (socialtag.tag.includes("http:") || socialtag.tag.includes("https:")) {
        social_link = socialtag.tag;
      } else {
        social_link = build_source_link(socialtag.type, socialtag.tag);
      }
    }

    // logger.log(`source: ${source.id}, static_link: ${is_static_link}, social_link: ${social_link}`);

    let refined_name = summarize_text(source.name, 30);
    let refined_description = summarize_text(source.description, 80);
    const classes_root = unfollow ? classes.root_disabled : classes.root;

    const feed_source_info = selected_feed.feed_sources.find(
      (feed_source) => feed_source.source_id === source.id
    );

    return (
      <div className={classes_root}>
        <Button className={classes.upvotesbtn} onClick={this.handleUpvote}>
          <ArrowDropUpIcon className={classes.upvotemark} />
          <Typography className={classes.upvotes}>{source.upvotes}</Typography>
        </Button>
        {(is_static_link && social_link) ? (
          <a target="_blank" rel="noopener noreferrer" href={social_link}>
            <img
              className={classes.branchimage}
              src={branchimage}
              alt={branchname}
            />
          </a>
        ) : (
          <img
            className={classes.branchimage}
            src={branchimage}
            alt={branchname}
          />
        )}
        <FiberManualRecordIcon
          className={classes.aliveimage}
          style={{ fontSize: 12, color: status_color }}
        />
        <Grid container className={classes.grid}>
          <div
            className={classes.triangle_corner}
            onClick={(e) =>
              is_static_link && social_link
                ? this.openSourceLink(social_link)
                : this.handleClick()
            }
          />
          <Typography
            className={classes.number}
            onClick={(e) =>
              is_static_link && social_link
                ? this.openSourceLink(social_link)
                : this.handleClick()
            }
          >
            {order + 1}
          </Typography>
          <IconButton className={classes.expand} onClick={this.handleExpand}>
            <ExpandMoreIcon />
          </IconButton>
          <Grid item>
            <Button
              className={classes.button}
              onClick={(e) =>
                is_static_link && social_link
                  ? this.openSourceLink(social_link)
                  : this.handleClick()
              }
            >
              <LazyLoadImage
                className={classes.image}
                src={source.thumbnail || source.image || RAVEN_PLACEHOLDER_IMAGE}
                alt={source.name}
              />
            </Button>
          </Grid>
          <div
            onClick={(e) => social_link ? this.openSourceLink(social_link) : null}
          >
            <Grid item>
              <div className={classes.titlediv} style={{ width: width - 80 }}>
                <Typography className={classes.title}>
                  {refined_name}
                </Typography>
                {!source.is_published ?
                  <img className={classes.roboicon}
                    alt="robort"
                    src={`/static/images/icons/${theme_mode}/robot.png`}
                  /> : <></>}
              </div>
              <Typography
                className={classes.description}
                style={{ width: width - 60 }}
              >
                {refined_description}
              </Typography>
              <div style={{ width: width }}></div>
            </Grid>
          </div>
        </Grid>
        <Menu
          id="source-menu"
          open={anchorEl !== null}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.handleCloseMenu}
        >
          {source.branch === ARTICLE_BRANCH_TWITTER && (
            <PopMenuSourceMod
              theme={theme_mode}
              follow={!unfollow}
              translate={source.translate}
              showrt={showrt}
              onEdit={this.handleEditSource}
              onDelete={(e) => this.showDeleteDlg(true)}
              onCopyLink={this.handleCopyLink}
              onShow={this.handleShowSource}
              onKeywordFilter={this.handleKeywordFilter}
              onTranslate={this.handleTranslate}
              onShowRetweet={this.handleShowRetweet}
              onEnableBiasCheck={this.handleEnableBiasCheck}
            />
          )}
          {(source.branch === ARTICLE_BRANCH_PODCAST || source.branch === ARTICLE_BRANCH_YOUTUBE)&& (
            <PopMenuSourceMod
              theme={theme_mode}
              follow={!unfollow}
              translate={source.translate}
              transcript={showTranscription}
              onEdit={this.handleEditSource}
              onDelete={(e) => this.showDeleteDlg(true)}
              onCopyLink={this.handleCopyLink}
              onShow={this.handleShowSource}
              onKeywordFilter={this.handleKeywordFilter}
              onTranslate={this.handleTranslate}
              onEnableTranscription={this.handleEnableTranscription}
              onEnableBiasCheck={this.handleEnableBiasCheck}
              showBiasCheck={showBiasCheck}
            />
          )}
          {source.branch !== ARTICLE_BRANCH_TWITTER && source.branch !== ARTICLE_BRANCH_PODCAST  && source.branch !== ARTICLE_BRANCH_YOUTUBE  && (
            <PopMenuSourceMod
              theme={theme_mode}
              follow={!unfollow}
              translate={source.translate}
              onEdit={this.handleEditSource}
              onDelete={(e) => this.showDeleteDlg(true)}
              onCopyLink={this.handleCopyLink}
              onShow={this.handleShowSource}
              onKeywordFilter={this.handleKeywordFilter}
              onTranslate={this.handleTranslate}
              onEnableBiasCheck={this.handleEnableBiasCheck}
              showBiasCheck={showBiasCheck}
            />
          )}
        </Menu>
        <DlgKeyword
          open={keywordDlg}
          theme={theme_mode}
          title={"Keyword Filter"}
          keyword={feed_source_info.keyword_filter}
          onApplyKeyword={this.handleApplyKeyword}
          onCancel={this.handleCloseKeywordDlg}
        />
        <DlgConfirm
          open={deleteDlg}
          title={"Delete Source"}
          content={"Are you sure you want to delete this source?"}
          onOK={this.handleDelete}
          onCancel={(e) => this.showDeleteDlg(false)}
        />
      </div>
    );
  }
}

Source.propTypes = {
  classes: PropTypes.object,
  source: PropTypes.object,
  order: PropTypes.number,
  onLogin: PropTypes.func,
  onClicked: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onUnfollowed: PropTypes.func,
  onShowRetweet: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Source);
