import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Menu, IconButton } from "@material-ui/core";
import MetaTags from "react-meta-tags";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ToastContainer } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { withAuthentication, withAuthorization } from "session";
import {
  SearchAppBar,
  TagChips,
  FollowFeedsList,
  PopMenuCategoryMod,
  DlgAlert,
  DlgConfirm,
  WaitingSpinner,
} from "components";
import { ModerationButtons, AlphabetToolbar } from "./components";
import * as ROUTES from "constants/routes";
import { withFirebase } from "services";
import { GraphqlService, createUserSubscriber } from "services";
import { ACTIVITY_TYPE_CATEGORY, ACTIVITY_DELETE } from "constants/activity";
import {
  MAX_WINDOW_WIDTH,
  MAX_ARTICLE_WIDTH,
  MIN_CARD_WIDTH,
  TAB_SOURCES,
} from "constants/types";
import { NOTIFICATION_CATEGORY_RESIGN_MODERATOR } from "constants/notification";
import {
  getAuthToken,
  getCategoryFeeds,
  getMainInfo,
  hasPaid,
  followFeed,
  unfollowFeed,
  isModeratorOfCategory,
} from "dataapis";
import { ToastSuccess, ToastError } from "utility/toast";
import { logger } from "utility/logging";
import { copy2clipboard } from "utility/utils";

const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  coverImage: {
    objectFit: "cover",
    marginBottom: theme.spacing(1),
    margin: 0,
    // height: 200,
    // [theme.breakpoints.down('xs')]: {
    //   width: "100%",
    // },
    // backgroundSize: "100%",
    borderRadius: 20,
  },
  chips: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: 0,
    backgroundColor: theme.palette.background.default,
  },
  expand: {
    float: "right",
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: 4,
    width: 24,
    height: 24,
    color: theme.palette.text.primary,
  },
  alphabettoolbar: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  proposedfeeds: {
    margin: theme.spacing(2),
  },
  moderationbuttons: {
    marginBottom: theme.spacing(2),
  },
  feeds: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.background.default,
  },
  addbutton: {
    position: "fixed",
    bottom: theme.spacing(2),
    zIndex: 1200,
  },
  addbuttonimg: {
    width: 60,
    height: 60,
  },
  bottomspace: {
    paddingBottom: theme.spacing(10),
  },
});

class CategoryModeration extends React.Component {
  constructor(props) {
    super(props);

    this.userConsumer = null;

    this.state = {
      searchKey: "",
      alphabetKey: "All",
      selectedTags: [],
      category: null,
      // feeds       : [],
      anchorEl: null,
      resignDlg: false,
      alertDlg: false,
      alertTitle: "",
      alertMsg: "",
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleChangeAlphabet = this.handleChangeAlphabet.bind(this);

    this.handleExpand = this.handleExpand.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.showResignDlg = this.showResignDlg.bind(this);
    this.hideResignDlg = this.hideResignDlg.bind(this);
    this.handleResign = this.handleResign.bind(this);

    this.handleReachLimit = this.handleReachLimit.bind(this);
    this.handleChangeFollowing = this.handleChangeFollowing.bind(this);
    this.handleChipSelect = this.handleChipSelect.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleAddFeed = this.handleAddFeed.bind(this);

    this.handleProposedFeeds = this.handleProposedFeeds.bind(this);
    this.handleFlaggedFeeds = this.handleFlaggedFeeds.bind(this);
    this.handleViewLog = this.handleViewLog.bind(this);
  }

  componentDidMount = async () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      const location = {
        pathname: ROUTES.SIGN_IN,
        state: { animation: "bottom" },
      };
      this.props.history.push(location);
      return;
    }

    const category_id = this.props.match.params.id;
    logger.log("category moderation category_id :", category_id);
    if (isModeratorOfCategory(category_id) === false) {
      const location = {
        pathname: ROUTES.HOME,
        state: { animation: "right" },
      };
      this.props.history.push(location);
      return;
    }

    this.setWaiting(true);

    await getMainInfo();

    const curCategory = this.props.categories.find(
      (category) => category.id === category_id
    );
    if (!curCategory) {
      const location = {
        pathname: ROUTES.HOME,
        state: { animation: "right" },
      };
      this.props.history.push(location);
      this.setWaiting(false);
      return;
    }
    this.props.selectCategory(curCategory);

    await getCategoryFeeds(category_id);

    await Promise.all([
      this.getCategoryModerates(curCategory),
      this.registerSubscribers(),
    ]);

    this.setWaiting(false);
  };

  registerSubscribers = () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return;
    }

    const userSubscriber = createUserSubscriber(authUser.uid);
    this.userConsumer = userSubscriber.subscribe(
      (data) => {
        const user = data.data.users[0];
        logger.log("user updated :", user);
        this.props.setAuthUser(user);
      },
      (err) => {
        let msg = "Error subscribing user: " + err.message;
        logger.error(msg);
      }
    );
  };

  unregisterSubscribers = () => {
    if (this.userConsumer) {
      this.userConsumer.unsubscribe();
      this.userConsumer = null;
    }
  };

  goTo = (location) => {
    this.unregisterSubscribers();
    this.props.history.push(location);
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.goTo(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleNavBack = () => {
    const { category_backroute } = this.props;
    if (category_backroute) {
      const location = {
        pathname: category_backroute,
        state: { animation: "right" },
      };
      this.goTo(location);
    } else {
      const location = {
        pathname: ROUTES.MODERATION,
        state: { animation: "right" },
      };
      this.goTo(location);
    }
  };

  handleChangeAlphabet = (alphabet) => {
    this.setState({
      ...this.state,
      alphabetKey: alphabet,
    });
  };

  handleSearchChange = (searchKey) => {
    this.setState({
      ...this.state,
      searchKey: searchKey,
    });
  };

  handleSearchEnter = (searchKey) => {};

  getCategoryTags() {
    const { selected_category, feeds } = this.props;
    const { searchKey } = this.state;

    let cat_feeds = feeds.filter(
      (feed) => feed.category_id === selected_category.id
    );
    if (cat_feeds.length === 0) {
      return [];
    }

    // get distinct tags and their occurence
    let tags = [];
    for (let feed of cat_feeds) {
      for (let feed_tag of feed.tags) {
        let exist_tag = tags.find((tag) => tag.name === feed_tag);
        if (!exist_tag) {
          tags.push({
            name: feed_tag,
            count: 1,
          });
        } else {
          exist_tag.count += 1;
        }
      }
    }

    // sort the tags according to the follower count(user count)
    tags.sort((a, b) => b.count - a.count);

    if (searchKey) {
      let key = searchKey.toLowerCase();
      tags = tags.filter((tag) => tag.name.toLowerCase().includes(key));
    }

    // get first 10 tags
    const selected_tags = tags.slice(0, 10).map((tag) => tag.name);

    return selected_tags;
  }

  handleExpand = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleCopyLink = () => {
    copy2clipboard(window.location.href);
    ToastSuccess("Copied to clipboard");
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleMenuClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  showResignDlg = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      resignDlg: true,
    });
  };

  hideResignDlg = () => {
    this.setState({
      ...this.state,
      resignDlg: false,
    });
  };

  handleResign = async () => {
    this.setState({
      ...this.state,
      resignDlg: false,
    });

    const { selected_category, authUser } = this.props;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    await gqlservice
      .resign_category_moderator(selected_category.id, authUser.uid)
      .then(
        (result) => {
          this.props.resignCategoryModerator(
            selected_category.id,
            authUser.uid
          );
          this.props.deleteCategoryModerated(selected_category.id);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    if (authUser.uid !== selected_category.created_by) {
      // insert notification
      const notification = {
        type: NOTIFICATION_CATEGORY_RESIGN_MODERATOR,
        object: selected_category.id,
        in_which: selected_category.id,
        to: selected_category.created_by,
        created_by: authUser.uid,
      };
      await gqlservice
        .insert_notification(notification)
        .then(
          (result) => {},
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });
    }

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_CATEGORY,
      type_id: selected_category.id,
      action: ACTIVITY_DELETE,
      object: `${authUser.username} resigned`,
      fromto: `from the category ${selected_category.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);

    const location = {
      pathname: `/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}`,
      state: {},
    };
    this.goTo(location);
  };

  handleAddFeed = () => {
    const location = {
      pathname: ROUTES.FEED_ADD,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleReachLimit = () => {
    if (!hasPaid()) {
      const location = {
        pathname: ROUTES.MEMBERSHIP,
        state: { animation: "bottom" },
      };
      this.goTo(location);
      // this.setState({
      //   ...this.state,
      //   alertDlg: true,
      //   alertTitle: "Follow Limit",
      //   alertMsg: "You've already reached the limit to follow. Upgrade your profile to follow more feeds."
      // });
    } else {
      this.setState({
        ...this.state,
        alertDlg: true,
        alertTitle: "Follow Limit",
        alertMsg:
          "You've already reached the limit to follow. Unfollow some feeds to follow another feeds.",
      });
    }
  };

  handleChangeFollowing = async (following, index) => {
    const { feeds, selected_category } = this.props;

    // get feeds corresponding to the following (refer to render)
    const category_feeds = feeds.filter(
      (feed) => feed.approved && feed.category_id === selected_category.id
    );
    let feeds2show = this.getFeeds2Show(category_feeds);
    if (feeds2show.length === 0) {
      return;
    }

    this.setWaiting(true);

    if (following[index]) {
      await followFeed(feeds2show[index]);
    } else {
      await unfollowFeed(feeds2show[index]);
    }

    this.setWaiting(false);
  };

  handleChipSelect = (name) => {
    const { selectedTags } = this.state;
    let new_selectedTags = selectedTags.slice();
    let selectTag = selectedTags.find((tag) => tag === name);
    if (!selectTag) {
      // select
      new_selectedTags.push(name);
    } else {
      // unselect
      new_selectedTags = selectedTags.filter((tag) => tag !== name);
    }

    this.setState({
      ...this.state,
      selectedTags: new_selectedTags,
    });
  };

  handleClickFeed = (feed) => {
    const { selected_category } = this.props;

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_SOURCES);
    this.props.setFeedBackRoute(
      `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}`
    );
    this.props.initScrollPos();

    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  searchFeeds = (feeds, searchKey) => {
    var result = [];
    var key = searchKey.toLowerCase();
    feeds.forEach((feed) => {
      if (
        !feed.private &&
        feed.approved &&
        (feed.name.toLowerCase().includes(key) ||
          feed.description.toLowerCase().includes(key))
      ) {
        result.push(feed);
      }
    });
    return result;
  };

  selectFeedsByLetter = (feeds, letter) => {
    let result = [];
    let key = letter.toLowerCase();
    feeds.forEach((feed) => {
      if (!feed.private && feed.approved) {
        let feedname = feed.name.toLowerCase();
        if (feedname[0] === key) {
          result.push(feed);
        }
      }
    });
    return result;
  };

  getFeeds2Show = (feeds) => {
    const { searchKey, selectedTags, alphabetKey } = this.state;

    let feeds2show = [];
    if (feeds.length === 0) {
      return [];
    }

    if (selectedTags.length > 0) {
      feeds2show = feeds.filter((feed) => {
        for (let feed_tag of feed.tags) {
          if (selectedTags.includes(feed_tag)) {
            return true;
          }
        }
        return false;
      });
    } else {
      feeds2show = feeds.slice();
    }

    if (searchKey) {
      return this.searchFeeds(feeds2show, searchKey);
    }
    if (alphabetKey !== "All") {
      return this.selectFeedsByLetter(feeds2show, alphabetKey);
    }
    return feeds2show;
  };

  getCategoryModerates = async (category) => {
    const { feeds } = this.props;

    category.proposed_feeds = [];
    category.feed_reports = [];
    category.category_logs = [];

    const feeds2moderate = feeds.filter(
      (feed) => feed.category_id === category.id && feed.approved === false
    );
    category.proposed_feeds = feeds2moderate;

    const category_feed_ids = feeds
      .filter((feed) => feed.category_id === category.id && feed.approved)
      .map((feed) => feed.id);
    // if (category_feed_ids.length === 0) {
    //   return category;
    // }

    let feed_reports = [];
    let category_logs = [];
    // let notifications = feeds2moderate.length;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    // show only previous 7 day's log
    const timestamp = new Date() - 86400 * 7 * 1000;
    const after = new Date(timestamp).toISOString();

    await gqlservice
      .category_moderation_fields(
        category.id,
        category_feed_ids,
        ACTIVITY_TYPE_CATEGORY,
        after
      )
      .then(
        (result) => {
          feed_reports = result.data.feed_reports_aggregate.nodes.slice();
          // const feed_reports_moderates = feed_reports.filter(report => !report.approved);
          // notifications += feed_reports_moderates.length;
          // only non approved category moderators
          // notifications += result.data.category_moderators_aggregate.aggregate.count;
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    category.feed_reports = feed_reports;
    category.category_logs = category_logs;
    // category.notifications = notifications;

    logger.log("getCategoryModerates", category);

    this.setState({
      ...this.state,
      category: category,
    });
  };

  handleProposedFeeds = () => {
    const { selected_category } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}/proposedfeeds`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleFlaggedFeeds = () => {
    const { selected_category } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}/flaggedfeeds`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleViewLog = async () => {
    const { selected_category } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    const now = Date.now();
    const viewtime = new Date(now).toISOString();

    await gqlservice
      .update_category_last_viewlog(selected_category.id, viewtime)
      .then(
        (result) => {
          const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}/logs`;
          const location = {
            pathname: route,
            state: { animation: "left" },
          };
          this.goTo(location);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  render() {
    const {
      classes,
      newssites,
      selected_category,
      followed_feeds,
      feeds,
      loggedIn,
      theme_mode,
      requesting,
    } = this.props;
    const {
      selectedTags,
      alphabetKey,
      category,
      // feeds,
      anchorEl,
      resignDlg,
      alertDlg,
      alertTitle,
      alertMsg,
    } = this.state;

    if (newssites.length === 0 || !selected_category) {
      return <div style={{display: "none"}}></div>;
    }

    // alphabets
    const alphabets = ["All"].concat("ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""));

    // tags
    const tags = this.getCategoryTags();
    // feeds
    let feeds2show = feeds.filter(
      (feed) => feed.category_id === selected_category.id && feed.approved
    );

    feeds2show = this.getFeeds2Show(feeds2show);
    feeds2show = feeds2show.sort((a, b) => b.notifications - a.notifications);

    // following
    let following = feeds2show.map((feed) => {
      let result = followed_feeds.find((element) => element.id === feed.id);
      return result === undefined ? false : true;
    });

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_WINDOW_WIDTH) {
      width = MAX_WINDOW_WIDTH;
    } else if (width < MIN_CARD_WIDTH) {
      width = MIN_CARD_WIDTH;
    }
    const height = width > MAX_ARTICLE_WIDTH ? 300 : 200;

    let addbuttonPos = 0;
    if (window.innerWidth > MAX_WINDOW_WIDTH) {
      addbuttonPos = (window.innerWidth + MAX_WINDOW_WIDTH) / 2 - 96;
    } else {
      addbuttonPos = width - 96;
    }

    // new category logs
    let new_logs = [];
    if (category) {
      new_logs = category.category_logs.filter(
        (log) => Date.parse(log.logged_at) > Date.parse(category.last_viewlog)
      );
    }

    let categoryUrl = "";
    if (typeof window !== "undefined") {
      categoryUrl = window.location.protocol + "//" + window.location.host;
    }
    categoryUrl += `/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}`;

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven: ${selected_category.name}`}</title>
            <meta name="description" content={selected_category.description} />
            <meta
              property="og:title"
              content={`Raven: ${selected_category.name}`}
            />
            <meta
              property="og:description"
              content={selected_category.description}
            />
            <meta property="og:image" content={selected_category.image} />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={categoryUrl} />
            <meta
              property="twitter:title"
              content={`Raven: ${selected_category.name}`}
            />
            <meta property="twitter:site" content="Raven App" />
            <meta
              property="twitter:description"
              content={selected_category.description}
            />
            <meta
              property="twitter:image:src"
              content={selected_category.image}
            />
            <meta
              property="twitter:image:alt"
              content={selected_category.name}
            />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar}>
          <SearchAppBar
            title={selected_category.name}
            onSearchChange={this.handleSearchChange}
            onSearchEnter={this.handleSearchEnter}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div>
          <LazyLoadImage
            className={classes.coverImage}
            style={{ width: width, height: height }}
            alt={selected_category.name}
            src={selected_category.coverImage}
          />
        </div>
        <div className={classes.chips}>
          <IconButton className={classes.expand} onClick={this.handleExpand}>
            <ExpandMoreIcon />
          </IconButton>
          {tags.length > 0 && (
            <TagChips
              chips={tags}
              selected={selectedTags}
              onSelect={this.handleChipSelect}
            />
          )}
        </div>
        <div className={classes.moderationbuttons}>
          {category !== null && (
            <ModerationButtons
              theme={theme_mode}
              flagged={category.feed_reports.length}
              proposed={category.proposed_feeds.length}
              newlogs={new_logs.length}
              logs={category.category_logs.length}
              onProposed={this.handleProposedFeeds}
              onFlagged={this.handleFlaggedFeeds}
              onViewLog={this.handleViewLog}
            />
          )}
        </div>
        <div className={classes.alphabettoolbar}>
          <AlphabetToolbar
            alphabets={alphabets}
            selected={alphabetKey}
            onChangeAlphabet={this.handleChangeAlphabet}
          />
        </div>
        <div className={classes.feeds}>
          <FollowFeedsList
            items={feeds2show}
            badged={true}
            following={following}
            onFollowingChanged={this.handleChangeFollowing}
            onItemClicked={this.handleClickFeed}
            onReachLimit={this.handleReachLimit}
          />
        </div>
        <Menu
          id="category-moderation-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleMenuClose}
        >
          <PopMenuCategoryMod
            theme={theme_mode}
            onCopyLink={this.handleCopyLink}
            onResign={this.showResignDlg}
          />
        </Menu>
        <DlgConfirm
          open={resignDlg}
          title={"Resign from Category"}
          content={"Are you sure to resign from this category?"}
          onOK={this.handleResign}
          onCancel={this.hideResignDlg}
        />
        <DlgAlert
          open={alertDlg}
          title={alertTitle}
          description={alertMsg}
          theme_mode={theme_mode}
          onOK={this.handleOK}
        />
        {loggedIn && (
          <Button
            className={classes.addbutton}
            style={{ left: addbuttonPos }}
            onClick={this.handleAddFeed}
          >
            <img
              className={classes.addbuttonimg}
              alt={"addfeed"}
              src={`/static/images/icons/${theme_mode}/add.png`}
            />
          </Button>
        )}

        <ToastContainer />
        <WaitingSpinner open={requesting} />
      </div>
    );
  }
}

CategoryModeration.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  categories: state.dataState.categories,
  selected_category: state.dataState.selected_category,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  category_backroute: state.uiState.category_backroute,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CategoryModeration);
