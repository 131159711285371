// signin method
export const SIGN_METHOD_EMAIL          = 0;
export const SIGN_METHOD_GOOGLE         = 1;
export const SIGN_METHOD_APPLE          = 2;
export const SIGN_METHOD_PHONE          = 3;

// theme mode
export const THEME_MODE_LIGHT           = 'light';
export const THEME_MODE_DARK            = 'dark';

// feed create mode
export const FEED_CREATE_PRIVACY_MODE   = 0;    // 0 -> public, 1 -> private

// default images
export const BLANK_IMAGE                = "/static/images/blank.png";
export const RAVEN_PLACEHOLDER_IMAGE    = "/static/images/placeholder.png";
export const BLANK_USER_IMAGE           = "/static/images/blank_avatar.png";
export const SUMMARY_IMAGE              ="/static/images/summary.png"
// usage quota
export const USE_QUOTA                  = false;
export const FREE_FEED_CREATE_LIMIT     = 4;
export const FREE_FEED_FOLLOW_LIMIT     = 8;
export const FREE_NEWSLETTER_LIMIT      = 8;
export const PAID_FEED_CREATE_LIMIT     = 20;
export const PAID_FEED_FOLLOW_LIMIT     = 45;

// article source type
export const SOURCE_TYPE_NEWSSITE       = 0;
export const SOURCE_TYPE_SOCIALFEED     = 1;

// 3th party api status
export const FIREBASE_SUCCESS           = 200;
export const FIREBASE_FIRESTORE_ERROR   = 501;
export const FIREBASE_STORAGE_ERROR     = 502;
export const AUTH_NOT_APPROVED          = 401;
export const GRAPHQL_SUCCESS            = 0;
export const GRAPHQL_ERROR              = -1;

// width of card & view
export const MAX_WINDOW_WIDTH           = 1920;     // breakpoint lg
export const MIN_TABLET_WIDTH           = 844;
export const MIN_CARD_WIDTH             = 320;
export const MAX_CARD_WIDTH             = 414;
export const MAX_ARTICLE_WIDTH          = 640;

// app constants
export const ARTICLES_PER_PAGE          = 10;
export const USER_TAGS_MIN_CNT          = 3;
export const TOP_FEEDS_CNT              = 10;

// status of scrape request
export const SCRAPE_REQUESTED           = 0;
export const SCRAPE_REJECTED            = 1;
export const SCRAPE_ACCEPTED            = 2;
export const SCRAPE_PROCESSING          = 3;
export const SCRAPE_DONE                = 4;

// Discover Page tab type
export const TAB_CATEGORIES             = 0;
export const TAB_TRENDING               = 1;
export const TAB_FORYOU                 = 2;
export const TAB_TOPFEEDS               = 3;

// feed tab type
export const TAB_FEED                   = 0;
export const TAB_SOURCES                = 1;
export const TAB_COMMENTS               = 2;
export const TAB_MODERATORS             = 3;

// profile tab type
export const PROFILE_TAB_POSTS          = 0;
export const PROFILE_TAB_FEEDS          = 1;
export const PROFILE_TAB_SETTINGS       = 2;

// map tab type
export const TAB_MAP_FEED               = 0;
export const TAB_MAP_READINGS           = 1;
export const TAB_MAP_SCHOOLS            = 2;
export const TAB_MAP_NEWS               = 3;
export const TAB_MAP_SPORTS             = 4;

// location tab type
export const TAB_LOC_READINGS           = 0;
export const TAB_LOC_FEEDS              = 1;
export const TAB_LOC_MODERATORS         = 2;

// leaderboards tab type
export const TAB_LBOARD_VOLUNTEERS      = 0;
export const TAB_LBOARD_REGIONS         = 1;
export const TAB_LBOARD_BEST            = 2;

// MyFeeds tab type
export const TAB_MYFEEDS_FEEDS          = 0;
export const TAB_MYFEEDS_USERS          = 1;
export const TAB_MYFEEDS_FOLLOWING      = 2;
export const TAB_MYFEEDS_FOLLOWERS      = 3;

// leaderboards ranking count to show
export const LBOARD_RANKING_COUNT       = 50;

// thread type
export const THREAD_TYPE_FEED           = 0;
export const THREAD_TYPE_ARTICLE        = 1;
export const THREAD_TYPE_MAPLOCATION    = 2;
export const THREAD_TYPE_MAPREADING     = 3;

// thread report type
export const THREAD_REPORT_FEED         = 0;
export const THREAD_REPORT_COMMENTS     = 1;
export const THREAD_REPORT_MAP_COMMENTS = 2;

// user ban type
export const BANNED_TYPE_1D             = 0;
export const BANNED_TYPE_7D             = 1;
export const BANNED_TYPE_PERM           = 2;

// feed comment setting
export const FEED_COMMENT_DISABLE       = 0;
export const FEED_COMMENT_CLOSEALL      = 1;  //'CLOSEALL';
export const FEED_COMMENT_UNMODERATED   = 2;  //'UNMODERATED';
export const FEED_COMMENT_MODERATED     = 3;  //'MODERATED';
export const FEED_COMMENT_DELETEALL     = 4;  //'DELETEALL';
export const FEED_COMMENT_TWITTERUSER   = 5;

// discussion type
export const DISCUSSION_TWITTER         = 0;
export const DISCUSSION_REDDIT          = 1;
export const DISCUSSION_RAVEN           = 2;

// max video length to upload
export const MAX_VIDEO_LENGTH           = 300; // seconds

// feed AI comment setting
export const FEED_NO_AI_COMMENT         = 0;  //'NO AI COMMENTS';
export const FEED_AI_COMMENT_FOR_CHOSEN = 1;  //'ONLY ON ARTICLES I CHOOSE';
export const FEED_AI_COMMENT_TOP_FIVE   = 2;  //AUTOMATICALLY ON TOP 5 ARTICLES
export const FEED_ALLOW_CHOOSE_TO_COMMENT  = 3;//AI chooses what to comment on

// feed AI summary setting
export const FEED_NO_SUMMARIES = 0;
export const FEED_DAILY_SUMMARY = 1;
export const FEED_WEEKLY_SUMMARY = 2;


//feed AI Auto moderation settings
export const APPROVING_POSTS = 1;
export const SOURCE_SUGGESTIONS = 2;
export const FLAGGED_COMMENTS_POSTS = 3;


//mastodon tab type

export const TAB_MASTODON_ALL    = 0;
export const TAB_MASTODON_FEEDS  = 1;
export const TAB_MASTODON_SOURCES = 2;
export const TAB_MASTODON_HASHTAGS = 3;
export const TAB_MASTODON_POSTS    =4;
export const TAB_NEWSRAVEN_POSTS=0;
export const TAB_MASTODONALL_POSTS=1;
export const TAB_BLUESKY_POSTS=2;

