import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Typography,
  InputBase,
  Button
} from "@material-ui/core";
import { Editor } from '@tinymce/tinymce-react';
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 0,
  },
  card_card: {
    backgroundColor: theme.palette.background.article,
    borderRadius: 0,
  },  
  header: {
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(0.5),
  },
  label: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
    color: theme.palette.text.primary,
  },
  replyto: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.primary,
  },
  content: {
    margin: 0,
    padding: 0,
  },
  commenttext: {
    flex: 1,
    color: theme.palette.text.primary,
    margin: 0,
    padding: 4,
    backgroundColor: theme.palette.background.dark,
    "& input": {
      fontSize: 14,
      fontWeight: 18,
      backgroundColor: theme.palette.background.dark,
    },
  },
  action: {
    padding: 0,
    margin: 0,
  },
  actionbtn: {
    padding: 4,
    color: theme.palette.background.default,
    marginRight: theme.spacing(1),
  },
  actionbtn_disabled: {
    padding: 4,
    color: theme.palette.background.default,
    marginRight: theme.spacing(1),
    opacity: 0.38,
  },
  actionimg: {
    padding: 0,
    width: 24,
    height: 24,
  },
  actionvalue: {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
    textTransform: "none",
    color: theme.palette.text.primary,
  },
});

class CommentUpdateBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.editorRef = React.createRef();
  }
  log = () => {
    if (this.editorRef.current) {
      console.log(this.editorRef.current.getContent());
    }
  };
  
  componentDidMount = () => {
    const { comment } = this.props;
    this.setState({
      ...this.state,
      comment: comment
    });
  }

  handleChange = (event) => {
    this.setState({
      comment: event,
    });
  };

  handleUpdate = () => {
    const { comment } = this.state;
    if (comment.trim().length === 0) {
      return;
    }

    this.props.onUpdate(this.state.comment);
    this.setState({
      ...this.state,
      comment:'',
    });
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  renderAction = (classes, modified, fn_cancel, fn_update) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Grid item>
          <Button
            className={classes.actionbtn}
            size="small"
            color="primary"
            onClick={fn_cancel}
            endIcon={
              <img
                alt={"Cancel"}
                src={`/static/images/delete.png`}
                className={classes.actionimg}
              />
            }
          >
            <Typography className={classes.actionvalue}>Cancel</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={modified ? classes.actionbtn : classes.actionbtn_disabled}
            size="small"
            color="primary"
            onClick={fn_update}
            endIcon={
              <img
                alt={"Update"}
                src={`/static/images/approve.png`}
                className={classes.actionimg}
              />
            }
          >
            <Typography className={classes.actionvalue}>Update</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, width, card_mode, replyto } = this.props;
    const { comment } = this.state; 
    const modified = comment !== this.props.comment;
    return (
      <div className={classes.root}>
        <div style={{width: width}} />
        <Card className={card_mode ? classes.card_card :classes.card}>
          { replyto !== undefined &&
            <CardHeader
              className={classes.header}
              title={
                <div>
                  <Typography className={classes.label} style={{display: 'inline-block'}}>Replying to:</Typography>&nbsp;
                  <Typography className={classes.replyto} style={{display: 'inline-block'}}>{replyto}</Typography>
                </div>
              }
            />
          }
          <CardContent className={classes.content}>
            {/* <InputBase
              id="comment-text"
              className={classes.commenttext}
              multiline
              fullWidth
              minRows="6"
              placeholder="Post a Comment"
              name="comment"
              value={comment}
              onChange={this.handleChange}
            /> */}
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              onInit={(evt, editor) => this.editorRef.current = editor}
              initialValue={this.props.comment}
              value={this.state.comment}
              placeholder="Post a Comment"
              onEditorChange={this.handleChange}
              init={{
                height: 300,
                menubar: false,
                branding: false,
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />
          </CardContent>
          <CardActions className={classes.action}>
            {this.renderAction(classes, modified, this.handleCancel, this.handleUpdate)}
          </CardActions>
        </Card>
      </div>
    );
  }
}

CommentUpdateBox.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  card_mode: PropTypes.func,
  comment: PropTypes.string,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func
};

export default withStyles(styles)(CommentUpdateBox);
