import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";

const styles = theme => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
    // height: 130,
    overflowX: "hidden",
    overflowY: "auto"
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.background.main,
    opacity: 0.5,
  },
  selected_chip: {
    margin: theme.spacing(0.5),
    color: "#FFF",
    backgroundColor: "#1878F3"
  }
});

class TagChips extends React.Component {

  render() {
    const { 
      classes, 
      tags, 
      selectedtags,
      onSelect
    } = this.props;

    if (tags.length === 0) {
      return (
        <></>
      );
    }

    return (
      <div className={classes.root}>
        {tags.map((tag) => {
          let selected_index = -1;
          if (selectedtags.length > 0) {
            selected_index = selectedtags.findIndex(item => item.id === tag.tag.id);
          }
          if (selected_index === -1) {
            return (
              <Chip
                className={classes.chip}
                clickable={false}
                key={tag.id}
                label={tag.tag.name}
                name={tag.tag.name}
                onClick={e => onSelect(tag.tag, true)}
              />
            );
          } else {
            return (
              <Chip
                className={classes.selected_chip}
                clickable={false}
                key={tag.id}
                label={tag.tag.name}
                name={tag.tag.name}
                onClick={e => onSelect(tag.tag, false)}
              />
            );
          }
        })}
      </div>
    );
  }
}

TagChips.propTypes = {
  classes: PropTypes.object,
  tags: PropTypes.array,
  selectedtags: PropTypes.array,
  onSelect: PropTypes.func
};

export default withStyles(styles)(TagChips);
