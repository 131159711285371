import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";

import {
    NewsCard,
    RedditCard,
    YoutubeCard,
    TwitterCard,
    InstagramCard,
    TwitchCard,
    MediumCard,
    RssCard,
    PodcastCard,
    HackernewsCard,
    SlashdotCard,
    VscoCard,
    PinterestCard,
    TiktokCard,
    SpotifyCard,
    QuoraCard,
    FacebookCard,
    SubstackCard,
    GmailCard,
    VimeoCard,
    WeblinkCard,
    MastodonCard,
    BlueskyCard,
    UserpostCard,
    VideocastCard,
    SummaryCard
  } from "components";
  import {
    ARTICLE_BRANCH_NEWSPAPER,
    ARTICLE_BRANCH_YOUTUBE,
    ARTICLE_BRANCH_TWITTER,
    ARTICLE_BRANCH_INSTAGRAM,
    ARTICLE_BRANCH_TWITCH,
    ARTICLE_BRANCH_MEDIUM,
    ARTICLE_BRANCH_REDDIT,
    ARTICLE_BRANCH_HACKERNEWS,
    ARTICLE_BRANCH_SLASHDOT,
    ARTICLE_BRANCH_PODCAST,
    ARTICLE_BRANCH_RSSFEED,
    ARTICLE_BRANCH_TIKTOK,
    ARTICLE_BRANCH_PINTEREST,
    ARTICLE_BRANCH_VSCO,
    ARTICLE_BRANCH_SPOTIFY,
    ARTICLE_BRANCH_QUORA,
    ARTICLE_BRANCH_FACEBOOK,
    ARTICLE_BRANCH_SUBSTACK,
    ARTICLE_BRANCH_GMAIL,
    ARTICLE_BRANCH_VIMEO,
    ARTICLE_BRANCH_WEBSITE,
    ARTICLE_BRANCH_MASTODON,
    ARTICLE_BRANCH_BLUESKY,
    ARTICLE_BRANCH_USERPOST,
    ARTICLE_BRANCH_YOUTUBE_SUMMARY,
    ARTICLE_BRANCH_PODCAST_SUMMARY
  } from "constants/branches";
const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  article: {
    margin: 2,
    backgroundColor: theme.palette.background.default
  }
});

class SimilarArticleDetails extends React.Component {
  render() {
    const { 
      classes, 
      article, 
      from
    } = this.props; 
    if (article.length === 0) {
      return <div style={{display: "none"}}></div>;
    }
    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    if(width>=1224) {
      width= 'auto';
    }

    let marginLeft =  width <= 1224 && width >= 599 ? '22%' : 0;
   
    
    return (
      <div className={classes.root} style={{width: width, marginLeft:marginLeft }}>
         <div>
         {article.type === 1 &&
          article.branch !== ARTICLE_BRANCH_YOUTUBE_SUMMARY &&
          article.branch !== ARTICLE_BRANCH_PODCAST_SUMMARY && (
          <SummaryCard
          article={article}
          // handleClick={handleClick}
          // onClickSource={onClickSource}
          // onClickFeed={onClickFeed}
          // onClickUpvote={this.handleClickUpvote}
          // onClickComment={this.handleClickComment}
          // onClickRepost={this.handleClickRepost}
          // onAnchorEl={this.handleAnchorEl}
          // onShare={this.handleShare}
        />
        )}
        {article.branch === ARTICLE_BRANCH_NEWSPAPER && (
          <NewsCard
            article={article}
            // search_mode={false}
            // handleClick={handleClick}
            // handleGroupId={handleGroupId}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_REDDIT && (
          <RedditCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_YOUTUBE && (
          <YoutubeCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_TWITTER && (
          <TwitterCard
            // article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_MEDIUM && (
          <MediumCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_RSSFEED && (
          <RssCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_PODCAST && (
          <PodcastCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_HACKERNEWS && (
          <HackernewsCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_SLASHDOT && (
          <SlashdotCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_TWITCH && (
          <TwitchCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_INSTAGRAM && (
          <InstagramCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_VSCO && (
          <VscoCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_PINTEREST && (
          <PinterestCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_TIKTOK && (
          <TiktokCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_SPOTIFY && (
          <SpotifyCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_QUORA && (
          <QuoraCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_FACEBOOK && (
          <FacebookCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_SUBSTACK && (
          <SubstackCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_MASTODON && (
          <MastodonCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_BLUESKY && (
          <BlueskyCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_GMAIL && (
          <GmailCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_VIMEO && (
          <VimeoCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_WEBSITE && (
          <WeblinkCard
            article={article}
          
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_USERPOST && !article.media_url && (
          <UserpostCard
            article={article}
            from={from}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}
        {article.branch === ARTICLE_BRANCH_USERPOST && article.media_url && (
          <VideocastCard
            article={article}
            // handleClick={handleClick}
            // onClickSource={onClickSource}
            // onClickFeed={onClickFeed}
            // onClickUpvote={this.handleClickUpvote}
            // onClickComment={this.handleClickComment}
            // onClickRepost={this.handleClickRepost}
            // onAnchorEl={this.handleAnchorEl}
            // onShare={this.handleShare}
          />
        )}

      </div>
      </div>
    );
  }
}

SimilarArticleDetails.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
  from: PropTypes.string,
  // width: PropTypes.number,
  // saved: PropTypes.bool,
  // aiSummary:PropTypes.array,
  // pins: PropTypes.array,
  // movetops: PropTypes.array,
  // onNeedMore: PropTypes.func,
  // onLogin: PropTypes.func,
  // onReport: PropTypes.func,
  // onEdit: PropTypes.func,
  // onSave: PropTypes.func,
  // onDeleteSaved: PropTypes.func,
  // onSelectArticle: PropTypes.func,
  // onSelectGroupArticle: PropTypes.func,
  // onClickSource: PropTypes.func,
  // onClickFeed: PropTypes.func,
  // onClickUpvote: PropTypes.func,
  // onClickComment: PropTypes.func,
  // onClickRepost: PropTypes.func,
};

const mapStateToProps = state => ({
  // loggedIn: state.sessionState.loggedIn,
  // authUser: state.sessionState.authUser,
  // scrollPos: state.uiState.scrollPos,
  // bottomNavbar: state.uiState.bottomNavbar,
  // topNavbar: state.uiState.topNavbar,
  // theme_mode: state.uiState.theme_mode
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(SimilarArticleDetails)));
