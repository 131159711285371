import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { compose } from "recompose";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Grid,
  Button,
  Menu,
} from "@material-ui/core";
import {
  PopMenuHomeComment,
  DlgLoginConfirm,
  DlgReport,
  DlgShareComment,
} from "components";
import { ARTICLE_BRANCH_NEWSPAPER } from "constants/branches";
import {
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
  THREAD_REPORT_COMMENTS,
  GRAPHQL_SUCCESS,
  BANNED_TYPE_1D,
  BANNED_TYPE_7D,
  BANNED_TYPE_PERM,
} from "constants/types";
import * as ROUTES from "constants/routes";
import { GraphqlService } from "services";
import { get_suspended_state } from "utility/user";
import { ToastError, ToastInfo } from "utility/toast";
import { logger } from "utility/logging";
import { is_comment_enabled_feed } from "utility/feeds";
import { get_elapsed_time } from "utility/utils";

const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
    padding:3
  },
  carddiv: {

    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    // borderRadius: 10,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 8,
    backgroundColor: theme.palette.feedbackground.default,
  },
  header: {
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  titleheader: {
    marginLeft: 40,
    marginTop: 5,
  },
  share: {
    position: "absolute",
    top: 12,
    right: 12,
    padding: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    backgroundColor: "#7289DA",
    zIndex: 100,
  },

  expand: {
    position: "absolute",
    top: 15,
    right: 10,
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  avatar: {
    position: "absolute",
    top: 10,
    left: 10,
    width: 40,
    height: 40,
    cursor:"text"
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  socialimg: {
    position: "absolute",
    top: 52,
    left: 14,
    width: 20,
    height: 20,
  },
  upvotebtn: {
    padding: 4,
    color: theme.palette.background.default,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionimg: {
    width: 16,
    height: 16,
  },
  actionbtn: {
    padding: 4,
    color: theme.palette.background.default,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionvalue: {
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "none",
    color: theme.palette.text.secondary,
  },
  timestamp: {
    position: "absolute",
    top: 2,
    right: 2,
    padding: 2,
    paddingTop: theme.spacing(1),
    fontSize: "10px",
    lineHeight: "12px",
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    zIndex: 100,
  },
  title: {
    position: "relative",
    marginTop: 8,
    marginLeft: 40,
    marginRight: 40,
  },
  authorname: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.primary,
  },
  authorbio: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
    color: theme.palette.text.primary,
    width:"275px"
  },
  feed: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontStyle: "Italic",
    lineHeight: "18px",
    marginTop: 4,
    marginLeft: 40,
    marginRight: 30,
    marginBottom: 4,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  // content: {
  //   padding: 4,
  //   margin: 8,
  //   marginLeft: 50,
  //   marginRight: 10,
  //   marginTop: 10,
  //   cursor: "pointer",
  //   "&:last-child": {
  //     paddingBottom: 0,
  //   },
  // },
  detail_txt: {
    display: "inline",
    overflowWrap: "break-word",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
  },
  media: {
    display: "block",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  actions: {
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  // repostbtn: {
  //   width: 20,
  //   height: 20,
  //   zIndex: 100,
  // },
  commentbtn: {
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20,
    zIndex: 100,
  },
  comment: {
    marginLeft: theme.spacing(0.5),
    fontSize: "14px",
    fontStyle: "Roboto",
  },
  commentDetails: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  content: {
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  text: {
    fontWeight: 400,
    display: "inline",
    overflowWrap: "break-word",
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.text.primary,
    marginTop: 10,
    cursor:"pointer"
  },
});


class FollowedCommentCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      loginDlg: false,
      reportDlg: false,
      shareDlg: false,
      recommends: 0,
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.handleClickReport = this.handleClickReport.bind(this);
    this.handleCloseReport = this.handleCloseReport.bind(this);
    this.handleReport = this.handleReport.bind(this);

    this.handleRemove = this.handleRemove.bind(this);
    this.handleBan1d = this.handleBan1d.bind(this);
    this.handleBan7d = this.handleBan7d.bind(this);
    this.handleBanPerm = this.handleBanPerm.bind(this);
    this.handleCloseComments = this.handleCloseComments.bind(this);
    this.handleDeleteComments = this.handleDeleteComments.bind(this);
    this.onClickComment = this.onClickComment.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);
    this.getShareInfo = this.getShareInfo.bind(this);
    this.handleShareComment = this.handleShareComment.bind(this);
    this.handleRecommend = this.handleRecommend.bind(this);

  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };
  componentDidMount = () => {
    const { thread } = this.props;
    this.setState({
      ...this.state,
      recommends: thread.recommends,
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.sortType !== this.props.sortType)
      {
      const { thread } = this.props;
      this.setState({
        ...this.state,
        recommends: thread.recommends,
      });
    }
  }

handleRecommend = async (event) => {
    
    if (!this.props.loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null
      });
      return;
    }

    const { thread, authUser } = this.props;
    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token);

    const recommender_result = await gqlservice.comment_recommender(thread.id, authUser.uid);
    if (recommender_result.status_code !== GRAPHQL_SUCCESS) {
      this.setError("Error getting comment recommender");
      return;
    }

    const recommended = recommender_result.data.comment_recommenders.length > 0;
    let comment_recommend_func = null;
    if (recommended) {
      comment_recommend_func = gqlservice.comment_dec_recommends;
    } else {
      comment_recommend_func = gqlservice.comment_inc_recommends;
    }

    this.setWaiting(true);

    gqlservice.set_auth_jwt(token, true);
    comment_recommend_func(thread.id, authUser.uid)
    .then(
      (result) => {
        let updated_recommends = result.data.update_comments.returning[0].recommends;
        this.setState({
          ...this.state,
          recommends: updated_recommends
        }, () => {
          this.forceUpdate();
        });
      },
      (reason) => {
        this.setError(reason.msg);
        this.setWaiting(false);
      }
    )
    .catch((err) => {
      this.setError(JSON.stringify(err));
      this.setWaiting(false);
    });
    
    this.setWaiting(false);
    
  };


  getShareInfo = (thread) => {
    // share info
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    const { feeds } = this.props;

    if (thread.feed_id) {
      const selected_feed = feeds.find((feed) => feed.id === thread.feed_id);
      if (thread.thread?.article) {
        const type =
          thread.thread?.article.source.branch === ARTICLE_BRANCH_NEWSPAPER
            ? ROUTES.ARTICLE_NEWS_PREFIX
            : ROUTES.ARTICLE_PREFIX;
        shareUrl =
          shareUrl +
          `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${thread.thread?.article.source.slug}/${type}/${thread.thread?.article.nid}`;
      } else {
        shareUrl =
          shareUrl +
          `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.thread?.id}`;
      }
    } else {
      if (thread.thread?.article) {
        const type =
          thread.thread?.article.source.branch === ARTICLE_BRANCH_NEWSPAPER
            ? ROUTES.ARTICLE_NEWS_PREFIX
            : ROUTES.ARTICLE_PREFIX;
        shareUrl = shareUrl + `/${type}/${thread.thread?.article.nid}`;
      } else {
        shareUrl = shareUrl + `/${ROUTES.THREAD_PREFIX}/${thread.thread?.id}`;
      }
    }

    // get share title
    let shareTitle = "";
    if (!thread.content) {
      shareTitle = "Raven thread";
    } else {
      shareTitle = thread.content;
    }

    const shareInfo = {
      title: "Raven thread: " + shareTitle,
      description: "",
      image: "",
      hashtag: "",
      url: shareUrl,
    };

    return shareInfo;
  };

  handleCloseShare() {
    this.setState({
      ...this.state,
      shareDlg: false,
    });
  }

  handleExpand = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };
  handleShareComment = () => {
    this.setState({
      ...this.state,
      shareDlg: true,
      anchorEl: null,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
      shareDlg: false,
    });
    this.props.onLogin();
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  _isBanned = () => {
    const { loggedIn, authUser } = this.props;
    const suspendedState = get_suspended_state(loggedIn, authUser);
    return (
      suspendedState.suspended1day ||
      suspendedState.suspended7day ||
      suspendedState.suspendedPermanent ||
      suspendedState.suspendedComments
    );
  };

  _getAuthToken = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    let token = authUser.token;
    if (Date.now() >= authUser.expiredTS) {
      const result = await this.props.firebase.refreshToken();
      if (result.error) {
        this.setError(result.msg);
        token = null;
      } else {
        token = result.token;
      }
    }
    return token;
  };

  removeThread = async (thread) => {
    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    const { authUser } = this.props;
    const currentTime = new Date().toISOString();
    await gqlservice
      .remove_thread_from_home(thread.id, true, authUser.uid, currentTime)
      .then(
        (result) => {
          this.props.removeThread(thread.id);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleRemove = async () => {
    this.handleCloseMenu();

    if (this._isBanned()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const { thread } = this.props;
    await this._removeThread(thread);
  };

  handleBan1d = async (thread, feed) => {
    this.handleCloseMenu();

    if (this._isBanned()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    await this._removeThread(thread);

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }

    let result = await gqlservice.feed_by_id(feed.id);
    if (result.status_code !== GRAPHQL_SUCCESS || !result.data?.feeds) {
      this.setError("Failed to get feed :", feed.name);
      return;
    }

    const thread_feed = result.data.feeds[0];

    const is_banned =
      thread_feed.banned_users.find(
        (banned) => banned.user_id ===thread.author?.uid
      ) !== undefined;
    let fnBan = is_banned
      ? gqlservice.update_banned_user
      : gqlservice.insert_banned_user;

    const now = Date.now();
    const banned_at = new Date(now).toISOString();
    const banned_till = new Date(now + 86400 * 1000).toISOString();

    const { authUser } = this.props;
    const banned = {
      user_id: thread.author?.uid,
      feed_id: thread_feed.id,
      banned_at: banned_at,
      banned_till: banned_till,
      type: BANNED_TYPE_1D,
      banned_by: authUser.uid,
    };

    gqlservice.set_auth_jwt(token, true);
    await fnBan(banned)
      .then(
        (result) => {
          const new_banned = is_banned
            ? result.data.update_users_banned.returning[0]
            : result.data.insert_users_banned.returning[0];
          this.props.insertBannedUser(new_banned);
          this.setAlert(`User ${thread.author?.username} banned for a day!`);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleBan7d = async (thread, feed) => {
    this.handleCloseMenu();

    if (this._isBanned()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    await this._removeThread(thread);

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }

    let result = await gqlservice.feed_by_id(feed.id);
    if (result.status_code !== GRAPHQL_SUCCESS || !result.data?.feeds) {
      this.setError("Failed to get feed :", feed.name);
      return;
    }

    const thread_feed = result.data.feeds[0];

    const is_banned =
      thread_feed.banned_users.find(
        (banned) => banned.user_id === thread.author?.uid
      ) !== undefined;
    let fnBan = is_banned
      ? gqlservice.update_banned_user
      : gqlservice.insert_banned_user;

    const now = Date.now();
    const banned_at = new Date(now).toISOString();
    const banned_till = new Date(now + 86400 * 7 * 1000).toISOString();

    const { authUser } = this.props;
    const banned = {
      user_id: thread.author?.uid,
      feed_id: thread_feed.id,
      banned_at: banned_at,
      banned_till: banned_till,
      type: BANNED_TYPE_7D,
      banned_by: authUser.uid,
    };

    gqlservice.set_auth_jwt(token, true);
    await fnBan(banned)
      .then(
        (result) => {
          const new_banned = is_banned
            ? result.data.update_users_banned.returning[0]
            : result.data.insert_users_banned.returning[0];
          this.props.insertBannedUser(new_banned);
          this.setAlert(`User ${thread.author?.username} banned for 7 days!`);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleBanPerm = async (thread, feed) => {
    this.handleCloseMenu();

    if (this._isBanned()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    await this._removeThread(thread);

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }

    let result = await gqlservice.feed_by_id(feed.id);
    if (result.status_code !== GRAPHQL_SUCCESS || !result.data?.feeds) {
      this.setError("Failed to get feed :", feed.name);
      return;
    }

    const thread_feed = result.data.feeds[0];

    const is_banned =
      thread_feed.banned_users.find(
        (banned) => banned.user_id === thread.author?.uid
      ) !== undefined;
    let fnBan = is_banned
      ? gqlservice.update_banned_user
      : gqlservice.insert_banned_user;

    const now = Date.now();
    const banned_at = new Date(now).toISOString();
    const banned_till = new Date(now + 86400 * 36500 * 1000).toISOString();

    const { authUser } = this.props;
    const banned = {
      user_id: thread.author?.uid,
      feed_id: thread_feed.id,
      banned_at: banned_at,
      banned_till: banned_till,
      type: BANNED_TYPE_PERM,
      banned_by: authUser.uid,
    };

    gqlservice.set_auth_jwt(token, true);
    await fnBan(banned)
      .then(
        (result) => {
          const new_banned = is_banned
            ? result.data.update_users_banned.returning[0]
            : result.data.insert_users_banned.returning[0];
          this.props.insertBannedUser(new_banned);
          this.setAlert(`User ${thread.author?.username} banned permanently!`);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleCloseComments = async () => {
    this.handleCloseMenu();

    if (this._isBanned()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    const { authUser, thread } = this.props;
    const currentTime = new Date().toISOString();
    await gqlservice
      .close_thread(thread.id, true, authUser.uid, currentTime)
      .then(
        (result) => {
          this.props.closeThread(thread.id);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleDeleteComments = async () => {
    this.handleCloseMenu();

    if (this._isBanned()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    if (!token) {
      this.props.onLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    const { authUser, thread } = this.props;
    const currentTime = new Date().toISOString();
    await gqlservice
      .close_thread(thread.id, true, authUser.uid, currentTime)
      .then(
        (result) => {
          this.props.closeThread(thread.id);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    await gqlservice
      .deleteall_comment(thread.id)
      .then((result) => {
        return gqlservice.thread_by_id(thread.id, null);
      })
      .then(
        (result) => {
          const new_thread = result.data.threads[0];
          this.props.updateThread(new_thread);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleClickReport = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }
    this.handleCloseMenu();
    if (this._isBanned()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    this.setState({
      ...this.state,
      reportDlg: true,
    });
  };

  handleCloseReport = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      reportDlg: false,
    });
  };

  handleReport = async (reportMsg) => {
    this.handleCloseMenu();

    const message = reportMsg.trim();
    if (!message) {
      this.setError("Report shouldn't be blank. Please input your report.");
      return;
    }

    const { authUser, thread } = this.props;
    const feed = this.getFeed();
    if (!feed) {
      this.setError("Can't find feed of thread");
      return;
    }

    this.setState({
      ...this.state,
      reportDlg: false,
    });

    let report = {
      thread_id: thread.id,
      report: message,
      reported_by: authUser.uid,
      type: THREAD_REPORT_COMMENTS,
    };

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }

    gqlservice.set_auth_jwt(token);
    await gqlservice
      .insert_thread_report(feed.id, report)
      .then(
        (result) => {
          ToastInfo("Reported the thread");
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getFeed = () => {
    const { thread, feeds } = this.props;
    let feed = null;

    // if the thread is from a feed
    if (thread.feed_id) {
      feed = feeds.find((item) => item.id === thread.feed_id);
      return feed;
    }

    // if the thread is from an article
    if (thread.thread?.article) {
      feed = this.getFeedofSource(thread.thread?.article.source.id);
      return feed;
    }

    // the thread is from location or readings
    return feed;
  };

  getFeedofSource = (source_id) => {
    const { loggedIn, followed_feeds, feeds } = this.props;
    let feed = null;
    if (loggedIn) {
      feed = followed_feeds.find(
        (item) =>
          is_comment_enabled_feed(item) &&
          item.feed_sources.find(
            (feed_source) => feed_source.source_id === source_id
          ) !== undefined
      );
      if (feed) {
        return feed;
      }
    }

    feed = feeds.find(
      (item) =>
        is_comment_enabled_feed(item) &&
        item.feed_sources.find(
          (feed_source) => feed_source.source_id === source_id
        ) !== undefined
    );
    return feed;
  };

  _getThreadUrl = () => {
    const { thread } = this.props;
    const feed = this.getFeed();
    if (feed) {
      if (thread.thread?.article) {
        const type =
          thread.thread?.article.source.branch === ARTICLE_BRANCH_NEWSPAPER
            ? ROUTES.ARTICLE_NEWS_PREFIX
            : ROUTES.ARTICLE_PREFIX;
        return `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${thread.thread?.article.source.slug}/${type}/${thread.thread?.article.nid}`;
      } else {
        return `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.thread?.id}`;
      }
    } else {
      return `/${ROUTES.THREAD_PREFIX}/${thread.thread?.id}`;
    }
  };
  onClickComment = (thread) => {
    this.props.onClickThread(thread);
  };
  onClickUpVote = (thread) => {
    this.props.onClickUpvoteThread(thread);
  };

  render() {
    const {
      classes,
      theme_mode,
      moderator,
      sources,
      thread,
    } = this.props;
    const { anchorEl, loginDlg, reportDlg, recommends, shareDlg } = this.state;
    // get source to use name, image, etc
    let source = null;
    if (thread.thread?.article) {
      source = sources.find((item) => item.id === thread.thread?.article.source.id);
      if (!source) {
        
        logger.error("Error, Unknown article source!", thread.thread?.article);
        return <div style={{display: "none"}}></div>;
      }
    }

    // find the feed contain this thread
    let feed = this.getFeed();
    if (!feed) {
      logger.error("Error, can't find the feed!", thread);
      return <div style={{display: "none"}}></div>;
    }
    // image
    // let thread_image = null;
    // if (thread.thread?.article) {
    //   thread_image = thread.thread?.article.image;
    //   const image_thumb = thread.thread?.article.image_thumb;
    //   if (image_thumb) {
    //     if (image_thumb["150"]) {
    //       thread_image = image_thumb["150"];
    //     } else if (image_thumb["240"]) {
    //       thread_image = image_thumb["240"];
    //     } else if (image_thumb["320"]) {
    //       thread_image = image_thumb["320"];
    //     } else if (image_thumb["org"]) {
    //       thread_image = image_thumb["org"];
    //     }
    //   }
    // }

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    width -= 16;
    if (width > MAX_CARD_WIDTH - 16) width = MAX_CARD_WIDTH - 16;
    if (width < MIN_CARD_WIDTH - 16) width = MIN_CARD_WIDTH - 16;

    // // get comments
    // const commentsCnt = thread?.threads?.comments_aggregate.aggregate.count;
    // const comment_string = `${commentsCnt} Comments`;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }
    const posted_at =  get_elapsed_time(thread.created_at);
    // share information
    let shareInfo = null;
    if (shareDlg) {
      let baseUrl = "";
      if (typeof window !== "undefined") {
        baseUrl = window.location.protocol + "//" + window.location.host;
      }
      let shareUrl = baseUrl + this._getThreadUrl();
      shareInfo = {
        title: "Raven Comment: " + thread.title,
        description: thread.text,
        image: "/static/images/icons/raven_logo.png",
        hashtag: "raven,comments",
        url: shareUrl,
      };
    }

    return (
      <div>
        <Card
          className={classes.card}
          style={{
            width: width,
            backgroundColor: classes.card.backgroundColor,
          }}
        >
          <div className={classes.carddiv}>
            <CardHeader
              className={classes.header}
              avatar={
                <div>
                  {thread.author !== null && (
                    <>
                      <Avatar
                        alt={thread.author?.username}
                        src={thread.author?.image || RAVEN_PLACEHOLDER_IMAGE}
                        className={classes.avatar}
                        classes={{ img: classes.avatar_img }}
                        onClick={() => this.onClickComment(thread?.thread)}
                      />
                    </>
                  )}
                  {!source && feed && (
                    <Avatar
                      alt={feed.name}
                      src={feed.thumbnail || feed.image || RAVEN_PLACEHOLDER_IMAGE}
                      className={classes.avatar}
                      classes={{ img: classes.avatar_img }}
                      onClick={() => this.onClickComment(thread?.thread)}
                    />
                  )}
                </div>
              }
              title={
                <div>
                  <Grid item>
                    <Grid
                      className={classes.titleheader}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography className={classes.authorname}>
                          {thread.author?.username}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.authorbio}>
                          {thread.author?.biography}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              }
            />
            <CardContent className={classes.content}  onClick={() => this.onClickComment(thread?.thread)}>

              <Typography className={classes.text}>
                {thread.text}
              </Typography>

            </CardContent>

            <CardActions className={classes.actions}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item
                >
                  <Button
                    className={classes.actionbtn}
                    size="small"
                    color="primary"
                    startIcon={
                      <img
                        alt={"Recommends"}
                        src={`/static/images/icons/${theme_mode}/like.png`}
                        className={classes.actionimg}
                      />
                    }
                  onClick={this.handleRecommend}
                  >
                    <Typography className={classes.actionvalue}>
                      Recommends&nbsp;&nbsp;{recommends}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.actionbtn}
                    size="small"
                    color="primary"
                    startIcon={
                      <img
                        alt={"Reply"}
                        src={`/static/images/icons/${theme_mode}/creply.png`}
                        className={classes.actionimg}
                      />
                    }
                    onClick={() => this.onClickComment(thread?.thread)}
                    >
                    <Typography className={classes.actionvalue}>Reply</Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            <div>
            <Typography className={classes.timestamp}>
                 {posted_at}
                </Typography>
            </div>
          </div>
        </Card>

        <Menu
          id="thread-menu"
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleCloseMenu}
        >
          <PopMenuHomeComment
            theme={theme_mode}
            moderator={moderator}
            onRemove={this.handleRemove}
            onBan1d={(e) => this.handleBan1d(thread, feed)}
            onBan7d={(e) => this.handleBan7d(thread, feed)}
            onBanPerm={(e) => this.handleBanPerm(thread, feed)}
            onCloseComments={this.handleCloseComments}
            onDeleteComments={this.handleDeleteComments}
            onReport={this.handleClickReport}
            onShare={this.handleShareComment}
          />
        </Menu>

        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgReport
          open={reportDlg}
          title={"Report Comment"}
          theme={theme_mode}
          onReport={this.handleReport}
          onCancel={this.handleCloseReport}
        />
        <DlgShareComment
          open={shareDlg}
          comment={thread}
          shareInfo={shareInfo}
          onClose={this.handleCloseShare}
        />
      </div>
    );
  }
}

FollowedCommentCard.propTypes = {
  classes: PropTypes.object,
  thread: PropTypes.object,
  moderator: PropTypes.bool,
  onLogin: PropTypes.func,
  onClickThread: PropTypes.func,
  onClickFeed: PropTypes.func,
  onClickSource: PropTypes.func,
  sortType: PropTypes.number,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  sources: state.dataState.sources,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(FollowedCommentCard);