import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { 
  List, 
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { 
  BasicAppBar, 
  WaitingSpinner 
} from "components";
import * as ROUTES from "constants/routes";
import { GraphqlService } from "services";
import { 
  GRAPHQL_SUCCESS, 
  MAX_ARTICLE_WIDTH 
} from "constants/types";
import { 
  get_branch
} from "constants/branches";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 1200,
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  logo: {
    minWidth: 60,
  },
  logoimage: {
    width: 60,
    height: 60,
  },
  titlebox: {
    marginLeft: theme.spacing(2),
  },
  title: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    textTransform: "none",
  },
  group: {
    padding: theme.spacing(2),
  },
  avatar: {
    minWidth: 20,
  },
  image: {
    height: 20,
    width: 20,
  },
  text: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.primary
  },
  name: {
    fontSize: "16px",
    color: theme.palette.text.primary
  },
});

class UptimeStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: []
    };

    this.handleNavBack = this.handleNavBack.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    const result = await gqlservice.get_uptimestats();
    if (result.status_code === GRAPHQL_SUCCESS) {
      logger.log("stats :", result);
      this.setState({
        ...this.state,
        stats: result.data.uptimestats
      });
    } else {
      this.setState({
        ...this.state,
        stats: [],
      });
    }

    this.setWaiting(false);
  };

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.ABOUT,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  renderStatItem = (classes, item) => {
    const { theme_mode } = this.props;

    const branch = get_branch(item.branch);

    let color = "green";
    if (item.stats >= 30 && item.stats < 80) {
      color = "orange";
    } else if (item.stats < 30) {
      color = "red";
    };

    return (
      <ListItem style={{paddingTop: "4px", paddingBottom: "4px"}} key={branch.name}>
        <ListItemAvatar className={classes.avatar}>
          <img
            className={classes.image}
            alt={branch.name}
            src={`/static/images/icons/${theme_mode}/${branch.image}`}
          />
        </ListItemAvatar>
        <ListItemText className={classes.text}>
          <Typography className={classes.name}>
            {item.trending ? `${branch.name} Trending` : branch.name}
          </Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          <Typography className={classes.name} style={{color: color}}>
            {item.trending ? (item.stats === 0 ? "Down" : "Up") : `${item.stats}%`}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  render() {
    const { classes, requesting } = this.props;
    const { stats } = this.state;

    const sources = 
      stats
        .filter(stat => !stat.trending)
        .sort((x,y) => x.branch - y.branch);

    const trendings = 
      stats
        .filter(stat => stat.trending)
        .sort((x,y) => x.branch - y.branch);


    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_ARTICLE_WIDTH}
            title={"Uptime Stats"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.container}>
          <List>
            <ListItem style={{paddingTop: "4px", paddingBottom: "4px"}}>
              <ListItemAvatar className={classes.logo}>
                <img
                  className={classes.logoimage}
                  alt="logo"
                  src="/static/images/icons/raven_logo.png"
                />
              </ListItemAvatar>
              <ListItemText className={classes.titlebox}>
                <Typography className={classes.title}>
                  Not every source will be available due to API or server issues
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          <div className={classes.group}>
            <List>
              {sources.map((item) => (
                this.renderStatItem(classes, item)
              ))}
            </List>
          </div>
          <div className={classes.group}>
            <List>
              {trendings.map((item) => (
                this.renderStatItem(classes, item)
              ))}
            </List>
          </div>
        </div>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UptimeStats);
