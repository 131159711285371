import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import { MAX_CARD_WIDTH, MIN_CARD_WIDTH } from "constants/types";

const styles = (theme) => ({
  modal:{
    border: "1px solid black",
    borderRadius: 0,
    minWidth: MIN_CARD_WIDTH - 140,
    maxWidth: MAX_CARD_WIDTH - 140,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    width:"100%"
  },
  actionbtns: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '-5px 0 10px'
  },
  content: {
    margin: "0 16px",
  },
  text: {
    fontSize: 23,
    lineHeight: "26px",
    fontWeight: 450
  },
  approve_img: {
    width: 30,
    height: 30,
  },
  approveButton: {
    padding: 4,
    color: theme.palette.background.default,
  },
  close_img: {
    marginLeft:'-5px',
    width: 30,
    height: 30,
  },
})

class DlgMembershipActions extends React.Component {
  constructor(props) {
    super(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleCancelMembership = this.handleCancelMembership.bind(this);
    this.handleRequestRefund = this.handleRequestRefund.bind(this);
  }

  handleCancelMembership = () => {
    const { showDialog } = this.props
    this.setState({ showDialog: true, actionType: "cancel" });
  };

  handleRequestRefund = () => {
    const { showDialog } = this.props
    this.setState({ showDialog: true, actionType: "refund" });
  };

  handleConfirm = () => {
    const { actionType } = this.props
    this.props.handleApprove(actionType);
  }

  handleDismiss = () => {
    this.props.onClose();
}


  render() {
    const { open, classes,onClose, actionType , credits} = this.props;

    return (
      <Dialog open={open} onClose={onClose} PaperProps={{className: classes.modal}}>
        <Card>
          <CardContent className={classes.content}>
            <Typography className={classes.text}>
              {actionType === "cancel" ? `Are you sure you want to cancel your membership?` : `You have  ${credits}  in credits. Are you sure you want a refund?`}
            </Typography>
          </CardContent>
          <div className={classes.actionbtns}>
            <IconButton
              onClick={this.handleConfirm}
              color="primary"
              variant="contained"
              className={classes.approveButton}
            >
              <img
                className={classes.approve_img}
                alt="copy link"
                src={`/static/images/icons/approve.png`}
              />
            </IconButton>
            <IconButton
              onClick={this.handleDismiss}
              color="primary"
              variant="contained"
              className={classes.closeButton}
            >
              <img
                className={classes.close_img}
                alt="copy link"
                src={`/static/images/dismiss.png`}
              />
            </IconButton>
          </div>
        </Card>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  theme_mode: state.uiState.theme_mode
});

DlgMembershipActions.propTypes = {
  open: PropTypes.bool,
  actionType: PropTypes.string,
  handleApprove: PropTypes.func,
  onClose: PropTypes.func,
  credits:PropTypes.string
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(DlgMembershipActions);
