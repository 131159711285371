import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

class PopMenuFeedMod extends React.Component {
  render() {
    const { 
      classes, 
      theme, 
      approved, 
      creator, 
      owner,
      showfirst, 
      onApproveFeed, 
      onEditFeed, 
      onCopyLink, 
      onDeleteFeed, 
      onResignFeed,
      onShowFirst
    } = this.props;

    return (
      <div>
        <MenuList className={classes.menu_list}>  
          {!approved && 
            <MenuItem className={classes.menu_item} onClick={onApproveFeed}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"approve"}
                  src={`/static/images/icons/${theme}/approve.png`}
                />
              </span>
              Approve Feed
            </MenuItem>
          }
          {approved &&
            <MenuItem className={classes.menu_item} onClick={onEditFeed}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"edit"}
                  src={`/static/images/icons/${theme}/edit.png`}
                />
              </span>
              Edit Feed
            </MenuItem>
          }
          <MenuItem className={classes.menu_item} onClick={onCopyLink}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"copylink"}
                src={`/static/images/icons/${theme}/link.png`}
              />
            </span>
            Copy Link to Feed
          </MenuItem>
          {(creator || owner) && (
          <MenuItem className={classes.menu_item} onClick={onDeleteFeed}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"delete"}
                src={`/static/images/icons/${theme}/warning.png`}
              />
            </span>
            Delete Feed
          </MenuItem>
          )}
          {!creator && (
            <MenuItem className={classes.menu_item} onClick={onResignFeed}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"resign"}
                  src={`/static/images/icons/${theme}/close.png`}
                />
              </span>
              Resign from Moderator
            </MenuItem>
          )}
        </MenuList>
        {!showfirst && 
          <MenuItem className={classes.menu_item} onClick={e => onShowFirst(true)}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"copylink"}
                src={`/static/images/icons/${theme}/notification.png`}
              />
            </span>
            Show First
          </MenuItem>
        }
        {showfirst &&
          <MenuItem className={classes.menu_item} onClick={e => onShowFirst(false)}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"copylink"}
                src={`/static/images/icons/${theme}/notification_disabled.png`}
              />
            </span>
            Don't Show First
          </MenuItem>
        }
      </div>
    );
  }
}

PopMenuFeedMod.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  approved: PropTypes.bool,
  creator: PropTypes.bool,
  owner: PropTypes.bool,
  showfirst: PropTypes.bool,
  onApproveFeed: PropTypes.func,
  onEditFeed: PropTypes.func,
  onCopyLink: PropTypes.func,
  onDeleteFeed: PropTypes.func,
  onResignFeed: PropTypes.func,
  onShowFirst: PropTypes.func
};

export default withStyles(styles)(PopMenuFeedMod);
