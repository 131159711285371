import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Badge,
  Typography,
} from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MAX_CARD_WIDTH, RAVEN_PLACEHOLDER_IMAGE } from "constants/types";

const styles = (theme) => ({
  root: {
    display: "-webkit-box",
    width: MAX_CARD_WIDTH,
    maxWidth: "100%",
    padding: 0,
    paddingRight: 60,
    marginTop: theme.spacing(1),
    margin: 0,
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "#232323"
    // }
  },
  badge_div: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
  },
  badge: {
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    backgroundColor: "red",
    color: "white",
  },
  avatar: {
    minWidth: 60,
  },
  image: {
    objectFit: "cover",
    height: 60,
    width: 60,
    borderRadius: 5,
  },
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: 68,
    color: theme.palette.text.primary,
  },
  name: {
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  feedtype_img: {
    marginLeft: theme.spacing(1),
    width: 16,
    height: 16,
  },
  description: {
    fontSize: "12px",
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
  action: {
    margin: 0,
    padding: 0,
  },
  following: {
    withth: 48,
    height: 48,
  },
});

class FeedItem extends React.Component {
  render() {
    const {
      classes,
      feed,
      ismyfeed,
      following,
      enabled,
      badged,
      theme_mode,
      onClicked,
      onFollowed,
    } = this.props;

    const following_img = `/static/images/icons/${theme_mode}/following.png`;
    const unfollowing_img = `/static/images/icons/${theme_mode}/unfollowing.png`;

    let feedtype_img = "";
    if (feed.type === 1) {
      feedtype_img = `/static/images/icons/${theme_mode}/key.png`;
    } else if (feed.type === 2) {
      feedtype_img = `/static/images/icons/${theme_mode}/moderator.png`;
    }

    const opacity = enabled ? 1.0 : 0.38;

    return (
      <ListItem
        className={classes.root}
        key={feed.id}
        style={{ opacity: opacity }}
      >
        <ListItemAvatar
          className={classes.avatar}
          onClick={(event) => (enabled ? onClicked(feed.id) : null)}
        >
          {badged ? (
            <div className={classes.badge_div}>
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={feed.notifications}
                overlap="rectangular"
              >
                <LazyLoadImage
                  className={classes.image}
                  alt={feed.name}
                  src={feed.thumbnail || feed.image || RAVEN_PLACEHOLDER_IMAGE}
                />
              </Badge>
            </div>
          ) : (
            <div>
              <LazyLoadImage
                className={classes.image}
                alt={feed.name}
                src={feed.thumbnail || feed.image || RAVEN_PLACEHOLDER_IMAGE}
              />
            </div>
          )}
        </ListItemAvatar>
        <ListItemText
          id={feed.id}
          className={classes.text}
          primary={
            <div className={"item"}>
              <Typography className={classes.name}>
                {feed.name}
                <img
                  className={classes.feedtype_img}
                  alt={""}
                  src={feedtype_img}
                />
              </Typography>
              <Typography className={classes.description}>
                {feed.description}
              </Typography>
            </div>
          }
        />
        {ismyfeed === false && (
          <ListItemSecondaryAction className={classes.action}>
            {following && (
              <img
                className={classes.following}
                alt={"following"}
                src={following_img}
                onClick={(event) =>
                  enabled ? onFollowed(feed.id, false) : null
                }
              />
            )}
            {!following && (
              <img
                className={classes.following}
                alt={"unfollowing"}
                src={unfollowing_img}
                onClick={(event) =>
                  enabled ? onFollowed(feed.id, true) : null
                }
              />
            )}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
}

FeedItem.propTypes = {
  classes: PropTypes.object,
  feed: PropTypes.object,
  ismyfeed: PropTypes.bool,
  following: PropTypes.bool,
  enabled: PropTypes.bool,
  theme_mode: PropTypes.string,
  onClicked: PropTypes.func,
  onFollowed: PropTypes.func,
};

export default withStyles(styles)(FeedItem);
