import gql from 'graphql-tag';

export const QUERY_USER_BY_ID = `
query user_by_id(
  $uid: String!
) {
  users(
    where: {
      uid: {_eq: $uid}
    }
  ) {
    uid
    name
    username
    biography
    image
    provider
    email
    emailVerified
    phone
    phoneVerified
    state
    country
    links
    role
    approved
    signMethod
    skipProfile
    msgToken
    cryptoAddress
    cryptoENS
    cryptoAmount
    credits
    current_plans
    paid
    paid_until
    subscribe_email
    project_scistarter
    project_librarykit
    is_mastodon_enabled
    is_bluesky_enabled
    total_report_per_day                  
    last_report_at 
    created_at
    is_mastodon_enabled
    mastodon_access_token
    bluesky_username
    mastodon_username
    bluesky_refresh_token
    bluesky_access_token
    categories_moderated {
      category_id
      approved
      owner
    }
    feeds_created {
      id
      name
      slug
      image
      category_id
    }
    feeds_followed {
      feed_id
      order
      feed {
        id
        name
        slug
        image
        thumbnail
        description
      }
    }
    feeds_moderated {
      feed_id
      approved
      owner
      feed {
        id
        name
        slug
        image
        thumbnail
      }
    }
    feed_sources_unfollowed {
      feed_id
      source_id
    }
    feed_source_showretweets {
      feed_id
      source_id
    }
    sources_created {
      id
    }
    sources_followed {
      source_id
    }
    tags {
      tag {
        id
        name
        tag_categories {
          category_id
        }
      }
    }
    banned {
      id
      user_id
      feed_id
      type
      banned_at
      banned_till
    }
    preapproved {
      id
      feed_id
    }
    users_followed {
      id
      user_id
      followed_at
      user {
        uid
        name
        username
        image
        biography
        approved
        msgToken
      }
    }
    user_followers {
      id
      follower_id
      followed_at
      follower {
        uid
        name
        username
        image
        biography
        approved
        msgToken
      }
    }
    users_invites {
      id
      invitee
      invited_to
      invited_at
      is_phone
      inviter {
        name
        username
        image
        biography
        approved
        msgToken
      }
    }
    user_suspensions (
      order_by: {id: desc}
    ){
      id
      user_id
      type
      from
      till
      suspended_by
      suspended_at
      enabled
    }
    suspensions (
      order_by: {id: desc}
    ){
      id
      user_id
      type
      from
      till
      suspended_by
      suspended_at
      enabled
    }
    user_blockmutes {
      id
      user_id
      type
      target_user_id
      created_at
      enabled
    }
    targetuser_blockmutes {
      id
      user_id
      type
      target_user_id
      created_at
      enabled
    }
    feeds_subscribed {
      feed_id
    }
    user_feed_showfirst {
      feed_id
    }
    regions_moderated {
      id
      region_id
      approved
    }
    locations_moderated {
      id
      location_id
      approved
    }
    locations_followed {
      location_id
    }
    location_banned {
      id
      user_id
      location_id
      banned_at
      user {
        uid
        username
      }
    }
    readings_aggregate {
      aggregate {
        count
      }
    }
    articles_voted {
      article_id
    }
    articles_reposted {
      article_id
    }
    threads_voted{
      thread_id 
      }
    sponsorships{
      id
      source_id
      user_id
      status
    }
  }
}`;

export const QUERY_USER_BY_USERNAME = `
query user_by_username(
  $username: String!
) {
  users(
    where: {
      username: {_eq: $username}
    }
  ) {
    uid
    name
    username
    biography
    image
    provider
    email
    emailVerified
    phone
    phoneVerified
    state
    country
    links
    role
    approved
    signMethod
    skipProfile
    msgToken
    cryptoAddress
    cryptoENS
    cryptoAmount
    paid
    paid_until
    credits
    current_plans
    subscribe_email
    project_scistarter
    project_librarykit
    is_mastodon_enabled
    is_bluesky_enabled
    created_at
    is_mastodon_enabled
    mastodon_access_token
    mastodon_username
    bluesky_username
    categories_moderated {
      category_id
      approved
      owner
    }
    feeds_created {
      id
      name
      slug
      image
      thumbnail
    }
    feeds_followed {
      feed_id
      order
      feed {
        id
        name
        slug
        image
        thumbnail
        description
      }
    }
    feeds_moderated {
      feed_id
      approved
      owner
      feed {
        id
        name
        slug
        image
        thumbnail
      }
    }
    feed_sources_unfollowed {
      feed_id
      source_id
    }
    feed_source_showretweets {
      feed_id
      source_id
    }
    sources_created {
      id
    }
    sources_followed {
      source_id
    }
    tags {
      tag {
        id
        name
        tag_categories {
          category_id
        }
      }
    }
    banned {
      id
      user_id
      feed_id
      type
      banned_at
      banned_till
    }
    preapproved {
      id
      feed_id
    }
    users_followed {
      id
      user_id
      followed_at
      user {
        name
        username
        image
        biography
        approved
        msgToken
      }
    }
    user_followers {
      id
      follower_id
      followed_at
      follower {
        name
        username
        image
        biography
        approved
        msgToken
      }
    }
    users_invites {
      id
      invitee
      invited_to
      invited_at
      is_phone
      inviter {
        name
        username
        image
        biography
        approved
        msgToken
      }
    }
    user_suspensions (
      order_by: {id: desc}
    ){
      id
      user_id
      type
      from
      till
      suspended_by
      suspended_at
      enabled
    }
    suspensions (
      order_by: {id: desc}
    ){
      id
      user_id
      type
      from
      till
      suspended_by
      suspended_at
      enabled
    }
    user_blockmutes {
      id
      user_id
      type
      target_user_id
      created_at
      enabled
    }
    targetuser_blockmutes {
      id
      user_id
      type
      target_user_id
      created_at
      enabled
    }
    feeds_subscribed {
      feed_id
    }
    user_feed_showfirst {
      feed_id
    }
    regions_moderated {
      id
      region_id
      approved
    }
    locations_moderated {
      id
      location_id
      approved
    }
    locations_followed {
      location_id
    }
    location_banned {
      id
      user_id
      location_id
      banned_at
      user {
        uid
        username
      }
    }
    readings_aggregate {
      aggregate {
        count
      }
    }
    articles_voted {
      article_id
    }
    articles_reposted {
      article_id
    }
  }
}`;

export const QUERY_USERS_FOR_LEADERBOARDS = `
query users_for_leaderboards {
  users (
    order_by: {readings_aggregate: {count: desc}}, 
    limit: 100
  ) {
    uid
    name
    username
    email
    biography
    image
    country
    readings_aggregate {
      aggregate {
        count
      }
    }
  }
}`;

export const QUERY_USERS_BY_SEARCHKEY = `
query user_by_searchkey(
  $searchkey: String!
) {
  users(
    where: {
      _or: [
        { name: {_ilike: $searchkey} }
        { username: {_ilike: $searchkey} }
        { biography: {_ilike: $searchkey} }
      ]
    }
  ) {
    uid
    name
    username
    biography
    image
    provider
    email
    emailVerified
    phone
    phoneVerified
    state
    country
    links
    role
    approved
    signMethod
    skipProfile
    msgToken
    cryptoAddress
    cryptoENS
    cryptoAmount
    paid
    paid_until
    credits
    current_plans
    subscribe_email
    project_scistarter
    project_librarykit
    is_mastodon_enabled
    is_bluesky_enabled
    mastodon_username
    bluesky_username
    created_at
  }
}`;



// User
export const MUTATION_INSERT_USER = `
mutation(
  $uid: String!,
  $name: String,
  $username: String,
  $biography: String,
  $image: String,
  $provider: String,
  $email: String,
  $emailVerified: Boolean!,
  $phone: String,
  $phoneVerified: Boolean!
  $state: String,
  $country: String,
  $links: jsonb,
  $role: Int!,
  $approved: Boolean!,
  $signMethod: Int!,
  $msgToken: String,
  $credits: float8!,
  $free_credits_expiry_date:timestamptz!
) {
  insert_users(
    objects: {
      uid: $uid, 
      name: $name, 
      username: $username,
      biography: $biography,
      image: $image,
      provider: $provider,
      email: $email,
      emailVerified: $emailVerified,
      phone: $phone,
      phoneVerified: $phoneVerified,
      state: $state,
      country: $country,
      links: $links,
      role: $role,
      approved: $approved,
      signMethod: $signMethod,
      msgToken: $msgToken
      credits : $credits
      free_credits_expiry_date:$free_credits_expiry_date
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
      credits
      free_credits_expiry_date
      feeds_followed {
        feed_id
        order
        feed {
          id
          name
          slug
          image
          thumbnail
          description
        }
      }
    }
  }
}`;

export const MUTATION_UPDATE_USER = `
mutation (
  $uid: String!,
  $name: String,
  $username: String,
  $biography: String,
  $image: String,
  $provider: String,
  $email: String,
  $emailVerified: Boolean,
  $phone: String,
  $phoneVerified: Boolean,
  $cryptoAddress: String,
  $cryptoENS: String,
  $cryptoAmount: Float,
  $state: String,
  $country: String,  
  $links: jsonb,
  $role: Int,
  $approved: Boolean,
  $skipProfile: Boolean,
  $enableMastodon: Boolean,
  $enableBluesky:  Boolean,
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      name: $name, 
      username: $username,
      biography: $biography,
      image: $image,
      provider: $provider,
      email: $email,
      emailVerified: $emailVerified,
      phone: $phone,
      phoneVerified: $phoneVerified,
      cryptoAddress: $cryptoAddress,
      cryptoENS: $cryptoENS,
      cryptoAmount: $cryptoAmount,
      state: $state,
      country: $country,
      links: $links,
      role: $role,
      approved: $approved,
      skipProfile: $skipProfile,
      is_mastodon_enabled: $enableMastodon,
      is_bluesky_enabled: $enableBluesky,
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
    }
  }
}`;

export const MUTATION_UPDATE_USER_PHONE = `
mutation (
  $uid: String!,
  $phone: String!
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      phone: $phone
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
    }
  }
}`;

export const MUTATION_UPDATE_USER_LINKS = `
mutation (
  $uid: String!,
  $links: jsonb!
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      links: $links
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      created_at
    }
  }
}`;

export const MUTATION_UPDATE_USER_MSGTOKEN = `
mutation (
  $uid: String!,
  $msgToken: String
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      msgToken: $msgToken
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
    }
  }
}`;

export const MUTATION_UPDATE_USER_SKIPPROFILE = `
mutation (
  $uid: String!,
  $skipProfile: Boolean!
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      skipProfile: $skipProfile
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
    }
  }
}`;

export const MUTATION_UPDATE_USER_SUBSCRIBE_EMAIL = `
mutation (
  $uid: String!,
  $subscribe_email: String!
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      subscribe_email: $subscribe_email
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
    }
  }
}`;

export const MUTATION_DELETE_USER_SUBSCRIBE_EMAIL = `
mutation (
  $uid: String!
) {
  update_users (
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      subscribe_email: null
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
    }
  }
}`;

export const MUTATION_UPDATE_USER_PROJECT_SCISTATER = `
mutation (
  $uid: String!,
  $project_scistarter: Boolean!
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      project_scistarter: $project_scistarter
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
    }
  }
}`;

export const MUTATION_UPDATE_USER_PROJECT_LIBRARYKIT = `
mutation (
  $uid: String!,
  $project_librarykit: Boolean!
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      project_librarykit: $project_librarykit
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
    }
  }
}`;


export const QUERY_TAG_USER_BY_REF = `
query tag_user_by_ref (
  $tag_id: Int!
  $user_id: String!
) {
  tag_user (
    where: {
      tag_id: {_eq: $tag_id},
      user_id: {_eq: $user_id}
    }
  ) {
    id
    tag_id
    user_id
  }
}`;

export const MUTATION_ADD_TAG_USER = `
mutation (
  $tag_id: Int!,
  $user_id: String!
) {
  insert_tag_user (
    objects: {
      tag_id: $tag_id,
      user_id: $user_id
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_DELETE_TAG_USER = `
mutation (
  $tag_id: Int!,
  $user_id: String!
) {
  delete_tag_user (
    where: {
      tag_id: {_eq: $tag_id},
      user_id: {_eq: $user_id}
    }
  ) {
    affected_rows
  }
}`;

export const SUBSCRIPTION_USER = gql`
subscription UserSubscription(
  $uid: String!
) {
  users(
    where: {
      uid: {_eq: $uid}
    }
  ) {
    uid
    name
    username
    biography
    image
    provider
    email
    emailVerified
    phone
    phoneVerified
    state
    country
    links
    role
    approved
    signMethod
    skipProfile
    msgToken
    cryptoAddress
    cryptoENS
    cryptoAmount
    paid
    paid_until
    subscribe_email
    project_scistarter
    project_librarykit
    is_mastodon_enabled
    is_bluesky_enabled
    mastodon_username
    bluesky_username
    created_at
    categories_moderated {
      category_id
      approved
      owner
    }
    feeds_created {
      id
    }
    feeds_followed {
      feed_id
      order
    }
    feeds_moderated {
      feed_id
      approved
      owner
    }
    feed_sources_unfollowed {
      feed_id
      source_id
    }
    feed_source_showretweets {
      feed_id
      source_id
    }
    sources_created {
      id
    }
    sources_followed {
      source_id
    }
    tags {
      tag {
        id
        name
        tag_categories {
          category_id
        }
      }
    }
    banned {
      id
      user_id
      feed_id
      type
      banned_at
      banned_till
    }
    preapproved {
      id
      feed_id
    }
    users_followed {
      id
      user_id
      followed_at
      user {
        name
        username
        image
        biography
        approved
        msgToken
      }
    }
    user_followers {
      id
      follower_id
      followed_at
      follower {
        name
        username
        image
        biography
        approved
        msgToken
      }
    }
    users_invites {
      id
      invitee
      invited_to
      invited_at
      is_phone
      inviter {
        name
        username
        image
        biography
        approved
        msgToken
      }
    }
    user_suspensions (
      order_by: {id: desc}
    ){
      id
      user_id
      type
      from
      till
      suspended_by
      suspended_at
      enabled
    }
    suspensions (
      order_by: {id: desc}
    ){
      id
      user_id
      type
      from
      till
      suspended_by
      suspended_at
      enabled
    }
    user_blockmutes {
      id
      user_id
      type
      target_user_id
      created_at
      enabled
    }
    targetuser_blockmutes {
      id
      user_id
      type
      target_user_id
      created_at
      enabled
    }
    feeds_subscribed {
      feed_id
    }
    user_feed_showfirst {
      feed_id
    }
    regions_moderated {
      id
      region_id
      approved
    }
    locations_moderated {
      id
      location_id
      approved
    }
    locations_followed {
      location_id
    }
    location_banned {
      id
      user_id
      location_id
      banned_at
      user {
        uid
        username
      }
    }
    readings_aggregate {
      aggregate {
        count
      }
    }
    articles_voted {
      article_id
    }
    articles_reposted {
      article_id
    }
  }
}`;

export const MUTATION_UPDATE_REPORT_LIMIT = `
mutation (
  $uid: String!,
  $lastReportAt: timestamptz!,
  $totalReportsPerDay: Int!
) {
  update_users(
    where: { uid: { _eq: $uid } },
    _set: { 
      last_report_at: $lastReportAt, 
      total_report_per_day: $totalReportsPerDay 
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_DELETE_USER = `
mutation ( 
  $user_id: String!
) {
  delete_users (
    where: { 
      uid: {_eq: $user_id}
    }
  ) {
    affected_rows
  }
}`;

export const QUERY_FOLLOWED_AIPERSONAS = `
query follwed_ai_personas(
  $user_id:String!
) {
  user_followers(
    where: {
      follower_id: {_eq: $user_id},
      user_id: {_similar:"bot_%"}
    }
  ) {
    user_id
  }
}`;

export const QUERY_THREAD_BY_AIUSER = `
query threads_by_user(
  $author_id: [String!], 
  $fromDate: timestamptz!, 
  $toDate: timestamptz!
) {
  threads(
    where: {
      comments: {
        author_id: {_in: $author_id},
        created_at: {_gte: $fromDate, _lte: $toDate}
      }
    }
  ) {
    id
    title
    text
    type
    poster {
      uid
      username
      image
    }
    feed_id
    article {
      nid
      image
      image_thumb
      source {
        id
        branch
        name
        image
        slug
      }
    }
    location_id
    reading {
      id
      location {
        id
        type
        name
        slug
      }
    }
    posted_at
    approved
    upvotes
    closed
    comments_aggregate {
      aggregate {
        count
      }
    }
  }
}`;
    
export const QUERY_FIRST_COMMENT_BY_AIUSER =`
query GetComments(
  $author_id:[String!],
  $fromDate:timestamptz!
) {
  comments(
    where: {
      author_id:{_in:$author_id},
      created_at: {_gte: $fromDate}
    }
  ) {
    approved
    approved_by
    article_id
    author_id
    feed_id
    id
    parent_id
    reading_id
    recommends
    status
    thread_id
    text
    approved_at
    created_at
    modified_at
    author{
      username
      image
      biography
      uid
    }
    thread{
      id
      type
      text
      poster{
        uid
        username
        image
        biography
      }
      feed_id
      article{
        nid
        image
        image_thumb
        source{
          id
          branch
          name
          image
          slug
        }
      }
      location_id
      reading{
        id
      }
      posted_at
      upvotes
      closed
      comments_aggregate{
        aggregate{
          count
        }
      }      
    }    
  }
}`;
    
export const QUERY_COMMENT_OF_AI_USERS = `
query GetComments(
  $author_id: [String!],
  $fromDate: timestamptz!,
  $toDate: timestamptz
) {
  comments(
    where: {
      author_id: {_in:$author_id},
      created_at: {_gte: $fromDate, _lte: $toDate}
    }
  ) {
    id
    feed_id
    article_id
    author_id
    parent_id
    reading_id
    approved
    approved_by
    recommends
    status
    thread_id
    text
    approved_at
    created_at
    modified_at
    author{
      username
      image
      biography
      uid
    }
    thread{
      id
      type
      text
      poster{
        uid
        username
        image
        biography
      }
      feed_id
      article{
        nid
        image
        image_thumb
        source{
          id
          branch
          name
          image
          slug
        }
      }
      location_id
      reading{
        id
      }
      posted_at
      upvotes
      closed
      comments_aggregate{
        aggregate{
          count
        }
      }
    }
  }
}`;

export const QUERY_FIRST_THREAD_BY_AIUSER = `
query first_threads_by_user(
  $author_id: [String!], 
  $fromDate: timestamptz!
) {
  threads(
    where: {
      comments: {
        author_id: {_in: $author_id},
        created_at: {_gte: $fromDate}
      }
    }
  ) {
    id
    title
    text
    type
    poster {
      uid
      username
      image
    }
    feed_id
    article {
      nid
      image
      image_thumb
      source {
        id
        branch
        name
        image
        slug
      }
    }
    location_id
    reading {
      id
      location {
        id
        type
        name
        slug
      }
    }
    posted_at
    approved
    upvotes
    closed
    comments_aggregate {
      aggregate {
        count
      }
    }
  }
}`;

export const QUERY_FOR_GETTING_PRICES =`
query getProductDetails {
  raven_membership_products(where:{status:{_eq:1}}, order_by:{payment_type:asc}){
    amount
    payment_type
    title
    description
    lookup_key
    price_id
    price_to_display
    currency
    credits
  }
}`;

export const QUERY_GET_PRICE_BY_PAYMENT_TYPE =`
query getProductDetailsById($paymentType:Int!) {
  raven_membership_products(
    where:{payment_type:{_eq:$paymentType}}){
    amount
    payment_type
    title
    description
    lookup_key
    price_to_display
    currency
    credits
  }
}`;

export const QUERY_SELFPOSTS_BY_USER = `
query getSelfpostsByUser(
  $uid: String!,
  $sourceId: String!,
  $fromDate: Int!,
  $toDate: Int!
) {
  articles(
    where: {
      source_id: { _eq: $sourceId },
      txt_param1: { _eq: $uid },
      published: { _gte: $fromDate, _lte: $toDate }
    },
    order_by: { published: desc }
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    type
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;

export const QUERY_FIRST_SELFPOSTS_BY_USER = `
query getFirstSelfpostsByUser(
  $uid: String!,
  $sourceId: String!,
  $fromDate: Int!,
) {
  articles(
    where: {
      source_id: { _eq: $sourceId },
      txt_param1: { _eq: $uid },
      published: { _gte: $fromDate }
    },
    order_by: { published: desc }
  ) {
    nid
    source_id
    title
    author
    summary
    image
    text
    html
    summarized_text
    tr_title
    tr_summary
    tr_text
    translated
    branch
    country
    crawled_at
    media_url
    url
    author_image
    published
    data
    extra_data
    param1
    param2
    param3
    param4
    param5
    txt_param1
    txt_param2
    image_thumb
    author_image_thumb
    link_preview
    discussion_twitter
    discussion_reddit
    upvotes
    type
    ai_summary
    ai_transcription
    political_sentiments
    summary_status
    threads {
      id
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;
export const MUTATION_UPDATE_USER_EMAIL = `
mutation (
  $uid: String!,
  $email: String!
) {
  update_users(
    where: {
      uid: {_eq: $uid}
    }, 
    _set: {
      email: $email
    }
  ) {
    affected_rows
    returning {
      uid
      name
      username
      biography
      image
      provider
      email
      emailVerified
      phone
      phoneVerified
      state
      country
      links
      role
      approved
      signMethod
      skipProfile
      msgToken
      cryptoAddress
      cryptoENS
      cryptoAmount
      paid
      paid_until
      subscribe_email
      project_scistarter
      project_librarykit
      is_mastodon_enabled
      is_bluesky_enabled
      mastodon_username
    bluesky_username
      created_at
    }
  }
}`;