import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { hasPaid } from "./user";
import { get_cached_item, set_cache_item } from "utility/cachemanager";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { logger } from "utility/logging";
import { ToastError } from "utility/toast";
import { GRAPHQL_ERROR } from "constants/types";
import { getInfo, upDateNodeCache } from "utility/ravenapi";  

const setError = (errMsg) => {
  logger.error(errMsg);
  ToastError(errMsg);
}

const getMainInfoOld = async (useCache=true) => {
  const state = store.getState();

  const { loggedIn, authUser } = state.sessionState;

  let base_data = null;
  let following_feeds = [];
  await store.dispatch({ type: ActionTypes.SET_REQUEST_GET_MAIN_INFO, state: true })

  // get base data from cache
  const cache_name = loggedIn
    ? hasPaid()
      ? "base_data_paiduser_v1"
      : "base_data_user_v1"
    : "base_data_anonymous_v1";
  if (useCache) {
    base_data = await get_cached_item(cache_name);
    if (base_data) {
      const default_feeds = base_data.feeds.filter(feed => feed.is_default);
      if (authUser.feeds_followed.length > 0) {
        following_feeds = 
          authUser
            .feeds_followed
            .sort((a, b) => a.order - b.order)
            .map(followed => base_data.feeds.find(feed => followed.feed_id === feed.id));
      }

      await Promise.all([
        store.dispatch({type: ActionTypes.SET_NEWSSITE_INFO, newssites: base_data.newssite}),
        store.dispatch({type: ActionTypes.SET_SOCIALTYPE_INFO, socialtypes: base_data.socialtype}),
        store.dispatch({type: ActionTypes.SET_CATEGORIES, categories: base_data.categories}),
        store.dispatch({type: ActionTypes.SET_LISTS, lists: base_data.lists ? base_data.lists : []}),
        store.dispatch({type: ActionTypes.SET_FEEDS, feeds: base_data.feeds}),
        store.dispatch({type: ActionTypes.SET_TAGS, tags: base_data.tags}),
        store.dispatch({type: ActionTypes.SET_SOURCES, sources: base_data.sources}),
        store.dispatch({type: ActionTypes.SET_DEFAULT_FEEDS, feeds: default_feeds}),
        store.dispatch({type: ActionTypes.SET_FOLLOWING_FEEDS, feeds: following_feeds}),
        store.dispatch({ type: ActionTypes.SET_REQUEST_GET_MAIN_INFO, state: false })
      ]);
      return;
    }
  }

  // get base data from the database if there isn't a cached
  const gqlservice = new GraphqlService();
  if (loggedIn) {
    const token = await getAuthToken();
    if (!token) {
      // this.handleLogin();
      setError(ERR_MSG_AUTH_TOKEN);
      return;
    }
    // logger.log("getAuthToken token :", token);
    gqlservice.set_auth_jwt(token);
  }

  let result = await gqlservice.base_data();
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  base_data = result.data;
  const default_feeds = base_data.feeds.slice();
  // get sources from default feeds
  base_data.sources = [];
  for (let feed of base_data.feeds) {
    if (feed.feed_sources.length > 0) {
      let sources = feed.feed_sources.map(item => item.source);
      for (let source of sources) {
        if (!base_data.sources.find(item => item.id === source.id)) {
          base_data.sources.push(source);
        }
      }
    }
  }

  // get feeds from authUser
  let feed_ids = [];
  if (loggedIn) {
    const created_feed_ids = authUser.feeds_created.map(feed => feed.id);
    const followed_feed_ids = authUser.feeds_followed.map(feed => feed.feed_id);
    const moderated_feed_ids = authUser.feeds_moderated.map(feed => feed.feed_id);
    const subscribed_feed_ids = authUser.feeds_subscribed.map(feed => feed.feed_id);

    feed_ids = feed_ids.concat(created_feed_ids, followed_feed_ids, moderated_feed_ids, subscribed_feed_ids);
    feed_ids = [...new Set(feed_ids)];
    logger.log("user feeds :", feed_ids);

    if (feed_ids.length > 0) {
      result = await gqlservice.get_feeds(feed_ids);
      if (result.status_code === GRAPHQL_ERROR) {
        setError(result.msg);
        return;
      }
  
      const feeds = result.data.feeds;
      for (let feed of feeds) {
        if (!base_data.feeds.find(item => item.id === feed.id)) {
          base_data.feeds.push(feed);
        }
      }      

      for (let feed of feeds) {
        if (feed.feed_sources.length > 0) {
          let sources = feed.feed_sources.map(item => item.source);
          for (let source of sources) {
            if (!base_data.sources.find(item => item.id === source.id)) {
              base_data.sources.push(source);
            }
          }
        }
      }
    }

    if (authUser.feeds_followed.length > 0) {
      following_feeds = 
        authUser
          .feeds_followed
          .sort((a, b) => a.order - b.order)
          .map(followed => base_data.feeds.find(feed => followed.feed_id === feed.id));
    }
  }

  await Promise.all([
    store.dispatch({type: ActionTypes.SET_NEWSSITE_INFO, newssites: base_data.newssite}),
    store.dispatch({type: ActionTypes.SET_SOCIALTYPE_INFO, socialtypes: base_data.socialtype}),
    store.dispatch({type: ActionTypes.SET_CATEGORIES, categories: base_data.categories}),
    store.dispatch({type: ActionTypes.SET_LISTS, lists: []}),
    store.dispatch({type: ActionTypes.SET_FEEDS, feeds: base_data.feeds}),
    store.dispatch({type: ActionTypes.SET_SOURCES, sources: base_data.sources}),
    store.dispatch({type: ActionTypes.SET_TAGS, tags: base_data.tags}),
    store.dispatch({type: ActionTypes.SET_DEFAULT_FEEDS, feeds: default_feeds}),
    store.dispatch({type: ActionTypes.SET_FOLLOWING_FEEDS, feeds: following_feeds}),
    store.dispatch({ type: ActionTypes.SET_REQUEST_GET_MAIN_INFO, state: false })
  ]);

  // store base_data to the cache
  set_cache_item(cache_name, base_data);
};

const updateCacheOld = async () => {
  const state = store.getState();

  const { loggedIn } = state.sessionState;

  // get base data from cache
  let cache_name = loggedIn
    ? hasPaid()
      ? "base_data_paiduser_v1"
      : "base_data_user_v1"
    : "base_data_anonymous_v1";

  const {
    newssites,
    socialtypes,
    categories,
    feeds,
    sources,
    tags,
  } = state.dataState;

  const base_data = {
    newssite: newssites,
    socialtype: socialtypes,
    categories: categories,
    feeds: feeds,
    sources: sources,
    tags: tags,
  };

  // store base_data to the cache
  await set_cache_item(cache_name, base_data);
};

const getMainInfo = async (useCache=true) => {
  const state = store.getState();

  const { loggedIn, authUser } = state.sessionState;
 
  await store.dispatch({ type: ActionTypes.SET_REQUEST_GET_MAIN_INFO, state: true })
  var params = JSON.stringify({ 
    user:authUser.uid?authUser.uid:null
   });  
  await getInfo(params)
    .then(
      async (result) => {
        if (result.status === 200) {
            let data = result.data  
            setBaseData(data)  
        } else {
          setError("Failed to get Info.");
          return;
        }
      },
      (reason) => {
        setError("Failed to get Infos.");
        return;
      }
    ) 
    .catch((err) => { 
      this.setError(err);
      return;
    }); 

 
};

const updateCache = async () => {
  const state = store.getState();
  const { authUser } = state.sessionState;
  var params = JSON.stringify({
    user: authUser.uid ? authUser.uid : null
  });  
  // store base_data to the cache
  await upDateNodeCache(params) 
};

const setBaseData = async (data) => { 
  const base_data =data.baseData;
  const default_feeds =data.default_feeds;
  let feed2show =data.feeds2show;
  const following_feeds =data.followedFeeds;
  //const aiComments =data.aiComments;
  //feed2show = feed2show.concat(data.default_feeds)
  await Promise.all([
    store.dispatch({type: ActionTypes.SET_NEWSSITE_INFO, newssites: base_data.newssite}),
    store.dispatch({type: ActionTypes.SET_SOCIALTYPE_INFO, socialtypes: base_data.socialtype}),
    store.dispatch({type: ActionTypes.SET_CATEGORIES, categories: base_data.categories}),
    store.dispatch({type: ActionTypes.SET_LISTS, lists: []}),
    store.dispatch({type: ActionTypes.SET_FEEDS, feeds: feed2show}),
    store.dispatch({type: ActionTypes.SET_SOURCES, sources: base_data.sources}),
    store.dispatch({type: ActionTypes.SET_TAGS, tags: base_data.tags}),
     store.dispatch({type: ActionTypes.SET_DEFAULT_FEEDS, feeds: default_feeds}),
    store.dispatch({type: ActionTypes.SET_FOLLOWING_FEEDS, feeds: following_feeds}),
    store.dispatch({ type: ActionTypes.SET_REQUEST_GET_MAIN_INFO, state: false }),
    //store.dispatch({type: ActionTypes.SET_FOLLOWED_COMMENTS, followedComments: aiComments}),

  ]);
}
export {
  setError,
  getMainInfo,
  updateCache,
  setBaseData
};
