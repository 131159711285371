import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from "@material-ui/core";
import { 
  PaymentRequestButtonElement, 
  useStripe, 
  useElements 
} from "@stripe/react-stripe-js";
import { stripeService } from "services";
import { logger } from "utility/logging";
import { setError } from "dataapis";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    position: "relative",
  },
  alerticon: {
    padding: 4,
    width: 32,
    height: 32,
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 18,
    fontWeight: 500,
  },
  cancelbutton: {
    padding: 0,
    margin: 0,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const DlgGooglePayment = (props) => {
  const {  open, paymentType, user, onSuccess, onError, onCancel ,onLogin} = props;
  const title = paymentType === 0 ? "Subscribe Raven Membership" : paymentType === 1 ? 'Raven Pay-as-you-go plan' : 'Raven Volume-Discount plan';


  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  
  const RAVEN_SUBSCRIPTION = 0;
  const PAY_AS_YOU_GO = 1;
  const VOLUME_DISCOUNT = 2;

  useEffect(() => {
    const { amount } = props
    if (!stripe || !elements) {
      return; 
    }

    async function doPayment() {
    console.log("amount from the parent :", amount)
      const pr =  stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Subscribe Raven Membership',
          amount:amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
      // Step 2: Create PaymentIntent over Stripe API
      try{
        pr.on('paymentmethod', async (e) => {
          console.log(" inisde the  'ON'" ,paymentType)

          // Payment intent or subscription api to be called based on the payment type

          if(paymentType === PAY_AS_YOU_GO || paymentType === VOLUME_DISCOUNT){
            const clientSecret = await stripeService.createPaymentIntent(paymentType, props.user);
            const { 
              error: stripeError,
              paymentIntent
            } = await stripe.confirmCardPayment(clientSecret, {
              payment_method: e.paymentMethod.id,
            }, { handleActions: false });

            if (stripeError) {
              e.complete('fail')
              props.onError(stripeError);
              return;
            }

            e.complete('success')

            // Sometimes payment requires action so if requireaction once again call the confirmCardPayment after authentication.
            if (paymentIntent.status === 'requires_action') {
              const {error, paymentIntent: updatedPaymentIntent} = await stripe.confirmCardPayment(clientSecret);
              console.log("error of required",error)
              if (error) {
                // Handle error here
                e.complete('fail')
                props.onError({message:error.message})
                return
              } else if (updatedPaymentIntent.status === 'succeeded') {
                props.onSuccess({...updatedPaymentIntent , paymentType});
                return;
              }
            }else if(paymentIntent.status === 'succeeded'){
              props.onSuccess({...paymentIntent , paymentType});
              return
            }
          }
          // Calling of subscription based on the paymentType
          if(paymentType === RAVEN_SUBSCRIPTION){
            console.log("subscription");            
            const data = {
              payment_method: e.paymentMethod.id,
              email: props.user.email,
              name: props.user.name,
              paymentType: paymentType
            }
            const res = await stripeService.createSubscriptionIntent(data, props.user);
            if(res.error){
              e.complete('fail')
              props.onError(res.error)
              return;
            } else {
              e.complete('success')
              const {client_secret, status} = res;
              console.log(" subscription api response ",status)
              if (status === 'requires_action' || status ==='succeeded' ) {
                try{
                  let payload = await stripe.confirmCardPayment(client_secret);
                  console.log("payload of subscription",payload)
                  if(payload.error){
                    props.onError(payload.error)
                    return
                  }
                  console.log("payload handling",payload)
                  if (payload.paymentIntent.status === 'succeeded') {
                    props.onSuccess({...payload.paymentIntent , paymentType});
                    return
                  }
                } catch(err) {
                  setError(JSON.stringify(err));
                  return err;
                }
              }
              else if( status==='requires_payment_method'){
                try{
                  let payload = await stripe.confirmCardPayment(client_secret,{
                    payment_method: e.paymentMethod.id,
                  });
                  console.log("payload of subscription",payload)
                  if(payload.error){
                    props.onError(payload.error)
                    return
                  }
                  console.log("payload handling",payload)
                  if (payload.paymentIntent.status === 'succeeded') {
                    props.onSuccess({...payload.paymentIntent , paymentType});
                    return
                  }
                } catch(err) {
                  setError(JSON.stringify(err));
                  return err;
                }
              }
            }
          }
        });
      }catch(err){
        console.log("error on something",err)
        return props.onError({message:err.message})
      }
    }

    doPayment();
    
  }, [stripe, elements, paymentType, props.user]);
  const classes = useStyles();

  // const title = "Subscribe Raven Membership";
  const description =
    "When you join Raven Membership, you can view more feeds and sources...";

  return (
    <div className={classes.root}>
      <Dialog open={props.open} PaperProps={{ style: { borderRadius: 10 } }}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <img
              className={classes.alerticon}
              alt="google payment"
              src={`/static/images/google-pay.png`}
            />
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <IconButton
              onClick={props.onCancel}
              className={classes.cancelbutton}
            >
              <img
                className={classes.actionimg}
                alt="cancel"
                src="/static/images/delete.png"
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Typography className={classes.description}>
            {description}
          </Typography>
          
          {paymentRequest && 
            <PaymentRequestButtonElement 
              options={{paymentRequest}} 
            />
          }
        </div>
      </Dialog>
    </div>
  );
}

DlgGooglePayment.propTypes = {
  open: PropTypes.bool,
  paymentType: PropTypes.number,
  user: PropTypes.object,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
  amount: PropTypes.number
};

export default DlgGooglePayment;