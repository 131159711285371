import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Badge } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  MAX_ARTICLE_WIDTH,
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { connect } from "react-redux";
const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  listitem: {
    display: "-webkit-box",
    padding: 0,
    margin: 0,
    cursor: "pointer",
  },
  badge_div: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
  },
  badge: {
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    backgroundColor: "red",
    color: "white",
  },
  listitem_avatar: {
    minWidth: 60,
    marginTop: 4,
  },
  listimage: {
    objectFit: "cover",
    height: 60,
    width: 60,
    borderRadius: 30,
    cursor: "pointer",
  },
  listitem_text: {
    maxWidth: `calc(100% - 160px)`,
    paddingLeft: theme.spacing(2),
    marginTop: 4,
    color: theme.palette.text.primary,
    cursor: "pointer",
    width: "auto",
    alignItems:'flex-start'
  },
  name: {
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: "12px",
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
  action: {
    width: "auto",
    margin: 0,
    marginTop: 0,
    padding: 0,
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  },
  following: {
    withth: 48,
    height: 48,
  },
});

class SourceItem extends React.Component {
  constructor(props) {
    super(props);
    
    this.listenToScroll = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
    window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }
  listenToScroll(event) {
    event.preventDefault();
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = (winScroll * 1.0) / height;

    if (scrolled === 1.0 || scrolled >= 0.99) {
      
      if (typeof this.props.onNeedMore === "function") {
        this.props.onNeedMore();
      }
    }
  }
  render() {
    const {
      classes,
      width,
      feed,
      index,
      following,
      loggedIn,
      badged,
      theme_mode,
      onClickFeed,
      onFollowed,
      unFollowed,
      source
    } = this.props;

    const following_img = `/static/images/icons/${theme_mode}/following.png`;
    const unfollowing_img = `/static/images/icons/${theme_mode}/unfollowing.png`;

    let rootWidth =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (rootWidth > 640) {
      rootWidth = 640 - 42;
    } else {
      rootWidth -= 32;
    }
    let textwidth = rootWidth - (16 + 60 + 32);


    return (
      <div
        className={classes.root}
        // style={{width: rootWidth }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          // alignItems="flex-start"
        >
          <Grid item className={classes.listitem_avatar}>
              <LazyLoadImage
                className={classes.listimage}
                alt={(source.name)}
                src={source.image|| RAVEN_PLACEHOLDER_IMAGE}
                onClick={onClickFeed}
              />
          </Grid>
          <Grid
            item
            className={classes.listitem_text}
            style={{width: textwidth}}
            onClick={(e) => onClickFeed(source)}
          >
            <div>
              <Typography className={classes.name}>{source.name}</Typography>
              <Typography className={classes.description}>
                {source.description||""}
              </Typography>
            </div>
          </Grid>
          <Grid item xs style={{ flexGrow: 1 }}></Grid>
          <Grid item className={classes.action}>
            {loggedIn && following && (
              <img
                className={classes.following}
                alt={"following"}
                src={following_img}
                onClick={(e) => onFollowed(source.source_id)}
              />
            )}
            {loggedIn && !following && (
              <img
                className={classes.following}
                alt={"unfollowing"}
                src={unfollowing_img}
                onClick={(e) => unFollowed(source.source_id)}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

SourceItem.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  feed: PropTypes.object,
  index: PropTypes.number,
  following: PropTypes.bool,
  loggedIn: PropTypes.bool,
  theme_mode: PropTypes.string,
  badged: PropTypes.bool,
  onClickFeed: PropTypes.func,
  onFollowed: PropTypes.func,
  unFollowed: PropTypes.func,
  onNeedMore: PropTypes.func,
};

const mapStateToProps = (state) => ({
  scrollPos: state.uiState.scrollPos,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SourceItem);
