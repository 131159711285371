import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  InputBase,
  Button
} from "@material-ui/core";
import { THEME_MODE_LIGHT } from "constants/types";


const styles = (theme) => ({
  caption: {
    fontSize: 16,
    fontWeight: 500,
  },
  closebutton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  title: {
    padding: 0,
    paddingBottom: theme.spacing(1),
  },
  content: {
    padding: 0,
  },
  text: {
    flex: 1,
    color: theme.palette.text.primary,
    margin: 0,
    padding: 4,
    backgroundColor: theme.palette.background.dark,
    "& input": {
      fontSize: 14,
      fontWeight: 18,
      backgroundColor: theme.palette.background.dark,
    },
  },
  applybtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    }
  },
  applybtn_disabled: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },  
});

class DlgComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = () => {
    const { content } = this.state;
    this.props.onSubmit(content);
    this.setState({
      ...this.state,
      content: ""
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      content: ""
    });
    this.props.onClose();
  };

  render() {
    const { classes, open, theme } = this.props;
    const { content } = this.state;

    const enabled = content.trim() ? true : false;

    const paper_style = {
      borderRadius: 10,
      backgroundColor: theme === THEME_MODE_LIGHT ? "white" : "black"
    };

    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: paper_style }}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Typography className={classes.caption} align="center">
            Post a Comment
          </Typography>
          <IconButton
            onClick={this.handleClose}
            className={classes.closebutton}
          >
            <img
              className={classes.actionimg}
              alt="apply"
              src="/static/images/delete.png"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className={classes.content}>
            <InputBase
              id="comment-content"
              className={classes.text}
              multiline
              fullWidth
              minRows="6"
              name="content"
              placeholder="content"
              value={content}
              onChange={this.handleChange}
            />
          </div>
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          {enabled &&
            <Button
              className={classes.applybtn}
              onClick={this.handleSubmit}
            >
              Post
            </Button>
          }
          {!enabled &&
            <Button
              className={classes.applybtn_disabled}
              style={{opacity: 0.38}}
            >
              Post
            </Button>
          }
        </DialogActions>
      </Dialog>
    );
  }
}

DlgComment.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  theme: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default withStyles(styles)(DlgComment);
