import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { 
  BasicAppBar,
  ArticleList,
  ArticleMasonry,
  ArticleModList,
  ArticleModMasonry,
  DlgLoginConfirm,
  DlgPostEdit,
  WaitingSpinner, 
} from "components";
import { 
  withAuthentication, 
  withAuthorization, 
} from "session";
import { 
  GraphqlService,
  withFirebase,
} from "services";
import * as ROUTES from "constants/routes";
import { 
  MAX_WINDOW_WIDTH,
  MIN_TABLET_WIDTH,
} from "constants/types";
import { 
  deleteArticle, 
  getAuthToken, 
  getMainInfo, 
  repostArticle, 
  saveArticle, 
  updatePost, 
  upvoteArticle 
} from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";
import { ARTICLE_BRANCH_NEWSPAPER } from "constants/branches";

const condition = (authUser) => !!authUser && authUser.uid !== ""

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 10,
  },
  articlecontainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.default,
  },
  bottomdiv: {
    paddingBottom: theme.spacing(2),
  },
});


class UserProfilePosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginDlg: false,
      postDlg: false,
      article_edit: null,
      postcounts: null,
      articleReposts: [],
      articlePosts: [],
      finalpostcount: null,
      repostCount: null
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.handlePinArticle = this.handlePinArticle.bind(this);
    this.handleMoveTopArticle = this.handleMoveTopArticle.bind(this);
    this.handleCommentArticle = this.handleCommentArticle.bind(this);
    this.handleAICommentArticle = this.handleAICommentArticle.bind(this);

    this.handleNeedMore = this.handleNeedMore.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.closePostEditDlg = this.closePostEditDlg.bind(this);
    this.handleUpdatePost = this.handleUpdatePost.bind(this);
  }

  setError = (errMsg) => {
    ToastError(errMsg);
    this.props.requestDataFinished();
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const { match, user_posts, selected_user } = this.props
    this.setWaiting(true)
    let username = match.params.username;
    await this.getUserInfo(username);
    this.setWaiting(false)
  }

  getUserInfo = async (username) => {
    const { loggedIn, sources,user_posts, selected_user,feeds } = this.props;

    const gqlservice = new GraphqlService();

    try {
      if (loggedIn) {
        const token = await getAuthToken();
        if (!token) {
          this.handleLogin();
          return;
        }
        gqlservice.set_auth_jwt(token);
      }

      // Get user
        const userResult = await gqlservice.user_by_username(username);
        const users = userResult.data.users;
        let selected_user = users[0];
        this.props.selectUser(selected_user);

        if (users.length === 0) {
          return
        }

      const sourceId = `${selected_user.uid}-selfpost`; 
      const counts = await gqlservice.article_voter_count_by_user(selected_user.uid, sourceId);
      const artcilecount = counts.data.articles_aggregate.aggregate?.count;
      const repostcount = counts.data.article_repost_aggregate.aggregate.count
      this.setState({
        ...this.state,
        repostCount: repostcount,
        postcounts: artcilecount+repostcount
      });
      if (this.state.postcounts === 0 || this.state.postcounts === user_posts.length) {
        return
      }
      await getMainInfo();
      // Get user's posts
      const result = await gqlservice.articles_to_profile(selected_user.uid, sourceId);
      let newPosts = result.data.articles;
      let oldestDate = null
      if (newPosts.length > 0) {
        this.setState((prevState) => {
          const updatedArticlePosts = [...prevState.articlePosts, ...newPosts];
          oldestDate = newPosts.reduce((oldest, post) => (post.published < oldest ? post.published : oldest), Infinity);
          return {
            articlePosts: updatedArticlePosts,
          };
        });
      }

      let myRepostsResult = [];
      if (newPosts.length < 5) {
        myRepostsResult = await gqlservice.article_reposts(selected_user.uid);
      } else {
        myRepostsResult = await gqlservice.article_reposts(selected_user.uid, oldestDate);
      }
      const myReposts = myRepostsResult.data.article_repost;
      if (myReposts.length > 0) {
        for (let i = 0; i < myReposts.length; i++) {
          newPosts.push(myReposts[i].article);
        }
        this.setState({
          ...this.state,
          articleReposts: myReposts
        });
      }

      //get unknown sources and feeds
      if (newPosts.length > 0) {
        let articlesWithoutSourceDetails = newPosts.filter(article => (
          article.source_id !== null && !sources.some(source => source.id === article.source_id)
        ));
        if (articlesWithoutSourceDetails.length > 0) {
          const getSourceDetails = async (article) => {
            try {
              const sourceDetailsResult = await gqlservice.source_and_feed_sources_by_id(article.source_id);
              var sourceDetails = sourceDetailsResult.data.sources[0];
              this.props.addSource(sourceDetails);
              const feedFromSource = sourceDetails.feed_sources[0]?.feed;
              if (feedFromSource) {
                this.props.addFeed(feedFromSource);
              }
            } catch (error) {
              console.error(`Error fetching source details for article ${article.nid}:`, error);
            }
          };
          await Promise.all(articlesWithoutSourceDetails.map(article => getSourceDetails(article)));
        }
      }
      const uniqueNids = new Set(user_posts.map(article => article.nid));
      const filteredNewPosts = newPosts.filter(article => !uniqueNids.has(article.nid));
      this.props.setUserPosts([...user_posts, ...filteredNewPosts]);
    } catch (error) {
      this.setError(error.message || JSON.stringify(error));
    } finally {
      this.setWaiting(false);
    }
  };

  handleNavBack = () => {
    // const location = {
    //   pathname: ROUTES.PROFILE,
    //   state: { animation: "right" },
    // };
    // this.props.history.push(location);
    this.props.history.goBack();
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  handlePinArticle = (article) => {}
  handleMoveTopArticle = (article) => {}
  handleCommentArticle = (article) => {}
  handleAICommentArticle = (article) => {}


  handleNeedMore = async () => {

    const { selected_user, user_posts, sources, feeds, requesting } = this.props;
    const { articlePosts, articleReposts, postcounts, repostCount, finalpostcount } = this.state;
    if (user_posts.length >= postcounts || finalpostcount === 0) {
      this.setWaiting(false)
      return
    }
    if (requesting) {
      return;
    }
    this.setWaiting(true);

    try {
      const gqlservice = new GraphqlService();

      let oldestDate = null
      if (articlePosts.length > 0) {
        oldestDate = articlePosts.reduce((oldest, post) => {
          return post.published < oldest ? post.published : oldest;
        }, Infinity);
      }

      let dateTo = null;
      if (articleReposts.length > 0) {
        dateTo = articleReposts.reduce((oldest, repost) => {
          return repost.created_at < oldest ? repost.created_at : oldest;
        });
      }

      const sourceId = `${selected_user.uid}-selfpost`
      const result = await gqlservice.articles_to_profile(selected_user.uid, sourceId, oldestDate);
      let newPosts = result.data.articles;
      if (newPosts.length > 0) {
        this.setState((prevState) => {
          const updatedArticlePosts = [...newPosts];      
          return {
            ...prevState,
            articlePosts: updatedArticlePosts,
          };
        });
      }
      
      if (repostCount > articleReposts.length) {
        let myRepostsResult = []
        if (newPosts.length < 5) {
          oldestDate = null;
          myRepostsResult = await gqlservice.article_reposts(selected_user.uid, oldestDate, dateTo);
        } else {
          myRepostsResult = await gqlservice.article_reposts(selected_user.uid, oldestDate, dateTo);
        }
        const myReposts = myRepostsResult.data.article_repost;
        for (let i = 0; i < myReposts.length; i++) {
          newPosts.push(myReposts[i].article);
        }
        this.setState({
          ...this.state,
          articleReposts: myReposts
        });
      }

      //unknown sources
      if (newPosts.length > 0) {
        let articlesWithoutSourceDetails = newPosts.filter(article => (
          article.source_id !== null && !sources.some(source => source.id === article.source_id)
        ));
        if (articlesWithoutSourceDetails.length > 0) {
          const getSourceDetails = async (article) => {
            try {
              const sourceDetailsResult = await gqlservice.source_and_feed_sources_by_id(article.source_id);
              var sourceDetails = sourceDetailsResult.data.sources[0];
              this.props.addSource(sourceDetails);
              const feedFromSource = sourceDetails.feed_sources[0]?.feed;
              if (feedFromSource && !feeds.some(feed => feed.id === feedFromSource.id)) {
                this.props.addFeed(feedFromSource);
              }
            } catch (error) {
              console.error(`Error fetching source details for article ${article.nid}:`, error);
            }
          };
          await Promise.all(articlesWithoutSourceDetails.map(article => getSourceDetails(article)));
        }
      }

      const uniqueNids = new Set(user_posts.map(article => article.nid));
      const filteredNewPosts = newPosts.filter(article => !uniqueNids.has(article.nid));
      if (filteredNewPosts.length === 0) {
        this.setState({
          ...this.state,
          finalpostcount: 0
        }, () => {
        });
      }
      // Update user_posts in the Redux state
      this.props.setUserPosts([...user_posts, ...filteredNewPosts]);
    } catch (error) {
      console.error("Error fetching more user articles:", error);
    } finally {
      this.setWaiting(false);
    }
  }

  handleSelectArticle = (article) => {
    this.props.selectArticle(article);

    let path = `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path = `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    }
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.PROFILE);
  }

  handleSelectGroupArticle = async (nid) => {}

  handleReportArticle = async (article, reportMsg) => {}

  handleEditArticle = (article) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn || authUser.uid !== article.txt_param1) {
      return;
    }
    this.setState({
      ...this.state,
      postDlg: true,
      article_edit: article
    });
  }

  handleDeleteArticle = async (article) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn || authUser.uid !== article.txt_param1) {
      return;
    }
    this.setWaiting(true);
    await deleteArticle(article);
    this.setWaiting(false);
  }

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  }

  handleDeleteSavedArticle = (article) => {}

  handleClickSource = (source, feed) => {
    // const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    // const location = {
    //   pathname: path,
    //   state: { animation: "left" },
    // };
    // this.props.history.push(location);
  }

  handleClickFeed = (feed) => {
    // let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // // Go to moderation page if the logged user is the moderator of the feed
    // if (isFeedModerator(feed)) {
    //   route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // }

    // this.props.selectFeed(feed);
    // this.props.selectFeedTab(TAB_FEED);
    // const location = {
    //   pathname: route,
    //   state: { animation: "left" },
    // };
    // this.props.history.push(location);

    // this.props.setFeedBackRoute(ROUTES.HOME);
    // this.props.refreshArticles();
    // this.props.clsArticlePins();
    // this.props.clsArticleMovetops();
    // this.props.refreshThreads();
    // this.props.initScrollPos();
    // this.props.selectCountry(ALL);
    // this.props.selectBranch(BRANCH_ALL);
  }

  handleClickUpvote = async (article) => {
    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  }

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  }

  handleClickRepost = async (article) => {
    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  }

  closePostEditDlg = () => {
    this.setState({
      ...this.state,
      postDlg: false,
      article_edit: null
    });
  };

  handleUpdatePost = async (description, postlink) => {
    this.setState({
      ...this.state,
      postDlg: false
    });

    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
        return;
    }

    const { article_edit } = this.state;

    this.setWaiting(true);
    await updatePost(description, postlink, article_edit);
    this.setWaiting(false);

    this.setState({
      ...this.state,
      article_edit: null
    });
  }

  render() {
    const {
      classes,
      theme_mode,
      selected_feed,
      my_posts,
      user_posts,
      posts_backroute,
      requesting,
    } = this.props;
    const {
      loginDlg,
      postDlg,
      article_edit,
    } = this.state;

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;

    // layout variables
    const isDesktop = width > MAX_WINDOW_WIDTH;
    const isTablet =
      width >= MIN_TABLET_WIDTH &&
      width <= MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;
    const innerWidth =
      width > MAX_WINDOW_WIDTH
        ? MAX_WINDOW_WIDTH
        : width;

    // card width = 414 - 16, grid space
    const masonryWidth = Math.floor(innerWidth / 402) * 402 + 16;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_WINDOW_WIDTH}
            title={"Posts"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.articlecontainer}>
          {(isDesktop || isTablet) && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                {user_posts.length > 0 && (
                  <ArticleMasonry
                    userProfile={true}
                    width={masonryWidth}
                    articles={user_posts}
                    pins={[]}
                    movetops={[]}
                    onNeedMore={this.handleNeedMore}
                    onSelectArticle={this.handleSelectArticle}
                    onSelectGroupArticle={this.handleSelectGroupArticle}
                    onNeedLogin={this.handleLogin}
                    onReport={this.handleReportArticle}
                    onEdit={this.handleEditArticle}
                    onDelete={this.handleDeleteArticle}
                    onSave={this.handleSaveArticle}
                    onDeleteSaved={this.handleDeleteSavedArticle}
                    onClickSource={this.handleClickSource}
                    onClickFeed={this.handleClickFeed}
                    onClickUpvote={this.handleClickUpvote}
                    onClickComment={this.handleClickComment}
                    onClickRepost={this.handleClickRepost}
                  />
                )}
              </Grid>
            </Grid>
          )}
          {isMobile && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                {user_posts.length > 0 && (
                  <ArticleList
                    userProfile={true}
                    articles={user_posts}
                    pins={[]}
                    movetops={[]}
                    onNeedMore={this.handleNeedMore}
                    onSelectArticle={this.handleSelectArticle}
                    onSelectGroupArticle={this.handleSelectGroupArticle}
                    onLogin={this.handleLogin}
                    onReport={this.handleReportArticle}
                    onEdit={this.handleEditArticle}
                    onDelete={this.handleDeleteArticle}
                    onSave={this.handleSaveArticle}
                    onDeleteSaved={this.handleDeleteSavedArticle}
                    onClickSource={this.handleClickSource}
                    onClickFeed={this.handleClickFeed}
                    onClickUpvote={this.handleClickUpvote}
                    onClickComment={this.handleClickComment}
                    onClickRepost={this.handleClickRepost}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </div>

        <div className={classes.bottomdiv}></div>

        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        {postDlg &&
          <DlgPostEdit
            open={postDlg}
            theme={theme_mode}
            article={article_edit}
            onSubmit={this.handleUpdatePost}
            onClose={this.closePostEditDlg}
          />
        }
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  selected_user: state.dataState.selected_user,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  my_posts: state.dataState.my_posts,
  user_posts: state.dataState.user_posts,
  posts_backroute: state.uiState.posts_backroute,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UserProfilePosts);
