export const QUERY_BLOCKMUTES_BY_USER = `
query blockmutes_by_user (
  $user_id: String!
) {
  user_blockmute (
    where: {
      user_id: {_eq: $user_id}
    },
    order_by: {id: desc}
  ) {
    id
    user_id
    type
    target_user_id
    created_at
    enabled
  }
}`;

export const MUTATION_INSERT_USER_BLOCKMUTE = `
mutation insert_user_blockmute (
  $user_id: String!,
  $type: Int!,
  $target_user_id: String,
  $enabled: Boolean!,
  $created_at: timestamptz
) {
  insert_user_blockmute_one (
    object: {
      user_id: $user_id,
      type: $type,
      target_user_id: $target_user_id,
      enabled: $enabled,
      created_at: $created_at
    },
    on_conflict: {
      constraint: user_blockmute_pkey,
      update_columns: [
        created_at,
        enabled
      ]
    }
  ) {
    id
    user_id
    type
    target_user_id
    created_at
    enabled
  }
}`;
