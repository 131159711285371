import gql from 'graphql-tag';


// Source
export const QUERY_SOURCES = `
query sources @cached(ttl:120){ 
  sources (
    where: {category_id: {_neq: "deleted"}}
  ) {
    id
    category_id
    name
    description
    slug
    image
    thumbnail
    branch
    weblink
    translate
    translateLang
    translateAPI
    followers
    upvotes
    throttle
    disableFullText
    articles_no
    created_at
    created_by
    approved
    private
    last_updated
    updated_at
    transcription_enabled
    is_bias_check
    total_bias_score
    total_biased_articles_no
    is_published
    socialtags {
      tag
      type
    }
  }
}
`;

export const QUERY_SOURCE_BY_ID = `
query source_by_id (
  $id: String!
) {
  sources(
    where: { id: {_eq: $id} }
  ) {
    id
    category_id
    name
    description
    slug
    image
    thumbnail
    branch
    weblink
    translate
    translateLang
    translateAPI
    followers
    upvotes
    throttle
    disableFullText
    articles_no
    created_at
    created_by
    approved
    private
    last_updated
    updated_at
    transcription_enabled
    is_bias_check
    total_bias_score
    total_biased_articles_no
    is_published
    socialtags {
      tag
      type
    }
  }
}`;

export const QUERY_SOURCE_AND_FEED_SOURCES_BY_ID = `
query source_by_id (
  $id: String!
) {
  sources(
    where: { id: {_eq: $id} }
  ) {
    id
    category_id
    name
    description
    slug
    image
    thumbnail
    branch
    weblink
    translate
    translateLang
    translateAPI
    followers
    upvotes
    throttle
    disableFullText
    articles_no
    created_at
    created_by
    approved
    private
    last_updated
    updated_at
    transcription_enabled
    is_bias_check
    total_bias_score
    total_biased_articles_no
    is_published
    feed_sources {
      id
      feed_id
      source_id
      created_at
      create_user {
        uid
        username
      }
      feed {
        id
    category_id
    name
    description
    slug
    image
    thumbnail
    tags
    followers
    created_at
    created_by
    approved
    last_viewlog
    private
    ai_moderation
    op_comments
    op_posts
    op_members
    op_payment
    op_anonymity
    comment_conf
    ai_comment_conf
    ai_summary_conf
    tg_wallet
    tg_address
    tg_amount
    token_address
    token_amount
    channel_instagram
    channel_tiktok
    channel_twitter
    channel_youtube
    article_count
    notifications
    notif_date
    updated_at
    feed_sources {
      id
      source_id
      keyword_filter
      approved
      created_at
      create_user {
        uid
        username
      }
      source {
        id
        category_id
        name
        description
        slug
        image
        thumbnail
        branch
        weblink
        translate
        translateLang
        translateAPI
        followers
        upvotes
        throttle
        disableFullText
        articles_no
        created_at
        created_by
        approved
        private
        last_updated
        updated_at
        transcription_enabled
        total_bias_score
        total_biased_articles_no
        is_bias_check
        is_published
        socialtags {
          tag
          type
        }
      }
    }
    feed_reports {
      id
      report
      approved
      reported_at
    }
    feed_moderators {
      id
      feed_id
      user_id
      created
      owner
      approved
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_followers {
      id
      user_id
      feed_id
      created
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_videocasts_aggregate {
      aggregate {
        count
      }
    }
    ai_persons{
      id
      user_id
      seed_phrase
      is_owner
      user{
        name
        biography
        image
        username
        }
    }
      }
    }
    socialtags {
      tag
      type
    }
  }
}`;

export const QUERY_SOURCE_BY_SLUG = `
query source_by_id (
  $slug: String!
) {
  sources(
    where: { slug: {_eq: $slug} }
  ) {
    id
    category_id
    name
    description
    slug
    image
    thumbnail
    branch
    weblink
    translate
    translateLang
    translateAPI
    followers
    upvotes
    throttle
    disableFullText
    articles_no
    created_at
    created_by
    approved
    private
    last_updated
    updated_at
    transcription_enabled
    is_bias_check
    total_bias_score
    total_biased_articles_no
    is_published
    socialtags {
      tag
      type
    }
  }
}`;

export const QUERY_EXIST_SOURCE_BY_SLUG = `
query source_by_id (
  $id: String!,
  $slug: String!
) {
  sources(
    where: { 
      id: {_neq: $id},
      slug: {_eq: $slug} 
    }
  ) {
    id
    category_id
    name
    description
    slug
  }
}`;

export const QUERY_EXIST_SOURCE_BY_ID = `
query source_exist_by_id (
  $id: String!
) {
  sources_aggregate(
    where: { 
      id: {_eq: $id} 
    }
  ) {
    aggregate {
      count
    }
  }
}`;

export const MUTATION_INSERT_SOURCE = `
mutation(
  $id: String!,
  $category_id: String!,
  $name: String!,
  $description: String,
  $slug: String!,
  $image: String!,
  $thumbnail: String!,
  $branch: Int!,
  $weblink: String,
  $translateLang: String!,
  $translateAPI: String,
  $translate: Boolean!,
  $frequency: Int!,
  $disableFullText: Boolean!,
  $followers: Int!,
  $created_by: String!,
  $created_at: Int!,
  $approved: Boolean!
) {
  insert_sources(
    objects: {
      id: $id, 
      category_id: $category_id,
      name: $name, 
      description: $description,
      slug: $slug,
      image: $image,
      thumbnail: $thumbnail,
      branch: $branch,
      weblink: $weblink,
      translateLang: $translateLang,
      translateAPI: $translateAPI,
      translate: $translate,
      frequency: $frequency,  
      disableFullText: $disableFullText,
      followers: $followers,
      created_by: $created_by,
      created_at: $created_at,
      approved: $approved,
      updated_at: $created_at
    }
  ) {
    affected_rows
    returning {
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      is_published
      socialtags {
        tag
        type
      }
    }
  }
}`;

export const MUTATION_UPDATE_SOURCE = `
mutation (
  $id: String!,
  $category_id: String!,
  $name: String!,
  $description: String,
  $slug: String!,
  $image: String!,
  $thumbnail: String!,
  $branch: Int!,
  $weblink: String,
  $translateLang: String!,
  $translateAPI: String,
  $translate: Boolean!,
  $disableFullText: Boolean!,
  $followers: Int!,
  $created_by: String!,
  $created_at: Int!,
  $approved: Boolean!,
  $updated_at: Int
) {
  update_sources(
    where: {
      id: {_eq: $id}
    }, 
    _set: {
      category_id: $category_id,
      name: $name, 
      description: $description,
      slug: $slug,
      image: $image,
      thumbnail: $thumbnail,
      branch: $branch,
      weblink: $weblink,
      translateLang: $translateLang,
      translateAPI: $translateAPI,
      translate: $translate,
      disableFullText: $disableFullText,
      followers: $followers,
      created_by: $created_by,
      created_at: $created_at,
      approved: $approved,
      updated_at: $updated_at
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_DELETE_SOURCE = `
mutation (
  $id: String!
) {
  delete_sources(
    where: {
      id: {_eq: $id}
    } 
  ) {
    affected_rows
  }
}`;

// export const MUTATION_DELETE_SOURCE = `
// mutation (
//   $id: String!
// ) {
//   update_sources(
//     where: { id: {_eq: $id} },
//     _set: { category_id: "deleted" }  
//   ) {
//     affected_rows
//   }
// }`;

export const MUTATION_UPDATE_SOURCE_FOLLOWERS = `
mutation update_source_followers(
  $id: String!,
  $followers: Int!
) {
  update_sources(
    where: {
      id: {_eq: $id}
    }, 
    _set: {
      followers: $followers
    }
  ) {
    affected_rows
  }
}`;

export const QUERY_SOURCE_VOTER = `
query source_voter(
  $source_id: String!,
  $user_id: String!
) {
  source_voter(
    where: {
      source_id: {_eq: $source_id},
      user_id: {_eq: $user_id} 
    }
  ) {
    id
  }
}`;

export const MUTATION_SOURCE_INC_UPVOTES = `
mutation source_inc_upvotes(
  $source_id: String!,
  $user_id: String!,
) {
  update_sources(
    where: {
      id: {_eq: $source_id}
    },
    _inc: {upvotes: 1}
  ) {
    affected_rows
    returning {
      upvotes
    }
  }

  insert_source_voter(
    objects: {
      source_id: $source_id, 
      user_id: $user_id
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_SOURCE_DEC_UPVOTES = `
mutation source_dec_upvotes(
  $source_id: String!,
  $user_id: String!,
) {
  update_sources(
    where: {
      id: {_eq: $source_id}
    },
    _inc: {upvotes: -1}
  ) {
    affected_rows
    returning {
      upvotes
    }
  }
  
  delete_source_voter(
    where: {
      source_id: {_eq: $source_id}, 
      user_id: {_eq: $user_id}
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_SOURCE_THROTTLE = `
mutation update_source_throttle(
  $id: String!,
  $throttle: Int!
) {
  update_sources(
    where: {
      id: {_eq: $id}
    }, 
    _set: {
      throttle: $throttle
    }
  ) {
    affected_rows
    returning {
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      upvotes
      throttle
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      socialtags {
        tag
        type
      }
    }
  }
}`;


export const MUTATION_UPDATE_SOURCE_LASTUPDATED = `
mutation update_source_hasarticle(
  $id: String!,
  $last_updated: timestamptz!
) {
  update_sources(
    where: {
      id: {_eq: $id}
    }, 
    _set: {
      last_updated: $last_updated
    }
  ) {
    affected_rows
    returning {
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      upvotes
      throttle
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      socialtags {
        tag
        type
      }
    }
  }
}`;

export const MUTATION_UPDATE_SOURCE_TRANSLATE = `
mutation update_source_translate(
  $id: String!,
  $translate: Boolean!
) {
  update_sources(
    where: {
      id: {_eq: $id}
    }, 
    _set: {
      translate: $translate
    }
  ) {
    affected_rows
    returning {
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      upvotes
      throttle
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      socialtags {
        tag
        type
      }
    }
  }
}`;

export const SUBSCRIPTION_SOURCES = gql`
subscription SourceSubscription {
  sources (
    where: {category_id: {_neq: "deleted"}}
  ){
    id
    category_id
    name
    description
    slug
    image
    thumbnail
    branch
    weblink
    translate
    translateLang
    translateAPI
    followers
    upvotes
    throttle
    disableFullText
    articles_no
    created_at
    created_by
    approved
    private
    last_updated
    updated_at
    transcription_enabled
    is_bias_check
    total_bias_score
    total_biased_articles_no
    is_published
    socialtags {
      tag
      type
    }
  }
}
`;
export const MUTATION_UPDATE_ENABLE_TRANSCRIPTION = `
mutation (
  $source_id: String, 
  $transcription_enabled: Boolean!
) {
  update_sources(
    where: {
      id: {_eq: $source_id}
    }, 
    _set: {
      transcription_enabled: $transcription_enabled
    }
  ) {
    affected_rows
    returning{
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      socialtags {
        tag
        type
      }
    }
  }
}`;

export const MUTATION_UPDATE_BIAS_CHECK_ENABLE = `
mutation (
  $source_id: String,
  $is_bias_check: Boolean!
) {
  update_sources(
    where: {
      id: { _eq: $source_id }
    },
    _set: {
      is_bias_check: $is_bias_check
    }
  ) {
    affected_rows
    returning {
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      socialtags {
        tag
        type
      }
    }
  }
}`;

export const QUERY_SOURCE_REPORTS_COUNT =`
query (
  $time: timestamptz!,
  $id:String!
) {
  source_reports_aggregate(
    where: {
      _and: [
        { reported_by: {_eq:$id} }
        { reported_at: {_gt: $time} }
      ]
    }
  ) {
    aggregate {
      count
    }
  }
}`;

export const MUTATION_INSERT_AI_QUERY_TRANSCRIPTION =`
mutation ($query:String!,$response:String!,$created_at:timestamp,$article_id:String!,$user_id:String!){
  insert_ai_query_transcriptions(objects:{
    query:$query,
    response:$response,
    created_at:$created_at,
    article_id:$article_id,
    user_id:$user_id
  }){
    affected_rows
    returning{
      query
      id
    }
  }
}`;

export const GET_HISTORY_FOR_QUERY_TARNSCRIPTION =`
query getHistoryForTranscription($article_id:String!,$user_id:String!){
  ai_query_transcriptions(where:{article_id:{_eq:$article_id},user_id:{_neq:$user_id}}
  order_by:{created_at:desc}
    limit:3
  ){
  query
}
}`;

export const MUTATION_INSERT_SEARCH_TERM_SOURCE = `
mutation(
  $id: String!,
  $category_id: String!,
  $name: String!,
  $description: String,
  $slug: String!,
  $image: String!,
  $thumbnail: String!,
  $branch: Int!,
  $weblink: String,
  $translateLang: String!,
  $translateAPI: String,
  $translate: Boolean!,
  $frequency: Int!,
  $disableFullText: Boolean!,
  $followers: Int!,
  $created_by: String!,
  $created_at: Int!,
  $approved: Boolean!,
  $is_published: Boolean!,
) {
  insert_sources(
    objects: {
      id: $id, 
      category_id: $category_id,
      name: $name, 
      description: $description,
      slug: $slug,
      image: $image,
      thumbnail: $thumbnail,
      branch: $branch,
      weblink: $weblink,
      translateLang: $translateLang,
      translateAPI: $translateAPI,
      translate: $translate,
      frequency: $frequency,  
      disableFullText: $disableFullText,
      followers: $followers,
      created_by: $created_by,
      created_at: $created_at,
      approved: $approved,
      updated_at: $created_at,
      is_published: $is_published
    }
  ) {
    affected_rows
    returning {
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      is_published
      socialtags {
        tag
        type
      }
    }
  }
}`;

export const MUTATION_UPDATE_SOURCE_IMAGE = `
mutation (
  $source_id: String, 
  $image: String, 
) {
  update_sources(
    where: {
      id: {_eq: $source_id}
    }, 
    _set: {
      image: $image
    }
  ) {
    affected_rows
    returning{
      id
      category_id
      name
      description
      slug
      image
      thumbnail
      branch
      weblink
      translate
      translateLang
      translateAPI
      followers
      upvotes
      throttle
      disableFullText
      articles_no
      created_at
      created_by
      approved
      private
      last_updated
      updated_at
      transcription_enabled
      is_bias_check
      total_bias_score
      total_biased_articles_no
      is_published
      socialtags {
        tag
        type
      }
    }
  }
}`;

//sponsorship
export const MUTATION_INSERT_SPONSORSHIP = `
mutation insert_sponsorship(
  $source_id: String!,
  $user_id: String!,
) {
  insert_sponsorships(
    objects: {
      source_id: $source_id,
      user_id: $user_id,
    }
  ) {
    affected_rows
    returning {
      id
      source_id
      user_id
    }
  }
}`;

export const QUERY_SPONSORSHIPS_BY_ID = `
query sponsorships_by_id (
  $id: String!
) {
  sponsorships(
    where: { source_id: {_eq: $id} }
  ) {
    id
    source_id
    user_id
    created_at
    status
    user {
      uid
      image
      credits
    }
  }
}`;

export const MUTATION_UPDATE_SPONSORSHIP_STATUS = `
mutation (
  $id: Int!
  $status: Int!
) {
  update_sponsorships(
    where: {
      id: {_eq: $id}
    }
    _set: {
      status: $status
    }
  ) {
    affected_rows
  }
}`;

