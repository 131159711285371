// Log Actions
export const ACTIVITY_ADD             = 0;
export const ACTIVITY_CHANGE          = 1;
export const ACTIVITY_DELETE          = 2;
export const ACTIVITY_REMOVE          = 3;
export const ACTIVITY_APPROVE         = 4;
export const ACTIVITY_REJECT          = 5;
export const ACTIVITY_MAKE            = 6;
export const ACTIVITY_APPLY           = 7;
export const ACTIVITY_REQUEST         = 8;
export const ACTIVITY_REPORT          = 9;
export const ACTIVITY_PIN             = 10;
export const ACTIVITY_MOVETOP         = 11;
export const ACTIVITY_COPY            = 12;
export const ACTIVITY_BLOCK           = 13;
export const ACTIVITY_UNBLOCK         = 14;
export const ACTIVITY_MUTE            = 15;
export const ACTIVITY_UNMUTE          = 16;
export const ACTIVITY_SUSPEND_1D      = 17;
export const ACTIVITY_UNSUSPEND_1D    = 18;
export const ACTIVITY_SUSPEND_7D      = 19;
export const ACTIVITY_UNSUSPEND_7D    = 20;
export const ACTIVITY_SUSPEND_PERM    = 21;
export const ACTIVITY_UNSUSPEND_PERM  = 22;
export const ACTIVITY_SUSPEND_POSTS   = 23;
export const ACTIVITY_UNSUSPEND_POSTS = 24;
export const ACTIVITY_SUSPEND_COMMENTS= 25;
export const ACTIVITY_UNSUSPEND_COMMENTS= 26;
export const ACTIVITY_SUSPEND_IMAGES  = 27;
export const ACTIVITY_UNSUSPEND_IMAGES  = 28;
export const ACTIVITY_SUSPEND_VIDEO   = 29;
export const ACTIVITY_UNSUSPEND_VIDEO   = 30;
export const ACTIVITY_SUSPEND_FEEDCREATE = 31;
export const ACTIVITY_UNSUSPEND_FEEDCREATE = 32;

export const ACTIVITY_TYPE_SYSTEM     = 0;
export const ACTIVITY_TYPE_CATEGORY   = 1;
export const ACTIVITY_TYPE_FEED       = 2;
export const ACTIVITY_TYPE_SOURCE     = 3;
export const ACTIVITY_TYPE_USER       = 4;
export const ACTIVITY_TYPE_ONBOARDING = 5;
export const ACTIVITY_TYPE_CLEANAIRMAP= 6;

export const conf_activity = [
  { type: ACTIVITY_ADD, message: "added" },
  { type: ACTIVITY_CHANGE, message: "changed" },
  { type: ACTIVITY_DELETE, message: "deleted" },
  { type: ACTIVITY_REMOVE, message: "removed" },
  { type: ACTIVITY_APPROVE, message: "approved" },
  { type: ACTIVITY_REJECT, message: "rejected" },
  { type: ACTIVITY_MAKE, message: "made" },
  { type: ACTIVITY_APPLY, message: "applied" },
  { type: ACTIVITY_REQUEST, message: "requested" },
  { type: ACTIVITY_REPORT, message: "reported" },
  { type: ACTIVITY_PIN, message: "pinned" },
  { type: ACTIVITY_MOVETOP, message: "moved to top" },
  { type: ACTIVITY_COPY, message: "copied" }
];