import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { setError } from "./base";
import { 
  FEED_COMMENT_UNMODERATED,
  GRAPHQL_ERROR, 
  THREAD_TYPE_FEED 
} from "constants/types";
import { 
  ACTIVITY_TYPE_FEED, 
  ACTIVITY_ADD,
  ACTIVITY_DELETE
} from "constants/activity";
import { logger } from "utility/logging";

const getThreadOfArticle = async (article_id) => {
  const state = store.getState();
  const { selected_feed } = state.dataState;
  let approved = true;
  if (
    selected_feed &&
    selected_feed.comment_conf === FEED_COMMENT_UNMODERATED
  ) {
    approved = null;
  }

  const gqlservice = new GraphqlService();
  const result = await gqlservice.thread_by_article(article_id, approved);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  logger.log("thread of article :", result.data?.threads);
  if (result.data.threads.length > 0) {
    store.dispatch({
      type: ActionTypes.SELECT_THREAD,
      thread: result.data.threads[0],
    });
  }
}

const getTranscriptionQueryHistory = async (article_id) => {
  const state = store.getState();
  const { authUser } = state.sessionState  ;
  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    return;
  }

  gqlservice.set_auth_jwt(token);
  const result = await gqlservice.get_history_for_query_transcription(article_id, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  logger.log("query history :", result.data?.ai_query_transcriptions);
  if (result.data.ai_query_transcriptions.length > 0) {
    return result.data.ai_query_transcriptions
  }else{
    return []
  }
}

const addThread = async (title, content, feed, approved) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  let thread = {
    title: title,
    text: content,
    type: THREAD_TYPE_FEED,
    posted_by: authUser.uid,
    feed_id: feed.id,
    approved: approved,
    approved_by: approved ? authUser.uid : null,
    approved_at: approved ? new Date().toISOString() : null,
  };

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  gqlservice.set_auth_jwt(token);
  let result = await gqlservice.insert_thread(thread);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const new_thread = result.data.insert_threads.returning[0];
  store.dispatch({ 
    type: ActionTypes.ADD_THREAD,
    thread: new_thread 
  });

  // log this activity
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_FEED,
    type_id: feed.id,
    action: ACTIVITY_ADD,
    object: `the comment ${thread.title}`,
    fromto: `to the feed ${feed.name}`,
    reason: "",
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
};

const deleteThread = async (thread) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  gqlservice.set_auth_jwt(token, true);

  let result = await gqlservice.delete_thread(thread.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  store.dispatch({
    type: ActionTypes.DELETE_THREAD,
    thread_id: thread.id,
  });

  const { selected_feed } = state.dataState;
  if (!selected_feed) {
    return;
  }
  gqlservice.set_auth_jwt(token, false);
  
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_FEED,
    type_id: selected_feed.id,
    action: ACTIVITY_DELETE,
    object: `the thread ${thread.title}`,
    fromto: `from the feed ${selected_feed.name}`,
    reason: ''
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
};

const reportThread = async (thread, report_msg, report_type) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  gqlservice.set_auth_jwt(token);

  const { selected_feed } = state.dataState;
  const report = {
    thread_id: thread.id,
    type: report_type,
    report: report_msg,
    reported_by: authUser.uid
  };

  let result = await gqlservice.insert_thread_report(selected_feed.id, report);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

const upvoteThread = async (thread) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.thread_voter(thread.id, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const voter = result.data.thread_voter.length > 0;
  let thread_upvote_func = null;
  if (voter) {
    thread_upvote_func = gqlservice.thread_dec_upvotes;
  } else {
    thread_upvote_func = gqlservice.thread_inc_upvotes;
  }

  result = await thread_upvote_func(thread.id, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  let updated_votes = result.data.update_threads.returning[0].upvotes;
  store.dispatch({
    type: ActionTypes.UPDATE_THREAD_UPVOTES,
    thread_id: thread.id,
    upvotes: updated_votes,
  });
}

const closeThread = async (thread) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token, true);

  const currentTime = new Date().toISOString();
  let result = await gqlservice.close_thread(thread.id, true, authUser.uid, currentTime)
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  store.dispatch({
    type: ActionTypes.CLOSE_THREAD,
    thread_id: thread.id
  });
}

const deleteAllComments = async (thread) => {
  const state = store.getState();
  const { loggedIn } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.deleteall_comment(thread.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const { selected_feed } = state.dataState;
  let approved = true;
  if (selected_feed && selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
    approved = null;
  }
  result = await gqlservice.thread_by_id(thread.id, approved);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const new_thread = result.data.threads[0];
  store.dispatch({
    type: ActionTypes.UPDATE_THREAD,
    thread: new_thread,
  });
}

export { 
  getThreadOfArticle,
  addThread,
  deleteThread,
  reportThread,
  upvoteThread,
  closeThread,
  deleteAllComments,
  getTranscriptionQueryHistory
};
