import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { compose } from "recompose";
import { withRouter } from "react-router"; 
import { withStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/core";
import {
  DlgSharePost,
  DlgReport,
  DlgLoginConfirm,
  PopMenuPost,
  SearchResultCard,
} from "components";
import {
  ARTICLE_BRANCH_NEWSPAPER,
  ARTICLE_BRANCH_USERPOST,
  get_branch_name,
} from "constants/branches";
import { 
  ACTIVITY_TYPE_FEED, 
  ACTIVITY_DELETE 
} from "constants/activity";
import {
  FLAGGED_COMMENTS_POSTS
} from "constants/types";
import * as ROUTES from "constants/routes";
import { 
  getAuthToken,
  isBannedPosts,
  updateFeedNotifications
} from "dataapis";
import { 
  ToastSuccess, 
  ToastError 
} from "utility/toast";
import { isFlaggedModerationByAi } from "utility/ravenapi";
import { logger } from "utility/logging";
import {GraphqlService, Mixpanel} from "services"; 
import * as MIXPANEL_EVENTS from "constants/mixpanel"; 
import moment from "moment/moment";
import { handleReportWithLimit } from "utility/reportlimit";
import { updateReportLimit } from "dataapis/user";
import { copy2clipboard } from "utility/utils";
import { isTypeNode } from "graphql";
import MastodonPosts from "components/MastodonPosts/MastodonPosts";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
});

class SearchArticle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      reportDlg: false,
      loginDlg: false,
      shareDlg: false,
      // sourceDetails: null, 
    };

    this.handleShare = this.handleShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);
    this.handleAnchorEl = this.handleAnchorEl.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);

    this.showReportDlg = this.showReportDlg.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleCancelReport = this.handleCancelReport.bind(this);
    this.handleDeleteReport = this.handleDeleteReport.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDeleteSaved = this.handleDeleteSaved.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this); 
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleShare = () => {   
    const { article } = this.props;
    const properties = {
      type: 'Article',
      info: {
        nid: article.nid,
        title: article.tr_title !== '' ? article.tr_title : article.title,
        source_id: article.source_id
      }
    } 
    Mixpanel.track(MIXPANEL_EVENTS.SHARE, properties); 

    this.setState({
      ...this.state,
      shareDlg: true,
    });
  };

  handleCloseShare() {
    this.setState({
      ...this.state,
      shareDlg: false,
    });
  }

  handleAnchorEl = (anchorEl) => {
    this.setState({
      ...this.state,
      anchorEl: anchorEl,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  showReportDlg = () => {
    const { loggedIn } = this.props;

    if (loggedIn) {
      this.setState({
        ...this.state,
        reportDlg: true,
        loginDlg: false,
        anchorEl: null,
      });

      if (isBannedPosts()) {
        ToastError("You've suspended for post operations.");
        return;
      }
    } else {
      this.setState({
        ...this.state,
        reportDlg: false,
        loginDlg: true,
        anchorEl: null,
      });
    }
  };


handleDeleteReport = async (token, article,authUser, articleFeed) => {
  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);
    await gqlservice.delete_article(article.nid)
                  .then((result) => {
                    this.props.deleteArticle(article.nid);
                  }, (reason) => {
                    this.setError(reason.msg);
                  })
                  .catch(err => {
                    this.setError(JSON.stringify(err));
                  });

                   // log this activity
                gqlservice.set_auth_jwt(token, false);
                const activity = {
                  user_id: authUser.uid,
                  type: ACTIVITY_TYPE_FEED,
                  type_id: articleFeed.id,
                  action: ACTIVITY_DELETE,
                  object: `the post ${article.title}`,
                  fromto: `of the feed ${articleFeed.name}`,
                  reason: ''
                };
                await gqlservice
                  .insert_activitylog(activity)
                  .then(result => {}, reason => {
                    this.setError(reason.msg);
                  })
                  .catch(err => {
                    this.setError(JSON.stringify(err));
                  });
                await updateFeedNotifications(articleFeed);
            
                this.setWaiting(false);
  }

  handleReport = async (reportMsg) => {
    this.setState({
      ...this.state,
      reportDlg: false,
      anchorEl: null
    });
    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    if (!reportMsg.trim()) {
      this.setError("Report shouldn't be blank. Please input your report.");
      return;
    }
    const { authUser } = this.props;
    const { article ,selected_feed } = this.props;

    const updatedAuthUser = await handleReportWithLimit(authUser);
    if(!updatedAuthUser){
      ToastError("Report limit exceeded. Please try again later.");
      return null
    }

    //top feeds
    const gqlservice = new GraphqlService();
    const token = await getAuthToken(); 
    gqlservice.set_auth_jwt(token);

    let topSources = [];
    let articleFeed = article.source? article.source.feed_sources[0]?.feed : selected_feed
    console.log("No articleFeed!!", articleFeed);
    const totalCount = articleFeed?.feed_sources?.length >= 9 ? 9 : articleFeed?.feed_sources?.length;
    for (let i = 0; i < totalCount; i++) {
      const branch_name = get_branch_name(articleFeed?.feed_sources[i]?.source?.branch);
      if (articleFeed?.feed_sources[i] && articleFeed?.feed_sources[i].source) {
        let new_top_source ={
          name:articleFeed?.feed_sources[i].source?.name ,
          description:articleFeed?.feed_sources[i].source?.description , 
          socialMediaType: branch_name
         }
         topSources.push(new_top_source);
      }
    }
    const feeds = JSON.parse(articleFeed?.ai_moderation) == null ? [] : JSON.parse(articleFeed?.ai_moderation);
    if (feeds.includes(FLAGGED_COMMENTS_POSTS)) {
      const currentDate = moment(); // Get the current date and time
      const date90DaysAgo = currentDate.subtract(90, 'days'); // Subtract 90 days from the current date
      console.log(date90DaysAgo.toISOString());
      const reportCount = await gqlservice.article_reports_count(authUser.uid ,date90DaysAgo.toISOString())
      const params = {
          name: articleFeed.name,
          description: articleFeed.description,
          userName: authUser.name,
          userBio: authUser.biography,
          userId:authUser.uid,
          userKarmaScore: authUser?.articles_voted?.length,
          topSources: topSources,
          userPostedSources: {
            postType:"post",
            socialMediaContent: article.text,
            name: article.title,
            reportCount:reportCount?.data?.article_reports_aggregate?.aggregate?.count

          },
      };
      
      if (!token) {
        this.handleLogin();
        return;
      }
      try {
        const result = await isFlaggedModerationByAi(params, token, authUser);
        if (result.status === 200) {
          if (result.message.decision.toLowerCase() === "denied") {
            this.handleDeleteReport(token, article, authUser, articleFeed);
            ToastSuccess(
              "Reported and deleted the article successfully."
            );
            const res = await updateReportLimit(updatedAuthUser)
            if (res.status === 200) {
              this.props.setAuthUser(updatedAuthUser);
            }
            return
          } else {
            this.setError("This post can't be reported since "+(result.message.reason));
            const res = await updateReportLimit(updatedAuthUser)
            if (res.status === 200) {
              this.props.setAuthUser(updatedAuthUser);
            }
            throw new Error("Post approved");
          }
        } else {
          this.setError("Failed to generate ai approvement.");
          return;
        }
      } catch (error) {
        if (error.message === "Post approved") {
          return;
        } else {
          this.setError(JSON.stringify(error));
          return;
        }
      }
    }
    this.props.onReport(article, reportMsg);
    await updateReportLimit(updatedAuthUser);
    this.props.setAuthUser(updatedAuthUser);
    ToastSuccess(
      "Report success, wait for approvement by the feed moderator."
    );
  };
  handleCancelReport = () => {
    this.setState({
      ...this.state,
      reportDlg: false,
      anchorEl: null
    });
  };

  handleEdit = () => {
    const { article, loggedIn } = this.props;
    if (loggedIn) {
      this.setState({
        ...this.state,
        anchorEl: null
      });

      if (isBannedPosts()) {
        ToastError("You've suspended for post operations.");
        return;
      }

      this.props.onEdit(article);
    } else {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
    }
  }

  handleDelete = () => {
    const { article, loggedIn } = this.props;
    if (loggedIn) {
      this.setState({
        ...this.state,
        anchorEl: null
      });

      if (isBannedPosts()) {
        ToastError("You've suspended for post operations.");
        return;
      }

      this.props.onDelete(article);
    } else {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
    }
  }

  handleSave = () => {
    const { article, loggedIn } = this.props;
    if (loggedIn) {
      this.setState({
        ...this.state,
        anchorEl: null
      });
      this.props.onSave(article);
    } else {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
    }
  }

  handleDeleteSaved = () => {
    const { article, loggedIn } = this.props;
    if (loggedIn) {
      this.setState({
        ...this.state,
        anchorEl: null
      });
      this.props.onDeleteSaved(article);
    } else {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
    }
  }

  handleCopyLink = () => {
    const { article } = this.props;

    let url = "";
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      url = `${document.location.origin}/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      url = `${document.location.origin}/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }
    
    copy2clipboard(url);

    this.setState({
      ...this.state,
      anchorEl: null,
    });
    ToastSuccess("Copied to clipboard");
  };

  handleClickUpvote = () => {
    const { loggedIn, article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    this.props.onClickUpvote(article);
  }

  handleClickComment = () => {
    const { article } = this.props;
    this.props.onClickComment(article);
  }

  handleClickRepost = () => {
    const { loggedIn, article } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null,
      });
      return;
    }

    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    this.props.onClickRepost(article);
  }

  handleLogin = () => {
    this.props.onLogin();
    this.setState({
      ...this.state,
      loginDlg: false,
      shareDlg: false,
      anchorEl: null
    });
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
      anchorEl: null
    });
  };

  render() {
    const { 
      classes, 
      theme, 
      loggedIn,
      authUser,
      article, 
      saved,
      sources,
      handleClick, 
      handleGroupId,
      onClickSource, 
      onClickFeed,
      onClickArticle,
      posts,
    } = this.props;
    const { 
      anchorEl, 
      reportDlg, 
      loginDlg, 
      shareDlg 
    } = this.state;
    // validation check
    let sourceDetails = null
    if (article.source_id !== null) {
      sourceDetails = article.source
      if (!sourceDetails) {
        logger.log("Unknown error source :", article.source_id)
        return <div style={{ display: "none" }}></div>;
      }
    }

    // popup menu position
    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    return (
      <div className={classes.root}>
          <SearchResultCard
            article={article}
            search_mode={false}
            handleClick={handleClick}
            handleGroupId={handleGroupId}
            onClickSource={onClickSource}
            onClickFeed={onClickFeed}
            onClickUpvote={this.handleClickUpvote}
            onClickComment={this.handleClickComment}
            onClickRepost={this.handleClickRepost}
            onAnchorEl={this.handleAnchorEl}
            onShare={this.handleShare}
            sourceDetails={sourceDetails}
          />
        <Menu
          id="source-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleCloseMenu}
        >
          <PopMenuPost
            theme={theme}
            saved={saved}
            owner={article.branch === ARTICLE_BRANCH_USERPOST && loggedIn && authUser && article.author === authUser.username}
            onReport={this.showReportDlg}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
            onCopyLink={this.handleCopyLink}
            onSave={this.handleSave}
            onDeleteSaved={this.handleDeleteSaved}
          />
        </Menu>
        <DlgReport
          open={reportDlg}
          title={"Report Article"}
          theme={theme}
          onReport={this.handleReport}
          onCancel={this.handleCancelReport}
        />
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        {article.source_id &&
          <DlgSharePost
            open={shareDlg}
            post={article}
            onLogin={this.handleLogin}
            onClose={this.handleCloseShare}
          /> 
        }
      </div>
    );
  }
}

SearchArticle.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  loggedIn: PropTypes.bool,
  authUser: PropTypes.object,
  article: PropTypes.object, 
  saved: PropTypes.bool,
  onReport: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onDeleteSaved: PropTypes.func,
  onLogin: PropTypes.func,
  handleClick: PropTypes.func, 
  handleGroupId: PropTypes.func, 
  onClickSource: PropTypes.func, 
  onClickFeed: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
  from: PropTypes.string,
  onClickArticle:PropTypes.func,
};

const mapStateToProps = (state) => ({
  sources: state.dataState.sources,
  selected_feed: state.dataState.selected_feed,
});
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}


export default compose(
  connect(mapStateToProps,mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(SearchArticle);