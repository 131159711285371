import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { 
  THEME_MODE_DARK, 
  TAB_MASTODON_ALL,
  TAB_MASTODON_FEEDS,
  TAB_MASTODON_SOURCES,
  TAB_MASTODON_HASHTAGS,
  TAB_MASTODON_POSTS,
  MAX_ARTICLE_WIDTH, 
} from "constants/types";

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    padding: 0,
    marginTop:0,
  },
  tabs: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: 18,
    // maxWidth: MAX_ARTICLE_WIDTH - 32,
    borderRadius: 12,
    backgroundColor: theme.palette.background.light,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
    alignItems: "center",
  },
  indicator_light: {
    backgroundColor: "#1878f3",
  },
  indicator_dark: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  tab_light: {
    padding: 0,
    minWidth: 120,
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#1878f3",
  },
  tab_dark: {
    padding: 0,
    minWidth: 120,
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  icon: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    margin: 0,
    width: 16,
    height: 16
  }
});

class MastodonTab extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  handleChangeTab = (event, newValue) => {
    const { myfeedstab } = this.props;
    if (newValue === null) return;
    if (myfeedstab === newValue) return;

    this.props.onChangeTab(newValue);
  };

  render() {
    const { classes, myfeedstab, theme_mode,authUser,loggedIn  } = this.props;

    const all_tab = { value: TAB_MASTODON_ALL, name: "ALL", icon: "home.png" };
    const feeds_tab = { value: TAB_MASTODON_FEEDS, name: "FEEDS", icon: "raven_logo.png" };
    const sources_tab = { value: TAB_MASTODON_SOURCES, name: "SOURCES", icon: "account.png" };
    const hashtag_tab = { value: TAB_MASTODON_HASHTAGS, name: "HASHTAGS", icon: "members.png" };
    const posts_tab = { value: TAB_MASTODON_POSTS, name: "POSTS", icon: "copy.png" };


    let classes_indicator = theme_mode === THEME_MODE_DARK ? classes.indicator_dark : classes.indicator_light;
    let classes_tab = theme_mode === THEME_MODE_DARK ? classes.tab_dark : classes.tab_light;

    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const numberOfTabs = 5; 
    
    
    const responsiveTabWidth = Math.min((width * 0.95) / numberOfTabs, MAX_ARTICLE_WIDTH);
    return (
        
      <div className={classes.root}>
        <Tabs
          className={classes.tabs}
          classes={{ indicator: theme_mode === THEME_MODE_DARK ? classes.indicator_dark : classes.indicator_light }}
          value={myfeedstab}
          onChange={this.handleChangeTab}
          variant={width > MAX_ARTICLE_WIDTH ? "fullWidth" : "scrollable"}
          scrollButtons="auto"
          style={{ width: responsiveTabWidth * numberOfTabs }}
        >
          <Tab
            value={all_tab.value}
            key={all_tab.value}
            label={all_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={all_tab.name}
                src={`/static/images/icons/${theme_mode}/${all_tab.icon}`}
                className={classes.icon}
              />
            }
          />
          <Tab
            value={feeds_tab.value}
            key={feeds_tab.value}
            label={feeds_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={feeds_tab.name}
                src={`/static/images/icons/${theme_mode}/${feeds_tab.icon}`}
                className={classes.icon}
              />
            }
          />
          <Tab
            value={sources_tab.value}
            key={sources_tab.value}
            label={sources_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={sources_tab.name}
                src={`/static/images/icons/${theme_mode}/${sources_tab.icon}`}
                className={classes.icon}
              />
            }
          />{ loggedIn && authUser.is_mastodon_enabled &&  (
          <Tab
            value={hashtag_tab.value}
            key={hashtag_tab.value}
            label={hashtag_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={hashtag_tab.name}
                src={`/static/images/icons/${theme_mode}/${hashtag_tab.icon}`}
                className={classes.icon}
              />
            }
          />)}
          <Tab
            value={posts_tab.value}
            key={posts_tab.value}
            label={posts_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={posts_tab.name}
                src={`/static/images/icons/${theme_mode}/${posts_tab.icon}`}
                className={classes.icon}
              />
            }
          />
        </Tabs>
      </div>
    );
  }
}

MastodonTab.propTypes = {
  classes: PropTypes.object,
  onChangeTab: PropTypes.func,
 
};

const mapStateToProps = (state) => ({
  theme_mode: state.uiState.theme_mode,
  authUser: state.sessionState.authUser,
  loggedIn: state.sessionState.loggedIn,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MastodonTab);
