import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { THEME_MODE_DARK, TAB_FEED, TAB_SOURCES, TAB_COMMENTS, TAB_MODERATORS, MAX_ARTICLE_WIDTH } from "constants/types";

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    padding: 0
  },
  tabs: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: 18,
    // maxWidth: MAX_ARTICLE_WIDTH - 32,
    borderRadius: 12,
    backgroundColor: theme.palette.background.light,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`
  },
  indicator_light: {
    backgroundColor: "#1878f3",
  },
  indicator_dark: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  tab_light: {
    padding: 0,
    minWidth: 120,
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#1878f3",
  },
  tab_dark: {
    padding: 0,
    minWidth: 120,
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  icon: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    margin: 0,
    width: 16,
    height: 16
  }
});

class FeedTabs extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   activeTab: TAB_SOURCES
    // };

    this.handleFeedTab = this.handleFeedTab.bind(this);
  }

  handleFeedTab = (event, newValue) => {
    const { feedtab } = this.props;
    if (newValue === null) return;
    if (feedtab === newValue) return;

    this.props.onChangeFeedTab(newValue);
  };

  render() {
    const { classes, feedtab, theme_mode } = this.props;

    const feed_tab = { value: TAB_FEED, name: "Feed", icon: "home.png" };
    const sources_tab = { value: TAB_SOURCES, name: "Sources", icon: "sources.png" };
    const comments_tab = { value: TAB_COMMENTS, name: "Comments", icon: "comments.png" };
    const moderators_tab = { value: TAB_MODERATORS, name: "Mods", icon: "moderator.png" };

    let classes_indicator = theme_mode === THEME_MODE_DARK ? classes.indicator_dark : classes.indicator_light;
    let classes_tab = theme_mode === THEME_MODE_DARK ? classes.tab_dark : classes.tab_light;

    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const tabsWidth = width > MAX_ARTICLE_WIDTH ? MAX_ARTICLE_WIDTH - 32 : width - 32;

    return (
      <div className={classes.root}>
        <Tabs
          className={classes.tabs}
          classes={{ indicator: classes_indicator }}
          value={feedtab}
          onChange={this.handleFeedTab}
          variant={width > MAX_ARTICLE_WIDTH ? "fullWidth": "scrollable"}
          style={{ width: tabsWidth}}
        >
          <Tab
            value={feed_tab.value}
            key={feed_tab.value}
            label={feed_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={feed_tab.name}
                src={`/static/images/icons/${theme_mode}/${feed_tab.icon}`}
                className={classes.icon}
              />
            }
          />
          <Tab
            value={sources_tab.value}
            key={sources_tab.value}
            label={sources_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={sources_tab.name}
                src={`/static/images/icons/${theme_mode}/${sources_tab.icon}`}
                className={classes.icon}
              />
            }
          />
          <Tab
            value={comments_tab.value}
            key={comments_tab.value}
            label={comments_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={comments_tab.name}
                src={`/static/images/icons/${theme_mode}/${comments_tab.icon}`}
                className={classes.icon}
              />
            }
          />
          <Tab
            value={moderators_tab.value}
            key={moderators_tab.value}
            label={moderators_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={moderators_tab.name}
                src={`/static/images/icons/${theme_mode}/${moderators_tab.icon}`}
                className={classes.icon}
              />
            }
          />
        </Tabs>
      </div>
    );
  }
}

FeedTabs.propTypes = {
  classes: PropTypes.object,
  onChangeFeedTab: PropTypes.func
};

const mapStateToProps = (state) => ({
  theme_mode: state.uiState.theme_mode,
  feedtab: state.uiState.feedtab
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedTabs);