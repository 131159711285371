import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, Grid } from "@material-ui/core";
import { FeedModeButton } from "components";
import { 
  THEME_MODE_LIGHT,
  MAX_CARD_WIDTH,
} from "constants/types";


class DlgPubPriv extends React.Component {

  render() {
    const { theme, open, mode, onClose, onSelectMode } = this.props;

    const paper_style = {
      minWidth: MAX_CARD_WIDTH,
      backgroundColor: theme === THEME_MODE_LIGHT ? "white" : "black",
      paddingBottom: 8,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme === THEME_MODE_LIGHT ? "black" : "white",
    };

    return (
      <Dialog 
        open={open}
        onClose={onClose}
        PaperProps={{ style: paper_style }}
      >
        <DialogContent>
          <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <FeedModeButton
                theme={theme}
                mode={0}
                selected={mode === 0}
                onClick={e => onSelectMode(0)}
              />
            </Grid>
            <Grid item>
              <FeedModeButton
                theme={theme}
                mode={1}
                selected={mode === 1}
                onClick={e => onSelectMode(1)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

DlgPubPriv.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  open: PropTypes.bool,
  mode: PropTypes.number,
  onClose: PropTypes.func,
  onSelectMode: PropTypes.func
};

DlgPubPriv.defaultProps = {
};

export default DlgPubPriv;
