import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  BasicAppBar,
} from "components";

import {
  GraphqlService,
  withFirebase,
} from "services";
import {
  MAX_WINDOW_WIDTH,
  MIN_TABLET_WIDTH,
} from "constants/types";
import {
  withAuthentication,
  withAuthorization,
} from "session";
import { MemberItem } from "views/FeedMembers/components";
import { List, Toolbar } from "@material-ui/core";
import { ToastError } from "utility/toast";
import * as ROUTES from "constants/routes";
import { followUser, getAuthToken, isBanned, unfollowUser } from "dataapis";
import FollowerList from "views/ProfileFollowers/components/FollowerList";

const condition = (authUser) => !!authUser && authUser.uid !== ""

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 10,
  },
  membercontainer: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(5),
    padding: 0,
    backgroundColor: theme.palette.background.default
  },
  avatar: {
    margin: 0,
    padding: 1,
    width: 25,
    height: 25,
    marginLeft: theme.spacing(2),
  },
  logo_text: {
    marginLeft: theme.spacing(0.5),
    marginTop: 0.5,
    height: 22,
    width: "auto",
  },
})

class UserFollowers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginDlg: false,
      postDlg: false,
      article_edit: null,
      ifollow: [],
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleClickFollow = this.handleClickFollow.bind(this);
    this.handleClickUnfollow = this.handleClickUnfollow.bind(this);
    this.handleClickMember = this.handleClickMember.bind(this);

  }

  componentDidMount = async () => {

    const { loggedIn, authUser, selected_user, match } = this.props;
    let users_followed = []
    if (loggedIn) {
      users_followed = authUser.users_followed.slice();
    }

    this.setState({
      ...this.state,
      ifollow: users_followed,
    });
    if (!selected_user) {
      let username = match.params.username;
      const gqlservice = new GraphqlService();
      if (loggedIn) {
        const token = await getAuthToken();
        if (!token) {
          // this.handleLogin();
          return;
        }
        gqlservice.set_auth_jwt(token);
      }
      const userResult = await gqlservice.user_by_username(username);
      const users = userResult.data.users;
      let selected_user = users[0];
      this.props.selectUser(selected_user);

      if (users.length === 0) {
        return
      }
    }

  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }
  handleNavBack = () => {
    // const location = {
    //   pathname: ROUTES.PROFILE,
    //   state: { animation: "right" },
    // };
    // this.props.history.push(location);
    this.props.history.goBack();
  };

  handleClickFollow = async (member) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await followUser(member.follower_id);
    this.setWaiting(false);

    this.updateUsers();
  }

  handleClickUnfollow = async (member) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await unfollowUser(member.follower_id);
    this.setWaiting(false);

    this.updateUsers();
  }

  updateUsers = () => {
    const { loggedIn, authUser } = this.props;
    let users_followed = []
    if (loggedIn) {
      users_followed = authUser.user_followers.slice();
    }
    this.setState({
      ...this.state,
      ifollow: users_followed,
    });
  }

  handleClickMember = member => {

    if (member.follower.username === null || member.follower.username.length === 0) {
      return;
    }
    let route = `/${ROUTES.USER_PREFIX}/${member.follower.username}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  render() {
    const {
      classes,
      authUser,
      loggedIn,
      theme_mode,
      member,
      selected_user
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_WINDOW_WIDTH}
            title={"Followers"}
            onNavBack={this.handleNavBack}
          />
        </div>
        {selected_user && (
          <div>
            <Toolbar className={classes.toolbar}>
              <img
                alt={"Raven"}
                src={`/static/images/logo/${theme_mode}/logo.png`}
                className={classes.avatar}
              />
              <img
                alt={"Raven"}
                src={`/static/images/logo/${theme_mode}/logo_text.png`}
                className={classes.logo_text}
              />
            </Toolbar>
            <div className={classes.membercontainer}>
              <List>
                {selected_user.user_followers.map(member => (
                  <FollowerList
                    key={member.follower_id}
                    member={member}
                    disabled={member.follower_id === authUser.uid}
                    loggedIn={loggedIn}
                    following={authUser.users_followed.some(user => user.user_id === member.follower_id)}
                    theme_mode={theme_mode}
                    onClicked={this.handleClickMember}
                    onFollowed={this.handleClickFollow}
                    onUnfollowed={this.handleClickUnfollow}
                  />
                ))}
              </List>
            </div>
          </div>
        )}
      </div>
    )
  }
}


const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  followed_feeds: state.dataState.followed_feeds,
  my_posts: state.dataState.my_posts,
  posts_backroute: state.uiState.posts_backroute,
  requesting: state.uiState.requesting,
  selected_user: state.dataState.selected_user
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UserFollowers);