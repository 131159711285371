import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  Typography,
  IconButton,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { 
  get_timestring, 
} from "utility/utils";
import { 
  MAX_CARD_WIDTH, 
  MIN_CARD_WIDTH,
} from "constants/types";
import { logger } from "utility/logging";


const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 10,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.default,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  share: {
    position: "absolute",
    top: 4,
    right: 4,
    padding: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    backgroundColor: "#7289DA",
    zIndex: 100,
  },
  shareicon: {
    width: 16,
    height: 16,
  },
  pin: {
    position: "absolute",
    top: 8,
    right: 32,
    padding: 0,
    width: 16,
    height: 16,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    zIndex: 100,
  },
  expand: {
    position: "absolute",
    top: 20,
    right: 2,
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  videocast: {
    position: "absolute",
    top: 8,
    left: 8,
    width: 36,
    height: 36,
    borderRadius: "50%",
    backgroundColor: "#F29900",
  },
  videocasticon: {
    padding: 3,
    width: 36,
    height: 36
  },
  titleline: {
    position: "relative",
    marginLeft: 40,
    marginRight: 40,
    margin: 10,
  },
  author: {
    fontFamily: "Arial",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  feed: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontStyle: "Italic",
    lineHeight: "18px",
    marginTop: 4,
    marginBottom: 4,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  date: {
    float: "right",
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  playerwrapper: {
    position: "relative",
    paddingTop: 0,
    paddingBottom: 12,
  },
  reactplayer: {
    top: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

class VideocastPlayCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickShare = this.handleClickShare.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
    this.handleEnded = this.handleEnded.bind(this);
  }

  handleClickShare = () => {
    const { article } = this.props;
    this.props.onShare(article.nid);
  };

  handleExpand = (event) => {
    this.props.onAnchorEl(event.currentTarget);
  };

  handleEnded = () => {
    const video = document.getElementById(`videocast-${this.props.article.nid}`);
    if (video) {
      const domRect = video.getBoundingClientRect();
      logger.log("video rect :", domRect);
      window.scrollTo({
        top: window.scrollY + domRect.height, 
        behavior: "smooth"
      });
      // this.props.saveScrollPos(window.scrollX, window.scrollY);
    }
    this.props.onEnded(this.props.article.nid);
  }

  render() {
    const { 
      classes, 
      theme_mode, 
      article,
      selected_videocast,
      onPlay,
      onPause,
    } = this.props;

    const published = get_timestring(article.published);
    
    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    width -= 16;
    if (width > MAX_CARD_WIDTH - 16)
      width = MAX_CARD_WIDTH - 16;
    if (width < MIN_CARD_WIDTH - 16)
      width = MIN_CARD_WIDTH - 16;

    return (
      <Card 
        className={classes.card} 
        style={{ width: width }} 
        id={`videocast-${article.nid}`}
      >
        <div className={classes.carddiv}>
          <CardHeader
            className={classes.header}
            avatar={
              <div className={classes.videocast}>
                <img
                  alt={"videocast"}
                  src={`/static/images/icons/${theme_mode}/videocast.png`}
                  className={classes.videocasticon}
                />
              </div>
            }
            title={
              <div className={classes.titleline}>
                <Typography className={classes.author}>
                  {"RavenCast"}
                </Typography>
                <Typography className={classes.date}>{published}</Typography>
              </div>
            }
          />
          <div className={classes.playerwrapper}>
            <ReactPlayer
              className={classes.reactplayer}
              controls={true}
              playing={selected_videocast && selected_videocast.nid === article.nid}
              width='90%'
              height='90%'
              url={article.media_url}
              onPlay={e => onPlay(article.nid)}
              onPause={e => onPause(article.nid)}
              onEnded={e => this.handleEnded(article.nid)}
            />
          </div>
          <div>
            <IconButton
              className={classes.share}
              onClick={this.handleClickShare}
            >
              <ShareIcon className={classes.shareicon} />
            </IconButton>
            <IconButton className={classes.expand} onClick={this.handleExpand}>
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
      </Card>
    );
  }
}

VideocastPlayCard.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
  onAnchorEl: PropTypes.func,
  onShare: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnded: PropTypes.func
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  theme_mode: state.uiState.theme_mode,
  selected_videocast: state.dataState.selected_videocast,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(VideocastPlayCard));
