import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { 
  withAuthentication, 
  withAuthorization 
} from "session";
import { 
  UserpostCard, 
  BasicAppBar,
  WaitingSpinner, 
  GenerateSummary,
  DlgSharePost,
  PopMenuPostMod,
  DlgComment
} from "components";
import { Propose } from "./components";
import { 
  GraphqlService,
  withFirebase,
} from "services";
import * as ROUTES from "constants/routes";
import { MAX_ARTICLE_WIDTH } from "constants/types";
import { NOTIFICATION_POST_APPROVE } from "constants/notification";
import { 
  ACTIVITY_TYPE_FEED, 
  ACTIVITY_APPROVE, 
  ACTIVITY_DELETE,
  ACTIVITY_REMOVE,
  ACTIVITY_PIN,
  ACTIVITY_MOVETOP 
} from "constants/activity";
import { 
  getAuthToken, 
  updateFeedNotifications,
  ERR_MSG_AUTH_TOKEN,
  saveArticle,
  repostArticle 
} from "dataapis";
import { ToastSuccess,ToastError } from "utility/toast";
import { ARTICLE_BRANCH_PODCAST_SUMMARY, ARTICLE_BRANCH_YOUTUBE_SUMMARY,ARTICLE_BRANCH_NEWSPAPER } from "constants/branches";
import { Menu } from "@material-ui/core";
import {
  isBannedPosts
} from "dataapis";
import { logger } from "utility/logging";
import { copy2clipboard } from "utility/utils";
import { v4 as uuidv4 } from "uuid";
import { GRAPHQL_ERROR,THREAD_TYPE_ARTICLE } from "constants/types";
import store from "store";
const condition = (authUser) => !!authUser && authUser.uid !== ""


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    margin: theme.spacing(1),
  },
  propose: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class PostsProposed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      proposed: [],
      posted: [],
      anchorEl: null,
      shareDlg: false,
      selectedPost:null,
      expand:false,
      commentDlg: false,
    };

    this.handleNavBack = this.handleNavBack.bind(this);

    this.handleApproveProposed = this.handleApproveProposed.bind(this);
    this.handleDeleteProposed = this.handleDeleteProposed.bind(this);
    this.handleApprovePosted = this.handleApprovePosted.bind(this);
    this.handleDeletePosted = this.handleDeletePosted.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCopyLink=this.handleCopyLink.bind(this);
    this.handleAnchorEl = this.handleAnchorEl.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleCommentArticle = this.handleCommentArticle.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount() {
    const { selected_feed } = this.props;
    this.setState({
      ...this.state,
      proposed: selected_feed.proposed_posts,
      posted: selected_feed.posted_posts
    });
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    this.props.history.goBack();
  }
   
  handleClickRepost = () => {
    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    const { selectedPost } = this.state;
    this.props.onClickRepost(selectedPost);
  }


  deleteProposed = (post_id) => {
    const { proposed } = this.state;
    const new_proposed = proposed.filter(post => post.nid !== post_id);

    this.setState({
      ...this.state,
      proposed: new_proposed
    });
  }

  handleApproveProposed = async (post) => {
    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, 1);

    this.setWaiting(true);

    await gqlservice.approve_userpost(post.nid, true)
      .then(result => {
        this.deleteProposed(post.nid);
        this.props.approveUserPost(post)
      }, reason => {
        this.setError(reason.msg);
        return;
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        return;
      });

    gqlservice.set_auth_jwt(token, false);
    if (post.txt_param1) {
      // insert notification
      const notification = {
        type: NOTIFICATION_POST_APPROVE,
        object: post.nid,
        in_which: selected_feed.id,
        to: post.txt_param1,
        created_by: authUser.uid
      }

      await gqlservice
        .insert_notification(notification)
        .then(result => {}, reason => {
          this.setError(reason.msg);
          return;
        })
        .catch(err => {
          this.setError(JSON.stringify(err));
          return;
        });

      await updateFeedNotifications(selected_feed);
    }

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_APPROVE,
      object: `the user post ${post.nid}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  }

  handleDeleteProposed = async (post) => {
    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice.delete_article(post.nid)
      .then(result => {
        this.deleteProposed(post.nid);
        this.props.deleteArticle(post.nid);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_DELETE,
      object: `the user post ${post.nid}`,
      fromto: `from the feed ${selected_feed.name}`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    await updateFeedNotifications(selected_feed);

    this.setWaiting(false);
  }

  deletePosted = (post_id) => {
    const { posted } = this.state;
    const new_posted = posted.filter(post => post.nid !== post_id);

    this.setState({
      ...this.state,
      posted: new_posted
    });
  }

  handleApprovePosted = async (post) => {
    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    const currentTime = new Date().toISOString();
    await gqlservice.update_feed_post(selected_feed.id, post.nid, true, authUser.uid, currentTime)
      .then(result => {
        this.deletePosted(post.nid);
        this.props.approveFeedPost(post)
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_APPROVE,
      object: `the feed post ${post.nid}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    await updateFeedNotifications(selected_feed);

    this.setWaiting(false);
  }

  handleDeletePosted = async (post) => {
    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice.delete_feed_post(selected_feed.id, post.nid)
      .then(result => {
        this.deletePosted(post.nid);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_DELETE,
      object: `the feed post ${post.nid}`,
      fromto: `from the feed ${selected_feed.name}`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    await updateFeedNotifications(selected_feed);

    this.setWaiting(false);
  }  

  handleClick = (post) => {
    this.props.selectArticle(post);
    const path = `/${ROUTES.ARTICLE_PREFIX}/${post.nid}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
  
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.PROPOSED_POSTS);
  };

  handleAnchorEl =(anchorEl) => {
    this.setState({
      ...this.state,
      anchorEl: anchorEl,
    });
  };
  handleMenuClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };
  handleShare = () => { this.setState({
    shareDlg: true, 
  });};

  handleCloseShare = () => {
    this.setState({
      shareDlg: false,
    });
  };
  showCommentDlg = (show) => {
    if (isBannedPosts()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    this.setState({
      ...this.state,
      commentDlg: show,
      anchorEl: null,
    });
  };

  

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  };

handleCopyLink = (post,index) => {
  let url = "";
  if (post.branch === ARTICLE_BRANCH_NEWSPAPER) {
    url = `${document.location.origin}/${ROUTES.ARTICLE_NEWS_PREFIX}/${post.nid}`;
  } else {
    url = `${document.location.origin}/${ROUTES.ARTICLE_PREFIX}/${post.nid}`;
  }
  copy2clipboard(url);

  this.setState({
    ...this.state,
    anchorEl: null,
  });

  ToastSuccess("Copied to clipboard");
};

handleCommentArticle = async (article, content) => {
  const { authUser, selected_feed } = this.props;
  const{selectedPost}=this.state;
  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    this.handleLogin();
    return;
  }
  gqlservice.set_auth_jwt(token);

  this.setWaiting(true);
  // insert comment
  const comment = {
    parent_id: null,
    text: content,
    article_id: selectedPost.nid,
    reading_id: null,
    // thread_id: thread_id,
    author_id: authUser.uid,
    approved: true,
    approved_by: authUser.uid,
    approved_at: new Date().toISOString(),
  };

  await gqlservice
    .insert_comment(comment)
    .then(
      (result) => {
        if (result.data.insert_comments.affected_rows > 0) {
          return gqlservice.article_by_nid(selectedPost.nid);
        } else {
          this.setError("Failed to insert comment.");
          return;
        }
      },
      (reason) => {
        this.setError(reason.msg);
        return;
      }
    )
    .then((result) => {
      const articles = result.data;
      if (articles.length > 0) {
        this.props.updateArticle(articles[0]);
      }
    })
    .catch((err) => {
      this.setError(JSON.stringify(err));
      return;
    });

  this.setWaiting(false);
};

handleComment = (content) => {
  this.showCommentDlg(false);
  if (isBannedPosts()) {
    ToastError("You've suspended for comment operations.");
    return;
  }

  logger.log("comment :", content);
  const { article } = this.props;
  this.handleCommentArticle(article, content);
};
  handleClickRepost = async (article) => {
    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  };
  
  handlePostClick = (post) => {
    this.setState({ selectedPost: post });
  };
  render() {
    const { classes, requesting,saved,handleClick,content,theme_mode,selected_feed } = this.props;
    const { proposed, posted,shareDlg,anchorEl,deleteDlg,selectedPost,commentDlg,theme } = this.state;
    // popup menu position
    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }
    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    width = width > MAX_ARTICLE_WIDTH ? MAX_ARTICLE_WIDTH : width;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Proposed Posts"}
            onNavBack={this.handleNavBack}
            width={width}
          />
        </div>
        <Grid 
          container 
          justifyContent="center"
          spacing={1} 
          className={classes.container}
        >
          {proposed.map((post, index) => (
            <div key={index}>
              <Grid item key={`post-${index}`}>
                <UserpostCard 
                  article={post}
                  handleClick={this.handleClick}
                  onAnchorEl={this.handleAnchorEl}
                  onShare={this.handleShare}
                />
              </Grid>
              <Grid
                item
                key={`proposed-${index}`}
                className={classes.propose}
              >
                <Propose
                  post={post}
                  onApprove={this.handleApproveProposed}
                  onDelete={this.handleDeleteProposed}
                />
              </Grid>
            </div>
          ))}
        {posted.map((post, index) => (
  <div key={index} onClick={() => this.handlePostClick(post)}>
    <Grid item key={`post-${index}`}>
      {post.branch === ARTICLE_BRANCH_YOUTUBE_SUMMARY || post.branch === ARTICLE_BRANCH_PODCAST_SUMMARY ? (
        
        <GenerateSummary
          article={post}
          handleClick={this.handleClick}
          onAnchorEl={this.handleAnchorEl}
          onShare={this.handleShare}
          onClickRepost={()=>{this.handleClickRepost(post)}}
          onClickComment={()=>{this.handleClick(post)}}
        />
      ) : (
        
        <UserpostCard
          article={post}
          handleClick={this.handleClick}
          onAnchorEl={this.handleAnchorEl}
          onShare={this.handleShare}
          onClickRepost={()=>{this.handleClickRepost(post)}}
          onClickComment={()=>{this.handleClick(post)}}
        />
        
        
      )}
    </Grid>
    <Grid
      item
      key={`posted-${index}`}
      className={classes.propose}
    >
      <Propose
        post={post}
        onApprove={() => this.handleApprovePosted(post)}
        onDelete={() => this.handleDeletePosted(post)}
      />
    </Grid>
          <DlgSharePost
            open={shareDlg}
            post={selectedPost}
            onLogin={this.handleLogin}
            onClose={() => this.setState({ shareDlg: false })}
          />
        {/* )
         
        
    } */}
    <DlgComment
          open={commentDlg}
          theme={theme_mode}
          onClose={e => this.showCommentDlg(false)}
          onSubmit={this.handleComment}
        />
     <Menu
          id="source-menu"
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={selectedPost !== null && anchorEl !== null}
          onClose={this.handleMenuClose}
        >
          {selectedPost && (
            <PopMenuPostMod
              theme={theme_mode}
              post={selectedPost}
              onCopyLink={() => this.handleCopyLink(selectedPost)}
              isDisable={true}
              onSave={()=>{this.handleSaveArticle(selectedPost)}}
              onComment={e => this.showCommentDlg(true)}
              
            />
          )}
        </Menu>
    
  </div>
))}


        </Grid>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

PostsProposed.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PostsProposed);
