import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { THEME_MODE_DARK } from "constants/types";

const styles = (theme) => ({
  root: {
    position: "relative",
    cursor: "pointer",
    width: 150,
    height: 150,
    paddingLeft: 4,
    paddingRight: 4,
    textAlign: "center",
  },
  image: {
    marginTop: theme.spacing(1),
    height: 24,
    width: 24,
    backgroundColor: theme.palette.background.default,
  },
  title: {
    textTransform: "none",
    marginTop: theme.spacing(1),
    fontSize: "20px",
    lineHeight: "24px",
    color: theme.palette.text.primary,
  },
  description: {
    textTransform: "none",
    marginTop: theme.spacing(1),
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  }
});

class FeedModeButton extends React.Component {

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this);
  };

  handleClick = (mode) => {
    this.props.onClick(mode);
  }

  render() {
    const { classes, theme, mode, selected } = this.props;

    const border_width = selected ? 3 : 2;
    let container = {
      border: `${border_width}px solid`
    };
    if (selected) {
      container.borderColor = theme === THEME_MODE_DARK ? "#FFFFFF" : "#1878F3";
    } else {
      container.borderColor = theme === THEME_MODE_DARK ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.2)";
    }

    return (
      <div className={classes.root} style={container}>
        {mode === 0 &&
          <div onClick={e => this.handleClick(mode)}>
            <img 
              className={classes.image} 
              src={`/static/images/icons/${theme}/public.png`} 
              alt={"public"} 
            />
            <Typography className={classes.title}>{"Public Feed"}</Typography>
            <Typography className={classes.description}>{"Allow other Raven users to see and contribute to your feed after mod approval"}</Typography>
          </div>
        }
        {mode === 1 &&
          <div onClick={e => this.handleClick(mode)}>
            <img 
              className={classes.image} 
              src={`/static/images/icons/${theme}/private.png`} 
              alt={"private"} 
            />
            <Typography className={classes.title}>{"Private Feed"}</Typography>
            <Typography className={classes.description}>{"Only you and invited friends can see and contribute to this feed. You can change this later"}</Typography>
          </div>
        }
      </div>
    );
  }
}

FeedModeButton.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  mode: PropTypes.number,
  onClick: PropTypes.func
};

export default withStyles(styles)(FeedModeButton);
