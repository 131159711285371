export const QUERY_MAP_LOCATION_BY_ID = `
query map_locations_by_id (
  $id: Int!
) {
  locations (
    where: { 
      id: {_eq: $id}
    }
  ) {
    id
    region_id
    type
    tag_id
    suite
    address
    postcode
    latitude
    longitude
    name
    description
    slug
    social_link
    last_co2
    op_readings
    op_imageupload
    op_moderation
    op_description
    op_comments
    reading_conf
    created_at
    created_by
    approved
    approved_at
    approved_by
    region {
      id
      locality
      place
      district
      region
      country
      slug
      created_at
      created_by      
    }
   
    location_reports(
      where: { 
        location_id: {_eq: $id}
      }
    ) {
      id
      location_id
      report
      reported_at
      reported_by
      approved
      approved_at
      approved_by
      reported_user {
        uid
        username
      }
    }
    location_moderators (
      where: {
        location_id: {_eq: $id}}
    ) {
      id
      location_id
      created_at
      approved
      approved_at
      approved_by
      user {
        uid
        name
        username
        biography
        image
      }
    }
    location_followers(
      where: { 
        location_id: {_eq: $id} 
      }
    ) {
      id
      user_id
      location_id
      followed_at
      user {
        uid
        name
        username
        biography
        image
      }
    }
    readings {
      id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      reader {
        uid
        name
        biography 
        image
      }
      location_room {
        id
        name
      }
      location_mask_friendly_hours {
        id
        location_id
        day
        time
        created_at
        created_by
      }
    }
    location_rooms {
      id
      name
      readings {
        id
        co2
        ach
        mask
        image
        gps_verified
        adjusted
        co2_in
        activity
        people_no
        room_volume
        hepa_cadr
        uvgi_cap
        comment
        recommends
        reading_at
        reading_by
        reader {
          uid
          name
          biography 
          image
        }
        location_room {
          id
          name
        }
        location_mask_friendly_hours {
          id
          created_by
          day
          time
          location_id
          created_at
          reading_id    
        }
      }
    }readings
    location_users_banned {
      id
      banned_at
      user {
        uid
        username
        image
        biography
      }
    }
  }
}`;

export const QUERY_MAP_LOCATION_COUNT_BY_SLUG = `
query map_exist_location_by_slug (
  $slug: String!
) {
  locations_aggregate (
    where: { 
      slug: {_eq: $slug}
    }
  ) {
    aggregate {
      count
    }
  }
}`;

export const QUERY_MAP_LOCATION_BY_SLUG = `
query map_location_by_slug (
  $slug: String!
) {
  locations (
    where: { 
      slug: {_eq: $slug}
    }
  ) {
    id
    region_id
    type
    tag_id
    suite
    address
    postcode
    latitude
    longitude
    name
    description
    slug
    social_link
    last_co2
    op_readings
    op_imageupload
    op_moderation
    op_description
    op_comments
    reading_conf
    created_at
    created_by
    approved
    approved_at
    approved_by
    region {
      id
      locality
      place
      district
      region
      country
      slug
      created_at
      created_by      
    }
    readings {
      id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      reader {
        uid
        name
        biography 
        image
      }
      location_room {
        id
        name
      }
      location_mask_friendly_hours {
        id
        created_by
        day
        time
        location_id
        created_at
        reading_id    
      }
    }
    location_rooms {
      id
      name
      readings {
        id
        co2
        ach
        mask
        image
        gps_verified
        adjusted
        co2_in
        activity
        people_no
        room_volume
        hepa_cadr
        uvgi_cap
        comment
        recommends
        reading_at
        reading_by
        reader {
          uid
          name
          biography 
          image
        }
        location_room {
          id
          name
        }
        location_mask_friendly_hours {
          id
          created_by
          day
          time
          location_id
          created_at
          reading_id    
        }
      }
    }
  }
}`;

export const QUERY_MAP_LOCATIONS_BY_BOX = `
query map_locations_by_box (
  $lng_min: Float!,
  $lng_max: Float!,
  $lat_min: Float!,
  $lat_max: Float!
) {
  locations (
    where: { 
      longitude: {_gte: $lng_min, _lte: $lng_max}, 
      latitude: {_gte: $lat_min, _lte: $lat_max}
    },
    limit: 200,
    order_by : {created_at: desc}
  ) {
    id
    region_id
    type
    tag_id
    suite
    address
    postcode
    latitude
    longitude
    name
    description
    slug
    social_link
    last_co2
    op_readings
    op_imageupload
    op_moderation
    op_description
    op_comments
    reading_conf
    created_at
    created_by
    approved
    approved_at
    approved_by
    region {
      id
      locality
      place
      district
      region
      country
      slug
      created_at
      created_by      
    }
    readings {
      id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      reader {
        uid
        name
        biography 
        image
      }
      location_room {
        id
        name
      }
      location_mask_friendly_hours {
        id
        created_by
        day
        time
        location_id
        created_at
        reading_id    
      }
    }
    
    location_rooms {
      id
      name
      readings {
        id
        co2
        ach
        mask
        image
        gps_verified
        adjusted
        co2_in
        activity
        people_no
        room_volume
        hepa_cadr
        uvgi_cap
        comment
        recommends
        reading_at
        reading_by
        reader {
          uid
          name
          biography 
          image
        }
        location_room {
          id
          name
        }
        location_mask_friendly_hours {
          id
          created_by
          day
          time
          location_id
          created_at
          reading_id    
        }
      }
    }
  }  
}`;

export const QUERY_MAP_LOCATIONS_BY_BOX_DDAY = `
query map_locations_by_box (
  $lng_min: Float!,
  $lng_max: Float!,
  $lat_min: Float!,
  $lat_max: Float!,
  $dday: !timestamptz,
) {
  locations (
    where: { 
      longitude: {_gte: $lng_min, _lte: $lng_max}, 
      latitude: {_gte: $lat_min, _lte: $lat_max},
      created_at: {_gte: $dday}
    },
    limit: 200
  ) {
    id
    region_id
    type
    tag_id
    suite
    address
    postcode
    latitude
    longitude
    name
    description
    slug
    social_link
    last_co2
    op_readings
    op_imageupload
    op_moderation
    op_description
    op_comments
    reading_conf
    created_at
    created_by
    approved
    approved_at
    approved_by
    region {
      id
      locality
      place
      district
      region
      country
      slug
      created_at
      created_by      
    }
    readings {
      id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      reader {
        uid
        name
        biography 
        image
      }
      location_room {
        id
        name
      }
      location_mask_friendly_hours {
        id
        created_by
        day
        time
        location_id
        created_at
        reading_id    
      }
    }
    location_rooms {
      id
      name
      readings {
        id
        co2
        ach
        mask
        image
        gps_verified
        adjusted
        co2_in
        activity
        people_no
        room_volume
        hepa_cadr
        uvgi_cap
        comment
        recommends
        reading_at
        reading_by
        reader {
          uid
          name
          biography 
          image
        }
        location_room {
          id
          name
        }
        location_mask_friendly_hours {
          id
          created_by
          day
          time
          location_id
          created_at
          reading_id    
        }
      }
    }
  }
}`;

export const QUERY_MAP_LOCATIONS_FOR_LEADERBOARDS = `
query map_locations_for_leaderboards {
  locations(
    order_by: {last_co2: asc}, 
    limit: 100
  ) {
    id
    name
    type
    last_co2
    region {
      id
      country
      region
      place
    }
  }
}`;

export const QUERY_MAP_LOCATIONS_BY_REGION = `
query map_locations_by_region (
  $region_id: Int!
) {
  locations (
    where: { 
      region_id: {_eq: $region_id}
    }
  ) {
    id
  }
}`;

export const MUTATION_INSERT_MAP_LOCATION = `
mutation insert_map_location(
  $region_id: Int!,
  $type: Int!,
  $tag_id: Int,
  $suite: String,
  $address: String!,
  $postcode: String,
  $latitude: Float,
  $longitude: Float,
  $name: String!,
  $description: String,
  $slug: String!,
  $social_link: String,
  $last_co2: Int,
  $created_at: timestamptz,
  $created_by: String!,
  $approved: Boolean,
  $approved_at: timestamptz,
  $approved_by: String
) {
  insert_locations(
    objects: {
      region_id: $region_id,
      type: $type,
      tag_id: $tag_id,
      suite: $suite,
      address: $address,
      postcode: $postcode,
      latitude: $latitude,
      longitude: $longitude,
      name: $name,
      description: $description,
      slug: $slug,
      social_link: $social_link,
      last_co2: $last_co2,
      created_at: $created_at,
      created_by: $created_by,
      approved: $approved,
      approved_at: $approved_at,
      approved_by: $approved_by
    }
  ) {
    affected_rows
    returning {
      id
      region_id
      type
      tag_id
      suite
      address
      postcode
      latitude
      longitude
      name
      description
      slug
      social_link
      last_co2
      op_readings
      op_imageupload
      op_moderation
      op_description
      op_comments
      reading_conf
      created_at
      created_by
      approved
      approved_at
      approved_by
      region {
        id
        locality
        place
        district
        region
        country
        slug
        created_at
        created_by      
      }
    }
  }
}`;

export const MUTATION_UPDATE_MAP_LOCATION = `
mutation update_map_location(
  $id: Int!,
  $region_id: Int!,
  $type: Int!,
  $tag_id: Int,
  $suite: String,
  $address: String!,
  $postcode: String,
  $latitude: Float,
  $longitude: Float,
  $name: String!,
  $description: String,
  $slug: String!,
  $social_link: String,
  $approved: Boolean,
  $approved_at: timestamptz,
  $approved_by: String
) {
  update_locations(
    where: {
      id: {_eq: $id}, 
    }, 
    _set: {
      region_id: $region_id,
      type: $type,
      tag_id: $tag_id,
      suite: $suite,
      address: $address,
      postcode: $postcode,
      latitude: $latitude,
      longitude: $longitude,
      name: $name,
      description: $description,
      slug: $slug,
      social_link: $social_link,
      approved: $approved,
      approved_at: $approved_at,
      approved_by: $approved_by
    }
  ) {
    affected_rows
    returning {
      id
      region_id
      type
      tag_id
      suite
      address
      postcode
      latitude
      longitude
      name
      description
      slug
      social_link
      last_co2
      op_readings
      op_imageupload
      op_moderation
      op_description
      op_comments
      reading_conf
      created_at
      created_by
      approved
      approved_at
      approved_by
      region {
        id
        locality
        place
        district
        region
        country
        slug
        created_at
        created_by      
      }
      readings {
        id
        co2
        ach
        mask
        image
        gps_verified
        adjusted
        co2_in
        activity
        people_no
        room_volume
        hepa_cadr
        uvgi_cap
        comment
        recommends
        reading_at
        reading_by
        reader {
          uid
          name
          biography 
          image
        }
        location_room {
          id
          name
        }
        location_mask_friendly_hours {
          id
          created_by
          day
          time
          location_id
          created_at
          reading_id    
        }
      }
      location_rooms {
        id
        name
        readings {
          id
          co2
          ach
          mask
          image
          gps_verified
          adjusted
          co2_in
          activity
          people_no
          room_volume
          hepa_cadr
          uvgi_cap
          comment
          recommends
          reading_at
          reading_by
          reader {
            uid
            name
            biography 
            image
          }
          location_room {
            id
            name
          }
          location_mask_friendly_hours {
            id
            created_by
            day
            time
            location_id
            created_at
            reading_id    
          }
        }
      }
    }
  }
}`;

export const MUTATION_UPDATE_MAP_LOCATION_LAST_CO2 = `
mutation (
  $id: Int!,
  $co2: Int!
) {
  update_locations(
    where: { id: {_eq: $id} }, 
    _set: { last_co2: $co2 }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_MAP_LOCATION_OP_READINGS = `
mutation (
  $id: Int!,
  $readings: Boolean!
) {
  update_locations(
    where: { id: {_eq: $id} }, 
    _set: { op_readings: $readings }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_MAP_LOCATION_OP_IMAGEUPLOAD = `
mutation (
  $id: Int!,
  $imageupload: Boolean!
) {
  update_locations(
    where: { id: {_eq: $id} }, 
    _set: { op_imageupload: $imageupload }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_MAP_LOCATION_OP_MODERATION = `
mutation (
  $id: Int!,
  $moderation: Boolean!
) {
  update_locations(
    where: { id: {_eq: $id} }, 
    _set: { op_moderation: $moderation }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_MAP_LOCATION_OP_DESCRIPTION = `
mutation (
  $id: Int!,
  $description: Boolean!
) {
  update_locations(
    where: { id: {_eq: $id} }, 
    _set: { op_description: $description }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_MAP_LOCATION_OP_COMMENTS = `
mutation (
  $id: Int!,
  $comments: Boolean!
) {
  update_locations(
    where: { id: {_eq: $id} }, 
    _set: { op_comments: $comments }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_MAP_LOCATION_READINGCONF = `
mutation (
  $id: String!,
  $conf: Int!
) {
  update_locations(
    where: { id: {_eq: $id} }, 
    _set: { reading_conf: $conf }
  ) {
    affected_rows
    returning {
      id
      region_id
      type
      tag_id
      suite
      address
      postcode
      latitude
      longitude
      name
      description
      slug
      social_link
      last_co2
      op_readings
      op_imageupload
      op_moderation
      op_description
      op_comments
      reading_conf
      created_at
      created_by
      approved
      approved_at
      approved_by
      region {
        id
        locality
        place
        district
        region
        country
        slug
        created_at
        created_by      
      }
      readings {
        id
        co2
        ach
        mask
        image
        gps_verified
        adjusted
        co2_in
        activity
        people_no
        room_volume
        hepa_cadr
        uvgi_cap
        comment
        recommends
        reading_at
        reading_by
        reader {
          uid
          name
          biography 
          image
        }
        location_room {
          id
          name
        }
        location_mask_friendly_hours {
          id
          created_by
          day
          time
          location_id
          created_at
          reading_id    
        }
      }
      location_rooms {
        id
        name
        readings {
          id
          co2
          ach
          mask
          image
          gps_verified
          adjusted
          co2_in
          activity
          people_no
          room_volume
          hepa_cadr
          uvgi_cap
          comment
          recommends
          reading_at
          reading_by
          reader {
            uid
            name
            biography 
            image
          }
          location_room {
            id
            name
          }
          location_mask_friendly_hours {
            id
            created_by
            day
            time
            location_id
            created_at
            reading_id    
          }
        }
      }
    }
  }
}`;

export const MUTATION_DELETE_MAP_LOCATION = `
mutation delete_map_location(
  $id: Int!
) {
  delete_locations(
    where: { id: {_eq: $id} }
  ) {
    affected_rows
  }
}`;


export const QUERY_LOCATION_MODERATION_COUNT = `
query (
  $location_id: Int!
) {
  readings_aggregate(
    where: {
      location_id: {_eq: $location_id},
      approved: {_eq: false}
    }
  ) {
    aggregate {
      count
    }
  }
  reading_reports_aggregate(
    where: { 
      location_id: {_eq: $location_id},
      approved: {_eq: false}
    }
  ) {
    aggregate {
      count
    }
  }
  article_reports_aggregate(
    where: {
      location_id: {_eq: $location_id},
      approved: {_eq: false}
    }
  ) {
    aggregate {
      count
    }
  }
  location_moderator_reports_aggregate(
    where: { 
      location_id: {_eq: $location_id},
      approved: {_eq: false}
    }
  ) {
    aggregate {
      count
    }
  }
}`;

export const QUERY_LOCATION_MODERATION_FIELDS = `
query (
  $location_id: Int!,
  $location_id_str: String!,
  $after: timestamptz!,
  $logtype: Int!
) {
  location_reports(
    where: { 
      location_id: {_eq: $location_id}
    }
  ) {
    id
    location_id
    report
    reported_at
    reported_by
    approved
    approved_at
    approved_by
    reported_user {
      uid
      username
    }
  }
  readings(
    where: {
      location_id: {_eq: $location_id}
    }
  ) {
    id
    location_id
    co2
    ach
    mask
    image
    gps_verified
    adjusted
    co2_in
    activity
    people_no
    room_volume
    hepa_cadr
    uvgi_cap
    comment
    recommends
    reading_at
    approved
    approved_at
    approved_by
    reader {
      uid
      name
      biography 
      image
    }
    location_room {
      id
      name
    }
    location_mask_friendly_hours {
      id
      created_by
      day
      time
      location_id
      created_at
      reading_id    
    }
  }
  reading_reports(
    where: { 
      location_id: {_eq: $location_id}
    }
  ) {
    id
    report
    reported_at
    reported_by
    approved
    approved_at
    approved_by
    reported_user {
      uid
      username
    }
    reading{
      id
      location_id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      approved
      approved_at
      approved_by
      reader {
        uid
        name
        biography 
        image
      }
      location_room {
        id
        name
      }
    }
  }
  article_reports(
    where: {
      reported_at: {_gte: $after},
      location_id: {_eq: $location_id},
    }
  ) {
    id
    source_id
    location_id
    report
    reported_at
    reported_by
    approved
    approved_at
    approved_by
    reported_user {
      uid
      username
    }
    article {
      nid
      source_id
      location_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
    }
  }
  location_moderators (
    where: {
      location_id: {_eq: $location_id}}
  ) {
    id
    location_id
    created_at
    approved
    approved_at
    approved_by
    user {
      uid
      name
      username
      biography
      image
    }
  }
  location_followers(
    where: { 
      location_id: {_eq: $location_id} 
    }
  ) {
    id
    user_id
    location_id
    followed_at
    user {
      uid
      name
      username
      biography
      image
    }
  }
  location_moderator_reports(
    where: { 
      location_id: {_eq: $location_id}
    }
  ) {
    id
    moderator_id
    location_id
    report
    reported_at
    reported_by
    approved
    approved_at
    approved_by
    reported_user {
      uid
      username
    }
  }
  location_users_banned {
    id
    banned_at
    user {
      uid
      username
      image
      biography
    }
  }
  activitylogs (
    where: {
      type: {_eq: $logtype},
      type_id: {_eq: $location_id_str},
      logged_at: {_gte: $after}
    },
    order_by: {logged_at: desc}
  ) {
    id
    type
    type_id
    action
    object
    fromto
    reason
    logged_at
    user {
      uid
      username
    }
  }
}`;

export const MUTATION_UPDATE_LOCATION_NOTIFICATIONS = `
mutation (
  $location_id: Int!,
  $notifications: Int!
) {
  update_locations(
    where: { id: {_eq: $location_id} }, 
    _set: { notifications: $notifications }
  ) {
    affected_rows
  }
}`;

export const QUERY_MASK_FRIENDLY_HOURS = `
query mask_friendly_hours (
  $id: Int!
) {
  readings(
    where: {
      id: {_eq: $id}, 
    }
  ){
    co2_in
  }
  location_mask_friendly_hours(
    where: {
      reading_id: {_eq: $id}, 
    }
  ) {
    day
    time
  }
}`;

export const MUTATION_MASK_FRIENDLY_HOURS = `
mutation delete_location_mask_friendly_hours(
  $id: Int!
) {
  delete_location_mask_friendly_hours(
    where: { reading_id: {_eq: $id} }
  ) {
    affected_rows
  }
}`;