import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { 
  Button, 
  Typography, 
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withFirebase } from 'services';
import { withAuthentication } from "session";
import { 
  BasicAppBar,
  SearchInput, 
  DlgShare, 
  WaitingSpinner 
} from "components";
import * as ROUTES from "constants/routes";
import { GraphqlService } from "services";
import { 
  GRAPHQL_ERROR, 
  MAX_ARTICLE_WIDTH, 
  RAVEN_PLACEHOLDER_IMAGE 
} from "constants/types";
import { 
  getAuthToken,
  getFeedInfo,
  isBanned,
  inviteUser,
} from "dataapis";
import { summarize_text } from "utility/utils";
import { send_invitemsg } from "utility/ravenapi";
import { 
  ToastSuccess,
  ToastError 
} from "utility/toast";
import { isPlatform } from '@ionic/react';
import { v4 as uuidv4 } from 'uuid';
import { logger } from "utility/logging";

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  searchbox: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  banner: {
    margin: theme.spacing(2),
    textAlign: "center",
  },
  bannertitle: {
    width: 220,
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  bannerimage: {
    width: 260,
    marginLeft: "auto",
    marginRight: "auto",
  },
  buttoncontainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionbtn: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "20px",
    padding: "4px 10px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: 18,
    textTransform: "initial",
    // boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  actionicon: {
    padding: 4,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
  searchcontainer: {
    width: '100%',
    zIndex: 1100,
  },
  searchdiv: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  searchbtn: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    borderRadius: "8px",
    border: "1px solid",
    borderColor: theme.palette.text.secondary,
    padding: "4px 8px",
    width: `calc(100% - 32px)`,
    textTransform: "initial",
    marginLeft: 16,
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.secondary,
    }
  },
  content: {
    position: "relative",
    padding: theme.spacing(1)
  },
  sectiontitle: {
    display: "block",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
    color: theme.palette.text.primary,
    textTransform: "none",
  },
  useritem: {
    position: "relative",
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
  },
  image: {
    width: 40,
    height: 40,
  },
  contacttext: {
    paddingRight: 48,
    color: theme.palette.text.primary
  },
  name: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: "14px",
    fontWeight: 100,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  followicon: {
    width: 40,
    height: 40,
  },
  invitetext: {
    paddingRight: 92,
    color: theme.palette.text.primary
  },
  invitebtn: {
    backgroundColor: theme.palette.background.default,
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
  },
  inviteicon: {
    padding: 4,
    width: 20,
    height: 20,
    color: theme.palette.primary.contrastText,
  },
  invitedbtn: {
    backgroundColor: "#1878F3",
    borderRadius: "20px",
    padding: "4px 10px",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1878F3",
    },
  },
  allowdlg_text: {
    fontSize: 14,
  },
  raven: {
    display: "inline",
    fontWeight: 600,
  },
  allowdlg_button: {
    color: theme.palette.primary.contrastText,
  },
  skipbtn: {
    position: "absolute",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    }
  },
});


class FeedUserInvite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: "",
      searchOpen: true,
      raven_users: [],        // raven users by search
      showContacts: false,
      contact_users: [],      // users in my contact (mobile)
      allowDlg: false,
      showShareDlg: false,
      shareInfo: null,
    };

    this.handleNavBack = this.handleNavBack.bind(this);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchUser = this.handleSearchUser.bind(this);
    this.handleSearchCancel = this.handleSearchCancel.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleFollow = this.handleFollow.bind(this);
    
    this.handleInviteFollower = this.handleInviteFollower.bind(this);
    this.handleInviteFollowingUser = this.handleInviteFollowingUser.bind(this);
    this.handleInviteRavenUser  = this.handleInviteRavenUser.bind(this);

    this.handleClickAddContacts = this.handleClickAddContacts.bind(this);
    this.handleAllowContacts = this.handleAllowContacts.bind(this);
    this.handleCloseAllowDlg = this.handleCloseAllowDlg.bind(this);

    this.handleShare = this.handleShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
  }

  setError = message => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = waiting => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  componentDidMount = async () => {
    const { selected_feed, match } = this.props;

    this.setWaiting(true);

    let feed_slug =
      selected_feed !== null && selected_feed !== undefined
        ? selected_feed.slug
        : match.params.slug;
    if (!feed_slug) {
      const location = {
        pathname: ROUTES.HOME,
        state: { animation: "left" },
      };
      this.props.history.push(location);
      this.setWaiting(false);
      return;
    }
    await getFeedInfo(feed_slug);

    this.setWaiting(false);
  }

  handleNavBack = () => {
    this.props.history.goBack();
  };

  handleClickAddContacts = () => {
    this.setState({
      ...this.state,
      allowDlg: true
    });
  }

  handleAllowContacts = async () => {
    this.setState({
      ...this.state,
      allowDlg: false,
      showContacts: true
    });
  }

  handleCloseAllowDlg = () => {
    this.setState({
      ...this.state,
      allowDlg: false
    });
  }

  handleSearchChange = (event) => {
    this.setState({
      ...this.state,
      searchKey: event.target.value
    });
  }

  handleSearchUser = async (event) => {
    if (event.key !== "Enter") {
      return;
    }

    const { searchKey } = this.state;
    if (searchKey.length === 0) {
      return;
    }

    // get users by searchkey
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);
    const result = await gqlservice.users_by_searchkey(searchKey);
    if (result.status_code === GRAPHQL_ERROR) {
      return this.setError(result.msg);
    }
    this.setWaiting(false);

    let users = result.data.users.slice();
    users = users.filter(user => user.uid !== this.props.authUser.uid);
    if (users.length === 0) {
      return;
    }

    this.setState({
      ...this.state,
      searchOpen: false,
      raven_users: users
    });
  }

  handleSearchCancel = () => {
    this.setState({
      ...this.state,
      searchKey: "",
      searchOpen: true,
      raven_users: []
    });
  }


  handleShare = async () => {
    // register feed share info to the database
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    const { selected_feed, authUser } = this.props;

    let date = new Date();
    date.setDate(date.getDate() + 7);
    const share = {
      id: uuidv4(),
      feed_id: selected_feed.id,
      created_by: authUser.uid,
      expired_at: date.toISOString()
    };

    const result = await gqlservice.insert_feed_share(share);
    if (result.status_code === GRAPHQL_ERROR) {
      return this.setError(result.msg);
    }

    // make share link
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/share?id=${share.id}`;

    const shareInfo = {
      title: "Invite to our Raven Feed: " + selected_feed.name,
      description: selected_feed.description,
      image: selected_feed.image || RAVEN_PLACEHOLDER_IMAGE,
      hashtag: selected_feed.tags.join(),
      url: shareUrl,
    };

    // show share widget
    this.setState({
      ...this.state,
      showShareDlg: true,
      shareInfo: shareInfo
    });
  }

  handleCloseShare = () => {
    this.setState({
      ...this.state,
      showShareDlg: false
    });
  }

  handleSkip = () => {
    this.props.clearTempFeedSourceLinks();
    this.props.clearTempFeedSources();

    const { selected_feed } = this.props;

    let location = null;
    if (selected_feed) {
      const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
      location = {
        pathname: route,
        state: { animation: "left" },
      };
      this.props.history.push(location);
    } else {
      location = {
        pathname: ROUTES.HOME,
        state: { animation: "left" },
      };
    }
    this.props.history.push(location);
  }

  handleSearch = () => {
    const location = {
      pathname: ROUTES.CONTACTS,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  handleFollow = async (user) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    gqlservice.delete_banned_user(user.id)
      .then(result => {
        const new_users = this.state.contact_users.filter(banned_user => banned_user.id !== user.id);
        this.setState({
          ...this.state,
          contact_users: new_users
        });
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  handleInviteRavenUser = async (user) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    // send invite message using FCM (via ravenapi)
    const username = user.name || user.username;
    if (!user.msgToken) {
      logger.error("Can't invite non-registered user :", username)
      return;
    }

    const { authUser, selected_feed } = this.props;
    const invite_url = `${window.location.protocol}//${window.location.hostname}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/join`;
    const isSent = await send_invitemsg(user.msgToken, username, invite_url);
    if (!isSent) {
      this.setError("Failed to send invite");
      return;
    }
  
    this.setWaiting(true);
    await inviteUser(user.uid, authUser.uid, selected_feed.id, false);
    this.setWaiting(false);

    ToastSuccess("Invite sent");
  }

  handleInviteFollower = async (user) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    // send invite message using FCM (via ravenapi)
    const { authUser, selected_feed } = this.props;
    const invite_url = `${window.location.protocol}//${window.location.hostname}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/join`;

    const username = user.follower.name || user.follower.username;
    if (user.follower.msgToken) {
      const isSent = await send_invitemsg(user.follower.msgToken, username, invite_url);
      if (!isSent) {
        this.setError("Failed to send invite message!");
        // return;
      }
    }
  
    this.setWaiting(true);
    await inviteUser(user.follower_id, authUser.uid, selected_feed.id, false);
    this.setWaiting(false);

    ToastSuccess('Invite sent');
  }

  handleInviteFollowingUser = async (user) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    // send invite message using FCM (via ravenapi)
    const { authUser, selected_feed } = this.props;
    const invite_url = `${window.location.protocol}//${window.location.hostname}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/join`;

    const username = user.user.name || user.user.username;
    if (user.user.msgToken) {
      const isSent = await send_invitemsg(user.user.msgToken, username, invite_url);
      if (!isSent) {
        this.setError("Failed to send invite");
        // return;
      }
    }
  
    this.setWaiting(true);
    await inviteUser(user.user_id, authUser.uid, selected_feed.id, false);
    this.setWaiting(false);

    ToastSuccess('Invite sent');
  }

  _renderContactUsers = (users, classes, theme, onFollow) => {
    return (
      <List>
        {users.map((user, index) => 
          <ListItem className={classes.useritem} key={user.uid}>
            <ListItemAvatar>
              <Avatar className={classes.image} src={user.image} />
            </ListItemAvatar>
            <ListItemText
              id={user.uid}
              className={classes.contacttext}
              primary={
                <div className={"item"}>
                  <Typography className={classes.name}>
                    {user.name}
                  </Typography>
                  <Typography className={classes.label}>
                    {user.biography}
                  </Typography>
                </div>
              }
            />
            <ListItemSecondaryAction>
              <img
                className={classes.followicon}
                alt="Restore"
                src={`/static/images/icons/${theme}/unfollowing.png`}
                onClick={event => onFollow(user)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}        
      </List>
    );
  }

  
  _renderFollowMeUsers = (authUser, classes, theme, onInvite) => {
    const { selected_feed } = this.props;
    const users = authUser.user_followers.slice();

    const isInvited = (user) => {
      const result = authUser.users_invites.find(invited =>
        invited.invitee === user.follower_id && invited.invited_to === selected_feed.id
      ) !== undefined;
      logger.log("isInvited :", result, user);
      logger.log("authUser.users_invites :", authUser.users_invites);
      return result;
    }

    return (
      <List>
        {users.map((user, index) => 
          <ListItem className={classes.useritem} key={user.id}>
            <ListItemAvatar>
              <Avatar className={classes.image} src={user.follower.image} />
            </ListItemAvatar>
            <ListItemText
              id={user.follower_id}
              className={classes.invitetext}
              primary={
                <div className={"item"}>
                  <Typography className={classes.name}>
                    {user.follower.name}
                  </Typography>
                  <Typography className={classes.label}>
                    {summarize_text(user.follower.biography, 50)}
                  </Typography>
                </div>
              }
            />
            <ListItemSecondaryAction>
              {!isInvited(user) ? (
                  <Button
                    className={classes.invitebtn}
                    startIcon={
                      <img
                        className={classes.inviteicon}
                        alt="invite"
                        src={`/static/images/icons/${theme}/plus.png`}
                      />
                    }
                    onClick={e => onInvite(user)}
                  >
                    Invite
                  </Button>
                ):(
                  <Button className={classes.invitedbtn}>
                    Invited
                  </Button>
                )
              }
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    );
  }

  _renderFollowingUsers = (authUser, classes, theme, onInvite) => {
    const users = authUser.users_followed.slice();

    const isInvited = (user) => authUser.users_invites.find(invited =>
      invited.user_id === user.user_id
    ) !== undefined;

    return (
      <List>
        {users.map((user, index) => 
          <ListItem className={classes.useritem} key={user.id}>
            <ListItemAvatar>
              <Avatar className={classes.image} src={user.user.image} />
            </ListItemAvatar>
            <ListItemText
              id={user.user_id}
              className={classes.invitetext}
              primary={
                <div className={"item"}>
                  <Typography className={classes.name}>
                    {user.user.name}
                  </Typography>
                  <Typography className={classes.label}>
                    {summarize_text(user.user.biography, 50)}
                  </Typography>
                </div>
              }
            />
            <ListItemSecondaryAction>
              {!isInvited(user) ? (
                  <Button
                    className={classes.invitebtn}
                    startIcon={
                      <img
                        className={classes.inviteicon}
                        alt="invite"
                        src={`/static/images/icons/${theme}/plus.png`}
                      />
                    }
                    onClick={e => onInvite(user)}
                  >
                    Invite
                  </Button>
                ):(
                  <Button className={classes.invitedbtn}>
                    Invited
                  </Button>
                )
              }
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    );
  }

  _renderRavenUsers = (authUser, users, classes, theme, onInvite) => {

    const isInvited = (user) => authUser.users_invites.find(invited =>
      invited.user_id === user.uid
    ) !== undefined;

    return (
      <List>
        {users.map((user, index) => 
          <ListItem className={classes.useritem} key={user.uid}>
            <ListItemAvatar>
              <Avatar className={classes.image} src={user.image} />
            </ListItemAvatar>
            <ListItemText
              id={user.uid}
              className={classes.invitetext}
              primary={
                <div className={"item"}>
                  <Typography className={classes.name}>
                    {user.name}
                  </Typography>
                  <Typography className={classes.label}>
                    {summarize_text(user.biography, 50)}
                  </Typography>
                </div>
              }
            />
            <ListItemSecondaryAction>
              {!isInvited(user) ? (
                  <Button
                    className={classes.invitebtn}
                    startIcon={
                      <img
                        className={classes.inviteicon}
                        alt="invite"
                        src={`/static/images/icons/${theme}/plus.png`}
                      />
                    }
                    onClick={e => onInvite(user)}
                  >
                    Invite
                  </Button>
                ):(
                  <Button className={classes.invitedbtn}>
                    Invited
                  </Button>
                )
              }
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    );
  }

  render() {
    const { classes, theme_mode, authUser, requesting } = this.props;
    const 
    { 
      searchOpen,
      searchKey,
      raven_users,
      showContacts,
      contact_users,
      allowDlg,
      showShareDlg,
      shareInfo
    } = this.state;

    // let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    // const skipButtonPos = (width - 260) / 2;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Invite"}
            width={MAX_ARTICLE_WIDTH}
            action={"Skip"}
            onNavBack={this.handleNavBack}
            onAction={this.handleSkip}
          />
        </div>

        <div className={classes.searchbox}>
          <SearchInput
            open={searchOpen}
            searchKey={searchKey}
            onChange={this.handleSearchChange}
            onSearch={this.handleSearchUser}
            onCancel={this.handleSearchCancel}
          />
        </div>

        {searchOpen &&
          <div>
            <div className={classes.banner}>
              <Typography className={classes.bannertitle}>
                {"Invite your friends to Raven"}
              </Typography>
              <img 
                className={classes.bannerimage}
                alt="banner"
                src="/static/images/site/invite.png"
              />
            </div>

            <div className={classes.buttoncontainer}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    className={classes.actionbtn}
                    startIcon={
                      <img
                        className={classes.actionicon}
                        alt="add"
                        src={`/static/images/icons/${theme_mode}/plus.png`}
                      />
                    }
                    onClick={this.handleClickAddContacts}
                  >
                    {"Add your contacts"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.actionbtn}
                    startIcon={
                      <img
                        className={classes.actionicon}
                        alt="share"
                        src={`/static/images/icons/${theme_mode}/share1.png`}
                      />
                    }
                    onClick={this.handleShare}
                  >
                    {"Share"}
                  </Button>
                </Grid>
              </Grid>
            </div>

            {showContacts && (isPlatform("android") || isPlatform("ios")) && 
            <div className={classes.searchcontainer}>
              <div className={classes.searchdiv}>
                <Button
                  className={classes.searchbtn}
                  style={{justifyContent: 'left'}}
                  onClick={this.handleSearch}
                >
                  Search for someone
                </Button>
              </div>
            </div>
            }

            <div className={classes.content}>
              {showContacts &&
                <Button className={classes.sectiontitle}>{`${contact_users.length} Contacts on Raven`}</Button>
              }
              {showContacts && this._renderContactUsers(contact_users, classes, theme_mode, this.handleFollow)}

              <Button className={classes.sectiontitle}>{"Add People Who Follow You"}</Button>
              {this._renderFollowMeUsers(authUser, classes, theme_mode, this.handleInviteFollower)}

              <Button className={classes.sectiontitle}>{"Add People You Follow"}</Button>
              {this._renderFollowingUsers(authUser, classes, theme_mode, this.handleInviteFollowingUser)}
            </div>
          </div>
        }

        {!searchOpen &&
          <div className={classes.content}>
            {this._renderRavenUsers(authUser, raven_users, classes, theme_mode, this.handleInviteRavenUser)}
          </div>
        }

        {/* <Button
          className={classes.skipbtn}
          style={{left: skipButtonPos}}
          onClick={this.handleSkip}
        >
          Skip
        </Button> */}

        <Dialog
          className={classes.allowdlg}
          open={allowDlg}
          onClose={this.handleCloseAllowDlg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className={classes.allowdlg_text}>
              Allow <Typography className={classes.raven}>Raven</Typography> to access your contacts?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.allowdlg_button} onClick={this.handleCloseAllowDlg}>
              Deny
            </Button>
            <Button className={classes.allowdlg_button} onClick={this.handleAllowContacts} autoFocus>
              Allow
            </Button>
          </DialogActions>
        </Dialog>
        {shareInfo !== null &&
          <DlgShare
            open={showShareDlg}
            shareInfo={shareInfo}
            onClose={this.handleCloseShare}
          />
        }
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedUserInvite);
