import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, CardHeader, Dialog, IconButton, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { MAX_CARD_WIDTH, MIN_CARD_WIDTH } from "constants/types";
import { withRouter } from "react-router";

const styles = (theme) => ({
    root: {
        width: "95%",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.card,
        borderRadius: 32,
        textTransform: "inherit",
        textAlign: "left",
        margin: 'auto',
        marginBottom: 6,
    },
    button: {
        background: 'transparent',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '6px 16px',
    },
    icon: {
        width: 48,
        height: 48,
        borderRadius: 32,
        marginLeft: -8
    },
    startIcon: {
        marginLeft: -theme.spacing(1),
        marginRight: 0,
    },
    text: {
        marginLeft: theme.spacing(2),
        textAlign: 'initial',
    },
    title: {
        display: "block",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "20px",
        letterSpacing: "0.66px",
    },
    description: {
        display: "block",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.66px",
    },
    modal: {
        border: "2px solid black",
        borderRadius: 20,
        minWidth: MIN_CARD_WIDTH - 18,
        maxWidth: MAX_CARD_WIDTH - 18,
        backgroundColor: theme.palette.background.default,
        width: "100%",
        padding: 0,
        boxShadow: theme.shadows[10],
        height: "auto",
        "@media (max-width: 200px)": {
            width: "100%",
        },
    },
    customText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "2rem",
        lineHeight: 1.2,
        fontWeight: "normal",
    },
    sponsoredText: {
        fontSize: "2rem",
        lineHeight: 1.6,
        fontWeight: "normal",
        justifyContent: "center",
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(1),
    },
    titleSection: {
        display: 'flex'
    },
    heading: {
        marginTop: 10,
        textAlign: "center",
        flex: 1,
    },
    actionbtns: {
        display: 'flex',
        marginRight: "-8px"
    },
    close_img: {
        width: 30,
        height: 30,
        marginLeft: "10px",
    },
    image: {
        marginRight: "10px",
        width: 48,
        height: 48,
        borderRadius: 32,
    },
    cardContent: {
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(1),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: "-9px",
    },
    postButton: {
        fontSize: "1rem",
        width: "100%",
        backgroundColor: "#1878F3",
        color: "#FFFFFF",
        borderRadius: 25,
        margin: theme.spacing(1),
        textTransform: "initial",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#1878F3",
          color: "#FFFFFF",
        },
        "@media screen and (-webkit-min-device-pixel-ratio: 0)": {
          width: "-webkit-fill-available",
        },
      },
});

class Sponsorship extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginDlg: false,
        };
    }

    render() {
        const { classes, disabled, authUser, article, open, isDisabled,expanded, ifSponsored, toggleCancelSponsorship, toggleSponsorship, handleCloseModal, toggleExpanded, theme_mode } = this.props;
        const title = ifSponsored ? "Sponsored" : "Sponsor"
        const description = ifSponsored ? "You sponsor this content" : "Chip in for more summaries"
        const text1 = "Help cover the costs of transcribing this content. Get work by your favorite content creators reach a wider audience!"
        const text2 = "Cost: 1 credit for every hour of audio transcribed, 0.1 for other content, deducted from your account"

        return (
            <div >
                <div className={classes.root}>
                    <button
                        variant="contained"
                        className={classes.button}
                        onClick={toggleExpanded}
                        disabled={disabled}
                    >
                        <img className={classes.icon} alt='' src={`/static/images/icons/${theme_mode}/sponsor.png`} />
                        <div className={classes.text}>
                            <Typography className={classes.title}>{title}</Typography>
                            <Typography className={classes.description}>
                                {description}
                            </Typography>
                        </div>
                    </button>
                </div>
                {expanded &&
                    <Dialog
                        open={expanded}
                        onClose={handleCloseModal}
                        PaperProps={{ className: classes.modal, }}
                    >
                        <Card>
                            <CardHeader
                                className={classes.header}
                                title={
                                    <div className={classes.titleSection}>
                                        <img className={classes.image} alt='' src={`/static/images/icons/${theme_mode}/sponsor.png`} />
                                        <Typography variant="h4" className={classes.heading}>
                                            Sponsorship
                                        </Typography>
                                    </div>
                                }
                                action={
                                    <IconButton
                                        onClick={handleCloseModal}
                                        color="primary"
                                        variant="contained"
                                        className={classes.closeButton}
                                    >
                                        <img
                                            className={classes.close_img}
                                            alt="copy link"
                                            src={`/static/images/dismiss.png`}
                                        />
                                    </IconButton>
                                }
                            />
                            <div className={classes.cardContent}>
                                {ifSponsored ? (
                                    <div >
                                        <Typography className={classes.customText} align="center">
                                            Thank you for sponsoring this content!
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.postButton}
                                            onClick={toggleCancelSponsorship}
                                            disabled={isDisabled}
                                        >
                                            Cancel Sponsorship
                                        </Button>
                                    </div>
                                ) : (
                                    <div>
                                        <Typography variant="body1">
                                            {text1}
                                        </Typography>
                                        <Typography variant="body1" style={{ marginTop: '10px' }}>
                                            {text2}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.postButton}
                                            onClick={toggleSponsorship}
                                            disabled={isDisabled}
                                        >
                                            Sponsor
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </Dialog>
                }
            </div>
        );
    }
}

Sponsorship.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    expanded: PropTypes.bool,
    ifSponsored: PropTypes.bool,
    description: PropTypes.string,
    handleCloseModal: PropTypes.func,
    toggleExpanded: PropTypes.func,
    toggleCancelSponsorship: PropTypes.func,
    toggleSponsorship: PropTypes.func,
    theme_mode: PropTypes.string,
    isDisabled:PropTypes.bool
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  sources: state.dataState.sources,
  selected_feed: state.dataState.selected_feed,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Sponsorship)));
