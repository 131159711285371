import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  BasicAppBar,
} from "components";
import {
  withFirebase,
} from "services";
import {
  MAX_WINDOW_WIDTH,
  MIN_TABLET_WIDTH,
} from "constants/types";
import { 
  withAuthentication, 
  withAuthorization, 
} from "session";
import { MemberItem } from "views/FeedMembers/components";
import { List, Toolbar } from "@material-ui/core";
import { ToastError } from "utility/toast";
import * as ROUTES from "constants/routes";
import { followUser, isBanned, unfollowUser } from "dataapis";

const condition = (authUser) => !!authUser && authUser.uid !== ""

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 10,
  },
  membercontainer: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(5),
    padding: 0,
    backgroundColor: theme.palette.background.default
  },
  avatar: {
    margin: 0,
    padding: 1,
    width: 25,
    height: 25,
    // marginLeft: theme.spacing(2),
  },
  logo_text: {
    marginLeft: theme.spacing(0.5),
    marginTop: 0.5,
    height: 22,
    width: "auto",
  },
  toolbar:{
    marginLeft: theme.spacing(2),
  }
})

class ProfileFollowing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginDlg: false,
      postDlg: false,
      article_edit: null,
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleClickFollow = this.handleClickFollow.bind(this);
    this.handleClickUnfollow = this.handleClickUnfollow.bind(this);
    this.handleClickMember = this.handleClickMember.bind(this);
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  handleNavBack = () => {
    // const location = {
    //   pathname: ROUTES.PROFILE,
    //   state: { animation: "right" },
    // };
    // this.props.history.push(location);
    this.props.history.goBack();
  };

  handleClickFollow = async (member) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await followUser(member.user_id);
    this.setWaiting(false);

    this.updateUsers();
  }

  handleClickUnfollow = async (member) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await unfollowUser(member.user_id);
    this.setWaiting(false);

    this.updateUsers();
  }

  updateUsers = () => {

  }

  handleClickMember = member => {
    if (member.user.username === null || member.user.username.length === 0) {
      return;
    }
    let route = `/${ROUTES.USER_PREFIX}/${member.user.username}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  render() {

    const {
      classes,
      authUser,
      loggedIn,
      theme_mode,
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_WINDOW_WIDTH}
            title={"Following"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <Toolbar className={classes.toolbar}>
          <img
            alt={"Raven"}
            src={`/static/images/logo/${theme_mode}/logo.png`}
            className={classes.avatar}
          />
          <img
            alt={"Raven"}
            src={`/static/images/logo/${theme_mode}/logo_text.png`}
            className={classes.logo_text}
          />
        </Toolbar>
        <div className={classes.membercontainer}>
          <List>
            {authUser.users_followed.map(member => (
              <MemberItem
                key={member.user_id}
                member={member}
                loggedIn={loggedIn}
                following={true}
                theme_mode={theme_mode}
                onClicked={this.handleClickMember}
                onFollowed={this.handleClickFollow}
                onUnfollowed={this.handleClickUnfollow}
              />
            ))}
          </List>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  followed_feeds: state.dataState.followed_feeds,
  my_posts: state.dataState.my_posts,
  posts_backroute: state.uiState.posts_backroute,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ProfileFollowing);
