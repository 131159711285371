import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withFirebase } from "services";
import { withAuthentication, withAuthorization } from "session";
import { withStyles } from "@material-ui/core/styles";
import { List, Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { LogoAppBar, WaitingSpinner } from "components";
import { FeedItem } from "./components";
import * as ROUTES from "constants/routes";
import { MAX_WINDOW_WIDTH, PAID_FEED_FOLLOW_LIMIT } from "constants/types";
import { GraphqlService } from "services";
import { followFeed, getAuthToken, isBanned } from "dataapis";
import { ToastError, ToastInfo } from "utility/toast";
import { logger } from "utility/logging";

const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  container: {
    width: "100%",
    paddingLeft: 16,
    paddingRight: 8,
    backgroundColor: theme.palette.background.default,
  },
  bottomspace: {
    paddingBottom: theme.spacing(8),
  },
  applybtn: {
    position: "fixed",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
});

class OnboardingConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };

    this.handleToggleFeed = this.handleToggleFeed.bind(this);
    this.handleFollowFeeds = this.handleFollowFeeds.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
  }

  componentDidMount() {
    // const { onboarding_feeds } = this.props;
    // this.setState({
    //   ...this.state,
    //   selected: onboarding_feeds
    // });
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleSkip = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleToggleFeed = (feed_id) => {
    if (this.props.requesting) {
      return;
    }
    
    logger.log("handleToggleFeed :", feed_id);
    const { onboarding_feeds } = this.props;
    const { selected } = this.state;

    if (selected.length > PAID_FEED_FOLLOW_LIMIT) {
      ToastInfo("You have reached maximum feeds to follow");
      return;
    }

    let new_selected = selected.slice();
    if (selected.find((item) => item.id === feed_id)) {
      new_selected = new_selected.filter((item) => item.id !== feed_id);
    } else {
      const feed = onboarding_feeds.find((feed) => feed.id === feed_id);
      new_selected.push(feed);
    }

    this.setState({
      ...this.state,
      selected: new_selected,
    });
  };

  handleFollowFeeds = async () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { authUser, followed_feeds } = this.props;
    const { selected } = this.state;

    let feeds2follow = selected.slice();
    feeds2follow = feeds2follow.filter(
      (feed) => feed.created_by !== authUser.uid
    );
    feeds2follow = feeds2follow.filter(
      (feed) => followed_feeds.find((item) => item.id === feed.id) === undefined
    );
    if (feeds2follow.length > 0) {
      const gqlservice = new GraphqlService();
      const token = await getAuthToken();
      if (!token) {
        this.props.onLogin();
        return;
      }
      gqlservice.set_auth_jwt(token);

      for (let i = 0; i < feeds2follow.length; i++) {
        await followFeed(feeds2follow[i]);
      }
    }

    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  render() {
    const { classes, theme_mode, requesting, onboarding_feeds } = this.props;
    const { selected } = this.state;

    const hasSelected = (feed_id) => {
      const result = selected.find((item) => item.id === feed_id);
      if (!result) {
        return false;
      }
      return true;
    };

    logger.log("onboarding confirm selected :", selected);

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    const applyButtonPos = (width - 260) / 2;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <LogoAppBar theme_mode={theme_mode} onSkip={this.handleSkip} />
        </div>

        <div className={classes.container}>
          <List>
            {onboarding_feeds.map((feed, index) => (
              <FeedItem
                key={feed.id}
                feed={feed}
                checked={hasSelected(feed.id)}
                onToggled={this.handleToggleFeed}
              />
            ))}
          </List>
        </div>

        <div className={classes.bottomspace}></div>

        <Button
          className={classes.applybtn}
          style={{ left: applyButtonPos }}
          disabled={requesting}
          onClick={this.handleFollowFeeds}
        >
          Follow
        </Button>

        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

OnboardingConfirm.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  categories: state.dataState.categories,
  followed_feeds: state.dataState.followed_feeds,
  onboarding_feeds: state.dataState.onboarding_feeds,
  tags: state.dataState.tags,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withRouter,
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OnboardingConfirm);
