import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { 
  THEME_MODE_DARK, 
  TAB_LBOARD_VOLUNTEERS, 
  TAB_LBOARD_REGIONS, 
  TAB_LBOARD_BEST,
  MAX_ARTICLE_WIDTH 
} from "constants/types";

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    padding: 0
  },
  tabs: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: 18,
    // maxWidth: MAX_ARTICLE_WIDTH - 32,
    borderRadius: 12,
    backgroundColor: theme.palette.background.light,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`
  },
  indicator_light: {
    backgroundColor: "#1878f3",
  },
  indicator_dark: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  tab_light: {
    padding: 0,
    minWidth: 120,
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#1878f3",
  },
  tab_dark: {
    padding: 0,
    minWidth: 120,
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  icon: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    margin: 0,
    width: 16,
    height: 16
  }
});

class LeadeerboardTabs extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  handleChangeTab = (event, newValue) => {
    const { leaderboardtab } = this.props;
    if (newValue === null) return;
    if (leaderboardtab === newValue) return;

    this.props.onChangeTab(newValue);
  };

  render() {
    const { classes, leaderboardtab, theme_mode } = this.props;

    const volunteers_tab = { value: TAB_LBOARD_VOLUNTEERS, name: "Volunteers", icon: "members.png" };
    const regions_tab = { value: TAB_LBOARD_REGIONS, name: "Regions", icon: "public.png" };
    const best_tab = { value: TAB_LBOARD_BEST, name: "Best", icon: "trophy-cup.png" };

    let classes_indicator = theme_mode === THEME_MODE_DARK ? classes.indicator_dark : classes.indicator_light;
    let classes_tab = theme_mode === THEME_MODE_DARK ? classes.tab_dark : classes.tab_light;

    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const tabsWidth = width > MAX_ARTICLE_WIDTH ? MAX_ARTICLE_WIDTH - 32 : width - 32;

    return (
      <div className={classes.root}>
        <Tabs
          className={classes.tabs}
          classes={{ indicator: classes_indicator }}
          value={leaderboardtab}
          onChange={this.handleChangeTab}
          variant={width > MAX_ARTICLE_WIDTH ? "fullWidth": "scrollable"}
          style={{ width: tabsWidth}}
        >
          <Tab
            value={volunteers_tab.value}
            key={volunteers_tab.value}
            label={volunteers_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={volunteers_tab.name}
                src={`/static/images/icons/${theme_mode}/${volunteers_tab.icon}`}
                className={classes.icon}
              />
            }
          />
          <Tab
            value={regions_tab.value}
            key={regions_tab.value}
            label={regions_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={regions_tab.name}
                src={`/static/images/icons/${theme_mode}/${regions_tab.icon}`}
                className={classes.icon}
              />
            }
          />
          <Tab
            value={best_tab.value}
            key={best_tab.value}
            label={best_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={best_tab.name}
                src={`/static/images/icons/${theme_mode}/${best_tab.icon}`}
                className={classes.icon}
              />
            }
          />
        </Tabs>
      </div>
    );
  }
}

LeadeerboardTabs.propTypes = {
  classes: PropTypes.object,
  onChangeTab: PropTypes.func
};

const mapStateToProps = (state) => ({
  theme_mode: state.uiState.theme_mode,
  leaderboardtab: state.mapState.leaderboardtab
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(LeadeerboardTabs);
