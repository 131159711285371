import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

class PopMenuSource extends React.Component {
  render() {
    const { 
      classes, 
      theme, 
      loggedIn, 
      follow, 
      showrt, 
      onReport, 
      onCopyLink, 
      onShow,
      onShowRetweet
    } = this.props;

    return (
      <div>
        <MenuList className={classes.menu_list}>
          <MenuItem className={classes.menu_item} onClick={onReport}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"report"}
                src={`/static/images/icons/${theme}/warning.png`}
              />
            </span>
            Report Source
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onCopyLink}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"copylink"}
                src={`/static/images/icons/${theme}/link.png`}
              />
            </span>
            Copy Link to Source
          </MenuItem>
          {loggedIn &&
            <MenuItem className={classes.menu_item} onClick={onShow}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={follow ? "hide" : "show"}
                  src={follow ? `/static/images/icons/${theme}/invisible.png` : `/static/images/icons/${theme}/eye.png`}
                />
              </span>
              {follow ? "Hide Source" : "Show Source"}
            </MenuItem>
          }
          {showrt !== undefined &&
            <MenuItem className={classes.menu_item} onClick={onShowRetweet}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"showRT"}
                  src={`/static/images/icons/${theme}/twitter.png`}
                />
              </span>
              {showrt ? "Hide Replies/RTs" : "Show Replies/RTs"}
            </MenuItem>
          }
        </MenuList>
      </div>
    );
  }
}

PopMenuSource.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  loggedIn: PropTypes.bool,
  follow: PropTypes.bool,
  showrt: PropTypes.bool,
  onCheckClicked: PropTypes.func,
  onItemClicked: PropTypes.func,
  onShow: PropTypes.func,
  onShowRetweet: PropTypes.func
};

export default withStyles(styles)(PopMenuSource);
