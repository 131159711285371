import { GraphQLClient } from "graphql-request";
import {
  GRAPHQL_SUCCESS,
  GRAPHQL_ERROR,
  ARTICLES_PER_PAGE,
} from "constants/types";
import { logger } from "utility/logging";
import {
  /* #region  BaseData */
  QUERY_BASE_DATA,
  // QUERY_BASE_DATA_USER,
  // QUERY_BASE_DATA_PAIDUSER,
  QUERY_LISTS,
  /* #endregion */
  /* #region  Category Moderation */
  QUERY_CATEGORY_MODERATION_FIELDS,
  QUERY_CATEGORY_MODERATION_COUNT,
  QUERY_CATEGORY_MODERATORS_BY_CATEGORY,
  MUTATION_INSERT_CATEGORY_MODERATOR,
  MUTATION_DELETE_CATEGORY_MODERATOR,
  MUTATION_RESIGN_CATEGORY_MODERATOR,
  MUTATION_UPDATE_CATEGORY_MODERATOR,
  MUTATION_UPDATE_CATEGORY_LASTVIEWLOG,
  MUTATION_UPDATE_CATEGORY_NOTIFICATIONS,
  /* #endregion */
  /* #region  Feed Moderation */
  QUERY_SELECT_FEEDS,
  QUERY_CATEGORY_FEEDS,
  QUERY_FEEDS_BY_SEARCHKEY,
  QUERY_FEED_BY_ID,
  QUERY_FEED_BY_SLUG,
  QUERY_FEED_EXIST_BY_ID,
  QUERY_FEED_EXIST_BY_SLUG,
  QUERY_FEED_MODERATION_COUNT,
  QUERY_FEED_MODERATION_FIELDS,
  QUERY_FEED_MEMBERS,
  QUERY_DEFAULT_FEEDS,
  /* #endregion */
  /* #region  Feed Operations */
  MUTATION_INSERT_FEED,
  MUTATION_UPDATE_FEED,
  MUTATION_DELETE_FEED,
  MUTATION_INSERT_FEEDS,
  MUTATION_DELETE_FEEDS,
  MUTATION_UPDATE_FEED_APPROVED,
  MUTATION_UPDATE_FEED_FOLLOWERS,
  MUTATION_UPDATE_FEED_COMMENTCONF,
  MUTATION_UPDATE_FEED_LASTVIEWLOG,
  MUTATION_UPDATE_FEED_OP_VISIBILITY,
  MUTATION_UPDATE_FEED_AI_MODERATION,
  MUTATION_UPDATE_FEED_OP_COMMENTS,
  MUTATION_UPDATE_FEED_OP_POSTS,
  MUTATION_UPDATE_FEED_OP_MEMBERS,
  MUTATION_UPDATE_FEED_OP_PAYMENT,
  MUTATION_UPDATE_FEED_OP_ANONYMITY,
  MUTATION_UPDATE_FEED_TG_WALLET,
  MUTATION_UPDATE_FEED_TG_ADDRESS,
  MUTATION_UPDATE_FEED_TG_AMOUNT,
  MUTATION_UPDATE_FEED_TOKEN_ADDRESS,
  MUTATION_UPDATE_FEED_TOKEN_AMOUNT,
  MUTATION_UPDATE_FEED_TWITTER,
  MUTATION_UPDATE_FEED_INSTAGRAM,
  MUTATION_UPDATE_FEED_TIKTOK,
  MUTATION_UPDATE_FEED_YOUTUBE,
  MUTATION_UPDATE_FEED_NOTIFICATIONS,
  /* #endregion */
  /* #region  Feed Followers */
  QUERY_FEED_FOLLOWERS_BY_UID,
  QUERY_FEED_FOLLOWERS_BY_FEED,
  MUTATION_INSERT_FEED_FOLLOWER,
  MUTATION_DELETE_FEED_FOLLOWER,
  /* #endregion */
  /* #region  Feed Order */
  QUERY_FEED_ORDER_BY_USER,
  MUTATION_INSERT_FEED_ORDER,
  MUTATION_INSERT_FEED_ORDERS,
  MUTATION_UPDATE_FEED_ORDER,
  MUTATION_DELETE_FEED_ORDER,
  /* #endregion */
  /* #region  Feed Subscribers */
  QUERY_FEED_SUBSCRIBERS_BY_UID,
  QUERY_FEED_SUBSCRIBERS_BY_FEED,
  MUTATION_INSERT_FEED_SUBSCRIBER,
  MUTATION_DELETE_FEED_SUBSCRIBER,
  MUTATION_DELETE_FEED_SUBSCRIBERS,
  /* #endregion */
  /* #region  Feed Moderators */
  QUERY_FEED_MODERATORS_BY_FEED,
  QUERY_FEED_MODERATOR,
  MUTATION_INSERT_FEED_MODERATOR,
  MUTATION_DELETE_FEED_MODERATOR,
  MUTATION_RESIGN_FEED_MODERATOR,
  MUTATION_UPDATE_FEED_MODERATOR,
  /* #endregion */
  /* #region  Feed Moderator Reports */
  QUERY_MODERATOR_REPORTS_BY_FEED,
  MUTATION_INSERT_MODERATOR_REPORT,
  MUTATION_UPDATE_MODERATOR_REPORT,
  MUTATION_DELETE_MODERATOR_REPORT,
  MUTATION_DELETE_MODERATOR_REPORTS,
  /* #endregion */
  /* #region  Feed Reports */
  QUERY_FEED_REPORTS_BY_FEED,
  MUTATION_INSERT_FEED_REPORT,
  MUTATION_DELETE_FEED_REPORT,
  MUTATION_UPDATE_FEED_REPORT,
  /* #endregion */
  /* #region  Feed Posts */
  QUERY_FEED_POSTS,
  QUERY_FEED_POST,
  MUTATION_INSERT_FEED_POST,
  MUTATION_DELETE_FEED_POST,
  MUTATION_UPDATE_FEED_POST,
  /* #endregion */
  /* #region  Feed Comments */
  QUERY_FEED_COMMENTS,
  QUERY_FEED_COMMENT,
  MUTATION_INSERT_FEED_COMMENT,
  MUTATION_DELETE_FEED_COMMENT,
  MUTATION_UPDATE_FEED_COMMENT,
  /* #endregion */
  /* #region  Feed Sources */
  QUERY_FEED_SOURCES_BY_FEED,
  MUTATION_INSERT_FEED_SOURCES,
  MUTATION_DELETE_FEED_SOURCE,
  MUTATION_UPDATE_FEED_SOURCE,
  MUTATION_UPDATE_FEED_SOURCE_KEYWORD,
  MUTATION_INSERT_FEED_SOURCE_CHANGES,
  QUERY_FEED_SOURCE_UNFOLLOWERS,
  QUERY_FEED_SOURCE_UNFOLLOWERS_BY_FEED,
  MUTATION_INSERT_FEED_SOURCE_UNFOLLOWER,
  MUTATION_DELETE_FEED_SOURCE_UNFOLLOWER,
  QUERY_FEED_SOURCE_SHOWRETWEETS,
  QUERY_FEED_SOURCE_SHOWRETWEETS_BY_FEED,
  MUTATION_INSERT_FEED_SOURCE_SHOWRETWEET,
  MUTATION_DELETE_FEED_SOURCE_SHOWRETWEET,
  /* #endregion */
  /* #region  Feed share */
  QUERY_FEED_SHARE_BY_ID,
  QUERY_FEED_SHARE_BY_FEED,
  MUTATION_INSERT_FEED_SHARE,
  MUTATION_DELETE_FEED_SHARE,
  /* #endregion */
  /* #region  Feed Articles */
  QUERY_FEED_ARTICLES,
  QUERY_FEED_ARTICLES_OF_BRANCH,
  QUERY_FEED_ARTICLES_OF_COUNTRY,
  MUTATION_INSERT_FEED_ARTICLE,
  QUERY_FEED_ARTICLES_IN_FEEDS,
  QUERY_FEED_ARTICLES_OF_BRANCH_IN_FEEDS,
  QUERY_FEED_ARTICLES_OF_COUNTRY_IN_FEEDS,
  /* #endregion */
  /* #region  Feed videocasts */
  QUERY_VIDEOCASTS_BY_FEED,
  QUERY_VIDEOCASTS_ALL_IN_FEEDS,
  QUERY_VIDEOCASTS_IN_FEEDS,
  MUTATION_INSERT_FEED_VIDEOCAST,
  MUTATION_DELETE_FEED_VIDEOCAST,
  /* #endregion */
  /* #region  Source Operations */
  QUERY_SOURCES,
  QUERY_SOURCE_BY_ID,
  QUERY_SOURCE_AND_FEED_SOURCES_BY_ID,
  QUERY_SOURCE_BY_SLUG,
  QUERY_EXIST_SOURCE_BY_ID,
  QUERY_EXIST_SOURCE_BY_SLUG,
  MUTATION_INSERT_SOURCE,
  MUTATION_UPDATE_SOURCE,
  MUTATION_DELETE_SOURCE,
  QUERY_SOCIALTAG,
  GET_SOURCE_BY_SOCIALTAG,
  MUTATION_INSERT_SOCIALTAG,
  MUTATION_UPDATE_SOCIALTAG,
  MUTATION_DELETE_SOCIALTAG,
  MUTATION_UPDATE_SOURCE_THROTTLE,
  MUTATION_UPDATE_SOURCE_LASTUPDATED,
  MUTATION_UPDATE_SOURCE_TRANSLATE,
  /* #endregion */
  /* #region  Source Upvotes */
  QUERY_SOURCE_VOTER,
  MUTATION_SOURCE_INC_UPVOTES,
  MUTATION_SOURCE_DEC_UPVOTES,
  /* #endregion */
  /* #region  Source Followers */
  QUERY_SOURCE_FOLLOWERS_BY_SOURCE,
  MUTATION_INSERT_SOURCE_FOLLOWER,
  MUTATION_DELETE_SOURCE_FOLLOWER,
  MUTATION_UPDATE_SOURCE_FOLLOWERS,
  /* #endregion */
  /* #region  Source Reports */
  QUERY_SOURCE_REPORTS_BY_SOURCE,
  QUERY_SOURCE_REPORTS_BY_SOURCES,
  MUTATION_INSERT_SOURCE_REPORT,
  MUTATION_DELETE_SOURCE_REPORTS,
  MUTATION_DELETE_SOURCE_REPORT,
  MUTATION_UPDATE_SOURCE_REPORT,
  /* #endregion */
  /* #region  Article Operations */
  QUERY_ARTICLE_BY_NID,
  QUERY_ARTICLES,
  QUERY_ARTCILES_WITH_SUMMARY,
  QUERY_PROFILE_ARTICLES,
  QUERY_ARTICLE_REPOSTS,
  QUERY_ALL_ARTICLE_REPOSTS,
  QUERY_ARTICLES_EQ_BRANCH,
  QUERY_ARTICLES_EQ_SOURCE,
  QUERY_NEWSPAPERS_EQ_COUNTRY,
  QUERY_ARTICLES_IN_SOURCES,
  QUERY_ARTICLES_IN_SOURCES_NULLS_LAST,
  QUERY_ARTICLES_IN_SOURCES_OF_BRANCH,
  QUERY_ARTICLES_IN_SOURCES_OF_BRANCH_NULLS_LAST,
  QUERY_NEWSPAPERS_IN_SOURCES_OF_COUNTRY,
  QUERY_NEWSPAPERS_IN_SOURCES_OF_COUNTRY_NULLS_LAST,
  QUERY_GET_ONGOING_TRANSCRIPTIONS,
  MUTATION_DELETE_ARTICLE,
  MUTATION_INSERT_USERPOST,
  MUTATION_UPDATE_USERPOST,
  MUTATION_APPROVE_USERPOST,
  QUERY_SUMMARY_ARTICLES,
  /* #endregion */
  /* #region  Article Upvotes */
  QUERY_ARTICLE_VOTER_COUNTS_BY_USER,
  MUTATION_ARTICLE_INC_UPVOTES,
  MUTATION_ARTICLE_DEC_UPVOTES,
  /* #endregion */
  /* #region  Article Reposts */
  QUERY_ARTICLE_REPOST_BY_USER,
  MUTATION_ARTICLE_INC_REPOSTS,
  MUTATION_ARTICLE_DEC_REPOSTS,
  /* #endregion */
  /* #region  Article Save */
  QUERY_ARTICLE_SAVE_BY_USER,
  QUERY_ARTICLE_SAVE,
  MUTATION_INSERT_ARTICLE_SAVE,
  MUTATION_DELETE_ARTICLE_SAVE,
  /* #endregion */
  /* #region  Article Reports */
  QUERY_ARTICLE_REPORTS_BY_ARTICLE,
  QUERY_ARTICLE_REPORTS_BY_SOURCES,
  MUTATION_INSERT_ARTICLE_REPORT,
  MUTATION_DELETE_ARTICLE_REPORTS,
  MUTATION_DELETE_ARTICLE_REPORT,
  MUTATION_UPDATE_ARTICLE_REPORT,
  /* #endregion */
  /* #region  Article Pins */
  QUERY_ARTICLE_PINS_IN_FEEDS,
  MUTATION_INSERT_ARTICLE_PIN,
  MUTATION_DELETE_ARTICLE_PIN,
  /* #endregion */
  /* #region  Article MoveTops */
  QUERY_ARTICLE_MOVETOPS_IN_FEEDS,
  MUTATION_INSERT_ARTICLE_MOVETOP,
  MUTATION_DELETE_ARTICLE_MOVETOP,
  /* #endregion */
  /* #region  User Operations */
  QUERY_USER_BY_ID,
  QUERY_USER_BY_USERNAME,
  QUERY_USERS_FOR_LEADERBOARDS,
  QUERY_USERS_BY_SEARCHKEY,
  MUTATION_UPDATE_REPORT_LIMIT,
  MUTATION_INSERT_USER,
  MUTATION_UPDATE_USER,
  MUTATION_UPDATE_USER_PHONE,
  MUTATION_UPDATE_USER_LINKS,
  MUTATION_UPDATE_USER_MSGTOKEN,
  MUTATION_UPDATE_USER_SKIPPROFILE,
  MUTATION_UPDATE_USER_SUBSCRIBE_EMAIL,
  MUTATION_DELETE_USER_SUBSCRIBE_EMAIL,
  MUTATION_UPDATE_USER_PROJECT_SCISTATER,
  MUTATION_UPDATE_USER_PROJECT_LIBRARYKIT,
  MUTATION_UPDATE_USER_EMAIL,
  /* #endregion */

  /* #region  Tag User */
  QUERY_TAG_USER_BY_REF,
  MUTATION_ADD_TAG_USER,
  MUTATION_DELETE_TAG_USER,
  /* #endregion */
  /* #region  ActivityLogs */
  QUERY_ACTIVITYLOGS_BY_USER,
  QUERY_ACTIVITYLOGS_BY_TYPE,
  QUERY_ACTIVITYLOGS_BY_TYPEID,
  MUTATION_INSERT_ACTIVITYLOG,
  /* #endregion */
  /* #region  Scrape Request */
  QUERY_SCRAPE_REQUEST,
  MUTATION_INSERT_SCRAPE_REQUEST,
  MUTATION_DELETE_SCRAPE_REQUEST,
  /* #endregion */
  /* #region Comment */
  QUERY_TOP_COMMENTS_BY_NEWEST, QUERY_TOP_COMMENTS_BY_NEWEST_APPROVE,
  QUERY_TOP_COMMENTS_BY_OLDEST, QUERY_TOP_COMMENTS_BY_OLDEST_APPROVE,
  QUERY_TOP_COMMENTS_BY_RECOMMENDS, QUERY_TOP_COMMENTS_BY_RECOMMENDS_APPROVE,
  QUERY_TOP_COMMENTS_BY_REPLIES, QUERY_TOP_COMMENTS_BY_REPLIES_APPROVE,
  QUERY_COMMENTS_BY_NEWEST, QUERY_COMMENTS_BY_NEWEST_APPROVE,
  QUERY_COMMENTS_BY_OLDEST, QUERY_COMMENTS_BY_OLDEST_APPROVE,
  QUERY_COMMENTS_BY_RECOMMENDS, QUERY_COMMENTS_BY_RECOMMENDS_APPROVE,
  QUERY_COMMENTS_BY_REPLIES, QUERY_COMMENTS_BY_REPLIES_APPROVE,
  MUTATION_INSERT_COMMENT,
  MUTATION_UPDATE_COMMENT,
  MUTATION_APPROVE_COMMENT,
  MUTATION_DELETE_COMMENT,
  MUTATION_DELETEALL_COMMENTS,
  QUERY_COMMENT_RECOMMENDER,
  MUTATION_COMMENT_INC_RECOMMENDS,
  MUTATION_COMMENT_DEC_RECOMMENDS,
  /* #endregion */
  /* #region Comment Reports */
  QUERY_COMMENT_REPORTS_BY_COMMENT,
  MUTATION_INSERT_COMMENT_REPORT,
  MUTATION_DELETE_COMMENT_REPORT,
  MUTATION_UPDATE_COMMENT_REPORT,
  /* #endregion */
  /* #region Thread */
  QUERY_THREADS,
  QUERY_THREADS_REMOVED_HOME,
  QUERY_THREADS_APPROVED_BY_FEEDS,
  QUERY_THREADS_BY_FEEDS,
  QUERY_THREAD_APPROVED_BY_ID,
  QUERY_THREAD_BY_ID,
  QUERY_THREAD_APPROVED_BY_ARTICLE,
  QUERY_THREAD_BY_ARTICLE,
  MUTATION_INSERT_THREAD,
  MUTATION_APPROVE_THREAD,
  MUTATION_CLOSE_THREAD,
  MUTATION_THREAD_REMOVE_HOME,
  MUTATION_THREAD_REMOVE_CLEANAIR,
  MUTATION_DELETE_THREAD,
  MUTATION_DELETE_THREAD_BY_FROM,
  QUERY_MAPLOCATION_THREADS,
  QUERY_MAPLOCATION_THREADS_REMOVED_CLEANAIR,
  QUERY_THREADS_BY_MAPLOCATION,
  QUERY_THREAD_BY_MAPREADING,
  MUTATION_DELETE_THREAD_BY_MAPREADING,
  /* #endregion */
  /* #region  Thread Upvotes */
  QUERY_THREAD_VOTER,
  MUTATION_THREAD_INC_UPVOTES,
  MUTATION_THREAD_DEC_UPVOTES,
  /* #endregion */
  /* #region  Thread Reports */
  QUERY_THREAD_REPORTS_BY_THREAD,
  QUERY_THREAD_REPORTS_BY_TYPE,
  MUTATION_INSERT_THREAD_REPORT,
  MUTATION_APPROVE_THREAD_REPORT,
  MUTATION_DELETE_THREAD_REPORT,
  /* #endregion */
  /* #region  Banned User */
  QUERY_BANNED_USERS_BY_FEED,
  MUTATION_INSERT_BANNED_USER,
  MUTATION_UPDATE_BANNED_USER,
  MUTATION_DELETE_BANNED_USER,
  /* #endregion */
  /* #region  Preapproved User */
  QUERY_PREAPPROVED_USERS_BY_FEED,
  MUTATION_INSERT_PREAPPROVED_USER,
  MUTATION_DELETE_PREAPPROVED_USER,
  /* #endregion */
  /* #region  User Followers */
  QUERY_USERS_BY_FOLLOWER,
  MUTATION_INSERT_USER_FOLLOWER,
  MUTATION_DELETE_USER_FOLLOWER,
  /* #endregion */
  /* #region  User Invites */
  QUERY_INVITES_BY_USER,
  QUERY_INVITES_BY_PHONE,
  MUTATION_INSERT_USER_INVITE,
  MUTATION_INSERT_USER_INVITE_BYPHONE,
  MUTATION_DELETE_USER_INVITE,
  /* #endregion */
  /* #region  User Blockmutes */
  QUERY_BLOCKMUTES_BY_USER,
  MUTATION_INSERT_USER_BLOCKMUTE,
  /* #endregion */
  /* #region  User Reports */
  QUERY_USER_REPORTS_BY_USER,
  MUTATION_INSERT_USER_REPORT,
  MUTATION_UPDATE_USER_REPORT,
  MUTATION_DELETE_USER_REPORT,
  MUTATION_DELETE_USER_REPORTS,
  /* #endregion */
  /* #region  User Suspensions */
  QUERY_SUSPENSIONS_BY_USER,
  MUTATION_INSERT_USER_SUSPENSION,
  /* #endregion */
  /* #region  Trending */
  QUERY_TRENDING_SOURCES,
  QUERY_TRENDING_ARTICLES_OF_BRANCH,
  QUERY_TRENDING_ARTICLES_OF_BRANCH_COUNTRY,
  /* #endregion */
  /* #region  User Feed ShowFirst */
  QUERY_SHOWFIRST_FEEDS,
  MUTATION_INSERT_USER_FEED_SHOWFIRST,
  MUTATION_DELETE_USER_FEED_SHOWFIRST,
  /* #endregion */
  /* #region  Notifications */
  QUERY_NOTIFICATIONS_TO_USER,
  MUTATION_INSERT_NOTIFICATION,
  MUTATION_CONFIRM_NOTIFICATION,
  /* #endregion */
  /* #region  Uptime Stats */
  QUERY_STATS,
  /* #endregion */
  /* #region  Map Regions */
  QUERY_MAP_REGIONS,
  QUERY_MAP_REGIONS_FOR_LEADERBOARDS,
  QUERY_MAP_REGION_BY_ID,
  QUERY_MAP_REGION_BY_SLUG,
  MUTATION_INSERT_MAP_REGION,
  MUTATION_DELETE_MAP_REGION,
  MUTATION_INSERT_REGION_MODERATOR,
  MUTATION_APPROVE_REGION_MODERATOR,
  MUTATION_DELETE_REGION_MODERATOR,
  /* #endregion */
  /* #region  Locations */
  QUERY_MAP_LOCATION_BY_ID,
  QUERY_MAP_LOCATION_COUNT_BY_SLUG,
  QUERY_MAP_LOCATION_BY_SLUG,
  QUERY_MAP_LOCATIONS_BY_BOX,
  QUERY_MAP_LOCATIONS_BY_BOX_DDAY,
  QUERY_MAP_LOCATIONS_FOR_LEADERBOARDS,
  QUERY_MAP_LOCATIONS_BY_REGION,
  MUTATION_INSERT_MAP_LOCATION,
  MUTATION_UPDATE_MAP_LOCATION,
  MUTATION_UPDATE_MAP_LOCATION_LAST_CO2,
  MUTATION_UPDATE_MAP_LOCATION_OP_READINGS,
  MUTATION_UPDATE_MAP_LOCATION_OP_IMAGEUPLOAD,
  MUTATION_UPDATE_MAP_LOCATION_OP_MODERATION,
  MUTATION_UPDATE_MAP_LOCATION_OP_DESCRIPTION,
  MUTATION_UPDATE_MAP_LOCATION_OP_COMMENTS,
  MUTATION_UPDATE_MAP_LOCATION_READINGCONF,
  MUTATION_DELETE_MAP_LOCATION,
  /* #endregion */
  /* #region  Location Reports */
  QUERY_LOCATION_REPORTS_BY_LOCATION,
  MUTATION_INSERT_LOCATION_REPORT,
  MUTATION_DELETE_LOCATION_REPORT,
  MUTATION_APPROVE_LOCATION_REPORT,
  /* #endregion */
  /* #region  Location Moderators */
  QUERY_LOCATION_MODERATORS_BY_LOCATION,
  QUERY_LOCATION_MODERATOR,
  MUTATION_INSERT_LOCATION_MODERATOR,
  MUTATION_DELETE_LOCATION_MODERATOR,
  MUTATION_APPROVE_LOCATION_MODERATOR,
  /* #endregion */
  /* #region  Location Followers */
  QUERY_LOCATION_FOLLOWERS_BY_UID,
  QUERY_LOCATION_FOLLOWERS_BY_LOCATION,
  MUTATION_INSERT_LOCATION_FOLLOWER,
  MUTATION_DELETE_LOCATION_FOLLOWER,
  /* #endregion */
  /* #region  Readings */
  QUERY_MAP_READINGS_BY_LOCATION,
  MUTATION_INSERT_MAP_READING,
  MUTATION_UPDATE_MAP_READING,
  MUTATION_APPROVE_MAP_READING,
  MUTATION_DELETE_MAP_READING,
  QUERY_READING_RECOMMENDER,
  MUTATION_READING_INC_RECOMMENDS,
  MUTATION_READING_DEC_RECOMMENDS,
  /* #endregion */
  /* #region  Reading Reports */
  QUERY_READING_REPORTS_BY_READING,
  QUERY_READING_REPORTS_BY_LOCATION,
  MUTATION_INSERT_READING_REPORT,
  MUTATION_DELETE_READING_REPORT,
  MUTATION_APPROVE_READING_REPORT,
  /* #endregion */
  /* #region  Mappost */
  QUERY_MAPPOST_BY_NID,
  QUERY_MAPPOSTS_BY_REGION,
  QUERY_MAPPOSTS_BY_LOCATION,
  /* #endregion */
  /* #region  Location Moderation */
  QUERY_LOCATION_MODERATION_COUNT,
  QUERY_LOCATION_MODERATION_FIELDS,
  MUTATION_UPDATE_LOCATION_NOTIFICATIONS,
  /* #endregion */
  /* #region  Maplocation Moderator Reports */
  QUERY_MAPLOCATION_MODERATOR_REPORTS_BY_LOCATION,
  MUTATION_INSERT_MAPLOCATION_MODERATOR_REPORT,
  MUTATION_UPDATE_MAPLOCATION_MODERATOR_REPORT,
  MUTATION_DELETE_MAPLOCATION_MODERATOR_REPORT,
  MUTATION_DELETE_MAPLOCATION_MODERATOR_REPORTS,
  /* #endregion */
  /* #region  Map location Banned User */
  QUERY_MAPLOCATION_BANNED_USERS_BY_LOCATION,
  MUTATION_INSERT_MAPLOCATION_BANNED_USER,
  MUTATION_UPDATE_MAPLOCATION_BANNED_USER,
  MUTATION_DELETE_MAPLOCATION_BANNED_USER,
  /* #endregion */
  /* #region  Map location Rooms */
  QUERY_MAPLOCATION_ROOMS_BY_LOCATION,
  MUTATION_INSERT_MAPLOCATION_ROOM,
  MUTATION_DELETE_MAPLOCATION_ROOM,
  /* #endregion */
  /* #region insert mask friendly hours */
  MUTATION_INSERT_MASK_FRIENDLY_HOUR,
   /* #endregion */
  /* #region  Map tag */
  QUERY_MAPTAGS,
  QUERY_MAPTAG_CATEGORY_BY_CATEGORY,
  /* #endregion */
  /* #region  Map location Comments */
  QUERY_MAPLOCATION_COMMENTS,
  QUERY_MAPLOCATION_COMMENT,
  MUTATION_INSERT_MAPLOCATION_COMMENT,
  MUTATION_DELETE_MAPLOCATION_COMMENT,
  MUTATION_UPDATE_MAPLOCATION_COMMENT,
  /* #endregion */
  MUTATION_UPDATE_FEED_AICOMMENTCONF,
  MUTATION_UPDATE_FEED_AISUMMARYCONF,
  MUTATION_INSERT_AI_PERSONS,
  QUERY_GET_AI_PERSONS,
  QUERY_AI_PERSONS_BY_FEED_ID,
  MUTATION_DELETE_AI_PERSON,
  MUTATION_DELETE_USER,
  MUTATION_INSERT_AI_COMMENT,
  MUTATION_UPDATE_AI_USER,
  MUTATION_UPDATE_AI_PERSONAS,
  QUERY_AI_SUMMARY,
  QUERY_CLUSTER_MEMBERS_BY_ARTICLE,
  QUERY_THREAD_BY_AIUSER,
  QUERY_FIRST_THREAD_BY_AIUSER,
  QUERY_THREAD_BY_USER,
  MUTATION_DELETE_ASSOCIATIVE_AI_PERSONS_FROM_ASSOCIATIVE_FEEDS,
  MUTATION_UPDATE_ASSOCIATE_AI_PERSONS,
  /* #region  Map location reading Comments */
  QUERY_TOP_READING_COMMENTS_BY_NEWEST,
  QUERY_TOP_READING_COMMENTS_BY_NEWEST_APPROVE,
  QUERY_TOP_READING_COMMENTS_BY_OLDEST,
  QUERY_TOP_READING_COMMENTS_BY_OLDEST_APPROVE,
  QUERY_TOP_READING_COMMENTS_BY_RECOMMENDS,
  QUERY_TOP_READING_COMMENTS_BY_RECOMMENDS_APPROVE,
  QUERY_TOP_READING_COMMENTS_BY_REPLIES,
  QUERY_TOP_READING_COMMENTS_BY_REPLIES_APPROVE,
  QUERY_MASK_FRIENDLY_HOURS,
  MUTATION_MASK_FRIENDLY_HOURS,
  MUTATION_UPDATE_ENABLE_TRANSCRIPTION,
  MUTATION_UPDATE_BIAS_CHECK_ENABLE,
  /* #endregion */
  /* #AI_PERSONS_COMMENTS */
  QUERY_COMMENT_OF_AI_USERS,
  QUERY_FIRST_COMMENT_BY_AIUSER,
  QUERY_SOURCE_REPORTS_COUNT,
  QUERY_COMMENTS_REPORTS_COUNT,
  QUERY_THREAD_REPORTS_COUNT,
  QUERY_ARTICLE_REPORTS_COUNT,
  QUERY_SELFPOSTS_BY_USER,
  QUERY_FIRST_SELFPOSTS_BY_USER,
  MUTATION_UPDATE_FEED_IMAGE,
  MUTATION_INSERT_AI_QUERY_TRANSCRIPTION,
  GET_HISTORY_FOR_QUERY_TARNSCRIPTION,
   /*END #AI_PERSONS_COMMENTS */
   /*#search term source*/
   MUTATION_INSERT_SEARCH_TERM_SOURCE,
   QUERY_AI_SOURCE_ARTICLES_BY_SOURCEIDS,
   MUTATION_UPDATE_SOURCE_IMAGE,
   MUTATION_INSERT_SPONSORSHIP,
   QUERY_SPONSORSHIPS_BY_ID,
   MUTATION_DELETE_SPONSORSHIP,
   MUTATION_UPDATE_SPONSORSHIP_STATUS,
   QUERY_SPONSORED_ARTICLES_BY_ID,
   QUERY_FOR_GETTING_PRICES,
   QUERY_GET_PRICE_BY_PAYMENT_TYPE,
   /*#sockets*/
   MUTATION_INSERT_SOCKETS,
   MUTATION_DELETE_SOCKETS,
   GET_SOCKETS
   /*#sockets*/
} from "./endpoints";


// const RAVEN_API = "https://graphql.raventalk.org/v1/graphql";
// const RAVEN_API = "https://gateway.raventalk.org/graphql";
// const RAVEN_API = "https://gateway.ravenbalancer.com/graphql";
const RAVEN_API = process.env.REACT_APP_RAVEN_GRAPHQL_API;

class GraphqlService {
  constructor() {
    this.client = new GraphQLClient(RAVEN_API, { headers: {} });
    this.pagesize = ARTICLES_PER_PAGE;
    this.query_threshold = 3000;
  }

  /* #region  Graphql Request Header */
  set_auth_jwt = (token, moderator = false, bot = false) => {
    const bearerToken = `Bearer ${token}`;
    this.client.setHeader("Authorization", bearerToken);
    if (bot) {
      this.client.setHeader("x-hasura-role", "bot");
    }
    else if (moderator) {
      this.client.setHeader("x-hasura-role", "moderator");
    } else {
      this.client.setHeader("x-hasura-role", "user");
    }
  };
  /* #endregion */

  /* #region  Base Data */
  base_data = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_BASE_DATA)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting base data :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  // base_data_by_user = () =>
  //   new Promise((resolve, reject) => {
  //     this.client
  //       .request(QUERY_BASE_DATA_USER)
  //       .then((data) => {
  //         resolve({
  //           status_code: GRAPHQL_SUCCESS,
  //           data,
  //         });
  //       })
  //       .catch((err) => {
  //         let msg = "Error getting base data :" + err;
  //         logger.error(msg);
  //         reject({ status_code: GRAPHQL_ERROR, msg: msg });
  //       });
  //   });

  // base_data_by_paiduser = () =>
  //   new Promise((resolve, reject) => {
  //     this.client
  //       .request(QUERY_BASE_DATA_PAIDUSER)
  //       .then((data) => {
  //         resolve({
  //           status_code: GRAPHQL_SUCCESS,
  //           data,
  //         });
  //       })
  //       .catch((err) => {
  //         let msg = "Error getting base data :" + err;
  //         logger.error(msg);
  //         reject({ status_code: GRAPHQL_ERROR, msg: msg });
  //       });
  //   });

  lists = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_LISTS)
        .then(data => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data
          });
        })
        .catch(err => {
          let msg = 'Error getting lists :' + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Category Moderation Fields */
  category_moderation_fields = (category_id, category_feed_ids, logtype, after) =>
    new Promise((resolve, reject) => {
      const variables = {
        category_id: category_id,
        category_feed_ids: category_feed_ids,
        logtype: logtype,
        after: after
      };

      this.client
        .request(QUERY_CATEGORY_MODERATION_FIELDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting category moderation fields :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  category_moderation_count = (category_id, category_feed_ids) =>
    new Promise((resolve, reject) => {
      const variables = {
        category_id: category_id,
        category_feed_ids: category_feed_ids
      };

      this.client
        .request(QUERY_CATEGORY_MODERATION_COUNT, variables)
        .then(data => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data
          });
        })
        .catch(err => {
          let msg = 'Error getting category moderation count :' + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Category Moderators */
  category_moderators_by_category = (category_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        category_id: category_id,
      };

      this.client
        .request(QUERY_CATEGORY_MODERATORS_BY_CATEGORY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting moderators of category :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_category_moderator = (moderator) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: moderator.id,
        category_id: moderator.category_id,
        user_id: moderator.user_id,
      };

      this.client
        .request(MUTATION_INSERT_CATEGORY_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert category moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_category_moderator = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_CATEGORY_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete category moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  resign_category_moderator = (category_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        category_id: category_id,
        user_id: user_id
      };

      this.client
        .request(MUTATION_RESIGN_CATEGORY_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error resign category moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_category_moderator = (id, approved, approved_by) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
      };

      this.client
        .request(MUTATION_UPDATE_CATEGORY_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update category moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_category_last_viewlog = (id, viewtime) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        viewtime: viewtime
      };

      this.client
        .request(MUTATION_UPDATE_CATEGORY_LASTVIEWLOG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update category last view time :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_category_notifications = (id, notifications) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        notifications: notifications
      };

      this.client
        .request(MUTATION_UPDATE_CATEGORY_NOTIFICATIONS, variables)
        .then(data => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data
          });
        })
        .catch(err => {
          let msg = 'Error update category notifications :' + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Moderation Fields */
  get_feeds = (feed_ids) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_ids: feed_ids,
      };

      this.client
        .request(QUERY_SELECT_FEEDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  category_feeds = (category_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        category_id: category_id
      };

      this.client
        .request(QUERY_CATEGORY_FEEDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting category feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feeds_by_searchkey = (searchkey) =>
    new Promise((resolve, reject) => {
      const variables = {
        searchkey: searchkey
      };

      this.client
        .request(QUERY_FEEDS_BY_SEARCHKEY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feeds by searchkey :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_by_id = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: feed_id,
      };

      this.client
        .request(QUERY_FEED_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed info :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_by_slug = (slug) =>
    new Promise((resolve, reject) => {
      const variables = {
        slug: slug,
      };
      this.client
        .request(QUERY_FEED_BY_SLUG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed info by slug :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_exist_by_id = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(QUERY_FEED_EXIST_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed exist by id :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_exist_by_slug = (slug) =>
    new Promise((resolve, reject) => {
      const variables = {
        slug: slug,
      };

      this.client
        .request(QUERY_FEED_EXIST_BY_SLUG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed exist by slug :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_moderation_count = (feed_id, feed_source_ids, after) =>
    new Promise((resolve, reject) => {
      const userpost_source_id = `${feed_id}-userpost`;
      const variables = {
        feed_id: feed_id,
        userpost_source_id: userpost_source_id,
        feed_source_ids: feed_source_ids,
        after: after
      };

      this.client
        .request(QUERY_FEED_MODERATION_COUNT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed moderation count :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_moderation_fields = (feed_id, feed_source_ids, after, logtype) =>
    new Promise((resolve, reject) => {
      const userpost_source_id = `${feed_id}-userpost`;
      const variables = {
        feed_id: feed_id,
        userpost_source_id: userpost_source_id,
        feed_source_ids: feed_source_ids,
        after: after,
        logtype: logtype
      };

      this.client
        .request(QUERY_FEED_MODERATION_FIELDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed moderation fields :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_members = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id
      };

      this.client
        .request(QUERY_FEED_MEMBERS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed members :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    get_default_feeds = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_DEFAULT_FEEDS)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting default feeds data :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Operations */
  insert_feed = (feed) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_object: feed
      };

      this.client
        .request(MUTATION_INSERT_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed = (feed) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_FEED, feed)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feeds = (feed, feed_sources) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_objects: [feed],
        feed_sources_objects: feed_sources,
      };

      this.client
        .request(MUTATION_INSERT_FEEDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feeds = (ids) =>
    new Promise((resolve, reject) => {
      const variables = {
        ids: ids,
      };

      this.client
        .request(MUTATION_DELETE_FEEDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_approve = (id, approved) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_APPROVED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed approved :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_followers = (id, followers) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        followers: followers,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_FOLLOWERS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed followers :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_commentconf = (id, commentconf) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        conf: commentconf,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_COMMENTCONF, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed comment configuration :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_last_viewlog = (id, viewtime) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        viewtime: viewtime,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_LASTVIEWLOG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed last view time :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_op_visibility = (id, visibility) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        visibility: visibility,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_OP_VISIBILITY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed option visibility :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    update_feed_ai_moderation = (id, aiModeration) => {
      return new Promise((resolve, reject) => {
        const variables = {
          id: id,
          aiModeration: aiModeration,
        };
    
        this.client
          .request(MUTATION_UPDATE_FEED_AI_MODERATION, variables)
          .then((data) => {
            resolve({
              status_code: GRAPHQL_SUCCESS,
              data,
            });
          })
          .catch((err) => {
            let msg = "Error updating feed AI auto moderation: " + err;
            logger.error(msg);
            reject({ status_code: GRAPHQL_ERROR, msg: msg });
          });
      });
    };
    

  update_feed_op_comments = (id, comments) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        comments: comments,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_OP_COMMENTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed option comments :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_op_posts = (id, posts) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        posts: posts,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_OP_POSTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed option posts :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_op_members = (id, members) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        members: members,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_OP_MEMBERS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed option members :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_op_payment = (id, payment) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        payment: payment,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_OP_PAYMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed option payment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_op_anonymity = (id, anonymity) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        anonymity: anonymity,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_OP_ANONYMITY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed option anonymity :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_tg_wallet = (id, tg_wallet) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        tg_wallet: tg_wallet,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_TG_WALLET, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed tg wallet :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_tg_address = (id, tg_address) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        tg_address: tg_address,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_TG_ADDRESS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed tg address :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_tg_amount = (id, tg_amount) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        tg_amount: tg_amount,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_TG_AMOUNT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed tg amount :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_token_address = (id, token_address) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        token_address: token_address,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_TOKEN_ADDRESS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed token address :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_token_amount = (id, token_amount) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        token_amount: token_amount,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_TOKEN_AMOUNT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed token amount :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_sociallink = (id, provider, url) =>
    new Promise((resolve, reject) => {
      let mutation = null;
      let variables = null;
      if (provider === "twitter") {
        mutation = MUTATION_UPDATE_FEED_TWITTER;
        variables = {
          id: id,
          twitter: url,
        };
      } else if (provider === "instagram") {
        mutation = MUTATION_UPDATE_FEED_INSTAGRAM;
        variables = {
          id: id,
          instagram: url,
        };
      } else if (provider === "tiktok") {
        mutation = MUTATION_UPDATE_FEED_TIKTOK;
        variables = {
          id: id,
          tiktok: url,
        };
      } else if (provider === "youtube") {
        mutation = MUTATION_UPDATE_FEED_YOUTUBE;
        variables = {
          id: id,
          youtube: url,
        };
      }

      this.client
        .request(mutation, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed social link :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_notifications = (id, notifications, notif_date) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        notifications: notifications,
        notif_date: notif_date
      };

      this.client
        .request(MUTATION_UPDATE_FEED_NOTIFICATIONS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data
          });
        })
        .catch((err) => {
          let msg = "Error update feed notifications :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    update_report_limit = (updatedAuthUser) =>
  new Promise((resolve, reject) => {
    const variables = {
      uid: updatedAuthUser.uid,
      lastReportAt: updatedAuthUser.last_report_at,
      totalReportsPerDay: updatedAuthUser.total_report_per_day,
    };

    this.client
      .request(MUTATION_UPDATE_REPORT_LIMIT, variables)
      .then((data) => {
        resolve({
          status_code: GRAPHQL_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        let msg = "Error updating user report: " + err;
        logger.error(msg);
        reject({ status_code: GRAPHQL_ERROR, msg: msg });
      });
  });


  /* #endregion */

  /* #region  Feed Followers */
  feed_followers = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };

      this.client
        .request(QUERY_FEED_FOLLOWERS_BY_UID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting followers of feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_followers_by_feeds = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_FEED_FOLLOWERS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting followers of feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_follower = (follower) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: follower.id,
        feed_id: follower.feed_id,
        user_id: follower.user_id,
        order: follower.order,
      };

      this.client
        .request(MUTATION_INSERT_FEED_FOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed follower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_follower = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_FEED_FOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed follower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Order */
  feed_orders = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };

      this.client
        .request(QUERY_FEED_ORDER_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed orders :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_order = (order) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_FEED_ORDER, order)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert a feed order :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_orders = (orders) =>
    new Promise((resolve, reject) => {
      const variables = {
        orders: orders,
      };
      this.client
        .request(MUTATION_INSERT_FEED_ORDERS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed orders :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_order = (new_order) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_FEED_ORDER, new_order)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed order :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_order = (user_id, feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
        feed_id: feed_id
      };

      this.client
        .request(MUTATION_DELETE_FEED_ORDER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed order :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Subscribers */
  feed_subscribers = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };

      this.client
        .request(QUERY_FEED_SUBSCRIBERS_BY_UID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting subscribers of feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_subscribers_by_feeds = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_FEED_SUBSCRIBERS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting subscribers of feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_subscriber = (feed_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        user_id: user_id
      };

      this.client
        .request(MUTATION_INSERT_FEED_SUBSCRIBER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed subscriber :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_subscriber = (feed_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        user_id: user_id
      };

      this.client
        .request(MUTATION_DELETE_FEED_SUBSCRIBER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed subscriber :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_subscribers = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id
      };

      this.client
        .request(MUTATION_DELETE_FEED_SUBSCRIBERS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed subscribers :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Moderators */
  feed_moderators_by_feed = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_FEED_MODERATORS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting moderators of feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_moderator = (feed_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        user_id: user_id
      };

      this.client
        .request(QUERY_FEED_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting moderators of feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_moderator = (moderator) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: moderator.id,
        feed_id: moderator.feed_id,
        user_id: moderator.user_id,
        approved: moderator.approved,
        owner: moderator.owner
      };

      this.client
        .request(MUTATION_INSERT_FEED_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_moderator = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_FEED_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  resign_feed_moderator = (feed_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        user_id: user_id
      };

      this.client
        .request(MUTATION_RESIGN_FEED_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error resign feed moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_moderator = (id, approved, approved_by, approved_at, owner) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
        owner: owner
      };

      this.client
        .request(MUTATION_UPDATE_FEED_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Moderator Reports(Feed Moderator) */
  moderator_reports_by_feed = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_MODERATOR_REPORTS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting moderator reports :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_moderator_report = (report) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: report.id,
        moderator_id: report.moderator_id,
        feed_id: report.feed_id,
        report: report.report,
        reported_by: report.reported_by,
      };

      this.client
        .request(MUTATION_INSERT_MODERATOR_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert moderator report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_moderator_report = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_MODERATOR_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete moderator report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_moderator_reports = (feed_id, moderator_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        moderator_id: moderator_id,
      };

      this.client
        .request(MUTATION_DELETE_MODERATOR_REPORTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete moderator reports :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });


  update_moderator_report = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_MODERATOR_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update moderator report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  /* #endregion */

  /* #region  Feed Reports */
  feed_reports_by_feed = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_FEED_REPORTS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting reports of feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_report = (report) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: report.id,
        feed_id: report.feed_id,
        report: report.report,
        reported_by: report.reported_by,
      };

      this.client
        .request(MUTATION_INSERT_FEED_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_report = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_FEED_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_report = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Posts */
  feed_posts = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_FEED_POSTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting posts of feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_post = (feed_id, article_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        article_id: article_id
      };

      this.client
        .request(QUERY_FEED_POST, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting a post of feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_post = (post) =>
    new Promise((resolve, reject) => {

      this.client
        .request(MUTATION_INSERT_FEED_POST, post)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed post :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_post = (feed_id, article_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        article_id: article_id
      };

      this.client
        .request(MUTATION_DELETE_FEED_POST, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed post :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_post = (feed_id, article_id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        article_id: article_id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_POST, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed post :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Comments */
  feed_comments = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_FEED_COMMENTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting comments of feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_comment = (feed_id, comment_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        comment_id: comment_id
      };

      this.client
        .request(QUERY_FEED_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting a comment of feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_comment = (comment) =>
    new Promise((resolve, reject) => {

      this.client
        .request(MUTATION_INSERT_FEED_COMMENT, comment)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_comment = (feed_id, comment_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        comment_id: comment_id
      };

      this.client
        .request(MUTATION_DELETE_FEED_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_comment = (feed_id, comment_id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        comment_id: comment_id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Sources */
  feed_sources_by_feed = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_FEED_SOURCES_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting sources of feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_sources = (feed_sources) =>
    new Promise((resolve, reject) => {
      const variables = {
        objects: feed_sources,
      };

      this.client
        .request(MUTATION_INSERT_FEED_SOURCES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed sources :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_source = (feed_id, source_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        source_id: source_id,
      };

      this.client
        .request(MUTATION_DELETE_FEED_SOURCE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed source :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_source = (id, source_id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        source_id: source_id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_FEED_SOURCE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed source :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_source_keyword = (feed_id, source_id, keyword) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        source_id: source_id,
        keyword: keyword
      };

      this.client
        .request(MUTATION_UPDATE_FEED_SOURCE_KEYWORD, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed source keyword :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_source_changes = (feed_source_changes) =>
    new Promise((resolve, reject) => {
      const variables = {
        objects: feed_source_changes,
      };

      this.client
        .request(MUTATION_INSERT_FEED_SOURCE_CHANGES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed source changes :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Social Tag */
  socialtag = (tag, type) =>
    new Promise((resolve, reject) => {
      const variables = {
        tag: tag,
        type: type
      };
      this.client
        .request(QUERY_SOCIALTAG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error get socialtag :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  source_by_socialtag = (tag, type) => 
    new Promise((resolve, reject) => {
      const variables = {
        tag: tag,
        type: type
      };
      this.client
        .request(GET_SOURCE_BY_SOCIALTAG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error get source by socialtag :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_socialtag = (socialtag) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_SOCIALTAG, socialtag)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert socialtag :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_socialtag = (socialtag) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_SOCIALTAG, socialtag)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update socialtag :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_socialtag = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_SOCIALTAG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete socialtag :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_source_throttle = (source_id, throttle) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: source_id,
        throttle: throttle
      };

      this.client
        .request(MUTATION_UPDATE_SOURCE_THROTTLE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update source throttle :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_source_lastupdated = (source_id, last_updated) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: source_id,
        last_updated: last_updated
      };

      this.client
        .request(MUTATION_UPDATE_SOURCE_LASTUPDATED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update source last_updated :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_source_translate = (source_id, translate) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: source_id,
        translate: translate
      };

      this.client
        .request(MUTATION_UPDATE_SOURCE_TRANSLATE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update source translate :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  /* #endregion */

  /* #region  Feed Source Unfollowers */
  feed_source_unfollowers = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_FEED_SOURCE_UNFOLLOWERS)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed source unfollowers :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
    //Source report counts for past 90 days
    source_report_count = (id,time) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        time: time
      };
      this.client
        .request(QUERY_SOURCE_REPORTS_COUNT ,variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed source unfollowers :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  // For getting thread report count
  thread_reports_count = (id,time) =>
  new Promise((resolve, reject) => {
    const variables = {
      id: id,
      time: time
    };
    this.client
      .request(QUERY_THREAD_REPORTS_COUNT ,variables)
      .then((data) => {
        resolve({
          status_code: GRAPHQL_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        let msg = "Error getting Thread report count :" + err;
        logger.error(msg);
        reject({ status_code: GRAPHQL_ERROR, msg: msg });
      });
  });
// for getting article report count
article_reports_count = (id,time) =>
new Promise((resolve, reject) => {
  const variables = {
    id: id,
    time: time
  };
  this.client
    .request(QUERY_ARTICLE_REPORTS_COUNT ,variables)
    .then((data) => {
      resolve({
        status_code: GRAPHQL_SUCCESS,
        data,
      });
    })
    .catch((err) => {
      let msg = "Error getting article report count :" + err;
      logger.error(msg);
      reject({ status_code: GRAPHQL_ERROR, msg: msg });
    });
})

  feed_source_unfollowers_by_feed = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_FEED_SOURCE_UNFOLLOWERS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed source unfollowers :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_source_unfollower = (unfollower) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_FEED_SOURCE_UNFOLLOWER, unfollower)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed source unfollower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_source_unfollower = (feed_id, source_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        source_id: source_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_DELETE_FEED_SOURCE_UNFOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed source unfollower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Source ShowRetweet */
  feed_source_showretweets = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_FEED_SOURCE_SHOWRETWEETS)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed source showretweet :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_source_showretweets_by_feed = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_FEED_SOURCE_SHOWRETWEETS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed source showretweet :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_source_showretweet = (feed_id, source_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        source_id: source_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_INSERT_FEED_SOURCE_SHOWRETWEET, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed source showretweet :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_source_showretweet = (feed_id, source_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        source_id: source_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_DELETE_FEED_SOURCE_SHOWRETWEET, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed source showretweet :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed share Operations */
  feed_share_by_id = (share_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: share_id
      };

      this.client
        .request(QUERY_FEED_SHARE_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed share :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_share_by_feed = (feed_id, after) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        after: after
      };

      this.client
        .request(QUERY_FEED_SHARE_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed share :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_share = (shareInfo) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_FEED_SHARE, shareInfo)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed share :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_share = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id
      };

      this.client
        .request(MUTATION_DELETE_FEED_SHARE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed share :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed Articles Operations */
  feed_articles = (feed_id, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_FEED_ARTICLES, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.feed_articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed articles :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_articles_of_branch = (feed_id, branch, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        branch: branch,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_FEED_ARTICLES_OF_BRANCH, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.feed_articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed articles :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  
  feed_articles_of_country = (feed_id, country, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        country: country,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_FEED_ARTICLES_OF_COUNTRY, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.feed_articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed articles :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_article = (feedArticle) => 
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_FEED_ARTICLE, feedArticle)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result,
          });
        })
        .catch((err) => {
          let msg = "Error inserting feed articles :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_articles_in_feeds = (feed_ids, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_ids: feed_ids,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_FEED_ARTICLES_IN_FEEDS, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.feed_articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed articles :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  feed_articles_of_branch_in_feeds = (feed_ids, branch, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_ids: feed_ids,
        branch: branch,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_FEED_ARTICLES_OF_BRANCH_IN_FEEDS, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.feed_articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed articles :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  
  feed_articles_of_country_in_feeds = (feed_ids, country, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_ids: feed_ids,
        country: country,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_FEED_ARTICLES_OF_COUNTRY_IN_FEEDS, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.feed_articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed articles :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Feed videocasts Operations */
  videocasts_by_feed = (feed_id, after) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id
      };

      this.client
        .request(QUERY_VIDEOCASTS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting videocasts by feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  videocasts_all_in_feeds = (feed_ids) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_ids: feed_ids
      };

      this.client
        .request(QUERY_VIDEOCASTS_ALL_IN_FEEDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting all videocasts in feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  videocasts_in_feeds = (feed_ids, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_ids: feed_ids,
        pagesize: this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_VIDEOCASTS_IN_FEEDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting videocasts in feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_feed_videocast = (videocastInfo) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_FEED_VIDEOCAST, videocastInfo)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert feed videocast :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_feed_videocast = (feed_id, article_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        article_id: article_id
      };

      this.client
        .request(MUTATION_DELETE_FEED_VIDEOCAST, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete feed videocast :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Source Operations */
  sources = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_SOURCES)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting sources :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  source_by_id = (source_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: source_id,
      };

      this.client
        .request(QUERY_SOURCE_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting source info :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    source_and_feed_sources_by_id = (source_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: source_id,
      };

      this.client
        .request(QUERY_SOURCE_AND_FEED_SOURCES_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting source info :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  source_by_slug = (slug) =>
    new Promise((resolve, reject) => {
      const variables = {
        slug: slug,
      };

      this.client
        .request(QUERY_SOURCE_BY_SLUG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting source info by slug :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  source_exist_by_id = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id
      };

      this.client
        .request(QUERY_EXIST_SOURCE_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting exist source by id :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  source_exist_by_slug = (source_id, slug) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: source_id,
        slug: slug
      };

      this.client
        .request(QUERY_EXIST_SOURCE_BY_SLUG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting exist source info by slug :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  

  insert_source = (source) =>
    new Promise((resolve, reject) => {
      let query = source.is_published === undefined ? MUTATION_INSERT_SOURCE : MUTATION_INSERT_SEARCH_TERM_SOURCE;
      
      this.client
        .request(query, source)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert source :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_source = (source) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_SOURCE, source)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update source :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_source = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_SOURCE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete source :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Source Followers */
  source_followers_by_source = (source_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: source_id,
      };

      this.client
        .request(QUERY_SOURCE_FOLLOWERS_BY_SOURCE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting followers of source :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_source_follower = (follower) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: follower.id,
        source_id: follower.source_id,
        user_id: follower.user_id,
      };

      this.client
        .request(MUTATION_INSERT_SOURCE_FOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert source follower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_source_follower = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_SOURCE_FOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete source follower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_source_followers = (id, followers) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        followers: followers,
      };

      this.client
        .request(MUTATION_UPDATE_SOURCE_FOLLOWERS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update source followers :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Source Upvotes */
  source_voter = (source_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: source_id,
        user_id: user_id,
      };

      this.client
        .request(QUERY_SOURCE_VOTER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting source voter :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  source_inc_upvotes = async (source_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: source_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_SOURCE_INC_UPVOTES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error increment source upvotes :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  source_dec_upvotes = (source_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: source_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_SOURCE_DEC_UPVOTES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error decrement source upvotes :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Source Reports */
  source_reports_by_source = (source_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: source_id,
      };

      this.client
        .request(QUERY_SOURCE_REPORTS_BY_SOURCE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting reports of source :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  source_reports_by_sources = (source_ids) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_ids: source_ids,
      };

      this.client
        .request(QUERY_SOURCE_REPORTS_BY_SOURCES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting reports of sources :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_source_report = (report) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: report.id,
        source_id: report.source_id,
        report: report.report,
        reported_by: report.reported_by,
      };

      this.client
        .request(MUTATION_INSERT_SOURCE_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert source report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_source_reports = (source_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: source_id,
      };

      this.client
        .request(MUTATION_DELETE_SOURCE_REPORTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete source reports :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_source_report = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_SOURCE_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete source report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_source_report = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_SOURCE_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update source report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Article Operations */
  article_by_nid = (nid) =>
    new Promise((resolve, reject) => {
      const variables = {
        nid: nid,
      };

      this.client
        .request(QUERY_ARTICLE_BY_NID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting article :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    articles_with_summary = (nid, url, videoId, uid) =>
    new Promise((resolve, reject) => {
      const variables = {
        nid: nid,
        url: url,
        videoId: videoId ? `%${videoId}%` : "null",
        userId: uid
      };
      this.client
        .request(QUERY_ARTCILES_WITH_SUMMARY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting article :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  articles = (offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_ARTICLES, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting articles :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    get_ongoing_transcriptions = (userId) =>
    new Promise((resolve, reject) => {
      const variables = {
        userId : userId
      };

      this.client
        .request(QUERY_GET_ONGOING_TRANSCRIPTIONS, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.summary_api_logs,
          });
        })
        .catch((err) => {
          let msg = "Error getting on going transcriptions :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  newspapers_eq_country = (country, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        country: country,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_NEWSPAPERS_EQ_COUNTRY, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting newspapers of country :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  articles_to_profile = (user_id, sourceId, dateFrom) =>
    new Promise((resolve, reject) => {
      const currentDate = Math.floor(new Date().getTime() / 1000);
      const variables = {
        uid: user_id,
        sourceId: sourceId ? sourceId : "null",
        dateFrom: dateFrom ? dateFrom : currentDate,
      };

      this.client
        .request(QUERY_PROFILE_ARTICLES, variables)
        .then((data) => {
          logger.log("articles to profile :", data);
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting articles of user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    article_reposts = (user_id, dateTo, dateFrom) =>
    new Promise((resolve, reject) => {
      
      const currentDate = new Date();
      const dateToISOString = dateTo ? new Date(dateTo * 1000).toISOString() : currentDate.toISOString();
      const dateFromISOString = dateFrom ? dateFrom.article ? dateFrom.created_at : dateFrom : currentDate.toISOString();

      let variables = {
        uid: user_id,
        dateFrom: dateFromISOString,
        dateTo: dateToISOString
      };

      if(!dateTo) {
        variables = {
          uid: user_id,
          dateFrom : dateFromISOString
        }
      }
      const QUERY = (!dateTo) ? QUERY_ALL_ARTICLE_REPOSTS : QUERY_ARTICLE_REPOSTS

      this.client
        .request( QUERY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting articles of user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    query_selfposts_by_user = (user_id, sourceId, fromDate, toDate) =>
    new Promise((resolve, reject) => {
      let variables = {
        uid: user_id,
        sourceId: sourceId ? sourceId : "null",
        fromDate: fromDate
      };
      if (toDate != null) {
        variables.toDate = toDate
      } 
      const QUERY = (toDate != null) ? QUERY_SELFPOSTS_BY_USER : QUERY_FIRST_SELFPOSTS_BY_USER;
      this.client
        .request(QUERY, variables)
        .then((data) => {
          logger.log("selfpost articles to profile :", data);
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting selfpost articles of user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  articles_eq_branch = (branch, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        branch: branch,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_ARTICLES_EQ_BRANCH, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.data.articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting articles of branch :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  articles_eq_source = (source_id, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: source_id,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      this.client
        .request(QUERY_ARTICLES_EQ_SOURCE, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting articles of source :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  articles_in_sources = (source_ids, articles_no, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_ids: source_ids,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      const query = articles_no > this.query_threshold ? QUERY_ARTICLES_IN_SOURCES : QUERY_ARTICLES_IN_SOURCES_NULLS_LAST;
      this.client
        .request(query, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting articles in sources :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  articles_in_sources_of_branch = (branch, articles_no, source_ids, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        branch: branch,
        source_ids: source_ids,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      const query = articles_no > this.query_threshold ? QUERY_ARTICLES_IN_SOURCES_OF_BRANCH : QUERY_ARTICLES_IN_SOURCES_OF_BRANCH_NULLS_LAST;

      this.client
        .request(query, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting articles in sources of branch :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  newspapers_in_sources_of_country = (country, articles_no, source_ids, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        country: country,
        source_ids: source_ids,
        pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
        offset: offset,
      };

      const query = articles_no > this.query_threshold ? QUERY_NEWSPAPERS_IN_SOURCES_OF_COUNTRY : QUERY_NEWSPAPERS_IN_SOURCES_OF_COUNTRY_NULLS_LAST;
      this.client
        .request(query, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting newspapers in sources of country :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });


  delete_article = (article_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        nid: article_id,
      };

      this.client
        .request(MUTATION_DELETE_ARTICLE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete article :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_userpost = (post) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_USERPOST, post)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert user post :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_userpost = (post) =>
    new Promise((resolve, reject) => {
      const variables = {
        nid: post.nid,
        summary: post.summary,
        text: post.text,
        published: post.published,
        link_preview: post.link_preview
      };

      this.client
        .request(MUTATION_UPDATE_USERPOST, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert user post :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  approve_userpost = (post_id, approved) =>
    new Promise((resolve, reject) => {
      const variables = {
        nid: post_id,
        approved: approved ? 1 : 0
      };
      this.client
        .request(MUTATION_APPROVE_USERPOST, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error approve user post :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Article Upvotes */
  article_voter_count_by_user = (voter_id, sourceId) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: voter_id,
        sourceId: sourceId
      };

      this.client
        .request(QUERY_ARTICLE_VOTER_COUNTS_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting article voter count by user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  article_inc_upvotes = async (article_id, voter_id, voted_username) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
        voter_id: voter_id,
        voted_username: voted_username,
      };

      this.client
        .request(MUTATION_ARTICLE_INC_UPVOTES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error increment article upvotes :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  article_dec_upvotes = (article_id, voter_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
        voter_id: voter_id,
      };

      this.client
        .request(MUTATION_ARTICLE_DEC_UPVOTES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error decrement article upvotes :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Article Reposts */
  article_repost_by_user = (reposter_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        reposter_id: reposter_id,
      };

      this.client
        .request(QUERY_ARTICLE_REPOST_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting article repost by user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  article_inc_reposts = async (article_id, reposter_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
        reposter_id: reposter_id
      };

      this.client
        .request(MUTATION_ARTICLE_INC_REPOSTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error increment article reposts :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  article_dec_reposts = (article_id, reposter_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
        reposter_id: reposter_id,
      };

      this.client
        .request(MUTATION_ARTICLE_DEC_REPOSTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error decrement article reposts :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Article Save */
  article_save_by_user = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };

      this.client
        .request(QUERY_ARTICLE_SAVE_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting article save by user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  article_save = (article_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
        user_id: user_id
      };

      this.client
        .request(QUERY_ARTICLE_SAVE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error get article save :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_article_save = (article_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
        user_id: user_id
      };

      this.client
        .request(MUTATION_INSERT_ARTICLE_SAVE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert article save :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_article_save = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id
      };

      this.client
        .request(MUTATION_DELETE_ARTICLE_SAVE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete article save :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Article Reports */
  article_reports_by_article = (article_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
      };

      this.client
        .request(QUERY_ARTICLE_REPORTS_BY_ARTICLE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting reports of article :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  article_reports_by_sources = (source_ids) =>
    new Promise((resolve, reject) => {
      const timestamp = new Date() - 86400 * 1000;
      const after = new Date(timestamp).toISOString();

      const variables = {
        source_ids: source_ids,
        after: after,
      };

      this.client
        .request(QUERY_ARTICLE_REPORTS_BY_SOURCES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting reports of articles in sources :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_article_report = (report) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: report.id,
        article_id: report.article_id,
        source_id: report.source_id,
        report: report.report,
        reported_by: report.reported_by,
      };

      this.client
        .request(MUTATION_INSERT_ARTICLE_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert article report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_article_reports = (article_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
      };

      this.client
        .request(MUTATION_DELETE_ARTICLE_REPORTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete article reports :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_article_report = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_ARTICLE_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete article report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_article_report = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_ARTICLE_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update article report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Article Pins */
  article_pins_in_feeds = (feed_ids, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_ids: feed_ids,
      };

      this.client
        .request(QUERY_ARTICLE_PINS_IN_FEEDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting article pins in feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_article_pin = (pin) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: pin.id,
        article_id: pin.article_id,
        feed_id: pin.feed_id,
        pinned_by: pin.pinned_by,
      };

      this.client
        .request(MUTATION_INSERT_ARTICLE_PIN, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert article pin :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_article_pin = (article_id, feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
        feed_id: feed_id,
      };

      this.client
        .request(MUTATION_DELETE_ARTICLE_PIN, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete article pin :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Article MoveTops */
  article_movetops_in_feeds = (feed_ids) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_ids: feed_ids,
      };

      this.client
        .request(QUERY_ARTICLE_MOVETOPS_IN_FEEDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting article movetops in feeds :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_article_movetop = (movetop) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: movetop.id,
        article_id: movetop.article_id,
        feed_id: movetop.feed_id,
        moved_by: movetop.moved_by,
      };

      this.client
        .request(MUTATION_INSERT_ARTICLE_MOVETOP, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert article movetop :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_article_movetop = (article_id, feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
        feed_id: feed_id,
      };

      this.client
        .request(MUTATION_DELETE_ARTICLE_MOVETOP, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete article movetop :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  User Operations */
  user_by_id = (uid) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
      };

      this.client
        .request(QUERY_USER_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  user_by_username = (username) =>
    new Promise((resolve, reject) => {
      const variables = {
        username: username,
      };

      this.client
        .request(QUERY_USER_BY_USERNAME, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting user by username :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  users_for_leaderboards = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_USERS_FOR_LEADERBOARDS)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting users for leaderboards :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  users_by_searchkey = (searchkey) =>
    new Promise((resolve, reject) => {
      const variables = {
        searchkey: `%${searchkey}%`,
      };

      this.client
        .request(QUERY_USERS_BY_SEARCHKEY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting users by searchkey :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_user = (user) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_USER, user)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_user = (user) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_USER, user)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_user_phone = (uid, phone) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
        phone: phone
      };

      this.client
        .request(MUTATION_UPDATE_USER_PHONE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user phone:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    update_user_email = (uid, email) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
        email: email
      };

      this.client
        .request(MUTATION_UPDATE_USER_EMAIL, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user email:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_user_links = (uid, links) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
        links: links
      };

      this.client
        .request(MUTATION_UPDATE_USER_LINKS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user links:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_user_msgtoken = (uid, msgtoken) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
        msgToken: msgtoken
      };

      this.client
        .request(MUTATION_UPDATE_USER_MSGTOKEN, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user msgtoken :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_user_skipprofile = (uid, skipprofile) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
        skipProfile: skipprofile
      };

      this.client
        .request(MUTATION_UPDATE_USER_SKIPPROFILE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user skip profile :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_user_subscribe_email = (uid, email) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
        subscribe_email: email
      };

      this.client
        .request(MUTATION_UPDATE_USER_SUBSCRIBE_EMAIL, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user subscribe email :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_user_subscribe_email = (uid) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid
      };

      this.client
        .request(MUTATION_DELETE_USER_SUBSCRIBE_EMAIL, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete user subscribe email :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  
  update_user_project_scistarter = (uid, scistarter) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
        project_scistarter: scistarter
      };

      this.client
        .request(MUTATION_UPDATE_USER_PROJECT_SCISTATER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user project scistarter :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_user_project_librarykit = (uid, librarykit) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
        project_librarykit: librarykit
      };

      this.client
        .request(MUTATION_UPDATE_USER_PROJECT_LIBRARYKIT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user project librarykit :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  add_user = (user) =>
    new Promise(async (resolve, reject) => {
      var isNew = true;
      this.user_by_id(user.uid)
        .then((data) => {
          if (data.data.users.length > 0) {
            isNew = false;
            return data.data.users;
          } else {
            isNew = true;
            return this.insert_user(user);
          }
        })
        .then((result) => {
          let data = null;
          if (isNew) {
            data = result.data.insert_users.returning;
            data.isNew = true;
          } else {
            data = result;
          }
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user approved :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Tag User */
  tag_user = (tag_user) =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_TAG_USER_BY_REF, tag_user)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error get tag_user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  add_tag_user = (tag_user) =>
    new Promise((resolve, reject) => {
      this.tag_user(tag_user)
        .then((data) => {
          if (data.data.tag_user.length > 0) {
            resolve({
              status_code: GRAPHQL_SUCCESS,
              data,
            });
          } else {
            this.client
              .request(MUTATION_ADD_TAG_USER, tag_user)
              .then((data) => {
                resolve({
                  status_code: GRAPHQL_SUCCESS,
                  data,
                });
              })
              .catch((err) => {
                let msg = "Error insert tag_user :" + err;
                logger.error(msg);
                reject({ status_code: GRAPHQL_ERROR, msg: msg });
              });
          }
        })
        .catch((err) => {
          let msg = "Error get tag_user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_tag_user = (tag_user) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_DELETE_TAG_USER, tag_user)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete tag_user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  ActivityLogs */
  activitylogs_by_user = (uid) =>
    new Promise((resolve, reject) => {
      const variables = {
        uid: uid,
      };

      this.client
        .request(QUERY_ACTIVITYLOGS_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting activitylogs by user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  activitylogs_by_type = (type) =>
    new Promise((resolve, reject) => {
      const variables = {
        type: type,
      };

      this.client
        .request(QUERY_ACTIVITYLOGS_BY_TYPE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting activitylogs by type :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  activitylogs_by_typeid = (type, type_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        type: type,
        type_id: type_id,
      };

      this.client
        .request(QUERY_ACTIVITYLOGS_BY_TYPEID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting activitylogs by typeid :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_activitylog = (activitylog) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_ACTIVITYLOG, activitylog)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert activitylog :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  /* #endregion */

  /* #region  Scrape Request */
  scrape_request = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_SCRAPE_REQUEST)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting scrape request :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_scrape_request = (scrape_request_info) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_SCRAPE_REQUEST, scrape_request_info)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert scrape request :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_scrape_request = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_SCRAPE_REQUEST, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete scrape request :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Comment */
  top_comments_by_newest = (thread_id, approved, offset) =>
    new Promise((resolve, reject) => {
      let variables = {
        thread_id: thread_id,
        pagesize: this.pagesize,
        offset: offset
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_TOP_COMMENTS_BY_NEWEST : QUERY_TOP_COMMENTS_BY_NEWEST_APPROVE;
      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting top thread comments (by newest):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  top_comments_by_oldest = (thread_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        pagesize: this.pagesize,
        offset: offset
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_TOP_COMMENTS_BY_OLDEST : QUERY_TOP_COMMENTS_BY_OLDEST_APPROVE;
      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting top thread comments (by oldest):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  top_comments_by_recommends = (thread_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        pagesize: this.pagesize,
        offset: offset
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_TOP_COMMENTS_BY_RECOMMENDS : QUERY_TOP_COMMENTS_BY_RECOMMENDS_APPROVE;

      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting top thread comments (by recommends):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  top_comments_by_replies = (thread_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        pagesize: this.pagesize,
        offset: offset
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_TOP_COMMENTS_BY_REPLIES : QUERY_TOP_COMMENTS_BY_REPLIES_APPROVE;
      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting top thread comments (by replies):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  comments_by_newest = (thread_id, parent_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        parent_id: parent_id,
        pagesize: this.pagesize,
        offset: offset,
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_COMMENTS_BY_NEWEST : QUERY_COMMENTS_BY_NEWEST_APPROVE;
      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting thread comments (by newest):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  comments_by_oldest = (thread_id, parent_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        parent_id: parent_id,
        pagesize: this.pagesize,
        offset: offset,
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_COMMENTS_BY_OLDEST : QUERY_COMMENTS_BY_OLDEST_APPROVE;
      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting thread comments (by oldest):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  comments_by_recommends = (thread_id, parent_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        parent_id: parent_id,
        pagesize: this.pagesize,
        offset: offset,
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_COMMENTS_BY_RECOMMENDS : QUERY_COMMENTS_BY_RECOMMENDS_APPROVE;
      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting thread comments (by recommends):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  comments_by_replies = (thread_id, parent_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        parent_id: parent_id,
        pagesize: this.pagesize,
        offset: offset,
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_COMMENTS_BY_REPLIES : QUERY_COMMENTS_BY_REPLIES_APPROVE;

      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting thread comments (by replies):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_comment = (comment) =>
    new Promise((resolve, reject) => {
      let variables = {
        article_id: comment.article_id ? comment.article_id : null,
        reading_id: comment.reading_id ? comment.reading_id : null,
        thread_id: comment.thread_id,
        text: comment.text,
        author_id: comment.author_id,
        approved: comment.approved,
        approved_by: comment.approved_by,
        approved_at: comment.approved_at
      };
      if (comment.parent_id !== null) {
        variables.parent_id = comment.parent_id;
      }

      this.client
        .request(MUTATION_INSERT_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_comment = (id, text, modified_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        text: text,
        modified_at: modified_at,
        approved:true
      };

      this.client
        .request(MUTATION_UPDATE_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  approve_comment = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_APPROVE_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error approving comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_comment = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  deleteall_comment = (thread_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
      };

      this.client
        .request(MUTATION_DELETEALL_COMMENTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting all comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  comment_recommender = (comment_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        comment_id: comment_id,
        user_id: user_id,
      };

      this.client
        .request(QUERY_COMMENT_RECOMMENDER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting comment recommender :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  comment_inc_recommends = async (comment_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        comment_id: comment_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_COMMENT_INC_RECOMMENDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error increment comment recommends :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  comment_dec_recommends = (comment_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        comment_id: comment_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_COMMENT_DEC_RECOMMENDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error decrement comment recommends :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Comment Reports */
  comments_reports_by_comment = (comment_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        comment_id: comment_id,
      };

      this.client
        .request(QUERY_COMMENT_REPORTS_BY_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting reports of comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_comment_report = (report) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: report.id,
        comment_id: report.comment_id,
        report: report.report,
        reported_by: report.reported_by,
      };

      this.client
        .request(MUTATION_INSERT_COMMENT_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert comment report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
    
 // for getting comment report count for past 90 days
  comment_reports_count = (id,time) =>
  new Promise((resolve, reject) => {
    const variables = {
      id:id,
      time:time
    };

    this.client
      .request(QUERY_COMMENTS_REPORTS_COUNT, variables)
      .then((data) => {
        resolve({
          status_code: GRAPHQL_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        let msg = "Error getting comment report count :" + err;
        logger.error(msg);
        reject({ status_code: GRAPHQL_ERROR, msg: msg });
      });
  });

  delete_comment_report = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_COMMENT_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete comment report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_comment_report = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_COMMENT_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update comment report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Threads */
  threads = (offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        pagesize: this.pagesize * 2,
        offset: offset,
      };

      this.client
        .request(QUERY_THREADS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting threads:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  threads_removed_home = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_THREADS_REMOVED_HOME)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting threads removed from home:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  threads_by_feeds = (feed_id, feed_source_ids, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = approved === null ? {
        feed_id: feed_id,
        source_ids: feed_source_ids,
        pagesize: this.pagesize * 2,
        offset: offset,
      } : {
        feed_id: feed_id,
        source_ids: feed_source_ids,
        approved: approved,
        pagesize: this.pagesize * 2,
        offset: offset,
      };
      const query = approved === null ? QUERY_THREADS_BY_FEEDS : QUERY_THREADS_APPROVED_BY_FEEDS;

      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting threads of feeds:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  thread_by_id = (thread_id, approved) =>
    new Promise((resolve, reject) => {
      const variables = approved === null ? {
        thread_id: thread_id
      } : {
        thread_id: thread_id,
        approved: approved
      };
      const query = approved === null ? QUERY_THREAD_BY_ID : QUERY_THREAD_APPROVED_BY_ID;

      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting thread by id:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  thread_by_article = (article_id, approved) =>
    new Promise((resolve, reject) => {
      const variables = approved === null ? {
        article_id: article_id
      } : {
        article_id: article_id,
        approved: approved
      };
      const query = approved === null ? QUERY_THREAD_BY_ARTICLE : QUERY_THREAD_APPROVED_BY_ARTICLE

      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting article thread:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_thread = (thread) =>
    new Promise((resolve, reject) => {
      let variables = {
        title: thread.title,
        text: thread.text,
        type: thread.type,
        posted_by: thread.posted_by ? thread.posted_by : null,
        feed_id: thread.feed_id ? thread.feed_id : null,
        from: thread.from ? thread.from : null,
        approved: thread.approved,
        approved_at: thread.approved_at,
        approved_by: thread.approved_by,
      };
      // if (thread.posted_by !== null) {
      //   variables.posted_by = thread.posted_by;
      //   variables.feed_id = thread.feed_id;
      // }
      // if (thread.from !== null) {
      //   variables.from = thread.from;
      // }

      this.client
        .request(MUTATION_INSERT_THREAD, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting thread :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  approve_thread = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_APPROVE_THREAD, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error approving thread :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  close_thread = (id, closed, closed_by, closed_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        closed: closed,
        closed_by: closed_by,
        closed_at: closed_at,
      };

      this.client
        .request(MUTATION_CLOSE_THREAD, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error closing thread :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  remove_thread_from_home = (id, removed, removed_by, removed_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        removed_home: removed,
        removed_home_by: removed_by,
        removed_home_at: removed_at,
      };

      this.client
        .request(MUTATION_THREAD_REMOVE_HOME, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error removing thread from home :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  remove_thread_from_cleanair = (id, removed, removed_by, removed_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        removed_cleanair: removed,
        removed_cleanair_by: removed_by,
        removed_cleanair_at: removed_at,
      };

      this.client
        .request(MUTATION_THREAD_REMOVE_CLEANAIR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error removing thread from cleanair :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_thread = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_THREAD, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting thread :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_thread_by_from = (article_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
      };

      this.client
        .request(MUTATION_DELETE_THREAD_BY_FROM, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting thread by from :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  maplocation_threads = (offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        pagesize: this.pagesize * 2,
        offset: offset,
      };

      this.client
        .request(QUERY_MAPLOCATION_THREADS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting map location threads:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  maplocation_threads_removed_cleanair = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_MAPLOCATION_THREADS_REMOVED_CLEANAIR)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting map location threads removed from cleanair:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  threads_by_maplocation = (location_id, feed_source_ids, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        source_ids: feed_source_ids,
        approved: approved,
        pagesize: this.pagesize * 2,
        offset: offset,
      };

      this.client
        .request(QUERY_THREADS_BY_MAPLOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting threads of map location:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  thread_by_mapreading = (reading_id, approved) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: reading_id,
        approved: approved
      };

      this.client
        .request(QUERY_THREAD_BY_MAPREADING, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting map reading thread:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_thread_by_mapreading = (reading_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: reading_id,
      };

      this.client
        .request(MUTATION_DELETE_THREAD_BY_MAPREADING, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting thread by map reading :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Thread Upvotes */
  thread_voter = (thread_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        user_id: user_id,
      };

      this.client
        .request(QUERY_THREAD_VOTER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting thread voter :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  thread_inc_upvotes = async (thread_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_THREAD_INC_UPVOTES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error increment thread upvotes :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  thread_dec_upvotes = (thread_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_THREAD_DEC_UPVOTES, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error decrement thread upvotes :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Thread Reports */
  thread_reports_by_thread = (thread_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        thread_id: thread_id,
      };

      this.client
        .request(QUERY_THREAD_REPORTS_BY_THREAD, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting thread reports :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  thread_reports_by_type = (type) =>
    new Promise((resolve, reject) => {
      const variables = {
        type: type,
      };

      this.client
        .request(QUERY_THREAD_REPORTS_BY_TYPE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting thread reports by type :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_thread_report = async (feed_id, report) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
        thread_id: report.thread_id,
        type: report.type,
        report: report.report,
        reported_by: report.reported_by
      }

      this.client
        .request(MUTATION_INSERT_THREAD_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting thread report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  approve_thread_report = async (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_APPROVE_THREAD_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error approving thread report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_thread_report = async (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_THREAD_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting thread report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Banned User */
  banned_users_by_feed = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_BANNED_USERS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting banned users :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_banned_user = async (banned) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: banned.user_id,
        feed_id: banned.feed_id,
        banned_at: banned.banned_at,
        banned_till: banned.banned_till,
        type: banned.type,
        banned_by: banned.banned_by,
      };

      this.client
        .request(MUTATION_INSERT_BANNED_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting banned user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_banned_user = async (banned) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: banned.user_id,
        feed_id: banned.feed_id,
        banned_at: banned.banned_at,
        banned_till: banned.banned_till,
        type: banned.type,
        banned_by: banned.banned_by,
      };

      this.client
        .request(MUTATION_UPDATE_BANNED_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating banned user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_banned_user = async (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_BANNED_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting banned user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Pre Approved User */
  preapproved_users_by_feed = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_PREAPPROVED_USERS_BY_FEED, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting preapproved users :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_preapproved_user = async (approved) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: approved.user_id,
        feed_id: approved.feed_id,
        approved_by: approved.approved_by,
      };

      this.client
        .request(MUTATION_INSERT_PREAPPROVED_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting preapproved user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_preapproved_user = async (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_PREAPPROVED_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting preapproved user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  User Follower */
  users_by_follower = (follower_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        follower_id: follower_id,
      };

      this.client
        .request(QUERY_USERS_BY_FOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting users by follower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_user_follower = async (user_id, follower_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
        follower_id: follower_id
      };

      this.client
        .request(MUTATION_INSERT_USER_FOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting user follower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_user_follower = async (user_id, follower_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
        follower_id: follower_id
      };

      this.client
        .request(MUTATION_DELETE_USER_FOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting user follower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  User Invite */
  invites_by_user = (invitee) =>
    new Promise((resolve, reject) => {
      const variables = {
        invitee: invitee,
      };

      this.client
        .request(QUERY_INVITES_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting users by invitee :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  invites_by_phone = (phone) =>
    new Promise((resolve, reject) => {
      const variables = {
        phone: phone,
      };

      this.client
        .request(QUERY_INVITES_BY_PHONE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting users by inviter :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_user_invite = async (invitee, inviter_id, invited_to, is_phone) =>
    new Promise((resolve, reject) => {
      const variables = {
        invitee: invitee,
        is_phone: is_phone,
        invited_by: inviter_id,
        invited_to: invited_to,
      };

      this.client
        .request(MUTATION_INSERT_USER_INVITE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting user invite :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_user_invite_by_phone = async (phoneno, inviter_id, invited_to, is_phone) =>
    new Promise((resolve, reject) => {
      const variables = {
        phoneno: phoneno,
        is_phone: is_phone,
        invited_by: inviter_id,
        invited_to: invited_to,
      };

      this.client
        .request(MUTATION_INSERT_USER_INVITE_BYPHONE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting user invite by phone:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_user_invite = async (user_id, inviter_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
        invited_by: inviter_id
      };

      this.client
        .request(MUTATION_DELETE_USER_INVITE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting user invite :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region User Blockmutes */
  blockmutes_by_user = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };
      this.client
        .request(QUERY_BLOCKMUTES_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting user blockmutes :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_user_blockmute = async (blockmuteInfo) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: blockmuteInfo.user_id,
        type: blockmuteInfo.type,
        target_user_id: blockmuteInfo.target_user_id,
        enabled: blockmuteInfo.enabled,
        created_at: blockmuteInfo.created_at,
      };

      this.client
        .request(MUTATION_INSERT_USER_BLOCKMUTE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting user blockmute :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  User Reports */
  user_reports_by_user = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };

      this.client
        .request(QUERY_USER_REPORTS_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting user reports :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_user_report = (report) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: report.user_id,
        report: report.report,
        reported_by: report.reported_by,
        approved: report.approved,
      };

      this.client
        .request(MUTATION_INSERT_USER_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert user report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_user_report = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_USER_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete user report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_user_reports = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };

      this.client
        .request(MUTATION_DELETE_USER_REPORTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete user reports :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });


  update_user_report = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_USER_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region User Suspensions */
  suspensions_by_user = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };
      this.client
        .request(QUERY_SUSPENSIONS_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting user suspensions :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_user_suspension = async (suspensionInfo) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: suspensionInfo.user_id,
        type: suspensionInfo.type,
        from: suspensionInfo.from,
        till: suspensionInfo.till,
        suspended_by: suspensionInfo.suspended_by,
        enabled: suspensionInfo.enabled,
        suspended_at: suspensionInfo.suspended_at,
      };

      this.client
        .request(MUTATION_INSERT_USER_SUSPENSION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting user suspension :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Trending */
  trending_sources = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_TRENDING_SOURCES)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting trending sources :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  trending_articles_of_branch = (branch, source_ids, last_time) =>
    new Promise((resolve, reject) => {
      const variables = {
        branch: branch,
        source_ids: source_ids,
        last_time: last_time
      };

      this.client
        .request(QUERY_TRENDING_ARTICLES_OF_BRANCH, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting trending articles of branch :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  trending_articles_of_branch_country = (branch, country, source_ids, last_time) =>
    new Promise((resolve, reject) => {
      const variables = {
        branch: branch,
        country: country,
        source_ids: source_ids,
        last_time: last_time
      };

      this.client
        .request(QUERY_TRENDING_ARTICLES_OF_BRANCH_COUNTRY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting trending articles of branch and country :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  User Feed ShowFirst */
  showfirst_feeds_by_user = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };

      this.client
        .request(QUERY_SHOWFIRST_FEEDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting showfirst feeds by user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_user_feed_showfirst = async (user_id, feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
        feed_id: feed_id
      };

      this.client
        .request(MUTATION_INSERT_USER_FEED_SHOWFIRST, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting user feed showfirst :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_user_feed_showfirst = async (user_id, feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
        feed_id: feed_id
      };

      this.client
        .request(MUTATION_DELETE_USER_FEED_SHOWFIRST, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting user feed showfirst :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Notifications */
  notifications_to_user = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };

      this.client
        .request(QUERY_NOTIFICATIONS_TO_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting notifications to user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_notification = async (notification) =>
    new Promise((resolve, reject) => {

      this.client
        .request(MUTATION_INSERT_NOTIFICATION, notification)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting notification :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  confirm_notification = async (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id
      };

      this.client
        .request(MUTATION_CONFIRM_NOTIFICATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error confirm notification :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Stats */
  get_uptimestats = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_STATS)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting uptime stats :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Map Regions */
  map_regions = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_MAP_REGIONS)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting regions :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_regions_for_leaderboards = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_MAP_REGIONS_FOR_LEADERBOARDS)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting regions for leaderboards :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_region_by_id = (region_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: region_id
      };

      this.client
        .request(QUERY_MAP_REGION_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting region by id :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_region_by_slug = (slug) =>
    new Promise((resolve, reject) => {
      const variables = {
        slug: slug,
      };

      this.client
        .request(QUERY_MAP_REGION_BY_SLUG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting region by slug :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_map_region = (region) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_MAP_REGION, region)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting map region :" + err;
          logger.error(msg);
          resolve({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_map_region = (region_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: region_id
      };

      this.client
        .request(MUTATION_DELETE_MAP_REGION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting map region :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_map_region_moderator = (region) =>
    new Promise((resolve, reject) => {
      const variables = {
        region_id: region.region_id,
        user_id: region.user_id,
        approved: region.approved,
        approved_at: region.approved_at,
        approved_by: region.approved_by
      };

      this.client
        .request(MUTATION_INSERT_REGION_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting map region moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  approve_map_region_moderator = (moderator_id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: moderator_id,
        approved: approved,
        approved_at: approved_at,
        approved_by: approved_by
      };

      this.client
        .request(MUTATION_APPROVE_REGION_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error approving map region moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_map_region_moderator = (moderator_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: moderator_id
      };

      this.client
        .request(MUTATION_DELETE_REGION_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting map region moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Map Locations */
  map_location_by_id = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id
      };

      this.client
        .request(QUERY_MAP_LOCATION_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_location_count_by_slug = (slug) =>
    new Promise((resolve, reject) => {
      const variables = {
        slug: slug
      };

      this.client
        .request(QUERY_MAP_LOCATION_COUNT_BY_SLUG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_location_by_slug = (slug) =>
    new Promise((resolve, reject) => {
      const variables = {
        slug: slug
      };

      this.client
        .request(QUERY_MAP_LOCATION_BY_SLUG, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_locations_by_box = (lng_min, lng_max, lat_min, lat_max) =>
    new Promise((resolve, reject) => {
      const variables = {
        lng_min: lng_min,
        lng_max: lng_max,
        lat_min: lat_min,
        lat_max: lat_max
      };

      this.client
        .request(QUERY_MAP_LOCATIONS_BY_BOX, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting locations by box :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_locations_by_box_dday = (lng_min, lng_max, lat_min, lat_max, dday) =>
    new Promise((resolve, reject) => {
      const variables = {
        lng_min: lng_min,
        lng_max: lng_max,
        lat_min: lat_min,
        lat_max: lat_max,
        dday: dday
      };

      this.client
        .request(QUERY_MAP_LOCATIONS_BY_BOX_DDAY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting locations by box :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_locations_for_leaderboards = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_MAP_LOCATIONS_FOR_LEADERBOARDS)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting locations for leaderboards :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_locations_by_region = (region_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        region_id: region_id
      };

      this.client
        .request(QUERY_MAP_LOCATIONS_BY_REGION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting locations by region :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_map_location = (location) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_MAP_LOCATION, location)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_map_location = (location) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_MAP_LOCATION, location)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        }, (reason) => {
          return;
        })
        .catch((err) => {
          let msg = "Error updating location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_map_location_last_co2 = (location_id, co2) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: location_id,
        co2: co2
      };

      this.client
        .request(MUTATION_UPDATE_MAP_LOCATION_LAST_CO2, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating location last co2 :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_map_location_op_readings = (location_id, readings) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: location_id,
        readings: readings
      };

      this.client
        .request(MUTATION_UPDATE_MAP_LOCATION_OP_READINGS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating location op readings :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_map_location_op_imageupload = (location_id, imageupload) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: location_id,
        imageupload: imageupload
      };

      this.client
        .request(MUTATION_UPDATE_MAP_LOCATION_OP_IMAGEUPLOAD, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating location op imageupload :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_map_location_op_moderation = (location_id, moderation) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: location_id,
        moderation: moderation
      };

      this.client
        .request(MUTATION_UPDATE_MAP_LOCATION_OP_MODERATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating location op moderation :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_map_location_op_description = (location_id, description) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: location_id,
        description: description
      };

      this.client
        .request(MUTATION_UPDATE_MAP_LOCATION_OP_DESCRIPTION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating location op description :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_map_location_op_comments = (location_id, comments) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: location_id,
        comments: comments
      };

      this.client
        .request(MUTATION_UPDATE_MAP_LOCATION_OP_COMMENTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating location op comments :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_map_location_readingconf = (location_id, conf) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: location_id,
        conf: conf
      };

      this.client
        .request(MUTATION_UPDATE_MAP_LOCATION_READINGCONF, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_map_location = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id
      };

      this.client
        .request(MUTATION_DELETE_MAP_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Location Reports */
  location_reports_by_location = (location_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
      };

      this.client
        .request(QUERY_LOCATION_REPORTS_BY_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting reports of location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_location_report = (report) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: report.location_id,
        report: report.report,
        reported_by: report.reported_by,
      };

      this.client
        .request(MUTATION_INSERT_LOCATION_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert location report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_location_report = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_LOCATION_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete location report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  approve_location_report = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_APPROVE_LOCATION_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error approve location report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Location Moderators */
  location_moderators_by_location = (location_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
      };

      this.client
        .request(QUERY_LOCATION_MODERATORS_BY_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting moderators of location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_location_moderator = (location_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        user_id: user_id
      };

      this.client
        .request(QUERY_LOCATION_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting moderators of location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_map_location_moderator = (moderator) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: moderator.user_id,
        location_id: moderator.location_id,
        approved: moderator.approved,
        approved_by: moderator.approved_by,
        approved_at: moderator.approved_at
      };

      this.client
        .request(MUTATION_INSERT_LOCATION_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert location moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_map_location_moderator = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_LOCATION_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete location moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  approve_map_location_moderator = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at
      };

      this.client
        .request(MUTATION_APPROVE_LOCATION_MODERATOR, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error approve location moderator :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Location Followers */
  location_followers_by_uid = (user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: user_id,
      };

      this.client
        .request(QUERY_LOCATION_FOLLOWERS_BY_UID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting followers of location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  location_followers_by_location = (location_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
      };

      this.client
        .request(QUERY_LOCATION_FOLLOWERS_BY_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting followers of location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_location_follower = (follower) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: follower.location_id,
        user_id: follower.user_id
      };

      this.client
        .request(MUTATION_INSERT_LOCATION_FOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert location follower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_location_follower = (follower) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: follower.location_id,
        user_id: follower.user_id
      };

      this.client
        .request(MUTATION_DELETE_LOCATION_FOLLOWER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete location follower :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Map Readings */
  get_map_readings_by_location = (location_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id
      };

      this.client
        .request(QUERY_MAP_READINGS_BY_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting readings :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_map_reading = (reading) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_MAP_READING, reading)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert reading :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_map_reading = (reading) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_MAP_READING, reading)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating reading :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  approve_map_reading = (reading_id, approved_at, approved_by) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: reading_id,
        approved: true,
        approved_at: approved_at,
        approved_by: approved_by
      };
      this.client
        .request(MUTATION_APPROVE_MAP_READING, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error approve reading :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_map_reading = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id
      };

      this.client
        .request(MUTATION_DELETE_MAP_READING, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting reading :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_reading_recommender = (reading_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: reading_id,
        user_id: user_id,
      };

      this.client
        .request(QUERY_READING_RECOMMENDER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting map reading recommender :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_reading_inc_recommends = async (reading_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: reading_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_READING_INC_RECOMMENDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error increment map reading recommends :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_reading_dec_recommends = (reading_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: reading_id,
        user_id: user_id,
      };

      this.client
        .request(MUTATION_READING_DEC_RECOMMENDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error decrement map reading recommends :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Reading Reports */
  reading_reports_by_reading = (reading_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: reading_id,
      };

      this.client
        .request(QUERY_READING_REPORTS_BY_READING, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting reports of reading :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  reading_reports_by_location = (location_id, after) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        after: after
      };

      this.client
        .request(QUERY_READING_REPORTS_BY_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting reading reports of the location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_reading_report = (report) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: report.reading_id,
        location_id: report.location_id,
        report: report.report,
        reported_by: report.reported_by,
      };

      this.client
        .request(MUTATION_INSERT_READING_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert reading report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_reading_report = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_READING_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete reading report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  approve_reading_report = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_APPROVE_READING_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error approve reading report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Mappost */
  map_post_by_nid = (nid) =>
    new Promise((resolve, reject) => {
      const variables = {
        nid: nid,
      };

      this.client
        .request(QUERY_MAPPOST_BY_NID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting map post :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_posts_by_region = (region_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        region_id: region_id
      };

      this.client
        .request(QUERY_MAPPOSTS_BY_REGION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting map posts of region :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  map_posts_by_location = (location_id, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        pagesize: this.pagesize,
        offset: offset
      };

      this.client
        .request(QUERY_MAPPOSTS_BY_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting map posts of location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Location Moderation */
  location_moderation_count = (location_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id
      };

      this.client
        .request(QUERY_LOCATION_MODERATION_COUNT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting location moderation count :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  location_moderation_fields = (location_id, after, logtype) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        location_id_str: location_id.toString(),
        after: after,
        logtype: logtype
      };

      this.client
        .request(QUERY_LOCATION_MODERATION_FIELDS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting location moderation fields :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_location_notifications = (location_id, notifications) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        notifications: notifications
      };

      this.client
        .request(MUTATION_UPDATE_LOCATION_NOTIFICATIONS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating location notifications :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Moderator Reports(Map location) */
  maplocation_moderator_reports_by_location = (location_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
      };

      this.client
        .request(QUERY_MAPLOCATION_MODERATOR_REPORTS_BY_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting location moderator reports :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_maplocation_moderator_report = (report) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: report.id,
        moderator_id: report.moderator_id,
        location_id: report.location_id,
        report: report.report,
        reported_by: report.reported_by,
      };

      this.client
        .request(MUTATION_INSERT_MAPLOCATION_MODERATOR_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert location moderator report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_maplocation_moderator_report = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_MAPLOCATION_MODERATOR_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete location moderator report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_maplocation_moderator_reports = (location_id, moderator_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        moderator_id: moderator_id,
      };

      this.client
        .request(MUTATION_DELETE_MAPLOCATION_MODERATOR_REPORTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete location moderator reports :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });


  update_maplocation_moderator_report = (id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_MAPLOCATION_MODERATOR_REPORT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update location moderator report :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  /* #endregion */

  /* #region  Map location Banned User */
  maplocation_banned_users_by_location = (location_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
      };

      this.client
        .request(QUERY_MAPLOCATION_BANNED_USERS_BY_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting map location banned users :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_maplocation_banned_user = async (banned) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: banned.user_id,
        location_id: banned.location_id,
        banned_at: banned.banned_at,
        banned_by: banned.banned_by,
        type: banned.type,
        banned_till: banned.banned_till
      };

      this.client
        .request(MUTATION_INSERT_MAPLOCATION_BANNED_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting map location banned user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_maplocation_banned_user = async (banned) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: banned.user_id,
        location_id: banned.location_id,
        banned_at: banned.banned_at,
        banned_by: banned.banned_by,
        type: banned.type,
        banned_till: banned.banned_till
      };

      this.client
        .request(MUTATION_UPDATE_MAPLOCATION_BANNED_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating map location banned user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_maplocation_banned_user = async (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_MAPLOCATION_BANNED_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting map location banned user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Map location Rooms */
  maplocation_rooms_by_location = (location_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
      };

      this.client
        .request(QUERY_MAPLOCATION_ROOMS_BY_LOCATION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting map location rooms :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_maplocation_room = async (room) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: room.location_id,
        name: room.name,
        created_by: room.created_by,
      };

      this.client
        .request(MUTATION_INSERT_MAPLOCATION_ROOM, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error inserting map location room :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_maplocation_room = async (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_MAPLOCATION_ROOM, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting map location room :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  /* #endregion */
  insert_mask_friendly_hour = async (times) =>
  new Promise((resolve, reject) => {
    const variables = times

    this.client
      .request(MUTATION_INSERT_MASK_FRIENDLY_HOUR, variables)
      .then((data) => {
        
        resolve({
          status_code: GRAPHQL_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        let msg = "Error inserting map location room :" + err;
        logger.error(msg);
        reject({ status_code: GRAPHQL_ERROR, msg: msg });
      });
  });
/* #endregion */

  /* #region  MapTags-Category */
  maptags = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_MAPTAGS)
        .then(data => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data
          });
        })
        .catch(err => {
          let msg = 'Error getting map tags :' + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  maptags_by_category = category_id =>
    new Promise((resolve, reject) => {
      const variables = {
        category_id: category_id
      };

      this.client
        .request(QUERY_MAPTAG_CATEGORY_BY_CATEGORY, variables)
        .then(data => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data
          });
        })
        .catch(err => {
          let msg = 'Error get maptag category :' + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  /* #region  Map location Comments */
  maplocation_comments = (location_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
      };

      this.client
        .request(QUERY_MAPLOCATION_COMMENTS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting comments of map location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  maplocation_comment = (location_id, comment_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        comment_id: comment_id
      };

      this.client
        .request(QUERY_MAPLOCATION_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting a comment of map location :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_maplocation_comment = (comment) =>
    new Promise((resolve, reject) => {

      this.client
        .request(MUTATION_INSERT_MAPLOCATION_COMMENT, comment)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert map location comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_maplocation_comment = (location_id, comment_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        comment_id: comment_id
      };

      this.client
        .request(MUTATION_DELETE_MAPLOCATION_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete map location comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_maplocation_comment = (location_id, comment_id, approved, approved_by, approved_at) =>
    new Promise((resolve, reject) => {
      const variables = {
        location_id: location_id,
        comment_id: comment_id,
        approved: approved,
        approved_by: approved_by,
        approved_at: approved_at,
      };

      this.client
        .request(MUTATION_UPDATE_MAPLOCATION_COMMENT, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update map location comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  /* #endregion */

  update_feed_aicommentconf = (id, commentconf) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        conf: commentconf,
      };
      this.client
        .request(MUTATION_UPDATE_FEED_AICOMMENTCONF, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed ai comment configuration :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    update_feed_image = (id, image) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
        image: image,
      };
      this.client
        .request(MUTATION_UPDATE_FEED_IMAGE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed ai comment configuration :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  create_ai_person = (user) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_INSERT_AI_PERSONS, user)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error create ai person :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    get_ai_persons = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_GET_AI_PERSONS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting AI persons: " + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  ai_persons_by_feed = (feed_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        feed_id: feed_id,
      };

      this.client
        .request(QUERY_AI_PERSONS_BY_FEED_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting ai persons by feed :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_ai_persons = async (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_DELETE_AI_PERSON, variables)
        .then((result) => {

          console.log(result.delete_ai_persons.returning[0])
          const userId = result.delete_ai_persons.returning[0].user_id;
          this.delete_associative_ai_persons_from_associative_feeds(userId);
          return this.delete_ai_users(userId);
        })
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting ai person :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_ai_users = async (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        user_id: id,
      };

      this.client
        .request(MUTATION_DELETE_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error deleting ai user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  delete_associative_ai_persons = (id) => 
  new Promise((resolve, reject) => {
    const variables = {
      id: id,
    };
    this.client
      .request(MUTATION_DELETE_AI_PERSON, variables)
      .then((result) => {

        console.log("deleted result of associative ai persons :",result.delete_ai_persons.returning[0])
        // const userId = result.delete_ai_persons.returning[0].user_id;
        return result.delete_ai_persons.returning[0]
      })
      .then((data) => {
        resolve({
          status_code: GRAPHQL_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        let msg = "Error deleting associative ai person :" + err;
        logger.error(msg);
        reject({ status_code: GRAPHQL_ERROR, msg: msg });
      });
  });

  delete_associative_ai_persons_from_associative_feeds =(id)=>
  new Promise((resolve, reject) => {
    const variables = {
      user_id: id,
    };
    this.client
      .request(MUTATION_DELETE_ASSOCIATIVE_AI_PERSONS_FROM_ASSOCIATIVE_FEEDS, variables)
      .then((result) => {
        console.log("deleted result of associative ai persons from associative feeds :",result.delete_ai_persons.returning[0])
        return result.delete_ai_persons.returning[0]
      })
      .then((data) => {
        resolve({
          status_code: GRAPHQL_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        let msg = "Error deleting associative ai person :" + err;
        logger.error(msg);
        reject({ status_code: GRAPHQL_ERROR, msg: msg });
      });
  });

  insert_ai_comment = (comment) =>
    new Promise((resolve, reject) => {

      this.client
        .request(MUTATION_INSERT_AI_COMMENT, comment)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert ai comment :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
 
  update_aiuser = (user) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_AI_USER, user)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_aipersonas = (user) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_AI_PERSONAS, user)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_associate_ai_persons =(user ) =>
    new Promise((resolve, reject) => {
      this.client
        .request(MUTATION_UPDATE_ASSOCIATE_AI_PERSONS, user)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update user :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  
  articles_summary = (source_ids) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_ids: source_ids
      };

      const query = QUERY_AI_SUMMARY;

      this.client
        .request(query, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting articles summary :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  update_feed_aisummaryconf = (id, summaryconf) =>
      new Promise((resolve, reject) => {
        const variables = {
          id: id,
          conf: summaryconf,
        }; 
        this.client
          .request(MUTATION_UPDATE_FEED_AISUMMARYCONF, variables)
          .then((data) => {
            resolve({
              status_code: GRAPHQL_SUCCESS,
              data,
            });
          })
          .catch((err) => {
            let msg = "Error update feed ai comment configuration :" + err;
            logger.error(msg);
            reject({ status_code: GRAPHQL_ERROR, msg: msg });
          });
      });

 
  


  cluster_members_by_article = (nid) =>
    new Promise((resolve, reject) => {
      const variables = {
        nid: nid,
      };

      this.client
        .request(QUERY_CLUSTER_MEMBERS_BY_ARTICLE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting article :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  threads_by_aiuser = (author_id, fromDate, toDate) =>
    new Promise((resolve, reject) => {
      let variables = {
        author_id: author_id,
        fromDate :fromDate 
      }
      if(toDate!=null){
        variables.toDate =toDate
      } 
      const QUERY = (toDate!=null) ?QUERY_THREAD_BY_AIUSER:QUERY_FIRST_THREAD_BY_AIUSER;
      this.client
        .request(QUERY, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting threads of user:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
  comments_of_ai_users = (author_id, fromDate, toDate) => 
  new Promise((resolve, reject) => {
    let variables = {
      author_id: author_id,
      fromDate :fromDate 
    }
    if(toDate!=null){
      variables.toDate =toDate
    } 
    const QUERY = (toDate!=null) ?QUERY_COMMENT_OF_AI_USERS:QUERY_FIRST_COMMENT_BY_AIUSER;
    this.client
      .request(QUERY, variables)
      .then((data) => {
        resolve({
          status_code: GRAPHQL_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        let msg = "Error getting comments of user:" + err;
        logger.error(msg);
        reject({ status_code: GRAPHQL_ERROR, msg: msg });
      });
  });

  first_threads_by_aiuser = (author_id, fromDate, toDate) =>
    new Promise((resolve, reject) => {
      const variables = {
        author_id: author_id,
        fromDate :fromDate,
        toDate :toDate
      }
      this.client
        .request(QUERY_FIRST_THREAD_BY_AIUSER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting threads of user:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
    threads_by_user = (author_id,offset) =>
    new Promise((resolve, reject) => {
      const variables={
        author_id: author_id,
        offset: offset,
        pagesize: this.pagesize * 2,
       
      }
    //  const query = approved === null ? QUERY_THREADS_BY_FEEDS : QUERY_THREADS_APPROVED_BY_FEEDS;

      this.client
        .request(QUERY_THREAD_BY_USER, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting threads of user:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
    
    /*reading comments*/
    top_reading_comments_by_newest = (reading_id, approved, offset) =>
    new Promise((resolve, reject) => {
      let variables = {
        reading_id: reading_id,
        pagesize: this.pagesize,
        offset: offset
      };
      //console.log("variables",variables)

      if (approved !== null) {
        variables.approved = approved;
      } 
      let query = approved === null ? QUERY_TOP_READING_COMMENTS_BY_NEWEST : QUERY_TOP_READING_COMMENTS_BY_NEWEST_APPROVE;
      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting top thread comments (by newest):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
    top_reading_comments_by_oldest = (reading_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: reading_id,
        pagesize: this.pagesize,
        offset: offset
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_TOP_READING_COMMENTS_BY_OLDEST : QUERY_TOP_READING_COMMENTS_BY_OLDEST_APPROVE;
      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting top thread comments (by oldest):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  top_reading_comments_by_recommends = (reading_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: reading_id,
        pagesize: this.pagesize,
        offset: offset
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_TOP_READING_COMMENTS_BY_RECOMMENDS : QUERY_TOP_READING_COMMENTS_BY_RECOMMENDS_APPROVE;

      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting top thread comments (by recommends):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  top_reading_comments_by_replies = (reading_id, approved, offset) =>
    new Promise((resolve, reject) => {
      const variables = {
        reading_id: reading_id,
        pagesize: this.pagesize,
        offset: offset
      };

      if (approved !== null) {
        variables.approved = approved;
      }

      let query = approved === null ? QUERY_TOP_READING_COMMENTS_BY_REPLIES : QUERY_TOP_READING_COMMENTS_BY_REPLIES_APPROVE;
      this.client
        .request(query, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting top thread comments (by replies):" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });
     /*reading comments*/
     mask_friendly_hours = (id) =>
     new Promise((resolve, reject) => {
       const variables = {
        id:id,
       };
 
       this.client
         .request(QUERY_MASK_FRIENDLY_HOURS,variables)
         .then((data) => {
           resolve({
             status_code: GRAPHQL_SUCCESS,
             data,
           });
         })
         .catch((err) => {
           let msg = "Error getting day and hours:" + err;
           logger.error(msg);
           reject({ status_code: GRAPHQL_ERROR, msg: msg });
         });
     });

     delete_location_mask_friendly_hours = (id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: id,
      };

      this.client
        .request(MUTATION_MASK_FRIENDLY_HOURS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error delete mask friendly hours :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    }); 

    update_transcription_enable = (source_id, transcription_enabled) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: source_id,
        transcription_enabled: transcription_enabled 
      }; 
      this.client
        .request(MUTATION_UPDATE_ENABLE_TRANSCRIPTION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update feed source transcription settings :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    update_bias_check_enable = (source_id, updatedValue) => {
      return new Promise((resolve, reject) => {
        const variables = {
          source_id: source_id,
          is_bias_check: updatedValue 
        }; 
        this.client
          .request(MUTATION_UPDATE_BIAS_CHECK_ENABLE, variables)
          .then((data) => {
            resolve({
              status_code: GRAPHQL_SUCCESS,
              data,
            });
          })
          .catch((err) => {
            let msg = "Error updating bias check setting: " + err;
            logger.error(msg);
            reject({ status_code: GRAPHQL_ERROR, msg: msg });
          });
      });
    }
    
    insert_ai_query_transcription_results = (query, response ,articleId , userId) =>
    new Promise((resolve, reject) => {
      const variables = {
        query:query,
        response:response,
        created_at: new Date(),
        article_id:articleId,
        user_id:userId
      }; 
      this.client
        .request(MUTATION_INSERT_AI_QUERY_TRANSCRIPTION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert ai query transcription  :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    get_history_for_query_transcription = (article_id, user_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        article_id: article_id,
        user_id:user_id
      } 

      this.client
        .request(GET_HISTORY_FOR_QUERY_TARNSCRIPTION, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting transcription query history" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  /* #searchTermSourceArticles */
  searchTermSourceArticles = (sourceIds,offset) =>
  new Promise((resolve, reject) => {
    const variables = {
      sourceIds: sourceIds,
      pagesize: offset === 0 ? this.pagesize * 2 : this.pagesize,
      offset: offset
    };

    this.client
      .request(QUERY_AI_SOURCE_ARTICLES_BY_SOURCEIDS, variables)
      .then((data) => {
        resolve({
          status_code: GRAPHQL_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        let msg = "Error getting searchTermSourceArticles of feed :" + err;
        logger.error(msg);
        reject({ status_code: GRAPHQL_ERROR, msg: msg });
      });
  });

  update_source_image = (id, image) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: id,
        image: image,
      };
      this.client
        .request(MUTATION_UPDATE_SOURCE_IMAGE, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error update ai source image:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  //sponsorship
  insert_sponsorship = (sponsor) =>
    new Promise((resolve, reject) => {
      const variables = {
        source_id: sponsor.source_id,
        user_id: sponsor.uid,
      };
      this.client
        .request(MUTATION_INSERT_SPONSORSHIP, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error insert sponsorship :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    sponsorships_by_id = (source_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: source_id,
      };

      this.client
        .request(QUERY_SPONSORSHIPS_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting sponsorship info :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    update_sponsor = (data) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: data.id,
        status: data.status,
      };

      this.client
        .request(MUTATION_UPDATE_SPONSORSHIP_STATUS, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error updating sponsor :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    //sponsored_articles
    sponsored_articles_by_id = (article_id) =>
    new Promise((resolve, reject) => {
      const variables = {
        id: article_id,
      };

      this.client
        .request(QUERY_SPONSORED_ARTICLES_BY_ID, variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting sponsorsed articles info :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    // Get prices for membershipp
    get_prices = () =>
    new Promise((resolve, reject) => {
      this.client
        .request(QUERY_FOR_GETTING_PRICES)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting price:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

    // Get Price based on  payment type
    get_price_by_payment_type = (paymentType) =>
    new Promise((resolve, reject) => {
      const variables = {
        paymentType
      }
      this.client
        .request(QUERY_GET_PRICE_BY_PAYMENT_TYPE , variables)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error getting price:" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

      /*socket-user info insertion*/

  get_sockets = async (socketInfo) =>
    new Promise((resolve, reject) => {

      this.client
        .request(GET_SOCKETS, socketInfo)
        .then((data) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data,
          });
        })
        .catch((err) => {
          let msg = "Error fetching sockets :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });

  insert_socket =  async (socketInfo) =>
  new Promise((resolve, reject) => { 

    this.client
      .request(MUTATION_INSERT_SOCKETS, socketInfo)
      .then((data) => {
        resolve({
          status_code: GRAPHQL_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        let msg = "Error inserting sockets :" + err;
        logger.error(msg);
        reject({ status_code: GRAPHQL_ERROR, msg: msg });
      });
  });
/*socket-user info insertion*/
delete_sockets = async (userId, socketId) =>
new Promise((resolve, reject) => {
  const variables = {
    user_id: userId,
    socket_id: socketId
  };

  this.client
    .request(MUTATION_DELETE_SOCKETS, variables)
    .then((data) => {
      resolve({
        status_code: GRAPHQL_SUCCESS,
        data,
      });
    })
    .catch((err) => {
      let msg = "Error deleting user follower :" + err;
      logger.error(msg);
      reject({ status_code: GRAPHQL_ERROR, msg: msg });
    });
});
summary_articles_in_feeds = (from_date,to_date) =>
    new Promise((resolve, reject) => {
      const variables = {
        // feed_ids: feed_ids,
        from_date: from_date,
        to_date: to_date
       
      };

      this.client
        .request(QUERY_SUMMARY_ARTICLES, variables)
        .then((result) => {
          resolve({
            status_code: GRAPHQL_SUCCESS,
            data: result.articles,
          });
        })
        .catch((err) => {
          let msg = "Error getting feed articles :" + err;
          logger.error(msg);
          reject({ status_code: GRAPHQL_ERROR, msg: msg });
        });
    });


}

export default GraphqlService;
