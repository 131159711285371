import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { 
  Grid, 
  IconButton,
  Typography 
} from "@material-ui/core";
import { ImageSelector } from "components";
import { get_branch } from "constants/branches";
import { 
  MIN_CARD_WIDTH, 
  MAX_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE
} from "constants/types";
import { logger } from "utility/logging";

const styles = (theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 32,
    maxWidth: MAX_CARD_WIDTH - 32,
  },
  grid: {
    flexWrap: "inherit",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.card,
    borderRadius: 5,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  image: {
    objectFit: "cover",
    padding: 4,
    height: 80,
    width: 80,
    borderRadius: 10,
    // margin: theme.spacing(1),
  },
  title: {
    position: "relative",
    width: `calc(100% - 88px)`,
    textAlign: "left",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    textTransform: "none",
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  description: {
    position: "relative",
    width: `calc(100% - 88px)`,
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 100,
    lineHeight: "16px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(5),
  },
  branchimage: {
    position: "absolute",
    top: theme.spacing(1.5),
    right: theme.spacing(6),
    width: 16,
    height: 16,
  },
  deletebtn: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    padding: 0,
    margin: 0,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  actionimg: {
    padding: 4,
    width: 32,
    height: 32,
  },
});


class TempSourceCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: RAVEN_PLACEHOLDER_IMAGE,
      imageUpload: "",
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  componentDidMount = () => {
    const { source } = this.props;
    if (source.image) {
      this.setState({
        ...this.state,
        image: source.image,
        imageUpload: ""
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    const { source } = this.props;
    if (prevProps.source.image !== source.image) {
      this.setState({
        ...this.state,
        image: source.image,
        imageUpload: ""
      });
    }
  }

  handleDelete = () => {
    const { source } = this.props;
    this.props.onDelete(source);
  }

  handleImageChange = ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    const { source } = this.props;
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      this.setState({
        ...this.state,
        image: e.target.result,
        imageUpload: target.files[0],
      });
      this.props.onChangeImage(source, target.files[0]);
    };
  }

  makeSummary = (text) => {
    let words = text.split(" ");
    let characters = 0;
    let word_count = 0;
    let new_words = [];
    while (characters < 150 && word_count < words.length) {
      new_words.push(words[word_count]);
      characters += words[word_count].length;
      word_count++;
    }

    let summary = new_words.join(" ");
    if (word_count < words.length) {
      summary += "...";
    }
    return summary;
  }

  render() {
    const { classes, theme, source } = this.props;
    const { image } = this.state;

    logger.log("source :", source);

    let branchimage = "";
    let branchname = "";
    let branchInfo = get_branch(source.branch);
    if (branchInfo) {
      branchimage = `/static/images/icons/${theme}/${branchInfo.image}`;
      branchname = branchInfo.name;
    }

    let refined_description = this.makeSummary(source.description);

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    if (width > MAX_CARD_WIDTH)
      width = MAX_CARD_WIDTH;
    if (width < MIN_CARD_WIDTH)
      width = MIN_CARD_WIDTH;
    const text_width = width - 112 - 48;

    return (
      <div className={classes.root}>
        <img
          className={classes.branchimage}
          src={branchimage}
          alt={branchname}
        />
        <IconButton
          onClick={this.handleDelete}
          className={classes.deletebtn}
        >
          <img
            className={classes.actionimg}
            alt="cancel"
            src="/static/images/dismiss.png"
          />
        </IconButton>
        <Grid container className={classes.grid}>
          <Grid item style={{width: 100}}>
            <ImageSelector
              image={image}
              imageHandleChanged={this.handleImageChange}
            />
          </Grid>
          <Grid item style={{width: width - 132}}>
            <Typography className={classes.title} style={{width: text_width, wordWrap: "break-word"}}>{source.name}</Typography>
            <Typography className={classes.description} style={{width: text_width, wordWrap: "break-word"}}>
              {refined_description}
            </Typography>
            <div style={{ width: width }}></div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TempSourceCard.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  source: PropTypes.object,
  onChangeImage: PropTypes.func,
  onDelete: PropTypes.func,
};

export default withStyles(styles)(TempSourceCard);
