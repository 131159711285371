import React from "react";
import PropTypes from "prop-types";
import { 
  MenuList, 
  MenuItem,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

class PopMenuMapPostMod extends React.Component {
  render() {
    const { 
      classes, 
      theme, 
      onDelete,
      onSuspend1d,
      onSuspend7d,
      onSuspendPerm,
      onCloseComments, 
      onDeleteComments, 
    } = this.props;
 
    return (
      <div>
        <MenuList className={classes.menu_list}>
          <MenuItem className={classes.menu_item} onClick={onDelete}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"delete"}
                src={`/static/images/icons/${theme}/delete.png`}
              />
            </span>
            Delete Post
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onSuspend1d}>
            <span className={classes.ban_icon}>
              <AddIcon fontSize="small"
              />
            </span>
            <span>
              <img
                className={classes.menu_img}
                alt={"ban1d"}
                src={`/static/images/icons/${theme}/banned.png`}
              />
            </span>
            1-Day Suspend User
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onSuspend7d}>
            <span className={classes.ban_icon}>
              <AddIcon fontSize="small"
              />
            </span>
            <span>
              <img
                className={classes.menu_img}
                alt={"ban1d"}
                src={`/static/images/icons/${theme}/banned.png`}
              />
            </span>
            7-Day Suspend User
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onSuspendPerm}>
            <span className={classes.ban_icon}>
              <AddIcon fontSize="small"
              />
            </span>
            <span>
              <img
                className={classes.menu_img}
                alt={"ban1d"}
                src={`/static/images/icons/${theme}/banned.png`}
              />
            </span>
            Permanently Suspend
          </MenuItem>

          <Divider className={classes.divider} />

          <MenuItem className={classes.menu_item} onClick={onCloseComments}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"edit"}
                src={`/static/images/icons/${theme}/close.png`}
              />
            </span>
            Close Comments
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onDeleteComments}>
            <span className={classes.ban_icon}>
              <AddIcon fontSize="small"
              />
            </span>
            <span>
              <img
                className={classes.menu_img}
                alt={"ban1d"}
                src={`/static/images/icons/${theme}/delete.png`}
              />
            </span>
            Delete Comments
          </MenuItem>
        </MenuList>
      </div>
    );
  }
}

PopMenuMapPostMod.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  onDelete: PropTypes.func,
  onSuspend1d: PropTypes.func,
  onSuspend7d: PropTypes.func,
  onSuspendPerm: PropTypes.func,
  onCloseComments: PropTypes.func,
  onDeleteComments: PropTypes.func,
};

export default withStyles(styles)(PopMenuMapPostMod);
