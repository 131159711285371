import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as ROUTES from "constants/routes";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { withRouter } from "react-router";

import {
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
import { logger } from "utility/logging";
import _ from "lodash";

const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    borderRadius: 10,
    borderTopLeftRadius: 30,
    borderColor: theme.palette.feedstroke.news,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.news,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  morediv: {
    margin: "3px 16px",
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
    borderColor: theme.palette.feedstroke.news,
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 16,
    marginBottom: 8,
    padding: 4,
    backgroundColor: theme.palette.feedbackground.news,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 48,
    height: 48,
    cursor: "pointer",
  },
  listavatar: {
    width: 30,
    height: 30,
    cursor: "pointer",
    marginRight: 4,
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  sourcename: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "18px",
    marginTop: 10,
    marginLeft: 40,
    marginRight: 30,
    marginBottom: 4,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },

  moreButton: {
    background: "transparent",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  avatarList: {
    display: "flex",
    // width: 270,
    overflow: "hidden",
  },
});

class SimilarArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceImg: "",
      sourceName: "",
    };
  }

  getSource = (source_id) => {
    const { sources } = this.props;
    let source = sources.find((item) => item.id === source_id);
    if (!source) {
      logger.error("Error, Unknown article source!");
      return <div style={{display: "none"}}></div>;
    } else {
      return { img: source.image, name: source.name };
    }
  };

  handleMore = () => {
    const articleId = this.props.article.nid;
    const location = {
      pathname: `/${ROUTES.SIMILAR_ARTICLE_PREFIX}/${articleId}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  handleClick = (article) => {
    this.props.onClickArticle(article);
  };

  render() {
    const { classes, similarArticles, from = "", sources } = this.props;
    const sourceIds = _.map(sources, "id");
    const similarArticlesInSources = _.filter(similarArticles, (item) =>
      sourceIds.includes(item.article.source_id)
    );
    let maxWidth = from === "article" ? 270 : "85%";
    if (similarArticlesInSources.length > 0) {
      return (
        <>
          <div className={classes.morediv}>
            <div className={classes.avatarList} style={{ maxWidth: maxWidth }}>
              {similarArticles.map((article) => {
                let details = this.getSource(article.article.source_id);
                return (
                  <div
                    key={article.id}
                    onClick={() => this.handleClick(article)}
                  >
                    <Avatar
                      alt={details.name || "ait img"}
                      src={details.img || RAVEN_PLACEHOLDER_IMAGE}
                      className={classes.listavatar}
                      classes={{ img: classes.avatar_img }}
                    />
                  </div>
                );
              })}
            </div>
            <button
              className={classes.moreButton}
              onClick={() => this.handleMore()}
            >
              More
            </button>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
}

SimilarArticles.propTypes = {
  classes: PropTypes.object,
  similarArticles: PropTypes.array,
  handleClick: PropTypes.func,
  onClickSource: PropTypes.func,
  onClickFeed: PropTypes.func,
  article: PropTypes.object,
  from: PropTypes.string,
  onClickArticle: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  sources: state.dataState.sources,
  selected_feed: state.dataState.selected_feed,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(SimilarArticles)));
