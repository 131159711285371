import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  Avatar,
  Checkbox,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ToastContainer } from "react-toastify";
import { withFirebase } from "services";
import { withAuthentication } from "session";
import {
  BasicAppBar,
  Option,
  DlgSocialLink,
  WaitingSpinner,
  ImageSelector,
} from "components";
import EditAIPersonas from "components/EditAIPersonas";
import { SocialButton } from "./components";
import * as ROUTES from "constants/routes";
import { GraphqlService } from "services";
import {
  MAX_WINDOW_WIDTH,
  FEED_COMMENT_DISABLE,
  FEED_COMMENT_CLOSEALL,
  FEED_COMMENT_UNMODERATED,
  FEED_COMMENT_MODERATED,
  FEED_COMMENT_DELETEALL,
  FEED_COMMENT_TWITTERUSER,
  BANNED_TYPE_PERM,
  FEED_AI_COMMENT_TOP_FIVE,
  FEED_AI_COMMENT_FOR_CHOSEN,
  FEED_NO_AI_COMMENT,
  BLANK_USER_IMAGE,
  FEED_NO_SUMMARIES,
  FEED_DAILY_SUMMARY,
  FEED_WEEKLY_SUMMARY,
  FEED_ALLOW_CHOOSE_TO_COMMENT,
  THEME_MODE_DARK,
  APPROVING_POSTS,
  SOURCE_SUGGESTIONS,
  FLAGGED_COMMENTS_POSTS
} from "constants/types";
import { ROLE_BOT_USER } from "constants/user";
import { getAuthToken } from "dataapis";
import { is_valid_token_address } from "utility/utils";
import { ToastError } from "utility/toast";
import { resizeImageFile } from "utility/resizeimage";
import { logger } from "utility/logging";
import { moderate_image } from "utility/ravenapi";
import { v4 as uuidv4 } from "uuid";


const chkstyles = (theme) => ({
  root: {
    height: 14,
    color: theme.palette.text.secondary,
    "&$checked": {
      color: theme.palette.text.primary,
    },
    padding: 0,
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
  checked: {},
});

const CustomRadio = withStyles(chkstyles)((props) => (
  <Radio color="default" {...props} />
));
const CustomCheck = withStyles(chkstyles)((props) => (
  <Checkbox color="default" {...props} />
));

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  optioncontainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sociallinkcontainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  content: {
    position: "relative",
    padding: theme.spacing(1),
  },
  actionitem: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  radio: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  sectiontitle: {
    marginLeft: theme.spacing(2),
    display: "block",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
    color: theme.palette.text.primary,
  },
  sectiondescription: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: "11px",
    lineHeight: "11px",
    color: theme.palette.text.primary,
  },
  button: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    textTransform: "none",
  },
  title: {
    display: "block",
    marginBottom: 0,
    fontSize: "14px",
    lineHeight: "18px",
    color: theme.palette.text.primary,
  },
  description: {
    // marginBottom: theme.spacing(1),
    fontSize: "11px",
    lineHeight: "11px",
    color: theme.palette.text.primary,
  },
  useritem: {
    position: "relative",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  section: {
    marginLeft: theme.spacing(1),
    margin: 0,
    backgroundColor: theme.palette.background.default,
  },
  avatar: {
    width: 50,
    height: 50,
  },
  image: {
    width: "inherit",
    height: "inherit",
  },
  info: {
    width: 220,
    marginLeft: "16px",
  },
  name: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "14px",
    color: theme.palette.text.primary,
    marginBottom: 4,
    marginTop: 4,
  },
  label: {
    fontSize: "14px",
    fontWeight: 100,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
  icon: {
    padding: 2,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
  actionbtn: {
    backgroundColor: theme.palette.background.light,
    borderRadius: "20px",
    padding: "4px 10px",
    marginBottom: theme.spacing(1),
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.background.light,
    },
  },
  txtfield: {
    display: "block",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.card,
    marginLeft: theme.spacing(5),
    padding: 4,
    border: 0,
    borderRadius: 2,
    fontSize: 14,
    "&:focus": {
      outline: "0px",
    },
    "&::placeholder": {
      color: theme.palette.text.primary,
    },
    "&::-webkit-input-placeholder": {
      color: theme.palette.text.primary,
    },
    "&:-ms-input-placeholder": {
      color: theme.palette.text.primary,
    },
  },
  usernameAdd: {
    border: 0,
    borderBottom: "1px solid #000",
    borderRadius: 2,
    height: 25,
    padding: 4,
    marginBottom: 10,
    width: "95%",
    fontSize: 12,
    "&:focus-visible": {
      outline: "none",
    },
  },
  userTextField: {
    borderRadius: 2,
    height: 25,
    width: "95%",
    padding: "0px 4px",
    border: 0,
    borderBottom: "1px solid #000",
    fontSize: 11,
    "&:focus-visible": {
      outline: "none",
    },
  },
  actionbtnPerson: {
    backgroundColor: theme.palette.background.light,
    borderRadius: "20px",
    padding: "4px 1px",
    marginLeft: 22,
    marginBottom: theme.spacing(1),
    textTransform: "initial",
    height: 26,
    minWidth: 20,
    display: "block",
    alignItems: "baseline",
    textAlign: "center",
    marginTop: 0,
  },
  feedSettingsIcon: {
    padding: 2,
    width: 19,
    height: 19,
    color: theme.palette.primary.contrastText,
    marginLeft: 10,
  },
  sectionCreate: {
    marginLeft: 16,
    marginTop: 2,
    backgroundColor: theme.palette.background.default,
  },
  editIcon: {
    padding: 2,
    width: 19,
    height: 19,
    color: theme.palette.primary.contrastText,
    marginLeft: 11,
    marginRight: -2,
  },
});

const OPTION_VISIBILITY = 0;
const OPTION_COMMENTS = 1;
const OPTION_POSTS = 2;
const OPTION_MEMBERS = 3;
const OPTION_PAYMENT = 4;
const OPTION_ANONYMITY = 5;

const OptionsConf = [
  {
    title: "Visibility",
    btntype: "pubpriv",
    desc1: "All users can see feed, and is publically searchable",
    desc2: "Feed is private and visible to invited users",
  },
  {
    title: "Comments",
    btntype: "openpriv",
    desc1: "All users can view comments",
    desc2: "Only members can view comments",
  },
  {
    title: "Posts",
    btntype: "openclose",
    desc1: "Feed is open to suggested posts or sources",
    desc2: "Feed is closed to suggested posts or sources",
  },
  {
    title: "Members",
    btntype: "pubpriv",
    desc1: "Members are visible to each other",
    desc2: "Members are not visible to each other",
  },
  {
    title: "Payment",
    btntype: "openclose",
    desc1: "Allow unpaid users to post comments",
    desc2: "Only paid users can post comments",
  },
  {
    title: "Anonymity",
    btntype: "openclose",
    desc1: "Allow non-logged in users to post in the feed or suggest sources",
    desc2: "Only logged in users can post in the feed or suggest sources",
  },
];

const AiAutoModeration = [
  {
    label: "APPROVING POSTS",
    value: APPROVING_POSTS,
  },  {
    label: "SOURCE SUGGESTIONS",
    value: SOURCE_SUGGESTIONS,
  },  {
    label: "FLAGGED COMMENTS POSTS",
    value: FLAGGED_COMMENTS_POSTS,
  },
];

const CommentConf = [
  {
    label: "DISABLE COMMENTS",
    value: FEED_COMMENT_DISABLE,
  },
  {
    label: "CLOSE ALL COMMENTS",
    value: FEED_COMMENT_CLOSEALL,
  },
  {
    label: "ALLOW UNMODERATED COMMENTS",
    value: FEED_COMMENT_UNMODERATED,
  },
  {
    label: "ONLY MODERATED COMMENTS",
    value: FEED_COMMENT_MODERATED,
  },
  {
    label: "DELETE ALL COMMENTS AND POSTS",
    value: FEED_COMMENT_DELETEALL,
  },
  {
    label: "LIMIT TO ONLY TO THOSE WITH TWITTER LINKED TO THEIR BIO PAGE",
    value: FEED_COMMENT_TWITTERUSER,
  },
];

const AICommentConf = [
  {
    label: "AUTOMATICALLY ON TOP 5 ARTICLES",
    value: FEED_AI_COMMENT_TOP_FIVE,
  },
  {
    label: "ONLY ON ARTICLES I CHOOSE",
    value: FEED_AI_COMMENT_FOR_CHOSEN,
  },
  {
    label: "ALLOW AI's TO CHOOSE",
    value: FEED_ALLOW_CHOOSE_TO_COMMENT,
  },
  {
    label: "NO AI COMMENTS",
    value: FEED_NO_AI_COMMENT,
  },
];
const AISummaryConf = [
  {
    label: "NO SUMMARIES",
    value: FEED_NO_SUMMARIES,
  },
  {
    label: "DAILY",
    value: FEED_DAILY_SUMMARY,
  },
  {
    label: "WEEKLY",
    value: FEED_WEEKLY_SUMMARY,
  },
];

class FeedSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token_address: "",
      token_amount: 0,
      banned_users: [],
      preapproved_users: [],
      dlgSocialLink: false,
      provider: "",
      ai_person_name: "",
      ai_person_bio: "",
      ai_person_seed_phrase: "",
      ai_person_image: "",
      aiModeration:[],
      imageUpload: "",
      editPopUp: false,
      editPerson: "",
      is_category_moderator:false
      // showComponent: false
    };

    this.handleNavBack = this.handleNavBack.bind(this);

    this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
    this.handleToggleComments = this.handleToggleComments.bind(this);
    this.handleTogglePosts = this.handleTogglePosts.bind(this);
    this.handleToggleMembers = this.handleToggleMembers.bind(this);
    this.handleTogglePayment = this.handleTogglePayment.bind(this);
    this.handleToggleAnonymity = this.handleToggleAnonymity.bind(this);

    this.handleChangeCommentConf = this.handleChangeCommentConf.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this); // Bind the function
    this.handleAiModeration = this.handleAiModeration.bind(this); 
    this.handleToggleTGWallet = this.handleToggleTGWallet.bind(this);
    this.handleToggleTGAddress = this.handleToggleTGAddress.bind(this);
    this.handleToggleTGAmount = this.handleToggleTGAmount.bind(this);
    this.handleChangeTokenAddress = this.handleChangeTokenAddress.bind(this);
    this.handleUpdateTokenAddress = this.handleUpdateTokenAddress.bind(this);
    this.handleChangeTokenAmount = this.handleChangeTokenAmount.bind(this);
    this.handleUpdateTokenAmount = this.handleUpdateTokenAmount.bind(this);

    this.openSocialLink = this.openSocialLink.bind(this);
    this.closeSocialLink = this.closeSocialLink.bind(this);
    this.applySocialLink = this.applySocialLink.bind(this);

    this.handleRestore = this.handleRestore.bind(this);
    this.handleRevoke = this.handleRevoke.bind(this);
    this.handleChangeAICommentConf = this.handleChangeAICommentConf.bind(this);
    this.handleChangeAISummaryConf = this.handleChangeAISummaryConf.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitAIPerson = this.handleSubmitAIPerson.bind(this);
    this.handleDeleteAIPerson = this.handleDeleteAIPerson.bind(this);
    this.handleUpdatePerson = this.handleUpdatePerson.bind(this);
    this.handlePersonaLibrary = this.handlePersonaLibrary.bind(this);

  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  componentDidMount = async () => {
    const { selected_feed  , authUser} = this.props;

    const perm_banned_users = selected_feed.banned_users.filter(
      (user) => user.type === BANNED_TYPE_PERM
    );
    this.setState({
      ...this.state,
      token_address: selected_feed.token_address,
      token_amount: selected_feed.token_amount,
      banned_users: perm_banned_users,
      preapproved_users: selected_feed.preapproved_users,
      aiModeration: JSON.parse(selected_feed.ai_moderation) == null? [] : JSON.parse(selected_feed.ai_moderation)
    });
    if(authUser.categories_moderated.length>0){
      for (let category of authUser.categories_moderated) {
        if (category.category_id === selected_feed.category_id) {
          this.setState({
            ...this.state,
            is_category_moderator:true
          });
        }
      }
    } 
  };

   handleCheckboxChange(value) {
    const { aiModeration } = this.state;

    const updatedModeration = [...aiModeration];
    // If the value is already in the array, remove it
    if (updatedModeration.includes(value)) {
      updatedModeration.splice(updatedModeration.indexOf(value), 1);
    } else {
      // Otherwise, add the value to the array
      updatedModeration.push(value);
    }

    this.setState({ aiModeration: updatedModeration }, () => {
      this.handleAiModeration(); // Call handleAiModeration after updating the state
    });
  }

   handleAiModeration= async () => {
    const { selected_feed } = this.props;
    const { aiModeration } = this.state;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();

    if (!token) {
      this.handleLogin();
      return;
    }

    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    const aiModerationString = JSON.stringify(aiModeration);
    await gqlservice
      .update_feed_ai_moderation(selected_feed.id, aiModerationString)
      .then(
        (result) => {
          console.log("ai_moderation", this.props.selected_feed.ai_moderation);
          this.props.selected_feed.ai_moderation =  aiModerationString
          this.props.updateFeed(this.props.selected_feed)

        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  }

  handleNavBack = () => {
    const { selected_feed } = this.props;
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  handleToggleVisibility = async () => {
    const { selected_feed } = this.props;

    const new_op_visibility = !selected_feed.private;
    logger.log("new_op_visibility :", new_op_visibility);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    // Note : visibility = !private
    await gqlservice
      .update_feed_op_visibility(selected_feed.id, new_op_visibility)
      .then(
        (result) => {
          this.props.updateFeedOpVisibility(
            selected_feed.id,
            new_op_visibility
          );
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleToggleComments = async () => {
    const { selected_feed } = this.props;
    const new_op_comments = !selected_feed.op_comments;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .update_feed_op_comments(selected_feed.id, new_op_comments)
      .then(
        (result) => {
          this.props.updateFeedOpComments(selected_feed.id, new_op_comments);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleTogglePosts = async () => {
    const { selected_feed } = this.props;
    const new_op_posts = !selected_feed.op_posts;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .update_feed_op_posts(selected_feed.id, new_op_posts)
      .then(
        (result) => {
          this.props.updateFeedOpPosts(selected_feed.id, new_op_posts);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleToggleMembers = async () => {
    const { selected_feed } = this.props;
    const new_op_members = !selected_feed.op_members;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .update_feed_op_members(selected_feed.id, new_op_members)
      .then(
        (result) => {
          this.props.updateFeedOpMembers(selected_feed.id, new_op_members);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleTogglePayment = async () => {
    const { selected_feed } = this.props;
    const new_op_payment = !selected_feed.op_payment;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .update_feed_op_payment(selected_feed.id, new_op_payment)
      .then(
        (result) => {
          this.props.updateFeedOpPayment(selected_feed.id, new_op_payment);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleToggleAnonymity = async () => {
    const { selected_feed } = this.props;
    const new_op_anonymity = !selected_feed.op_anonymity;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .update_feed_op_anonymity(selected_feed.id, new_op_anonymity)
      .then(
        (result) => {
          this.props.updateFeedOpAnonymity(selected_feed.id, new_op_anonymity);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleChangeCommentConf = async (event) => {
    const { selected_feed } = this.props;

    logger.log("handleChangeCommentConf :", event.target.value);
    const new_conf = parseInt(event.target.value);
    if (new_conf === selected_feed.comment_conf) {
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();

    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    gqlservice
      .update_feed_commentconf(selected_feed.id, new_conf)
      .then(
        (result) => {
          this.props.updateFeedCommentConf(selected_feed.id, new_conf);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleChangeAICommentConf = async (event) => {
    const { selected_feed } = this.props;
    const new_conf = parseInt(event.target.value);
    if (new_conf === selected_feed.ai_comment_conf) {
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    console.log(token);
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);
    gqlservice
      .update_feed_aicommentconf(selected_feed.id, new_conf)
      .then(
        (result) => {
          console.log(result);
          this.props.updateFeedAICommentConf(selected_feed.id, new_conf);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleToggleTGWallet = async () => {
    const { selected_feed } = this.props;
    const new_tg_wallet = !selected_feed.tg_wallet;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .update_feed_tg_wallet(selected_feed.id, new_tg_wallet)
      .then(
        (result) => {
          this.props.updateFeedTGWallet(selected_feed.id, new_tg_wallet);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleToggleTGAddress = async () => {
    const { selected_feed } = this.props;
    const new_tg_address = !selected_feed.tg_address;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .update_feed_tg_wallet(selected_feed.id, new_tg_address)
      .then(
        (result) => {
          this.props.updateFeedTGWallet(selected_feed.id, new_tg_address);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleToggleTGAmount = async () => {
    const { selected_feed } = this.props;
    const new_tg_amount = !selected_feed.tg_amount;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .update_feed_tg_wallet(selected_feed.id, new_tg_amount)
      .then(
        (result) => {
          this.props.updateFeedTGWallet(selected_feed.id, new_tg_amount);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleChangeTokenAddress = async (event) => {
    event.preventDefault();

    this.setState({
      ...this.state,
      token_address: event.target.value,
    });
  };

  handleUpdateTokenAddress = async (event) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const { selected_feed } = this.props;
    const { token_address } = this.state;

    if (token_address && !is_valid_token_address(token_address)) {
      this.setError("Invalid Token Address!");
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    gqlservice
      .update_feed_token_address(selected_feed.id, token_address)
      .then(
        (result) => {
          this.props.updateFeedTokenAddress(selected_feed.id, token_address);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleChangeTokenAmount = async (event) => {
    event.preventDefault();

    this.setState({
      ...this.state,
      token_amount: event.target.value,
    });
  };

  handleUpdateTokenAmount = async (event) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const { selected_feed } = this.props;
    const { token_amount } = this.state;

    let amount = -1;
    try {
      amount = parseInt(token_amount);
    } catch (err) {
      this.setError("Invalid token amount, Token Amount should be numeric!");
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    gqlservice
      .update_feed_token_amount(selected_feed.id, amount)
      .then(
        (result) => {
          this.props.updateFeedTokenAmount(selected_feed.id, amount);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  openSocialLink = (provider) => {
    this.setState({
      ...this.state,
      provider: provider,
      dlgSocialLink: true,
    });
  };

  closeSocialLink = () => {
    this.setState({
      ...this.state,
      provider: "",
      dlgSocialLink: false,
    });
  };

  applySocialLink = async (provider, url) => {
    const { selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    await gqlservice
      .update_feed_sociallink(selected_feed.id, provider, url)
      .then(
        (result) => {
          this.props.updateFeedSocialLink(provider, url);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.forceUpdate();

    this.closeSocialLink();
  };

  handleRestore = async (user) => {
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    gqlservice
      .delete_banned_user(user.id)
      .then(
        (result) => {
          const new_users = this.state.banned_users.filter(
            (banned_user) => banned_user.id !== user.id
          );
          this.setState({
            ...this.state,
            banned_users: new_users,
          });
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleDeleteAIPerson = async (user) => {
    console.log("user details is delete handler : ",user)
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    const { selected_feed } = this.props;
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, false ,true);
    if(user.is_owner){
     await gqlservice
        .delete_ai_persons(user.id)
        .then((result) => {
          gqlservice.set_auth_jwt(token);
          return gqlservice.feed_by_id(selected_feed.id);
        })
        .then(
          (result) => {
            const feeds = result.data.feeds;
            if (feeds.length > 0) {
              this.props.selectFeed(feeds[0]);
            }
            this.setWaiting(false);
          },
          (reason) => {
            this.setError(reason.msg);
            this.setWaiting(false);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
          return;
        });
    }else{
     await gqlservice
      .delete_associative_ai_persons(user.id)
      .then((result)=>{
        gqlservice.set_auth_jwt(token);
        return gqlservice.feed_by_id(selected_feed.id);
      })
      .then(
        (result) => {
          const feeds = result.data.feeds;
          if (feeds.length > 0) {
            this.props.selectFeed(feeds[0]);
          }
          this.setWaiting(false);
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
    }
  };

  handleRevoke = async (user) => {
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    gqlservice
      .delete_preapproved_user(user.id)
      .then(
        (result) => {
          const new_users = this.state.preapproved_users.filter(
            (preapprove_user) => preapprove_user.id !== user.id
          );
          this.setState({
            ...this.state,
            preapproved_users: new_users,
          });
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleEditPerson = (person) => {
    this.setState({
      ...this.state,
      editPopUp: true,
      editPerson: person,
    });
  };

  closeEditPerson = () => {
    this.setState({
      ...this.state,
      editPopUp: false,
    });
  };

  handleUpdatePerson = async (updatedData) => {
    const { selected_feed } = this.props;
    let imageUrl = updatedData.image;
    if (updatedData.imageUpload != null) {
      const resized_image = await resizeImageFile(updatedData.imageUpload);
      const result = await this.props.firebase.uploadImage(
        resized_image,
        "avatars"
      );
      if (result.error) {
        this.setError("Failed to upload user image.");
        return;
      }
      imageUrl = result.url;
      const modresult = await moderate_image(imageUrl);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError(
          "Image not allowed, because it contains adults or racy content."
        );
        await this.props.firebase.deleteImage(imageUrl);
        return;
      }
    }

    this.setWaiting(true);
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, false, true);
    // /* creating user entries*/
    const update_aiuser = {
      uid: updatedData.user_id,
      name: updatedData.name,
      biography: updatedData.biography,
      image: imageUrl,
    };

    //   /* creating user entries*/

    await gqlservice
      .update_aiuser(update_aiuser)
      .then((result) => {
        const update_aiPersona = {
          id: updatedData.id,
          seed_phrase: updatedData.seed_phrase,
          user_id:updatedData.usr_id
        };
        const updateAssociateAiPersons = {
          seed_phrase: updatedData.seed_phrase,
          user_id:updatedData.user_id
        }
        gqlservice.update_aipersonas(update_aiPersona);
        gqlservice.update_associate_ai_persons(updateAssociateAiPersons);
        gqlservice.set_auth_jwt(token);
        return gqlservice.feed_by_id(selected_feed.id);
      })
      .then(
        (result) => {
          const feeds = result.data.feeds;
          if (feeds.length > 0) {
            this.props.selectFeed(feeds[0]);
          }

          this.setWaiting(false);
          this.closeEditPerson();
        },
        (reason) => {
          this.setError(reason.msg);

          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
  };

  handleChangeAISummaryConf = async (event) => {
    const { selected_feed } = this.props;
    const new_conf = parseInt(event.target.value);
    if (new_conf === selected_feed.ai_summary_conf) {
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);
    gqlservice
      .update_feed_aisummaryconf(selected_feed.id, new_conf)
      .then(
        (result) => {
          this.props.updateFeedAISummaryConf(selected_feed.id, new_conf);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  _renderBannedUser = (userInfo, index, classes, theme, onRestore) => {
    const user = userInfo.user;
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        key={`banned-user-${index}`}
        spacing={1}
      >
        <Grid item className={classes.avatar}>
          <Avatar className={classes.image} src={user.image} />
        </Grid>
        <Grid item className={classes.info}>
          <Typography className={classes.name}>{user.username}</Typography>
          <Typography className={classes.label}>{user.biography}</Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.actionbtn}
            startIcon={
              <img
                className={classes.icon}
                alt="Restore"
                src={`/static/images/icons/${theme}/checkmark.png`}
              />
            }
            onClick={(e) => onRestore(userInfo)}
          >
            Restore
          </Button>
        </Grid>
      </Grid>
    );
  };

  _renderApprovedUser = (userInfo, index, classes, theme, onRevoke) => {
    const user = userInfo.user;
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        key={`banned-user-${index}`}
        spacing={1}
      >
        <Grid item className={classes.avatar}>
          <Avatar className={classes.image} src={user.image} />
        </Grid>
        <Grid item className={classes.info}>
          <Typography className={classes.name}>{user.username}</Typography>
          <Typography className={classes.label}>{user.biography}</Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.actionbtn}
            startIcon={
              <img
                className={classes.icon}
                alt="Revoke"
                src={`/static/images/icons/${theme}/banned.png`}
              />
            }
            onClick={(e) => onRevoke(userInfo)}
          >
            Revoke
          </Button>
        </Grid>
      </Grid>
    );
  };

  handleChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handleImageChange = ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      this.setState({
        ...this.state,
        ai_person_image: e.target.result,
        imageUpload: target.files[0],
      });
    };
  };

   handlePersonaLibrary = () => {
    const { selected_feed } = this.props;
    ///${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/persona_library`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };


  handleSubmitAIPerson = async () => {
    const {
      imageUpload,
      ai_person_name,
      ai_person_seed_phrase,
      ai_person_bio,
    } = this.state;
    if (ai_person_name.trim() === "") {
      this.setError("Name can't be blank.");
      return;
    }
    if (ai_person_bio.trim() === "") {
      this.setError("Biography can't be blank.");
      return;
    }

    if (ai_person_seed_phrase.trim() === "") {
      this.setError("Seed phrase can't be blank.");
      return;
    }

    if (imageUpload === "") {
      this.setError("Please upload the image. ");
      return;
    }
    const { selected_feed } = this.props;
    this.setWaiting(true);
    const resized_image = await resizeImageFile(imageUpload);
    const result = await this.props.firebase.uploadImage(
      resized_image,
      "avatars"
    );
    if (result.error) {
      this.setError("Failed to upload user image.");
      return;
    }
    const imageUrl = result.url;
    const modresult = await moderate_image(imageUrl);
    logger.log("image moderation result :", modresult);
    if (modresult && modresult.result) {
      this.setError(
        "Image not allowed, because it contains adults or racy content."
      );
      await this.props.firebase.deleteImage(imageUrl);
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true, false);
    /* creating user entries*/
    const new_user = {
      uid: "bot_" + uuidv4(),
      name: ai_person_name,
      username: ai_person_name.trim().split(' ').join('-'),
      biography: ai_person_bio,
      image: imageUrl,
      email: "",
      emailVerified: false,
      phone: "",
      phoneVerified: false,
      links: [],
      msgToken: null,
      role: ROLE_BOT_USER,
      signMethod: 0,
      approved: false,
    };

    /* creating user entries*/

    await gqlservice
      .insert_user(new_user)
      .then((result) => {
        const aiPersona = {
          user_id: new_user.uid,
          feed_id: selected_feed.id,
          seed_phrase: ai_person_seed_phrase,
          is_owner : true
        };
        gqlservice.create_ai_person(aiPersona);
        gqlservice.set_auth_jwt(token);
        return gqlservice.feed_by_id(selected_feed.id);
      })
      .then(
        (result) => {
          const feeds = result.data.feeds;
          if (feeds.length > 0) {
            this.props.selectFeed(feeds[0]);
          }
          this.setState({
            ...this.state,
            ai_person_name: "",
            ai_person_bio: "",
            ai_person_seed_phrase: "",
            ai_person_image: "",
            imageUpload: "",
          });
          this.setWaiting(false);
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
  };

  render() {
    const { classes, theme_mode, selected_feed, requesting } = this.props;
    const {
      banned_users,
      preapproved_users,
      token_address,
      token_amount,
      dlgSocialLink,
      editPopUp,
      provider,
      ai_person_name,
      ai_person_bio,
      ai_person_seed_phrase,
      ai_person_image,
      editPerson,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar title={"Feed Settings"} onNavBack={this.handleNavBack} />
        </div>

        <Grid
          container
          className={classes.optioncontainer}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <Option
              theme={theme_mode}
              mode={!selected_feed.private}
              buttontype={OptionsConf[OPTION_VISIBILITY].btntype}
              title={OptionsConf[OPTION_VISIBILITY].title}
              description={
                !selected_feed.private
                  ? OptionsConf[OPTION_VISIBILITY].desc1
                  : OptionsConf[OPTION_VISIBILITY].desc2
              }
              onToggle={this.handleToggleVisibility}
            />
          </Grid>
          <Grid item>
            <Option
              theme={theme_mode}
              mode={selected_feed.op_comments}
              buttontype={OptionsConf[OPTION_COMMENTS].btntype}
              title={OptionsConf[OPTION_COMMENTS].title}
              description={
                selected_feed.op_comments
                  ? OptionsConf[OPTION_COMMENTS].desc1
                  : OptionsConf[OPTION_COMMENTS].desc2
              }
              onToggle={this.handleToggleComments}
            />
          </Grid>
          <Grid item>
            <Option
              theme={theme_mode}
              mode={selected_feed.op_posts}
              buttontype={OptionsConf[OPTION_POSTS].btntype}
              title={OptionsConf[OPTION_POSTS].title}
              description={
                selected_feed.op_posts
                  ? OptionsConf[OPTION_POSTS].desc1
                  : OptionsConf[OPTION_POSTS].desc2
              }
              onToggle={this.handleTogglePosts}
            />
          </Grid>
          <Grid item>
            <Option
              theme={theme_mode}
              mode={selected_feed.op_members}
              buttontype={OptionsConf[OPTION_MEMBERS].btntype}
              title={OptionsConf[OPTION_MEMBERS].title}
              description={
                selected_feed.op_members
                  ? OptionsConf[OPTION_MEMBERS].desc1
                  : OptionsConf[OPTION_MEMBERS].desc2
              }
              onToggle={this.handleToggleMembers}
            />
          </Grid>
          <Grid item>
            <Option
              theme={theme_mode}
              mode={selected_feed.op_payment}
              buttontype={OptionsConf[OPTION_PAYMENT].btntype}
              title={OptionsConf[OPTION_PAYMENT].title}
              description={
                selected_feed.op_payment
                  ? OptionsConf[OPTION_PAYMENT].desc1
                  : OptionsConf[OPTION_PAYMENT].desc2
              }
              onToggle={this.handleTogglePayment}
            />
          </Grid>
          <Grid item>
            <Option
              theme={theme_mode}
              mode={selected_feed.op_anonymity}
              buttontype={OptionsConf[OPTION_ANONYMITY].btntype}
              title={OptionsConf[OPTION_ANONYMITY].title}
              description={
                selected_feed.op_anonymity
                  ? OptionsConf[OPTION_ANONYMITY].desc1
                  : OptionsConf[OPTION_ANONYMITY].desc2
              }
              onToggle={this.handleToggleAnonymity}
            />
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.sociallinkcontainer}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <SocialButton
              theme_mode={theme_mode}
              provider={"twitter"}
              url={selected_feed.channel_twitter}
              onUpdate={(e) => this.openSocialLink("twitter")}
            />
          </Grid>
          <Grid item>
            <SocialButton
              theme_mode={theme_mode}
              provider={"instagram"}
              url={selected_feed.channel_instagram}
              onUpdateUser={(e) => this.openSocialLink("instagram")}
            />
          </Grid>
          <Grid item>
            <SocialButton
              theme_mode={theme_mode}
              provider={"tiktok"}
              url={selected_feed.channel_tiktok}
              onUpdateUser={(e) => this.openSocialLink("tiktok")}
            />
          </Grid>
          <Grid item>
            <SocialButton
              theme_mode={theme_mode}
              provider={"youtube"}
              url={selected_feed.channel_youtube}
              onUpdateUser={(e) => this.openSocialLink("youtube")}
            />
          </Grid>
        </Grid>
        <div className={classes.content}>
          <Typography className={classes.sectiontitle}>
            {"AI AUTO MODERATION"}
          </Typography>
          {AiAutoModeration.map((moderation)=>(
                      <div className={classes.actionitem}>
                      <FormControlLabel
                        key={"ai-posts"}
                        className={classes.radio}
                        value={moderation.value}
                        control={<CustomCheck 
                          checked={JSON.parse(selected_feed.ai_moderation)?.some((value) => value === moderation.value)} // Check if 1 is in aiModeration array
                          onChange={() => this.handleCheckboxChange(moderation.value)}  />}
                        label={
                          <div>
                            <Typography className={classes.title}>
                              {moderation.label}
                            </Typography>
                          </div>
                        }
                      />
                    </div>

          ))}
         
        </div>

        <div className={classes.content}>
          <Typography className={classes.sectiontitle}>{"COMMENTS"}</Typography>
          <div className={classes.actionitem}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="comment-actions"
                name="commentConf"
                value={selected_feed.commentConf}
                onChange={this.handleChangeCommentConf}
              >
                {CommentConf.map((conf) => (
                  <FormControlLabel
                    disabled={
                      (conf.value === FEED_COMMENT_UNMODERATED &&
                        selected_feed.op_payment) ||
                      (conf.value === FEED_COMMENT_MODERATED &&
                        !selected_feed.op_payment)
                    }
                    key={conf.value}
                    className={classes.radio}
                    value={conf.value}
                    control={<CustomRadio />}
                    checked={selected_feed.comment_conf === conf.value}
                    label={
                      <Typography className={classes.title}>
                        {conf.label}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className={classes.content}>
          <Typography className={classes.sectiontitle}>
            {"AI COMMENTS"}
          </Typography>
          <div className={classes.actionitem}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="ai-comment-actions"
                name="AiCommentConf"
                value={selected_feed.ai_comment_conf}
                onChange={this.handleChangeAICommentConf}
              >
                {AICommentConf.map((conf) => (
                  <FormControlLabel
                    key={conf.value}
                    className={classes.radio}
                    value={conf.value}
                    control={<CustomRadio />}
                    checked={selected_feed.ai_comment_conf === conf.value}
                    label={
                      <Typography className={classes.title}>
                        {conf.label}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className={classes.content}>
          <Typography className={classes.sectiontitle}>
            {"AI PERSONAS"}
          </Typography>
          <div className={classes.section}>
            {selected_feed.ai_persons.map((person) => (
              <div className={classes.useritem}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item className={classes.avatar}>
                    <Avatar className={classes.image} src={person.user?.image} />
                  </Grid>
                  <Grid item className={classes.info}>
                    <Typography className={classes.name}>
                      {person.user.name}
                    </Typography>
                    <Typography className={classes.label}>
                      {person.user.biography}
                    </Typography>
                    <Typography className={classes.label}>
                      {person.seed_phrase}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.actionbtnPerson}
                      startIcon={
                        <img
                          className={classes.feedSettingsIcon}
                          alt="Delete"
                          src={`/static/images/icons/${theme_mode}/icons-close.png`}
                          onClick={(e) => this.handleDeleteAIPerson(person)}
                        />
                      }
                    ></Button>
                    {person?.is_owner || this.state.is_category_moderator ? ( 
                      <Button
                        className={classes.actionbtnPerson}
                        style={{
                          backgroundColor:
                            theme_mode === THEME_MODE_DARK
                              ? "#42a1ea"
                              : "#E0E0E0",
                        }}
                        startIcon={
                          <img
                            className={classes.editIcon}
                            alt="Edit"
                            src={`/static/images/icons/${theme_mode}/edit.png`}
                            onClick={(e) => this.handleEditPerson(person)}
                          />
                        }
                      ></Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
          <div>
            <div className={classes.sectionCreate}>
              <Grid
                container
                direction="row"
                justifyContent="flex-center"
                alignItems="center"
                spacing={1}
              >
                <ImageSelector
                  image={
                    ai_person_image === "" ? BLANK_USER_IMAGE : ai_person_image
                  }
                  imageHandleChanged={this.handleImageChange}
                  imageSelectorType="small"
                />
                <Grid item className={classes.info}>
                  <Typography className={classes.name}>
                    <input
                      name="ai_person_name"
                      placeholder="Name"
                      value={ai_person_name}
                      className={classes.usernameAdd}
                      onChange={this.handleChange}
                    />
                  </Typography>
                  <Typography className={classes.name}>
                    <textarea
                      name="ai_person_bio"
                      placeholder="Enter a bio here"
                      value={ai_person_bio}
                      className={classes.userTextField}
                      onChange={this.handleChange}
                    />
                  </Typography>
                  <Typography className={classes.name}>
                    <textarea
                      name="ai_person_seed_phrase"
                      placeholder="Enter your seed phrase here"
                      value={ai_person_seed_phrase}
                      className={classes.userTextField}
                      onChange={this.handleChange}
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.actionbtn}
                    style={{ marginTop: "2rem", marginBottom: 0 }}
                    onClick={this.handleSubmitAIPerson}
                    startIcon={<AddIcon style={{ color: "black" }} />}
                  >
                    <Typography className={classes.buttontext}>
                      Create
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <div>
                <Button
                      className={classes.actionbtn}
                      style={{ marginTop: "2rem", marginBottom: 0 }}
                      onClick={this.handlePersonaLibrary}
                    >
                      <Typography className={classes.buttontext}>
                        Persona Library
                      </Typography>
                </Button>
                {/* {this.state.showComponent && <PersonaLibrary onClose={this.closePersonaComponent }/>} */}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.content}>
          <Typography className={classes.sectiontitle}>
            {"AI SUMMARY"}
          </Typography>
          <div className={classes.actionitem}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="ai-summary-actions"
                name="AiSummaryConf"
                value={selected_feed.ai_summary_conf}
                onChange={this.handleChangeAISummaryConf}
              >
                {AISummaryConf.map((conf) => (
                  <FormControlLabel
                    key={conf.value}
                    className={classes.radio}
                    value={conf.value}
                    control={<CustomRadio />}
                    checked={selected_feed.ai_summary_conf === conf.value}
                    label={
                      <Typography className={classes.title}>
                        {conf.label}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className={classes.content}>
          <Typography className={classes.sectiontitle}>
            {"TOKEN GATING"}
          </Typography>
          <Typography className={classes.sectiondescription}>
            {"You can restrict access to only holders of certain Tokens"}
          </Typography>
          <div className={classes.actionitem}>
            <FormControlLabel
              key={"tg-wallet"}
              className={classes.radio}
              value={selected_feed.tg_wallet}
              control={<CustomCheck />}
              onChange={this.handleToggleTGWallet}
              label={
                <div>
                  <Typography className={classes.title}>
                    {"OPEN TO USERS WITH A CRYPTO WALLET"}
                  </Typography>
                  <Typography className={classes.description}>
                    {"Allow anyone with a Metamask wallet to view the feed"}
                  </Typography>
                </div>
              }
            />
          </div>
          <div className={classes.actionitem}>
            <FormControlLabel
              key={"tg-address"}
              className={classes.radio}
              value={selected_feed.tg_address}
              control={<CustomCheck />}
              disabled={!selected_feed.tg_wallet}
              onChange={this.handleToggleTGAddress}
              label={
                <div>
                  <Typography className={classes.title}>
                    {"LIMIT TO HOLDERS OF THIS TOKEN"}
                  </Typography>
                  <Typography className={classes.description}>
                    {"List the contract address of an ERC-20 or ERC-721 token"}
                  </Typography>
                </div>
              }
            />
            <input
              // readOnly
              className={classes.txtfield}
              disabled={!selected_feed.tg_wallet || !selected_feed.tg_address}
              name="token_address"
              value={token_address || ""}
              style={{ width: 300 }}
              onChange={this.handleChangeTokenAddress}
              onKeyPress={this.handleUpdateTokenAddress}
            />
          </div>
          <div className={classes.actionitem}>
            <FormControlLabel
              key={"tg-amount"}
              className={classes.radio}
              value={selected_feed.tg_amount}
              control={<CustomCheck />}
              disabled={!selected_feed.tg_wallet}
              onChange={this.handleToggleTGAmount}
              onKeyPress={this.handleUpdateTokenAmount}
              label={
                <div>
                  <Typography className={classes.title}>
                    {"REQUIRE A CERTAIN NUMBER OF TOKENS"}
                  </Typography>
                  <Typography className={classes.description}>
                    {"List the number of tokens needed to join this feed"}
                  </Typography>
                </div>
              }
            />
            <input
              // readOnly
              className={classes.txtfield}
              disabled={!selected_feed.tg_wallet || !selected_feed.tg_amount}
              name="token_amount"
              value={token_amount !== null ? token_amount.toString() : ""}
              style={{ width: 300 }}
              onChange={this.handleChangeTokenAmount}
            />
          </div>
        </div>

        <div className={classes.content}>
          <Typography className={classes.sectiontitle}>
            {"PERMANENTLY BANNED USERS"}
          </Typography>
          {banned_users.length > 0 &&
            banned_users.map((user, index) => (
              <div className={classes.useritem}>
                {this._renderBannedUser(
                  user,
                  index,
                  classes,
                  theme_mode,
                  this.handleRestore
                )}
              </div>
            ))}

          <Typography className={classes.sectiontitle}>
            {"PRE-APPROVED USERS"}
          </Typography>
          {preapproved_users.length > 0 &&
            preapproved_users.map((user, index) => (
              <div className={classes.useritem}>
                {this._renderApprovedUser(
                  user,
                  index,
                  classes,
                  theme_mode,
                  this.handleRevoke
                )}
              </div>
            ))}
        </div>

        {dlgSocialLink && (
          <DlgSocialLink
            open={dlgSocialLink}
            theme={theme_mode}
            socialtype={provider}
            onClose={this.closeSocialLink}
            onApply={this.applySocialLink}
          />
        )}

        {editPopUp && (
          <EditAIPersonas
            open={editPopUp}
            theme={theme_mode}
            socialtype={editPerson}
            onClose={this.closeEditPerson}
            onSubmit={this.handleUpdatePerson}
          />
        )}

        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedSettings);
