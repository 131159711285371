import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ImageList, ImageListItem } from "@material-ui/core";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RAVEN_PLACEHOLDER_IMAGE } from "constants/types";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    overflow: "hidden",
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    overflowY: "hidden",
    overflowX: "auto",
  },
  tile: {
    cursor: 'pointer',
  },
  image: {
    height: 42,
    width: 42,
    borderRadius: 8,
    opacity: 0.72,
    backgroundColor: theme.palette.background.main,
  },
  image_selected: {
    height: 42,
    width: 42,
    borderRadius: 8,
    borderColor: theme.palette.icon,
    borderStyle: "solid",
    borderWidth: "2px",
    backgroundColor: theme.palette.background.main,
  },
});

class FeedsSlide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0
    };

    this.handleSelected = this.handleSelected.bind(this);
  }

  handleSelected = (index) => {
    // if (index === this.state.selected) {
    //   return;
    // }

    const { feeds } = this.props;
    this.setState({
      ...this.state,
      selected: index
    });

    this.props.onSelected(feeds[index])
  }
  render() {
    const { classes, feeds, width } = this.props;
    const { selected } = this.state;

    if (feeds.length === 0) {
      return <div style={{display: "none"}}></div>;
    }

    const height = 52;

    let cols = width / height;
    if (feeds.length < cols) {
      cols = feeds.length;
    }

    return (
      <div className={classes.root}>
        <ImageList
          className={classes.imageList}
          cols={cols}
          rowHeight={height}
        >
          {feeds.map((feed, index) => (
            <ImageListItem className={classes.tile} key={`feed-${index}`}>
              <LazyLoadImage 
                className={index === selected ? classes.image_selected : classes.image} 
                alt={feed.name}
                src={feed.thumbnail || feed.image || RAVEN_PLACEHOLDER_IMAGE}
                onClick={e => this.handleSelected(index)}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    );
  }
}

FeedsSlide.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  feeds: PropTypes.array,
  onSelected: PropTypes.func,
};

export default withStyles(styles)(FeedsSlide);
