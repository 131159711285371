import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ToastError, ToastSuccess, ToastWarning } from "utility/toast";
import { THEME_MODE_LIGHT, MIN_CARD_WIDTH } from "constants/types";
import { createSession, verificationPhone } from "utility/ravenapi";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Typography,
  Button,
  TextField,
  Toolbar,
  Link,
} from "@material-ui/core";
import { GraphqlService } from "services";
import { getAuthToken } from "dataapis";
import * as ROUTES from "constants/routes";
import DlgPhoneVerification from "components/DlgPhoneVerification/DlgPhoneVerification";
import { withRouter } from "react-router-dom";
const styles = (theme) => ({
  caption: {
    fontSize: 16,
    fontWeight: 500,
  },
  griditem: {
    backgroundColor: theme.palette.background.card,
  },
  actionbutton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionbutton_disabled: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
    opacity: 0.38,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  text: {
    flex: 1,
    color: theme.palette.text.primary,
    margin: 0,
    padding: 8,
    fontSize: 14,
    fontHeight: 18,
    backgroundColor: theme.palette.background.dark,
    "& input": {
      fontSize: 14,
      fontHeight: 18,
      backgroundColor: theme.palette.background.dark,
    },
  },
  applybtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "4px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  applybtn_disabled: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "4px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
  avatar: {
    margin: 0,
    padding: 1,
    width: 25,
    height: 25,
    marginLeft: theme.spacing(-0.5),
  },
});

class DlgBluesky extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      webDlg: false,
      requesting:false
    };
  }

  setError = (message) => {
    ToastError(message);
  };

  componentDidMount = () => {
    this.setState({
      url: "",
      errorMessage: "",
    });
  };

  handleChange = (event) => {
    if (event.target.value.length > 5000) {
      return;
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async () => {
    let { password, username } = this.state;
    const { authUser } = this.props;  
    // Check if username and password are not empty
    if (!username || !password) {
      this.setState({
        errorMessage: "Username and password are required.",
      });
      return;
    }
  
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    gqlservice.set_auth_jwt(token);

    if (!token) {
      this.handleLogin();
      return;
    }

    let params = {
      identifier: username,
      password: password,
      uid: authUser.uid,
    };

    try {
      let session = await createSession(params);
      if (!session.response || session.response.status !== 500) {
        this.setState({
          errorMessage: "",
          username: "",
          password: "",
        });

        // Close the modal if login is successful
        this.props.onClose();
        ToastSuccess("Successfully logged in");
      } else {
        this.setState({
          errorMessage: session.response.data.error,
          username: "",
          password: "",
        });
      }
    } catch (error) {
      console.error("Error creating session:", error);
      this.setState({
        errorMessage: "Error creating session. Please try again later.",
        username: "",
        password: "",
      });
    }
  };

  handleCreateAccount = async () => {
    const { authUser, history } = this.props;
const{requesting}=this.state;
    let phoneNumber = authUser.phone;
    let email = authUser.email;
    if (requesting){
      ToastWarning("A verification code has already been sent.");
      return
    }
    this.setState({requesting:true})
    try {
      if (phoneNumber !== "" && email !== "") {
        try {
          const phoneVerification = await verificationPhone(phoneNumber);
          if (
            phoneVerification.response &&
            phoneVerification.response.status === 400
          ) {
            ToastError(phoneVerification.response.data.message);
            this.setState({requesting:false})
          } else {
            this.setState({ webDlg: true });
          }
        } catch (error) {
          console.error("Error during phone verification:", error);
          ToastError(
            "Error occurred during phone verification. Please try again later."
          );
          this.setState({requesting:false})
        }
      } else {
        this.props.onClose();
        const location = {
          pathname: ROUTES.PROFILE_PHONE,
          state: { animation: "left" },
        };
        this.setState({requesting:false})
        history.push(location);
      }
    } catch (error) {
      this.setState({requesting:false})
      console.error("Error occurred during phone verification:", error);
    }
  };

  closePostDlg = () => {
    this.setState({ webDlg: false });
  };
  handleClose = () => {
    this.setState({
      ...this.state,
      username: "",
      password: "",
      errorMessage: "",
      webDlg: false,
    });
    this.props.onClose();
  };

  handleChangeUsername = (username) => {
    this.setState({ username });
  };
  handleChangePassword = (password) => {
    this.setState({ password });
  };

  render() {
    const { classes, open, theme_mode } = this.props;
    const { url, errorMessage, webDlg } = this.state;
    const paper_style = {
      width: MIN_CARD_WIDTH,
      borderRadius: 10,
      borderStyle: "solid",
      borderColor: theme_mode === THEME_MODE_LIGHT ? "black" : "white",
      borderWidth: "1px",
      backgroundColor: theme_mode === THEME_MODE_LIGHT ? "white" : "black",
    };

    return (
      <Dialog
        open={open}
        aria-labelledby="bluesky-dialog"
        aria-describedby="bluesky-dialog"
        PaperProps={{ style: paper_style }}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: -25,
            }}
          >
            <img
              alt={"Raven"}
              src={`/static/images/icons/${theme_mode}/bluesky.png`}
              className={classes.avatar}
              style={{ marginRight: 10, marginLeft: 5 }}
            />
            <Typography className={classes.caption}>Bluesky</Typography>
          </Toolbar>

          <IconButton
            onClick={this.handleClose}
            className={classes.actionbutton}
          >
            <img
              className={classes.actionimg}
              alt="apply"
              src="/static/images/delete.png"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ padding: "8px 16px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <TextField
                  id="outlined-basic"
                  label="Username"
                  value={this.state.username}
                  placeholder="@ Username or email address"
                  style={{ width: "80%" }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                  onChange={(e) => this.handleChangeUsername(e.target.value)}
                />
                <TextField
                  id="outlined-basic"
                  label="Password"
                  value={this.state.password}
                  placeholder="Password"
                  style={{ width: "80%" }}
                  type="password"
                  InputLabelProps={{ style: { fontSize: 16 } }}
                  onChange={(e) => this.handleChangePassword(e.target.value)}
                />
              </div>
              {errorMessage && (
                <Typography
                  color="error"
                  variant="body2"
                  style={{ marginTop: 8, textAlign: "center" }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <div style={{ textAlign: "center" }}>
            <Button
              className={classes.applybtn}
              onClick={this.handleSubmit}
              style={{
                marginRight: 10,
                marginLeft: '3%', width: '96%'
              }}
            >
              Login
            </Button>
            <Link
              onClick={this.handleCreateAccount}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              underline="none"
              style={{ margin: 10, fontSize: "0.5rem", opacity: 0.7,cursor: "pointer"  }}
            >
              <Typography>Create bluesky account using NewsRaven</Typography>
            </Link>
            <DlgPhoneVerification
              open={webDlg}
              onClose={this.closePostDlg}
              handleCloseModal={this.handleClose}
            />
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

DlgBluesky.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selected_feed: PropTypes.object,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  socketId: state.sessionState.socketId,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter
)(DlgBluesky);
