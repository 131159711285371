import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withFirebase } from 'services';
import { withAuthentication, withAuthorization } from "session";
import {
  ArticleList,
  ArticleMasonry,
  WaitingSpinner,
} from "components";
import { BasicAppBar } from "components";
import {
  BRANCH_ALL,
  ARTICLE_BRANCH_NEWSPAPER,
} from "constants/branches";
import * as ROUTES from "constants/routes";
import { ALL } from "constants/country";
import {
  MAX_WINDOW_WIDTH,
  MIN_TABLET_WIDTH,
  TAB_FEED,
} from "constants/types";
import {
  deleteSavedArticle,
  getArticle,
  getAuthToken,
  getMainInfo,
  isFeedModerator,
  reportArticle,
  repostArticle,
  upvoteArticle,
} from "dataapis";
import { GraphqlService } from "services";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 10,
  },
  articlecontainer: {
    padding: theme.spacing(1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.default,
  },
});

const condition = authUser => !!authUser && authUser.uid.length > 0;

class Saved extends React.Component {
  constructor(props) {
    super(props);

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);

    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.handleNeedMore = this.handleNeedMore.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  async componentDidMount() {
    this.setWaiting(true);
    await getMainInfo();
    await this.getSavedArticles();
    this.setWaiting(false);
  }

  getSavedArticles = async () => {
    const { authUser, saved_articles } = this.props;
    this.setWaiting(true);

    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }

    const saved_count = saved_articles.length;
    this.props.refreshSavedArticles();

    const gqlservice = new GraphqlService();
    gqlservice.set_auth_jwt(token);
    await gqlservice
      .article_save_by_user(authUser.uid)
      .then(
        (result) => {
          const articles = result.data.article_save;
          logger.log("Saved => articles :", articles);
          this.props.setSavedArticles(articles);
          if (saved_count === 0 || saved_count !== articles.length) {
            this.props.initScrollPos();
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  }

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.selectSource(null);
  };

  handleSelectArticle(article) {
    this.props.selectArticle(article);

    let articlePath = "";
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      articlePath += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      articlePath += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }
    const location = {
      pathname: articlePath,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.SAVED);
  }

  handleSelectGroupArticle = async (nid) => {
    this.setWaiting(true);
    await getArticle(nid);
    this.setWaiting(false);

    const location = {
      pathname: `/${ROUTES.ARTICLE_NEWS_PREFIX}/${nid}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.SAVED);
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleReportArticle = async (article, reportMsg) => {
    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };

  handleEditArticle = (article) => {}

  handleDeleteArticle = (article) => {}

  handleDeleteSavedArticle = async (article) => {
    this.setWaiting(true);
    await deleteSavedArticle(article);
    this.setWaiting(false);
  }

  handleClickFeed = (feed) => {
    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // Go to moderation page if the logged user is the moderator of the feed
    if (isFeedModerator(feed)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setFeedBackRoute(ROUTES.HOME);
    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickSource = (source, feed) => {
    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  handleClickUpvote = async (article) => {
    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  }

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  }

  handleClickRepost = async (article) => {
    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  }
  
  // empty functions
  handleNeedMore = () => {}
  handleSave = (article) => {}
  // empty functions

  render() {
    const {
      classes,
      newssites,
      saved_articles,
      requesting,
    } = this.props;

    if (newssites.length === 0) {
      return <div style={{display: "none"}}></div>;
    }

    const articles = saved_articles.map(saved_article => saved_article.article);    

    // layout variables
    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const isDesktop = width >= MAX_WINDOW_WIDTH;
    const isTablet =
      width >= MIN_TABLET_WIDTH &&
      width < MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;
    const innerWidth = width > MAX_WINDOW_WIDTH ? MAX_WINDOW_WIDTH : width;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
            <BasicAppBar
            title={"Saved"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.articlecontainer}>
          <Grid container spacing={1}>
            {(isDesktop || isTablet) && (
              <ArticleMasonry
                saved
                userProfile={true}
                width={innerWidth}
                articles={articles}
                pins={[]}
                movetops={[]}
                onNeedMore={this.handleNeedMore}
                onSelectArticle={this.handleSelectArticle}
                onSelectGroupArticle={this.handleSelectGroupArticle}
                onLogin={this.handleLogin}
                onReport={this.handleReportArticle}
                onEdit={this.handleEditArticle}
                onDelete={this.handleDeleteArticle}
                onSave={this.handleSave}
                onDeleteSaved={this.handleDeleteSavedArticle}
                onClickSource={this.handleClickSource}
                onClickFeed={this.handleClickFeed}
                onClickUpvote={this.handleClickUpvote}
                onClickComment={this.handleClickComment}
                onClickRepost={this.handleClickRepost}
              />
            )}
            {isMobile && (
              <ArticleList
                saved
                userProfile={true}
                articles={articles}
                pins={[]}
                movetops={[]}
                onNeedMore={this.handleNeedMore}
                onSelectArticle={this.handleSelectArticle}
                onSelectGroupArticle={this.handleSelectGroupArticle}
                onLogin={this.handleLogin}
                onReport={this.handleReportArticle}
                onEdit={this.handleEditArticle}
                onDelete={this.handleDeleteArticle}
                onSave={this.handleSave}
                onDeleteSaved={this.handleDeleteSavedArticle}
                onClickSource={this.handleClickSource}
                onClickFeed={this.handleClickFeed}
                onClickUpvote={this.handleClickUpvote}
                onClickComment={this.handleClickComment}
                onClickRepost={this.handleClickRepost}
              />
            )}
          </Grid>
          <WaitingSpinner open={requesting} />
        </div>
        <ToastContainer />
      </div>
    );
  }
}

Saved.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  saved_articles: state.dataState.saved_articles,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Saved);
