import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";
import { DlgConfirm } from "components";
import { MIN_CARD_WIDTH, MAX_CARD_WIDTH, THEME_MODE_LIGHT } from "constants/types";

const styles = (theme) => ({
  root: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_CARD_WIDTH,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  container: {
    // justifyContent: "left",
    flexWrap: "inherit",
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.main,
    // margin: 3,
    borderRadius: 5,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  prefix: {
    display: "inline",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  name: {
    display: "inline",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 500,
    color: "#E8EC31",
    marginTop: theme.spacing(1),
  },
  content: {
    textAlign: "left",
    fontSize: "12px",
    fontWeight: 200,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  btncontainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionbtn: {
    backgroundColor: theme.palette.background.main,
    borderRadius: "20px",
    padding: "4px 10px",
    marginLeft: theme.spacing(1),
    textTransform: "initial",
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  actionicon: {
    padding: 0,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
});

class Propose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteDlg: false,
    };

    this.handleApprove = this.handleApprove.bind(this);
    this.showDeleteDlg = this.showDeleteDlg.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleApprove = () => {
    const { source } = this.props;
    this.props.onApprove(source);
  }

  showDeleteDlg = (show) => {
    this.setState({
      ...this.state,
      deleteDlg: show
    });
  }

  handleDelete = () => {
    const { source } = this.props;
    this.props.onDelete(source);
    this.showDeleteDlg(false);
  }

  render() {
    const { classes, source, theme_mode, onClickProposer } = this.props;
    const { deleteDlg } = this.state;
    
    const proposer_name = source.propose.create_user.username;

    const class_name = {
      display: "inline",
      textAlign: "left",
      fontSize: "14px",
      fontWeight: 500,
      color: theme_mode === THEME_MODE_LIGHT ? "black" : "#E8EC31",
      marginTop: 8,
      cursor: "pointer"
    };

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    if (width > MAX_CARD_WIDTH) {
      width = MAX_CARD_WIDTH;
    }

    return (
      <div className={classes.root} style={{width: width - 16}}>
        <Box className={classes.container}>
            <Typography className={classes.prefix}>Proposed By </Typography>
            <Typography style={class_name} onClick={e => onClickProposer(source)}>{proposer_name}:</Typography>
        </Box>
        <Box className={classes.btncontainer}>
          <Button
            className={classes.actionbtn}
            startIcon={
              <img
                className={classes.actionicon}
                alt="approve"
                src={`/static/images/approve.png`}
              />
            }
            onClick={this.handleApprove}
          >
            Approve
          </Button>
          <Button
            className={classes.actionbtn}
            startIcon={
              <img
                className={classes.actionicon}
                alt="dismiss"
                src={`/static/images/delete.png`}
              />
            }
            onClick={e => this.showDeleteDlg(true)}
          >
            Dismiss
          </Button>
        </Box>
        <DlgConfirm
          open={deleteDlg}
          title={"Delete Source"}
          content={"Are you sure you want to delete this source?"}
          onOK={this.handleDelete}
          onCancel={e => this.showDeleteDlg(false)}
        />
      </div>
    );
  }
}

Propose.propTypes = {
  classes: PropTypes.object,
  theme_mode: PropTypes.string,
  onApprove: PropTypes.func,
  onDelete: PropTypes.func,
  onClickProposer: PropTypes.func
};

export default withStyles(styles)(Propose);
