import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    Button,
    Card,
    CardHeader,
    Dialog,
    Divider,
    IconButton,
    InputBase,
    Typography,
} from "@material-ui/core";
import { MAX_CARD_WIDTH, MIN_CARD_WIDTH } from "constants/types";
import { aiQueryTranscript } from "utility/ravenapi";
import { compose } from "recompose";
import { connect } from "react-redux";
import { WaitingSpinner } from "components/CommonControls";
import { hasPaid } from "dataapis";
import { DlgMemberTrial } from "components/Dialogs";
import { ToastError } from "utility/toast";

const styles = (theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    card: {
        backgroundColor: theme.palette.background.default,
        width: "70%",
        border: "2px solid black",
        borderRadius: 20,
        boxShadow: theme.shadows[10],
        // minWidth: MIN_CARD_WIDTH - 16,
        // maxWidth: MAX_CARD_WIDTH - 16,
        height: 'auto',
        "@media (max-width: 600px)": {
            width: "100%",
        },
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(2),
    },
    heading: {
        textAlign: "center",
        flex: 1,
    },
    actionbtns: {
        display: 'flex',
        marginRight: "-8px"
    },
    approve_img: {
        width: 30,
        height: 30,
    },
    close_img: {
        width: 30,
        height: 30,
    },
    approveButton: {
        padding: 4,
        color: theme.palette.background.default,
        marginRight: theme.spacing(1),
    },
    approveButtonDisabled: {
        padding: 4,
        color: theme.palette.background.default,
        marginRight: theme.spacing(1),
        opacity: 0.38,
      },
    closeButton: {
    },
    cardContent: {
        padding: theme.spacing(2),
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 200px)',
    },
    prompts: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    previousprompt: {
        marginBottom: theme.spacing(2),
    },
    exampleprompt: {
        marginBottom: theme.spacing(2),
    },
    output: {
        justifyContent: "center",
        alignItems: "center",
    },
    outputContent: {
        backgroundColor: theme.palette.background.dark,
        marginTop: theme.spacing(1),
        padding:10
    },
    inputBase: {
        flex: 1,
        color: theme.palette.text.primary,
        margin: 0,
        padding: 4,
        backgroundColor: theme.palette.background.dark,
        fontSize: 14,
        fontHeight: 18,
        fontWeight: 300,
    },
    postdiv: {
        justifyContent: "center",
        alignItems: "center",
    },
    postButton: {
        width: "70%",
        backgroundColor: "#1878F3",
        color: "#FFFFFF",
        borderRadius: 25,
        margin: theme.spacing(2),
        textTransform: "initial",
        zIndex: 9999,
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#1878F3",
            color: "#FFFFFF",
        },
        marginLeft: "50px",
        "@media (max-width: 300px)": {
            marginLeft: "0px",
        },
    }
});

class TranscriptQuery extends React.Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            inputText: "",
            transcript: "",
            inputTextCopy:'',
            isDisabled:true,
            loading:false,
            memberDlg: false,

        };
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmitQueryTranscript = this.handleSubmitQueryTranscript.bind(this);
    }

    componentDidMount() {
        // this.setState({ transcript: "transcriptData" });
    }

    handleSubmitQueryTranscript = async () => {
        try{
            const { inputText } = this.state;
            const { authUser ,previous_prompts} = this.props
            if( authUser.credits === 0){
                ToastError("You don't have any credits to do query transcription , Please buy some credits and try again.")
                return
              }          
            this.setState({
                ...this.state,
                inputTextCopy:inputText,
                loading:true,
                isDisabled:true
            })
            const {ai_transcription , articleId} = this.props
            if(inputText.trim() ===''){ 
                this.setState({
                    loading:false,
                    isDisabled:false
                })
                return
            }
            const history = previous_prompts.map((item)=>item.query)
            const requestData = {
                question:inputText,
                history:[
                    history
                ],
                articleId,
                userId:authUser.uid
            }
            const response = await aiQueryTranscript(requestData);
            console.log("-0----",response)
            this.setState({ output: response.message , loading:false , isDisabled:false});
            this.componentRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
              });
           
            return

        }catch(err){
            console.log("err",err)
            this.setState({
                loading:false,
                isDisabled:false
            })

        }

    }

    handleClose = () => {
        this.props.onCancel();
        this.setState({ inputText: "",output:"" ,isDisabled:false ,loading:false });
    }

    handlePost = () =>{
        const { selected_feed } = this.props;
        if(selected_feed){
        if (!selected_feed.op_payment && !hasPaid()) {
            this.setState({
            ...this.state,
            memberDlg: true
            });
            return;
        } 
        }
        else{
        if (!hasPaid()) {
            this.setState({
            ...this.state,
            dlgMember: true
            });
            return;
        }
        } 
        this.props.onPost(this.state.output ,this.state.inputTextCopy )
        this.handleClose();
    }

    handleChange = (e) =>{
        this.setState({ inputText: e.target.value })
        if(e.target.value.trim().length === 0 ) {
            this.setState({
                isDisabled:true
            })
        }else{
            this.setState({
                isDisabled:false
            })
        }
    }

    handleExamplePrompts = (prompt) =>{
        if(prompt.trim().length === 0 ) {
            this.setState({
                isDisabled:true
            })
        }else{
            this.setState({
                isDisabled:false
            })
        }
        this.setState({ inputText: " " + prompt })
    }

    handlePreviousPrompts =(prompt) =>{
         if(prompt.trim().length === 0 ) {
            this.setState({
                isDisabled:true
            })
        }else{
            this.setState({
                isDisabled:false
            })
        }
        this.setState({ inputText: " " + prompt })
    }
        
    handleMemberTrial = () => {
        this.setState({
          memberDlg: false,
        });
        this.props.onMemberTrial();
      }
    
      handleCloseMemberTrial = () => {
        this.setState({
          memberDlg: false,
        });
      }

    render() {
        const { open, classes, onCancel ,previous_prompts  } = this.props;
        const { output , loading ,memberDlg} = this.state;

        const example_prompts = [
            "Can you translate and summarize to french?",
            "Can you determine the extent and nature of political bias in this transcript?"
        ];
        
        return (
            <Dialog
                open={open}
                onClose={onCancel}
                className={classes.modal}
                PaperProps={{
                    className: classes.card,
                }}
            >
                <Card>
                    <CardHeader
                        className={classes.header}
                        title={
                            <Typography variant="h4" className={classes.heading}>
                                Query Transcript
                            </Typography>
                        }
                        action={
                            <div className={classes.actionbtns}>
                                <IconButton
                                    onClick={this.handleSubmitQueryTranscript}
                                    color="primary"
                                    variant="contained" 
                                    className={ classes.approveButton}
                                    disabled={this.state.isDisabled}
                                >
                                    <img
                                        className={classes.approve_img}
                                        alt="copy link"
                                        src={`/static/images/approve.png`}
                                    />
                                </IconButton>
                                <IconButton
                                    onClick={this.handleClose}
                                    color="primary"
                                    variant="contained"
                                    className={classes.closeButton}
                                >
                                    <img
                                        className={classes.close_img}
                                        alt="copy link"
                                        src={`/static/images/dismiss.png`}
                                    />
                                </IconButton>
                            </div>
                        }
                    />
                    <Divider />
                    <div className={classes.cardContent}>
                        <InputBase
                            id="comment-text"
                            className={classes.inputBase}
                            multiline
                            fullWidth
                            rows={6}
                            value={this.state.inputText}
                            onChange={this.handleChange}
                            placeholder="Write your prompt here"
                        />
                        <div className={classes.prompts}>
                            <div className={classes.exampleprompt}>
                                <Typography variant="h6">
                                    Example Prompts
                                </Typography>
                                {example_prompts.map((prompt, index) => (
                                    <Typography
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={()=>this.handleExamplePrompts(prompt)}
                                    >
                                        {prompt}
                                    </Typography>
                                ))}
                            </div>
                            <div className={classes.previousprompt}>
                               { previous_prompts.length>0 && <Typography variant="h6" style={{ marginBottom:5 }}>
                                    Previous Prompts for this Source
                                </Typography>}
                               {
                                previous_prompts && previous_prompts.map((history,index)=>(
                                    <Typography
                                    key={index}
                                    style={{ cursor: "pointer" ,padding:5 ,fontWeight:"10px"}}
                                    onClick={() => this.handlePreviousPrompts( history.query)}
                                    >
                                        {history.query}
                                    </Typography>
                                    )
                                )
                               }
                            </div>
                        </div>
                        {output ? (
                            <div className={classes.output} ref={this.componentRef}>
                                <Typography variant="h4" style={{ textAlign: "center" }}>
                                    Output
                                </Typography>
                                <div className={classes.outputContent}>
                                    <Typography style={{ padding: "0px 2px" }}>{output}</Typography>
                                </div>
                                <div className={classes.postdiv}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.postButton}
                                        onClick={this.handlePost}
                                    >
                                        Post
                                    </Button>
                                </div>
                            </div>) : null}
                    </div>
                    <WaitingSpinner open={loading}/>
                </Card>
                <DlgMemberTrial
                open={memberDlg}
                onClose={this.handleCloseMemberTrial}
                onMemberTrial={this.handleMemberTrial}
                />
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    previous_prompts: state.dataState.previous_prompts,
    authUser: state.sessionState.authUser,
    selected_feed: state.dataState.selected_feed,
  });

TranscriptQuery.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    articleId: PropTypes.string,
    onPost  : PropTypes.func,
    onMemberTrial:PropTypes.func
};

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(TranscriptQuery)
