import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { List, ListItem } from "@material-ui/core";
import { Article, FollowedCommentCard, SearchArticle} from "components";
import { logger } from "utility/logging";
import _ from "lodash";

const styles = theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  listitem: {
    padding: 0
  }
});

class SearchArticleList extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.scrollFn = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollFn);
    window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFn);
  }

  handleClick = article => {
    this.props.saveScrollPos(window.scrollX, window.scrollY);
    this.props.onSelectArticle(article);
  };

  handleGroupId = nid => {
    this.props.saveScrollPos(window.scrollX, window.scrollY);
    this.props.onSelectGroupArticle(nid);
  };

  listenToScroll = (event) => {
    event.preventDefault();

    const { bottomNavbar, topNavbar } = this.props;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    if (height === 0) {
      return;
    }

    const scrolled = winScroll * 1.0 / height;
    logger.log("articlelist :", scrolled);
    // this.props.saveScrollPos(window.scrollX, window.scrollY);
    if (scrolled === 0.0) {
      // this.props.saveScrollPos(window.scrollX, window.scrollY);
      this.props.showTopNavbar(true);
    } else if (scrolled === 1.0 || scrolled >= 0.99) {
      // this.props.saveScrollPos(window.scrollX, height);
      // this.props.saveScrollPos(window.scrollX, window.scrollY);
      this.props.onNeedMore();
    } else {
      if (topNavbar) {
        this.props.showTopNavbar(false);
      }
      if (!bottomNavbar) {
        this.props.showBottomNavbar(true);
      }
    }
  };

  render() {
    const { 
      classes,
      theme_mode, 
      loggedIn,
      authUser,
      saved,
      articles, 
      pins, 
      movetops, 
      onReport,
      onEdit,
      onDelete,
      onSave,
      onDeleteSaved,
      onNeedLogin,
      onClickSource,
      onClickFeed,
      onClickUpvote,
      onClickComment,
      onClickRepost,
      onClickArticle,
      aiSummary,
      onClickThread,
      onClickUpvoteThread
    } = this.props;

    if (articles.length === 0) {
      return <div style={{display: "none"}}></div>;
    }

    const pin_articles = pins.map(pin => {
      const article = pin.article;
      article.pinned = true;
      return article;
    });
    const movetop_articles = movetops.map(movetop => {
      const article = movetop.article;
      article.moved = true;
      return article;
    });

    
    let articles2show = [];
    if (aiSummary && aiSummary.length > 0) {
      articles2show = articles2show.concat(aiSummary);
    }
    if (pin_articles && pin_articles.length > 0) {
      articles2show = articles2show.concat(pin_articles);
    }
    if (movetop_articles && movetop_articles.length > 0) {
      articles2show = articles2show.concat(movetop_articles);
    }
    articles2show = articles2show.concat(articles);
    articles2show = _.uniqBy(articles2show, function (e) {
      return e.nid;
    });
    
    return (
      <div className={classes.root}>
        <List component="article-list" aria-label="article list">
          {articles2show.map(article => (
            article.isComment  ? 
            (
            <div className={classes.article} key={article.id}>
              <FollowedCommentCard
                thread={article}
                onClickThread={onClickThread}
                onClickUpvoteThread={onClickUpvoteThread}
              />
            </div>
            ) :
              (
            <ListItem className={classes.listitem} key={article.nid}>
              <SearchArticle
                theme={theme_mode}
                loggedIn={loggedIn}
                authUser={authUser}
                article={article}
                saved={saved !== undefined}
                onReport={onReport}
                onEdit={onEdit}
                onDelete={onDelete}
                onSave={onSave}
                onDeleteSaved={onDeleteSaved}
                onLogin={onNeedLogin}
                handleClick={this.handleClick}
                handleGroupId={this.handleGroupId}
                onClickSource={onClickSource}
                onClickFeed={onClickFeed}
                onClickUpvote={onClickUpvote}
                onClickComment={onClickComment}
                onClickRepost={onClickRepost}
                onClickArticle={onClickArticle}
              />
            </ListItem>)
          ))}
        </List>
      </div>
    );
  }
}

SearchArticleList.propTypes = {
  classes: PropTypes.object,
  saved: PropTypes.bool,
  articles: PropTypes.array,
  pins: PropTypes.array,
  movetops: PropTypes.array,
  onNeedMore: PropTypes.func,
  onLogin: PropTypes.func,
  onReport: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onDeleteSaved: PropTypes.func,
  onSelectArticle: PropTypes.func,
  onSelectGroupArticle: PropTypes.func,
  onClickSource: PropTypes.func,
  onClickFeed: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
  onClickArticle: PropTypes.func,
};


const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  scrollPos: state.uiState.scrollPos,
  bottomNavbar: state.uiState.bottomNavbar,
  topNavbar: state.uiState.topNavbar,
  theme_mode: state.uiState.theme_mode
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SearchArticleList);
