import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography
} from "@material-ui/core";
import { MAX_CARD_WIDTH } from "constants/types";

const styles = theme => ({
  root: {
    display: "-webkit-box",
    width: MAX_CARD_WIDTH,
    maxWidth: "100%",
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    cursor: "pointer"
  },
  avatar: {
    padding: 0,
  },
  text: {
    maxWidth: `calc(100% - 112px)`,
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.primary
  },
  name: {
    fontSize: "14px",
    color: theme.palette.text.primary
  },
  biography: {
    fontSize: "12px",
    lineHeight: 1,
    color: theme.palette.text.secondary
  },
  action: {
    margin: 0,
    right: 0,
    padding: 0,
  },
  following: {
    padding: 4,
    withth: 48,
    height: 48,
  }
});


class FollowerList extends React.Component {
  render() {
    const { 
      classes, 
      member,
      loggedIn,
      following,
      disabled,
      theme_mode, 
      onClicked, 
      onFollowed,
      onUnfollowed,
    } = this.props;
    const following_img = `/static/images/icons/${theme_mode}/following.png`;
    const unfollowing_img = `/static/images/icons/${theme_mode}/unfollowing.png`;

    return (
      <ListItem className={classes.root} key={member.id}>
        <ListItemAvatar
          className={classes.avatar}
          onClick={event => onClicked(member)}
        >
          <Avatar alt={member.follower.name} src={member.follower.image} />
        </ListItemAvatar>
        <ListItemText
          id={member.follower.uid}
          className={classes.text}
          primary={
            <div className={"item"}>
              <Typography className={classes.name}>
                {member.follower.username}
              </Typography>
              <Typography className={classes.biography}>
                {member.follower.biography}
              </Typography>
            </div>
          }
        />
        {loggedIn &&
          <ListItemSecondaryAction className={classes.action}>
            {following &&
              <img
                className={classes.following}
                alt={"following"}
                src={following_img}
                onClick={disabled ? null : event => onUnfollowed(member)}
              />
            }
            {!following &&
              <img
                className={classes.following}
                alt={"unfollowing"}
                src={unfollowing_img}
                onClick={disabled ? null : event => onFollowed(member)}
              />
            }
          </ListItemSecondaryAction>
        }
      </ListItem>
    );
  }
}

FollowerList.propTypes = {
  classes: PropTypes.object,
  member: PropTypes.object,
  loggedIn: PropTypes.bool,
  following: PropTypes.bool,
  theme_mode: PropTypes.string,
  onClicked: PropTypes.func,
  onFollowed: PropTypes.func,
  onUnfollowed: PropTypes.func,
};

export default withStyles(styles)(FollowerList);