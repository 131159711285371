import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { setError } from "./base";
import {
  GRAPHQL_ERROR,
} from "constants/types";
import { 
  NOTIFICATION_MAP_LOCATION_APPLY_MODERATOR,
} from "constants/notification";
import { 
  ACTIVITY_APPLY, 
  ACTIVITY_REJECT,
  ACTIVITY_REPORT,
  ACTIVITY_TYPE_CLEANAIRMAP 
} from "constants/activity";


const isModeratedLocation = (location) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return false;
  }

  if (authUser.locations_moderated.find(
      moderated => moderated.approved && moderated.location_id === location.id
    ) !== undefined) {
    return true;
  }

  if (authUser.regions_moderated.find(
      moderated => moderated.approved && moderated.region_id === location.region_id
    ) !== undefined) {
    return true;
  }

  return false;
};

const existLocationbySlug = async (slug) => {
  const state = store.getState();

  const { loggedIn } = state.sessionState;

  const gqlservice = new GraphqlService();
  if (loggedIn) {
    const token = await getAuthToken();
    if (!token) {
      return {
        error: true,
        message: ERR_MSG_AUTH_TOKEN
      };
    }
    gqlservice.set_auth_jwt(token);
  }

  let result = await gqlservice.map_location_count_by_slug(slug);
  if (result.status_code === GRAPHQL_ERROR) {
    return {
      error: true,
      message: result.msg
    };
  }

  try {
    return {
      error: false,
      exist: result.data.locations_aggregate.aggregate.count > 0
    }
  } catch(err) {
    return {
      error: true,
      message: err.toString()
    }
  }
}

const getLocationInfo = async (slug) => {
  const state = store.getState();

  const { loggedIn } = state.sessionState;

  const gqlservice = new GraphqlService();
  if (loggedIn) {
    const token = await getAuthToken();
    if (!token) {
      setError(ERR_MSG_AUTH_TOKEN);
      return;
    }
    gqlservice.set_auth_jwt(token);
  }

  let result = await gqlservice.map_location_by_slug(slug);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const locations = result.data.locations;
  if (locations.length > 0) {
    store.dispatch({
      type: ActionTypes.SELECT_MAP_LOCATION,
      location: locations[0],
    });
  }
};

const reportLocation = async (location, reportMsg) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  let report = {
    location_id: location.id,
    report: reportMsg,
    reported_by: authUser.uid,
    approved: false
  };

  let result = await gqlservice.insert_location_report(report);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  result = await gqlservice.map_location_by_id(location.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (result.data.locations.length > 0) {
    store.dispatch({
      type: ActionTypes.SELECT_MAP_LOCATION,
      location: result.data.locations[0],
    });
  }

  // log this activity
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_CLEANAIRMAP,
    type_id: location.id.toString(),
    action: ACTIVITY_REPORT,
    object: `the location ${location.name}`,
    fromto: `of the cleanairmap`,
    reason: ''
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

const moderateLocation = async (location) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  const moderator = {
    user_id: authUser.uid,
    location_id: location.id,
    approved: false,
    approved_by: null,
    approved_at: null
  };

  // insert location moderator
  let result = await gqlservice.insert_map_location_moderator(moderator);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (result.data.insert_location_moderators.affected_rows === 0) {
    setError("Failed to register as a moderator");
    return;
  }
  const location_moderator = result.data.insert_location_moderators.returning[0];
  store.dispatch({
    type: ActionTypes.INSERT_LOCATION_MODERATOR,
    moderator: location_moderator,
  });

  // update location
  result = await gqlservice.map_location_by_id(location.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (result.data.locations.length > 0) {
    store.dispatch({
      type: ActionTypes.SELECT_MAP_LOCATION,
      location: result.data.locations[0],
    });
  }

  // insert notification
  const notification = {
    type: NOTIFICATION_MAP_LOCATION_APPLY_MODERATOR,
    object: location.id.toString(),
    in_which: "cleanairmap",
    to: location.created_by,
    created_by: authUser.uid
  }
  result = await gqlservice.insert_notification(notification);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  // log activity
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_CLEANAIRMAP,
    type_id: location.id.toString(),
    action: ACTIVITY_APPLY,
    object: `the location ${location.name}`,
    fromto: `to cleanair map`,
    reason: "",
  };
  result = gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

const resignLocation = async (location) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  // check if the user is the moderator of this location
  let moderated = authUser.locations_moderated.find(
    (moderator) => moderator.approved && moderator.location_id === location.id
  );
  if (!moderated) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token, true);

  let result = await gqlservice.delete_map_location_moderator(moderated.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  store.dispatch({
    type: ActionTypes.DELETE_LOCATION_MODERATOR,
    moderator_id: moderated.id,
  });

  result = await gqlservice.map_location_by_id(location.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (result.data.locations.length > 0) {
    store.dispatch({
      type: ActionTypes.SELECT_MAP_LOCATION,
      location: result.data.locations[0],
    });
  }

  // log this activity
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_CLEANAIRMAP,
    type_id: location.id.toString(),
    action: ACTIVITY_REJECT,
    object: `the moderator ${authUser.uid}`,
    fromto: `of the region ${location.id}`,
    reason: ''
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}


const __getLocationModerates = async (location_id) => {
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return null;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.location_moderation_count(location_id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return null;
  }

  let moderation_count = result.data.readings_aggregate.aggregate.count;
  moderation_count += result.data.reading_reports_aggregate.aggregate.count;
  moderation_count += result.data.article_reports_aggregate.aggregate.count;
  moderation_count += result.data.location_moderator_reports_aggregate.aggregate.count;
  return moderation_count
}

const updateLocationNotification = async (location_id) => {
  const notifications = await __getLocationModerates(location_id);

  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  const result = await gqlservice.update_location_notifications(location_id, notifications);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
};


export {
  isModeratedLocation,
  existLocationbySlug,
  getLocationInfo, 
  reportLocation,
  moderateLocation,
  resignLocation,
  updateLocationNotification
};
