import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { default as PageTransition } from "utility/pagetransition";

import {
  Home as HomeView,
  SignIn as SignInView,
  SignInEmail as SignInEmailView,
  SignInPhone as SignInPhoneView,
  SignUp as SignUpView,
  Notifications as NotificationsView,
  Profile as ProfileView,
  ProfilePosts as ProfilePostsView,
  ProfilePhone as ProfilePhoneView,
  ProfileFollowing as ProfileFollowingView,
  ProfileFollowers as ProfileFollowersView,
  NewsletterFeeds as NewsletterFeedsView,
  Onboarding as OnboardingView,
  OnboardingConfirm as OnboardingConfirmView,
  ResetPassword as ResetPasswordView,
  MyFeeds as MyFeedsView,
  Saved as SavedView,
  Newspaper as NewspaperView,
  Social as SocialView,
  Podcasts as PodcastsView,
  Videocasts as VideocastsView,
  // Birdtok as BirdtokView,
  Comments as CommentsView,
  CommentsReported as CommentsReportedView,
  CleanAirMap as CleanAirMapView,
  CleanAirTutorial as CleanAirTutorialView,
  CleanAirPost as CleanAirPostView,
  CleanAirLocationAdd as CleanAirLocationAddView,
  CleanAirLocationEdit as CleanAirLocationEditView,
  CleanAirLocation as CleanAirLocationView,
  CleanAirLocationModeration as CleanAirLocationModerationView,
  CleanAirPostsFlagged as CleanAirPostsFlaggedView,
  CleanAirPostsProposed as CleanAirPostsProposedView,
  CleanAirReadingsFlagged as CleanAirReadingsFlaggedView,
  CleanAirReadingsProposed as CleanAirReadingsProposedView,
  CleanAirModeratorsFlagged as CleanAirModeratorsFlaggedView,
  CleanAirLocationLogs as CleanAirLocationLogsView,
  CleanAirLocationSettings as CleanAirLocationSettingsView,
  CleanAirLocationPost as CleanAirLocationPostView,
  CleanAirLeaderboards as CleanAirLeaderboardsView,
  CleanAirComments as CleanAirCommentsView,
  Discover as DiscoverView,
  Category as CategoryView,
  List as ListView,
  Feed as FeedView,
  FeedMembers as FeedMembersView,
  CommentThread as CommentThreadView,
  FeedAdd as FeedAddView,
  FeedEdit as FeedEditView,
  FeedReview as FeedReviewView,
  FeedInviteUser as FeedInviteUserView,
  FeedJoin as FeedJoinView,
  FeedShare as FeedShareView,
  Source as SourceView,
  SourceAdd as SourceAddView,
  SourceEdit as SourceEditView,
  SourceSearch as SourceSearchView,
  SearchResult as SearchResultView,
  SearchedNewspaper as SearchedNewspaperView,
  SearchedSocial as SearchedSocialView,
  Moderation as ModerationView,
  CategoryModeration as CategoryModerationView,
  FeedsFlagged as FeedsFlaggedView,
  FeedsProposed as FeedsProposedView,
  CategoryLogs as CategoryLogsView,
  FeedModeration as FeedModerationView,
  SourcesFlagged as SourcesFlaggedView,
  SourcesProposed as SourcesProposedView,
  PostsFlagged as PostsFlaggedView,
  PostsProposed as PostsProposedView,
  ModeratorsFlagged as ModeratorsFlaggedView,
  FeedLogs as FeedLogsView,
  Settings as SettingsView,
  NotFound as NotFoundView,
  ThreadsPended as ThreadsPendedView,
  ThreadsFlagged as ThreadsFlaggedView,
  FeedSettings as FeedSettingsView,
  PersonaLibrary as PersonaLibraryView,
  Membership as MembershipView,
  User as UserView,
  ContactsInvite as ContactsView,
  ExternalView as ExtView,
  About as AboutView,
  ContactUs as ContactUsView,
  OurValues as OurValuesView,
  UptimeStats as UptimeStatsView,
  TOS as TOSView,
  PrivacyPolicy as PrivacyPolicyView,
  SimilarArticlesList as SimilarArticlesListView,
  ProfileComments as ProfileCommentsView,
  VideoStories as VideoStoriesView,
  UserFollowing as UserFollowingView,
  UserFollowers as UserFollowersView,
  UserProfilePosts as UserProfilePostsView,
  Popular as PopularView
} from "./views";
import Account from "views/Account/Account";

const animations = {
  left: {
    preset: "fadeFromRight",
    enter: null,//"moveFromRight",
    exit: null,//"moveToLeft"
  },
  right: {
    preset: "fadeFromLeft",
    enter: null,//"moveFromLeft",
    exit: null,//"moveToRight"
  },
  top: {
    preset: "fadeFromTop",
    enter: null,//"moveFromTop",
    exit: null,//"moveToBottom",
  },
  bottom: {
    preset: "fadeFromBottom",
    enter: null,//"moveFromBottom",
    exit: null,//"moveToTop"
  }
};

const Routes = () => {
  return (
    <Route
      render={({ location }) => {
        let animation = animations["right"];
        if (location.state && location.state.animation) {
          if (location.state.animation === "left") {
            animation = animations["left"];
          } else if (location.state.animation === "right") {
            animation = animations["right"];
          } else if (location.state.animation === "top") {
            animation = animations["top"];
          } else if (location.state.animation === "bottom") {
            animation = animations["bottom"];
          }
        }

        return (
          <PageTransition
            preset={animation.preset}
            transitionKey={location.pathname}
            exitAnimation={animation.exit}
            enterAnimation={animation.enter}
          >
            <Switch location={location}>
              <Redirect exact from={ROUTES.LANDING} to={ROUTES.HOME} />
              <Route component={HomeView} exact path={ROUTES.HOME} />
              <Route component={SignUpView} exact path={ROUTES.SIGN_UP} />
              <Route component={SignInView} exact path={ROUTES.SIGN_IN} />
              <Route component={SignInEmailView} exact path={ROUTES.EMAIL_SIGNIN} />
              <Route component={SignInPhoneView} exact path={ROUTES.PHONE_SIGNIN} />
              <Route component={ResetPasswordView} exact path={ROUTES.RESET_PASSWORD} />

              <Route component={NotificationsView} exact path={ROUTES.NOTIFICATIONS} />
              <Route component={ProfileView} exact path={ROUTES.PROFILE} />
              <Route component={ProfilePostsView} exact path={ROUTES.PROFILE_POSTS} />
              <Route component={UserProfilePostsView} exact path={ROUTES.USER_PROFILE_POSTS}/>
              <Route component={ProfilePhoneView} exact path={ROUTES.PROFILE_PHONE} />
              <Route component={ProfileFollowingView} exact path={ROUTES.PROFILE_FOLLOWING} />
              <Route component={ProfileFollowersView} exact path={ROUTES.PROFILE_FOLLOWERS} />
              <Route component={NewsletterFeedsView} exact path={ROUTES.PROFILE_NEWSLETTER} />
              <Route component={OnboardingView} exact path={ROUTES.ONBOARDING} />
              <Route component={OnboardingConfirmView} exact path={ROUTES.ONBOARDING_CONFIRM} />
              
              <Route component={MyFeedsView} exact path={ROUTES.MY_FEEDS} />
              <Route component={SavedView} exact path={ROUTES.SAVED} />
              <Route component={DiscoverView} exact path={ROUTES.DISCOVER} />
              <Route component={PodcastsView} exact path={ROUTES.PODCASTS} />
              <Route component={VideocastsView} exact path={ROUTES.VIDEOCASTS} />
              {/* <Route component={BirdtokView} exact path={ROUTES.BIRDTOK} /> */}
              <Route component={CommentsView} exact path={ROUTES.COMMENTS} />
              <Route component={CommentsReportedView} exact path={ROUTES.COMMENTS_REPORTS} />

              <Route component={CleanAirMapView} exact path={ROUTES.CLEANAIRMAP} />
              <Route component={CleanAirTutorialView} exact path={ROUTES.CLEANAIRMAP_TUTORIAL} />
              <Route component={CleanAirPostView} exact path={ROUTES.CLEANAIRMAP_POST} />
              <Route component={CleanAirLocationAddView} exact path={ROUTES.CLEANAIRMAP_ADD} />
              <Route component={CleanAirLocationEditView} exact path={ROUTES.CLEANAIRMAP_EDIT} />
              <Route component={CleanAirLocationView} exact path={ROUTES.CLEANAIRMAP_DETAIL} />
              <Route component={CleanAirLocationView} exact path={ROUTES.CLEANAIRMAP_READINGS} />
              <Route component={CleanAirLocationView} exact path={ROUTES.CLEANAIRMAP_LOCATION_POSTS} />
              <Route component={CleanAirLocationModerationView} exact path={ROUTES.CLEANAIRMAP_MODERATION} />
              <Route component={CleanAirPostsFlaggedView} exact path={ROUTES.CLEANAIRMAP_FLAGGED_POSTS} />
              <Route component={CleanAirPostsProposedView} exact path={ROUTES.CLEANAIRMAP_PROPOSED_POSTS} />
              <Route component={CleanAirReadingsFlaggedView} exact path={ROUTES.CLEANAIRMAP_FLAGGED_READINGS} />
              <Route component={CleanAirReadingsProposedView} exact path={ROUTES.CLEANAIRMAP_PROPOSED_READINGS} />
              <Route component={CleanAirModeratorsFlaggedView} exact path={ROUTES.CLEANAIRMAP_FLAGGED_MODERATORS} />
              <Route component={CleanAirLocationLogsView} exact path={ROUTES.CLEANAIRMAP_LOCATION_LOGS} />
              <Route component={CleanAirLocationSettingsView} exact path={ROUTES.CLEANAIRMAP_LOCATION_SETTING} />
              <Route component={CleanAirLocationPostView} exact path={ROUTES.CLEANAIRMAP_LOCATION_POST} />
              <Route component={CleanAirLeaderboardsView} exact path={ROUTES.CLEANAIRMAP_LEADERBOARDS} />
              <Route component={CleanAirCommentsView} exact path={ROUTES.CLEANAIRMAP_COMMENTS} />
              
              <Route component={NewspaperView} exact path={ROUTES.CATEGORY_NEWS_DETAIL} />
              <Route component={NewspaperView} exact path={ROUTES.FEED_NEWS_DETAIL} />
              <Route component={NewspaperView} exact path={ROUTES.ARTICLE_NEWS_DETAIL} />
              
              <Route component={SocialView} exact path={ROUTES.CATEGORY_POST_DETAIL} />
              <Route component={SocialView} exact path={ROUTES.FEED_POST_DETAIL} />
              <Route component={SocialView} exact path={ROUTES.ARTICLE_DETAIL} />
              
              <Route component={CategoryView} exact path={ROUTES.CATEGORY_DETAIL} />
              <Route component={ListView} exact path={ROUTES.LIST_DETAIL} />
              
              <Route component={FeedAddView} exact path={ROUTES.FEED_ADD} />
              <Route component={FeedEditView} exact path={ROUTES.FEED_EDIT} />
              <Route component={FeedReviewView} exact path={ROUTES.FEED_REVIEW} />
              <Route component={FeedView} exact path={ROUTES.FEED_POSTS} />
              <Route component={FeedView} exact path={ROUTES.FEED_SOURCES} />
              <Route component={FeedView} exact path={ROUTES.FEED_COMMENTS} />
              <Route component={FeedView} exact path={ROUTES.FEED_DETAIL} />
              <Route component={FeedMembersView} exact path={ROUTES.FEED_MEMBERS} />
              <Route component={FeedInviteUserView} exact path={ROUTES.FEED_INVITEUSER} />
              <Route component={FeedJoinView} exact path={ROUTES.FEED_JOIN} />
              <Route component={FeedShareView} exact path={ROUTES.FEED_SHARE} />
              
              <Route component={CommentThreadView} exact path={ROUTES.FEED_THREAD_DETAIL} />
              <Route component={CommentThreadView} exact path={ROUTES.THREAD_DETAIL} />
              
              <Route component={SourceAddView} exact path={ROUTES.SOURCE_ADD} />
              <Route component={SourceEditView} exact path={ROUTES.FEED_SOURCE_EDIT} />
              <Route component={SourceSearchView} exact path={ROUTES.SOURCE_SEARCH} />
              <Route component={SourceView} exact path={ROUTES.CATEGORY_SOURCE_DETAIL} />
              <Route component={SourceView} exact path={ROUTES.FEED_SOURCE_DETAIL} />
              <Route component={SourceView} exact path={ROUTES.SOURCE_DETAIL} />
              
              <Route component={SearchResultView} exact path={ROUTES.SEARCH_RESULT} />
              <Route component={SearchedNewspaperView} exact path={ROUTES.SEARCH_NEWS_DETAIL} />
              <Route component={SearchedSocialView} exact path={ROUTES.SEARCH_DETAIL} />
              
              <Route component={ModerationView} exact path={ROUTES.MODERATION} />

              <Route component={CategoryModerationView} exact path={ROUTES.CATEGORY_MODERATION} />
              <Route component={FeedsFlaggedView} exact path={ROUTES.FLAGGED_FEEDS} />
              <Route component={FeedsProposedView} exact path={ROUTES.PROPOSED_FEEDS} />
              <Route component={CategoryLogsView} exact path={ROUTES.CATEGORY_LOGS} />

              <Route component={FeedModerationView} exact path={ROUTES.FEED_MODERATION} />
              <Route component={FeedSettingsView} exact path={ROUTES.FEED_SETTINGS} />
              <Route component={PersonaLibraryView} exact path={ROUTES.PERSONA_LIBRARY} />
              <Route component={SourcesFlaggedView} exact path={ROUTES.FLAGGED_SOURCES} />
              <Route component={SourcesProposedView} exact path={ROUTES.PROPOSED_SOURCES} />
              <Route component={PostsFlaggedView} exact path={ROUTES.FLAGGED_POSTS} />
              <Route component={PostsProposedView} exact path={ROUTES.PROPOSED_POSTS} />
              <Route component={ModeratorsFlaggedView} exact path={ROUTES.FLAGGED_MODERATORS} />
              <Route component={FeedLogsView} exact path={ROUTES.FEED_LOGS} />
              <Route component={ThreadsPendedView} exact path={ROUTES.PENDED_THREADS} />
              <Route component={ThreadsFlaggedView} exact path={ROUTES.FLAGGED_THREADS} />

              <Route component={SettingsView} exact path={ROUTES.SETTINGS} />
              <Route component={MembershipView} exact path={ROUTES.MEMBERSHIP} />
              <Route component={UserView} exact path={ROUTES.USER_DETAIL} />
              <Route component={ContactsView} exact path={ROUTES.CONTACTS} />

              <Route component={AboutView} exact path={ROUTES.ABOUT} />
              <Route component={Account} exact path={ROUTES.ACCOUNT}/>
              <Route component={ContactUsView} exact path={ROUTES.CONTACTUS} />
              <Route component={OurValuesView} exact path={ROUTES.OUR_VALUES} />
              <Route component={UptimeStatsView} exact path={ROUTES.UPTIME_STATS} />
              <Route component={TOSView} exact path={ROUTES.TERMS_OF_SERVICE} />
              <Route component={PrivacyPolicyView} exact path={ROUTES.PRIVACY_POLICY} />
              
              <Route component={ExtView} exact path={ROUTES.EXTERNAL_VIEW} />
              <Route component={NotFoundView} exact path={ROUTES.NOT_FOUND} />
              <Route component={SimilarArticlesListView} exact path={ROUTES.SIMILAR_ARTICLES} />
              <Route component={ProfileCommentsView} exact path={ROUTES.PROFILE_COMMENTS} />
              <Route component={VideoStoriesView} exact path={ROUTES.STORIES} />
              <Route component={UserFollowingView} exact path={ROUTES.USER_FOLLOWING} />
              <Route component={UserFollowersView} exact path={ROUTES.USER_FOLLOWERS} />
              <Route component={PopularView} exact path={ROUTES.POPULAR} />
              <Redirect to={ROUTES.NOT_FOUND} />
            </Switch>
          </PageTransition>
        );
      }}
    />
  );
};

export default Routes;
