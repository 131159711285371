import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import { isWindows } from "react-device-detect";
import { MAX_ARTICLE_WIDTH } from "constants/types";
import { 
  EMOJI_FLAGS,
  EMOJI_MARKS_READINGS,
  EMOJI_MARKS_MOST
} from "constants/emoji";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: MAX_ARTICLE_WIDTH,
  },
  logo: {
    minWidth: 60,
  },
  logoimage: {
    width: 60,
    height: 60,
  },
  titlebox: {
    marginLeft: theme.spacing(2),
  },
  title: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    textTransform: "none",
  },
  avatar: {
    minWidth: 20,
  },
  index: {
    display: "inline", 
    marginRight: 16,
    fontSize: "16px",
    fontWeight: 500,
  },
  image: {
    verticalAlign: 'middle',
    paddingTop: 2,
    paddingBottom: 2,
    height: 32,
    width: 32,
  },
  text: {
    paddingLeft: theme.spacing(2),
  },
  name: {
    marginRight: theme.spacing(1),
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  markimage: {
    verticalAlign: 'middle',
    height: 16,
    width: 16,
  },
  biography: {
    fontSize: "12px",
    color: theme.palette.text.primary,
  },
  actiontxt: {
    fontSize: "16px",
    fontWeight: 500,
  },
});

class Regions extends React.Component {

  render() {
    const { classes, regions } = this.props;

    return (
      <div className={classes.root}>
        <List>
          {regions.map((item, index) => {
            let emoji = EMOJI_FLAGS.find(emoji => emoji.country === item.country);
            if (!emoji) return (<></>);
            let readingMark = EMOJI_MARKS_READINGS.find(mark => item.readings >= mark.count);
            let mostMark = EMOJI_MARKS_MOST.find(most => item.most >= 0 && item.most === most.value);
            return (
              <ListItem key={`regions-${index}`} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <ListItemAvatar className={classes.avatar} style={{ minWidth: 64 }}>
                  {isWindows ? (
                    <>
                      <Typography className={classes.index}>{`${index + 1} `}</Typography>
                      <img
                        className={classes.image}
                        alt={item.name}
                        src={`/static/images/emojis/flags/${emoji.image}`}
                      />
                    </>
                  ):(
                    <>
                      <Typography className={classes.index}>{`${index + 1} `}</Typography>
                      <Typography style={{ display: "inline", fontSize: 24 }}>{emoji.emoji}</Typography>
                    </>
                  )}
                </ListItemAvatar>
                <ListItemText className={classes.text}>
                  {isWindows ? (
                    <>
                      <Typography className={classes.name} style={{display: "inline"}}>
                        {item.place}
                      </Typography>
                      {readingMark &&
                        <img
                          className={classes.markimage}
                          alt={readingMark.description}
                          src={`/static/images/emojis/marks/${readingMark.image}`}
                        />
                      }
                      {mostMark &&
                        <img
                          className={classes.markimage}
                          alt={mostMark.description}
                          src={`/static/images/emojis/marks/${mostMark.image}`}
                        />
                      }
                    </>
                  ):(
                    <Typography className={classes.name} style={{display: "inline"}}>
                      {`${item.place} ${readingMark.emoji} ${mostMark ? mostMark.emoji : ""}`}
                    </Typography>
                  )}
                  <Typography className={classes.biography}>
                    {`${item.region}, ${item.country}`}
                  </Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Typography className={classes.actiontxt}>
                    {item.readings}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(Regions);
