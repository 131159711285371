import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { ToastError, ToastSuccess } from "utility/toast";
import { createBlueskyAccount } from "utility/ravenapi";
import { THEME_MODE_LIGHT } from "constants/types";

const styles = (theme) => ({
  caption: {
    fontSize: 16,
    fontWeight: 500,
  },
  actionbutton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1), // Reduced margin
  },
  inputBox: {
    width: 40,
    height: 40,
    fontSize: 20,
    backgroundColor: theme.palette.type === 'dark' ? '#333' : '#d3d3d3',
    color: theme.palette.type === 'dark' ? '#fff' : '#333',
    textAlign: "center",
    borderRadius: 8,
    border: "1px solid #ccc",
    marginRight: theme.spacing(1),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
  errorMessage: {
    color: "red",
    marginTop: theme.spacing(1),
  },
  applybtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "4px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
});

class DlgPhoneVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codes: Array(6).fill(""),
      errorMessage: "",
      setWebDlg:false
    };
  }

  handleChange = (index, event) => {
    const { value } = event.target;
    const { codes } = this.state;

    if (!isNaN(value) && value.length <= 1) {
      const updatedCodes = [...codes];
      updatedCodes[index] = value;
      this.setState({ codes: updatedCodes }, () => {
        // Focus the next input field
        if (index < 5 && value !== "") {
          this[`input${index + 1}`].focus();
        }
      });
    }
  };

  handleSubmit = async () => {
    const { codes } = this.state;
    const { authUser } = this.props;
   

    const code = codes.join("");

    if (code.length !== 6) {
      this.setState({
        errorMessage: 'Please enter a 6-digit code',
      });
      return;
    }

    // Reset state
    this.setState({
      codes: Array(6).fill(""),
      errorMessage: "",
    });
    this.props.handleCloseModal();

    let params = {
      uid:authUser.uid,
      handle: authUser.username + ".bsky.social",
      verificationPhone: authUser.phone,
      password: authUser.username + "@raven",
      verificationCode: code,
      email: authUser.email,
    };

    const createAccount = await createBlueskyAccount(params);

    if (createAccount.data) {
      ToastSuccess("Account created successfully");
    } else {
      ToastError(createAccount.response.data.actualResponse.message);
    }
  };

  handleClose = () => {
    this.setState({
      codes: Array(6).fill(""),
      errorMessage: "",
    });
    this.props.onClose();
  };

  render() {
    const { classes, open, theme_mode } = this.props;
    const { codes, errorMessage } = this.state;

    const paperStyle = {
      width: 300,
      borderRadius: 10,
      borderStyle: "solid",
      borderColor: theme_mode === THEME_MODE_LIGHT ? "black" : "white",
      borderWidth: "1px",
      backgroundColor: theme_mode === THEME_MODE_LIGHT ? "white" : "black",
    };

    return (
      <Dialog
        open={open}
        aria-labelledby="verification-dialog"
        aria-describedby="verification-dialog"
        PaperProps={{ style: paperStyle }}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Typography className={classes.caption} align="center">
            Verification Code
          </Typography>
          <IconButton
            onClick={this.handleClose}
            className={classes.actionbutton}
          >
            <img
              alt="Close"
              className={classes.actionimg}
              src="/static/images/delete.png"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="body1"
            align="center"
            gutterBottom
            style={{ opacity: 0.6 }}
          >
            Enter the verification code sent to your phone number
          </Typography>

          <div className={classes.inputContainer}>
            {codes.map((code, index) => (
              <input
                key={index}
                type="text"
                className={classes.inputBox}
                value={code}
                maxLength="1"
                ref={(input) => {
                  this[`input${index}`] = input;
                }}
                onChange={(event) => this.handleChange(index, event)}
                style={{marginLeft: '3%', width: '96%'}}
              />
            ))}
          </div>
          {errorMessage && (
            <Typography className={classes.errorMessage} variant="body2">
              {errorMessage}
            </Typography>
          )}
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.applybtn}
            onClick={this.handleSubmit}
          >
            Verify Now
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DlgPhoneVerification.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleCloseModal: PropTypes.func,
  authUser: PropTypes.object,
  theme_mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DlgPhoneVerification);

