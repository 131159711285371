export const MUTATION_INSERT_MASK_FRIENDLY_HOUR = `
mutation insert_location_mask_friendly_hour(
  $location_id: Int,
  $reading_id: Int!,
  $day: String!,
  $time: String!,
  $created_by: String!
) {
  insert_location_mask_friendly_hours(objects: {
    location_id: $location_id,
    reading_id: $reading_id,
    time: $time,
    day: $day,
    created_by: $created_by
  }) {
    affected_rows
    returning {
			id
			location_id
      reading_id
      created_by
			day
			time
    }
  }
}`;
