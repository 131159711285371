import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Badge,
  Divider,
  Typography,
} from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MAX_CARD_WIDTH, RAVEN_PLACEHOLDER_IMAGE } from "constants/types";

const styles = (theme) => ({
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  item: {
    marginBottom: theme.spacing(4),
  },
});

class MastodonHashtags extends React.Component {
  constructor(props) {
    super(props);
    this.listenToScroll = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
    window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  // Function to convert Unix timestamp to number of days ago
  convertTimestampToDays(timestamp) {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const differenceInSeconds = currentTimestamp - timestamp;
    const daysAgo = Math.floor(differenceInSeconds / (24 * 60 * 60));
    return daysAgo;
  }

  listenToScroll(event) {
    event.preventDefault();
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = (winScroll * 1.0) / height;

    if (scrolled === 1.0 || scrolled >= 0.99) {
      if (typeof this.props.onNeedMore === "function") {
        this.props.onNeedMore();
      }
    }
  }

  render() {
    const { classes, hashtags } = this.props;

    return (
      <div>
        {hashtags.map((hashtag, index) => (
          <ListItemText
            key={index}
            primary={
              <div className={classes.item}>
                <a
                  href={hashtag.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${classes.name} ${classes.link}`}
                >
                  #{hashtag.name}
                </a>
                <Typography className={classes.count}>
                  {hashtag.history[hashtag.history.length - 1].accounts} people
                  in the past{" "}
                  {this.convertTimestampToDays(
                    hashtag.history[hashtag.history.length - 1].day
                  )}{" "}
                  days
                </Typography>
              </div>
            }
          />
        ))}
      </div>
    );
  }
}

MastodonHashtags.propTypes = {
  classes: PropTypes.object.isRequired,
  hashtags: PropTypes.array.isRequired,
  onNeedMore: PropTypes.func,
};

const mapStateToProps = (state) => ({
  scrollPos: state.uiState.scrollPos,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MastodonHashtags);
