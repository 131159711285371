import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { check_source_link } from "utility/checklink";

const styles = (theme) => ({
  root: {},
  actionbtn: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "20px",
    padding: "4px 10px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    textTransform: "initial",
    // boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  actionicon: {
    padding: 3,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
});

class SocialButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    };
  }

  componentDidMount = () => {
    this.setState({
      ...this.state,
      url: this.props.url
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.url !== prevProps.url) {
      this.setState({
        ...this.state,
        url: this.props.url
      });
    }
  }

  capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  }

  render() {
    const { 
      classes, 
      theme_mode, 
      provider, 
      onUpdate
    } = this.props;
    const { url } = this.state;

    let tag = "";
    if (url) {
      const sociallink = check_source_link(url);
      tag = sociallink.tag;
    }

    let img_src = "";
    if (provider === "twitter") {
      img_src = `/static/images/icons/${theme_mode}/twitter.png`;
    } else if (provider === "instagram") {
      img_src = `/static/images/icons/${theme_mode}/instagram.png`;
    } else if (provider === "tiktok") {
      img_src = `/static/images/icons/${theme_mode}/tiktok.png`;
    } else if (provider === "youtube") {
      img_src = `/static/images/icons/${theme_mode}/youtube.png`;
    }

    return (
      <div>
        <Button
          className={classes.actionbtn}
          startIcon={
            <img className={classes.actionicon} alt="approve" src={img_src} />
          }
          onClick={onUpdate}
        >
          {tag === "" ? `Add ${this.capitalize(provider)}` : `@${tag}`}
        </Button>
      </div>
    );
  }
}

SocialButton.propTypes = {
  classes: PropTypes.object,
  theme_mode: PropTypes.string,
  provider: PropTypes.string,
  url: PropTypes.string,
  onUpdate: PropTypes.func
};

export default withStyles(styles)(SocialButton);
