import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Grid,
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { withAuthentication, withAuthorization } from "session";
import { BasicAppBar, Report, WaitingSpinner } from "components";
import { GraphqlService, withFirebase } from "services";
import { ACTIVITY_TYPE_CATEGORY, ACTIVITY_DELETE } from "constants/activity";
import * as ROUTES from "constants/routes";
import {
  MIN_CARD_WIDTH,
  MAX_CARD_WIDTH,
  MAX_WINDOW_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
import { deleteFeed, getAuthToken } from "dataapis";
import { CategoryController } from "controllers";
import { ToastError } from "utility/toast";

const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  container: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  listitem: {
    display: "-webkit-box",
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
    // width: `calc(100% - 16px)`,
    padding: 0,
    paddingRight: 60,
    margin: 0,
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "#232323"
    // }
  },
  listitem_avatar: {
    minWidth: 60,
  },
  listimage: {
    objectFit: "cover",
    height: 60,
    width: 60,
    borderRadius: 5,
  },
  listitem_text: {
    paddingLeft: theme.spacing(2),
    paddingRight: 68,
    color: theme.palette.text.primary,
  },
  name: {
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: "12px",
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
  report: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class FeedsFlagged extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flagged: [],
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleDeleteReport = this.handleDeleteReport.bind(this);
    this.handleDismissReport = this.handleDismissReport.bind(this);
    this.handleClickReporter = this.handleClickReporter.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount() {
    const { selected_category, feeds } = this.props;

    let flagged_feeds = [];
    for (let report of selected_category.feed_reports) {
      let flagged_feed = feeds.find((feed) => feed.id === report.feed_id);
      if (flagged_feed === undefined) {
        continue;
      }

      if (
        flagged_feeds.find((feed) => feed.id === flagged_feed.id) === undefined
      ) {
        flagged_feed.reports = [report];
        flagged_feeds.push(flagged_feed);
      } else {
        flagged_feed.reports.push(report);
      }
    }

    this.setState({
      ...this.state,
      flagged: flagged_feeds,
    });
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    this.props.history.goBack();
  };

  getFeed = (report_id) => {
    const { flagged } = this.state;

    for (let feed of flagged) {
      for (let feed_report of feed.reports) {
        if (report_id === feed_report.id) {
          //return Object.assign({}, feed);
          return { ...feed };
        }
      }
    }
    return null;
  };

  // updateReport = (report) => {
  //   const { flagged } = this.state;

  //   let flagged_feeds = flagged.slice();
  //   for (let feed of flagged_feeds) {
  //     for (let feed_report of feed.reports) {
  //       if (report.id === feed_report.id) {
  //         feed_report = report;
  //       }
  //     }
  //   }

  //   this.setState({
  //     ...this.state,
  //     flagged: flagged_feeds
  //   });
  // }

  deleteReport = (report_id) => {
    const { flagged } = this.state;

    const flagged_feeds = [];
    for (let feed of flagged) {
      let reports = feed.reports.filter((report) => report.id !== report_id);
      if (reports.length > 0) {
        feed.reports = reports;
        flagged_feeds.push(feed);
      }
    }

    this.setState({
      ...this.state,
      flagged: flagged_feeds,
    });
  };

  deleteReports = (feed_id) => {
    const { flagged } = this.state;
    const flagged_feeds = flagged.filter((feed) => feed.id !== feed_id);
    this.setState({
      ...this.state,
      flagged: flagged_feeds,
    });
  };

  updateCategoryNotifications = async () => {
    const { selected_category, feeds } = this.props;
    const categoryController = new CategoryController();
    await categoryController.update_category_notifications(
      selected_category,
      feeds
    );
  };

  handleDeleteReport = async (report) => {
    const feed = this.getFeed(report.id);
    if (!feed) {
      return;
    }

    this.setWaiting(true);

    await deleteFeed(feed);
    this.props.deleteFeed(feed.id);
    this.props.deleteFeedReports(feed.id);

    await this.updateCategoryNotifications();

    this.setWaiting(false);
  };

  handleDismissReport = async (report) => {
    const { authUser, selected_category } = this.props;

    const feed = this.getFeed(report.id);
    if (!feed) {
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .delete_feed_report(report.id)
      .then(
        (result) => {
          this.deleteReport(report.id);
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_CATEGORY,
      type_id: selected_category.id,
      action: ACTIVITY_DELETE,
      object: `the report of feed ${feed.name}`,
      fromto: `of the category ${selected_category.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    await this.updateCategoryNotifications();

    this.setWaiting(false);
  };

  handleClickReporter = (report) => {
    const username = report.reported_user.username;
    const path = `/${ROUTES.USER_PREFIX}/${username}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  render() {
    const { classes, theme_mode, requesting } = this.props;
    const { flagged } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar title={"Flagged Feeds"} onNavBack={this.handleNavBack} />
        </div>
        <List className={classes.container}>
          {flagged.map((feed, index) => (
            <div key={index}>
              <ListItem className={classes.listitem} key={index}>
                <ListItemAvatar className={classes.listitem_avatar}>
                  <div>
                    <LazyLoadImage
                      className={classes.listimage}
                      alt={feed.name}
                      src={feed.thumbnail || feed.image || RAVEN_PLACEHOLDER_IMAGE}
                    />
                  </div>
                </ListItemAvatar>
                <ListItemText
                  id={index}
                  className={classes.listitem_text}
                  primary={
                    <div>
                      <Typography className={classes.name}>
                        {feed.name}
                      </Typography>
                      <Typography className={classes.description}>
                        {feed.description}
                      </Typography>
                    </div>
                  }
                />
              </ListItem>
              <div>
                {feed.reports.map((report, rindex) => (
                  <Grid
                    item
                    key={`feed-${index}-report-${rindex}`}
                    className={classes.report}
                  >
                    <Report
                      report={report}
                      theme_mode={theme_mode}
                      onDelete={this.handleDeleteReport}
                      onDismiss={this.handleDismissReport}
                      onClickReporter={this.handleClickReporter}
                    />
                  </Grid>
                ))}
              </div>
            </div>
          ))}
        </List>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

FeedsFlagged.propTypes = {
  className: PropTypes.string,
  theme_mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_category: state.dataState.selected_category,
  feeds: state.dataState.feeds,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedsFlagged);
