import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

class PopMenuFeed extends React.Component {
  render() {
    // const { classes, theme, onReport, onCopyLink, onModerate, onDuplicate } = this.props;
    const { classes, theme, showfirst, pending, onReport, onCopyLink, onModerate, onShowFirst } = this.props;

    return (
      <div>
        <MenuList className={classes.menu_list}>
          <MenuItem className={classes.menu_item} onClick={onReport}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"report"}
                src={`/static/images/icons/${theme}/warning.png`}
              />
            </span>
            Report Feed
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onCopyLink}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"copylink"}
                src={`/static/images/icons/${theme}/link.png`}
              />
            </span>
            Copy Link to Feed
          </MenuItem>
          <MenuItem 
            className={classes.menu_item}
            disabled={pending} 
            onClick={pending ? null : onModerate}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"moderate"}
                src={`/static/images/icons/${theme}/members.png`}
              />
            </span>
            {pending ? "Mod request pending" : "Apply to Moderate"}
          </MenuItem>
          {!showfirst &&
            <MenuItem className={classes.menu_item} onClick={e => onShowFirst(true)}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"moderate"}
                  src={`/static/images/icons/${theme}/notification.png`}
                />
              </span>
              Show First
            </MenuItem>
          }
          {showfirst &&
            <MenuItem className={classes.menu_item} onClick={e => onShowFirst(false)}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"moderate"}
                  src={`/static/images/icons/${theme}/notification_disabled.png`}
                />
              </span>
              Don't Show First
            </MenuItem>
          }
          {/* <MenuItem className={classes.menu_item} onClick={onDuplicate}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"duplicate"}
                src={`/static/images/icons/${theme}/copy.png`}
              />
            </span>
            Duplicate Feed
          </MenuItem> */}
        </MenuList>
      </div>
    );
  }
}

PopMenuFeed.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  showfirst: PropTypes.bool,
  pending: PropTypes.bool.isRequired,
  onReport: PropTypes.func,
  onCopyLink: PropTypes.func,
  onModerate: PropTypes.func,
  onDuplicate: PropTypes.func,
  onShowFirst: PropTypes.func
};

export default withStyles(styles)(PopMenuFeed);
