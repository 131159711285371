import { conf_social_types } from "constants/socialtypes";
import { check_source_link } from "utility/checklink";
import { logger } from "./logging";


export const build_source_link = (socialtype, tag) => {
    const social_type = conf_social_types.find(social_type => social_type.type === socialtype);

    // check if it has urlpattern
    if (!social_type) {
        return null;
    }

    // if (tag.includes("http") || tag.includes("https")) {
    //     return tag;
    // }

    switch(socialtype) {
        case "youtube_channel_feed":
            return social_type.url + tag;
        case "youtube_user_id":
            return social_type.url + tag;
        case "youtube_playlist_feed":
            return social_type.url + tag;
        case "youtube_handle_feed":
            return social_type.url + tag;
        case "youtube_static_link":
            return tag;
        case "youtube_static_feed":
            return tag;
        case "twitter_api_userid":
            return social_type.url + tag;
        case "twitter_api_query":
            return social_type.url + tag + "&src=typed_query";
        case "twitter_api_list":
            return social_type.url + tag;
        case "twitter_static_link":
            return tag;
        case "instagram_user_feed":
            return social_type.url + tag;
        case "instagram_hashtag_feed":
            return social_type.url + tag;
        case "instagram_static_link":
            return tag;
        case "twitch_user_video":
            return social_type.url + tag;
        case "medium_userprofile_feed":
            return social_type.url + tag;
        case "medium_publication_feed":
            return social_type.url + tag;
        case "medium_topic_feed":
            return social_type.url + tag;
        case "reddit_api_category":
            return social_type.url + tag;
        case "hackernews_api_story":
            return social_type.url + tag;
        case "slashdot_topic_feed":
            return social_type.url + tag;
        case "podcast_channel_feed":
            return tag;
        case "rss_url_feed":
            return tag;
        case "tiktok_user_feed":
            return social_type.url + tag;
        case "tiktok_hashtag_feed":
            return social_type.url + tag;
        case "tiktok_static_link":
            return tag;
        case "pinterest_rss_feed":
            return tag.replace('.rss', '');
        case "pinterest_static_link":
            return tag;
        case "vsco_user_images":
            return social_type.url + tag;
        case "spotify_user_playlists":
            return social_type.url + tag;
        case "spotify_playlists":
            return social_type.url + tag;
        case "quora_topic_feed":
            return social_type.url + tag;
        case "quora_space_feed":
            return social_type.url + tag;
        case "facebook_user_feed":
            return social_type.url + tag;
        case "substack_user_archive":
            return tag;
        case "gmail_subscribe":
            return null;
        case "vimeo_channel_videos":
            return social_type.url + tag;
        case "vimeo_category_videos":
            return social_type.url + tag;
        case "vimeo_group_videos":
            return social_type.url + tag;
        case "vimeo_user_videos":
            return social_type.url + tag;
        case "website_static_link":
            return tag;
        case "mastodon_user_feed":
            return tag;
        case "bluesky_user_feed":
            return social_type.url + tag;
        case "threads_user_feed":
            return tag;
        case "user_post_feed":
            return null;
        default:
            return null;
    }
}


export const get_source_links = (sourcesTxt) => {
    let source_links = [];
    const sources_txt = sourcesTxt.trim();
    if (sources_txt.length === 0) {
      return source_links;
    }

    let sources = sourcesTxt.split(/\r?\n/);
    sources = sources.filter(source => source.trim().length > 0);
    if (sources.length === 0) {
      return source_links;
    }

    // get uniq sources
    sources = Array.from(new Set(sources));

    source_links = sources
      .map(link => check_source_link(link))
      .filter(source => source !== null);
    logger.log("source links :", source_links);

    return source_links;

    // // remove duplicate links from the same site
    // let hostnames = [];
    // let new_source_links = [];
    // for (let link of source_links) {
    //   let hostname = get_hostname(link.url);
    //   if (hostnames.includes(hostname)) {
    //     continue;
    //   } else {
    //     hostnames.push(hostname);
    //     new_source_links.push(link);
    //   }
    // }

    // return new_source_links;
}