import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { List, Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { 
  withAuthentication, 
  withAuthorization,
} from "session";
import { SearchAppBar, WaitingSpinner } from "components";
import { MemberItem } from "./components";
import * as ROUTES from "constants/routes";
import { BRANCH_ALL } from "constants/branches";
import { ALL } from "constants/country";
import { 
  getMainInfo,
  getFeedInfo,
  isBanned,
  followUser,
  unfollowUser,
} from "dataapis";
import { withFirebase } from 'services';
import { MAX_WINDOW_WIDTH } from "constants/types";
import { ToastError } from "utility/toast";


const condition = authUser => !!authUser && authUser.uid.length > 0;


const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
    zIndex: 1200
  },
  membercontainer: {
    margin: theme.spacing(2),
    margintop: theme.spacing(1),
    padding: 0,
    backgroundColor: theme.palette.background.default
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
    color: theme.palette.text.primary,
    textTransform: "none"
  },
});

class FeedMembers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchKey : "",
      moderators: [],
      ifollow   : [],
      others    : []
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleClickFollow = this.handleClickFollow.bind(this);
    this.handleClickUnfollow = this.handleClickUnfollow.bind(this);
    this.handleClickMember = this.handleClickMember.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  componentDidMount = async () => {
    const { match } = this.props;

    this.setWaiting(true);

    await getMainInfo();
    let feed_slug = null;
    if (this.props.selected_feed === null || this.props.selected_feed === undefined) {
      feed_slug = match.params.slug;
    } else {
      feed_slug = this.props.selected_feed.slug;
    }
    await getFeedInfo(feed_slug);

    this.setWaiting(false);
    
    const { selected_feed } = this.props;

    // get users followed by me
    const { loggedIn, authUser } = this.props;
    let users_followed = []
    if (loggedIn) {
      users_followed = authUser.users_followed.slice();
    }

    // remove moderators from the followers
    let moderators = selected_feed.feed_moderators.slice();
    let followers = selected_feed.feed_followers.slice();
    for (let moderator of moderators) {
      followers = followers.filter(follower => follower.user_id !== moderator.user_id);
    }

    // remove users followed by me from the moderators and the followers
    for (let followed of users_followed) {
      moderators = moderators.filter(moderator => moderator.user_id !== followed.user_id);
      followers = followers.filter(follower => follower.user_id !== followed.user_id);
    }
    if (loggedIn) {
      moderators = moderators.filter(moderator => moderator.user_id !== authUser.uid);
      followers = followers.filter(follower => follower.user_id !== authUser.uid);
    }

    this.setState({
      ...this.state,
      moderators: moderators,
      ifollow: users_followed,
      others: followers
    });
  }

  updateUsers = () => {
    const { selected_feed } = this.props;

    // get users followed by me
    const { loggedIn, authUser } = this.props;
    let users_followed = []
    if (loggedIn) {
      users_followed = authUser.users_followed.slice();
    }

    // remove moderators from the followers
    let moderators = selected_feed.feed_moderators.slice();
    let followers = selected_feed.feed_followers.slice();
    for (let moderator of moderators) {
      followers = followers.filter(follower => follower.user_id !== moderator.user_id);
    }

    // remove users followed by me from the moderators and the followers
    for (let followed of users_followed) {
      moderators = moderators.filter(moderator => moderator.user_id !== followed.user_id);
      followers = followers.filter(follower => follower.user_id !== followed.user_id);
    }
    if (loggedIn) {
      moderators = moderators.filter(moderator => moderator.user_id !== authUser.uid);
      followers = followers.filter(follower => follower.user_id !== authUser.uid);
    }

    this.setState({
      ...this.state,
      moderators: moderators,
      ifollow: users_followed,
      others: followers
    });
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleClickFollow = async (member) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await followUser(member.user_id);
    this.setWaiting(false);

    this.updateUsers();
  }

  handleClickUnfollow = async (member) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await unfollowUser(member.user_id);
    this.setWaiting(false);

    this.updateUsers();
  }

  handleClickMember = member => {
    if (member.user.username === null || member.user.username.length === 0) {
      return;
    }
    let route = `/${ROUTES.USER_PREFIX}/${member.user.username}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  handleSearchChange = searchKey => {
    this.setState({
      ...this.state,
      searchKey: searchKey
    });
  };

  handleSearchEnter = searchKey => {};

  handleNavBack = () => {
    const { selected_feed } = this.props;

    let location = null;
    if (selected_feed === null) {
      location = {
        pathname: ROUTES.HOME,
        state: { animation: "right" }
      };
    } else {
      location = {
        pathname: `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`,
        state: { animation: "right" }
      };
    }

    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.showTopNavbar(true);
  };

  searchMembers = (members, searchKey) => {
    const key = searchKey.toLowerCase();
    const members2show = members.filter(member => 
      (member.user.name && member.user.name.toLowerCase().includes(key)) ||
      (member.user.username && member.user.username.toLowerCase().includes(key)) ||
      (member.user.biography && member.user.biography.toLowerCase().includes(key))
    );
    return members2show;
  }

  render() {
    const { 
      classes,
      loggedIn,
      theme_mode, 
      requesting 
    } = this.props;
    const { 
      searchKey, 
      moderators, 
      ifollow, 
      others
    } = this.state;

    let moderators2show = moderators.slice();
    if (searchKey) {
      moderators2show = this.searchMembers(moderators, searchKey);
    }
    let ifollow2show = ifollow.slice();
    if (searchKey) {
      ifollow2show = this.searchMembers(ifollow, searchKey);
    }
    let others2show = others.slice();
    if (searchKey) {
      others2show = this.searchMembers(others, searchKey);
    }

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <SearchAppBar
            title={"Members"}
            onSearchChange={this.handleSearchChange}
            onSearchEnter={this.handleSearchEnter}
            onNavBack={this.handleNavBack}
          />
        </div>

        <div className={classes.membercontainer}>
          <Button className={classes.title}>{"Admins and Moderators"}</Button>
          <List>
            {moderators2show.map(member => (
              <MemberItem
                key={member.user_id}
                member={member}
                loggedIn={loggedIn}
                following={false}
                theme_mode={theme_mode}
                onClicked={this.handleClickMember}
                onFollowed={this.handleClickFollow}
                onUnfollowed={this.handleClickUnfollow}
              />
            ))}
          </List>
        </div>
        <div className={classes.membercontainer}>
          <Button className={classes.title}>{"People I Follow"}</Button>
          <List>
            {ifollow2show.map(member => (
              <MemberItem
                key={member.user_id}
                member={member}
                loggedIn={loggedIn}
                following={true}
                theme_mode={theme_mode}
                onClicked={this.handleClickMember}
                onFollowed={this.handleClickFollow}
                onUnfollowed={this.handleClickUnfollow}
              />
            ))}
          </List>
        </div>
        <div className={classes.membercontainer}>
          <Button className={classes.title}>{"Others"}</Button>
          <List>
            {others2show.map(member => (
              <MemberItem
                key={member.user_id}
                member={member}
                loggedIn={loggedIn}
                following={false}
                theme_mode={theme_mode}
                onClicked={this.handleClickMember}
                onFollowed={this.handleClickFollow}
                onUnfollowed={this.handleClickUnfollow}
              />
            ))}
          </List>
        </div>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  selected_feed: state.dataState.selected_feed,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedMembers);
