import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import { GraphqlService, stripeService } from "services";
import { logger } from "utility/logging";
import { GRAPHQL_ERROR } from "constants/types";
import { forEach } from "lodash";


export default function CheckoutForm(props) {
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const RAVEN_SUBSCRIPTION = 0;
  const PAY_AS_YOU_GO = 1;
  const VOLUME_DISCOUNT=2;
  const msgToDisplay = props.paymentType === RAVEN_SUBSCRIPTION ? 'Order Raven Membership' : props.paymentType ===PAY_AS_YOU_GO ?'Order Raven Pay-as-you-go Plan':'Order Raven Volume-Discount Plan'

  useEffect(() => {
    const getPriceDetails = async () =>{

      const gqlservice = new GraphqlService();
      const product_prices =await gqlservice.get_price_by_payment_type(props.paymentType)  
      console.log("prices",product_prices)
      if(product_prices.data.status_code === GRAPHQL_ERROR){
        setError("Error getting price details")
        return
      }
      const results = product_prices.data.raven_membership_products
      if(results.length>0){
        setAmount(results[0].amount / 100);
        setCurrency(results[0].currency);
      }
  }
      getPriceDetails()

    // Step 1: Fetch product details such as amount and currency from
    // API to make sure it can't be tampered with in the client.

    // stripeService.getProductDetails(props.paymentType).then((productDetails) => {
    //   console.log("6^^^^^^^^^^^",productDetails)
    //   setAmount(productDetails.amount / 100);
    //   setCurrency(productDetails.currency);
    // });

    logger.log("CheckoutForm user :", props.user);

    // Step 2: Create PaymentIntent over Stripe API

  }, [props.paymentType, props.user]);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    try{
      const paymentType =parseInt(props.paymentType)
  
      // Step 3: Use clientSecret from PaymentIntent and the CardElement
      // to confirm payment with stripe.confirmCardPayment()
      let payload;
      if(paymentType === PAY_AS_YOU_GO || paymentType === VOLUME_DISCOUNT){
        payload= await payementForPayAsYouGo(ev)
      }
      if(paymentType === RAVEN_SUBSCRIPTION){
        payload = await ravenSubscription()
  
      }
      console.log("paayload",payload)
      if (payload.error) {
        setError(`Payment failed: ${payload.error.message}`);
        setProcessing(false);
        logger.log("[error]", payload.error);
        props.onError(payload.error);
      } else {
        setError(null);
        setSucceeded(true);
        setProcessing(false);
        setMetadata(payload.paymentIntent);
        logger.log("[PaymentIntent]", payload.paymentIntent);
        props.onSuccess(payload);
      }
    }catch(err){
      console.log(JSON.stringify(err))
    }
  };

  const payementForPayAsYouGo = async (ev) => {
    ev.persist();
    try {
      const clientSecret = await stripeService.createPaymentIntent(props.paymentType, props.user);
      logger.log("clientSecret :", clientSecret);
      if(clientSecret.error){
        return clientSecret
      }
      setClientSecret(clientSecret);
  
      let payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: ev.target.name.value,
          },
        },
      });
      return {...payload , paymentType:props.paymentType};
    } catch (error) {
      // Handle the error case here
      setError(`${error.message}`);
      return error
    }
  };
  
 
  const ravenSubscription = async () =>{
    try{

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          email: props.user.email,
        },
      });
  
      if(result.error){
        return result
      }else{
        const data = {
          payment_method:result.paymentMethod.id,
          email: props.user.email,
          name: props.user.name,
          paymentType:props.paymentType
  
        }
        const res =await stripeService.createSubscriptionIntent(data ,props.user)
        console.log("StripeData" ,res)
        if(res.error){
         return res
        }else{
          const {client_secret, status} = res;
          if (status === 'requires_action' || status ==='succeeded') {
            try{
             let payload = await  stripe.confirmCardPayment(client_secret)
             payload.paymentType = props.paymentType
             return payload
            }catch(err){
              setError(JSON.stringify(err))
              return err
            }
        }
      }
  
    }
    }catch(error){
      console.log("error occured on subscription :",error)
      return { error:true , message:error.message}
    }
}
      
  const renderSuccess = () => {
    return (
      <div className="sr-field-success message">
        <h1>Your test payment succeeded</h1>
        <p>View PaymentIntent response:</p>
        <pre className="sr-callout">
          <code>{JSON.stringify(metadata, null, 2)}</code>
        </pre>
      </div>
    );
  };

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    return (
      <form onSubmit={handleSubmit}>
        <h1>
          {currency.toLocaleUpperCase()}{" "}
          {amount.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}{" "}
        </h1>
        <h4>{msgToDisplay}</h4>

        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              autoComplete="cardholder"
              className="sr-input"
            />
          </div>

          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options}
            />
          </div>
        </div>

        {error && <div className="message sr-field-error">{error}</div>}

        <button
          className="btn"
          disabled={processing  || !stripe}
        >
          {processing ? "Processing…" : "Pay"}
        </button>
      </form>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
}
