import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { MIN_CARD_WIDTH, MAX_CARD_WIDTH } from "constants/types";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_CARD_WIDTH,
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    padding: 0,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
  banicon: {
    padding: 2,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
  actionbtn: {
    backgroundColor: theme.palette.background.main,
    borderRadius: "20px",
    padding: "4px 10px",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.background.main,
    },
  },
});

class CommentModButtons extends React.Component {

  render() {
    const {
      classes,
      theme,
      comment,
      onApprove,
      onDelete,
      onBan1d,
      onBan7d,
      onBanPerm
    } = this.props;

    return (
      <div className={classes.root}>
        {/* approve button */}
        <Button
          className={classes.actionbtn}
          startIcon={
            <img
              className={classes.icon}
              alt="approve"
              src={`/static/images/approve.png`}
            />
          }
          onClick={e => onApprove(comment)}
        >
          Approve
        </Button>

        {/* delete button */}
        <Button
          className={classes.actionbtn}
          startIcon={
            <img
              className={classes.icon}
              alt="delete"
              src={`/static/images/delete.png`}
            />
          }
          onClick={e => onDelete(comment)}
        >
          Delete
        </Button>

        {/* 1 day ban button */}
        <Button
          className={classes.actionbtn}
          startIcon={
            <img
              className={classes.banicon}
              alt="1dban"
              src={`/static/images/icons/${theme}/banned.png`}
            />
          }
          onClick={e => onBan1d(comment)}
        >
          1d Ban
        </Button>

        {/* 7 day ban button */}
        <Button
          className={classes.actionbtn}
          startIcon={
            <img
              className={classes.banicon}
              alt="7dban"
              src={`/static/images/icons/${theme}/banned.png`}
            />
          }
          onClick={e => onBan7d(comment)}
        >
          7d Ban
        </Button>

        {/* permanent ban button */}
        <Button
          className={classes.actionbtn}
          startIcon={
            <img
              className={classes.banicon}
              alt="permban"
              src={`/static/images/icons/${theme}/banned.png`}
            />
          }
          onClick={e => onBanPerm(comment)}
        >
          Perm Ban
        </Button>
      </div>
    );
  }
}

CommentModButtons.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  comment: PropTypes.object,
  onApprove: PropTypes.func,
  onDelete: PropTypes.func,
  onBan1d: PropTypes.func,
  onBan7d: PropTypes.func,
  onBanPerm: PropTypes.func
};

export default withStyles(styles)(CommentModButtons);
