import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withFirebase } from 'services';
import { withAuthentication } from "session";
import { withStyles } from "@material-ui/core/styles";
import MetaTags from "react-meta-tags";
import { Grid } from "@material-ui/core";
import { ToastContainer } from 'react-toastify';
import _ from "lodash";
import {
  SplashScreen,
  BottomNavBar,
  ArticleList,
  ArticleMasonry,
  // TopFeeds,
  DlgVideo,
  DlgAlert,
  DlgLoginConfirm,
  DlgPostEdit,
  WaitingSpinner,
  DlgPost,
  DlgWeblink
} from "components";
import { MainAppBar, FeedsAppBar } from "./components";
import { 
  ARTICLE_BRANCH_NEWSPAPER, 
  ARTICLE_BRANCH_PODCAST, 
  ARTICLE_BRANCH_PROFILEPOST,
  ARTICLE_BRANCH_USERPOST, 
  BRANCH_ALL 
} from "constants/branches";
import { ALL } from "constants/country";
import * as ROUTES from "constants/routes";
import {
  USE_QUOTA,
  FREE_FEED_CREATE_LIMIT,
  PAID_FEED_CREATE_LIMIT,
  TAB_FEED,
  MIN_TABLET_WIDTH,
  MAX_WINDOW_WIDTH,
  MAX_CARD_WIDTH,
  GRAPHQL_SUCCESS,
  APPROVING_POSTS,
} from "constants/types";
import { 
  GraphqlService,
  createCategorySubscriber, 
  createFeedSubscriber,
  // createSourceSubscriber,
  createNotificationSubscriber,
  Mixpanel
} from "services";
import { 
  getAuthToken,
  getMainInfo,
  getPinsAndMovetops,
  getFeeds2show,
  hasPaid,
  isBannedFeedCreation,
  reportArticle,
  deleteArticle,
  saveArticle,
  updatePost,
  getArticle,
  isBannedComments,
  isBannedPosts,
  updateCache,
  isFeedModerator,
  upvoteArticle,
  repostArticle,
  getFeedofSource,
  upvoteThread,
  setBaseData
} from "dataapis";
import { get_hash, is_source_alive, summarize_text } from "utility/utils";
import { ToastError, ToastInfo } from "utility/toast";
import { logger } from "utility/logging"; 
import * as MIXPANEL_EVENTS from "constants/mixpanel"; 
import { approveAiPosts, get_link_source, moderate_image, getInfo } from "utility/ravenapi";
import { resizeImageFile } from "utility/resizeimage";
import { ACTIVITY_ADD, ACTIVITY_TYPE_FEED } from "constants/activity"; 
import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import{postToMastodon,postToBluesky}from "utility/ravenapi"
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    zIndex: 10,
  },
  topfeeds: {
    position: "sticky",
    right: 0,
    backgroundColor: theme.palette.background.default,
  },
  addbutton: {
    position: "fixed",
    cursor: "pointer",
    bottom: theme.spacing(5),
    // right: theme.spacing(1),
    width: 60,
    height: 60,
    zIndex: 1200,
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.categoryConsumer = null;
    this.feedConsumer = null;
    this.sourceConsumer = null;
    this.notificationConsumer = null;

    this.state = {
      refetch: null,
      notifications: [],
      hasPodcasts: false,
      loginDlg: false,
      helpDlg: false,
      postDlg: false,
      webDlg:false,
      postFeed: null,
      postEditDlg: false,
      article_edit: null,
      alertDlg: false,
      alertTitle: "",
      alertMsg: ""
    };

    this.handleNewFeeds = this.handleNewFeeds.bind(this);
    this.handleComments = this.handleComments.bind(this);
    this.handlePlayHelp = this.handlePlayHelp.bind(this);
    this.handleCloseHelp = this.handleCloseHelp.bind(this);

    this.handleNotification = this.handleNotification.bind(this);
    this.handleProfileMenu = this.handleProfileMenu.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleClickBackButton = this.handleClickBackButton.bind(this);

    this.handleClickDiscover = this.handleClickDiscover.bind(this);
    this.handleClickCreate = this.handleClickCreate.bind(this);
    this.handleClickPodcasts = this.handleClickPodcasts.bind(this);
    this.handleClickCleanAir = this.handleClickCleanAir.bind(this);
    this.handleClickVideocasts = this.handleClickVideocasts.bind(this);
    // this.handleClickBirdtok = this.handleClickBirdtok.bind(this);

    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.handleReachLimit = this.handleReachLimit.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);

    this.handleNeedMore = this.handleNeedMore.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeBranch = this.handleChangeBranch.bind(this);

    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.showAddPostDlg = this.showAddPostDlg.bind(this);
    this.showADlgWeb = this.showADlgWeb.bind(this);
    this.closePostDlg = this.closePostDlg.bind(this);
    this.handleSelectPostFeed = this.handleSelectPostFeed.bind(this);
    this.handleSubmitPost = this.handleSubmitPost.bind(this);
    this.handleApprovePosts = this.handleApprovePosts.bind(this);

    this.handleOK = this.handleOK.bind(this);
    this.closePostEditDlg = this.closePostEditDlg.bind(this);
    this.handleUpdatePost = this.handleUpdatePost.bind(this);
    
    this.handleClickThread = this.handleClickThread.bind(this);
    this.handleClickUpvoteThread = this.handleClickUpvoteThread.bind(this);
  }

  componentDidMount = async () => {
     
    // const feeds2show = getFeeds2show();
    if (this.props.articles.length <=0) {
      await this.initialize();
    }
    // if (this.checkOnline()) {
    //   await this.initialize();
    // } else {
    //   ToastError("Internet disconnected! Check your internet connection.");
    // }
  };
    
   componentDidUpdate = async (prevProps) => {
    const { loggedIn, authUser } = this.props;
    if(prevProps.authUser.uid != authUser.uid){
       await this.initialize();
    }
    if ((!prevProps.loggedIn && loggedIn) || (prevProps.loggedIn===null && !loggedIn)) {
      //await this.initialize();
    }
    if(prevProps.loggedIn && loggedIn && !this.props.initialized){
      //await this.initialize();
    }
  } 
   setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  checkOnline = () => {
    return window.navigator.onLine ? true : false;
  };
  initialize = async () => {
    this.setWaiting(true);
    const { authUser } = this.props;
    
    var params = JSON.stringify({ 
      user:authUser.uid?authUser.uid:null
     });  
    await getInfo(params)
      .then(
        async (result) => {
          if (result.status === 200) {
              let data = result.data 
              const feed_ids = data.feeds2show.map((feed) => feed.id);
              await Promise.all([
                this.getNotifications(), 
                getPinsAndMovetops(feed_ids), 
              ]);
              if(data.aiComments){
                this.props.setFollowedComments(data.aiComments);
              }
              this.props.setShowFirstArticles(data.showfirst_articles);
              this.props.setArticles(data.articles, data.articles.length);
              
              setBaseData(data) 
              
              if(data.myPosts){
                this.props.setMyPosts(data.myPosts); 
              }
              this.setWaiting(false);
          } else {
            this.setError("Failed to get Info.");
            this.setWaiting(false);
            return;
          }
        },
        (reason) => {
          this.setError("Failed to get Infos.");
          this.setWaiting(false);
          return;
        }
      ) 
      .catch((err) => { 
        this.setError(err);
        this.setWaiting(false);
        return;
      }); 
    // the subscribed feeds have podcasts?
    const podcasts2show = this.getPodcastSourceIds2show();
    this.setState({
      ...this.state,
      hasPodcasts: podcasts2show.source_ids.length > 0
    });

    this.props.setModerate(false);
    this.setWaiting(false);
  }
  initializeOld  = async () => {
    this.setWaiting(true);

    await getMainInfo(false);

    const { loggedIn, feeds, articles } = this.props;
    const feed_ids = feeds.map((feed) => feed.id);
    await Promise.all([
      this.getNotifications(), 
      getPinsAndMovetops(feed_ids),
      // this.registerSubscribers(loggedIn),
     ]);
    
    // load articles
    if (this.props.articles.length === 0) {
      this.props.refreshArticles();
      if (loggedIn) {
        await this.getArticlesOfShowFirstFeeds();
      }
      await this.getArticlesOfFirstPage();
    }

    // the subscribed feeds have podcasts?
    const podcasts2show = this.getPodcastSourceIds2show();
    logger.log("podcasts2show :", podcasts2show);
    this.setState({
      ...this.state,
      hasPodcasts: podcasts2show.source_ids.length > 0
    });

    this.props.setModerate(false);

    const { showfirst_articles } = this.props;
    if (this.props.branch === BRANCH_ALL) {
      //const {articles,showfirst_articles} = this.props;
      if (articles.length > 0 || showfirst_articles.length > 0) {
        this.setWaiting(true);
        const articlesList =
          articles.length > 0 ? articles : showfirst_articles;
        const lastArticleElement = _.last(articlesList);
        const commentFromDate = new Date(
          lastArticleElement.published * 1000
        ).toISOString();
        // await this.getThreadsOfFollwedAIPersonas(commentFromDate,null)

        await this.getUserFollowedAIPersonas(commentFromDate, null);
        await this.getUserSelfPosts(commentFromDate, null);
        this.setWaiting(false);
      }
    }

    this.setWaiting(false);
  };

  //user self posts
  getUserSelfPosts = async (fromDate, toDate) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    const gqlservice = new GraphqlService();
  
    const sourceId = `${authUser.uid}-selfpost`
    const fromDateTimestamp = fromDate ? Math.floor(new Date(fromDate).getTime() / 1000) : null;
    const toDateTimestamp = toDate ? Math.floor(new Date(toDate).getTime() / 1000) : null;

    await gqlservice
    .query_selfposts_by_user(authUser.uid, sourceId, fromDateTimestamp, toDateTimestamp)
    .then(
      result => {
        let my_posts = result?.data?.articles;
        logger.log("my articles :", my_posts);
        this.props.setMyPosts(my_posts);
      },
      reason => {
        this.setError(reason.msg);
      }
    )
    .catch(err => {
      this.setError(JSON.stringify(err));
    });
  }

  registerSubscribers = (loggedIn) => {
    const categorySubscriber = createCategorySubscriber(loggedIn);
    this.categoryConsumer = categorySubscriber.subscribe(data => {
      const categories = data.data.categories;
      logger.log("Categories updated :", categories);
      this.props.setCategories(categories);
    }, (err) => {
      let msg = "Error subscribing categories: " + err;
      logger.error(msg);
    });

    const feedSubscriber = createFeedSubscriber(loggedIn);
    this.feedConsumer = feedSubscriber.subscribe(data => {
      const feeds = data.data.feeds;
      logger.log("Feeds updated :", feeds);      
      this.props.setFeeds(feeds);
    }, (err) => {
      let msg = "Error subscribing feeds: " + err;
      logger.error(msg);
    });

    // const sourceSubscriber = createSourceSubscriber(loggedIn);
    // this.sourceConsumer = sourceSubscriber.subscribe(data => {
    //   const sources = data.data.sources;
    //   logger.log("Sources updated :", sources);
    //   this.props.setSources(sources);
    // }, (err) => {
    //   let msg = "Error subscribing sources: " + err;
    //   logger.error(msg);
    // });

    if (loggedIn) {
      const { authUser} = this.props;
      const notificationSubscriber = createNotificationSubscriber(authUser.uid);
      this.notificationConsumer = notificationSubscriber.subscribe(data => {
        const notifications = data.data.notifications;
        logger.log("Notifications updated :", notifications);      
        this.setState({
          ...this.state,
          notifications: notifications
        });
      }, (err) => {
        let msg = "Error subscribing notifications: " + err;
        logger.error(msg);
      });
    }
  };

  unregisterSubscribers = () => {
    if (this.categoryConsumer) {
      this.categoryConsumer.unsubscribe();
    }
    if (this.feedConsumer) {
      this.feedConsumer.unsubscribe();
    }
    if (this.sourceConsumer) {
      this.sourceConsumer.unsubscribe();
    }
    if (this.notificationConsumer) {
      this.notificationConsumer.unsubscribe();
    }
  }

  goTo = (location) => {
    this.unregisterSubscribers();
    this.props.history.push(location);
  }

  getNotifications = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    await gqlservice.notifications_to_user(authUser.uid)
      .then(result => {
        const notifications = result.data.notifications;
        this.setState({
          ...this.state,
          notifications: notifications
        });
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  getPinsAndMovetops = async () => {
    const { feeds } = this.props;

    const feed_ids = feeds.map((feed) => feed.id);

    const gqlservice = new GraphqlService();
    await gqlservice
      .article_pins_in_feeds(feed_ids)
      .then(
        (result) => {
          const pins = result.data.article_pins;
          if (pins.length > 0) {
            this.props.setArticlePins(pins);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    await gqlservice
      .article_movetops_in_feeds(feed_ids)
      .then(
        (result) => {
          const movetops = result.data.article_movetops;
          if (movetops.length > 0) {
            this.props.setArticleMovetops(movetops);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getSourceIds2show = () => {
    const {
      loggedIn,
      authUser,
      default_feeds,
      followed_feeds,
    } = this.props;

    let feeds = default_feeds;
    if (loggedIn && followed_feeds.length > 0) {
      feeds = followed_feeds.slice();
    }

    let articles_no = 0;
    let source_ids = [];
    for (let feed of feeds) {
      if (loggedIn && authUser.feed_sources_unfollowed.length > 0) {
        let feed_sources = feed.feed_sources
          .filter(
            (feed_source) =>
              feed_source.approved &&
              is_source_alive(feed_source.source) &&
              authUser.feed_sources_unfollowed.findIndex(
                (unfollowed) =>
                  unfollowed.feed_id === feed.id &&
                  unfollowed.source_id === feed_source.source_id
              ) === -1
          );

        for (let feed_source of feed_sources) {
          if (source_ids.indexOf(feed_source.source_id) === -1) {
            source_ids.push(feed_source.source_id);
            articles_no += feed_source.source.articles_no;
          }
        }
      } else {
        let feed_sources = feed.feed_sources
          .filter((feed_source) => 
            feed_source.approved && 
            is_source_alive(feed_source.source)
          );

        for (let feed_source of feed_sources) {
          if (source_ids.indexOf(feed_source.source_id) === -1) {
            source_ids.push(feed_source.source_id);
            articles_no += feed_source.source.articles_no;
          }
        }
      }
    }

    return {
      source_ids: source_ids,
      articles_no: articles_no
    };
  };

  getArticlesOfShowFirstFeeds = async () => {
    const { loggedIn, authUser, branch, country } = this.props;

    let showfirst_feed_ids = [];
    if (loggedIn && authUser.feeds_showfirst.length > 0) {
      showfirst_feed_ids = authUser.feeds_showfirst.map(item => item.feed_id);
    }
    logger.log("showfirst feeds :", showfirst_feed_ids);
    if (showfirst_feed_ids.length === 0) {
      return;
    }

    if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      if (country === ALL) {
        await this.getArticlesOfBranchInFirstPage(showfirst_feed_ids, branch, true);
      } else {
        await this.getArticlesOfCountryInFirstPage(showfirst_feed_ids, country, true);
      }
    } else {
      if (branch === BRANCH_ALL) {
        await this.getArticlesInFirstPage(showfirst_feed_ids, true);
      } else {
        await this.getArticlesOfBranchInFirstPage(showfirst_feed_ids, branch, true);
      }
    }
  }

  getArticlesOfFirstPage = async () => {
    const { branch, country } = this.props;

    const feeds2show = getFeeds2show();
    if (feeds2show.length === 0) {
      this.props.refreshArticles();
      return;
    }
    logger.log("feeds to show :", feeds2show);
    const feed_ids = feeds2show.map((feed) => feed.id);

    if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      if (country === ALL) {
        await this.getArticlesOfBranchInFirstPage(feed_ids, branch);
      } else {
        await this.getArticlesOfCountryInFirstPage(feed_ids, country);
      }
    } else {
      if (branch === BRANCH_ALL) {
        await this.getArticlesInFirstPage(feed_ids);
      } else {
        await this.getArticlesOfBranchInFirstPage(feed_ids, branch);
      }
    }
  };

  handleNeedMore = async () => {
    const { 
      country, 
      branch, 
      last_offset, 
      requesting,
      articles,
      followedThreads,
      followedComments 
    } = this.props;
    
    // it means there is no articles in these sources
    if (last_offset === 0) {
      this.props.showBottomNavbar(false);
      return;
    }
    if (requesting) {
      return;
    }

    const feeds2show = getFeeds2show();
    if (feeds2show.length === 0) {
      this.props.refreshArticles();
      return;
    }
    logger.log("feeds to show :", feeds2show);
    const feed_ids = feeds2show.map((feed) => feed.id);

    const preArticleElement = _.last(articles); 
    const commentFromDate = new Date(preArticleElement?.published * 1000).toISOString();

    this.setWaiting(true);

    if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      if (country === ALL) {
        await this.getArticlesOfBranchInNextPage(feed_ids, branch, last_offset);
      } else {
        await this.getArticlesOfCountryInNextPage(feed_ids, country, last_offset);
      }
    } else {
      if (branch === BRANCH_ALL) {
        await this.getArticlesInNextPage(feed_ids, last_offset);
        const lastArticleElement = _.last(articles);  
        const commentToDate = new Date(lastArticleElement.published* 1000).toISOString(); 
        if (followedThreads.length === 0 || followedComments.length === 0) {
          // await this.getThreadsOfFollwedAIPersonas(commentFromDate,null)
          await this.getUserFollowedAIPersonas(commentFromDate,null)
          await this.getUserSelfPosts(commentFromDate,null)
        } else {
          // await this.getThreadsOfFollwedAIPersonas(commentFromDate,commentToDate)
          await this.getUserFollowedAIPersonas(commentToDate, commentFromDate)
          await this.getUserSelfPosts(commentToDate, commentFromDate)
        }
      } else {
        await this.getArticlesOfBranchInNextPage(feed_ids, branch, last_offset);
      }
    }
    
    this.setWaiting(false);
  };

  getArticlesInFirstPage = async (feed_ids, showfirst=false) => {
    if (!feed_ids || feed_ids.length === 0) {
      return;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_in_feeds(feed_ids, 0)
      .then(
       async (result) => {
          const feed_articles = result.data;
          logger.log("Home => articles in feeds(first page) :", feed_articles);
          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);

            if (this.props.branch === BRANCH_ALL) {
              const lastArticleElement = _.last(articles);
              const commentFromDate = new Date(
                lastArticleElement.published * 1000
              ).toISOString();
              try {
                await this.getUserFollowedAIPersonas(commentFromDate, null);
                await this.getUserSelfPosts(commentFromDate, null);
              } catch (err) {
                logger.log("comment of followed ai persons error :", err);
              }
            }

            if (showfirst) {
              this.props.setShowFirstArticles(articles);
            } else {
              this.props.setArticles(articles, articles.length);
            }
            this.props.showBottomNavbar(true);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesInNextPage = async (feed_ids, last_offset) => {
    if (!feed_ids || feed_ids.length === 0) {
      return;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_in_feeds(feed_ids, last_offset)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("Home => articles in feeds(next page) :", feed_articles);
          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.appendArticles(articles, last_offset + articles.length);
          } else {
            this.props.appendArticles([], last_offset);
          }
          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesOfBranchInFirstPage = async (feed_ids, branch, showfirst=false) => {
    if (!feed_ids || feed_ids.length === 0) {
      this.props.refreshArticles();
      return;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_branch_in_feeds(feed_ids, branch, 0)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("Home => articles in feeds of branch(first page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            if (showfirst) {
              this.props.setShowFirstArticles(articles);
            } else {
              this.props.setArticles(articles, articles.length);
            }
          }

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesOfBranchInNextPage = async (feed_ids, branch, last_offset) => {

    if (!feed_ids || feed_ids.length === 0) {
      this.props.refreshArticles();
      return;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_branch_in_feeds(feed_ids, branch, last_offset)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("Home => articles in feeds of branch(next page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.appendArticles(articles, last_offset + articles.length);
          } else {
            this.props.appendArticles([], last_offset);
          }          

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesOfCountryInFirstPage = async (feed_ids, country, showfirst=false) => {

    if (!feed_ids || feed_ids.length === 0) {
      this.props.refreshArticles();
      return;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_country_in_feeds(feed_ids, country, 0)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("Home => newspapers in feeds of country(first page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            if (showfirst) {
              this.props.setShowFirstArticles(articles);
            } else {
              this.props.setArticles(articles, articles.length);
            }
          }        

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesOfCountryInNextPage = async (feed_ids, country, last_offset) => {

    if (!feed_ids || feed_ids.length === 0) {
      this.props.refreshArticles();
      return;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_country_in_feeds(feed_ids, country, last_offset)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("Home => newspapers in feeds of country(next page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.appendArticles(articles, last_offset + articles.length);
          } else {
            this.props.appendArticles([], last_offset);
          }   

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };
  

  getPodcastSourceIds2show = () => {
    const {
      loggedIn,
      authUser,
      default_feeds,
      followed_feeds,
    } = this.props;

    let feeds = default_feeds;
    if (loggedIn && followed_feeds.length > 0) {
      feeds = followed_feeds.slice();
    }

    let articles_no = 0;
    let source_ids = [];
    for (let feed of feeds) {
      if (loggedIn && authUser.feed_sources_unfollowed.length > 0) {
        let feed_sources = feed.feed_sources
          .filter(
            (feed_source) =>
              feed_source.approved && 
              feed_source.source.branch === ARTICLE_BRANCH_PODCAST &&
              is_source_alive(feed_source.source) &&
              authUser.feed_sources_unfollowed.findIndex(
                (unfollowed) =>
                  unfollowed.feed_id === feed.id &&
                  unfollowed.source_id === feed_source.source_id
              ) === -1
          );
        
        for (let feed_source of feed_sources) {
          if (source_ids.indexOf(feed_source.source_id) === -1) {
            source_ids.push(feed_source.source_id);
            articles_no += feed_source.source.articles_no;
          }
        }
      } else {
        let feed_sources = feed.feed_sources
          .filter((feed_source) => 
            feed_source.approved && 
            feed_source.source.branch === ARTICLE_BRANCH_PODCAST &&
            is_source_alive(feed_source.source)
          );
        
          for (let feed_source of feed_sources) {
            if (source_ids.indexOf(feed_source.source_id) === -1) {
              source_ids.push(feed_source.source_id);
              articles_no += feed_source.source.articles_no;
            }
          }
      }
    }

    return {
      source_ids,
      articles_no
    };
  };

  handleClickDiscover = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Discover'});
    const location = {
      pathname: ROUTES.DISCOVER,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.initScrollPos();
    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
  };
  handleClickPopular = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Summary'});
    const location = {
      pathname: ROUTES.POPULAR,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.initScrollPos();
    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
  };

  handleClickCreate = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Create Feed Tab Click'});
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    if (isBannedFeedCreation()) {
      ToastError("You've suspended for feed creation.");
      return;
    }

    // check if the created count is less than the limit
    const paidUser = hasPaid();
    if (USE_QUOTA) {
      if (
        !paidUser &&
        authUser.feeds_created.length > FREE_FEED_CREATE_LIMIT
      ) {
        this.setState({
          ...this.state,
          alertDlg: true,
          alertTitle: "Create Limit",
          alertMsg:
            "You've already reached the limit to create. Upgrade your profile to create more feeds.",
        });
        return;
      }
    }
    
    // delete maximum feed creation limit on paid user
    if (USE_QUOTA) {
      if (
        paidUser &&
        authUser.feeds_created.length > PAID_FEED_CREATE_LIMIT
      ) {
        this.setState({
          ...this.state,
          alertDlg: true,
          alertTitle: "Create Limit",
          alertMsg: "You've already reached the limit to create.",
        });
        return;
      }
    }

    const location = {
      pathname: ROUTES.FEED_ADD,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.initScrollPos();
    this.props.selectCategory(null);
    this.props.refreshArticles();
    this.props.refreshThreads();
  };

  handleClickPodcasts = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Podcasts'});
    const location = {
      pathname: ROUTES.PODCASTS,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.initScrollPos();
    this.props.refreshArticles();
    this.props.refreshThreads();
  }

  handleClickCleanAir = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Map'});
    const location = {
      pathname: ROUTES.CLEANAIRMAP,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.initScrollPos();
    this.props.refreshArticles();
    this.props.refreshThreads();
  }

  handleClickVideocasts = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Videocasts'});
    const location = {
      pathname: ROUTES.VIDEOCASTS,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.initScrollPos();
    this.props.refreshArticles();
    this.props.refreshThreads();
  }

  // handleClickBirdtok = () => {
  //   Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Birdtok'}); 
  //   const location = {
  //     pathname: ROUTES.BIRDTOK,
  //     state: { animation: "left" },
  //   };
  //   this.goTo(location);

  //   this.props.initScrollPos();
  //   this.props.refreshArticles();
  //   this.props.refreshThreads();
  // }

  handleClickFeed = (feed) => {
    this.setWaiting(false);
    const properties = {id:feed.id,name:feed.name,slug:feed.slug};
    Mixpanel.track(MIXPANEL_EVENTS.FEED_DETAILS,properties); 
    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // Go to moderation page if the logged user is the moderator of the feed
    if (isFeedModerator(feed)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
    const { get_article_pins_requesting } = this.props;
    if (get_article_pins_requesting === true) {
      store.dispatch({ type: ActionTypes.SET_REQUEST_GET_ARTICLE_PINS_IN_FEEDS, state: false })
    }

    this.props.setFeedBackRoute(ROUTES.HOME);
    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickSource = (source, feed) => {
    const properties={
      sourceId : source.id,
      name : source.name
    }
    Mixpanel.track(MIXPANEL_EVENTS.FEED_SOURCE_DETAILS,properties); 

    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);
  }

  handleClickUpvote = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  }

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  }

  handleClickRepost = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  }

  handleClickBackButton = () => {
    window.scrollTo(0, 0);
    this.props.initScrollPos();
  };

  handleReachLimit = () => {
    if (!hasPaid()) {
      const location = {
        pathname: ROUTES.MEMBERSHIP,
        state: { animation: "bottom" },
      };
      this.goTo(location);
    } else {
      this.setState({
        ...this.state,
        alertDlg: true,
        alertTitle: "Follow Limit",
        alertMsg:
          "You've already reached the limit to follow. Unfollow some feeds to follow another feeds.",
      });
    }
  };

  handleSearchChange = (searchKey) => {};

  handleSearchEnter = (searchKey) => {
    this.props.updateSearchKey(searchKey);
    const location = {
      pathname: ROUTES.SEARCH_RESULT,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleSelectArticle = (article) => { 
    const { authUser } = this.props
    if(article.article) {
      article = article.article
    }
    this.props.selectArticle(article);
    const properties ={
      author : article.author,
      source_id: article.source_id,
      title:article.title,
      url:article.url,
      nid:article.nid
    }
    Mixpanel.track(MIXPANEL_EVENTS.ARTICLE_DETAILS,properties); 
    const { sources } = this.props;
    let article_source = sources.find(
      (source) => source.id === article.source_id
    );
    if (article.source_id === `${authUser.uid}-selfpost`){
      article_source = article.source_id
    }

    const feed = getFeedofSource(article.source_id);
    logger.log("home article's feed :", feed);
    // only for commenting, so don't delete articles from redux
    this.props.selectFeedforComments(feed);

    let path = `/${ROUTES.CATEGORY_PREFIX}/${article_source.category_id}/${ROUTES.SOURCE_PREFIX}/${article_source.slug}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else if (article.source_id === `${authUser.uid}-selfpost`) {
      path = `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`
    } else {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setArticleBackRoute(ROUTES.HOME);
  };

  handleSelectGroupArticle = async (nid) => {
    this.setWaiting(true);
    await getArticle(nid);
    this.setWaiting(false);

    const location = {
      pathname: `/${ROUTES.ARTICLE_NEWS_PREFIX}/${nid}`,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setArticleBackRoute(ROUTES.HOME);
  };

  handleNewFeeds = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS, {type:'Your Feeds'}); 
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    const location = {
      pathname: ROUTES.MY_FEEDS,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  }

  handleComments = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Comments'}); 
    const location = {
      pathname: "/comments",
      state: { animation: "left" },
    };
    this.goTo(location);
  }

  handlePlayHelp = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Help'}); 
    this.setState({
      ...this.state,
      helpDlg: true
    });
  }

  handleCloseHelp = () => {
    this.setState({
      ...this.state,
      helpDlg: false
    });
  }

  handleNotification = () => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Notification'}); 
    const location = {
      pathname: "/notifications",
      state: { animation: "left" },
    };
    this.goTo(location);
  }

  handleProfileMenu = (route) => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS,{type:'Profile'}); 
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleSignOut = async () => {
    await this.props.firebase.doSignOut();
    await this.props.signOut();

    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);

    this.initialize();
  };

  handleChangeCountry = async (country) => {
    const { loggedIn, authUser } = this.props;
    Mixpanel.track(MIXPANEL_EVENTS.COUNTRY_CHANGE, { id: country });

    let showfirst_feed_ids = [];
    if (loggedIn && authUser.feeds_showfirst.length > 0) {
      showfirst_feed_ids = authUser.feeds_showfirst.map(item => item.feed_id);
    }
    let feed_ids = []
    const feeds2show = getFeeds2show();
    feed_ids = feeds2show.map((feed) => feed.id);

    this.props.selectCountry(country);
    this.props.initScrollPos();
    this.props.showTopNavbar(true);

    const { branch } = this.props;

    this.setWaiting(true);

    // get articles of the country in first page
    if (country === ALL) {
      if (loggedIn) {
        if (showfirst_feed_ids.length > 0) {
          await this.getArticlesOfBranchInFirstPage(showfirst_feed_ids, branch, true);
        }
      }
      if (feed_ids.length > 0) {
        await this.getArticlesOfBranchInFirstPage(feed_ids, branch);
      }
    } else {
      if (loggedIn) {
        if (showfirst_feed_ids.length > 0) {
          await this.getArticlesOfCountryInFirstPage(showfirst_feed_ids, country, true);
        }
      }
      if (feed_ids.length > 0) {
        await this.getArticlesOfCountryInFirstPage(feed_ids, country);
      }
    }

    this.setWaiting(false);
  };

  handleChangeBranch = async (branch) => {
    const { loggedIn, authUser } = this.props;
    let showfirst_feed_ids = [];
    if (loggedIn && authUser.feeds_showfirst.length > 0) {
      showfirst_feed_ids = authUser.feeds_showfirst.map(item => item.feed_id);
    }
    let feed_ids = []
    const feeds2show = getFeeds2show();
    feed_ids = feeds2show.map((feed) => feed.id);

    this.props.selectCountry(ALL);
    this.props.selectBranch(branch);
    this.props.initScrollPos();
    this.props.showTopNavbar(true);

    this.setWaiting(true);

    // get articles of the country in first page
    if (branch === BRANCH_ALL) {
      if (loggedIn) {
        if (showfirst_feed_ids.length > 0) {
          await this.getArticlesInFirstPage(showfirst_feed_ids, true);
        }
      }
      if (feed_ids.length > 0) {
        await this.getArticlesInFirstPage(feed_ids);
      }
    } else {
      if (loggedIn) {
        if (showfirst_feed_ids.length > 0) {
          await this.getArticlesOfBranchInFirstPage(showfirst_feed_ids, branch, true);
        }
      }
      if (feed_ids.length > 0) {
        await this.getArticlesOfBranchInFirstPage(feed_ids, branch);
      }
    }

    this.setWaiting(false);
  };

  handleReportArticle = async (article, reportMsg) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);  
  };

  handleEditArticle = (article) => {
    this.setState({
      ...this.state,
      postEditDlg: true,
      article_edit: article
    });
  }

  handleDeleteArticle = async (article) => {
    this.setWaiting(true);
    await deleteArticle(article);
    this.setWaiting(false);
  }

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  }

  // empty funciton
  handleDeleteSavedArticle = (article) => {}
  // empty function

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.goTo(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  showAddPostDlg = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true
      });
      return;
    }

    if (isBannedPosts()) {
      ToastError("You've suspended for post operation.");
      return;
    }

    this.setState({
      ...this.state,
      postDlg: true
    });
  };
  showADlgWeb=()=>{
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true
      });
      return;
    }
    this.setState({
      ...this.state,
      webDlg: true
    });

  }
  closePostDlg = () => {
    this.setState({
      ...this.state,
      postDlg: false,
      webDlg:false
    });
  };

  handleSelectPostFeed = (feed) => {
    this.setState({
      ...this.state,
      postFeed: feed
    });
  }

  handleApprovePosts = async (
    imageUpload,
    videoUpload,
    description,
    postlink
  ) => {
    
    const { authUser } = this.props;
    let { postFeed } = this.state;
    
    if (postFeed && postFeed.id !== authUser.uid) {
      const feeds = JSON.parse(postFeed.ai_moderation) == null ? [] : JSON.parse(postFeed.ai_moderation);
      if (feeds.includes(APPROVING_POSTS)) {
        const params = {
          name: postFeed.name,
          description: postFeed.description,
          userName: authUser.name,
          userBio: authUser.biography,
          userId: authUser.uid,
          userKarmaScore: authUser?.articles_voted?.length,
          postText: description,
          triggersContentFilter: false
        };
        const token = await getAuthToken();
        if (!token) {
          this.handleLogin();
          return;
        }

        await approveAiPosts(params, token)
          .then(
            (result) => {
              if (result.status === 200) {
                if ((result.message.decision.toLowerCase()) === "approve") {
                  this.handleSubmitPost(imageUpload, videoUpload, description, postlink);
                } else {
                  this.setState({
                    ...this.state,
                    postDlg: false
                  });
                  this.setError(`This post is not approved by AI beacuse ${JSON.stringify(result.message.reason)}`);
                }
              } else {
                this.setError("Failed to generate ai approvement.");
                return;
              }
            },
            (reason) => {
              this.setError(reason.msg);
              return;
            }
          )
          .catch((err) => {
            this.setError(JSON.stringify(err));
            return;
          });
      }
      else {
        this.handleSubmitPost(imageUpload, videoUpload, description, postlink);
      }
    } else {
      this.handleSubmitPost(imageUpload, videoUpload, description, postlink);
    }
  }

  handleSubmitPost = async ( imageUpload, videoUpload, description, postlink) => {
    this.setState({
      ...this.state,
      postDlg: false
    });

    this.setWaiting(true);

    let new_image = "";
    if (imageUpload) {
      const resized_image = await resizeImageFile(imageUpload);
      const result = await this.props.firebase.uploadImage(resized_image, "posts");
      if (result.error) {
        this.setError("Failed to upload image.");
        return;
      }
      new_image = result.url;
      const modresult =await moderate_image(new_image);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError("Image not allowed, because it contains adults or racy content.");
        await this.props.firebase.deleteImage(new_image);
        return;
      }
    }

    let video = "";
    if (videoUpload) {
      const result = await this.props.firebase.uploadVideo(videoUpload, "videocasts");
      if (result.error) {
        this.setError("Failed to upload video.");
        return;
      }
      video = result.url;
    }

    let preview = null;
    if (postlink) {
      preview = await get_link_source(postlink);
    }

    const { authUser } = this.props;

    let title = authUser.biography;
    if (!title) {
      title = authUser.name;
    }

    let nid = "";
    const uid = authUser.uid;
    if (postlink) {
      nid = get_hash(uid + postlink);
    } else {
      nid = get_hash(uid + description);
    }

    const gqlservice = new GraphqlService();

    // check if there is already article
    const result = await gqlservice.article_by_nid(nid);
    if (result.status_code === GRAPHQL_SUCCESS && result.data.articles.length > 0) {
      this.setError("This post was already posted");
      return;
    }

    const summary = summarize_text(description, 150);
    const published = new Date().getTime() / 1000;

    // check if this post is profile post
    let userpost = {};
    let { postFeed } = this.state;
    if (postFeed === null) {
      postFeed = {
        id: authUser.uid,
        name: authUser.name,
        image: authUser.image 
      }
    }
    const isProfilepost = postFeed.id === authUser.uid;
    const source_id = isProfilepost ? null : `${postFeed.id}-userpost`;
    userpost = {
      nid: nid,
      source_id: source_id,
      author: authUser.username,
      title: title,
      summary: summary,
      text: description,
      image: new_image,
      media_url: video,
      branch: isProfilepost ? ARTICLE_BRANCH_PROFILEPOST : ARTICLE_BRANCH_USERPOST,
      url: "",
      author_image: authUser.image || "",
      published: Math.floor(published),
      param1: isProfilepost ? 1 : postFeed.private ? 0 : 1,   // approved
      param2: isProfilepost ? 1 : 0,                          // moderator
      param3: -1,
      txt_param1: authUser.uid,                               // user id
      link_preview: preview,
    }

    const token = await getAuthToken();
    if (!token) {
      this.setWaiting(false);
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    await gqlservice
      .insert_userpost(userpost)
      .then(
        (result) => {
          logger.log("insert userpost result :", result);
          const articles = result.data.insert_articles.returning;
            // profile posts can be showed only in profile page
            if (!isProfilepost && articles.length > 0) {
            this.props.addUserPost(articles[0]);
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    if (!isProfilepost && videoUpload) {
      const feed_videocast = {
        feed_id: postFeed.id,
        article_id: userpost.nid,
        posted_by: authUser.uid
      }

      await gqlservice
        .insert_feed_videocast(feed_videocast)
        .then(
          (result) => {
            const feed_videocasts = result.data.insert_feed_videocasts.returning;
            if (feed_videocasts.length > 0) {
              
            }
          },
          (reason) => {
            this.setError(reason.msg);
            return;
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
          return;
        });
    }

    if (!isProfilepost) {
      const curTs = Math.floor(Date.now() / 1000);
      const feed_article = {
        feed_id: postFeed.id,
        article_id: userpost.nid,
        branch: ARTICLE_BRANCH_USERPOST,
        published: curTs,
        crawled_at: curTs
      }

      await gqlservice
        .insert_feed_article(feed_article)
        .then((result) => {
          ToastInfo("Posted successfully");
        }, (reason) => {
          this.setError(reason.msg);
          return;
        })
        .catch((err) => {
          this.setError(JSON.stringify(err));
          return;
        });

      const now = new Date();
      await gqlservice
        .update_source_lastupdated(source_id, now.toISOString())
        .then((result) => {
          return gqlservice.feed_by_id(postFeed.id);
        })
        .then((result) => {
          const feeds = result.data.feeds;
          if (feeds.length > 0) {
            const selected_feed = feeds[0];
            this.props.updateFeed(selected_feed);
          }
        }, (reason) => {
          this.setError(reason.msg);
          return;
        })
        .catch((err) => {
          this.setError(JSON.stringify(err));
          return;
        });

      await updateCache();
    }

    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: postFeed.id,
      action: ACTIVITY_ADD,
      object: `the post ${userpost.nid}`,
      fromto: isProfilepost ? `to the profile` : `to the feed ${postFeed.name}`,
      reason: "",
    };

    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => { this.setError(reason.msg); }
      )
      .catch(
        (err) => { this.setError(JSON.stringify(err)); }
      );

    this.setState({
      ...this.state,
      postFeed: null,
    });

    this.setWaiting(false);
    let userpostToBluesky;
    let userpostToMastodon;
    try {
      const promises = [];
    
      if (authUser.is_mastodon_enabled === true && authUser.mastodon_username) {
        promises.push(postToMastodon(userpost, token, authUser.mastodon_access_token));
      }
    
      if (authUser.is_bluesky_enabled === true) {
        const { authUser } = this.props;
        let params = {
          text: description,
          image: new_image,
          accessJwt:authUser.bluesky_access_token
        };
    
        promises.push(postToBluesky(params, token));
      }
    
      const [userpostToMastodon, userpostToBluesky] = await Promise.all(promises);
    
    } catch (error) {
      console.error('Error posting to Mastodon or Bluesky:', error);
    }
    
  }

  handleOK = () => {
    this.setState({
      ...this.state,
      alertDlg: false,
      alertTitle: "",
      alertMsg: "",
    });
  };

  closePostEditDlg = () => {
    this.setState({
      ...this.state,
      postEditDlg: false,
      article_edit: null
    });
  };

  handleUpdatePost = async (description, postlink) => {
    this.setState({
      ...this.state,
      postEditDlg: false
    });

    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
        return;
    }

    const { article_edit } = this.state;

    this.setWaiting(true);
    await updatePost(description, postlink, article_edit);
    this.setWaiting(false);

    this.setState({
      ...this.state,
      article_edit: null
    });
  }

  renderSplashScreen = (classes, theme_mode) => {
    return (
      <div className={classes.root}>
        <SplashScreen theme_mode={theme_mode} />
      </div>
    );
  };

  getSourceThrottles2show = () => {
    const {
      loggedIn,
      authUser,
      default_feeds,
      followed_feeds,
    } = this.props;

    let feeds = default_feeds;
    if (loggedIn && followed_feeds.length > 0) {
      feeds = followed_feeds.slice();
    }

    let throttles = [];
    for (let feed of feeds) {
      let feed_sources = [];
      if (loggedIn && authUser.feed_sources_unfollowed.length > 0) {
        feed_sources = feed.feed_sources.filter(
          (feed_source) =>
            feed_source.approved &&
            authUser.feed_sources_unfollowed.findIndex(
              (unfollowed) =>
                unfollowed.feed_id === feed.id &&
                unfollowed.source_id === feed_source.source_id
            ) === -1
        );
      } else {
        feed_sources = feed.feed_sources.filter(
          (feed_source) => feed_source.approved
        );
      }

      for (let feed_source of feed_sources) {
        if (
          throttles.findIndex(
            (throttle) => throttle.source_id === feed_source.source_id
          ) === -1
        ) {
          throttles.push({
            source_id: feed_source.source_id,
            throttle: feed_source.source.throttle,
            article_count: 0,
          });
        }
      }
    }

    return throttles;
  };

  removeArticlesByThrottle = (articles) => {
    let new_articles = [];
    if (articles.length === 0) {
      return new_articles;
    }

    let throttles = this.getSourceThrottles2show();
    if (throttles.length === 0) {
      return new_articles;
    }

    for (let article of articles) {
      let throttle = throttles.find(
        (throttle) => throttle.source_id === article.source_id
      );
      if (throttle === undefined) {
        new_articles.push(article);
        continue;
      }

      throttle.article_count++;
      switch (throttle.throttle) {
        case 100:
          new_articles.push(article);
          break;

        case 75:
          if (throttle.article_count % 4 !== 0) {
            new_articles.push(article);
          }
          break;

        case 50:
          if (throttle.article_count % 2 === 0) {
            new_articles.push(article);
          }
          break;

        case 25:
          if (throttle.article_count % 4 === 0) {
            new_articles.push(article);
          }
          break;

        default:
          break;
      }
    }

    return new_articles;
  };
    /**
     * Function for getting user followed ai persons
    */
    getUserFollowedAIPersonas = async (fromDate, toDate) =>{
      const { loggedIn, authUser } = this.props;
      if (!loggedIn) {
        return null;
      }
      let userIds =[]; 

      if(authUser.users_followed.length>0){
        
        userIds = authUser.users_followed.map(user => user.user_id); 
        userIds= _.filter(userIds, function (item) {
            return _.startsWith(item, "bot_");
        }); 

      }else{
        return null;
      }
  		const gqlservice = new GraphqlService();

      let aiComments =[]
      // for(let userId of userIds){
        await gqlservice
        .comments_of_ai_users(userIds, fromDate, toDate)
        .then((result) => {
          
          this.props.setFollowedComments(result?.data?.comments)

          aiComments = aiComments.concat(result?.data?.comments)
          // this.props.setFollowedThreads(result.data.threads)  
          return result?.data?.comments
        })
        .then(
        (result) => { 
          console.log("result",result);
        },
        )
        .catch((err) => {
          
        this.setError(JSON.stringify(err));
        });
        
      // this.props.setFollowedComments(aiComments)
      // }

    }

    getThreadsOfFollwedAIPersonas = async (fromDate, toDate) => {
      const { loggedIn, authUser } = this.props;
      if (!loggedIn) {
        return null;
      }
       let userIds =[]; 
      if(authUser.users_followed.length>0){
        
        userIds = authUser.users_followed.map(user => user.user_id); 
        userIds= _.filter(userIds, function (item) {
            return _.startsWith(item, "bot_");
        }); 

      }else{
        return null;
      }
      
		const gqlservice = new GraphqlService();
      await gqlservice
      .threads_by_aiuser(userIds, fromDate, toDate)
      .then((result) => {
        this.props.setFollowedThreads(result.data.threads)  
      })
      .then(
      (result) => { 
        console.log("result",result);
      },
      )
      .catch((err) => {
      this.setError(JSON.stringify(err));
      });

      }
      
      
  handleClickThread = async(thread) => {  
    const feed = await this.getFeed(thread);
    this.props.selectFeed(feed);
    this.props.selectThread(thread);
    const route = `/${ROUTES.THREAD_PREFIX}/${thread.id}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location); 
  }

  getFeed = async(thread) => {
    const { feeds } = this.props;
    let feed = null;
    if (thread.feed_id) {
      feed = feeds.find(item => item.id === thread.feed_id);
      return feed;
    }
    if (thread.article) {
      feed = getFeedofSource(thread.article.source.id);
      return feed;
    } 
    return feed;
  }
   

  handleClickUpvoteThread = async (thread) => {
    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    this.setWaiting(true);
    await upvoteThread(thread);
    this.setWaiting(false);
  }

  render() {
    const {
      classes,
      loggedIn,
      authUser,
      initialized,
      // branch,
      // country,
      sources,
      showfirst_articles,
      articles,
      theme_mode,
      topNavbar,
      bottomNavbar,
      requesting,
      followedThreads,
      followedComments,
      my_posts
    } = this.props; 
    const {
      notifications,
      hasPodcasts,
      loginDlg,
      helpDlg,
      postDlg,
      webDlg,
      postEditDlg,
      article_edit,
      alertDlg,
      alertTitle,
      alertMsg 
    } = this.state; 

    if (!initialized) {
      return this.renderSplashScreen(classes, theme_mode);
    }

    let feeds2show = getFeeds2show();
    logger.log("feeds2show :", feeds2show);
    feeds2show = feeds2show.filter(feed => feed.category_id !== "deleted") 

    if (feeds2show.length === 0 || sources.length === 0) {
      return this.renderSplashScreen(classes, theme_mode);
    }
    if (loggedIn) {
      // sort feeds by order
      feeds2show = feeds2show.map(feed => {
        const feed_order = authUser.feeds_order.filter(order => order.feed_id === feed.id);
        if (feed_order.length > 0) {
          let new_feed = feed;
          new_feed.order = feed_order[0].order;
          return new_feed;
        } else {
          return feed;
        }
      });
      feeds2show = feeds2show.sort(
        (a, b) => a.order - b.order
      );
    }

    // get pins & movetops to show (should be changed)
    let pins2show = [];
    let movetops2show = [];
    // if (branch === ARTICLE_BRANCH_NEWSPAPER) {
    //   if (country === ALL) {
    //     pins2show = pins.filter((pin) => pin.article.branch === branch);
    //     movetops2show = movetops.filter(
    //       (movetop) => movetop.article.branch === branch
    //     );
    //   } else {
    //     pins2show = pins.filter(
    //       (pin) =>
    //         pin.article.branch === branch && pin.article.country === country
    //     );
    //     movetops2show = movetops.filter(
    //       (movetop) =>
    //         movetop.article.branch === branch &&
    //         movetop.article.country === country
    //     );
    //   }
    // } else {
    //   if (branch === BRANCH_ALL) {
    //     pins2show = pins;
    //     movetops2show = movetops;
    //   } else {
    //     pins2show = pins.filter((pin) => pin.article.branch === branch);
    //     movetops2show = movetops.filter(
    //       (movetop) => movetop.article.branch === branch
    //     );
    //   }
    // }

    const all_articles = showfirst_articles.concat(articles);

    // exclude non-approved user post
    const real_articles = all_articles.filter(article =>
      article.branch !== ARTICLE_BRANCH_USERPOST || (
        article.branch === ARTICLE_BRANCH_USERPOST && article.param1 === 1
      )
    );

    // get articles to show(delete pins & movetops from the articles)
    let articles2show = [];
    const throttled_articles = this.removeArticlesByThrottle(real_articles);
    for (let article of throttled_articles) {
      // if (
      //   pins2show.find((pin) => pin.article.nid === article.nid) !==
      //     undefined ||
      //   movetops2show.find((movetop) => movetop.article.nid === article.nid) !==
      //     undefined
      // ) {
      //   continue;
      // }
      articles2show.push(article);
    }
    const newfollowedThreads = followedThreads.map(comment => {
      comment.isThread = true;
      comment.nid = comment.id;
      const date = new Date(comment.posted_at);
      const timestamp = date.getTime();
      comment.published = timestamp/1000;
      return comment;
    });
    const newFollowedComments = followedComments.map(comment => {
      comment.isComment =true;
      comment.nid =comment.id;
      const date = new Date(comment.created_at);
      const timestamp = date.getTime();
      comment.published = timestamp/1000;
      return comment;
    })
    for (let article of newFollowedComments) { 
      articles2show.push(article);
    }
    for (let article of newfollowedThreads) { 
      articles2show.push(article);
    }
    for (let article of my_posts) { 
      if (article.source_id === `${authUser.uid}-selfpost`) {
        articles2show.push(article);
      }
    }
   articles2show = _.orderBy(articles2show, ['published'],['desc']); 

    // ActionBar  : 32px
    // ListBar    : 144px
    const title_height = window.innerWidth >= 600 ? 64 : 56;
    const appbar_height = topNavbar ? title_height + 192 : title_height;

    // layout variables
    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const isDesktop = width >= MAX_WINDOW_WIDTH;
    const isTablet =
      width >= MIN_TABLET_WIDTH &&
      width < MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;
    let topFeedsWidth = width - MAX_CARD_WIDTH;
    if (topFeedsWidth > MAX_CARD_WIDTH) {
      topFeedsWidth = MAX_CARD_WIDTH;
    }

    const innerWidth =
      width > MAX_WINDOW_WIDTH
        ? MAX_WINDOW_WIDTH
        : width;
    let addbuttonPos = 0;
    if (isDesktop) {
      addbuttonPos = MAX_WINDOW_WIDTH - 96 + (width - MAX_WINDOW_WIDTH) / 2;
    } else if (innerWidth > MAX_CARD_WIDTH) {
      addbuttonPos = (innerWidth + MAX_CARD_WIDTH) / 2 - 96;
    } else {
      addbuttonPos = innerWidth - 96;
    }

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven App`}</title>
            <meta name="description" content={`Raven: news and social media simplified`} />
            <meta property="og:title" content={`Raven App`} />
            <meta property="og:description" content={`Raven: news and social media simplified`} />
            <meta property="og:image" content="logo512.png" />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content="https://www.ravenapp.org" />
            <meta property="og:type" content="website" />
            <meta property="twitter:title" content={`Raven App`} />
            <meta property="twitter:site" content="Raven App" />
            <meta property="twitter:description" content={`Raven: news and social media simplified`} />
            <meta property="twitter:image:src" content="logo512.png" />
            <meta property="twitter:image:alt" content="Raven" />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar} style={{ height: appbar_height }}>
          <MainAppBar
            show={topNavbar}
            feeds={feeds2show}
            hasPodcasts={hasPodcasts}
            notifications={notifications}
            onNewFeeds={this.handleNewFeeds}
            onComments={this.handleComments}
            onHelp={this.handlePlayHelp}
            onNotification={this.handleNotification}
            onProfileMenu={this.handleProfileMenu}
            onSignOut={this.handleSignOut}
            onSearchChange={this.handleSearchChange}
            onSearchEnter={this.handleSearchEnter}
            onClickDiscover={this.handleClickDiscover}
            onClickCreate={this.handleClickCreate}
            onClickPodcasts={this.handleClickPodcasts}
            onClickCleanAir={this.handleClickCleanAir}
            onClickVideocasts={this.handleClickVideocasts}
            // onClickBirdtok={this.handleClickBirdtok}
            onListSelected={this.handleClickFeed}
            onClickSummary={this.showADlgWeb}
            onClickPopular={this.handleClickPopular}
          />
          <FeedsAppBar
            show={!topNavbar}
            image={`/static/images/logo/${theme_mode}/logo.png`}
            title={"Feeds"}
            onClickBackButton={this.handleClickBackButton}
          />
        </div>
        <BottomNavBar
          // show_all_branch
          selected_feeds={feeds2show}
          show={bottomNavbar}
          onChangeCountry={this.handleChangeCountry}
          onChangeBranch={this.handleChangeBranch}
        />

        {isDesktop && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <ArticleMasonry
                // width={1224}
                width={width}
                articles={articles2show}
                pins={pins2show}
                movetops={movetops2show}
                onNeedMore={this.handleNeedMore}
                onSelectArticle={this.handleSelectArticle}
                onSelectGroupArticle={this.handleSelectGroupArticle}
                onNeedLogin={this.handleLogin}
                onReport={this.handleReportArticle}
                onEdit={this.handleEditArticle}
                onDelete={this.handleDeleteArticle}
                onSave={this.handleSaveArticle}
                onDeleteSaved={this.handleDeleteSavedArticle}
                onClickSource={this.handleClickSource}
                onClickFeed={this.handleClickFeed}
                onClickUpvote={this.handleClickUpvote}
                onClickComment={this.handleClickComment}
                onClickRepost={this.handleClickRepost}
                onClickArticle={this.handleSelectArticle}
                onClickThread={this.handleClickThread}
                onClickUpvoteThread={this.handleClickUpvoteThread}
              />
            </Grid>
          </Grid>
        )}
        {isTablet && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <ArticleMasonry
                width={width >= 1632 ? 1632 : MIN_TABLET_WIDTH}
                articles={articles2show}
                pins={pins2show}
                movetops={movetops2show}
                onNeedMore={this.handleNeedMore}
                onSelectArticle={this.handleSelectArticle}
                onSelectGroupArticle={this.handleSelectGroupArticle}
                onNeedLogin={this.handleLogin}
                onReport={this.handleReportArticle}
                onEdit={this.handleEditArticle}
                onDelete={this.handleDeleteArticle}
                onSave={this.handleSaveArticle}
                onDeleteSaved={this.handleDeleteSavedArticle}
                onClickSource={this.handleClickSource}
                onClickFeed={this.handleClickFeed}
                onClickUpvote={this.handleClickUpvote}
                onClickComment={this.handleClickComment}
                onClickRepost={this.handleClickRepost}
                onClickArticle={this.handleSelectArticle} 
                onClickThread={this.handleClickThread}
                onClickUpvoteThread={this.handleClickUpvoteThread}
              />
            </Grid>
          </Grid>
        )}
        {isMobile && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <ArticleList
                articles={articles2show}
                pins={pins2show}
                movetops={movetops2show}
                onNeedMore={this.handleNeedMore}
                onSelectArticle={this.handleSelectArticle}
                onSelectGroupArticle={this.handleSelectGroupArticle}
                onNeedLogin={this.handleLogin}
                onReport={this.handleReportArticle}
                onEdit={this.handleEditArticle}
                onDelete={this.handleDeleteArticle}
                onSave={this.handleSaveArticle}
                onDeleteSaved={this.handleDeleteSavedArticle}
                onClickSource={this.handleClickSource}
                onClickFeed={this.handleClickFeed}
                onClickUpvote={this.handleClickUpvote}
                onClickComment={this.handleClickComment}
                onClickRepost={this.handleClickRepost}
                onClickArticle={this.handleSelectArticle}
                onClickThread={this.handleClickThread}
                onClickUpvoteThread={this.handleClickUpvoteThread}
              />
            </Grid>
          </Grid>
        )}

        <div onClick={this.showAddPostDlg}>
          <img
            className={classes.addbutton}
            style={{ left: addbuttonPos, bottom: 80 }}
            alt={"addpost"}
            src={`/static/images/icons/${theme_mode}/add.png`}
          />
        </div>
        <DlgPost 
          open={postDlg}
          tohome={true}
          onSelectFeed={this.handleSelectPostFeed}
          onSubmit={this.handleApprovePosts}
          onClose={this.closePostDlg}
        />
        <div onClick={this.showADlgWeb}>
          <img
          className={classes.addbutton}
          style={{backgroundColor:"#10a37f", marginLeft:-65,left: addbuttonPos, bottom: 80 ,borderRadius:30,padding:6}}
          alt={"addlink"}
           src={`/static/images/summary.png`}

          />
          </div>
        
        <DlgWeblink
          open={webDlg}
          onClose={this.closePostDlg}
        />

        {postEditDlg &&
          <DlgPostEdit
            open={postDlg}
            theme={theme_mode}
            article={article_edit}
            onSubmit={this.handleUpdatePost}
            onClose={this.closePostEditDlg}
          />
        }
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgVideo
          open={helpDlg}
          video={"/static/videos/Raven_Mainpage.mp4"}
          onClose={this.handleCloseHelp}
        />
        <DlgAlert
          open={alertDlg}
          title={alertTitle}
          description={alertMsg}
          theme_mode={theme_mode}
          onOK={this.handleOK}
        />
        <ToastContainer />
        <WaitingSpinner open={requesting} />
      </div>
    );
    // }
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  initialized: state.dataState.initialized,
  newssites: state.dataState.newssites,
  categories: state.dataState.categories,
  feeds: state.dataState.feeds,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  default_feeds: state.dataState.default_feeds,
  followed_feeds: state.dataState.followed_feeds,
  tags: state.dataState.tags,
  my_posts: state.dataState.my_posts,
  showfirst_articles: state.dataState.showfirst_articles,
  articles: state.dataState.articles,
  pins: state.dataState.pins,
  movetops: state.dataState.movetops,
  last_offset: state.dataState.last_offset,
  podcasts: state.dataState.podcasts,
  podcast_last_offset: state.dataState.podcast_last_offset,
  country: state.dataState.country,
  branch: state.dataState.branch,
  theme_mode: state.uiState.theme_mode,
  topNavbar: state.uiState.topNavbar,
  bottomNavbar: state.uiState.bottomNavbar,
  requesting: state.uiState.requesting,
  followedThreads:state.dataState.followedThreads,
  followedComments:state.dataState.followedComments,
  get_article_pins_requesting : state.uiState.get_article_pins_requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Home);
