import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { BasicAppBar, SearchInput } from "components";
import { withStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Checkbox,
} from "@material-ui/core";
import { withFirebase } from "services";
import { withAuthentication } from "session";
import { GraphqlService } from "services";
import { getAuthToken, updateCache } from "dataapis";
import { ToastError } from "utility/toast";
import { MAX_ARTICLE_WIDTH } from "constants/types";
import * as ROUTES from "constants/routes";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  pointer: {
    cursor: "pointer",
  },
  noData: {
    textAlign: "center ",
  },
  searchbox: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    borderRadius: "12px",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-0.05px",
    backgroundColor: theme.palette.background.main,
  },
  addButton: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    margin: theme.spacing(2),
    borderRadius: 20,
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  title: {
    flexGrow: 1,
    position: "relative",
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
  actnbtn: {
    color: theme.palette.primary.contrastText,
  },
  list: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
});

class PersonaLibrary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: "",
      searchOpen: true,
      aiPersonas: [],
      checked: false,
      selectedAiPersons: [],
      searchedAiPersons: [],
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchCancel = this.handleSearchCancel.bind(this);
    // this.handleSubmitAIPerson = this.handleSubmitAIPerson.bind(this)
  }
  setError = message => {
    ToastError(message);
    this.props.requestDataFinished();
  };
  setWaiting = waiting => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  
  componentDidMount = async () => {
    const feed_id = this.props.selected_feed.id;
    const gqlservice = new GraphqlService();
    try {
      const result = await gqlservice.get_ai_persons(feed_id);
      let aiPersonaFromSelectedFeeds = this.props.selected_feed.ai_persons;
      let filteredAiPersons = result.data.ai_persons
        .filter((person) => {
          return !aiPersonaFromSelectedFeeds.some((aiPerson) => {
            return aiPerson.user_id === person.user_id;
          });
        })
        .map((person) => {
          return { ...person, user: { ...person.user, checked: false } };
        });

      this.setState({
        aiPersonas: filteredAiPersons,
        searchedAiPersons: filteredAiPersons,
      });
    } catch (error) {
      this.setError(JSON.stringify(error));
    }
  };

  handleSelectAiPerson = (person) => {
    const { selectedAiPersons, searchedAiPersons } = this.state;

    const personIndex = searchedAiPersons.findIndex((p) => p.id === person.id);
    if (personIndex === -1) {
      return;
    }

    const isPersonAlreadySelected = selectedAiPersons.some(
      (p) => p.id === person.id
    );
    searchedAiPersons[personIndex].user.checked = !isPersonAlreadySelected;

    this.setState({
      selectedAiPersons: isPersonAlreadySelected
        ? selectedAiPersons.filter((p) => p.id !== person.id)
        : [...selectedAiPersons, person],
      searchedAiPersons: [...searchedAiPersons],
    });
  };

  handleNavBack = () => {
    this.props.history.goBack();
  };

  handleSearchChange = (event) => {
    const query = event.target.value;
    let filteredPersons = this.state.aiPersonas;
    if (query) {
      filteredPersons = this.state.aiPersonas.filter((person) => {
        return person.user?.name.toLowerCase().includes(query.toLowerCase());
      });
    }

    this.setState({
      ...this.state,
      searchKey: event.target.value,
    });
    this.setState((prevState) => ({
      searchedAiPersons: [...filteredPersons],
    }));
  };

  handleSearchCancel = () => {
    this.setState({
      ...this.state,
      searchKey: "",
      searchOpen: true,
    });
  };

  openProfile = (author) => {
    let route = `/${ROUTES.USER_PREFIX}/${author.username}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  onClickCategoryIndicator = (id) => {
    const route = `/${ROUTES.CATEGORY_PREFIX}/${id}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.selectCategory(null);
    this.props.history.push(location);
  };
  
  handleClickFeed = (feed) => {
    const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  /**
   * Function for inserting selected ai persons to ai_person table
   */
  handleSubmitAIPerson = async () => {
    const { selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true, false);
    
    const { selectedAiPersons } = this.state;
    if (selectedAiPersons.length > 0) {
      this.setWaiting(true);

      for (let person of selectedAiPersons) {
        const variables = {
          user_id: person.user_id,
          feed_id: selected_feed.id,
          seed_phrase: person.seed_phrase,
          is_owner: false,
        };

        await gqlservice.create_ai_person(variables).then((result) => {
          console.log("result of adding associate user : ", result);
          person.is_owner = false;
          person.id = result.data.insert_ai_persons.returning[0].id;
          this.props.selected_feed.ai_persons = [
            ...this.props.selected_feed.ai_persons,
            person,
          ];
          this.props.updateFeed(this.props.selected_feed);

          const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/settings`;
          const location = {
            pathname: route,
            state: { animation: "left" },
          };
          this.props.history.push(location);
        });
      };

      await updateCache();

      this.setWaiting(false);
    }
  };


  render() {
    const {
      classes,
    } = this.props;
    const {
      searchKey,
      searchOpen,
      selectedAiPersons,
      searchedAiPersons,
    } = this.state;
    console.log("searched ai persons", searchedAiPersons);
    console.log("selected ai persons", selectedAiPersons);
    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Persona Library"}
            width={MAX_ARTICLE_WIDTH}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.searchbox}>
          <SearchInput
            open={searchOpen}
            searchKey={searchKey}
            onChange={this.handleSearchChange}
            onCancel={this.handleSearchCancel}
            placeholder={"Search AI Personas"}
          />
        </div>
        <List>
          {searchedAiPersons.length > 0 ? (
            searchedAiPersons.map((person, index) => (
              <ListItem key={person.id}>
                <ListItemAvatar>
                  {/* <Avatar alt={person.user.name} src={person.user.image} /> */}
                  <div onClick={() => this.openProfile(person?.user)}>
                    {" "}
                    <Avatar
                      className={classes.pointer}
                      alt={person.user?.name}
                      src={person.user?.image}
                    />
                  </div>
                </ListItemAvatar>
                {/* <ListItemText primary={person.user.name} secondary={person.user.biography} /> */}
                <div>
                  <ListItemText
                    className={classes.pointer}
                    onClick={() => this.openProfile(person?.user)}
                    primary={person.user?.name}
                    secondary={person.user?.biography}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      className={classes.pointer}
                      onClick={() =>
                        this.onClickCategoryIndicator(person.feed.category.id)
                      }
                      style={{ marginRight: "5px" }}
                    >
                      {person.feed.category.emoji}
                    </span>
                    <ListItemText
                      className={classes.pointer}
                      onClick={() => this.handleClickFeed(person.feed.id)}
                      primary={
                        <span
                          style={{
                            fontStyle: "italic",
                            fontWeight: "normal",
                            marginTop: "-4px",
                          }}
                        >
                          {person.feed_id}
                        </span>
                      }
                    />
                  </div>
                </div>

                {/* <ListItemSecondaryAction>
                <IconButton onClick={() => {
                  const updatedAiPersonas = [...aiPersonas];
                  updatedAiPersonas[index].checked = !updatedAiPersonas[index].checked;
                  this.setState({ aiPersonas: updatedAiPersonas });
                }}><CheckBoxIcon className={classes.actnbtn} variant="contained" color={person.checked ? 'primary' : 'inherit'} /></IconButton>
              </ListItemSecondaryAction> */}
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    icon={
                      <CheckBoxOutlineBlankIcon className={classes.actnbtn} />
                    }
                    checkedIcon={<CheckBoxIcon className={classes.actnbtn} />}
                    onClick={() => this.handleSelectAiPerson(person)}
                    checked={person.user?.checked}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <Typography className={classes.noData}>
              {"No AI Personas found"}
            </Typography>
          )}
        </List>
        <Grid container justify="center">
          <Button
            variant="contained"
            className={classes.addButton}
            disabled={selectedAiPersons.length === 0}
            onClick={this.handleSubmitAIPerson}
          >
            ADD PERSONAS
          </Button>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PersonaLibrary);
