import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '1000ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

const FacebookCircularProgress = (props) => {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={3}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={3}
        {...props}
      />
    </div>
  );
}

FacebookCircularProgress.propTypes = {
};

// const useStyles = makeStyles({
//   root: {
//     flexGrow: 1,
//   },
// });

// export default function CustomizedProgressBars() {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <FacebookCircularProgress />
//     </div>
//   );
// }

export default FacebookCircularProgress;