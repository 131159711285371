export const QUERY_VIDEOCASTS_BY_FEED = `
query videocast_by_feed(
  $feed_id: String!
) {
  feed_videocasts(
    where: { feed_id: {_eq: $feed_id} },
    order_by: { posted_at: desc }
  ) {
    id
    feed_id
    posted_by
    posted_at
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}`;

export const QUERY_VIDEOCASTS_ALL_IN_FEEDS = `
query videocast_all_by_feed(
  $feed_ids: [String!]
) {
  feed_videocasts(
    where: { 
      feed_id: {_in: $feed_ids}
    },
    order_by: { posted_at: desc_nulls_last }
  ) {
    id
    feed_id
    posted_by
    posted_at
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}`;

export const QUERY_VIDEOCASTS_IN_FEEDS = `
query videocast_by_feed(
  $feed_ids: [String!],
  $pagesize: Int!,
  $offset: Int!
) {
  feed_videocasts(
    where: { 
      feed_id: {_in: $feed_ids}
    },
    order_by: { posted_at: desc_nulls_last },
    limit: $pagesize, 
    offset: $offset
  ) {
    id
    feed_id
    posted_by
    posted_at
    article {
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}`;

export const MUTATION_INSERT_FEED_VIDEOCAST = `
mutation insert_feed_videocast(
    $feed_id: String!,
    $article_id: String!,
    $posted_by: String!
) {
  insert_feed_videocasts(
    objects: {
      feed_id: $feed_id, 
      article_id: $article_id
      posted_by: $posted_by
    }
  ) {
    affected_rows
    returning {
      id
      feed_id
      posted_by
      posted_at
      article {
        nid
        source_id
        title
        author
        summary
        image
        text
        html
        summarized_text
        tr_title
        tr_summary
        tr_text
        translated
        branch
        country
        crawled_at
        media_url
        url
        author_image
        published
        data
        extra_data
        param1
        param2
        param3
        param4
        param5
        txt_param1
        txt_param2
        image_thumb
        author_image_thumb
        link_preview
        discussion_twitter
        discussion_reddit
        upvotes
        threads {
          id
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
}`;

export const MUTATION_DELETE_FEED_VIDEOCAST = `
mutation delete_feed_videocast(
  $feed_id: String!,
  $article_id: String!
) {
  delete_feed_videocasts(
    where: { 
      feed_id: {_eq: $feed_id},
      article_id: {_eq: $article_id}
    }
  ) {
    affected_rows
  }
}`;
