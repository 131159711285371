import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { withFirebase } from 'services';
import { withAuthentication } from "session";
import SwipeableViews from "react-swipeable-views";
import { ToastContainer } from "react-toastify";
import { 
  DlgAlert, 
  WaitingSpinner 
} from "components";
import { 
  LeaderboardsAppBar,
  LeaderboardTabs,
  Volunteers,
  Regions,
  Best
} from "./components";
import * as ROUTES from "constants/routes";
import { GraphqlService } from "services";
import { 
  MAX_ARTICLE_WIDTH,
  LBOARD_RANKING_COUNT,
  TAB_LBOARD_REGIONS,
  TAB_LBOARD_VOLUNTEERS,
  TAB_LBOARD_BEST,
  BLANK_USER_IMAGE,
} from "constants/types";
import { 
  EMOJI_MARK_1ST_IN_WORLD,
  EMOJI_MARK_1ST_IN_COUNTRY, 
  EMOJI_MARK_1ST_IN_REGION,
  EMOJI_MARK_2ND_IN_REGION,
  EMOJI_MARK_3RD_IN_REGION
} from "constants/emoji";
import { getAuthToken } from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  tabcontainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
    color: theme.palette.text.primary,
    textTransform: "none"
  },
  description: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "14px",
    lineHeight: "14px",
    color: theme.palette.text.secondary,
    textTransform: "none"
  },
});

class CleanAirLeaderboards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alertDlg: false,
      alertTitle: "",
      alertMsg: "",
      volunteerRankings: [],
      regionRankings: [],
      bestRankings: [],
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleSwiped = this.handleSwiped.bind(this);

    this.handleOK = this.handleOK.bind(this);
  }

  setError = message => {
    ToastError(message);
  };

  setWaiting = waiting => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  componentDidMount = async () => {
    this.setWaiting(true);
    await this.getVolunteersRankings();
    this.setWaiting(false);
  }

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.CLEANAIRMAP,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleChangeTab = async (tab_value) => {
    window.scroll(0, 0);
    const { leaderboardtab } = this.props;
    if (tab_value === leaderboardtab) {
      return;
    }

    this.props.selectLeaderboardTab(tab_value);
    this.setWaiting(true);
    if (tab_value === TAB_LBOARD_VOLUNTEERS) {
      await this.getVolunteersRankings();
    } else if (tab_value === TAB_LBOARD_REGIONS) {
      await this.getRegionsRankings();
    } else if (tab_value === TAB_LBOARD_BEST) {
      await this.getBestRankings();
    }
    this.setWaiting(false);
  }

  handleSwiped = (tabIndex) => {
    this.handleChangeTab(tabIndex);
  }

  handleOK = () => {
    this.setState({
      ...this.state,
      alertDlg: false,
      alertTitle: "",
      alertMsg: ""
    });
  }

  getVolunteersRankings = async () => {
    const { loggedIn } = this.props;
    const gqlservice = new GraphqlService();
    if (loggedIn) {
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      gqlservice.set_auth_jwt(token);
    }

    await gqlservice.users_for_leaderboards()
      .then(result => {
        const volunteers = result.data.users;
        if (volunteers.length === 0) {
          return;
        }

        let rankings = 
          volunteers
            .map((volunteer) => {
              const readings = volunteer.readings_aggregate.aggregate.count;
                
              return {
                uid: volunteer.uid,
                name: volunteer.name || volunteer.username || "Anonymous user",
                biography: volunteer.biography,
                image: volunteer.image || BLANK_USER_IMAGE,
                country: volunteer.country,
                readings: readings,
                most: null
              }
            })
            .sort((a, b) => b.readings - a.readings);

        // make emoji for the rankings
        var countries = [];
        // 1st in the world
        rankings[0].most = EMOJI_MARK_1ST_IN_WORLD;
        countries.push(rankings[0].country);
        for (let i = 1; i < rankings.length; i++) {
          if (rankings[i].country && !countries.includes(rankings[i].country)) {
            rankings[i].most = EMOJI_MARK_1ST_IN_COUNTRY;
            countries.push(rankings[i].country);
          }
        }
        logger.log("volunteer rankings :", rankings);

        const rankingCount = rankings.length > LBOARD_RANKING_COUNT ? LBOARD_RANKING_COUNT : rankings.length;
        this.setState({
          ...this.state,
          volunteerRankings: rankings.slice(0, rankingCount)
        });
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  getRegionsRankings = async () => {
    const { loggedIn } = this.props;
    const gqlservice = new GraphqlService();
    if (loggedIn) {
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      gqlservice.set_auth_jwt(token);
    }

    await gqlservice.map_regions_for_leaderboards()
      .then(result => {
        const regions = result.data.region;
        if (regions.length === 0) {
          return;
        }

        let rankings = 
          regions
            .filter(region => region.country && region.region)
            .map((region) => {
              const readings = 
                region.locations.map(location => location.readings_aggregate.aggregate.count)
                  .reduce((a, b) => a + b, 0);
              return {
                place: this.getRegionName(region),
                region: region.region,
                country: region.country,
                readings: readings,
                most: null
              }
            })
            .sort((a, b) => b.readings - a.readings);

        // make emoji for the rankings
        var countries = [];
        // 1st in the world
        rankings[0].most = EMOJI_MARK_1ST_IN_WORLD;
        countries.push(rankings[0].country);
        for (let i = 1; i < rankings.length; i++) {
          if (!countries.includes(rankings[i].country)) {
            rankings[i].most = EMOJI_MARK_1ST_IN_COUNTRY;
            countries.push(rankings[i].country);
          }
        }
        logger.log("region rankings :", rankings);

        const rankingCount = rankings.length > LBOARD_RANKING_COUNT ? LBOARD_RANKING_COUNT : rankings.length;
        this.setState({
          ...this.state,
          regionRankings: rankings.slice(0, rankingCount)
        });
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  getBestRankings = async () => {
    const { loggedIn } = this.props;
    const gqlservice = new GraphqlService();
    if (loggedIn) {
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      gqlservice.set_auth_jwt(token);
    }

    await gqlservice.map_locations_for_leaderboards()
      .then(result => {
        const locations = result.data.locations;
        if (locations.length === 0) {
          return;
        }

        let rankings = 
          locations
            .map((location) => {
              return {
                id: location.id,
                region_id: location.region.id,
                type: location.type,
                name: location.name,
                place: location.region.place || location.region.region,
                country: location.region.country,
                co2: location.last_co2,
                most: null
              }
            })

        // make emoji for the rankings
        var countries = [];
        var regions = [];
        // 1st in the world
        rankings[0].most = EMOJI_MARK_1ST_IN_WORLD;
        countries.push(rankings[0].country);
        regions.push({
          id: rankings[0].region_id,
          most: 1
        });
        for (let i = 1; i < rankings.length; i++) {
          if (!rankings[i].country) {
            continue;
          }
          if (!countries.includes(rankings[i].country)) {
            rankings[i].most = EMOJI_MARK_1ST_IN_COUNTRY;
            countries.push(rankings[i].country);
            regions.push({
              id: rankings[i].region_id,
              most: 1
            });
          } else {
            let curRegion = regions.find(region => region.id === rankings[i].region_id);
            if (!curRegion) {
              rankings[i].most = EMOJI_MARK_1ST_IN_REGION;
              regions.push({
                id: rankings[i].region_id,
                most: 1
              });
            } else {
              if (curRegion.most === 1) {
                rankings[i].most = EMOJI_MARK_2ND_IN_REGION;
                curRegion.most = 2;
                regions = regions.map(region => region.id === curRegion.id ? curRegion : region);
              } else if (curRegion.most === 2) {
                rankings[i].most = EMOJI_MARK_3RD_IN_REGION;
                curRegion.most = 3;
                regions = regions.map(region => region.id === curRegion.id ? curRegion : region);
              }
            }
          }
        }
        logger.log("best rankings :", rankings);  

        const rankingCount = rankings.length > LBOARD_RANKING_COUNT ? LBOARD_RANKING_COUNT : rankings.length;
        this.setState({
          ...this.state,
          bestRankings: rankings.slice(0, rankingCount)
        });
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }
  
  getRegionName = (region) => {
    if (region.place) {
      return region.place;
    } else if (region.district) {
      return region.district;
    } else if (region.locality) {
      return region.locality;
    } else if (region.region) {
      return region.region;
    }
  }

  render() {
    const { 
      classes,
      leaderboardtab, 
      requesting, 
      theme_mode 
    } = this.props;
    const {
      volunteerRankings,
      regionRankings,
      bestRankings,
      alertDlg, 
      alertTitle, 
      alertMsg,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <LeaderboardsAppBar
            onNavBack={this.handleNavBack}
          />
        </div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item>
            <LeaderboardTabs
              theme_mode={theme_mode}
              onChangeTab={this.handleChangeTab}
            />
          </Grid>
        </Grid>
        
        <SwipeableViews index={leaderboardtab} onChangeIndex={this.handleSwiped} enableMouseEvents>
          
          <div className={classes.tabcontainer}>
            <Volunteers 
              volunteers={volunteerRankings}
            />
          </div>

          <div className={classes.tabcontainer}>
            <Regions 
              regions={regionRankings}
            />
          </div>

          <div className={classes.tabcontainer}>
            <Best
              theme_mode={theme_mode}
              best={bestRankings}
            />
          </div>

        </SwipeableViews>
        
        <DlgAlert
          open={alertDlg}
          title={alertTitle}
          description={alertMsg}
          theme_mode={theme_mode}
          onOK={this.handleOK}
        />
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  leaderboardtab: state.mapState.leaderboardtab,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CleanAirLeaderboards);
