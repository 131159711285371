import gql from 'graphql-tag';

export const QUERY_BASE_DATA = `
query base_data {
  newssite(order_by: {id: asc}) {
    id
    name
    country
    lang
    engine
  }
  socialtype(order_by: {branch: asc}) {
    id
    name
    hint
    branch
  }
  categories(
    where: {id: {_neq: "deleted"}},
    order_by: {name: asc}
  ) {
    id
    name
    description
    image
    coverImage
    emoji
    order
    created_at
    created_by
    approved
    notifications
    updated_at
    category_moderators {
      id
      category_id
      approved
      owner
      created
    }
    category_tags {
      id
      category_id
      tag_id
      tag {
        id
        name
      }
    }
  }
  feeds(
    where: {
      category_id: {_neq: "deleted"},
      is_default: {_eq: true}
    }
  ) {
    id
    category_id
    name
    description
    slug
    image
    thumbnail
    tags
    followers
    created_at
    created_by
    is_default
    approved
    last_viewlog
    ai_moderation
    private
    op_comments
    op_posts
    op_members
    op_payment
    op_anonymity
    comment_conf
    ai_comment_conf
    ai_summary_conf
    tg_wallet
    tg_address
    tg_amount
    token_address
    token_amount
    channel_instagram
    channel_tiktok
    channel_twitter
    channel_youtube
    article_count
    notifications
    notif_date
    updated_at
    feed_sources {
      id
      source_id
      keyword_filter
      approved
      created_at
      create_user {
        uid
        username
      }
      source {
        id
        category_id
        name
        description
        slug
        image
        thumbnail
        branch
        weblink
        translate
        translateLang
        translateAPI
        followers
        upvotes
        throttle
        disableFullText
        articles_no
        created_at
        created_by
        approved
        private
        last_updated
        is_bias_check
        is_published
        total_bias_score
        total_biased_articles_no
        updated_at
        transcription_enabled
        socialtags {
          tag
          type
        }
      }
    }
    feed_reports {
      id
      report
      approved
      reported_at
    }
    feed_moderators {
      id
      feed_id
      user_id
      created
      owner
      approved
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_followers {
      id
      user_id
      feed_id
      created
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_videocasts_aggregate {
      aggregate {
        count
      }
    }
    ai_persons{
      id
      user_id
      seed_phrase
      user{
        name
        biography
        image
        }
    }
  }
  tags {
    id
    name
    tag_categories {
      category_id
    }
    tag_feeds {
      feed_id
    }
    tag_users {
      user_id
    }
  }
}`;


export const QUERY_LISTS = `
query {
  lists(order_by: {order: asc}) {
    id
    name
    order
    created_at
    created_by
    list_feeds {
      id
      feed {
        id
        category_id
        name
        description
        slug
        image
        thumbnail
        tags
        followers
        created_at
        created_by
        is_default
        approved
        last_viewlog
        private
        op_comments
        op_posts
        op_members
        op_payment
        op_anonymity
        comment_conf
        ai_moderation
        ai_comment_conf
        ai_summary_conf
        tg_wallet
        tg_address
        tg_amount
        token_address
        token_amount
        channel_instagram
        channel_tiktok
        channel_twitter
        channel_youtube
        article_count
        notifications
        notif_date
        updated_at
        feed_sources {
          id
          source_id
          keyword_filter
          approved
          created_at
          create_user {
            uid
            username
          }
          source {
            id
            category_id
            name
            description
            slug
            image
            thumbnail
            branch
            weblink
            translate
            translateLang
            translateAPI
            followers
            upvotes
            throttle
            disableFullText
            articles_no
            created_at
            created_by
            approved
            private
            last_updated
            is_bias_check
            total_bias_score
            total_biased_articles_no
            updated_at
            transcription_enabled
            socialtags {
              tag
              type
            }
          }
        }
        feed_reports {
          id
          report
          approved
          reported_at
        }
        feed_moderators {
          id
          feed_id
          user_id
          created
          owner
          approved
          user {
            uid
            name
            username
            biography
            image
            msgToken
          }
        }
        feed_followers {
          id
          user_id
          feed_id
          created
          user {
            uid
            name
            username
            biography
            image
            msgToken
          }
        }
        feed_videocasts_aggregate {
          aggregate {
            count
          }
        }
        ai_persons{
          id
          user_id
          seed_phrase
          user{
            name
            biography
            image
            }
        }
      }
    }
    list_sources {
      id
      source {
        id
        category_id
        name
        description
        slug
        image
        thumbnail
        branch
        weblink
        translate
        translateLang
        translateAPI
        followers
        upvotes
        throttle
        disableFullText
        articles_no
        created_at
        created_by
        approved
        private
        last_updated
        updated_at
        transcription_enabled
        is_bias_check
        total_bias_score
        total_biased_articles_no
        socialtags {
          tag
          type
        }
      }
    }
  }
}`;


export const SUBSCRIPTION_CATEGORIES = gql`
subscription CategorySubscription {
  categories(
    where: {id: {_neq: "deleted"}},
    order_by: {name: asc}
  ) {
    id
    name
    description
    image
    coverImage
    emoji
    order
    created_at
    created_by
    approved
    notifications
    updated_at
    category_tags {
      id
      category_id
      tag_id
    }
  }
}
`;

export const SUBSCRIPTION_CATEGORIES_USER = gql`
subscription CategorySubscription {
  categories(
    where: {id: {_neq: "deleted"}},
    order_by: {name: asc}
  ) {
    id
    name
    description
    image
    coverImage
    emoji
    order
    created_at
    created_by
    approved
    notifications
    last_viewlog
    updated_at
    category_moderators {
      id
      category_id
      approved
      owner
    }
    category_tags {
      id
      category_id
      tag_id
    }
  }
}
`;


export const SUBSCRIPTION_FEEDS = gql`
subscription FeedsSubscription {
  feeds (
    where: {category_id: {_neq: "deleted"}}
  ){
    id
    category_id
    name
    description
    slug
    image
    tags
    followers
    created_at
    created_by
    approved
    is_default
    last_viewlog
    private
    op_comments
    op_posts
    op_members
    op_payment
    op_anonymity
    comment_conf
    ai_comment_conf
    ai_summary_conf
    ai_moderation
    tg_wallet
    tg_address
    tg_amount
    token_address
    token_amount
    channel_instagram
    channel_tiktok
    channel_twitter
    channel_youtube
    article_count
    notifications
    notif_date
    updated_at
    feed_sources {
      id
      source_id
      keyword_filter
      approved
      created_at
      create_user {
        uid
        username
      }
      source {
        id
        branch
        throttle
        last_updated
        has_articles
        articles_no
      }
    }
    feed_reports {
      id
      report
      approved
      reported_at
    }
    feed_moderators {
      id
      feed_id
      user_id
      created
      owner
      approved
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_followers {
      id
      user_id
      feed_id
      created
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_videocasts_aggregate {
      aggregate {
        count
      }
    }
    ai_persons{
      id
      user_id
      seed_phrase
      user{
        name
        biography
        image
        }
    }
  }
}
`;

export const SUBSCRIPTION_FEEDS_USER = gql`
subscription FeedsSubscription {
  feeds (
    where: {category_id: {_neq: "deleted"}}
  ){
    id
    category_id
    name
    description
    slug
    image
    tags
    followers
    created_at
    created_by
    approved
    comment_conf
    last_viewlog
    private
    op_comments
    op_posts
    op_members
    op_payment
    op_anonymity
    comment_conf
    ai_comment_conf
    ai_summary_conf
    ai_moderation
    tg_wallet
    tg_address
    tg_amount
    token_address
    token_amount
    channel_instagram
    channel_tiktok
    channel_twitter
    channel_youtube
    article_count
    notifications
    notif_date
    updated_at
    feed_sources {
      id
      source_id
      keyword_filter
      approved
      created_at
      create_user {
        uid
        username
      }
      source {
        id
        branch
        throttle
        last_updated
        has_articles
        articles_no
      }
    }
    feed_reports {
      id
      report
      approved
      reported_at
    }
    feed_moderators {
      id
      feed_id
      user_id
      created
      owner
      approved
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_followers {
      id
      user_id
      feed_id
      created
      user {
        uid
        name
        username
        biography
        image
        msgToken
      }
    }
    feed_videocasts_aggregate {
      aggregate {
        count
      }
    }
    ai_persons{
      id
      user_id
      seed_phrase
      user{
        name
        biography
        image
      }
    }
  }
}`;
