import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ShareIcon from "@material-ui/icons/Share";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { DlgShare } from "components";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Fade,
} from "@material-ui/core";
import {  Mixpanel } from "services";
import * as MIXPANEL_EVENTS from "constants/mixpanel";
import { MAX_WINDOW_WIDTH } from "constants/types";
const styles = (theme) => ({
  root: {
    position: "fixed",
    // width: theme.breakpoints.values.lg,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    zIndex: 1200,
  },
  appbar: {
    position: "absolute",
  },
  toolbar: {
    // borderBottom: `2px solid ${theme.palette.text.secondary}`
  },
  title: {
    flexGrow: 1,
    position: "relative",
    marginTop: 0,
  },
  toolbox: {
    display: "flex",
  },
  navbefore: {
    color: theme.palette.text.primary,
  },
  share: {
    backgroundColor: "#7289DA",
    color: theme.palette.info.contrastText,
    borderRadius: 16,
    width: 32,
    height: 32,
    padding: 4,
    "&:hover": {
      backgroundColor: "#7289DA",
      color: theme.palette.info.contrastText,
    }
  },
  shareicon: {
    width: 24,
    height: 24,
  },
});

class MainAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showShareDlg: false,
    };

    this.handleClickShare = this.handleClickShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);
  }

  handleClickShare = () => {  
    const properties = {type:"Feed",info:this.props.share_info}; 
    Mixpanel.track(MIXPANEL_EVENTS.SHARE,properties); 
    this.setState({
      showShareDlg: true,
    });
  }

  handleCloseShare = () => {
    this.setState({
      showShareDlg: false,
    });
  }

  render() {
    const {
      classes,
      show,
      title,
      share_info,
      onNavBack,
    } = this.props;
    const { showShareDlg } = this.state;

    return (
      <div className={classes.root}>
        <Fade in={show} timeout={{ enter: 300, exit: 300 }}>
          <AppBar className={classes.appbar}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                className={classes.navbefore}
                aria-label="nav before"
                onClick={onNavBack}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>
                {title}
              </Typography>
              <div className={classes.toolbox}>
                <IconButton
                  className={classes.share}
                  onClick={this.handleClickShare}
                >
                  <ShareIcon className={classes.shareicon} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </Fade>
        <DlgShare
          open={showShareDlg}
          shareInfo={share_info}
          onClose={this.handleCloseShare}
        />
      </div>
    );
  }
}

MainAppBar.propTypes = {
  classes: PropTypes.object,
  show: PropTypes.bool,
  title: PropTypes.string,
  share_info: PropTypes.object,
  onNavBack: PropTypes.func
};

export default withStyles(styles)(MainAppBar);
