import gql from 'graphql-tag';

export const QUERY_MAPLOCATION_COMMENTS = `
query maplocation_comments(
  $location_id: Int!
) {
  location_comments(
    where: {
      location_id: {_eq: $location_id}}
  ) {
    location_id
    posted_at
    approved
    approved_at
    approved_by
    poster {
      uid
      name
      username
      biography
      image
    }
    comment {
      id
      article_id
      reading_id
      thread_id
      parent_id
      created_at
      modified_at
      text
      author {
        uid
        image
        username
        biography
      }
      recommends
      approved
      children_aggregate ( 
        where: {
          approved: {_eq: $approved}
        },
        order_by: {created_at: desc} 
      ) {
        aggregate { count }
      }
      children (
        where: {
          approved: {_eq: $approved}
        },
        order_by: {created_at: desc},
        limit: 1,
        offset: 0
      ) {
        id
        created_at
        recommends
        approved
        author {
          uid
          image
          username
          biography
        }
      }
    }
  }
}`;

export const QUERY_MAPLOCATION_COMMENT = `
query maplocation_comment(
  $location_id: Int!,
  $comment_id: Int!
) {
  location_comments(
    where: {
      location_id: {_eq: $location_id},
      comment_id: {_eq: $comment_id}
    }
  ) {
    location_id
    posted_at
    approved
    approved_at
    approved_by
    poster {
      uid
      name
      username
      biography
      image
    }
    comment {
      id
      article_id
      reading_id
      thread_id
      parent_id
      created_at
      modified_at
      text
      author {
        uid
        image
        username
        biography
      }
      recommends
      approved
      children_aggregate ( 
        where: {
          approved: {_eq: $approved}
        },
        order_by: {created_at: desc} 
      ) {
        aggregate { count }
      }
      children (
        where: {
          approved: {_eq: $approved}
        },
        order_by: {created_at: desc},
        limit: 1,
        offset: 0
      ) {
        id
        created_at
        recommends
        approved
        author {
          uid
          image
          username
          biography
        }
      }
    }
  }
}`;

export const MUTATION_INSERT_MAPLOCATION_COMMENT = `
mutation insert_maplocation_comment(
    $location_id: Int!,
    $comment_id: Int!,
    $posted_by: String!,
    $approved: Boolean,
    $approved_by: String,
    $approved_at: timestamptz,
) {
  insert_location_comments(
    objects: {
      location_id: $location_id, 
      comment_id: $comment_id,
      posted_by: $posted_by,
      approved: $approved,
      approved_by: $approved_by,
      approved_at: $approved_at
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_DELETE_MAPLOCATION_COMMENT = `
mutation delete_maplocation_comment(
  $location_id: Int!,
  $comment_id: Int!
) {
  delete_location_comments(
    where: {
      location_id: {_eq: $location_id}
      comment_id: {_eq: $comment_id}
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_MAPLOCATION_COMMENT = `
mutation update_maplocation_comment(
  $location_id: Int!,
  $comment_id: Int!,
  $approved: Boolean!,
  $approved_by: String!,
  $approved_at: timestamptz!
) {
  update_location_comments(
    where: {
      location_id: {_eq: $location_id}
      comment_id: {_eq: $comment_id}
    }, 
    _set: {
      approved: $approved,
      approved_by: $approved_by,
      approved_at: $approved_at
    }
  ) {
    affected_rows
  }
}`;

export const SUBSCRIPTION_MAPLOCATION_COMMENTS = gql`
subscription maplocation_comments(
  $location_id: Int!
) {
  location_comments(
    where: {
      location_id: {_eq: $location_id}}
  ) {
    location_id
    comment_id
    posted_at
    approved
    approved_at
    approved_by
    poster {
      uid
      name
      username
      biography
      image
    }
    comment {
      id
      article_id
      reading_id
      thread_id
      parent_id
      created_at
      modified_at
      text
      author {
        uid
        image
        username
        biography
      }
      recommends
      approved
      children_aggregate ( 
        where: {
          approved: {_eq: $approved}
        },
        order_by: {created_at: desc} 
      ) {
        aggregate { count }
      }
      children (
        where: {
          approved: {_eq: $approved}
        },
        order_by: {created_at: desc},
        limit: 1,
        offset: 0
      ) {
        id
        created_at
        recommends
        approved
        author {
          uid
          image
          username
          biography
        }
      }
    }
  }
}`;