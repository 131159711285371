import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { compose } from "recompose";
import { withRouter } from "react-router"; 
import { withStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/core";
import {
  DlgSharePost,
  DlgReport,
  DlgLoginConfirm,
  PopMenuPost,
  SearchResultCard,
} from "components";
import {
  ARTICLE_BRANCH_NEWSPAPER,
  ARTICLE_BRANCH_USERPOST,
  get_branch_name,
} from "constants/branches";
import { 
  ACTIVITY_TYPE_FEED, 
  ACTIVITY_DELETE 
} from "constants/activity";
import {
  FLAGGED_COMMENTS_POSTS
} from "constants/types";
import * as ROUTES from "constants/routes";
import { 
  getAuthToken,
  isBannedPosts,
  updateFeedNotifications
} from "dataapis";
import { 
  ToastSuccess, 
  ToastError 
} from "utility/toast";
import { isFlaggedModerationByAi } from "utility/ravenapi";
import { logger } from "utility/logging";
import {GraphqlService, Mixpanel} from "services"; 
import * as MIXPANEL_EVENTS from "constants/mixpanel"; 
import moment from "moment/moment";
import { handleReportWithLimit } from "utility/reportlimit";
import { updateReportLimit } from "dataapis/user";
import { copy2clipboard } from "utility/utils";
import { isTypeNode } from "graphql";
import MastodonPosts from "components/MastodonPosts/MastodonPosts";
import BlueskyPosts from "components/BlueskyPosts/BlueskyPosts";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
});

class BlueskySharedPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      reportDlg: false,
      loginDlg: false,
      shareDlg: false,
      // sourceDetails: null, 
    };
  }



  render() {
    
    const { 
      classes, 
      theme, 
      loggedIn,
      authUser,
      article, 
      saved,
      sources,
      handleClick, 
      handleGroupId,
      onClickSource, 
      onClickFeed,
      onClickArticle,
      posts,
    } = this.props;
    const { 
      anchorEl, 
      reportDlg, 
      loginDlg, 
      shareDlg 
    } = this.state;
    
    return (
      <div className={classes.root}>
          
          
  <BlueskyPosts posts={posts} />
  
  

      </div>
      

    );
  }
}

BlueskySharedPosts.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  loggedIn: PropTypes.bool,
  authUser: PropTypes.object,
  article: PropTypes.object, 
  saved: PropTypes.bool,
  onReport: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onDeleteSaved: PropTypes.func,
  onLogin: PropTypes.func,
  handleClick: PropTypes.func, 
  handleGroupId: PropTypes.func, 
  onClickSource: PropTypes.func, 
  onClickFeed: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
  from: PropTypes.string,
  onClickArticle:PropTypes.func,
};

const mapStateToProps = (state) => ({
  sources: state.dataState.sources,
  selected_feed: state.dataState.selected_feed,
});
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps,mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(BlueskySharedPosts);

