import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Badge,
} from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  listitem: {
    display: "-webkit-box",
    width: "100%",
    padding: 0,
    paddingRight: 60,
    marginTop: theme.spacing(1),
    margin: 0,
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "#232323"
    // }
  },
  badge_div: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
  },
  badge: {
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    backgroundColor: "red",
    color: "white",
  },
  listitem_avatar: {
    minWidth: 60,
  },
  listimage: {
    objectFit: "cover",
    height: 60,
    width: 60,
    borderRadius: 5,
  },
  listitem_text: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  name: {
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: "12px",
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
});

class ItemList extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickItem = this.handleClickItem.bind(this);
  }

  handleClickItem = (feed) => {
    this.props.onItemClicked(feed);
  };

  render() {
    const { classes, items, badged } = this.props;

    if (!items || items.length === 0) {
      return <div style={{display: "none"}}></div>;
    }

    return (
      <div className={classes.root}>
        <List>
          {items.map((item, index) => (
            <ListItem className={classes.listitem} key={index}>
              <ListItemAvatar
                className={classes.listitem_avatar}
                onClick={(e) => this.handleClickItem(item)}
              >
                <div className={badged !== undefined && classes.badge_div}>
                  {(badged === undefined || !badged) && (
                    <LazyLoadImage
                      className={classes.listimage}
                      alt={item.name}
                      src={item.thumbnail || item.image}
                    />
                  )}
                  {badged && (
                    <Badge
                      classes={{ badge: classes.badge }}
                      badgeContent={item.notifications}
                      overlap="rectangular"
                    >
                      <LazyLoadImage
                        className={classes.listimage}
                        alt={item.name}
                        src={item.thumbnail || item.image}
                      />
                    </Badge>
                  )}
                </div>
              </ListItemAvatar>
              <ListItemText
                id={index}
                className={classes.listitem_text}
                primary={
                  <div className={"item"}>
                    <Typography className={classes.name}>
                      {item.name}
                    </Typography>
                    <Typography className={classes.description}>
                      {item.description}
                    </Typography>
                  </div>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

ItemList.propTypes = {
  classes: PropTypes.object,
  badged: PropTypes.any,
  items: PropTypes.array,
  onItemClicked: PropTypes.func,
};

export default withStyles(styles)(ItemList);
