import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from '@material-ui/core/styles';
import { 
  AppBar, 
  Toolbar, 
  IconButton,
  Typography,
  Badge,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SettingsIcon from '@material-ui/icons/Settings';
import ShareIcon from "@material-ui/icons/Share";
import { 
  MAX_ARTICLE_WIDTH,
} from 'constants/types';


const styles = theme => ({
  root: {
    position: "fixed",
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    height: "56px",
    zIndex: 1100,
  },
  appbar: {
    position: "absolute",
    // borderBottom: `2px solid ${theme.palette.text.secondary}`
  },
  navbefore: {
    padding: 0,
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  titlebox: {
    display: "flex",
    alignItems: "center"
  },
  image: {
    height: 32,
    width: 32,
    padding: 4,
  },
  title: {
    display: "inline",
    marginLeft: theme.spacing(1),
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: 500,
  },
  mainBtnGroup: {
    flexGrow: 1,
  },
  badge: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: "red",
    color: "white",
  },
  settings: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRadius: 16,
    width: 32,
    height: 32,
    marginRight: 4,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }
  },
  settingsicon: {
    width: 32,
    height: 32,
  },
  share: {
    backgroundColor: "#7289DA",
    color: theme.palette.info.contrastText,
    borderRadius: 16,
    width: 32,
    height: 32,
    padding: 4,
    "&:hover": {
      backgroundColor: "#7289DA",
      color: theme.palette.info.contrastText,
    }
  },
  shareicon: {
    width: 24,
    height: 24,
  },
});

class CommentsAppBar extends React.Component {

  render() {
    const {
      classes,
      theme_mode,
      moderator,
      notifications,
      onNavBack,
      onReports,
      onShare
    } = this.props;

    return (
      <div className={classes.root}>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton 
              edge="start" 
              className={classes.navbefore} 
              aria-label="nav before"
              onClick={onNavBack}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <div className={classes.titlebox}>
              <img
                className={classes.image}
                src={`/static/images/icons/${theme_mode}/comments_all.png`}
                alt="Comments"
              />
              <Typography className={classes.title} variant="h6" noWrap>
                Comments
              </Typography>
            </div>

            <div className={classes.mainBtnGroup} />

            {moderator && 
              <IconButton
                className={classes.settings}
                onClick={onReports}
              >
                {notifications > 0 ? (
                  <Badge
                    classes={{ badge: classes.badge }}
                    badgeContent={notifications}
                    overlap="rectangular"
                  >
                    <SettingsIcon className={classes.settings} />
                  </Badge>
                ) : (
                  <SettingsIcon className={classes.settings} />
                )}
              </IconButton>
            }

            <IconButton
              className={classes.share}
              onClick={onShare}
            >
              <ShareIcon className={classes.shareicon} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

CommentsAppBar.propTypes = {
  classes: PropTypes.object,
  moderator: PropTypes.bool,
  notifications: PropTypes.number,
  onNavBack: PropTypes.func,
  onReports: PropTypes.func,
  onShare: PropTypes.func,
};
 

const mapStateToProps = state => ({
  theme_mode: state.uiState.theme_mode
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CommentsAppBar));
