import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  description: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

class PopMenuUserMod extends React.Component {
  render() {
    const { 
      classes, 
      theme,
      suspendedCount,
      suspendedState,
      onSuspend1day, 
      onSuspend7day,
      onSuspendPermanent,
      onSuspendPosts,
      onSuspendComments,
      onSuspendImages,
      onSuspendVideo,
      onSuspendFeedCreation,
      onEndSuspend1day,
      onEndSuspend7day,
      onEndSuspendPermanent,
      onAllowPosts,
      onAllowComments,
      onAllowImages,
      onAllowVideo,
      onAllowFeedCreation,
      onActivityLog,
    } = this.props;

    const suspended = suspendedState.suspended1day || suspendedState.suspended7day || suspendedState.suspendedPermenant;
    const description1 = `This user has ${suspendedCount > 0 ? "had " : "not"}`;
    const description2 = `${suspendedCount > 0 ? suspendedCount : ""} previous suspension${suspendedCount === 1 ? "s" : ""}`
;    return (
      <div>
        <Typography className={classes.description}>
          {description1}<br/>
          {description2}
        </Typography>

        <MenuList className={classes.menu_list}>
          <MenuItem 
            className={classes.menu_item} 
            disabled={suspended && !suspendedState.suspended1day}
            onClick={suspendedState.suspended1day ? onEndSuspend1day : onSuspend1day}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"block"}
                src={`/static/images/icons/${theme}/banned-sign.png`}
              />
            </span>
            {suspendedState.suspended1day ? "End 1-Day Suspension" : "1-Day Suspension"}
          </MenuItem>

          <MenuItem 
            className={classes.menu_item}
            disabled={suspended && !suspendedState.suspended7day}
            onClick={suspendedState.suspended7day ? onEndSuspend7day : onSuspend7day}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"block"}
                src={`/static/images/icons/${theme}/banned-sign.png`}
              />
            </span>
            {suspendedState.suspended7day ? "End 7-Day Suspension" : "7-Day Suspension"}
          </MenuItem>
          
          <MenuItem 
            className={classes.menu_item}
            disabled={suspended && !suspendedState.suspendedPermenant}
            onClick={suspendedState.suspendedPermenant ? onEndSuspendPermanent : onSuspendPermanent}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"block"}
                src={`/static/images/icons/${theme}/banned-sign.png`}
              />
            </span>
            {suspendedState.suspendedPermenant ? "Undo Perm Suspension" : "Permanently Suspend"}
          </MenuItem>
          
          <MenuItem 
            className={classes.menu_item}
            disabled={suspended}
            onClick={suspendedState.suspendedPosts ? onAllowPosts : onSuspendPosts}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"block"}
                src={`/static/images/icons/${theme}/chat.png`}
              />
            </span>
            {suspendedState.suspendedPosts ? "Allow Posts" : "Suspend Posts"}
          </MenuItem>

          <MenuItem 
            className={classes.menu_item}
            disabled={suspended}
            onClick={suspendedState.suspendedComments ? onAllowComments : onSuspendComments}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"block"}
                src={`/static/images/icons/${theme}/chat.png`}
              />
            </span>
            {suspendedState.suspendedComments ? "Allow Comments" : "Suspend Comments"}
          </MenuItem>

          <MenuItem 
            className={classes.menu_item}
            disabled={suspended}
            onClick={suspendedState.suspendedImages ? onAllowImages : onSuspendImages}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"block"}
                src={`/static/images/icons/${theme}/camera.png`}
              />
            </span>
            {suspendedState.suspendedImages ? "Allow Images" : "Suspend Images"}
          </MenuItem>

          <MenuItem 
            className={classes.menu_item}
            disabled={suspended}
            onClick={suspendedState.suspendedVideo ? onAllowVideo : onSuspendVideo}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"block"}
                src={`/static/images/icons/${theme}/videocast.png`}
              />
            </span>
            {suspendedState.suspendedVideo ? "Allow Video" : "Suspend Video"}
          </MenuItem>

          <MenuItem 
            className={classes.menu_item}
            disabled={suspended}
            onClick={suspendedState.suspendedFeedCreation ? onAllowFeedCreation : onSuspendFeedCreation}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"block"}
                src={`/static/images/icons/${theme}/plus.png`}
              />
            </span>
            {suspendedState.suspendedFeedCreation ? "Allow Feed Creation" : "Suspend Feed Creation"}
          </MenuItem>

          <MenuItem 
            className={classes.menu_item}
            style={{marginTop: 16}}
            disabled={suspendedCount === 0}
            onClick={onActivityLog}
          >
            {"Activity Log"}
          </MenuItem>
        </MenuList>
      </div>
    );
  }
}

PopMenuUserMod.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string.isRequired,
  suspendedCount: PropTypes.number.isRequired,
  suspendedState: PropTypes.object.isRequired,
  onSuspend1day: PropTypes.func.isRequired, 
  onSuspend7day: PropTypes.func.isRequired,
  onSuspendPermanent: PropTypes.func.isRequired,
  onSuspendPosts: PropTypes.func.isRequired,
  onSuspendComments: PropTypes.func.isRequired,
  onSuspendImages: PropTypes.func.isRequired,
  onSuspendVideo: PropTypes.func.isRequired,
  onSuspendFeedCreation: PropTypes.func.isRequired,
  onEndSuspend1day: PropTypes.func.isRequired,
  onEndSuspend7day: PropTypes.func.isRequired,
  onEndSuspendPermanent: PropTypes.func.isRequired,
  onAllowPosts: PropTypes.func.isRequired,
  onAllowComments: PropTypes.func.isRequired,
  onAllowImages: PropTypes.func.isRequired,
  onAllowVideo: PropTypes.func.isRequired,
  onAllowFeedCreation: PropTypes.func.isRequired,
  onActivityLog: PropTypes.func.isRequired,
};

export default withStyles(styles)(PopMenuUserMod);
