import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
} from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { get_timestring, int2kstring, decodeHTMLEntities } from "utility/utils";
import {
  MIN_CARD_WIDTH,
  MAX_ARTICLE_WIDTH,
  BLANK_USER_IMAGE,
} from "constants/types";

const styles = (theme) => ({
  card: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_ARTICLE_WIDTH,
    backgroundColor: theme.palette.background.default,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    borderRadius: 10,
    backgroundColor: theme.palette.background.article,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 32,
    height: 32,
  },
  title: {
    position: "relative",
    marginLeft: 30,
    marginRight: 10,
    margin: 10,
  },
  name: {
    display: "inline",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  screenname: {
    display: "inline",
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.text.primary,
  },
  subtitle: {
    display: "block",
    marginLeft: 40,
    marginRight: 20,
  },
  replydecl: {
    float: "left",
    fontSize: "14px",
    lineHeight: "18px",
    color: theme.palette.text.secondary,
  },
  content: {
    paddingTop: 4,
    paddingBottom: 0,
    marginLeft: 20,
    marginRight: 10,
    marginBottom: 8,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  date: {
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
  detail_txt: {
    fontFamily: "Roboto",
    display: "inline",
    overflowWrap: "break-word",
    color: theme.palette.text.primary,
  },
  media: {
    display: "block",
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    // marginBottom: theme.spacing(1),
    borderRadius: 10,
  },
  reaction: {
    left: "50%",
    transform: "translate(-50%)",
    fontSize: 12,
    marginBottom: 4,
    color: theme.palette.text.primary,
  },
  reactiondiv: {
    marginLeft: 16,
  },
  reactionimg: {
    float: "left",
    width: 12,
    height: 12,
  },
  reactionvalue: {
    float: "left",
    marginLeft: 5,
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
});

class MainCard extends React.Component {
  render() {
    const { classes, article, theme_mode } = this.props;

    // username, screenname
    const user_name = article.author;
    const handle = article.txt_param1;

    // user image
    let user_image = article.author_image;
    if (!user_image) {
      user_image = BLANK_USER_IMAGE;
    }

    const reposts = int2kstring(article.param1);
    const likes = int2kstring(article.param2);
    const replies = int2kstring(article.param3);
    const published = get_timestring(article.published);
    const media = article.extra_data;

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) width = MAX_ARTICLE_WIDTH;
    if (width < MIN_CARD_WIDTH) width = MIN_CARD_WIDTH;
    width = width - 16;

    return (
      <Card className={classes.card} style={{ width: width }}>
        <div className={classes.carddiv}>
          <CardHeader
            className={classes.header}
            avatar={
              <Avatar
                alt={article.name}
                src={user_image}
                className={classes.avatar}
                classes={{ img: classes.avatar_img }}
              />
            }
            title={
              <div className={classes.title}>
                <Typography className={classes.name}>
                  {decodeHTMLEntities(user_name)}
                </Typography>
                <Typography className={classes.screenname}>
                  @{handle}
                </Typography>
              </div>
            }
          />
          <CardContent className={classes.content}>
            <Typography className={classes.date}>{published}</Typography>
          </CardContent>
          <CardContent className={classes.content}>
            <div
              className={classes.detail_txt}
              dangerouslySetInnerHTML={{ __html: article.text }}
            />
          </CardContent>
          {media && (
            <LazyLoadImage alt={""} src={media[0]} className={classes.media} />
          )}
          <CardActions>
            <Button
              className={classes.reaction}
              size="small"
              color="primary"
              target="_blank"
            >
              <div>
                <img
                  alt={"replies"}
                  src={`/static/images/icons/${theme_mode}/reply.png`}
                  className={classes.reactionimg}
                />
                <Typography className={classes.reactionvalue}>
                  {replies}
                </Typography>
              </div>
              <div className={classes.reactiondiv}>
                <img
                  alt={"reposts"}
                  src={`/static/images/icons/${theme_mode}/retweet.png`}
                  className={classes.reactionimg}
                />
                <Typography className={classes.reactionvalue}>
                  {reposts}
                </Typography>
              </div>
              <div className={classes.reactiondiv}>
                <img
                  alt={"likes"}
                  src={`/static/images/icons/${theme_mode}/favorite.png`}
                  className={classes.reactionimg}
                />
                <Typography className={classes.reactionvalue}>
                  {likes}
                </Typography>
              </div>
            </Button>
          </CardActions>
        </div>
      </Card>
    );
  }
}

MainCard.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
};

const mapStateToProps = (state) => ({
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MainCard));
