import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MetaTags from "react-meta-tags";
import { ToastContainer } from "react-toastify";
import * as ROUTES from "constants/routes";
import { 
  CommentsAppBar, 
  ThreadList,
  DlgShare,
  DlgLoginConfirm,
  DlgTopThread,
  WaitingSpinner
} from "components";
import { 
  withAuthentication 
} from "session";
import {
  withFirebase,
  GraphqlService,
} from "services";
import { 
  MAX_ARTICLE_WIDTH,
  TAB_FEED,
  THREAD_REPORT_COMMENTS, 
} from "constants/types";
import { 
  ARTICLE_BRANCH_NEWSPAPER,
  BRANCH_ALL,
} from "constants/branches";
import { ALL } from "constants/country";
import { 
  getMainInfo,
  isCategoryModerator,
  isFeedModerator,
  isBannedComments,
  getFeeds2show,
  addThread,
} from "dataapis";
import {
  ToastError
} from "utility/toast";
import { logger } from "utility/logging";
import { Mixpanel } from "services";
import * as MIXPANEL_EVENTS from "constants/mixpanel";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 1100,
  },
  // threads: {
  //   margin: theme.spacing(1),
  // },
  addbutton: {
    position: "fixed",
    width: 60,
    height: 60,
    zIndex: 1200,
  },
});

class Comments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareDlg: false,
      loginDlg: false,
      postDlg: false,
      reportDlg: false,
      feed2show: [],
      selected_feed: null,
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleClickShare = this.handleClickShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);
    this.handleClickReportManage = this.handleClickReportManage.bind(this);

    this.handleNeedMore = this.handleNeedMore.bind(this);

    this.handleClickThread = this.handleClickThread.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);

    this.handleAddThread = this.handleAddThread.bind(this);
    this.handleCloseThread = this.handleCloseThread.bind(this);
    this.handleSubmitThread = this.handleSubmitThread.bind(this);
  }

  componentDidMount = async () => {
    this.setWaiting(true);

    await getMainInfo();
    await Promise.all([
      this.getThreadsInFirstPage(),
      this._updateThreadReports()
    ]);

    this.setWaiting(false);

    const feeds2show = getFeeds2show();
    this.setState({
      ...this.state,
      feeds2show: feeds2show
    });
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  getThreadsInFirstPage = async () => {
    this.setWaiting(true);
    
    const gqlservice = new GraphqlService();
    await gqlservice
      .threads(0)
      .then(
        (result) => {
          const threads = result.data.threads;
          logger.log("Home => threads(first page) :", threads);

          this.props.setThreads(threads, threads.length);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  getThreadsInNextPage = async (last_offset) => {
    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    await gqlservice
      .threads(last_offset)
      .then(
        (result) => {
          const threads = result.data.threads;
          logger.log("Home => threads(next page) :", threads);

          if (threads.length > 0) {
            this.props.appendThreads(threads, last_offset + threads.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.refreshThreads();
    this.props.history.push(location);
  }

  handleClickShare = () => {
    this.setState({
      shareDlg: true,
    });
  }

  handleCloseShare = () => {
    this.setState({
      shareDlg: false,
    });
  }

  handleClickReportManage = () => {
    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const { thread_reports } = this.props;
    if (thread_reports.length > 0) {
      const location = {
        pathname: ROUTES.COMMENTS_REPORTS,
        state: { animation: "left" },
      };
      this.props.history.push(location);
    }
  }

  handleNeedMore = async () => {
    const { threads_last_offset } = this.props;
    await this.getThreadsInNextPage(threads_last_offset);
  }

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
  };

  handleClickThread = (thread, feed) => {
    this.props.selectThread(thread);
    this.props.selectFeed(feed);
    if (thread.article) {
      const type = thread.article.source.branch === ARTICLE_BRANCH_NEWSPAPER ? ROUTES.ARTICLE_NEWS_PREFIX : ROUTES.ARTICLE_PREFIX;
      const route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${thread.article.source.slug}/${type}/${thread.article.nid}`;
      const location = {
        pathname: route,
        state: { animation: "left" },
      };
      this.props.history.push(location);
      this.props.setArticleBackRoute(ROUTES.COMMENTS);
    } else {
      const route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.id}`;
      const location = {
        pathname: route,
        state: { animation: "left" },
      };
      this.props.history.push(location);
    }
  }

  handleClickFeed = (feed) => {
    const properties = {
      id: feed.id,
      name: feed.name,
      slug: feed.slug
    };
    Mixpanel.track(MIXPANEL_EVENTS.FEED_DETAILS, properties);

    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // Go to moderation page if the logged user is the moderator of the feed
    if (isFeedModerator(feed)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.setFeedBackRoute(ROUTES.COMMENTS);
    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  }

  handleClickSource = (source, feed) => {
    const properties={
      sourceId : source.id,
      name : source.name
    }
    Mixpanel.track(MIXPANEL_EVENTS.FEED_SOURCE_DETAILS, properties); 

    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  _updateThreadReports = async () => {
    const gqlservice = new GraphqlService();
    await gqlservice
      .thread_reports_by_type(THREAD_REPORT_COMMENTS)
      .then(
        (result) => {
          const thread_reports = result.data.thread_reports;
          logger.log("thread reports from home :", thread_reports);
          this.props.setThreadReports(thread_reports);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  }

  handleAddThread = () => {
    const { loggedIn } = this.props;
    if (loggedIn) {
      if (isBannedComments()) {
        this.setError("You've suspended for comment operations.");
        return;
      }
      this.setState({
        ...this.state,
        threadDlg: true,
      });
    } else {
      this.setState({
        ...this.state,
        loginDlg: true
      });
    }
  };

  handleCloseThread = () => {
    this.setState({
      ...this.state,
      threadDlg: false,
    });
  };

  handleSubmitThread = async (title, content, feed) => {
    const { authUser } = this.props;

    // check if the user is pre-approved for this selected feed
    const approved_user = authUser.preapproved.find(
      (item) => item.feed_id === feed.id
    );
    const approved = approved_user !== undefined;

    this.setWaiting(true);
    await addThread(title, content, feed, approved);
    this.setWaiting(false);

    this.handleCloseThread();
  };

  render() {
    const { 
      classes, 
      theme_mode,
      threads,
      thread_reports,
      requesting 
    } = this.props;
    const {
      feeds2show,
      shareDlg,
      loginDlg,
      threadDlg,
    } = this.state;

    let moderator = isCategoryModerator();

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    let addbuttonPos = width - 96;
    if (width > MAX_ARTICLE_WIDTH) {
      addbuttonPos = MAX_ARTICLE_WIDTH - 96 + (width - MAX_ARTICLE_WIDTH) / 2;
      width = MAX_ARTICLE_WIDTH;
    }

    let baseUrl = "";
    if (typeof window !== "undefined") {
      baseUrl = window.location.protocol + "//" + window.location.host;
    }
    const shareUrl = baseUrl + ROUTES.COMMENT;

    const shareInfo = {
      title: "Raven Comments",
      description: "Show Raven comments",
      image: "/static/images/icons/raven_logo.png",
      hashtag: "raven,comments",
      url: shareUrl,
    }

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{"Raven Comments"}</title>
            <meta name="description" content={"Comments throughout Raven"} />
            <meta property="og:title" content={"Raven Comments"} />
            <meta
              property="og:description"
              content={"Comments throughout Raven"}
            />
            <meta property="og:image" content={""} />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={shareUrl} />
            <meta property="twitter:title" content={"Raven Comments"} />
            <meta property="twitter:site" content="Raven App" />
            <meta
              property="twitter:description"
              content={"Comments throughout Raven"}
            />
            <meta property="twitter:image:src" content={""} />
            <meta property="twitter:image:alt" content={""} />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>

        <div className={classes.appbar}>
          <CommentsAppBar
            moderator={moderator}
            notifications={thread_reports.length}
            onNavBack={this.handleNavBack}
            onReports={this.handleClickReportManage}
            onShare={this.handleClickShare}
          />
        </div>

        {threads.length > 0 && (
          <div>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                <ThreadList
                  threads={threads}
                  moderator={moderator}
                  onNeedMore={this.handleNeedMore}
                  onLogin={this.handleLogin}
                  onClickThread={this.handleClickThread}
                  onClickFeed={this.handleClickFeed}
                  onClickSource={this.handleClickSource}
                />
              </Grid>
            </Grid>
            <div style={{ width: width - 16, height: 20 }}></div>
          </div>
        )}

        <div onClick={this.handleAddThread}>
          <img
            className={classes.addbutton}
            style={{ left: addbuttonPos, bottom: 16 }}
            alt={"addthread"}
            src={`/static/images/icons/${theme_mode}/add.png`}
          />
        </div>

        <DlgTopThread
          open={threadDlg}
          theme={theme_mode}
          feeds={feeds2show}
          onClose={this.handleCloseThread}
          onSubmit={this.handleSubmitThread}
        />
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgShare
          open={shareDlg}
          shareInfo={shareInfo}
          onClose={this.handleCloseShare}
        />
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

Comments.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
  newssites: state.dataState.newssites,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  sources: state.dataState.sources,
  threads: state.dataState.threads,
  threads_last_offset: state.dataState.threads_last_offset,
  thread_reports: state.dataState.thread_reports,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withAuthentication,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Comments);
