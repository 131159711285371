import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ToastError, ToastSocketSuccess } from "utility/toast";
import { THEME_MODE_LIGHT, MIN_CARD_WIDTH } from "constants/types";
import { generateSummary } from "utility/ravenapi";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Toolbar,
} from "@material-ui/core";
const styles = (theme) => ({
  caption: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 20,
  },
  griditem: {
    backgroundColor: theme.palette.background.card,
  },
  actionbutton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionbutton_disabled: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
    opacity: 0.38,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  text: {
    flex: 1,
    color: theme.palette.text.primary,
    margin: 0,
    padding: 8,
    fontSize: 14,
    fontHeight: 18,
    backgroundColor: theme.palette.background.dark,
    "& input": {
      fontSize: 14,
      fontHeight: 18,
      backgroundColor: theme.palette.background.dark,
    },
  },

  applybtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "4px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  applybtn_disabled: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "4px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  copyButton: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    margin: 0,
    padding: 1,
    width: 25,
    height: 25,
    marginLeft: theme.spacing(-0.5),
    marginTop: 22,
  },
  shareicon: {
    width: 350,
    height: 50,
    // marginLeft: theme.spacing(-0.5),
    marginTop: 22,
  },
  podcastshare: {
    width: 300,
    height: 250,
  },
  summaryButton: {
    width: 350,
    height: 250,
  },
  head: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: -10,
  },
});

class DlgTutorial extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  handleClose = () => {
    this.props.onClose();
  };
  render() {
    const { classes, open, theme_mode } = this.props;
    const paper_style = {
      width: MIN_CARD_WIDTH,
      borderRadius: 10,
      borderStyle: "solid",
      borderColor: theme_mode === THEME_MODE_LIGHT ? "black" : "white",
      borderWidth: "1px",
      backgroundColor: theme_mode === THEME_MODE_LIGHT ? "white" : "black",
      overflow: "auto",
  };
  
    return (
      <Dialog
        open={open}
        aria-labelledby="summary-tutorial"
        aria-describedby="summary-tutorial"
        PaperProps={{ style: paper_style, margin: "auto", maxWidth: "100%" }}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={this.handleClose}
              className={classes.actionbutton}
            >
              <img
                className={classes.actionimg}
                alt="apply"
                src="/static/images/delete.png"
              />
            </IconButton>
            <div style={{ textAlign: "center" }}>
              <Typography className={classes.head} align="center">
                How to find the link
              </Typography>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ }}>
            <Typography style={{ textAlign: "center" }}>
              1. Tap Share below the video, or next to the{" "}
              <span style={{ whiteSpace: "nowrap" }}>podcast</span>
            </Typography>
            <Toolbar className={classes.toolbar} style={{marginTop:-20}}>
              <img
                alt={"Raven"}
                src={`/static/images/icons/light/youtube.png`}
                className={classes.avatar}
                style={{ marginRight: 10 }}
              />
              <Typography
                style={{
                  marginBottom: -25,
                }}
                className={classes.caption}
              >
                YouTube
              </Typography>
            </Toolbar>
            <Toolbar className={classes.toolbar}>
              <img
                alt={"Raven"}
                src={`/static/images/icons/${theme_mode}/youtubeshare.jpg`}
                className={classes.shareicon}
                style={{ maxWidth: "110%", height: "auto", marginRight: 30 ,marginTop:-10}}
              />
            </Toolbar>
          </div>
          <div style={{ marginLeft: 10,marginTop:-20 }}>
            <Toolbar className={classes.toolbar}>
              <img
                alt={"Raven"}
                src={`/static/images/icons/${theme_mode}/podcast.png`}
                className={classes.avatar}
                style={{ marginRight: 10 }}
              />
              <Typography
                style={{
                  marginBottom: -25,
                }}
                className={classes.caption}
              >
                Podcasts
              </Typography>
            </Toolbar>
            <Toolbar className={classes.toolbar}>
              <img
                alt={"Raven"}
                src={`/static/images/icons/${theme_mode}/podcastsshare.png`}
                className={classes.podcastshare}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginRight: 20,
                  marginBottom: 20,
                  marginTop:5
                }}
              />
            </Toolbar>
          </div>
          <div>
            <div style={{marginLeft:10}}>
            <Typography>2. Copy link from share menu</Typography>
            <Toolbar className={classes.toolbar}>
              <img
                alt={"Raven"}
                src={theme_mode === 'dark' ? `/static/images/icons/${theme_mode}/iphoneshare.jpg` : `/static/images/icons/${theme_mode}/iphoneshare.jpeg`}
                className={classes.podcastshare}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginRight: 20,
                  marginBottom: 20,
                 marginLeft:"2%",
                 marginTop:10
                }}
              />
            </Toolbar>
            </div >
            </div>
            <div style={{marginLeft:10}}>
            <Typography>3. Paste into summarize box</Typography>
            <Toolbar className={classes.toolbar}>
              <img
                alt={"Raven"}
                src={`/static/images/icons/${theme_mode}/summary_button.png`}
                className={classes.podcastshare}
                style={{
                  maxWidth: "140%",
                  height: "auto",
                  marginRight: 20,
                  marginBottom: 20,
                  width: "100%",
                }}
              />
            </Toolbar>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

DlgTutorial.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selected_feed: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  socketId: state.sessionState.socketId,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DlgTutorial);
