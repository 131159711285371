import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  // InputBase,
  Button,
  Grid,
} from "@material-ui/core";
import { THEME_MODE_LIGHT, THEME_MODE_DARK } from "constants/types";
import { ImageSelector } from "components/CommonControls";
import { ToastError } from "utility/toast";
// import { GraphqlService } from "services";
import * as ROUTES from "constants/routes";
// import { isNull } from "lodash";

const styles = (theme) => ({
  caption: {
    fontSize: 16,
    fontWeight: 500,
  },
  closebutton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  title: {
    padding: 0,
    paddingBottom: theme.spacing(1),
  },
  content: {
    padding: 0,
  },
  text: {
    flex: 1,
    color: theme.palette.text.primary,
    margin: 0,
    padding: 4,
    backgroundColor: theme.palette.background.dark,
    "& input": {
      fontSize: 14,
      fontWeight: 18,
      backgroundColor: theme.palette.background.dark,
    },
  },
  applybtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    fontSize: "15px",
    width: "218px",
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    height: 42,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  applybtn_disabled: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    fontSize: "16px",
    width: "218px",
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
  info: {
    width: "82%",
    marginLeft: "16px",
  },
  name: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "14px",
    color: theme.palette.text.primary,
    marginBottom: 4,
    marginTop: 4,
  },
  userTextField: {
    borderRadius: 2,
    height: 25,
    width: "95%",
    padding: "0px 4px",
    border: 0,
    borderBottom: "1px solid #000",
    fontSize: 11,
    "&:focus-visible": {
      outline: "none",
    },
  },
  usernameAdd: {
    border: 0,
    borderBottom: "1px solid #000",
    borderRadius: 2,
    height: 30,
    padding: 4,
    marginBottom: 10,
    width: "95%",
    fontSize: 10,
    "&:focus-visible": {
      outline: "none",
    },
  },
});

class EditAIPersonas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.socialtype.user.name,
      biography: this.props.socialtype.user.biography,
      seed_phrase: this.props.socialtype.seed_phrase,
      image: this.props.socialtype.user.image,
      user_id: this.props.socialtype.user_id,
      id: this.props.socialtype.id,
      imageUpload: null,
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeBio = this.handleChangeBio.bind(this);
    this.handleChangeSeed = this.handleChangeSeed.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }
  setError = (message) => {
    ToastError(message);
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  _getAuthToken = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    let token = authUser.token;
    if (Date.now() >= authUser.expiredTS) {
      const result = await this.props.firebase.refreshToken();
      if (result.error) {
        this.setError(result.msg);
        token = null;
      } else {
        token = result.token;
      }
    }
    return token;
  };

  handleChangeName = (event) => {
    this.setState({
      ...this.state,
      name: event.target.value,
    });
  };
  handleChangeBio = (event) => {
    this.setState({
      ...this.state,
      biography: event.target.value,
    });
  };
  handleChangeSeed = (event) => {
    this.setState({
      ...this.state,
      seed_phrase: event.target.value,
    });
  };
  handleChangeImage = (event) => {
    this.setState({
      ...this.state,
      image: event.target.value,
    });
  };
  handleImageChange = ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      this.setState({
        ...this.state,
        image: e.target.result,
        imageUpload: target.files[0],
      });
    };
  };

  handleSubmit = async () => {
    const { name, biography, seed_phrase, image } =
      this.state;
    if (name.trim() === "") {
      this.setError("Name can't be blank.");
      return;
    }
    if (biography.trim() === "") {
      this.setError("Biography can't be blank.");
      return;
    }

    if (seed_phrase.trim() === "") {
      this.setError("Seed phrase can't be blank.");
      return;
    }

    if (image === "") {
      this.setError("Please upload the image. ");
      return;
    }

    const updatedData = this.state;
    this.props.onSubmit(updatedData);
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { classes, open, theme } = this.props;
    const aiPersonDatas = this.state;
    const paper_style = {
      backgroundColor: theme === THEME_MODE_LIGHT ? "white" : "black",
      boxShadow:
        theme === THEME_MODE_DARK
          ? "0 1px 1px 1px rgb(63 63 68 / 5%), 0 3px 3px 0 rgb(63 63 68 / 15%)"
          : "none",
      bordeColor: theme === THEME_MODE_DARK ? "#CCCCFF" : "",
      borderStyle: theme === THEME_MODE_DARK ? "solid" : "none",
      borderWidth: theme === THEME_MODE_DARK ? 2 : 0,
      borderRadius: theme === THEME_MODE_DARK ? 10 : 0,
    };

    return (
      <>
        {" "}
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ style: paper_style }}
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle>
            <Typography className={classes.caption} align="center">
              Edit Profile
            </Typography>
            <IconButton
              onClick={this.handleClose}
              className={classes.closebutton}
            >
              <img
                className={classes.actionimg}
                alt="apply"
                src="/static/images/delete.png"
              />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <div className={classes.content}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <ImageSelector
                  image={aiPersonDatas.image}
                  imageHandleChanged={this.handleImageChange}
                  imageSelectorType="small"
                />
                <Grid item className={classes.info}>
                  <Typography className={classes.name}>
                    <input
                      id="ai_person_name"
                      name="ai_person_name"
                      placeholder="Name"
                      value={aiPersonDatas.name}
                      className={classes.usernameAdd}
                      onChange={(e) => this.handleChangeName(e)}
                    />
                  </Typography>
                  <Typography className={classes.name}>
                    <textarea
                      id="ai_person_bio"
                      name="ai_person_bio"
                      placeholder="Enter a bio here"
                      value={aiPersonDatas.biography}
                      className={classes.usernameAdd}
                      onChange={(e) => this.handleChangeBio(e)}
                    />
                  </Typography>
                  <Typography className={classes.name}>
                    <textarea
                      id="ai_person_seed_phrase"
                      name="ai_person_seed_phrase"
                      placeholder="Enter your seed phrase here"
                      value={aiPersonDatas.seed_phrase}
                      className={classes.usernameAdd}
                      onChange={(e) => this.handleChangeSeed(e)}
                    />
                  </Typography>
                </Grid>
                <Grid item></Grid>
              </Grid>
            </div>
          </DialogContent>

          <DialogActions style={{ justifyContent: "center" }}>
            {/* {enabled && */}
            <Button
              className={classes.applybtn}
              onClick={(e) => this.handleSubmit(e)}
            >
              Save
            </Button>
            {/* } */}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

EditAIPersonas.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  theme: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  socialtype: PropTypes.object,
};

export default withStyles(styles)(EditAIPersonas);
