import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Source } from "./components";

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    margin: 0,
    backgroundColor: theme.palette.background.default,
  },
});

class SourceList extends React.Component {
  constructor(props) {
    super(props);

    this.handleClicked = this.handleClicked.bind(this);
    this.handleUnfollowed = this.handleUnfollowed.bind(this);
    this.handleShowRetweet = this.handleShowRetweet.bind(this);
    this.scrollFn = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollFn);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFn);
  }

  handleClicked = (source_id) => {
    const { sources } = this.props;
    const source = sources.find(item => item.id === source_id);
    if (source) {
      this.props.onClicked(source);
    }
  }

  handleUnfollowed = (source_id, unfollowed) => {
    const { sources } = this.props;
    const source = sources.find(item => item.id === source_id);
    if (source) {
      this.props.onUnfollowed(source, unfollowed);
    }
  }

  handleShowRetweet = (source_id, showrt) => {
    const { sources } = this.props;
    const source = sources.find(item => item.id === source_id);
    if (source) {
      this.props.onShowRetweet(source, showrt);
    }
  }

  listenToScroll = (event) => {
    event.preventDefault();

    const { topNavbar } = this.props;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    if (height === 0) {
      return;
    }
    
    const scrolled = winScroll * 1.0  / height;
    if (scrolled === 0.0) {
      this.props.showTopNavbar(true);
    } else {
      if (topNavbar) {
        this.props.showTopNavbar(false);
      }
    }
  };

  render() {
    const { classes, width, sources, onNeedLogin } = this.props;

    if (sources.length === 0) {
      return <div style={{display: "none"}}></div>;
    }
    let sorted_sources = sources.sort((a, b) => b.upvotes - a.upvotes);

    return (
      <div className={classes.root} style={{width: width}}>
        <Grid container spacing={1}>
          {sorted_sources.map((source, index) => (
            <Grid item key={index}>
              <Source
                source={source}
                order={index}
                onClicked={this.handleClicked}
                onUnfollowed={this.handleUnfollowed}
                onShowRetweet={this.handleShowRetweet}
                onNeedLogin={onNeedLogin}
              />
            </Grid>
          ))}
        </Grid>
        <div style={{width: width - 16, height: 80}}></div>
      </div>
    );
  }
}

SourceList.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  sources: PropTypes.array,
  onUnfollowed: PropTypes.func,
  onShowRetweet: PropTypes.func,
  onNeedLogin: PropTypes.func,
  onClicked: PropTypes.func
};

const mapStateToProps = state => ({
  topNavbar: state.uiState.topNavbar
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SourceList);

