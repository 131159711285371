import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Grid,
  // Button,
  IconButton,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarIcon from "@material-ui/icons/Star";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { decodeHTMLEntities, get_timestring, render_text } from "utility/utils";
import { CONF_COUNTRIES } from "constants/country";
import {
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  THEME_MODE_DARK,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
import { CONF_BRANCHES, get_branch, ARTICLE_BRANCH_PODCAST } from "constants/branches";
import { logger } from "utility/logging";
import { GraphqlService } from "services";
import { LinkPreview } from "components/CommonControls";

const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    borderRadius: 10,
    borderTopLeftRadius: 30,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.default,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  share: {
    position: "absolute",
    top: 12,
    right: 12,
    padding: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    backgroundColor: "#7289DA",
    zIndex: 100,
  },
  shareicon: {
    width: 16,
    height: 16,
  },
  pin: {
    position: "absolute",
    top: 12,
    right: 40,
    padding: 0,
    width: 16,
    height: 16,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    zIndex: 100,
  },
  expand: {
    position: "absolute",
    top: 28,
    right: 10,
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 48,
    height: 48,
    cursor: "pointer",
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  socialimg: {
    position: "absolute",
    top: 52,
    left: 14,
    width: 20,
    height: 20,
  },
  upvotebtn: {
    position: "absolute",
    top: 80,
    left: 12,
    width: 20,
    height: 20,
    zIndex: 100,
  },
  upvotetxt: {
    position: "absolute",
    top: 102,
    left: 16,
    fontSize: "14px",
    fontStyle: "Roboto",
  },
  actionimg: {
    width: 20,
    height: 20,
  },
  sourcename: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "18px",
    marginTop: 10,
    marginLeft: 40,
    marginRight: 30,
    marginBottom: 4,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  title: {
    position: "relative",
    marginTop: 4,
    marginLeft: 40,
    marginRight: 40,
  },
  feed: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontStyle: "Italic",
    lineHeight: "18px",
    marginTop: 4,
    marginLeft: 40,
    marginRight: 30,
    marginBottom: 4,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  subtitle: {
    position: "relative",
    marginLeft: 40,
    marginRight: 24,
  },
  subtitle_left: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    float: "left",
    position: "relative",
  },
  subtitle_right: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    float: "right",
    marginRight: 10,
  },
  country_img: {
    position: "relative",
    width: "auto",
    float: "left",
    height: 16,
    marginTop: 2,
    marginRight: theme.spacing(1),
  },
  content: {
    padding: 4,
    margin: 8,
    marginLeft: 50,
    marginRight: 10,
    minHeight: 90,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  summary: {
    display: "inline",
    overflowWrap: "break-word",
  },
  media: {
    width: 100,
    // height: "auto",
    maxHeight: 80,
    objectFit: "cover",
    float: "right",
    borderRadius: 5,
    marginLeft: 5,
    marginTop: 10,
    marginBottom: 8,
  },
  podcast_media:{
    display: "block",
    width: 240,
    height: 32,
    marginLeft: "auto",
    marginRight: "auto",
  },
  groupArticles: {
    width: 250,
    marginLeft: 20,
  },
  groupAvatar: {
    width: 24,
    height: 24,
  },
  actions: {
    padding: 0,
    margin: 0,
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  translated_div: {
    marginLeft: 10,
  },
  traslated_icon: {
    // position: "relative",
    float: "left",
    top: 3,
    marginLeft: 5,
    width: 16,
    height: 16,
    color: theme.palette.text.primary,
  },
  translated_txt: {
    display: "inline",
    marginLeft: 2,
    fontSize: 12,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  // actionbtn: {
  //   position: "absolute",
  //   textTransform: "inherit",
  //   right: 4,
  //   bottom: 4,
  // },
  // readmore: {
  //   fontSize: 12,
  //   color: theme.palette.text.secondary,
  //   float: "right",
  //   marginRight: 16,
  // },
  // righticon: {
  //   position: "absolute",
  //   right: 0,
  //   bottom: 5,
  //   color: theme.palette.text.secondary,
  // },
  repostbtn: {
    width: 20,
    height: 20,
    zIndex: 100,
  },
  commentbtn: {
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20,
    zIndex: 100,
  },
  comment: {
    marginLeft: theme.spacing(0.5),
    fontSize: "14px",
    fontStyle: "Roboto",
  },
  biasBanner:{
    width: "100%",
    borderRadius: 25,
    borderColor: theme.palette.feedstroke.news,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.news,
    padding: "8px",
    display: "flex",
    textAlign: "center",
    color: "orange",
    fontSize: '15px',
    letterSpacing: '0.05px',
    fontWeight: "bold",
  },
});

class SearchResultCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickShare = this.handleClickShare.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleClickShare = () => {
    const { article } = this.props;
    this.props.onShare(article.nid);
  };

  handleExpand = (event) => {
    this.props.onAnchorEl(event.currentTarget);
  };

  getCountryInfo = (countryCode) => {
    for (let country of CONF_COUNTRIES) {
      if (country.value === countryCode) {
        return country;
      }
    }
    return "";
  };

  getFeedofSource = async (article) => {
    const { loggedIn, feeds } = this.props;
    let feedSource = article.source.feed_sources;
    let feed = null;
      feed = feeds?.find(
        (item) =>
          item.feed_sources.find(
            (feed_source) => feed_source.source_id === article.source_id
          ) !== undefined
      );

    if (!feed) {
      feed = feedSource[0]?.feed
    }
    return feed;
  };


  render() {
    const {
      classes,
      theme_mode,
      loggedIn,
      authUser,
      selected_feed,
      sourceDetails,
      article,
      feeds,
      // search_mode,
      handleClick,
      handleGroupId,
      onClickSource,
      onClickFeed,
      onClickUpvote,
      onClickComment,
      onClickRepost,
    } = this.props;

    const branchOfArticle = get_branch(article.branch)
    const sentiment = article.political_sentiments ? JSON.parse(article.political_sentiments) : null;
    let bannerColor = "green"; // Default color for scores below 25

    if (sentiment) {
      if (sentiment.Score >= 26 && sentiment.Politics === "Right") {
        bannerColor = "red";
      } else if (sentiment.Score >= 26 && sentiment.Politics === "Left") {
        bannerColor = "orange";
      }
    }
    let wingBias = "Balanced"; 
    if (sentiment) {
      if (sentiment.Politics === "Left" || sentiment.Politics === "Right") {
        wingBias = `${sentiment.Politics} Wing Bias`;
      }
    }

    // get source to use name, image, etc
    let source = article.source
    
    if (!source) {
      logger.error("Error, Unknown article source!", article);
      return <div style={{display: "none"}}></div>;
    }
    // find the feed contain this article(source)
    let feed = null;
    feed = feeds?.find((item) => item.feed_sources.find((feed_source) => feed_source.source_id === article.source_id
        ) !== undefined
    );
    if (!feed) {
      feed = source?.feed_sources[0]?.feed
      if(!feed){
        return <div style={{display: "none"}}></div>;
      }
    }
   

    // voted, reposted by me
    let voted = false;
    let reposted = false;
    if (loggedIn && authUser) {
      voted =
        authUser.articles_voted.find(
          (voted) => voted.article_id === article.nid
        ) !== undefined;
      reposted =
        authUser.articles_reposted.find(
          (reposted) => reposted.article_id === article.nid
        ) !== undefined;
    }

    let country = this.getCountryInfo(article.country);

    const transMark = source.translate
      ? `Translated from ${source.translateLang} by GPT3.5`
      : "";
    var title = "";
    var summary = "";
    if (article.translated) {
      title = article.tr_title;
      // omit the last character(.)
      title = title.slice(0, title.length - 1);
      summary = article.tr_summary;
    } else {
      title = article.title;
      summary = article.summary;
    }
    var newsTime = get_timestring(article.published);

    // limit summary to 5 lines
    if (summary && summary.length > 160) {
      summary = summary.slice(0, 160);
      summary += "...";
    }

    //preview
    let preview = null;
    if (
      article.link_preview !== null &&
      article.link_preview.title !== null &&
      article.link_preview.title !== "" &&
      article.link_preview.image !== null &&
      article.link_preview.image !== ""
    ) {
      preview = article.link_preview;
    }

    // image
    let article_image = article.image;
    const image_thumb = article.image_thumb;
    if (image_thumb) {
      if (image_thumb["150"]) {
        article_image = image_thumb["150"];
      } else if (image_thumb["240"]) {
        article_image = image_thumb["240"];
      } else if (image_thumb["320"]) {
        article_image = image_thumb["320"];
      } else if (image_thumb["org"]) {
        article_image = image_thumb["org"];
      }
    }
    const hasImage = article_image !== "" && article_image !== null;

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    width -= 16;
    if (width > MAX_CARD_WIDTH - 16) width = MAX_CARD_WIDTH - 16;
    if (width < MIN_CARD_WIDTH - 16) width = MIN_CARD_WIDTH - 16;

    // get comments
    let commentsCnt = 0;
    for (let thread of article.threads) {
      commentsCnt += thread.comments_aggregate.aggregate.count;
    }

    return (
      <Card
        className={classes.card}
        style={{ width: width, backgroundColor: classes.card.backgroundColor }}
      >
        <div className={classes.carddiv}>
          <CardHeader
            className={classes.header}
            avatar={
              <div>
                <Avatar
                  alt={source.name}
                  src={source.thumbnail || source.image || RAVEN_PLACEHOLDER_IMAGE}
                  className={classes.avatar}
                  classes={{ img: classes.avatar_img }}
                  onClick={(e) => onClickSource(source, feed)}
                />
                <img
                  alt={branchOfArticle.name}
                  src={`/static/images/icons/${theme_mode}/${branchOfArticle.image}`}
                  className={classes.socialimg}
                />
              </div>
            }
            title={
              <div>
                <Typography
                  className={classes.sourcename}
                  onClick={(e) => onClickSource(source, feed)}
                >
                  {source.name}
                </Typography>
                <Typography
                  className={classes.title}
                  variant="subtitle2"
                  onClick={(e) => handleClick(article)}
                >
                {render_text(title, theme_mode === THEME_MODE_DARK)}
                </Typography>

                  <Typography
                    className={classes.feed}
                    onClick={(e) => onClickFeed(feed)}
                  >
                    {feed.name}
                  </Typography>

              </div>
            }
            subheader={
              <div
                className={classes.subtitle}
                onClick={(e) => handleClick(article)}
              >
                <img
                  alt={country.name}
                  className={classes.country_img}
                  src={country.flag}
                />
                <Typography className={classes.subtitle_left}>
                  {country.name}
                </Typography>
                <Typography className={classes.subtitle_right} data-cy="newsTime" data-cy-time={article.published}>
                  {newsTime}
                </Typography>
              </div>
            }
          />

          <div>
            <IconButton className={classes.upvotebtn} onClick={onClickUpvote}>
              <img
                alt={"upvote"}
                src={
                  voted
                    ? `/static/images/icons/thumbsup.png`
                    : `/static/images/icons/${theme_mode}/thumbsup.png`
                }
                className={classes.actionimg}
              />
            </IconButton>
            {article.upvotes > 0 && (
              <Typography
                className={classes.upvotetxt}
                style={{ left: 24 - 4 * article.upvotes.toString().length }}
              >
              {article.upvotes}
              </Typography>
            )}
          </div>
          <div>
            {article.pinned !== undefined && (
              <img
                alt={"pin"}
                className={classes.pin}
                src={`/static/images/icons/${theme_mode}/pin.png`}
              />
            )}
            <IconButton
              className={classes.share}
              onClick={this.handleClickShare}
            >
              <ShareIcon className={classes.shareicon} />
            </IconButton>
            <IconButton className={classes.expand} onClick={this.handleExpand}>
              <ExpandMoreIcon />
            </IconButton>
          </div>
          {(summary || hasImage) && article.branch !== ARTICLE_BRANCH_PODCAST && (
            <CardContent
              className={classes.content}
              onClick={(e) => handleClick(article)}
            >
              <div>
                <Typography className={classes.summary} variant="body2">
                  {hasImage && !preview && (
                    <LazyLoadImage
                      alt={""}
                      src={article_image}
                      className={classes.media}
                    />
                  )}
                  {render_text(summary, theme_mode === THEME_MODE_DARK)}
                </Typography>
              </div>
            </CardContent>
          )}
          {article.branch === ARTICLE_BRANCH_PODCAST && (<CardContent className={classes.content} onClick={e => handleClick(article)}>
            <Typography className={classes.detail_txt} variant="body2">
              {render_text(summary, theme_mode === THEME_MODE_DARK)}
            </Typography>
          </CardContent>)}
          {preview !== null && <LinkPreview preview={preview} />}
          {preview === null && article.branch === ARTICLE_BRANCH_PODCAST && (
            <div>
              <audio
                className={classes.podcast_media}
                name={"media"}
                controls={true}
                controlsList={"nofullscreen nodownload"}
              >
                <source src={article.media_url} type={"audio/mpeg"} />
              </audio>
            </div>
          )}
          <CardActions className={classes.actions}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
              >
                {sentiment ?
                  (<div style={{ marginLeft: 44 }}>
                    <Typography className={classes.biasBanner} style={{ color: bannerColor }}>{`${wingBias === 'Balanced' ? 'Balanced' : `${sentiment.Score}% ${wingBias}`}`}</Typography>
                  </div>) : null}
              </Grid>
              <Grid item>
                {source.translate === true ? (
                  <div className={classes.translated_div}>
                    <StarIcon className={classes.traslated_icon} />
                    <Typography className={classes.translated_txt}>
                      {transMark}
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.translated_div}></div>
                )}
              </Grid>
              <Grid item>
                <div style={{ marginRight: 16 }}>
                  <IconButton
                    className={classes.repostbtn}
                    onClick={onClickRepost}
                  >
                    <img
                      alt={"repost"}
                      src={
                        reposted
                          ? `/static/images/icons/repost.png`
                          : `/static/images/icons/${theme_mode}/repost.png`
                      }
                      className={classes.actionimg}
                    />
                  </IconButton>
                  <IconButton
                    className={classes.commentbtn}
                    onClick={onClickComment}
                  >
                    <img
                      alt={"comment"}
                      src={`/static/images/icons/${theme_mode}/chat.png`}
                      className={classes.actionimg}
                    />
                  </IconButton>
                  {commentsCnt > 0 && (
                    <span
                      className={classes.comment}
                    >{`${commentsCnt} Comments`}</span>
                  )}
                </div>
              </Grid>
            </Grid>
          </CardActions>
        </div>
      </Card>
    );
  }
}

SearchResultCard.propTypes = {
  classes: PropTypes.object,
  search_mode: PropTypes.bool,
  article: PropTypes.object,
  sourceDetails: PropTypes.object,
  handleClick: PropTypes.func,
  onClickSource: PropTypes.func,
  onClickFeed: PropTypes.func,
  onAnchorEl: PropTypes.func,
  onShare: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  selected_feed: state.dataState.selected_feed,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SearchResultCard));
