import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Grid, Button } from "@material-ui/core";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  card: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  content: {
    padding: 4,
    paddingBottom: 8,
    marginLeft: 10,
    marginRight: 10,
    "&:last-child": {
      paddingBottom: 0
    }
  },
  text: {
    display: "inline",
    fontFamily: "Merriweather, serif",
    fontSize: "14px",
    lineHeight: "14px",
    color: theme.palette.text.secondary
  },
  actionbtn: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 12,
    marginRight: 5,
    textTransform: "inherit",
    color: theme.palette.text.secondary
  }
});

class NewsContent extends React.Component {
  render() {
    const { classes, article, source, content_type } = this.props;

    let content = "";
    switch (content_type) {
      case 0:
        content = article.summarized_text;
        break;

      case 1:
        if (source.translate && article.translated) {
          content = article.tr_text;
        } else {
          content = article.text;
        }
        break;

      case 2:
        content = article.text;
        break;

      default:
        content = article.summarized_text;
        break;
    }

    const sentences = content.split("\n");
    const real_sentences = sentences
      .map(sentence => {
        let real_sentence = sentence.trim();
        if (real_sentence && real_sentence !== ".") {
          return real_sentence;
        } else {
          return "";
        }
      })
      .filter(sentence => sentence.trim() !== "");

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          {real_sentences.length > 0 &&
            real_sentences.map(sentence => (
              <CardContent className={classes.content}>
                <Typography className={classes.text}>{sentence}</Typography>
              </CardContent>
            ))}
        </Card>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          direction="row"
        >
          <Button
            className={classes.actionbtn}
            size="small"
            color="primary"
            target="_blank"
            href={article.url}
          >
            Read original article
          </Button>
        </Grid>
      </div>
    );
  }
}

NewsContent.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
  source: PropTypes.object,
  content_type: PropTypes.number
};

export default withStyles(styles)(NewsContent);
