import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { withFirebase } from 'services';
import { withAuthentication } from "session";
import { ToastContainer } from "react-toastify";
import {
  BasicAppBar,
  WaitingSpinner,
} from "components";
import {
  PodcastList
} from './components';
import {
  BRANCH_ALL,
  ARTICLE_BRANCH_NEWSPAPER,
  ARTICLE_BRANCH_PODCAST,
} from "constants/branches";
import * as ROUTES from "constants/routes";
import { ALL } from "constants/country";
import {
  MAX_ARTICLE_WIDTH,
  TAB_FEED,
} from "constants/types";
import { GraphqlService } from "services";
import { 
  getMainInfo,
  reportArticle,
  saveArticle,
} from "dataapis";
import { build_source_link } from "utility/buildlink";
import { is_source_alive } from "utility/utils";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 10,
  },
  container: {
    marginTop: theme.spacing(1),
    margin: 0,
  },
  feedscontainer: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    margin: theme.spacing(2),
  },
  feeds_title: {
    fontSize: 14,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  articlecontainer: {
    padding: theme.spacing(1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.default,
  },
});

class Podcasts extends React.Component {
  constructor(props) {
    super(props);

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleNeedMore = this.handleNeedMore.bind(this);

    this.handleClickSource = this.handleClickSource.bind(this);
    this.handleClickSourceInArticle = this.handleClickSourceInArticle.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);

    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  async componentDidMount() {
    this.setWaiting(true);

    await getMainInfo();

    // load podcasts
    if (this.props.podcasts.length === 0) {
      this.props.refreshPodcasts();
      await this.getPodcastsOfFirstPage();
    }

    this.setWaiting(false);
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  getPodcastSourceIds2show = () => {
    const {
      loggedIn,
      default_feeds,
      followed_feeds,
      authUser,
    } = this.props;

    let feeds = default_feeds;
    if (loggedIn && followed_feeds.length > 0) {
      feeds = followed_feeds.slice();
    }

    let articles_no = 0;
    let source_ids = [];
    for (let feed of feeds) {
      if (loggedIn && authUser.feed_sources_unfollowed.length > 0) {
        let feed_sources = feed.feed_sources
          .filter(
            (feed_source) =>
              feed_source.approved && 
              feed_source.source.branch === ARTICLE_BRANCH_PODCAST &&
              is_source_alive(feed_source.source) &&
              authUser.feed_sources_unfollowed.findIndex(
                (unfollowed) =>
                  unfollowed.feed_id === feed.id &&
                  unfollowed.source_id === feed_source.source_id
              ) === -1
          );

        for (let feed_source of feed_sources) {
          if (source_ids.indexOf(feed_source.source_id) === -1) {
            source_ids.push(feed_source.source_id);
            articles_no += feed_source.source.articles_no;
          }
        }
      } else {
        let feed_sources = feed.feed_sources
          .filter((feed_source) => 
            feed_source.approved && 
            feed_source.source.branch === ARTICLE_BRANCH_PODCAST &&
            is_source_alive(feed_source.source)
          );

        for (let feed_source of feed_sources) {
          if (source_ids.indexOf(feed_source.source_id) === -1) {
            source_ids.push(feed_source.source_id);
            articles_no += feed_source.source.articles_no;
          }
        }
      }
    }

    return {
      source_ids,
      articles_no
    };
  };

  getPodcastsOfFirstPage = async () => {
    const { source_ids, articles_no } = this.getPodcastSourceIds2show();
    if (source_ids.length === 0) {
      return;
    }
    await this.getPodcastArticlesOfFirstPage(source_ids, articles_no);
  };

  handleNeedMore = async () => {
    const { source_ids, articles_no } = this.getPodcastSourceIds2show();
    if (source_ids.length === 0) {
      return;
    }
    this.setWaiting(true);
    await this.getPodcastArticlesOfNextPage(source_ids, articles_no, this.props.podcast_last_offset);
    this.setWaiting(false);
  };

  getPodcastArticlesOfFirstPage = async (source_ids, articles_no) => {
    const gqlservice = new GraphqlService();
    await gqlservice
      .articles_in_sources(source_ids, articles_no, 0)
      .then(
        (result) => {
          const podcasts = result.data;
          logger.log("Podcasts => podcast articles in sources(first page) :", podcasts);

          this.props.setPodcasts(podcasts, podcasts.length);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getPodcastArticlesOfNextPage = async (source_ids, articles_no, last_offset) => {
    const gqlservice = new GraphqlService();
    await gqlservice
      .articles_in_sources(source_ids, articles_no, last_offset)
      .then(
        (result) => {
          const podcasts = result.data;
          logger.log("Podcasts => podcast articles in sources(next page) :", podcasts);

          if (podcasts.length === 0) {
            this.props.appendPodcasts(podcasts, last_offset);
          } else {
            this.props.appendPodcasts(podcasts, last_offset + podcasts.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleNavBack = () => {
    const { selected_feed, selected_list } = this.props;
    let route = "";
    if (selected_feed) {
      route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
    } else if (selected_list) {
      route = `/${ROUTES.LIST_PREFIX}/${selected_list.id}`;
    } else {
      route = ROUTES.HOME;
    }

    const location = {
      pathname: route,
      state: { animation: "right" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.selectSource(null);
  };

  handleClickSource = () => {
    const { selected_source } = this.props;

    // source link
    let social_link = null;
    const socialtags = selected_source.socialtags;
    if (
      selected_source.branch !== ARTICLE_BRANCH_NEWSPAPER &&
      selected_source.socialtags.length > 0
    ) {
      const socialtag = socialtags[0];
      if (socialtag.tag.includes("http:") || socialtag.tag.includes("https:")) {
        social_link = socialtag.tag;
      } else {
        social_link = build_source_link(socialtag.type, socialtag.tag);
      }

      window.open(social_link, "_blank");
    }
  };

  handleClickSourceInArticle = (source, feed) => {
    this.props.selectSource(source);
    this.props.setSourceBackRoute(ROUTES.PODCASTS);
    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  };

  // empty funciton
  handleDeleteSavedArticle = (article) => {};
  // empty function

  handleSelectFeed = (feed) => {
    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickFeed = (feed) => {
    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleSelectArticle = (article) => {
    this.props.selectArticle(article);

    const { selected_feed, sources } = this.props;
    const article_source = sources.find(
      (source) => source.id === article.source_id
    );

    logger.log("handleSelect Podcast :", article, article_source);

    let sourcePath = "";
    if (selected_feed !== null) {
      sourcePath = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${article_source.slug}`;
    } else {
      sourcePath = `/${ROUTES.CATEGORY_PREFIX}/${article_source.category_id}/${ROUTES.SOURCE_PREFIX}/${article_source.slug}`;
    }
    let articlePath = "";
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      articlePath = sourcePath + `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      articlePath = sourcePath + `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }
    const location = {
      pathname: articlePath,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.PODCASTS);
  };

  handleSelectGroupArticle = async (nid) => {};

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleReportArticle = async (article, reportMsg) => {
    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };

  getFeedsBySource = (feeds, source) => {
    var sub_feeds = [];
    if (feeds.length === 0) {
      return sub_feeds;
    }
    feeds.forEach((feed) => {
      const sources = feed.feed_sources.map(
        (feed_source) => feed_source.source_id
      );
      if (sources.includes(source.id)) {
        sub_feeds.push(feed);
      }
    });
    return sub_feeds;
  };

  render() {
    const {
      classes,
      podcasts,
      requesting,
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Podcasts"}
            width={MAX_ARTICLE_WIDTH}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.articlecontainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <PodcastList
                podcasts={podcasts}
                onNeedMore={this.handleNeedMore}
                onSelectArticle={this.handleSelectArticle}
                onClickSource={this.handleClickSourceInArticle}
                onClickFeed={this.handleClickFeed}
              />
            </Grid>
          </Grid>
        </div>
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

Podcasts.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  feeds: state.dataState.feeds,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  default_feeds: state.dataState.default_feeds,
  followed_feeds: state.dataState.followed_feeds,
  podcasts: state.dataState.podcasts,
  podcast_last_offset: state.dataState.podcast_last_offset,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Podcasts);
