import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const styles = theme => ({
  root: {
    width: 112,
    height: 112,
    borderRadius: 16,
  },
  root_highlight: {
    position: "relative",
    width: 112,
    height: 112,
    borderRadius: 16,
    backgroundColor: "#F29900",
  },
  cherryimage: {
    position: "absolute",
    top: -4,
    left: 96,
    width: 24,
    height: 24,
  },
  image: {
    objectFit: "cover",
    padding: 6,
    height: 112,
    width: 112,
    borderRadius: 16,
  },
});

class FeedImage extends React.Component {
  render() {
    const { classes, image, title, highlight } = this.props;

    const root_class = highlight ? classes.root_highlight : classes.root;
    return (
      <div className={root_class}>
        <LazyLoadImage 
          className={classes.image} 
          src={image} 
          alt={title} 
        />
        {highlight &&
          <img className={classes.cherryimage} 
            src={"/static/images/icons/cherry-blossom.png"}
            alt={"cherry"}
          />
        }
      </div>
    );
  }
}

FeedImage.propTypes = {
  classes: PropTypes.object,
  image: PropTypes.string,
  title: PropTypes.string,
  highlight: PropTypes.bool,
};

export default withStyles(styles)(FeedImage);
