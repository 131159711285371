import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux"; 
import { withFirebase } from 'services';
import { ActionCreators } from "actions";
import { withAuthentication } from "session";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import {
  BasicAppBar,
  ArticleList,
  ArticleMasonry 
} from "components"; 
import { 
  GraphqlService,
  createCategorySubscriber, 
  createFeedSubscriber,
  // createSourceSubscriber,
  createNotificationSubscriber,
  Mixpanel
} from "services";
import {  
  BRANCH_ALL,
  ARTICLE_BRANCH_NEWSPAPER
} from "constants/branches";
import { 
  TAB_FEED,
  MIN_TABLET_WIDTH,
  MAX_WINDOW_WIDTH,
} from "constants/types";
import { ALL } from "constants/country";
import * as ROUTES from "constants/routes";
import * as MIXPANEL_EVENTS from "constants/mixpanel";
import { 
  deleteArticle,
  getArticle,
  getFeedofSource,
  reportArticle,
  repostArticle,
  saveArticle,
  upvoteArticle,
} from "dataapis";
import { ToastError } from "utility/toast"; 
import { logger } from "utility/logging";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
}); 

class SimilarArticlesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceImg: '',
      sourceName: '',
      similarArticlesData: []
    }
    this.categoryConsumer = null;
    this.feedConsumer = null;
    this.sourceConsumer = null;
    this.notificationConsumer = null;

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleNeedMore = this.handleNeedMore.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);

    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);
  }

  componentDidMount = async () => {
    const nid = this.props.match.params.id;
     this.getClusters(nid)
    //debugger;
    //this.setWaiting(true);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleNavBack = () => {
    this.props.history.goBack();
  };

  getClusters = async (nid) => {
    this.setWaiting(true);
    const gqlservice = new GraphqlService();
    await gqlservice
      .cluster_members_by_article(nid)
      .then(
        result => {
          const articles = result.data.home_clusters.map((members) => members.article);
          this.setState({
            ...this.state,
            similarArticlesData: articles
          });
          //const articles = result.data.articles;
          // if (articles.length > 0) {
          //   this.props.selectArticle(articles[0]);
          // }
        },
        reason => {
          this.setError(reason.msg);
        }
      )
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
    
      this.setWaiting(false);
  } 

  handleNeedMore = () => { }

  handleDeleteSavedArticle = (article) => { }

  handleSelectArticle = (article) => { 
    this.props.selectArticle(article);
    const properties ={
      author : article.author,
      source_id: article.source_id,
      title:article.title,
      url:article.url,
      nid:article.nid
    }
    Mixpanel.track(MIXPANEL_EVENTS.ARTICLE_DETAILS,properties); 
    const { sources } = this.props;
    const article_source = sources.find(
      (source) => source.id === article.source_id
    );

    const feed = getFeedofSource(article.source_id);
    // only for commenting, so don't delete articles from redux
    this.props.selectFeedforComments(feed);

    let path = `/${ROUTES.CATEGORY_PREFIX}/${article_source.category_id}/${ROUTES.SOURCE_PREFIX}/${article_source.slug}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setArticleBackRoute(ROUTES.HOME);
  };

  unregisterSubscribers = () => {
    if (this.categoryConsumer) {
      this.categoryConsumer.unsubscribe();
    }
    if (this.feedConsumer) {
      this.feedConsumer.unsubscribe();
    }
    if (this.sourceConsumer) {
      this.sourceConsumer.unsubscribe();
    }
    if (this.notificationConsumer) {
      this.notificationConsumer.unsubscribe();
    }
  }

  registerSubscribers = (loggedIn) => {
    const categorySubscriber = createCategorySubscriber(loggedIn);
    this.categoryConsumer = categorySubscriber.subscribe(data => {
      const categories = data.data.categories;
      logger.log("Categories updated :", categories);
      this.props.setCategories(categories);
    }, (err) => {
      let msg = "Error subscribing categories: " + err;
      logger.error(msg);
    });

    const feedSubscriber = createFeedSubscriber(loggedIn);
    this.feedConsumer = feedSubscriber.subscribe(data => {
      const feeds = data.data.feeds;
      logger.log("Feeds updated :", feeds);      
      this.props.setFeeds(feeds);
    }, (err) => {
      let msg = "Error subscribing feeds: " + err;
      logger.error(msg);
    });

    if (loggedIn) {
      const { authUser} = this.props;
      const notificationSubscriber = createNotificationSubscriber(authUser.uid);
      this.notificationConsumer = notificationSubscriber.subscribe(data => {
        const notifications = data.data.notifications;
        logger.log("Notifications updated :", notifications);      
        this.setState({
          ...this.state,
          notifications: notifications
        });
      }, (err) => {
        let msg = "Error subscribing notifications: " + err;
        logger.error(msg);
      });
    }
  };

  goTo = (location) => {
    this.unregisterSubscribers();
    this.props.history.push(location);
  }

  handleSelectGroupArticle = async (nid) => {
    this.setWaiting(true);
    await getArticle(nid);
    this.setWaiting(false);

    const location = {
      pathname: `/${ROUTES.ARTICLE_NEWS_PREFIX}/${nid}`,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setArticleBackRoute(ROUTES.HOME);
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.goTo(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleReportArticle = async (article, reportMsg) => {
    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };

  handleEditArticle = (article) => {
    this.setState({
      ...this.state,
      postDlg: true,
      article_edit: article
    });
  }

  handleDeleteArticle = async (article) => {
    this.setWaiting(true);
    await deleteArticle(article);
    this.setWaiting(false);
  }

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  }

  handleClickSource = (source, feed) => {
    const properties = {
      sourceId: source.id,
      name: source.name
    }

    Mixpanel.track(MIXPANEL_EVENTS.FEED_SOURCE_DETAILS, properties);
    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);
  }

  handleClickFeed = (feed) => {
    const properties = { id: feed.id, name: feed.name, slug: feed.slug };
    Mixpanel.track(MIXPANEL_EVENTS.FEED_DETAILS, properties);
    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // Go to moderation page if the logged user is the moderator of the feed
    if (this._isFeedModerator(feed)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.setFeedBackRoute(ROUTES.HOME);
    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickSource = (source, feed) => {
    const properties = {
      sourceId: source.id,
      name: source.name
    }

    Mixpanel.track(MIXPANEL_EVENTS.FEED_SOURCE_DETAILS, properties);
    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);
  }

  handleClickUpvote = async (article) => {
    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  }

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  }

  handleClickRepost = async (article) => {
    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      similarArticlesData
    } = this.state;

    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;

    const isDesktop = width > MAX_WINDOW_WIDTH;
    const isTablet =
      width >= MIN_TABLET_WIDTH &&
      width <= MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;
    const pins2show = [];
    const movetops2show = [];

    return (
      <>
        <div className={classes.root}>
          <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_WINDOW_WIDTH}
            title={"More Articles"}
            onNavBack={this.handleNavBack}
          />
          </div>
          {isDesktop && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                <ArticleMasonry
                  width={1224}
                  articles={similarArticlesData}
                  pins={pins2show}
                  movetops={movetops2show}
                  onNeedMore={this.handleNeedMore}
                  onSelectArticle={this.handleSelectArticle}
                  onSelectGroupArticle={this.handleSelectGroupArticle}
                  onNeedLogin={this.handleLogin}
                  onReport={this.handleReportArticle}
                  onEdit={this.handleEditArticle}
                  onDelete={this.handleDeleteArticle}
                  onSave={this.handleSaveArticle}
                  onDeleteSaved={this.handleDeleteSavedArticle}
                  onClickSource={this.handleClickSource}
                  onClickFeed={this.handleClickFeed}
                  onClickUpvote={this.handleClickUpvote}
                  onClickComment={this.handleClickComment}
                  onClickRepost={this.handleClickRepost}
                />
              </Grid>
              
              
            </Grid>
          )}
          {isTablet && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                <ArticleMasonry
                  width={width >= 1224 ? 1224 : MIN_TABLET_WIDTH}
                  articles={similarArticlesData}
                  pins={pins2show}
                  movetops={movetops2show}
                  onNeedMore={this.handleNeedMore}
                  onSelectArticle={this.handleSelectArticle}
                  onSelectGroupArticle={this.handleSelectGroupArticle}
                  onNeedLogin={this.handleLogin}
                  onReport={this.handleReportArticle}
                  onEdit={this.handleEditArticle}
                  onDelete={this.handleDeleteArticle}
                  onSave={this.handleSaveArticle}
                  onDeleteSaved={this.handleDeleteSavedArticle}
                  onClickSource={this.handleClickSource}
                  onClickFeed={this.handleClickFeed}
                  onClickUpvote={this.handleClickUpvote}
                  onClickComment={this.handleClickComment}
                  onClickRepost={this.handleClickRepost}
                />
              </Grid>
               
            </Grid>
          )}
          {isMobile && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                <ArticleList
                  articles={similarArticlesData}
                  pins={pins2show}
                  movetops={movetops2show}
                  onNeedMore={this.handleNeedMore}
                  onSelectArticle={this.handleSelectArticle}
                  onSelectGroupArticle={this.handleSelectGroupArticle}
                  onNeedLogin={this.handleLogin}
                  onReport={this.handleReportArticle}
                  onEdit={this.handleEditArticle}
                  onDelete={this.handleDeleteArticle}
                  onSave={this.handleSaveArticle}
                  onDeleteSaved={this.handleDeleteSavedArticle}
                  onClickSource={this.handleClickSource}
                  onClickFeed={this.handleClickFeed}
                  onClickUpvote={this.handleClickUpvote}
                  onClickComment={this.handleClickComment}
                  onClickRepost={this.handleClickRepost}
                />
              </Grid>
            </Grid>
          )}
          <ToastContainer />
        </div>
      </>
    );
  }
}

SimilarArticlesList.propTypes = {
  classes: PropTypes.object, 
};
 

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  feeds: state.dataState.feeds,
  sources: state.dataState.sources,
  selected_category: state.dataState.selected_category,
  followed_feeds: state.dataState.followed_feeds,
  selected_feed: state.dataState.selected_feed,
  selected_list: state.dataState.selected_list,
  selected_source: state.dataState.selected_source,
  articles: state.dataState.articles,
  last_offset: state.dataState.last_offset,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
  source_backroute: state.uiState.source_backroute,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SimilarArticlesList);
