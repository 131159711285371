import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
  IconButton,
  Grid,
  Menu,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { v4 as uuidv4 } from 'uuid';
import {
  CommentUpdateBox,
  CommentEditBox,
  CommentChildCards,
  PopMenuCommentAuthor,
  PopMenuCommentUser,
  CommentModButtons,
  DlgLoginConfirm,
  DlgReport,
  DlgMemberTrial,
  DlgShareComment,
  PopMenuCommentMod
} from "components";
import { GraphqlService } from "services";
import { get_elapsed_time } from "utility/utils";
import { 
  ARTICLES_PER_PAGE, 
  GRAPHQL_SUCCESS, 
  FEED_COMMENT_UNMODERATED,
  // THREAD_TYPE_ARTICLE, 
  // THREAD_TYPE_FEED, 
  BANNED_TYPE_1D, 
  BANNED_TYPE_7D, 
  BANNED_TYPE_PERM,
  MAX_ARTICLE_WIDTH,
  FLAGGED_COMMENTS_POSTS,
  // MAX_CARD_WIDTH
} from "constants/types";
import { 
  NOTIFICATION_COMMENT_APPROVE, 
  NOTIFICATION_COMMENT_REPLY 
} from "constants/notification";
import { hasPaid } from "dataapis";
import { ToastError, ToastSuccess } from "utility/toast";
import { logger } from "utility/logging";
import * as ROUTES from "constants/routes";
import { withRouter } from "react-router";
import { get_branch_name } from "constants/branches";
import { isFlaggedModerationByAi } from "utility/ravenapi";
import moment from "moment/moment";
import { handleReportWithLimit } from "utility/reportlimit";
import { updateReportLimit } from "dataapis/user";
import parse from "html-react-parser";

const styles = (theme) => ({
  card: {
    position: "relative",
    maxWidth: MAX_ARTICLE_WIDTH,
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
  },
  cardframe: {
    margin: 0,
    borderLeftStyle: "solid",
    borderLeftColor: `${theme.palette.text.secondary}`,
    borderLeftWidth: 0,
  },
  carddiv: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    // borderRadius: 10,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 8,
    backgroundColor: theme.palette.feedbackground.default,
    // boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  carddiv_card: {
    marginBottom: theme.spacing(1),
    // borderRadius: 10,
    backgroundColor: theme.palette.background.article,
    // boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: 36,
    height: 36,
    cursor:'pointer'
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  authorname: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.primary,
    cursor:'pointer'
  },
  authorbio: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
    color: theme.palette.text.primary,
  },
  moderation: {
    marginTop: 4,
    marginLeft: theme.spacing(4),
  },
  modimage: {
    width: 20,
    height: 20,
    marginRight: 4,
  },
  modlabel: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  menu: {
    marginRight: theme.spacing(1),
  },
  timestamp: {
    paddingTop: theme.spacing(1),
    fontSize: "10px",
    lineHeight: "12px",
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  expand: {
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    marginRight: 4,
    color: theme.palette.text.primary,
  },
  content: {
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  text: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.text.primary,
  },
  action: {
    margin: 0,
    padding: 0,
  },
  actionbtn: {
    padding: 4,
    color: theme.palette.background.default,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionimg: {
    width: 16,
    height: 16,
  },
  actionvalue: {
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "none",
    color: theme.palette.text.secondary,
  },
});

const SORT_NEWEST = 0;
const SORT_OLDEST = 1;
const SORT_MOST_RECOMMENDED = 2;
const SORT_MOST_REPLIED = 3;

class CommentCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      children: [],
      approved: false,
      recommends: 0,
      last_offset: 0,
      collapsed: false,
      showEdit: false,
      showMore: false,
      showReply: false,
      loginDlg: false,
      memberDlg: false,
      reportDlg: false,
      shareDlg: false,
      anchorEl: null,
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);

    this.handleRecommend = this.handleRecommend.bind(this);
    this.handleReply = this.handleReply.bind(this);
    this.handleSubmitChild = this.handleSubmitChild.bind(this);

    this.handleEditComment = this.handleEditComment.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);

    this.handleUpdateComment = this.handleUpdateComment.bind(this);
    this.handleUpdateChild = this.handleUpdateChild.bind(this);

    this.handleDeleteComment = this.handleDeleteComment.bind(this);
    this.handleDeleteChild = this.handleDeleteChild.bind(this);

    this.handleApproveComment = this.handleApproveComment.bind(this);
    this.handleBan1d = this.handleBan1d.bind(this);
    this.handleBan7d = this.handleBan7d.bind(this);
    this.handleBanPerm = this.handleBanPerm.bind(this);

    this.handleShareComment = this.handleShareComment.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.handleMemberTrial = this.handleMemberTrial.bind(this);
    this.handleCloseMemberTrial = this.handleCloseMemberTrial.bind(this);

    this.showReportDlg = this.showReportDlg.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleCancelReport = this.handleCancelReport.bind(this);

    this.handleCollapse = this.handleCollapse.bind(this);
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  componentDidMount = () => {
    const { comment, collapsed } = this.props;
    this.setState({
      ...this.state,
      children: comment.children === undefined ? [] : comment.children,
      approved: comment.approved,
      last_offset: 0,
      recommends: comment.recommends,
      collapsed: collapsed
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.sortType !== this.props.sortType ||
      prevProps.collapsed !== this.props.collapsed ||
      (prevProps.comment.children === undefined && this.props.comment.children !== undefined)) {
      logger.log("CommentCard sortType changed :", this.props.comment.children);
      const { comment } = this.props;
      this.setState({
        ...this.state,
        children: comment.children === undefined ? [] : comment.children,
        approved: comment.approved,
        last_offset: 0,
        recommends: comment.recommends,
        collapsed: this.props.collapsed,
      });
    }
  }

  handleExpand = (event) => {
    logger.log("commentcard handleExpand :", event.currentTarget);
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleRecommend = async (event) => {
    if (!this.props.loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
        anchorEl: null
      });
      return;
    }

    const { comment, authUser } = this.props;
    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token);

    const recommender_result = await gqlservice.comment_recommender(comment.id, authUser.uid);
    if (recommender_result.status_code !== GRAPHQL_SUCCESS) {
      this.setError("Error getting comment recommender");
      return;
    }

    const recommended = recommender_result.data.comment_recommenders.length > 0;
    let comment_recommend_func = null;
    if (recommended) {
      comment_recommend_func = gqlservice.comment_dec_recommends;
    } else {
      comment_recommend_func = gqlservice.comment_inc_recommends;
    }

    this.setWaiting(true);

    gqlservice.set_auth_jwt(token, true);
    comment_recommend_func(comment.id, authUser.uid)
    .then(
      (result) => {
        let updated_recommends = result.data.update_comments.returning[0].recommends;
        this.setState({
          ...this.state,
          recommends: updated_recommends
        }, () => {
          this.forceUpdate();
        });
        
        let followedCommentsFromProps = this.props.followedComments
        const recommendedChanged = followedCommentsFromProps.map((comment)=>{
          if(comment.id === this.props.comment.id){
            comment.recommends = updated_recommends
          }
          return comment
        })
        this.props.setFollowedComments(recommendedChanged)
      },
      (reason) => {
        this.setError(reason.msg);
        this.setWaiting(false);
      }
    )
    .catch((err) => {
      this.setError(JSON.stringify(err));
      this.setWaiting(false);
    });
    
    this.setWaiting(false);
  };

  handleReply = (event) => { 
   const { selected_feed } = this.props;
    if(selected_feed){
      if (!selected_feed.op_payment && !hasPaid()) {
        this.setState({
          ...this.state,
          memberDlg: true
        });
        return;
      } 
    }
    else{
      if (!hasPaid()) {
        this.setState({
          ...this.state,
          dlgMember: true
        });
        return;
      }
    } 
    
    // if (authUser.uid === comment.author.uid) {
    //   this.setError("Can't comment to self comment.");
    //   return;
    // }

    const { showReply } = this.state;
    this.setState({
      showReply: !showReply,
    });
  };

  _isFeedModerator = (feed) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn || !feed) {
      return false;
    }

    // check if the user is the creator of this feed
    if (authUser.feeds_created.find(
      (item) => item.id === feed.id
    )) {
      return true;
    };

    // check if the user is a moderator of the category the feed is created from
    if (authUser.categories_moderated.find(
      (moderator) =>
        moderator.approved && moderator.category_id === feed.category_id
    )) {
      return true;
    };

    // check if the user is a moderator of this feed
    if (authUser.feeds_moderated.find(
      (moderator) => moderator.approved && moderator.feed_id === feed.id
    )) {
      return true;
    };
    return false;
  };
  
  handleSubmitChild = async (comment_text) => {
    const { authUser, sortType, comment, selected_feed } = this.props;
    if(comment.reading_id){
      if (!hasPaid()) {
        this.setState({
          ...this.state,
          memberDlg: true
        });
        return;
      } 
    }else{
      if (!selected_feed.op_payment && !hasPaid()) {
        this.setState({
          ...this.state,
          memberDlg: true
        });
        return; 
      }
    } 
    

    // check if the user is pre-approved for this selected feed
    let approved = false;
    if(comment.reading_id){ 
      approved = true;
    }else{ 
      const approved_user = authUser.preapproved.find(item => item.feed_id === selected_feed.id);
      approved = approved_user !== undefined;
      approved = approved || !selected_feed.private || this._isFeedModerator(selected_feed);
  
    }
    
    const child_comment = {
      parent_id: comment.id,
      text: comment_text,
      author_id: authUser.uid,
      article_id: comment.article_id,
      reading_id: comment.reading_id,
      thread_id: comment.thread_id,
      approved: approved,
      approved_by: approved ? authUser.uid : null,
      approved_at: approved ? new Date().toISOString() : null
    };

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token);

    let inserted_child = null;
    this.setWaiting(true);
    await gqlservice
      .insert_comment(child_comment)
      .then(
        (result) => {
          inserted_child = result.data.insert_comments.returning[0];
          logger.log("inserted child comment :", inserted_child);

          let children = [];
          if (sortType === SORT_NEWEST) {
            children = [inserted_child].concat(this.state.children);
          } else {
            children = this.state.children.concat([inserted_child]);
          }

          this.setState({
            ...this.state,
            children: children,
            showReply: false,
            collapsed: false,
          }, () => {
            this.forceUpdate();
          });
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });

    if (child_comment.thread_id !== null && approved) {
      // insert notification
      const notification = {
        type: NOTIFICATION_COMMENT_REPLY,
        object: child_comment.thread_id.toString(),
        in_which: selected_feed.id,
        to: comment.author_id,
        created_by: authUser.uid
      }

      gqlservice.set_auth_jwt(token, false);
      await gqlservice
        .insert_notification(notification)
        .then(result => {}, reason => {
          this.setError(reason.msg);
        })
        .catch(err => {
          this.setError(JSON.stringify(err));
        });
    }

    this.setWaiting(false);
  };

  handleEditComment = () => {
    this.setState({
      ...this.state,
      showEdit: true,
      anchorEl: null,
    });
  };

  handleCancelEdit = () => {
    this.setState({
      ...this.state,
      showEdit: false,
    });
  };

  handleUpdateComment = async (comment_text) => {
    const { comment } = this.props;

    const currentTime = new Date().toISOString();
    
    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);
    gqlservice
      .update_comment(comment.id, comment_text, currentTime)
      .then(
        (result) => {
          let updated_comment = result.data.update_comments.returning[0];
          logger.log("updated comment :", updated_comment);
          
          this.props.onUpdate(comment.id, comment_text, currentTime);
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });
    this.setWaiting(false);

    this.setState({
      ...this.state,
      showEdit: false
    });
  };

  handleUpdateChild = async (child_id, child_text, update_time) => {
    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);
    gqlservice
      .update_comment(child_id, child_text, update_time)
      .then(
        (result) => {
          let updated_child = result.data.update_comments.returning[0];
          logger.log("updated child :", updated_child);
          
          const children = this.state.children.map(child => {
            if (child.id === child_id) {
              const new_child = {
                ...child,
                text: child_text,
                modified_at: update_time
              }
              return new_child;
            } else {
              return child;
            }
          });

          this.setState({
            ...this.state,
            children: children,
          }, () => {
            this.forceUpdate();
          });
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });
    this.setWaiting(false);

    this.setState({
      ...this.state,
      showEdit: false
    });
  }

  handleDeleteComment = async () => {
    const { comment } = this.props;

    this.setState({
      ...this.state,
      anchorEl: null
    });

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token, true);

    gqlservice
      .delete_comment(comment.id)
      .then(
        (result) => {
          logger.log("comment delete itself :", comment);
          this.props.onDelete(comment);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleDeleteChild = async (child) => {
    this.setState({
      ...this.state,
      anchorEl: null
    });

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token, true);

    gqlservice
      .delete_comment(child.id)
      .then(
        (result) => {
          logger.log("child comment delete itself :", child);
          const children = this.state.children.filter(item => item.id !== child.id);
          this.setState({
            ...this.state,
            children: children
          });
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleApproveComment = async () => {
    const { authUser, comment, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token, true);

    const currentTime = new Date().toISOString();
    await gqlservice.approve_comment(comment.id, true, authUser.uid, currentTime)
      .then(result => {
        this.setState({
          ...this.state,
          approved: true
        });
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // insert notification
    const notification = {
      type: NOTIFICATION_COMMENT_APPROVE,
      object: comment.id.toString(),
      in_which: selected_feed.id,
      to: comment.author_id,
      created_by: authUser.uid
    }

    gqlservice.set_auth_jwt(token, false);
    await gqlservice
      .insert_notification(notification)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  handleBan1d = async (thread) => {
    this.handleCloseMenu();
    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token, true);

    const is_banned = selected_feed.banned_users.find(banned => banned.user_id === thread.poster.uid) !== undefined;
    let fnBan = is_banned ? gqlservice.update_banned_user : gqlservice.insert_banned_user;

    const now = Date.now();
    const banned_at = new Date(now).toISOString();
    const banned_till = new Date(now + 86400*1000).toISOString();
    
    const banned = {
      user_id: thread.poster.uid,
      feed_id: selected_feed.id,
      banned_at: banned_at,
      banned_till: banned_till,
      type: BANNED_TYPE_1D,
      banned_by: authUser.uid
    };

    fnBan(banned)
      .then(result => {
        const new_banned = is_banned ? 
          result.data.update_users_banned.returning[0] :
          result.data.insert_users_banned.returning[0];
        this.props.insertBannedUser(new_banned);
        this.setAlert(`User ${thread.poster.username} banned for a day!`);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  handleBan7d = async (thread) => {
    this.handleCloseMenu();
    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token, true);

    const is_banned = selected_feed.banned_users.find(banned => banned.user_id === thread.poster.uid) !== undefined;
    let fnBan = is_banned ? gqlservice.update_banned_user : gqlservice.insert_banned_user;

    const now = Date.now();
    const banned_at = new Date(now).toISOString();
    const banned_till = new Date(now + 86400*7*1000).toISOString();
    
    const banned = {
      user_id: thread.poster.uid,
      feed_id: selected_feed.id,
      banned_at: banned_at,
      banned_till: banned_till,
      type: BANNED_TYPE_7D,
      banned_by: authUser.uid
    };

    fnBan(banned)
      .then(result => {
        const new_banned = is_banned ? 
          result.data.update_users_banned.returning[0] :
          result.data.insert_users_banned.returning[0];
        this.props.insertBannedUser(new_banned);
        this.setAlert(`User ${thread.poster.username} banned for 7 days!`);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  handleBanPerm = async (thread) => {
    this.handleCloseMenu();
    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token, true);

    const is_banned = selected_feed.banned_users.find(banned => banned.user_id === thread.poster.uid) !== undefined;
    let fnBan = is_banned ? gqlservice.update_banned_user : gqlservice.insert_banned_user;

    const now = Date.now();
    const banned_at = new Date(now).toISOString();
    const banned_till = new Date(now + 86400*36500*1000).toISOString();
    
    const banned = {
      user_id: thread.poster.uid,
      feed_id: selected_feed.id,
      banned_at: banned_at,
      banned_till: banned_till,
      type: BANNED_TYPE_PERM,
      banned_by: authUser.uid
    };

    fnBan(banned)
      .then(result => {
        const new_banned = is_banned ? 
          result.data.update_users_banned.returning[0] :
          result.data.insert_users_banned.returning[0];
        this.props.insertBannedUser(new_banned);
        this.setAlert(`User ${thread.poster.username} banned permanently!`);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  handleShareComment = () => {
    this.setState({
      ...this.state,
      shareDlg: true,
      anchorEl: null,
    });
  };

  handleShare = () => {};

  handleCloseShare() {
    this.setState({
      ...this.state,
      shareDlg: false,
    });
  }

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
    this.props.onNeedLogin();
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  handleMemberTrial = () => {
    this.setState({
      ...this.state,
      memberDlg: false,
    });
    this.props.onMemberTrial();
  }

  handleCloseMemberTrial = () => {
    this.setState({
      ...this.state,
      memberDlg: false,
    });
  }

  showReportDlg = () => {
    const { loggedIn } = this.props;

    if (loggedIn) {
      this.setState({
        ...this.state,
        reportDlg: true,
        loginDlg: false,
        anchorEl: null,
      });
    } else {
      this.setState({
        ...this.state,
        reportDlg: false,
        loginDlg: true,
        anchorEl: null,
      });
    }
  };

  handleReport = async (reportMsg) => {
    const message = reportMsg.trim();
    if (!message) {
      this.setError("Report shouldn't be blank. Please input your report.");
      return;
    }

    const { authUser, comment ,selected_feed } = this.props;
    
    let report = {
      id: uuidv4(),
      comment_id: comment.id,
      report: message,
      reported_by: authUser.uid
    };

    //reportlimit
      const updatedAuthUser = await handleReportWithLimit(authUser);
      if(!updatedAuthUser){
        ToastError("Report limit exceeded. Please try again later.");
        return null
      }

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token);
    const isAiModerationEnabled = JSON.parse(selected_feed.ai_moderation) === null ? false :JSON.parse(selected_feed.ai_moderation).some((item)=> item === FLAGGED_COMMENTS_POSTS)
    // const isAiModerationEnabled =true
    if(isAiModerationEnabled){
      const topSources = this.getTopSources()
      const currentDate = moment(); // Get the current date and time
      const date90DaysAgo = currentDate.subtract(90, 'days'); // Subtract 90 days from the current date
      console.log(date90DaysAgo.toISOString());
      const reportCount = await gqlservice.comment_reports_count(authUser.uid ,date90DaysAgo.toISOString())
      const params ={
        name: selected_feed.name,
        description: selected_feed.description,
        userName: authUser.name,
        userBio: authUser.biography,
        userId:authUser.uid,
        userKarmaScore: authUser?.articles_voted?.length,
        topSources:topSources,
        userPostedSources:{
          postType : "comment",
          socialMediaContent:comment?.text,
          reportCount:reportCount?.data?.comment_reports_aggregate?.aggregate?.count
        }
      }
      try{
        this.setState({
          ...this.state,
          reportDlg: false,
        });
        this.props.requestDataPending();
        await isFlaggedModerationByAi(params, token).then(
         async (result) => {
            if (result.status === 200) {
              if ((result.message.decision.toLowerCase()) !== "denied") {
                this.setError("This comment can't be reported due to "+ JSON.stringify(result.message.reason));
                await updateReportLimit(updatedAuthUser)
                  if (result.status === 200) {
                     this.props.setAuthUser(updatedAuthUser);
                  }
                throw new Error('Approve'); // Throw an error to exit the Promise chain
              }
              }
          },
          (reason) => {
            this.setError(reason.msg);
            throw new Error('Error'); // Throw an error to exit the Promise chain
          }
        ).catch((err) => {
          this.setError(JSON.stringify(err.Error));
          throw new Error(err); // Throw an error to exit the Promise chain
        });
      
      }catch(err){
        console.log("The source is denied",err)
        return ;
      }

    }

    gqlservice
      .insert_comment_report(report)
      .then(result => {
        (async () => {
          ToastSuccess(
            "Report success, wait for approvement by the feed moderator."
          );
          await updateReportLimit(updatedAuthUser);
          this.props.setAuthUser(updatedAuthUser);
        })().catch((err) => {
          this.setError(JSON.stringify(err));
        });
        
      }, reason => {
        this.setError(reason.msg);
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setState({
      ...this.state,
      reportDlg: false,
      anchorEl: null
    });
  };
 // For getting top sources
 getTopSources = () => {
  const { selected_feed  } =this.props
  let topSources = [];
  let totalCount = selected_feed?.feed_sources?.length >= 9 ? 9 : selected_feed?.feed_sources?.length;
  for (let i = 0; i <= totalCount; i++) {
    const branch_name = get_branch_name(selected_feed?.feed_sources[i]?.source?.branch);
    if (selected_feed?.feed_sources[i] && selected_feed?.feed_sources[i].source) {
        let new_top_source ={
          name:selected_feed?.feed_sources[i].source?.name ,
          description:selected_feed?.feed_sources[i].source?.description , 
          socialMediaType: branch_name
         }
         topSources.push(new_top_source);
    }
  }
  return topSources;
}
  handleCancelReport = () => {
    this.setState({
      ...this.state,
      reportDlg: false,
      anchorEl: null
    });
  };

  handleCollapse = (collapsed) => {
    const { comment } = this.props;
    const { last_offset } = this.state;
    if (!collapsed && last_offset === 0) {
      if (comment.thread_id) {
        this._getChildrenOfFirstPage(comment.thread_id, comment.id);
      }
    }
  };

  
  _getAuthToken = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    let token = authUser.token;
    if (Date.now() >= authUser.expiredTS) {
      const result = await this.props.firebase.refreshToken();
      if (result.error) {
        this.setError(result.msg);
        token = null;
      } else {
        token = result.token;
      }
    }
    return token;
  };

  _getChildrenOfFirstPage = (thread_id, parent_id) => {
    const { sortType } = this.props;

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    let fn_get_comments = null;

    if (sortType === SORT_NEWEST) {
      fn_get_comments = gqlservice.comments_by_newest;
    } else if (sortType === SORT_OLDEST) {
      fn_get_comments = gqlservice.comments_by_oldest;
    } else if (sortType === SORT_MOST_RECOMMENDED) {
      fn_get_comments = gqlservice.comments_by_recommends;
    } else if (sortType === SORT_MOST_REPLIED) {
      fn_get_comments = gqlservice.comments_by_replies;
    }

    const { selected_feed, moderate } = this.props;
    let approved = true;
    if (moderate) {
      approved = null;
    } else if (selected_feed && selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    fn_get_comments(thread_id, parent_id, approved, 0)
      .then(
        (result) => {
          let comments = result.data.comments;
          logger.log(
            "News article => children comments(first page) :",
            comments
          );
          for (let comment of comments) {
            // comment.children = comment.children;
            comment.childrenCount = comment.children_aggregate.aggregate.count;
          }

          //this.props.setChildComments(comments, parent_id, comments.length);
          this.setWaiting(false);

          this.setState({
            ...this.state,
            children: comments,
            last_offset: comments.length,
            showMore: comments.length === ARTICLES_PER_PAGE,
          });
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });
  };

  _getChildrenOfNextPage = async (thread_id, parent_id, last_offset) => {
    const { sortType } = this.props;

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    let fn_get_comments = null;

    if (sortType === SORT_NEWEST) {
      fn_get_comments = gqlservice.comments_by_newest;
    } else if (sortType === SORT_OLDEST) {
      fn_get_comments = gqlservice.comments_by_oldest;
    } else if (sortType === SORT_MOST_RECOMMENDED) {
      fn_get_comments = gqlservice.comments_by_recommends;
    } else if (sortType === SORT_MOST_REPLIED) {
      fn_get_comments = gqlservice.comments_by_replies;
    }

    const { selected_feed, moderate } = this.props;
    let approved = true;
    if (moderate) {
      approved = null;
    } else if (selected_feed && selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    fn_get_comments(thread_id, parent_id, approved, last_offset)
      .then(
        (result) => {
          const comments = result.data.comments;
          logger.log(
            "News article => children comments(next page) :",
            comments
          );

          if (comments.length === 0) {
            this.setState({
              ...this.state,
              showMore: false
            });
          } else {
            for (let comment of comments) {
              // comment.children = comment.children;
              comment.childrenCount = comment.children_aggregate.aggregate.count;
            }
            let children = this.state.children.slice();
            for (let comment of comments) {
              if (children.find(child => child.id === comment.id) === undefined) {
                children.push(comment);
              }
            }

            this.setState({
              ...this.state,
              children: children,
              last_offset: this.state.last_offset + comments.length,
              showMore: comments.length === ARTICLES_PER_PAGE
            });
          }

          this.setWaiting(false);
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });
  };

  renderTitle = (classes, theme_mode, author, description) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item onClick={()=>this.openProfile(author)}>
              <Typography className={classes.authorname}>
                {author.username}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.authorbio}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {author.isMod && (
          <Grid item className={classes.moderation}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item>
                <img
                  alt="Mod"
                  src={`/static/images/icons/${theme_mode}/moderator.png`}
                  className={classes.modimage}
                />
              </Grid>
              <Grid item>
                <Typography className={classes.modlabel}>MOD</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  renderMenu = (classes, timestamp, fn_menu) => {
    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-end"
        className={classes.menu}
      >
        <Grid item>
          <Typography className={classes.timestamp}>
            {get_elapsed_time(timestamp)}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton className={classes.expand} onClick={fn_menu}>
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  renderAction = (classes, theme_mode, recommends, fn_recommend, fn_reply) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <Button
            className={classes.actionbtn}
            size="small"
            color="primary"
            startIcon={
              <img
                alt={"Recommends"}
                src={`/static/images/icons/${theme_mode}/like.png`}
                className={classes.actionimg}
              />
            }
            onClick={fn_recommend}
          >
            <Typography className={classes.actionvalue}>
              Recommends&nbsp;&nbsp;{recommends}
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.actionbtn}
            size="small"
            color="primary"
            startIcon={
              <img
                alt={"Reply"}
                src={`/static/images/icons/${theme_mode}/creply.png`}
                className={classes.actionimg}
              />
            }
            onClick={fn_reply}
          >
            <Typography className={classes.actionvalue}>Reply</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  getShareInfo = (comment) => {
    // share info
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }

    // get share title
    let shareTitle = "";
    if (!comment.content) {
      shareTitle = "Raven comment";
    } else {
      shareTitle = comment.content;
    }

    const shareInfo = {
      title: "Raven Comment: " + shareTitle,
      description: "",
      image: "",
      hashtag: "",
      url: shareUrl,
    };

    return shareInfo;
  };

  openProfile = (author) => {
      let route = `/${ROUTES.USER_PREFIX}/${author.username}`;
      const location = {
        pathname: route,
        state: { animation: "left" },
      };
      this.props.history.push(location);

  }

  render() {
    const {
      classes,
      authUser,
      theme_mode,
      width,
      card_mode,
      sortType,
      comment,
      moderate,
      // collapsed,
      closed,
      commentcount,
      level,
      onNeedLogin,
      onMemberTrial,
      selected_feed,
    } = this.props;
    const {
      children,
      approved,
      recommends,
      collapsed,
      showEdit,
      showMore,
      showReply,
      loginDlg,
      memberDlg,
      reportDlg,
      shareDlg,
      anchorEl
    } = this.state;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    const indent_width = 16;
    const hasChildren = children.length > 0;
    const child_width = width - indent_width;

    let description = comment.author.biography;
    if (collapsed) {
      description = `${commentcount} Comments`;
      logger.log("CommentCard description :", description, comment);
    }

    const isAuthor = authUser.uid === comment.author.uid;
    const moderator = this._isFeedModerator(selected_feed)
    return (
      <div>
        <Card className={classes.card}>
          {/* comment info */}
          <div className={card_mode ? classes.carddiv_card : classes.carddiv} style={{ width: width }} 
          >
            <CardHeader
              className={classes.header}
              avatar={
                <div onClick={()=>this.openProfile(comment.author)}>

                <Avatar
                  alt={comment.author.username}
                  src={comment.author.image}
                  className={classes.avatar}
                  classes={{ img: classes.avatar_img }}
                />
                </div>
              }
              title={this.renderTitle(
                classes,
                theme_mode,
                comment.author,
                description
              )}
              action={this.renderMenu(
                classes,
                comment.created_at,
                this.handleExpand
              )}
            />
            {!collapsed && (
              <CardContent className={classes.content}>
                <div>
                  <Typography className={classes.text}>
                    {parse(comment.text)}
                  </Typography>
                </div>
              </CardContent>
            )}
            {!collapsed && !closed && (
              <CardActions className={classes.action}>
                {this.renderAction(
                  classes,
                  theme_mode,
                  recommends,
                  this.handleRecommend,
                  this.handleReply
                )}
              </CardActions>
            )}
          </div>

          {/* moderation */}
          {moderate && !approved && !collapsed && !closed && (
            <CommentModButtons
              theme={theme_mode}
              comment={comment}
              onApprove={this.handleApproveComment}
              onDelete={this.handleDeleteComment}
              onBan1d={this.handleBan1d}
              onBan7d={this.handleBan7d}
              onBanPerm={this.handleBanPerm}
            />
          )}

          {/* edit/update box */}
          {showEdit && !closed && (
            <CommentUpdateBox
              width={width}
              card_mode={card_mode}
              comment={comment.text}
              replyto={comment.author.username}
              onCancel={this.handleCancelEdit}
              onUpdate={this.handleUpdateComment}
            />
          )}
          {showReply && !closed && (
            <CommentEditBox
              width={width}
              card_mode={card_mode}
              replyto={comment.author.username}
              onSubmit={this.handleSubmitChild}
            />
          )}
        </Card>
          
        {/* child comments */}
        {hasChildren && !collapsed &&
          <CommentChildCards
            width={child_width}
            card_mode={card_mode}
            children={children}
            childrenCount={comment.childrenCount}
            sortType={sortType}
            level={level+1}
            closed={closed}
            collapsed={collapsed}
            onNeedLogin={onNeedLogin}
            onMemberTrial={onMemberTrial}
            onCollapsed={this.handleCollapse}
            onDeleteChild={this.handleDeleteChild}
            onUpdateChild={this.handleUpdateChild}
          />
        }

        {/* show more button */}
        {showMore && (
          <div className={classes.more}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.morebtn}
              onClick={this.handleNeedMore}
            >
              Show More Comments...
            </Button>
          </div>
        )}
        
        {/* other menu & dialogs */}
        <Menu
          id="filter-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleMenuClose}
        >
          {isAuthor && !closed && (
            <PopMenuCommentAuthor
              theme={theme_mode}
              onEdit={this.handleEditComment}
              onDelete={this.handleDeleteComment}
              onShare={this.handleShareComment}
            />
          )}
          {moderator && !closed && (
            <PopMenuCommentMod
              theme={theme_mode}
              onEdit={this.handleEditComment}
              onDelete={this.handleDeleteComment}
              onReport={this.showReportDlg}
              onShare={this.handleShareComment}
            />
          )}
          {!isAuthor && !moderator && !closed && (
            <PopMenuCommentUser
              theme={theme_mode}
              onReport={this.showReportDlg}
              onShare={this.handleShareComment}
            />
          )}
        </Menu>
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgMemberTrial
          open={memberDlg}
          onClose={this.handleCloseMemberTrial}
          onMemberTrial={this.handleMemberTrial}
        />
        <DlgReport
          open={reportDlg}
          title={"Report Comment"}
          theme={theme_mode}
          onReport={this.handleReport}
          onCancel={this.handleCancelReport}
        />
        <DlgShareComment
          open={shareDlg}
          comment={comment}
          shareInfo={this.getShareInfo(comment)}
          onClose={this.handleCloseShare}
        />
      </div>
    );
  }
}

CommentCard.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  card_mode: PropTypes.bool,
  comment: PropTypes.object,
  commentcount: PropTypes.number,
  level: PropTypes.number,
  sortType: PropTypes.number,
  closed: PropTypes.bool,
  collapsed: PropTypes.bool,
  onNeedLogin: PropTypes.func,
  onMemberTrial: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  default_feeds: state.dataState.default_feeds,
  selected_feed: state.dataState.selected_feed,
  moderate: state.dataState.moderate,
  scrollPos: state.uiState.scrollPos,
  requesting: state.uiState.requesting,
  theme_mode: state.uiState.theme_mode,
  followedComments:state.dataState.followedComments
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(CommentCard);
