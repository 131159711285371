import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { setError } from "./base";
import {
  GRAPHQL_ERROR,
} from "constants/types";
import { 
  NOTIFICATION_MAP_REGION_APPLY_MODERATOR,
} from "constants/notification";
import { 
  ACTIVITY_APPLY, 
  ACTIVITY_TYPE_CLEANAIRMAP 
} from "constants/activity";

const moderateRegion = async (region) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  const moderator = {
    user_id: authUser.uid,
    region_id: region.id,
    approved: false,
    approved_by: null,
    approved_at: null
  };

  // insert region moderator
  let result = await gqlservice.insert_map_region_moderator(moderator);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (result.data.insert_region_moderators.affected_rows === 0) {
    setError("Failed to register as a moderator");
    return;
  }
  const region_moderator = result.data.insert_region_moderators.returning[0];
  store.dispatch({
    type: ActionTypes.INSERT_REGION_MODERATOR,
    moderator: region_moderator,
  });

  // insert notification
  const notification = {
    type: NOTIFICATION_MAP_REGION_APPLY_MODERATOR,
    object: region.id.toString(),
    in_which: "cleanairmap",
    to: region.created_by,
    created_by: authUser.uid
  }
  result = await gqlservice.insert_notification(notification);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  // log activity
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_CLEANAIRMAP,
    type_id: region.id.toString(),
    action: ACTIVITY_APPLY,
    object: `the region ${region.id}`,
    fromto: `to cleanair map`,
    reason: "",
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

export { 
  moderateRegion,
};
