import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withRouter } from "react-router-dom";
import { withFirebase } from "services";
import { withStyles } from "@material-ui/core/styles";
import { Typography, TextField, Grid, Button } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BasicAppBar, DlgConfirm } from "components";
import { GraphqlService } from "services";
import { ARTICLE_BRANCH_PODCAST } from "constants/branches";
import {
  GRAPHQL_SUCCESS,
  MAX_ARTICLE_WIDTH,
  MAX_WINDOW_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
import * as ROUTES from "constants/routes";
import { getAuthToken, getSourcebySocialtag, isFeedModerator, updateCache } from "dataapis";
import { get_source_links } from "utility/buildlink";
import { get_link_source } from "utility/ravenapi";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";

// const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: theme.breakpoints.lg,
    maxWidth: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  container: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  image: {
    objectFit: "cover",
    height: 100,
    width: 100,
    borderRadius: 10,
  },
  title: {
    position: "relative",
    textAlign: "left",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "24px",
    textTransform: "none",
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  description: {
    position: "relative",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 200,
    lineHeight: "16px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  linkcontainer: {
    backgroundColor: theme.palette.background.dark,
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  linktitle: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 400,
  },
  sources: {
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  sourcesInput: {
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 14,
    "&:before": {
      borderBottomColor: "transparent",
    },
    "&:after": {
      borderBottomColor: "transparent",
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "transparent",
    },
  },
  applybtn: {
    position: "absolute",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 24,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  applybtn_disabled: {
    position: "absolute",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 24,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
    opacity: 0.3,
  },
});

class SourceAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sourcesTxt: "",
      showConfirmDlg: false,
    };

    this.handleNavBack = this.handleNavBack.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.handleApply = this.handleApply.bind(this);
    this.handleSubmitSources = this.handleSubmitSources.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  _getAuthToken = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    let token = authUser.token;
    if (Date.now() >= authUser.expiredTS) {
      const result = await this.props.firebase.refreshToken();
      if (result.error) {
        this.setError(result.msg);
        token = null;
      } else {
        token = result.token;
      }
    }
    return token;
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    this.props.clearTempFeedSources();
    this.props.clearTempFeedSourceLinks();
    this.props.history.goBack();
  };

  handleCancel = () => {
    this.props.clearTempFeedSources();
    this.props.clearTempFeedSourceLinks();
    this.setState({
      ...this.state,
      showConfirmDlg: false,
    });
  };

  handleChange = (event) => {
    event.persist();

    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handleApply = async () => {
    let source_links = get_source_links(this.state.sourcesTxt);
    logger.error("source links :", source_links);
    if (source_links.length === 0) {
      this.setError("There aren't sources to add! Check the links please.");
      return;
    }

    let exist_sources = [];
    let new_source_links = [];
    for (let source_link of source_links) {
      let exist_source = null;
      // have to fetch link preview in podcast, because podcast link is apple link
      if (source_link.branch === ARTICLE_BRANCH_PODCAST) {
        let link_preview = await get_link_source(source_link.url);
        if (link_preview) {
          exist_source = await getSourcebySocialtag(link_preview.url, source_link.type);
        }
      } else {
        exist_source = await getSourcebySocialtag(source_link.tag, source_link.type);
      }
      if (exist_source) {
        exist_sources.push(exist_source);
      } else {
        new_source_links.push(source_link);
      }
    }
    if (new_source_links.length > 0) {
      logger.log("new source links :", new_source_links);
      this.props.setTempFeedSourceLinks(new_source_links);
    }

    if (exist_sources.length > 0) {
      logger.warn("exist sources :", exist_sources);
      this.props.setTempFeedSources(exist_sources);
    }

    this.setState({
      ...this.state,
      showConfirmDlg: true,
    });
  };

  handleSubmitSources = async () => {
    const {
      selected_feed,
      authUser,
      temp_feed_sources,
      temp_feed_sourcelinks,
    } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    const isModerator = isFeedModerator(selected_feed);
    const approved = isModerator || !selected_feed.private;

    // insert temporary feed sources
    let feed_sources = [];
    let feed_source_changes = [];
    const currentTime = new Date().toISOString();
    if (temp_feed_sources !== null && temp_feed_sources.length > 0) {
      for (let temp_feed_source of temp_feed_sources) {
        feed_sources.push({
          id: uuidv4(),
          feed_id: selected_feed.id,
          source_id: temp_feed_source.id,
          created_by: authUser.uid,
          approved: approved,
          approved_by: approved ? authUser.uid : null,
          approved_at: approved ? currentTime : null,
        });
        feed_source_changes.push({
          feed_id: selected_feed.id,
          source_id: temp_feed_source.id,
          removed: false,
          created_by: authUser.uid,
        });
      }

      await gqlservice
        .insert_feed_sources(feed_sources)
        .then((result) => {
          this.props.clearTempFeedSources();
          return gqlservice.feed_by_id(selected_feed.id);
        })
        .then(
          (result) => {
            const feeds = result.data.feeds;
            if (feeds.length > 0) {
              this.props.updateFeed(feeds[0]);
            }

            this.setState({
              ...this.state,
              error: false,
            });
          },
          (reason) => {
            this.setError(reason.msg);
            this.setWaiting(false);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
          this.setWaiting(false);
        });

      // insert feed source changes
      if (feed_source_changes.length > 0) {
        logger.log("feed source changes :", feed_source_changes);
        let result = await gqlservice.insert_feed_source_changes(feed_source_changes);
        if (result.status_code !== GRAPHQL_SUCCESS || result.data.insert_feed_source_change.affected_rows === 0) {
          this.setError("Failed to insert feed source changes!");
        }
      }

      await updateCache();
      
      this.setWaiting(false);
    }

    let route = "";
    if (temp_feed_sourcelinks && temp_feed_sourcelinks.length > 0) {
      route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/review`;
    } else {
      route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
    }
    const location = {
      pathname: route,
      state: { animation: "left" },
    };

    this.props.history.push(location);
  };

  render() {
    const { classes, socialtypes, selected_feed } = this.props;
    const { sourcesTxt, showConfirmDlg } = this.state;

    if (!socialtypes) {
      return <div style={{display: "none"}}></div>;
    }

    // enable apply button
    let apply_enabled = false;
    if (sourcesTxt.trim()) {
      apply_enabled = true;
    }

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;

    // source name & description width
    let text_width =
      width > MAX_ARTICLE_WIDTH ? MAX_ARTICLE_WIDTH - 148 : width - 148; // image selector(116), padding(32)

    const applyButtonPos = (width - 260) / 2;

    // sources placeholder
    const sources_placeholder = `Example:

twitter.com/elonmusk
facebook.com/BillGates
www.cnn.com
`;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar title={"Add Sources"} onNavBack={this.handleNavBack} />
        </div>

        <div className={classes.container}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <LazyLoadImage
                className={classes.image}
                src={selected_feed.thumbnail || selected_feed.image || RAVEN_PLACEHOLDER_IMAGE}
                alt={selected_feed.name}
              />
            </Grid>
            <Grid item style={{ width: text_width }}>
              <Typography className={classes.title}>
                {selected_feed.name}
              </Typography>
              <Typography className={classes.description}>
                {selected_feed.description}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.linkcontainer}>
          <Typography className={classes.linktitle}>
            Add links to sources, one on each line
          </Typography>
          <TextField
            className={classes.sources}
            fullWidth
            multiline
            minRows={9}
            name="sourcesTxt"
            value={sourcesTxt || ""}
            onChange={this.handleChange}
            placeholder={sources_placeholder}
            InputProps={{
              classes: {
                root: classes.sourcesInput,
              },
            }}
          />
        </div>
        {apply_enabled && (
          <Button
            className={classes.applybtn}
            style={{ left: applyButtonPos }}
            onClick={this.handleApply}
          >
            Save
          </Button>
        )}
        {!apply_enabled && (
          <Button
            className={classes.applybtn_disabled}
            style={{ left: applyButtonPos }}
          >
            Save
          </Button>
        )}
        <DlgConfirm
          open={showConfirmDlg}
          title={"Add Sources"}
          content={"Do you really want to add these sources?"}
          onOK={this.handleSubmitSources}
          onCancel={this.handleCancel}
        />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  socialtypes: state.dataState.socialtypes,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
  temp_feed_sources: state.dataState.temp_feed_sources,
  temp_feed_sourcelinks: state.dataState.temp_feed_sourcelinks,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SourceAdd);
