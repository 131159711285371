import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Grid,
  IconButton,
  Menu,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShareIcon from "@material-ui/icons/Share";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  PopMenuThread,
  DlgShareComment,
  DlgLoginConfirm,
  DlgReport,
  // DlgConfirm,
} from "components";
import {
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  THEME_MODE_DARK,
  RAVEN_PLACEHOLDER_IMAGE,
  THREAD_REPORT_COMMENTS,
  FLAGGED_COMMENTS_POSTS,
} from "constants/types";
import {
  CONF_BRANCHES,
  ARTICLE_BRANCH_NEWSPAPER,
  get_branch_name,
} from "constants/branches";
import * as ROUTES from "constants/routes";
import {
  deleteThread,
  getAuthToken,
  isBannedComments,
  reportThread,
  updateFeedNotifications,
  upvoteThread,
} from "dataapis";
import { get_elapsed_time, render_text } from "utility/utils";
import { ToastInfo, ToastError, ToastSuccess } from "utility/toast";
import { logger } from "utility/logging";
import { isFlaggedModerationByAi } from "utility/ravenapi";
import { GraphqlService } from "services";
import moment from "moment/moment";
import { handleReportWithLimit } from "utility/reportlimit";
import { updateReportLimit } from "dataapis/user";

const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    borderRadius: 10,
    borderTopLeftRadius: 30,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.default,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  share: {
    position: "absolute",
    top: 12,
    right: 12,
    padding: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    backgroundColor: "#7289DA",
    zIndex: 100,
  },
  shareicon: {
    width: 16,
    height: 16,
  },
  expand: {
    position: "absolute",
    top: 28,
    right: 10,
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 48,
    height: 48,
    cursor: "pointer",
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  socialimg: {
    position: "absolute",
    top: 52,
    left: 14,
    width: 20,
    height: 20,
  },
  upvotebtn: {
    position: "absolute",
    top: 80,
    left: 12,
    width: 20,
    height: 20,
    zIndex: 100,
  },
  upvotetxt: {
    position: "absolute",
    top: 102,
    left: 16,
    fontSize: "14px",
    fontStyle: "Roboto",
  },
  actionimg: {
    width: 20,
    height: 20,
  },
  title: {
    position: "relative",
    marginTop: 16,
    marginLeft: 40,
    marginRight: 40,
    minHeight: 60,
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.palette.text.primary,
  },
  date: {
    float: "right",
    fontSize: "12px",
    lineHeight: "14px",
    marginRight: 8,
    marginBottom: 8,
    color: theme.palette.text.secondary,
  },
  content: {
    padding: 4,
    marginLeft: 50,
    marginRight: 10,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  detail_txt: {
    display: "inline",
    overflowWrap: "break-word",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
  },
  media: {
    display: "block",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  actions: {
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  commentbtn: {
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20,
    zIndex: 100,
  },
  comment: {
    marginLeft: theme.spacing(0.5),
    fontSize: "14px",
    fontStyle: "Roboto",
  },
});

class ThreadCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      loginDlg: false,
      reportDlg: false,
      // deleteDlg: false,
      shareDlg: false,
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);

    this.handleDeleteThread = this.handleDeleteThread.bind(this);

    this.handleReportThread = this.handleReportThread.bind(this);
    this.handleCloseReport = this.handleCloseReport.bind(this);
    this.handleReport = this.handleReport.bind(this);
  }

  setError = (message) => {
    ToastError(message);
  };

  setAlert = (message) => {
    ToastInfo(message);
  };

  handleExpand = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
    this.props.onLogin();
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  handleShare = () => {
    this.setState({
      ...this.state,
      shareDlg: true,
      anchorEl: null,
    });
  };

  handleCloseShare = () => {
    this.setState({
      ...this.state,
      shareDlg: false,
    });
  };

  handleClickUpvote = async () => {
    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const { thread } = this.props;
    await upvoteThread(thread);
  };

  handleDeleteThread = async () => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    const { thread } = this.props;
    await deleteThread(thread);
  };

  handleReportThread = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }
    this.handleCloseMenu();
    if (isBannedComments()) {
      this.setError("You've suspended for comment operations.");
      return;
    }

    this.setState({
      ...this.state,
      reportDlg: true,
    });
  };

  handleCloseReport = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      reportDlg: false,
    });
  };

  handleReport = async (reportMsg) => {
    const message = reportMsg.trim();
    if (!message) {
      this.setError("Report shouldn't be blank. Please input your report.");
      return;
    }

    this.setState({
      ...this.state,
      reportDlg: false,
      anchorEl: null,
    });

    const { thread, selected_feed, authUser } = this.props;

    //reportlimit
    const updatedAuthUser = await handleReportWithLimit(authUser);
    if(!updatedAuthUser){
      ToastError("Report limit exceeded. Please try again later.");
      return null
    }

    const isAiModerationEnabled =
      JSON.parse(selected_feed.ai_moderation) === null
        ? false
        : JSON.parse(selected_feed.ai_moderation).some(
            (item) => item === FLAGGED_COMMENTS_POSTS
          );
    //  const isAiModerationEnabled =true
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    gqlservice.set_auth_jwt(token);
    if (isAiModerationEnabled) {
      const topSources = this.getTopSources();
      const currentDate = moment(); // Get the current date and time
      const date90DaysAgo = currentDate.subtract(90, 'days'); // Subtract 90 days from the current date
      console.log(date90DaysAgo.toISOString());
      const reportCount = await gqlservice.thread_reports_count(authUser.uid ,date90DaysAgo.toISOString())
      const params = {
        name: selected_feed.name,
        description: selected_feed.description,
        userName: authUser.name,
        userBio: authUser.biography,
        userId:authUser.uid,
        userKarmaScore: authUser?.articles_voted?.length,
        topSources: topSources,
        userPostedSources: {
          postType: "comment",
          socialMediaContent: thread?.text,
          reportCount:reportCount?.data?.thread_reports_aggregate?.aggregate?.count
        },
      };
      try {
        // const gqlservice = new GraphqlService();
        await isFlaggedModerationByAi(params, token)
          .then(
            async (result) => {
              if (result.status === 200) {
                if (result.message.decision.toLowerCase() !== "denied") {
                  console.log(
                    "Response from ai :",
                    JSON.stringify(result.message.reason)
                  );
                  this.setError(
                    "This comment can't be reported due to " +
                      JSON.stringify(result.message.reason)
                  );
                  await updateReportLimit(updatedAuthUser)
                  this.props.setAuthUser(updatedAuthUser)
                  throw new Error("Approve"); // Throw an error to exit the Promise chain
                } else {
                  // const token = await getAuthToken();
                  if (!token) {
                    this.handleLogin();
                    return;
                  }
                  gqlservice.set_auth_jwt(token, true);
                  ToastSuccess(
                    "Reported the Thread successfully by AI " +
                      JSON.stringify(result.message.reason)
                  );
                  // let route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
                  // const location = {
                  //   pathname: route,
                  //   state: { animation: "left" },
                  // };
                  // this.goTo(location);
                  await deleteThread(thread);
                  await updateFeedNotifications(selected_feed);
                  await updateReportLimit(updatedAuthUser)
                  this.props.setAuthUser(updatedAuthUser);
                }
              }
              throw new Error("Error"); // Throw an error to exit the Promise chain
            },
            (reason) => {
              this.setError(reason.msg);
              throw new Error("Error"); // Throw an error to exit the Promise chain
            }
          )
          .catch((err) => {
            this.setError(JSON.stringify(err.Error));
            throw new Error(err); // Throw an error to exit the Promise chain
          });
      } catch (err) {
        console.log("The thread is denied", err);
        return;
      }
    }
    await reportThread(thread, message, THREAD_REPORT_COMMENTS);
    ToastInfo("Reported the thread");
    await updateReportLimit(updatedAuthUser);
    this.props.setAuthUser(updatedAuthUser);
  };

  // For getting top sources
  getTopSources = () => {
    const { selected_feed } = this.props;
    let topSources = [];
    let totalCount =
      selected_feed?.feed_sources?.length >= 9
        ? 9
        : selected_feed?.feed_sources?.length;
    for (let i = 0; i <= totalCount; i++) {
      const branch_name = get_branch_name(
        selected_feed?.feed_sources[i]?.source?.branch
      );
      if (
        selected_feed?.feed_sources[i] &&
        selected_feed?.feed_sources[i].source
      ) {
        let new_top_source = {
          name: selected_feed?.feed_sources[i].source?.name,
          description: selected_feed?.feed_sources[i].source?.description,
          socialMediaType: branch_name,
        };
        topSources.push(new_top_source);
      }
    }
    return topSources;
  };

  _getShareInfo = () => {
    const { thread, selected_feed } = this.props;

    // share info
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }

    if (thread.article) {
      const type =
        thread.article.source.branch === ARTICLE_BRANCH_NEWSPAPER
          ? ROUTES.ARTICLE_NEWS_PREFIX
          : ROUTES.ARTICLE_PREFIX;
      shareUrl = `${shareUrl}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${thread.article.source.slug}/${type}/${thread.article.nid}`;
    } else {
      shareUrl = `${shareUrl}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.id}`;
    }

    let shareInfo = {
      title: "Raven Comment: " + thread.title,
      description: thread.text,
      image: "/static/images/icons/raven_logo.png",
      hashtag: "raven,comments",
      url: shareUrl,
    };

    return shareInfo;
  };

  render() {
    const {
      classes,
      theme_mode,
      loggedIn,
      authUser,
      sources,
      selected_feed,
      thread,
      onClickThread,
      onClickSource,
    } = this.props;
    const {
      anchorEl,
      loginDlg,
      reportDlg,
      // deleteDlg,
      shareDlg,
    } = this.state;

    // get source to use name, image, etc
    let source = null;
    let branch = null;
    if (thread.article) {
      source = sources.find((item) => item.id === thread.article.source.id);
      if (!source) {
        logger.error("Error, Unknown article source!", thread.article);
        return <div style={{display: "none"}}></div>;
      }
      branch = CONF_BRANCHES.find((item) => item.value === source.branch);
    }

    // voted, reposted by me
    let voted =
      authUser.threads_voted.find((voted) => voted.thread_id === thread.id) !==
      undefined;
    // let reposted = authUser.threads_reposted.find(reposted => reposted.thread_id === thread.id) !== undefined;

    // image
    let thread_image = null;
    if (thread.article) {
      thread_image = thread.article.image;
      const image_thumb = thread.article.image_thumb;
      if (image_thumb) {
        if (image_thumb["150"]) {
          thread_image = image_thumb["150"];
        } else if (image_thumb["240"]) {
          thread_image = image_thumb["240"];
        } else if (image_thumb["320"]) {
          thread_image = image_thumb["320"];
        } else if (image_thumb["org"]) {
          thread_image = image_thumb["org"];
        }
      }
    }

    // get comments
    const commentsCnt = thread.comments_aggregate.aggregate.count;
    const posted_at = "Posted " + get_elapsed_time(thread.posted_at);

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    width -= 16;
    if (width > MAX_CARD_WIDTH - 16) width = MAX_CARD_WIDTH - 16;
    if (width < MIN_CARD_WIDTH - 16) width = MIN_CARD_WIDTH - 16;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    // share info
    const shareInfo = this._getShareInfo();

    return (
      <div>
        <Card
          className={classes.card}
          style={{
            width: width,
            backgroundColor: classes.card.backgroundColor,
          }}
        >
          <div className={classes.carddiv}>
            <CardHeader
              className={classes.header}
              avatar={
                <div>
                  {source && (
                    <>
                      <Avatar
                        alt={source.name}
                        src={source.thumbnail || source.image || RAVEN_PLACEHOLDER_IMAGE}
                        className={classes.avatar}
                        classes={{ img: classes.avatar_img }}
                        onClick={(e) => onClickSource(source)}
                      />
                      <img
                        alt={branch.name}
                        src={`/static/images/icons/${theme_mode}/${branch.image}`}
                        className={classes.socialimg}
                      />
                    </>
                  )}
                  {!source && selected_feed && (
                    <Avatar
                      alt={selected_feed.name}
                      src={selected_feed.thumbnail || selected_feed.image || RAVEN_PLACEHOLDER_IMAGE}
                      className={classes.avatar}
                      classes={{ img: classes.avatar_img }}
                    />
                  )}
                </div>
              }
              title={
                <div>
                  <Typography
                    className={classes.title}
                    variant="subtitle2"
                    onClick={(e) => onClickThread(thread)}
                  >
                    {render_text(thread.title, theme_mode === THEME_MODE_DARK)}
                  </Typography>
                  <Typography className={classes.date}>{posted_at}</Typography>
                </div>
              }
            />

            <div>
              <IconButton
                className={classes.upvotebtn}
                onClick={this.handleClickUpvote}
              >
                <img
                  alt={"upvote"}
                  src={
                    voted
                      ? `/static/images/icons/thumbsup.png`
                      : `/static/images/icons/${theme_mode}/thumbsup.png`
                  }
                  className={classes.actionimg}
                />
              </IconButton>
              {thread.upvotes > 0 && (
                <Typography
                  className={classes.upvotetxt}
                  style={{ left: 24 - 4 * thread.upvotes.toString().length }}
                >
                  {thread.upvotes}
                </Typography>
              )}
            </div>
            <div>
              <IconButton
                className={classes.share}
                onClick={this.handleClickShare}
              >
                <ShareIcon className={classes.shareicon} />
              </IconButton>
              <IconButton
                className={classes.expand}
                onClick={this.handleExpand}
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
            {thread.text && (
              <CardContent
                className={classes.content}
                onClick={(e) => onClickThread(thread)}
              >
                <Typography className={classes.detail_txt}>
                  {render_text(thread.text, theme_mode === THEME_MODE_DARK)}
                </Typography>
              </CardContent>
            )}
            {thread_image && (
              <LazyLoadImage
                alt={""}
                src={thread_image}
                className={classes.media}
              />
            )}
            <CardActions className={classes.actions}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <div
                    style={{ marginTop: 8, marginRight: 16, cursor: "pointer" }}
                    onClick={(e) => onClickThread(thread)}
                  >
                    <IconButton className={classes.commentbtn}>
                      <img
                        alt={"comment"}
                        src={`/static/images/icons/${theme_mode}/chat.png`}
                        className={classes.actionimg}
                      />
                    </IconButton>
                    {commentsCnt > 0 && (
                      <span
                        className={classes.comment}
                      >{`${commentsCnt} Comments`}</span>
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardActions>
          </div>
        </Card>

        <Menu
          id="thread-menu"
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleCloseMenu}
        >
          <PopMenuThread
            theme={theme_mode}
            type={thread.type}
            isPoster={loggedIn && thread.poster.uid === authUser.uid}
            onDelete={this.handleDeleteThread}
            onReport={this.handleReportThread}
            onShare={this.handleShare}
          />
        </Menu>

        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgReport
          open={reportDlg}
          title={"Report Comment"}
          theme={theme_mode}
          onReport={this.handleReport}
          onCancel={this.handleCloseReport}
        />
        {shareDlg && (
          <DlgShareComment
            open={shareDlg}
            comment={thread}
            shareInfo={shareInfo}
            onClose={this.handleCloseShare}
          />
        )}
      </div>
    );
  }
}

ThreadCard.propTypes = {
  classes: PropTypes.object,
  thread: PropTypes.object,
  onLogin: PropTypes.func,
  onClickThread: PropTypes.func,
  onClickSource: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ThreadCard);
