import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { THEME_MODE_DARK } from "constants/types";

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    padding: 0
  },
  tabs: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: 18,
    borderRadius: 12,
    backgroundColor: theme.palette.background.light,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`
  },
  indicator_light: {
    backgroundColor: "#1878f3",
  },
  indicator_dark: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  tab_light: {
    padding: 0,
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#1878f3",
    backgroundColor: theme.palette.background.light
  },
  tab_dark: {
    padding: 0,
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.light
  },
  icon: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    margin: 0,
    width: 16,
    height: 16
  }
});

const TAB_SUMMARY = 0;
// const TAB_FULL = 1;
const TAB_ORIGIN = 1;

class NewsTabs extends React.Component {

  render() {
    const { classes, theme_mode, selected_tab, show_original, onChangeNewsTab } = this.props;

    const summary_tab = {
      value: TAB_SUMMARY,
      name: "summary",
      icon: "summary.png"
    };
    // const full_tab = { 
    //   value: TAB_FULL, 
    //   name: "full", 
    //   icon: "full.png" 
    // };
    const original_tab = {
      value: TAB_ORIGIN,
      name: "Untranslated",
      icon: "home.png"
    };

    let classes_indicator = theme_mode === THEME_MODE_DARK ? classes.indicator_dark : classes.indicator_light;
    let classes_tab = theme_mode === THEME_MODE_DARK ? classes.tab_dark : classes.tab_light;

    return (
      <div className={classes.root}>
        <Tabs
          centered
          className={classes.tabs}
          classes={{ indicator: classes_indicator }}
          value={selected_tab}
          onChange={onChangeNewsTab}
          variant="fullWidth"
        >
          <Tab
            value={summary_tab.value}
            key={summary_tab.value}
            label={summary_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={summary_tab.name}
                src={`/static/images/icons/${theme_mode}/${summary_tab.icon}`}
                className={classes.icon}
              />
            }
          />
          {/* <Tab
            value={full_tab.value}
            key={full_tab.value}
            label={full_tab.name}
            className={classes_tab}
            icon={
              <img
                alt={full_tab.name}
                src={`/static/images/icons/${theme_mode}/${full_tab.icon}`}
                className={classes.icon}
              />
            }
          /> */}
          {show_original === true ? (
            <Tab
              value={original_tab.value}
              key={original_tab.value}
              label={original_tab.name}
              className={classes_tab}
              icon={
                <img
                  alt={original_tab.name}
                  src={`/static/images/icons/${theme_mode}/${original_tab.icon}`}
                  className={classes.icon}
                />
              }
            />
          ) : (
            <Tab
              disabled
              value={original_tab.value}
              key={original_tab.value}
              label={original_tab.name}
              className={classes_tab}
              icon={
                <img
                  alt={original_tab.name}
                  src={`/static/images/icons/${theme_mode}/${original_tab.icon}`}
                  className={classes.icon}
                />
              }
            />
          )}
        </Tabs>
      </div>
    );
  }
}

NewsTabs.propTypes = {
  classes: PropTypes.object,
  theme_mode: PropTypes.string,
  selected_tab: PropTypes.number,
  show_original: PropTypes.bool,
  onChangeNewsTab: PropTypes.func
};

export default withStyles(styles)(NewsTabs);
