import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import {
  ARTICLE_BRANCH_YOUTUBE,
  ARTICLE_BRANCH_TWITTER,
  ARTICLE_BRANCH_INSTAGRAM,
  ARTICLE_BRANCH_TWITCH,
  ARTICLE_BRANCH_MEDIUM,
  ARTICLE_BRANCH_REDDIT,
  ARTICLE_BRANCH_HACKERNEWS,
  ARTICLE_BRANCH_SLASHDOT,
  ARTICLE_BRANCH_PODCAST,
  ARTICLE_BRANCH_RSSFEED,
  ARTICLE_BRANCH_TIKTOK,
  ARTICLE_BRANCH_PINTEREST,
  ARTICLE_BRANCH_VSCO,
  ARTICLE_BRANCH_SPOTIFY,
  ARTICLE_BRANCH_QUORA,
  ARTICLE_BRANCH_FACEBOOK,
  ARTICLE_BRANCH_SUBSTACK,
  ARTICLE_BRANCH_GMAIL,
  ARTICLE_BRANCH_VIMEO,
  ARTICLE_BRANCH_WEBSITE,
  ARTICLE_BRANCH_MASTODON,
  ARTICLE_BRANCH_BLUESKY,
  ARTICLE_BRANCH_USERPOST,
  ARTICLE_BRANCH_NEWSPAPER,
} from "constants/branches";
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,
  },
  politicalCard: {
    width: "70%",
    borderRadius: 25,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.background.card,
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#03C04A",
    fontSize: "15px",
    letterSpacing: "0.05px",
    fontWeight: "bold",
  },
  title: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "0.66px",
  },
  politicalTitle: {
    width: "60%",
    borderRadius: 25,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.background.card,
    borderColor: theme.palette.feedstroke.default,
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#03C04A",
    fontSize: "15px",
    letterSpacing: "0.05px",
    fontWeight: "bold",
  },
  commentary: {
    display: "none",
    justifyContent: "center",
    marginTop: "5px",
    textAlign: "left",
  },
  expanded: {
    display: "block",
  },
  average: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "0.66px",
  },
  averagepolitics: {
    justifyContent: "center",
    alignItems: "center",
  },
  politicalCardYoutube: {
    borderColor: theme.palette.feedstroke.youtube,
  },
  politicalCardTwitter: {
    borderColor: theme.palette.feedstroke.twitter,
  },
  politicalCardInstagram: {
    borderColor: theme.palette.feedstroke.instagram,
  },
  politicalCardTwitch: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardMedium: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardReddit: {
    borderColor: theme.palette.feedstroke.reddit,
  },
  politicalCardHackerNews: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardSlashdot: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardPodcast: {
    borderColor: theme.palette.feedstroke.podcast,
  },
  politicalCardRSSFeed: {
    borderColor: theme.palette.feedstroke.rss,
  },
  politicalCardTikTok: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardPinterest: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardVSCO: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardSpotify: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardQuora: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardFacebook: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardSubstack: {
    borderColor: theme.palette.feedstroke.substack,
  },
  politicalCardGmail: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardVimeo: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardWebsite: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardMastodon: {
    borderColor: theme.palette.feedstroke.mastodon,
  },
  politicalCardBluesky: {
    borderColor: theme.palette.feedstroke.twitter,
  },
  politicalCardUserPost: {
    borderColor: theme.palette.feedstroke.default,
  },
  politicalCardNewspaper: {
    borderColor: theme.palette.feedstroke.news,
  },
});



class PoliticalSentiment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    const { classes, branch, article, source, disabled } = this.props;
    const { expanded } = this.state;
    const sentiment = article.political_sentiments ? JSON.parse(article.political_sentiments) : null;

    let bannerColor = "green";

    if (sentiment) {
      if (sentiment.Score >= 26 && sentiment.Politics === "Right") {
        bannerColor = "red";
      } else if (sentiment.Score >= 26 && sentiment.Politics === "Left") {
        bannerColor = "orange";
      }
    }

    let averageScore = source.total_biased_articles_no !== 0
    ? Math.round(Math.floor(source.total_bias_score / source.total_biased_articles_no) / 100)
    : 0;
    if(isNaN(averageScore)) {
      averageScore = 0;
     }

     let wingBias = "Balanced"; 
    if (sentiment) {
      if (sentiment.Politics === "Left" || sentiment.Politics === "Right") {
        wingBias = `${sentiment.Politics} Wing Bias`;
      }
    }

     let averageWing = "Balanced"; 
     if(averageScore > 0) {
       averageWing = "Right Wing Bias";
     } else if (averageScore < 0) {
      averageWing = "Left Wing Bias" 
     }
     
    const averageScoreWithoutSign = Math.abs(averageScore); 
    let averageBannerColor = "green";
    if (averageScoreWithoutSign >= 26 && averageWing === "Right Wing Bias") {
      averageBannerColor = "red";
    } else if (averageScoreWithoutSign >= 26 && averageWing === "Left Wing Bias") {
      averageBannerColor = "orange";
    }
    
    let politicalCardClass = classes.politicalCard;
    let titleClass = classes.title;
    let averageClass = classes.average;

    if (branch === ARTICLE_BRANCH_YOUTUBE) {
      politicalCardClass += ` ${classes.politicalCardYoutube}`;
    } else if (branch === ARTICLE_BRANCH_PODCAST) {
      politicalCardClass += ` ${classes.politicalCardPodcast}`;
    } else if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      politicalCardClass += ` ${classes.politicalCardNewspaper}`;
    } else if (branch === ARTICLE_BRANCH_TWITCH) {
      politicalCardClass += ` ${classes.politicalCardTwitch}`;
    }
    else if (branch === ARTICLE_BRANCH_TWITTER) {
      politicalCardClass += ` ${classes.politicalCardTwitter}`;
    }
    else if (branch === ARTICLE_BRANCH_INSTAGRAM) {
      politicalCardClass += ` ${classes.politicalCardInstagram}`;
    }
    else if (branch === ARTICLE_BRANCH_MEDIUM) {
      politicalCardClass += ` ${classes.politicalCardMedium}`;
    }
    else if (branch === ARTICLE_BRANCH_REDDIT) {
      politicalCardClass += ` ${classes.politicalCardReddit}`;
    }
    else if (branch === ARTICLE_BRANCH_HACKERNEWS) {
      politicalCardClass += ` ${classes.politicalCardHackerNews}`;
    }
    else if (branch === ARTICLE_BRANCH_SLASHDOT) {
      politicalCardClass += ` ${classes.politicalCardSlashdot}`;
    }
    else if (branch === ARTICLE_BRANCH_RSSFEED) {
      politicalCardClass += ` ${classes.politicalCardRSSFeed}`;
    }
    else if (branch === ARTICLE_BRANCH_TIKTOK) {
      politicalCardClass += ` ${classes.politicalCardTikTok}`;
    }
    else if (branch === ARTICLE_BRANCH_PINTEREST) {
      politicalCardClass += ` ${classes.politicalCardPinterest}`;
    }
    else if (branch === ARTICLE_BRANCH_VSCO) {
      politicalCardClass += ` ${classes.politicalCardVSCO}`;
    }
    else if (branch === ARTICLE_BRANCH_SPOTIFY) {
      politicalCardClass += ` ${classes.politicalCardSpotify}`;
    }
    else if (branch === ARTICLE_BRANCH_QUORA) {
      politicalCardClass += ` ${classes.politicalCardQuora}`;
    }
    else if (branch === ARTICLE_BRANCH_FACEBOOK) {
      politicalCardClass += ` ${classes.politicalCardFacebook}`;
    }
    else if (branch === ARTICLE_BRANCH_SUBSTACK) {
      politicalCardClass += ` ${classes.politicalCardSubstack}`;
    }
    else if (branch === ARTICLE_BRANCH_GMAIL) {
      politicalCardClass += ` ${classes.politicalCardGmail}`;
    }
    else if (branch === ARTICLE_BRANCH_VIMEO) {
      politicalCardClass += ` ${classes.politicalCardVimeo}`;
    }
    else if (branch === ARTICLE_BRANCH_WEBSITE) {
      politicalCardClass += ` ${classes.politicalCardWebsite}`;
    }
    else if (branch === ARTICLE_BRANCH_MASTODON) {
      politicalCardClass += ` ${classes.politicalCardMastodon}`;
    }
    else if (branch === ARTICLE_BRANCH_BLUESKY) {
      politicalCardClass += ` ${classes.politicalCardBluesky}`;
    }
    else if (branch === ARTICLE_BRANCH_USERPOST) {
      politicalCardClass += ` ${classes.politicalCardUserPost}`;
    }

    if (expanded) {
      titleClass += ` ${politicalCardClass}`;
      averageClass += ` ${politicalCardClass}`;
    }

    return (
      <div className={classes.root}>
        <button
          className={politicalCardClass}
          onClick={this.toggleExpanded}
          disabled={disabled}
        >
          {sentiment ?
            (<div>
              <div className={titleClass} style={{ marginLeft: expanded ? "15%" : "0" }}>
                <Typography className={classes.title} style={{ color: bannerColor }}>{`${wingBias === 'Balanced' ? 'Balanced' : `${sentiment.Score}% ${wingBias}`}`}</Typography>
              </div>
              <Typography
                className={`${classes.commentary} ${expanded ? classes.expanded : ""}`}
              >
                {sentiment && sentiment.Commentary}
              </Typography>
              {expanded &&
                <div className={classes.averagepolitics}>
                  <Typography style={{ fontWeight: 'bold' }}>Average for this source</Typography>
                  <div className={averageClass} style={{ marginLeft: expanded ? "15%" : "0" }}>
                    <Typography className={classes.average} style={{ color: averageBannerColor }}>
                      {`${averageWing === 'Balanced' ? 'Balanced' :`${averageScoreWithoutSign}% ${averageWing}`}`}
                    </Typography>
                  </div>
                </div>
              }
            </div>) : null}
        </button>
      </div>
    );
  }
}

PoliticalSentiment.propTypes = {
  article: PropTypes.object,
  source: PropTypes.object,
};

export default withStyles(styles)(PoliticalSentiment);
