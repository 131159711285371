import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { ToastContainer } from "react-toastify";
import { withAuthentication } from "session";
import { v4 as uuidv4 } from "uuid";
import { logger } from "utility/logging";
import {
  SearchResultAppBar,
  SearchArticleMasonary,
  ArticleList,
  DlgConfirm,
  WaitingSpinner,
  SearchAppBar,
} from "components";
import * as ROUTES from "constants/routes";
import {
  ARTICLE_BRANCH_NEWSPAPER,
  ARTICLE_BRANCH_USERPOST,
  ARTICLE_BRANCH_SEARCH,
} from "constants/branches";
//import { searchIndex } from "searchService";
import {
  GRAPHQL_SUCCESS,
  MAX_WINDOW_WIDTH,
  MIN_TABLET_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
  TAB_FEED,
  TAB_MASTODON_ALL,
  TAB_MASTODON_FEEDS,
  TAB_MASTODON_HASHTAGS,
  TAB_MASTODON_POSTS,
  TAB_MASTODON_SOURCES,
  TAB_NEWSRAVEN_POSTS,
  TAB_MASTODONALL_POSTS,
  TAB_BLUESKY_POSTS,
} from "constants/types";
import { BRANCH_ALL } from "constants/branches";
import { ALL } from "constants/country";
import {
  deleteArticle,
  deleteSavedArticle,
  existFeedbyID,
  existFeedbySlug,
  getAuthToken,
  isFeedModerator,
  reportArticle,
  repostArticle,
  saveArticle,
  upvoteArticle,
  hasPaid,
  getFeeds2show,
  isBanned,
  getMainInfo,
  unfollowFeed,
  followFeed,
  followSource,
  unfollowSource,
} from "dataapis";
import {
  createImageByAI,
  getFeednameFromAI,
  moderate_image,
  searchSources,
  search_articles,
  retrieveFollowdata,
  followRequest,
  unFollowRequest,
  blueskyGetFollows,
  blueskyUnfollows,
  blueskyFollows,
  blueskyGetFollowed,
  blueskyPutFollowed,
  searchFeeds,
  searchPosts,
  fetchMastodonSimilarPosts,
  refreshSession,
} from "utility/ravenapi";
import { ToastError, ToastInfo, ToastWarning } from "utility/toast";
import {
  Button,
  Grid,
  Toolbar,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import { GraphqlService } from "services";
import { gen_random_int, is_valid_slug, slugify } from "utility/utils";
import { resizeImageFile } from "utility/resizeimage";
import { ACTIVITY_ADD, ACTIVITY_TYPE_CATEGORY } from "constants/activity";
import { get_cached_item, set_cache_item } from "utility/cachemanager";
import {
  FollowFeedsList,
  MastodonShared,
  SearchArticleList,
} from "components/SharedControls";
import MastodonTab from "views/MastodonTab";
import SwipeableViews from "react-swipeable-views";
import MastodonHashtags from "views/MastodonHashtags";
import MastodonPosts from "components/MastodonPosts/MastodonPosts";
import { Block } from "@material-ui/icons";
import PostTab from "views/PostTab/PostTab";
import { Tabs, Tab, List } from "@material-ui/core";
import FeedTabItem from "views/Profile/components/FeedTabItem";
import SourceItem from "views/SourceItem/SourceItem";
import MastodonSourceItem from "views/MastodonSourceItem/MastodonSourceItem";
import { Link } from "@material-ui/core";
import BlueskyShared from "components/SharedControls/BlueskyShared/BlueskyShared";
import BlueskySourceItem from "views/BlueskySourceItem/BlueskySourceItem";
import BlueskyFeeds from "views/BlueskyFeeds/BlueskyFeeds";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  articlecontainer: {
    marginTop: theme.spacing(-2),
    marginLeft: 170,
  },
  addButton: {
    width: "25%",
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    margin: theme.spacing(2),
    borderRadius: 20,
    textTransform: "initial",
    zIndex: 9999,
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  addButtonForMobile: {
    width: "45%",
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    margin: theme.spacing(2),
    borderRadius: 20,
    textTransform: "initial",
    zIndex: 9999,
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  addButtonDisableForMobile: {
    width: "45%",
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    margin: theme.spacing(2),
    borderRadius: 20,
    textTransform: "initial",
    zIndex: 9999,
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
  addButtonDisable: {
    width: "25%",
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    margin: theme.spacing(2),
    borderRadius: 20,
    textTransform: "initial",
    zIndex: 9999,
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    margin: 0,
    padding: 1,
    width: 25,
    height: 25,
    marginLeft: theme.spacing(-0.5),
    marginTop: 22,
  },
  logo_text: {
    marginLeft: theme.spacing(2),
    marginTop: 10,
    height: 22,
    width: "auto",
    marginTop: 20,
  },
  text: {
    marginLeft: 5,
  },
  seeAllButtonContainer: {
    justifyContent: "center",
    width: "100%",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    marginBottom: 10,
  },
  seeAllButtonContainerr: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginLeft: "-20%",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    marginBottom: 10,
  },
  seeAllButtonContainerMobile: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    margin: 10,
  },
  seeAllDivider: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dividerLine: {
    width: "80%", // Adjust width as needed
    borderBottom: "1px solid #ccc", // Adjust styling as needed
    marginBottom: 10, // Adjust margin as needed
    marginLeft: "-20%",
  },
  dividerLineMobile: {
    width: "100%", // Adjust width as needed
    borderBottom: "1px solid #ccc", // Adjust styling as needed
    marginBottom: 10, // Adjust margin as needed
    // marginLeft:'-20%'
  },
  toolbar: {
    marginLeft: -20,
  },
  hashtagHeading: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2), // Adjust spacing as needed
    marginBottom: theme.spacing(1), // Adjust spacing as needed
  },
  warningtitle: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginLeft: "-20%",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    margin: 20,
  },
  warningtitlemobile: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    margin: 10,
  },
  warningimage: {
    width: "48px",
  },
  warningContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column", // Stack items vertically
    justifyContent: "center",
    alignItems: "center",
  },
});

class SearchResultPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      showConfirmDlg: false,
      searchArticles: [],
      tab_value: TAB_MASTODON_ALL,
      mastodonDetails: null,
      hashtags: null,
      history: null,
      waiting: false,
      mastodonMoreDetails: null,
      hashtagsMore: null,
      historyMore: null,
      isMaxWidthTrue: true,
      mastodonMoreeDetails: null,
      postLimit: null,
      tagLimit: null,
      statuses: null,
      postMoree: null,
      postMore: null,
      hashtagsMoree: null,
      innerTabValue: TAB_NEWSRAVEN_POSTS,
      searchFeeds: null,
      searchSources: null,
      newfollowing: false,
      followData: [],
      isUsernamePresent: false,
      currentPage: 1,
      currentPageSize: 6,
      currentFeedPageSize: 5,
      FeedPageSize: 100,
      showWarning: !this.props.searchKey,
      blueskyDetailss: null,
      blueskyDetails: null,
      blueskyDetailsss: null,
      blueskyMoreeDetails: null,
      postsMoree: null,
      feedsMoree: null,
      postLimits: null,
      sourceMoree: null,
      feedLimit: null,
      sourceLimit: null,
      blueskyMoreeDetailss: null,
      blueskyMoreeDetailsss: null,
      mastodonMoreeeDetails: null,
      sourceMoreee: null,
      searchSourcess: null,
      searchFeedss: null,
      blueskyfollows: null,
      blueskyFollowss: null,
      followDataa: null,
      isUsernamePresent: null,
      isFollowing: null,
      isFollowingg: null,
      followDataaa: null,
      newfollowingg: null,
      blueskyDid : null,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);

    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleNeedMore = this.handleNeedMore.bind(this);

    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOpenDialogue = this.handleOpenDialogue.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const { selected_feed, sources, searchKey, authUser, myfeedstab } = this.props;
    let sessionVariable = {
      refreshJwt:  authUser.bluesky_refresh_token,
      uid: authUser.uid,
    };
    let refreshBlueskySession = await refreshSession(sessionVariable);
    this.setState({blueskyDid:refreshBlueskySession?.sessionData?.did})
    let limit = 5;
    let limitMoree = 20;
    const { tab_value, innerTabValue } = this.state;
    this.props.selectMyfeedsTab(tab_value);
    this.props.selectProfileTab(innerTabValue);

    await getMainInfo();
    let selected_sources = [];
    if (selected_feed) {
      const source_ids = selected_feed.feed_sources
        .filter((feed_source) => feed_source.approved)
        .map((feed_source) => feed_source.source_id);
      selected_sources = source_ids.map((source_id) =>
        _.find(sources, { id: source_id })
      );
    }
    let param = {
      did:refreshBlueskySession?.sessionData?.did,
      accessJwt:authUser.bluesky_access_token,
      q: searchKey,
      limit: limit,
    };
    let paramMore = {
      accessJwt:authUser.bluesky_access_token,
      q: searchKey,
      limit: limitMoree,
      did:refreshBlueskySession?.sessionData?.did
    };

    let params = {};

    if (selected_sources.length === 0) {
      params = {
        keywords: this.props.searchKey,
        limit: 10
      };
    } else {
      // const source_ids = selected_sources.map(source => source.id);
      params = {
        keywords: this.props.searchKey,
        limit: 10
      };
    }
    if (searchKey) {
      this.setWaiting(true);
      this.setState({ showWarning: false });
      let limit = 5;
      let limitMoree = 10;
      const minimumArticleCount = 5;
      let articles = [];
      articles = await search_articles(params);
      if (articles?.length < minimumArticleCount) {
        const publishedDates = articles.map((article) => article.published);
        const lowestPublishedDate = Math.min(...publishedDates);
        params = {
          keywords: this.props.searchKey,
          published: lowestPublishedDate,
        };
        const additionalArticles = await search_articles(params);
        articles = articles.concat(additionalArticles);
      }
      if (!articles) {
        this.setError("Failed to get Search results.");
        return;
      } else {
        this.props.updateSearchResult(articles);
        this.setState({
          isDisabled: false,
          searchArticles: articles,
        });
        for (const article of articles) {
          let article_source = sources.find(
            (source) => source.id === article?.source_id
          );
          if (!article_source) {
            article_source = article.source;
            this.props.addSource(article_source);
          }
        }
      }
      let mastodonDetails = await fetchMastodonSimilarPosts(searchKey, limit);
      let statuses = mastodonDetails?.statuses;

      if (mastodonDetails && mastodonDetails.hashtags) {
        const { hashtags } = mastodonDetails;

        // Update state with mastodonDetails and hashtags
        this.setState({ mastodonDetails, hashtags, statuses, error: null });
        this.handleSources(searchKey);
        this.handleSourcee(searchKey);
        this.handleMoreHashtags(searchKey, limitMoree);

        this.handleMore(searchKey, limitMoree);
        this.handleFeeds(searchKey);
        this.handleFeedss(searchKey);
        let blueskyDetails = await searchPosts(param);

        this.setState({ blueskyDetails });
        let blueskyDetailss = await searchFeeds(param);
        this.setState({ blueskyDetailss });
        let blueskyDetailsss = await searchSources(param);
        this.setState({ blueskyDetailsss });
        this.handleMoreBlueskyPosts(paramMore);
        this.handleMoreBlueskyFeeds(paramMore);
        this.handleMoreBlueskySources(paramMore);

        try {
          let username = authUser.mastodon_username;

          if (username) {
            const follow = await retrieveFollowdata(username);
            this.setState({ followData: follow }, () => {
              const { mastodonDetails } = this.state;
              const mastodonAccountIds = mastodonDetails?.accounts?.map(
                (account) => account.id
              );
              this.setState({ isUsernamePresent: true });
              // Check if the user is already following any of the sources
              const isFollowing = follow.some((item) =>
                mastodonAccountIds?.includes(item.id)
              );

              this.setState({ newfollowing: isFollowing });
            });
          } else {
            console.log("Username is undefined or null.");
          }
        } catch (error) {
          console.error("Error retrieving follow data:", error);
        }
        try {
          let username = authUser.username;
          if (username) {
            const follows = await blueskyGetFollows(param);
            this.setState({ followDataa: follows.data.follows }, () => {
              const { blueskyDetailsss } = this.state;
              const blueskyAccountIds = blueskyDetailsss?.actors.map(
                (account) => account.did
              );
              this.setState({ isUsernamePresent: true });
              // Check if the user is already following any of the sources
              const isFollowing = follows.data.follows.some((item) =>
                blueskyAccountIds.includes(item.did)
              );
              this.setState({ newfollowing: isFollowing });
            });
          } else {
            console.log("Username is undefined or null.");
          }
        } catch (error) {
          console.error("Error retrieving follow data:", error);
        }
        try {
          let username = authUser.username;
          if (username) {
            const bluesyFollowed = await blueskyGetFollowed(param);
            if (bluesyFollowed?.data?.preferences && bluesyFollowed.data.preferences.length > 0) {
              const pinnedIndex = bluesyFollowed.data.preferences.findIndex(preference => preference.pinned);
              const pinnedValue = pinnedIndex !== -1 ? bluesyFollowed.data.preferences[pinnedIndex].pinned : null;
        
              this.setState(
                { followDataaa: pinnedValue },
                () => {
                  const { blueskyMoreeDetailss } = this.state;
                  const blueskyAccountIds = blueskyMoreeDetailss?.feeds?.map(
                    (account) => account.uri
                  );
        
                  this.setState({ isUsernamePresent: true });
                  // Check if the user is already following any of the sources
                  const isFollowingg = bluesyFollowed.data.preferences.some(
                    (item) => blueskyAccountIds?.includes(item.uri)
                  );
        
                  this.setState({ newfollowingg: isFollowingg });
                }
              );
            } else {
              // Handle the case when there are no preferences or pinned is not found
              console.log("No preferences found or pinned is not set.");
            }
          } else {
            console.log("Username is undefined or null.");
          }
        } catch (error) {
          console.error("Error retrieving follow data:", error);
        }
        this.setWaiting(false);
      }

      this.setWaiting(false);
    } else {
      this.setState({ showWarning: true });
    }
  };

  handleNeedMore = async () => {
    const { last_offset, requesting, articles, searchResults } = this.props;
    this.setState({ showWarning: false });
    // if (last_offset === 0) {
    //     return;
    // }
    const {tab_value} = this.state;
    if (tab_value === TAB_MASTODON_ALL) {
      this.setWaiting(false);
      return
    }
    if (requesting) {
        return;
    }
    if (searchResults.length > 0) {
      const publishedDates = searchResults.map((article) => article.published);
      const lowestPublishedDate = Math.min(...publishedDates);

      let params = {
        keywords: this.props.searchKey,
        limit: 10,
        published: lowestPublishedDate,
      };

      this.setWaiting(true);

      const minimumArticleCount = 5;
      let newArticles = [];
      newArticles = await search_articles(params);

      if (newArticles.length < minimumArticleCount) {
        const additionalParams = {
          keywords: this.props.searchKey,
          limit: 10,
          published: lowestPublishedDate,
        };
        const additionalArticles = await search_articles(additionalParams);
        newArticles = newArticles.concat(additionalArticles);
      }

      if (!newArticles) {
        this.setError("Failed to get Search results.");
        return;
      }

      const updatedSearchArticles = [...searchResults, ...newArticles];

      this.props.updateSearchResult(updatedSearchArticles);

      this.setState({
        isDisabled: false,
        searchArticles: updatedSearchArticles,
      });

      this.setWaiting(false);
    }
  };

  handleSelectArticle = (article) => {
    this.props.selectArticle(article);

    const { sources } = this.props;
    const article_source = sources.find(
      (source) => source.id === article.source_id
    );

    let path = `/${ROUTES.CATEGORY_PREFIX}/${article_source?.category_id}/${ROUTES.SOURCE_PREFIX}/${article_source?.slug}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.SEARCH_RESULT);
  };

  handleSelectGroupArticle = (article) => {};

  handleClose = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);
    this.props.showTopNavbar(true);
    this.props.initScrollPos();
    this.props.initSearchScrollPos();
  };

  handleReportArticle = async (article, reportMsg) => {
    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };

  handleEditArticle = (article) => {};

  handleDeleteArticle = async (article) => {
    this.setWaiting(true);
    await deleteArticle(article);
    this.setWaiting(false);
  };

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  };

  handleDeleteSavedArticle = async (article) => {
    this.setWaiting(true);
    await deleteSavedArticle(article);
    this.setWaiting(false);
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleCancel = () => {
    this.setState({
      ...this.state,
      showConfirmDlg: false,
    });
  };

  handleOpenDialogue = () => {
    this.setState({
      ...this.state,
      showConfirmDlg: true,
    });
  };

  handleCreateFeed = async () => {
    this.setState({
      ...this.state,
      showConfirmDlg: false,
    });

    try {
      this.setWaiting(true);
      this.setState({
        isDisabled: true,
      });
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      const { feeds, searchResults } = this.props;
      let params = {
        searchKey: this.props.searchKey,
      };
      const newFeedDetails = await getFeednameFromAI(params);
      if (!newFeedDetails?.success) {
        this.setState({
          isDisabled: false,
        });
        this.setWaiting(false);
        return;
      }

      if (newFeedDetails?.result?.status === 400) {
        this.setState({
          isDisabled: false,
        });
        this.setWaiting(false);
        this.setError(`Cannot create a feed since the prompt is not relevant.`);
        return;
      }

      // let newSourcesOfArticles = [...new Set(searchResults.map((item)=>item.source_id))]
      const name = newFeedDetails.result.feedName;
      // const imageUrl = newFeedDetails.result.generatedImage
      let slug = slugify(name);
      if (name.trim() === "") {
        this.setError("Feed name shouldn't be blank!");
        return;
      }
      let index = feeds.findIndex(
        (feed) => feed.name.toLowerCase() === name.trim().toLowerCase()
      );
      if (index !== -1) {
        this.setError(
          `The feeds name ${name} is already exist. Choose another one.`
        );
        return;
      }

      if (is_valid_slug(slug.trim()) === false) {
        this.setError(
          `Invalid feed url format, permits only alphabet, numeric, _, -, and + characters.`
        );
        return;
      }
      index = feeds.findIndex((feed) => feed.slug === slug.trim());
      if (index !== -1) {
        this.setError(
          `The feeds url "/f/${slug}" is already exist. Choose another one.`
        );
        return;
      }
      // const properties = {name,slug, emoji};
      // Mixpanel.track(MIXPANEL_EVENTS.CREATE_FEED,{properties});
      this.addFeed(name, slug, searchResults, token);
    } catch (err) {
      this.setError(`Something went wrong while creating the AI feed`);
      this.setWaiting(false);
      this.setState({
        isDisabled: false,
      });
      console.log("handle feed error", err);
    }
  };

  addFeed = async (name, slug, searchResults, token) => {
    const { authUser } = this.props;

    const gqlservice = new GraphqlService();

    gqlservice.set_auth_jwt(token);

    // this.setWaiting(true);

    const timestamp = Math.floor(Date.now() / 1000);

    const id = await this._makeFeedID(name.trim());
    if (!id) {
      this.setWaiting(false);
      return;
    }
    const feed_slug = await this._makeFeedSlug(slug.trim());
    if (!feed_slug) {
      this.setWaiting(false);
      return;
    }

    const isModerator = true;
    const CATEGORY_ID = "ai-feeds";

    // feed information
    const is_private = false;
    const feed = {
      id: id,
      category_id: CATEGORY_ID,
      name: name.trim(),
      description: this.props.searchKey?.trim(),
      slug: feed_slug,
      image: "",
      created_by: authUser.uid,
      tags: [],
      created_at: timestamp,
      private: is_private,
      approved: is_private ? isModerator : true,
    };

    // userpost source information
    const userpost_source = {
      id: `${feed.id}-userpost`,
      category_id: feed.category_id,
      name: `User posts on ${feed.name}`,
      description: `User posts on ${feed.name}`,
      slug: `${feed.id}-userpost`,
      image: feed.image,
      branch: ARTICLE_BRANCH_USERPOST,
      weblink: "",
      translateLang: "English",
      translateAPI: "",
      translate: false,
      frequency: -1,
      disableFullText: false,
      followers: 0,
      created_by: feed.created_by,
      created_at: feed.created_at,
      approved: true, // no need to approve for userpost source
    };

    // insert userpost source to feed source
    const currentTime = new Date().toISOString();
    const feed_source = {
      id: uuidv4(),
      feed_id: feed.id,
      source_id: userpost_source.id,
      created_by: authUser.uid,
      approved: true, // no need to approve for userpost source
      approved_by: authUser.uid,
      approved_at: currentTime,
    };

    let feed_sources = [];
    feed_sources.push(feed_source);

    // insert New feed sources from the articles
    let source_name = this.props.searchKey;
    let sourceSlug = slugify(source_name);
    const aiSource = {
      id: sourceSlug,
      category_id: feed.category_id,
      name: source_name,
      description: "Search Term",
      slug: sourceSlug,
      image: feed.image,
      branch: ARTICLE_BRANCH_SEARCH,
      weblink: "",
      translateLang: "English",
      translateAPI: "",
      translate: false,
      frequency: -1,
      disableFullText: false,
      followers: 0,
      created_by: feed.created_by,
      created_at: feed.created_at,
      approved: true, // no need to approve for userpost source
      is_published: false,
    };

    feed_sources.push({
      id: uuidv4(),
      feed_id: feed.id,
      source_id: sourceSlug,
      created_by: authUser.uid,
      approved: true,
      approved_by: authUser.uid,
      approved_at: currentTime,
    });

    let socialtag = {
      id: aiSource.id,
      type: "search_term_source",
      tag: this.props.searchKey,
    };

    try {
      //insert ai source
      let aiSourceResult = await gqlservice.insert_source(aiSource);
      if (aiSourceResult.status_code !== GRAPHQL_SUCCESS) {
        logger.error(
          "Failed to create ai source :",
          aiSource.id,
          aiSource.name
        );
        return null;
      } else if (aiSourceResult.data.insert_sources.affected_rows === 0) {
        logger.error("Failed to create a source :", aiSource.id, aiSource.name);
        return null;
      }
      //const inserted_source = result.data.insert_sources.returning[0];
      let result = await gqlservice.insert_socialtag(socialtag);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        logger.error("Failed to create a socialtag :", socialtag);
        return null;
      } else if (result.data.insert_socialtags.affected_rows === 0) {
        logger.error("Failed to create a socialtag :", socialtag);
        return null;
      }

      // insert userpost source
      result = await gqlservice.insert_source(userpost_source);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        this.setState({
          isDisabled: false,
        });
        this.setWaiting(false);
        this.setError("Failed to insert feed(userpost)!");
        return;
      }
      this.props.addSource(userpost_source);

      // insert feed
      result = await gqlservice.insert_feed(feed);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        this.setError("Failed to insert feed!");
        this.setState({
          isDisabled: false,
        });
        return;
      } else if (result.data.insert_feeds_one.length === 0) {
        this.setState({
          isDisabled: false,
        });
        this.setWaiting(false);
        this.setError("Failed to insert feed!");
        return;
      }
      if (feed_sources.length > 0) {
        logger.log("feed sources :", feed_sources);
        result = await gqlservice.insert_feed_sources(feed_sources);
        logger.log("insert feed sources result :", result);
        if (result.status_code !== GRAPHQL_SUCCESS) {
          this.setError("Failed to insert feed sources!");
          // this.setError(result.msg);
          return;
        } else if (result.data.insert_feed_sources.affected_rows === 0) {
          this.setWaiting(false);
          this.setError("Failed to insert feed sources!");
          return;
        }
      }

      // insert feed moderator
      const feed_moderator = {
        id: uuidv4(),
        feed_id: feed.id,
        user_id: authUser.uid,
        approved: true,
        approved_by: authUser.uid,
        owner: true,
      };

      result = await gqlservice.insert_feed_moderator(feed_moderator);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        this.setError("Failed to insert feed moderator!");
        this.setWaiting(false);
        // this.setError(result.msg);
        return;
      } else if (result.data.insert_feed_moderators.affected_rows === 0) {
        this.setError("Failed to insert feed moderator!");
        return;
      }

      searchResults.map(async (feedArticle) => {
        const feed_article = {
          feed_id: feed.id,
          article_id: feedArticle.nid,
          branch: feedArticle.branch,
          published: feedArticle.published,
          crawled_at: feedArticle.crawled_at,
        };
        await gqlservice.insert_feed_article(feed_article);
      });
      // get feed
      result = await gqlservice.feed_by_id(feed.id);
      logger.log("get feed result :", result);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        this.setError("Failed to insert feed moderator!");
        this.setWaiting(false);
        // this.setError(result.msg);
        return;
      }
      const feeds = result.data.feeds;
      if (feeds.length > 0) {
        this.props.addFeed(feeds[0]);
        this.props.addFeedModerated(feeds[0]);
      }

      // insert activity log
      const activity = {
        user_id: authUser.uid,
        type: ACTIVITY_TYPE_CATEGORY,
        type_id: "ai-feeds",
        action: ACTIVITY_ADD,
        object: `the feed ${feed.name}`,
        fromto: `to the category add-feedsp`,
        reason: "",
      };
      result = await gqlservice.insert_activitylog(activity);

      const { loggedIn } = this.props;
      let cache_name = loggedIn
        ? hasPaid()
          ? "base_data_paiduser_v1"
          : "base_data_user_v1"
        : "base_data_anonymous_v1";
      let base_data = await get_cached_item(cache_name);
      if (base_data) {
        let base_feeds = base_data.feeds;
        base_feeds.push(feeds[0]);
        base_data.feeds = base_feeds;
        set_cache_item(cache_name, base_data);
      }
    } catch (err) {
      this.setState({
        isDisabled: false,
      });
      this.setWaiting(false);
      this.setError(JSON.stringify(err));
      return;
    }

    this.setWaiting(false);
    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();

    let route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;

    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this._generateAnduploadImage(name, feed.id, aiSource.id, gqlservice);
    this.props.history.push(location);
  };

  _generateAnduploadImage = async (name, feedId, sourceId, gqlservice) => {
    const { firebase, loggedIn } = this.props;
    let params = {
      feedName: name,
    };
    const image = await createImageByAI(params);
    if (!image.success) {
      this.setError("Something went wrong while creating AI feed image");
      return;
    }
    const base64ImageWithPrefix = `data:image/jpeg;base64, ${image?.generatedImage}`;

    // Convert the base64 string to bytes
    const bytes = atob(image?.generatedImage);

    // Create a Uint8Array from the bytes
    const uint8Array = new Uint8Array(bytes.length);
    for (let i = 0; i < bytes.length; i++) {
      uint8Array[i] = bytes.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: "image/jpeg" }); // Specify the appropriate MIME type

    // Create a File object from the Blob
    const file = new File([blob], "image.jpg", { type: "image/jpeg" }); // Specify the appropriate file name
    let new_image = base64ImageWithPrefix;
    if (new_image === RAVEN_PLACEHOLDER_IMAGE) {
      new_image = "";
    }
    if (file) {
      const resized_image = await resizeImageFile(file);
      const result = await firebase.uploadImage(resized_image, "feeds");
      if (result.error) {
        this.setError("Failed to upload feed image.");
        return;
      }

      new_image = result.url;
      const modresult = await moderate_image(new_image);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError(
          "Image not allowed, because it contains adults or racy content."
        );
        await firebase.deleteImage(new_image);
        new_image = "";
      }
    }
    const result = await gqlservice.update_feed_image(feedId, new_image);
    if (result.data.update_feeds.affected_rows > 0) {
      this.props.updateFeedImage(feedId, new_image);
      let cache_name = loggedIn
        ? hasPaid()
          ? "base_data_paiduser_v1"
          : "base_data_user_v1"
        : "base_data_anonymous_v1";
      let base_data = await get_cached_item(cache_name);
      if (base_data) {
        let base_feeds = base_data.feeds;
        const updatedFeedImage = base_feeds.map((item) => {
          if (item.id === feedId) {
            item.image = new_image;
          }
          return item;
        });
        base_data.feeds = updatedFeedImage;
        await set_cache_item(cache_name, base_data);
      }
    }

    await gqlservice
      .update_source_image(sourceId, new_image)
      .then(
        async (result) => {
          const update_source = result.data.update_sources
            ? result.data.update_sources.returning
            : [];
          if (update_source.length > 0) {
            this.props.updateSource(update_source[0]);
            let cache_name = loggedIn
              ? hasPaid()
                ? "base_data_paiduser_v1"
                : "base_data_user_v1"
              : "base_data_anonymous_v1";
            let base_data = await get_cached_item(cache_name);
            if (base_data) {
              let sources = base_data.sources;
              let source_index = sources.findIndex(
                (src) => src.id === sourceId
              );
              if (source_index !== -1) {
                sources[source_index] = update_source[0];
              }
              base_data.sources = sources;
              set_cache_item(cache_name, base_data);
            }
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
  };

  _makeFeedID = async (name) => {
    let id = slugify(name);
    let result = await existFeedbyID(id);
    if (result.error) {
      this.setError(result.message);
      return "";
    }

    var rand_id = "";
    while (result.exist) {
      rand_id = `${id}-${gen_random_int(1000).toString()}`;
      result = await existFeedbyID(rand_id);
      if (result.error) {
        this.setError(result.message);
        return "";
      }
    }

    return rand_id ? rand_id : id;
  };

  _makeFeedSlug = async (slug) => {
    let result = await existFeedbySlug(slug);
    if (result.error) {
      this.setError(result.message);
      return "";
    }

    var rand_slug = "";
    while (result.exist) {
      rand_slug = `${slug}-${gen_random_int(1000).toString()}`;
      result = await existFeedbySlug(rand_slug);
      if (result.error) {
        this.setError(result.message);
        return "";
      }
    }

    return rand_slug ? rand_slug : slug;
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleClickFeed = (feed) => {
    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // Go to moderation page if the logged user is the moderator of the feed
    if (isFeedModerator(feed)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setFeedBackRoute(ROUTES.SEARCH_RESULT);
    this.props.refreshArticles();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickSource = (source) => {
    this.props.selectSource(source);
    this.props.addSource(source)
    const path = `/${ROUTES.SOURCE_PREFIX}/${source.slug}`;

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setSourceBackRoute(ROUTES.SEARCH_RESULT);
  };

  handleClickUpvote = async (article) => {
    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  };

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  };

  handleClickRepost = async (article) => {
    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  };
  handleSearchChange = (searchKey) => {};
  handleSearchEnter = async (searchKey) => {
    const { authUser } = this.props;
    let limit = 5;
    let limitMoree = 10;
    let sessionVariable = {
      refreshJwt:  authUser.bluesky_refresh_token,
      uid: authUser.uid,
    };
    let refreshBlueskySession = await refreshSession(sessionVariable);
    this.setState({blueskyDid:refreshBlueskySession.sessionData.did})
    let param = {
      did:refreshBlueskySession.sessionData.did,
      accessJwt: authUser.bluesky_access_token,
      q: searchKey,
      limit: limit,
    };
    let paramMore = {
      accessJwt: authUser.bluesky_access_token,
      q: searchKey,
      limit: limitMoree,
      did:refreshBlueskySession.sessionData.did
    };
    
    try {
      let params = {
        keywords: searchKey,
        limit: 10
      };
      this.setWaiting(true);

      // Reset existing search results
      this.props.updateSearchKey("");
      this.props.updateSearchResult([]);

      // Reset Mastodon details and hashtags
      this.setState({
        mastodonDetails: null,
        hashtags: null,
        error: null,
        limit: null,
        tagLimit: null,
        postLimit: null,
        currentPageSize: 6,
        currentPageSizee: 100,
        currentFeedPageSize: 5,
        FeedPageSize: 100,
        showWarning: false,
        blueskyDetails:null
      });
      this.setState({
        mastodonMoreDetails: null,
        hashtagsMore: null,
        error: null,
      });
      let searchResults = await search_articles(params);
      this.props.updateSearchKey(searchKey);
      this.props.updateSearchResult(searchResults);

      // Fetch Mastodon details
      let mastodonDetails = await fetchMastodonSimilarPosts(searchKey, limit);
      let statuses = mastodonDetails.statuses;

      if (mastodonDetails && mastodonDetails.hashtags) {
        const { hashtags } = mastodonDetails;

        // Update state with mastodonDetails and hashtags
        this.setState({ mastodonDetails, hashtags, statuses, error: null });
        this.handleSources(searchKey);
        this.handleSourcee(searchKey);
        this.handleMoreHashtags(searchKey, limitMoree);
        this.handleFeeds(searchKey);
        this.handleFeedss(searchKey);
        this.handleMore(searchKey, limitMoree);
        if (authUser.username) {
          let blueskyDetails = await searchPosts(param);

          this.setState({ blueskyDetails });
          let blueskyDetailss = await searchFeeds(param);

          this.setState({ blueskyDetailss });
          let blueskyDetailsss = await searchSources(param);
          this.setState({ blueskyDetailsss });
          this.handleMoreBlueskyPosts(paramMore);
          this.handleMoreBlueskyFeeds(paramMore);
          this.handleMoreBlueskySources(paramMore);
          try {
            let username = authUser.mastodon_username;
            if (username) {
              const follow = await retrieveFollowdata(username);
              this.setState({ followData: follow }, () => {
                const { mastodonDetails } = this.state;
                const mastodonAccountIds = mastodonDetails?.accounts.map(
                  (account) => account.id
                );
                this.setState({ isUsernamePresent: true });
                // Check if the user is already following any of the sources
                const isFollowing = follow.some((item) =>
                  mastodonAccountIds.includes(item.id)
                );

                this.setState({ newfollowing: isFollowing });
              });
            } else {
              console.log("Username is undefined or null.");
            }
          } catch (error) {
            console.error("Error retrieving follow data:", error);
          }
          try {
            let username = authUser.username;
            if (username) {
              const follows = await blueskyGetFollows(param);
              this.setState({ followDataa: follows.data.follows }, () => {
                const { blueskyDetailsss } = this.state;
                const blueskyAccountIds = blueskyDetailsss?.actors.map(
                  (account) => account.did
                );
                this.setState({ isUsernamePresent: true });
                // Check if the user is already following any of the sources
                const isFollowing = follows.data.follows.some((item) =>
                  blueskyAccountIds.includes(item.did)
                );
                this.setState({ newfollowing: isFollowing });
              });
            } else {
              console.log("Username is undefined or null.");
            }
          } catch (error) {
            console.error("Error retrieving follow data:", error);
          }
          try {
            let username = authUser.username;
            if (username) {
              const bluesyFollowed = await blueskyGetFollowed(param);
              if (bluesyFollowed?.data?.preferences && bluesyFollowed.data.preferences.length > 0) {
                const pinnedIndex = bluesyFollowed.data.preferences.findIndex(preference => preference.pinned);
                const pinnedValue = pinnedIndex !== -1 ? bluesyFollowed.data.preferences[pinnedIndex].pinned : null;
          
                this.setState(
                  { followDataaa: pinnedValue },
                  () => {
                    const { blueskyMoreeDetailss } = this.state;
                    const blueskyAccountIds = blueskyMoreeDetailss?.feeds?.map(
                      (account) => account.uri
                    );
          
                    this.setState({ isUsernamePresent: true });
                    // Check if the user is already following any of the sources
                    const isFollowingg = bluesyFollowed.data.preferences.some(
                      (item) => blueskyAccountIds?.includes(item.uri)
                    );
          
                    this.setState({ newfollowingg: isFollowingg });
                  }
                );
              } else {
                // Handle the case when there are no preferences or pinned is not found
                console.log("No preferences found or pinned is not set.");
              }
            } else {
              console.log("Username is undefined or null.");
            }
          } catch (error) {
            console.error("Error retrieving follow data:", error);
          }
        }
      } else {
        console.error("Hashtags not found in Mastodon details");
      }
    } catch (error) {
      console.error("Error handling search:", error);
      this.setState({ mastodonDetails: null, error });
      this.setState({ hashtags: null, error });
      this.setState({ history: null, error });
    }
    this.setWaiting(false);
    if((this.state.blueskyDetails===null)&&(this.state.blueskyDetailss===null)&&(this.state.blueskyMoreeDetailsss===null)){
      ToastInfo("Bluesky Rate Limit Exceeded");
    }
  };

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleChangeTab = (tab_value) => {
    if (tab_value === !this.state.tab_value) {
      return;
    }

    this.setState(
      {
        ...this.state,
        searchKey: "",
      },
      async () => {
        window.scroll(0, 0);
        this.props.selectMyfeedsTab(tab_value);
        this.props.initScrollPos();
      }
    );

    if (tab_value === TAB_MASTODON_ALL) {
    } else if (tab_value === TAB_MASTODON_FEEDS) {
    } else if (tab_value === TAB_MASTODON_SOURCES) {
    } else if (tab_value === TAB_MASTODON_HASHTAGS) {
    } else if (tab_value === TAB_MASTODON_POSTS) {
    }

    this.setState({ tab_value });
  };
  handleChangeTabs = (innerTabValue) => {
    if (innerTabValue === !this.state.innerTabValue) {
      return;
    }

    this.setState(
      {
        ...this.state,
        searchKey: "",
      },
      async () => {
        window.scroll(0, 0);
        this.props.selectProfileTab(innerTabValue);
        this.props.initScrollPos();
      }
    );

    if (innerTabValue === TAB_NEWSRAVEN_POSTS) {
    }

    this.setState({ innerTabValue });
  };

  handleSwiped = (tabIndex) => {
    this.handleChangeTab(tabIndex);
  };
  handleSwipedd = (tabIndex) => {
    this.handleChangeTabs(tabIndex);
  };

  handleMoreHashtags = async (searchKey, limit) => {
    try {
      let params = {
        keywords: searchKey,
      };
      this.setWaiting(true);

      // Fetch Mastodon details
      let mastodonMoreDetails = await fetchMastodonSimilarPosts(
        searchKey,
        limit
      );

      let hashtagsMoree = mastodonMoreDetails.hashtags;

      // Update state with mastodonDetails and hashtags
      this.setState({ mastodonMoreDetails, hashtagsMoree, error: null });
    } catch (error) {
      console.error("Error handling search:", error);
      // Update state with null values and error message for error handling
      this.setState({
        mastodonMoreDetails: null,
        hashtagsMoree: null,
        historyMore: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      // Make sure to set waiting state to false regardless of success or failure
      this.setWaiting(false);
    }
  };
  handleMore = async (searchKey, limit) => {
    try {
      let params = {
        keywords: searchKey,
      };
      this.setWaiting(true);

      // Fetch Mastodon details
      let mastodonMoreeDetails = await fetchMastodonSimilarPosts(
        searchKey,
        limit
      );
      let postMoree = mastodonMoreeDetails.statuses;

      const { hashtagsMore } = mastodonMoreeDetails;

      // Update state with mastodonDetails and hashtags
      this.setState({
        mastodonMoreeDetails,
        hashtagsMore,
        postMoree,
        error: null,
      });
    } catch (error) {
      console.error("Error handling search:", error);
      // Update state with null values and error message for error handling
      this.setState({
        mastodonMoreeDetails: null,
        hashtagsMore: null,
        historyMore: null,
        postMoree: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      // Make sure to set waiting state to false regardless of success or failure
      this.setWaiting(false);
    }
  };
  handleMorePosts = async () => {
    const { searchKey } = this.props;
    const { tagLimit } = this.state;
    let limit = tagLimit + 15;
    if (limit > 100) {
      return;
    }

    try {
      let params = {
        keywords: searchKey,
      };
      this.setWaiting(true);

      // Fetch Mastodon details
      let mastodonMoreDetails = await fetchMastodonSimilarPosts(
        searchKey,
        limit
      );

      let hashtagsMoree = mastodonMoreDetails.hashtags;

      // Update state with mastodonDetails and hashtags
      this.setState({
        mastodonMoreDetails,
        hashtagsMoree,
        tagLimit: limit,
        error: null,
      });
    } catch (error) {
      console.error("Error handling search:", error);
      // Update state with null values and error message for error handling
      this.setState({
        mastodonMoreDetails: null,
        hashtagsMoree: null,
        historyMore: null,
        tagLimit: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      // Make sure to set waiting state to false regardless of success or failure
      this.setWaiting(false);
    }
  };
  handleMoreMastodonPosts = async () => {
    const { searchKey } = this.props;
    let { postLimit } = this.state;

    // Calculate the new limit by adding 15
    const limit = postLimit + 15;

    // Check if the new limit exceeds 50
    if (limit > 100) {
      return; // Exit the function if the limit exceeds 50
    }

    try {
      // Set waiting state to true before fetching data
      this.setWaiting(true);

      // Fetch Mastodon details with the incremented limit
      let mastodonMoreeDetails = await fetchMastodonSimilarPosts(
        searchKey,
        limit
      );
      let postMoree = mastodonMoreeDetails.statuses;
      // Check if mastodonDetails and hashtags are not null/undefined
      const { hashtagsMore } = mastodonMoreeDetails;

      // Update state with mastodonDetails, hashtags, and the new postLimit
      this.setState({
        mastodonMoreeDetails,
        hashtagsMore,
        postMoree,
        postLimit: limit,
        error: null,
      });
    } catch (error) {
      console.error("Error handling search:", error);

      // Update state with null values and error message for error handling
      this.setState({
        mastodonMoreeDetails: null,
        hashtagsMore: null,
        postMoree: null,
        historyMore: null,
        postLimit: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      // Set waiting state to false after fetching data
      this.setWaiting(false);
    }
  };
  handleMoreBlueskyPosts = async (param) => {
    // let limit = 20;
    try {
      this.setWaiting(true);

      // Fetch Mastodon details
      let blueskyMoreeDetails = await searchPosts(param);
      let postsMoree = blueskyMoreeDetails?.posts;

      // Update state with mastodonDetails and hashtags
      this.setState({
        blueskyMoreeDetails,

        postsMoree,
        error: null,
      });
    } catch (error) {
      console.error("Error handling search:", error);
      // Update state with null values and error message for error handling
      this.setState({
        blueskyMoreeDetails: null,

        historyMore: null,
        postsMoree: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      // Make sure to set waiting state to false regardless of success or failure
      this.setWaiting(false);
    }
  };
  handleMoreeBlueskyPosts = async () => {
    const { searchKey, requesting, authUser } = this.props;
    let { postLimits } = this.state;
    let param = {
      accessJwt: authUser.bluesky_access_token,
      q: searchKey,
      limit: 30,
    };
    if (requesting) {
      return;
    }
    // Calculate the new limit by adding 15
    const limit = postLimits + 50;

    // Check if the new limit exceeds 50
    if (limit > 100) {
      return; // Exit the function if the limit exceeds 50
    }

    try {
      // Set waiting state to true before fetching data
      this.setWaiting(true);

      // Fetch Mastodon details with the incremented limit
      let blueskyMoreeDetails = await searchPosts(param);
      let postsMoree = blueskyMoreeDetails?.posts;
      // Update state with mastodonDetails, hashtags, and the new postLimit
      this.setState({
        blueskyMoreeDetails,
        postsMoree,
        postLimits: limit,
        error: null,
      });
    } catch (error) {
      console.error("Error handling search:", error);

      // Update state with null values and error message for error handling
      this.setState({
        blueskyMoreeDetails: null,
        postLimits: null,
        postsMoree: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      // Set waiting state to false after fetching data
      this.setWaiting(false);
    }
  };

  handleMoreBlueskyFeeds = async (searchKey, limit) => {
    // let limit = 20;
    let feedLimit = limit;
    try {
      let params = {
        keywords: searchKey,
      };
      this.setWaiting(true);

      // Fetch Mastodon details
      let blueskyMoreeDetailss = await searchFeeds(searchKey, limit);
      let feedsMoree = blueskyMoreeDetailss?.feeds;

      // Update state with mastodonDetails and hashtags
      this.setState({
        blueskyMoreeDetailss,
        feedLimit,
        feedsMoree,
        error: null,
      });
    } catch (error) {
      console.error("Error handling search:", error);

      this.setState({
        blueskyMoreeDetailss: null,
        feedLimit: null,
        historyMore: null,
        feedsMoree: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      this.setWaiting(false);
    }
  };

  handleMoreeBlueskyFeeds = async () => {
    const { searchKey, requesting,authUser} = this.props;
    let { feedLimit } = this.state;
    let accessJwt=authUser.bluesky_access_token

    if (requesting) {
      return;
    }

    const limit = feedLimit + 50;

    if (limit > 100) {
      return;
    }

    try {
      this.setWaiting(true);

      Promise.resolve().then(async () => {
        let blueskyMoreeDetailss = await searchFeeds(searchKey, limit);

        let feedsMoree = blueskyMoreeDetailss?.feeds;
        // Update state with mastodonDetails, hashtags, and the new postLimit
        this.setState({
          blueskyMoreeDetailss,
          feedsMoree,
          feedLimit: limit,
          error: null,
        });

        // Set waiting state to false after fetching data
        this.setWaiting(false);
      });
    } catch (error) {
      console.error("Error handling search:", error);

      // Update state with null values and error message for error handling
      this.setState({
        blueskyMoreeDetailss: null,
        feedLimit: null,
        feedsMoree: null,
        error: "Error handling search: " + error.message,
      });

      // Set waiting state to false if there's an error
      this.setWaiting(false);
    }
  };

  handleMoreBlueskySources = async (param) => {
    // let limit = 20;

    try {
      // let params = {
      //   keywords: searchKey,
      // };
      this.setWaiting(true);

      // Fetch Mastodon details
      let blueskyMoreeDetailsss = await searchSources(param);
      let sourceMoree = blueskyMoreeDetailsss?.actors;

      // Update state with mastodonDetails and hashtags
      this.setState({
        blueskyMoreeDetailsss,
        sourceLimit: 20,
        sourceMoree,
        error: null,
      });
    } catch (error) {
      console.error("Error handling search:", error);
      // Update state with null values and error message for error handling
      this.setState({
        blueskyMoreeDetailsss: null,
        sourceLimit: null,
        historyMore: null,
        sourceMoree: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      // Make sure to set waiting state to false regardless of success or failure
      this.setWaiting(false);
    }
  };
  handleMoreeBlueskySources = async () => {
    const { searchKey, requesting, authUser } = this.props;
    let { sourceLimit } = this.state;
    let param = {
      q: searchKey,
      limit: sourceLimit,
      accessJwt: authUser.bluesky_access_token,
      
    };
    if (requesting) {
      return;
    }
    const limit = sourceLimit + 50;

    if (limit > 100) {
      return;
    }
    try {
      this.setWaiting(true);

      // Fetch Bluesky details
      let blueskyMoreeDetailsss = await searchSources(param);
      let sourceMoree = blueskyMoreeDetailsss?.actors;

      // Update state with blueskyDetails and sourceMore
      this.setState({
        blueskyMoreeDetailsss,
        sourceLimit: limit,
        sourceMoree,
        error: null,
      });
    } catch (error) {
      console.error("Error handling search:", error);
      // Update state with null values and error message for error handling
      this.setState({
        blueskyMoreeDetailsss: null,
        sourceLimit: null,
        sourceMoree: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      // Make sure to set waiting state to false regardless of success or failure
      this.setWaiting(false);
    }
  };

  handleSeeAllClick = async (tab, searchKey, mastodonDetails,innerTab) => {
    let limit = 30;
    this.handleSwiped(tab); // Call the previous function
    await this.handleMoreHashtags(searchKey, limit);
    this.handleSwipedd(innerTab);
  };

  handleInnerTabChange = (event, newValue) => {
    this.setState({ innerTabValue: newValue });
  };
  handleFeeds = (searchKey) => {
    let { currentPage, currentFeedPageSize } = this.state;
    const { feeds } = this.props;
    const uniqueFeedsMap = new Map();
    const lowercaseSearchKey = searchKey.toLowerCase();
    // Filter sources based on search criteria and add them to the uniqueSourcesMap
    feeds.forEach((feed) => {
      const lowercaseName = feed.name.toLowerCase();
      const lowercaseDescription = feed.description
        ? feed.description.toLowerCase()
        : "";
      if (
        lowercaseName.includes(lowercaseSearchKey) ||
        (lowercaseDescription &&
          lowercaseDescription.includes(lowercaseSearchKey))
      ) {
        uniqueFeedsMap.set(feed.id, feed);
      }
    });

    // Convert the map values to an array to ensure all sources are included at least once
    const searchFeeds = Array.from(uniqueFeedsMap.values());
    const paginatedFeeds =
      searchFeeds.length >= currentPage + currentFeedPageSize
        ? searchFeeds.slice(currentPage, currentPage + currentFeedPageSize)
        : searchFeeds;

    // Update state with the new data, page, and page size
    this.setState({
      searchFeeds: paginatedFeeds,
      currentPage: currentPage,
      currentFeedPageSize: currentFeedPageSize,
    });
  };

  handleFeedss = (searchKey) => {
    let { currentPage, FeedPageSize } = this.state;

    this.setState({ FeedPageSize: FeedPageSize });
    const { feeds } = this.props;
    const uniqueFeedsMap = new Map();
    const lowercaseSearchKey = searchKey.toLowerCase();

    // if (articles.length <= 5) {
    // Filter sources based on search criteria and add them to the uniqueSourcesMap
    feeds.forEach((feed) => {
      const lowercaseName = feed.name.toLowerCase();
      const lowercaseDescription = feed.description
        ? feed.description.toLowerCase()
        : "";
      if (
        lowercaseName.includes(lowercaseSearchKey) ||
        (lowercaseDescription &&
          lowercaseDescription.includes(lowercaseSearchKey))
      ) {
        uniqueFeedsMap.set(feed.id, feed);
      }
    });
    // }

    // Convert the map values to an array to ensure all sources are included at least once
    const searchFeedss = Array.from(uniqueFeedsMap.values());

    // // Paginate the data
    // const startIndex = (currentPage - 1) * currentPageSize;
    // const endIndex = startIndex + currentPageSize;
    const paginatedFeeds =
      searchFeedss.length >= currentPage + FeedPageSize
        ? searchFeedss.slice(currentPage, currentPage + FeedPageSize)
        : searchFeedss;

    // Update state with the new data, page, and page size
    this.setState({
      searchFeedss: paginatedFeeds,
      currentPage: currentPage,
      FeedPageSize: FeedPageSize,
    });
  };

  handleFeedsss = () => {
    let { currentPage, FeedPageSize, searchFeedss } = this.state;
    const { searchKey } = this.props;

    // If current page size exceeds 50, return without fetching more data
    if (FeedPageSize > 100) {
      return;
    }

    // If current page size exceeds 5, increase the page size by 30
    if (FeedPageSize > 5) {
      FeedPageSize = FeedPageSize + 30;
    }
    this.setWaiting(true); // Indicate that data fetching is in progress

    const { feeds } = this.props;
    const uniqueFeedsMap = new Map();
    const lowercaseSearchKey = searchKey.toLowerCase();

    // Filter sources based on search criteria and add them to the uniqueSourcesMap
    feeds.forEach((feed) => {
      const lowercaseName = feed.name.toLowerCase();
      const lowercaseDescription = feed.description
        ? feed.description.toLowerCase()
        : "";
      if (
        lowercaseName.includes(lowercaseSearchKey) ||
        (lowercaseDescription &&
          lowercaseDescription.includes(lowercaseSearchKey))
      ) {
        uniqueFeedsMap.set(feed.id, feed);
      }
    });

    // Convert the map values to an array to ensure all sources are included at least once
    const filteredFeeds = Array.from(uniqueFeedsMap.values());

    // Append the new sources to existing searchSources
    searchFeedss = searchFeedss.concat(filteredFeeds);

    // Update state with the new data, page, and page size
    this.setState(
      {
        searchFeedss: searchFeedss,
        currentPage: currentPage,
        FeedPageSize: FeedPageSize,
      },
      () => {
        this.setWaiting(false); // Indicate that data fetching is complete
      }
    );
  };
  handleBlueskyFeedfollows = async (uri) => {
    const { authUser } = this.props;
    let param = {
        accessJwt: authUser.bluesky_access_token,
    };
    let accessJwt = authUser.bluesky_access_token;

    try {
        const bluesyFollowed = await blueskyGetFollowed(param);
        const bluesyFolloweds = await blueskyGetFollowed(param);
        if (
            !bluesyFollowed ||
            !Array.isArray(bluesyFollowed.data.preferences)
        ) {
            console.error(
                "Invalid bluesyFolloweeeeed object or preferences array."
            );
            return;
        }

        let pinned, saved;
        for (const preference of bluesyFollowed.data.preferences) {
            if (preference.$type === "app.bsky.actor.defs#savedFeedsPref") {
                pinned = preference.pinned;
                saved = preference.saved;
                break;
            }
        }

        if (!pinned || !saved) {
            console.error("Pinned or saved feeds not found in preferences.");
            return;
        }

        if (!pinned.includes(uri) && !saved.includes(uri)) {
            // If URI is not in pinned or saved, add it to both
            pinned.push(uri);
            saved.push(uri);
            const folowinggggg = await blueskyPutFollowed(
                bluesyFollowed.data,
                accessJwt
            );
            this.setState({
                followDataaa: pinned,
                newfollowingg: folowinggggg.following,
            });
        } else {
            // If URI is in pinned or saved, remove it from both
            const pinnedIndex = pinned.indexOf(uri);
            const savedIndex = saved.indexOf(uri);
            pinned.splice(pinnedIndex, 1);
            saved.splice(savedIndex, 1);

            const unfollowingggg = await blueskyPutFollowed(bluesyFollowed.data,accessJwt);
            this.setState({
                followDataaa: pinned,
                newfollowingg: unfollowingggg.following,
            });
        }
    } catch (error) {
        console.error(
            "Error occurred while fetching or processing followed feeds:",
            error.message
        );
    }
};

  handleBlueskyFOllows = async (did) => {
    const{blueskyDid}=this.state;
    const { authUser } = this.props;
    let param = {
      accessJwt: authUser.bluesky_access_token,
      subject: did,
      did:blueskyDid
       };
   let accessJwt= authUser.bluesky_access_token;

    try {
      if (!did) {
        console.error("Invalid argument: did is undefined or null");
        return;
      }

      const blueskyFollowss = await blueskyGetFollows(param);

      if (
        !blueskyFollowss ||
        !blueskyFollowss.data ||
        !Array.isArray(blueskyFollowss.data.follows)
      ) {
        console.error(
          "Bluesky follows data is not available or has unexpected structure."
        );
        return;
      }

      const blueskyFollower = blueskyFollowss.data.follows.find(
        (item) => item.did === did
      );

      if (!blueskyFollower) {
        const newFollow = await blueskyFollows(did,accessJwt,blueskyDid);

        // Fetch updated follow data
        const updatedFollowDataResponse = await blueskyGetFollows(param);
        const updatedFollowData = updatedFollowDataResponse.data.follows;

        this.setState({ newfollowing: true, followDataa: updatedFollowData });
      } else {
        let rkey = null;
        if (
          blueskyFollower &&
          blueskyFollower.viewer &&
          blueskyFollower.viewer.following
        ) {
          const urlParts = blueskyFollower.viewer.following.split("/");
          rkey = urlParts[urlParts.length - 1];
        }

        if (rkey) {
          const unFollow = await blueskyUnfollows(rkey,accessJwt,did);
          const updatedFollowData = blueskyFollowss.data.follows.filter(
            (item) => item.did !== did
          );

          this.setState({
            newfollowing: false,
            followDataa: updatedFollowData,
          });
        } else {
          console.error("Unable to extract rkey from blueskyFollower");
        }
      }
    } catch (error) {
      console.error("Error handling Bluesky follows:", error);
      // Handle error appropriately
    }
  };

  handleUnFollowFeed = async (feed_id) => {
    const { authUser, feeds } = this.props;
    let followed_feeds = authUser.feeds_followed;

    let feed = feeds.find((item) => item.id === feed_id);
    if (!feed) {
      const feeddata = authUser.feeds_followed.find(
        (item) => item.feed_id === feed_id
      );
      feed = feeddata.feed;
      if (!feed) {
        return;
      }
    }
    const res = await unfollowFeed(feed);
    if (res === false) {
      return;
    }
  };

  handleFollowFeed = async (feed_id) => {
    if (isBanned()) {
      ToastError("You've been suspended.");
      return;
    }
    const token = await getAuthToken();
    if (!token) {
      return null;
    }
    const gqlservice = new GraphqlService();
    gqlservice.set_auth_jwt(token);
    let result = await gqlservice.feed_by_id(feed_id);
    const feeds2show = result.data.feeds[0];
    const res = await followFeed(feeds2show);
  };

  handleSources = (searchKey) => {
    let { currentPage, currentPageSize } = this.state;

    const { sources, articles } = this.props;

    const uniqueSourcesMap = new Map();
    const lowercaseSearchKey = searchKey.toLowerCase();

    sources.forEach((source) => {
      const lowercaseName = source.name.toLowerCase();
      const lowercaseDescription = source.description
        ? source.description.toLowerCase()
        : "";

      if (
        lowercaseName.includes(lowercaseSearchKey) ||
        (lowercaseDescription &&
          lowercaseDescription.includes(lowercaseSearchKey))
      ) {
        uniqueSourcesMap.set(source.id, source);
      }
    });
    // }

    // Convert the map values to an array to ensure all sources are included at least once
    const searchSourcess = Array.from(uniqueSourcesMap.values());

    const paginatedSources = searchSourcess.slice(currentPage, currentPageSize);

    // Update state with the new data, page, and page size
    this.setState({
      searchSourcess: paginatedSources,
      currentPage: currentPage,
      currentPageSize: currentPageSize,
    });
  };
  handleSourcee = (searchKey) => {
    let { currentPage } = this.state;
    const currentPageSize = 100;
    const { sources, articles } = this.props;
    const uniqueSourcesMap = new Map();
    const lowercaseSearchKey = searchKey.toLowerCase();

    sources.forEach((source) => {
      const lowercaseName = source.name.toLowerCase();
      const lowercaseDescription = source.description
        ? source.description.toLowerCase()
        : "";

      if (
        lowercaseName.includes(lowercaseSearchKey) ||
        (lowercaseDescription &&
          lowercaseDescription.includes(lowercaseSearchKey))
      ) {
        uniqueSourcesMap.set(source.id, source);
      }
    });

    const searchSources = Array.from(uniqueSourcesMap.values());

    const paginatedSources = searchSources.slice(currentPage, currentPageSize);

    // Update state with the new data, page, and page size
    this.setState({
      searchSources: paginatedSources,
      currentPage: currentPage,
      currentPageSize: currentPageSize,
    });
  };

  handleSourcees = () => {
    let { currentPage, currentPageSize, searchSources } = this.state;
    const { searchKey } = this.props;

    // If current page size exceeds 50, return without fetching more data
    if (currentPageSize > 50) {
      return;
    }

    // If current page size exceeds 5, increase the page size by 30
    if (currentPageSize > 5) {
      currentPageSize = currentPageSize + 30;
    }
    this.setWaiting(true); // Indicate that data fetching is in progress

    const { sources } = this.props;
    const uniqueSourcesMap = new Map();
    const lowercaseSearchKey = searchKey.toLowerCase();

    // Filter sources based on search criteria and add them to the uniqueSourcesMap
    sources.forEach((source) => {
      const lowercaseName = source.name.toLowerCase();
      const lowercaseDescription = source.description
        ? source.description.toLowerCase()
        : "";

      if (
        lowercaseName.includes(lowercaseSearchKey) ||
        (lowercaseDescription &&
          lowercaseDescription.includes(lowercaseSearchKey))
      ) {
        uniqueSourcesMap.set(source.id, source);
      }
    });

    // Convert the map values to an array to ensure all sources are included at least once
    const filteredSources = Array.from(uniqueSourcesMap.values());

    // Append the new sources to existing searchSources
    searchSources = searchSources.concat(filteredSources);

    // Update state with the new data, page, and page size
    this.setState(
      {
        searchSources: searchSources,
        currentPage: currentPage,
        currentPageSize: currentPageSize,
      },
      () => {
        this.setWaiting(false); // Indicate that data fetching is complete
      }
    );
  };

  handleFollowSource = async (source_id) => {
    if (isBanned()) {
      ToastError("You've been suspended.");
      return;
    }
    const token = await getAuthToken();
    if (!token) {
      return null;
    }
    const gqlservice = new GraphqlService();
    gqlservice.set_auth_jwt(token);
    let result = await gqlservice.source_by_id(source_id);
    const source2show = result.data.sources[0];
    const res = await followSource(source2show);
  };

  handleUnFollowSource = async (source_id) => {
    const { authUser, sources } = this.props;
    let followed_sources = authUser.sources_followed;

    let source = sources.find((item) => item.id === source_id);
    if (!source) {
      const sourceData = authUser.sources_followed.find(
        (item) => item.source_id === source_id
      );
      source = sourceData.source;
      if (!source) {
        return;
      }
    }
    const res = await unfollowSource(source);
    if (res === false) {
      return;
    }
  };

  handleFollowMastodonSource = async (source_id) => {
    const { authUser } = this.props;
    let username = authUser.mastodon_username;
    try {
      const followData = await retrieveFollowdata(username);

      if (!Array.isArray(followData)) {
        console.error("Invalid follow data:", followData);
        return;
      }

      // Find the follower with the given source_id
      const follower = followData.find((item) => item.id === source_id);

      if (!follower) {
        // Follow the source_id
        const newFollow = await followRequest(source_id);
        const updatedFollowData = [...followData, newFollow]; // Assuming newFollow contains the new follower data

        this.setState({
          followData: updatedFollowData,
          newfollowing: newFollow.following,
        });
      } else {
        // Unfollow the source_id
        const unFollow = await unFollowRequest(source_id);
        const sourceIdString = String(source_id); // Convert source_id to string

        const isFollowing2 = followData.some(
          (item) => item.id === sourceIdString
        );
        const updatedFollowData = followData.filter(
          (item) => item.id !== sourceIdString
        );

        this.setState({
          followData: updatedFollowData,
          newfollowing: unFollow.following,
        });
      }
    } catch (error) {
      console.error("Error handling Mastodon source follow:", error);
    }
  };

  handleMoreeMastodonSources = async () => {
    const { searchKey, requesting } = this.props;
    let { sourceLimit } = this.state;

    if (requesting) {
      return;
    }
    const limit = sourceLimit + 15;

    if (limit > 100) {
      return;
    }
    try {
      this.setWaiting(true);

      let mastodonMoreeeDetails = await fetchMastodonSimilarPosts(
        searchKey,
        limit
      );
      let sourceMoreee = mastodonMoreeeDetails.accounts;

      this.setState({
        mastodonMoreeeDetails,
        sourceLimit: limit,
        sourceMoreee,
        error: null,
      });
    } catch (error) {
      console.error("Error handling search:", error);
      // Update state with null values and error message for error handling
      this.setState({
        mastodonMoreeeDetails: null,
        sourceLimit: null,
        sourceMoreee: null,
        error: "Error handling search: " + error.message,
      });
    } finally {
      // Make sure to set waiting state to false regardless of success or failure
      this.setWaiting(false);
    }
  };

  render() {
    const {
      classes,
      searchKey,
      searchResults,
      requesting,
      theme_mode,
      loggedIn,
      authUser,
    } = this.props;
    const {
      isDisabled,
      showConfirmDlg,
      tab_value,
      mastodonDetails,
      hashtags,
      history,
      mastodonMoreDetails,
      mastodonMoreeDetails,
      hashtagsMore,
      isMaxWidthTrue,
      statuses,
      postMoree,
      hashtagsMoree,
      searchArticles,
      innerTabValue,
      searchFeeds,
      searchSources,
      newfollowing,
      followData,
      isUsernamePresent,
      showWarning,
      blueskyfollowing,
      blueskyDetailss,
      blueskyDetails,
      sourceMoreee,
      searchSourcess,
      searchFeedss,
      newfollowingg,
      postsMoree,
      feedsMoree,
      sourceMoree,
    } = this.state;
    const title = "Search results for " + searchKey;
    const width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    const isDesktop = width >= MAX_WINDOW_WIDTH;
    const isTablet = width >= MIN_TABLET_WIDTH && width < MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;

    const pins = [];
    const movetops = [];

    const filteredArtciles = searchResults.filter(article => article.source && article.source.feed_sources.length > 0);
    let limitedArticles = filteredArtciles.slice(0, 5);

        return (
          <div className={classes.root}>
            <div className={classes.appbar}>
              <SearchAppBar
                onSearchChange={this.handleSearchChange}
                onSearchEnter={this.handleSearchEnter}
                onNavBack={this.handleNavBack}
                title={searchKey}
                isMaxWidthTrue={isMaxWidthTrue}
              />
            </div>

            <div className={classes.profiletabs}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <MastodonTab
                    theme_mode={theme_mode}
                    onChangeTab={this.handleChangeTab}
                    myfeedstab={tab_value}
                  />
                </Grid>
              </Grid>
              <div>
                <SwipeableViews
                  index={tab_value}
                  onChangeIndex={this.handleChangeTab}
                  enableMouseEvents
                >
                  <div
                    // className={classes.articlecontainer}
                    id="feed-swipeable-views-all"
                  >
                    {this.state.tab_value === TAB_MASTODON_ALL && (
                      <Grid
                        // container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        {this.state.mastodonDetails &&
                          (isDesktop || isTablet) && (
                            <div style={{ marginLeft: 50 }}>
                              <Toolbar className={classes.toolbar}>
                                <img
                                  alt={"Raven"}
                                  src={`/static/images/logo/${theme_mode}/logo.png`}
                                  className={classes.avatar}
                                  style={{ marginLeft: 5 }}
                                />
                                <img
                                  alt={"Raven"}
                                  src={`/static/images/logo/${theme_mode}/logo_text.png`}
                                  className={classes.logo_text}
                                />
                              </Toolbar>
                              <div>
                              <Grid item>
                                <Typography
                                  style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                    marginLeft: 20,
                                    marginTop: 10,
                                  }}
                                >
                                  Posts
                                </Typography>
                                  <SearchArticleMasonary
                                    width={width}
                                    articles={limitedArticles}
                                    pins={pins}
                                    movetops={movetops}
                                    onNeedMore={this.handleNeedMore}
                                    onSelectArticle={this.handleSelectArticle}
                                    onSelectGroupArticle={
                                      this.handleSelectGroupArticle
                                    }
                                    onNeedLogin={this.handleLogin}
                                    onReport={this.handleReportArticle}
                                    onEdit={this.handleEditArticle}
                                    onDelete={this.handleDeleteArticle}
                                    onSave={this.handleSaveArticle}
                                    onDeleteSaved={
                                      this.handleDeleteSavedArticle
                                    }
                                    onClickSource={this.handleClickSource}
                                    onClickFeed={this.handleClickFeed}
                                    onClickUpvote={this.handleClickUpvote}
                                    onClickComment={this.handleClickComment}
                                    onClickRepost={this.handleClickRepost}
                                  />
                                </Grid>
                                {this.state.mastodonDetails &&
                                  (!searchResults ||
                                  searchResults.length === 0 ? (
                                    <div className={classes.seeAllDivider}>
                                      <div className={classes.warningContainer}>
                                        <img
                                          className={classes.warningimage}
                                          alt="warning"
                                          src={`/static/images/icons/${theme_mode}/warning.png`}
                                        />
                                        <Typography
                                          className={
                                            classes.seeAllButtonContainerMobile
                                          }
                                        >
                                          No posts yet.
                                        </Typography>
                                      </div>

                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ) : (
                                    <div className={classes.seeAllDivider}>
                                      <Link
                                        className={
                                          classes.seeAllButtonContainerr
                                        }
                                        onClick={() =>
                                          this.handleSeeAllClick(
                                            TAB_MASTODON_POSTS,
                                            searchKey,
                                            mastodonDetails,
                                            TAB_NEWSRAVEN_POSTS
                                          )
                                        }
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                        >
                                          See All
                                        </Typography>
                                      </Link>
                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ))}
                              </div>
                              <Grid item>
                                <Typography
                                  style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "bold",
                                    //   marginBottom: "5px",
                                    marginLeft: 20,
                                    //   marginTop: 10,
                                  }}
                                >
                                  Feeds
                                </Typography>

                                <Grid
                                  container
                                  direction="row"
                                  alignItems="flex-start"
                                >
                                  <List>
                                    <div>
                                      {this.state.mastodonDetails &&
                                        searchFeeds &&
                                        searchFeeds?.map((feed, index) => (
                                          <FeedTabItem
                                            key={index}
                                            width={
                                              width === undefined ? null : width
                                            }
                                            feed={feed}
                                            index={index}
                                            following={authUser.feeds_followed.some(
                                              (feeds) =>
                                                feeds.feed_id === feed.id
                                            )}
                                            loggedIn={loggedIn}
                                            theme_mode={theme_mode}
                                            onClickFeed={() => {
                                              this.handleClickFeed(feed);
                                            }}
                                            onFollowed={() => {
                                              this.handleUnFollowFeed(feed.id);
                                            }}
                                            unFollowed={() => {
                                              this.handleFollowFeed(feed.id);
                                            }}
                                          />
                                        ))}
                                    </div>
                                  </List>
                                </Grid>
                                {this.state.mastodonDetails &&
                                  (!searchFeeds || searchFeeds.length === 0 ? (
                                    <div className={classes.seeAllDivider}>
                                      <div className={classes.warningContainer}>
                                        <img
                                          className={classes.warningimage}
                                          alt="warning"
                                          src={`/static/images/icons/${theme_mode}/warning.png`}
                                        />
                                        <Typography
                                          className={
                                            classes.seeAllButtonContainer
                                          }
                                        >
                                          No feeds yet.
                                        </Typography>
                                      </div>

                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ) : (
                                    <div className={classes.seeAllDivider}>
                                      <Link
                                        className={
                                          classes.seeAllButtonContainerr
                                        }
                                        onClick={() =>
                                          this.handleSeeAllClick(
                                            TAB_MASTODON_FEEDS,
                                            searchKey,
                                            mastodonDetails,
                                            TAB_NEWSRAVEN_POSTS
                                          )
                                        }
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                        >
                                          See All
                                        </Typography>
                                      </Link>
                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ))}
                              </Grid>
                              <Grid item>
                                <Typography
                                  style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "bold",

                                    marginLeft: 20,
                                  }}
                                >
                                  Sources
                                </Typography>

                                <Grid
                                  container
                                  direction="row"
                                  alignItems="flex-start"
                                >
                                  <List>
                                    {this.state.mastodonDetails &&
                                      searchSourcess &&
                                      authUser &&
                                      searchSourcess?.map((source, index) => (
                                        <SourceItem
                                          key={index}
                                          width={width || null}
                                          source={source}
                                          index={index}
                                          following={authUser.sources_followed.some(
                                            (sources) =>
                                              sources.source_id === source.id
                                          )}
                                          loggedIn={loggedIn}
                                          theme_mode={theme_mode}
                                          onClickFeed={() => {
                                            this.handleClickSource(source);
                                          }}
                                          onFollowed={() => {
                                            this.handleUnFollowSource(
                                              source.id
                                            );
                                          }}
                                          unFollowed={() => {
                                            this.handleFollowSource(source.id);
                                          }}
                                        />
                                      ))}
                                  </List>
                                </Grid>
                                {this.state.mastodonDetails &&
                                  (!searchSourcess ||
                                  searchSourcess.length === 0 ? (
                                    <div className={classes.seeAllDivider}>
                                      <div className={classes.warningContainer}>
                                        <img
                                          className={classes.warningimage}
                                          alt="warning"
                                          src={`/static/images/icons/${theme_mode}/warning.png`}
                                        />
                                        <Typography
                                          className={
                                            classes.seeAllButtonContainer
                                          }
                                        >
                                          No sources yet.
                                        </Typography>
                                      </div>

                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ) : (
                                    <div className={classes.seeAllDivider}>
                                      <Link
                                        className={
                                          classes.seeAllButtonContainerr
                                        }
                                        onClick={() =>
                                          this.handleSeeAllClick(
                                            TAB_MASTODON_SOURCES,
                                            searchKey,
                                            mastodonDetails,
                                            TAB_NEWSRAVEN_POSTS
                                          )
                                        }
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                        >
                                          See All
                                        </Typography>
                                      </Link>
                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ))}
                              </Grid>
                              <Grid item>
                                {authUser.is_mastodon_enabled && (
                                  <Toolbar className={classes.toolbar}>
                                    <img
                                      alt={"Raven"}
                                      src={`/static/images/icons/${theme_mode}/mastodon.png`}
                                      className={classes.avatar}
                                      style={{ marginRight: 20 }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        marginBottom: -25,
                                      }}
                                    >
                                      Mastodon
                                    </Typography>
                                  </Toolbar>
                                )}
                                {authUser.is_mastodon_enabled && (
                                  <Typography
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    # Hashtags
                                  </Typography>
                                )}

                                {this.state.mastodonDetails &&
                                  authUser.is_mastodon_enabled && (
                                    <MastodonHashtags hashtags={hashtags} />
                                  )}
                                {this.state.mastodonDetails &&
                                  authUser.is_mastodon_enabled &&
                                  (!hashtags || hashtags.length === 0 ? (
                                    <div className={classes.seeAllDivider}>
                                      <div className={classes.warningContainer}>
                                        <img
                                          className={classes.warningimage}
                                          alt="warning"
                                          src={`/static/images/icons/${theme_mode}/warning.png`}
                                        />
                                        <Typography
                                          className={
                                            classes.seeAllButtonContainer
                                          }
                                        >
                                          No hashtags yet.
                                        </Typography>
                                      </div>

                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ) : (
                                    <div className={classes.seeAllDivider}>
                                      <Link
                                        className={
                                          classes.seeAllButtonContainerr
                                        }
                                        onClick={() =>
                                          this.handleSeeAllClick(
                                            TAB_MASTODON_HASHTAGS,
                                            searchKey,
                                            mastodonDetails,
                                            TAB_MASTODONALL_POSTS
                                          )
                                        }
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                        >
                                          See All
                                        </Typography>
                                      </Link>
                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ))}
                              </Grid>
                              <Grid item>
                                {authUser.is_mastodon_enabled && (
                                  <Typography
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",
                                      marginLeft: 20,
                                    }}
                                  >
                                    Posts
                                  </Typography>
                                )}

                                {this.state.mastodonDetails &&
                                  authUser.is_mastodon_enabled && (
                                    <MastodonShared posts={statuses} />
                                  )}
                              </Grid>
                              {this.state.mastodonDetails &&
                                authUser.is_mastodon_enabled &&
                                (!statuses || statuses.length === 0 ? (
                                  <div className={classes.seeAllDivider}>
                                    <div className={classes.warningContainer}>
                                      <img
                                        className={classes.warningimage}
                                        alt="warning"
                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                      />
                                      <Typography
                                        className={
                                          classes.seeAllButtonContainer
                                        }
                                      >
                                        No posts yet.
                                      </Typography>
                                    </div>

                                    <div className={classes.dividerLine}></div>
                                  </div>
                                ) : (
                                  <div className={classes.seeAllDivider}>
                                    <Link
                                      className={classes.seeAllButtonContainerr}
                                      onClick={() =>
                                        this.handleSeeAllClick(
                                          TAB_MASTODON_POSTS,
                                          searchKey,
                                          mastodonDetails,
                                          TAB_MASTODONALL_POSTS
                                        )
                                      }
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                      >
                                        See All
                                      </Typography>
                                    </Link>
                                    <div className={classes.dividerLine}></div>
                                  </div>
                                ))}
                              <Grid item>
                                {authUser.is_mastodon_enabled && (
                                  <Typography
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",

                                      marginLeft: 20,
                                    }}
                                  >
                                    Sources
                                  </Typography>
                                )}

                                <Grid
                                  container
                                  direction="row"
                                  alignItems="flex-start"
                                >
                                  <List>
                                    {authUser.is_mastodon_enabled &&
                                      this.state.mastodonDetails?.accounts?.map(
                                        (source, index) => (
                                          <MastodonSourceItem
                                            key={index}
                                            width={
                                              width === undefined ? null : width
                                            }
                                            source={source}
                                            index={index}
                                            newfollowing={newfollowing}
                                            loggedIn={loggedIn}
                                            theme_mode={theme_mode}
                                            unFollowed={() => {
                                              this.handleFollowMastodonSource(
                                                source.id
                                              );
                                            }}
                                            followData={this.state.followData} // Pass followData here
                                            isUsernamePresent={
                                              isUsernamePresent
                                            }
                                          />
                                        )
                                      )}
                                  </List>
                                </Grid>
                                {this.state.mastodonDetails &&
                                  authUser.is_mastodon_enabled &&
                                  (!this.state.mastodonDetails?.accounts ||
                                  this.state.mastodonDetails?.accounts
                                    .length === 0 ? (
                                    <div className={classes.seeAllDivider}>
                                      <div className={classes.warningContainer}>
                                        <img
                                          className={classes.warningimage}
                                          alt="warning"
                                          src={`/static/images/icons/${theme_mode}/warning.png`}
                                        />
                                        <Typography
                                          className={
                                            classes.seeAllButtonContainer
                                          }
                                        >
                                          No sources yet.
                                        </Typography>
                                      </div>

                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ) : (
                                    <div className={classes.seeAllDivider}>
                                      <Link
                                        className={
                                          classes.seeAllButtonContainerr
                                        }
                                        onClick={() =>
                                          this.handleSeeAllClick(
                                            TAB_MASTODON_SOURCES,
                                            searchKey,
                                            mastodonDetails,
                                            TAB_MASTODONALL_POSTS
                                          )
                                        }
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                        >
                                          See All
                                        </Typography>
                                      </Link>
                                      <div
                                        className={classes.dividerLine}
                                      ></div>
                                    </div>
                                  ))}

                                {authUser.bluesky_username &&
                                  authUser.is_bluesky_enabled && (
                                    <div>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                      >
                                        <List>
                                          <Toolbar className={classes.toolbar}>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                alt={"Raven"}
                                                src={`/static/images/icons/${theme_mode}/bluesky.png`}
                                                className={classes.avatar}
                                                style={{ marginRight: 20 }} // Adjust margin between image and text
                                              />
                                              <Typography
                                                style={{
                                                  fontSize: "1.1rem",
                                                  fontWeight: "bold",
                                                  display: "inline-block",
                                                  verticalAlign: "middle",
                                                  marginBottom: -25,
                                                }}
                                              >
                                                BlueSky
                                              </Typography>
                                            </div>
                                          </Toolbar>
                                          <Typography
                                            style={{
                                              fontSize: "1.3rem",
                                              fontWeight: "bold",
                                              marginLeft: 20,
                                            }}
                                          >
                                            Feeds
                                          </Typography>

                                          {blueskyDetailss &&
                                            blueskyDetailss?.feeds &&
                                            authUser.bluesky_username &&
                                            blueskyDetailss?.feeds?.map(
                                              (feed, index) => (
                                                <div key={index}>
                                                  <BlueskyFeeds
                                                    width={
                                                      width === undefined
                                                        ? null
                                                        : width
                                                    }
                                                    feeds={feed}
                                                    loggedIn={loggedIn}
                                                    theme_mode={theme_mode}
                                                    // unFollowed={this.handleBlueskyFeedfollows}
                                                    unFollowed={() => {
                                                      this.handleBlueskyFeedfollows(
                                                        feed.uri
                                                      );
                                                    }}
                                                    followData={
                                                      this.state.followDataaa
                                                    } // Pass followData here
                                                    newfollowing={newfollowingg}
                                                    // isUsernamePresent={this.props.authuser.username}
                                                    isUsernamePresent={
                                                      isUsernamePresent
                                                    }
                                                  />
                                                </div>
                                              )
                                            )}
                                        </List>
                                  </Grid>
                                  {this.state.blueskyDetailss && authUser.is_bluesky_enabled &&
                                    authUser.bluesky_username ? (
                                    this.state.blueskyDetails === null ||
                                      this.state.blueskyDetailss?.feeds?.length ===
                                      0 ? (
                                      <div
                                        className={classes.seeAllDivider}
                                      >
                                            <div
                                              className={
                                                classes.warningContainer
                                              }
                                            >
                                              <img
                                                className={classes.warningimage}
                                                alt="warning"
                                                src={`/static/images/icons/${theme_mode}/warning.png`}
                                              />
                                              <Typography
                                                className={
                                                  classes.seeAllButtonContainerr
                                                }
                                              >
                                                No feeds yet.
                                              </Typography>
                                            </div>
                                            <div
                                              className={classes.dividerLine}
                                            ></div>
                                          </div>
                                        ) : (
                                          <div
                                            className={classes.seeAllDivider}
                                          >
                                            <Link
                                              className={
                                                classes.seeAllButtonContainerr
                                              }
                                              onClick={() =>
                                                this.handleSeeAllClick(
                                                  TAB_MASTODON_FEEDS,
                                                  searchKey,
                                                  mastodonDetails,
                                                  TAB_BLUESKY_POSTS
                                                )
                                              }
                                            >
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                See All
                                              </Typography>
                                            </Link>
                                            <div
                                              className={
                                                classes.dividerLine
                                              }
                                            ></div>
                                          </div>
                                        )
                                      ) : (authUser.is_bluesky_enabled &&(
                                        <div className={classes.seeAllDivider}>
                                          <div
                                            className={classes.warningContainer}
                                          >
                                            <img
                                              className={classes.warningimage}
                                              alt="warning"
                                              src={`/static/images/icons/${theme_mode}/warning.png`}
                                            />
                                            <Typography
                                              className={
                                                classes.seeAllButtonContainer
                                              }
                                            >
                                              No feeds yet.
                                            </Typography>
                                          </div>
                                          <div
                                            className={
                                              classes.dividerLineMobile
                                            }
                                          ></div>
                                        </div>
                                      )
                                      )}

                                      <Grid item>
                                        <Typography
                                          style={{
                                            fontSize: "1.3rem",
                                            fontWeight: "bold",
                                            marginLeft: 20,
                                          }}
                                        >
                                          Posts
                                        </Typography>
                                        {this.state.blueskyDetails &&
                                          authUser.bluesky_username && (
                                            <BlueskyShared
                                              posts={blueskyDetails.posts}
                                            />
                                          )}
                                      </Grid>
                                      {this.state.blueskyDetails &&
                                      authUser.bluesky_username ? (
                                        this.state.blueskyDetails.posts ===
                                          null ||
                                        this.state.blueskyDetails.posts
                                          .length === 0 ? (
                                          <div
                                            className={classes.seeAllDivider}
                                          >
                                            <div
                                              className={
                                                classes.warningContainer
                                              }
                                            >
                                              <img
                                                className={classes.warningimage}
                                                alt="warning"
                                                src={`/static/images/icons/${theme_mode}/warning.png`}
                                              />
                                              <Typography
                                                className={
                                                  classes.seeAllButtonContainer
                                                }
                                              >
                                                No posts yet.
                                              </Typography>
                                            </div>
                                            <div
                                              className={classes.dividerLine}
                                            ></div>
                                          </div>
                                        ) : (
                                          <div
                                            className={classes.seeAllDivider}
                                          >
                                            <Link
                                              className={
                                                classes.seeAllButtonContainerr
                                              }
                                              onClick={() =>
                                                this.handleSeeAllClick(
                                                  TAB_MASTODON_POSTS,
                                                  searchKey,
                                                  mastodonDetails,
                                                  TAB_BLUESKY_POSTS
                                                )
                                              }
                                            >
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                See All
                                              </Typography>
                                            </Link>
                                            <div
                                              className={classes.dividerLine}
                                            ></div>
                                          </div>
                                        )
                                      ) : (
                                        <div className={classes.seeAllDivider}>
                                          <div
                                            className={classes.warningContainer}
                                          >
                                            <img
                                              className={classes.warningimage}
                                              alt="warning"
                                              src={`/static/images/icons/${theme_mode}/warning.png`}
                                            />
                                            <Typography
                                              className={
                                                classes.seeAllButtonContainer
                                              }
                                            >
                                              No posts yet.
                                            </Typography>
                                          </div>
                                          <div
                                            className={classes.dividerLine}
                                          ></div>
                                        </div>
                                      )}

                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                      >
                                        <List>
                                          <Typography
                                            style={{
                                              fontSize: "1.3rem",
                                              fontWeight: "bold",
                                              marginLeft: 20,
                                            }}
                                          >
                                            Sources
                                          </Typography>

                                          {this.state.blueskyDetailsss &&
                                            authUser.bluesky_username &&
                                            this.state.blueskyDetailsss?.actors?.map(
                                              (source) => (
                                                <BlueskySourceItem
                                                  key={source.id} // Ensure each item has a unique key
                                                  width={
                                                    width === undefined
                                                      ? null
                                                      : width
                                                  }
                                                  source={source} // Use source instead of blueskyDetailsss.actors
                                                  loggedIn={loggedIn}
                                                  theme_mode={theme_mode}
                                                  unFollowed={() => {
                                                    this.handleBlueskyFOllows(
                                                      source.did
                                                    );
                                                  }}
                                                  followData={
                                                    this.state.followDataa
                                                  } // Pass followData here
                                                  newfollowing={newfollowing}
                                                  isUsernamePresent={
                                                    isUsernamePresent
                                                  }
                                                />
                                              )
                                            )}
                                        </List>
                                      </Grid>
                                      {this.state.blueskyDetails &&
                                        authUser.bluesky_username ? (
                                        this.state.blueskyDetails.posts === null ||
                                          this.state.blueskyDetails.posts.length === 0 ? (
                                          <div className={classes.seeAllDivider}>
                                            <div className={classes.warningContainer}>
                                              <img
                                                className={classes.warningimage}
                                                alt="warning"
                                                src={`/static/images/icons/${theme_mode}/warning.png`}
                                              />
                                              <Typography className={classes.seeAllButtonContainer}>
                                                No sources yet.
                                              </Typography>
                                            </div>
                                            <div className={classes.dividerLine}></div>
                                          </div>
                                        ) : (
                                          <div className={classes.seeAllDivider}>
                                            <Link
                                              className={classes.seeAllButtonContainerr}
                                              onClick={() =>
                                                this.handleSeeAllClick(
                                                  TAB_MASTODON_SOURCES,
                                                  searchKey,
                                                  mastodonDetails,
                                                  TAB_BLUESKY_POSTS
                                                )
                                              }
                                            >
                                              <Typography style={{ fontWeight: "bold", cursor: "pointer" }}>
                                                See All
                                              </Typography>
                                            </Link>
                                            <div className={classes.dividerLine}></div>
                                          </div>
                                        )
                                      ) : (
                                        <div className={classes.seeAllDivider}>
                                          <div className={classes.warningContainer}>
                                            <img
                                              className={classes.warningimage}
                                              alt="warning"
                                              src={`/static/images/icons/${theme_mode}/warning.png`}
                                            />
                                            <Typography className={classes.seeAllButtonContainer}>
                                              No sources yet.
                                            </Typography>
                                          </div>
                                          <div className={classes.dividerLine}></div>
                                        </div>
                                      )}

                                    </div>
                                  )}
                              </Grid>
                            </div>
                          )}
                        {isMobile &&
                          this.state.mastodonDetails &&
                          searchResults && (
                            <div>
                              <Toolbar className={classes.toolbar}>
                                <img
                                  alt={"Raven"}
                                  src={`/static/images/logo/${theme_mode}/logo.png`}
                                  className={classes.avatar}
                                  style={{ marginLeft: 10 }}
                                />
                                <img
                                  alt={"Raven"}
                                  src={`/static/images/logo/${theme_mode}/logo_text.png`}
                                  className={classes.logo_text}
                                />
                              </Toolbar>
                              <Grid item>
                              <Typography
                                  style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                    marginLeft: 20,
                                    marginTop: 10,
                                  }}
                                >
                                  Posts
                                </Typography>
                                <SearchArticleList
                                  articles={limitedArticles}
                                  pins={pins}
                                  movetops={movetops}
                                  onNeedMore={this.handleNeedMore}
                                  onSelectArticle={this.handleSelectArticle}
                                  onSelectGroupArticle={
                                    this.handleSelectGroupArticle
                                  }
                                  onNeedLogin={this.handleLogin}
                                  onReport={this.handleReportArticle}
                                  onEdit={this.handleEditArticle}
                                  onDelete={this.handleDeleteArticle}
                                  onSave={this.handleSaveArticle}
                                  onDeleteSaved={this.handleDeleteSavedArticle}
                                  onClickSource={this.handleClickSource}
                                  onClickFeed={this.handleClickFeed}
                                  onClickUpvote={this.handleClickUpvote}
                                  onClickComment={this.handleClickComment}
                                  onClickRepost={this.handleClickRepost}
                                />
                              </Grid>
                              {this.state.mastodonDetails &&
                                (!searchResults || searchResults === 0 ? (
                                  <div className={classes.seeAllDivider}>
                                    <div className={classes.warningContainer}>
                                      <img
                                        className={classes.warningimage}
                                        alt="warning"
                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                      />
                                      <Typography
                                        className={
                                          classes.seeAllButtonContainerMobile
                                        }
                                      >
                                        No posts yet.
                                      </Typography>
                                    </div>

                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ) : (
                                  <div className={classes.seeAllDivider}>
                                    <Link
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                      onClick={() =>
                                        this.handleSeeAllClick(
                                          TAB_MASTODON_POSTS,
                                          searchKey,
                                          mastodonDetails,
                                          TAB_NEWSRAVEN_POSTS
                                        )
                                      }
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                      >
                                        See All
                                      </Typography>
                                    </Link>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ))}
                              <Grid item>
                                <Typography
                                  style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "bold",

                                    marginLeft: 20,
                                  }}
                                >
                                  Feeds
                                </Typography>
                                <List>
                                  {this.state.mastodonDetails &&
                                    searchFeeds &&
                                    searchFeeds?.map((feed, index) => (
                                      <FeedTabItem
                                        key={index}
                                        width={
                                          width === undefined ? null : width
                                        }
                                        feed={feed}
                                        index={index}
                                        following={authUser.feeds_followed.some(
                                          (feeds) => feeds.feed_id === feed.id
                                        )}
                                        loggedIn={loggedIn}
                                        theme_mode={theme_mode}
                                        onClickFeed={() => {
                                          this.handleClickFeed(feed);
                                        }}
                                        onFollowed={() => {
                                          this.handleUnFollowFeed(feed.id);
                                        }}
                                        unFollowed={() => {
                                          this.handleFollowFeed(feed.id);
                                        }}
                                      />
                                    ))}
                                </List>
                              </Grid>
                              {this.state.mastodonDetails &&
                                (!searchFeeds || searchFeeds.length === 0 ? (
                                  <div className={classes.seeAllDivider}>
                                    <div className={classes.warningContainer}>
                                      <img
                                        className={classes.warningimage}
                                        alt="warning"
                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                      />
                                      <Typography
                                        className={
                                          classes.seeAllButtonContainerMobile
                                        }
                                      >
                                        No feeds yet.
                                      </Typography>
                                    </div>

                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ) : (
                                  <div className={classes.seeAllDivider}>
                                    <Link
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                      onClick={() =>
                                        this.handleSeeAllClick(
                                          TAB_MASTODON_FEEDS,
                                          searchKey,
                                          mastodonDetails,
                                          TAB_NEWSRAVEN_POSTS
                                        )
                                      }
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                      >
                                        See All
                                      </Typography>
                                    </Link>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ))}
                              <Grid item>
                                <Typography
                                  style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "bold",

                                    marginLeft: 20,
                                  }}
                                >
                                  Sources
                                </Typography>

                                <List>
                                  {this.state.mastodonDetails &&
                                    searchSourcess &&
                                    authUser &&
                                    searchSourcess?.map((source, index) => (
                                      <SourceItem
                                        key={index}
                                        width={width || null}
                                        source={source}
                                        index={index}
                                        following={authUser.sources_followed.some(
                                          (sources) =>
                                            sources.source_id === source.id
                                        )}
                                        loggedIn={loggedIn}
                                        theme_mode={theme_mode}
                                        onClickFeed={() => {
                                          this.handleClickSource(source);
                                        }}
                                        onFollowed={() => {
                                          this.handleUnFollowSource(source.id);
                                        }}
                                        unFollowed={() => {
                                          this.handleFollowSource(source.id);
                                        }}
                                      />
                                    ))}
                                </List>
                              </Grid>
                              {this.state.mastodonDetails &&
                                (!searchSources ||
                                searchSources.length === 0 ? (
                                  <div className={classes.seeAllDivider}>
                                    <div className={classes.warningContainer}>
                                      <img
                                        className={classes.warningimage}
                                        alt="warning"
                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                      />
                                      <Typography
                                        className={
                                          classes.seeAllButtonContainerMobile
                                        }
                                      >
                                        No sources yet.
                                      </Typography>
                                    </div>

                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ) : (
                                  <div className={classes.seeAllDivider}>
                                    <Link
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                      onClick={() =>
                                        this.handleSeeAllClick(
                                          TAB_MASTODON_SOURCES,
                                          searchKey,
                                          mastodonDetails,
                                          TAB_NEWSRAVEN_POSTS
                                        )
                                      }
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                      >
                                        See All
                                      </Typography>
                                    </Link>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ))}
                              <Grid item>
                                {authUser.is_mastodon_enabled && (
                                  <Toolbar className={classes.toolbar}>
                                    <img
                                      alt={"Raven"}
                                      src={`/static/images/icons/${theme_mode}/mastodon.png`}
                                      className={classes.avatar}
                                      style={{ marginLeft: 20, width: 25 }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        marginBottom: -25,
                                        marginLeft: 20,
                                      }}
                                    >
                                      Mastodon
                                    </Typography>
                                  </Toolbar>
                                )}
                                {authUser.is_mastodon_enabled && (
                                  <Typography
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                      marginLeft: 20,
                                    }}
                                  >
                                    # Hashtags
                                  </Typography>
                                )}
                                {this.state.mastodonDetails &&
                                  authUser.is_mastodon_enabled && (
                                    <div style={{ marginLeft: 20 }}>
                                      <MastodonHashtags hashtags={hashtags} />
                                    </div>
                                  )}
                              </Grid>
                              {this.state.mastodonDetails &&
                                authUser.is_mastodon_enabled &&
                                (!hashtags || hashtags.length === 0 ? (
                                  <div className={classes.seeAllDivider}>
                                    <div className={classes.warningContainer}>
                                      <img
                                        className={classes.warningimage}
                                        alt="warning"
                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                      />
                                      <Typography
                                        className={
                                          classes.seeAllButtonContainerMobile
                                        }
                                      >
                                        No hashtags yet.
                                      </Typography>
                                    </div>

                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ) : (
                                  <div className={classes.seeAllDivider}>
                                    <Link
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                      onClick={() =>
                                        this.handleSeeAllClick(
                                          TAB_MASTODON_HASHTAGS,
                                          searchKey,
                                          mastodonDetails,
                                          TAB_MASTODONALL_POSTS
                                        )
                                      }
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                      >
                                        See All
                                      </Typography>
                                    </Link>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ))}
                              <Grid item>
                                {authUser.is_mastodon_enabled && (
                                  <Typography
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",

                                      marginLeft: 20,
                                    }}
                                  >
                                    Posts
                                  </Typography>
                                )}
                                {this.state.mastodonDetails &&
                                  authUser.is_mastodon_enabled && (
                                    <MastodonShared posts={statuses} />
                                  )}
                              </Grid>
                              {this.state.mastodonDetails &&
                                authUser.is_mastodon_enabled &&
                                (!statuses || statuses.length === 0 ? (
                                  <div className={classes.seeAllDivider}>
                                    <div className={classes.warningContainer}>
                                      <img
                                        className={classes.warningimage}
                                        alt="warning"
                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                      />
                                      <Typography
                                        className={
                                          classes.seeAllButtonContainerMobile
                                        }
                                      >
                                        No posts yet.
                                      </Typography>
                                    </div>

                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ) : (
                                  <div className={classes.seeAllDivider}>
                                    <Link
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                      onClick={() =>
                                        this.handleSeeAllClick(
                                          TAB_MASTODON_POSTS,
                                          searchKey,
                                          mastodonDetails,
                                          TAB_MASTODONALL_POSTS
                                        )
                                      }
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                      >
                                        See All
                                      </Typography>
                                    </Link>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ))}
                              <Grid item>
                                {authUser.is_mastodon_enabled && (
                                  <Typography
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",

                                      marginLeft: 20,
                                    }}
                                  >
                                    Sources
                                  </Typography>
                                )}
                                <List>
                                  {this.state.mastodonDetails &&
                                    authUser.is_mastodon_enabled &&
                                    this.state.mastodonDetails?.accounts?.map(
                                      (source, index) => (
                                        <MastodonSourceItem
                                          key={index}
                                          width={
                                            width === undefined ? null : width
                                          }
                                          source={source}
                                          index={index}
                                          newfollowing={newfollowing}
                                          loggedIn={loggedIn}
                                          theme_mode={theme_mode}
                                          unFollowed={() => {
                                            this.handleFollowMastodonSource(
                                              source.id
                                            );
                                          }}
                                          followData={this.state.followData} // Pass followData here
                                          isUsernamePresent={isUsernamePresent}
                                        />
                                      )
                                    )}
                                </List>
                              </Grid>
                              {this.state.mastodonDetails &&
                                authUser.is_mastodon_enabled &&
                                (!this.state.mastodonDetails?.accounts ||
                                this.state.mastodonDetails?.accounts.length ===
                                  0 ? (
                                  <div className={classes.seeAllDivider}>
                                    <div className={classes.warningContainer}>
                                      <img
                                        className={classes.warningimage}
                                        alt="warning"
                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                      />
                                      <Typography
                                        className={
                                          classes.seeAllButtonContainerMobile
                                        }
                                      >
                                        No sources yet.
                                      </Typography>
                                    </div>

                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ) : (
                                  <div className={classes.seeAllDivider}>
                                    <Link
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                      onClick={() =>
                                        this.handleSeeAllClick(
                                          TAB_MASTODON_SOURCES,
                                          searchKey,
                                          mastodonDetails,
                                          TAB_MASTODONALL_POSTS
                                        )
                                      }
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                      >
                                        See All
                                      </Typography>
                                    </Link>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ))}
                              <Grid
                                container
                                direction="row"
                                alignItems="flex-start"
                              >
                                <List>
                                  {authUser.is_bluesky_enabled && (
                                    <Toolbar className={classes.toolbar}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          alt={"Raven"}
                                          src={`/static/images/icons/${theme_mode}/bluesky.png`}
                                          className={classes.avatar}
                                          style={{
                                            marginRight: 20,
                                            marginLeft: 10,
                                          }} // Adjust margin between image and text
                                        />
                                        <Typography
                                          style={{
                                            fontSize: "1.1rem",
                                            fontWeight: "bold",
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            marginBottom: -25,
                                          }}
                                        >
                                          BlueSky
                                        </Typography>
                                      </div>
                                    </Toolbar>
                                  )}
                                  {authUser.is_bluesky_enabled && (
                                    <Typography
                                      style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                        marginLeft: 20,
                                        marginTop: 10,
                                      }}
                                    >
                                      Feeds
                                    </Typography>
                                  )}

                                  {blueskyDetailss &&
                                    blueskyDetailss?.feeds &&
                                    authUser.bluesky_username &&
                                    authUser.is_bluesky_enabled &&
                                    blueskyDetailss?.feeds?.map(
                                      (feed, index) => (
                                        <div key={index}>
                                          <BlueskyFeeds
                                            width={
                                              width === undefined ? null : width
                                            }
                                            feeds={feed}
                                            loggedIn={loggedIn}
                                            theme_mode={theme_mode}
                                            unFollowed={() => {
                                              this.handleBlueskyFeedfollows(
                                                feed.uri
                                              );
                                            }}
                                            followData={this.state.followDataaa}
                                            newfollowing={newfollowingg}
                                            isUsernamePresent={
                                              isUsernamePresent
                                            }
                                          />
                                        </div>
                                      )
                                    )}
                                </List>
                              </Grid>
                              {this.state.blueskyDetailss &&  authUser.is_bluesky_enabled &&
                              authUser.bluesky_username ? (
                                this.state.blueskyDetails === null ||
                                this.state.blueskyDetailss?.feeds?.length ===
                                  0 ?  (
                                  <div className={classes.seeAllDivider}>
                                    <div className={classes.warningContainer}>
                                      <img
                                        className={classes.warningimage}
                                        alt="warning"
                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                      />
                                      <Typography
                                        className={
                                          classes.seeAllButtonContainerMobile
                                        }
                                      >
                                        No feeds yet.
                                      </Typography>
                                    </div>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ) : (
                                  authUser.is_bluesky_enabled &&(
                                  <div className={classes.seeAllDivider}>
                                    <Link
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                      onClick={() =>
                                        this.handleSeeAllClick(
                                          TAB_MASTODON_FEEDS,
                                          searchKey,
                                          mastodonDetails,
                                          TAB_BLUESKY_POSTS
                                        )
                                      }
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                      >
                                        See All
                                      </Typography>
                                    </Link>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ))
                              ) : (authUser.is_bluesky_enabled &&(
                                <div className={classes.seeAllDivider}>
                                  <div className={classes.warningContainer}>
                                    <img
                                      className={classes.warningimage}
                                      alt="warning"
                                      src={`/static/images/icons/${theme_mode}/warning.png`}
                                    />
                                    <Typography
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                    >
                                      No feeds yet.
                                    </Typography>
                                  </div>
                                  <div
                                    className={classes.dividerLineMobile}
                                  ></div>
                                </div>
                              )
                              )}

                              <Grid item>
                                {authUser.is_bluesky_enabled && (
                                  <Typography
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",
                                      marginLeft: 20,
                                    }}
                                  >
                                    Posts
                                  </Typography>
                                )}

                                {this.state.blueskyDetails &&
                                  authUser.bluesky_username &&
                                  authUser.is_bluesky_enabled && (
                                    <BlueskyShared
                                      posts={blueskyDetails.posts}
                                    />
                                  )}
                              </Grid>
                              {this.state.blueskyDetails && authUser.is_bluesky_enabled &&
                              authUser.bluesky_username ? (
                                this.state.blueskyDetails.posts === null ||
                                this.state.blueskyDetails.posts.length === 0 ? (
                                  <div className={classes.seeAllDivider}>
                                    <div className={classes.warningContainer}>
                                      <img
                                        className={classes.warningimage}
                                        alt="warning"
                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                      />
                                      <Typography
                                        className={
                                          classes.seeAllButtonContainerMobile
                                        }
                                      >
                                        No posts yet.
                                      </Typography>
                                    </div>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ) : (authUser.is_bluesky_enabled && (
                                  <div className={classes.seeAllDivider}>
                                    <Link
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                      onClick={() =>
                                        this.handleSeeAllClick(
                                          TAB_MASTODON_POSTS,
                                          searchKey,
                                          mastodonDetails,
                                          TAB_BLUESKY_POSTS
                                        )
                                      }
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                      >
                                        See All
                                      </Typography>
                                    </Link>
                                    <div
                                      className={classes.dividerLineMobile}
                                    ></div>
                                  </div>
                                ))
                              ) : ( authUser.is_bluesky_enabled &&(
                                <div className={classes.seeAllDivider}>
                                  <div className={classes.warningContainer}>
                                    <img
                                      className={classes.warningimage}
                                      alt="warning"
                                      src={`/static/images/icons/${theme_mode}/warning.png`}
                                    />
                                    <Typography
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                    >
                                      No posts yet.
                                    </Typography>
                                  </div>
                                  <div
                                    className={classes.dividerLineMobile}
                                  ></div>
                                </div>
                              )
                              )}

                              <Grid
                                container
                                direction="row"
                                alignItems="flex-start"
                              >
                                <List>
                                  {authUser.is_bluesky_enabled && (
                                    <Typography
                                      style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                        marginLeft: 20,
                                      }}
                                    >
                                      Sources
                                    </Typography>
                                  )}

                                  {this.state.blueskyDetailsss &&
                                    authUser.bluesky_username &&
                                    authUser.is_bluesky_enabled &&
                                    this.state.blueskyDetailsss?.actors?.map(
                                      (source) => (
                                        <BlueskySourceItem
                                          key={source.id} // Ensure each item has a unique key
                                          width={
                                            width === undefined ? null : width
                                          }
                                          source={source} // Use source instead of blueskyDetailsss.actors
                                          loggedIn={loggedIn}
                                          theme_mode={theme_mode}
                                          unFollowed={() => {
                                            this.handleBlueskyFOllows(
                                              source.did
                                            );
                                          }}
                                          followData={this.state.followDataa} // Pass followData here
                                          newfollowing={newfollowing}
                                          isUsernamePresent={isUsernamePresent}
                                        />
                                      )
                                    )}
                                </List>
                              </Grid>
                            {this.state.blueskyDetails &&
                              authUser.bluesky_username ? (
                              this.state.blueskyDetails.posts === null ||
                                this.state.blueskyDetails.posts.length === 0 ? (
                                <div className={classes.seeAllDivider}>
                                  <div className={classes.warningContainer}>
                                    <img
                                      className={classes.warningimage}
                                      alt="warning"
                                      src={`/static/images/icons/${theme_mode}/warning.png`}
                                    />
                                    <Typography className={classes.seeAllButtonContainerMobile}>
                                      No sources yet.
                                    </Typography>
                                  </div>
                                  <div className={classes.dividerLineMobile}></div>
                                </div>
                              ) : (authUser.is_bluesky_enabled && (
                                <div className={classes.seeAllDivider}>
                                  <Link
                                    className={classes.seeAllButtonContainerMobile}
                                    onClick={() =>
                                      this.handleSeeAllClick(
                                        TAB_MASTODON_SOURCES,
                                        searchKey,
                                        mastodonDetails,
                                        TAB_BLUESKY_POSTS
                                      )
                                    }
                                  >
                                    <Typography style={{ fontWeight: "bold", cursor: "pointer" }}>
                                      See All
                                    </Typography>
                                  </Link>
                                  <div className={classes.dividerLineMobile}></div>
                                </div>
                              ))
                            ) : (authUser.is_bluesky_enabled && (
                              <div className={classes.seeAllDivider}>
                                <div className={classes.warningContainer}>
                                  <img
                                    className={classes.warningimage}
                                    alt="warning"
                                    src={`/static/images/icons/${theme_mode}/warning.png`}
                                  />
                                  <Typography className={classes.seeAllButtonContainerMobile}>
                                    No sources yet.
                                  </Typography>
                                </div>
                                <div className={classes.dividerLineMobile}></div>
                              </div>)
                            )}

                            </div>
                          )}
                      </Grid>
                    )}
                  </div>
                  <div
                    // className={classes.articlecontainer}
                    id="feed-swipeable-views-feeds"
                  >
                    {this.state.tab_value === TAB_MASTODON_FEEDS && (
                      <div style={{ marginTop: 20 }}>
                        <div className={classes.profiletabs}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Grid item>
                              <PostTab
                                theme_mode={theme_mode}
                                onChangeTab={this.handleChangeTabs}
                              />
                            </Grid>
                          </Grid>
                          <div>
                            <div
                              // className={classes.articlecontainer}
                              id="posts-swipeable-newsraven-all"
                            >
                              {this.state.innerTabValue ===
                                TAB_NEWSRAVEN_POSTS && (
                                <div>
                                  {this.state.mastodonDetails &&
                                    (isDesktop || isTablet) && (
                                      <div>
                                        {(searchResults &&
                                          searchResults.length > 0) ||
                                        (postMoree && postMoree.length > 0) ? (
                                          <div>
                                            <Grid
                                              container
                                              direction="row"
                                              justifyContent="center"
                                              alignItems="flex-start"
                                              style={{ marginTop: 20 }}
                                            >
                                              <List>
                                                {searchFeeds &&
                                                searchFeedss.length > 0 ? (
                                                  searchFeedss?.map(
                                                    (feed, index) => (
                                                      <FeedTabItem
                                                        key={index}
                                                        width={
                                                          width === undefined
                                                            ? null
                                                            : width
                                                        }
                                                        feed={feed}
                                                        index={index}
                                                        following={authUser.feeds_followed.some(
                                                          (feeds) =>
                                                            feeds.feed_id ===
                                                            feed.id
                                                        )}
                                                        loggedIn={loggedIn}
                                                        theme_mode={theme_mode}
                                                        onClickFeed={() => {
                                                          this.handleClickFeed(
                                                            feed
                                                          );
                                                        }}
                                                        onFollowed={() => {
                                                          this.handleUnFollowFeed(
                                                            feed.id
                                                          );
                                                        }}
                                                        unFollowed={() => {
                                                          this.handleFollowFeed(
                                                            feed.id
                                                          );
                                                        }}
                                                      />
                                                    )
                                                  )
                                                ) : (
                                                  <div
                                                    className={
                                                      classes.seeAllDivider
                                                    }
                                                    style={{
                                                      alignItems: "center",
                                                      marginTop: 70,
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        classes.warningContainer
                                                      }
                                                    >
                                                      <img
                                                        className={
                                                          classes.warningimage
                                                        }
                                                        alt="warning"
                                                        src={`/static/images/icons/${theme_mode}/warning.png`}
                                                      />
                                                      <Typography
                                                        className={
                                                          classes.seeAllButtonContainerMobile
                                                        }
                                                      >
                                                        No feeds yet.
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )}
                                              </List>
                                            </Grid>
                                          </div>
                                        ) : (
                                          <div
                                            className={classes.seeAllDivider}
                                            style={{
                                              alignItems: "center",
                                              marginTop: 50,
                                            }}
                                          >
                                            <div
                                              className={
                                                classes.warningContainer
                                              }
                                            >
                                              <img
                                                className={classes.warningimage}
                                                alt="warning"
                                                src={`/static/images/icons/${theme_mode}/warning.png`}
                                              />
                                              <Typography
                                                className={
                                                  classes.seeAllButtonContainerMobile
                                                }
                                              >
                                                No feeds yet.
                                              </Typography>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}

                                  {this.state.mastodonDetails && isMobile && (
                                    <div>
                                      {(searchResults &&
                                        searchResults.length > 0) ||
                                      (postMoree && postMoree.length > 0) ? (
                                        <div
                                        // style={{ marginLeft: -333 }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            // style={{ marginTop: 20 }}
                                          >
                                            <List>
                                              {/* <Toolbar className={classes.toolbar}>
                                            <img
                                              alt={"Raven"}
                                              src={`/static/images/logo/${theme_mode}/logo.png`}
                                              className={classes.avatar}
                                              style={{ marginLeft: 10 }}
                                            />
                                            <img
                                              alt={"Raven"}
                                              src={`/static/images/logo/${theme_mode}/logo_text.png`}
                                              className={classes.logo_text}
                                            />
                                          </Toolbar> */}
                                              {searchFeeds &&
                                              searchFeedss.length > 0 ? (
                                                searchFeedss?.map(
                                                  (feed, index) => (
                                                    <FeedTabItem
                                                      key={index}
                                                      width={
                                                        width === undefined
                                                          ? null
                                                          : width
                                                      }
                                                      feed={feed}
                                                      index={index}
                                                      following={authUser.feeds_followed.some(
                                                        (feeds) =>
                                                          feeds.feed_id ===
                                                          feed.id
                                                      )}
                                                      loggedIn={loggedIn}
                                                      theme_mode={theme_mode}
                                                      onClickFeed={() => {
                                                        this.handleClickFeed(
                                                          feed
                                                        );
                                                      }}
                                                      onFollowed={() => {
                                                        this.handleUnFollowFeed(
                                                          feed.id
                                                        );
                                                      }}
                                                      unFollowed={() => {
                                                        this.handleFollowFeed(
                                                          feed.id
                                                        );
                                                      }}
                                                    />
                                                  )
                                                )
                                              ) : (
                                                <div
                                                  className={
                                                    classes.seeAllDivider
                                                  }
                                                  style={{
                                                    alignItems: "center",
                                                    marginTop: 50,
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      classes.warningContainer
                                                    }
                                                  >
                                                    <img
                                                      className={
                                                        classes.warningimage
                                                      }
                                                      alt="warning"
                                                      src={`/static/images/icons/${theme_mode}/warning.png`}
                                                    />
                                                    <Typography
                                                      className={
                                                        classes.seeAllButtonContainerMobile
                                                      }
                                                    >
                                                      No feeds yet.
                                                    </Typography>
                                                  </div>
                                                </div>
                                              )}
                                            </List>
                                          </Grid>
                                        </div>
                                      ) : (
                                        <div
                                          className={classes.seeAllDivider}
                                          style={{
                                            alignItems: "center",
                                            marginTop: 50,
                                          }}
                                        >
                                          <div
                                            className={classes.warningContainer}
                                          >
                                            <img
                                              className={classes.warningimage}
                                              alt="warning"
                                              src={`/static/images/icons/${theme_mode}/warning.png`}
                                            />
                                            <Typography
                                              className={
                                                classes.seeAllButtonContainerMobile
                                              }
                                            >
                                              No feeds yet.
                                            </Typography>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>

                            <div
                              // className={classes.articlecontainer}
                              id="posts-swipeable-mastodon-all"
                            >
                              {this.state.innerTabValue ===
                                TAB_MASTODONALL_POSTS && (
                                <div
                                  style={{
                                    alignItems: "center",
                                    marginTop: 50,
                                  }}
                                >
                                  {this.state.mastodonDetails &&
                                    !requesting &&
                                    (isDesktop || isTablet) && (
                                      <div className={classes.seeAllDivider}>
                                        <div
                                          className={classes.warningContainer}
                                        >
                                          <img
                                            className={classes.warningimage}
                                            alt="warning"
                                            src={`/static/images/icons/${theme_mode}/warning.png`}
                                          />
                                          <Typography
                                            className={
                                              classes.seeAllButtonContainerMobile
                                            }
                                          >
                                            No feeds yet.
                                          </Typography>
                                        </div>
                                      </div>
                                    )}

                                  {this.state.mastodonDetails &&
                                    !requesting &&
                                    isMobile && (
                                      <div
                                        className={classes.seeAllDivider}
                                        style={{
                                          alignItems: "center",
                                          marginTop: 50,
                                        }}
                                      >
                                        <div
                                          className={classes.warningContainer}
                                        >
                                          <img
                                            className={classes.warningimage}
                                            alt="warning"
                                            src={`/static/images/icons/${theme_mode}/warning.png`}
                                          />
                                          <Typography
                                            className={
                                              classes.seeAllButtonContainerMobile
                                            }
                                          >
                                            No feeds yet.
                                          </Typography>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>
                            <div
                              // className={classes.articlecontainer}
                              id="posts-swipeable-mastodon-all"
                            >
                              {this.state.innerTabValue ===
                                TAB_BLUESKY_POSTS && (
                                <div>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                  >
                                    <List>
                                      {this.state.blueskyDetailss &&
                                        (isDesktop || isTablet) && (
                                          <div>
                                            {blueskyDetails!==null &&
                                            authUser.bluesky_username && authUser.is_bluesky_enabled &&
                                            feedsMoree?.length > 0 ? (
                                              <Grid item>
                                                {feedsMoree?.map(
                                                  (feed, index) => (
                                                    <div key={index}>
                                                      <BlueskyFeeds
                                                        width={
                                                          width === undefined
                                                            ? null
                                                            : width
                                                        }
                                                        feeds={feed}
                                                        loggedIn={loggedIn}
                                                        theme_mode={theme_mode}
                                                        // unFollowed={this.handleBlueskyFeedfollows}
                                                        unFollowed={() => {
                                                          this.handleBlueskyFeedfollows(
                                                            feed.uri
                                                          );
                                                        }}
                                                        followData={
                                                          this.state
                                                            .followDataaa
                                                        } // Pass followData here
                                                        newfollowing={
                                                          newfollowingg
                                                        }
                                                        isUsernamePresent={
                                                          isUsernamePresent
                                                        }
                                                      />
                                                    </div>
                                                  )
                                                )}
                                              </Grid>
                                            ) : (
                                              <div
                                                className={
                                                  classes.seeAllDivider
                                                }
                                                style={{
                                                  alignItems: "center",
                                                  marginTop: 50,
                                                }}
                                              >
                                                <div
                                                  className={
                                                    classes.warningContainer
                                                  }
                                                >
                                                  <img
                                                    className={
                                                      classes.warningimage
                                                    }
                                                    alt="warning"
                                                    src={`/static/images/icons/${theme_mode}/warning.png`}
                                                  />
                                                  <Typography
                                                    className={
                                                      classes.seeAllButtonContainerMobile
                                                    }
                                                  >
                                                    No feeds yet.
                                                  </Typography>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}

                                      {this.state.blueskyDetails &&
                                        isMobile &&
                                        // authUser.bluesky_username &&
                                         (
                                          <div
                                          // style={{ marginLeft: -333 }}
                                          >
                                            {blueskyDetails!==null &&
                                            authUser.bluesky_username && authUser.is_bluesky_enabled &&
                                            feedsMoree?.length > 0 ?(
                                              <Grid item>
                                                {feedsMoree?.map(
                                                  (feed, index) => (
                                                    <div key={index}>
                                                      <BlueskyFeeds
                                                        width={
                                                          width === undefined
                                                            ? null
                                                            : width
                                                        }
                                                        feeds={feed}
                                                        loggedIn={loggedIn}
                                                        theme_mode={theme_mode}
                                                        // unFollowed={this.handleBlueskyFeedfollows}
                                                        unFollowed={() => {
                                                          this.handleBlueskyFeedfollows(
                                                            feed.uri
                                                          );
                                                        }}
                                                        followData={
                                                          this.state
                                                            .followDataaa
                                                        } // Pass followData here
                                                        newfollowing={
                                                          newfollowingg
                                                        }
                                                        isUsernamePresent={
                                                          isUsernamePresent
                                                        }
                                                      />
                                                    </div>
                                                  )
                                                )}
                                              </Grid>
                                            ) : (
                                              <div
                                                className={
                                                  classes.seeAllDivider
                                                }
                                                style={{
                                                  alignItems: "center",
                                                  marginTop: 50,
                                                }}
                                              >
                                                <div
                                                  className={
                                                    classes.warningContainer
                                                  }
                                                >
                                                  <img
                                                    className={
                                                      classes.warningimage
                                                    }
                                                    alt="warning"
                                                    src={`/static/images/icons/${theme_mode}/warning.png`}
                                                  />
                                                  <Typography
                                                    className={
                                                      classes.seeAllButtonContainerMobile
                                                    }
                                                  >
                                                    No feeds yet.
                                                  </Typography>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </List>
                                  </Grid>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    // className={classes.articlecontainer}
                    id="feed-swipeable-views-sources"
                  >
                    {this.state.tab_value === TAB_MASTODON_SOURCES && (
                      <div>
                        <div style={{ marginTop: 20 }}>
                          <div className={classes.profiletabs}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Grid item>
                                <PostTab
                                  theme_mode={theme_mode}
                                  onChangeTab={this.handleChangeTabs}
                                />
                              </Grid>
                            </Grid>
                            <div>
                              <div
                                // className={classes.articlecontainer}
                                id="posts-swipeable-newsraven-all"
                              >
                                {this.state.innerTabValue ===
                                  TAB_NEWSRAVEN_POSTS && (
                                  <div>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      style={{ marginTop: 20 }}
                                    >
                                      <List>
                                        {this.state.mastodonDetails &&
                                          (isDesktop || isTablet) && (
                                            <div
                                            // style={{ marginLeft: 20 }}
                                            >
                                              {searchSources &&
                                              searchSources.length > 0 ? (
                                                searchSources?.map(
                                                  (source, index) => (
                                                    <SourceItem
                                                      key={index}
                                                      width={
                                                        width === undefined
                                                          ? null
                                                          : width
                                                      }
                                                      source={source}
                                                      index={index}
                                                      following={authUser.sources_followed.some(
                                                        (sources) =>
                                                          sources.source_id ===
                                                          source.id
                                                      )}
                                                      loggedIn={loggedIn}
                                                      theme_mode={theme_mode}
                                                      onClickFeed={() => {
                                                        this.handleClickSource(
                                                          source
                                                        );
                                                      }}
                                                      onFollowed={() => {
                                                        this.handleUnFollowSource(
                                                          source.id
                                                        );
                                                      }}
                                                      unFollowed={() => {
                                                        this.handleFollowSource(
                                                          source.id
                                                        );
                                                      }}
                                                      onNeedMore={
                                                        this.handleSourcees
                                                      }
                                                    />
                                                  )
                                                )
                                              ) : (
                                                <div
                                                  className={
                                                    classes.seeAllDivider
                                                  }
                                                  style={{
                                                    alignItems: "center",
                                                    marginTop: 50,
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      classes.warningContainer
                                                    }
                                                  >
                                                    <img
                                                      className={
                                                        classes.warningimage
                                                      }
                                                      alt="warning"
                                                      src={`/static/images/icons/${theme_mode}/warning.png`}
                                                    />
                                                    <Typography
                                                      className={
                                                        classes.seeAllButtonContainerMobile
                                                      }
                                                    >
                                                      No sources yet.
                                                    </Typography>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                      </List>
                                    </Grid>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      style={{ marginTop: 20 }}
                                    >
                                      <List>
                                        {this.state.mastodonDetails &&
                                          isMobile && (
                                            <div
                                            // style={{ marginLeft: -333 }}
                                            >
                                              {searchSources &&
                                              searchSources.length > 0 ? (
                                                searchSources?.map(
                                                  (source, index) => (
                                                    <SourceItem
                                                      key={index}
                                                      width={
                                                        width === undefined
                                                          ? null
                                                          : width
                                                      }
                                                      source={source}
                                                      index={index}
                                                      following={authUser.sources_followed.some(
                                                        (sources) =>
                                                          sources.source_id ===
                                                          source.id
                                                      )}
                                                      loggedIn={loggedIn}
                                                      theme_mode={theme_mode}
                                                      onClickFeed={() => {
                                                        this.handleClickSource(
                                                          source
                                                        );
                                                      }}
                                                      onFollowed={() => {
                                                        this.handleUnFollowSource(
                                                          source.id
                                                        );
                                                      }}
                                                      unFollowed={() => {
                                                        this.handleFollowSource(
                                                          source.id
                                                        );
                                                      }}
                                                    />
                                                  )
                                                )
                                              ) : (
                                                <div
                                                  className={
                                                    classes.seeAllDivider
                                                  }
                                                  style={{
                                                    alignItems: "center",
                                                    marginTop: 50,
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      classes.warningContainer
                                                    }
                                                  >
                                                    <img
                                                      className={
                                                        classes.warningimage
                                                      }
                                                      alt="warning"
                                                      src={`/static/images/icons/${theme_mode}/warning.png`}
                                                    />
                                                    <Typography
                                                      className={
                                                        classes.seeAllButtonContainerMobile
                                                      }
                                                    >
                                                      No sources yet.
                                                    </Typography>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                      </List>
                                    </Grid>
                                  </div>
                                )}
                              </div>

                              <div
                                // className={classes.articlecontainer}
                                id="posts-swipeable-mastodon-all"
                              >
                                {this.state.innerTabValue ===
                                  TAB_MASTODONALL_POSTS && (
                                  <div>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      style={{ marginTop: 20 }}
                                    >
                                      <List>
                                        {this.state.mastodonDetails &&
                                          (isDesktop || isTablet) && (
                                            <div
                                            // style={{marginLeft:-250}}
                                            >
                                              {this.state.mastodonMoreeDetails
                                                ?.accounts &&
                                              this.state.mastodonMoreeDetails
                                                ?.accounts.length > 0 ? (
                                                this.state.mastodonMoreeDetails?.accounts?.map(
                                                  (source, index) => (
                                                    <MastodonSourceItem
                                                      key={index}
                                                      width={
                                                        width === undefined
                                                          ? null
                                                          : width
                                                      }
                                                      source={source}
                                                      index={index}
                                                      newfollowing={
                                                        newfollowing
                                                      }
                                                      loggedIn={loggedIn}
                                                      theme_mode={theme_mode}
                                                      unFollowed={() => {
                                                        this.handleFollowMastodonSource(
                                                          source.id
                                                        );
                                                      }}
                                                      followData={
                                                        this.state.followData
                                                      } // Pass followData here
                                                      isUsernamePresent={
                                                        isUsernamePresent
                                                      }
                                                      onNeedMore={
                                                        this
                                                          .handleMoreeMastodonSources
                                                      }
                                                    />
                                                  )
                                                )
                                              ) : (
                                                <div
                                                  className={
                                                    classes.seeAllDivider
                                                  }
                                                  style={{
                                                    alignItems: "center",
                                                    marginTop: 50,
                                                    // marginLeft:280
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      classes.warningContainer
                                                    }
                                                  >
                                                    <img
                                                      className={
                                                        classes.warningimage
                                                      }
                                                      alt="warning"
                                                      src={`/static/images/icons/${theme_mode}/warning.png`}
                                                    />
                                                    <Typography
                                                      className={
                                                        classes.seeAllButtonContainerMobile
                                                      }
                                                    >
                                                      No sources yet.
                                                    </Typography>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                      </List>
                                    </Grid>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      style={{ marginTop: 20 }}
                                    >
                                      <List>
                                        {this.state.mastodonDetails &&
                                          isMobile && (
                                            <div>
                                              {this.state.mastodonDetails && (
                                                <div>
                                                  {this.state.mastodonDetails
                                                    ?.accounts &&
                                                  this.state.mastodonDetails
                                                    .accounts.length > 0 ? (
                                                    this.state.mastodonMoreeDetails.accounts?.map(
                                                      (source, index) => (
                                                        <MastodonSourceItem
                                                          key={index}
                                                          width={
                                                            width === undefined
                                                              ? null
                                                              : width
                                                          }
                                                          source={source}
                                                          index={index}
                                                          newfollowing={
                                                            newfollowing
                                                          }
                                                          loggedIn={loggedIn}
                                                          theme_mode={
                                                            theme_mode
                                                          }
                                                          unFollowed={() => {
                                                            this.handleFollowMastodonSource(
                                                              source.id
                                                            );
                                                          }}
                                                          followData={
                                                            this.state
                                                              .followData
                                                          } // Pass followData here
                                                          isUsernamePresent={
                                                            isUsernamePresent
                                                          }
                                                          onNeedMore={
                                                            this
                                                              .handleMoreeMastodonSources
                                                          }
                                                        />
                                                      )
                                                    )
                                                  ) : (
                                                    <div
                                                      className={
                                                        classes.seeAllDivider
                                                      }
                                                      style={{
                                                        alignItems: "center",
                                                        marginTop: 50,
                                                      }}
                                                    >
                                                      <div
                                                        className={
                                                          classes.warningContainer
                                                        }
                                                      >
                                                        <img
                                                          className={
                                                            classes.warningimage
                                                          }
                                                          alt="warning"
                                                          src={`/static/images/icons/${theme_mode}/warning.png`}
                                                        />
                                                        <Typography
                                                          className={
                                                            classes.seeAllButtonContainerMobile
                                                          }
                                                        >
                                                          No sources yet.
                                                        </Typography>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                      </List>
                                    </Grid>
                                  </div>
                                )}
                              </div>
                              <div
                                // className={classes.articlecontainer}
                                id="posts-swipeable-mastodon-all"
                              >
                                {this.state.innerTabValue ===
                                  TAB_BLUESKY_POSTS && (
                                  <div>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      style={{ marginTop: 20 }}
                                    >
                                      <List>
                                        {this.state.mastodonDetails &&
                                          (isDesktop || isTablet) && (
                                            <div style={{ marginLeft: -50 }}>
                                              <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="flex-start"
                                                style={{ marginTop: 20 }}
                                              >
                                                <List>
                                                  {this.state.blueskyDetails &&
                                                    (isDesktop || isTablet) && (
                                                      <div>
                                                        {this.state
                                                          .blueskyMoreeDetailsss &&
                                                        authUser.bluesky_username &&
                                                        sourceMoree.length >
                                                          0 ? (
                                                          sourceMoree?.map(
                                                            (source) => (
                                                              <div
                                                                key={source.did}
                                                              >
                                                                <BlueskySourceItem
                                                                  width={
                                                                    width ===
                                                                    undefined
                                                                      ? null
                                                                      : width
                                                                  }
                                                                  source={
                                                                    source
                                                                  }
                                                                  loggedIn={
                                                                    loggedIn
                                                                  }
                                                                  theme_mode={
                                                                    theme_mode
                                                                  }
                                                                  onNeedMore={() => {
                                                                    this.handleMoreeBlueskySources();
                                                                  }}
                                                                  blueskyfollowing={
                                                                    blueskyfollowing
                                                                  }
                                                                  unFollowed={() => {
                                                                    this.handleBlueskyFOllows(
                                                                      source.did
                                                                    );
                                                                  }}
                                                                  followData={
                                                                    this.state
                                                                      .followDataa
                                                                  } // Pass followData here
                                                                  newfollowing={
                                                                    newfollowing
                                                                  }
                                                                  isUsernamePresent={
                                                                    isUsernamePresent
                                                                  }
                                                                />
                                                              </div>
                                                            )
                                                          )
                                                        ) : (
                                                          <div
                                                            className={
                                                              classes.seeAllDivider
                                                            }
                                                            style={{
                                                              alignItems:
                                                                "center",
                                                              marginTop: 50,
                                                              // marginLeft:260
                                                            }}
                                                          >
                                                            <div
                                                              className={
                                                                classes.warningContainer
                                                              }
                                                            >
                                                              <img
                                                                className={
                                                                  classes.warningimage
                                                                }
                                                                alt="warning"
                                                                src={`/static/images/icons/${theme_mode}/warning.png`}
                                                              />
                                                              <Typography
                                                                className={
                                                                  classes.seeAllButtonContainerMobile
                                                                }
                                                              >
                                                                No sources yet.
                                                              </Typography>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    )}
                                                </List>
                                              </Grid>
                                            </div>
                                          )}

                                        {this.state.blueskyDetails &&
                                          isMobile &&
                                          authUser.bluesky_username && (
                                            <div>
                                              <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="flex-start"
                                                style={{ marginTop: 20 }}
                                              >
                                                <List>
                                                  {this.state
                                                    .mastodonDetails && (
                                                    <div>
                                                      {this.state
                                                        .blueskyMoreeDetailsss &&
                                                      authUser.bluesky_username &&
                                                      sourceMoree.length > 0 ? (
                                                        sourceMoree?.map(
                                                          (source) => (
                                                            <div
                                                              key={source.id}
                                                            >
                                                              <BlueskySourceItem
                                                                width={
                                                                  width ===
                                                                  undefined
                                                                    ? null
                                                                    : width
                                                                }
                                                                source={source}
                                                                loggedIn={
                                                                  loggedIn
                                                                }
                                                                theme_mode={
                                                                  theme_mode
                                                                }
                                                                onNeedMore={() => {
                                                                  this.handleMoreeBlueskySources();
                                                                }}
                                                                followData={
                                                                  this.state
                                                                    .followDataa
                                                                } // Pass followData here
                                                                newfollowing={
                                                                  newfollowing
                                                                }
                                                                isUsernamePresent={
                                                                  isUsernamePresent
                                                                }
                                                                unFollowed={() => {
                                                                  this.handleBlueskyFOllows(
                                                                    source.did
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          )
                                                        )
                                                      ) : (
                                                        <div
                                                          className={
                                                            classes.seeAllDivider
                                                          }
                                                          style={{
                                                            alignItems:
                                                              "center",
                                                            marginTop: 50,
                                                          }}
                                                        >
                                                          <div
                                                            className={
                                                              classes.warningContainer
                                                            }
                                                          >
                                                            <img
                                                              className={
                                                                classes.warningimage
                                                              }
                                                              alt="warning"
                                                              src={`/static/images/icons/${theme_mode}/warning.png`}
                                                            />
                                                            <Typography
                                                              className={
                                                                classes.seeAllButtonContainerMobile
                                                              }
                                                            >
                                                              No sources yet.
                                                            </Typography>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                                </List>
                                              </Grid>
                                            </div>
                                          )}
                                      </List>
                                    </Grid>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className={classes.articlecontainer}
                    id="feed-swipeable-views-hashtags"
                    style={{ marginTop: 50 }}
                  >
                    {this.state.tab_value === TAB_MASTODON_HASHTAGS && (
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        {(isDesktop || isTablet) && (
                          <Grid item>
                            {authUser.is_mastodon_enabled && loggedIn && (
                              <Toolbar className={classes.toolbar}>
                                <img
                                  alt={"Raven"}
                                  src={`/static/images/icons/${theme_mode}/mastodon.png`}
                                  className={classes.avatar}
                                  // style={{ marginLeft: 20, width: 25 }}
                                />
                                <Typography
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "bold",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    marginBottom: -25,
                                    marginLeft: 20,
                                  }}
                                >
                                  Mastodon
                                </Typography>
                              </Toolbar>
                            )}
                            {this.state.mastodonDetails &&
                              loggedIn &&
                              hashtagsMoree &&
                              hashtagsMoree.length > 0 && (
                                <MastodonHashtags
                                  hashtags={hashtagsMoree}
                                  onNeedMore={this.handleMorePosts}
                                />
                              )}

                            {this.state.mastodonDetails &&
                              (!hashtagsMoree ||
                                hashtagsMoree.length === 0) && (
                                <div
                                  className={classes.seeAllDivider}
                                  style={{
                                    alignItems: "center",
                                    marginTop: 70,
                                  }}
                                >
                                  <div className={classes.warningContainer}>
                                    <img
                                      className={classes.warningimage}
                                      alt="warning"
                                      src={`/static/images/icons/${theme_mode}/warning.png`}
                                    />
                                    <Typography
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                    >
                                      No hashtags yet.
                                    </Typography>
                                  </div>
                                </div>
                              )}
                          </Grid>
                        )}
                        {isMobile && (
                          <Grid item>
                            {this.state.mastodonDetails &&
                              authUser.is_mastodon_enabled &&
                              hashtagsMoree &&
                              hashtagsMoree.length > 0 && (
                                <div style={{ marginLeft: -90 }}>
                                  {authUser.is_mastodon_enabled && (
                                    <Toolbar className={classes.toolbar}>
                                      <img
                                        alt={"Raven"}
                                        src={`/static/images/icons/${theme_mode}/mastodon.png`}
                                        className={classes.avatar}
                                        // style={{ marginLeft: 20, width: 25 }}
                                      />
                                      <Typography
                                        style={{
                                          fontSize: "1.1rem",
                                          fontWeight: "bold",
                                          display: "inline-block",
                                          verticalAlign: "middle",
                                          marginBottom: -25,
                                          marginLeft: 20,
                                        }}
                                      >
                                        Mastodon
                                      </Typography>
                                    </Toolbar>
                                  )}
                                  <MastodonHashtags
                                    hashtags={hashtagsMoree}
                                    onNeedMore={this.handleMorePosts}
                                  />
                                </div>
                              )}

                            {this.state.mastodonDetails &&
                              (!hashtagsMoree ||
                                hashtagsMoree.length === 0) && (
                                <div
                                  className={classes.seeAllDivider}
                                  style={{
                                    alignItems: "center",
                                    marginTop: 70,
                                    marginLeft: -100,
                                  }}
                                >
                                  <div className={classes.warningContainer}>
                                    <img
                                      className={classes.warningimage}
                                      alt="warning"
                                      src={`/static/images/icons/${theme_mode}/warning.png`}
                                    />
                                    <Typography
                                      className={
                                        classes.seeAllButtonContainerMobile
                                      }
                                    >
                                      No hashtags yet.
                                    </Typography>
                                  </div>
                                </div>
                              )}
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </div>
                  <div
                    // className={classes.articlecontainer}
                    id="feed-swipeable-views-posts"
                  >
                    {this.state.tab_value === TAB_MASTODON_POSTS && (
                      <div style={{ marginTop: 20 }}>
                        <div className={classes.profiletabs}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Grid item>
                              <PostTab
                                theme_mode={theme_mode}
                                onChangeTab={this.handleChangeTabs}
                                loggedIn={loggedIn}
                              />
                            </Grid>
                          </Grid>
                          <div>
                            <div
                              // className={classes.articlecontainer}
                              id="posts-swipeable-newsraven-all"
                            >
                              {this.state.innerTabValue ===
                                TAB_NEWSRAVEN_POSTS && (
                                <div>
                                  {this.state.mastodonDetails &&
                                    (isDesktop || isTablet) && (
                                      <div style={{marginLeft:50}}>
                                        {searchResults &&
                                        searchResults.length > 0 ? (
                                          <Grid item>
                                            <SearchArticleMasonary
                                              width={width}
                                              articles={searchResults}
                                              pins={pins}
                                              movetops={movetops}
                                              onNeedMore={this.handleNeedMore}
                                              onSelectArticle={
                                                this.handleSelectArticle
                                              }
                                              onSelectGroupArticle={
                                                this.handleSelectGroupArticle
                                              }
                                              onNeedLogin={this.handleLogin}
                                              onReport={
                                                this.handleReportArticle
                                              }
                                              onEdit={this.handleEditArticle}
                                              onDelete={
                                                this.handleDeleteArticle
                                              }
                                              onSave={this.handleSaveArticle}
                                              onDeleteSaved={
                                                this.handleDeleteSavedArticle
                                              }
                                              onClickSource={
                                                this.handleClickSource
                                              }
                                              onClickFeed={this.handleClickFeed}
                                              onClickUpvote={
                                                this.handleClickUpvote
                                              }
                                              onClickComment={
                                                this.handleClickComment
                                              }
                                              onClickRepost={
                                                this.handleClickRepost
                                              }
                                            />
                                          </Grid>
                                        ) : (
                                          <div
                                            className={classes.seeAllDivider}
                                            style={{
                                              alignItems: "center",
                                              marginTop: 70,
                                            }}
                                          >
                                            <div
                                              className={
                                                classes.warningContainer
                                              }
                                            >
                                              <img
                                                className={classes.warningimage}
                                                alt="warning"
                                                src={`/static/images/icons/${theme_mode}/warning.png`}
                                              />
                                              <Typography
                                                className={
                                                  classes.seeAllButtonContainerMobile
                                                }
                                              >
                                                No posts yet.
                                              </Typography>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}

                                  {this.state.mastodonDetails && isMobile && (
                                    <div
                                    // style={{ marginLeft: -333 }}
                                    >
                                      {searchResults &&
                                      searchResults.length > 0 ? (
                                        <Grid item>
                                          <SearchArticleMasonary
                                            width={width}
                                            articles={searchResults}
                                            pins={pins}
                                            movetops={movetops}
                                            onNeedMore={this.handleNeedMore}
                                            onSelectArticle={
                                              this.handleSelectArticle
                                            }
                                            onSelectGroupArticle={
                                              this.handleSelectGroupArticle
                                            }
                                            onNeedLogin={this.handleLogin}
                                            onReport={this.handleReportArticle}
                                            onEdit={this.handleEditArticle}
                                            onDelete={this.handleDeleteArticle}
                                            onSave={this.handleSaveArticle}
                                            onDeleteSaved={
                                              this.handleDeleteSavedArticle
                                            }
                                            onClickSource={
                                              this.handleClickSource
                                            }
                                            onClickFeed={this.handleClickFeed}
                                            onClickUpvote={
                                              this.handleClickUpvote
                                            }
                                            onClickComment={
                                              this.handleClickComment
                                            }
                                            onClickRepost={
                                              this.handleClickRepost
                                            }
                                          />
                                        </Grid>
                                      ) : (
                                        <div
                                          className={classes.seeAllDivider}
                                          style={{
                                            alignItems: "center",
                                            marginTop: 70,
                                            // marginLeft:230
                                          }}
                                        >
                                          <div
                                            className={classes.warningContainer}
                                          >
                                            <img
                                              className={classes.warningimage}
                                              alt="warning"
                                              src={`/static/images/icons/${theme_mode}/warning.png`}
                                            />
                                            <Typography
                                              className={
                                                classes.seeAllButtonContainerMobile
                                              }
                                            >
                                              No posts yet.
                                            </Typography>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            <div
                              // className={classes.articlecontainer}
                              id="posts-swipeable-mastodon-all"
                            >
                              {this.state.innerTabValue ===
                                TAB_MASTODONALL_POSTS && (
                                <div>
                                  {this.state.mastodonDetails &&
                                    (isDesktop || isTablet) && (
                                      <div style={{ marginLeft: 50 }}>
                                        {this.state.mastodonMoreeDetails
                                          ?.statuses &&
                                        this.state.mastodonMoreeDetails
                                          ?.statuses.length > 0 ? (
                                          <div>
                                            <Grid item>
                                              <MastodonShared
                                                posts={postMoree}
                                                onNeedMore={
                                                  this.handleMoreMastodonPosts
                                                }
                                              />
                                            </Grid>
                                          </div>
                                        ) : (
                                          <div
                                            className={classes.seeAllDivider}
                                            style={{
                                              alignItems: "center",
                                              marginTop: 70,
                                            }}
                                          >
                                            <div
                                              className={
                                                classes.warningContainer
                                              }
                                            >
                                              <img
                                                className={classes.warningimage}
                                                alt="warning"
                                                src={`/static/images/icons/${theme_mode}/warning.png`}
                                              />
                                              <Typography
                                                className={
                                                  classes.seeAllButtonContainerMobile
                                                }
                                              >
                                                No posts yet.
                                              </Typography>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}

                                  {this.state.mastodonDetails && isMobile && (
                                    <div>
                                      {postMoree && postMoree.length > 0 ? (
                                        <div
                                        // style={{ marginLeft: -50 }}
                                        >
                                          <Grid item>
                                            <MastodonShared
                                              posts={postMoree}
                                              onNeedMore={
                                                this.handleMoreMastodonPosts
                                              }
                                            />
                                          </Grid>
                                        </div>
                                      ) : (
                                        <div
                                          className={classes.seeAllDivider}
                                          style={{
                                            alignItems: "center",
                                            marginTop: 70,
                                            // marginLeft:200
                                          }}
                                        >
                                          <div
                                            className={classes.warningContainer}
                                          >
                                            <img
                                              className={classes.warningimage}
                                              alt="warning"
                                              src={`/static/images/icons/${theme_mode}/warning.png`}
                                            />
                                            <Typography
                                              className={
                                                classes.seeAllButtonContainerMobile
                                              }
                                            >
                                              No posts yet.
                                            </Typography>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            <div
                              // className={classes.articlecontainer}
                              id="posts-swipeable-mastodon-all"
                            >
                              {this.state.innerTabValue ===
                                TAB_BLUESKY_POSTS && (
                                <div>
                                  {this.state.mastodonDetails &&
                                    (isDesktop || isTablet) && (
                                      <div style={{ marginLeft: 50 }}>
                                        {this.state.blueskyDetails &&
                                        authUser.bluesky_username &&
                                        this.state.blueskyMoreeDetails?.posts
                                          .length > 0 ? (
                                          <Grid item>
                                            <BlueskyShared
                                              posts={postsMoree}
                                              onNeedMore={
                                                this.handleMoreeBlueskyPosts
                                              }
                                            />
                                          </Grid>
                                        ) : (
                                          <div
                                            className={classes.seeAllDivider}
                                            style={{
                                              alignItems: "center",
                                              marginTop: 70,
                                              // marginLeft:200
                                            }}
                                          >
                                            <div
                                              className={
                                                classes.warningContainer
                                              }
                                            >
                                              <img
                                                className={classes.warningimage}
                                                alt="warning"
                                                src={`/static/images/icons/${theme_mode}/warning.png`}
                                              />
                                              <Typography
                                                className={
                                                  classes.seeAllButtonContainerMobile
                                                }
                                              >
                                                No posts yet.
                                              </Typography>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}

                                  {this.state.blueskyDetails &&
                                    isMobile &&
                                    authUser.bluesky_username && (
                                      <div
                                      // style={{ marginLeft: -155 }}
                                      >
                                        {this.state.blueskyDetails &&
                                        authUser.bluesky_username &&
                                        this.state.blueskyDetails.posts.length >
                                          0 ? (
                                          <Grid item>
                                            <BlueskyShared
                                              posts={postsMoree}
                                              onNeedMore={
                                                this.handleMoreeBlueskyPosts
                                              }
                                            />
                                          </Grid>
                                        ) : (
                                          <div
                                            className={classes.seeAllDivider}
                                            style={{
                                              alignItems: "center",
                                              marginTop: 70,
                                              // marginLeft:230
                                            }}
                                          >
                                            <div
                                              className={
                                                classes.warningContainer
                                              }
                                            >
                                              <img
                                                className={classes.warningimage}
                                                alt="warning"
                                                src={`/static/images/icons/${theme_mode}/warning.png`}
                                              />
                                              <Typography
                                                className={
                                                  classes.seeAllButtonContainerMobile
                                                }
                                              >
                                                No posts yet.
                                              </Typography>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </SwipeableViews>
                <div
                  className={classes.seeAllDivider}
                  style={{ marginTop: 50 }}
                >
                  {showWarning && (
                    <div className={classes.warningContainer}>
                      <img
                        className={classes.warningImage}
                        alt="warning"
                        src={`/static/images/icons/${theme_mode}/warning.png`}
                      />
                      <Typography variant="body1">No results yet.</Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <DlgConfirm
              open={showConfirmDlg}
              title={"Create a List/Feed"}
              content={"Do you really want to add this feed?"}
              onOK={this.handleCreateFeed}
              onCancel={this.handleCancel}
            />
            <ToastContainer />
            <WaitingSpinner open={requesting} />
          </div>
        );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  searchKey: state.dataState.searchKey,
  searchResults: state.dataState.searchResults,
  last_offset: state.dataState.last_offset,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  requesting: state.uiState.requesting,
  feeds: state.dataState.feeds,
  theme_mode: state.uiState.theme_mode,
  scrollPos: state.uiState.scrollPos,
  myfeedstab: state.uiState.myfeedstab
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SearchResultPage);
