import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { BasicAppBar } from "components";
import { MAX_ARTICLE_WIDTH } from "constants/types";


const styles = theme => ({
  root: {
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    marginLeft: 8,
    marginRight: 8,
  },
});

class ExternalView extends React.Component {
  constructor(props) {
    super(props);

    this.handleNavBack = this.handleNavBack.bind(this);
  }

  handleNavBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes, external_url } = this.props;

    // const external_url2 = "https://twitter.com/imgrund/status/1405869703277449222";

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    width -= 16;
    let height = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight;
    height -= 64;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_ARTICLE_WIDTH}
            title={"Web View"}
            onNavBack={this.handleNavBack}
          />
        </div>

        <div className={classes.container}>
          <div dangerouslySetInnerHTML={{__html: `<iframe src="${external_url}" height="${height}" width="${width}" />`}}>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  external_url: state.dataState.external_url
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ExternalView);
