import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withRouter } from "react-router-dom";
import { withFirebase } from 'services';
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  Popover,
} from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer } from "react-toastify";
import { 
  withAuthentication, 
  withAuthorization 
} from "session";
import {
  ApplyAppBar,
  BranchPad, 
  SourceCardList
} from "components";
import { 
  BRANCH_ALL, 
  get_branch,
  is_external_branch
} from "constants/branches";
import * as ROUTES from "constants/routes";
import { ACTIVITY_TYPE_FEED, ACTIVITY_ADD } from "constants/activity";
import { GRAPHQL_SUCCESS, MAX_WINDOW_WIDTH } from "constants/types";
import { getAuthToken, isFeedModerator, updateCache } from "dataapis";
import { GraphqlService } from "services";
import { ToastInfo, ToastError } from "utility/toast";
import { logger } from "utility/logging";


const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: theme.breakpoints.lg,
    maxWidth: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  searchcontainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  searchicon: {
    padding: 8,
    width: 32,
    height: 32,
  },
  icon: {
    padding: 8,
    paddingLeft: 12,
    paddingRight: 12,
    width: 40,
    height: 32,
    backgroundColor: theme.palette.background.main,
    borderRadius: 2,
  },
  all: {
    padding: 6,
    paddingLeft: 12,
    fontSize: 14,
    fontWeight: 500,
    width: 40,
    height: 32,
    backgroundColor: theme.palette.background.main,
    borderRadius: 2,
  },
  customInput: {
    "&:before": {
      borderBottomColor: theme.palette.text.secondary,
    },
    "&:after": {
      borderBottomColor: theme.palette.text.secondary,
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: theme.palette.text.secondary,
    },
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: 12,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  sourcescontainer: {
    margin: theme.spacing(1),
  }
});

class SourceSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: '',
      anchorEl: null,
      branch: BRANCH_ALL,
      foundsources: [],
      selectedsources: []
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleClickBranchIcon = this.handleClickBranchIcon.bind(this);
    this.handleSelectBranch = this.handleSelectBranch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelectSource = this.handleSelectSource.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = waiting => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleNavBack = () => {
    this.props.history.goBack();
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleApply = async () => {
    const { selectedsources } = this.state;
    const { selected_feed, authUser } = this.props;
    if (selectedsources.length === 0) {
      return;
    }

    // From Add Feed page
    if (!selected_feed) {
      this.props.setTempFeedSources(selectedsources);
      this.handleNavBack();
      return;
    }

    // from Edit Feed page
    const feed_sources = selected_feed.feed_sources.slice();
    let new_sources = [];
    for (let source of selectedsources) {
      if (feed_sources.find(feed_source => feed_source.source_id === source.id) === undefined) {
        new_sources.push(source);
      }
    }
    if (new_sources.length === 0) {
      ToastInfo("These sources are already in the feed.");
      return;
    }

    const isModerator = isFeedModerator(selected_feed);
    const approved = isModerator || !selected_feed.private;

    const currentTime = new Date().toISOString();
    const new_feed_sources = new_sources.map(source => {
      return {
        id: uuidv4(),
        feed_id: selected_feed.id,
        source_id: source.id,
        created_by: authUser.uid,
        approved: approved,
        approved_by: approved ? authUser.uid : null,
        approved_at: approved ? currentTime : null
      };
    });
    const feed_source_changes = new_sources.map(source => {
      return {
        feed_id: selected_feed.id,
        source_id: source.id,
        removed: false,
        created_by: authUser.uid
      };
    });

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);
    await gqlservice
      .insert_feed_sources(new_feed_sources)
      .then(result => {
        const feed_sources = result.data.insert_feed_sources.returning[0].feed.feed_sources;
        this.props.updateFeedSources(selected_feed.id, feed_sources);
        
        const infoMsg = isModerator ?
          `${new_feed_sources.length} sources inserted!` :
          `${new_feed_sources.length} sources inserted! Please wait to be approved by moderator.`;
        ToastInfo(infoMsg);

        const activity = {
          user_id: authUser.uid,
          type: ACTIVITY_TYPE_FEED,
          type_id: selected_feed.id,
          action: ACTIVITY_ADD,
          object: `${new_feed_sources.length} sources`,
          fromto: `to the feed ${selected_feed.name}`,
          reason: ''
        };

        return gqlservice.insert_activitylog(activity);
      })
      .then(result => {
        this.setState({
          ...this.state,
          selectedsources: []
        });
        this.setWaiting(false);
      }, (reason) => {
        this.setError(reason.msg);
        this.setWaiting(false);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });

    // insert feed source changes
    if (feed_source_changes.length > 0) {
      logger.log("feed source changes :", feed_source_changes);
      let result = await gqlservice.insert_feed_source_changes(feed_source_changes);
      if (result.status_code !== GRAPHQL_SUCCESS || result.data.insert_feed_source_change.affected_rows === 0) {
        this.setError("Failed to insert feed source changes!");
      }
    }

    await updateCache();

    this.handleNavBack();
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleClickBranchIcon = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleSelectBranch = (branch) => {
    if (branch.value === this.state.branch) {
      this.setState({
        ...this.state,
        anchorEl: null
      });
    } else {
      this.setState({
        ...this.state,
        branch: branch.value,
        selectedsources: [],
        anchorEl: null
      }, () => {
        this.doSearch();
      });
    }
  };
  
  handleChange = (event) => {
    event.persist();

    this.setState({
      ...this.state,
      searchKey: event.target.value,
    });

    // this.setState({
    //   ...this.state,
    //   searchKey: event.target.value,
    // }, () => {
    //   const searchKey = event.target.value;
    //   if (searchKey.length > 1) {
    //     this.doSearch();
    //   }
    // });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.doSearch();
    }
  }

  handleSearch = () => {
    this.setWaiting(true);
    this.doSearch();
    this.setWaiting(false);
  }

  doSearch = () => {
    const { sources } = this.props;
    const { branch, searchKey } = this.state;

    const approved_sources = sources.filter(source => 
      source.approved && is_external_branch(source.branch) && source.is_published
    );

    var result = [];
    if (searchKey.length === 0) {
      // if (branch === BRANCH_ALL) {
      //   result = approved_sources.slice();
      // } else {
      //   result = approved_sources.filter(source => source.branch === branch);
      // }
      result = [];
    } else {
      const key = searchKey.toLowerCase();
      approved_sources.forEach(source => {
        if (branch === BRANCH_ALL) {
          if ( (source.name.toLowerCase().includes(key) || source.description.toLowerCase().includes(key) )
          ) {
            result.push(source);
          }
        } else {
          if ( 
            (source.name.toLowerCase().includes(key) || source.description.toLowerCase().includes(key)) &&
            source.branch === branch
          ) {
            result.push(source);
          }
        }
      });
    }

    this.setState({
      ...this.state,
      foundsources: result,
      error: false,
    });
  };

  handleSelectSource = (source_id, selected) => {
    const { sources } = this.props;
    const { selectedsources } = this.state;

    const selected_source = sources.find(source => source.id === source_id);
    if (!selected_source) {
      return;
    }

    let new_selected = selectedsources.slice();
    if (selected) {
      if (selectedsources.find(source => source.id === source_id) === undefined) {
        new_selected.push(selected_source);
      }
    } else {
      new_selected = selectedsources.filter(source => source.id !== source_id);
    }

    this.setState({
      ...this.state,
      selectedsources: new_selected
    });
  }

  render() {
    const { classes, theme_mode } = this.props;
    const {
      searchKey,
      branch,
      foundsources,
      selectedsources,
      anchorEl
    } = this.state;

    // enable apply button
    let apply_enabled = false;
    if (foundsources.length > 0) {
      apply_enabled = true;
    }

    const branch_info = get_branch(branch);

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <ApplyAppBar
            title={"Find Sources"}
            apply_enabled={apply_enabled}
            onApply={this.handleApply}
            onNavBack={this.handleNavBack}
          />
        </div>
        
        <Box className={classes.searchcontainer}>
          <TextField
            className={classes.searchKey}
            fullWidth
            name="searchKey"
            value={searchKey || ""}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            placeholder="Keywords/Hashtags"
            InputProps={{
              classes: {
                root: classes.customInput,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    className={classes.searchicon}
                    alt="search"
                    src={`/static/images/icons/${theme_mode}/search.png`}
                    onClick={this.handleSearch}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {branch === BRANCH_ALL && (
                    <Typography 
                      className={classes.all}
                      onClick={this.handleClickBranchIcon}
                    >
                      All
                    </Typography>
                  )}
                  {branch !== BRANCH_ALL && (
                    <img
                      className={classes.icon}
                      alt={branch_info.name}
                      src={`/static/images/icons/${theme_mode}/${branch_info.image}`}
                      onClick={this.handleClickBranchIcon}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {foundsources.length === 0 && (
            <Typography className={classes.description}>
              Find sources already indexed by Raven
            </Typography>
          )}
        </Box>
        <Popover
          id="branch-menu"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <BranchPad
            useAll
            selected={branch}
            theme={theme_mode}
            onSelect={this.handleSelectBranch} 
          />
        </Popover>
        {foundsources.length > 0 && (
          <div className={classes.sourcescontainer}>
            <SourceCardList
              sources={foundsources}
              selected={selectedsources}
              theme={theme_mode}
              onSelected={this.handleSelectSource}
            />
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  categories: state.dataState.categories,
  selected_category: state.dataState.selected_category,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withRouter,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SourceSearch);
