import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Badge } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  MAX_ARTICLE_WIDTH,
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  listitem: {
    display: "-webkit-box",
    padding: 0,
    margin: 0,
    cursor: "pointer",
  },
  badge_div: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
  },
  badge: {
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    backgroundColor: "red",
    color: "white",
  },
  listitem_avatar: {
    minWidth: 60,
    marginTop: 4,
  },
  listimage: {
    objectFit: "cover",
    height: 60,
    width: 60,
    borderRadius: 5,
    cursor: "pointer",
  },
  listitem_text: {
    maxWidth: `calc(100% - 160px)`,
    paddingLeft: theme.spacing(2),
    marginTop: 4,
    color: theme.palette.text.primary,
    cursor: "pointer",
    width: "auto",
    alignItems:'flex-start'
  },
  name: {
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: "12px",
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
  action: {
    width: "auto",
    margin: 0,
    marginTop: 0,
    padding: 0,
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  },
  following: {
    withth: 48,
    height: 48,
  },
});

class FeedTabItem extends React.Component {
  render() {
    const {
      classes,
      width,
      feed,
      index,
      following,
      loggedIn,
      badged,
      theme_mode,
      onClickFeed,
      onFollowed,
      unFollowed
    } = this.props;

    const following_img = `/static/images/icons/${theme_mode}/following.png`;
    const unfollowing_img = `/static/images/icons/${theme_mode}/unfollowing.png`;

    let rootWidth =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (rootWidth > 640) {
      rootWidth = 640 - 42;
    } else {
      rootWidth -= 32;
    }
    let textwidth = rootWidth - (16 + 60 + 32);

    return (
      <div
        className={classes.root}
        // style={{width: rootWidth }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          // alignItems="flex-start"
        >
          <Grid item className={classes.listitem_avatar}>
              <LazyLoadImage
                className={classes.listimage}
                alt={(feed.name)||(feed.feed.name)}
                src={ feed?.image || feed?.thumbnail || feed?.feed?.thumbnail || feed?.feed?.image || RAVEN_PLACEHOLDER_IMAGE}
                onClick={(e) => onClickFeed(feed.feed_id)}
              />
          </Grid>
          <Grid
            item
            className={classes.listitem_text}
            style={{width: textwidth}}
            onClick={(e) => onClickFeed(feed.feed_id)}
          >
            <div>
              <Typography className={classes.name}>{feed.name || feed.feed.name}</Typography>
              <Typography className={classes.description}>
                {(feed?.description)||(feed?.feed?.description)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs style={{ flexGrow: 1 }}></Grid>
          <Grid item className={classes.action}>
            {loggedIn && following && (
              <img
                className={classes.following}
                alt={"following"}
                src={following_img}
                onClick={(e) => onFollowed(feed.feed_id)}
              />
            )}
            {loggedIn && !following && (
              <img
                className={classes.following}
                alt={"unfollowing"}
                src={unfollowing_img}
                onClick={(e) => unFollowed(feed.feed_id)}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

FeedTabItem.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  feed: PropTypes.object,
  index: PropTypes.number,
  following: PropTypes.bool,
  loggedIn: PropTypes.bool,
  theme_mode: PropTypes.string,
  badged: PropTypes.bool,
  onClickFeed: PropTypes.func,
  onFollowed: PropTypes.func,
  unFollowed: PropTypes.func,
};

export default withStyles(styles)(FeedTabItem);
