import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withFirebase } from 'services';
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  Menu,
} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ToastContainer } from "react-toastify";
import _ from "lodash";
import {
  ArticleList,
  SlideIconLists,
  PopMenuUser,
  PopMenuUserMod,
  DlgLoginConfirm,
  DlgReport,
  DlgPostEdit,
  WaitingSpinner,
} from "components";
import {
  UserAppBar
} from "./components";
import { withAuthentication, withAuthorization } from "session";
import { GraphqlService } from "services";
import * as ROUTES from "constants/routes";
import {
  BLANK_USER_IMAGE,
  MAX_ARTICLE_WIDTH,
  PROFILE_TAB_FEEDS,
  PROFILE_TAB_SETTINGS,
  PROFILE_TAB_POSTS,
} from "constants/types";
import {
  BLOCK_USER,
  MUTE_USER,
  SUSPENSION_ALL_1D,
  SUSPENSION_ALL_7D,
  SUSPENSION_ALL_PERM,
  SUSPENSION_POSTS,
  SUSPENSION_COMMENTS,
  SUSPENSION_IMAGES,
  SUSPENSION_VIDEO,
  SUSPENSION_FEED_CREATION,
} from "constants/user";
import {
  ACTIVITY_TYPE_USER,
  ACTIVITY_BLOCK,
  ACTIVITY_UNBLOCK,
  ACTIVITY_MUTE,
  ACTIVITY_UNMUTE,
} from "constants/activity";
import {
  ACTIVITY_REPORT,
} from "constants/activity";
import { ToastError } from "utility/toast";
import { 
  deleteArticle,
  followFeed,
  followUser,
  getAuthToken,
  getMainInfo,
  isBanned,
  repostArticle, 
  saveArticle, 
  suspendUser, 
  unfollowFeed,
  unfollowUser,
  updatePost,
  upvoteArticle
} from "dataapis";  
import { logger } from "utility/logging";
import {
  get_substring,
} from "utility/utils";
import ProfileTabs from "views/Profile/components/ProfileTabs/ProfileTabs";
import SwipeableViews from "react-swipeable-views";
import FeedTabItem from "views/Profile/components/FeedTabItem";
import { ARTICLE_BRANCH_NEWSPAPER } from "constants/branches";

const condition = (authUser) => !!authUser && authUser.uid !== ""

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  header: {
    position: "fixed"
  },
  profileContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // marginTop: 56,
    // [theme.breakpoints.up("md")]: {
    //   marginTop: 64
    // }
  },
  userimage: {
    objectFit: "cover",
    height: 100,
    width: 100,
    borderRadius: 10,
  },
  followbutton: {
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.default
    },
    borderRadius: "16px",
    border: "2px solid #1878F3",
    boxSizing: "border-box",
    padding: "2px 16px",
    marginRight: theme.spacing(1),
  },
  followingbutton: {
    backgroundColor: "#1878F3",
    "&:hover": {
      backgroundColor: "#1878F3"
    },
    color: "#FFFFFF",
    borderRadius: "16px",
    border: "2px solid #1878F3",
    padding: "2px 16px",
    marginRight: theme.spacing(1),
  },
  detail: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  name: {
    width: "100%",
    minWidth: 100,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    border: 0,
    fontSize: 20,
    fontWeight: 600,
  },
  statusicon: {
    width: 16,
    height: 16,
  },
  statusroboricon: {
    width: 26,
    height: 26,
  },
  upvotes: {
    marginLeft: 4,
    fontSize: "18px",
    fontFamily: "Roboto",
  },
  username: {
    maxWidth: "100%",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    border: 0,
    fontSize: 20,
    marginTop: 4,
  },
  description: {
    width: "100%",
    height: 50,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    border: 0,
    fontSize: 16,
    padding: 0,
    marginTop: theme.spacing(3),
    fontFamily: "Roboto",
  },
  linkbuttons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  selectfollowing: {
    color: theme.palette.text.secondary,
    padding: 0,
    fontSize: 14,
    textTransform: 'none',
    position: "absolute",
    right: 16,
    alignItems: "top",
    lineHeight: "20px"
  },
  articles: {
    backgroundColor: theme.palette.background.default,
  },
  buttontext: {
    color: "#FFF",
    fontWeight: 400,
    fontSize: 16
  },
  actionbtn: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "20px",
    padding: "4px 10px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    textTransform: "initial",
  },
  actionicon: {
    padding: 3,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
  slidecontainer: {
    marginTop: theme.spacing(2),
  },
  slidetitlebar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  slidetitle: {
    color: theme.palette.text.primary
  },
  slidetitleicon: {
    width: 16,
    height: 16,
  },
  joinstring: {
    marginTop: theme.spacing(3),
    marginLeft: "15px"
  },
  stats: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "start",
    gap: "20px",
  },
  statText:{
    cursor: 'pointer'
  },
  profiletabs: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  warningcontainer: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: "center",
  },
  warningimg: {
    width: 48,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  warningicon: {
    width: 48,
    height: 48,
  },
  warningtitle: {
    width: 220,
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
});


class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upvotes: 0,
      loginDlg: false,
      reportDlg: false,
      postDlg: false,
      article_edit: null,
      anchorEl: null,
      setValue: 0,
      postcounts: null,
      finalpostcount: null,
      articleReposts: [],
      articlePosts: [],
      repostCount: null,
      followers:[],
      following:[]
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.showModerateMenu = this.showModerateMenu.bind(this);
    this.closeModerateMenu = this.closeModerateMenu.bind(this);

    this.handleBlockUser = this.handleBlockUser.bind(this);
    this.handleMuteUser = this.handleMuteUser.bind(this);
    this.showReportDlg = this.showReportDlg.bind(this);
    this.handleReportUser = this.handleReportUser.bind(this);

    this.handleSuspend1day = this.handleSuspend1day.bind(this);
    this.handleSuspend7day = this.handleSuspend7day.bind(this);
    this.handleSuspendPermanent = this.handleSuspendPermanent.bind(this);
    this.handleSuspendPosts = this.handleSuspendPosts.bind(this);
    this.handleSuspendComments = this.handleSuspendComments.bind(this);
    this.handleSuspendImages = this.handleSuspendImages.bind(this);
    this.handleSuspendVideo = this.handleSuspendVideo.bind(this);
    this.handleSuspendFeedCreation = this.handleSuspendFeedCreation.bind(this);
    this.handleActivityLog = this.handleActivityLog.bind(this);


    this.handleClickFollow = this.handleClickFollow.bind(this);
    this.handleClickUnfollow = this.handleClickUnfollow.bind(this);

    this.handleClickTwitter = this.handleClickTwitter.bind(this);
    this.handleClickInstagram = this.handleClickInstagram.bind(this);
    this.handleClickLinkedIn = this.handleClickLinkedIn.bind(this);

    this.handleSelectFeeds = this.handleSelectFeeds.bind(this);
    this.handleSelectUser = this.handleSelectUser.bind(this);

    this.handleNeedMore = this.handleNeedMore.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.closePostEditDlg = this.closePostEditDlg.bind(this);
    this.handleUpdatePost = this.handleUpdatePost.bind(this);
    this.handleShowPosts = this.handleShowPosts.bind(this);
    this.handlefollowers = this.handlefollowers.bind(this);
    this.handlefollowing = this.handlefollowing.bind(this);
    this.handleDivScroll = this.handleDivScroll.bind(this);
    this.handleUserPostsNeedMore = this.handleUserPostsNeedMore.bind(this);

  }

  setError = message => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const { match, loggedIn } = this.props;

    this.setWaiting(true);

    let username = match.params.username;
    if (!username) {
      this.setWaiting(false);
      return;
    }

    await this.getUserInfo(username);

    this.setWaiting(false);
  }

  componentDidUpdate = async (prevProps) => {
    const {authUser, match} = this.props;
    let username = match.params.username;
    if (!username) {
      this.setWaiting(false);
      return;
    }
    if (prevProps.authUser.uid !== authUser.uid) {
      await this.getUserInfo(username);
    }
  }

  getUserInfo = async (username) => {
    const { loggedIn, sources, feeds, user_posts } = this.props;

    const gqlservice = new GraphqlService();

    try {
      if (loggedIn) {
        const token = await getAuthToken();
        if (!token) {
          this.handleLogin();
          return;
        }
        gqlservice.set_auth_jwt(token);
      }

      // Get user
      const userResult = await gqlservice.user_by_username(username);
      const users = userResult.data.users;

      if (users.length === 0) {
        return
      }

      let selected_user = users[0];
      this.props.selectUser(selected_user);
      this.setState({
        ...this.state,
        followers: selected_user.user_followers ? selected_user.user_followers.map(item => item.follower_id) : [],
        following: selected_user.users_followed ? selected_user.users_followed.map(item => item.user_id) : []
      })
      
      const sourceId = `${selected_user.uid}-selfpost`;

      // Get upvotes
      const upvotesResult = await gqlservice.article_voter_count_by_user(selected_user.uid, sourceId);
      const counts = upvotesResult.data.article_voter_aggregate.aggregate.count;
      const artcilecount = upvotesResult.data.articles_aggregate.aggregate?.count;
      const repostcount = upvotesResult.data.article_repost_aggregate.aggregate.count
      const totalCount = artcilecount+repostcount
      this.setState({
        ...this.state,
        upvotes: counts,
        repostCount: repostcount,
        postcounts: totalCount
      });
      await getMainInfo();

    if (totalCount === 0 ) {
      this.setWaiting(false);
      return
    }    
    if (user_posts.length === totalCount) {
      this.setWaiting(false);
      return
    }  
      // Get user's posts
      const result = await gqlservice.articles_to_profile(selected_user.uid, sourceId);
      let newPosts = result.data.articles;
      let oldestDate = null
      if (newPosts.length > 0) {
        this.setState((prevState) => {
          const updatedArticlePosts = [...prevState.articlePosts, ...newPosts];
          oldestDate = newPosts.reduce((oldest, post) => (post.published < oldest ? post.published : oldest), Infinity);
          return {
            articlePosts: updatedArticlePosts,
          };
        });
      }

      let myRepostsResult = [];
      if (newPosts.length < 5) {
        myRepostsResult = await gqlservice.article_reposts(selected_user.uid);
      } else {
        myRepostsResult = await gqlservice.article_reposts(selected_user.uid, oldestDate);
      }
      const myReposts = myRepostsResult.data.article_repost;
      if (myReposts.length > 0) {
        for (let i = 0; i < myReposts.length; i++) {
          newPosts.push(myReposts[i].article);
        }
        this.setState({
          ...this.state,
          articleReposts: myReposts
        }, () => {
        });
      }

      //get unknown sources and feeds
      // if (newPosts.length > 0) {
      //   let articlesWithoutSourceDetails = newPosts.filter(article => (
      //     article.source_id !== null && !sources.some(source => source.id === article.source_id)
      //   ));
      //   if (articlesWithoutSourceDetails.length > 0) {
      //     const getSourceDetails = async (article) => {
      //       try {
      //         const sourceDetailsResult = await gqlservice.source_and_feed_sources_by_id(article.source_id);
      //         var sourceDetails = sourceDetailsResult.data.sources[0];
      //         this.props.addSource(sourceDetails);
      //         const feedFromSource = sourceDetails.feed_sources[0]?.feed;
      //         if (feedFromSource ) {
      //           this.props.addFeed(feedFromSource);
      //         }
      //       } catch (error) {
      //         console.error(`Error fetching source details for article ${article.nid}:`, error);
      //       }
      //     };
      //     await Promise.all(articlesWithoutSourceDetails.map(article => getSourceDetails(article)));
      //   }
      // }

      this.props.setUserPosts(newPosts);

    } catch (error) {
      this.setError(error.message || JSON.stringify(error));
    } finally {
      this.setWaiting(false);
    }
  };

  handleNavBack = () => {
    // const location = {
    //   pathname: ROUTES.HOME,
    //   state: { animation: "right" },
    // };
    // this.props.history.push(location);
    this.props.history.goBack();
  };

  showLoginDlg = (show) => {
    this.setState({
      ...this.state,
      loginDlg: show,
    });
  }

  handleLogin = () => {
    this.showLoginDlg(false);

    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.showLoginDlg(false);
  }

  showModerateMenu = (event) => {
    if (!this.props.loggedIn) {
      this.showLoginDlg();
      return;
    }
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  }

  hideModerateMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  }

  closeModerateMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  }

  handleBlockUser = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, authUser, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    if (loggedIn) {
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      gqlservice.set_auth_jwt(token);
    }

    const currentTime = new Date().toISOString();
    const blockmuteInfo = {
      user_id: authUser.uid,
      type: BLOCK_USER,
      target_user_id: selected_user.uid,
      enabled: enabled,
      created_at: currentTime,
    };

    await gqlservice
      .insert_user_blockmute(blockmuteInfo)
      .then((result) => {
        if (result.data.insert_user_blockmute_one?.id > 0) {
          return gqlservice.user_by_id(authUser.uid);
        } else {
          this.setError("Can't block user(server error)");
          return;
        }
      })
      .then((result) => {
        const users = result?.data?.users;
        if (users.length > 0) {
          this.props.setAuthUser(users[0]);
        }
      }, (reason) => {
        this.setError(reason.msg);
        return;
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_USER,
      type_id: selected_user.uid,
      action: enabled ? ACTIVITY_BLOCK : ACTIVITY_UNBLOCK,
      object: `the user ${selected_user.username}(${selected_user.uid})`,
      fromto: "",
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => { },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  }

  handleMuteUser = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, authUser, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    if (loggedIn) {
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      gqlservice.set_auth_jwt(token);
    }

    const currentTime = new Date().toISOString();
    const blockmuteInfo = {
      user_id: authUser.uid,
      type: MUTE_USER,
      target_user_id: selected_user.uid,
      enabled: enabled,
      created_at: currentTime,
    };

    await gqlservice
      .insert_user_blockmute(blockmuteInfo)
      .then((result) => {
        if (result.data.insert_user_blockmute_one?.id > 0) {
          return gqlservice.user_by_id(authUser.uid);
        } else {
          this.setError("Can't mute user(server error)");
          return;
        }
      })
      .then((result) => {
        const users = result?.data?.users;
        if (users.length > 0) {
          this.props.setAuthUser(users[0]);
        }
      }, (reason) => {
        this.setError(reason.msg);
        return;
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_USER,
      type_id: selected_user.uid,
      action: enabled ? ACTIVITY_MUTE : ACTIVITY_UNMUTE,
      object: `the user ${selected_user.username}(${selected_user.uid})`,
      fromto: "",
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => { },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  }

  showReportDlg = (show) => {
    this.setState({
      ...this.state,
      reportDlg: show,
    });
  }

  handleReportUser = async (reportMsg) => {
    this.hideModerateMenu();
    this.showReportDlg(false);
    const { loggedIn, authUser, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);

    let report = {
      user_id: selected_user.uid,
      report: reportMsg,
      reported_by: authUser.uid,
      approved: false,
    };

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    gqlservice.set_auth_jwt(authUser.token);
    await gqlservice
      .insert_user_report(report)
      .then(
        (result) => { },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_USER,
      type_id: selected_user.uid,
      action: ACTIVITY_REPORT,
      object: `the user ${selected_user.username}(${selected_user.uid})`,
      fromto: "",
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => { },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  }

  handleSuspend1day = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);
    await suspendUser(selected_user.uid, SUSPENSION_ALL_1D, enabled);
    this.setWaiting(false);
  }

  handleSuspend7day = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);
    this.suspendUser(selected_user.uid, SUSPENSION_ALL_7D, enabled);
    this.setWaiting(false);
  }

  handleSuspendPermanent = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);
    await suspendUser(selected_user.uid, SUSPENSION_ALL_PERM, enabled);
    this.setWaiting(false);
  }

  handleSuspendPosts = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);
    await suspendUser(selected_user.uid, SUSPENSION_POSTS, enabled);
    this.setWaiting(false);
  }

  handleSuspendComments = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);
    await suspendUser(selected_user.uid, SUSPENSION_COMMENTS, enabled);
    this.setWaiting(false);
  }

  handleSuspendImages = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);
    await suspendUser(selected_user.uid, SUSPENSION_IMAGES, enabled);
    this.setWaiting(false);
  }

  handleSuspendVideo = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);
    await suspendUser(selected_user.uid, SUSPENSION_VIDEO, enabled);
    this.setWaiting(false);
  }

  handleSuspendFeedCreation = async (enabled) => {
    this.hideModerateMenu();
    const { loggedIn, selected_user } = this.props;
    if (!loggedIn) {
      this.showLoginDlg();
      return;
    }

    this.setWaiting(true);
    await suspendUser(selected_user.uid, SUSPENSION_FEED_CREATION, enabled);
    this.setWaiting(false);
  }

  handleActivityLog = () => {

  }

  handleClickFollow = async () => {
    const { selected_user, authUser } = this.props;
    this.props.refreshFollowedComments()
    this.setWaiting(true);
    await followUser(selected_user.uid);
    this.setState(prevState => ({
      followers: [...prevState.followers, authUser.uid]
    }));
    this.setWaiting(false);
  }

  handleClickUnfollow = async () => {
    const { selected_user ,authUser } = this.props;
    const {followers} = this.state
    this.props.refreshFollowedComments()
    this.setWaiting(true);
    await unfollowUser(selected_user.uid);
    const newUser = authUser.users_followed.filter((user)=>{
      return user.user_id !== selected_user.uid
    })
    authUser.users_followed = newUser
    this.props.setAuthUser(authUser)
    this.setWaiting(false);

    this.setState(prevState => ({
      followers: prevState.followers.filter(followerId => followerId !== authUser.uid)
    }));
    
  }

  // empty functions
  handleClickTwitter = () => { }
  handleClickInstagram = () => { }
  handleClickLinkedIn = () => { }
  // empty functions


  handleSelectFeeds = (feed) => {
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  handleClickFeed = async (feed_id) => {
    const { selected_user, feeds } = this.props;
    const selected = feeds.find((item) => item.id === feed_id);
    if (selected) {
      let route = `/${ROUTES.FEEDS_PREFIX}/${selected.slug}`;
      this.props.selectFeed(selected);
      this.props.selectProfileTab(PROFILE_TAB_FEEDS);
      const location = {
        pathname: route,
        state: { animation: "left" },
      };

      this.props.history.push(location);
    } else {
      const token = await getAuthToken();
      if (!token) {
        console.log("no token");
        return null;
      }
      const gqlservice = new GraphqlService();
      gqlservice.set_auth_jwt(token);

      try {
        
        let result = await gqlservice.feed_by_id(feed_id);
        const fetchedFeed = result.data.feeds;
        if (fetchedFeed.length === 0) {
          return null;
        }
        const selectedFeed = fetchedFeed.find((item) => item.id === feed_id);
        let route = `/${ROUTES.FEEDS_PREFIX}/${selectedFeed.slug}`;
        this.props.selectFeed(selectedFeed);
        this.props.selectProfileTab(PROFILE_TAB_FEEDS);

        const location = {
          pathname: route,
          state: { animation: "left" },
        };

        this.props.history.push(location);
      } catch (error) {
        console.error("Error fetching feed:", error);
      }
    }
  };

  handleFollowFeed = async (feed_id) => {
    
    if (isBanned()) {
      ToastError("You've been suspended.");
      return;
    }
    const token = await getAuthToken();
    if (!token) {
      return null;
    }
    const gqlservice = new GraphqlService();
    gqlservice.set_auth_jwt(token);
    let result = await gqlservice.feed_by_id(feed_id);
    const feeds2show = result.data.feeds[0];
    const res = await followFeed(feeds2show);
  };

  handleUnFollowFeed = async (feed_id) => {
    
    const { followed_feeds, selected_user } = this.props;
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }
    let feeds2show = followed_feeds.slice();
    let feed = feeds2show.find((item) => item.id === feed_id);
    if (!feed) {
      const feeddata = selected_user.feeds_followed.find((item) => item.feed_id === feed_id);
      feed = feeddata.feed;
      if (!feed){
        return;
      }
    }
    const res = await unfollowFeed(feed);
    if (res === false) {
      return
    }
  };

  handleSelectUser = (user) => {
    const location = {
      pathname: `/${ROUTES.USER_PREFIX}/${user.username}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  handleNeedMore = () => { }

  handleSelectArticle = (article) => {
    const { selected_user } = this.props;
    this.props.selectArticle(article);

    let path = `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path = `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    }
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(`${ROUTES.USER_PREFIX}/${selected_user.username}`);
  }

  handleSelectGroupArticle = async (nid) => {}

  handleReportArticle = async (article, reportMsg) => {}

  handleEditArticle = (article) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn || authUser.uid !== article.txt_param1) {
      return;
    }
    this.setState({
      ...this.state,
      postDlg: true,
      article_edit: article
    });
  }

  handleDeleteArticle = async (article) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn || authUser.uid !== article.txt_param1) {
      return;
    }
    this.setWaiting(true);
    await deleteArticle(article);
    this.setWaiting(false);
  }

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  }

  handleDeleteSavedArticle = (article) => { }

  handleClickSource = (source, feed) => {
  }

  handleDivScroll(event) {
    const div = event.target;
    if ((div.scrollTop + div.clientHeight === div.scrollHeight) || (div.scrollTop + div.clientHeight >= div.scrollHeight - 4)) {
      this.handleUserPostsNeedMore();
    }
  }

  async handleUserPostsNeedMore() {

    const { requesting, selected_user, user_posts, sources } = this.props;
    const { postcounts, articlePosts, articleReposts, finalpostcount, repostCount } = this.state;

    if (user_posts.length >= postcounts || finalpostcount === 0) {
      return;
    }
    if (requesting) {
      return;
    }
    this.setWaiting(true);

    try {
      const gqlservice = new GraphqlService();

      let oldestDate = null
      if (articlePosts.length > 0) {

        const lastArticleElement = _.last(articlePosts);  
        oldestDate = lastArticleElement?.published
      }
      let dateTo = null;
      if (articleReposts.length > 0) {
        dateTo = articleReposts.reduce((oldest, repost) => {
          return repost.created_at < oldest ? repost.created_at : oldest;
        });
      }

      const sourceId = `${selected_user.uid}-selfpost`
      const result = await gqlservice.articles_to_profile(selected_user.uid, sourceId, oldestDate);
      let newPosts = result.data.articles;
      if (newPosts.length > 0) {
        this.setState((prevState) => {
          const updatedArticlePosts = [...newPosts];      
          return {
            ...prevState,
            articlePosts: updatedArticlePosts,
          };
        });
      }
      if (repostCount > articleReposts.length){
        let myRepostsResult = []
        if (newPosts.length < 5) {
          oldestDate = null
          myRepostsResult = await gqlservice.article_reposts(selected_user.uid, oldestDate, dateTo);
        } else {
          myRepostsResult = await gqlservice.article_reposts(selected_user.uid, oldestDate, dateTo);
        }
        const myReposts = myRepostsResult.data.article_repost;
        for (let i = 0; i < myReposts.length; i++) {
          newPosts.push(myReposts[i].article);
        }
        this.setState({
          ...this.state,
          articleReposts: myReposts
        }, () => {
        });
      }

      //unknown sources
      // if (newPosts.length > 0) {
      //   let articlesWithoutSourceDetails = newPosts.filter(article => (
      //     article.source_id !== null && !sources.some(source => source.id === article.source_id)
      //   ));
      //   if (articlesWithoutSourceDetails.length > 0) {
      //     const getSourceDetails = async (article) => {
      //       try {
      //         const sourceDetailsResult = await gqlservice.source_and_feed_sources_by_id(article.source_id);
      //         var sourceDetails = sourceDetailsResult.data.sources[0];
      //         this.props.addSource(sourceDetails);
      //       } catch (error) {
      //         console.error(`Error fetching source details for article ${article.nid}:`, error);
      //       }
      //     };
      //     await Promise.all(articlesWithoutSourceDetails.map(article => getSourceDetails(article)));
      //   }
      // }
      const uniqueNids = new Set(user_posts.map(article => article.nid));
      const filteredNewPosts = newPosts.filter(article => !uniqueNids.has(article.nid));
      if (filteredNewPosts.length === 0) {
        this.setState({
          ...this.state,
          finalpostcount: 0
        }, () => {
        });
      }
      // Update user_posts in the Redux state
      this.props.setUserPosts([...user_posts, ...filteredNewPosts]);
    } catch (error) {
      console.error("Error fetching more user articles:", error);
    } finally {
      this.setWaiting(false);
    }
  }

  handleClickUpvote = async (article) => {
    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  }

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  }

  handleClickRepost = async (article) => {
    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  }

  closePostEditDlg = () => {
    this.setState({
      ...this.state,
      postDlg: false,
      article_edit: null
    });
  };

  handleUpdatePost = async (description, postlink) => {
    this.setState({
      ...this.state,
      postDlg: false
    });

    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }

    const { article_edit } = this.state;

    this.setWaiting(true);
    await updatePost(description, postlink, article_edit);
    this.setWaiting(false);
    this.setState({
      ...this.state,
      article_edit: null
    });
  }
  handleSwipeChange = (tab_value) => {
    this.setState({ setValue: tab_value });
    this.props.selectProfileTab(tab_value);

  };
  handleSwipeScroll = (event) => {
    event.preventDefault();

    const { profiletab } = this.props;

    let element_id = "profile-swipeable-views-posts";
    if (profiletab === PROFILE_TAB_FEEDS) {
      element_id = "profile-swipeable-views-feeds";
    } else if (profiletab === PROFILE_TAB_SETTINGS) {
      element_id = "profile-swipeable-views-settings";
    }

    const domElement = document.getElementById(element_id).parentNode;
    const scrollTop = domElement.scrollTop;

    if (scrollTop > 50) {
      this.props.showTopNavbar(false);
    } else {
      this.props.showTopNavbar(true);
    }
  }

  renderLinks = (links, classes, theme_mode, onClickTwitter, onClickInstagram, onClickLinkedIn) => {
    if (!links) {
      return <div style={{display: "none"}}></div>;
    }
    const twitter = links.find(link => link.type === "twitter");
    const instagram = links.find(link => link.type === "instagram");
    const linkedin = links.find(link => link.type === "linkedin");

    let twitter_value = "";
    if (twitter?.value) {
      twitter_value = twitter.value;
      if (twitter_value[0] !== "@") {
        twitter_value = "@" + twitter_value;
      }
    }

    let instagram_value = "";
    if (instagram?.value) {
      instagram_value = instagram.value;
      if (instagram_value[0] !== "@") {
        instagram_value = "@" + instagram_value;
      }
    }

    let linkedin_value = "";
    if (linkedin?.value) {
      linkedin_value = linkedin.value;
      linkedin_value = get_substring(linkedin_value, "linkedin.com/");
    }

    return (
      <Grid
        container
        className={classes.linkbuttons}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {twitter !== undefined &&
          <Grid item>
            <Button
              className={classes.actionbtn}
              startIcon={
                <img
                  className={classes.actionicon}
                  alt="approve"
                  src={`/static/images/icons/${theme_mode}/twitter.png`}
                />
              }
              onClick={onClickTwitter}
            >
              {twitter_value}
            </Button>
          </Grid>
        }
        {instagram !== undefined &&
          <Grid item>
            <Button
              className={classes.actionbtn}
              startIcon={
                <img
                  className={classes.actionicon}
                  alt="dismiss"
                  src={`/static/images/icons/${theme_mode}/instagram.png`}
                />
              }
              onClick={onClickInstagram}
            >
              {instagram_value}
            </Button>
          </Grid>
        }
        {linkedin !== undefined &&
          <Grid item>
            <Button
              className={classes.actionbtn}
              startIcon={
                <img
                  className={classes.actionicon}
                  alt="dismiss"
                  src={`/static/images/icons/${theme_mode}/linkedin.png`}
                />
              }
              onClick={onClickLinkedIn}
            >
              {linkedin_value}
            </Button>
          </Grid>
        }
      </Grid>
    );
  }

  is_paid_user = () => {
    const { selected_user } = this.props;
    const paid_until_ts = Date.parse(selected_user.paid_until);
    const now_ts = Date.now();
    return paid_until_ts >= now_ts;
  };

  is_twitter_user = () => {
    const { selected_user } = this.props;
    if (selected_user.links.length === 0) {
      return false;
    }
    return selected_user.links.find((link) => link.type === "twitter") !== undefined;
  };

  handleShowPosts = () => {
    const { selected_user } = this.props;
    if (selected_user.uid.includes("bot_")) {
      const aipersonaId = selected_user.uid;
      const location = {
        pathname: `/${ROUTES.PROFILE_COMMENTS_PATH}/${aipersonaId}`,
        state: { animation: "left" },
      };
      this.props.history.push(location);
      this.props.setPostsBackRoute(`${ROUTES.USER_PREFIX}/${selected_user.username}`);
    }
    else {
      const location = {
        pathname: `/${ROUTES.USER_PREFIX}/${selected_user.username}/posts`,
        state: { animation: "left" },
      };
      this.props.history.push(location);
      this.props.setPostsBackRoute(`${ROUTES.USER_PREFIX}/${selected_user.username}`);
    }

  }

  handlefollowers = () => {
    const { selected_user } = this.props;

    const location = {
      pathname: `/${ROUTES.USER_PREFIX}/${selected_user.username}/followers`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setPostsBackRoute(`${ROUTES.USER_PREFIX}/${selected_user.username}`);
  };

  handlefollowing = () => {
    const { selected_user } = this.props;
    const location = {
      pathname: `/${ROUTES.USER_PREFIX}/${selected_user.username}/following`,
      state: { animation: "left" },

    };
    this.props.history.push(location);
    this.props.setPostsBackRoute(`${ROUTES.USER_PREFIX}/${selected_user.username}`);
  };

  checkModerator = () => {
    const { loggedIn, selected_user, selected_feed } = this.props;
    if (!loggedIn) {
      return "none";
    }
    if (selected_user.categories_moderated.length > 0) {
      return "category";
    }
    if (selected_feed && selected_user.feeds_moderated.find(item => item.feed_id === selected_feed.id) !== undefined) {
      return "feed";
    }
    return "none";
  }

  getSuspendedState = (moderator) => {
    const { loggedIn, authUser, selected_user } = this.props;
    logger.log("getSuspendedState :", moderator, selected_user);
    let suspendedState = {
      suspended1day: false,
      suspended7day: false,
      suspendedPermanent: false,
      suspendedPosts: false,
      suspendedComments: false,
      suspendedImages: false,
      suspendedVideo: false,
      suspendedFeedCreation: false,
    };

    if (loggedIn && authUser.user_suspensions.length > 0 && moderator === "category") {
      suspendedState.suspended1day = authUser.user_suspensions.find(suspension =>
        suspension.user_id === selected_user.uid &&
        suspension.type === SUSPENSION_ALL_1D &&
        suspension.enabled &&
        (Date.parse(suspension.till) >= Date.now())
      ) !== undefined;

      suspendedState.suspended7day = authUser.user_suspensions.find(suspension =>
        suspension.user_id === selected_user.uid &&
        suspension.type === SUSPENSION_ALL_7D &&
        suspension.enabled &&
        (Date.parse(suspension.till) >= Date.now())
      ) !== undefined;

      suspendedState.suspended_perm = authUser.user_suspensions.find(suspension =>
        suspension.user_id === selected_user.uid &&
        suspension.type === SUSPENSION_ALL_PERM &&
        suspension.enabled
      ) !== undefined;

      suspendedState.suspended_posts = authUser.user_suspensions.find(suspension =>
        suspension.user_id === selected_user.uid &&
        suspension.type === SUSPENSION_POSTS &&
        suspension.enabled
      ) !== undefined;

      suspendedState.suspended_comments = authUser.user_suspensions.find(suspension =>
        suspension.user_id === selected_user.uid &&
        suspension.type === SUSPENSION_COMMENTS &&
        suspension.enabled
      ) !== undefined;

      suspendedState.suspended_images = authUser.user_suspensions.find(suspension =>
        suspension.user_id === selected_user.uid &&
        suspension.type === SUSPENSION_IMAGES &&
        suspension.enabled
      ) !== undefined;

      suspendedState.suspended_video = authUser.user_suspensions.find(suspension =>
        suspension.user_id === selected_user.uid &&
        suspension.type === SUSPENSION_VIDEO &&
        suspension.enabled
      ) !== undefined;

      suspendedState.suspended_feedcreate = authUser.user_suspensions.find(suspension =>
        suspension.user_id === selected_user.uid &&
        suspension.type === SUSPENSION_FEED_CREATION &&
        suspension.enabled
      ) !== undefined;
    }

    return suspendedState;
  }

  getUserFeeds = (user) => {
    let userFeeds = user.feeds_created;
    if (user.feeds_followed.length > 0) {
      const userFollowedFeeds = user.feeds_followed.map(item => item.feed);
      userFeeds = userFeeds.concat(userFollowedFeeds);
    }
    if (user.feeds_moderated.length > 0) {
      const userModeratedFeeds = user.feeds_moderated.map(item => item.feed);
      userFeeds = userFeeds.concat(userModeratedFeeds);
    }
    userFeeds = _.uniqBy(userFeeds, function (feed) {
      return feed.id;
    });
    return userFeeds;
  }

  render() {
    const {
      classes,
      theme_mode,
      loggedIn,
      authUser,
      selected_user,
      user_posts,
      requesting,
      profiletab,
      followed_feeds
    } = this.props;
    const {
      upvotes,
      loginDlg,
      reportDlg,
      postDlg,
      article_edit,
      anchorEl,
      postcounts,
      followers,
      following
    } = this.state;

    if (!selected_user) {
      return <div style={{display: "none"}}></div>;
    }

    const ai_personas = selected_user.uid.startsWith("bot_");
    let profile_image = BLANK_USER_IMAGE;
    if (!selected_user.image) {
      profile_image = BLANK_USER_IMAGE;
    } else {
      profile_image = selected_user.image;
    }

    // SlideLists width
    let seeAllPos = 0;
    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) {
      seeAllPos = (width - MAX_ARTICLE_WIDTH) / 2;
      width = MAX_ARTICLE_WIDTH;
    }
    const slidelist_width = width - 16; // root padding

    // follow by me?
    let ifollow = false;
    if (loggedIn) {
      ifollow = authUser.users_followed.find(user => user.user_id === selected_user.uid) !== undefined;
    }

    // is moderator? -> need to be changed(is this feed's moderator)
    const moderator = this.checkModerator();

    // followed feeds

    let userFeeds = this.getUserFeeds(selected_user);

    // joined(created) date
    let join_date_str = "";
    if (selected_user.created_at) {
      const join_date = new Date(selected_user.created_at);
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      join_date_str = `Joined ${join_date.toLocaleDateString("en-US", options)}`;
    }

    // followed users
    let following_users = [];
    for (let followed of selected_user.users_followed) {
      following_users.push({
        title: followed.user.name,
        image: followed.user.image,
        username: followed.user.username,
      });
    }

    // blocked state
    let blocked = false;
    let muted = false;
    if (loggedIn) {
      blocked = authUser.user_blockmutes.find(blockmute =>
        blockmute.target_user_id === selected_user.uid &&
        blockmute.type === BLOCK_USER &&
        blockmute.enabled
      ) !== undefined;

      muted = authUser.user_blockmutes.find(blockmute =>
        blockmute.target_user_id === selected_user.uid &&
        blockmute.type === MUTE_USER &&
        blockmute.enabled
      ) !== undefined;
    }

    // suspended state
    let suspendedState = this.getSuspendedState(moderator);
    logger.log("suspendedState :", suspendedState);

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <UserAppBar
            theme={theme_mode}
            onNavBack={this.handleNavBack}
            onModerate={this.showModerateMenu}
          />
        </div>
        <div className={classes.profileContainer}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item>
              <img
                className={classes.userimage}
                alt="profile"
                src={profile_image}
              />
            </Grid>
            <Grid item>
              {loggedIn && ifollow &&
                <Button
                  className={classes.followingbutton}
                  onClick={this.handleClickUnfollow}
                >
                  Following
                </Button>
              }
              {loggedIn && !ifollow &&
                <Button
                  className={classes.followbutton}
                  onClick={this.handleClickFollow}
                >
                  Follow
                </Button>
              }
            </Grid>
          </Grid>
          <div className={classes.detail}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item>
                <Typography className={classes.name}>
                  {selected_user.name || ""}
                </Typography>
              </Grid>
              {selected_user.paid &&
                <Grid item>
                  <img
                    className={classes.statusroboricon}
                    alt={selected_user.username}
                    src={`/static/images/icons/${theme_mode}/shield.png`}
                  />
                </Grid>
              }
              {ai_personas &&
                <img
                  className={classes.statusroboricon}
                  alt="robort"
                  src={`/static/images/icons/${theme_mode}/robot.png`}
                />
              }
              {(moderator === "category" || moderator === "feed") &&
                <Grid item>
                  <img
                    className={classes.statusicon}
                    alt="moderator"
                    src={`/static/images/icons/${theme_mode}/moderator.png`}
                  />
                </Grid>
              }
              {upvotes > 0 &&
                <Grid item>
                  <img
                    className={classes.statusicon}
                    alt="upvotes"
                    src={`/static/images/icons/thumbsup.png`}
                  /><span className={classes.upvotes}>{upvotes}</span>
                </Grid>
              }
            </Grid>
            <Typography className={classes.username}>
              @{selected_user.username || ""}
            </Typography>
            <Typography className={classes.description}>
              {selected_user.biography || ""}
            </Typography>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <Grid
              container
              className={classes.stats}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h5" className={classes.statText}
                  onClick={this.handlefollowers}
                >
                  {followers.length} Followers
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" className={classes.statText}
                  onClick={this.handlefollowing}
                >
                  {following.length} Following
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" className={classes.statText}
                  onClick={this.handleShowPosts}
                >
                  {postcounts !== null ? postcounts : 0} Posts
                </Typography>
              </Grid>
            </Grid>
          </div>

          {this.renderLinks(selected_user.links, classes, theme_mode, this.handleClickTwitter, this.handleClickInstagram, this.handleClickLinkedIn)}
        </div>
        <div className={classes.profiletabs}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <ProfileTabs
                theme_mode={theme_mode}
                onChangeTab={this.handleSwipeChange}
                const user={true}
              />
            </Grid>
          </Grid>
          <div style={{ maxHeight: "calc(70vh)", overflowY: "auto" }} onScroll={this.handleDivScroll}>
            <SwipeableViews
              index={profiletab}
              onChangeIndex={this.handleSwipeChange}
              onScroll={this.handleSwipeScroll}
              enableMouseEvents
            >
              <div id="profile-swipeable-views-posts">
                {profiletab === PROFILE_TAB_POSTS && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      {user_posts.length > 0 && (
                        <ArticleList
                          userProfile={true}
                          articles={user_posts}
                          pins={[]}
                          movetops={[]}
                          onNeedMore={this.handleNeedMore}
                          onSelectArticle={this.handleSelectArticle}
                          onSelectGroupArticle={this.handleSelectGroupArticle}
                          onLogin={this.handleLogin}
                          onReport={this.handleReportArticle}
                          onEdit={this.handleEditArticle}
                          onDelete={this.handleDeleteArticle}
                          onSave={this.handleSaveArticle}
                          onDeleteSaved={this.handleDeleteSavedArticle}
                          onClickSource={this.handleClickSource}
                          onClickFeed={this.handleClickFeed}
                          onClickUpvote={this.handleClickUpvote}
                          onClickComment={this.handleClickComment}
                          onClickRepost={this.handleClickRepost}
                        />
                      )}
                      {postcounts === 0 && (
                        <Grid item>
                          <div className={classes.warningcontainer}>
                            <div className={classes.warningimg}>
                              <img
                                className={classes.warningicon}
                                alt="warning"
                                src={`/static/images/icons/${theme_mode}/warning.png`}
                              />
                            </div>
                            <Typography className={classes.warningtitle}>
                              No posts in the profile yet.
                            </Typography>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </div>
              <div
                style={{
                  marginLeft: "10px"
                }}
                id="profile-swipeable-views-feeds"
              >
                {profiletab === PROFILE_TAB_FEEDS &&
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                  >
                    {selected_user.feeds_followed.map((item, index) => (
                      <Grid item key={`feed-${index}`}>
                        <FeedTabItem
                          width={width === undefined ? null : width}
                          feed={item}
                          index={index}
                          following={authUser.feeds_followed.some(feed => feed.feed_id === item.feed_id)}
                          loggedIn={loggedIn}
                          theme_mode={theme_mode}
                          onClickFeed={this.handleClickFeed}
                          onFollowed={this.handleUnFollowFeed}
                          unFollowed={this.handleFollowFeed}
                        />
                      </Grid>
                    ))}
                  </Grid>
                }
              </div>
            </SwipeableViews>
            <WaitingSpinner open={requesting} />

          </div>
          <Typography className={classes.joinstring}>
            {join_date_str}
          </Typography>
          {/* <div className={classes.slidecontainer}>
            <div className={classes.slidetitlebar}>
              <Typography className={classes.slidetitle}>My Feeds</Typography>
            </div>
            <div className={classes.slide}>
              <SlideIconLists
                width={slidelist_width}
                items={userFeeds}
                onItemSelected={this.handleSelectFeeds}
              />
            </div>
          </div>

          <div className={classes.slidecontainer}>
            <div className={classes.slidetitlebar}>
              <Typography className={classes.slidetitle}>Following</Typography>
            </div>
            <div className={classes.slide}>
              <SlideIconLists
                width={slidelist_width}
                items={following_users}
                onItemSelected={this.handleSelectUser}
              />
            </div>
          </div>

          <div className={classes.slidecontainer}>
            <div className={classes.slidetitlebar}>
              <Typography className={classes.slidetitle}>My Posts</Typography>
              <Button
                className={classes.selectfollowing}
                style={{ right: seeAllPos }}
                clickable={"false"}
                endIcon={<ArrowForwardIosIcon fontSize="small" style={{ width: 16, marginBottom: 2 }} />}
                onClick={this.handleShowPosts}
              />
            </div>
            {user_posts.length > 0 && (
              <div className={classes.articles}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                  <ArticleList
                      articles={user_posts}
                      pins={[]}
                      movetops={[]}
                      onNeedMore={this.handleNeedMore}
                      onSelectArticle={this.handleSelectArticle}
                      onSelectGroupArticle={this.handleSelectGroupArticle}
                      onLogin={this.handleLogin}
                      onReport={this.handleReportArticle}
                      onEdit={this.handleEditArticle}
                      onDelete={this.handleDeleteArticle}
                      onSave={this.handleSaveArticle}
                      onDeleteSaved={this.handleDeleteSavedArticle}
                      onClickSource={this.handleClickSource}
                      onClickFeed={this.handleClickFeed}
                      onClickUpvote={this.handleClickUpvote}
                      onClickComment={this.handleClickComment}
                      onClickRepost={this.handleClickRepost}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </div> */}

        </div>
        {postDlg &&
          <DlgPostEdit
            open={postDlg}
            theme={theme_mode}
            article={article_edit}
            onSubmit={this.handleUpdatePost}
            onClose={this.closePostEditDlg}
          />
        }

        <Menu
          id="user-moderate-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.closeModerateMenu}
        >
          {moderator === "category" ? (
            <PopMenuUserMod
              theme={theme_mode}
              suspendedCount={2}
              suspendedState={suspendedState}
              onSuspend1day={e => this.handleSuspend1day(true)}
              onSuspend7day={e => this.handleSuspend7day(true)}
              onSuspendPermanent={e => this.handleSuspendPermanent(true)}
              onSuspendPosts={e => this.handleSuspendPosts(true)}
              onSuspendComments={e => this.handleSuspendComments(true)}
              onSuspendImages={e => this.handleSuspendImages(true)}
              onSuspendVideo={e => this.handleSuspendVideo(true)}
              onSuspendFeedCreation={e => this.handleSuspendFeedCreation(true)}
              onEndSuspend1day={e => this.handleSuspend1day(false)}
              onEndSuspend7day={e => this.handleSuspend7day(false)}
              onEndSuspendPermanent={e => this.handleSuspendPermanent(false)}
              onAllowPosts={e => this.handleSuspendPosts(false)}
              onAllowComments={e => this.handleSuspendComments(false)}
              onAllowImages={e => this.handleSuspendImages(false)}
              onAllowVideo={e => this.handleSuspendVideo(false)}
              onAllowFeedCreation={e => this.handleSuspendFeedCreation(false)}
              onActivityLog={this.handleActivityLog}
            />
          ) : (
            <PopMenuUser
              theme={theme_mode}
              blocked={blocked}
              muted={muted}
              onBlock={e => this.handleBlockUser(true)}
              onEndBlock={e => this.handleBlockUser(false)}
              onMute={e => this.handleMuteUser(true)}
              onEndMute={e => this.handleMuteUser(false)}
              onReport={e => this.showReportDlg(true)}
            />
          )}
        </Menu>

        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgReport
          open={reportDlg}
          title={"Report User"}
          theme={theme_mode}
          onReport={this.handleReportUser}
          onCancel={e => this.showReportDlg(false)}
        />
        {/* <WaitingSpinner open={requesting} /> */}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  initialized: state.dataState.initialized,
  selected_user: state.dataState.selected_user,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  user_posts: state.dataState.user_posts,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
  profiletab: state.uiState.profiletab,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(User);
