import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ToastError, ToastSocketSuccess } from "utility/toast";
import {
    THEME_MODE_LIGHT,
    MIN_CARD_WIDTH,
  } from "constants/types";
import{generateSummary}from "utility/ravenapi"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Typography,
  InputBase,
  Button,
} from "@material-ui/core";
import { GraphqlService } from "services";
import { getAuthToken } from "dataapis";
import { extractPodcastId, extractVideoId, get_hash } from "utility/utils";
import * as ROUTES from "constants/routes";
import DlgTutorial from "../DlgTutorial";
const styles = (theme) => ({
  caption: {
    fontSize: 16,
    fontWeight: 500,
  },
  griditem: {
    backgroundColor: theme.palette.background.card,
  },
  actionbutton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionbutton_disabled: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
    opacity: 0.38,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  text: {
    flex: 1,
    color: theme.palette.text.primary,
    margin: 0,
    padding: 5,
    fontSize: 14,
    fontHeight: 18,
    backgroundColor: theme.palette.background.dark,
    "& input": {
      fontSize: 14,
      fontHeight: 18,
      backgroundColor: theme.palette.background.dark,
    },
  },

  applybtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "4px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  applybtn_disabled: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "4px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
  inputContainer: {
    alignItems: 'center',
 },
 copyButton: {
    marginLeft: theme.spacing(1),
 },
});

class DlgWeblink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      errorMessage: "",
      copiedLink:"",
      tutorial:false,
    };
    this.handlePaste = this.handlePaste.bind(this);
  }

  setError = (message) => {
    ToastError(message);
  };

  componentDidMount = () => {
    this.setState({
      url: "",
      errorMessage: "",
    });
  }

  handleChange = (event) => {
    if (event.target.value.length > 5000) {
      return;
    }
    const { copiedLink, url } = this.state;
    const newValue = event.target.value;
    if (copiedLink && !url.trim()) {
      this.setState({
        url: copiedLink,
        copiedLink: "", 
      });
    } else {
      this.setState({
        url: newValue,
        copiedLink: "",
      });
    }
  };
  

  handleSubmit = async () => {
    let { url, errorMessage, type, params } = this.state;

    const { authUser, socketId, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    gqlservice.set_auth_jwt(token);

    if (!token) {
      this.handleLogin();
      return;
    }

    // if (typeof this.props.onSubmit === "function") {
    //   this.props.onSubmit(url);
    // } else {
    //   console.error("this.props.onSubmit is not a function.");
    // }

    if (url.trim()) {
      const urlRegex = /^(http|https):\/\//;
      const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;

      if (!urlRegex.test(url) || !domainRegex.test(url)) {
        errorMessage = "Invalid URL. Please enter a valid URL.";
      } else if (authUser.credits <= 0) {
        errorMessage = "Insufficient credits.";
      } else {
        errorMessage = "";
        // apply_enabled = true;
        const youtubeRegex =
          /^(https?:\/\/)?(www\.)?(youtube\.com\/|youtu\.be\/)/;
        let videoId = null
        if (youtubeRegex.test(url)) {
          params = {
            userId: authUser.uid,
            url: url,
            type: "youtube",
            socketId: socketId,
            feedId: selected_feed ? selected_feed.id : null,
          };
          videoId = extractVideoId(url);
        } else {
          params = {
            userId: authUser.uid,
            url: url,
            type: "podcast",
            socketId: socketId,
            feedId: selected_feed ? selected_feed.id : null,
          };
          videoId = extractPodcastId(url);
        }

        let nid = "";
        nid = get_hash(authUser.uid + url);
        let sourceId = `${authUser.uid}-selfpost`;
        if (selected_feed) {
          sourceId = `${selected_feed.id}-userpost`;
        }
        const articleDetails = await gqlservice.articles_with_summary(
          nid,
          url,
          videoId,
          authUser.uid
          );
        if (articleDetails.data.articles.length > 0) {
          if (selected_feed) {
            const isUserpostExist = articleDetails.data.articles.some(
              (article) => article.source_id === `${selected_feed.id}-userpost`
            );
            if (!isUserpostExist) {
              const summary = await generateSummary(params, token);
              this.setState({
                url: "",
                errorMessage,
              });
              this.props.onClose();
              return;
            }
          }
          const APP_URL = process.env.REACT_APP_RAVEN_APP;
          const articleId = articleDetails.data.articles[0].nid;
          let path = `${APP_URL}/${ROUTES.ARTICLE_PREFIX}/${articleId}`;
          const htmlMessage = `<p>Summary already exists for this link! <a href="${path}" target="_blank" rel="noopener noreferrer">Click here to view</a></p>`;
          ToastSocketSuccess(htmlMessage);
          this.setState({
            url: "",
            errorMessage,
          });
          this.props.onClose();
          return;
        }
        const summary = await generateSummary(params, token);
        this.props.onClose();
      }
    } else {
      console.log("URL is empty");
    }

    this.setState({
      url: "",
      errorMessage,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      url: "",
      errorMessage: "",
      copiedLink:""
    });
    this.props.onClose();
  };
  handlePaste = async (event) => {
    try {
      const text = await navigator.clipboard.readText();
      this.setState({ url: text });
    } catch (err) {
      console.error('Failed to read clipboard contents:', err);
    }
  };
  showADlgWeb=()=>{
    
    this.setState({
      ...this.state,
     tutorial: true
    });

  }
  closePostDlg = () => {
    this.setState({
      ...this.state,
      tutorial:false
    });
  };
  render() {
    
    const { classes, open, theme_mode, authUser } = this.props;
    const { url, errorMessage,tutorial } = this.state;

    const paper_style = {
      width: MIN_CARD_WIDTH,
      borderRadius: 10,
      borderStyle: "solid",
      borderColor: theme_mode === THEME_MODE_LIGHT ? "black" : "white",
      borderWidth: "1px",
      backgroundColor: theme_mode === THEME_MODE_LIGHT ? "white" : "black",
    };

    let apply_enabled = false;

    if (url.trim()) {
      apply_enabled = true;
    }

    return (
      <Dialog
        open={open}
        aria-labelledby="summary-dialog"
        aria-describedby="summary-dialog"
        PaperProps={{ style: paper_style }}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={`/static/images/summary.png`}
                  className={classes.branchimg}
                  style={{
                    width: 40,
                    height: 40,
                    padding: 6,
                    background: theme_mode === "light" ? "#10a37f" : "#10a37f",
                    marginRight: 10, // Added margin for spacing
                    borderRadius: 20,
                  }}
                />
                <Typography className={classes.caption} align="center">
                  Summarize
                </Typography>
              </div>
              <Typography
                align="center"
                style={{ marginTop: 10, fontSize: 16 }}
              >
                <span style={{ marginLeft: 5 }}>Paste a YouTube or Apple </span>
                <br />
                Podcast link here
              </Typography>
            </div>
          </div>
          <IconButton
            onClick={this.handleClose}
            className={classes.actionbutton}
          >
            <img
              className={classes.actionimg}
              alt="apply"
              src="/static/images/delete.png"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ padding: "8px 16px" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <div className={classes.inputContainer}>
                <div style={{ alignItems: "center" }}>
                  <InputBase
                    id="summary-url"
                    className={classes.text}
                    fullWidth
                    name="url"
                    value={this.state.copiedLink || this.state.url}
                    autoFocus={true}
                    onChange={this.handleChange}
                    inputProps={{ "aria-label": "paste link" }}
                    style={{
                      borderRadius: 40,
                      flex: " 1  1 auto",
                      minWidth: "80px",
                    }}
                    endAdornment={
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.copyButton}
                        style={{
                          borderRadius: 20,
                          fontSize: "0.6rem",
                          minWidth: "110px",
                          backgroundColor: "#9966FF",
                          padding: "8px 15px",
                        }}
                        startIcon={
                          <img
                            src={`/static/images/icons/${theme_mode}/copy.png`}
                            alt="Icon"
                            style={{ height: 15, width: 15 }}
                          />
                        }
                        onClick={this.handlePaste}
                      >
                        Paste Link
                      </Button>
                    }
                  />
                </div>
                <div>
                  {errorMessage && (
                    <Typography
                      color="error"
                      variant="body2"
                      style={{ marginTop: 8 }}
                    >
                      <span>{errorMessage}</span>
                    </Typography>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Typography
                  className={classes.caption}
                  align="center"
                  style={{ cursor: "pointer", opacity: 0.5, marginTop: -10 }}
                  onClick={this.showADlgWeb}
                >
                  How to get a link &gt;
                </Typography>
              </Grid>
            </Grid>
            <DlgTutorial open={tutorial} onClose={this.closePostDlg} />
          </Grid>
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          {apply_enabled && (
            <Button
              className={classes.applybtn}
              style={{ marginLeft: "2%", width: "96%", marginTop: -10 }}
              onClick={this.handleSubmit}
            >
              Generate
            </Button>
          )}
          {!apply_enabled && (
            <Button
              className={classes.applybtn_disabled}
              style={{ marginLeft: "2%", width: "96%", marginTop: -10 }}
              onClick={this.handleSubmit}
            >
              Generate
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

DlgWeblink.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selected_feed: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  socketId: state.sessionState.socketId,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DlgWeblink);
