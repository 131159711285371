import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { List, ListItem } from "@material-ui/core";
import { ThreadCard } from "components";
import _ from "lodash";

const styles = theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  listitem: {
    padding: 0
  }
});

class ThreadList extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.scrollFn = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollFn);
    window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFn);
  }

  handleClick = thread => {
    this.props.saveScrollPos(window.scrollX, window.scrollY);
    this.props.onSelectThread(thread);
  };

  listenToScroll = (event) => {
    event.preventDefault();

    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    if (height === 0) {
      return;
    }

    const scrolled = winScroll * 1.0 / height;
    if (scrolled === 0.0) {
    } else if (scrolled === 1.0 || scrolled >= 0.99) {
      this.props.onNeedMore();
    }
  };

  render() {
    const { 
      classes,
      threads, 
      moderator,
      onLogin,
      onClickThread,
      onClickSource,
      onClickFeed,
    } = this.props;

    if (threads.length === 0) {
      return <div style={{display: "none"}}></div>;
    }

    const threads2show =  _.uniqBy(threads, function (thread) {
      return thread.id;
    });
    
    return (
      <div className={classes.root}>
        <List component="thread-list" aria-label="thread list">
          {threads2show.map(thread => (
            <ListItem className={classes.listitem} key={thread.id}>
              <ThreadCard
                thread={thread}
                moderator={moderator}
                onLogin={onLogin}
                onClickThread={onClickThread}
                onClickFeed={onClickFeed}
                onClickSource={onClickSource}
              />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

ThreadList.propTypes = {
  classes: PropTypes.object,
  threads: PropTypes.array,
  moderator: PropTypes.bool,
  onNeedMore: PropTypes.func,
  onLogin: PropTypes.func,
  onClickThread: PropTypes.func,
  onClickFeed: PropTypes.func,
  onClickSource: PropTypes.func,
};


const mapStateToProps = state => ({
  scrollPos: state.uiState.scrollPos,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ThreadList);
