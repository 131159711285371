export const QUERY_AI_SOURCE_ARTICLES_BY_SOURCEIDS = `
query GetAiSourceArticles (
  $sourceIds: [String!]
  $pagesize: Int!,
  $offset: Int!
) {
    ai_source_articles(
    where: { 
      source_id: {_in: $sourceIds}
    },
    order_by: {id:desc },
    limit: $pagesize, 
    offset: $offset
  ) { 
    id
    source_id
    article_id
    source{
      feed_sources{
        feed {
          ai_moderation
          id
          feed_sources{
            source{
              name
              description
              branch
            }
          }
        }
      }
    }
    article{
      nid
      source_id
      title
      author
      summary
      image
      text
      html
      summarized_text
      tr_title
      tr_summary
      tr_text
      translated
      branch
      country
      crawled_at
      media_url
      url
      author_image
      published
      data
      extra_data
      param1
      param2
      param3
      param4
      param5
      txt_param1
      txt_param2
      image_thumb
      author_image_thumb
      link_preview
      discussion_twitter
      discussion_reddit
      upvotes
      ai_summary
      ai_transcription
      political_sentiments
      threads {
        id
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      source{
        feed_sources{
          feed {
            ai_moderation
            id
            feed_sources{
              source{
                name
                description
                branch
              }
            }
          }
        }
      }
      home_cluster_members {
        id
        parent_id
        article_id
        article{
          nid
          source_id
          title
          author
          summary
          image
          text
          html
          summarized_text
          tr_title
          tr_summary
          tr_text
          translated
          branch
          country
          crawled_at
          media_url
          url
          author_image
          published
          data
          extra_data
          param1
          param2
          param3
          param4
          param5
          txt_param1
          txt_param2
          image_thumb
          author_image_thumb
          link_preview
          discussion_twitter
          discussion_reddit
          political_sentiments
          upvotes
          threads {
            id
            comments_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
}`;