import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { 
  Grid, 
  Typography, 
  Avatar, 
  Button, 
  IconButton, 
  Menu 
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  THREAD_TYPE_FEED, 
  BANNED_TYPE_1D, 
  BANNED_TYPE_7D, 
  BANNED_TYPE_PERM, 
  MAX_ARTICLE_WIDTH,
} from "constants/types";
import { 
  banComments,
  closeThread,
  deleteAllComments,
  deleteThread,
  isBanned,
  isBannedComments,
  preapproveUser,
} from "dataapis";
import { get_elapsed_time } from "utility/utils";
import { 
  ToastInfo,
  ToastError 
} from "utility/toast";
import { 
  PopMenuThreadMod, 
  DlgShareComment, 
  // DlgConfirm,
} from "components";
import * as ROUTES from "constants/routes";


const styles = (theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
  },
  gridorder: {
    width: 24,
  },
  order: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  title: {
    fontSize: "16px",
    fontWeight: 200,
    lineHeight: "18px",
    color: theme.palette.text.primary,
  },
  image: {
    width: 24,
    height: 24,
  },
  label: {
    fontSize: "14px",
    fontWeight: 100,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
  name: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "14px",
    color: theme.palette.text.primary,
  },
  gridupvotes: {
    width: 28,
  },
  action: {
    padding: 0,
    minWidth: 28,
    width: 28,
  },
  upvotesbtn: {
    position: "absolute",
    top: 0,
    right: 8,
    minWidth: 28,
    width: 28,
    minHight: 56,
    height: 56
  },
  upvotemark: {
    position: "absolute",
    top: 4,
    right: 0,
    height: 24,
    width: 24,
    color: theme.palette.text.secondary,
  },
  upvotes: {
    position: "absolute",
    top: 28,
    right: 8,
    fontSize: 12,
    lineHeight: 0,
  },
  avatar: {
    width: 32,
    height: 24,
  },
  expand: {
    position: "absolute",
    bottom: 8,
    right: 8,
    padding: 0,
    minWidth: 28,
    width: 28,
  },
});


class ThreadItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      // deleteDlg: false,
      shareDlg: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleUpvote = this.handleUpvote.bind(this);

    this.handleExpand = this.handleExpand.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);

    // this.showDeleteDlg = this.showDeleteDlg.bind(this);

    this.handleDeleteThread = this.handleDeleteThread.bind(this);
    this.handleDeleteBan1d = this.handleDeleteBan1d.bind(this);
    this.handleDeleteBan7d = this.handleDeleteBan7d.bind(this);
    this.handleDeleteBanPerm = this.handleDeleteBanPerm.bind(this);
    this.handleCloseComments = this.handleCloseComments.bind(this);
    this.handleDeleteComments = this.handleDeleteComments.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleApproveUser = this.handleApproveUser.bind(this);

    // this.handleCloseDelete = this.handleCloseDelete.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);
  }

  setError = (message) => {
    ToastError(message);
  }

  setAlert = (message) => {
    ToastInfo(message);
  }

  handleClick = () => {
    this.props.onClick(this.props.thread);
  }

  handleUpvote = () => {
    this.props.onUpvote(this.props.thread);
  }

  handleExpand = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  }

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null
    });
  }

  // showDeleteDlg = () => {
  //   this.setState({
  //     ...this.state,
  //     deleteDlg: false
  //   });
  // }

  _getAuthToken = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    let token = authUser.token;
    if (Date.now() >= authUser.expiredTS) {
      const result = await this.props.firebase.refreshToken();
      if (result.error) {
        this.setError(result.msg);
        token = null;
      } else {
        token = result.token;
      }
    }
    return token;
  };

  handleDeleteThread = async () => {
    this.handleCloseMenu();

    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    const { thread } = this.props;
    await deleteThread(thread);
  }

  handleDeleteBan1d = async () => {
    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    const { thread } = this.props;
    await deleteThread(thread);
    await banComments(thread.poster.uid, BANNED_TYPE_1D);
    this.setAlert(`User ${thread.poster.username} banned for a day!`);
  }

  handleDeleteBan7d = async () => {
    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    const { thread } = this.props;
    await deleteThread(thread);
    await banComments(thread.poster.uid, BANNED_TYPE_7D);
    this.setAlert(`User ${thread.poster.username} banned for 7 days!`);
  }

  handleDeleteBanPerm = async () => {
    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }
    
    const { thread } = this.props;
    await deleteThread(thread);
    await banComments(thread.poster.uid, BANNED_TYPE_PERM);
    this.setAlert(`User ${thread.poster.username} banned permanently!`);
  }

  handleCloseComments = async () => {
    this.setState({
      ...this.state,
      anchorEl: null
    });

    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    const { thread } = this.props;
    await closeThread(thread);
  }

  handleDeleteComments = async () => {
    if (isBannedComments()) {
      ToastError("You've suspended for comment operations.");
      return;
    }

    await this.handleCloseComments();

    const { thread } = this.props;
    await deleteAllComments(thread);
  }

  handleShare = () => {
    this.setState({
      ...this.state,
      shareDlg: true,
      anchorEl: null
    });
  }

  handleApproveUser = async () => {
    this.handleCloseMenu();
    
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { thread, selected_feed } = this.props;
    await preapproveUser(thread.poster.uid, selected_feed);
    this.setAlert(`User ${thread.poster.username} preapproved!`);
  }

  handleCloseShare = () => {
    this.setState({
      ...this.state,
      shareDlg: false
    });
  }

  _getShareInfo = () => {
    const { thread } = this.props;

    // share info
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.THREAD_PREFIX}/${thread.id}`;

    let shareInfo = {
      title: "Raven Comment: " + thread.title,
      description: thread.text,
      image: '',
      hashtag: '',
      url: shareUrl
    };

    return shareInfo;
  }


  render() {
    const { 
      classes, 
      theme_mode, 
      thread, 
      index, 
      width, 
      moderate 
    } = this.props;
    const { 
      anchorEl, 
      // deleteDlg, 
      shareDlg 
    } = this.state;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    let fromWidth = 130;
    if (width > MAX_ARTICLE_WIDTH) {
      fromWidth = (MAX_ARTICLE_WIDTH) / 2 - 48;
    } else {
      fromWidth = (width) / 2 - 48;
    }
    

    let image = "";
    let posted_by = "";
    let comment_count = 0;
    if (thread.type === THREAD_TYPE_FEED) {
      image = thread.poster.image;
      posted_by = thread.poster.username;
      comment_count = thread.comments_aggregate.aggregate.count;
    } else {
      image = thread.article.source.image;
      posted_by = thread.article.source.name;
      comment_count = thread.comments_aggregate.aggregate.count;
    }
    const posted_at = "Posted " + get_elapsed_time(thread.posted_at);
    const comment_string = `${comment_count} Comments`;

    // share info
    const shareInfo = this._getShareInfo();

    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item className={classes.gridorder}>
            <Typography className={classes.order}>
              {`${index}. `}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item xs={11} onClick={this.handleClick}>
                    <Typography className={classes.title}>
                      {thread.title}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.action}>
                    <Button className={classes.upvotesbtn} onClick={this.handleUpvote}>
                      <ArrowDropUpIcon className={classes.upvotemark} />
                      <Typography className={classes.upvotes}>
                        {thread.upvotes === null ? "0": thread.upvotes}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item className={classes.avatar} onClick={this.handleClick}>
                    <Avatar className={classes.image} src={image} />
                  </Grid>
                  <Grid item style={{width: fromWidth}} onClick={this.handleClick}>
                    <Typography className={classes.label}>{"From"}</Typography>
                    <Typography className={classes.name}>{posted_by}</Typography>
                  </Grid>
                  <Grid item onClick={this.handleClick}>
                    <Typography className={classes.label}>{posted_at}</Typography>
                    <Typography className={classes.name}>{comment_string}</Typography>
                  </Grid>
                  {moderate && (
                    <Grid item className={classes.action}>
                      <IconButton className={classes.expand} onClick={this.handleExpand}>
                        <ExpandMoreIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          id="thread-menu"
          // anchorEl={anchorEl}
          open={anchorEl !== null}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.handleCloseMenu}
        >
          <PopMenuThreadMod
            theme={theme_mode}
            type={thread.type}
            onDelete={this.handleDeleteThread}
            onBan1d={this.handleDeleteBan1d}
            onBan7d={this.handleDeleteBan7d}
            onBanPerm={this.handleDeleteBanPerm}
            onCloseComments={this.handleCloseComments}
            onDeleteComments={this.handleDeleteComments}
            onShare={this.handleShare}
            onApproveUser={this.handleApproveUser}
          />
        </Menu>
        {/* <DlgConfirm
          open={deleteDlg}
          title={"Delete Thread"}
          content={"Are you sure you want to delete this thread?"}
          onOK={this.handleDelete}
          onCancel={e => this.showDeleteDlg(false)}
        /> */}
        <DlgShareComment
          open={shareDlg}
          comment={thread}
          shareInfo={shareInfo}
          onClose={this.handleCloseShare}
        />
      </div>
    );
  }
}

ThreadItem.propTypes = {
  classes: PropTypes.object,
  thread: PropTypes.object,
  index: PropTypes.number,
  width: PropTypes.number,
  moderate: PropTypes.bool,
  onClick: PropTypes.func,
  onUpvote: PropTypes.func,
  onLogin: PropTypes.func
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ThreadItem);

