import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { setError } from "./base";
import { 
  GRAPHQL_ERROR,
  BANNED_TYPE_1D, 
  BANNED_TYPE_7D, 
  BANNED_TYPE_PERM, 
} from "constants/types";
import {
  SUSPENSION_ALL_1D,
  SUSPENSION_ALL_7D,
  SUSPENSION_ALL_PERM,
  SUSPENSION_POSTS,
  SUSPENSION_COMMENTS,
  SUSPENSION_IMAGES,
  SUSPENSION_VIDEO,
  SUSPENSION_FEED_CREATION,
} from "constants/user";
import { NOTIFICATION_FEED_INVITE } from "constants/notification";
import { 
  ACTIVITY_TYPE_USER,
  ACTIVITY_SUSPEND_1D,
  ACTIVITY_UNSUSPEND_1D,
  ACTIVITY_SUSPEND_7D,
  ACTIVITY_UNSUSPEND_7D,
  ACTIVITY_SUSPEND_PERM,
  ACTIVITY_UNSUSPEND_PERM,
  ACTIVITY_SUSPEND_POSTS,
  ACTIVITY_UNSUSPEND_POSTS,
  ACTIVITY_SUSPEND_COMMENTS,
  ACTIVITY_UNSUSPEND_COMMENTS,
  ACTIVITY_SUSPEND_IMAGES,
  ACTIVITY_UNSUSPEND_IMAGES,
  ACTIVITY_SUSPEND_VIDEO,
  ACTIVITY_UNSUSPEND_VIDEO,
  ACTIVITY_SUSPEND_FEEDCREATE,
  ACTIVITY_UNSUSPEND_FEEDCREATE,
} from "constants/activity";

const hasPaid = () => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return false;
  }
  if (!authUser.paid || !authUser.paid_until) {
    return false;
  } else {
    const paid_until_ts = Date.parse(authUser.paid_until);
    const now_ts = Date.now();
    return paid_until_ts >= now_ts;
  }
};

const hasTwitter = () => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return false;
  }

  const links = authUser.links;
  if (links.length === 0) {
    return false;
  }

  return links.find((link) => link.type === "twitter") !== undefined;
};

const getSuspendedState = () => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;

  let suspendedState = {
    suspended1day: false,
    suspended7day: false,
    suspendedPermanent: false,
    suspendedPosts: false,
    suspendedComments: false,
    suspendedImages: false,
    suspendedVideo: false,
    suspendedFeedCreation: false,
  };

  if (loggedIn && authUser.suspensions.length > 0) {
    suspendedState.suspended1day = authUser.suspensions.find(suspension => 
      suspension.type === SUSPENSION_ALL_1D &&
      suspension.enabled &&
      (Date.parse(suspension.till) >= Date.now())
    ) !== undefined;

    suspendedState.suspended7day = authUser.suspensions.find(suspension => 
      suspension.type === SUSPENSION_ALL_7D &&
      suspension.enabled && 
      (Date.parse(suspension.till) >= Date.now())
    ) !== undefined;

    suspendedState.suspended_perm = authUser.suspensions.find(suspension => 
      suspension.type === SUSPENSION_ALL_PERM &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_posts = authUser.suspensions.find(suspension => 
      suspension.type === SUSPENSION_POSTS &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_comments = authUser.suspensions.find(suspension => 
      suspension.type === SUSPENSION_COMMENTS &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_images = authUser.suspensions.find(suspension => 
      suspension.type === SUSPENSION_IMAGES &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_video = authUser.suspensions.find(suspension => 
      suspension.type === SUSPENSION_VIDEO &&
      suspension.enabled
    ) !== undefined;

    suspendedState.suspended_feedcreate = authUser.suspensions.find(suspension => 
      suspension.type === SUSPENSION_FEED_CREATION &&
      suspension.enabled
    ) !== undefined;
  }

  return suspendedState;
};

const suspendUser = async (user_id, suspend_type, enabled) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token, true);

  const now = new Date();
  const currentTime = now.toISOString();
  let days = 0;
  if (suspend_type === SUSPENSION_ALL_1D) {
    days = 1;
  } else if (suspend_type === SUSPENSION_ALL_7D) {
    days = 7;
  } else {
    days = 36500;
  }
  const tillTime = new Date(now + 86400*1000*days).toISOString();

  const suspensionInfo = {
    user_id: user_id,
    type: suspend_type,
    from: currentTime,
    till: tillTime,
    suspended_by: authUser.uid,
    enabled: enabled,
    suspended_at: currentTime,
  };

  let result = await gqlservice.insert_user_suspension(suspensionInfo);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (result.data.insert_user_suspensions_one?.id < 0) {
    setError("Can't suspend user");
    return;
  }
   
  result = await gqlservice.user_by_id(authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const users = result?.data?.users;
  if (users.length > 0) {
    store.dispatch({
      type: ActionTypes.SET_AUTH_USER,
      authUser: users[0]
    });
  }

  let activity_detail = -1;
  if (suspend_type === SUSPENSION_ALL_1D) {
    activity_detail = enabled ? ACTIVITY_SUSPEND_1D : ACTIVITY_UNSUSPEND_1D;
  } else if (suspend_type === SUSPENSION_ALL_7D) {
    activity_detail = enabled ? ACTIVITY_SUSPEND_7D : ACTIVITY_UNSUSPEND_7D;
  } else if (suspend_type === SUSPENSION_ALL_PERM) {
    activity_detail = enabled ? ACTIVITY_SUSPEND_PERM : ACTIVITY_UNSUSPEND_PERM;
  } else if (suspend_type === SUSPENSION_POSTS) {
    activity_detail = enabled ? ACTIVITY_SUSPEND_POSTS : ACTIVITY_UNSUSPEND_POSTS;
  } else if (suspend_type === SUSPENSION_COMMENTS) {
    activity_detail = enabled ? ACTIVITY_SUSPEND_COMMENTS : ACTIVITY_UNSUSPEND_COMMENTS;
  } else if (suspend_type === SUSPENSION_IMAGES) {
    activity_detail = enabled ? ACTIVITY_SUSPEND_IMAGES : ACTIVITY_UNSUSPEND_IMAGES;
  } else if (suspend_type === SUSPENSION_VIDEO) {
    activity_detail = enabled ? ACTIVITY_SUSPEND_VIDEO : ACTIVITY_UNSUSPEND_VIDEO;
  } else if (suspend_type === SUSPENSION_FEED_CREATION) {
    activity_detail = enabled ? ACTIVITY_SUSPEND_FEEDCREATE : ACTIVITY_UNSUSPEND_FEEDCREATE;
  }

  // log this activity
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_USER,
    type_id: user_id,
    action: activity_detail,
    object: `the user ${user_id}`,
    fromto: `from ${currentTime} till ${tillTime}`,
    reason: "",
  };

  gqlservice.set_auth_jwt(token, false);
  result = await gqlservice.insert_activitylog(activity)
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
};

const updateReportLimit = async (updatedAuthUser) => {
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN)
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  try {
    const result = await gqlservice.update_report_limit(updatedAuthUser);
    if (result.status_code === GRAPHQL_ERROR) {
      this.setError(result.msg);
      return;
    }
    return result;
  } catch (error) {
    const msg = "Error updating user report: " + error;
    this.setError(msg);
    return;
  }
}

const isCategoryModerator = () => {
  const state = store.getState();

  const { loggedIn, authUser } = state.sessionState;
  // check if the user is logged in
  if (!loggedIn) {
    return false;
  }
  // check if the user is a category moderator
  return authUser.categories_moderated.length > 0;
};

const isModeratorOfCategory = (category_id) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return false;
  }

  // check if the user is a moderator of the category the feed is created from
  const result = authUser.categories_moderated.find(
    (moderator) =>
      moderator.approved && moderator.category_id === category_id
  );
  return result !== undefined;
}

const isFeedModerator = (feed) => {
  const state = store.getState();

  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn || !feed) {
    return false;
  }

  // check if the user is the creator of this feed
  if (authUser.feeds_created.find((item) => item.id === feed.id)) {
    return true;
  }

  // check if the user is a moderator of the category the feed is created from
  if (
    authUser.categories_moderated.find(
      (moderator) =>
        moderator.approved && moderator.category_id === feed.category_id
    )
  ) {
    return true;
  }

  // check if the user is a moderator of this feed
  if (
    authUser.feeds_moderated.find(
      (moderator) => moderator.approved && moderator.feed_id === feed.id
    )
  ) {
    return true;
  }
  return false;
};

const isLocationModerator = (location) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return false;
  }

  // check if the user is a moderator of this location
  if (authUser.locations_moderated.find(
    (moderator) => moderator.approved && moderator.location_id === location.id
  ) !== undefined) {
    return true;
  };
  // check if the user is a moderator of this region
  if (authUser.regions_moderated.find(
    (moderator) => moderator.approved && moderator.region_id === location.region.id
  ) !== undefined) {
    return true;
  };

  return false;
};


const isRegionModerator = (region_id) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return false;
  }

  // check if the user is a moderator of this region
  if (authUser.regions_moderated.find(
    (moderator) => moderator.approved && moderator.region_id === region_id
  ) !== undefined) {
    return true;
  };

  return false;
}

const isBanned = () => {
  const suspendedState = getSuspendedState();
  return (
    suspendedState.suspended1day || 
    suspendedState.suspended7day || 
    suspendedState.suspendedPermanent
  );
}

const isBannedFeedCreation = () => {
  const suspendedState = getSuspendedState();
  return (
    suspendedState.suspended1day || 
    suspendedState.suspended7day || 
    suspendedState.suspendedPermanent ||
    suspendedState.suspendedFeedCreation
  );
}

const isBannedPosts = () => {
  const suspendedState = getSuspendedState();
  return (
    suspendedState.suspended1day || 
    suspendedState.suspended7day || 
    suspendedState.suspendedPermanent ||
    suspendedState.suspendedPosts
  );
}

const isBannedComments = () => {
  const suspendedState = getSuspendedState();
  return (
    suspendedState.suspended1day || 
    suspendedState.suspended7day || 
    suspendedState.suspendedPermanent || 
    suspendedState.suspendedComments
  );
}

const isBannedImage = () => {
  const suspendedState = getSuspendedState();
  if (suspendedState.suspended1day || 
    suspendedState.suspended7day || 
    suspendedState.suspendedPermanent) {
    return false;
  }
  return !suspendedState.suspendedImages;
}

const isBannedVideo = () => {
  const suspendedState = getSuspendedState();
  if (suspendedState.suspended1day || 
    suspendedState.suspended7day || 
    suspendedState.suspendedPermanent) {
    return false;
  }
  return !suspendedState.suspendedVideo;
}

const inviteUser = async (invitee, inviter_id, invited_to, is_phone) => {
  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  gqlservice.set_auth_jwt(token);
  let result = await gqlservice.insert_user_invite(invitee, inviter_id, invited_to, is_phone);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const users_invites = result.data.insert_user_invites.returning;
  if (users_invites.length > 0) {
    store.dispatch({ type: ActionTypes.ADD_USER_INVITE, user: users_invites[0] });
  }

  // insert notification
  const notification = {
    type: NOTIFICATION_FEED_INVITE,
    object: invitee,
    in_which: invited_to,
    to: invitee,
    created_by: inviter_id
  }

  result = await gqlservice.insert_notification(notification);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

const followUser = async (user_id) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.insert_user_follower(user_id, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const users_followed = result.data.insert_user_followers.returning;
  if (users_followed.length > 0) {
    store.dispatch({ type: ActionTypes.ADD_FOLLOW_USER, user: users_followed[0] });
  }
}

const unfollowUser = async (user_id) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.delete_user_follower(user_id, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  store.dispatch({ 
    type: ActionTypes.DELETE_FOLLOW_USER, 
    user_id: user_id,
  });
}

const banComments = async (user_id, ban_type) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token, true);

  const { selected_feed } = state.dataState;
  const is_banned = selected_feed.banned_users.find(banned => banned.user_id === user_id) !== undefined;
  let fnBan = is_banned ? gqlservice.update_banned_user : gqlservice.insert_banned_user;

  const now = Date.now();
  const banned_at = new Date(now).toISOString();
  let days = 0;
  if (ban_type === BANNED_TYPE_1D) {
    days = 1;
  } else if (ban_type === BANNED_TYPE_7D) {
    days = 7;
  } else if (ban_type === BANNED_TYPE_PERM) {
    days = 36500;
  } else {
    return;
  }

  const banned_till = new Date(now + 86400*1000*days).toISOString();
  
  const banned = {
    user_id: user_id,
    feed_id: selected_feed.id,
    banned_at: banned_at,
    banned_till: banned_till,
    type: ban_type,
    banned_by: authUser.uid
  };

  let result = await fnBan(banned);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const new_banned = is_banned ? 
    result.data.update_users_banned.returning[0] :
    result.data.insert_users_banned.returning[0];
  store.dispatch({
    type: ActionTypes.INSERT_BANNED_USER,
    banned: new_banned,
  });
}

const preapproveUser = async (user_id, feed) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const is_approved = feed.preapproved_users.find(approved => approved.user_id === user_id) !== undefined;
  if (is_approved) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token, true);

  const approved = {
    user_id: user_id,
    feed_id: feed.id,
    approved_by: authUser.uid
  };

  let result = await gqlservice.insert_preapproved_user(approved);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const new_approved = result.data.insert_users_preapproved.returning[0];
  store.dispatch({
    type: ActionTypes.INSERT_PREAPPROVE_USER,
    approved: new_approved,
  });
}

const usersBySearchkey = async (searchkey) => {
  const state = store.getState();
  const { loggedIn } = state.sessionState;
  if (!loggedIn) {
    return;
  }
  
  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  const result = await gqlservice.users_by_searchkey(searchkey);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  return result.data.users;
}

const summaryGenerationUserStatusUpdate = async (status) => { 
  store.dispatch({ type: ActionTypes.UPDATE_USER_SUMMARY_STATUS, status });
};

export {
  updateReportLimit,
  hasPaid,
  hasTwitter,
  getSuspendedState,
  suspendUser,
  isCategoryModerator,
  isModeratorOfCategory,
  isFeedModerator,
  isRegionModerator,
  isLocationModerator,
  isBanned,
  isBannedFeedCreation,
  isBannedPosts,
  isBannedComments,
  isBannedImage,
  isBannedVideo,
  inviteUser,
  followUser,
  unfollowUser,
  banComments,
  preapproveUser,
  usersBySearchkey,
  summaryGenerationUserStatusUpdate
};