import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { setError, updateCache } from "./base";
import { get_cached_item, set_cache_item } from "utility/cachemanager";
import { hasPaid } from "./user";
import { GRAPHQL_ERROR } from "constants/types";

const existSourcebyID = async (id) => {
  const state = store.getState();

  const { loggedIn } = state.sessionState;

  const gqlservice = new GraphqlService();
  if (loggedIn) {
    const token = await getAuthToken();
    if (!token) {
      return {
        error: true,
        message: ERR_MSG_AUTH_TOKEN,
      };
    }
    gqlservice.set_auth_jwt(token);
  }

  let result = await gqlservice.source_exist_by_id(id);
  if (result.status_code === GRAPHQL_ERROR) {
    return {
      error: true,
      message: result.msg,
    };
  }

  try {
    return {
      error: false,
      exist: result.data.sources_aggregate.aggregate.count > 0,
    };
  } catch (err) {
    return {
      error: true,
      message: err.toString(),
    };
  }
};

const getSourcebySocialtag = async (tag, type) => {
  const state = store.getState();

  const { loggedIn } = state.sessionState;
  const { sources } = state.dataState;

  const gqlservice = new GraphqlService();
  if (loggedIn) {
    const token = await getAuthToken();
    if (!token) {
      return {
        error: true,
        message: ERR_MSG_AUTH_TOKEN,
      };
    }
    gqlservice.set_auth_jwt(token);
  }

  let result = await gqlservice.source_by_socialtag(tag, type);
  if (result.status_code === GRAPHQL_ERROR) {
    return {
      error: true,
      message: result.msg,
    };
  }

  try {
    const source = result.data.socialtags[0].source;
    if (sources.find((item) => item.id === source.id) === undefined) {
      store.dispatch({
        type: ActionTypes.ADD_SOURCE,
        source: source,
      });

      // update cache
      let new_sources = sources.slice();
      new_sources.push(source);
      let cache_name = loggedIn
        ? hasPaid()
          ? "base_data_paiduser_v1"
          : "base_data_user_v1"
        : "base_data_anonymous_v1";
      let base_data = await get_cached_item(cache_name);
      if (base_data) {
        base_data.sources = new_sources;
        set_cache_item(cache_name, base_data);
      }
    }
    return source;
  } catch (err) {
    return null;
  }
};

const getFeedofSource = (source_id) => {
  const state = store.getState();
  const { loggedIn } = state.sessionState;
  const { followed_feeds, feeds } = state.dataState;

  let feed = null;
  if (loggedIn) {
    feed = followed_feeds.find(
      (item) =>
        item.feed_sources.find(
          (feed_source) => feed_source.source_id === source_id
        ) !== undefined
    );
    if (feed) {
      return feed;
    }
  }

  feed = feeds.find(
    (item) =>
      item.feed_sources.find(
        (feed_source) => feed_source.source_id === source_id
      ) !== undefined
  );

  return feed;
};

const followSource = async (source) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  const followers = source.followers + 1;

  let result = await gqlservice.update_source_followers(source.id, followers);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  store.dispatch({
    type: ActionTypes.SET_SOURCE_FOLLOWERS,
    source: source,
    followers: followers,
  });

  const follower = {
    id: `${source.id}-${authUser.uid}`,
    source_id: source.id,
    user_id: authUser.uid,
  };
  result = await gqlservice.insert_source_follower(follower);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  const newSource = result.data.insert_source_followers.returning[0];
  updateCache();
  const updatedAuthUser = { ...authUser };
  updatedAuthUser.sources_followed.push(newSource);
  store.dispatch({
    type: ActionTypes.SET_AUTH_USER,
    authUser: updatedAuthUser,
  });
};

const unfollowSource = async (source) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  const followers = source.followers - 1;

  let result = await gqlservice.update_source_followers(source.id, followers);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  store.dispatch({
    type: ActionTypes.SET_SOURCE_FOLLOWERS,
    source: source,
    followers: followers,
  });

  const follower_id = `${source.id}-${authUser.uid}`;
  result = await gqlservice.delete_source_follower(follower_id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  const updatedAuthUser = { ...authUser };
  const indexToRemove = updatedAuthUser.sources_followed.findIndex(
    (item) => item.source_id === source.id
  );
  if (indexToRemove !== -1) {
    updatedAuthUser.sources_followed.splice(indexToRemove, 1);
  }
  store.dispatch({
    type: ActionTypes.SET_AUTH_USER,
    authUser: updatedAuthUser,
  });
};

const upvoteSource = async (source) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.source_voter(source.id, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const voter = result.data.source_voter.length > 0;
  let source_upvote_func = null;
  if (voter) {
    source_upvote_func = gqlservice.source_dec_upvotes;
  } else {
    source_upvote_func = gqlservice.source_inc_upvotes;
  }

  result = await source_upvote_func(source.id, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const updated_votes = result.data.update_sources.returning[0].upvotes;
  store.dispatch({
    type: ActionTypes.UPDATE_SOURCE_UPVOTES,
    source_id: source.id,
    upvotes: updated_votes,
  });
};

const translateSource = async (source) => {
  const state = store.getState();
  const { loggedIn } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  const new_translate = !source.translate;
  let result = await gqlservice.update_source_translate(
    source.id,
    new_translate
  );
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  store.dispatch({
    type: ActionTypes.UPDATE_SOURCE_TRANSLATE,
    source_id: source.id,
    translate: new_translate,
  });
};

const getSourceReportForPast90days = async (id, time) => {
  const state = store.getState();
  const { loggedIn } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  // const new_translate = !source.translate;
  let result = await gqlservice.source_report_count(id, time);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  return result;
};

const getSourceInfoBySlug = async (slug) => {
  const gqlservice = new GraphqlService();
  return await gqlservice.source_by_slug(slug).then((result) => {
    const sources = result.data.sources;
    if (sources?.length > 0) {
      return sources[0];
    } else {
      return [];
    }
  });
};

export {
  existSourcebyID,
  getSourcebySocialtag,
  getFeedofSource,
  followSource,
  unfollowSource,
  upvoteSource,
  translateSource,
  getSourceReportForPast90days,
  getSourceInfoBySlug,
};
