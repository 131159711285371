import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { 
  Grid, 
  List
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { ToastContainer } from "react-toastify";
import { 
  withAuthentication, 
  withAuthorization 
} from "session";
import { 
  SearchAppBar, 
  WaitingSpinner 
} from "components";
import { 
  MyFeedsTabs,
  FeedItem,
  FeedSwipeList,
  FollowItem,
} from "./components";
import * as ROUTES from "constants/routes";
import { BRANCH_ALL } from "constants/branches";
import { ALL } from "constants/country";
import { withFirebase } from 'services';
import { GraphqlService } from "services";
import { 
  TAB_FEED, 
  MAX_ARTICLE_WIDTH, 
  GRAPHQL_SUCCESS, 
  TAB_MYFEEDS_FEEDS,
  TAB_MYFEEDS_USERS,
  TAB_MYFEEDS_FOLLOWING,
  TAB_MYFEEDS_FOLLOWERS
} from "constants/types";
import { 
  // feedsBySearchkey,
  followUser,
  getAuthToken, 
  getMainInfo, 
  isBanned, 
  isFeedModerator, 
  unfollowFeed, 
  unfollowUser,
  usersBySearchkey
} from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const condition = (authUser) => !!authUser && authUser.uid.length > 0;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 1200,
  },
  tabcontainer: {
    paddingLeft: theme.spacing(2),
    padding: 0,
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  myfeeds: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
    color: theme.palette.text.primary,
    textTransform: "none",
  },
});

class MyFeeds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: "",
      yourFeeds: [],
      // searched_feeds: [],
      searched_users: [],
      // myFeeds: [],
      // moderatedFeeds: []
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.handleNavBack = this.handleNavBack.bind(this);

    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleSwiped = this.handleSwiped.bind(this);
    this.handleSwipScroll = this.handleSwipScroll.bind(this);

    this.handleUnFollowFeed = this.handleUnFollowFeed.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleSortEnd = this.handleSortEnd.bind(this);

    this.handleClickUser = this.handleClickUser.bind(this);
    this.handleFollowUser = this.handleFollowUser.bind(this);
    this.handleUnfollowUser = this.handleUnfollowUser.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    this.setWaiting(true);

    await getMainInfo();
    await this.getMyFeeds();

    this.setWaiting(false);
  }

  getMyFeeds = async () => {
    const { feeds, followed_feeds, authUser } = this.props;
    if (feeds.length === 0) {
      return;
    }

    // Feeds created by me
    const myfeeds = feeds
      .filter((feed) => feed.created_by === authUser.uid)
      .map((feed) => {
        let new_feed = feed;
        new_feed.type = 1; // my feed
        return new_feed;
      });

    // Feeds moderated by me
    const moderated_feeds = authUser.feeds_moderated
      .map((item) => feeds.find((feed) => feed.id === item.feed_id))
      .filter((feed) => feed && feed.created_by !== authUser.uid)
      .map((feed) => {
        let new_feed = feed;
        new_feed.type = 2; // moderated feed
        return new_feed;
      });

    // Feeds followed by me
    const feeds2show = followed_feeds
      .filter(
        (feed) =>
          feed.private === false &&
          myfeeds.find((item) => item.id === feed.id) === undefined &&
          moderated_feeds.find((item) => item.id === feed.id) === undefined
      )
      .map((feed) => {
        let new_feed = feed;
        new_feed.type = 0;  // followed feed
        return new_feed;
      });

    // const myfeeds_mod = await this.getFeedsModerates(myfeeds);
    // this.setState({
    //   ...this.state,
    //   myFeeds: myfeeds_mod
    // });

    // const moderated_feeds_mod = await this.getFeedsModerates(moderated_feeds);
    // this.setState({
    //   ...this.state,
    //   moderatedFeeds: moderated_feeds_mod
    // });
    
    let yourfeeds = feeds2show.concat(myfeeds, moderated_feeds);
    logger.log("yourfeeds :", yourfeeds);

    // Get Feed Orders
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    let result = await gqlservice.feed_orders(authUser.uid);
    if (result.status_code !== GRAPHQL_SUCCESS) {
      this.setError(result.message);
      return;
    }

    let orders = result.data.feed_order;
    let yourfeedsOrder = [];

    if (orders.length === 0) {
      // Make orders
      const followedOrder = feeds2show.map((feed, index) => {
        return {
          user_id: authUser.uid,
          feed_id: feed.id,
          order: index
        }
      });
      const myfeedOrder = myfeeds.map((feed, index) => {
        return {
          user_id: authUser.uid,
          feed_id: feed.id,
          order: index + feeds2show.length
        }
      });
      const moderatedOrder = moderated_feeds.map((feed, index) => {
        return {
          user_id: authUser.uid,
          feed_id: feed.id,
          order: index + feeds2show.length + myfeedOrder.length
        }
      });
      yourfeedsOrder = followedOrder.concat(myfeedOrder, moderatedOrder);

      let result = gqlservice.insert_feed_orders(yourfeedsOrder);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        this.setError(result.message);
        return;
      }
    } else {
      // get feeds not in order
      const feeds2add = yourfeeds.filter(feed => orders.find(order => order.feed_id === feed.id) === undefined);
      if (feeds2add.length > 0) {
        const order2add = feeds2add.map((feed, index) => {
          return {
            user_id: authUser.uid,
            feed_id: feed.id,
            order: index + orders.length
          }
        });
        let result = gqlservice.insert_feed_orders(order2add);
        if (result.status_code !== GRAPHQL_SUCCESS) {
          this.setError(result.message);
          return;
        }
        yourfeedsOrder = orders.concat(order2add);
      } else {
        yourfeedsOrder = orders.slice();
      }
    }
    
    logger.log("yourfeedsOrder :", yourfeedsOrder);
    this.props.updateFeedsOrder(yourfeedsOrder);

    this.setState({
      ...this.state,
      yourFeeds: yourfeeds
    });
  }

  getFeedsModerates = async (feeds) => {
    // feeds already approved
    const approved_feeds = feeds.filter(feed => feed.approved);
    if (approved_feeds.length === 0) {
      return feeds;
    }

    // get moderation count for all approved feeds
    let newfeeds = [];
    for (let feed of approved_feeds) {
      let new_feed = await this.getFeedModerates(feed);
      newfeeds.push(new_feed);
    }

    return newfeeds;
  }

  getFeedModerates = async (feed) => {
    let new_feed = feed;
    const feed_source_ids = new_feed.feed_sources
      .filter(feed_source => feed_source.approved)
      .map(feed_source => feed_source.source_id);
    if (feed_source_ids.length === 0) {
      return feed;
    }

    const timestamp = new Date() - 86400 * 1000;
    const after = new Date(timestamp).toISOString();

    let badgeCount = 0;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    logger.log("MyFeeds getFeedModerates :", feed);

    await gqlservice.feed_moderation_count(feed.id, feed_source_ids, after)
      .then(result => {
        badgeCount += result.data.feed_reports_aggregate.aggregate.count;
        badgeCount += result.data.feed_sources_aggregate.aggregate.count;
        badgeCount += result.data.source_reports_aggregate.aggregate.count + result.data.feed_posts_aggregate.aggregate.count;
        badgeCount += result.data.articles_aggregate.aggregate.count;
        badgeCount += result.data.article_reports_aggregate.aggregate.count;
        badgeCount += result.data.feed_moderators_aggregate.aggregate.count;
        badgeCount += result.data.moderator_reports_aggregate.aggregate.count;
        badgeCount += result.data.thread_reports_aggregate.aggregate.count;
        badgeCount += result.data.threads_aggregate.aggregate.count;
        // badgeCount += result.data.activitylogs_aggregate.aggregate.count;
      }, reason => {
        this.setError(reason.msg);
        return feed;
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        return feed;
      });

    new_feed.badgeCount = badgeCount;

    return new_feed;
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleUnFollowFeed = async (feed_id) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { followed_feeds } = this.props;
    const { searchKey } = this.state;

    // get feeds corresponding to the following (refer to render)
    let feeds2show = followed_feeds.slice();
    if (searchKey) {
      feeds2show = this.searchFeeds(feeds2show, searchKey);
    }

    const feed = feeds2show.find((item) => item.id === feed_id);
    if (!feed) {
      return;
    }

    const res = await unfollowFeed(feed);
    if (res === false){
      return
    }
    // update the yourFeeds  state
    const yourFeeds = this.state.yourFeeds.filter(
      (item) => item.id !== feed.id
    );
    this.setState({
      ...this.state,
      yourFeeds: yourFeeds,
    });
  };

  handleClickFeed = (feed_id) => {
    const { feeds } = this.props;
    const feed = feeds.find((item) => item.id === feed_id);
    if (!feed) {
      return;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);

    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    if (isFeedModerator(feed)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    }
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setFeedBackRoute(ROUTES.MY_FEEDS);
  };

  handleSearchChange = (searchKey) => {
    this.setState({
      ...this.state,
      searchKey: searchKey,
    });
  };

  handleSearchEnter = async (searchKey) => {
    logger.log("handleSearchEnter :", searchKey);
    if (!searchKey) {
      return;
    }

    this.setWaiting(true);

    const { myfeedstab } = this.props;
    if (myfeedstab === TAB_MYFEEDS_FEEDS) {
      // const searched_feeds = await feedsBySearchkey(searchKey.trim());
      // this.setState({
      //   ...this.state,
      //   searched_feeds: searched_feeds
      // });
    } else if (myfeedstab === TAB_MYFEEDS_USERS) {
      const { authUser } = this.props;
      let searched_users = await usersBySearchkey(searchKey.trim());
      searched_users = searched_users.filter(user => user.uid !== authUser.uid);
      this.setState({
        ...this.state,
        searched_users: searched_users
      });
    }

    this.setWaiting(false);
  };

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.showTopNavbar(true);
  };

  handleChangeTab = (tab_value) => {
    if (tab_value === this.props.myfeedstab) {
      return;
    }

    this.setState({
      ...this.state,
      searchKey: ""
    }, async () => {
      window.scroll(0, 0);
      this.props.selectMyfeedsTab(tab_value);
      this.props.initScrollPos();
    });

    if (tab_value === TAB_MYFEEDS_FEEDS) {
    } else if (tab_value === TAB_MYFEEDS_USERS) {
    } else if (tab_value === TAB_MYFEEDS_FOLLOWING) {
    } else if (tab_value === TAB_MYFEEDS_FOLLOWERS) {
    }
  }

  handleSwiped = (tabIndex) => {
    this.handleChangeTab(tabIndex);
  }

  handleSwipScroll = (event) => {
    event.preventDefault();

    // const { myfeedstab } = this.props;

    // let element_id = "myfeeds-swipeable-views-feeds";
    // if (myfeedstab === TAB_MYFEEDS_FOLLOWING) {
    //   element_id = "myfeeds-swipeable-views-following";
    // } else if (myfeedstab === TAB_MYFEEDS_FOLLOWERS) {
    //   element_id = "myfeeds-swipeable-views-followers";
    // }

    // const domElement = document.getElementById(element_id).parentNode;
  }

  searchFeeds(feeds, searchKey) {
    const key = searchKey.toLowerCase();
    var result = [];
    feeds.forEach((feed) => {
      if (
        !feed.private &&
        feed.approved &&
        (feed.name.toLowerCase().includes(key) ||
          feed.description.toLowerCase().includes(key))
      ) {
        result.push(feed);
      }
    });
    return result;
  }

  handleSortEnd = async (oldIndex, newIndex) => {
    const { authUser } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }

    gqlservice.set_auth_jwt(token);

    // swap order
    const orders = authUser.feeds_order
      .filter(item => item.order === oldIndex || item.order === newIndex)
      .map(item => {
        if (item.order === oldIndex) {
          return {
            user_id: item.user_id,
            feed_id: item.feed_id,
            order: newIndex
          }
        } else {
          return {
            user_id: item.user_id,
            feed_id: item.feed_id,
            order: oldIndex
          }
        }
      });

    for (let order of orders) {
      await gqlservice
        .update_feed_order(order)
        .then(
          (result) => {},
          (reason) => {
            this.setError(reason.msg);
            return;
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
          return;
        });
    }

    // const new_orders = reorder(authUser.feeds_order, oldIndex, newIndex);
    logger.log("authUser feeds order :", authUser.feeds_order);
    let new_orders = authUser.feeds_order
      .map(item => {
        if (item.order === oldIndex) {
          return {
            user_id: item.user_id,
            feed_id: item.feed_id,
            order: newIndex
          }
        } else if (item.order === newIndex) {
          return {
            user_id: item.user_id,
            feed_id: item.feed_id,
            order: oldIndex
          }
        } else {
          return item;
        }
      });

    this.props.updateFeedsOrder(new_orders);
  };

  handleClickUser = (user) => {
    if (user.username === null || user.username.length === 0) {
      return;
    }
    let route = `/${ROUTES.USER_PREFIX}/${user.username}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  handleFollowUser = async (user) => {
    if (isBanned()) {
      this.setError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await followUser(user.uid);
    this.setWaiting(false);
  }

  handleUnfollowUser = async (user) => {
    if (isBanned()) {
      this.setError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await unfollowUser(user.uid);
    this.setWaiting(false);
  }

  render() {
    const { 
      classes, 
      theme_mode,
      authUser,
      // feeds, 
      myfeedstab,
      requesting 
    } = this.props;
    const { 
      searchKey, 
      yourFeeds,
      // searched_feeds,
      searched_users,
      // myFeeds, 
      // moderatedFeeds 
    } = this.state;

    // show feeds only followed by user (followed feeds)
    let feeds2show = yourFeeds.slice();
    if (searchKey) {
      feeds2show = this.searchFeeds(feeds2show, searchKey);
    }

    // // feeds created by me
    // let myFeeds2Show = myFeeds.slice();
    // if (searchKey) {
    //   myFeeds2Show = this.searchFeeds(myFeeds, searchKey);
    // }

    // // feeds moderated by me
    // let moderatedFeeds2Show = [];
    // if (feeds.length > 0 && authUser.feeds_moderated.length > 0) {
    //   moderatedFeeds2Show = moderatedFeeds.slice();
    //   if (searchKey) {
    //     moderatedFeeds2Show = this.searchFeeds(moderatedFeeds, searchKey);
    //   }
    // }

    let following = [];
    if (authUser.users_followed.length > 0) {
      following = authUser.users_followed.map(item => item.user);
    }
    if (searchKey) {
      following = following.filter(item => 
        item.name.toLowerCase().includes(searchKey) ||
        item.username.toLowerCase().includes(searchKey) ||
        item.biography.toLowerCase().includes(searchKey)
      );
    }

    let followers = [];
    if (authUser.user_followers.length > 0) {
      followers = authUser.user_followers.map(item => item.follower);
    }
    if (searchKey) {
      followers = followers.filter(item => 
        item.name.toLowerCase().includes(searchKey) ||
        item.username.toLowerCase().includes(searchKey) ||
        item.biography.toLowerCase().includes(searchKey)
      );
    }

    const height = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight;
    const slideContainer = {
      height: height,
      WebkitOverflowScrolling: 'touch',
    }

    logger.log("follow :", following, followers);

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <SearchAppBar
            title={"Following"}
            width={MAX_ARTICLE_WIDTH}
            onSearchChange={this.handleSearchChange}
            onSearchEnter={this.handleSearchEnter}
            onNavBack={this.handleNavBack}
          />
        </div>

        <MyFeedsTabs
          theme_mode={theme_mode}
          onChangeTab={this.handleChangeTab}
        />
        
        <SwipeableViews
          containerStyle={myfeedstab !== TAB_MYFEEDS_FEEDS ? slideContainer : null}
          index={myfeedstab}
          onChangeIndex={this.handleSwiped} 
          onScroll={this.handleSwipScroll}
          enableMouseEvents
        >
          <div 
            className={classes.tabcontainer}
            id="myfeeds-swipeable-views-feeds"
          >
            {myfeedstab === TAB_MYFEEDS_FEEDS &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  {searchKey && feeds2show.length > 0 && (
                    <List>
                      {feeds2show.map((feed) => (
                        <FeedItem
                          key={feed.id}
                          feed={feed}
                          following={true}
                          ismyfeed={feed.ismyfeed}
                          enabled={true}
                          badged={feed.type === 1 || feed.type === 2}
                          theme_mode={theme_mode}
                          onClicked={this.handleClickFeed}
                          onFollowed={this.handleUnFollowFeed}
                        />
                      ))}
                    </List>
                  )}
                  {!searchKey && feeds2show.length > 0 && (
                    <FeedSwipeList
                      feeds={feeds2show}
                      ismyfeed={false}
                      onClickFeed={this.handleClickFeed}
                      onFollowFeed={this.handleUnFollowFeed}
                      onDeleteFeed={this.handleUnFollowFeed}
                      onSortEnd={this.handleSortEnd}
                    />
                  )}
                  {/* { myFeeds2Show.length > 0 && (
                    <div className={classes.myfeeds}>
                      <Button className={classes.title}>{"My Feeds"}</Button>
                      <List>
                        {myFeeds2Show.map((feed) => {
                          let approved = false;
                          const feed_moderator = feed.feed_moderators.find(
                            (moderator) => moderator.user_id === authUser.uid
                          );
                          if (feed_moderator) {
                            approved = feed_moderator.approved;
                          }
                          return (
                            <FeedItem
                              key={feed.id}
                              feed={feed}
                              following={true}
                              ismyfeed={true}
                              enabled={approved}
                              badged={true}
                              theme_mode={theme_mode}
                              onClicked={this.handleClickFeed}
                            />
                          );
                        })}
                      </List>
                    </div>
                  )}
                  { moderatedFeeds2Show.length > 0 && (
                    <div className={classes.myfeeds}>
                      <Button className={classes.title}>{"Moderated Feeds"}</Button>
                      <List>
                        {moderatedFeeds2Show.map((feed) => {
                          let approved = false;
                          const feed_moderator = feed.feed_moderators.find(
                            (moderator) => moderator.user_id === authUser.uid
                          );
                          if (feed_moderator) {
                            approved = feed_moderator.approved;
                          }
                          return (
                            <FeedItem
                              key={feed.id}
                              feed={feed}
                              following={true}
                              ismyfeed={true}
                              enabled={approved}
                              badged={true}
                              theme_mode={theme_mode}
                              onClicked={this.handleClickFeed}
                            />
                          );
                        })}
                      </List>
                    </div>
                  )} */}
                </Grid>
              </Grid>
            }
          </div>

          <div 
            className={classes.tabcontainer} 
            id="myfeeds-swipeable-views-following"
          >
            {myfeedstab === TAB_MYFEEDS_USERS &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <List>
                    {searched_users.length > 0 && searched_users.map(user => 
                      <FollowItem
                        key={user.uid}
                        theme_mode={theme_mode}
                        user={user}
                        following={authUser.users_followed.find(followed => followed.user_id === user.uid) !== undefined}
                        onClicked={this.handleClickUser}
                        onFollowed={this.handleFollowUser}
                        onUnfollowed={this.handleUnfollowUser}
                      />
                    )}
                  </List>
                </Grid>
              </Grid>
            }
          </div>

          <div 
            className={classes.tabcontainer} 
            id="myfeeds-swipeable-views-following"
          >
            {myfeedstab === TAB_MYFEEDS_FOLLOWING &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <List>
                    {following.length > 0 && following.map(user => 
                      <FollowItem
                        key={user.uid}
                        theme_mode={theme_mode}
                        user={user}
                        following={true}
                        onClicked={this.handleClickUser}
                        onFollowed={this.handleFollowUser}
                        onUnfollowed={this.handleUnfollowUser}
                      />
                    )}
                  </List>
                </Grid>
              </Grid>
            }
          </div>

          <div 
            className={classes.tabcontainer} 
            id="myfeeds-swipeable-views-followers"
          >
            {myfeedstab === TAB_MYFEEDS_FOLLOWERS &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <List>
                    {followers.length > 0 && followers.map(user => 
                      <FollowItem
                        key={user.uid}
                        theme_mode={theme_mode}
                        user={user}
                        following={authUser.users_followed.find(followed => followed.user_id === user.uid) !== undefined}
                        onClicked={this.handleClickUser}
                        onFollowed={this.handleFollowUser}
                        onUnfollowed={this.handleUnfollowUser}
                      />
                    )}
                  </List>
                </Grid>
              </Grid>
            }
          </div>
        </SwipeableViews>

        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  myfeedstab: state.uiState.myfeedstab,
  requesting: state.uiState.requesting,
  newssites: state.dataState.newssites,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  socialtypes: state.dataState.socialtypes,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MyFeeds);
