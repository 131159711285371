import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";
import { MIN_CARD_WIDTH, MAX_CARD_WIDTH, THEME_MODE_LIGHT } from "constants/types";

const styles = (theme) => ({
  root: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_CARD_WIDTH,
    backgroundColor: theme.palette.background.default,
  },
  container: {
    // justifyContent: "left",
    flexWrap: "inherit",
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.article,
    // margin: 3,
    borderRadius: 5,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  prefix: {
    display: "inline",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  name: {
    display: "inline",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 500,
    color: "#E8EC31",
    marginTop: theme.spacing(1),
  },
  report: {
    textAlign: "left",
    fontSize: "12px",
    fontWeight: 200,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  btncontainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionbtn: {
    backgroundColor: theme.palette.background.main,
    borderRadius: "20px",
    padding: "4px 10px",
    marginLeft: theme.spacing(1),
    textTransform: "initial",
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  actionicon: {
    padding: 0,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
});

class Report extends React.Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDelete = () => {
    const { report } = this.props;
    this.props.onDelete(report);
  };

  handleDismiss = () => {
    const { report } = this.props;
    this.props.onDismiss(report);
  };

  render() {
    const { classes, report, theme_mode, onClickReporter } = this.props;

    const class_name = {
      display: "inline",
      textAlign: "left",
      fontSize: "14px",
      fontWeight: 500,
      color: theme_mode === THEME_MODE_LIGHT ? "black" : "#E8EC31",
      marginTop: 8,
    };

    const report_username = report.reported_user.username;
    const report_content = report.report;
    return (
      <div className={classes.root}>
        <Box className={classes.container}>
          <Typography className={classes.prefix}>Reported By </Typography>
          <Typography style={class_name} onClick={e => onClickReporter(report)}>{report_username}:</Typography>
          <Typography className={classes.report}>{report_content}</Typography>
        </Box>
        {!report.approved && (
          <Box className={classes.btncontainer}>
            <Button
              className={classes.actionbtn}
              startIcon={
                <img
                  className={classes.actionicon}
                  alt="approve"
                  src={`/static/images/delete.png`}
                />
              }
              onClick={this.handleDelete}
            >
              Delete
            </Button>
            <Button
              className={classes.actionbtn}
              startIcon={
                <img
                  className={classes.actionicon}
                  alt="dismiss"
                  src={`/static/images/dismiss.png`}
                />
              }
              onClick={this.handleDismiss}
            >
              Dismiss
            </Button>
          </Box>
        )}
      </div>
    );
  }
}

Report.propTypes = {
  className: PropTypes.string,
  theme_mode: PropTypes.string,
  onDelete: PropTypes.func,
  onDismiss: PropTypes.func,
  onClickReporter: PropTypes.func
};

export default withStyles(styles)(Report);
