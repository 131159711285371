import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MetaTags from "react-meta-tags";
import { ToastContainer } from "react-toastify";
import * as ROUTES from "constants/routes";
import { 
  CommentsAppBar, 
  CommentThreadItem,
  DlgShare,
  DlgLoginConfirm,
  DlgTopThread,
  WaitingSpinner
} from "components";
import { 
  withAuthentication 
} from "session";
import {
  withFirebase,
  GraphqlService,
} from "services";
import { 
  MAX_ARTICLE_WIDTH,
} from "constants/types";
import { 
  ARTICLE_BRANCH_NEWSPAPER 
} from "constants/branches";
import { 
  addThread,
  getMainInfo, 
  isCategoryModerator, 
  upvoteThread 
} from "dataapis";
import {
  ToastError
} from "utility/toast";
import { logger } from "utility/logging";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 1100,
  },
  threads: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  threaditem: {
    marginBottom: theme.spacing(1),
  },
  addbutton: {
    position: "fixed",
    bottom: theme.spacing(5),
    // right: theme.spacing(1),
    width: 60,
    height: 60,
    zIndex: 1200,
  },
});

class CleanAirComments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showShareDlg: false,
      loginDlg: false,
      postDlg: false,
      selected_feed: null,
    };

    this.scrollFn = this.listenToScroll.bind(this);

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleClickShare = this.handleClickShare.bind(this);
    this.handleCloseShare = this.handleCloseShare.bind(this);
    this.handleClickReportManage = this.handleClickReportManage.bind(this);

    this.handleClickThread = this.handleClickThread.bind(this);
    this.handleClickUpvote = this.handleClickUpvote.bind(this);

    this.handleAddThread = this.handleAddThread.bind(this);
    this.handleCloseThread = this.handleCloseThread.bind(this);
    this.handleSubmitThread = this.handleSubmitThread.bind(this);
  }

  componentDidMount = async () => {
    window.addEventListener("scroll", this.scrollFn);

    this.setWaiting(true);

    await getMainInfo();
    await this.getThreadsInFirstPage();

    this.setWaiting(false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFn);
  }

  listenToScroll = async (event) => {
    event.preventDefault();

    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    if (height === 0) {
      return;
    }

    const scrolled = winScroll * 1.0 / height;
    if (scrolled === 0.0) {
    } else if (scrolled === 1.0 || scrolled >= 0.99) {
      // this.props.saveScrollPos(window.scrollX, window.scrollY);
      await this.getThreadsInNextPage(this.props.threads_last_offset);
    } else {
    }
  };

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  getThreadsInFirstPage = async () => {
    this.setWaiting(true);
    
    const gqlservice = new GraphqlService();
    await gqlservice
      .maplocation_threads(0)
      .then(
        (result) => {
          const threads = result.data.threads;
          logger.log("Feed => threads(first page) :", threads);

          this.props.setThreads(threads, threads.length);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  getThreadsInNextPage = async (last_offset) => {
    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    await gqlservice
      .maplocation_threads(last_offset)
      .then(
        (result) => {
          const threads = result.data.threads;
          logger.log("Feed => threads(next page) :", threads);

          if (threads.length > 0) {
            this.props.appendThreads(threads, last_offset + threads.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.CLEANAIRMAP,
      state: { animation: "right" },
    };
    this.props.refreshThreads();
    this.props.history.push(location);
  }

  handleClickShare = () => {
    this.setState({
      showShareDlg: true,
    });
  }

  handleCloseShare = () => {
    this.setState({
      showShareDlg: false,
    });
  }

  handleClickReportManage = () => {
    
  }

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
  };

  handleClickThread = (thread) => {
    const { feeds } = this.props;
    this.props.selectThread(thread);

    if (thread.feed_id) {
      const selected_feed = feeds.find(feed => feed.id === thread.feed_id);
      if (thread.article) {
        const type = thread.article.source.branch === ARTICLE_BRANCH_NEWSPAPER ? ROUTES.ARTICLE_NEWS_PREFIX : ROUTES.ARTICLE_PREFIX;
        const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${thread.article.source.slug}/${type}/${thread.article.nid}`;
        const location = {
          pathname: route,
          state: { animation: "left" },
        };
        this.props.history.push(location);
        this.props.setArticleBackRoute(ROUTES.COMMENTS);
      } else {
        const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.id}`;
        const location = {
          pathname: route,
          state: { animation: "left" },
        };
        this.props.history.push(location);
      }
    } else {
      if (thread.article) {
        const type = thread.article.source.branch === ARTICLE_BRANCH_NEWSPAPER ? ROUTES.ARTICLE_NEWS_PREFIX : ROUTES.ARTICLE_PREFIX;
        const route = `/${type}/${thread.article.nid}`;
        const location = {
          pathname: route,
          state: { animation: "left" },
        };
        this.props.history.push(location);
        this.props.setArticleBackRoute(ROUTES.COMMENTS);
      } else {
        const route = `/${ROUTES.THREAD_PREFIX}/${thread.id}`;
        const location = {
          pathname: route,
          state: { animation: "left" },
        };
        this.props.history.push(location);
      }
    }
  }

  _getAuthToken = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    let token = authUser.token;
    if (Date.now() >= authUser.expiredTS) {
      const result = await this.props.firebase.refreshToken();
      if (result.error) {
        this.setError(result.msg);
        token = null;
      } else {
        token = result.token;
      }
    }
    return token;
  };

  handleClickUpvote = async (thread) => {
    if (!this.props.loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await upvoteThread(thread);
    this.setWaiting(false);
  }

  handleAddThread = () => {
    const { loggedIn } = this.props;
    if (loggedIn) {
      // if (hasPaid()) {
        this.setState({
          ...this.state,
          threadDlg: true,
        });
      // } else {
      //   const location = {
      //     pathname: ROUTES.MEMBERSHIP,
      //     state: { animation: "bottom" },
      //   };
      //   this.props.history.push(location);
      // }
    } else {
      this.setState({
        ...this.state,
        loginDlg: true
      });
    }
  };

  handleCloseThread = () => {
    this.setState({
      ...this.state,
      threadDlg: false,
    });
  };

  handleSubmitThread = async (title, content, feed) => {
    const { authUser } = this.props;

    // check if the user is pre-approved for this selected feed
    const approved_user = authUser.preapproved.find(
      (item) => item.feed_id === feed.id
    );
    const approved = approved_user !== undefined;

    this.setWaiting(true);
    await addThread(title, content, feed, approved);
    this.setWaiting(false);

    this.handleCloseThread();
  };

  render() {
    const { 
      classes, 
      theme_mode,
      feeds,
      threads, 
      requesting 
    } = this.props;
    const { 
      showShareDlg,
      loginDlg,
      threadDlg,
    } = this.state;

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    let addbuttonPos = width - 96;
    if (width > MAX_ARTICLE_WIDTH) {
      addbuttonPos = MAX_ARTICLE_WIDTH - 96 + (width - MAX_ARTICLE_WIDTH) / 2;
      width = MAX_ARTICLE_WIDTH;
    }

    let moderator = isCategoryModerator();

    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += ROUTES.CLEANAIRMAP_COMMENTS;

    const shareInfo = {
      title: "Raven: CleanAir Map Comments",
      description: "Show Raven comments for CleanAir Map",
      image: "/static/images/icons/raven_logo.png",
      hashtag: "raven,cleanairmap,comments",
      url: shareUrl,
    }

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{"Raven Comments"}</title>
            <meta name="description" content={"Comments throughout Raven"} />
            <meta property="og:title" content={"Raven Comments"} />
            <meta
              property="og:description"
              content={"Comments throughout Raven"}
            />
            <meta property="og:image" content={""} />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={shareUrl} />
            <meta property="twitter:title" content={"Raven Comments"} />
            <meta property="twitter:site" content="Raven App" />
            <meta
              property="twitter:description"
              content={"Comments throughout Raven"}
            />
            <meta property="twitter:image:src" content={""} />
            <meta property="twitter:image:alt" content={""} />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>

        <div className={classes.appbar}>
          <CommentsAppBar
            moderator={moderator}
            notifications={0}
            onNavBack={this.handleNavBack}
            onReports={this.handleClickReportManage}
            onShare={this.handleClickShare}
          />
        </div>

        {threads.length > 0 && (
          <div className={classes.threads}>
            {threads.map((thread, index) => (
              <div className={classes.threaditem} key={`thread-${index}`}>
                <CommentThreadItem
                  key={`thread-${index}`}
                  width={width - 16}
                  thread={thread}
                  index={index + 1}
                  onClick={this.handleClickThread}
                  onUpvote={this.handleClickUpvote}
                />
              </div>
            ))}
            <div style={{ width: width - 16, height: 20 }}></div>
          </div>
        )}

        <DlgTopThread
          open={threadDlg}
          theme={theme_mode}
          feeds={feeds}
          onClose={this.handleCloseThread}
          onSubmit={this.handleSubmitThread}
        />
        <div onClick={this.handleAddThread}>
          <img
            className={classes.addbutton}
            style={{ left: addbuttonPos, bottom: 16 }}
            alt={"addthread"}
            src={`/static/images/icons/${theme_mode}/add.png`}
          />
        </div>

        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgShare
          open={showShareDlg}
          shareInfo={shareInfo}
          onClose={this.handleCloseShare}
        />
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

CleanAirComments.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  feeds: state.dataState.feeds,
  threads: state.dataState.threads,
  threads_last_offset: state.dataState.threads_last_offset,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withAuthentication,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CleanAirComments);
