import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withRouter } from "react-router-dom";
import { withFirebase } from 'services';
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Typography
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { 
  withAuthentication, 
  withAuthorization 
} from "session";
import {
  BasicAppBar,
  SourceLink,
  WaitingSpinner,
  DlgConfirm,
  ImageSelector
} from "components";
import { GraphqlService } from "services";
import { check_static_link } from "utility/checklink";
import { ARTICLE_BRANCH_NEWSPAPER } from "constants/branches";
import { 
  MAX_ARTICLE_WIDTH, 
  MAX_WINDOW_WIDTH, 
  GRAPHQL_SUCCESS,
  RAVEN_PLACEHOLDER_IMAGE
} from "constants/types";
import * as ROUTES from "constants/routes";
import { getAuthToken, updateCache } from "dataapis";
// import { request_crawlsource } from "utility/ravenapi";
import { ToastError } from "utility/toast";
import { 
  slugify,
  is_valid_slug 
} from "utility/utils";
import { 
  moderate_image 
} from "utility/ravenapi";
import {
  resizeImageFile
} from "utility/resizeimage";
import { logger } from "utility/logging";


const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: theme.breakpoints.lg,
    maxWidth: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  sourceItem: {
    display: "flex",
    padding: 8,
  },
  detail: {
    paddingLeft: 16,
  },
  name: {
    display: 'block',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.main,
    padding: 0,
    border: 0,
    fontSize: 20,
    "&:focus": {
      outline: "0px",
    },
    "&::placeholder": {
      color: theme.palette.text.primary,
    },
    "&::-webkit-input-placeholder": {
      color: theme.palette.text.primary,
    },
    "&:-ms-input-placeholder": {
      color: theme.palette.text.primary,
    },
  },
  description: {
    display: 'block',
    height: 70,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.main,
    border: 0,
    fontSize: 14,
    padding: 0,
    marginTop: 4,
    fontFamily: "Roboto",
    "&:focus": {
      outline: "0px",
    },
  },
  sourceurlcontainer: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sourceurlprefix: {
    display: "inline",
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  sourceurlinput: {
    display: 'inline',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    marginLeft: 4,
    padding: 2,
    paddingLeft: 4,
    border: 0,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.text.secondary,
    fontSize: 14,
    "&:focus": {
      outline: "0px",
    },
    "&::placeholder": {
      color: theme.palette.text.primary,
    },
    "&::-webkit-input-placeholder": {
      color: theme.palette.text.primary,
    },
    "&:-ms-input-placeholder": {
      color: theme.palette.text.primary,
    },
  },
  applycontainer: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  applybtn: {
    position: "absolute",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#3AB54A",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    }
  },
  applybtn_disabled: {
    position: "absolute",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#3AB54A",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.3,
  },
});

class SourceEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "",
      slug: "",
      image: RAVEN_PLACEHOLDER_IMAGE,
      imageUpload: "",
      // linkImage: "",
      linksource: null,
      linkchanged: false,
      showConfirmDlg: false
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitSource = this.handleSubmitSource.bind(this);
    this.handleApplyLink = this.handleApplyLink.bind(this);
    this.handleChangedSourceLink = this.handleChangedSourceLink.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = () => {
    const { selected_feed, selected_source } = this.props;
    if (selected_source.branch === ARTICLE_BRANCH_NEWSPAPER) {
      const location = {
        pathname: `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${selected_source.slug}`,
        state: {},
      };
      this.props.history.replace(location);
      return
    }

    const linksource = {
      id: 0,
      branch: selected_source.branch,
      socialtype: selected_source.socialtags[0].type,
      source: selected_source.socialtags[0].tag,
      weblink: selected_source.weblink
    };

    this.setState({
      name: selected_source.name,
      description: selected_source.description,
      slug: selected_source.slug,
      image: selected_source.image,
      linksource: linksource
    });
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    this.props.history.goBack();
  };

  handleCancel = () => {
    this.setState({
      ...this.state,
      showConfirmDlg: false,
    });
  };

  handleApply = () => {
    this.setState({
      ...this.state,
      showConfirmDlg: true,
    });
  };

  handleImageChange = ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      this.setState({
        ...this.state,
        image: e.target.result,
        imageUpload: target.files[0],
        // linkImage: ""
      });
    };
  };

  handleChange = (event) => {
    event.persist();

    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handleApplyLink = async (link_id, branch, socialtype, source, weblink) => {
    const { linksource } = this.state;
    
    let new_linksource = {
      id: linksource.id,
      branch: branch,
      socialtype: socialtype,
      source: source,
      weblink: weblink
    };
    // get link information if the link is a static link
    const static_link_type = check_static_link(source, branch);
    if (static_link_type !== null) {
      linksource.socialtype = static_link_type;
    }

    this.setState({
      ...this.state,
      linksource: new_linksource,
      linkchanged: false
    });

    // if (!source.startsWith("http")) {
    //   this.setState({
    //     ...this.state,
    //     linkImage: "" 
    //   });
    //   return;
    // }
    // // get link image if the source is full link
    // let linkImage = await get_link_image(source);
    // if (linkImage === null) {
    //   const hostname = (new URL(source)).hostname;
    //   const linkUrl = `https://${hostname}`;
    //   linkImage = await get_link_image(linkUrl);
    // }
    // if (linkImage === null) {
    //   this.setState({
    //     ...this.state,
    //     linkImage: "" 
    //   });
    // } else {
    //   this.setState({
    //     ...this.state,
    //     linkImage: linkImage,
    //     image: RAVEN_PLACEHOLDER_IMAGE,
    //     imageUpload: ""
    //   });
    // }
  };

  handleChangedSourceLink = (changed) => {
    this.setState({
      ...this.state,
      linkchanged: changed
    });
  }

  _checkValidSlug = async (source_id, slug) => {
    if (!is_valid_slug(slug)) {
      this.setError("Invalid source url format, permits only alphabet, numeric, _, - and + characters.");
      return false;      
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return false;
    }
    gqlservice.set_auth_jwt(token);
 
    const result = await gqlservice.source_exist_by_slug(source_id, slug);
    if (result.status_code === GRAPHQL_SUCCESS) {
      if (result.data.sources.length > 0) {
        this.setError("Source Url name exist, choose another one!");
        return false;
      } else {
        return true;
      };
    } else {
      this.setError("Failed to connect database!");
      return false;
    }
  }

  handleSubmitSource = async () => {
    const { name, slug, linksource, linkchanged } = this.state;
    const { selected_source } = this.props;
    
    this.setState({
      ...this.state,
      showConfirmDlg: false,
    });

    if (!name.trim()) {
      this.setError("Source name shouldn't be blank!");
      return;
    }
    if (!slug.trim()) {
      this.setError("Url name shouldn't be blank!");
      return;
    }
    if (!linksource.source.trim()) {
      this.setError("Source tag shouldn't be blank!");
      return;
    }
    if (linkchanged) {
      this.setError("Source link changed. Save the change first!");
      return;
    }
    
    const new_slug = slugify(slug.trim().toLowerCase());
    const validSlug = await this._checkValidSlug(selected_source.id, new_slug);
    if (!validSlug) {
      return;
    }

    this.updateSource();
  };

  updateSource = async () => {
    const {
      image, 
      imageUpload, 
      name, 
      description,
      slug,
      linksource 
    } = this.state;
    const {
      selected_feed,
      selected_source, 
      firebase 
    } = this.props;

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    let imageUrl = image;
    let thumb_image = image; 
    if (imageUpload) {
      const flag = true;
      const resized_image = await resizeImageFile(imageUpload, flag);
      const uploadPromises = resized_image.map(async (image, index) => {
        const result = await firebase.uploadImage(image, "sources");
        console.log(`Uploaded image ${index}:`, result);
        return result;
      });
      const uploadResults = await Promise.all(uploadPromises);
      console.log("Upload results:", uploadResults);
      if (uploadResults.some((result) => result.error)) {
        this.setError("Failed to upload source image.");
        return;
      }

      thumb_image = uploadResults[0].url;
      imageUrl = uploadResults[1].url;
      const modresult = await moderate_image(imageUrl);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError("Image not allowed, because it contains adults or racy content.");
        await firebase.deleteImage(imageUrl);
        await firebase.deleteImage(thumb_image);
        // return;
        imageUrl = "";
        thumb_image = "";
      }
    }

    const updated_socialtag = {
      id: selected_source.id,
      type: linksource.socialtype,
      tag: linksource.source
    };

    // this is a new source, so add it
    const updated_source = {
      id: selected_source.id,
      category_id: selected_source.category_id,
      name: name.trim(),
      description: description.trim(),
      slug: slugify(slug.trim().toLowerCase()),
      image: imageUrl,
      thumbnail: thumb_image,
      branch: linksource.branch,
      weblink: linksource.weblink,
      translate: selected_source.translate,
      translateLang: selected_source.translateLang,
      translateAPI: selected_source.translateAPI,
      disableFullText: selected_source.disableFullText,
      followers: selected_source.followers,
      created_by: selected_source.created_by,
      created_at: selected_source.created_at,
      approved: true
    };
  
    await gqlservice
      .update_source(updated_source)
      .then(result => {
        return gqlservice.update_socialtag(updated_socialtag);
      })
      .then(result => {
        return gqlservice.source_by_id(updated_source.id);
      })
      .then(result => {
        const sources = result.data.sources;
        if (sources.length > 0) {
          this.props.updateSource(sources[0]);
        }
        return gqlservice.feed_by_id(selected_feed.id);
      })
      .then(result => {
        const feeds = result.data.feeds;
        if (feeds.length > 0) {
          this.props.selectFeed(feeds[0]);
        }
        // request_crawlsource(updated_source);
      }, (reason) => {
        this.setError(reason.msg);
        return;
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        return;
      });

    await updateCache();

    this.setWaiting(false);
    this.handleNavBack();
  };

  render() {
    const { classes, theme_mode, socialtypes, requesting } = this.props;
    const {
      image,
      linkImage,
      name,
      description,
      slug,
      linksource,
      linkchanged,
      showConfirmDlg
    } = this.state;

    if (!socialtypes || !linksource) {
      return <div style={{display: "none"}}></div>;
    }

    let source_image = image;
    if (image === RAVEN_PLACEHOLDER_IMAGE) {
      if (!linkImage) {
        source_image = RAVEN_PLACEHOLDER_IMAGE;
      } else {
        source_image = linkImage;
      }      
    }

    // enable apply button
    let apply_enabled = false;
    if (source_image !== RAVEN_PLACEHOLDER_IMAGE && name && linksource.source && !linkchanged) {
      apply_enabled = true;
    }

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;

    // source name & description width
    let text_width = width > MAX_ARTICLE_WIDTH ? MAX_ARTICLE_WIDTH - 156 : width - 156; // image selector(116), padding(32)

    const confirmButtonPos = (width - 260) / 2;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Edit Source"}
            onNavBack={this.handleNavBack}
          />
        </div>

        <Box className={classes.container}>
          <Box>
            <div className={classes.sourceItem}>
              <ImageSelector
                image={source_image}
                imageHandleChanged={this.handleImageChange}
              />
              <Box className={classes.detail}>
                <input
                  name="name"
                  placeholder="Name"
                  value={name || ""}
                  className={classes.name}
                  style={{width: text_width}}
                  onChange={this.handleChange}
                />
                <textarea
                  name="description"
                  placeholder="Description"
                  value={description || ""}
                  className={classes.description}
                  style={{width: text_width}}
                  onChange={this.handleChange}
                />
              </Box>
            </div>
          </Box>
          <div className={classes.sourceurlcontainer}>
            <Typography className={classes.sourceurlprefix}>
              Url Name: {`/${ROUTES.SOURCE_PREFIX}/`}
            </Typography>
            <input
              className={classes.sourceurlinput}
              name="slug"
              value={slug || ""}
              onChange={this.handleChange}
            />
          </div>
          <Box>
            <SourceLink
              editonly
              selected={true}
              theme={theme_mode}
              socialtypes={socialtypes}
              link={linksource}
              onApply={this.handleApplyLink}
              onChanged={this.handleChangedSourceLink}
            />
          </Box>
          <Box className={classes.applycontainer}>
            {apply_enabled && 
              <Button
                className={classes.applybtn}
                style={{left: confirmButtonPos}}
                onClick={this.handleApply}
              >
                Update Source
              </Button>
            }
            {!apply_enabled && 
              <Button
                className={classes.applybtn_disabled}
                style={{left: confirmButtonPos}}
              >
                Update Source
              </Button>
            }
          </Box>
        </Box>
        <DlgConfirm
          open={showConfirmDlg}
          title={"Update Source"}
          content={"Do you really want to update this source?"}
          onOK={this.handleSubmitSource}
          onCancel={this.handleCancel}
        />
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  socialtypes: state.dataState.socialtypes,
  selected_category: state.dataState.selected_category,
  selected_feed: state.dataState.selected_feed,
  selected_source: state.dataState.selected_source,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withRouter,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SourceEdit);
