import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  // Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LinkPreview } from "components";
import { get_timestring, decodeHTMLEntities, render_text } from "utility/utils";
import {
  BLANK_USER_IMAGE,
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  THEME_MODE_DARK,
  THEME_MODE_LIGHT,
} from "constants/types";
import { logger } from "utility/logging";

const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 10,
    borderTopLeftRadius: 30,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.default,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  share: {
    position: "absolute",
    top: 12,
    right: 12,
    padding: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    backgroundColor: "#7289DA",
    zIndex: 100,
  },
  shareicon: {
    width: 16,
    height: 16,
  },
  pin: {
    position: "absolute",
    top: 12,
    right: 40,
    padding: 0,
    width: 16,
    height: 16,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    zIndex: 100,
  },
  expand: {
    position: "absolute",
    top: 28,
    right: 10,
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 48,
    height: 48,
    cursor: "pointer",
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  socialimg: {
    position: "absolute",
    top: 52,
    left: 16,
    width: 20,
    height: 20,
  },
  upvotebtn: {
    position: "absolute",
    top: 100,
    left: 12,
    width: 20,
    height: 20,
    zIndex: 100,
  },
  buttonimg: {
    width: 20,
    height: 20,
  },
  usertype: {
    position: "absolute",
    top: 76,
    left: 8,
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 600,
    color: "#1878F3",
  },
  titleline: {
    position: "relative",
    marginLeft: 40,
    marginRight: 40,
    margin: 10,
  },
  author: {
    fontFamily: "Arial",
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: 600,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  title: {
    marginTop: 4,
  },
  feed: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontStyle: "Italic",
    lineHeight: "18px",
    marginTop: 4,
    marginBottom: 4,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  date: {
    float: "right",
    fontSize: "12px",
    color: theme.palette.text.secondary,
    marginTop: 4,
  },
  content: {
    paddingTop: 4,
    paddingBottom: 4,
    marginLeft: 40,
    marginRight: 10,
    minHeight: 60,
    marginBottom: 8,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  detail_txt: {
    display: "inline",
    overflowWrap: "break-word",
  },
  media: {
    display: "block",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(1),
  },
  actionbtn: {
    left: "50%",
    transform: "translate(-50%)",
    fontSize: 12,
    textTransform: "none",
    padding: 0,
    marginBottom: 4,
    color: theme.palette.text.primary,
  },
  actiondiv: {
    marginLeft: 16,
  },
  actionimg: {
    float: "left",
    width: 16,
    height: 16,
  },
  actionvalue: {
    float: "left",
    marginLeft: 5,
    fontFamily: "Arial",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  translated_div: {
    marginLeft: 10,
  },
  traslated_icon: {
    float: "left",
    top: 3,
    marginLeft: 5,
    width: 16,
    height: 16,
    color: theme.palette.text.primary,
  },
  translated_txt: {
    display: "inline",
    marginLeft: 2,
    fontSize: 12,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  actions: {
    padding: 0,
    margin: 0,
  },
  repostbtn: {
    width: 20,
    height: 20,
    zIndex: 100,
  },
  commentbtn: {
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20,
    zIndex: 100,
  },
  comment: {
    marginLeft: theme.spacing(0.5),
    fontSize: "14px",
    fontStyle: "Roboto",
  },
  biasBanner:{
    width: "100%",
    borderRadius: 25,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.default,
    padding: "8px",
    display: "flex",
    textAlign: "center",
    color: "orange",
    fontSize: '15px',
    letterSpacing: '0.05px',
    fontWeight: "bold",
  },
});

class ProfilepostCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickShare = this.handleClickShare.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleClickShare = () => {
    const { article } = this.props;
    this.props.onShare(article.nid);
  };

  handleExpand = (event) => {
    this.props.onAnchorEl(event.currentTarget);
  };

  render() {
    const {
      classes,
      theme_mode,
      loggedIn,
      authUser,
      selected_user,
      article,
      handleClick,
      onClickUpvote,
      onClickComment,
      onClickRepost,
    } = this.props;

    const sentiment = article.political_sentiments ? JSON.parse(article.political_sentiments) : null;
    let bannerColor = "green"; // Default color for scores below 25

    if (sentiment) {
      if (sentiment.Score >= 26 && sentiment.Politics === "Right") {
        bannerColor = "red";
      } else if (sentiment.Score >= 26 && sentiment.Politics === "Left") {
        bannerColor = "orange";
      }
    }
    let wingBias = "Balanced";
    if (sentiment) {
      if (sentiment.Politics === "Left" || sentiment.Politics === "Right") {
        wingBias = `${sentiment.Politics} Wing Bias`;
      }
    }
    // check validity
    const posted_uid = article.txt_param1;
    if (posted_uid !== authUser?.uid && posted_uid !== selected_user?.uid) {
      logger.error("Error, Invalid user id: ", posted_uid);
      return <div style={{display: "none"}}></div>;
    }

    // voted, reposted by me
    let voted = false;
    let reposted = false;
    if (loggedIn && authUser) {
      voted =
        authUser.articles_voted.find(
          (voted) => voted.article_id === article.nid
        ) !== undefined;
      reposted =
        authUser.articles_reposted.find(
          (reposted) => reposted.article_id === article.nid
        ) !== undefined;
    }

    const class_usertype = {
      position: "absolute",
      top: 76,
      left: 8,
      fontFamily: "Arial",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 600,
      color: theme_mode === THEME_MODE_LIGHT ? "#1878F3" : "white",
    };

    // moderator
    const isModerator = article.param2 === 1;
    const social_image = isModerator
      ? `/static/images/icons/${theme_mode}/moderator.png`
      : `/static/images/icons/${theme_mode}/members.png`;

    // author
    let author = article.author;
    let avatar_image = article.author_image;
    if (!avatar_image) {
      avatar_image = BLANK_USER_IMAGE;
    }

    // publish time
    let published = get_timestring(article.published);

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    width -= 16;
    if (width > MAX_CARD_WIDTH - 16) width = MAX_CARD_WIDTH - 16;
    if (width < MIN_CARD_WIDTH - 16) width = MIN_CARD_WIDTH - 16;

    // link preview
    let preview = null;
    if (
      article.link_preview
      // article.link_preview.title && article.link_preview.image
    ) {
      preview = article.link_preview;
    }

    // get comments
    let commentsCnt = 0;
    for (let thread of article.threads) {
      commentsCnt += thread.comments_aggregate.aggregate.count;
    }

    return (
      <Card
        className={classes.card}
        style={{ width: width, backgroundColor: classes.card.backgroundColor }}
      >
        <div className={classes.carddiv}>
          <CardHeader
            className={classes.header}
            avatar={
              <div>
                <Avatar
                  alt={author}
                  src={avatar_image}
                  className={classes.avatar}
                  classes={{ img: classes.avatar_img }}
                />
                <LazyLoadImage
                  alt={"user"}
                  src={social_image}
                  className={classes.socialimg}
                />
                <Typography style={class_usertype}>
                  {isModerator ? "MOD" : "USER"}
                </Typography>
              </div>
            }
            title={
              <div className={classes.titleline}>
                <Typography
                  className={classes.author}
                >
                  {decodeHTMLEntities(article.author)}
                </Typography>
                <Typography
                  className={classes.title}
                  variant="subtitle2"
                  onClick={(e) => handleClick(article)}
                >
                  {render_text(article.title, theme_mode === THEME_MODE_DARK)}
                </Typography>
                <Typography className={classes.date} data-cy="userpostTime" data-cy-time={article.published}>{published}</Typography>
              </div>
            }
          />
          <div>
            <IconButton className={classes.upvotebtn} onClick={onClickUpvote}>
              <img
                alt={"upvote"}
                src={
                  voted
                    ? `/static/images/icons/thumbsup.png`
                    : `/static/images/icons/${theme_mode}/thumbsup.png`
                }
                className={classes.buttonimg}
              />
            </IconButton>
          </div>

          <div>
            {article.pinned !== undefined && (
              <img
                alt={"pin"}
                className={classes.pin}
                src={`/static/images/icons/${theme_mode}/pin.png`}
              />
            )}
            <IconButton
              className={classes.share}
              onClick={this.handleClickShare}
            >
              <ShareIcon className={classes.shareicon} />
            </IconButton>
            <IconButton className={classes.expand} onClick={this.handleExpand}>
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <CardContent
            className={classes.content}
            onClick={(e) => handleClick(article)}
          >
            <Typography className={classes.detail_txt} variant="body2">
              {render_text(article.summary, theme_mode === THEME_MODE_DARK)}
            </Typography>
          </CardContent>
          {article.image && (
            <LazyLoadImage
              alt={""}
              src={article.image}
              className={classes.media}
              onClick={(e) => handleClick(article)}
            />
          )}
          {preview !== null && <LinkPreview preview={preview} />}
          <CardActions className={classes.actions}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
              >
                {sentiment ?
                  (<div style={{ marginLeft: 44 }}>
                    <Typography className={classes.biasBanner} style={{ color: bannerColor }}>{`${wingBias === 'Balanced' ? 'Balanced' : `${sentiment.Score}% ${wingBias}`}`}</Typography>
                  </div>) : null}
              </Grid>
              <Grid item>
              </Grid>
              <Grid item>
                <div style={{ marginRight: 16 }}>
                  <IconButton
                    className={classes.repostbtn}
                    onClick={onClickRepost}
                  >
                    <img
                      alt={"repost"}
                      src={
                        reposted
                          ? `/static/images/icons/repost.png`
                          : `/static/images/icons/${theme_mode}/repost.png`
                      }
                      className={classes.actionimg}
                    />
                  </IconButton>
                  <IconButton
                    className={classes.commentbtn}
                    onClick={onClickComment}
                  >
                    <img
                      alt={"comment"}
                      src={`/static/images/icons/${theme_mode}/chat.png`}
                      className={classes.actionimg}
                    />
                  </IconButton>
                  {commentsCnt > 0 && (
                    <span
                      className={classes.comment}
                    >{`${commentsCnt} Comments`}</span>
                  )}
                </div>
              </Grid>
            </Grid>
          </CardActions>
        </div>
      </Card>
    );
  }
}

ProfilepostCard.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
  handleClick: PropTypes.func,
  onAnchorEl: PropTypes.func,
  onShare: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  selected_user: state.dataState.selected_user,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProfilepostCard));
