import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Menu, IconButton } from "@material-ui/core";
import MetaTags from "react-meta-tags";
import { withAuthentication } from "session";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ToastContainer } from "react-toastify";
import { get_cached_item } from "utility/cachemanager";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  SearchAppBar,
  FollowFeedsList,
  MyFeedsList,
  DlgAlert,
  DlgModerate,
  DlgLoginConfirm,
  TagChips,
  PopMenuCategory,
  WaitingSpinner,
} from "components";
import { CategoryDetail, AlphabetToolbar } from "./components";
import * as ROUTES from "constants/routes";
import { withFirebase } from "services";
import { GraphqlService, createUserSubscriber } from "services";
import { v4 as uuidv4 } from "uuid";
import { ACTIVITY_TYPE_CATEGORY, ACTIVITY_APPLY } from "constants/activity";
import {
  MAX_WINDOW_WIDTH,
  MAX_ARTICLE_WIDTH,
  MIN_CARD_WIDTH,
} from "constants/types";
import { NOTIFICATION_CATEGORY_APPLY_MODERATOR } from "constants/notification";
import {
  getAuthToken,
  getMainInfo,
  hasPaid,
  isBanned,
  isBannedFeedCreation,
  followFeed,
  unfollowFeed,
  isModeratorOfCategory,
  getCategoryFeeds,
} from "dataapis";
import { ToastSuccess, ToastError } from "utility/toast";
import { logger } from "utility/logging";
import { copy2clipboard } from "utility/utils";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  category: {
    margin: theme.spacing(2),
  },
  coverImage: {
    objectFit: "cover",
    marginBottom: theme.spacing(1),
    margin: 0,
    // [theme.breakpoints.down('xs')]: {
    //   width: "100%",
    // },
    // backgroundSize: "100%",
    borderRadius: 20,
  },
  chips: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    margin: 0,
  },
  expand: {
    float: "right",
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: 4,
    width: 24,
    height: 24,
    color: theme.palette.text.primary,
  },
  alphabettoolbar: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  feeds: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.background.default,
  },
  addbutton: {
    position: "fixed",
    bottom: theme.spacing(2),
    zIndex: 1200,
  },
  addbuttonimg: {
    width: 60,
    height: 60,
  },
  bottomspace: {
    paddingBottom: theme.spacing(10),
  },
});

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.userConsumer = null;

    this.state = {
      searchKey: "",
      alphabetKey: "All",
      anchorEl: null,
      moderateDlg: false,
      alertDlg: false,
      alertTitle: "",
      alertMsg: "",
      loginDlg: false,
      selectedTags: [],
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.showModerateDlg = this.showModerateDlg.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.handleModerate = this.handleModerate.bind(this);
    this.handleCancelModerate = this.handleCancelModerate.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);
    this.handleOK = this.handleOK.bind(this);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleChangeAlphabet = this.handleChangeAlphabet.bind(this);

    this.handleChangeFollowing = this.handleChangeFollowing.bind(this);
    this.handleReachLimit = this.handleReachLimit.bind(this);
    this.handleChipSelect = this.handleChipSelect.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleAddFeed = this.handleAddFeed.bind(this);
  }

  componentDidMount = async () => {
    const { selected_category } = this.props;

    this.setWaiting(true);

    await getMainInfo();

    this.setWaiting(false);

    const category_id = this.props.match.params.id;
    logger.log("Category id :", category_id);
    console.log("the selected category " ,!selected_category)

    if (!selected_category) {
      // browse this category url directly (by category link)
      const curCategory = this.props.categories.find(
        (category) => category.id === category_id
      );
      if (!curCategory) {
        const location = {
          pathname: ROUTES.HOME,
          state: { animation: "right" },
        };
        this.props.history.push(location);
        return;
      }
      this.props.selectCategory(curCategory);
    }

    // go to the moderation page if the user is the moderator of this category
    if (isModeratorOfCategory(category_id)) {
      logger.log("Moderator of this Category!!!");
      const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CATEGORY_PREFIX}/${category_id}`;
      const location = {
        pathname: route,
        state: { animation: "left" },
      };
      this.props.history.push(location);
    }

    await Promise.all([
      getCategoryFeeds(category_id),
      this.registerSubscribers(),
    ]);
  };

  componentDidUpdate = (prevProps) => {
    const { selected_category, authUser, category_backroute } = this.props;
    if (!selected_category || !authUser) {
      return;
    }
    if (prevProps.authUser.uid !== this.props.authUser.uid) {
      this.fetchDataForAuthUser()
    }
    const category_moderated = authUser.categories_moderated.find(
      (item) => item.category_id === selected_category.id
    );
    const prev_category_moderated =
      prevProps.authUser.categories_moderated.find(
        (item) => item.category_id === selected_category.id
      );
    if (!category_moderated || !prev_category_moderated) {
      return;
    }

    if (prev_category_moderated.approved !== category_moderated.approved) {
      // go to the previous page if the category moderation is changed
      if (isModeratorOfCategory(selected_category.id)) {
        const location = {
          pathname: category_backroute,
          state: { animation: "left" },
        };
        this.goTo(location);
      }
    }
  };

  fetchDataForAuthUser = async () => {
    const { loggedIn, authUser } = this.props; 
    if (!loggedIn) {
      return;
    }
      let base_data = null;
      let following_feeds = [];
      const cache_name = loggedIn
        ? hasPaid()
          ? "base_data_paiduser_v1"
          : "base_data_user_v1"
        : "base_data_anonymous_v1";
        base_data = await get_cached_item(cache_name);
        if (base_data) {
          if (authUser.feeds_followed.length > 0) {
            following_feeds =
              authUser
                .feeds_followed
                .sort((a, b) => a.order - b.order)
                .map(followed => base_data.feeds.find(feed => followed.feed_id === feed.id))
                .filter(feed => feed !== undefined);
          }
      }
      this.props.setFollowingFeeds(following_feeds);
  }

  setError = (message) => {
    this.setState({
      ...this.state,
      moderateDlg: false,
    });
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  registerSubscribers = () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return;
    }

    const userSubscriber = createUserSubscriber(authUser.uid);
    this.userConsumer = userSubscriber.subscribe(
      (data) => {
        const user = data.data.users[0];
        logger.log("user updated :", user);
        this.props.setAuthUser(user);
      },
      (err) => {
        let msg = "Error subscribing user: " + err.message;
        logger.error(msg);
      }
    );
  };

  unregisterSubscribers = () => {
    if (this.userConsumer) {
      this.userConsumer.unsubscribe();
      this.userConsumer = null;
    }
  };

  goTo = (location) => {
    this.unregisterSubscribers();
    this.props.history.push(location);
  };

  getCategoryTags() {
    const { selected_category, feeds } = this.props;
    const { searchKey } = this.state;

    let cat_feeds = feeds.filter(
      (feed) => feed.category_id === selected_category.id
    );
    if (cat_feeds.length === 0) {
      return [];
    }

    // get distinct tags and their occurence
    let tags = [];
    for (let feed of cat_feeds) {
      for (let feed_tag of feed.tags) {
        let exist_tag = tags.find((tag) => tag.name === feed_tag);
        if (!exist_tag) {
          tags.push({
            name: feed_tag,
            count: 1,
          });
        } else {
          exist_tag.count += 1;
        }
      }
    }

    // sort the tags according to the follower count(user count)
    tags.sort((a, b) => b.count - a.count);

    if (searchKey) {
      let key = searchKey.toLowerCase();
      tags = tags.filter((tag) => tag.name.toLowerCase().includes(key));
    }

    // get first 10 tags
    const selected_tags = tags.slice(0, 10).map((tag) => tag.name);

    return selected_tags;
  }

  handleSearchChange = (searchKey) => {
    this.setState({
      ...this.state,
      searchKey: searchKey,
    });
  };

  handleSearchEnter = (searchKey) => {};

  handleNavBack = () => {
    const { category_backroute } = this.props;
    const location = {
      pathname: category_backroute,
      state: { animation: "right" },
    };
    this.goTo(location);
  };

  handleChangeAlphabet = (alphabet) => {
    this.setState({
      ...this.state,
      alphabetKey: alphabet,
    });
  };

  handleExpand = async (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleCopyLink = () => {
    copy2clipboard(window.location.href);
    ToastSuccess("Copied to clipboard");
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  showModerateDlg = () => {
    const { loggedIn } = this.props;

    if (loggedIn) {
      this.setState({
        ...this.state,
        moderateDlg: true,
        loginDlg: false,
        anchorEl: null,
      });
    } else {
      this.setState({
        ...this.state,
        moderateDlg: false,
        loginDlg: true,
        anchorEl: null,
      });
    }
  };

  handleModerate = async () => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { authUser, selected_category } = this.props;
    const isModerated =
      authUser.categories_moderated.find(
        (item) => item.category_id === selected_category.id
      ) !== undefined;
    if (isModerated) {
      this.setError("This category was already moderated");
      return;
    }

    this.setWaiting(true);

    let moderator = {
      id: uuidv4(),
      category_id: selected_category.id,
      user_id: authUser.uid,
    };

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);
    await gqlservice
      .insert_category_moderator(moderator)
      .then(
        (result) => {
          this.props.addCategoryModerated(selected_category);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // insert notification
    const notification = {
      type: NOTIFICATION_CATEGORY_APPLY_MODERATOR,
      object: selected_category.id,
      in_which: selected_category.id,
      to: selected_category.created_by,
      created_by: authUser.uid,
    };
    await gqlservice
      .insert_notification(notification)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_CATEGORY,
      type_id: selected_category.id,
      action: ACTIVITY_APPLY,
      object: "for moderator",
      fromto: `to the category ${selected_category.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setState({
      ...this.state,
      moderateDlg: false,
    });

    this.setWaiting(false);
  };

  handleCancelModerate = () => {
    this.setState({
      ...this.state,
      moderateDlg: false,
    });
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.goTo(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  handleOK = () => {
    this.setState({
      ...this.state,
      alertDlg: false,
      alertTitle: "",
      alertMsg: "",
    });
  };

  handleMenuClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleReachLimit = () => {
    if (!hasPaid()) {
      const location = {
        pathname: ROUTES.MEMBERSHIP,
        state: { animation: "bottom" },
      };
      this.goTo(location);
    } else {
      this.setState({
        ...this.state,
        alertDlg: true,
        alertTitle: "Follow Limit",
        alertMsg:
          "You've already reached the limit to follow. Unfollow some feeds to follow another feeds.",
      });
    }
  };

  handleChangeFollowing = async (following, index) => {
    // get feeds corresponding to the following (refer to render)
    let feeds2show = this.getFeeds2show();
    if (feeds2show.length === 0) {
      return;
    }

    this.setWaiting(true);
    let res = null;
    if (following[index]) {
      await followFeed(feeds2show[index]);
    } else {
      res = await unfollowFeed(feeds2show[index]);
    }
    if(res === false){
      return
    }

    this.setWaiting(false);
  };

  handleChipSelect = (name) => {
    const { selectedTags } = this.state;
    let new_selectedTags = selectedTags.slice();
    let selectTag = selectedTags.find((tag) => tag === name);
    if (!selectTag) {
      // select
      new_selectedTags.push(name);
    } else {
      // unselect
      new_selectedTags = selectedTags.filter((tag) => tag !== name);
    }

    this.setState({
      ...this.state,
      selectedTags: new_selectedTags,
    });
  };

  handleClickFeed = (feed) => {
    this.props.selectFeed(feed);

    let path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);

    const { selected_category } = this.props;
    const category_path = `/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}`;
    this.props.setFeedBackRoute(category_path);
  };

  searchFeeds(feeds, searchKey) {
    var result = [];
    var key = searchKey.toLowerCase();
    feeds.forEach((feed) => {
      if (
        !feed.private &&
        feed.approved &&
        (feed.name.toLowerCase().includes(key) ||
          feed.description.toLowerCase().includes(key))
      ) {
        result.push(feed);
      }
    });
    return result;
  }

  selectFeedsByLetter = (feeds, letter) => {
    let result = [];
    let key = letter.toLowerCase();
    feeds.forEach((feed) => {
      if (!feed.private && feed.approved) {
        let feedname = feed.name.toLowerCase();
        if (feedname[0] === key) {
          result.push(feed);
        }
      }
    });
    return result;
  };

  getFeeds2show = () => {
    const { selected_category, feeds } = this.props;
    const { searchKey, selectedTags, alphabetKey } = this.state;

    let feeds2show = [];
    const category_feeds = feeds.filter(
      (feed) =>
        feed.category_id === selected_category.id &&
        feed.approved &&
        !feed.private
    );
    if (category_feeds.length === 0) {
      return [];
    }

    if (selectedTags.length > 0) {
      feeds2show = category_feeds.filter((feed) => {
        for (let feed_tag of feed.tags) {
          if (selectedTags.includes(feed_tag)) {
            return true;
          }
        }
        return false;
      });
    } else {
      feeds2show = category_feeds.slice();
    }

    if (searchKey) {
      return this.searchFeeds(feeds2show, searchKey);
    }
    if (alphabetKey !== "All") {
      return this.selectFeedsByLetter(feeds2show, alphabetKey);
    }
    return feeds2show;
  };

  handleAddFeed = () => {
    if (isBannedFeedCreation()) {
      ToastError("You've suspended for feed creation.");
      return;
    }

    const location = {
      pathname: ROUTES.FEED_ADD,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  getMyFeeds = () => {
    const { feeds, loggedIn, authUser, selected_category } = this.props;
    if (!loggedIn) {
      return [];
    }

    const myFeeds = feeds.filter(
      (feed) =>
        feed.created_by === authUser.uid &&
        feed.category_id === selected_category.id
    );
    return myFeeds;
  };

  render() {
    const {
      classes,
      newssites,
      selected_category,
      followed_feeds,
      loggedIn,
      authUser,
      theme_mode,
      requesting,
    } = this.props;
    const {
      alphabetKey,
      anchorEl,
      moderateDlg,
      alertDlg,
      alertTitle,
      alertMsg,
      loginDlg,
      selectedTags,
    } = this.state;

    if (newssites.length === 0 || selected_category === null) {
      return <div style={{display: "none"}}></div>;
    }

    // cover image
    let hasCoverImage = false;
    if (selected_category.coverImage) {
      hasCoverImage = true;
    }

    // alphabets
    const alphabets = ["All"].concat("ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""));

    // tags
    const tags = this.getCategoryTags();

    // moderation pending
    let pending = false;
    if (loggedIn) {
      const category = authUser.categories_moderated.find(
        (category_moderated) =>
          category_moderated.category_id === selected_category.id
      );
      if (category) {
        pending = category.approved === false;
      }
    }

    // feeds
    const feeds2show = this.getFeeds2show();
    // following
    let following = [];
    if (loggedIn) {
      following = feeds2show.map((feed) => {
        let result = followed_feeds?.find((element) => element.id === feed.id);
        return result === undefined ? false : true;
      });
    }
    // my feeds
    const myfeeds = this.getMyFeeds();

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.CATEGORY_PREFIX}/${selected_category.id}`;

    // let shareInfo = {
    //   title: 'Raven Category: ' + selected_category.name,
    //   description: selected_category.description,
    //   image: selected_category.image,
    //   hashtag: tags.join(),
    //   url: shareUrl
    // };

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_WINDOW_WIDTH) {
      width = MAX_WINDOW_WIDTH;
    } else if (width < MIN_CARD_WIDTH) {
      width = MIN_CARD_WIDTH;
    }
    const height = width > MAX_ARTICLE_WIDTH ? 300 : 200;

    let addbuttonPos = 0;
    if (window.innerWidth > MAX_WINDOW_WIDTH) {
      addbuttonPos = (window.innerWidth + MAX_WINDOW_WIDTH) / 2 - 96;
    } else {
      addbuttonPos = width - 96;
    }

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven: ${selected_category.name}`}</title>
            <meta name="description" content={selected_category.description} />
            <meta
              property="og:title"
              content={`Raven: ${selected_category.name}`}
            />
            <meta
              property="og:description"
              content={selected_category.description}
            />
            <meta property="og:image" content={selected_category.image} />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={shareUrl} />
            <meta
              property="twitter:title"
              content={`Raven: ${selected_category.name}`}
            />
            <meta property="twitter:site" content="Raven App" />
            <meta
              property="twitter:description"
              content={selected_category.description}
            />
            <meta
              property="twitter:image:src"
              content={selected_category.image}
            />
            <meta
              property="twitter:image:alt"
              content={selected_category.name}
            />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar}>
          <SearchAppBar
            title={selected_category.name}
            onSearchChange={this.handleSearchChange}
            onSearchEnter={this.handleSearchEnter}
            onNavBack={this.handleNavBack}
          />
        </div>
        {!hasCoverImage && (
          <div className={classes.category}>
            <CategoryDetail
              title={selected_category.name}
              description={selected_category.description}
              image={selected_category.image}
            />
          </div>
        )}
        {hasCoverImage && (
          <div onClick={this.handleCopyLink}>
            <LazyLoadImage
              className={classes.coverImage}
              style={{ width: width, height: height }}
              alt={selected_category.name}
              src={selected_category.coverImage}
            />
          </div>
        )}
        <div className={classes.chips}>
          <IconButton className={classes.expand} onClick={this.handleExpand}>
            <ExpandMoreIcon />
          </IconButton>
          {tags.length > 0 && (
            <TagChips
              chips={tags}
              selected={selectedTags}
              onSelect={this.handleChipSelect}
            />
          )}
        </div>
        <div className={classes.alphabettoolbar}>
          <AlphabetToolbar
            alphabets={alphabets}
            selected={alphabetKey}
            onChangeAlphabet={this.handleChangeAlphabet}
          />
        </div>
        <div className={classes.feeds}>
          <FollowFeedsList
            items={feeds2show}
            badged={false}
            following={following}
            onFollowingChanged={this.handleChangeFollowing}
            onItemClicked={this.handleClickFeed}
            onReachLimit={this.handleReachLimit}
          />
        </div>
        {myfeeds.length > 0 && (
          <div className={classes.feeds}>
            <MyFeedsList items={myfeeds} onItemClicked={this.handleClickFeed} />
          </div>
        )}
        <div className={classes.bottomspace}></div>
        <DlgAlert
          open={alertDlg}
          title={alertTitle}
          description={alertMsg}
          theme_mode={theme_mode}
          onOK={this.handleOK}
        />
        {loggedIn && (
          <Button
            className={classes.addbutton}
            style={{ left: addbuttonPos }}
            onClick={this.handleAddFeed}
          >
            <img
              className={classes.addbuttonimg}
              alt={"addfeed"}
              src={`/static/images/icons/${theme_mode}/add.png`}
            />
          </Button>
        )}
        <Menu
          id="category-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleMenuClose}
        >
          <PopMenuCategory
            theme={theme_mode}
            pending={pending}
            onCopyLink={this.handleCopyLink}
            onModerate={this.showModerateDlg}
          />
        </Menu>
        <DlgModerate
          open={moderateDlg}
          type={"category"}
          onModerate={this.handleModerate}
          onCancel={this.handleCancelModerate}
        />
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <ToastContainer />
        <WaitingSpinner open={requesting} />
      </div>
    );
  }
}

Category.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  categories: state.dataState.categories,
  selected_category: state.dataState.selected_category,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  category_backroute: state.uiState.category_backroute,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Category);
