import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

class PopMenuModerator extends React.Component {
  render() {
    const { classes, theme, owner, onReport, onMakeOwner } = this.props;

    return (
      <div>
        <MenuList className={classes.menu_list}>
          <MenuItem className={classes.menu_item} onClick={onReport}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"report"}
                src={`/static/images/icons/${theme}/warning.png`}
              />
            </span>
            Flag Moderator
          </MenuItem>
          {owner && (
            <MenuItem className={classes.menu_item} onClick={onMakeOwner}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"make owner"}
                  src={`/static/images/icons/${theme}/moderator.png`}
                />
              </span>
              Make Owner
            </MenuItem>
          )}
        </MenuList>
      </div>
    );
  }
}

PopMenuModerator.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  owner: PropTypes.bool,
  onReport: PropTypes.func,
  onMakeOwner: PropTypes.func
};

export default withStyles(styles)(PopMenuModerator);
