import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, IconButton, Menu } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  ARTICLE_BRANCH_TWITTER,
  get_branch,
  get_branch_name,
  is_weblink_branch,
} from "constants/branches";
import { 
  PopMenuSource, 
  DlgReport, 
  DlgLoginConfirm 
} from "components";
import * as ROUTES from "constants/routes";
import { GraphqlService } from "services";
import { v4 as uuidv4 } from "uuid";
import { ACTIVITY_TYPE_FEED, ACTIVITY_REPORT } from "constants/activity";
import {
  MIN_CARD_WIDTH,
  MAX_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
  FLAGGED_COMMENTS_POSTS,
} from "constants/types";
import { getAuthToken, isBanned, upvoteSource } from "dataapis";
import { build_source_link } from "utility/buildlink";
import { summarize_text, is_source_alive, copy2clipboard } from "utility/utils";
import { ToastSuccess, ToastError } from "utility/toast";
import { isFlaggedModerationByAi } from "utility/ravenapi";
import { getSourceReportForPast90days } from "dataapis/source";
import moment from "moment/moment";
import { handleReportWithLimit } from "utility/reportlimit";
import { updateReportLimit } from "dataapis/user";
// import { logger } from "utility/logging";

const styles = (theme) => ({
  root: {
    // height: '100%',
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16, // grid spacing(2)
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  root_disabled: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    opacity: 0.38,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  grid: {
    // justifyContent: "left",
    flexWrap: "inherit",
    backgroundColor: theme.palette.background.card,
    // margin: 3,
    borderRadius: 5,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  button: {
    margin: 0,
    marginRight: theme.spacing(1),
    padding: 0,
  },
  image: {
    objectFit: "cover",
    height: 80,
    width: 80,
    borderRadius: 10,
    margin: theme.spacing(1),
  },
  number: {
    position: "absolute",
    fontSize: "18px",
    fontWeight: 600,
    fontStyle: "italic",
    lineHeight: "24px",
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 100,
  },
  check: {
    position: "absolute",
    left: theme.spacing(0.5),
    top: theme.spacing(1),
    zIndex: 100,
  },
  triangle_corner: {
    position: "absolute",
    borderRight: "56px solid transparent",
    borderLeft: `56px solid ${theme.palette.background.default}`,
    borderBottom: "56px solid transparent",
    height: 0,
    width: 0,
    left: 0,
    top: 0,
    zIndex: 2,
  },
  expand: {
    position: "absolute",
    top: 0,
    right: theme.spacing(1),
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  titlediv: {
    marginTop: theme.spacing(1),
  },
  title: {
    position: "relative",
    display: "inline",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    textTransform: "none",
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  branchimage: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1) + 28,
    width: 16,
    height: 16,
  },
  aliveimage: {
    position: "absolute",
    top: theme.spacing(3.5),
    right: theme.spacing(1) + 30,
  },
  description: {
    position: "relative",
    textAlign: "left",
    fontSize: "12px",
    fontWeight: 100,
    lineHeight: "14px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1) + 36,
  },
  upvotesbtn: {
    position: "absolute",
    display: "block",
    paddingBottom: 12,
    padding: 0,
    minWidth: 32,
    top: 32,
    right: 8,
  },
  upvotemark: {
    height: 32,
    width: 32,
    color: theme.palette.text.secondary,
  },
  upvotes: {
    width: 36,
    fontSize: 14,
    fontWeight: 100,
    lineHeight: 0,
  },
});

// const chkstyles = (theme) => ({
//   root: {
//     height: 18,
//     color: theme.palette.text.secondary,
//     "&$checked": {
//       color: theme.palette.text.primary,
//     },
//     padding: 0,
//     marginLeft: 0,
//   },
//   checked: {},
// });

// const CustomCheckbox = withStyles(chkstyles)((props) => <Checkbox color="default" {...props} />);

class Source extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      reportDlg: false,
      loginDlg: false,
      // showCheck: false,
      unfollow: false,
      showrt: false,
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);

    this.showReportDlg = this.showReportDlg.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleCancelReport = this.handleCancelReport.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.handleShowSource = this.handleShowSource.bind(this);
    this.handleShowRetweet = this.handleShowRetweet.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.handleClick = this.handleClick.bind(this);
    // this.handleToggleShowCheck = this.handleToggleShowCheck.bind(this);
    // this.handleCheck = this.handleCheck.bind(this);
    this.handleUpvote = this.handleUpvote.bind(this);
    this.openSourceLink = this.openSourceLink.bind(this);
  }

  setError = (message) => {
    this.setState({
      ...this.state,
      reportDlg: false,
    });
    ToastError(message);
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount() {
    const { loggedIn, authUser, source, selected_feed } = this.props;

    if (!loggedIn) {
      this.setState({
        ...this.state,
        unfollow: false,
      });
      return;
    }

    const unfollowed_source = authUser.feed_sources_unfollowed.find(
      (item) =>
        item.feed_id === selected_feed.id && item.source_id === source.id
    );
    const showretweet_source = authUser.feed_source_showretweets.find(
      (item) =>
        item.feed_id === selected_feed.id && item.source_id === source.id
    );
    this.setState({
      ...this.state,
      unfollow: unfollowed_source !== undefined,
      showrt: showretweet_source !== undefined,
    });
  }

  handleExpand = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  showReportDlg = () => {
    this.handleCloseMenu();
    const { loggedIn } = this.props;
    if (loggedIn) {
      if (isBanned()) {
        ToastError("You've suspended.");
        return;
      }

      this.setState({
        ...this.state,
        reportDlg: true,
        loginDlg: false,
      });
    } else {
      this.setState({
        ...this.state,
        reportDlg: false,
        loginDlg: true,
      });
    }
  };

  handleReport =async (reportMsg) => {
    const message = reportMsg.trim();
    if (message === "") {
      this.setError("Report shouldn't be blank. Please input your report.");
      return;
    }

    const { authUser, source, selected_feed } = this.props;
    //reportlimit
    const updatedAuthUser = await handleReportWithLimit(authUser);
    if(!updatedAuthUser){
      ToastError("Report limit exceeded. Please try again later.");
      return null
    } 

    const isAiModerationEnabled = JSON.parse(selected_feed.ai_moderation) === null ? false :JSON.parse(selected_feed.ai_moderation).some((item)=> item === FLAGGED_COMMENTS_POSTS)
    if(isAiModerationEnabled){
      const currentDate = moment(); // Get the current date and time
      const date90DaysAgo = currentDate.subtract(90, 'days'); // Subtract 90 days from the current date
      console.log(date90DaysAgo.toISOString());
      const reportCount = await getSourceReportForPast90days(authUser.uid ,date90DaysAgo.toISOString())
      const topSources = this.getTopSources()
      const branch_name = get_branch_name(source.branch)
      const params ={
        name: selected_feed.name,
        description: selected_feed.description,
        userName: authUser.name,
        userBio: authUser.biography,
        userId:authUser.uid,
        userKarmaScore: authUser?.articles_voted?.length,
        topSources:topSources,
        userPostedSources:{
          postType : "source",
          name:source?.name,
          description:source?.description,
          socialMediaType:branch_name,
          reportCount:reportCount?.data?.source_reports_aggregate?.aggregate?.count
        }
      }
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      try{
        this.setState({
          ...this.state,
          reportDlg: false,
          anchorEl:null
        });
        this.props.requestDataPending();
        await isFlaggedModerationByAi(params, token).then(
         async (result) => {
            if (result.status === 200) {
              if ((result.message.decision.toLowerCase()) !== "denied") {
                console.log("Response from ai :", JSON.stringify(result.message.reason));
                this.setError("This post can't be reported due to "+ JSON.stringify(result.message.reason));
                await updateReportLimit(updatedAuthUser)
                this.props.setAuthUser(updatedAuthUser);
                throw new Error('Approve'); // Throw an error to exit the Promise chain
              }
              }
          },
          (reason) => {
            this.setError(reason.msg);
            throw new Error('Error'); // Throw an error to exit the Promise chain
          }
        ).catch((err) => {
          this.setError(JSON.stringify(err.Error));
          throw new Error(err); // Throw an error to exit the Promise chain
        });
      
      }catch(err){
        console.log("The source is denied",err)
        return ;
      }
    }
    let report = {
      id: uuidv4(),
      source_id: source.id,
      report: message,
      reported_by: authUser.uid,
      approved: false,
    };

    const gqlservice = new GraphqlService();
    gqlservice.set_auth_jwt(authUser.token);
    gqlservice
      .insert_source_report(report)
      .then(
        (result) => {
          (async () => {
          if (result.data.insert_source_reports.affected_rows) {
            ToastSuccess(
              "Report success, wait for approvement by the feed moderator."
            );
          }
          await updateReportLimit(updatedAuthUser);
          this.props.setAuthUser(updatedAuthUser);
        })().catch((err) => {
          this.setError(JSON.stringify(err));
        });
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setState({
      ...this.state,
      reportDlg: false,
    });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_REPORT,
      object: `the source ${source.name}`,
      fromto: `of the feed ${selected_feed.name}`,
      reason: "",
    };
    gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
    
  };
  // For getting top sources
  getTopSources = () => {
    const { selected_feed  ,source} =this.props
    let topSources = [];
    let totalCount = selected_feed?.feed_sources?.length >= 9 ? 9 : selected_feed?.feed_sources?.length;
    for (let i = 0; i <= totalCount; i++) {
      const branch_name = get_branch_name(selected_feed?.feed_sources[i]?.source?.branch);
      if (selected_feed?.feed_sources[i] && selected_feed?.feed_sources[i].source) {
        if(selected_feed?.feed_sources[i] && selected_feed?.feed_sources[i].source_id !== source.id ){
          let new_top_source ={
            name:selected_feed?.feed_sources[i].source?.name ,
            description:selected_feed?.feed_sources[i].source?.description , 
            socialMediaType: branch_name
           }
           topSources.push(new_top_source);
        }else{
          totalCount ++

        }
      }
    }
    return topSources;
  }
  handleCancelReport = () => {
    this.setState({
      ...this.state,
      reportDlg: false,
    });
  };

  handleCopyLink = () => {
    const { selected_feed, source } = this.props;
    const url = `${document.location.origin}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    copy2clipboard(url);

    this.setState({
      ...this.state,
      anchorEl: null,
    });
    ToastSuccess("Copied to clipboard");
  };

  handleShowSource = () => {
    const { source } = this.props;
    const unfollow = !this.state.unfollow;
    this.setState({
      ...this.state,
      unfollow: unfollow,
      anchorEl: null,
    });
    this.props.onUnfollowed(source.id, unfollow);
  };

  handleShowRetweet = () => {
    const { source } = this.props;
    const showrt = !this.state.showrt;
    this.setState({
      ...this.state,
      showrt: showrt,
      anchorEl: null,
    });
    this.props.onShowRetweet(source.id, showrt);
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
    this.props.onNeedLogin();
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  handleClick = () => {
    this.props.onClicked(this.props.source.id);
  };

  // handleToggleShowCheck = () => {
  //   const showCheck = !this.state.showCheck;
  //   this.setState({
  //     ...this.state,
  //     showCheck: showCheck,
  //   });
  // };

  // handleCheck = (event) => {
  //   const { source } = this.props;
  //   const unfollow = !event.target.checked;
  //   this.setState({
  //     ...this.state,
  //     unfollow: unfollow,
  //   });
  //   this.props.onUnfollowed(source.id, unfollow);
  // };

  handleUpvote = async () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    const { source } = this.props;
    await upvoteSource(source);
    this.setWaiting(false);
  };

  openSourceLink = (source_url) => {
    if (!source_url) {
      return;
    }
    window.open(source_url, "_blank");
  };

  isStaticLink = (socialtag) => {
    if (
      socialtag.type === "youtube_static_link" ||
      socialtag.type === "twitter_static_link" ||
      socialtag.type === "tiktok_static_link" ||
      socialtag.type === "pinterest_static_link" ||
      socialtag.type === "website_static_link" ||
      socialtag.type === "podcast_channel_feed" ||
      socialtag.type === "rss_url_feed"
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { classes, source, order, loggedIn, authUser, theme_mode } =
      this.props;
    const { anchorEl, reportDlg, loginDlg, unfollow, showrt } = this.state;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    let branchimage = "";
    let branchname = "";
    let branchInfo = get_branch(source.branch);
    if (branchInfo !== undefined) {
      branchimage = `/static/images/icons/${theme_mode}/${branchInfo.image}`;
      branchname = branchInfo.name;
    }

    const status_color = is_source_alive(source) ? "yellowgreen" : "red";

    let refined_name = summarize_text(source.name, 30);
    let refined_description = summarize_text(source.description, 80);
    const classes_root = unfollow ? classes.root_disabled : classes.root;

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_CARD_WIDTH) width = MAX_CARD_WIDTH;
    if (width < MIN_CARD_WIDTH) width = MIN_CARD_WIDTH;
    width = width - 112;

    // source link
    let social_link = null;
    let is_static_link = false;
    const socialtag = source.socialtags[0];

    if (is_weblink_branch(source.branch)) {
      if (source.weblink) {
        social_link = source.weblink;
      }
    } else {
      // check if the source is static link, then open a new window
      if(socialtag){
        is_static_link = this.isStaticLink(socialtag);
      }
    }

    if (!social_link && socialtag) {
      if (socialtag.tag.includes("http:") || socialtag.tag.includes("https:")) {
        social_link = socialtag.tag;
      } else {
        social_link = build_source_link(socialtag.type, socialtag.tag);
      }
    }

    return (
      <div className={classes_root}>
        <Button className={classes.upvotesbtn} onClick={this.handleUpvote}>
          <ArrowDropUpIcon className={classes.upvotemark} />
          <Typography className={classes.upvotes}>{source.upvotes}</Typography>
        </Button>
        {(is_static_link && social_link) ? (
          <a target="_blank" rel="noopener noreferrer" href={social_link}>
            <img
              className={classes.branchimage}
              src={branchimage}
              alt={branchname}
            />
          </a>
        ) : (
          <img
            className={classes.branchimage}
            src={branchimage}
            alt={branchname}
          />
        )}
        <FiberManualRecordIcon
          className={classes.aliveimage}
          style={{ fontSize: 12, color: status_color }}
        />
        <Grid container className={classes.grid}>
          <div
            className={classes.triangle_corner}
            onClick={(e) =>
              is_static_link && social_link
                ? this.openSourceLink(social_link)
                : this.handleClick()
            }
          />
          <Typography
            className={classes.number}
            onClick={(e) =>
              is_static_link && social_link
                ? this.openSourceLink(social_link)
                : this.handleClick()
            }
          >
            {order + 1}
          </Typography>
          <IconButton className={classes.expand} onClick={this.handleExpand}>
            <ExpandMoreIcon />
          </IconButton>
          <Grid item>
            <Button
              className={classes.button}
              onClick={(e) =>
                is_static_link && social_link
                  ? this.openSourceLink(social_link)
                  : this.handleClick()
              }
            >
              <LazyLoadImage
                className={classes.image}
                src={source.thumbnail || source.image || RAVEN_PLACEHOLDER_IMAGE}
                alt={source.name}
              />
            </Button>
          </Grid>
          <div
            onClick={(e) => social_link ? this.openSourceLink(social_link) : null}
          >
            <Grid item>
              <div className={classes.titlediv} style={{ width: width - 80 }}>
                <Typography className={classes.title}>
                  {refined_name}
                </Typography>
              </div>
              <Typography
                className={classes.description}
                style={{ width: width - 60 }}
              >
                {refined_description}
              </Typography>
              <div style={{ width: width }}></div>
            </Grid>
          </div>
        </Grid>
        <Menu
          id="source-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleCloseMenu}
        >
          {source.branch === ARTICLE_BRANCH_TWITTER &&
          source.created_by === authUser.uid ? (
            <PopMenuSource
              theme={theme_mode}
              loggedIn={loggedIn}
              follow={!unfollow}
              showrt={showrt}
              onReport={this.showReportDlg}
              onCopyLink={this.handleCopyLink}
              onShow={this.handleShowSource}
              onShowRetweet={this.handleShowRetweet}
            />
          ) : (
            <PopMenuSource
              theme={theme_mode}
              loggedIn={loggedIn}
              follow={!unfollow}
              onReport={this.showReportDlg}
              onCopyLink={this.handleCopyLink}
              onShow={this.handleShowSource}
            />
          )}
        </Menu>
        <DlgReport
          open={reportDlg}
          title={"Report Source"}
          theme={theme_mode}
          onReport={this.handleReport}
          onCancel={this.handleCancelReport}
        />
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
      </div>
    );
  }
}

Source.propTypes = {
  classes: PropTypes.object,
  source: PropTypes.object,
  order: PropTypes.number,
  onClicked: PropTypes.func,
  onUnfollowed: PropTypes.func,
  onShowRetweet: PropTypes.func,
  onNeedLogin: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Source);
