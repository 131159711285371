import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Menu } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withAuthentication, withAuthorization } from "session";
import {
  BasicAppBar,
  CommentThreadCard,
  CommentModButtons,
  PopMenuThreadMod,
  DlgConfirm,
  DlgShareComment,
  WaitingSpinner
} from "components";
import { GraphqlService } from "services";
import { withFirebase } from 'services';
import { 
  MAX_ARTICLE_WIDTH,
  BANNED_TYPE_1D, 
  BANNED_TYPE_7D, 
  BANNED_TYPE_PERM, 
  THREAD_TYPE_FEED, 
} from "constants/types";
import { NOTIFICATION_THREAD_APPROVE } from "constants/notification";
import * as ROUTES from "constants/routes";
import { 
  banComments, 
  closeThread, 
  deleteAllComments, 
  deleteThread, 
  getAuthToken, 
  preapproveUser, 
  updateFeedNotifications 
} from "dataapis";
import { ToastInfo, ToastError } from "utility/toast";
import { logger } from "utility/logging";

const condition = (authUser) => !!authUser && authUser.uid !== ""


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    marginTop: theme.spacing(1),
  },
  threadcard: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actions: {
    margin: theme.spacing(2),
  },
});

class ThreadsPended extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pended: [],
      selectedthread: null,
      anchorEl: null,
      deleteDlg: false,
      shareDlg: false
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleAnchorEl = this.handleAnchorEl.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    
    this.handleApproveThread = this.handleApproveThread.bind(this);
    this.handleDeleteThread = this.handleDeleteThread.bind(this);
    this.handleBan1d = this.handleBan1d.bind(this);
    this.handleBan7d = this.handleBan7d.bind(this);
    this.handleBanPerm = this.handleBanPerm.bind(this);

    this.handleDeleteBan1d = this.handleDeleteBan1d.bind(this);
    this.handleDeleteBan7d = this.handleDeleteBan7d.bind(this);
    this.handleDeleteBanPerm = this.handleDeleteBanPerm.bind(this);
    this.handleCloseComments = this.handleCloseComments.bind(this);
    this.handleDeleteComments = this.handleDeleteComments.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleApproveUser = this.handleApproveUser.bind(this);

    this.handleCloseShare = this.handleCloseShare.bind(this);
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  }

  setInfo = (message) => {
    ToastInfo(message);
  }

  componentDidMount() {
    const { selected_feed } = this.props;

    const threads = selected_feed.threads.filter(thread => thread.approved !== true);

    this.setState({
      ...this.state,
      pended: threads,
    });
  }

  _getAuthToken = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    let token = authUser.token;
    if (Date.now() >= authUser.expiredTS) {
      const result = await this.props.firebase.refreshToken();
      if (result.error) {
        this.setError(result.msg);
        token = null;
      } else {
        token = result.token;
      }
    }
    return token;
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    this.props.history.goBack();
  }

  handleAnchorEl = (anchorEl, thread) => {
    this.setState({
      ...this.state,
      selectedthread: thread,
      anchorEl: anchorEl
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  }

  deleteThread = (thread_id) => {
    const { pended } = this.state;
    const new_pended = pended.filter(thread => thread.id !== thread_id);

    this.setState({
      ...this.state,
      pended: new_pended,
      anchorEl: null
    });
  }

  handleApproveThread = async (thread) => {
    const { selected_feed, authUser } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);
    const currentTime = new Date().toISOString();

    this.setWaiting(true);

    await gqlservice
      .approve_thread(thread.id, true, authUser.uid, currentTime)
      .then(result => {
        this.deleteThread(thread.id);
      }, reason => {
        this.setError(reason.msg);
        return;
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        return;
      });

    // insert notification
    const notification = {
      type: NOTIFICATION_THREAD_APPROVE,
      object: thread.id.toString(),
      in_which: selected_feed.id,
      to: thread.poster.uid,
      created_by: authUser.uid
    }

    gqlservice.set_auth_jwt(token, false);
    await gqlservice
      .insert_notification(notification)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    await updateFeedNotifications(selected_feed);

    this.setWaiting(false);
  }

  handleDeleteThread = async (thread) => {
    this.handleCloseMenu();

    this.setWaiting(true);
    const { selected_feed } = this.props;
    await deleteThread(thread);
    await updateFeedNotifications(selected_feed);
    this.setWaiting(false);

    this.deleteThread(thread.id);
  }

  handleBan1d = async (thread) => {
    this.handleCloseMenu();

    this.setWaiting(true);
    await banComments(thread.poster.uid, BANNED_TYPE_1D);
    this.setInfo(`User ${thread.poster.username} banned for a day!`);
    this.setWaiting(false);
  }

  handleBan7d = async (thread) => {
    this.handleCloseMenu();
    
    this.setWaiting(true);
    await banComments(thread.poster.uid, BANNED_TYPE_7D);
    this.setInfo(`User ${thread.poster.username} banned for 7 days!`);
    this.setWaiting(false);
  }

  handleBanPerm = async (thread) => {
    this.handleCloseMenu();
    
    this.setWaiting(true);
    await banComments(thread.poster.uid, BANNED_TYPE_PERM);
    this.setInfo(`User ${thread.poster.username} banned permanently!`);
    this.setWaiting(false);
  }

  handleDeleteBan1d = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    await this.handleDeleteThread(selectedthread);

    this.setWaiting(true);
    await banComments(selectedthread.poster.uid, BANNED_TYPE_1D);
    this.setInfo(`User ${selectedthread.poster.username} banned for a day!`);
    this.setWaiting(false);
  }

  handleDeleteBan7d = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }
    await this.handleDeleteThread(selectedthread);

    this.setWaiting(true);
    await banComments(selectedthread.poster.uid, BANNED_TYPE_7D);
    this.setInfo(`User ${selectedthread.poster.username} banned for 7 days!`);
    this.setWaiting(false);
  }

  handleDeleteBanPerm = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }
    await this.handleDeleteThread(selectedthread);

    this.setWaiting(true);
    await banComments(selectedthread.poster.uid, BANNED_TYPE_PERM);
    this.setInfo(`User ${selectedthread.poster.username} banned permanently!`);
    this.setWaiting(false);
  }

  handleCloseComments = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    this.setWaiting(true);
    await closeThread(selectedthread);
    this.setWaiting(false);
  }

  handleDeleteComments = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    this.setWaiting(true);
    await closeThread(selectedthread);
    await deleteAllComments(selectedthread);
    this.setWaiting(false);
  }

  handleShare = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    this.setState({
      ...this.state,
      shareDlg: true,
      anchorEl: null
    });
  }

  handleApproveUser = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    this.setState({
      ...this.state,
      anchorEl: null
    });

    this.setWaiting(true);
    const { selected_feed } = this.props;
    await preapproveUser(selectedthread.poster.uid, selected_feed);
    this.setWaiting(true);

    this.setInfo(`User ${selectedthread.poster.username} preapproved!`);
  }

  handleCloseShare = () => {
    this.setState({
      ...this.state,
      shareDlg: false
    });
  }

  _getShareInfo = () => {
    const { selectedthread } = this.state;
    const { selected_feed } = this.props;
    if (!selectedthread) {
      logger.error("Can't share this thread because selected thread is none!");
      return null;
    }
    if (!selected_feed) {
      logger.error("Can't share this thread because selected feed is none!");
      return null;
    }

    // share info
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.THREAD_PREFIX}/${selectedthread.id}`;

    let shareInfo = {
      title: "Raven Comment: " + selectedthread.title,
      description: selectedthread.text,
      image: '',
      hashtag: '',
      url: shareUrl
    };

    return shareInfo;
  }


  render() {
    const { classes, theme_mode, requesting } = this.props;
    const { 
      pended, 
      selectedthread, 
      anchorEl, 
      deleteDlg, 
      shareDlg
    } = this.state;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    // share info
    const shareInfo = this._getShareInfo();

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Pending Threads"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <Grid container spacing={1} className={classes.container}>
          {pended.length > 0 && pended.map((thread, index) => (
            <div key={index} className={classes.threadcard}>
              <Grid item key={`thread-${index}`}>
                <CommentThreadCard
                  thread={thread}
                  onAnchorEl={this.handleAnchorEl}
                />
              </Grid>
              <Grid
                item
                key={`thread-actions-${index}`}
                className={classes.actions}
              >
                <CommentModButtons
                  theme={theme_mode}
                  comment={thread}
                  onApprove={this.handleApproveThread}
                  onDelete={this.handleDeleteThread}
                  onBan1d={this.handleBan1d}
                  onBan7d={this.handleBan7d}
                  onBanPerm={this.handleBanPerm}
                />
              </Grid>
            </div>
          ))}
        </Grid>
        <Menu
          id="thread-menu"
          // anchorEl={anchorEl}
          open={anchorEl !== null}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.handleCloseMenu}
        >
          <PopMenuThreadMod
            theme={theme_mode}
            type={selectedthread === null ? THREAD_TYPE_FEED : selectedthread.type}
            onDelete={this.handleDeleteThread}
            onBan1d={this.handleDeleteBan1d}
            onBan7d={this.handleDeleteBan7d}
            onBanPerm={this.handleDeleteBanPerm}
            onCloseComments={this.handleCloseComments}
            onDeleteComments={this.handleDeleteComments}
            onShare={this.handleShare}
            onApproveUser={this.handleApproveUser}
          />
        </Menu>
        <DlgConfirm
          open={deleteDlg}
          title={"Delete Thread"}
          content={"Are you sure you want to delete this thread?"}
          onOK={this.handleDelete}
          onCancel={e => this.showDeleteDlg(false)}
        />
        {shareInfo &&
          <DlgShareComment
            open={shareDlg}
            comment={selectedthread}
            shareInfo={shareInfo}
            onClose={this.handleCloseShare}
          />
        }
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

ThreadsPended.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ThreadsPended);
