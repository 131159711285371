import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { 
  Typography, 
  IconButton, 
  Menu 
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  FeedImage,
  PopMenuFeed,
  DlgConfirm,
  DlgReport,
  DlgModerate,
  DlgLoginConfirm,
} from "components";
import { withFirebase } from 'services';
import { GraphqlService } from "services";
import { v4 as uuidv4 } from 'uuid';
import { 
  ACTIVITY_TYPE_FEED, 
  ACTIVITY_APPLY,
  ACTIVITY_REPORT
} from "constants/activity";
import { 
  MIN_CARD_WIDTH, 
  MAX_ARTICLE_WIDTH,
  USE_QUOTA,
  FREE_FEED_FOLLOW_LIMIT,
  PAID_FEED_FOLLOW_LIMIT, 
  GRAPHQL_ERROR
} from "constants/types";
import {
  NOTIFICATION_FEED_APPLY_MODERATOR
} from "constants/notification";
import {
  getAuthToken,
  followFeed,
  hasPaid,
  isBanned,
  isFeedModerator,
  unfollowFeed 
} from "dataapis";
import { objects_equal } from "utility/utils";
import { ToastError, ToastWarning } from "utility/toast";
import _ from "lodash";


const styles = (theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    minWidth: MIN_CARD_WIDTH - 32,
    maxWidth: MAX_ARTICLE_WIDTH - 32,
    margin: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  grid: {
    justifyContent: "left",
    flexWrap: "inherit",
  },
  catindicator: {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: 20,
    fontHeight: 20,
  },
  title: {
    position: "relative",
    textAlign: "left",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "24px",
    textTransform: "none",
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  description: {
    position: "relative",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 200,
    lineHeight: "20px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  specification: {
    position: "relative",
    textAlign: "left",
    fontFamily: "Merriweather",
    fontSize: "16px",
    fontWeight: 200,
    lineHeight: "20px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
  },
  expand: {
    position: "absolute",
    top: 8,
    right: 12,
    padding: 4,
    width: 24,
    height: 24,
    color: theme.palette.text.primary,
  },
  following: {
    position: "absolute",
    top: 32,
    right: 0,
    width: 48,
    height: 48,
    cursor: "pointer",
  },
});

class FeedDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      following: true,
      showfirst: false,
      anchorEl: null,
      reportDlg: false,
      moderateDlg: false,
      duplicateDlg: false,
      loginDlg: false
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.showReportDlg = this.showReportDlg.bind(this);
    this.showModerateDlg = this.showModerateDlg.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleFollow = this.handleFollow.bind(this);
    this.handleClickSocial = this.handleClickSocial.bind(this);
    
    this.handleReport = this.handleReport.bind(this);
    this.handleCancelReport = this.handleCancelReport.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.handleModerate = this.handleModerate.bind(this);
    this.hideModerateDlg = this.hideModerateDlg.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.showDuplicateDlg = this.showDuplicateDlg.bind(this);
    this.hideDuplicateDlg = this.hideDuplicateDlg.bind(this);
    this.handleShowFirst = this.handleShowFirst.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);
  }

  setError = (message) => {
    this.setState({
      ...this.state,
      reportDlg: false,
      moderateDlg: false,
      duplicateDlg: false,
    });

    ToastError(message);
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = () => {
    const { followed_feeds, selected_feed, authUser } = this.props;
    const following = followed_feeds.find((item) => item.id === selected_feed.id) !== undefined;
    const showfirst = authUser.feeds_showfirst.find(item => item.feed_id === selected_feed.id) !== undefined;
    this.setState({
      ...this.state,
      following: following,
      showfirst: showfirst
    });
  };

  componentDidUpdate = (prevProps) => {
    const { followed_feeds, selected_feed } = this.props;

    if (followed_feeds && selected_feed && !objects_equal(prevProps.followed_feeds, followed_feeds)) {
      const following = followed_feeds.find((item) => item && item.id === selected_feed.id) !== undefined;
      this.setState({
        ...this.state,
        following: following
      });
    }
  };

  handleExpand = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  showReportDlg = () => {
    this.handleCloseMenu();

    const { loggedIn } = this.props;
    if (loggedIn) {
      if (isBanned()) {
        ToastError("You've suspended.");
        return;
      }

      this.setState({
        ...this.state,
        reportDlg: true,
        loginDlg: false,
      });
    } else {
      this.setState({
        ...this.state,
        reportDlg: false,
        loginDlg: true,
      });
    }
  };

  handleReport = async (reportMsg) => {
    const message = reportMsg.trim();
    if (!message) {
      this.setError("Report shouldn't be blank. Please input your report.");
      return;
    }

    const { loggedIn, authUser, selected_feed } = this.props;
    if (!loggedIn) {
      this.props.onNeedLogin();
      return;
    }
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.props.onNeedLogin();
      return;
    }

    let report = {
      id: uuidv4(),
      feed_id: selected_feed.id,
      report: message,
      reported_by: authUser.uid,
      approved: false,
    };
    
    gqlservice.set_auth_jwt(token);
    await gqlservice
      .insert_feed_report(report)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_REPORT,
      object: `the feed ${selected_feed.name}`,
      fromto: `of the category ${selected_feed.category_id}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setState({
      ...this.state,
      reportDlg: false,
    });
  };

  handleCancelReport = () => {
    this.setState({
      ...this.state,
      reportDlg: false,
    });
  };

  handleCopyLink = () => {
    this.props.onCopyLink();
    this.setState({
      ...this.state,
      anchorEl: null
    });
  };

  showModerateDlg = () => {
    this.handleCloseMenu();

    const { loggedIn } = this.props;
    if (loggedIn) {
      if (isBanned()) {
        ToastError("You've suspended.");
        return;
      }

      this.setState({
        ...this.state,
        moderateDlg: true,
        loginDlg: false,
      });
    } else {
      this.setState({
        ...this.state,
        moderateDlg: false,
        loginDlg: true,
      });
    }
  };

  handleModerate = async () => {
    const { authUser, selected_feed, selected_category } = this.props;

    if (isFeedModerator(selected_feed)) {
      this.setError("This feed has already moderated");
      return;
    }

    const gqlservice = new GraphqlService();
    const result = await gqlservice.feed_moderator(selected_feed.id, authUser.uid);
    if (result.status_code === GRAPHQL_ERROR) {
      this.setError("Try again after a while!");
      return;
    }
    if (result.data.feed_moderators.length > 0) {
      this.setError("You've already registered to moderate this feed, wait for approve from feed moderator.");
      return;
    }

    let moderator = {
      id: uuidv4(),
      feed_id: selected_feed.id,
      user_id: authUser.uid,
      approved: !selected_feed.private,
      approved_by: null,
      owner: false
    };

    const token = await getAuthToken();
    if (!token) {
      this.props.onNeedLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);    
    await gqlservice
      .insert_feed_moderator(moderator)
      .then(
        (result) => {
          this.props.addFeedModerated(selected_feed);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    // insert notification
    const notification = {
      type: NOTIFICATION_FEED_APPLY_MODERATOR,
      object: selected_feed.id,
      in_which: selected_category.id,
      to: selected_feed.created_by,
      created_by: authUser.uid
    }

    await gqlservice
      .insert_notification(notification)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_APPLY,
      object: `for moderator`,
      fromto: `to the feed ${selected_feed.name}`,
      reason: "",
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setState({
      ...this.state,
      moderateDlg: false,
    });
  };

  hideModerateDlg = () => {
    this.setState({
      ...this.state,
      moderateDlg: false,
    });
  };

  showDuplicateDlg = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true
      });
    } else {
      if (isBanned()) {
        ToastError("You've suspended.");
        return;
      }

      this.setState({
        ...this.state,
        duplicateDlg: true
      });
    }
  }

  hideDuplicateDlg = () => {
    this.setState({
      ...this.state,
      duplicateDlg: false
    });
  }

  __isValidFeedId = (feed_id) => {
    const { feeds } = this.props;
    return feeds.find(feed => feed.id === feed_id) === undefined;
  }

  handleDuplicate = () => {
    this.setState({
      ...this.state,
      duplicateDlg: false
    });
    this.props.onDuplicate();
  }

  handleShowFirst = async (showfirst) => {
    this.handleCloseMenu();

    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    const { authUser, selected_feed } = this.props;
    if (showfirst) {
      await gqlservice.insert_user_feed_showfirst(authUser.uid, selected_feed.id)
      .then(result => {
        this.props.addUserFeedShowFirst(selected_feed.id);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
    } else {
      await gqlservice.delete_user_feed_showfirst(authUser.uid, selected_feed.id)
      .then(result => {
        this.props.deleteUserFeedShowFirst(selected_feed.id);
      }, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
    }

    this.setWaiting(false);

    this.setState({
      ...this.state,
      showfirst: showfirst
    });
  }

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
    this.props.onNeedLogin();
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };


  handleFollow = async () => {
    const following = !this.state.following;
    const { loggedIn, authUser, selected_feed, followed_feeds } = this.props;

    // check if the user was logged in
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true
      });
      return;
    }

    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    // check if quota is exceeded
    if (USE_QUOTA) {
      if (following) {
        const paidUser = hasPaid();
        if (!paidUser && followed_feeds.length + authUser.feeds_moderated.length >= FREE_FEED_FOLLOW_LIMIT) {
          this.props.onReachLimit();
          return;
        }
        if (paidUser && followed_feeds.length + authUser.feeds_moderated.length >= PAID_FEED_FOLLOW_LIMIT) {
          this.props.onReachLimit();
          return;
        }
      }
    }
    let res = null
    if (following) {
      await followFeed(selected_feed);
    } else {
      res = await unfollowFeed(selected_feed);
    }
    if(res === false) {
      return
    }

    this.setState({
      ...this.state,
      following: following,
    });
  };

  handleClickSocial = (socialtype) => {
    const { selected_feed } = this.props;
    if (socialtype === "twitter") {
      window.open(selected_feed.channel_twitter, "_blank");
    } else if (socialtype === "youtube") {
      window.open(selected_feed.channel_youtube, "_blank");
    } else if (socialtype === "tiktok") {
      window.open(selected_feed.channel_tiktok, "_blank");
    } else if (socialtype === "instagram") {
      window.open(selected_feed.channel_instagram, "_blank");
    }
  }

  render() {
    const {
      classes,
      title,
      description,
      image,
      pending,
      // loggedIn,
      // authUser,
      categories,
      selected_feed,
      theme_mode,
      onClickCategoryIndicator
    } = this.props;
    const {
      following,
      showfirst,
      anchorEl,
      reportDlg,
      moderateDlg,
      duplicateDlg,
      loginDlg
    } = this.state;

    // let specification = "";
    // specification = `${sources} Sources`;
    // specification += ` \u00B7 ${followers} Followers`;
    const feed_category = categories.find(category => category.id === selected_feed.category_id);

    const following_img = `/static/images/icons/${theme_mode}/following.png`;
    const unfollowing_img = `/static/images/icons/${theme_mode}/unfollowing.png`;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) {
      width = MAX_ARTICLE_WIDTH;
    } else if (width < MIN_CARD_WIDTH) {
      width = MIN_CARD_WIDTH;
    }
    width -= 32;
    const infoWidth = width - (112 + 48 + 16);

    // crypto
    let top_pos = 80;
    const crypto_class = selected_feed.tg_wallet ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 32,
      height: 32,
    } : null;
    top_pos = selected_feed.tg_wallet ? top_pos + 32 : top_pos;

    // twitter
    const twitter_class = selected_feed.channel_twitter ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 24,
      height: 24,
    } : null;
    top_pos = selected_feed.channel_twitter ? top_pos + 32 : top_pos;

    // instagram
    const instagram_class = selected_feed.channel_instagram ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 24,
      height: 24,
    } : null;
    top_pos = selected_feed.channel_instagram ? top_pos + 32 : top_pos;

    // tiktok
    const tiktok_class = selected_feed.channel_tiktok ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 24,
      height: 24,
    } : null;
    top_pos = selected_feed.channel_tiktok ? top_pos + 32 : top_pos;

    // youtube
    const youtube_class = selected_feed.channel_youtube ? {
      position: "absolute",
      top: top_pos,
      right: 8,
      width: 24,
      height: 24,
    } : null;

    return (
      <div className={classes.root} style={{width: width}}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item>
                <FeedImage
                  image={image}
                  title={title}
                  highlight={selected_feed.feed_videocasts_aggregate.aggregate.count > 0}
                />
              </Grid>
              <Grid item>
                {feed_category &&
                  <Typography 
                    className={classes.catindicator}
                    onClick={e => onClickCategoryIndicator(feed_category)}
                  >
                    {feed_category.emoji}
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{width: infoWidth}}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Grid>
        </Grid>
        
        <IconButton className={classes.expand} onClick={this.handleExpand}>
          <ExpandMoreIcon />
        </IconButton>
        <div onClick={this.handleFollow}>
          <img
            className={classes.following}
            alt={"following"}
            src={following ? following_img : unfollowing_img}
          />
        </div>
        {selected_feed.tg_wallet &&
          <img
            style={crypto_class}
            alt={"crypto"}
            src={`/static/images/icons/${theme_mode}/crypto.png`}
          />
        }
        {selected_feed.channel_twitter &&
          <div onClick={e => this.handleClickSocial("twitter")}>
            <img
              style={twitter_class}
              alt={"twitter"}
              src={`/static/images/icons/${theme_mode}/twitter.png`}
            />
          </div>
        }
        {selected_feed.channel_instagram &&
          <div onClick={e => this.handleClickSocial("instagram")}>
            <img
              style={instagram_class}
              alt={"crypto"}
              src={`/static/images/icons/${theme_mode}/instagram.png`}
            />
          </div>
        }
        {selected_feed.channel_tiktok &&
          <div onClick={e => this.handleClickSocial("tiktok")}>
            <img
              style={tiktok_class}
              alt={"crypto"}
              src={`/static/images/icons/${theme_mode}/tiktok.png`}
            />
          </div>
        }
        {selected_feed.channel_youtube &&
          <div onClick={e => this.handleClickSocial("youtube")}>
            <img
              style={youtube_class}
              alt={"crypto"}
              src={`/static/images/icons/${theme_mode}/youtube.png`}
            />
          </div>
        }

        <Menu
          id="feed-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleCloseMenu}
        >
          <PopMenuFeed
            theme={theme_mode}
            showfirst={showfirst}
            pending={pending}
            onReport={this.showReportDlg}
            onCopyLink={this.handleCopyLink}
            onModerate={this.showModerateDlg}
            onDuplicate={this.showDuplicateDlg}
            onShowFirst={this.handleShowFirst}
          />
        </Menu>
        <DlgReport
          open={reportDlg}
          title={"Report Feed"}
          theme={theme_mode}
          onReport={this.handleReport}
          onCancel={this.handleCancelReport}
        />
        <DlgModerate
          open={moderateDlg}
          type={"feed"}
          onModerate={this.handleModerate}
          onCancel={this.hideModerateDlg}
        />
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <DlgConfirm
          open={duplicateDlg}
          title={"Duplicate Feed"}
          content={"Are you sure to duplicate this feed?"}
          onOK={this.handleDuplicate}
          onCancel={this.hideDuplicateDlg}
        />
      </div>
    );
  }
}

FeedDetail.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onCopyLink: PropTypes.func,
  onDuplicate: PropTypes.func,
  onNeedLogin: PropTypes.func,
  onReachLimit: PropTypes.func,
  onClickCategoryIndicator: PropTypes.func
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  categories: state.dataState.categories,
  selected_category: state.dataState.selected_category,
  feeds: state.dataState.feeds,
  selected_feed: state.dataState.selected_feed,
  followed_feeds: state.dataState.followed_feeds,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedDetail);
