import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "services";
import MetaTags from "react-meta-tags";
import { ToastContainer } from "react-toastify";
import { withAuthentication } from "session";
import { 
  ArticleBar, 
  DlgSharePost,
  SimilarArticles,
} from "components";
import { 
  NewsTabs, 
  NewsCard, 
  NewsContent 
} from "./components";
import * as ROUTES from "constants/routes";
import { 
  MAX_ARTICLE_WIDTH, 
} from "constants/types";
import { 
  getMainInfo,
  getFeedInfo,
  getArticle,
  getThreadOfArticle,
  isCommentEnabledFeed,
} from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";
import {  
  ARTICLE_BRANCH_NEWSPAPER 
} from "constants/branches";
import PoliticalSentiment from "components/SharedControls/PoliticalSentiment";

const styles = theme => ({
  root: {
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  card: {
    margin: theme.spacing(1),
  },
  tabs: {
    marginBottom: theme.spacing(0.5),
    margin: theme.spacing(1),
  },
});

class Newspaper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentType: 0, // 0:SUMMARY, 1:FULL, 2:ORIGINAL(for translated articles)
      postShareDlg: false,
      discussShareDlg: false,
      discussShareInfo: null,
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleChangeNewsTab = this.handleChangeNewsTab.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleMemberTrial = this.handleMemberTrial.bind(this);

    this.handleSwiped = this.handleSwiped.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);

    this.handleSharePost = this.handleSharePost.bind(this);
    this.handleCloseSharePost = this.handleCloseSharePost.bind(this);

    this.handleShareRedditDiscussion =
      this.handleShareRedditDiscussion.bind(this);
    this.handleShareTwitterDiscussion =
      this.handleShareTwitterDiscussion.bind(this);
      this.handleCloseShareDiscuss = this.handleCloseShareDiscuss.bind(this);
      this.handleClusters = this.handleClusters.bind(this);
      
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const nid = this.props.match.params.id;

    this.setWaiting(true);

    await getMainInfo();

    const category_id = this.props.match.params.category_id;
    if (category_id) {
      logger.log("category id of news article :", category_id);
      const category = this.props.categories.find(
        (item) => item.id === category_id
      );
      if (category) {
        this.props.selectCategory(category);
      }
    }

    const feed_slug = this.props.match.params.feed_slug;
    if (feed_slug && !this.props.selected_feed && feed_slug) {
      logger.log("feed slug of news article :", feed_slug);
      await getFeedInfo(feed_slug);
    }

    await Promise.all([
      getArticle(nid), 
      getThreadOfArticle(nid)
    ]);

    this.setWaiting(false);
    window.scrollTo(0, 0);
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleNavBack = () => {
    const { article_backroute, selected_feed } = this.props;

    if (article_backroute === ROUTES.HOME) {
      this.props.selectFeedforComments(null);
    }

    if (selected_feed) {
      this.props.history.goBack();
    } else {
      const location = {
        pathname: article_backroute,
        state: { animation: "right" },
      };
      this.props.history.push(location);
    }
  };

  handleMemberTrial = () => {
    const location = {
      pathname: ROUTES.MEMBERSHIP,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleChangeNewsTab = (event, newType) => {
    if (this.state.contentType === newType) return;

    this.setState({
      ...this.state,
      contentType: newType,
    });
  };

  handleSwiped = (contentType) => {
    this.handleChangeNewsTab(null, contentType);
    window.scroll(0, 0);
  };

  handleSharePost = () => {
    this.setState({
      ...this.state,
      postShareDlg: true,
    });
  };

  handleCloseSharePost = () => {
    this.setState({
      ...this.state,
      postShareDlg: false,
    });
  };

  handleShareRedditDiscussion = (comment) => {
    const { selected_article } = this.props;

    let shareTitle = "Discussion on Reddit for ";
    if (!selected_article.title) {
      shareTitle += "Raven Post";
    } else {
      shareTitle += selected_article.title;
    }
    const discussShareInfo = {
      title: shareTitle,
      description: comment.body,
      image: "",
      hashtag: "",
      url: comment.url,
    };

    this.setState({
      ...this.state,
      discussShareDlg: true,
      discussShareInfo: discussShareInfo,
    });
  };

  handleShareTwitterDiscussion = (tweet) => {
    const { selected_article } = this.props;

    let shareTitle = "Discussion on Twitter for ";
    if (!selected_article.title) {
      shareTitle += "Raven Post";
    } else {
      shareTitle += selected_article.title;
    }

    const url = `https://twitter.com/${tweet.user_scname}/status/${tweet.id}`;
    logger.log("Twitter discussion share url :", url);
    const discussShareInfo = {
      title: shareTitle,
      description: tweet.text,
      image: "",
      hashtag: "",
      url: url,
    };

    this.setState({
      ...this.state,
      discussShareDlg: true,
      discussShareInfo: discussShareInfo,
    });
  };

  handleCloseShareDiscuss = () => {
    this.setState({
      ...this.state,
      discussShareDlg: false,
      discussShareInfo: null,
    });
  };

  handleClickSource = (source) => {
    const { selected_feed } = this.props;
    let path = "";
    if (selected_feed) {
      path = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    } else {
      path = `/${ROUTES.CATEGORY_PREFIX}/${source.category_id}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    }

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  handleClusters = (article) => {
    if(article.article) {
      article = article.article
    }
    this.props.selectArticle(article);

    const { selected_feed } = this.props;
    let path = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${article.source_id}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setArticleBackRoute(
      `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`
    );
  };

  render() {
    const {
      classes,
      theme_mode,
      loggedIn,
      selected_feed,
      selected_article,
      sources,
      newssites,
    } = this.props;
    const { 
      contentType, 
      postShareDlg, 
      // discussShareInfo, 
      // discussShareDlg 
    } = this.state;

    if (!selected_article) {
      return <div style={{display: "none"}}></div>;
    }

    let articleSource = sources.find(
      (source) => source.id === selected_article.source_id
    );
    if(!articleSource) {
      articleSource = selected_article.source
    }

    const show_original = articleSource?.translate === true;

    const newssite = newssites.find(
      (item) => item.id === selected_article.source_id
    );

    // get share url
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    if (selected_feed) {
      shareUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${articleSource.slug}`;
    } else {
      shareUrl += `/${ROUTES.CATEGORY_PREFIX}/${articleSource?.category_id}/${ROUTES.SOURCE_PREFIX}/${articleSource?.slug}`;
    }
    shareUrl += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${selected_article.nid}`;

    const show_comments =
      selected_feed &&
      isCommentEnabledFeed(selected_feed);

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven: ${selected_article.title}`}</title>
            <meta name="description" content={selected_article.summary} />
            <meta
              property="og:title"
              content={`Raven: ${selected_article.title}`}
            />
            <meta
              property="og:description"
              content={selected_article.summary}
            />
            <meta
              property="og:image"
              content={
                selected_article.image === null ? "" : selected_article.image
              }
            />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={shareUrl} />
            <meta
              property="twitter:title"
              content={`Raven: ${selected_article.title}`}
            />
            <meta property="twitter:site" content="Raven App" />
            <meta
              property="twitter:description"
              content={selected_article.summary}
            />
            <meta
              property="twitter:image:src"
              content={
                selected_article.image === null ? "" : selected_article.image
              }
            />
            <meta
              property="twitter:image:alt"
              content={selected_article.title}
            />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar}>
          <ArticleBar
            title={"Article"}
            onNavBack={this.handleNavBack}
            onShare={this.handleSharePost}
          />
        </div>
        <div className={classes.card}>
          <NewsCard
            theme_mode={theme_mode}
            article={selected_article}
            source={articleSource}
            newssite={newssite}
            search_mode={false}
            onClickSource={this.handleClickSource}
          />
        </div>

        {selected_article.home_cluster_members && selected_article.home_cluster_members.length > 0 && 
          <SimilarArticles
            article= {selected_article}
            theme_mode={theme_mode}
            loggedIn={loggedIn}
            similarArticles={selected_article.home_cluster_members}
            sources={sources} 
            onClickArticle={this.handleClusters}
          />
        }

        {selected_article.political_sentiments && (
          <PoliticalSentiment disabled={false} source={articleSource} article={selected_article} branch={selected_article.branch} />
        )}

        {!articleSource.disableFullText &&(
          <div className={classes.tabs}>
            <NewsTabs
              theme_mode={theme_mode}
              selected_tab={contentType === undefined ? 0 : contentType}
              show_original={show_original}
              onChangeNewsTab={this.handleChangeNewsTab}
            />
          </div>
        )}
          
        <NewsContent
          article={selected_article}
          source={articleSource}
          theme_mode={theme_mode}
          content_type={contentType}
          show_original={show_original}
          comment_show={show_comments}
          onError={this.setError}
          onNeedLogin={this.handleLogin}
          onMemberTrial={this.handleMemberTrial}
          onSwiped={this.handleSwiped}
          onShareTwitterDiscussion={this.handleShareTwitterDiscussion}
          onShareRedditDiscussion={this.handleShareRedditDiscussion}
        />
        {/* Post Share */}
        <DlgSharePost
          open={postShareDlg}
          post={selected_article}
          onLogin={this.handleLogin}
          onClose={this.handleCloseSharePost}
        />
        {/* Discuss Share */}
        {/* <DlgSharePost
          open={discussShareDlg}
          post={selected_article}
          shareInfo={discussShareInfo}
          onLogin={this.handleLogin}
          onClose={this.handleCloseShareDiscuss}
        /> */}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  categories: state.dataState.categories,
  selected_article: state.dataState.selected_article,
  selected_feed: state.dataState.selected_feed,
  feeds: state.dataState.feeds,
  sources: state.dataState.sources,
  newssites: state.dataState.newssites,
  article_backroute: state.uiState.article_backroute,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Newspaper);
