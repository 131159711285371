import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  // Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LinkPreview } from "components";
import { ToastSuccess, ToastError } from "utility/toast";
import { marked } from "marked";

import {
  get_timestring,
  decodeHTMLEntities,
  render_text,
  summarize_text,
} from "utility/utils";
import {
  BLANK_USER_IMAGE,
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  THEME_MODE_DARK,
  THEME_MODE_LIGHT,
  SUMMARY_IMAGE,
} from "constants/types";
import { logger } from "utility/logging";
import { copy2clipboard } from "utility/utils";
import { ARTICLE_BRANCH_YOUTUBE_SUMMARY } from "constants/branches";

const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 10,
    borderTopLeftRadius: 30,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.default,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  share: {
    position: "absolute",
    top: 12,
    right: 12,
    padding: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    backgroundColor: "#7289DA",
    zIndex: 100,
  },
  shareicon: {
    width: 16,
    height: 16,
  },
  pin: {
    position: "absolute",
    top: 12,
    right: 40,
    padding: 0,
    width: 16,
    height: 16,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    zIndex: 100,
  },
  copy_image: {
    position: "absolute",
    top: 40,
    right: 12,
    padding: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    zIndex: 100,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 48,
    height: 48,
    cursor: "pointer",
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  socialimg: {
    position: "absolute",
    top: 52,
    left: 16,
    width: 20,
    height: 20,
  },
  upvotebtn: {
    position: "absolute",
    top: 80,
    left: 12,
    width: 20,
    height: 20,
    zIndex: 100,
  },
  summaryBanner: {
    width: "95%",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    borderColor: theme.palette.feedstroke.podcast,
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedstroke.podcast,
    padding: "8px",
    display: "flex",
    textAlign: "center",
    fontSize: "15px",
    letterSpacing: "0.05px",
    fontWeight: "bold",
  },
  banner: {
    paddingBottom: "5px",
  },
  buttonimg: {
    width: 20,
    height: 20,
  },
  titleline: {
    position: "relative",
    marginLeft: 40,
    marginRight: 40,
    margin: 10,
  },
  author: {
    fontFamily: "Arial",
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: 600,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  title: {
    marginTop: -4,
  },
  content: {
    paddingTop: 4,
    paddingBottom: 4,
    marginLeft: 40,
    marginRight: 10,
    minHeight: 60,
    marginBottom: 8,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  detail_txt: {
    display: "inline",
    overflowWrap: "break-word",
  },
  media: {
    display: "block",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(1),
  },

  actionimg: {
    float: "left",
    width: 16,
    height: 16,
  },

  actions: {
    padding: 0,
    margin: 0,
  },
  repostbtn: {
    width: 20,
    height: 20,
    zIndex: 100,
  },
  commentbtn: {
    marginLeft: theme.spacing(0.5),
    width: 20,
    height: 20,
    zIndex: 100,
  },
  comment: {
    marginLeft: theme.spacing(0.5),
    fontSize: "14px",
    fontStyle: "Roboto",
  },

  description_title: {
    display: "block",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.66px",
    marginBottom: theme.spacing(0.5),
  },
  expand: {
    position: "absolute",
    top: 28,
    right: 10,
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
});

class PopularCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickShare = this.handleClickShare.bind(this);
    // this.handleExpand = this.handleExpand.bind(this);
  }

  handleClickShare = () => {
    const { article } = this.props;
    this.props.onShare(article.nid);
  };

  handleExpand = (event) => {
    this.props.onAnchorEl(event.currentTarget);
  };
  handleCopy = (text) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = marked(text);
    const plainText = tempElement.textContent || tempElement.innerText || "";
    copy2clipboard(plainText);
    ToastSuccess("Summary Copied to Clipboard!");
  };
  render() {
    const {
      classes,
      theme_mode,
      loggedIn,
      authUser,
      selected_user,
      sources,
      article,
      handleClick,
      onClickUpvote,
      onClickComment,
      onClickRepost,
    } = this.props;

    const sentiment = article.political_sentiments
      ? JSON.parse(article.political_sentiments)
      : null;
    let bannerColor = "green"; // Default color for scores below 25

    // voted, reposted by me
    let voted = false;
    let reposted = false;
    if (loggedIn && authUser) {
      voted =
        authUser.articles_voted.find(
          (voted) => voted.article_id === article.nid
        ) !== undefined;
      reposted =
        authUser.articles_reposted.find(
          (reposted) => reposted.article_id === article.nid
        ) !== undefined;
    }

    // author
    let author = article.author;
    let avatar_image = article.author_image;
    if (!avatar_image) {
      avatar_image = BLANK_USER_IMAGE;
    }
    let image = "";

    if (article.branch === ARTICLE_BRANCH_YOUTUBE_SUMMARY) {
      image = `/static/images/icons/${theme_mode}/youtube.png`;
    } else {
      image = `/static/images/icons/${theme_mode}/podcast.png`;
    }

    // publish time
    let published = get_timestring(article.published);

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    width -= 16;
    if (width > MAX_CARD_WIDTH - 16) width = MAX_CARD_WIDTH - 16;
    if (width < MIN_CARD_WIDTH - 16) width = MIN_CARD_WIDTH - 16;

    // get comments
    let commentsCnt = 0;
    for (let thread of article.threads) {
      commentsCnt += thread.comments_aggregate.aggregate.count;
    }

    let articleImage = article.image_thumb
      ? article.image_thumb["100"]
        ? article.image_thumb["100"]
        : article.image_thumb["150"]
      : article.image;
    let shortSummary = null;
    if (article.text) {
      shortSummary = article.text;
    } else if (article.summary) {
      shortSummary = article.summary;
    } else {
      shortSummary = summarize_text(article.ai_summary, 150);
    }

    return (
      <Card
        className={classes.card}
        style={{ width: width, backgroundColor: classes.card.backgroundColor }}
      >
        <div className={classes.carddiv}>
          <CardHeader
            className={classes.header}
            avatar={
              <div>
                <Avatar
                  alt={author}
                  src={SUMMARY_IMAGE}
                  className={classes.avatar}
                  classes={{ img: classes.avatar_img }}
                  style={{
                    background: "#10a37f",
                    borderRadius: 30,
                    padding: 6,
                  }}
                />
                <img alt={"image"} src={image} className={classes.socialimg} />
              </div>
            }
            title={
              <div className={classes.titleline}>
                <Typography className={classes.author}>
                  {decodeHTMLEntities(article.author)}
                </Typography>
                <Typography
                  className={classes.description_title}
                  style={{ marginTop: "10px" }}
                >
                  {/* <i>Summary of</i> */}
                </Typography>
                <Typography
                  className={classes.title}
                  variant="subtitle2"
                  onClick={(e) => handleClick(article)}
                >
                  {render_text(article.title, theme_mode === THEME_MODE_DARK)}
                </Typography>
                {/* <Typography className={classes.date} data-cy="userpostTime" data-cy-time={article.published}>{published}</Typography> */}
              </div>
            }
          />
          <div>
            <IconButton className={classes.upvotebtn} onClick={onClickUpvote}>
              <img
                alt={"upvote"}
                src={
                  voted
                    ? `/static/images/icons/thumbsup.png`
                    : `/static/images/icons/${theme_mode}/thumbsup.png`
                }
                className={classes.buttonimg}
              />
            </IconButton>
          </div>

          <div>
            {article.pinned !== undefined && (
              <img
                alt={"pin"}
                className={classes.pin}
                src={`/static/images/icons/${theme_mode}/pin.png`}
              />
            )}
            <IconButton
              className={classes.share}
              onClick={this.handleClickShare}
            >
              <ShareIcon className={classes.shareicon} />
            </IconButton>
            <IconButton className={classes.expand} onClick={this.handleExpand}>
              <ExpandMoreIcon />
            </IconButton>
          </div>

          {article.image && (
            <LazyLoadImage
              alt={""}
              src={articleImage}
              className={classes.media}
              onClick={(e) => handleClick(article)}
            />
          )}
          <CardActions className={classes.actions}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item></Grid>
              <Grid item>
                <div style={{ marginRight: 16 }}>
                  <IconButton
                    className={classes.repostbtn}
                    onClick={onClickRepost}
                  >
                    <img
                      alt={"repost"}
                      src={
                        reposted
                          ? `/static/images/icons/repost.png`
                          : `/static/images/icons/${theme_mode}/repost.png`
                      }
                      className={classes.actionimg}
                    />
                  </IconButton>

                  <IconButton
                    className={classes.commentbtn}
                    onClick={onClickComment}
                  >
                    <img
                      alt={"comment"}
                      src={`/static/images/icons/${theme_mode}/chat.png`}
                      className={classes.actionimg}
                    />
                  </IconButton>
                  {commentsCnt > 0 && (
                    <span
                      className={classes.comment}
                    >{`${commentsCnt} Comments`}</span>
                  )}
                </div>
              </Grid>
            </Grid>
          </CardActions>
        </div>
      </Card>
    );
  }
}

PopularCard.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
  handleClick: PropTypes.func,
  onAnchorEl: PropTypes.func,
  onShare: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  selected_user: state.dataState.selected_user,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PopularCard));
