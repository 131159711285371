import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { hasPaid } from "./user";
import { get_cached_item, set_cache_item } from "utility/cachemanager";
import { setError } from "./base";
import { GRAPHQL_ERROR } from "constants/types";

const getCategoryFeeds = async (category_id) => {
  const state = store.getState();

  const gqlservice = new GraphqlService();
  let result = await gqlservice.category_feeds(category_id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const category_feeds = result.data.feeds;
  if (category_feeds.length === 0) {
    return;
  }

  const { feeds, sources } = state.dataState;

  let new_feeds = feeds.slice();
  let new_sources = sources.slice();

  for (let feed of category_feeds) {
    if (!new_feeds.find((item) => item.id === feed.id)) {
      new_feeds.push(feed);
    }

    if (feed.feed_sources.length > 0) {
      let feed_sources = feed.feed_sources.map((item) => item.source);
      for (let source of feed_sources) {
        if (!new_sources.find((item) => item.id === source.id)) {
          new_sources.push(source);
        }
      }
    }
  }

  await Promise.all([
    store.dispatch({ type: ActionTypes.SET_FEEDS, feeds: new_feeds }),
    store.dispatch({ type: ActionTypes.SET_SOURCES, sources: new_sources }),
  ]);

  // get base data from cache
  const { loggedIn } = state.sessionState;
  let cache_name = loggedIn
    ? hasPaid()
      ? "base_data_paiduser_v1"
      : "base_data_user_v1"
    : "base_data_anonymous_v1";
  let base_data = await get_cached_item(cache_name);
  if (base_data) {
    base_data.feeds = new_feeds;
    base_data.sources = new_sources;
    set_cache_item(cache_name, base_data);
  }

  return category_feeds;
};

export { getCategoryFeeds };
