import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { 
  // ThreadItem,
  ThreadCard
} from "./components";
import {
  MIN_CARD_WIDTH,
  MAX_ARTICLE_WIDTH,
  FEED_COMMENT_DISABLE, 
  FEED_COMMENT_CLOSEALL,
  FEED_COMMENT_TWITTERUSER
} from "constants/types";
import { hasTwitter } from "dataapis";
import { logger } from "utility/logging";


const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_ARTICLE_WIDTH,
    backgroundColor: theme.palette.background.default,
  },
  warningcontainer: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: "center",
  },
  warningimg: {
    width: 48,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  warningicon: {
    width: 48,
    height: 48,
  },
  warningtitle: {
    width: 220,
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  warningtext: {
    width: 220,
    fontSize: 14,
    fontWeight: 400,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.text.secondary,
  },
  indicatoricon: {
    width: 16,
    height: 16,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  threads: {
    margin: 0,
    marginTop: theme.spacing(2),
  },
  listitem: {
    padding: 0
  },
});

class ThreadList extends React.Component {
  constructor(props) {
    super(props);
    this.scrollFn = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollFn);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFn);
  }

  listenToScroll = (event) => {
    event.preventDefault();

    const { topNavbar } = this.props;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    if (height === 0) {
      return;
    }

    const scrolled = winScroll * 1.0  / height;
    if (scrolled === 0.0) {
      this.props.showTopNavbar(true);
    } else if (scrolled === 1.0 || scrolled >= 0.99) {
      this.props.onNeedMore();
    } else {
      if (topNavbar) {
        this.props.showTopNavbar(false);
      }
    }
  };

  renderClosed = (classes, theme) => {
    return (
      <div className={classes.warningcontainer}>
        <div className={classes.warningimg}>
          <img
            className={classes.warningicon}
            alt="warning"
            src={`/static/images/icons/${theme}/warning.png`}
          />
        </div>
        <Typography className={classes.warningtitle}>
          Comments are Closed.
        </Typography>
        <Typography className={classes.warningtext}>
          Apply to be a moderator and you can contribute to keeping this
          discussion helpful and informative
        </Typography>
      </div>
    );
  }

  renderNotEnabled = (classes, theme) => {
    return (
      <div className={classes.warningcontainer}>
        <div className={classes.warningimg}>
          <img
            className={classes.warningicon}
            alt="warning"
            src={`/static/images/icons/${theme}/warning.png`}
          />
        </div>
        <Typography className={classes.warningtitle}>
          Comments are not enabled for this feed.
        </Typography>
        <Typography className={classes.warningtext}>
          Apply to be a moderator and you can contribute to keeping this
          discussion helpful and informative
        </Typography>
      </div>
    );
  }

  renderTwitterUserOnly = (classes, theme) => {
    return (
      <div className={classes.warningcontainer}>
        <div className={classes.warningimg}>
          <img
            className={classes.warningicon}
            alt="warning"
            src={`/static/images/icons/${theme}/warning.png`}
          />
        </div>
        <Typography className={classes.warningtitle}>
          Comments are allowed only to Twitter Users.
        </Typography>
        <Typography className={classes.warningtext}>
          Add your twitter link to your profile and you can comment on this feed.
        </Typography>
      </div>
    );
  }

  renderNoComment = (classes, theme) => {
    logger.log("render no comment");
    return (
      <div className={classes.warningcontainer}>
        <div className={classes.warningimg}>
          <img
            className={classes.warningicon}
            alt="warning"
            src={`/static/images/icons/${theme}/warning.png`}
          />
        </div>
        <Typography className={classes.warningtitle}>
          No comments posted
        </Typography>
        <Typography className={classes.warningtext}>
          Press
          <img
            className={classes.indicatoricon}
            alt="plus"
            src={`/static/images/icons/${theme}/add.png`}
          />
          button to start a comment thread. Users need to be logged in to join in
        </Typography>
      </div>
    );
  }

  render() {
    const { 
      classes,
      theme, 
      requesting,
      width, 
      selected_feed, 
      threads, 
      // onClick, 
      // onUpvote, 
      onLogin,
      onClickSource,
      onClickThread,
    } = this.props;
    // logger.log("ThreadList width :", width);

    const disabled = selected_feed.comment_conf === FEED_COMMENT_DISABLE;
    const closed = selected_feed.comment_conf === FEED_COMMENT_CLOSEALL;
    const twittered = selected_feed.comment_conf === FEED_COMMENT_TWITTERUSER && hasTwitter();
    const notwittered = selected_feed.comment_conf === FEED_COMMENT_TWITTERUSER && !hasTwitter();
    const allowed = !disabled && !closed && 
      (selected_feed.comment_conf !== FEED_COMMENT_TWITTERUSER || twittered);

    logger.log("disabled :", disabled);
    logger.log("closed :", closed);
    logger.log("twittered :", twittered);
    logger.log("notwittered :", notwittered);
    logger.log("allowed :", allowed);
    logger.log("requesting :", requesting);

    return (
      <div className={classes.root} style={{width: width}}>
        {disabled && this.renderNotEnabled(classes, theme)}
        {!disabled && closed && this.renderClosed(classes, theme)}
        {!disabled && !closed && notwittered && this.renderTwitterUserOnly(classes, theme)}
        {allowed && threads.length === 0 && !requesting && this.renderNoComment(classes, theme)}
        {allowed && threads.length > 0 &&
          <div className={classes.threads}>
            <List component="thread-list" aria-label="thread list">
              {threads.map((thread, index) =>
                <ListItem className={classes.listitem} key={thread.id}>
                  {/* <ThreadItem
                    thread={thread}
                    width={width}
                    index={index+1}
                    moderate={true}
                    onClick={onClick}
                    onUpvote={onUpvote}
                    onLogin={onLogin}
                  /> */}
                  <ThreadCard
                    thread={thread}
                    onLogin={onLogin}
                    onClickSource={onClickSource}
                    onClickThread={onClickThread}
                  />
                </ListItem>
              )}
            </List>
            <div style={{width: width - 16, height: 80}}></div>
          </div>
        }
      </div>
    );
  }
}

ThreadList.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  theme: PropTypes.string,
  threads: PropTypes.array,
  onClickSource: PropTypes.func,
  onClickThread: PropTypes.func,
  onLogin: PropTypes.func,
  onNeedMore: PropTypes.func,
};

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  topNavbar: state.uiState.topNavbar,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ThreadList);
