import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  NativeSelect,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import validate from "validate.js";
import { 
  withAuthentication, 
  withAuthorization 
} from "session";
import {
  BasicAppBar,
  WaitingSpinner,
  DlgConfirm,
  ImageSelector,
  // SelectableSlide,
  ChipsArray,
  PubPrivBtn,
  // FeedModeButton,
  SourceCardList,
  DlgPubPriv,
  SourceLinkAdd,
  TempSourceCard
} from "components";
import { 
  GraphqlService,
  withFirebase
} from "services";
import * as ROUTES from "constants/routes";
import { 
  ACTIVITY_TYPE_CATEGORY, 
  ACTIVITY_TYPE_FEED, 
  ACTIVITY_CHANGE,
  ACTIVITY_DELETE
} from "constants/activity";
import { 
  MAX_WINDOW_WIDTH, 
  MAX_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
  GRAPHQL_SUCCESS
} from "constants/types";
import { 
  ARTICLE_BRANCH_USERPOST,
  ARTICLE_BRANCH_PODCAST,
  get_branch_name
} from "constants/branches";
import { 
  getAuthToken,
  isFeedModerator,
  getFeedInfo,
  existSourcebyID,
  updateCache,
} from "dataapis";
import { 
  slugify,
  gen_random_int,
  is_valid_slug 
} from "utility/utils";
import { get_source_links } from "utility/buildlink";
import { get_link_source } from "utility/ravenapi";
import { v4 as uuidv4 } from 'uuid';
import { 
  ToastInfo,
  ToastError 
} from "utility/toast";
import { 
  moderate_image 
} from "utility/ravenapi";
import {
  resizeImageFile
} from "utility/resizeimage";
import { logger } from "utility/logging";


const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128
    }
  },
  slug: {
    presence: { allowEmpty: false, message: "is required" },
    format: {
      pattern: "^[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*$",
      message: "Invalid feed url format, permits only alphabet, numeric, _, -, and + characters."
    }
  },
  emoji: {
    numericality: {
      onlyInteger: true,
      greaterThan: -1,
      notGreaterThan: "Choose a category"
    }
  }
};

const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  infocontainer: {
    margin: theme.spacing(1),
  },
  feedItem: {
    display: "flex",
    padding: 8,
  },
  detail: {
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main
  },
  info: {
    color: theme.palette.info.main
  },
  name: {
    display: 'block',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    border: 0,
  },
  nameInput: {
    fontSize: 20,
    fontFamily: "Roboto",
    "&:before": {
      borderBottomColor: 'transparent',
    },
    "&:after": {
      borderBottomColor: 'transparent',
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: 'transparent',
    },
  },
  description: {
    display: 'block',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    border: 0,
  },
  descriptionInput: {
    fontSize: 14,
    padding: 0,
    marginTop: 4,
    fontFamily: "Roboto",
    "&:before": {
      borderBottomColor: 'transparent',
    },
    "&:after": {
      borderBottomColor: 'transparent',
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: 'transparent',
    },
  },
  feedurlcontainer: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    margin: 0,
  },
  feedurlprefix: {
    display: "inline",
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  feedurlinput: {
    display: 'inline',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    marginLeft: 4,
    padding: 2,
    paddingLeft: 4,
    border: 0,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.text.secondary,
    fontSize: 14,
    "&:focus": {
      outline: "0px",
    },
  },
  feedurlinput_error: {
    display: 'inline',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    marginLeft: 4,
    padding: 2,
    paddingLeft: 4,
    border: 0,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.error.main,
    fontSize: 14,
    "&:focus": {
      outline: "0px",
    },
  },
  categorycontainer: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  emoji: {
    marginLeft: theme.spacing(2),
  },
  pubpriv: {
    marginRight: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  tagcontainer: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  tagtext: {
    color: theme.palette.text.primary,
    marginTop: 8,
    marginBottom: 0
  },
  plus: {
    padding: 8,
    width: 32,
    height: 32,
    color: theme.palette.primary.contrastText,
  },
  tagsInput: {
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 14,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,
    borderColor: theme.palette.text.primary,
    "&:before": {
      borderBottomColor: 'transparent',
    },
    "&:after": {
      borderBottomColor: 'transparent',
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: 'transparent',
    },
  },
  hashtags: {
    marginTop: theme.spacing(3),
  },
  sourceaddcontainer: {
    margin: theme.spacing(2),
    borderRadius: 8,
  },
  linkcontainer: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 400,
  },
  sources: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  sourcesInput: {
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 14,
    "&:before": {
      borderBottomColor: 'transparent',
    },
    "&:after": {
      borderBottomColor: 'transparent',
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: 'transparent',
    },
  },
  sourcesInputPlaceholder: {
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 14,
    color: theme.palette.text.secondary,
    "&:before": {
      borderBottomColor: 'transparent',
    },
    "&:after": {
      borderBottomColor: 'transparent',
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: 'transparent',
    },
  },
  searchcontainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  searchbtn: {
    backgroundColor: theme.palette.background.main,
    textTransform: "initial",
    padding: "4px 16px 4px 4px",
    borderRadius: "20px",
    fontSize: "16px",
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: theme.palette.background.main,
      color: theme.palette.text.primary,
    }
  },
  sourceadd: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.main,
    textTransform: "initial",
    padding: "4px 24px 4px 8px",
    borderRadius: "20px",
    fontSize: "16px",
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: theme.palette.background.main,
      color: theme.palette.text.primary,
    }
  },
  starticon: {
    marginLeft: 0,
    marginRight: 0,
  },
  searchicon: {
    padding: 4,
    width: 28,
    height: 28,
    opacity: 0.38,
    marginRight: theme.spacing(1),
  },
  tempsourcecontainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  sourcecontainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(2),
  },
  feedmodecontainer: {
    marginTop: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
  },
  bottomspace: {
    paddingBottom: theme.spacing(8),
  },
  applybtn: {
    position: "fixed",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    }
  },
  applybtn_disabled: {
    position: "fixed",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
});

// sources placeholder
const sources_placeholder = "Add social media sources\nexample: twitter.com/elonmusk\nYou can also add podcasts by adding the Apple podcasts link.\nexample: podcasts.apple.com/us/podcast/the-daily/id1200361736\nAdding websites will add to your sources list, but won't generate posts on a feed.";

class FeedEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "",
      slug: "",
      mode: 0,    // feed privacy: 0 -> public, 1 -> private
      image: RAVEN_PLACEHOLDER_IMAGE,
      imageUpload: "",
      emoji: -1,
      tagtext: "",
      hashtags: [],
      sourceAddMode: 0,   // source add mode : 0 -> single source, 1 -> fast add
      sourcesTxt: sources_placeholder,
      sourcesPlaceholder: sources_placeholder,
      temp_sources: [],
      sources: [],
      values: {},
      touched: {},
      errors: {},
      modeDlg: false,
      showConfirmDlg: false,
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleApply = this.handleApply.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleChangeEmoji = this.handleChangeEmoji.bind(this);
    this.handleChangeFeedMode = this.handleChangeFeedMode.bind(this);
    this.handleFocusSourcesTxt = this.handleFocusSourcesTxt.bind(this);
    this.showModeDlg = this.showModeDlg.bind(this);

    this.handleTagDelete = this.handleTagDelete.bind(this);
    this.handleSelectCategory = this.handleSelectCategory.bind(this);
    this.handleSubmitFeed = this.handleSubmitFeed.bind(this);

    this.handleClickSourceSearch = this.handleClickSourceSearch.bind(this);
    this.handleDeleteTempFeedSource = this.handleDeleteTempFeedSource.bind(this);

    this.handleChangeSourceaddMode = this.handleChangeSourceaddMode.bind(this);

    this.handleEditSource = this.handleEditSource.bind(this);
    this.handleDeleteSource = this.handleDeleteSource.bind(this);
    this.handleChangeThrottle = this.handleChangeThrottle.bind(this);

    this.handleApplyTempSource = this.handleApplyTempSource.bind(this);
    this.handleDeleteTempSource = this.handleDeleteTempSource.bind(this);
    this.handleChangeTempImage = this.handleChangeTempImage.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const { 
      selected_feed, 
      categories, 
      sources,
      feed_create_state
    } = this.props;

    await getFeedInfo(selected_feed.slug);

    // select category of feed
    let feed_category = null;
    const feed_category_index = categories.findIndex(category => category.id === selected_feed.category_id);
    if (feed_category_index === -1) {
      feed_category = categories[0];
    } else {
      feed_category = categories[feed_category_index];
    }
    this.props.changeCategory(feed_category);
    
    // sources to show
    const feed_sources = selected_feed.feed_sources
      .filter(feed_source => feed_source.approved)
      .map(feed_source => sources.find(source => source.id === feed_source.source_id));    
    const mode = selected_feed.private === false ? 0 : 1;

    if (feed_create_state) {
      this.setState({
        ...this.state,
        name: feed_create_state.name,
        description: feed_create_state.description,
        slug: feed_create_state.slug,
        image: feed_create_state.image,
        emoji: feed_create_state.emoji,
        tagtext: feed_create_state.tagtext,
        hashtags: feed_create_state.hashtags,
        mode: feed_create_state.mode,
        sources: feed_sources,
        temp_sources: feed_create_state.temp_sources,
        sourcesTxt: feed_create_state.sourcesTxt,
      });
      this.props.setFeedCreateState(null);
    } else {
      this.setState({
        ...this.state,
        name: selected_feed.name,
        description: selected_feed.description,
        slug: selected_feed.slug,
        image: selected_feed.image,
        emoji: feed_category_index,
        hashtags: selected_feed.tags,
        mode: mode,
        sources: feed_sources
      });
    }
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    this.props.clearTempFeedSources();
    this.props.clearTempFeedSourceLinks();
    this.props.history.goBack();
  };

  handleCancel = () => {
    this.props.clearTempFeedSources();
    this.props.clearTempFeedSourceLinks();
    this.setState({
      ...this.state,
      showConfirmDlg: false,
    });
  };

  handleApply = async () => {
    const { sources } = this.props;
    const { sourcesTxt, sourceAddMode } = this.state;

    if (sourceAddMode === 1 && sourcesTxt === sources_placeholder) {
      let source_links = get_source_links(this.state.sourcesTxt);
      logger.log("source links :", source_links);

      if (source_links.length > 0) {
        let exist_sources = [];
        let new_source_links = [];
        for (let source_link of source_links) {
          let exist_source = null;
          // have to fetch link preview in podcast, because podcast link is apple link
          if (source_link.branch === ARTICLE_BRANCH_PODCAST) {
            let link_preview = await get_link_source(source_link.url);
            if (link_preview) {
              exist_source = sources.find(source => 
                source.branch === ARTICLE_BRANCH_PODCAST &&
                source.socialtags.length > 0 &&
                source.socialtags[0].type === source_link.type && 
                source.socialtags[0].tag === link_preview.url
              );
            }
          } else {
            exist_source = sources.find(source => 
              source.socialtags.length > 0 &&
              source.socialtags[0].type === source_link.type && 
              source.socialtags[0].tag.toLowerCase() === source_link.tag.toLowerCase()
            );
          }
          if (exist_source) {
            if (exist_sources.find(source => source.id === exist_source.id) === undefined) {
              exist_sources.push(exist_source);
            }
          } else {
            new_source_links.push(source_link);
          }
        }
        if (new_source_links.length > 0) {
          this.props.setTempFeedSourceLinks(new_source_links);
        }

        if (exist_sources.length > 0) {
          logger.log("exist sources :", exist_sources);
          this.props.setTempFeedSources(exist_sources);
        }
      }
    }

    this.setState({
      ...this.state,
      showConfirmDlg: true,
    });
  };

  handleImageChange = ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      this.setState({
        ...this.state,
        image: e.target.result,
        imageUpload: target.files[0],
      });
    };
  };

  showModeDlg = (show) => {
    this.setState({
      ...this.state,
      modeDlg: show
    });
  }

  handleChangeFeedMode = (mode) => {
    this.setState({
      ...this.state,
      mode: mode
    });
  }

  handleChange = (event) => {
    event.persist();

    let slug = this.state.slug;
    if (event.target.name === "name") {
      slug = slugify(event.target.value);
    }

    if (event.target.name !== "slug") {
      this.setState(
        {
          ...this.state,
          [event.target.name]: event.target.value,
          slug: slug,
          values: {
            ...this.state.values,
            [event.target.name]: event.target.value,
            slug: slug
          },
          touched: {
            ...this.state.touched,
            [event.target.name]: true
          },
        },
        () => {
          const errors = validate(this.state.values, schema);
          this.setState({
            ...this.state,
            errors: errors || {}
          });
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          [event.target.name]: event.target.value,
          values: {
            ...this.state.values,
            [event.target.name]: event.target.value,
          },
          touched: {
            ...this.state.touched,
            [event.target.name]: true
          },
        },
        () => {
          const errors = validate(this.state.values, schema);
          this.setState({
            ...this.state,
            errors: errors || {}
          });
        }
      );
    }
  };

  handleFocusSourcesTxt = (event) => {
    event.persist();
    const { sourcesTxt, sourcesPlaceholder } = this.state;
    if (sourcesTxt === sourcesPlaceholder) {
      this.setState({
        ...this.state,
        sourcesTxt: ''
      });
    } else if (sourcesTxt.length === 0) {
      this.setState({
        ...this.state,
        sourcesTxt: sourcesPlaceholder
      });
    }
  }

  handleChangeEmoji = (event) => {
    event.persist();
    this.setState({
      ...this.state,
      emoji: event.target.value,
      values: {
        ...this.state.values,
        emoji: event.target.value
      },
      touched: {
        ...this.state.touched,
        emoji: true
      },
    },
    () => {
      const errors = validate(this.state.values, schema);
      this.setState({
        ...this.state,
        errors: errors || {}
      });
    });

    const { categories } = this.props;
    const emoji = parseInt(event.target.value);
    if (emoji === -1) {
      this.props.selectCategory(null);
      return;
    }

    this.props.selectCategory(categories[emoji]);
  }

  handleSelectCategory = (category) => {
    this.props.changeCategory(category);
  };

  handleAddHashtags = () => {
    const { tagtext, hashtags } = this.state;

    let tags = hashtags.slice();
    const words = tagtext.split(",");
    for (const word of words) {
      let tag = word.trim();
      if (tag) {
        tags.push(tag.toLowerCase());
      }
    }

    if (tags.length > 0) {
      const uniqueTags = tags.filter((tag, pos, ar) => ar.indexOf(tag) === pos);

      this.setState({
        ...this.state,
        hashtags: uniqueTags,
        tagtext: "",
      });
    }
  };

  handleTagDelete = (tagname) => {
    const tags = this.state.hashtags.filter((item) => item !== tagname);
    this.setState({
      ...this.state,
      hashtags: tags,
    });
  };

  handleSubmitFeed = async () => {
    const { name, slug, emoji} = this.state;
    const { feeds, selected_feed } = this.props;

    this.setState({
      ...this.state,
      showConfirmDlg: false,
    });

    // if (image === RAVEN_PLACEHOLDER_IMAGE) {
    //   this.setError("You have to select a image of feed first");
    //   return;
    // }
    if (name.trim() === "") {
      this.setError("Feed name shouldn't be blank!");
      return;
    }
    if (slug.trim() === "") {
      this.setError("Feed url shouldn't be blank!");
      return;
    }
    if (emoji === -1) {
      this.setError("Please choose a category");
      return;
    }

    let index = 
      feeds
        .filter(feed => feed.id !== selected_feed.id)
        .findIndex(feed => feed.name.toLowerCase() === name.trim().toLowerCase());
    if (index !== -1) {
      this.setError(`The feeds name ${name} is already exist. Choose another one.`);
      return;
    }

    if (is_valid_slug(slug) === false) {
      this.setError(`Invalid feed url format, permits only alphabet, numeric, _ and + characters.`);
      return;
    }
    index = 
      feeds
        .filter(feed => feed.id !== selected_feed.id)
        .findIndex(feed => feed.slug === slug.trim());
    if (index !== -1) {
      this.setError(`The feeds url "/f/${slug}" is already exist. Choose another one.`);
      return;
    }

    this.updateFeed();
  };
  
  updateFeed = async () => {
    const { 
      image, 
      imageUpload, 
      name, 
      description, 
      slug, 
      hashtags, 
      mode,
      sourceAddMode,
      temp_sources
    } = this.state;
    const { 
      selected_category, 
      selected_feed, 
      authUser, 
      firebase, 
      temp_feed_sources, 
      temp_feed_sourcelinks 
    } = this.props;

    this.setWaiting(true);

    // update image if necessary
    let new_image = image;
    let thumb_image = image;
    if (new_image === RAVEN_PLACEHOLDER_IMAGE) {
      new_image = "";
      thumb_image = "";
    }
    if (imageUpload) {
      const flag = true;
      const resized_image = await resizeImageFile(imageUpload, flag);
      const uploadPromises = resized_image.map(async (image, index) => {
        const result = await firebase.uploadImage(image, "feeds");
        console.log(`Uploaded image ${index}:`, result);
        return result;
      }); 
      const uploadResults = await Promise.all(uploadPromises);
      console.log("Upload results:", uploadResults);
      if (uploadResults.some((result) => result.error)) {
        this.setError("Failed to upload source image.");
        return;
      }
      thumb_image = uploadResults[0].url;
      new_image = uploadResults[1].url;
      const modresult = await moderate_image(new_image);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError("Image not allowed, because it contains adults or racy content.");
        await firebase.deleteImage(new_image);
        await firebase.deleteImage(thumb_image);
        // return;
        new_image = ""
        thumb_image = "";
        this.setState({
          ...this.state,
          image: ""
        });
      }
    }

    const isModerator = isFeedModerator(selected_feed);
    const is_private = mode === 1;

    // update feed information
    const feed = {
      id: selected_feed.id,
      category_id: selected_category.id,
      name: name.trim(),
      description: description.trim(),
      slug: slug.trim(),
      image: new_image,
      tags: hashtags,
      created_by: selected_feed.created_by,
      created_at: selected_feed.created_at,
      followers: selected_feed.followers,
      private: is_private,
      approved: is_private ? isModerator : true
    };

    // new feed sources
    let feed_sources = [];
    let feed_source_changes = [];
    const currentTime = new Date().toISOString();
    if (temp_feed_sources && temp_feed_sources.length > 0) {
      for (let temp_feed_source of temp_feed_sources) {
        feed_sources.push({
          id: uuidv4(),
          feed_id: feed.id,
          source_id: temp_feed_source.id,
          created_by: authUser.uid,
          approved: true,
          approved_by: authUser.uid,
          approved_at: currentTime
        });
        feed_source_changes.push({
          feed_id: feed.id,
          source_id: temp_feed_source.id,
          removed: false,
          created_by: authUser.uid,
        });
      }
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    try {
      let result = await gqlservice.update_feed(feed);
      if (result.status_code !== GRAPHQL_SUCCESS || result.data.update_feeds.affected_rows === 0) {
        this.setError("Failed to update feed!");
        return;
      }

      // insert temp sources if exist
      if (sourceAddMode === 0) {
        for (let temp_source of temp_sources) {
          let inserted_source = await this.addTempSource(gqlservice, temp_source);
          if (inserted_source) {
            const currentTime = new Date().toISOString();
            feed_sources.push({
              id: uuidv4(),
              feed_id: feed.id,
              source_id: inserted_source.id,
              created_by: authUser.uid,
              approved: true,
              approved_by: authUser.uid,
              approved_at: currentTime
            });
            this.props.addSource(inserted_source);
          }
        }
      }

      // insert feed sources
      if (feed_sources.length > 0) {
        result = await gqlservice.insert_feed_sources(feed_sources);
        if (result.status_code !== GRAPHQL_SUCCESS || result.data.insert_feed_sources.affected_rows === 0) {
          this.setError("Failed to insert feed sources!");
          return;
        }
      }
      this.props.clearTempFeedSources();

      // insert feed source changes
      if (feed_source_changes.length > 0) {
        logger.log("feed source changes :", feed_source_changes);
        result = await gqlservice.insert_feed_source_changes(feed_source_changes);
        logger.log("insert feed source changes result :", result);
        if (result.status_code !== GRAPHQL_SUCCESS || result.data.insert_feed_source_change.affected_rows === 0) {
          this.setError("Failed to insert feed source changes!");
          return;
        }
      }

      // get feed
      result = await gqlservice.feed_by_id(feed.id);
      logger.log("get feed result :", result);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        this.setError("Failed to insert feed moderator!");
        return;
      }
      const feeds = result.data.feeds;
      if (feeds.length > 0) {
        this.props.updateFeed(feeds[0]);
      } else {
        return;
      }

      const activity = {
        user_id: authUser.uid,
        type: ACTIVITY_TYPE_CATEGORY,
        type_id: selected_category.id,
        action: ACTIVITY_CHANGE,
        object: `the feed ${feed.name}`,
        fromto: `of the category ${selected_category.name}`,
        reason: ''
      };
      result = await gqlservice.insert_activitylog(activity);
      
      this.setState({
        ...this.state,
        temp_sources: []
      });

      ToastInfo('Feed updated');
      await updateCache();

    } catch (err) {
      this.setError(JSON.stringify(err));
      return;
    }
    this.setWaiting(false);

    let route = "";
    if (temp_feed_sourcelinks && temp_feed_sourcelinks.length > 0) {
      route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/review`;
    } else {
      // route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/inviteuser`;
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
      this.props.initScrollPos();
    }
    const location = {
      pathname: route,
      state: { animation: "left" },
    };

    this.props.history.push(location);
  };

  _makeSourceID = async (id) => {
    let result = await existSourcebyID(id);
    if (result.error) {
      this.setError(result.message);
      return "";
    }

    var rand_id = "";
    while (result.exist) {
      rand_id = `${id}-${gen_random_int(1000).toString()}`;
      result = await existSourcebyID(rand_id);
      if (result.error) {
        this.setError(result.message);
        return "";
      }
    }

    return rand_id ? rand_id : id;
  }

  addTempSource = async (gqlservice, temp_source) => {
    const { 
      authUser, 
      selected_category, 
      socialtypes 
    } = this.props;

    logger.log("temp source :", temp_source);

    let id = '';
    const source_name = temp_source.name.trim();
    const socialtype = socialtypes.find(item => item.id === temp_source.type);
    const id_slug = slugify(socialtype.id);
    const name_slug = slugify(source_name);
    id = this._makeSourceID(id_slug + '-' + name_slug);
    if (!id) {
      return;
    }

    let slug = name_slug;
    const branch_name = get_branch_name(socialtype.branch);
    if (branch_name) {
      slug += "-" + branch_name;
    }

    let socialtag = {
      id: id,
      type: socialtype.id,
      tag: temp_source.tag
    };

    const timestamp = Math.floor(Date.now() / 1000);
    const source = {
      id: id,
      category_id: selected_category.id,
      name: source_name,
      description: temp_source.description.trim(),
      slug: slug,
      image: temp_source.image,
      branch: temp_source.branch,
      weblink: temp_source.weblink,
      translateLang: 'English',
      translateAPI: '',
      translate: false,
      frequency: 24,
      disableFullText: false,
      followers: 0,
      created_by: authUser.uid,
      created_at: timestamp,
      approved: true
    };

    try {
      // insert source
      let result = await gqlservice.insert_source(source);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        logger.error("Failed to create a source :", source.id, source.name);
        return null;
      } else if (result.data.insert_sources.affected_rows === 0) {
        logger.error("Failed to create a source :", source.id, source.name);
        return null;
      }
      const inserted_source = result.data.insert_sources.returning[0];

      // insert social tag
      result = await gqlservice.insert_socialtag(socialtag);
      if (result.status_code !== GRAPHQL_SUCCESS) {
        logger.error("Failed to create a socialtag :", socialtag);
        return null;
      } else if (result.data.insert_socialtags.affected_rows === 0) {
        logger.error("Failed to create a socialtag :", socialtag);
        return null;
      }

      return inserted_source;

    } catch (err) {
      logger.error("Failed to create a source :", source.id, source.name);
      return null;
    }
  }

  handleClickSourceSearch = () => {
    // save current state to the redux
    const current_state = {
      name: this.state.name,
      description: this.state.description,
      slug: this.state.slug,
      image: this.state.image,
      imageUpload: this.state.imageUpload,
      emoji: this.state.emoji,
      tagtext: this.state.tagtext,
      hashtags: this.state.hashtags,
      mode: this.state.mode,
      temp_sources: this.state.temp_sources,
      sourcesTxt: this.state.sourcesTxt,
    };

    this.props.setFeedCreateState(current_state);

    const location = {
      pathname: ROUTES.SOURCE_SEARCH,
      state: { animation: "left" },
    };
    this.props.clearTempFeedSources();
    this.props.history.push(location);
  };

  handleDeleteTempFeedSource = (source_id) => {
    this.props.deleteTempFeedSource(source_id);
  }

  handleChangeSourceaddMode = () => {
    const new_mode = this.state.sourceAddMode === 0 ? 1 : 0;
    this.setState({
      ...this.state,
      sourceAddMode: new_mode
    });
  }

  handleApplyTempSource = (sourceInfo) => {
    if (this.state.temp_sources.find(item => item.name === sourceInfo.name || item.source === sourceInfo.source)) {
      this.setError("The same source exists! Choose another one.");
      return;
    };

    let new_tempsources = this.state.temp_sources;
    new_tempsources.push(sourceInfo);
    this.setState({
      ...this.state,
      temp_sources: new_tempsources
    });
  }

  handleDeleteTempSource = (sourceInfo) => {
    const new_tempsources = this.state.temp_sources.filter(item => item.id !== sourceInfo.id);
    this.setState({
      ...this.state,
      temp_sources: new_tempsources
    });
  }

  handleChangeTempImage = async (source, imageUpload) => {
    const { firebase } = this.props;
    this.setWaiting(true);
    const flag = true;
    const resized_image = await resizeImageFile(imageUpload, flag);
    const uploadPromises = resized_image.map(async (image, index) => {
      const result = await firebase.uploadImage(image, "feeds");
      console.log(`Uploaded image ${index}:`, result);
      return result;
    });
    const uploadResults = await Promise.all(uploadPromises);
    console.log("Upload results:", uploadResults);
    if (uploadResults.some((result) => result.error)) {
      this.setError("Failed to upload source image.");
      return;
    }
    let thumb_image = uploadResults[0].url;
    let new_image = uploadResults[1].url;
    const modresult = await moderate_image(new_image);
    logger.log("image moderation result :", modresult);
    if (modresult && modresult.result) {
      this.setError("Image not allowed, because it contains adults or racy content.");
      await firebase.deleteImage(new_image);
      await firebase.deleteImage(thumb_image);
      // return;
      new_image = "";
      thumb_image = "";
    }

    if (source.image) {
      await firebase.deleteImage(source.image);
    }

    this.setWaiting(false);

    const new_tempsources = this.state.temp_sources.map(item => {
      if (item.id === source.id) {
        let new_item = item;
        new_item.image = new_image;
        new_item.thumbnail = thumb_image;
        return new_item;
      } else {
        return item;
      }
    });
    this.setState({
      ...this.state,
      temp_sources: new_tempsources
    });
  }

  handleEditSource = (source_id) => {
    const { selected_feed, sources } = this.props;
    const source2edit = sources.find(source => source.id === source_id);
    if (source2edit === undefined) {
      logger.error("Can't find the source :", source_id);
      return;
    }
    this.props.selectSource(source2edit);
    this.props.setSourceBackRoute(`/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/edit`);
    
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${source2edit.slug}/edit`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  handleDeleteSource = async (source_id) => {
    logger.log("handleDeleteSource :", source_id);
    const { selected_feed, authUser } = this.props;
    const { sources } = this.state;

    const deleted_source = sources.find(source => source.id === source_id);
    if (!deleted_source) {
      this.props.deleteTempFeedSource(source_id);
      return;
    }

    this.setWaiting(true);
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    logger.log("delete feed source :", selected_feed.id, source_id);
 
    await gqlservice
      .delete_feed_source(selected_feed.id, source_id)
      .then(result => {
        logger.log("result :", result);
        return gqlservice.feed_by_id(selected_feed.id);
      })
      .then(result => {
        const feeds = result.data.feeds;
        if (feeds.length > 0) {
          this.props.selectFeed(feeds[0]);
        }
      })
      .then(result => {
        const activity = {
          user_id: authUser.uid,
          type: ACTIVITY_TYPE_FEED,
          type_id: selected_feed.id,
          action: ACTIVITY_DELETE,
          object: `source '${deleted_source.name}'`,
          fromto: `from the feed ${selected_feed.name}`,
          reason: ''
        };

        return gqlservice.insert_activitylog(activity);
      })
      .then(result => {
        const new_sources = this.state.sources.filter(source => source.id !== source_id);
        this.setState({
          ...this.state,
          sources: new_sources
        });
        this.setWaiting(false);
      }, reason => {
        this.setError(reason.msg);
        this.setWaiting(false);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });

    // insert feed source changes
    const feed_source_change = {
      feed_id: selected_feed.id,
      source_id: source_id,
      removed: true,
      created_by: authUser.uid
    };
    let result = await gqlservice.insert_feed_source_changes([feed_source_change]);
    if (result.status_code !== GRAPHQL_SUCCESS || result.data.insert_feed_source_change.affected_rows === 0) {
      this.setError("Failed to insert feed source changes!");
    }

    await updateCache();
  }

  handleChangeThrottle = async (source_id, throttle) => {
    const { authUser, selected_feed } = this.props;
    const { sources } = this.state;

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    await gqlservice
      .update_source_throttle(source_id, throttle)
      .then(result => {
        const update_result = result.data.update_sources.returning;
        if (update_result.length > 0) {
          const source = update_result[0];
          this.props.updateSource(source);

          let new_sources = sources.map(item => 
            item.id === source.id ? source : item
          );
          this.setState({
            ...this.state,
            sources: new_sources
          });

          gqlservice.set_auth_jwt(token);
          let activity = {
            user_id: authUser.uid,
            type: ACTIVITY_TYPE_FEED,
            type_id: selected_feed.id,
            action: ACTIVITY_CHANGE,
            object: `the throttle as ${throttle}`,
            fromto: `of the feed ${selected_feed.name}`,
            reason: ''
          };
          return gqlservice.insert_activitylog(activity);
        }
      })
      .then(result => {
        this.setWaiting(false);
      }, (reason) => {
        this.setError(reason.msg);
        this.setWaiting(false);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });
  }

  render() {
    const { 
      classes, 
      categories,
      selected_feed,
      temp_feed_sources,
      theme_mode,
      requesting
    } = this.props;
    const {
      image,
      name,
      description,
      slug,
      emoji,
      mode,
      tagtext,
      hashtags,
      sourceAddMode,
      sourcesTxt,
      sources,
      temp_sources,
      modeDlg,
      showConfirmDlg,
    } = this.state;

    let feed_image = image;
    if (!image) {
      feed_image = RAVEN_PLACEHOLDER_IMAGE;
    }

    const { errors, touched } = this.state;
    const hasError = field => (touched[field] && errors[field] ? true : false);

    // enable apply button
    let prev_mode = selected_feed.private === false ? 0 : 1;
    let apply_enabled = false;
    if ((name && name !== selected_feed.name) ||
      (description && description !== selected_feed.description) ||
      (slug && slug !== selected_feed.slug) ||
      mode !== prev_mode ||
      feed_image !== selected_feed.image ||
      hashtags.length !== selected_feed.tags.length ||
      sourcesTxt.trim() 
    ) {
      apply_enabled = true;
    }

    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const feed_width = width > MAX_CARD_WIDTH ? MAX_CARD_WIDTH : width;
    // feed name & description width
    // const text_width = width > MAX_CARD_WIDTH ? MAX_CARD_WIDTH - 148 : width - 148; // image selector(116), padding(32)

    const updateButtonPos = (width - 260) / 2;

    // privacy button postion
    const privacy_btn_css = {
      position: "absolute",
      top: 110 + 40,
      right: 8
    };
    if (width > MAX_WINDOW_WIDTH) {
      privacy_btn_css.right = (width - feed_width) / 2;
    }

    // exclude userpost source
    const sources2show = sources.filter(source => 
      source && source.approved && source.branch !== ARTICLE_BRANCH_USERPOST
    );

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Edit a List/Feed"}
            onNavBack={this.handleNavBack}
          />
        </div>

        <div className={classes.infocontainer}>
          <div className={classes.feedItem}>
            <div className={classes.feedItem} style={{ width: feed_width }}>
              <ImageSelector
                image={feed_image}
                imageHandleChanged={this.handleImageChange}
              />
              <div className={classes.detail}>
                <TextField
                  className={classes.name}
                  error={hasError("name")}
                  fullWidth
                  required
                  placeholder="Name your feed*"
                  helperText={hasError("name") ? errors.name[0] : null}
                  name="name"
                  value={name || ""}
                  onChange={this.handleChange}
                  InputProps={{
                    classes: {root: classes.nameInput}
                  }}
                />
                <TextField
                  className={classes.description}
                  fullWidth
                  multiline
                  minRows={4}
                  name="description"
                  value={description || ""}
                  onChange={this.handleChange}
                  placeholder={"Describe your feed"}
                  InputProps={{
                    classes: {root: classes.descriptionInput}
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.feedurlcontainer}>
          <Typography className={classes.feedurlprefix}>
            {`ravenapp.org/${ROUTES.FEEDS_PREFIX}/`}
          </Typography>
          <input
            className={hasError("slug") ? classes.feedurlinput_error : classes.feedurlinput}
            name="slug"
            value={slug || ""}
            onChange={this.handleChange}
          />
        </div>

        <div className={classes.categorycontainer}>
          <Grid 
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <div className={classes.emoji}>
                <FormControl error={hasError("emoji")}>
                  <NativeSelect
                    value={emoji}
                    onChange={this.handleChangeEmoji}
                    name="emoji"
                    className={classes.selectEmpty}
                    inputProps={{
                      'aria-label': 'emoji'
                    }}
                  >
                    <option key={"emoji-select"} value={-1}>Choose a Category</option>
                    {categories
                      .map((category, index) =>
                      (category.id !== "cleanairmap" && category.id !== "trending") &&
                      <option key={category.id} value={index} >{`${category.emoji !== null ? category.emoji : ' '} ${category.name}`}</option>
                    )}
                  </NativeSelect>
                  <FormHelperText>{hasError("emoji") ? errors.emoji[0] : null}</FormHelperText>
                </FormControl>
              </div>
            </Grid>
            <Grid item>
              <PubPrivBtn 
                theme={theme_mode} 
                publicOnly={false} 
                mode={mode} 
                onClick={e => this.showModeDlg(true)}
              />
            </Grid>
            {/* <Grid item>
              <div className={classes.pubpriv}>
                {!paidUser && (
                  <PubPrivBtn 
                    theme={theme_mode} 
                    publicOnly={true} 
                    mode={0} 
                  />
                )}
                {paidUser && (
                  <PubPrivBtn 
                    theme={theme_mode} 
                    publicOnly={false} 
                    mode={mode} 
                    onChangeMode={this.handleChangeFeedMode}
                  />
                )}
              </div>
            </Grid> */}
          </Grid>
        </div>

        <div className={classes.tagcontainer}>
          <TextField
            className={classes.tagtext}
            fullWidth
            variant="outlined"
            size="small"
            name="tagtext"
            value={tagtext || ""}
            onChange={this.handleChange}
            placeholder="Keywords/Hashtags (Use , to separate)"
            InputProps={{
              classes: {
                root: classes.tagsInput,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    className={classes.plus}
                    alt="plus"
                    src={`/static/images/icons/${theme_mode}/plus.png`}
                    onClick={this.handleAddHashtags}
                  />
                </InputAdornment>
              ),
            }}
          />
          {hashtags.length > 0 && (
            <div className={classes.hashtags}>
              <ChipsArray
                color={"primary"}
                chips={hashtags}
                onDeleteChip={this.handleTagDelete}
              />
            </div>
          )}
        </div>

        {sourceAddMode === 0 ? (
          <div className={classes.sourceaddcontainer}>
            <SourceLinkAdd 
              onApply={this.handleApplyTempSource}
            />
          </div>
        ):(
          <div className={classes.linkcontainer}>
            <Typography className={classes.categoryTitle}>
              Add links to sources, one on each line
            </Typography>
            <TextField
              className={classes.sources}
              fullWidth
              multiline
              minRows={9}
              name="sourcesTxt"
              value={sourcesTxt || ""}
              onChange={this.handleChange}
              onFocus={this.handleFocusSourcesTxt}
              onBlur={this.handleFocusSourcesTxt}
              // placeholder={sources_placeholder}
              InputProps={{
                classes: {
                  root: sourcesTxt === sources_placeholder ? classes.sourcesInputPlaceholder : classes.sourcesInput,
                }
              }}
            />
          </div>
        )}

        {temp_sources && temp_sources.length > 0 && (
          <div className={classes.tempsourcecontainer}>
            <Grid container spacing={1}>
              {temp_sources.map((source, index) => (
                <Grid item key={index}>
                  <TempSourceCard
                    theme={theme_mode}
                    source={source}
                    onDelete={this.handleDeleteTempSource}
                    onChangeImage={this.handleChangeTempImage}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}

        <div className={classes.searchcontainer}>
          <Button
            className={classes.searchbtn}
            classes={{startIcon: classes.starticon}}
            startIcon={
              <img
                className={classes.searchicon}
                alt="search"
                src={`/static/images/icons/${theme_mode}/search.png`}
              />
            }
            onClick={this.handleClickSourceSearch}
          >
            Search Existing
          </Button>

          {sourceAddMode === 0 ? (
            <Button
              className={classes.sourceadd}
              classes={{startIcon: classes.starticon}}
              startIcon={
                <img
                  className={classes.searchicon}
                  alt="search"
                  src={`/static/images/icons/${theme_mode}/edit.png`}
                />
              }
              onClick={this.handleChangeSourceaddMode}
            >
              Fast Add
            </Button>
          ):(
            <Button
              className={classes.sourceadd}
              classes={{startIcon: classes.starticon}}
              startIcon={
                <img
                  className={classes.searchicon}
                  alt="edit"
                  src={`/static/images/icons/${theme_mode}/edit.png`}
                />
              }
              onClick={this.handleChangeSourceaddMode}
            >
              Single Source
            </Button>
          )}
        </div>

        {sources2show.length > 0 && (
          <div className={classes.sourcecontainer}>
            <SourceCardList
              noncheck
              editable
              deletable
              sources={sources2show}
              theme={theme_mode}
              onEdit={this.handleEditSource}
              onDelete={this.handleDeleteSource}
              onChangeThrottle={this.handleChangeThrottle}
            />
          </div>
        )}

        {temp_feed_sources && temp_feed_sources.length > 0 && (
          <div className={classes.sourcecontainer}>
            <SourceCardList
              noncheck
              deletable
              sources={temp_feed_sources}
              theme={theme_mode}
              onDelete={this.handleDeleteTempFeedSource}
            />
          </div>
        )}

        {/* <div className={classes.feedmodecontainer}>
          <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <FeedModeButton
                theme={theme_mode}
                mode={0}
                selected={mode === 0}
                onClick={this.handleChangeFeedMode}
              />
            </Grid>
            <Grid item>
              <FeedModeButton
                theme={theme_mode}
                mode={1}
                selected={mode === 1}
                onClick={this.handleChangeFeedMode}
              />
            </Grid>
          </Grid>
        </div> */}

        <div className={classes.bottomspace}></div>

        {apply_enabled &&
          <Button
            className={classes.applybtn}
            style={{left: updateButtonPos}}
            onClick={this.handleApply}
          >
            Update my Feed!
          </Button>
        }
        {!apply_enabled &&
          <Button
            className={classes.applybtn_disabled}
            style={{left: updateButtonPos}}
          >
            Update my Feed!
          </Button>
        }

        <DlgPubPriv
          open={modeDlg}
          theme={theme_mode}
          mode={mode}
          onClose={e => this.showModeDlg(false)}
          onSelectMode={this.handleChangeFeedMode}
        />
        <DlgConfirm
          open={showConfirmDlg}
          title={"Edit a List/Feed"}
          content={"Do you really want to update this feed?"}
          onOK={this.handleSubmitFeed}
          onCancel={this.handleCancel}
        />
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  categories: state.dataState.categories,
  selected_category: state.dataState.selected_category,
  socialtypes: state.dataState.socialtypes,
  feeds: state.dataState.feeds,
  selected_feed: state.dataState.selected_feed,
  sources: state.dataState.sources,
  temp_feed_sources: state.dataState.temp_feed_sources,
  temp_feed_sourcelinks: state.dataState.temp_feed_sourcelinks,
  feed_create_state: state.uiState.feed_create_state,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withRouter,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedEdit);
