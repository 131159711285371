// Session Action Types
export const SET_AUTH_USER              = 'SET_AUTH_USER';
export const SESSION_LOGIN              = 'SESSION_LOGIN';
export const SESSION_LOGOUT             = 'SESSION_LOGOUT';
export const UPDATE_USER_PROFILE        = 'UPDATE_USER_PROFILE';

export const UPDATE_USER_PAID           = 'UPDATE_USER_PAID';

export const SET_FOLLOWING_FEED_USER    = 'SET_FOLLOWING_FEED_USER';
export const GIVEUP_FEED_OWNER_USER     = 'GIVEUP_FEED_OWNER_USER';

export const ADD_CATEGORY_MODERATED     = 'ADD_CATEGORY_MODERATED';
export const DELETE_CATEGORY_MODERATED  = 'DELETE_CATEGORY_MODERATED';
export const ADD_FEED_MODERATED         = 'ADD_FEED_MODERATED';
export const DELETE_FEED_MODERATED      = 'DELETE_FEED_MODERATED';
export const UPDATE_FEEDS_SUBSCRIBED    = 'UPDATE_FEEDS_SUBSCRIBED';
export const DELETE_FEEDS_SUBSCRIBED    = 'DELETE_FEEDS_SUBSCRIBED';

export const UPDATE_FEEDS_ORDER         = 'UPDATE_FEEDS_ORDER';
export const ADD_FOLLOW_USER            = 'ADD_FOLLOW_USER';
export const DELETE_FOLLOW_USER         = 'DELETE_FOLLOW_USER';

export const ADD_USER_INVITE            = 'ADD_USER_INVITE';
export const DELETE_USER_INVITE         = 'DELETE_USER_INVITE';

export const ADD_USER_FEED_SHOWFIRST    = 'ADD_USER_FEED_SHOWFIRST';
export const DELETE_USER_FEED_SHOWFIRST = 'DELETE_USER_FEED_SHOWFIRST';

export const SET_MAP_CENTER_POS         = 'SET_MAP_CENTER_POS';
export const SET_MAP_PARAMS             = 'SET_MAP_PARAMS';

export const INSERT_FOLLOWING_LOCATION  = 'SET_FOLLOWING_LOCATION';
export const DELETE_FOLLOWING_LOCATION  = 'DELETE_FOLLOWING_LOCATION';

export const INSERT_REGION_MODERATOR    = 'INSERT_REGION_MODERATOR';
export const DELETE_REGION_MODERATOR    = 'DELETE_REGION_MODERATOR';
export const APPROVE_REGION_MODERATOR   = 'APPROVE_REGION_MODERATOR';

export const INSERT_LOCATION_MODERATOR  = 'INSERT_LOCATION_MODERATOR';
export const DELETE_LOCATION_MODERATOR  = 'DELETE_LOCATION_MODERATOR';
export const APPROVE_LOCATION_MODERATOR = 'APPROVE_LOCATION_MODERATOR';

export const INSERT_ARTICLE_VOTED       = 'INSERT_ARTICLE_VOTED';
export const DELETE_ARTICLE_VOTED       = 'DELETE_ARTICLE_VOTED';
export const INSERT_ARTICLE_REPOSTED    = 'INSERT_ARTICLE_REPOSTED';
export const DELETE_ARTICLE_REPOSTED    = 'DELETE_ARTICLE_REPOSTED';

export const INSERT_THREAD_VOTED       = 'INSERT_THREAD_VOTED';
export const DELETE_THREAD_VOTED       = 'DELETE_THREAD_VOTED';


// Data Action Types
export const RESET_ALL_DATA         = 'RESET_ALL_DATA';
export const SET_NEWSSITE_INFO      = 'SET_NEWSSITE_INFO';
export const SET_SOCIALTYPE_INFO    = 'SET_SOCIALTYPE_INFO';
export const SET_CATEGORIES         = 'SET_CATEGORIES';
export const SET_FEEDS              = 'SET_FEEDS';
export const SET_SOURCES            = 'SET_SOURCES';
export const SET_DEFAULT_FEEDS      = 'SET_DEFAULT_FEEDS';
export const SET_LISTS              = 'SET_LISTS';
export const SET_MY_POSTS           = 'SET_MY_POSTS';
export const SET_USER_POSTS         = 'SET_USER_POSTS';
export const SELECT_LIST            = 'SELECT_LIST';
export const SET_ONBORDING_FEEDS    = 'SET_ONBOARDING_FEEDS';

export const SET_MODERATE           = 'SET_MODERATE';

export const SET_TAGS               = 'SET_TAGS';

export const SELECT_CATEGORY        = 'SELECT_CATEGORY';
export const CHANGE_CATEGORY        = 'CHANGE_CATEGORY';
export const RESIGN_CATEGORY_MODERATOR  = 'RESIGN_CATEGORY_MODERATOR';
export const DELETE_FEED_REPORTS    = 'DELETE_FEED_REPORTS';

export const SELECT_FEED            = 'SELECT_FEED';
export const SELECT_FEED_FOR_COMMENTS   = 'SELECT_FEED_FOR_COMMENTS'; 
export const ADD_FEED               = 'ADD_FEED';
export const UPDATE_FEED            = 'UPDATE_FEED';
export const APPROVE_FEED           = 'APPROVE_FEED';
export const DELETE_FEED            = 'DELETE_FEED';

export const SET_FEED_JOIN_INFO     = 'SET_FEED_JOIN_INFO';
export const DELETE_FEED_JOIN_INFO  = 'DELETE_FEED_JOIN_INFO';
export const SET_FEED_SHARE_INFO    = 'SET_FEED_SHARE_INFO';
export const DELETE_FEED_SHARE_INFO = 'DELETE_FEED_SHARE_INFO';

export const SET_TEMP_FEED_SOURCELINKS = 'SET_TEMP_FEED_SOURCELINKS';
export const CLS_TEMP_FEED_SOURCELINKS = 'CLS_TEMP_FEED_SOURCELINKS';
export const DEL_TEMP_FEED_SOURCELINK = 'DEL_TEMP_FEED_SOURCELINK';

export const SET_TEMP_FEED_SOURCES  = 'SET_TEMP_FEED_SOURCES';
export const CLS_TEMP_FEED_SOURCES  = 'CLS_TEMP_FEED_SOURCES';
export const ADD_TEMP_FEED_SOURCE   = 'ADD_TEMP_FEED_SOURCE';
export const DEL_TEMP_FEED_SOURCE   = 'DEL_TEMP_FEED_SOURCE';

export const UPDATE_FEED_SOURCES    = 'UPDATE_FEED_SOURCES';
export const UPDATE_FEED_FOLLOWERS  = 'UPDATE_FEED_FOLLOWERS';
export const APPROVE_FEED_MODERATOR = 'APPROVE_FEED_MODERATOR';
export const DELETE_FEED_MODERATOR  = 'DELETE_FEED_MODERATOR';
export const RESIGN_FEED_MODERATOR  = 'RESIGN_FEED_MODERATOR';
export const MAKE_FEED_OWNER        = 'MAKE_FEED_OWNER';

export const UPDATE_FEED_OP_VISIBILITY  = 'UPDATE_FEED_OP_VISIBILITY';
export const UPDATE_FEED_OP_COMMENTS    = 'UPDATE_FEED_OP_COMMENTS';
export const UPDATE_FEED_OP_POSTS       = 'UPDATE_FEED_OP_POSTS';
export const UPDATE_FEED_OP_MEMBERS     = 'UPDATE_FEED_OP_MEMBERS';
export const UPDATE_FEED_OP_PAYMENT     = 'UPDATE_FEED_OP_PAYMENT';
export const UPDATE_FEED_OP_ANONYMITY   = 'UPDATE_FEED_OP_ANONYMITY';

export const UPDATE_FEED_COMMENTCONF    = 'UPDATE_FEED_COMMENTCONF';

export const UPDATE_FEED_TG_WALLET      = 'UPDATE_FEED_TG_WALLET';
export const UPDATE_FEED_TG_ADDRESS     = 'UPDATE_FEED_TG_ADDRESS';
export const UPDATE_FEED_TG_AMOUNT      = 'UPDATE_FEED_TG_AMOUNT';
export const UPDATE_FEED_TOKEN_ADDRESS  = 'UPDATE_FEED_TOKEN_ADDRESS';
export const UPDATE_FEED_TOKEN_AMOUNT   = 'UPDATE_FEED_TOKEN_AMOUNT';
export const UPDATE_FEED_SOCIALLINK     = 'UPDATE_FEED_SOCIALLINK';

export const SELECT_SOURCE              = 'SELECT_SOURCE';
export const ADD_SOURCE                 = 'ADD_SOURCE';
export const UPDATE_SOURCE              = 'UPDATE_SOURCE';
export const UPDATE_SOURCE_APPROVE      = 'UPDATE_SOURCE_APPROVE';
export const UPDATE_SOURCE_UPVOTES      = 'UPDATE_SOURCE_UPVOTES';
export const UPDATE_SOURCE_TRANSLATE    = 'UPDATE_SOURCE_TRANSLATE';

export const SELECT_ARTICLE         = 'SELECT_ARTICLE';
export const UPDATE_ARTICLE         = 'UPDATE_ARTICLE';
export const DELETE_ARTICLE         = 'DELETE_ARTICLE';
export const SELECT_COUNTRY         = 'SELECT_COUNTRY';
export const SELECT_BRANCH          = 'SELECT_BRANCH';
export const ADD_USERPOST           = 'ADD_USERPOST';
export const UPDATE_USERPOST        = 'UPDATE_USERPOST';
export const APPROVE_USERPOST       = 'APPROVE_USERPOST';
export const APPROVE_FEEDPOST       = 'APPROVE_FEEDPOST';

export const REFRESH_ARTICLES       = 'REFRESH_ARTICLES';
export const SET_ARTICLES           = 'SET_ARTICLES';
export const SET_ARTICLES_ON_FEED_MODERATION = 'SET_ARTICLES_ON_FEED_MODERATION';
export const REFRESH_ARTICLES_ON_FEED_MODERATION ='REFRESH_ARTICLES_ON_FEED_MODERATION';
export const APPEND_ARTICLES        = 'APPEND_ARTICLES';
export const APPEND_ARTICLES_ON_FEED_MODERATION = 'APPEND_ARTICLES_ON_FEED_MODERATION';
export const SET_SHOWFIRST_ARTICLES = 'SET_SHOWFIRST_ARTICLES';
export const SET_FEEDPOSTS          = 'SET_FEEDPOSTS';
export const REFRESH_FEEDPOSTS      = 'REFRESH_FEEDPOSTS';

export const REFRESH_PODCASTS       = 'REFRESH_PODCASTS';
export const SET_PODCASTS           = 'SET_PODCASTS';
export const APPEND_PODCASTS        = 'APPEND_PODCASTS';
export const SELECT_PODCAST         = 'SELECT_PODCAST';
export const UNSELECT_PODCAST       = 'UNSELECT_PODCAST';
export const DELETE_PODCAST         = 'DELETE_PODCAST';

export const REFRESH_VIDEOCASTS     = 'REFRESH_VIDEOCASTS';
export const SET_VIDEOCASTS         = 'SET_VIDEOCASTS';
export const APPEND_VIDEOCASTS      = 'APPEND_VIDEOCASTS';
export const SELECT_VIDEOCAST       = 'SELECT_VIDEOCAST';
export const UNSELECT_VIDEOCAST     = 'UNSELECT_VIDEOCAST';
export const DELETE_VIDEOCAST       = 'DELETE_VIDEOCAST';

export const SET_ARTICLE_PINS       = 'SET_ARTICLE_PINS';
export const CLS_ARTICLE_PINS       = 'CLS_ARTICLE_PINS';
export const PIN_ARTICLE            = 'PIN_ARTICLE';
export const UNDO_PIN_ARTICLE       = 'UNDO_PIN_ARTICLE';
export const SET_ARTICLE_MOVETOPS   = 'SET_ARTICLE_MOVETOPS';
export const CLS_ARTICLE_MOVETOPS   = 'CLS_ARTICLE_MOVETOPS';
export const MOVETOP_ARTICLE        = 'MOVETOP_ARTICLE';
export const UNDO_MOVETOP_ARTICLE   = 'UNDO_MOVETOP_ARTICLE';

export const REFRESH_SAVED_ARTICLES = 'REFRESH_SAVED_ARTICLES';
export const SET_SAVED_ARTICLES     = 'SET_SAVED_ARTICLES';
export const DELETE_SAVED_ARTICLE   = 'DELETE_SAVED_ARTICLE';

export const UPDATE_SEARCH_KEY      = 'UPDATE_SEARCH_KEY';
export const UPDATE_SEARCH_RESULT   = 'UPDATE_SEARCH_RESULT';
export const SELECT_SEARCH_ARTICLE  = 'SELECT_SEARCH_ARTICLE';

export const SET_FOLLOWING_FEEDS    = 'SET_FOLLOWING_FEEDS';
export const SET_FOLLOWING_FEED     = 'SET_FOLLOWING_FEED';
export const SET_SOURCE_FOLLOWERS   = 'SET_SOURCE_FOLLOWERS';

export const INS_FEED_SOURCE_UNFOLLOWER = 'INS_FEED_SOURCE_UNFOLLOWER';
export const DEL_FEED_SOURCE_UNFOLLOWER = 'DEL_FEED_SOURCE_UNFOLLOWER';
export const INS_FEED_SOURCE_SHOWRETWEET = 'INS_FEED_SOURCE_SHOWRETWEET';
export const DEL_FEED_SOURCE_SHOWRETWEET = 'DEL_FEED_SOURCE_SHOWRETWEET';

export const REFRESH_THREADS        = 'REFRESH_THREADS';
export const SET_THREADS            = 'SET_THREADS';
export const APPEND_THREADS         = 'APPEND_THREADS';
export const SELECT_THREAD          = 'SELECT_THREAD';
export const ADD_THREAD             = 'ADD_THREAD';
export const UPDATE_THREAD          = 'UPDATE_THREAD';
export const APPROVE_THREAD         = 'APPROVE_THREAD';
export const DELETE_THREAD          = 'DELETE_THREAD';
export const DELETE_THREAD_BY_FROM  = 'DELETE_THREAD_BY_FROM';
export const CLOSE_THREAD           = 'CLOSE_THREAD';
export const REMOVE_THREAD          = 'REMOVE_THREAD';

export const SET_THREAD_REPORTS     = 'SET_THREAD_REPORTS';
export const DELETE_THREAD_REPORT   = 'DELETE_THREAD_REPORT';

export const UPDATE_THREAD_UPVOTES  = 'UPDATE_THREAD_UPVOTES';

export const INSERT_BANNED_USER     = 'INSERT_BANNED_USER';
export const DELETE_BANNED_USER     = 'DELETE_BANNED_USER';
export const INSERT_PREAPPROVE_USER = 'INSERT_PREAPPROVE_USER';
export const DELETE_PREAPPROVE_USER = 'DELETE_PREAPPROVE_USER';

export const SELECT_USER            = 'SELECT_USER';

export const UPDATE_FEED_AICOMMENTCONF      = 'UPDATE_FEED_AICOMMENTCONF';
export const UPDATE_FEED_IMAGE              = 'UPDATE_FEED_IMAGE';
export const UPDATE_FEED_AISUMMARYCONF      = 'UPDATE_FEED_AISUMMARYCONF';
export const SET_FOLLOWED_THREADS           = 'SET_FOLLOWED_THREADS';
export const REFRESH_FOLLOWED_THREADS       = 'REFRESH_FOLLOWED_THREADS';
//For Followed Comments
export const SET_FOLLOWED_COMMENTS          =  'SET_FOLLOWED_COMMENTS';
export const REFRESH_FOLLOWED_COMMENTS      =  'REFRESH_FOLLOWED_COMMENTS';

// CleanAir Map related actions
export const SET_MAP_VIEW_MODE      = 'SET_MAP_VIEW_MODE';

export const SET_MAP_REGIONS        = 'SET_MAP_REGIONS';
export const INSERT_MAP_REGION      = 'INSERT_MAP_REGION';
export const DELETE_MAP_REGION      = 'DELETE_MAP_REGION';

export const SET_MAP_LOCATIONS      = 'SET_MAP_LOCATIONS';
export const SELECT_MAP_LOCATION    = 'SELECT_MAP_LOCATION';
export const INSERT_MAP_LOCATION    = 'INSERT_MAP_LOCATION';
export const UPDATE_MAP_LOCATION    = 'UPDATE_MAP_LOCATION';
export const DELETE_MAP_LOCATION    = 'DELETE_MAP_LOCATION';

export const UPDATE_MAP_LOCATION_OP_READINGS    = '';
export const UPDATE_MAP_LOCATION_OP_IMAGEUPLOAD = '';
export const UPDATE_MAP_LOCATION_OP_MODERATION  = '';
export const UPDATE_MAP_LOCATION_OP_DESCRIPTION = '';
export const UPDATE_MAP_LOCATION_OP_COMMENTS    = '';
export const UPDATE_MAP_LOCATION_READINGCONF = 'UPDATE_MAP_LOCATION_READINGCONF';

export const SET_LOCATION_GEOPOS    = 'SET_LOCATION_GEOPOS';
export const SELECT_LOCATION_TAB    = 'SELECT_LOCATION_TAB';
export const SELECT_LEADERBOARD_TAB = 'SELECT_LEADERBOARD_TAB';

export const SET_MAP_POSTS          = 'SET_MAP_POSTS';
export const APPEND_MAP_POSTS       = 'APPEND_MAP_POSTS';
export const SELECT_MAP_POST        = 'SELECT_MAP_POST';

export const SELECT_MAP_LOCATION_READING    = 'SELECT_MAP_LOCATION_READING';
export const SELECT_MAP_TAB                 = 'SELECT_MAP_TAB';

export const REFRESH_MAP_ARTICLES   = 'REFRESH_MAP_ARTICLES';
export const SET_MAP_ARTICLES       = 'SET_MAP_ARTICLES';
export const APPEND_MAP_ARTICLES    = 'APPEND_MAP_ARTICLES';


// UI Action Types
export const SELECT_THEME_MODE      = 'SELECT_THEME_MODE';
export const SHOW_SPLASH_SCREEN     = 'SHOW_SPLASH_SCREEN';

export const REQUEST_DATA_PENDING   = 'REQUEST_DATA_PENDING';
export const REQUEST_DATA_FINISHED  = 'REQUEST_DATA_FINISHED';

export const INIT_SCROLL_POS        = 'INIT_SCROLL_POS';
export const SAVE_SCROLL_POS        = 'SAVE_SCROLL_POS';
export const SHOW_TOP_NAVBAR        = 'SHOW_TOP_NAVBAR';
export const SHOW_BOTTOM_NAVBAR     = 'SHOW_BOTTOM_NAVBAR';

export const SELECT_FEED_TAB        = 'SELECT_FEED_TAB';
export const SELECT_PROFILE_TAB     = 'SELECT_PROFILE_TAB';

export const SELECT_DISCOVER_TAB    = 'SELECT_DISCOVER_TAB';
export const SELECT_MYFEEDS_TAB     = 'SELECT_MYFEEDS_TAB';

export const LOGIN_BACKROUTE        = 'LOGIN_BACKROUTE';
export const CATEGORY_BACK_ROUTE    = 'CATEGORY_BACK_ROUTE';
export const FEED_BACK_ROUTE        = 'FEED_BACK_ROUTE';
export const SOURCE_BACK_ROUTE      = 'SOURCE_BACK_ROUTE';
export const ARTICLE_BACK_ROUTE     = 'ARTICLE_BACK_ROUTE';
export const USER_BACK_ROUTE        = 'USER_BACK_ROUTE';
export const POSTS_BACK_ROUTE       = 'POSTS_BACK_ROUTE';

export const INIT_SEARCH_SCROLL_POS = 'INIT_SEARCH_SCROLL_POS';
export const SAVE_SEARCH_SCROLL_POS = 'SAVE_SEARCH_SCROLL_POS';

export const SET_TRENDING_BRANCH    = 'SET_TRENDING_BRANCH';
export const SET_TRENDING_COUNTRY   = 'SET_TRENDING_COUNTRY';
export const SET_TRENDING_TAGINDEX  = 'SET_TRENDING_TAGINDEX';

export const SET_FEED_CREATE_STATE  = 'SET_FEED_CREATE_STATE';

export const  SET_HISTORY_OF_QUERY_PROMPT = 'SET_HISTORY_OF_QUERY_PROMPT'
export const CLEAR_HISTORY_PROMPT = 'CLEAR_HISTORY_PROMPT'

export const  SET_ARTICLES_ON_SEARCH_TERM_SOURCE = 'SET_ARTICLES_ON_SEARCH_TERM_SOURCE'
export const APPEND_ARTICLES_ON_SEARCH_TERM_SOURCE = 'APPEND_ARTICLES_ON_SEARCH_TERM_SOURCE'

// Feed page view related actions
export const SET_REQUEST_GET_FEED_INFO  = 'SET_REQUEST_GET_FEED_INFO';
export const SET_REQUEST_GET_FEED_MODERATES = 'SET_REQUEST_GET_FEED_MODERATES';
export const SET_REQUEST_GET_ARTICLE_PINS_IN_FEEDS = 'SET_REQUEST_GET_ARTICLE_PINS_IN_FEEDS';
export const SET_REQUEST_GET_ARTICLES_SUMMARY = 'SET_REQUEST_GET_ARTICLES_SUMMARY';
export const SET_REQUEST_GET_SEARCH_TERM_SOURCE_ARTICLES = 'SET_REQUEST_GET_SEARCH_TERM_SOURCE_ARTICLES';
export const SET_REQUEST_GET_FEED_ARTICLES_OF_BRANCH = 'SET_REQUEST_GET_FEED_ARTICLES_OF_BRANCH';
export const SET_REQUEST_GET_FEED_ARTICLES_OF_COUNTRY = 'SET_REQUEST_GET_FEED_ARTICLES_OF_COUNTRY';
export const SET_REQUEST_GET_FEED_ARTICLES  = 'SET_REQUEST_GET_FEED_ARTICLES';
export const SET_REQUEST_GET_FEED_POSTS  = 'SET_REQUEST_GET_FEED_POSTS';
export const SET_REQUEST_GET_MAIN_INFO  = 'SET_REQUEST_GET_MAIN_INFO';
export const ADD_SOCKET= 'ADD_SOCKET';
export const DELETE_SOCKET= 'DELETE_SOCKET';
export const UPDATE_USER_SUMMARY_STATUS       = 'UPDATE_USER_SUMMARY_STATUS';
export const SET_LOGIN_STATUS ='SET_LOGIN_STATUS';