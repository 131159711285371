import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  InputBase,
} from "@material-ui/core";
import { THEME_MODE_LIGHT } from "constants/types";
import { check_source_link } from "utility/checklink";
import { ToastError } from "utility/toast";
import { 
  ARTICLE_BRANCH_INSTAGRAM, 
  ARTICLE_BRANCH_TIKTOK,
  ARTICLE_BRANCH_TWITTER, 
  ARTICLE_BRANCH_YOUTUBE 
} from "constants/branches";
import { logger } from "utility/logging";

const styles = (theme) => ({
  socialimage: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    padding: 4,
    width: 32,
    height: 32,
  },
  caption: {
    fontSize: 16,
    fontWeight: 500,
  },
  applybutton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  cancelbutton: {
    position: 'absolute',
    right: theme.spacing(5.5),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  text: {
    flex: 1,
    backgroundColor: theme.palette.background.card,
    paddingLeft: 4,
    paddingRight: 4,
    color: theme.palette.text.primary,
    margin: 0,
    "& input": {
      fontSize: 16,
      fontWeight: 18,
    },
  },
});

class DlgSocialLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleApply = () => {
    const { socialtype } = this.props;
    const { url } = this.state;
    if (!url.trim()) {
      this.props.onApply(socialtype, null);
      return;
    }

    const sociallink = check_source_link(url);
    logger.log("DlgSocialLink :", sociallink);
    if (!sociallink) {
      ToastError("Unsupported social link");
      return;
    }

    if (
      (socialtype === "youtube" && sociallink.branch !== ARTICLE_BRANCH_YOUTUBE) ||
      (socialtype === "twitter" && sociallink.branch !== ARTICLE_BRANCH_TWITTER) ||
      (socialtype === "instagram" && sociallink.branch !== ARTICLE_BRANCH_INSTAGRAM) ||
      (socialtype === "tiktok" && sociallink.branch !== ARTICLE_BRANCH_TIKTOK)
    ) {
      ToastError("Social type mismatch");
      return;
    }

    this.props.onApply(socialtype, url);
    this.setState({
      ...this.state,
      url: "",
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      url: "",
    });
    this.props.onClose();
  };

  render() {
    const { classes, open, theme, socialtype } = this.props;
    const { url } = this.state;

    const paper_style = {
      borderRadius: 10,
      borderStyle: "solid",
      borderColor: theme === THEME_MODE_LIGHT ? "black" : "white",
      borderWidth: "1px",
      backgroundColor: theme === THEME_MODE_LIGHT ? "white" : "black"
    };

    return (
      <Dialog
        open={open}
        aria-labelledby="social-link-dialog"
        aria-describedby="social-link-dialog"
        PaperProps={{ style: paper_style }}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <img
            className={classes.socialimage}
            alt={socialtype}
            src={`/static/images/icons/${theme}/${socialtype}.png`}
          />
          <Typography className={classes.caption} align="center">
            {`Add ${socialtype} channel`}
          </Typography>
          <IconButton
            onClick={this.handleClose}
            className={classes.cancelbutton}
          >
            <img
              className={classes.actionimg}
              alt="apply"
              src="/static/images/delete.png"
            />
          </IconButton>
          <IconButton
            onClick={this.handleApply}
            className={classes.applybutton}
          >
            <img
              className={classes.actionimg}
              alt="apply"
              src="/static/images/approve.png"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{padding: "0px 8px 16px 8px"}}>
          <InputBase
            id="room-url"
            className={classes.text}
            fullWidth
            name="url"
            placeholder="url"
            autoFocus={true}
            value={url}
            onChange={this.handleChange}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

DlgSocialLink.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  theme: PropTypes.string,
  socialtype: PropTypes.string,
  onClose: PropTypes.func,
  onApply: PropTypes.func
};

export default withStyles(styles)(DlgSocialLink);
