import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    justifyContent: "center",
    flexGrow: 1,
    // height:40,
  },
  grid: {
    justifyContent: "left",
    flexWrap: "inherit",
    marginLeft: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    margin: 0,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    fontSize: "14px",
    textTransform: "none",
    borderRadius: "10px",
    boxShadow: "none",
    paddingLeft: 8,
    paddingRight: 8,
    padding: 2,
    fontWeight: 600,
    textDecoration: "none",
    minWidth: 32,
    flexBasis: "calc(50% - 16px)", // Adjust based on your design
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100%",
      marginLeft: theme.spacing(0.2),
      // margin: theme.spacing(1),
    },
    "@media (max-width: 320px)": {
      margin: 0,
      margin: theme.spacing(0.2),
      marginLeft: theme.spacing(0.2),
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none",
    },
  },
  iconImageWithSpace: {
    height: "auto",
    width: 14,
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  iconImage: {
    height: "auto",
    width: 14,
    color: theme.palette.text.primary,
  },
});

class HeaderActionBar extends React.Component {
  render() {
    const { classes, theme_mode, hasPodcasts } = this.props;

    return (
      <div className={classes.root}>
      <Grid container className={classes.grid}>
          
       <Grid>
           <Button
             className={classes.button}
             variant="contained"
             size="small"
             style={{ minWidth: 32 }}
             onClick={this.props.onClickCleanAir}
           >
             <img
               className={classes.iconImageWithSpace}
               src={`/static/images/icons/${theme_mode}/cleanair.png`}
               alt="Cleanair Map"
             />
             Map
           </Button>
       </Grid>
        <Grid>
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              onClick={this.props.onClickSummary}
            >
              <img
                className={classes.iconImageWithSpace}
                src={`/static/images/summary.png`}
                alt="summary"
                style={{background: theme_mode === 'light' ? "#10a37f" : undefined}}
              />
              AI Summary
           </Button>
        </Grid>
      <Grid>
           <Button
              className={classes.button}
              variant="contained"
              size="small"
              onClick={this.props.onClickPopular}
            >
              <img
                className={classes.iconImageWithSpace}
                src={`/static/images/icons/${theme_mode}/star1.png`}
                alt="popular"
                
              />
             Popular
           </Button>
      </Grid>
        <Grid>
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              onClick={this.props.onClickDiscover}
            >
              <img
                className={classes.iconImageWithSpace}
                src={`/static/images/icons/${theme_mode}/discover.png`}
                alt="Discover"
              />
              Explore
           </Button>
        </Grid>
         

           {/* <Button
            className={classes.button}
            variant="contained"
            size="small"
            style={{ minWidth: 32 }}
            onClick={this.props.onClickCreate}
          >
            <img
              className={classes.iconImage}
              src={`/static/images/icons/${theme_mode}/plus.png`}
              alt="Create"
            />
          </Button>

          <Button
            className={classes.button}
            variant="contained"
            size="small"
            style={{ minWidth: 32 }}
            disabled={!hasPodcasts}
            onClick={this.props.onClickPodcasts}
          >
            <img
              className={classes.iconImage}
              src={`/static/images/icons/${theme_mode}/podcast.png`}
              alt="Podcasts"
            />
          </Button> */}

          {/* <Button
            className={classes.button}
            variant="contained"
            size="small"
            style={{ minWidth: 32 }}
            disabled={false}
            onClick={this.props.onClickVideocasts}
          >
            <img
              className={classes.iconImage}
              src={`/static/images/icons/${theme_mode}/videocast.png`}
              alt="Videocasts"
            />
          </Button> */}

          {/* <Button
            className={classes.button}
            variant="contained"
            size="small"
            style={{ minWidth: 32 }}
            disabled={false}
            onClick={this.props.onClickBirdtok}
          >
            <img
              className={classes.iconImage}
              src={`/static/images/icons/birdtok.png`}
              alt="Birdtok"
            />
          </Button> */}
        </Grid>
      </div>
    );
  }
}

HeaderActionBar.propTypes = {
  classes: PropTypes.object,
  hasPodcasts: PropTypes.bool,
  onClickDiscover: PropTypes.func,
  onClickCreate: PropTypes.func,
  onClickPodcasts: PropTypes.func,
  onClickCleanAir: PropTypes.func,
  onClickVideocasts: PropTypes.func,
  // onClickBirdtok: PropTypes.func,
};

const mapStateToProps = (state) => ({
  theme_mode: state.uiState.theme_mode,
});

export default connect(mapStateToProps)(withStyles(styles)(HeaderActionBar));
