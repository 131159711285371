import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    // height: '100%',
    position: "relative",
    width: 32,
    cursor: "pointer",
  },
  container: {
    textAlign: "center",
  },
  image: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.background.default,
  },
  title: {
    textTransform: "none",
    fontSize: "10px",
    lineHeight: "14px",
    color: theme.palette.text.primary,
  },
});

class PubPrivBtn extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickStatus = this.handleClickStatus.bind(this);
  }

  handleClickStatus = () => {
    const { publicOnly, onChangeMode } = this.props;
    if (publicOnly || !onChangeMode) {
      return;
    }

    const mode = this.props.mode === 0 ? 1 : 0;
    //   mode: 0, // 0: public mode, 1: private mode
    onChangeMode(mode);
  };

  render() {
    const { classes, theme, publicOnly, mode, onClick } = this.props;

    return (
      <div className={classes.root}>
        {publicOnly && (
          <div className={classes.container}>
            <img 
              className={classes.image} 
              src={`/static/images/icons/${theme}/public.png`} 
              alt={"public"} 
            />
            <Typography className={classes.title}>{"public"}</Typography>
          </div>
        )}
        {!publicOnly && mode === 0 && (
          <div className={classes.container} onClick={onClick ? onClick : this.handleClickStatus}>
            <img 
              className={classes.image} 
              src={`/static/images/icons/${theme}/public.png`} 
              alt={"public"} 
            />
            <Typography className={classes.title}>{"Public"}</Typography>
          </div>
        )}
        {!publicOnly && mode === 1 && (
          <div className={classes.container} onClick={onClick ? onClick : this.handleClickStatus}>
            <img 
              className={classes.image} 
              src={`/static/images/icons/${theme}/private.png`} 
              alt={"private"} 
            />
            <Typography className={classes.title}>{"Private"}</Typography>
          </div>
        )}
      </div>
    );
  }
}

PubPrivBtn.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  publicOnly: PropTypes.bool,
  mode: PropTypes.number,
  onClick: PropTypes.func,
  onChangeMode: PropTypes.func
};

export default withStyles(styles)(PubPrivBtn);
