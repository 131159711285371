export const QUERY_TOP_READING_COMMENTS_BY_NEWEST = `
query top_reading_comments_by_newest(
  $reading_id: Int!,
  $pagesize: Int!,
  $offset: Int!
) {
  comments (
    where: {
      reading_id: {_eq: $reading_id},
      parent_id: {_is_null: true}
    },
    order_by: {created_at: desc},
    limit: $pagesize,
    offset: $offset
  ) {
    id
    article_id
    reading_id
    thread_id
    parent_id
    created_at
    modified_at
    text
    feed_id
    author {
      uid
      image
      username
      biography
    }
    recommends
    approved
    children_aggregate ( 
      order_by: {created_at: desc} 
    ) {
      aggregate { count }
    }
    children (
      order_by: {created_at: desc},
      limit: 1,
      offset: 0
    ) {
      id
      text
      created_at
      recommends
      approved
      author {
        uid
        image
        username
        biography
      }
    }
  }
}`;

export const QUERY_TOP_READING_COMMENTS_BY_NEWEST_APPROVE = `
query top_reading_comments_by_newest(
  $reading_id: Int!,
  $approved: Boolean,
  $pagesize: Int!,
  $offset: Int!
) {
  comments (
    where: {
      reading_id: {_eq: $reading_id},
      parent_id: {_is_null: true},
      approved: {_eq: $approved}
    },
    order_by: {created_at: desc},
    limit: $pagesize,
    offset: $offset
  ) {
    id
    article_id
    reading_id
    thread_id
    parent_id
    created_at
    modified_at
    text
    feed_id
    author {
      uid
      image
      username
      biography
    }
    recommends
    approved
    children_aggregate ( 
      where: {
        approved: {_eq: $approved}
      },
      order_by: {created_at: desc} 
    ) {
      aggregate { count }
    }
    children (
      where: {
        approved: {_eq: $approved}
      },
      order_by: {created_at: desc},
      limit: 1,
      offset: 0
    ) {
      id
      text
      created_at
      recommends
      approved
      author {
        uid
        image
        username
        biography
      }
    }
  }
}`;


export const QUERY_TOP_READING_COMMENTS_BY_OLDEST = `
query top_reading_comments_by_oldest(
  $reading_id: Int!,
  $pagesize: Int!,
  $offset: Int!
) {
  comments (
    where: {
      reading_id: {_eq: $reading_id},
      parent_id: {_is_null: true}
    },
    order_by: {created_at: asc},
    limit: $pagesize,
    offset: $offset
  ) {
    id
    article_id
    reading_id
    thread_id
    parent_id
    created_at
    modified_at
    text
    feed_id
    author {
      uid
      image
      username
      biography
    }
    recommends
    approved
    children_aggregate ( 
      order_by: {created_at: desc} 
    ) {
      aggregate { count }
    }
    children (
      order_by: {created_at: desc},
      limit: 1,
      offset: 0
    ) {
      id
      text
      created_at
      recommends
      approved
      author {
        uid
        image
        username
        biography
      }
    }
  }
}`;

export const QUERY_TOP_READING_COMMENTS_BY_OLDEST_APPROVE = `
query top_reading_comments_by_oldest(
  $reading_id: Int!,
  $approved: Boolean,
  $pagesize: Int!,
  $offset: Int!
) {
  comments (
    where: {
      reading_id: {_eq: $reading_id},
      parent_id: {_is_null: true},
      approved: {_eq: $approved}
    },
    order_by: {created_at: asc},
    limit: $pagesize,
    offset: $offset
  ) {
    id
    article_id
    reading_id
    thread_id
    parent_id
    created_at
    modified_at
    text
    feed_id
    author {
      uid
      image
      username
      biography
    }
    recommends
    approved
    children_aggregate ( 
      where: {
        approved: {_eq: $approved}
      },
      order_by: {created_at: desc} 
    ) {
      aggregate { count }
    }
    children (
      where: {
        approved: {_eq: $approved}
      },
      order_by: {created_at: desc},
      limit: 1,
      offset: 0
    ) {
      id
      text
      created_at
      recommends
      approved
      author {
        uid
        image
        username
        biography
      }
    }
  }
}`;

export const QUERY_TOP_READING_COMMENTS_BY_RECOMMENDS = `
query top_reading_comments_by_recommends(
  $reading_id: Int!,
  $pagesize: Int!,
  $offset: Int!
) {
  comments (
    where: {
      reading_id: {_eq: $reading_id},
      parent_id: {_is_null: true}
    },
    order_by: {recommends: desc},
    limit: $pagesize,
    offset: $offset
  ) {
    id
    article_id
    reading_id
    thread_id
    parent_id
    created_at
    modified_at
    text
    feed_id
    author {
      uid
      image
      username
      biography
    }
    recommends
    approved
    children_aggregate ( 
      order_by: {created_at: desc} 
    ) {
      aggregate { count }
    }
    children (
      order_by: {created_at: desc},
      limit: 1,
      offset: 0
    ) {
      id
      text
      created_at
      recommends
      approved
      author {
        uid
        image
        username
        biography
      }
    }
  }
}`;

export const QUERY_TOP_READING_COMMENTS_BY_RECOMMENDS_APPROVE = `
query top_reading_comments_by_recommends(
  $reading_id: Int!,
  $approved: Boolean,
  $pagesize: Int!,
  $offset: Int!
) {
  comments (
    where: {
      reading_id: {_eq: $reading_id},
      parent_id: {_is_null: true},
      approved: {_eq: $approved}
    },
    order_by: {recommends: desc},
    limit: $pagesize,
    offset: $offset
  ) {
    id
    article_id
    reading_id
    thread_id
    parent_id
    created_at
    modified_at
    text
    feed_id
    author {
      uid
      image
      username
      biography
    }
    recommends
    approved
    children_aggregate ( 
      where: {
        approved: {_eq: $approved}
      },
      order_by: {created_at: desc} 
    ) {
      aggregate { count }
    }
    children (
      where: {
        approved: {_eq: $approved}
      },
      order_by: {created_at: desc},
      limit: 1,
      offset: 0
    ) {
      id
      text
      created_at
      recommends
      approved
      author {
        uid
        image
        username
        biography
      }
    }
  }
}`;

export const QUERY_TOP_READING_COMMENTS_BY_REPLIES = `
query top_reading_comments_by_replies(
  $reading_id: Int!,
  $pagesize: Int!,
  $offset: Int!
) {
  comments (
    where: {
      reading_id: {_eq: $reading_id},
      parent_id: {_is_null: true}
    },
    order_by: {children_aggregate: {count: desc}},
    limit: $pagesize,
    offset: $offset
  ) {
    id
    article_id
    reading_id
    thread_id
    parent_id
    created_at
    modified_at
    text
    feed_id
    author {
      uid
      image
      username
      biography
    }
    recommends
    approved
    children_aggregate ( 
      order_by: {created_at: desc} 
    ) {
      aggregate { count }
    }
    children (
      order_by: {created_at: desc},
      limit: 1,
      offset: 0
    ) {
      id
      text
      created_at
      recommends
      approved
      author {
        uid
        image
        username
        biography
      }
    }
  }
}`;

export const QUERY_TOP_READING_COMMENTS_BY_REPLIES_APPROVE = `
query top_reading_comments_by_replies(
  $reading_id: Int!,
  $approved: Boolean,
  $pagesize: Int!,
  $offset: Int!
) {
  comments (
    where: {
      reading_id: {_eq: $reading_id},
      parent_id: {_is_null: true},
      approved: {_eq: $approved}
    },
    order_by: {children_aggregate: {count: desc}},
    limit: $pagesize,
    offset: $offset
  ) {
    id
    article_id
    reading_id
    thread_id
    parent_id
    created_at
    modified_at
    text
    feed_id
    author {
      uid
      image
      username
      biography
    }
    recommends
    approved
    children_aggregate ( 
      where: {
        approved: {_eq: $approved}
      },
      order_by: {created_at: desc} 
    ) {
      aggregate { count }
    }
    children (
      where: {
        approved: {_eq: $approved}
      },
      order_by: {created_at: desc},
      limit: 1,
      offset: 0
    ) {
      id
      text
      created_at
      recommends
      approved
      author {
        uid
        image
        username
        biography
      }
    }
  }
}`;