import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { setError } from "./base";
import { GRAPHQL_ERROR } from "constants/types";
import { 
  ACTIVITY_DELETE,
  ACTIVITY_REPORT, 
  ACTIVITY_TYPE_CLEANAIRMAP, 
} from "constants/activity";

const recommendReading = async (reading) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  gqlservice.set_auth_jwt(token);
  let result = await gqlservice.map_reading_recommender(reading.id, authUser.uid);
  if (result.status_code === GRAPHQL_ERROR) {
    this.setError("Error getting map reading recommender");
    return;
  }
  const recommended = result.data.reading_recommenders.length > 0;

  gqlservice.set_auth_jwt(token, true);
  if (recommended) {
    result = await gqlservice.map_reading_dec_recommends(reading.id, authUser.uid);
  } else {
    result = await gqlservice.map_reading_inc_recommends(reading.id, authUser.uid);
  }
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const { selected_location } = state.mapState;
  const updated_reading = result.data.update_readings.returning[0];
  const readings = selected_location.readings.map(reading => reading.id === updated_reading.id ? updated_reading : reading);
  const location = {
    ...selected_location,
    readings: readings
  };
  store.dispatch({
    type: ActionTypes.UPDATE_MAP_LOCATION,
    location: location,
  });
}

const reportReading = async (reading, reportMsg) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  let report = {
    reading_id: reading.id,
    location_id: reading.location_id,
    report: reportMsg,
    reported_by: authUser.uid,
    approved: false
  };

  let result = await gqlservice.insert_reading_report(report);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  result = await gqlservice.map_location_by_id(reading.location_id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  store.dispatch({
    type: ActionTypes.SELECT_MAP_LOCATION,
    location: result.data.locations[0],
  });

  const { selected_location } = state.mapState;
  // log this activity
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_CLEANAIRMAP,
    type_id: selected_location.id.toString(),
    action: ACTIVITY_REPORT,
    object: `the report ${reportMsg}`,
    fromto: `to location ${selected_location.name}`,
    reason: "",
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

const deleteReading = async (reading) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token, true);

  let result = await gqlservice.delete_map_reading(reading.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const { selected_location } = state.mapState;
  let updated_location = selected_location;
  updated_location.readings = updated_location.readings.filter(item => 
    item.id !== reading.id
  );
  store.dispatch({
    type: ActionTypes.UPDATE_MAP_LOCATION,
    location: updated_location,
  });

  // log this activity
  gqlservice.set_auth_jwt(token, false);
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_CLEANAIRMAP,
    type_id: selected_location.id.toString(),
    action: ACTIVITY_DELETE,
    object: `the reading ${reading.id}`,
    fromto: `of the location ${selected_location.name}`,
    reason: ''
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

export { 
  recommendReading,
  reportReading,
  deleteReading,
};