import { 
    FEED_COMMENT_DISABLE,
    FEED_COMMENT_CLOSEALL,
    FEED_COMMENT_TWITTERUSER,
} from "constants/types"
import { have_twitter } from "./user"


export const is_feed_member = (feed, loggedIn, authUser) => {
    if (!loggedIn) return false;
    if (feed.feed_followers.length === 0) return false;
    return feed.feed_followers.find(follower => follower.user_id === authUser.uid) !== undefined;
}

export const is_comment_enabled_feed = (feed, loggedIn, authUser) => {
    return (
        (feed.op_comments || is_feed_member(feed, loggedIn, authUser)) &&
        feed.comment_conf !== FEED_COMMENT_DISABLE && feed.comment_conf !== FEED_COMMENT_CLOSEALL && 
        (feed.comment_conf !== FEED_COMMENT_TWITTERUSER || 
            (feed.comment_conf === FEED_COMMENT_TWITTERUSER && have_twitter(loggedIn, authUser))
        )
    );
};