import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import MastodonPosts from "components/MastodonPosts/MastodonPosts";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
});

class MastodonSharedPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      reportDlg: false,
      loginDlg: false,
      shareDlg: false,
    };
  }

  render() {
    const {
      classes,
      posts,
    } = this.props;

    return (
      <div className={classes.root}>
        <MastodonPosts posts={posts} />
      </div>
    );
  }
}

MastodonSharedPosts.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  sources: state.dataState.sources,
  selected_feed: state.dataState.selected_feed,
});
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(MastodonSharedPosts);
