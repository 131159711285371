export const story1 = `
    <audio controls autoplay hidden>
        <source src="/static/audio/2.mp3" type="audio/mpeg">
        unsupported !! 
    </audio>
    <h1>Instagram and Facebook to get paid-for verification</h1>
    <p>The move comes after Twitter introduced a subscription-based verification service last year.</p>
    <p>Or here, an image!</p>
    <br />
    <img
        style="display:block;max-width:100%;border-radius:4px;"
        src="https://firebasestorage.googleapis.com/v0/b/newsraven-kashif.appspot.com/o/images%2Farticles%2FBBC%2F8da93d0d1a19687c8210451f86f5f1fc_320.jpg?alt=media"
    ></img>
    <h3>Perfect. But there's more! →</h3>
`;

export const story2 = `
    <div style="position:relative;">
        <img src="https://picsum.photos/1080/1920" alt="photo" style="width:100%;" loading="lazy">
        <div style="position:absolute;bottom:20px;right:20px;background-color:black;color:white;padding-left:20px;padding-right:20px;">
            <h4>Nature</h4>
            <p>What a beautiful sunrise</p>
        </div>
    </div>
`;

export const story4 = `
<div style="width:100%;padding:20px;background:Aquamarine;color:#333;height:100%;">
    <h1>The Indian comedians under fire from Hindu nationalists - BBC News</h1>
    <br />
    <img
        style="display:block;max-width:100%;border-radius:4px;"
        src="https://firebasestorage.googleapis.com/v0/b/newsraven-kashif.appspot.com/o/images%2Farticles%2FBBC%20News%2F74eb10bc49ebf0b1f1cc2b195de7568c_org.jpg?alt=media"
    ></img>
    <p>Two prominent Indian stand-up comedians, Kunal Kamra and Munawar Faruqui, have said it’s becoming unsafe to perform after they received threats from right-wing Hindu nationalists.Faruqui, who is Muslim, was jailed earlier this year for jokes he didn’t even tell.Authorities say the arrest was in the interest of law and order, and to protect religious sentiments.But critics argue that laws are being misused to silence free speech, especially among critics of Prime Minister Narendra Modi.</p>
</div>
`;