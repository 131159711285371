// notification type
export const NOTIFICATION_FEED_APPROVE = 0;
export const NOTIFICATION_SOURCE_APPROVE = 1;
export const NOTIFICATION_POST_APPROVE = 2;
export const NOTIFICATION_THREAD_APPROVE = 3;
export const NOTIFICATION_FEED_APPROVE_MODERATOR = 4;
export const NOTIFICATION_FEED_INVITE = 5;
export const NOTIFICATION_COMMENT_APPROVE = 6;
export const NOTIFICATION_COMMENT_REPLY = 7;
export const NOTIFICATION_FEED_SUBSCRIBE = 8;
export const NOTIFICATION_MESSAGE_MODERATOR = 9;
export const NOTIFICATION_MAP_LOCATION_APPROVE = 10;
export const NOTIFICATION_MAP_REGION_APPROVE = 11;
export const NOTIFICATION_CATEGORY_APPLY_MODERATOR = 12;
export const NOTIFICATION_CATEGORY_RESIGN_MODERATOR = 13;
export const NOTIFICATION_FEED_APPLY_MODERATOR = 14;
export const NOTIFICATION_FEED_RESIGN_MODERATOR = 15;
export const NOTIFICATION_MAP_LOCATION_APPLY_MODERATOR = 16;
export const NOTIFICATION_MAP_REGION_APPLY_MODERATOR = 17;
