import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withRouter } from "react-router-dom";
import { withFirebase } from "services";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withAuthentication, withAuthorization } from "session";
import { BasicAppBar, FeedEditCard, WaitingSpinner } from "components";
import { GraphqlService } from "services";
import * as ROUTES from "constants/routes";
import {
  MAX_WINDOW_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
  SOURCE_SUGGESTIONS,
} from "constants/types";
import {
  get_branch_name,
  get_branch,
  is_weblink_branch,
} from "constants/branches";
import { existSourcebyID, getAuthToken, getFeedInfo, isFeedModerator, updateCache } from "dataapis";
import { get_link_source, isSourceApprovedbyAI } from "utility/ravenapi";
import { v4 as uuidv4 } from "uuid";
import { gen_random_int, slugify } from "utility/utils";
// import { request_crawlsource } from "utility/ravenapi";
import { ToastError } from "utility/toast";
import { moderate_image } from "utility/ravenapi";
import { resizeImageFile } from "utility/resizeimage";
import { logger } from "utility/logging";

const condition = (authUser) => !!authUser && authUser.uid !== "";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: theme.breakpoints.lg,
    maxWidth: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
  },
  container: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  feedItem: {
    display: "flex",
    padding: 0,
  },
  detail: {
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  name: {
    display: "block",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    border: 0,
    fontSize: 20,
    "&:focus": {
      outline: "0px",
    },
    "&::placeholder": {
      color: theme.palette.text.primary,
    },
    "&::-webkit-input-placeholder": {
      color: theme.palette.text.primary,
    },
    "&:-ms-input-placeholder": {
      color: theme.palette.text.primary,
    },
  },
  description: {
    display: "block",
    height: 70,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    border: 0,
    fontSize: 14,
    padding: 0,
    marginTop: 4,
    fontFamily: "Roboto",
    "&:focus": {
      outline: "0px",
    },
  },
  applybtn: {
    //position: "absolute",
    position: "fixed",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    }
  },
  applybtn_disabled: {
    position: "fixed",
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
});

class FeedReview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feed_sources: [],
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateLink = this.handleUpdateLink.bind(this);
    this.handleDeleteLink = this.handleDeleteLink.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const { temp_feed_sourcelinks } = this.props;
    logger.log("temp feed source links :", temp_feed_sourcelinks);

    this.setWaiting(true);

    let feed_sources = [];
    let index = 0;
    for (let source_link of temp_feed_sourcelinks) {
      const link_preview = await get_link_source(source_link.url);
      if (!link_preview) {
        feed_sources.push({
          name: "",
          description: "",
          image: RAVEN_PLACEHOLDER_IMAGE,
          linkImage: "",
          imageUpload: "",
          link: {
            id: index,
            branch: source_link.branch,
            socialtype: source_link.type,
            tag: source_link.tag,
            source: source_link.url,
            weblink: "",
          },
        });
      } else {
        const branchinfo = get_branch(source_link.branch);
        let image = RAVEN_PLACEHOLDER_IMAGE;
        if (branchinfo) {
          image = `/static/images/icons/light/${branchinfo.image}`;
        }
        feed_sources.push({
          name: link_preview.title === undefined ? "" : link_preview.title,
          description:
            link_preview.description === undefined
              ? ""
              : link_preview.description,
          image: link_preview.image !== "" ? link_preview.image : image,
          linkImage: link_preview.image !== "" ? link_preview.image : "",
          imageUpload: "",
          link: {
            id: index,
            branch: source_link.branch,
            socialtype: source_link.type,
            tag:
              source_link.type === "podcast_channel_feed"
                ? link_preview.url
                : source_link.tag,
            source:
              source_link.type === "podcast_channel_feed"
                ? link_preview.url
                : source_link.url,
            weblink: "",
          },
        });
        logger.log("feed_sources :", feed_sources);
      }
      index++;

      // Don't to wait for a long time
      this.setState({
        ...this.state,
        feed_sources: feed_sources,
      });
      // this.setWaiting(false);
    }

    this.setWaiting(false);

    // this.setState({
    //   ...this.state,
    //   feed_sources: feed_sources
    // });
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    // shouldn't go back
    // this.props.clearTempFeedSourceLinks();
    // this.props.history.goBack();
  };

  handleConfirm = async () => {
    const { feed_sources } = this.state;

    // check if all the sources are correct
    for (let feed_source of feed_sources) {
      if (
        feed_source.name.trim() === "" ||
        // feed_source.image === RAVEN_PLACEHOLDER_IMAGE ||
        feed_source.link.source === ""
      ) {
        this.setError("A source must have name, image and url");
        return;
      }
      // if (is_weblink_branch(feed_source.branch) && !feed_source.weblink) {
      //   this.setError("RSS or Podcast source must have web link");
      //   return;
      // }
    }

    // Add feed sources
    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    for (let feed_source of feed_sources) {
      await this.addFeedSource(gqlservice, feed_source);
    }

    const { selected_feed } = this.props;
    await getFeedInfo(selected_feed.slug);
    await updateCache();

    this.setWaiting(false);

    const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/inviteuser`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  addFeedSource = async (gqlservice, feed_source) => {
    const {
      authUser,
      firebase,
      selected_category,
      selected_feed,
      socialtypes,
    } = this.props;

    let imageUrl = feed_source.linkImage;
    let thumb_image = feed_source.linkImage;
    if (feed_source.imageUpload) {
      const flag = true;
      const resized_image = await resizeImageFile(feed_source.imageUpload, flag);
      const uploadPromises = resized_image.map(async (image, index) => {
        const result = await firebase.uploadImage(image, "feeds");
        console.log(`Uploaded image ${index}:`, result);
        return result;
      }); 
      const uploadResults = await Promise.all(uploadPromises);
      console.log("Upload results:", uploadResults);

      if (uploadResults.some((result) => result.error)) {
        this.setError("Failed to upload source image.");
        return;
      }
      thumb_image = uploadResults[0].url;
      imageUrl = uploadResults[1].url;
      const modresult = await moderate_image(imageUrl);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError(
          "Image not allowed, because it contains adults or racy content."
        );
        await firebase.deleteImage(imageUrl);
        await firebase.deleteImage(thumb_image);
        // return;
        imageUrl = "";
        thumb_image = "";
      }
    }

    let id = "";
    const source_name = feed_source.name.trim();
    const socialtype = socialtypes.find(
      (item) => item.id === feed_source.link.socialtype
    );
    const id_slug = slugify(socialtype.id);
    const name_slug = slugify(source_name);
    id = await this._makeSourceID(id_slug + '-' + name_slug);
    if (!id) {
      return;
    }

    let slug = name_slug;
    const branch_name = get_branch_name(socialtype.branch);
    if (branch_name.length > 0) {
      slug += "-" + branch_name;
    }

    let socialtag = {
      id: id,
      type: socialtype.id,
      tag: feed_source.link.tag,
    };
    const isAiModerationEnabled =
      JSON.parse(selected_feed.ai_moderation) === null
        ? false
        : JSON.parse(selected_feed.ai_moderation).some(
            (item) => item === SOURCE_SUGGESTIONS
          );
    let approvedFromAi;
    if (isAiModerationEnabled) {
      const topSources = this.getTopSources();
      const params = {
        name: selected_feed.name,
        description: selected_feed.description,
        userName: authUser.name,
        userBio: authUser.biography,
        userKarmaScore: authUser?.articles_voted?.length,
        userId: authUser.uid,
        topSources: topSources,
        userPostedSources: {
          name: feed_source?.name,
          description: feed_source?.description,
          socialMediaType: branch_name,
        },
      };
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      try {
        await isSourceApprovedbyAI(params, token)
          .then(
            (result) => {
              if (result.status === 200) {
                if (result.message.decision.toLowerCase() === "denied") {
                  console.log(
                    "Response from ai :",
                    JSON.stringify(result.message.reason)
                  );
                  this.setError(JSON.stringify(result.message.reason));
                  throw new Error("Denied"); // Throw an error to exit the Promise chain
                } else {
                  approvedFromAi = true;
                }
              }
            },
            (reason) => {
              this.setError(reason.msg);
              throw new Error("Error"); // Throw an error to exit the Promise chain
            }
          )
          .catch((err) => {
            this.setError(JSON.stringify(err.Error));
            throw new Error(err); // Throw an error to exit the Promise chain
          });
      } catch (err) {
        console.log("The source is denied", err);
        return;
      }
    }
    const isModerator = isFeedModerator(selected_feed);
    const approved = isAiModerationEnabled
      ? approvedFromAi
      : isModerator || !selected_feed.private;

    const timestamp = Math.floor(Date.now() / 1000);
    const source = {
      id: id,
      category_id: selected_category.id,
      name: source_name,
      description: feed_source.description?.trim() || "",
      slug: slug,
      image: imageUrl,
      thumbnail: thumb_image,
      branch: feed_source.link.branch,
      weblink: "",
      translateLang: "English",
      translateAPI: "",
      translate: false,
      frequency: 24,
      disableFullText: false,
      followers: 0,
      created_by: authUser.uid,
      created_at: timestamp,
      approved: approved,
    };

    const currentTime = new Date().toISOString();
    const feed_source_info = {
      id: uuidv4(),
      feed_id: selected_feed.id,
      source_id: source.id,
      created_by: authUser.uid,
      approved: approved,
      approved_by: approved ? authUser.uid : null,
      approved_at: approved ? currentTime : null,
    };
    const scrape_request_info = {
      name: source.name,
      image: source.image,
      feed_id: selected_feed.id,
      source_id: source.id,
      requested_by: authUser.uid
    }

    await gqlservice
      .insert_source(source)
      .then((result) => {
        return gqlservice.insert_socialtag(socialtag);
      })
      .then((result) => {
        return gqlservice.insert_feed_sources([feed_source_info]);
      })
      .then((result) => {
        return gqlservice.source_by_id(source.id);
      })
      .then((result) => {
        const sources = result.data.sources;
        if (sources.length > 0) {
          this.props.addSource(sources[0]);
          return sources[0];
        }
      })
      .then(() => {
        return gqlservice.insert_scrape_request(scrape_request_info);
      })
      .then(
        (result) => {},
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
  };

  _makeSourceID = async (id) => {
    let result = await existSourcebyID(id);
    if (result.error) {
      this.setError(result.message);
      return "";
    }

    var rand_id = "";
    while (result.exist) {
      rand_id = `${id}-${gen_random_int(1000).toString()}`;
      result = await existSourcebyID(rand_id);
      if (result.error) {
        this.setError(result.message);
        return "";
      }
    }

    return rand_id ? rand_id : id;
  }

  // for setting topoSources
  getTopSources = () => {
    const { selected_feed } = this.props;
    let topSources = [];
    const totalCount =
      selected_feed?.feed_sources?.length >= 9
        ? 9
        : selected_feed?.feed_sources?.length;
    for (let i = 0; i < totalCount; i++) {
      const branch_name = get_branch_name(
        selected_feed?.feed_sources[i]?.source?.branch
      );
      if (
        selected_feed?.feed_sources[i] &&
        selected_feed?.feed_sources[i].source
      ) {
        let new_top_source = {
          name: selected_feed?.feed_sources[i].source?.name,
          description: selected_feed?.feed_sources[i].source?.description,
          socialMediaType: branch_name,
        };
        topSources.push(new_top_source);
      }
    }
    return topSources;
  };

  handleImageChange = (id, target) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      const new_feed_sources = this.state.feed_sources.map((feed_source) => {
        if (feed_source.link.id === id) {
          feed_source.image = e.target.result;
          feed_source.imageUpload = target.files[0];
          feed_source.linkImage = "";
        }
        return feed_source;
      });

      this.setState({
        ...this.state,
        feed_sources: new_feed_sources,
      });
    };
  };

  handleChange = (id, event) => {
    const new_feed_sources = this.state.feed_sources.map((feed_source) => {
      if (feed_source.link.id === id) {
        feed_source[event.target.name] = event.target.value;
      }
      return feed_source;
    });

    this.setState({
      ...this.state,
      feed_sources: new_feed_sources,
    });
  };

  handleUpdateLink = (id, link) => {
    const new_feed_sources = this.state.feed_sources.map((feed_source) => {
      if (feed_source.link.id === id) {
        feed_source.link.branch = link.branch;
        feed_source.link.socialtype = link.socialtype;
        feed_source.link.source = link.source;
        feed_source.link.weblink = link.weblink;
      }
      return feed_source;
    });

    this.setState({
      ...this.state,
      feed_sources: new_feed_sources,
    });
  };

  handleDeleteLink = (id) => {
    const new_feed_sources = this.state.feed_sources.filter(
      (feed_source) => feed_source.link.id !== id
    );

    this.setState({
      ...this.state,
      feed_sources: new_feed_sources,
    });
  };

  render() {
    const { classes, socialtypes, theme_mode, requesting } = this.props;
    const { feed_sources } = this.state;

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    const confirmButtonPos = (width - 260) / 2;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar title={"Review"} onNavBack={this.handleNavBack} />
        </div>

        <WaitingSpinner open={requesting} />

        <div m={1} className={classes.container}>
          {feed_sources.length > 0 &&
            feed_sources.map((source, index) => (
              <div key={`feed-source-${index}`}>
                <FeedEditCard
                  theme_mode={theme_mode}
                  socialtypes={socialtypes}
                  id={source.link.id}
                  name={source.name}
                  description={source.description}
                  image={source.thumbnail || source.image || RAVEN_PLACEHOLDER_IMAGE}
                  linkImage={source.linkImage}
                  link={source.link}
                  onChange={this.handleChange}
                  onImageChange={this.handleImageChange}
                  onUpdateLink={this.handleUpdateLink}
                  onDeleteLink={this.handleDeleteLink}
                />
              </div>
            ))}
        </div>

        <Button
          className={requesting ? classes.applybtn_disabled : classes.applybtn}
          style={{ left: confirmButtonPos }}
          onClick={requesting ? null : this.handleConfirm}
        >
          Confirm
        </Button>

        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  socialtypes: state.dataState.socialtypes,
  selected_category: state.dataState.selected_category,
  feeds: state.dataState.feeds,
  sources: state.dataState.sources,
  selected_feed: state.dataState.selected_feed,
  temp_feed_sourcelinks: state.dataState.temp_feed_sourcelinks,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withRouter,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FeedReview);
