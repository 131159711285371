import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { 
  Button,
  Grid,
  IconButton, 
  InputBase, 
  Popover 
} from "@material-ui/core";
import { 
  BranchPad,
  ImageSelector
} from "components";
import { 
  withFirebase
} from "services";
import { 
  ARTICLE_BRANCH_YOUTUBE,
  is_social_branch,
  is_weblink_branch,
  get_branch
} from "constants/branches";
import { 
  MAX_WINDOW_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE
} from "constants/types";
import { ToastError } from "utility/toast";
import { 
  downloadImage,
  get_link_source,
  moderate_image
} from "utility/ravenapi";
import { check_source_link } from "utility/checklink";
import { resizeImageFile } from "utility/resizeimage";
import { gen_random_int } from "utility/utils";
import { logger } from "utility/logging";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: 8,
    backgroundColor: theme.palette.background.main,
  },
  container: {
    marginTop: 4,
    borderRadius: 4,
  },
  url_input: {
    flex: 1,
    width: `calc(100% - 48px)`,
    color: theme.palette.text.secondary,
    margin: "4px 0 4px 0",
    padding: "0 4px 0 4px",
    backgroundColor: theme.palette.background.default,
    fontSize: "14px",
    lineHeight: "18px",
  },
  infocontainer: {
    margin: theme.spacing(2),
  },
  feedItem: {
    display: "flex",
    padding: 0,
  },
  detail: {
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  name: {
    display: 'block',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    border: 0,
    fontSize: 20,
    "&:focus": {
      outline: "0px",
    },
    "&::placeholder": {
      color: theme.palette.text.primary,
    },
    "&::-webkit-input-placeholder": {
      color: theme.palette.text.primary,
    },
    "&:-ms-input-placeholder": {
      color: theme.palette.text.primary,
    },
  },
  description: {
    display: 'block',
    height: 70,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    border: 0,
    fontSize: 14,
    padding: 0,
    marginTop: 4,
    fontFamily: "Roboto",
    "&:focus": {
      outline: "0px",
    },
  },
  weblink_container: {
    backgroundColor: theme.palette.background.main,
    marginTop: theme.spacing(1),
    marginLeft: 80,
    borderRadius: 4,
  },
  weblink_input_edit: {
    flex: 1,
    width: `calc(100% - 48px)`,
    color: theme.palette.text.secondary,
    margin: "2px 0 2px 0",
    padding: "0 2px 0 2px",
    backgroundColor: theme.palette.background.default,
    "& input": {
      fontSize: 14,
      backgroundColor: theme.palette.background.default,
    }
  },
  icon_button: {
    padding: 4,
  },
  branchicon: {
    width: 24,
    height: 24  ,
  },
  btncontainer: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  applybtn: {
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    }
  },
  applybtn_disabled: {
    bottom: theme.spacing(1),
    zIndex: 1100,
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "8px 8px",
    width: 260,
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
});

class SourceLinkAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      branch: ARTICLE_BRANCH_YOUTUBE,
      socialtype: "youtube_channel_feed",
      source: "",
      weblink: "",
      anchorEl: null,
      counter: 0,
      showPreview: false,
      preview: null,
      image: RAVEN_PLACEHOLDER_IMAGE,
      imageUpload: "",
      name: "",
      description: "",
      ch_image: RAVEN_PLACEHOLDER_IMAGE,
      ch_imageUpload: "",
      ch_name: "",
      ch_description: ""
    };

    this.timer = null;

    this.handleClickBranchIcon = this.handleClickBranchIcon.bind(this);
    this.handleSelectBranch = this.handleSelectBranch.bind(this);

    this.handleChangeSource = this.handleChangeSource.bind(this);
    // this.handleConfirmSource = this.handleConfirmSource.bind(this);
    this.handleChangeWeblink = this.handleChangeWeblink.bind(this);

    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.handleApply = this.handleApply.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  setError = (message) => {
    ToastError(message);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.source !== this.state.source) {
      this.setState({
        ...this.state,
        preview: null,
        showPreview: null
      });
      this.handleCheck();
    }
  }

  handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      await this.getSourcePreview();
      clearTimeout(this.timer);
    }, 2000);
  }

  handleClickBranchIcon = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget
    });
  };

  handleSelectBranch = (branch) => {
    if (branch.value === this.state.branch) {
      return;
    }

    let socialtype_id = "";
    if (is_social_branch(branch.value)) {
      const { socialtypes } = this.props;
      const socials = socialtypes.filter(socialtype => socialtype.branch === branch.value);
      socialtype_id = socials[0].id;
    }

    this.setState({
      ...this.state,
      branch: branch.value,
      socialtype: socialtype_id,
      // source: "",
      anchorEl: null,
      counter: 0
    });
  };

  handleChangeSource = async (event) => {
    const value = event.target.value;
    this.setState({
      ...this.state,
      source: value,
      error: false
    });
  };

  getSourcePreview = async () => {
    const { source } = this.state;
    if (source.length < 10) {
      return;
    }

    this.setWaiting(true);

    let sourcelink = source.trim();
    const linkInfo = check_source_link(sourcelink);
    if (!linkInfo) {
      this.setError("Unsupported social format!");
      return;
    }

    const preview = await get_link_source(sourcelink);
    if (preview) {
      const imageBuffer = await downloadImage(preview.image);
      const imageFile = new File([imageBuffer], 'preview_image.png', { type: 'image/png' });
      console.log("imagefile...", imageFile);
      this.setState({
        ...this.state,
        showPreview: true,
        preview: preview,
        name: preview.title || "",
        description: preview.description || "",
        image: preview.image || RAVEN_PLACEHOLDER_IMAGE,
        branch: linkInfo.branch,
        imageUpload: imageFile
      });
    }

    this.setWaiting(false);
  }

  handleChangeWeblink = (event) => {
    const value = event.target.value;

    this.setState({
      ...this.state,
      weblink: value,
      error: false
    });

    // if (event.key === "Enter") {
    //   this.props.onSourceChanged(value);
    // }
  };

  handleChangeImage = ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      this.setState({
        ...this.state,
        image: e.target.result,
        imageUpload: target.files[0],
      });
    };
  }

  handleChange = (event) => {
    event.persist();
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleApply = async () => {
    const {
      branch, 
      source, 
      weblink,
      name,
      description,
      image,
      imageUpload,
    } = this.state;
    const { firebase } = this.props;
    
    logger.log("branch :", branch);
    if (!source.trim()) {
      this.setError("Input a valid source please");
      return;
    }
    const linkInfo = check_source_link(source.trim());
    if (!linkInfo) {
      this.setError("Unsupported social format!");
      return;
    }
    if (is_weblink_branch(branch)) {
      if (!weblink.trim()) {
        this.setError("Input a web link please");
        return;
      }
    }

    let new_image = image;
    let thumb_image = image; 
    if (imageUpload) {
      this.setWaiting(true);
      const flag = true;
      const resized_image = await resizeImageFile(imageUpload, flag);
      const uploadPromises = resized_image.map(async (image, index) => {
        const result = await firebase.uploadImage(image, "sources");
        console.log(`Uploaded image ${index}:`, result);
        return result;
      });
      const uploadResults = await Promise.all(uploadPromises);
      console.log("Upload results:", uploadResults);

      if (uploadResults.some((result) => result.error)) {
        this.setError("Failed to upload source image.");
        return;
      }

      thumb_image = uploadResults[0].url;
      new_image = uploadResults[1].url;
      const modresult = await moderate_image(new_image);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError("Image not allowed, because it contains adults or racy content.");
        await firebase.deleteImage(new_image);
        await firebase.deleteImage(thumb_image);
        // return;
        new_image = "";
        thumb_image = "";
        this.setState({
          ...this.state,
          image: ""
        });
      }

      this.setWaiting(false);
    }

    const sourceInfo = {
      id: gen_random_int(10000),
      name: name,
      description: description,
      image: new_image,
      thumbnail: thumb_image,
      branch: branch,
      type: linkInfo.type,
      tag: linkInfo.tag,
      source: linkInfo.url,
      weblink: weblink.trim()
    }
    this.props.onApply(sourceInfo);

    this.setState({
      ...this.state,
      source: "",
      weblink: "",
      showPreview: false,
      preview: null
    });
  };

  render() {
    const { 
      classes, 
      theme_mode 
    } = this.props;
    const { 
      branch, 
      source, 
      weblink,
      anchorEl,
      name,
      description,
      image,
      showPreview,
      preview
    } = this.state;

    if (branch === -1) {
      return <div style={{display: "none"}}></div>;
    }

    let source_image = image;
    if (!image) {
      source_image = RAVEN_PLACEHOLDER_IMAGE;
    }

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const text_width = width > MAX_WINDOW_WIDTH ? MAX_WINDOW_WIDTH - 148 - 32 : width - 148 - 32;
    const url_width = width > MAX_WINDOW_WIDTH ? MAX_WINDOW_WIDTH - 72 : width - 72;

    let channel_source_image = RAVEN_PLACEHOLDER_IMAGE;
    if (preview && preview.channel && preview.channel.image) {
      channel_source_image = preview.channel.image;
    }

    const branch_info = get_branch(branch);

    let apply_enabled = false;
    if (source) {
      apply_enabled = true;
    }

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item>
              <IconButton
                className={classes.icon_button}
                onClick={this.handleClickBranchIcon}
              >
                <img
                  className={classes.branchicon}
                  style={{padding: 2}}
                  alt={`${branch_info.name}`}
                  src={`/static/images/icons/${theme_mode}/${branch_info.image}`}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <InputBase
                name="source"
                style={{width: url_width}}
                value={source}
                className={classes.url_input}
                inputProps={{ "aria-label": "link-url" }}
                onChange={this.handleChangeSource}
                // onKeyDown={this.handleConfirmSource}
              />
            </Grid>
          </Grid>
        </div>

        {showPreview && preview && (
          <div className={classes.infocontainer}>
            <div className={classes.feedItem}>
              <ImageSelector
                image={source_image}
                imageHandleChanged={this.handleChangeImage}
              />
              <div className={classes.detail}>
                <input
                  name="name"
                  placeholder="Name your feed"
                  value={name || "unnamed"}
                  className={classes.name}
                  style={{width: text_width}}
                  onChange={this.handleChange}
                />
                <textarea
                  name="description"
                  placeholder="Describe your feed"
                  value={description || "no description"}
                  className={classes.description}
                  style={{width: text_width}}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {showPreview && preview && preview.channel && (
              <div className={classes.feedItem}>
                <ImageSelector
                  image={channel_source_image}
                  imageHandleChanged={this.handleChangeChannelImage}
                />
                <div className={classes.detail}>
                  <input
                    name="ch_name"
                    placeholder="Name your feed"
                    value={preview.channel.title || "unnamed"}
                    className={classes.name}
                    style={{width: text_width}}
                    onChange={this.handleChange}
                  />
                  <textarea
                    name="ch_description"
                    placeholder="Describe your feed"
                    value={preview.channel.description || "no description"}
                    className={classes.description}
                    style={{width: text_width}}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )}
            <div className={classes.btncontainer}>
              {apply_enabled &&
                <Button
                  className={classes.applybtn}
                  onClick={this.handleApply}
                >
                  Apply
                </Button>
              }
              {!apply_enabled &&
                <Button
                  className={classes.applybtn_disabled}
                >
                  Apply
                </Button>
              }
            </div>
          </div>
        )}

        <Popover
          id="branch-menu"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <BranchPad 
            selected={branch}
            theme={theme_mode}
            onSelect={this.handleSelectBranch} 
          />
        </Popover>
        {is_weblink_branch(branch) && 
          <div className={classes.weblink_container}>
            <IconButton className={classes.icon_button}>
              <img
                className={classes.branchicon}
                alt={`${branch_info.name}`}
                src={`/static/images/icons/${theme_mode}/weblink.png`}
              />
            </IconButton>
            <InputBase
              name="weblink"
              value={weblink}
              className={classes.weblink_input_edit}
              placeholder={"web link"}
              inputProps={{ "aria-label": "weblink-url" }}
              onChange={this.handleChangeWeblink}
            />
          </div>
        }
      </div>
    );
  }
}


SourceLinkAdd.propTypes = {
  classes: PropTypes.object,
  onApply: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  socialtypes: state.dataState.socialtypes,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SourceLinkAdd);
