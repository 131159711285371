import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { makeStyles } from "@material-ui/styles";
import { IconButton } from "@material-ui/core";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { FeedItem } from "../../components";
// import { reorder } from "utility/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  swipe: {
    backgroundColor: theme.palette.background.default,
  },
  listitem: {
    padding: 0,
    listStyleType: "none"
  },
  icon_button: {
    padding: 4,
  },
  icon: {
    padding: 4,
    width: 48,
    height: 48,
  },
}));

const FeedSwipeList = (props) => {
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    // sort feeds by order
    let new_feeds = props.feeds.map(feed => {
      const feed_order = props.authUser.feeds_order.filter(order => order.feed_id === feed.id);
      let new_feed = feed;
      new_feed.order = feed_order[0].order;
      return new_feed;
    });

    new_feeds = new_feeds.sort(
      (a, b) => a.order - b.order
    );

    setFeeds(new_feeds);
  }, [props.feeds, props.authUser.feeds_order]);

  const handleSwipeStart = () => {
  };

  const handleSwipeEnd = () => {
  };

  const handleSwipeProgress = (progress) => {
  };

  const handleUp = (index) => () => {
    if (index === 0) {
      return;
    }
    // const reordered_feeds = reorder(feeds, index, index - 1);
    // setFeeds(reordered_feeds);
    props.onSortEnd(index, index - 1);
  };

  const handleDown = (index) => () => {
    if (index === feeds.length - 1) {
      return;
    }

    // const reordered_feeds = reorder(feeds, index, index + 1);
    // setFeeds(reordered_feeds);
    props.onSortEnd(index, index + 1);
  };

  const handleDelete = (feed) => () => {
    const new_feeds = feeds.filter((item) => item.id !== feed.id);
    setFeeds(new_feeds);
    props.onDeleteFeed(feed.id);
  };

  const leadingActions = (index) => (
    <LeadingActions>
      <SwipeAction onClick={handleUp(index)}>
        <IconButton className={classes.icon_button}>
          <img
            className={classes.icon}
            alt={"up"}
            src={`/static/images/icons/up.png`}
          />
        </IconButton>
      </SwipeAction>
      <SwipeAction onClick={handleDown(index)}>
        <IconButton className={classes.icon_button}>
          <img
            className={classes.icon}
            alt={"down"}
            src={`/static/images/icons/down.png`}
          />
        </IconButton>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (feed) => (
    <TrailingActions>
      <SwipeAction destructive={true} onClick={handleDelete(feed)}>
        <IconButton className={classes.icon_button}>
          <img
            className={classes.icon}
            alt={"delete"}
            src={`/static/images/icons/delete.png`}
          />
        </IconButton>
      </SwipeAction>
    </TrailingActions>
  );

  const classes = useStyles();

  const swipeBackColor = classes.swipe;

  return (
    <div className={classes.root}>
      {feeds.length > 0 && (
        <SwipeableList
          fullSwipe={false}
          style={{ swipeBackColor }}
          threshold={0.3}
          type={ListType.IOS}
        >
          {feeds.map((feed, index) => (
            <SwipeableListItem
              key={feed.id}
              className={classes.listitem}
              leadingActions={leadingActions(index)}
              trailingActions={feed.type === 0 ? trailingActions(feed) : null}
              onSwipeEnd={handleSwipeEnd}
              onSwipeProgress={handleSwipeProgress}
              onSwipeStart={handleSwipeStart}
            >
              <FeedItem
                key={feed.id}
                feed={feed}
                following={true}
                ismyfeed={feed.type === 1 || feed.type === 2}
                enabled={true}
                badged={feed.type === 1 || feed.type === 2}
                theme_mode={props.theme_mode}
                onClicked={props.onClickFeed}
                onFollowed={props.onFollowFeed}
              />
            </SwipeableListItem>
          ))}          
        </SwipeableList>
      )}
    </div>
  );
};

FeedSwipeList.propTypes = {
  classes: PropTypes.object,
  onClickFeed: PropTypes.func,
  onFollowFeed: PropTypes.func,
  onDeleteFeed: PropTypes.func,
  onSortEnd: PropTypes.func
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  // podcasts: state.dataState.podcasts
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FeedSwipeList
);
