export const MUTATION_INSERT_SOCKETS = `
mutation InsertSockets(
  $user_id: String!,
  $socket_id: String! 
) {
  insert_sockets(objects: {user_id: $user_id,
    socket_id: $socket_id  }) {
    affected_rows
    returning {
      socket_id
			user_id
			id
			created_at
    }
  }
}`;
export const MUTATION_DELETE_SOCKETS = `
mutation delete_sockets(
  $user_id: String!,
  $socket_id: String! 
) {
  delete_sockets (
    where: {
      user_id: {_eq: $user_id},
      socket_id: {_eq: $socket_id}
    }
  ) {
    affected_rows
  }
}`;
export const GET_SOCKETS =`
query get_all_sockets (
  $user_id: String!,
  $socket_id: String!
) {
  sockets(
    where: {
      user_id: { _eq: $user_id },
      socket_id: { _eq: $socket_id }
    }
  ) {
    id
    user_id
    socket_id
    created_at
  }
}
`