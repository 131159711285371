import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { 
  Card, 
  CardContent, 
  Typography, 
  Grid, 
  Button 
} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';
import { 
  DiscussionButton, 
  DiscussTwitterCard, 
  DiscussRedditCard, 
  CommentList,
} from "components";
import { 
  THREAD_TYPE_ARTICLE,
  DISCUSSION_TWITTER,
  DISCUSSION_REDDIT,
  DISCUSSION_RAVEN,
  THEME_MODE_DARK
} from "constants/types";
import { render_text } from "utility/utils";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  card: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  content: {
    padding: 4,
    paddingBottom: 8,
    marginLeft: 10,
    marginRight: 10,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  text: {
    display: "inline",
  },
  actionbtn: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 12,
    marginRight: 5,
    textTransform: "inherit",
    color: theme.palette.text.secondary,
  },
  discussion: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    margin: theme.spacing(1),
  },
  discussbutton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    margin: 0,
  },
  bottomspace: {
    paddingBottom: theme.spacing(2)
  },
  disabletxtdiv: {
    textAlign: "center"
  },
  disabletxt: {
    margin: theme.spacing(1),
    fontSize: 14,
    color: theme.palette.text.primary
  }
});

class NewsContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDiscussTwitter: false,
      showDiscussReddit: false,
    };

    this.handleClickDiscussion = this.handleClickDiscussion.bind(this);
  }

  handleClickDiscussion = (discussion_type) => {
    let showDiscussTwitter = this.state.showDiscussTwitter;
    let showDiscussReddit = this.state.showDiscussReddit;
    if (discussion_type === DISCUSSION_TWITTER) {
      showDiscussTwitter = !showDiscussTwitter;
    } else if (discussion_type === DISCUSSION_REDDIT) {
      showDiscussReddit = !showDiscussReddit;
    }

    this.setState({
      ...this.state,
      showDiscussTwitter: showDiscussTwitter,
      showDiscussReddit: showDiscussReddit,
    });
  };


  renderOriginLink = (classes, article) => {
    return (
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="row"
      >
        <Button
          className={classes.actionbtn}
          size="small"
          color="primary"
          target="_blank"
          href={article.url}
        >
          Read original article
        </Button>
      </Grid>
    );
  };

  renderDiscussion = (classes, article, comment_show, theme_mode) => {
    const { onShareRedditDiscussion, onShareTwitterDiscussion } = this.props;
    const { showDiscussTwitter, showDiscussReddit } = this.state;

    // discussion
    let discussion_tweets = null;
    // exception for searched result
    if (article.discussion_twitter !== undefined) {
      discussion_tweets = article.discussion_twitter;
    };
    if (discussion_tweets && discussion_tweets.length > 3) {
      const sorted_discussion = discussion_tweets.sort(
        (a, b) => b.favorite_count - a.favorite_count
      );
      discussion_tweets = sorted_discussion.slice(0, 3);
    }
    let discussion_reddit = null;
    if (article.discussion_reddit !== undefined) {
      discussion_reddit = article.discussion_reddit;
    };

    return (
      <div>
        {comment_show && discussion_tweets && discussion_tweets.length > 0 && (
          <div className={classes.discussion}>
            <div className={classes.discussbutton}>
              <DiscussionButton
                type={DISCUSSION_TWITTER}
                title={"Discussion on Twitter"}
                description={"Press to expand and view"}
                image={`/static/images/icons/twitter_circle.png`}
                onClick={this.handleClickDiscussion}
              />
            </div>
            {showDiscussTwitter &&
              discussion_tweets.map((tweet, index) => (
                <DiscussTwitterCard
                  key={`discussion-twitter-${index}`}
                  tweet={tweet}
                  theme_mode={theme_mode}
                  onShare={onShareTwitterDiscussion}
                />
              ))}
          </div>
        )}
        {comment_show && discussion_reddit !== null && discussion_reddit.comments.length > 0 && (
          <div className={classes.discussion}>
            <div className={classes.discussbutton}>
              <DiscussionButton
                type={DISCUSSION_REDDIT}
                title={"Discussion on Reddit"}
                description={"Press to expand and view"}
                image={`/static/images/icons/reddit_circle.png`}
                onClick={this.handleClickDiscussion}
              />
            </div>
            {showDiscussReddit &&
              discussion_reddit.comments.map((comment, index) => (
                <DiscussRedditCard
                  key={`discussion-reddit-${index}`}
                  theme_mode={theme_mode}
                  number={index}
                  comment={comment}
                  onShare={onShareRedditDiscussion}
                />
              ))}
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-end"
              direction="row"
            >
              <Button
                className={classes.actionbtn}
                size="small"
                color="primary"
                target="_blank"
                href={discussion_reddit.url}
              >
                View original thread
              </Button>
            </Grid>
          </div>
        )}
        {comment_show && (
          <div>
            <div className={classes.discussion}>
              <DiscussionButton
                type={DISCUSSION_RAVEN}
                title={"Discussion on Raven"}
                description={"Moderated Comments"}
                image={`/static/images/icons/raven_logo.png`}
              />
            </div>
            <CommentList
              type={THREAD_TYPE_ARTICLE}
              // thread_id={article.nid}
              thread_id={article.threads.length > 0 ? article.threads[0].id : null}
              onError={this.props.onError}
              onNeedLogin={this.props.onLogin}
              onMemberTrial={this.props.onMemberTrial}
            />
          </div>
        )}
        {!comment_show && (
          <div>
            <div className={classes.discussion}>
              <DiscussionButton
                disabled
                type={DISCUSSION_RAVEN}
                title={"Discussion on Raven"}
                description={"Moderated Comments"}
                image={`/static/images/icons/raven_logo.png`}
              />
            </div>
            <div className={classes.disabletxtdiv}>
              <Typography className={classes.disabletxt}>
                {"Comments are closed or disabled"}
              </Typography>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      classes,
      theme_mode,
      article,
      source,
      content_type,
      // show_original,
      comment_show,
      onSwiped
    } = this.props;

    // summarized
    let summarized_text = null;
    if (article.translated) {
      summarized_text = article.tr_text;
    } else {
      summarized_text = article.summarized_text;
    }
    summarized_text = summarized_text.replace(/<\/?[^>]+(>|$)/g, "");
    let summarized_sentences = summarized_text.split("\n");
    summarized_sentences = summarized_sentences
      .map((sentence) => {
        let real_sentence = sentence.trim();
        if (real_sentence && real_sentence !== ".") {
          return real_sentence;
        } else {
          return "";
        }
      })
      .filter((sentence) => sentence.trim() !== "");

    // // translated
    // let content = article.text;
    // if (source.translate && article.translated) {
    //   content = article.tr_text;
    // }
    // let translated_sentences = content.split("\n");
    // translated_sentences = translated_sentences
    //   .map((sentence) => {
    //     let real_sentence = sentence.trim();
    //     if (real_sentence && real_sentence !== ".") {
    //       return real_sentence;
    //     } else {
    //       return "";
    //     }
    //   })
    //   .filter((sentence) => sentence.trim() !== "");

    // original
    let original_sentences = article.summarized_text.split("\n");
    original_sentences = original_sentences
      .map((sentence) => {
        let real_sentence = sentence.trim();
        if (real_sentence && real_sentence !== ".") {
          return real_sentence;
        } else {
          return "";
        }
      })
      .filter((sentence) => sentence.trim() !== "");

    return (
      <div className={classes.root}>
        {source.disableFullText &&
          <div>
            {this.renderOriginLink(classes, article)}
            {this.renderDiscussion(classes, article, comment_show, theme_mode)}
          </div>
        }
        {!source.disableFullText && 
          <SwipeableViews
            index={content_type}
            onChangeIndex={onSwiped}
            enableMouseEvents
          >
            <div>
              <Card className={classes.card}>
                {summarized_sentences.length > 0 &&
                  summarized_sentences.map((sentence, index) => (
                    <CardContent
                      key={`content-index-${index}`}
                      className={classes.content}
                    >
                      <Typography className={classes.text} variant="body2">
                        {render_text(sentence, theme_mode === THEME_MODE_DARK)}
                      </Typography>
                    </CardContent>
                  ))}
              </Card>
              {this.renderOriginLink(classes, article)}
             
              {this.renderDiscussion(classes, article, comment_show, theme_mode)}
              <div className={classes.bottomspace} />
            </div>
            {/* <div>
              <Card className={classes.card}>
                {translated_sentences.length > 0 &&
                  translated_sentences.map((sentence, index) => (
                    <CardContent
                      key={`content-index-${index}`}
                      className={classes.content}
                    >
                      <Typography className={classes.text} variant="body2">
                        {sentence}
                      </Typography>
                    </CardContent>
                  ))}
              </Card>
              {this.renderOriginLink(classes, article)}
              {this.renderDiscussion(classes, article, theme_mode)}
              <div className={classes.bottomspace} />
            </div> */}
            <div>
              <Card className={classes.card}>
                {original_sentences.length > 0 &&
                  original_sentences.map((sentence, index) => (
                    <CardContent
                      key={`content-index-${index}`}
                      className={classes.content}
                    >
                      <Typography className={classes.text} variant="body2">
                        {render_text(sentence, theme_mode === THEME_MODE_DARK)}
                      </Typography>
                    </CardContent>
                  ))}
              </Card>
              {this.renderOriginLink(classes, article)}
              {this.renderDiscussion(classes, article, comment_show, theme_mode)}
              <div className={classes.bottomspace} />
            </div>
          </SwipeableViews>
        }
      </div>
    );
  }
}

NewsContent.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
  source: PropTypes.object,
  theme_mode: PropTypes.string,
  content_type: PropTypes.number,
  show_original: PropTypes.bool,
  comment_show: PropTypes.bool,
  onError: PropTypes.func,
  onNeedLogin: PropTypes.func,
  onMemberTrial: PropTypes.func,
  onSwiped: PropTypes.func,
  onShareTwitterDiscussion: PropTypes.func,
  onShareRedditDiscussion: PropTypes.func
};

export default withStyles(styles)(NewsContent);
