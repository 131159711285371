import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  IconButton,
  Button,
  List,
  ListItem,
  Menu,
  Typography,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { 
  CommentCard, 
  CommentEditBox, 
  PopMenuCommentSort,
  DlgMemberTrial
} from "components";
import { GraphqlService } from "services";
import { 
  ARTICLES_PER_PAGE, 
  THREAD_TYPE_ARTICLE, 
  FEED_COMMENT_MODERATED, 
  FEED_COMMENT_UNMODERATED, 
  FEED_COMMENT_CLOSEALL, 
  MIN_CARD_WIDTH, 
  MAX_ARTICLE_WIDTH, 
  MAX_CARD_WIDTH,
  THREAD_TYPE_MAPREADING
} from "constants/types";
import { hasPaid } from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const styles = (theme) => ({
  root: {
    // width: "100%",
    padding: theme.spacing(2),
    paddingBottom: 0,
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_ARTICLE_WIDTH,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  root_card: {
    // width: "100%",
    padding: theme.spacing(2),
    paddingBottom: 0,
    minWidth: MIN_CARD_WIDTH,
    maxWidth: MAX_CARD_WIDTH,
    width: "100%",
    backgroundColor: theme.palette.background.articles,
  },
  header: {
    margin: 4,
  },
  title: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.primary,
    paddingBottom:0,
  },
  closed: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  policy: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    paddingTop:0
  },
  sort: {
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    marginRight: 4,
    color: theme.palette.text.primary,
  },
  commentlist: {
    marginTop: theme.spacing(2),
  },
  listitem: {
    padding: 0,
  },
  more: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 200,
  },
  morebtn: {
    backgroundColor: theme.palette.background.main,
    "&:hover": {
      backgroundColor: theme.palette.background.main,
    },
  },
});

const SORT_NEWEST = 0;
const SORT_OLDEST = 1;
const SORT_MOST_RECOMMENDED = 2;
const SORT_MOST_REPLIED = 3;

class CommentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: [],
      last_offset: 0,
      anchorEl: null,
      sortType: SORT_NEWEST,
      showMore: false,
      dlgMember: false
    };

    this.handleFilter = this.handleFilter.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleSortType = this.handleSortType.bind(this);
    this.handleNeedMore = this.handleNeedMore.bind(this);

    this.handleSubmitComment = this.handleSubmitComment.bind(this);
    this.handleDeleteComment = this.handleDeleteComment.bind(this);
    this.handleUpdateComment = this.handleUpdateComment.bind(this);

    this.handleOpenMemberTrial = this.handleOpenMemberTrial.bind(this);
    this.handleCloseMemberTrial = this.handleCloseMemberTrial.bind(this);
  }

  componentDidMount() {
    const { thread_id,selected_reading } = this.props;
    const { sortType } = this.state;
    this._getTopOfFirstPage(thread_id, sortType);
    this._getTopReadingCommentsOfFirstPage(selected_reading, sortType);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected_thread !== this.props.selected_thread) {
      const { thread_id } = this.props;
      const { sortType } = this.state;
      this._getTopOfFirstPage(thread_id, sortType);
    }
    if (prevProps.selected_reading !== this.props.selected_reading) {
      const { selected_reading } = this.props;
      const { sortType } = this.state;
      this._getTopReadingCommentsOfFirstPage(selected_reading, sortType);
    }
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  _getTopOfFirstPage = (thread_id, sortType) => {
    if (!thread_id) {
      return;
    }

    this.setState({
      ...this.state,
      comments: [],
      last_offset: 0,
    });
    
    logger.log("_getTopOfFirstPage :", thread_id, sortType);
    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    let fn_get_comments = null;

    if (sortType === SORT_NEWEST) {
      fn_get_comments = gqlservice.top_comments_by_newest;
    } else if (sortType === SORT_OLDEST) {
      fn_get_comments = gqlservice.top_comments_by_oldest;
    } else if (sortType === SORT_MOST_RECOMMENDED) {
      fn_get_comments = gqlservice.top_comments_by_recommends;
    } else if (sortType === SORT_MOST_REPLIED) {
      fn_get_comments = gqlservice.top_comments_by_replies;
    }

    // flag to show approved comments or all the comments
    const { selected_feed, moderate } = this.props;
    let approved = true;
    if (moderate) {
      approved = null;
    } else if (selected_feed && selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    fn_get_comments(thread_id, approved, 0)
      .then(
        (result) => {
          let comments = result.data.comments;
          logger.log("News article => top comments(first page) :", approved, comments);
          for (let comment of comments) {
            // comment.children = comment.children;
            comment.childrenCount = comment.children_aggregate.aggregate.count;
          }
          this.setWaiting(false);

          //this.props.setTopComments(comments, comments.length);
          this.setState({
            ...this.state,
            comments: comments,
            last_offset: comments.length,
            sortType: sortType,
            showMore: comments.length === ARTICLES_PER_PAGE,
          });
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });
  };

  _getTopOfNextPage = async (thread_id, last_offset) => {
    if (!thread_id) {
      return;
    }
    
    const { sortType } = this.state;

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    let fn_get_comments = null;

    if (sortType === SORT_NEWEST) {
      fn_get_comments = gqlservice.top_comments_by_newest;
    } else if (sortType === SORT_OLDEST) {
      fn_get_comments = gqlservice.top_comments_by_oldest;
    } else if (sortType === SORT_MOST_RECOMMENDED) {
      fn_get_comments = gqlservice.top_comments_by_recommends;
    } else if (sortType === SORT_MOST_REPLIED) {
      fn_get_comments = gqlservice.top_comments_by_replies;
    }

    const { selected_feed, moderate } = this.props;
    let approved = true;
    if (moderate) {
      approved = null;
    } else if (selected_feed && selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    fn_get_comments(thread_id, approved, last_offset)
      .then(
        (result) => {
          const comments = result.data.comments;
          logger.log("News article => top comments(next page) :", approved, comments);
          for (let comment of comments) {
            // comment.children = comment.children;
            comment.childrenCount = comment.children_aggregate.aggregate.count;
          }

          if (comments.length === 0) {
            this.setState({
              ...this.state,
              showMore: false
            });
          } else {
            for (let comment of comments) {
              comment.children = comment.children_aggregate.nodes;
            }
            let all_comments = this.state.comments.slice();
            for (let comment of comments) {
              if (all_comments.find(child => child.id === comment.id) === undefined) {
                all_comments.push(comment);
              }
            }

            this.setState({
              ...this.state,
              comments: all_comments,
              last_offset: this.state.last_offset + comments.length,
              showMore: comments.length === ARTICLES_PER_PAGE
            });
          }

          this.setState({
            ...this.state,
            showMore: comments.length === ARTICLES_PER_PAGE,
          });

          this.setWaiting(false);
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });
  };

  _getTopReadingCommentsOfFirstPage = (selected_reading, sortType) => {
    if (!selected_reading) {
      return;
    } 
    this.setState({
      ...this.state,
      comments: [],
      last_offset: 0,
    });
    console.log("sortType",sortType)
    logger.log("_getTopReadingCommentsOfFirstPage :", selected_reading, sortType);
    this.setWaiting(true); 
    const gqlservice = new GraphqlService();
    let fn_get_comments = null;

    if (sortType === SORT_NEWEST) {
      fn_get_comments = gqlservice.top_reading_comments_by_newest;
    } else if (sortType === SORT_OLDEST) {
      fn_get_comments = gqlservice.top_reading_comments_by_oldest;
    } else if (sortType === SORT_MOST_RECOMMENDED) {
      fn_get_comments = gqlservice.top_reading_comments_by_recommends;
    } else if (sortType === SORT_MOST_REPLIED) {
      fn_get_comments = gqlservice.top_reading_comments_by_replies;
    }

    // flag to show approved comments or all the comments
    //const { selected_feed, moderate } = this.props;
    let approved = true; 
    const reading_id =  selected_reading.id;
    fn_get_comments(reading_id, approved, 0)
      .then(
        (result) => {
          let comments = result.data.comments;
          logger.log("News article => top comments(first page) :", approved, comments);
          for (let comment of comments) {
            // comment.children = comment.children;
            comment.childrenCount = comment.children_aggregate.aggregate.count;
          }
          this.setWaiting(false);

          //this.props.setTopComments(comments, comments.length);
          this.setState({
            ...this.state,
            comments: comments,
            last_offset: comments.length,
            sortType: sortType,
            showMore: comments.length === ARTICLES_PER_PAGE,
          });
        },
        (reason) => {
          this.setError(reason.msg);
          this.setWaiting(false);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        this.setWaiting(false);
      });
  };
  _getAuthToken = async () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return null;
    }
    let token = authUser.token;
    if (Date.now() >= authUser.expiredTS) {
      const result = await this.props.firebase.refreshToken();
      if (result.error) {
        this.setError(result.msg);
        token = null;
      } else {
        token = result.token;
      }
    }
    return token;
  };

  handleFilter = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleSortType = (sort_type) => {
    const { sortType } = this.state;
    if (sortType === sort_type) {
      return;
    }

    this.setState(
      {
        ...this.state,
        anchorEl: null,
      },
      () => {
        const { thread_id,selected_reading } = this.props;
        if (thread_id) {
          this._getTopOfFirstPage(thread_id, sort_type);
        }
        if(selected_reading){
          this._getTopReadingCommentsOfFirstPage(selected_reading, sort_type);
        }
      }
    );
  };

  handleNeedMore = () => {
    const { thread_id, comments_top_last_offset, requesting } = this.props;
    if (requesting) {
      return;
    }
    this._getTopOfNextPage(thread_id, comments_top_last_offset);
  };

  _isFeedModerator = (feed) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn || !feed) {
      return false;
    }

    // check if the user is the creator of this feed
    if (authUser.feeds_created.find(
      (item) => item.id === feed.id
    )) {
      return true;
    };

    // check if the user is a moderator of the category the feed is created from
    if (authUser.categories_moderated.find(
      (moderator) =>
        moderator.approved && moderator.category_id === feed.category_id
    )) {
      return true;
    };

    // check if the user is a moderator of this feed
    if (authUser.feeds_moderated.find(
      (moderator) => moderator.approved && moderator.feed_id === feed.id
    )) {
      return true;
    };

    return false;
  };

  handleSubmitComment = async (comment_text) => {
    const { 
      type, 
      thread_id, 
      authUser, 
      selected_thread, 
      selected_feed,
      selected_article, 
      // selected_location,
      selected_reading,
    } = this.props;
    const { sortType } = this.state; 
    
    if(selected_reading){
      if (!hasPaid()) {
        this.setState({
          ...this.state,
          dlgMember: true
        });
        return;
      }
    }else{
      if (!selected_feed.op_payment && !hasPaid()) {
      this.setState({
        ...this.state,
        dlgMember: true
      });
      return;
      } 
    }
    // check if the user is pre-approved for this selected feed
   
    let approved =false;
    if(type === THREAD_TYPE_MAPREADING){
      approved =true;
    }else{
      const approved_user = authUser.preapproved.find(item => item.feed_id === selected_feed.id);
      approved = approved_user !== undefined;
      approved = approved || !selected_feed.private || this._isFeedModerator(selected_feed);
      
    }
    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    // insert article to threads if it is the first comment
    let new_thread_id = thread_id;
    if (type === THREAD_TYPE_ARTICLE) {
      if (selected_article.threads.length === 0) {
        const thread = {
          title: selected_article.title ? selected_article.title : selected_article.author,
          text: selected_article.summary ? selected_article.summary : selected_article.text?.slice(0, 50),
          type: THREAD_TYPE_ARTICLE,
          posted_by: authUser.uid,
          from: selected_article.nid,
          feed_id: selected_feed ? selected_feed.id : null,
          approved: approved,
          approved_by: approved ? authUser.uid : null,
          approved_at: approved ? new Date().toISOString() : null
        };

        await gqlservice
          .insert_thread(thread)
          .then(result => {
            const new_thread = result.data.insert_threads.returning[0];
            new_thread_id = new_thread.id;
            this.props.addThread(new_thread);
          }, reason => {
            this.setError(reason.msg);
            return;
          })
          .catch((err) => {
            this.setError(JSON.stringify(err));
            return;
          });
      }
    }

    // insert comment
    const comment = {
      parent_id: null,
      text: comment_text,
      author_id: authUser.uid,
      article_id: type === THREAD_TYPE_ARTICLE ? selected_article.nid : null,
      reading_id: type === THREAD_TYPE_MAPREADING ? selected_reading.id : null,
      thread_id: new_thread_id,
      approved: approved,
      approved_by: approved ? authUser.uid : null,
      approved_at: approved ? new Date().toISOString(): null
    };

    await gqlservice
      .insert_comment(comment)
      .then(
        (result) => {
          let inserted_comment = result.data.insert_comments.returning[0];
          logger.log("inserted comment :", inserted_comment);

          let comments = [];
          if (sortType === SORT_NEWEST) {
            comments = [inserted_comment].concat(this.state.comments);
          } else {
            comments = this.state.comments.concat([inserted_comment]);
          }

          this.setState({
            ...this.state,
            comments: comments
          });

          if (selected_thread) {
            const comment_approved = selected_feed.comment_conf === FEED_COMMENT_MODERATED ? true : null;
            return gqlservice.thread_by_id(selected_thread.id, comment_approved);
          } else {
            return inserted_comment;
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .then(result => {
        if (selected_thread) {
          const new_thread = result.data.threads[0];
          this.props.updateThread(new_thread);
        }
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    if (type === THREAD_TYPE_ARTICLE) {
      // update article
      await gqlservice
      .article_by_nid(selected_article.nid)
      .then(
        result => {
          const articles = result.data;
          if (articles.length > 0) {
            this.props.selectArticle(articles[0]);
          }
        },
        reason => {
          this.setError(reason.msg);
        }
      )
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
    }
    
    this.setWaiting(false);
  };

  handleDeleteComment = async (comment) => {
    const { type, selected_article, selected_thread } = this.props;
    const comments = this.state.comments.filter(item => item.id !== comment.id);

    const gqlservice = new GraphqlService();
    const token = await this._getAuthToken();
    gqlservice.set_auth_jwt(token);

    const { selected_feed } = this.props;
    let approved = true;
    if (selected_feed && selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    this.setWaiting(true);
    // delete thread of this article from threads if it hasn't any comment
    if (type === THREAD_TYPE_ARTICLE && comments.length === 0) {
      gqlservice
        .delete_thread_by_from(selected_article.nid)
        .then(result => {
          this.props.deleteThreadByFrom(selected_article.nid);
        }, reason => {
          this.setError(reason.msg);
        })
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });
    } else {
      // update the thread
      gqlservice.thread_by_id(selected_thread.id, approved)
        .then(result => {
          const new_thread = result.data.threads[0];
          this.props.updateThread(new_thread);
        }, reason => {
          this.setError(reason.msg);
        })
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });
    }
    this.setWaiting(false);

    this.setState({
      ...this.state,
      comments: comments
    });
  }

  handleUpdateComment = (comment_id, comment_text, updated_time) => {
    const comments = this.state.comments.map(comment => {
      if (comment.id === comment_id) {
        const new_comment = {
          ...comment,
          text: comment_text,
          modified_at: updated_time
        };
        logger.log("new comment :", new_comment);
        return new_comment;
      } else {
        return comment;
      }
    });

    this.setState({
      ...this.state,
      comments: comments
    }, () => {
      logger.log("force update! :", comments);
      this.forceUpdate();
    });
  }

  handleOpenMemberTrial = () => {
    this.setState({
      ...this.state,
      dlgMember: false,
    });
    this.props.onMemberTrial();
  }

  handleCloseMemberTrial = () => {
    this.setState({
      ...this.state,
      dlgMember: false
    });
  }
  
  renderHeader = (classes, count) => {
    const policy =
      "Please keep comments respectful. By commenting you agree to abide by our community guidelines and terms and conditions. Please report inappropriate comments.";
    return (
      <div className={classes.header}>
        <Typography className={classes.title}>{`${count} Comments`}</Typography>
        <Typography className={classes.policy}>{policy}</Typography>
      </div>
    );
  };

  renderSort = (classes, fn_filter) => {
    return (
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid item>
          <IconButton className={classes.sort} onClick={(e) => fn_filter(e)}>
            <FilterListIcon />
          </IconButton>
        </Grid>
        {/* <Grid item>
          <IconButton className={classes.sort}>
            <ArrowDropDownIcon />
          </IconButton>
        </Grid> */}
      </Grid>
    );
  };

  render() {
    const { 
      classes, 
      card_mode,
      selected_feed, 
      onNeedLogin, 
      onMemberTrial,
    } = this.props;
    const { 
      comments, 
      anchorEl, 
      sortType, 
      showMore, 
      dlgMember 
    } = this.state;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    let closed = false;
    if (selected_feed) {
      closed = selected_feed.comment_conf === FEED_COMMENT_CLOSEALL;
    }
    // if (selected_thread) {
    //   closed = selected_thread.closed;
    // };

    // // comments count
    // let commentsCount = 0;
    // if (type === THREAD_TYPE_ARTICLE) {
    //   if (selected_article) {
    //     commentsCount = selected_article.comments_aggregate.aggregate.count;
    //   } else {
    //     commentsCount = selected_thread.comments_aggregate.aggregate.count;
    //   }
    // } else {
    //   commentsCount = selected_thread.comments_aggregate.aggregate.count;
    // }

    // comments
    let comments2show = comments;
    if (selected_feed && selected_feed.comment_conf === FEED_COMMENT_MODERATED) {
      comments2show = comments.filter(comment => comment.approved === true);
    }

    const commentsCount = comments2show.length;

    // padding
    let width = document.documentElement.clientWidth || document.body.clientWidth;
    if (card_mode) {
      if (width > MAX_CARD_WIDTH)
        width = MAX_CARD_WIDTH - 32;
    } else {
      if (width > MAX_ARTICLE_WIDTH)
        width = MAX_ARTICLE_WIDTH;
    }
    if (width < MIN_CARD_WIDTH)
      width = MIN_CARD_WIDTH;
    width -= 32;

    return (
      <div className={card_mode ? classes.root_card : classes.root}>
        <Grid container direction="column">
          <Grid item>{this.renderHeader(classes, commentsCount)}</Grid>
          <Grid item>
            {closed && (
              <div className={classes.closed}>
                <Typography className={classes.title}>
                  {'Comments are now closed'}
                </Typography>
              </div>
            )}
            {!closed && (
              <CommentEditBox 
                width={width}
                card_mode={card_mode}
                onSubmit={this.handleSubmitComment} 
              />
            )}
          </Grid>
          {comments2show.length > 0 &&
            <>
              <Grid item>{this.renderSort(classes, this.handleFilter)}</Grid>
              <Grid item className={classes.commentlist}>
                <List component="comment-list" aria-label="comment list">
                  {comments2show.map((comment) => (
                    <ListItem className={classes.listitem} key={comment.id}>
                      <CommentCard
                        key={`comment-${comment.id}`}
                        level={0}
                        width={width}
                        card_mode={card_mode}
                        comment={comment}
                        sortType={sortType}
                        closed={closed}
                        collapsed={false}
                        onNeedLogin={onNeedLogin}
                        onMemberTrial={onMemberTrial}
                        onDelete={this.handleDeleteComment}
                        onUpdate={this.handleUpdateComment}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </>
          }
        </Grid>
        {showMore && (
          <div className={classes.more}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.morebtn}
              onClick={this.handleNeedMore}
            >
              Show More Comments...
            </Button>
          </div>
        )}
        <Menu
          id="filter-menu"
          // anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={anchorEl !== null}
          onClose={this.handleCloseMenu}
        >
          <PopMenuCommentSort
            selected={sortType}
            onSort={this.handleSortType}
          />
        </Menu>
        <DlgMemberTrial
          open={dlgMember}
          onClose={this.handleCloseMemberTrial}
          onMemberTrial={this.handleOpenMemberTrial}
        />
      </div>
    );
  }
}

CommentList.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.number,
  card_mode: PropTypes.bool,
  thread_id: PropTypes.number,
  onError: PropTypes.func,
  onNeedLogin: PropTypes.func,
  onMemberTrial: PropTypes.func,
};


const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_article: state.dataState.selected_article,
  selected_thread: state.dataState.selected_thread,
  selected_feed: state.dataState.selected_feed,
  moderate: state.dataState.moderate,
  selected_location: state.mapState.selected_location,
  requesting: state.uiState.requesting,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CommentList);
