import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { get_timestring, get_textwidth } from "utility/utils";
import { CONF_COUNTRIES } from "constants/country";
import { RAVEN_PLACEHOLDER_IMAGE } from "constants/types";

const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
  },
  carddiv: {
    margin: 3,
    marginBottom: 4,
    borderRadius: 10,
    backgroundColor: theme.palette.background.article,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
  },
  avatar: {
    position: "absolute",
    top: -2,
    left: -2,
    width: 32,
    height: 32,
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  title: {
    position: "relative",
    fontFamily: "Merriweater, serif",
    fontSize: "18px",
    lineHeight: "20px",
    marginTop: 10,
    marginLeft: 30,
    marginRight: 10,
    marginBottom: 10,
    color: theme.palette.text.primary,
  },
  subtitle: {
    position: "relative",
    marginLeft: 30,
    marginRight: 10,
  },
  subtitle_left: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    float: "left",
    position: "relative",
  },
  subtitle_right: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    float: "right",
    marginRight: 10,
  },
  source_img: {
    position: "relative",
    width: "auto",
    float: "left",
    height: 12,
    marginTop: 2,
    marginRight: theme.spacing(1),
  },
  content: {
    padding: 4,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 8,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  summary: {
    display: "inline",
    fontFamily: "Merriweather, serif",
    fontSize: "14px",
    lineHeight: "14px",
    color: theme.palette.text.primary,
  },
  media: {
    width: 100,
    height: "auto",
    float: "right",
    borderRadius: 5,
    marginLeft: 5,
    marginTop: 10,
    marginBottom: 8,
  },
  actions: {
    position: "relative",
    width: "100%",
    padding: 0,
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1)
  },
  translated_div: {
    float: "left",
    height: 16,
    width: 250,
    marginLeft: 10,
    marginBottom: 8,
  },
  traslated_icon: {
    // position: "relative",
    float: "left",
    top: 3,
    marginLeft: 5,
    width: 16,
    height: 16,
    color: theme.palette.text.primary,
  },
  translated_txt: {
    display: "inline",
    marginLeft: 2,
    fontSize: 12,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
});

class NewsCard extends React.Component {
  getCountryInfo(countryCode) {
    for (let country of CONF_COUNTRIES) {
      if (country.value === countryCode) {
        return country;
      }
    }
    return "";
  }

  render() {
    const { classes, source, newssite, article, search_mode } = this.props;

    let newssite_name = newssite.name;
    newssite_name = newssite_name.replace(" Opinion", "");
    newssite_name = newssite_name.replace(" Breaking", "");

    let country = this.getCountryInfo(article.country);

    var transMark = "";
    var headline = "";
    var summary = "";
    if (source.translate && article.translated) {
      transMark = `Translated from ${source.translateLang} by GPT3.5`;

      // In search results, the headline and the summary was already replaced.
      if (search_mode) {
        headline = article.headline;
        summary = article.summary;
      } else {
        headline = article.tr_headline;
        // omit the last character(.)
        headline = headline.slice(0, headline.length - 1);
        summary = article.tr_summary;
      }
    } else {
      headline = article.headline;
      summary = article.summary;
    }
    var newsTime = get_timestring(article.published);
    var hasImage = article.image !== null && article.image !== "";

    // see the content css
    let headlineMargin = 50;
    let summaryMargin = 40;
    let needSpacing = false;
    let headlineWidth = get_textwidth(headline, "16pt Merriweather");
    let summaryWidth = get_textwidth(summary, "14pt Merriweather");

    if (
      headlineWidth < window.innerWidth - headlineMargin &&
      summaryWidth < window.innerWidth - summaryMargin
    ) {
      needSpacing = true;
    }

    return (
      <Card className={classes.card}>
        <div className={classes.carddiv}>
          <CardHeader
            className={classes.header}
            avatar={
              <div>
                <Avatar
                  alt={country.name}
                  src={country.flag}
                  className={classes.avatar}
                  classes={{ img: classes.avatar_img }}
                />
              </div>
            }
            title={
              <Typography className={classes.title}>{headline}</Typography>
            }
            subheader={
              <div className={classes.subtitle}>
                <LazyLoadImage
                  alt={source.name}
                  className={classes.source_img}
                  src={source.thumbnail || source.image || RAVEN_PLACEHOLDER_IMAGE}
                />
                <Typography className={classes.subtitle_left}>
                  {newssite_name}
                </Typography>
                <Typography className={classes.subtitle_right}>
                  {newsTime}
                </Typography>
              </div>
            }
          />
          <CardContent className={classes.content}>
            <Typography className={classes.summary}>
              {hasImage && (
                <LazyLoadImage
                  alt={""}
                  src={article.image}
                  className={classes.media}
                />
              )}
              {summary}
              {needSpacing && (
                <div style={{ width: window.innerWidth - summaryMargin }}></div>
              )}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions}>
            {source.translate === true && (
              <div className={classes.translated_div}>
                <StarIcon className={classes.traslated_icon} />
                <Typography className={classes.translated_txt}>
                  {transMark}
                </Typography>
              </div>
            )}
          </CardActions>
        </div>
      </Card>
    );
  }
}

NewsCard.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
  source: PropTypes.object,
  newssite: PropTypes.object,
  search_mode: PropTypes.bool,
};

export default withStyles(styles)(NewsCard);
