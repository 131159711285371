import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { 
  // Button, 
  Grid 
} from "@material-ui/core";
import { withFirebase } from 'services';
import { withAuthentication } from "session";
// import SwipeableViews from "react-swipeable-views";
import { ToastContainer } from "react-toastify";
import _ from "lodash";
import { 
  SearchAppBar,
  IconTextItem,
  // ArticleList,
  // ArticleMasonry,
  // SlideLists, 
  FollowFeedsList, 
  DlgPostEdit,
  DlgAlert, 
  WaitingSpinner 
} from "components";
// import { 
//   DiscoverTabs,
//   Trending 
// } from "./components";
import { 
  BRANCH_ALL, 
  // ARTICLE_BRANCH_USERPOST, 
  ARTICLE_BRANCH_NEWSPAPER 
} from "constants/branches";
import { 
  TOP_FEEDS_CNT, 
  MAX_WINDOW_WIDTH,
  TAB_FORYOU,
  MAX_CARD_WIDTH,
  // MIN_TABLET_WIDTH,
} from "constants/types";
import * as ROUTES from "constants/routes";
import { ALL } from "constants/country";
import { GraphqlService } from "services";
import {
  getMainInfo,
  getLists,
  getTrendingFeeds,
  hasPaid,
  isModeratorOfCategory,
  isFeedModerator,
  upvoteArticle,
  repostArticle,
  followFeed,
  unfollowFeed,
  getArticle,
  reportArticle,
  deleteArticle,
  saveArticle,
} from "dataapis";
import { get_link_source } from "utility/ravenapi";
import { 
  is_source_alive,
  summarize_text 
} from "utility/utils";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";


const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  cattabcontainer: {
    marginTop: theme.spacing(1),
    // padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  tabcontainer: {
    marginTop: theme.spacing(1),
    // padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
    color: theme.palette.text.primary,
    textTransform: "none"
  },
  button: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    textTransform: "none"
  },
  description: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "14px",
    lineHeight: "14px",
    color: theme.palette.text.secondary,
    textTransform: "none"
  },
  category: {
    clear: "both",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  trending: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  topfeeds: {
    marginTop: theme.spacing(2),
  },
  topfeedsitems: {
    marginLeft: theme.spacing(1),
    margin: 0,
    backgroundColor: theme.palette.background.default
  },
  list: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    margin: '0px',
  },
  listitems: {
    clear: "both",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  searchcategory: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  searchfeeds: {
    marginLeft: theme.spacing(1),
    margin: 0,
    backgroundColor: theme.palette.background.default
  },
});

class Discover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: "",
      topFeeds: [],
      postDlg: false,
      article_edit: null,
      alertDlg: false,
      alertTitle: "",
      alertMsg: ""
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleChangeDiscoverTab = this.handleChangeDiscoverTab.bind(this);
    this.handleSwiped = this.handleSwiped.bind(this);

    this.handleSelectCategory = this.handleSelectCategory.bind(this);

    this.handleNeedMore = this.handleNeedMore.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);

    // this.handleShowAllCategories = this.handleShowAllCategories.bind(this);

    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickList = this.handleClickList.bind(this);
    this.handleClickListItem = this.handleClickListItem.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);
    this.handleClickSourceInArticle = this.handleClickSourceInArticle.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);
    
    this.handleChangeFollowing = this.handleChangeFollowing.bind(this);
    this.handleReachLimit = this.handleReachLimit.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    
    this.handleOK = this.handleOK.bind(this);
    this.closePostEditDlg = this.closePostEditDlg.bind(this);
    this.handleUpdatePost = this.handleUpdatePost.bind(this);
  }

  setError = message => {
    ToastError(message);
  };

  setWaiting = waiting => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  componentDidMount = async () => {
    this.setWaiting(true);

    await getMainInfo();
    await getTrendingFeeds();
    await getLists();

    this.setWaiting(false);

    this.getTopFeeds();
    this.props.setModerate(false);
  }

  getTopFeeds = () => {
    const { loggedIn, authUser, feeds } = this.props;
    
    const tags = authUser.tags;
    const feeds_by_followers = feeds
      .filter((feed) => feed.private === false && feed.approved)
      .sort(
        (a, b) => b.followers - a.followers
      );

    if (!loggedIn || (loggedIn && tags.length === 0)) {

      const topFeeds = feeds_by_followers.slice(0, TOP_FEEDS_CNT);
      this.setState({
        ...this.state,
        topFeeds: topFeeds
      });
      return;

    } else {

      // get & calculate vote of categories related to the tags.
      let tag_categories = [];
      for (let tag of tags) {
        const category_ids = tag.tag.tag_categories;

        for (let category_id of category_ids) {
          let category = tag_categories.find(tag_category => tag_category.id === category_id.category_id);
          if (!category) {
            tag_categories.push({
              id: category_id.category_id,
              vote: 1
            });
          } else {
            tag_categories = tag_categories.map(tag_category =>
              tag_category.id === category_id.category_id ?
                { id: tag_category.id, vote: tag_category.vote + 1 } : tag_category
            );
          }
        }
      }

      // select top feeds
      let topFeeds = [];

      // select one feed for category
      for (let tag_category of tag_categories) {
        const topFeed = feeds_by_followers.find(feed => feed.category_id === tag_category.id);
        if (topFeed) {
          topFeeds.push(topFeed);
        }
      }
      if (topFeeds.length >= TOP_FEEDS_CNT) {
        this.setState({
          ...this.state,
          topFeeds: topFeeds.slice(0, TOP_FEEDS_CNT)
        });
        return;
      }

      // select only categories having more than 2 feeds
      tag_categories = tag_categories.filter(tag_category =>
        feeds_by_followers.filter(feed => feed.category_id === tag_category.id).length > 1
      );
      
      let totalVotes = 0;
      for (let tag_category of tag_categories) {
        totalVotes += tag_category.vote;
      }

      // select the remains based on the vote
      const needCnt = TOP_FEEDS_CNT - topFeeds.length;
      for (let tag_category of tag_categories) {
        const feedCnt = Math.ceil(needCnt * tag_category.vote / totalVotes);
        const category_feeds = feeds_by_followers.filter(feed => feed.category_id === tag_category.id);
        if (category_feeds.length > feedCnt) {
          topFeeds = topFeeds.concat(category_feeds.slice(1, feedCnt + 1));
        } else {
          topFeeds = topFeeds.concat(category_feeds.slice(1, category_feeds.length));
        }
      }

      this.setState({
        ...this.state,
        topFeeds: topFeeds.length >= TOP_FEEDS_CNT ? topFeeds.slice(0, TOP_FEEDS_CNT) : topFeeds
      });
    }
  }

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.showTopNavbar(true);
  };

  handleChangeDiscoverTab = async (tab_value) => {
    window.scroll(0, 0);
    this.props.selectDiscoverTab(tab_value);

    if (tab_value === TAB_FORYOU) {
      if (this.props.articles.length === 0) {
        this.setWaiting(true);
        await this.getArticlesInFirstPage();
        this.setWaiting(false);
      }
    }
  }

  handleSwiped = (tabIndex) => {
    this.handleChangeDiscoverTab(tabIndex);
  }

  handleOK = () => {
    this.setState({
      ...this.state,
      alertDlg: false,
      alertTitle: "",
      alertMsg: ""
    });
  }

  handleSelectCategory = category => {
    let route = `/${ROUTES.CATEGORY_PREFIX}/${category.id}`;
    if (isModeratorOfCategory(category.id)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CATEGORY_PREFIX}/${category.id}`;
    }
    this.props.selectCategory(category);
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setCategoryBackRoute(ROUTES.DISCOVER);
  };

  // handleShowAllCategories = () => {
  //   const location = {
  //     pathname: ROUTES.CATEGORIES,
  //     state: { animation: "left" },
  //   };
  //   this.props.history.push(location);
  // };

  handleNeedMore = async () => {
    const { last_offset, requesting } = this.props;

    if (requesting) {
      return;
    }

    this.setWaiting(true);
    await this.getArticlesInNextPage(last_offset);
    this.setWaiting(false);
  };

  getSourceIdsToShow = () => {
    const { loggedIn, authUser } = this.props;
    const { topFeeds } = this.state;

    let source_ids = [];
    for (let feed of topFeeds) {
      if (loggedIn) {
        // skip if the feed is already followed by this user
        if (authUser.feeds_followed.find(followed_feed => followed_feed.feed_id === feed.id) !== undefined) {
          continue
        }
        // skip is the feed is already created by this user
        if (authUser.feeds_moderated.find(moderated_feed => moderated_feed.feed_id === feed.id) !== undefined) {
          continue
        }
        // skip is the feed is already moderated by this user
        if (authUser.feeds_created.find(created_feed => created_feed.id === feed.id) !== undefined) {
          continue
        }
      }

      // remove unfollowed sources
      if (loggedIn && authUser.feed_sources_unfollowed.length > 0) {
        let feed_source_ids = feed.feed_sources
          .filter(
            (feed_source) =>
              feed_source.approved && 
              is_source_alive(feed_source.source) &&
              authUser.feed_sources_unfollowed.findIndex(
                (unfollowed) =>
                  unfollowed.feed_id === feed.id &&
                  unfollowed.source_id === feed_source.source_id
              ) === -1
          )
          .map((feed_source) => feed_source.source_id);

        source_ids = _.union(source_ids, feed_source_ids);
      } else {
        let feed_source_ids = feed.feed_sources
          .filter((feed_source) => feed_source.approved)
          .map((feed_source) => feed_source.source_id);
        source_ids = _.union(source_ids, feed_source_ids);
      }
    }

    return source_ids;
  };

  getArticlesInFirstPage = async () => {
    const { sources } = this.props;
    const source_ids = this.getSourceIdsToShow();
    if (source_ids.length === 0) {
      this.props.refreshArticles();
      return;
    }

    const feed_sources = source_ids.map((source_id) =>
      _.find(sources, { id: source_id })
    );
    let articles_no = 0;
    for (let feed_source of feed_sources) {
      articles_no += feed_source.articles_no;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .articles_in_sources(source_ids, articles_no, 0)
      .then(
        (result) => {
          const articles = result.data;
          logger.log("Feed => articles in sources(first page) :", articles);

          this.props.setArticles(articles, articles.length);
          // this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesInNextPage = async (last_offset) => {
    const { sources } = this.props;
    let source_ids = this.getSourceIdsToShow();
    if (source_ids.length === 0) {
      return;
    }

    const feed_sources = source_ids.map((source_id) =>
      _.find(sources, { id: source_id })
    );
    let articles_no = 0;
    for (let feed_source of feed_sources) {
      articles_no += feed_source.articles_no;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .articles_in_sources(source_ids, articles_no, last_offset)
      .then(
        (result) => {
          const articles = result.data;
          logger.log("Feed => articles in sources(next page) :", articles);

          if (articles.length === 0) {
            this.props.appendArticles(articles, last_offset);
          } else {
            this.props.appendArticles(articles, last_offset + articles.length);
          }

          // this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getSourceThrottles2show = () => {
    const { loggedIn, authUser } = this.props;
    const { topFeeds } = this.state;

    let throttles = [];
    for (let feed of topFeeds) {
      let feed_sources = [];
      if (loggedIn && authUser.feed_sources_unfollowed.length > 0) {
        feed_sources = feed.feed_sources.filter(
          (feed_source) =>
            feed_source.approved && 
            is_source_alive(feed_source.source) &&
            authUser.feed_sources_unfollowed.findIndex(
              (unfollowed) =>
                unfollowed.feed_id === feed.id &&
                unfollowed.source_id === feed_source.source_id
            ) === -1
        );
      } else {
        feed_sources = feed.feed_sources.filter(
          (feed_source) => feed_source.approved
        );
      }

      for (let feed_source of feed_sources) {
        if (
          throttles.findIndex(
            (throttle) => throttle.source_id === feed_source.source_id
          ) === -1
        ) {
          throttles.push({
            source_id: feed_source.source_id,
            throttle: feed_source.source.throttle,
            article_count: 0,
          });
        }
      }
    }

    return throttles;
  };

  removeArticlesByThrottle = (articles) => {
    let new_articles = [];
    if (articles.length === 0) {
      return new_articles;
    }

    let throttles = this.getSourceThrottles2show();
    if (throttles.length === 0) {
      return new_articles;
    }

    for (let article of articles) {
      let throttle = throttles.find(
        (throttle) => throttle.source_id === article.source_id
      );
      if (!throttle) {
        new_articles.push(article);
        continue;
      }

      throttle.article_count++;
      switch (throttle.throttle) {
        case 100:
          new_articles.push(article);
          break;

        case 75:
          if (throttle.article_count % 4 !== 0) {
            new_articles.push(article);
          }
          break;

        case 50:
          if (throttle.article_count % 2 === 0) {
            new_articles.push(article);
          }
          break;

        case 25:
          if (throttle.article_count % 4 === 0) {
            new_articles.push(article);
          }
          break;

        default:
          break;
      }
    }

    return new_articles;
  };

  handleSelectArticle = (article) => {
    this.props.selectArticle(article);
    
    const { sources } = this.props;
    const article_source = sources.find(
      (source) => source.id === article.source_id
    );

    let path = `/${ROUTES.CATEGORY_PREFIX}/${article_source.category_id}/${ROUTES.SOURCE_PREFIX}/${article.source_id}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.DISCOVER);
  };

  handleSelectGroupArticle = async (nid) => {
    this.setWaiting(true);
    await getArticle(nid);
    this.setWaiting(false);

    const location = {
      pathname: `/${ROUTES.ARTICLE_NEWS_PREFIX}/${nid}`,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setArticleBackRoute(ROUTES.HOME);
  };

  handleReportArticle = async (article, reportMsg) => {
    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };

  handleEditArticle = (article) => {
    this.setState({
      ...this.state,
      postDlg: true,
      article_edit: article
    });
  }

  handleDeleteArticle = async (article) => {
    this.setWaiting(true);
    await deleteArticle(article);
    this.setWaiting(false);
  }

  closePostEditDlg = () => {
    this.setState({
      ...this.state,
      postDlg: false,
      article_edit: null
    });
  };

  handleUpdatePost = async (description, postlink) => {
    this.setState({
      ...this.state,
      postDlg: false
    });

    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }

    const { article_edit } = this.state;

    this.setWaiting(true);

    let preview = null;
    if (postlink) {
      preview = await get_link_source(postlink);
      logger.log("User post preview :", preview);
    }

    const gqlservice = new GraphqlService();

    const summary = summarize_text(description, 150);
    const source_id = article_edit.source_id;
    const published = new Date().getTime() / 1000;
    const userpost = {
      nid: article_edit.nid,
      summary: summary,
      text: description,
      published: Math.floor(published),
      link_preview: preview,
    }

    const token = await this._getAuthToken();
    if (!token) {
      this.setWaiting(false);
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    await gqlservice
      .update_userpost(userpost)
      .then(
        (result) => {
          const articles = result.data.update_articles.returning;
          if (articles.length > 0) {
            let article = articles[0];
            if (article.link_preview) {
              article.link_preview = JSON.parse(article.link_preview);
            }
            this.props.updateUserPost(articles[0]);
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    const now = new Date();
    await gqlservice
      .update_source_lastupdated(source_id, now.toISOString())
      .then((result) => {}, (reason) => {
        this.setError(reason.msg);
        return;
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    this.setWaiting(false);

    this.setState({
      ...this.state,
      article_edit: null
    });
  }

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  }

  // empty funciton
  handleDeleteSavedArticle = (article) => {}
  // empty function

  handleClickFeed = feed => {
    this.props.selectFeed(feed);

    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    if (isFeedModerator(feed)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    }
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setFeedBackRoute(ROUTES.DISCOVER);

    this.props.refreshArticles();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.showTopNavbar(true);
  };

  handleClickSource = source => {
    this.props.selectSource(source);
    this.props.setSourceBackRoute(ROUTES.DISCOVER);
    const path = `/${ROUTES.CATEGORY_PREFIX}/${source.category_id}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  }

  handleClickSourceInArticle = (source, feed) => {
    this.props.selectSource(source);
    this.props.setSourceBackRoute(ROUTES.DISCOVER);
    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  }

  handleClickList = list => {
    this.props.selectList(list);
    const path = `/${ROUTES.LIST_PREFIX}/${list.id}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  handleClickListItem = item => {
    const { feeds } = this.props;
    const list_feed = feeds.find(feed => feed.id === item.id);
    if (list_feed) {
      this.handleClickFeed(list_feed);
      return;
    }

    const { sources } = this.props;
    const list_source = sources.find(source => source.id === item.id);
    if (list_source) {
      this.handleClickSource(list_source);
    }
  }

  handleClickUpvote = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }

    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  }

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  }

  handleClickRepost = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }

    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  }

  getSortedFeeds = () => {
    const { feeds } = this.props;
    const approved_feeds = feeds.filter(feed => feed.approved);
    const sorted_feeds = approved_feeds.sort(
        (a, b) => b.followers - a.followers
    );

    // if (sorted_feeds.length > 4) {
    //   return sorted_feeds.slice(0, 4);
    // }

    return sorted_feeds;
  }

  handleReachLimit = () => {
    if (!hasPaid()) {
      const location = {
        pathname: ROUTES.MEMBERSHIP,
        state: { animation: "bottom" },
      };
      this.props.history.push(location);
    } else { 
      this.setState({
        ...this.state,
        alertDlg: true,
        alertTitle: "Follow Limit",
        alertMsg: "You've already reached the limit to follow. Unfollow some feeds to follow another feeds."
      });
    }
  }

  handleChangeFollowing = async (following, index) => {
    const { topFeeds } = this.state;

    this.setWaiting(true);
    let res = null
    if (following[index]) {
      await followFeed(topFeeds[index]);
    } else {
      res = await unfollowFeed(topFeeds[index]);
    }
    if (res === false ) {
      return
    }

    this.setWaiting(false);
  }

  handleSearchChange = searchKey => {
    // this.setState({
    //   ...this.state,
    //   searchKey: searchKey
    // });
  };

  handleSearchEnter = searchKey => {
    this.setState({
      ...this.state,
      searchKey: searchKey
    });
  };

  renderSearchResults = (classes, searchKey) => {
    const { feeds } = this.props;

    const searchFeeds = feeds.filter(feed => feed.name.toLowerCase().includes(searchKey) || 
      (feed.description !== null && feed.description.toLowerCase().includes(searchKey))
    );

    logger.log("searchFeeds :", searchFeeds);

    let category_ids = [];
    for (let feed of searchFeeds) {
      if (category_ids.indexOf(feed.category_id) === -1) {
        category_ids.push(feed.category_id);
      }
    }

    logger.log("category_ids :", category_ids);

    return (
      <div>
        {category_ids.length > 0 && category_ids.map(category_id => 
          this.renderSearchCategory(category_id, searchFeeds, classes)
        )}
      </div>
    );
  }

  renderSearchCategory = (category_id, feeds, classes) => {
    const { categories, followed_feeds } = this.props;
    const category = categories.find(item => item.id === category_id);
    if (category === undefined) {
      return;
    }

    const categoryFeeds = feeds.filter(feed => feed.category_id === category_id);

    let following = categoryFeeds.map(feed => {
      let result = followed_feeds.find(element => element.id === feed.id);
      return result === undefined ? false : true;
    });

    return (
      <div>
        <div
          className={classes.searchcategory} 
          onClick={e => this.handleSelectCategory(category)}
        >
          <IconTextItem title={category.name} image={category.image} />
        </div>
        <div className={classes.searchfeeds}>
          <FollowFeedsList
            items={categoryFeeds}
            badged={false}
            following={following}
            onFollowingChanged={this.handleChangeFollowing}
            onItemClicked={this.handleClickFeed}
            onReachLimit={this.handleReachLimit}
          />
        </div>
      </div>
    );
  }

  // searchItem(items, searchKey) {
  //   var result = [];
  //   items.forEach(item => {
  //     if (
  //       item.name.toLowerCase().includes(searchKey) ||
  //       item.description.toLowerCase().includes(searchKey)
  //     ) {
  //       result.push(item);
  //     }
  //   });
  //   return result;
  // }

  render() {
    const { 
      classes,
      // discovertab, 
      categories,
      // articles,
      // followed_feeds, 
      // lists,
      requesting, 
      theme_mode 
    } = this.props;
    const {
      // topFeeds, 
      searchKey,
      postDlg,
      article_edit,
      alertDlg, 
      alertTitle, 
      alertMsg
    } = this.state;

    // // categories to show
    // let categories2show = categories;
    // if (searchKey) {
    //   categories2show = this.searchItem(categories2show, searchKey);
    // }

    // // articles to show
    // // exclude non-approved user post
    // const real_articles = articles.filter(article =>
    //   article.branch !== ARTICLE_BRANCH_USERPOST || (
    //     article.branch === ARTICLE_BRANCH_USERPOST && article.param1 === 1
    //   )
    // );
    // // get articles to show(delete pins & movetops from the articles)
    // const throttled_articles = this.removeArticlesByThrottle(real_articles);
    // const articles2show = throttled_articles.slice();

    // // Top feeds following
    // let following = [];
    // if (topFeeds.length > 0) {
    //   following = topFeeds.map(feed => {
    //     let result = followed_feeds.find(element => element.id === feed.id);
    //     return result === undefined ? false : true;
    //   });
    // }

    let width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    // const height = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight;

    // layout variables
    // const isDesktop = width > MAX_WINDOW_WIDTH;
    // const isTablet =
    //   width >= MIN_TABLET_WIDTH &&
    //   width <= MAX_WINDOW_WIDTH;
    // const isMobile = width < MIN_TABLET_WIDTH;
    // const innerWidth =
    //   width > MAX_WINDOW_WIDTH
    //     ? MAX_WINDOW_WIDTH
    //     : width;
    let topFeedsWidth = width - MAX_CARD_WIDTH;
    if (topFeedsWidth > MAX_CARD_WIDTH) {
      topFeedsWidth = MAX_CARD_WIDTH;
    }

    // // card width = 414 - 16, grid space
    // const masonryWidth = Math.floor(innerWidth / 402) * 402 + 16;

    // SlideList width
    if (width > MAX_WINDOW_WIDTH) {
      width = MAX_WINDOW_WIDTH;
    }
    // const slidelist_width = width - 32; // content padding, slidelist margin

    // const slideContainer = {
    //   height: height - 112,
    //   WebkitOverflowScrolling: 'touch',
    // }

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <SearchAppBar
            title={"Explore"}
            onSearchChange={this.handleSearchChange}
            onSearchEnter={this.handleSearchEnter}
            onNavBack={this.handleNavBack}
          />
        </div>
        {/* <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item>
            <DiscoverTabs
              theme_mode={theme_mode}
              onChangeDiscoverTab={this.handleChangeDiscoverTab}
            />
          </Grid>
        </Grid> */}
        
        {/* <SwipeableViews 
          containerStyle={slideContainer}
          index={discovertab}
          onChangeIndex={this.handleSwiped} 
          enableMouseEvents
        > */}
          <div className={classes.cattabcontainer}>
            {searchKey.length === 0 &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                {categories.filter(category => category.id !== 'trending' && category.id !== 'cleanairmap')
                  .map((category, index) => (
                  <Grid item key={index}>
                    <div onClick={e => this.handleSelectCategory(category)}>
                      <IconTextItem title={category.name} image={category.image} />
                    </div>
                  </Grid>
                ))}
              </Grid>
            }
            {searchKey &&
              this.renderSearchResults(classes, searchKey.toLowerCase())
            }
          </div>
          
          {/* <div className={classes.tabcontainer}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item>
                <Trending
                  articles={articles2show}
                  onSelectArticle={this.handleSelectArticle}
                  onSelectGroupArticle={this.handleSelectGroupArticle}
                  onLogin={this.handleLogin}
                  onReport={this.handleReportArticle}
                  onSave={this.handleSaveArticle}
                  onDeleteSaved={this.handleDeleteSavedArticle}
                  onClickSource={this.handleClickSourceInArticle}
                  onClickFeed={this.handleClickFeed}
                  onClickUpvote={this.handleClickUpvote}
                  onClickComment={this.handleClickComment}
                  onClickRepost={this.handleClickRepost}
                />
              </Grid>
            </Grid>
          </div>

          <div className={classes.tabcontainer}>
            {(isDesktop || isTablet) && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  {articles2show.length > 0 && (
                    <ArticleMasonry
                      width={masonryWidth}
                      articles={articles2show}
                      pins={[]}
                      movetops={[]}
                      onNeedMore={this.handleNeedMore}
                      onSelectArticle={this.handleSelectArticle}
                      onSelectGroupArticle={this.handleSelectGroupArticle}
                      onLogin={this.handleLogin}
                      onReport={this.handleReportArticle}
                      onEdit={this.handleEditArticle}
                      onDelete={this.handleDeleteArticle}
                      onSave={this.handleSaveArticle}
                      onDeleteSaved={this.handleDeleteSavedArticle}
                      onClickSource={this.handleClickSourceInArticle}
                      onClickFeed={this.handleClickFeed}
                      onClickUpvote={this.handleClickUpvote}
                      onClickComment={this.handleClickComment}
                      onClickRepost={this.handleClickRepost}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {isMobile && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  {articles2show.length > 0 && (
                    <ArticleList
                      articles={articles2show}
                      pins={[]}
                      movetops={[]}
                      onNeedMore={this.handleNeedMore}
                      onSelectArticle={this.handleSelectArticle}
                      onSelectGroupArticle={this.handleSelectGroupArticle}
                      onLogin={this.handleLogin}
                      onReport={this.handleReportArticle}
                      onEdit={this.handleEditArticle}
                      onDelete={this.handleDeleteArticle}
                      onSave={this.handleSaveArticle}
                      onDeleteSaved={this.handleDeleteSavedArticle}
                      onClickSource={this.handleClickSourceInArticle}
                      onClickFeed={this.handleClickFeed}
                      onClickUpvote={this.handleClickUpvote}
                      onClickComment={this.handleClickComment}
                      onClickRepost={this.handleClickRepost}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </div>

          <div className={classes.tabcontainer}>
            <div className={classes.topfeeds}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Grid item>
                  <Button className={classes.title}>{"Top Feeds"}</Button>
                </Grid>
              </Grid>
              <div className={classes.topfeedsitems}>
                <FollowFeedsList
                  items={topFeeds}
                  badged={false}
                  following={following}
                  onFollowingChanged={this.handleChangeFollowing}
                  onItemClicked={this.handleClickFeed}
                  onReachLimit={this.handleReachLimit}
                />
              </div>
            </div>

            {lists.length > 0 && lists.map(list => {
              let items = [];
              for (let item of list.list_feeds) {
                items.push(item.feed);
              }
              for (let item of list.list_sources) {
                items.push(item.source);
              };

              return (
                <div key={`${list.id}`} className={classes.list}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Grid item>
                      <Button className={classes.title}>{list.name}</Button>
                    </Grid>
                    <Grid item>
                      <Button 
                        className={classes.button}
                        onClick={e => this.handleClickList(list)}
                      >
                        {"See All"}
                      </Button>
                    </Grid>
                  </Grid>
                  <div className={classes.listitems}>
                    <SlideLists
                      width={slidelist_width}
                      items={items}
                      onItemSelected={this.handleClickListItem}
                    />
                  </div>
                </div>
              );
            })}            
          </div>

        </SwipeableViews> */}
        
        {postDlg &&
          <DlgPostEdit
            open={postDlg}
            theme={theme_mode}
            article={article_edit}
            onSubmit={this.handleUpdatePost}
            onClose={this.closePostEditDlg}
          />
        }
        <DlgAlert
          open={alertDlg}
          title={alertTitle}
          description={alertMsg}
          theme_mode={theme_mode}
          onOK={this.handleOK}
        />
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  categories: state.dataState.categories,
  selected_category: state.dataState.selected_category,
  feeds: state.dataState.feeds,
  selected_feed: state.dataState.selected_feed,
  followed_feeds: state.dataState.followed_feeds,
  sources: state.dataState.sources,
  lists: state.dataState.lists,
  articles: state.dataState.articles,
  last_offset: state.dataState.last_offset,
  discovertab: state.uiState.discovertab,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Discover);
