import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Grid,
  IconButton
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { get_elapsed_time } from "utility/utils";
import { 
  THREAD_TYPE_FEED, 
  MIN_CARD_WIDTH, 
  MAX_CARD_WIDTH, 
  MAX_ARTICLE_WIDTH 
} from "constants/types";
import { logger } from "utility/logging";

const styles = (theme) => ({
  card: {
    position: "relative",
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_ARTICLE_WIDTH - 16,
    backgroundColor: theme.palette.background.default,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    borderRadius: 10,
    borderColor: theme.palette.feedstroke.default,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.default,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  header: {
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  expand: {
    position: "absolute",
    top: 4,
    right: 2,
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 32,
    height: 32
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  title: {
    position: "relative",
    // fontFamily: "Merriweater, serif",
    fontSize: "18px",
    lineHeight: "20px",
    marginTop: 10,
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 10,
    color: theme.palette.text.primary,
  },
  subtitle: {
    position: "relative",
    marginLeft: 40,
    marginRight: 24,
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: "14px",
    fontWeight: 100,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
  name: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "14px",
    color: theme.palette.text.primary,
  },
  content: {
    padding: 4,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 8,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
  summary: {
    display: "inline",
    // fontFamily: "Merriweather, serif",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
    color: theme.palette.text.primary,
  },
});

class CommentThreadCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand = (event) => {
    logger.log("commentThreadCard handleExpand :", event.currentTarget);
    const { thread } = this.props;
    this.props.onAnchorEl(event.currentTarget, thread);
  };

  render() {
    const {
      classes,
      thread,
    } = this.props;

    let image = "";
    let posted_by = "";
    let comment_count = 0;
    if (thread.type === THREAD_TYPE_FEED) {
      image = thread.poster.image;
      posted_by = thread.poster.username;
      comment_count = thread.comments_aggregate.aggregate.count;
    } else {
      image = thread.article.source.image;
      posted_by = thread.article.source.name;
      comment_count = thread.comments_aggregate.aggregate.count;
    }
    const posted_at = "Posted " + get_elapsed_time(thread.posted_at);
    const comment_string = `${comment_count} Comments`;

    let width = document.documentElement.clientWidth || document.body.clientWidth;
    if (width > MAX_ARTICLE_WIDTH)
      width = MAX_ARTICLE_WIDTH;
    if (width < MIN_CARD_WIDTH)
      width = MIN_CARD_WIDTH;
    
    const fromWidth = width / 2 - 48;

    return (
      <Card className={classes.card} style={{ width: width -16 }}>
        <div className={classes.carddiv}>
          <CardHeader
            className={classes.header}
            avatar={
              <div>
                <Avatar
                  alt={"thread"}
                  src={image}
                  className={classes.avatar}
                  classes={{ img: classes.avatar_img }}
                />
              </div>
            }
            title={<Typography className={classes.title}>{thread.title}</Typography>}
            subheader={
              <div className={classes.subtitle}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item style={{width: fromWidth}}>
                    <Typography className={classes.label}>{"From"}</Typography>
                    <Typography className={classes.name}>{posted_by}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.label}>{posted_at}</Typography>
                    <Typography className={classes.name}>{comment_string}</Typography>
                  </Grid>
                </Grid>
              </div>
            }
          />
          <div>
            <IconButton className={classes.expand} onClick={this.handleExpand}>
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <CardContent className={classes.content}>
            <Typography className={classes.summary}>
              {thread.text}
            </Typography>
          </CardContent>
        </div>
      </Card>
    );
  }
}

CommentThreadCard.propTypes = {
  classes: PropTypes.object,
  thread: PropTypes.object,
  onAnchorEl: PropTypes.func
};

export default withStyles(styles)(CommentThreadCard);
