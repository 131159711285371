import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Typography,
  InputBase,
  Button,
} from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import StarIcon from "@material-ui/icons/Star";
import { LinkPreview, FeedsSlide } from "components";
import { isBannedImage, isBannedVideo, getFeeds2show } from "dataapis";
import { is_valid_url, gen_random_int, get_urls } from "utility/utils";
import {
  THEME_MODE_LIGHT,
  MAX_VIDEO_LENGTH,
  MIN_CARD_WIDTH,
} from "constants/types";
import { check_source_link } from "utility/checklink";
import { get_link_source } from "utility/ravenapi";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";

const styles = (theme) => ({
  caption: {
    fontSize: 16,
    fontWeight: 500,
  },
  griditem: {
    backgroundColor: theme.palette.background.card,
  },
  actionbutton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
  },
  actionbutton_disabled: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    margin: 0,
    opacity: 0.38,
  },
  actionimg: {
    padding: 0,
    width: 32,
    height: 32,
  },
  photoinput: {
    display: "none",
  },
  image: {
    width: 32,
    height: 32,
  },
  iconbutton: {
    padding: "16px 4px",
    backgroundColor: theme.palette.background.dark,
    borderRadius: 0,
  },
  text: {
    flex: 1,
    color: theme.palette.text.primary,
    margin: 0,
    padding: 8,
    fontSize: 14,
    fontHeight: 18,
    backgroundColor: theme.palette.background.dark,
    "& input": {
      fontSize: 14,
      fontHeight: 18,
      backgroundColor: theme.palette.background.dark,
    },
  },
  postimage: {
    display: "block",
    width: "90%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
  },
  subject_div: {
    float: "left",
    height: 16,
    width: 250,
    margin: theme.spacing(1),
  },
  subject_icon: {
    float: "left",
    top: 3,
    marginLeft: 5,
    width: 16,
    height: 16,
    color: theme.palette.text.secondary,
  },
  subject_txt: {
    display: "inline",
    marginLeft: 2,
    fontSize: 11,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  feedtitle: {
    fontSize: "14px",
    lineHeight: "18px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  applybtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "4px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    },
  },
  applybtn_disabled: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "4px 8px",
    fontSize: "16px",
    width: 260,
    textTransform: "initial",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#3AB54A",
      color: "#FFFFFF",
    },
    opacity: 0.38,
  },
});

class DlgPost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: "",
      imageUpload: "",
      video: "",
      videoUpload: "",
      description: "",
      preview: null,
      postlink: false,
    };

    this.timer = null;

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleVideoChange = this.handleVideoChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectFeed = this.handleSelectFeed.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  setError = (message) => {
    ToastError(message);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.description !== this.state.description) {
      this.setState({
        ...this.state,
        preview: null,
        postlink: "",
      });
      this.handleCheck();
    }
  };

  handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      await this.getSourcePreview();
      clearTimeout(this.timer);
    }, 3000);
  };

  getSourcePreview = async () => {
    const urls = get_urls(this.state.description);

    let postlink = "";
    if (urls) {
      for (let url of urls) {
        if (!is_valid_url(url)) {
          continue;
        }
        postlink = url;
      }
    }
    if (!postlink) {
      return;
    }
    logger.log("postlink :", postlink);

    const linkInfo = check_source_link(postlink);
    if (!linkInfo) {
      this.setError("Unsupported social format!");
      return;
    }

    const preview = await get_link_source(postlink);
    if (preview) {
      this.setState({
        ...this.state,
        postlink: postlink,
        preview: preview,
      });
    }
  };

  handleImageChange = async ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      this.setState({
        ...this.state,
        image: e.target.result,
        imageUpload: target.files[0],
      });
    };
  };

  handleVideoChange = async ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    const fileReader = new FileReader();

    const duration = await this.getDuration(target.files[0]);
    logger.log("video duration :", duration);
    if (duration > MAX_VIDEO_LENGTH) {
      ToastError("Video length should be less than 5 minutes.");
      return;
    }

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      this.setState({
        ...this.state,
        video: e.target.result,
        videoUpload: target.files[0],
      });
    };
  };

  getDuration = async (file) => {
    const url = URL.createObjectURL(file);

    return new Promise((resolve) => {
      const video = document.createElement("video");
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      video.preload = "metadata";
      video.appendChild(source);
      video.onloadedmetadata = function () {
        resolve(video.duration);
      };
    });
  };

  handleChange = (event) => {
    if (event.target.value.length > 5000) {
      return;
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSelectFeed = (feed) => {
    logger.log("DlgPost selected feed :", feed);
    this.props.onSelectFeed(feed);
  };

  handleSubmit = () => {
    const { imageUpload, videoUpload, description, postlink } = this.state;

    this.props.onSubmit(imageUpload, videoUpload, description, postlink);
    this.setState({
      ...this.state,
      image: "",
      imageUpload: "",
      video: "",
      videoUpload: "",
      description: "",
      postlink: "",
      preview: null,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      image: "",
      imageUpload: "",
      video: "",
      videoUpload: "",
      description: "",
      postlink: "",
      preview: null,
    });
    this.props.onClose();
  };

  render() {
    const { classes, open, theme_mode, authUser, tohome } = this.props;
    const { image, imageUpload, videoUpload, description, preview } =
      this.state;

    const paper_style = {
      width: MIN_CARD_WIDTH,
      borderRadius: 10,
      borderStyle: "solid",
      borderColor: theme_mode === THEME_MODE_LIGHT ? "black" : "white",
      borderWidth: "1px",
      backgroundColor: theme_mode === THEME_MODE_LIGHT ? "white" : "black",
    };

    let apply_enabled = false;
    if (description.trim()) {
      apply_enabled = true;
    }

    const photo_id = `image-file-${gen_random_int(1000)}`;
    const video_id = `video-file-${gen_random_int(10000)}`;

    let imageEnabled = isBannedImage();
    let videoEnabled = isBannedVideo();

    // first share item should be user's profile
    const shareItem = {
      id: authUser.uid,
      name: authUser.name,
      image: authUser.image
    }

    const feeds2show = getFeeds2show();
    let shareFeeds = feeds2show.slice();
    shareFeeds.unshift(shareItem);

    return (
      <Dialog
        open={open}
        aria-labelledby="post-dialog"
        aria-describedby="post-dialog"
        PaperProps={{ style: paper_style }}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Typography className={classes.caption} align="center">
            Add a Post
          </Typography>
          <IconButton
            onClick={this.handleClose}
            className={classes.actionbutton}
          >
            <img
              className={classes.actionimg}
              alt="apply"
              src="/static/images/delete.png"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ padding: "8px 16px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={2}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                {videoEnabled && (
                  <Grid item>
                    <input
                      accept="video/*"
                      className={classes.photoinput}
                      id={video_id}
                      onChange={this.handleVideoChange}
                      type="file"
                    />
                    <label htmlFor={video_id} style={{ margin: 0 }}>
                      <IconButton
                        aria-label="upload video"
                        component="span"
                        className={classes.iconbutton}
                      >
                        <img
                          className={classes.image}
                          style={{ opacity: videoUpload ? 1.0 : 0.38 }}
                          alt="videocast"
                          src={`/static/images/icons/${theme_mode}/videocast.png`}
                        />
                      </IconButton>
                    </label>
                  </Grid>
                )}
                {imageEnabled && (
                  <Grid item>
                    <input
                      accept="image/*"
                      className={classes.photoinput}
                      id={photo_id}
                      onChange={this.handleImageChange}
                      type="file"
                    />
                    <label htmlFor={photo_id} style={{ margin: 0 }}>
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        className={classes.iconbutton}
                      >
                        <img
                          className={classes.image}
                          style={{ opacity: imageUpload ? 1.0 : 0.38 }}
                          alt="camera"
                          src={`/static/images/icons/${theme_mode}/camera.png`}
                        />
                      </IconButton>
                    </label>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <InputBase
                id="post-description"
                className={classes.text}
                multiline
                fullWidth
                minRows="7"
                name="description"
                value={description || ""}
                autoFocus={true}
                placeholder={"Description"}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>

          {/* {moderator === undefined &&
            <div className={classes.subject_div}>
              <StarIcon className={classes.subject_icon} />
              <Typography className={classes.subject_txt}>
                {"Subject to moderation"}
              </Typography>
            </div>
          }
          {moderator !== undefined &&
            <div className={classes.emptylink} />
          } */}

          {image && (
            <LazyLoadImage className={classes.postimage} alt={""} src={image} />
          )}

          {preview && <LinkPreview preview={preview} />}

          {tohome && (
            <div>
              <Typography className={classes.feedtitle}>
                Choose a Feed
              </Typography>
              <FeedsSlide
                width={MIN_CARD_WIDTH}
                feeds={shareFeeds}
                onSelected={this.handleSelectFeed}
              />
            </div>
          )}
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          {apply_enabled && (
            <Button className={classes.applybtn} onClick={this.handleSubmit}>
              Post
            </Button>
          )}
          {!apply_enabled && (
            <Button
              className={classes.applybtn_disabled}
              style={{ opacity: 0.38 }}
            >
              Post
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

DlgPost.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  moderator: PropTypes.bool,
  tohome: PropTypes.bool,
  onSelectFeed: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DlgPost);
