import React, { useState, useEffect, useRef } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { makeStyles, withStyles } from '@material-ui/styles';
import { 
  Typography, 
  Button, 
  Grid,
  Input,
  InputAdornment, 
  Avatar,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import io from 'socket.io-client';
import { ToastContainer } from "react-toastify";
import { 
  BasicAppBar, 
  WaitingSpinner, 
  ImageSelector,
  DlgProject,
  DlgPostEdit,
  ArticleModList,
  SlideIconLists,
  DlgWeblink,
} from "components";
import { 
  SocialButton, 
} from "./components";
import { 
  withAuthentication, 
  withAuthorization,
} from "session";
import {
  withFirebase,
  GraphqlService 
} from "services";
import * as ROUTES from "constants/routes";
import { 
  USER_TAGS_MIN_CNT, 
  MAX_ARTICLE_WIDTH, 
  GRAPHQL_SUCCESS, 
  GRAPHQL_ERROR, 
  BLANK_USER_IMAGE,
  PROFILE_TAB_POSTS,
  PROFILE_TAB_FEEDS,
  PROFILE_TAB_SETTINGS,
} from "constants/types";
import { 
  deleteArticle, 
  getAuthToken,
  getFeeds2show,
  getMainInfo,
  hasPaid,
  isBanned,
  repostArticle, 
  saveArticle, 
  unfollowFeed,
  updatePost, 
  upvoteArticle 
} from "dataapis";
import { 
  validate_email,
  get_substring,
} from "utility/utils";
// import { auth_response } from "utility/ravenapi";
import { useWeb3React } from "@web3-react/core";
import { injected } from "wallet/Connector";
import { get_ens_name } from "utility/ens";
import { ToastInfo, ToastError,ToastSuccess } from "utility/toast";
import { 
  moderate_image, verificationPhone 
} from "utility/ravenapi";
import {
  resizeImageFile
} from "utility/resizeimage";
import { logger } from "utility/logging";
import SwipeableViews from 'react-swipeable-views';
import ProfileTabs from "./components/ProfileTabs/ProfileTabs";
import FeedTabItem from "./components/FeedTabItem/FeedTabItem";
import { ARTICLE_BRANCH_NEWSPAPER } from "constants/branches";
import { createMastodonAccount } from "utility/ravenapi";
import DlgPhoneVerification from "components/DlgPhoneVerification/DlgPhoneVerification";
import DlgBluesky from "components/Dialogs/DlgBluesky/DlgBluesky";

const API_URL = "https://authapi.raventalk.org";
const socket = io(API_URL);

const condition = (authUser) => !!authUser && authUser.uid !== ""

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh - 64px)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  profileContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // marginTop: 56,
    // [theme.breakpoints.up("md")]: {
    //   marginTop: 64
    // }
  },
  titlediv: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 500,
  },
  detail: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  name: {
    width: "100%",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    border: 0,
    fontSize: 20,
    fontWeight: 600,
    "&:focus": {
      outline: "0px"
    },
    "&::placeholder": {
      color: theme.palette.text.primary
    },
    "&::-webkit-input-placeholder": {
      color: theme.palette.text.primary
    },
    "&:-ms-input-placeholder": {
      color: theme.palette.text.primary
    }
  },
  statusicon: {
    width: 24,
    height: 24,
  },
  upvotes: {
    marginLeft: 4,
    fontSize: "18px",
    fontFamily: "Roboto",
  },
  adornment: {
    display: "inline",
    fontSize: 20,
    marginRight: 4,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
  },
  username: {
    maxWidth: "100%",
    // backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    border: 0,
    fontSize: 20,
    marginTop: 4,
    "&:focus": {
      outline: "0px"
    },
    "&::placeholder": {
      color: theme.palette.text.primary
    },
    "&::-webkit-input-placeholder": {
      color: theme.palette.text.primary
    },
    "&:-ms-input-placeholder": {
      color: theme.palette.text.primary
    }
  },
  description: {
    width: "100%",
    height: 50,
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    border: 0,
    fontSize: 16,
    padding: 0,
    marginTop: theme.spacing(3),
    fontFamily: "Roboto",
    "&:focus": {
      outline: "0px"
    }
  },
  profiletabs:{
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  childProfileTab: {
    position: 'fixed',
    top: 55, 
    width: MAX_ARTICLE_WIDTH,
    zIndex: 1000, 
    backgroundColor: theme.palette.background.default,// Ensure tabs are above other content
  },
  blockcontainer: {
    marginTop: theme.spacing(1),
  },
  blockdiv: {
    paddingBottom: theme.spacing(1),
  },
  settingblockdiv:{
    display: 'flex',
    paddingTop: 8
  },
  settingblock:{
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  settingmastodon:{
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '18px'
  },
  settingbluesky:{
    display: 'flex', 
    alignItems: 'center'
  },
  blockTitleBar: {
    display: "flex",
    marginBottom: theme.spacing(2),
    justifyContent: "space-between"
  },
  blockTitle: {
    color: theme.palette.text.primary,
    marginLeft: 16
  },
  feedSeeAll: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    position: "absolute",
    right: 16,
    alignItems: "center",
    lineHeight: "24px"
  },
  selectfollowing: {
    color: theme.palette.text.secondary,
    padding: 0,
    fontSize: 14,
    textTransform: 'none',
    position: "relative",
    right: 16,
    alignItems: "top",
    lineHeight: "20px"
  },
  selectDiv:{
    width: "100%",
    display: 'flex',
    flexDirection: 'column'
  },
  selectbuttontext: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 14
  },
  articles: {
    backgroundColor: theme.palette.background.default,
  },
  buttonbox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  projectbutton: {
    textTransform: "none",
  },
  projectimage: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
  subscribebutton: {
    backgroundColor: "#1878F3",
    "&:hover": {
      backgroundColor: "#1878F3"
    },
    color: theme.palette.text.primary,
    borderRadius: "30px",
    padding: "3px 3px",
    width: "100%",
    textTransform: "initial",
    // marginTop: theme.spacing(1),
  },
  branchimg: {
    margin: 0,
    width: 16,
    height: 16
  },
  skipbutton: {
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.default
    },
    borderRadius: "30px",
    border: "3px solid #1878F3",
    boxSizing: "border-box",
    padding: "8px 8px",
    width: "100%",
    color: theme.palette.text.primary,
    textTransform: "initial",
    marginTop: theme.spacing(1),
  },
  skipbuttontext: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 16
  },
  floatingButtonContainer: {
    position: "fixed",
    bottom: 0,
    zIndex: 1100,
    backgroundColor: theme.palette.background.default,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    paddingTop: "6px",
    backgroundColor: theme.palette.background.default
  },
  savebutton: {
    // position: "fixed",
    // bottom: theme.spacing(1),
    // zIndex: 1100,
    backgroundColor: "#1878F3",
    "&:hover": {
      backgroundColor: "#1878F3"
    },
    color: theme.palette.text.primary,
    borderRadius: "30px",
    border: "2px solid #1878F3",
    padding: "1px 1px",
    width: "100%",
    textTransform: "initial",
    marginTop: 1,
    marginBottom: 1,
  },
  savebutton_disabled: {
    backgroundColor: "#303030",
    "&:hover": {
      backgroundColor: "#303030"
    },
    color: theme.palette.text.primary,
    borderRadius: "30px",
    border: "3px solid #303030",
    padding: "8px 8px",
    width: "100%",
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    opacity: 0.5
  },
  buttontext: {
    color: "#FFF",
    fontWeight: 400,
    fontSize: 16
  },
  linkbuttons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  stats:{
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "start",
    gap: "20px",
  },
  statText:{
    cursor: 'pointer'
  },
  actionbtn: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "20px",
    padding: "4px 10px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    textTransform: "initial",
    // boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
  },
  actionicon: {
    padding: 3,
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
  },
  bottomdiv: {
    paddingBottom: theme.spacing(2),
  },
  socialimg: {
    position: "relative",
    width:42,
    height: 42,
    marginTop: -10,
  },
  warningcontainer: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: "center",
  },
  warningimg: {
    width: 48,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  warningicon: {
    width: 48,
    height: 48,
  },
  warningtitle: {
    width: 220,
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
}));

const CustomCheckBox = withStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    '&$checked': {
      color: theme.palette.text.primary,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const ACCOUNT_TYPE_TWITTER    = 0;
const ACCOUNT_TYPE_INSTAGRAM  = 1;
const ACCOUNT_TYPE_LINKEDIN   = 2;



const Profile = props => {
  const { 
    active, 
    account, 
    // library, 
    activate, 
    // deactivate 
  } = useWeb3React();

  const { 
    authUser,
    followed_feeds,
    sources,
    tags,
    theme_mode,
    loggedIn,
    profiletab,
    requesting,
    my_posts,
  } = props;

  const classes = useStyles();

  const [profile, setProfile] = useState({
    name: "",
    username: "",
    biography: "",
    image: BLANK_USER_IMAGE,
    imageUpload: "",
    email: "",
    project_scistarter: false,
    project_librarykit: false,
    links: [],
    enableMastodon: false,
    enableBluesky: false,
  });

  const [project, setProject] = useState("");
  const [dlgSciStarter, setDlgSciStarter] = useState(false);
  const [dlgLibraryKit, setDlgLibraryKit] = useState(false);
  const [postToMastodon, setPostToMastodon] = useState(false);
  const [connectToBlueSky, setConnectToBlueSky] = useState(false);

  const [joinDate, setJoinDate] = useState("");
  const [width, setWidth] = useState(document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth);
  const [seeAllPos, setSeeAllPos] = useState(16);
  const [cryptoConnected, setCryptoConnected] = useState(active);
  const [cryptoAddress, setCryptoAddress] = useState("");
  const [upvotes, setUpvotes] = useState(0);
  const [postCounts, setPostCounts] = useState(null);
  const [value, setValue] = useState(0);
  const [postDlg, setPostDlg] = useState(false);
  const [articleEdit, setArticleEdit] = useState(null);
  const [articleReposts, setArticleReposts] = useState([]);
  const [articlePosts, setArticlePosts] = useState([]);
  const [finalpostcount, setFinalpostcount] = useState(null)
  const [repostCount, setRepostCount] = useState(null)
  const [password, setPassword] = useState('');
  const scrollableDivRef = useRef(null);
  const [blueskyDlg,setBlueskyDlg]=useState(false);
  const [isProfileTabsFixed, setIsProfileTabsFixed] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      setIsProfileTabsFixed(false);
      const profileTabsElement = document.getElementById('profile-tabs');
      if (profileTabsElement) {
        const { top } = profileTabsElement.getBoundingClientRect();
        if (top <= 64) {
          setIsProfileTabsFixed(true);
        } else {
          setIsProfileTabsFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      let links = authUser.links.slice();

      // const result = await auth_response();
      // if (!result.error) {
      //   logger.log("social auth result :", result);
      //   for (let link of links) {
      //     if (link.type === "twitter") {
      //       link.value = result.user.twitterId;
      //     }
      //   }
      // }

      logger.log("crypto connected :", cryptoConnected);
      if (cryptoConnected) {
        const result = await get_ens_name(account);
        if (result === null) {
          // const crypto_address = `${account.substring(0, 6)}...${account.substring(account.length - 4)}`;
          setCryptoAddress("");
        } else {
          setCryptoAddress(result);
        }
      } else {
        setCryptoAddress("");
      }

      setProfile({
        name: authUser.name,
        username: authUser.username,
        biography: authUser.biography,
        image: authUser.image,
        email: authUser.subscribe_email,
        project_scistarter: authUser.project_scistarter,
        project_librarykit: authUser.project_librarykit,
        links: links,
        // create_mode: !hasProfile
      });

      setPostToMastodon(authUser.is_mastodon_enabled);
      setConnectToBlueSky(authUser.is_bluesky_enabled)

      logger.log("Profile width :", width);

      if (width > MAX_ARTICLE_WIDTH) {
        setSeeAllPos(16 + (width - MAX_ARTICLE_WIDTH) / 2);
        setWidth(MAX_ARTICLE_WIDTH);
      }
    
      // joined(created) date
      if (authUser.created_at) {
        const join_date = new Date(authUser.created_at);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        setJoinDate(`Joined ${join_date.toLocaleDateString("en-US", options)}`);
      }
    }

    fetchData();
  }, [authUser, cryptoConnected, width, account]);

  const handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    props.history.push(location);
  };
  
  const setError = (errMsg) => {
    ToastError(errMsg);
    props.requestDataFinished(true);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gqlservice = new GraphqlService();
        const token = await getAuthToken();
  
        if (!token) {
          handleLogin();
          return;
        }
  
        gqlservice.set_auth_jwt(token);
        const sourceId = `${authUser.uid}-selfpost`;
  
        // get upvotes and post count
        const upvotesResult = await gqlservice.article_voter_count_by_user(authUser.uid, sourceId);
        const counts = upvotesResult.data.article_voter_aggregate.aggregate.count;
        const postcounts = upvotesResult.data.articles_aggregate.aggregate?.count;
        const repostcounts = upvotesResult.data.article_repost_aggregate.aggregate?.count;
        const totalCount = postcounts + repostcounts
        setUpvotes(counts);
        setRepostCount(repostcounts);
        setPostCounts(totalCount);
        setWaiting(true)
        await getMainInfo();

        if (totalCount === 0) {
          setWaiting(false)
          return
        }
        if (my_posts.length === totalCount) {
          setWaiting(false)
          return
        }
        // Get my posts
        let myPosts = []
        if (postcounts > 0) {
          const myPostsResult = await gqlservice.articles_to_profile(authUser.uid, sourceId);
          myPosts = myPostsResult.data.articles;
          setArticlePosts([...myPostsResult.data.articles]);
        }
        // Get oldest date
        let oldestDate = null;
        if (myPosts.length > 0) {
          oldestDate = myPosts.reduce((oldest, post) => (post.published < oldest ? post.published : oldest), Infinity);
        }
  
        // Article reposts
        let myRepostsResult = []
        if (myPosts.length < 5) {
           myRepostsResult = await gqlservice.article_reposts(authUser.uid);
        } else {
           myRepostsResult = await gqlservice.article_reposts(authUser.uid, oldestDate);
        }
        const myReposts = myRepostsResult.data.article_repost;
  
        if (myReposts.length > 0) {
          for (let i = 0; i < myReposts.length; i++) {
            myPosts.push(myReposts[i].article);
          }
          myPosts.sort((a, b) => new Date(b.published) - new Date(a.published));
          setArticleReposts(myReposts)
        }
          props.setMyPosts(myPosts);
          // if (myPosts.length > 0) {
          //   let articlesWithoutSourceDetails = myPosts.filter(article => (
          //     article.source_id !== null && !sources.some(source => source.id === article.source_id)
          //   ));
          //   if (articlesWithoutSourceDetails.length > 0) {
          //     const getSourceDetails = async (article) => {
          //       try {
          //         const sourceDetailsResult = await gqlservice.source_and_feed_sources_by_id(article.source_id);
          //         var sourceDetails = sourceDetailsResult.data.sources[0];
          //         props.addSource(sourceDetails);
          //         const feedFromSource = sourceDetails.feed_sources[0]?.feed;
          //         if (feedFromSource ) {
          //           props.addFeed(feedFromSource);
          //         }
          //       } catch (error) {
          //         console.error(`Error fetching source details for article ${article.nid}:`, error);
          //       }
          //     };
          //     await Promise.all(articlesWithoutSourceDetails.map(article => getSourceDetails(article)));
          //   }
          // }
      } catch (error) {
        setError(error.message || JSON.stringify(error));
      } finally {
        setWaiting(false);
      }
    };
  
    fetchData();
  }, [authUser]);

  const setWaiting = (waiting) => {
    if (waiting) {
      props.requestDataPending();
    } else {
      props.requestDataFinished();
    }
  };

  const showInfo = (msg) => {
    ToastInfo(msg);
  };


  const handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    props.history.push(location);
  };

  const handleChangeFeedTab = (newValue) => {
    setValue(newValue); 
  };

  const handlePostToMastodonChange = async(event) => {
    setPostToMastodon(event.target.checked);
    let phoneNumber=authUser.phone;
    let email = authUser.email;
    
    if(!authUser.mastodon_username && email){
      
    
    if(event.target.checked===true){
      
      let params={                    
        password: authUser.username+"@raven",        
        uid:authUser.uid,
        agreement:true,
        locale:"en",
        scopes:"write:accounts"
    
    }
   
    const account=await createMastodonAccount(params)
    if(account.data){
    ToastSuccess("Mastodon Account Created Sucessfully");
    }else{
      ToastError(account.response.data.actualResponse.error);
    }
    }else{
      
    }
    }
  };

  const handleConnectToBlueSkyChange = async (event) => {
    setConnectToBlueSky(event.target.checked);
    if(!authUser.bluesky_username){
    if (event.target.checked === true) {
         setBlueskyDlg(true);
    }
  }
  };
  

  const handleSwipeChange = (tab_value) => {
    setValue(tab_value);
    props.selectProfileTab(tab_value);

    props.selectProfileTab(tab_value);
  };

  const handleSwipeScroll = (event) => {
    event.preventDefault();

    const { profiletab } = this.props;

    let element_id = "profile-swipeable-views-posts";
    if (profiletab === PROFILE_TAB_FEEDS) {
      element_id = "profile-swipeable-views-feeds";
    } else if (profiletab === PROFILE_TAB_SETTINGS) {
      element_id = "profile-swipeable-views-settings";
    }

    const domElement = document.getElementById(element_id).parentNode;
    const scrollTop = domElement.scrollTop;

    if (scrollTop > 50) {
      props.showTopNavbar(false);
    } else {
      props.showTopNavbar(true);
    }
    if (event.target.scrollTop + event.target.clientHeight === event.target.scrollHeight) {
      // Trigger the onNeedMore function
      // handlepostsNeedMore();
    }
  }

  // const handleDivScroll = (event) => {
  //    Check if the div is scrolled to the bottom
  //   const div = event.target;
  //   if ((div.scrollTop + div.clientHeight === div.scrollHeight) || (div.scrollTop + div.clientHeight >= div.scrollHeight - 4)) {
  //      Trigger the handlepostsNeedMore function
  //      handlepostsNeedMore();
  //   }
  // };

  const handleNeedMore = () => {
    handlepostsNeedMore()
  }

  const handleChange = event => {
    event.persist();

    setProfile({
      ...profile,
      [event.target.name]: event.target.value
    });
  };

  const handleImageChange = ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = e => {
      setProfile({
        ...profile,
        image: e.target.result,
        imageUpload: target.files[0]
      });
    };
  };


  const handleShowFeeds = () => {
    const location = {
      pathname: ROUTES.PROFILE_NEWSLETTER,
      state: { animation: "left" },
    };
    props.history.push(location);
  };

  // const handleSelectFeeds = (feed) => {
  //   props.selectFeed(feed);

  //   let path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
  //   const location = {
  //     pathname: path,
  //     state: { animation: "left" },
  //   };
  //   props.history.push(location);

  //   props.setFeedBackRoute(ROUTES.PROFILE);
  //};

  // const handleShowFollowing = () => {
  //   const location = {
  //     pathname: ROUTES.MY_FEEDS,
  //     state: { animation: "left" },
  //   };
  //   props.history.push(location);
  // };

  const handleSelectFollowingUser = (user) => {
    logger.log("selected user :", user);
    if (user.username === null || user.username.length === 0) {
      return;
    }
    let route = `/${ROUTES.USER_PREFIX}/${user.username}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    props.history.push(location);
  };

  const handleShowPosts = () => {
    const location = {
      pathname: ROUTES.PROFILE_POSTS,
      state: { animation: "left" },
    };
    props.history.push(location);
    props.setPostsBackRoute(ROUTES.PROFILE);
  }

  const handlefollowers = () => {
    const location = {
      pathname: ROUTES.PROFILE_FOLLOWERS,
      state: { animation: "left" },
    };
    props.history.push(location);
    props.setPostsBackRoute(ROUTES.PROFILE);
  };

  const handlefollowing = () => {
    const location = {
      pathname: ROUTES.PROFILE_FOLLOWING,
      state: { animation: "left" },
    };
    props.history.push(location);
    props.setPostsBackRoute(ROUTES.PROFILE);
  };

  const handleUpdateAccount = (provider, username) => {
    let account_type = null;
    if (provider === "twitter") {
      account_type = ACCOUNT_TYPE_TWITTER;
    } else if (provider === "instagram") {
      account_type = ACCOUNT_TYPE_INSTAGRAM;
    } else if (provider === "linkedin") {
      account_type = ACCOUNT_TYPE_LINKEDIN;
    }

    handleApplyAccount(account_type, username);
  }

  const handleClickCrypto = async () => {
    logger.log("Click crypto button, cryptoConnected :", cryptoConnected);

    if (cryptoConnected) {
      try {
        // await deactivate();
        // setCryptoConnected(false);
        const etherscan_url = `https://etherscan.io/address/${account}`;
        window.open(etherscan_url);
      } catch (ex) {
        logger.error(ex);
      }
    } else {
      try {
        await activate(injected);
        setCryptoConnected(true);
      } catch (ex) {
        logger.error(ex)
      }
    }
  }

  const handleApplyAccount = async (account_type, account) => {
    let links = profile.links.slice();
    if (account_type === ACCOUNT_TYPE_TWITTER) {
      if (links.find(link => link.type === "twitter") === undefined) {
        links.push({
          type: "twitter",
          value: account
        });
      } else {
        for (let link of links) {
          if (link.type === "twitter") {
            link.value = account;
          }
        }
      }
    } else if (account_type === ACCOUNT_TYPE_INSTAGRAM) {
      if (links.find(link => link.type === "instagram") === undefined) {
        links.push({
          type: "instagram",
          value: account
        });
      } else {
        for (let link of links) {
          if (link.type === "instagram") {
            link.value = account;
          }
        }
      }
    } else if (account_type === ACCOUNT_TYPE_LINKEDIN) {
      if (links.find(link => link.type === "linkedin") === undefined) {
        links.push({
          type: "linkedin",
          value: account
        });
      } else {
        for (let link of links) {
          if (link.type === "linkedin") {
            link.value = account;
          }
        }
      }
    }

    setProfile({
      ...profile,
      links: links
    });

    let real_links = links.filter(link => link.value !== "");

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);
    await gqlservice
      .update_user_links(authUser.uid, real_links)
      .then(
        result => {
          let user = result.data.update_users.returning[0];
          props.updateUserProfile(user);
        },
        reason => {
          setError(reason.msg);
        }
      )
      .catch(err => {
        setError(JSON.stringify(err));
      });
  }

  const checkUsername = (username) => {
    const regex = /^[a-zA-Z0-9]+$/g;
    const found = username.match(regex);
    return found !== null;
  }

  const existUsername = async (username) => {
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);
    const result = await gqlservice.user_by_username(username);
    if (result.status_code === GRAPHQL_SUCCESS) {
      return result.data.users.length > 0;
    } else {
      setError("Network disconnected, can't access database");
    }
  }

  const handleChangeProject = event => {
    event.persist();
    setProject(event.target.value);

    if (event.target.value.trim() === "scistarter" && !profile.project_scistarter) {
      setDlgSciStarter(true);
      return;
    }
    if (event.target.value.trim() === "librarykit" && !profile.project_librarykit) {
      setDlgLibraryKit(true);
      return;
    }
  }

  const handleClickSubscribe = async () => {
    if (profile.email.trim().length === 0 || !validate_email(profile.email)) {
      setError("Invalid email");
      return;
    }

    setWaiting(true);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      setWaiting(false);
      handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    let result = null;
    if (authUser.subscribe_email !== profile.email.trim())  {
      result = await gqlservice.update_user_subscribe_email(authUser.uid, profile.email)
      if (result.status_code !== GRAPHQL_SUCCESS) {
        setError(result.msg);
        return;
      }

      const user_profile = result.data.update_users.returning;
      if (user_profile.length === 0) {
        setError("Failed to get user profile");
        return;
      }

      props.updateUserProfile(user_profile[0]);
      result = await gqlservice.user_by_id(authUser.uid);
      if (result.status_code === GRAPHQL_ERROR) {
        setError(result.msg);
        return;
      }

      showInfo("Success to subscribe feeds");
      localStorage.setItem('authUser', JSON.stringify(result.data.users[0]));
    }

    setWaiting(false);
  }

  const handleClickUnsubscribe = async () => {
    setWaiting(true);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      setWaiting(false);
      handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    let result = await gqlservice.delete_user_subscribe_email(authUser.uid)
    if (result.status_code === GRAPHQL_SUCCESS) {
      const profile = result.data.update_users.returning;
      if (profile.length > 0) {
        props.updateUserProfile(profile[0]);
      }
    } else {
      setError(result.msg);
      return;
    }

    // delete subscribed feeds
    result = await gqlservice.delete_feed_subscribers(authUser.uid);
    if (result.status_code === GRAPHQL_ERROR) {
      setError(result.msg);
      return;
    }

    setProfile({
      ...profile,
      email: ""
    });

    // update user & localstorage
    props.deleteFeedsSubscribed();

    let newAuthUser = {
      ...authUser,
      feeds_subscribed: []
    };
    localStorage.setItem('authUser', JSON.stringify(newAuthUser));

    setWaiting(false);
  }

  const handleSubmitProfile = async () => {
    const { name, username, image } = profile;

    if (name.trim() === "") {
      setError("Name can't be blank");
      return;
    }

    const trimmed_username = username.trim();
    if (trimmed_username === "") {
      setError("Username can't be blank");
      return;
    } else if (trimmed_username.length < 6) {
      setError("Username must be longer than 6 characters");
      return;
    } else if (!checkUsername(trimmed_username)) {
      setError("Username format error");
      return;
    }

    if (trimmed_username !== authUser.username) {
      const result = await existUsername(trimmed_username);
      if (result) {
        setError("Duplicate Username, choose another one");
        return;
      } else if (result === undefined) {
        return;
      }
    }

    if (image !== BLANK_USER_IMAGE && image !== authUser.image) {
      updateImageAndProfile();
    } else {
      updateProfile(image);
    }
  };

  const updateImageAndProfile = async () => {
    const { imageUpload } = profile;

    setWaiting(true);

    const resized_image = await resizeImageFile(imageUpload);
    const result = await props.firebase.uploadImage(resized_image, "avatars");
    if (result.error) {
      setError("Failed to upload user image.");
      return;
    }

    const imageUrl = result.url;
    const modresult = await moderate_image(imageUrl);
    logger.log("image moderation result :", modresult);
    if (modresult && modresult.result) {
      this.setError("Image not allowed, because it contains adults or racy content.");
      await props.firebase.deleteImage(imageUrl);
      return;
    }

    await updateProfile(imageUrl);
    setWaiting(false);
  };

  const updateProfile = async (image_url) => {
    const { name, username, biography, links } = profile;

    let real_links = links.filter(link => link.value !== "");

    logger.log("real_links :", real_links);

    const user = {
      uid: authUser.uid,
      name: name.trim(),
      username: username.trim(),
      biography: biography?.trim(),
      image: image_url,
      provider: authUser.provider,
      email: authUser.email,
      emailVerified: authUser.emailVerified,
      phone: authUser.phone,
      phoneVerified: authUser.phoneVerified,
      cryptoAddress: active ? account : "",
      cryptoENS: cryptoAddress.length > 0 ? cryptoAddress : "",
      cryptoAmount: null,
      state: "",
      country: "",
      links: real_links,
      role: authUser.role,
      approved: authUser.approved,
      skipProfile: true,
      enableMastodon: postToMastodon,
      enableBluesky: connectToBlueSky,
    };

    logger.log("updateProfile :", user);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      handleLogin();
      return;
    }

    gqlservice.set_auth_jwt(token);
    await gqlservice
      .update_user(user)
      .then(
        result => {
          let user = result.data.update_users.returning[0];
          props.updateUserProfile(user);

          const location = {
            pathname: ROUTES.PROFILE_PHONE,
            state: { animation: "left" },
          };
          props.history.push(location);
        },
        reason => {
          setError(reason.msg);
          return;
        }
      )
      .catch(err => {
        setError(JSON.stringify(err));
        return;
      });
  }

  const handleSkip = async () => {
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);
    await gqlservice
      .update_user_skipprofile(authUser.uid, true)
      .then(
        result => {
          let user = result.data.update_users.returning[0];
          props.updateUserProfile(user);
        },
        reason => {
          setError(reason.msg);
        }
      )
      .catch(err => {
        setError(JSON.stringify(err));
      });

    const mytags = tags.filter(
      tag =>
        tag.tag_users.findIndex(
          tag_user => tag_user.user_id === authUser.uid
        ) !== -1
    );

    if (tags.length === 0 || mytags.length >= USER_TAGS_MIN_CNT) {
      const location = {
        pathname: ROUTES.HOME,
        state: { animation: "right" },
      };
      props.history.push(location);
    } else {
      const location = {
        pathname: ROUTES.ONBOARDING,
        state: { animation: "right" },
      };
      props.history.push(location);
    }
  }

  const _isModerator = () => {
    return authUser.categories_moderated.length > 0 || authUser.feeds_moderated.length > 0;
  }

  const handleApplySciStarter = async () => {
    if (!authUser.email) {
      ToastError("You must register your email to participate SciStarter project.");
      return;
    }

    setDlgSciStarter(false);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);
    await gqlservice
      .update_user_project_scistarter(authUser.uid, !profile.project_scistarter)
      .then(
        result => {
          let user = result.data.update_users.returning[0];
          props.updateUserProfile(user);
          setProject("");
        },
        reason => {
          setError(reason.msg);
        }
      )
      .catch(err => {
        setError(JSON.stringify(err));
      });
  }

  const closePostDlg = () => {
    setBlueskyDlg(false)
  };

  const handleApplyLibraryKit = async () => {
    setDlgLibraryKit(false);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);
    await gqlservice
      .update_user_project_librarykit(authUser.uid, !profile.project_librarykit)
      .then(
        result => {
          let user = result.data.update_users.returning[0];
          props.updateUserProfile(user);
          setProject("");
        },
        reason => {
          setError(reason.msg);
        }
      )
      .catch(err => {
        setError(JSON.stringify(err));
      });
  }

  const handlepostsNeedMore = async () => {
    if (requesting) {
      return;
    }
    setWaiting(true);
    
    try {
      if(my_posts.length >= postCounts || finalpostcount === 0) {
        setWaiting(false)
        return
      }
      if (profiletab !== PROFILE_TAB_POSTS){
        setWaiting(false)
        return
      }
      const gqlservice = new GraphqlService();
      const token = await getAuthToken();
      if (!token) {
        handleLogin();
        return;
      }
      gqlservice.set_auth_jwt(token);
      let oldestDate = null;
      if (articlePosts.length > 0) {
        oldestDate = articlePosts.reduce((oldest, post) => {
          return post.published < oldest ? post.published : oldest;
        }, Infinity);
      }

      let dateTo = null
      if (articleReposts.length > 0) {
        dateTo = articleReposts.reduce((oldest, repost) => {
          return repost.created_at < oldest ? repost.created_at : oldest;
        });
      }
  
      const sourceId = `${authUser.uid}-selfpost`
      const result = await gqlservice.articles_to_profile(authUser.uid, sourceId, oldestDate);
      let newPosts = result.data.articles;
      setArticlePosts([...result.data.articles])

      if (repostCount > articleReposts.length) {
        let myRepostsResult = []
        if (newPosts.length < 5) {
          oldestDate = null
          myRepostsResult = await gqlservice.article_reposts(authUser.uid, oldestDate, dateTo);
        } else {
          myRepostsResult = await gqlservice.article_reposts(authUser.uid, oldestDate, dateTo);
        }
        const myReposts = myRepostsResult.data.article_repost;
        if (myReposts.length > 0) {
        for (let i = 0; i < myReposts.length; i++) {
          newPosts.push(myReposts[i].article);
        }
        setArticleReposts(myReposts);
        }
      }
      // if (newPosts.length > 0) {
      //   let articlesWithoutSourceDetails = newPosts.filter(article => (
      //     article.source_id !== null && !sources.some(source => source.id === article.source_id)
      //   ));
      //   if (articlesWithoutSourceDetails.length > 0) {
      //     const getSourceDetails = async (article) => {
      //       try {
      //         const sourceDetailsResult = await gqlservice.source_and_feed_sources_by_id(article.source_id);
      //         var sourceDetails = sourceDetailsResult.data.sources[0];
      //         props.addSource(sourceDetails);
      //         const feedFromSource = sourceDetails.feed_sources[0]?.feed;
      //         if (feedFromSource ) {
      //         props.addFeed(feedFromSource);
      //         }
      //       } catch (error) {
      //         console.error(`Error fetching source details for article ${article.nid}:`, error);
      //       }
      //     };
      //     await Promise.all(articlesWithoutSourceDetails.map(article => getSourceDetails(article)));
      //   }
      // }
      const uniqueNids = new Set(my_posts.map(article => article.nid));
      const filteredNewPosts = newPosts.filter(article => !uniqueNids.has(article.nid));
      if (filteredNewPosts.length === 0) {
        setFinalpostcount(0);
      }
      props.setMyPosts([...my_posts, ...filteredNewPosts]);

    } catch (error) {
      console.error("Error fetching more articles:", error);
    } finally {
      setWaiting(false);
    }
  };
  
  // const getFeedofSource = (source_id) => {
  //   let feed = null;
  //   if (loggedIn) {
  //     feed = followed_feeds.find(
  //       (item) =>
  //         item.feed_sources.find(
  //           (feed_source) => feed_source.source_id === source_id
  //         ) !== undefined
  //     );
  //     if (feed) {
  //       return feed;
  //     }
  //   }

  //   feed = props.feeds.find(
  //     (item) =>
  //       item.feed_sources.find(
  //         (feed_source) => feed_source.source_id === source_id
  //       ) !== undefined
  //   );
  //   return feed;
  // }

  

  const handleSelectArticle = (article) => {
    props.selectArticle(article);

    let path = `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path = `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    }
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    props.history.push(location);
    props.setArticleBackRoute(ROUTES.PROFILE);
  }

  const handleSelectGroupArticle = async (nid) => {}

  const handleReportArticle = async (article, reportMsg) => {}

  const handleEditArticle = (article) => {
    // const { loggedIn, authUser } = this.props;
    if (!loggedIn || authUser.uid !== article.txt_param1) {
      return;
    }
    setPostDlg(true);
    setArticleEdit(article);
  }

  const handleDeleteArticle = async (article) => {
    // const { loggedIn, authUser } = this.props;
    if (!loggedIn || authUser.uid !== article.txt_param1) {
      return;
    }
    setWaiting(true);
    await deleteArticle(article);
    setWaiting(false);
  }

  const handleSaveArticle = async (article) => {
    setWaiting(true);
    await saveArticle(article);
    setWaiting(false);
  }

  const handleDeleteSavedArticle = (article) => {}

  const handlePinArticle = (article) => {}
  const handleMoveTopArticle = (article) => {}
  const handleCommentArticle = (article) => {}
  
  const handleClickSource = (source, feed) => {
    // const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    // const location = {
    //   pathname: path,
    //   state: { animation: "left" },
    // };
    // props.history.push(location);
  }

  const handleClickFeed = (feed_id) => {

    let feeds2show = followed_feeds.slice();
    if (feeds2show.length === 0) {
      feeds2show = getFeeds2show()
    }

    let feed = feeds2show.find((item) => item.id === feed_id);
    let route = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // Go to moderation page if the logged user is the moderator of the feed
    // if (isFeedModerator(feed)) {
    //   route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${feed.slug}`;
    // }

    props.selectFeed(feed);
    props.selectProfileTab(PROFILE_TAB_FEEDS);
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    props.history.push(location);

    props.setFeedBackRoute(ROUTES.PROFILE);
    // props.refreshArticles();
    // props.clsArticlePins();
    // props.clsArticleMovetops();
    // props.refreshThreads();
    // props.initScrollPos();
    // props.selectCountry(ALL);
    // props.selectBranch(BRANCH_ALL);
  }

  const handleUnFollowFeed = async (feed_id) => {
    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    let feeds2show = followed_feeds.slice();
    if (feeds2show.length === 0) {
      feeds2show = getFeeds2show()
    }
    const feed = feeds2show.find((item) => item.id === feed_id);
    if (!feed) {
      return;
    }
    const res = await unfollowFeed(feed);
    if (res === false){
      return
    }
  };

  const handleClickUpvote = async (article) => {
    setWaiting(true);
    await upvoteArticle(article);
    setWaiting(false);
  }

  const handleClickComment = (article) => {
    handleSelectArticle(article);
  }

  const handleClickRepost = async (article) => {
    setWaiting(true);
    await repostArticle(article);
    setWaiting(false);
  }

  const closePostEditDlg = () => {
    setPostDlg(false);
    setArticleEdit(null);
  };

  const handleUpdatePost = async (description, postlink) => {
    setPostDlg(false);

    setWaiting(true);
    await updatePost(description, postlink, articleEdit);
    setWaiting(false);
    setArticleEdit(null);
  }

  const renderLinks = (myprofile, classes, theme_mode, handleUpdateAccount, onClickCrypto) => {
    if (!myprofile.links) {
      return <div style={{display: "none"}}></div>;
    }
    const twitter = myprofile.links.find(link => link.type === "twitter");
    const instagram = myprofile.links.find(link => link.type === "instagram");
    const linkedin = myprofile.links.find(link => link.type === "linkedin");
    let twitter_value = "";
    if (twitter?.value) {
      twitter_value = twitter.value;
      console.log("twitter value :", twitter_value);
      if (twitter_value[0] !== "@") {
        twitter_value = "@" + twitter_value;
      }
    }

    let instagram_value = "";
    if (instagram?.value) {
      instagram_value = instagram.value;
      if (instagram_value[0] !== "@") {
        instagram_value = "@" + instagram_value;
      }
    }

    let linkedin_value = "";
    if (linkedin?.value) {
      linkedin_value = linkedin.value;
      linkedin_value = get_substring(linkedin_value, "linkedin.com/");
    }

    let cryptoAddr = "";
    if (cryptoAddress) {
      cryptoAddr = cryptoAddress;
    } else if (cryptoConnected && account !== undefined) {
      cryptoAddr = `${account.substring(0, 6)}...${account.substring(account.length - 4)}`;
    } else {
      cryptoAddr = "";
    }
    return (
      <Grid
        container
        className={classes.linkbuttons}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <SocialButton
            theme_mode={theme_mode}
            socket={socket}
            provider={"twitter"}
            username={twitter_value}
            onUpdateUser={handleUpdateAccount}
          />
        </Grid>
        <Grid item>
          <SocialButton
            theme_mode={theme_mode}
            socket={socket}
            provider={"instagram"}
            username={instagram_value}
            onUpdateUser={handleUpdateAccount}
          />
        </Grid>
        <Grid item>
          <SocialButton
            theme_mode={theme_mode}
            socket={socket}
            provider={"linkedin"}
            username={linkedin_value}
            onUpdateUser={handleUpdateAccount}
          />
        </Grid>

        <Grid item>
          <Button
            className={classes.actionbtn}
            startIcon={
              <img
                className={classes.actionicon}
                alt="approve"
                src={`/static/images/icons/${theme_mode}/crypto.png`}
              />
            }
            onClick={onClickCrypto}
          >
            {cryptoConnected ? cryptoAddr : "Add Wallet"}
          </Button>
        </Grid>
        {/* <DlgWeblink open={webDlg} onClose={closePostDlg} /> */}
      </Grid>
    );
  };

 

  return (
    <div className={classes.root}>
      <div className={classes.appbar}>
        <BasicAppBar
          width={MAX_ARTICLE_WIDTH}
          title={"Profile"}
          onNavBack={handleNavBack}
        />
      </div>
      <div className={classes.profileContainer}>
        <div className={classes.profileContent}>
          {/* <div className={classes.titlediv}>
            <Typography className={classes.title}>
              Tell us about yourself
            </Typography>
          </div> */}
          <div>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={1}
            >
              <Grid item>
                <ImageSelector
                  image={profile.image === "" ? BLANK_USER_IMAGE : profile.image}
                  imageHandleChanged={handleImageChange}
                />
              </Grid>
              {hasPaid() &&
                <Grid item>
                  <img
                    className={classes.statusicon}
                    alt="paiduser"
                    src={`/static/images/icons/${theme_mode}/shield.png`}
                  />
                </Grid>
              }
              {_isModerator() &&
                <Grid item>
                  <img
                    className={classes.statusicon}
                    alt="moderator"
                    src={`/static/images/icons/${theme_mode}/moderator.png`}
                  />
                </Grid>
              }
              {upvotes > 0 &&
                <Grid item>
                  <img
                    className={classes.statusicon}
                    alt="upvotes"
                    src={`/static/images/icons/thumbsup.png`}
                  /><span className={classes.upvotes}>{upvotes}</span>
                </Grid>
              }
            </Grid>
            <div className={classes.detail}>
              <input
                name="name"
                placeholder="Your name"
                value={profile.name || ""}
                className={classes.name}
                onChange={handleChange}
              />
              <div>
                <p className={classes.adornment}>@</p>
                <input
                  name="username"
                  placeholder="your username"
                  value={profile.username || ""}
                  className={classes.username}
                  onChange={handleChange}
                />
              </div>
              <textarea
                name="biography"
                placeholder="Add your details here"
                value={profile.biography || ""}
                className={classes.description}
                onChange={handleChange}
              />
            </div>
            <div>
              <Grid
                container
                className={classes.stats}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" className={classes.statText}
                    onClick={handlefollowers}
                  >
                    {authUser.user_followers.length} Followers
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.statText}
                    onClick={handlefollowing}
                  >
                    {authUser.users_followed.length} Following
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.statText}
                    onClick={handleShowPosts}
                  >
                    {postCounts !== null ? postCounts : 0} Posts
                  </Typography>
                </Grid>
              </Grid> 
            </div>
          </div>
          {renderLinks(profile, classes, theme_mode, handleUpdateAccount, handleClickCrypto)}
        </div>

        <div className={classes.profiletabs}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
      <div id="profile-tabs" className={isProfileTabsFixed ? classes.childProfileTab : ''}>
             <Grid item>
               <ProfileTabs
                 theme_mode={theme_mode}
                 onChangeTab={handleSwipeChange}
               />
             </Grid>
           </div >
          </Grid>
          <div className={classes.childProfilecontainer}>
            <SwipeableViews
              index={profiletab}
              onChangeIndex={handleSwipeChange}
              onScroll={handleSwipeScroll}
              enableMouseEvents
            >
              <div
                style={{ marginBottom: 30}}
                id="profile-swipeable-views-posts"
              >
                {profiletab === PROFILE_TAB_POSTS && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      {my_posts.length > 0 && (
                        <ArticleModList
                          userProfile={true}
                          articles={my_posts}
                          aiSummary={[]}
                          pins={[]}
                          movetops={[]}
                          onNeedMore={handleNeedMore}
                          onSelectArticle={handleSelectArticle}
                          onSelectGroupArticle={handleSelectGroupArticle}
                          onDelete={handleDeleteArticle}
                          onEdit={handleEditArticle}
                          onPin={handlePinArticle}
                          onComment={handleCommentArticle}
                          onMoveTop={handleMoveTopArticle}
                          onSave={handleSaveArticle}
                          onDeleteSaved={handleDeleteSavedArticle}
                          onClickSource={handleClickSource}
                          onClickFeed={handleClickFeed}
                          onClickUpvote={handleClickUpvote}
                          onClickComment={handleClickComment}
                          onClickRepost={handleClickRepost}
                        />
                      )}
                      {postCounts === 0 && (
                        <Grid item>
                          <div className={classes.warningcontainer}>
                            <div className={classes.warningimg}>
                              <img
                                className={classes.warningicon}
                                alt="warning"
                                src={`/static/images/icons/${theme_mode}/warning.png`}
                              />
                            </div>
                            <Typography className={classes.warningtitle}>
                              No posts in the profile yet.
                            </Typography>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                 <WaitingSpinner open={requesting} /> 
              </div>
              <div
                style={{ marginBottom: 30}}
                id="profile-swipeable-views-feeds"
              >
                {profiletab === PROFILE_TAB_FEEDS &&
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                  >
                    {authUser.feeds_followed.map((item, index) => (
                      <Grid item key={`feed-${index}`}>
                        <FeedTabItem
                          width={width === undefined ? null : width}
                          feed={item}
                          index={index}
                          following={true}
                          loggedIn={loggedIn}
                          theme_mode={theme_mode}
                          onClickFeed={handleClickFeed}
                          onFollowed={handleUnFollowFeed}
                        />
                      </Grid>
                    ))}
                  </Grid>
                }
              </div>
              <div
                id="profile-swipeable-views-settings"
              >
                {profiletab === PROFILE_TAB_SETTINGS &&
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <div className={classes.blockcontainer}>
                      <div className={classes.blockdiv}>

                        <div className={classes.newsblockdiv}>
                          <Grid
                            justifyContent="space-between"
                            className={classes.blockTitleBar}
                          >
                            <Typography className={classes.blockTitle}>Email Newsletter</Typography>
                            <Button
                              className={classes.selectfollowing}
                              clickable={"false"}
                              endIcon={<ArrowForwardIosIcon fontSize="small" style={{ width: 16, marginBottom: 2 }} />}
                              onClick={handleShowFeeds}
                            >
                              <Typography className={classes.selectbuttontext}>
                                Select
                              </Typography>
                            </Button>
                          </Grid>
                          <div className={classes.buttonbox}>
                            {authUser.subscribe_email === null && (
                              <Input
                                id="input-email"
                                fullWidth
                                name="email"
                                value={profile.email || ""}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <img
                                      alt={"email"}
                                      src={`/static/images/icons/${theme_mode}/email.png`}
                                      className={classes.branchimg}
                                    />
                                  </InputAdornment>
                                }
                                onChange={handleChange}
                              />
                            )}
                          </div>
                          <div className={classes.buttonbox}>
                            {authUser.subscribe_email === null && (
                              <Button
                                className={classes.subscribebutton}
                                disabled={profile.email === "" || authUser.feeds_subscribed.length === 0}
                                clickable={"false"}
                                onClick={handleClickSubscribe}
                              >
                                <Typography className={classes.buttontext}>
                                  Subscribe
                                </Typography>
                              </Button>
                            )}
                            {authUser.subscribe_email !== null && authUser.feeds_subscribed.length >= 0 && (
                              <Button
                                className={classes.subscribebutton}
                                disabled={false}
                                clickable={"false"}
                                onClick={handleClickUnsubscribe}
                              >
                                <Typography className={classes.buttontext}>
                                  Unsubscribe
                                </Typography>
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className={classes.settingblockdiv}>
                          <div className={classes.settingblock}>
                            <div className={classes.settingmastodon}>
                              <div style={{ width: '85%' }}>
                                <FormControlLabel
                                  control={
                                    <CustomCheckBox
                                      checked={postToMastodon}
                                      onChange={handlePostToMastodonChange}
                                    />
                                  }
                                  labelPlacement="end"
                                  label={
                                    <div>
                                      <Typography variant="h5">Post to Mastodon?</Typography>
                                      <Typography variant="body1">
                                        Your public posts will be visible on the Mastodon network
                                      </Typography>
                                    </div>
                                  }
                                />
                              </div>
                              <img
                                alt={"mastodon"}
                                src={`/static/images/icons/${theme_mode}/mastodon.png`}
                                className={classes.socialimg}
                              />
                            </div>

                            <div className={classes.settingbluesky}>
                              <div style={{ width: '85%' }}>
                                <FormControlLabel
                                  control={
                                    <CustomCheckBox
                                      checked={connectToBlueSky}
                                      onChange={handleConnectToBlueSkyChange}
                                    />
                                  }
                                  labelPlacement="end"
                                  label={
                                    <>
                                      <Typography variant="h5">Connect your BlueSky Account?</Typography>
                                      <Typography variant="body1">
                                        Your public posts will be visible on the BlueSky network
                                      </Typography>
                                    </>
                                  }
                                />
                              </div>
                              <img
                                alt={"bluesky"}
                                src={`/static/images/icons/${theme_mode}/bluesky.png`}
                                className={classes.socialimg}
                              />
                            </div>
                          </div>
                        </div>
                          <div style={{marginBottom:15, marginTop: 10}}>
                        <Typography>{joinDate}</Typography>
                      </div>
                      </div>
                    </div>
                  </Grid>
                }
              </div>
            </SwipeableViews>
          </div>
        </div>
{/* 
        <div className={classes.blockcontainer}>
          <div className={classes.blockdiv}>
            <div className={classes.blockTitleBar}>
              <Typography className={classes.blockTitle}>My Feeds</Typography>
            </div>
            <div className={classes.slide}>
              <SlideIconLists
                width={width - 16}
                items={followed_feeds}
                onItemSelected={handleSelectFeeds}
              />
            </div>
          </div>
        </div> */}

        {/* <div className={classes.blockcontainer}>
          <div className={classes.blockdiv}>
            <div className={classes.blockTitleBar}>
              <Typography className={classes.blockTitle}>Following</Typography>
            </div>
            <div className={classes.slide}>
              <SlideIconLists
                width={width - 16}
                items={authUser.users_followed.map(followed => followed.user)}
                onItemSelected={handleSelectFollowingUser}
              />
            </div>
          </div>
        </div> */}

        {/* <div className={classes.blockcontainer}>
          <div className={classes.blockdiv}>
            <div className={classes.blockTitleBar}>
              <Typography className={classes.blockTitle}>My Posts</Typography>
              <Button
                className={classes.selectfollowing}
                style={{right: seeAllPos - 16}}
                clickable={"false"}
                endIcon={<ArrowForwardIosIcon fontSize="small" style={{width: 16, marginBottom: 2}} />}
                onClick={handleShowPosts}
              />
            </div>
            {my_posts.length > 0 && (
              <div className={classes.articles}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <ArticleModList
                      articles={[my_posts[0]]}
                      pins={[]}
                      movetops={[]}
                      onNeedMore={handleNeedMore}
                      onSelectArticle={handleSelectArticle}
                      onSelectGroupArticle={handleSelectGroupArticle}
                      onNeedLogin={handleLogin}
                      onPin={handlePinArticle}
                      onComment={handleCommentArticle}
                      onMoveTop={handleMoveTopArticle}
                      onReport={handleReportArticle}
                      onEdit={handleEditArticle}
                      onDelete={handleDeleteArticle}
                      onSave={handleSaveArticle}
                      onDeleteSaved={handleDeleteSavedArticle}
                      onClickSource={handleClickSource}
                      onClickFeed={handleClickFeed}
                      onClickUpvote={handleClickUpvote}
                      onClickComment={handleClickComment}
                      onClickRepost={handleClickRepost}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </div> */}

        {/* <div className={classes.blockcontainer}>
          <div className={classes.blockdiv}>
            <Typography className={classes.blockTitle}>Join a Project</Typography>
            <div className={classes.buttonbox}>
              <Input
                id="input-join-project"
                fullWidth
                name="project"
                value={project || ""}
                onChange={handleChangeProject}
              />
            </div>
            {profile.project_scistarter &&
              <div className={classes.buttonbox}>
                <Button 
                  className={classes.projectbutton}
                  onClick={e => setDlgSciStarter(true)}
                >
                  <img
                    className={classes.projectimage}
                    alt="scistarter"
                    src={"/static/images/scistarter.png"}
                  />
                  {"SciStarter (Active)"}
                </Button>
              </div>
            }
            {profile.project_librarykit &&
              <div className={classes.buttonbox}>
                <Button 
                  className={classes.projectbutton}
                  onClick={e => setDlgLibraryKit(true)}
                >
                  <Avatar
                    className={classes.projectimage}
                    alt="librarykit"
                    src={"/static/images/librarykit.png"}
                  />
                  {"Library Kit (Active)"}
                </Button>
              </div>
            }
          </div>
        </div> */}

        {/* <div className={classes.blockcontainer}>
          <Typography>{joinDate}</Typography>
        </div> */}
        {/* <div className={classes.blockcontainer}>
          {create_mode &&
          <div className={classes.buttonbox}>
            <Button
              className={classes.skipbutton}
              onClick={handleSkip}
            >
              <Typography className={classes.skipbuttontext}>
                Skip
              </Typography>
            </Button>
          </div>
          }

        </div> */}
      </div>
      <div className={classes.bottomdiv}></div>
      <div className={classes.floatingButtonContainer}>
        <div className={classes.buttonbox}>
          {(profile.username !== "" || profile.name !== "") &&
            <Button
              className={classes.savebutton}
              disabled={false}
              clickable={"false"}
              endIcon={<ArrowForwardIcon style={{ color: "white" }} />}
              onClick={handleSubmitProfile}
            >
              <Typography className={classes.buttontext}>
                Save
              </Typography>
            </Button>
          }
          {(profile.username === "" && profile.name === "") &&
            <Button
              className={classes.savebutton_disabled}
              disabled={true}
              endIcon={<ArrowForwardIcon style={{ color: "white" }} />}
            >
              <Typography className={classes.buttontext}>
                Save
              </Typography>
            </Button>
          }
        </div>
      </div>


      {postDlg &&
        <DlgPostEdit
          open={postDlg}
          theme={theme_mode}
          article={articleEdit}
          onSubmit={handleUpdatePost}
          onClose={closePostEditDlg}
        />
      }
      {/* <WaitingSpinner open={requesting} /> */}
      <ToastContainer />
      <DlgProject
        open={dlgSciStarter}
        project={"scistarter"}
        connect={!profile.project_scistarter}
        theme={theme_mode}
        onApply={handleApplySciStarter}
        onClose={e => setDlgSciStarter(false)}
      />
      <DlgProject
        open={dlgLibraryKit}
        project={"librarykit"}
        connect={!profile.project_librarykit}
        theme={theme_mode}
        onApply={handleApplyLibraryKit}
        onClose={e => setDlgLibraryKit(false)}
      />
      <DlgBluesky open={blueskyDlg} onClose={closePostDlg} />
    </div>
  );
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  sources: state.dataState.sources,
  tags: state.dataState.tags,
  my_posts: state.dataState.my_posts,
  theme_mode: state.uiState.theme_mode,
  profiletab: state.uiState.profiletab,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  // withRouter,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
)(Profile);
