import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from 'services';
import MetaTags from 'react-meta-tags';
import { ToastContainer } from "react-toastify";
import * as ROUTES from "constants/routes";
import { GraphqlService } from "services";
import {
  ArticleBar,
  MapUserpostView,
  // DiscussionButton,
  // CommentList,
  DlgShareMapPost,
} from "components";
import { 
  MAX_ARTICLE_WIDTH,
  // DISCUSSION_RAVEN,
  // THREAD_TYPE_ARTICLE,
} from "constants/types";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";

const styles = theme => ({
  root: {
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  discussion: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    margin: theme.spacing(1),
  },
  discussbutton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    margin: 0,
  },
});

class CleanAirPost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postShareDlg: false,
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleMemberTrial = this.handleMemberTrial.bind(this);

    this.handleSharePost = this.handleSharePost.bind(this);
    this.handleCloseSharePost = this.handleCloseSharePost.bind(this);
  }

  setError = message => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const nid = this.props.match.params.id;
    const { selected_map_post } = this.props;

    this.setWaiting(true);
    if (selected_map_post === null) {
      await this.getMapPost(nid);
    }
    this.setWaiting(false);

    window.scrollTo(0, 0);
  }

  getMapPost = async (nid) => {
    const gqlservice = new GraphqlService();
    await gqlservice
      .map_post_by_nid(nid)
      .then(
        result => {
          const articles = result.data.articles;
          if (articles.length > 0) {
            this.props.selectMapPost(articles[0]);
          }
        },
        reason => {
          this.setError(reason.msg);
        }
      )
      .catch(err => {
        this.setError(JSON.stringify(err));
      });
  }

  handleNavBack = () => {
    const { article_backroute } = this.props;
    const location = {
      pathname: article_backroute ? article_backroute : ROUTES.CLEANAIRMAP,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleMemberTrial = () => {
    const location = {
      pathname: ROUTES.MEMBERSHIP,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleSharePost = () => {
    this.setState({
      ...this.state,
      postShareDlg: true
    });
  }

  handleCloseSharePost = () => {
    this.setState({
      ...this.state,
      postShareDlg: false
    });
  }

  render() {
    const { 
      classes, 
      selected_map_post,
    } = this.props;
    const {
      postShareDlg,
    } = this.state;

    if (!selected_map_post) {
      return <div></div>
    }
    
    logger.log("CleanAirPost selected_map_post :", selected_map_post);

    // get share url
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.CLEANAIRMAP_PREFIX}/${ROUTES.ARTICLE_PREFIX}/${selected_map_post.nid}`;

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven: ${selected_map_post.title}`}</title>
            <meta name="description" content={selected_map_post.summary} />
            <meta property="og:title" content={`Raven CleanAirMap: ${selected_map_post.title}`} />
            <meta property="og:description" content={selected_map_post.summary} />
            <meta property="og:image" content={selected_map_post.image === null ? "" : selected_map_post.image} />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={shareUrl} />
            <meta property="twitter:title" content={`Raven: ${selected_map_post.title}`} />
            <meta property="twitter:site" content="Raven App" />
            <meta property="twitter:description" content={selected_map_post.summary} />
            <meta property="twitter:image:src" content={selected_map_post.image === null ? "" : selected_map_post.image} />
            <meta property="twitter:image:alt" content={selected_map_post.title} />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar}>
          <ArticleBar
            title={"CleanAir Post"}
            onNavBack={this.handleNavBack}
            onShare={this.handleSharePost}
          />
        </div>
        
        <MapUserpostView
          article={selected_map_post}
        />

        {/* <div>
          <div className={classes.discussion}>
            <DiscussionButton
              type={DISCUSSION_RAVEN}
              title={"Discussion on Raven"}
              description={"Moderated Comments"}
              image={`/static/images/icons/raven_logo.png`}
            />
          </div>
          <CommentList
            type={THREAD_TYPE_ARTICLE}
            // thread_id={selected_article.nid}
            thread_id={
              selected_map_post.threads?.length > 0
                ? selected_map_post.threads[0].id
                : null
            }
            onError={this.setError}
            onNeedLogin={this.handleLogin}
            onMemberTrial={this.handleMemberTrial}
          />
        </div> */}

        {/* Post Share */}
        <DlgShareMapPost
          open={postShareDlg}
          post={selected_map_post}
          onClose={this.handleCloseSharePost}
        />        
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selected_map_post: state.mapState.selected_map_post,
  article_backroute: state.uiState.article_backroute,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CleanAirPost);
