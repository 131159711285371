import store from "store";
import * as ActionTypes from "actions/ActionTypes";
import { GraphqlService } from "services";
import { ERR_MSG_AUTH_TOKEN, getAuthToken } from "./token";
import { setError, updateCache } from "./base";
import {
  GRAPHQL_ERROR,
  FEED_COMMENT_DISABLE,
  FEED_COMMENT_CLOSEALL,
  FEED_COMMENT_TWITTERUSER,
} from "constants/types"
import {
  ACTIVITY_TYPE_CATEGORY,
  ACTIVITY_TYPE_FEED,
  ACTIVITY_APPROVE,
  ACTIVITY_DELETE,
} from "constants/activity";
import { NOTIFICATION_FEED_APPROVE } from "constants/notification";
import { logger } from "utility/logging";
import { get_cached_item, set_cache_item } from "utility/cachemanager";
import { hasPaid, hasTwitter } from "./user";
import { ToastWarning } from "utility/toast";
import _ from "lodash";
import { updateNodeCacheOfFeed } from "utility/ravenapi";


const existFeedbyID = async (id) => {
  const state = store.getState();

  const { loggedIn } = state.sessionState;

  const gqlservice = new GraphqlService();
  if (loggedIn) {
    const token = await getAuthToken();
    if (!token) {
      return {
        error: true,
        message: ERR_MSG_AUTH_TOKEN
      };
    }
    gqlservice.set_auth_jwt(token);
  }

  let result = await gqlservice.feed_exist_by_id(id);
  if (result.status_code === GRAPHQL_ERROR) {
    return {
      error: true,
      message: result.msg
    };
  }

  try {
    return {
      error: false,
      exist: result.data.feeds_aggregate.aggregate.count > 0
    }
  } catch(err) {
    return {
      error: true,
      message: err.toString()
    }
  }
};

const existFeedbySlug = async (slug) => {
  const state = store.getState();

  const { loggedIn } = state.sessionState;

  const gqlservice = new GraphqlService();
  if (loggedIn) {
    const token = await getAuthToken();
    if (!token) {
      return {
        error: true,
        message: ERR_MSG_AUTH_TOKEN
      };
    }
    gqlservice.set_auth_jwt(token);
  }

  let result = await gqlservice.feed_exist_by_slug(slug);
  if (result.status_code === GRAPHQL_ERROR) {
    return {
      error: true,
      message: result.msg
    };
  }

  try {
    return {
      error: false,
      exist: result.data.feeds_aggregate.aggregate.count > 0
    }
  } catch(err) {
    return {
      error: true,
      message: err.toString()
    }
  }
};

const getFeedInfo = async (slug) => {
  const state = store.getState();

  const { loggedIn } = state.sessionState;
  const { feeds, sources } = state.dataState; 

  await store.dispatch({ type: ActionTypes.SET_REQUEST_GET_FEED_INFO, state: true })

  const gqlservice = new GraphqlService();
  if (loggedIn) {
    const token = await getAuthToken();
    if (!token) {
      setError(ERR_MSG_AUTH_TOKEN);
      return;
    }
    gqlservice.set_auth_jwt(token);
  }

  let result = await gqlservice.feed_by_slug(slug);
  logger.log("feed by slug :", result);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (result.data.feeds.length === 0) {
    setError(`Can't find the feed ${slug}`);
    return;
  }

  const feed = result.data.feeds[0];

  let new_feeds = feeds.slice();
  let new_sources = sources.slice();
  if (!new_feeds.find((item) => item.id === feed.id)) {
    new_feeds.push(feed);
  }
  if (feed.feed_sources.length > 0) {
    let feed_sources = feed.feed_sources.map((item) => item.source);
    for (let source of feed_sources) {
      if (!new_sources.find((item) => item.id === source.id)) {
        new_sources.push(source);
      }
    }
  }

  await Promise.all([
    store.dispatch({ type: ActionTypes.SET_FEEDS, feeds: new_feeds }),
    store.dispatch({ type: ActionTypes.SET_SOURCES, sources: new_sources }),
    store.dispatch({ type: ActionTypes.SELECT_FEED, feed: feed }),
    store.dispatch({ type: ActionTypes.SET_REQUEST_GET_FEED_INFO, state: false })
  ]);

  // update cache
  let cache_name = loggedIn
    ? hasPaid()
      ? "base_data_paiduser_v1"
      : "base_data_user_v1"
    : "base_data_anonymous_v1";
  let base_data = await get_cached_item(cache_name);
  if (base_data) {
    base_data.feeds = new_feeds;
    base_data.sources = new_sources;
    set_cache_item(cache_name, base_data);
  }
};

const setFeedData = async (data) => {

  await Promise.all([
    store.dispatch({ type: ActionTypes.SELECT_FEED, feed: data.selectedFeed }),
    store.dispatch({ type: ActionTypes.SET_REQUEST_GET_FEED_INFO, state: false })
  ]);
}


const updateFeedCache = async (slug) => {
  const state = store.getState();
  const { authUser } = state.sessionState;
  var params = JSON.stringify({
    user: authUser.uid ? authUser.uid : null,
    feed: slug
  });  
  // store base_data to the cache
  const data = await updateNodeCacheOfFeed(params) 
  await Promise.all([
    store.dispatch({ type: ActionTypes.SELECT_FEED, feed: data.selectedFeed }),
    store.dispatch({ type: ActionTypes.SET_ARTICLE_PINS, pins: data.pins }),
    store.dispatch({ type: ActionTypes.SET_ARTICLE_MOVETOPS, movetops: data.movetops }),
    store.dispatch({ type: ActionTypes.SET_ARTICLES_ON_SEARCH_TERM_SOURCE, articles: data.searchTermSourceArticles, last_offset:data.searchTermSourceArticles.length, feed_id: data.selectedFeed.id }),
    store.dispatch({ type: ActionTypes.SET_ARTICLES_ON_FEED_MODERATION, articles: data.articles, last_offset: data.articles.length, feed_id: data.selectedFeed.id }),
    store.dispatch({ type: ActionTypes.SET_FEEDPOSTS, feed_posts: data.feedPosts }),
  ]);
}

const getFeedModerates = async (feed) => {
  await store.dispatch({ type: ActionTypes.SET_REQUEST_GET_FEED_MODERATES, state: true })
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return null;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  let result = await gqlservice.feed_by_id(feed.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return null;
  }
  const feeds = result.data.feeds;
  if (feeds.length === 0) {
    setError(`Can't find the feed ${feed.name}`);
    return null;
  }

  let new_feed = feeds[0];
  if (new_feed.feed_sources.length === 0) {
    return null;
  }
  const sources2moderate = new_feed.feed_sources.filter(
    (feed_source) => !feed_source.approved
  );

  const feed_sources = new_feed.feed_sources.filter(
    (feed_source) => feed_source.approved
  );
  if (feed_sources.length === 0) {
    return null;
  }
  const feed_source_ids = feed_sources.map(
    (feed_source) => feed_source.source_id
  );
  if (feed_source_ids.length === 0) {
    return null;
  }

  // show only previous 7 day's log
  const timestamp = new Date() - 86400 * 7 * 1000;
  const after = new Date(timestamp).toISOString();

  result = await gqlservice.feed_moderation_fields(
    feed.id,
    feed_source_ids,
    after,
    ACTIVITY_TYPE_FEED
  );
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return null;
  }

  const source_reports = result.data.source_reports.slice();
  const user_posts = result.data.articles.slice();
  let feed_posts = result.data.feed_posts.slice();
  const article_reports = result.data.article_reports.slice();
  const thread_reports = result.data.thread_reports.slice();
  const threads = result.data.threads.slice();
  const feed_moderators = result.data.feed_moderators.slice();
  const moderator_reports = result.data.moderator_reports.slice();
  const feed_logs = result.data.activitylogs.slice();

  // reports of a feed should be processed by category moderation
  // new_feed.reports = feed_reports_count;

  const source_reports_moderate = source_reports.filter(
    (report) => !report.approved
  );
  const user_posts_moderate = user_posts.filter(
    post => post.param1 === 0
  );
  const article_reports_moderate = article_reports.filter(
    (report) => !report.approved
  );
  const threads_moderate = threads.filter(
    thread => !thread.approved
  );
  const thread_reports_moderate = thread_reports.filter(
    (report) => !report.approved
  );
  const feed_moderators_moderate = feed_moderators.filter(
    (moderator) => !moderator.approved
  );
  const moderator_reports_moderate = moderator_reports.filter(
    (report) => !report.approved
  );
  feed_posts = feed_posts.map((post) => post.article);

  new_feed.proposed_sources = sources2moderate;
  new_feed.source_reports = source_reports;
  new_feed.post_reports = article_reports;
  new_feed.proposed_posts = user_posts;
  new_feed.posted_posts = feed_posts;
  new_feed.threads = threads;
  new_feed.thread_reports = thread_reports;
  new_feed.moderators = feed_moderators;
  new_feed.moderator_reports = moderator_reports;

  // logger.log("feed moderations :", new_feed);
  // logger.log("sources2moderate :", sources2moderate);
  // logger.log("source_reports_moderate :", source_reports_moderate);
  // logger.log("user_posts_moderate :", user_posts_moderate);
  // logger.log("article_reports_moderate :", article_reports_moderate);
  // logger.log("threads_moderate :", threads_moderate);
  // logger.log("thread_reports_moderate :", thread_reports_moderate);
  // logger.log("feed_moderators_moderate :", feed_moderators_moderate);
  // logger.log("moderator_reports_moderate :", moderator_reports_moderate);

  // const new_feed_logs = feed_logs.filter(
  //   (log) => Date.parse(log.logged_at) > Date.parse(new_feed.last_viewlog)
  // );
  new_feed.feed_logs = feed_logs;

  const notifications =
    // feed_reports.length +
    sources2moderate.length +
    source_reports_moderate.length +
    user_posts_moderate.length + feed_posts.length +
    article_reports_moderate.length +
    threads_moderate.length +
    thread_reports_moderate.length +
    feed_moderators_moderate.length +
    moderator_reports_moderate.length;
  // new_feed_logs.length;

  let notif_date = null;
  for (let source of sources2moderate) {
      let dt = Date.parse(source.created_at);
      if (notif_date === null || notif_date < dt) {
          notif_date = dt;
      }
  }
  for (let report of source_reports_moderate) {
      let dt = Date.parse(report.reported_at);
      if (notif_date === null || notif_date < dt) {
          notif_date = dt;
      }
  }
  for (let user_post of user_posts_moderate) {
      let dt = new Date(user_post.published * 1000);
      if (notif_date === null || notif_date < dt) {
          notif_date = dt;
      }
  }
  for (let feed_post of feed_posts) {
      let dt = new Date(feed_post.published * 1000);
      if (notif_date === null || notif_date < dt) {
          notif_date = dt;
      }
  }
  for (let report of article_reports_moderate) {
      let dt = Date.parse(report.reported_at);
      if (notif_date === null || notif_date < dt) {
          notif_date = dt;
      }
  }
  for (let thread of threads_moderate) {
      let dt = Date.parse(thread.posted_at);
      if (notif_date === null || notif_date < dt) {
          notif_date = dt;
      }
  }
  for (let report of thread_reports_moderate) {
      let dt = Date.parse(report.reported_at);
      if (notif_date === null || notif_date < dt) {
          notif_date = dt;
      }
  }
  for (let moderator of feed_moderators_moderate) {
      let dt = Date.parse(moderator.created);
      if (notif_date === null || notif_date < dt) {
          notif_date = dt;
      }
  }
  for (let report of moderator_reports_moderate) {
      let dt = Date.parse(report.reported_at);
      if (notif_date === null || notif_date < dt) {
          notif_date = dt;
      }
  }

  if (notif_date !== null) {
      notif_date = new Date(notif_date).toISOString();
  }

  new_feed.notifications = notifications;
  new_feed.notif_date = notif_date;

  logger.log("notifications :", notifications, notif_date);
  store.dispatch({ type: ActionTypes.SET_REQUEST_GET_FEED_MODERATES, state: false })

  return new_feed;
};

const getFeeds2show = () => {
  const state = store.getState();

  const { loggedIn, authUser } = state.sessionState;
  const { feeds, default_feeds, followed_feeds } = state.dataState;

  let my_feeds = [];
  let moderated_feeds = [];
  if (loggedIn || feeds.length > 0) {
    my_feeds = feeds.filter((feed) => feed.created_by === authUser.uid);
    moderated_feeds = authUser.feeds_moderated
    .map((item) => feeds.find(feed => feed.id === item.feed_id))
    .filter(feed => feed && feed.created_by !== authUser.uid);
  }
  if (!loggedIn || my_feeds.length + moderated_feeds.length + followed_feeds.length === 0) {
    return default_feeds;
  }

  let feeds2show = [];
  // following feeds
  if (followed_feeds.length > 0) {
    feeds2show = followed_feeds.filter(feed => 
      feed.approved && // redundary check
      !feed.private &&
      my_feeds.find(item => item.id === feed.id) === undefined &&
      moderated_feeds.find(item => item.id === feed.id) === undefined
    );
  }

  const my_feeds_mod = my_feeds.filter(feed => feed.approved);
  const moderated_feeds_mod = moderated_feeds.filter(feed => feed.approved);
  feeds2show = feeds2show.concat(my_feeds_mod, moderated_feeds_mod);

  return feeds2show;
};

const approveFeed = async (feed) => {
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token, true);

  let result = await gqlservice.update_feed_approve(feed.id, true);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (result.data.update_feeds.returning.length > 0) {
    store.dispatch({
      type: ActionTypes.UPDATE_FEED, 
      feed: result.data.update_feeds.returning[0],
    });
  }
  
  const state = store.getState();
  const { authUser } = state.sessionState;
  const { selected_category } = state.dataState;

  // insert notification
  const notification = {
    type: NOTIFICATION_FEED_APPROVE,
    object: feed.id,
    in_which: selected_category.id,
    to: feed.created_by,
    created_by: authUser.uid
  }

  gqlservice.set_auth_jwt(token, false);
  result = await gqlservice.insert_notification(notification);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  // log this activity
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_CATEGORY,
    type_id: selected_category.id,
    action: ACTIVITY_APPROVE,
    object: `the feed ${feed.name}`,
    fromto: `of the category ${selected_category.name}`,
    reason: ''
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

const deleteFeed = async (feed) => {
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token, true);

  let result = await gqlservice.delete_feed(feed.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  if (result.data.delete_feeds.affected_rows > 0) {
    feed.category_id = "deleted";
    store.dispatch({
      type: ActionTypes.UPDATE_FEED, 
      feed: feed,
    });
  }
  
  const state = store.getState();
  const { authUser } = state.sessionState;
  const { selected_category } = state.dataState;

  gqlservice.set_auth_jwt(token, false);
  const activity = {
    user_id: authUser.uid,
    type: ACTIVITY_TYPE_CATEGORY,
    type_id: selected_category.id,
    action: ACTIVITY_DELETE,
    object: `the feed ${feed.name}`,
    fromto: `from the category ${selected_category.name}`,
    reason: ''
  };
  result = await gqlservice.insert_activitylog(activity);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

const updateFeedNotifications = async (feed) => {
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN)
    return;
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token, true);

  const new_feed = await getFeedModerates(feed);
  if (!new_feed) {
    return;
  }

  let result = await gqlservice.update_feed_notifications(
    new_feed.id, new_feed.notifications, new_feed.notif_date);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
}

const followFeed = async (feed) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN)
    return;
  }
  const { feeds_created, feeds_moderated } = authUser;
  const isFeedCreated = feeds_created.some(item => item.id === feed.id);
  const isFeedModerated = feeds_moderated.some(item => item.feed_id === feed.id);
  if (isFeedCreated || isFeedModerated) {
    ToastWarning("Created/Moderated Feed!")
    return
  }

  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  const { followed_feeds } = state.dataState;
  const follower = {
    id: `${feed.id}-${authUser.uid}`,
    feed_id: feed.id,
    user_id: authUser.uid,
    order: followed_feeds.length,
  };
  const new_followers = feed.followers + 1;

  let result = await gqlservice.update_feed_followers(feed.id, new_followers);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  result =  await gqlservice.insert_feed_follower(follower);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  const newFeed = result.data.insert_feed_followers.returning[0];
  store.dispatch({
    type: ActionTypes.UPDATE_FEED_FOLLOWERS, 
    feed_id: feed.id,
    followers: new_followers,
  });
  store.dispatch({
    type: ActionTypes.SET_FOLLOWING_FEED,
    feed: feed,
    following: true,
  });
   updateCache();
  const updatedAuthUser = { ...authUser };
  updatedAuthUser.feeds_followed.push(newFeed);
  store.dispatch({
    type: ActionTypes.SET_AUTH_USER,
    authUser: updatedAuthUser,
  })
}

const unfollowFeed = async (feed) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return;
  }

  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN)
    return;
  }
  const feedsJoined = []
  const followedFeeds = authUser.feeds_followed.map(feed => feed.feed);
  const moderatedFeeds = authUser.feeds_moderated.map(feed => feed.feed);
  const createdFeeds = authUser.feeds_created.filter(feed => feed.category_id !== "deleted");
  const feedsToShow = feedsJoined.concat(followedFeeds,moderatedFeeds,createdFeeds)
  const uniqueFeedsToShow = _.uniqBy(feedsToShow, 'id');
  if (uniqueFeedsToShow.length === 1) {
    ToastWarning("You must have at least one feed!")
    return false
  }
  const gqlservice = new GraphqlService();
  gqlservice.set_auth_jwt(token);

  const follower_id = `${feed.id}-${authUser.uid}`;
  const new_followers = feed.followers - 1;

  let result = await gqlservice.update_feed_followers(feed.id, new_followers);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  result = await gqlservice.delete_feed_follower(follower_id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  store.dispatch({
    type: ActionTypes.UPDATE_FEED_FOLLOWERS, 
    feed_id: feed.id,
    followers: new_followers,
  });
  store.dispatch({
    type: ActionTypes.SET_FOLLOWING_FEED,
    feed: feed,
    following: false,
  });
   updateCache();
  // delete feed order
  result = await gqlservice.delete_feed_order(authUser.uid, feed.id);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }

  const new_orders = authUser.feeds_order.filter(item => item.id !== feed.id);
  store.dispatch({
    type: ActionTypes.UPDATE_FEEDS_ORDER, 
    orders: new_orders,
  });
  const updatedAuthUser = { ...authUser };
  const indexToRemove = updatedAuthUser.feeds_followed.findIndex(item => item.feed_id === feed.id);
  if (indexToRemove !== -1) {
    updatedAuthUser.feeds_followed.splice(indexToRemove, 1);
  }
  store.dispatch({
    type: ActionTypes.SET_AUTH_USER,
    authUser: updatedAuthUser,
  })
}

const isFeedMember = (feed) => {
  const state = store.getState();
  const { loggedIn, authUser } = state.sessionState;
  if (!loggedIn) {
    return false;
  }
  if (feed.feed_followers.length === 0) {
    return false;
  }

  return feed.feed_followers.find(follower => follower.user_id === authUser.uid) !== undefined;
}

const isCommentEnabledFeed = (feed) => {
  return (
      (feed.op_comments || isFeedMember(feed)) &&
      feed.comment_conf !== FEED_COMMENT_DISABLE && feed.comment_conf !== FEED_COMMENT_CLOSEALL && 
      (feed.comment_conf !== FEED_COMMENT_TWITTERUSER || 
          (feed.comment_conf === FEED_COMMENT_TWITTERUSER && hasTwitter())
      )
  );
};

const feedsBySearchkey = async (searchkey) => {
  const state = store.getState();
  const { loggedIn } = state.sessionState;
  if (!loggedIn) {
    return;
  }
  
  const gqlservice = new GraphqlService();
  const token = await getAuthToken();
  if (!token) {
    setError(ERR_MSG_AUTH_TOKEN);
    return;
  }
  gqlservice.set_auth_jwt(token);

  const result = await gqlservice.feeds_by_searchkey(searchkey);
  if (result.status_code === GRAPHQL_ERROR) {
    setError(result.msg);
    return;
  }
  return result.data.feeds;
};

const addDefaultFeedstoFollowed = async () => {
  const state = store.getState();
  const { authUser , loggedIn } = state.sessionState;
  const { default_feeds } = state.dataState;
  let feedData = null;
  if(default_feeds.length === 0) {
    const gqlservice = new GraphqlService();
    if (loggedIn) {
      const token = await getAuthToken();
      if (!token) {
        setError(ERR_MSG_AUTH_TOKEN);
        return;
      }
      gqlservice.set_auth_jwt(token);
    }
    let result = await gqlservice.get_default_feeds();
    if (result.status_code === GRAPHQL_ERROR) {
      setError(result.msg);
      return;
    }
    feedData = result.data;
    store.dispatch({type: ActionTypes.SET_DEFAULT_FEEDS, feeds: feedData.feeds})
  }
  let defaultFeeds = default_feeds && default_feeds.length > 0 ? default_feeds : feedData.feeds.slice();
    if(authUser.feeds_followed.length === 0) {
      const followPromises = defaultFeeds.map(async (feed) => {
       await followFeed(feed);
      });
    }
}

export {
  existFeedbyID,
  existFeedbySlug,
  getFeedInfo,
  setFeedData,
  updateFeedCache,
  getFeedModerates,
  getFeeds2show,
  approveFeed,
  deleteFeed,
  updateFeedNotifications,
  followFeed,
  unfollowFeed,
  isCommentEnabledFeed,
  feedsBySearchkey,
  addDefaultFeedstoFollowed,
};
