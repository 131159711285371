import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  // Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarIcon from "@material-ui/icons/Star";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LinkPreview } from "components";
import { render_text } from "utility/utils";
import {
  BLANK_USER_IMAGE,
  MAX_CARD_WIDTH,
  MIN_CARD_WIDTH,
  THEME_MODE_DARK,
} from "constants/types";
import { logger } from "utility/logging";
import Link from "@material-ui/core/Link";
import {
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
const styles = (theme) => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_CARD_WIDTH - 16,
    maxWidth: MAX_CARD_WIDTH - 16,
  },
  carddiv: {
    margin: 3,
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 10,
    borderTopLeftRadius: 30,
    borderColor: theme.palette.feedstroke.mastodon,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.mastodon,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 0 rgba(63,63,68,0.15)`,
    marginRight: 15,
  },
  header: {
    padding: 0,
  },
  share: {
    position: "absolute",
    top: 12,
    right: 20,
    padding: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    backgroundColor: "#7289DA",
    zIndex: 100,
  },
  shareicon: {
    width: 16,
    height: 16,
  },
  pin: {
    position: "absolute",
    top: 12,
    right: 30,
    padding: 0,
    width: 16,
    height: 16,
    borderRadius: 10,
    color: theme.palette.info.contrastText,
    zIndex: 100,
  },
  expand: {
    position: "absolute",
    top: 28,
    right: 20,
    padding: 4,
    width: 24,
    height: 24,
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  avatar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 48,
    height: 48,
    cursor: "pointer",
    // width: 60, // Adjust the width and height as needed
    // height: 60,
    borderRadius: "50%",
  },
  ai_day: {
    height: 23,
    top: 55,
    left: 14,
    position: "absolute",
  },
  avatar_img: {
    backgroundColor: "transparent",
  },
  socialimg: {
    position: "absolute",
    top: 52,
    left: 16,
    width: 20,
    height: 20,
  },
  upvotebtn: {
    position: "absolute",
    top: 80,
    left: 12,
    width: 20,
    height: 20,
    zIndex: 100,
  },
  buttonimg: {
    width: 20,
    height: 20,
    marginTop: -20,
  },
  usertype: {
    position: "absolute",
    top: 76,
    left: 8,
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 600,
    color: "#1878F3",
  },
  titleline: {
    position: "relative",
    marginLeft: 40,
    marginRight: 40,
    margin: 10,
  },
  author: {
    fontFamily: "Arial",
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: 600,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  title: {
    marginTop: 4,
  },
  feed: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontStyle: "Italic",
    lineHeight: "18px",
    marginTop: 4,
    marginBottom: 4,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  date: {
    float: "right",
    fontSize: "12px",
    color: theme.palette.text.secondary,
    marginTop: 4,
  },
  content: {
    paddingTop: 4,
    paddingBottom: 4,
    marginLeft: 40,
    marginRight: 10,
    minHeight: 60,
    marginBottom: 8,
    cursor: "pointer",
    "&:last-child": {
      paddingBottom: 0,
    },
    marginTop: 10,
  },
  detail_txt: {
    display: "inline",
    overflowWrap: "break-word",
    marginLeft: 5,
  },
  media: {
    display: "block",
    width: "30%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(1),
  },
  actionbtn: {
    left: "50%",
    transform: "translate(-50%)",
    fontSize: 12,
    textTransform: "none",
    padding: 0,
    marginBottom: 4,
    color: theme.palette.text.primary,
  },
  actiondiv: {
    marginLeft: 16,
  },
  actionimg: {
    float: "left",
    width: 16,
    height: 16,
  },
  actionvalue: {
    float: "left",
    marginLeft: 5,
    fontFamily: "Arial",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },

  translated_div: {
    marginLeft: 10,
  },
  traslated_icon: {
    float: "left",
    top: 3,
    marginLeft: 5,
    width: 16,
    height: 16,
    color: theme.palette.text.primary,
  },
  translated_txt: {
    display: "inline",
    marginLeft: 2,
    fontSize: 12,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  actions: {
    padding: 0,
    margin: 0,
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1)
  },
  repostbtn: {
    width: 20,
    height: 20,
    zIndex: 100,
    marginLeft: 260,
  },
  commentbtn: {
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20,
    zIndex: 100,
  },
  comment: {
    marginLeft: theme.spacing(0.5),
    fontSize: "14px",
    fontStyle: "Roboto",
  },
  carddivSummary: {
    border: "1px solid #10a37f",
    backgroundColor: "#eef8f6",
    marginBottom: 12,
    borderRadius: 12,
    marginRight: 8,
    borderTopLeftRadius: 48,
  },
  carddivSummaryDark: {
    border: "1px solid #10a37f",
    backgroundColor: "#021813",
    marginBottom: 12,
    borderRadius: 12,
    marginRight: 8,
    borderTopLeftRadius: 48,
  },
  biasBanner: {
    width: "100%",
    borderRadius: 25,
    borderColor: theme.palette.feedstroke.mastodon,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: theme.palette.feedbackground.mastodon,
    padding: "8px",
    display: "flex",
    textAlign: "center",
    color: "red",
    fontSize: "15px",
    letterSpacing: "0.05px",
    fontWeight: "bold",
  },
  username: {
    marginLeft: 60,
    marginTop: 10,
    fontWeight: "bold",
  },
  acc: {
    marginLeft: 60,
  },
});

class BlueskyPosts extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, theme_mode, posts } = this.props;

    let bannerColor = "blue";

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    width -= 16;
    if (width > MAX_CARD_WIDTH - 16) width = MAX_CARD_WIDTH - 16;
    if (width < MIN_CARD_WIDTH - 16) width = MIN_CARD_WIDTH - 16;

    return (
      <Card
        className={classes.card}
        style={{ width: width, backgroundColor: classes.card.backgroundColor }}
      >
        <div className={classes.carddiv}>
          <CardHeader
            className={classes.header}
            avatar={
              <div>
                <img
                  alt={"user"}
                  src={posts.author.avatar|| RAVEN_PLACEHOLDER_IMAGE}
                  className={classes.avatar}
                />
                {theme_mode === THEME_MODE_DARK && (
                  <img
                    alt={"ai_image"}
                    src="/static/images/icons/dark/bluesky.png"
                    className={classes.ai_day}
                  />
                )}
                {theme_mode !== THEME_MODE_DARK && (
                  <img
                    alt={"ai_image"}
                    src="/static/images/icons/light/bluesky.png"
                    className={classes.ai_day}
                  />
                )}
              </div>
            }
          />

          <div></div>
          <div>
            <Typography className={classes.username} variant="body2">
              {posts.author.displayName}
            </Typography>
            <Typography
              className={classes.acc}
              variant="body2"
              style={{ opacity: 0.7 }}
            >
              @{posts.author.handle}
            </Typography>
          </div>

          <CardContent className={classes.content}>
            <Typography className={classes.detail_txt} variant="body2">
              <Link
                href={`https://bsky.app/profile/${posts.author.handle}/post/${posts.uri.substring(posts.uri.lastIndexOf("/") + 1)}`}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                underline="none"
              >
                {posts.record.text}
              </Link>
            </Typography>
          </CardContent>

          <LazyLoadImage
            alt=""
            src={
              posts.embed &&
              ((posts.embed.media &&
                Array.isArray(posts.embed.media.images) &&
                posts.embed.media.images.length > 0 &&
                posts.embed.media.images[0].thumb) ||
                (Array.isArray(posts.embed.images) &&
                  posts.embed.images.length > 0 &&
                  posts.embed.images[0].thumb))
                ? posts.embed.media &&
                  Array.isArray(posts.embed.media.images) &&
                  posts.embed.media.images.length > 0 &&
                  posts.embed.media.images[0].thumb
                  ? posts.embed.media.images[0].thumb
                  : posts.embed.images[0].thumb
                : ""
            }
            className={classes.media}
           
          />

          <Grid item>
            <div style={{ marginRight: 16 }}></div>
          </Grid>
        </div>
      </Card>
    );
  }

}

BlueskyPosts.propTypes = {
  classes: PropTypes.object,
  article: PropTypes.object,
  handleClick: PropTypes.func,
  onClickSource: PropTypes.func,
  onClickFeed: PropTypes.func,
  onAnchorEl: PropTypes.func,
  onShare: PropTypes.func,
  onClickUpvote: PropTypes.func,
  onClickComment: PropTypes.func,
  onClickRepost: PropTypes.func,
  onNeedMore: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  sources: state.dataState.sources,
  theme_mode: state.uiState.theme_mode,
  selected_feed: state.dataState.selected_feed,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  scrollPos: state.uiState.scrollPos,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlueskyPosts));
