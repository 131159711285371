export const handleReportWithLimit = async ( authUser ) => {
    const currentDate = new Date();
    const reportDate = new Date(authUser.last_report_at);

    const lastReportDate = new Date(reportDate.toISOString().split('T')[0]);
    const currentDateOnly = new Date(currentDate.toISOString().split('T')[0]);
    const isSameDay =
    lastReportDate.getDate() === currentDateOnly.getDate() &&
    lastReportDate.getMonth() === currentDateOnly.getMonth() &&
    lastReportDate.getFullYear() === currentDateOnly.getFullYear();
    let updatedAuthUser = { ...authUser };

  if (!isSameDay) {
    //reset the limit and increment it
    updatedAuthUser.last_report_at = currentDate.toISOString();
    updatedAuthUser.total_report_per_day = 1;
  } else if (authUser.total_report_per_day >= 10) {
    // If limit exceeded (10 reports per day), do not proceed with the API call
    return false;
  } else {
    updatedAuthUser.last_report_at = currentDate.toISOString();
    updatedAuthUser.total_report_per_day += 1;
  }
  return updatedAuthUser;
  }
  