import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MIN_CARD_WIDTH, MAX_ARTICLE_WIDTH } from "constants/types";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minWidth: MIN_CARD_WIDTH - 32,
    maxWidth: MAX_ARTICLE_WIDTH - 32,
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  grid: {
    justifyContent: "left",
    flexWrap: "inherit",
  },
  image: {
    objectFit: "cover",
    height: 100,
    width: 100,
    borderRadius: 15,
  },
  title: {
    position: "relative",
    textAlign: "left",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    textTransform: "none",
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  description: {
    position: "relative",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 200,
    lineHeight: "20px",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

class CategoryDetail extends React.Component {
  render() {
    const { classes, title, description, image } = this.props;

    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    if (width > MAX_ARTICLE_WIDTH) {
      width = MAX_ARTICLE_WIDTH;
    } else if (width < MIN_CARD_WIDTH) {
      width = MIN_CARD_WIDTH;
    }
    width -= 32;
    const infoWidth = width - (100 + 16);

    return (
      <div className={classes.root} style={{ width: width }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <LazyLoadImage className={classes.image} src={image} alt={title} />
          </Grid>
          <Grid item style={{ width: infoWidth }}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CategoryDetail.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default withStyles(styles)(CategoryDetail);
