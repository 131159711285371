import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

class PopMenuUser extends React.Component {
  render() {
    const { 
      classes, 
      theme,
      blocked,
      muted,
      onBlock, 
      onEndBlock,
      onMute,
      onEndMute,
      onReport 
    } = this.props;

    return (
      <div>
        <MenuList className={classes.menu_list}>
          <MenuItem 
            className={classes.menu_item} 
            onClick={blocked ? onEndBlock : onBlock}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"block"}
                src={`/static/images/icons/${theme}/banned-sign.png`}
              />
            </span>
            {blocked ? "Unblock User" : "Block User"}
          </MenuItem>
          <MenuItem 
            className={classes.menu_item} 
            onClick={muted ? onEndMute : onMute}
          >
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"mute"}
                src={`/static/images/icons/${theme}/banned-sign.png`}
              />
            </span>
            {muted ? "Unmute User" : "Mute User"}
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onReport}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"report"}
                src={`/static/images/icons/${theme}/warning.png`}
              />
            </span>
            Report User
          </MenuItem>
        </MenuList>
      </div>
    );
  }
}

PopMenuUser.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.string,
  blocked: PropTypes.bool,
  muted: PropTypes.bool,
  onBlock: PropTypes.func,
  onEndBlock: PropTypes.func,
  onMute: PropTypes.func,
  onEndMute: PropTypes.func,
  onReport: PropTypes.func,
};

export default withStyles(styles)(PopMenuUser);
