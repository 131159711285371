import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import { isWindows } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MAX_ARTICLE_WIDTH } from "constants/types";
import { EMOJI_MARKS_READINGS, EMOJI_MARKS_MOST } from "constants/emoji";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: MAX_ARTICLE_WIDTH,
  },
  logo: {
    minWidth: 60,
  },
  logoimage: {
    width: 60,
    height: 60,
  },
  titlebox: {
    marginLeft: theme.spacing(2),
  },
  title: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    textTransform: "none",
  },
  avatar: {
    minWidth: 20,
  },
  index: {
    display: "inline",
    marginRight: 16,
    fontSize: "16px",
    fontWeight: 500,
  },
  image: {
    verticalAlign: "middle",
    height: 32,
    width: 32,
    borderRadius: "50%",
  },
  text: {
    paddingLeft: theme.spacing(2),
  },
  name: {
    marginRight: theme.spacing(1),
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  markimage: {
    verticalAlign: "middle",
    height: 16,
    width: 16,
  },
  biography: {
    fontSize: "12px",
    color: theme.palette.text.primary,
  },
  actiontxt: {
    fontSize: "16px",
    fontWeight: 500,
  },
});

class Volunteers extends React.Component {
  render() {
    const { classes, volunteers } = this.props;

    return (
      <div className={classes.root}>
        <List>
          {volunteers.map((item, index) => {
            let readingMark = EMOJI_MARKS_READINGS.find(
              (mark) => item.readings >= mark.count
            );
            let mostMark = EMOJI_MARKS_MOST.find(
              (most) => item.most >= 0 && item.most === most.value
            );
            return (
              <ListItem
                key={`volunteer-${index}`}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
              >
                <ListItemAvatar
                  className={classes.avatar}
                  style={{ minWidth: 64 }}
                >
                  <>
                    <Typography className={classes.index}>{`${
                      index + 1
                    } `}</Typography>
                    <LazyLoadImage
                      className={classes.image}
                      alt={item.name}
                      src={item.image}
                    />
                  </>
                </ListItemAvatar>
                <ListItemText className={classes.text}>
                  {isWindows ? (
                    <>
                      <Typography
                        className={classes.name}
                        style={{ display: "inline" }}
                      >
                        {item.name}
                      </Typography>
                      {readingMark && (
                        <img
                          className={classes.markimage}
                          alt={readingMark.description}
                          src={`/static/images/emojis/marks/${readingMark.image}`}
                        />
                      )}
                      {mostMark && (
                        <img
                          className={classes.markimage}
                          alt={mostMark.description}
                          src={`/static/images/emojis/marks/${mostMark.image}`}
                        />
                      )}
                    </>
                  ) : (
                    <Typography
                      className={classes.name}
                      style={{ display: "inline" }}
                    >
                      {`${item.name} ${readingMark.emoji} ${
                        mostMark ? mostMark.emoji : ""
                      }`}
                    </Typography>
                  )}
                  <Typography className={classes.biography}>
                    {item.biography}
                  </Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Typography className={classes.name}>
                    {item.readings}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(Volunteers);
