export const QUERY_MAP_READINGS_BY_LOCATION = `
query map_readings_by_location (
  $location_id: Int!
) {
  readings (
    where: { 
      location_id: {_eq: $location_id}
    }
  ) {
    id
    location_id
    room_id
    co2
    ach
    mask
    image
    gps_verified
    adjusted
    co2_in
    activity
    people_no
    room_volume
    hepa_cadr
    uvgi_cap
    comment
    recommends
    reading_at
    reading_by
    approved
    approved_at
    approved_by
    reader {
      uid
      name
      biography
      image
    }
    location_room {
      id
      name
    }
  }
}`;

export const MUTATION_INSERT_MAP_READING = `
mutation insert_map_reading(
  $location_id: Int!,
  $room_id: Int,
  $co2: Int,
  $ach: Int,
  $mask: Int!,
  $image: String,
  $gps_verified: Boolean,
  $adjusted: Boolean,
  $co2_in: Int,
  $activity: Int,
  $people_no: Int,
  $room_volume: Int,
  $hepa_cadr: Int,
  $uvgi_cap: Int,
  $comment: String,
  $reading_at: timestamptz,
  $reading_by: String!,
  $approved: Boolean,
  $approved_at: timestamptz,
  $approved_by: String,
  $selectedTime: String,
) {
  insert_readings(
    objects: {
      location_id: $location_id,
      room_id: $room_id,
      co2: $co2,
      ach: $ach,
      mask: $mask,
      image: $image,
      gps_verified: $gps_verified,
      adjusted: $adjusted,
      co2_in: $co2_in,
      activity: $activity,
      people_no: $people_no,
      room_volume: $room_volume,
      hepa_cadr: $hepa_cadr,
      uvgi_cap: $uvgi_cap,
      comment: $comment,
      reading_at: $reading_at,
      reading_by: $reading_by,
      approved: $approved,
      approved_at: $approved_at,
      approved_by: $approved_by
    }
  ) {
    affected_rows
    returning {
      id
      location_id
      room_id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      approved
      approved_at
      approved_by
      reader {
        uid
        name
        biography
        image
      }
      location_room {
        id
        name
      }
      location_mask_friendly_hours {
        id
        created_by
        day
        time
        location_id
        reading_id
      }
    }
  }
}`;

export const MUTATION_UPDATE_MAP_READING = `
mutation update_map_reading(
  $id: Int!,
  $location_id: Int!,
  $room_id: Int,
  $co2: Int,
  $ach: Int,
  $mask: Int!,
  $image: String,
  $gps_verified: Boolean,
  $adjusted: Boolean,
  $co2_in: Int,
  $activity: Int,
  $people_no: Int,
  $room_volume: Int,
  $hepa_cadr: Int,
  $uvgi_cap: Int,
  $comment: String,
  $reading_at: timestamptz,
  $reading_by: String!,
  $approved: Boolean,
  $approved_at: timestamptz,
  $approved_by: String
) {
  update_readings(
    where: {
      id: {_eq: $id}, 
    }, 
    _set: {
      location_id: $location_id,
      room_id: $room_id,
      co2: $co2,
      ach: $ach,
      mask: $mask,
      image: $image,
      gps_verified: $gps_verified,
      adjusted: $adjusted,
      co2_in: $co2_in,
      activity: $activity,
      people_no: $people_no,
      room_volume: $room_volume,
      hepa_cadr: $hepa_cadr,
      uvgi_cap: $uvgi_cap,
      comment: $comment,
      reading_at: $reading_at,
      reading_by: $reading_by,
      approved: $approved,
      approved_at: $approved_at,
      approved_by: $approved_by
    }
  ) {
    affected_rows
    returning {
      id
      location_id
      room_id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      approved
      approved_at
      approved_by
      reader {
        uid
        name
        biography 
        image
      }
      location_room {
        id
        name
      }
      location_mask_friendly_hours {
        id
        created_by
        day
        time
        location_id
        reading_id
      }
    }
  }
}`;

export const MUTATION_APPROVE_MAP_READING = `
mutation update_map_reading(
  $id: Int!,
  $approved: Boolean!,
  $approved_at: timestamptz!,
  $approved_by: String!
) {
  update_readings(
    where: {
      id: {_eq: $id}, 
    }, 
    _set: {
      approved: $approved,
      approved_at: $approved_at,
      approved_by: $approved_by
    }
  ) {
    affected_rows
    returning {
      id
      location_id
      room_id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      approved
      approved_at
      approved_by
      reader {
        uid
        name
        biography 
        image
      }
      location_room {
        id
        name
      }
      location_mask_friendly_hours {
        id
        created_by
        day
        time
        location_id
        reading_id
      }
    }
  }
}`;

export const MUTATION_DELETE_MAP_READING = `
mutation delete_map_reading(
  $id: Int!
) {
  delete_readings(
    where: { id: {_eq: $id} }
  ) {
    affected_rows
  }
}`;


export const QUERY_READING_RECOMMENDER = `
query reading_recommmenders(
  $reading_id: Int!,
  $user_id: String!
) {
  reading_recommenders(
    where: {
      reading_id: {_eq: $reading_id},
      user_id: {_eq: $user_id} 
    }
  ) {
    id
  }
}`;

export const MUTATION_READING_INC_RECOMMENDS = `
mutation reading_inc_recommends(
  $reading_id: Int!,
  $user_id: String!,
) {
  update_readings(
    where: {
      id: {_eq: $reading_id}
    },
    _inc: {recommends: 1}
  ) {
    affected_rows
    returning {
      id
      location_id
      room_id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      approved
      approved_at
      approved_by
      reader {
        uid
        name
        biography 
        image
      }
      location_room {
        id
        name
      }
      location_mask_friendly_hours {
        id
        created_by
        day
        time
        location_id
        reading_id
      }
    }
  }

  insert_reading_recommenders(
    objects: {
      reading_id: $reading_id, 
      user_id: $user_id
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_READING_DEC_RECOMMENDS = `
mutation reading_dec_recommends(
  $reading_id: Int!,
  $user_id: String!,
) {
  delete_reading_recommenders(
    where: {
      reading_id: {_eq: $reading_id}, 
      user_id: {_eq: $user_id}
    }
  ) {
    affected_rows
  }

  update_readings(
    where: {
      id: {_eq: $reading_id}
    },
    _inc: {recommends: -1}
  ) {
    affected_rows
    returning {
      id
      location_id
      room_id
      co2
      ach
      mask
      image
      gps_verified
      adjusted
      co2_in
      activity
      people_no
      room_volume
      hepa_cadr
      uvgi_cap
      comment
      recommends
      reading_at
      reading_by
      approved
      approved_at
      approved_by
      reader {
        uid
        name
        biography 
        image
      }
      location_room {
        id
        name
      }
      location_mask_friendly_hours {
        id
        created_by
        day
        time
        location_id
        reading_id
      }
    }
  }
}`;