import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  RAVEN_PLACEHOLDER_IMAGE,
} from "constants/types";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { connect } from "react-redux";
const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  listitem: {
    display: "-webkit-box",
    padding: 0,
    margin: 0,
    cursor: "pointer",
  },
  badge_div: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
  },
  badge: {
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    backgroundColor: "red",
    color: "white",
  },
  listitem_avatar: {
    minWidth: 60,
    marginTop: 4,
  },
  listimage: {
    objectFit: "cover",
    height: 60,
    width: 60,
    borderRadius: 30,
    cursor: "pointer",
  },
  listitem_text: {
    maxWidth: `calc(100% - 160px)`,
    paddingLeft: theme.spacing(2),
    marginTop: 4,
    color: theme.palette.text.primary,
    cursor: "pointer",
    width: "auto",
    alignItems: "flex-start",
  },
  name: {
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: "12px",
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
  action: {
    width: "auto",
    margin: 0,
    marginTop: 0,
    padding: 0,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  following: {
    width: 48,
    height: 48,
  },
});

class BlueskySourceItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newfollowing: this.props.source.newfollowing,
    };
    this.listenToScroll = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    this.checkFollowing();
    window.addEventListener("scroll", this.listenToScroll);
    window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.followData !== this.props.followData ||
      prevProps.source !== this.props.source
    ) {
      this.checkFollowing();
    }
  }

  listenToScroll(event) {
    event.preventDefault();
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = (winScroll * 1.0) / height;

    if (scrolled === 1.0 || scrolled >= 0.99) {
      if (typeof this.props.onNeedMore === "function") {
        this.props.onNeedMore();
      }
    }
  }

  handleFollowToggle = () => {
    this.setState((prevState) => ({
      newfollowing: !prevState.newfollowing,
    }));
    this.props.unFollowed(this.props.source.did);
  };


  checkFollowing() {
    const { followData, source } = this.props;
    
    if (followData && source) {
      const isFollowing = followData.some((item) => item.did === source.did);
      this.setState({ newfollowing: isFollowing });
    }
  }

  render() {
    const {
      classes,
      source,
      theme_mode,
      loggedIn,
      blueskyfollowing,
      isUsernamePresent,
    } = this.props;
    const { newfollowing } = this.state;
    const following_img = `/static/images/icons/${theme_mode}/following.png`;
    const unfollowing_img = `/static/images/icons/${theme_mode}/unfollowing.png`;
    let rootWidth =
    document.documentElement.clientWidth ||
    document.body.clientWidth ||
    window.innerWidth;
  if (rootWidth > 640) {
    rootWidth = 640 - 42;
  } else {
    rootWidth -= 32;
  }
  let textwidth = rootWidth - (16 + 60 + 32);

    return (
      <div className={classes.root}>
        <Grid container direction="row" justifyContent="center">
          <Grid item className={classes.listitem_avatar}>
            <Link
              href={`https://bsky.app/profile/${source.handle}`}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              underline="none"
            >
              <LazyLoadImage
                className={classes.listimage}
                alt={source.username}
                src={source.avatar || RAVEN_PLACEHOLDER_IMAGE}
              />
            </Link>
          </Grid>
          <Grid item className={classes.listitem_text}
          style={{width: textwidth}}>

            <div>
              <Typography className={classes.name}>
                <Link
                  href={`https://bsky.app/profile/${source.handle}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  underline="none"
                >
                  {source.displayName}
                </Link>
              </Typography>
              <Typography className={classes.description}>
                <Link
                  href={`https://bsky.app/profile/${source.handle}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  underline="none"
                >
                  @{source.handle}
                </Link>
              </Typography>
            </div>
          </Grid>
          <Grid item xs style={{ flexGrow: 1 }}></Grid>
          <Grid item className={classes.action}>
            {isUsernamePresent && (
              <img
                className={classes.following}
                alt={newfollowing ? "following" : "unfollowing"}
                src={newfollowing ? following_img : unfollowing_img}
                onClick={this.handleFollowToggle}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

BlueskySourceItem.propTypes = {
  classes: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  following_img: PropTypes.string,
  unfollowing_img: PropTypes.string,
  unFollowed: PropTypes.func.isRequired,
  onNeedMore: PropTypes.func,
  followData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  scrollPos: state.uiState.scrollPos,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(BlueskySourceItem);