import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Menu } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { withFirebase } from 'services';
import { 
  withAuthentication, 
  withAuthorization 
} from "session";
import { 
  BasicAppBar, 
  CommentThreadCard, 
  PopMenuThreadMod,
  Report,
  DlgConfirm,
  DlgShareComment,
  WaitingSpinner
} from "components";
import { GraphqlService } from "services";
import * as ROUTES from "constants/routes";
import { 
  BANNED_TYPE_1D, 
  BANNED_TYPE_7D, 
  BANNED_TYPE_PERM, 
  THREAD_TYPE_FEED, 
} from "constants/types";
import { ACTIVITY_TYPE_FEED, ACTIVITY_DELETE } from "constants/activity";
import { MAX_ARTICLE_WIDTH } from "constants/types";
import { 
  banComments,
  closeThread, 
  deleteAllComments, 
  deleteThread, 
  getAuthToken,
  preapproveUser,
  updateFeedNotifications 
} from "dataapis";
import { ToastInfo, ToastError } from "utility/toast";
import { logger } from "utility/logging";


const condition = (authUser) => !!authUser && authUser.uid !== "";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    marginTop: theme.spacing(1)
  },
  threadcard: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  report: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
});

class ThreadsFlagged extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flagged: [],
      selectedthread: null,
      anchorEl: null,
      deleteDlg: false,
      shareDlg: false
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleAnchorEl = this.handleAnchorEl.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);

    this.handleDeleteReport = this.handleDeleteReport.bind(this);
    this.handleDismissReport = this.handleDismissReport.bind(this);
    this.handleClickReporter = this.handleClickReporter.bind(this);

    this.handleDeleteBan1d = this.handleDeleteBan1d.bind(this);
    this.handleDeleteBan7d = this.handleDeleteBan7d.bind(this);
    this.handleDeleteBanPerm = this.handleDeleteBanPerm.bind(this);
    this.handleCloseComments = this.handleCloseComments.bind(this);
    this.handleDeleteComments = this.handleDeleteComments.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleApproveUser = this.handleApproveUser.bind(this);

    this.handleCloseShare = this.handleCloseShare.bind(this);
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  setError = (message) => {
    ToastError(message);
    this.props.reqeustDataFinished();
  };

  setInfo = (message) => {
    ToastInfo(message);
  }

  componentDidMount() {
    const { selected_feed } = this.props;

    let flagged_threads = [];
    for (let report of selected_feed.thread_reports) {
      let flagged_thread = flagged_threads.find((thread) => thread.id === report.thread_id);
      if (!flagged_thread) {
        flagged_thread = report.thread;
        flagged_thread.reports = [report];
        flagged_threads.push(flagged_thread);
      } else {
        flagged_thread.reports.push(report);
      }
    }

    this.setState({
      ...this.state,
      flagged: flagged_threads,
    });
  }

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
  };

  handleNavBack = () => {
    this.props.history.goBack();
  }

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  }

  getThread = (report_id) => {
    const { flagged } = this.state;

    for (let thread of flagged) {
      for (let thread_report of thread.reports) {
        if (report_id === thread_report.id) {
          return {...thread};
        }
      }
    }
    return null;
  }

  deleteReport = (report_id) => {
    const { flagged } = this.state;

    const flagged_threads = [];
    for (let thread of flagged) {
      let reports = thread.reports.filter(report => report.id !== report_id);
      if (reports.length > 0) {
        thread.reports = reports;
        flagged_threads.push(thread);
      }
    }

    this.setState({
      ...this.state,
      flagged: flagged_threads
    });
  }

  deleteReports = (thread_id) => {
    const { flagged } = this.state;

    const flagged_threads = flagged.filter(thread => thread.id !== thread_id);
    this.setState({
      ...this.state,
      flagged: flagged_threads
    });
  }

  handleDeleteReport = async (report) => {
    const thread = this.getThread(report.id);
    if (!thread) {
      return;
    }

    this.setWaiting(true);
    const { selected_feed } = this.props;
    await deleteThread(thread);
    await updateFeedNotifications(selected_feed);
    this.setWaiting(false);

    this.deleteReports(thread.id);
  }

  handleDismissReport = async (report) => {
    const { authUser, selected_feed } = this.props;

    const thread = this.getThread(report.id);
    if (!thread) {
      return;
    }

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);

    await gqlservice
      .delete_thread_report(report.id)
      .then(result => {
        this.deleteReport(report.id);
      }, reason => {
        this.setError(reason.msg);
        return;
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        return;
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_DELETE,
      object: `the report of thread ${thread.title}`,
      fromto: `from the feed ${selected_feed.name}`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      });  
      
    await updateFeedNotifications(selected_feed);

    this.setWaiting(false);
  }

  handleClickReporter = (report) => {
    const user_id = report.reported_user.uid;
    const path = `/${ROUTES.USER_PREFIX}/${user_id}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  handleSelectThread = thread => {
    this.props.selectThread(thread);

    const { selected_feed } = this.props;
    let path = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.id}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  };

  handleDeleteThread = async (thread) => {
    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    this.setWaiting(true);
    const { selected_feed } = this.props;
    await deleteThread(thread);
    await updateFeedNotifications(selected_feed);
    this.setWaiting(false);

    this.deleteThread(thread.id);
  }

  handleAnchorEl = (anchorEl, thread) => {
    this.setState({
      ...this.state,
      selectedthread: thread,
      anchorEl: anchorEl
    });
  };

  deleteThread = (thread_id) => {
    const { flagged } = this.state;
    const new_flagged = flagged.filter(thread => thread.id !== thread_id);

    this.setState({
      ...this.state,
      flagged: new_flagged,
      anchorEl: null
    });
  }

  handleDeleteBan1d = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    await this.handleDeleteThread(selectedthread);

    this.setWaiting(true);
    await banComments(selectedthread.poster.uid, BANNED_TYPE_1D);
    this.setWaiting(false);

    this.setInfo(`User ${selectedthread.poster.username} banned for a day!`);
  }

  handleDeleteBan7d = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }
    await this.handleDeleteThread(selectedthread);

    this.setWaiting(true);
    await banComments(selectedthread.poster.uid, BANNED_TYPE_7D);
    this.setWaiting(false);

    this.setInfo(`User ${selectedthread.poster.username} banned for 7 days!`);
  }

  handleDeleteBanPerm = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }
    await this.handleDeleteThread(selectedthread);

    this.setWaiting(true);
    await banComments(selectedthread.poster.uid, BANNED_TYPE_PERM);
    this.setWaiting(false);

    this.setInfo(`User ${selectedthread.poster.username} banned permanently!`);
  }

  handleCloseComments = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    this.setWaiting(true);
    await closeThread(selectedthread);
    this.setWaiting(false);
  }

  handleDeleteComments = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    await this.handleCloseComments();

    this.setWaiting(true);
    await deleteAllComments(selectedthread);
    this.setWaiting(false);
  }

  handleShare = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    this.setState({
      ...this.state,
      shareDlg: true,
      anchorEl: null
    });
  }

  handleApproveUser = async () => {
    const { selectedthread } = this.state;
    if (!selectedthread) {
      this.handleCloseMenu();
      return;
    }

    this.setState({
      ...this.state,
      anchorEl: null
    });

    this.setWaiting(true);
    const { selected_feed } = this.props;
    await preapproveUser(selectedthread.poster.uid, selected_feed);
    this.setWaiting(false);

    this.setInfo(`User ${selectedthread.poster.username} preapproved!`);
  }

  handleCloseShare = () => {
    this.setState({
      ...this.state,
      shareDlg: false
    });
  }

  _getShareInfo = () => {
    const { selectedthread } = this.state;
    const { selected_feed } = this.props;
    if (!selectedthread) {
      logger.error("Can't share this thread because selected thread is none!");
      return null;
    }
    if (!selected_feed) {
      logger.error("Can't share this thread because selected feed is none!");
      return null;
    }

    // share info
    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.THREAD_PREFIX}/${selectedthread.id}`;

    let shareInfo = {
      title: "Raven Comment: " + selectedthread.title,
      description: selectedthread.text,
      image: '',
      hashtag: '',
      url: shareUrl
    };

    return shareInfo;
  }


  render() {
    const { classes, theme_mode, requesting } = this.props;
    const { 
      flagged, 
      selectedthread, 
      anchorEl, 
      deleteDlg, 
      shareDlg
    } = this.state;

    let menuPos = { top: -1000, left: -1000 };
    if (anchorEl) {
      var rect = anchorEl.getBoundingClientRect();
      menuPos = { top: rect.top, left: rect.left };
    }

    // share info
    const shareInfo = this._getShareInfo();

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            title={"Flagged by Users"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <Grid container spacing={1} className={classes.container}>
          {flagged.length > 0 && flagged.map((thread, index) => (
            <div key={index} className={classes.threadcard}>
              <Grid item key={`thread-${index}`}>
                <CommentThreadCard
                  thread={thread}
                  onAnchorEl={this.handleAnchorEl}
                />
              </Grid>
              <div>
                {thread.reports.map((report, rindex) => (
                  <Grid
                    item
                    key={`thread-${index}-report-${rindex}`}
                    className={classes.report}
                  >
                    <Report
                      report={report}
                      theme_mode={theme_mode}
                      onDelete={this.handleDeleteReport}
                      onDismiss={this.handleDismissReport}
                      onClickReporter={this.handleClickReporter}
                    />
                  </Grid>
                ))}
              </div>
            </div>
          ))}
        </Grid>
        <Menu
          id="thread-menu"
          // anchorEl={anchorEl}
          open={anchorEl !== null}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPos.top, left: menuPos.left + 24 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.handleCloseMenu}
        >
          <PopMenuThreadMod
            theme={theme_mode}
            type={selectedthread === null ? THREAD_TYPE_FEED : selectedthread.type}
            onDelete={this.handleDeleteThread}
            onBan1d={this.handleDeleteBan1d}
            onBan7d={this.handleDeleteBan7d}
            onBanPerm={this.handleDeleteBanPerm}
            onCloseComments={this.handleCloseComments}
            onDeleteComments={this.handleDeleteComments}
            onShare={this.handleShare}
            onApproveUser={this.handleApproveUser}
          />
        </Menu>
        <DlgConfirm
          open={deleteDlg}
          title={"Delete Thread"}
          content={"Are you sure you want to delete this thread?"}
          onOK={this.handleDelete}
          onCancel={e => this.showDeleteDlg(false)}
        />
        {shareInfo &&
          <DlgShareComment
            open={shareDlg}
            comment={selectedthread}
            shareInfo={shareInfo}
            onClose={this.handleCloseShare}
          />
        }
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

ThreadsFlagged.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  selected_feed: state.dataState.selected_feed,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ThreadsFlagged);
