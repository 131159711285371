import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { MAX_ARTICLE_WIDTH, PROFILE_TAB_FEEDS, PROFILE_TAB_POSTS, PROFILE_TAB_SETTINGS } from "constants/types";

const styles = (theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        padding: 0,
        display: "flex",
        justifyContent: "center"
    },
    tabs: {
        margin: 0,
        // paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        minHeight: 15,
    },
    indicator: {
        backgroundColor: "#000000",
        width: '80px',
        left: '80px'
    },
    tab: {
        padding: 0,
        minWidth: 80,
        fontWeight: 600,
        fontSize: 14,
        textTransform: "capitalize",
        color: theme.palette.text.primary,

    }
});

class ProfileTabs extends React.Component {
    constructor(props) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(event, newValue) {
        const { profiletab } = this.props;
        if (newValue === null) return;
        if (profiletab === newValue) return;
        this.props.onChangeTab(newValue);
    }

    render() {
        const { classes, profiletab, user } = this.props;

        const postsTab = { value: PROFILE_TAB_POSTS, label: "Posts" };
        const feedsTab = { value: PROFILE_TAB_FEEDS, label: "Feeds" };
        const settingsTab = { value: PROFILE_TAB_SETTINGS, label: "Settings" };

        const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
        const tabsWidth = width > MAX_ARTICLE_WIDTH ? MAX_ARTICLE_WIDTH - 32 : width - 32;



        return (
            <div className={classes.root}>
                <Tabs
                    className={classes.tabs}
                    classes={{ indicator: classes.indicator }}
                    value={profiletab}
                    onChange={this.handleTabChange}
                    variant={width > MAX_ARTICLE_WIDTH ? "fullWidth" : "scrollable"}
                    style={{ width: tabsWidth }}
                >
                    <Tab
                        value={postsTab.value}
                        key={postsTab.value}
                        label={postsTab.label}
                        className={classes.tab}
                    />
                    <Tab
                        value={feedsTab.value}
                        key={feedsTab.value}
                        label={feedsTab.label}
                        className={classes.tab}
                    />
                    {user !== true && (<Tab
                        value={settingsTab.value}
                        key={settingsTab.value}
                        label={settingsTab.label}
                        className={classes.tab}
                    />)}
                </Tabs>
            </div>
        );
    }
}

ProfileTabs.propTypes = {
    classes: PropTypes.object,
    onChangeTab: PropTypes.func,
};

const mapStateToProps = (state) => ({
    profiletab: state.uiState.profiletab,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(ProfileTabs);
