import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  menu_list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu_item: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    minHeight: 24,
    width: 180,
    color: theme.palette.text.primary,
  },
  menu_icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
  },
  menu_img: {
    padding: 4,
    height: 24,
    width: 24,
  },
});

class PopMenuSourceMod extends React.Component {
  render() {
    const { 
      classes, 
      theme, 
      follow, 
      showrt,
      translate,
      transcript,
      onEdit, 
      onDelete, 
      onCopyLink, 
      onShow, 
      onKeywordFilter,
      onTranslate,
      onShowRetweet,
      onEnableTranscription,
      onEnableBiasCheck,
      showBiasCheck,
    } = this.props;

    return (
      <div>
        <MenuList className={classes.menu_list}>
          <MenuItem className={classes.menu_item} onClick={onEdit}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"edit"}
                src={`/static/images/icons/${theme}/edit.png`}
              />
            </span>
            Edit Source
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onDelete}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"delete"}
                src={`/static/images/icons/${theme}/warning.png`}
              />
            </span>
            Delete Source
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onCopyLink}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"copylink"}
                src={`/static/images/icons/${theme}/link.png`}
              />
            </span>
            Copy Link to Source
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onShow}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={follow ? "hide" : "show"}
                src={follow ? `/static/images/icons/${theme}/invisible.png` : `/static/images/icons/${theme}/eye.png`}
              />
            </span>
            {follow ? "Hide Source" : "Show Source"}
          </MenuItem>
          <MenuItem className={classes.menu_item} onClick={onKeywordFilter}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"keywordfilter"}
                src={`/static/images/icons/${theme}/filter.png`}
              />
            </span>
            Keyword Filter
          </MenuItem>
          {translate !== undefined &&
            <MenuItem className={classes.menu_item} onClick={onTranslate}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"translate"}
                  src={`/static/images/icons/${theme}/edit.png`}
                />
              </span>
              {translate ? "Disable Translate" : "Enable Translate"}
            </MenuItem>
          }
          {showrt !== undefined &&
            <MenuItem className={classes.menu_item} onClick={onShowRetweet}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"showRT"}
                  src={`/static/images/icons/${theme}/twitter.png`}
                />
              </span>
              {showrt ? "Hide Replies/RTs" : "Show Replies/RTs"}
            </MenuItem>
          }
          {transcript !== undefined &&
            <MenuItem className={classes.menu_item} onClick={onEnableTranscription}>
              <span className={classes.menu_icon}>
                <img
                  className={classes.menu_img}
                  alt={"transcript"}
                  src={`/static/images/icons/${theme}/edit.png`}
                />
              </span>
              {transcript ? "Disable Transcription":  "Enable Transcription"}
            </MenuItem>
          }
          <MenuItem className={classes.menu_item} onClick={onEnableBiasCheck}>
            <span className={classes.menu_icon}>
              <img
                className={classes.menu_img}
                alt={"biascheck"}
                src={`/static/images/icons/${theme}/edit.png`}
              />
            </span>
            {showBiasCheck ? "Disable Bias Check" : "Enable Bias Check"}
          </MenuItem>
        </MenuList>
      </div>
    );
  }
}

PopMenuSourceMod.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  follow: PropTypes.bool,
  showRT: PropTypes.bool,
  translate: PropTypes.bool,
  transcript: PropTypes.bool,
  showBiasCheck: PropTypes.bool,
  onCheckClicked: PropTypes.func,
  onItemClicked: PropTypes.func,
  onShow: PropTypes.func,
  onKeywordFilter: PropTypes.func,
  onTranslate: PropTypes.func,
  onShowRetweet: PropTypes.func,
  onEnableTranscription: PropTypes.func,
  onEnableBiasCheck: PropTypes.func,
};

export default withStyles(styles)(PopMenuSourceMod);
