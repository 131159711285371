import React, { Fragment } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Fade } from "@material-ui/core";
import MetaTags from "react-meta-tags";
import _ from "lodash";
import { ToastContainer } from "react-toastify";
import { withAuthentication } from "session";
import SwipeableViews from "react-swipeable-views";
import { v4 as uuidv4 } from 'uuid';
import {
  ArticleList,
  ArticleMasonry,
  BottomNavBar,
  InviteControl,
  WaitingSpinner,
  DlgSuggestSource,
  DlgPost,
  DlgPostEdit,
  DlgLoginConfirm,
  DlgThread,
  DlgWeblink,
} from "components";
import {
  MainAppBar,
  FeedsAppBar,
  FeedDetail,
  FeedTabs,
  SourceList,
  ThreadList,
} from "./components";
import { 
  ACTIVITY_TYPE_FEED, 
  ACTIVITY_ADD,
  ACTIVITY_TYPE_CATEGORY, 
  ACTIVITY_COPY,
} from "constants/activity";
import {
  GRAPHQL_SUCCESS,
  TAB_FEED,
  TAB_SOURCES,
  TAB_COMMENTS,
  FEED_COMMENT_UNMODERATED,
  MAX_WINDOW_WIDTH,
  MIN_TABLET_WIDTH,
  MAX_CARD_WIDTH,
  RAVEN_PLACEHOLDER_IMAGE,
  APPROVING_POSTS,
} from "constants/types";
import { 
  BRANCH_ALL, 
  ARTICLE_BRANCH_NEWSPAPER, 
  ARTICLE_BRANCH_USERPOST,
  ARTICLE_BRANCH_YOUTUBE_SUMMARY,
  ARTICLE_BRANCH_PODCAST_SUMMARY, 
} from "constants/branches";
import { ALL } from "constants/country";
import * as ROUTES from "constants/routes";
import {
  withFirebase,
  GraphqlService,
  createUserSubscriber,
  Mixpanel
} from "services";
import { 
  getAuthToken,
  getMainInfo,
  isFeedModerator,
  getFeedInfo,
  getPinsAndMovetops,
  repostArticle,
  deleteArticle,
  updatePost,
  saveArticle,
  addThread,
  hasPaid,
  isBanned,
  isBannedFeedCreation,
  isBannedPosts,
  isBannedComments,
  isCommentEnabledFeed,
  reportArticle,
  followFeed,
  getArticle,
  upvoteArticle,
} from "dataapis";
import { 
  get_hash, 
  summarize_text, 
  gen_random_int, 
  is_source_alive,
  copy2clipboard
} from "utility/utils";
import {
  resizeImageFile
} from "utility/resizeimage";
import {
  approveAiPosts,
  getFeedsInfo,
  get_link_source,
  moderate_image 
} from "utility/ravenapi";
import { get_crypto_tokens } from "utility/ens";
import {
  ToastSuccess,
  ToastInfo,
  ToastError
} from "utility/toast";
import { logger } from "utility/logging";
import * as MIXPANEL_EVENTS from "constants/mixpanel";
import { updateFeedCache } from "dataapis/feed";
import{postToMastodon,postToBluesky}from "utility/ravenapi"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 1100,
  },
  maincontainer: {
    position: "sticky",
    top: "56px",
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
  },
  invitecontainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  tabcontainer: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  addbutton: {
    position: "fixed",
    cursor: "pointer",
    bottom: theme.spacing(5),
    // right: theme.spacing(1),
    width: 60,
    height: 60,
    zIndex: 1200,
  },
  warningcontainer: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: "center",
  },
  warningimg: {
    width: 48,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  warningicon: {
    width: 48,
    height: 48,
  },
  warningtitle: {
    width: 220,
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  warningtext: {
    width: 220,
    fontSize: 14,
    fontWeight: 400,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.text.secondary,
  },
  indicatoricon: {
    width: 16,
    height: 16,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  bottomspace: {
    paddingBottom: theme.spacing(10),
  }
});

class Feed extends React.Component {
  constructor(props) {
    super(props);

    this.userConsumer = null;

    this.state = {
      topFeeds: [],
      searchKey: "",
      suggestSourceDlg: false,
      postDlg: false,
      webDlg:false,
      postEditDlg: false,
      article_edit: null,
      loginDlg: false,
      threadDlg: false,
      aiSummary:[],
      isPostsEmpty: true,
      loading:false
    };

    this.handleWheel = this.handleWheel.bind(this);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleClickBackButton = this.handleClickBackButton.bind(this);

    this.handleInviteMembers = this.handleInviteMembers.bind(this);
    this.handleClickMembers = this.handleClickMembers.bind(this);

    this.handleChangeFeedTab = this.handleChangeFeedTab.bind(this);
    this.handleSwiped = this.handleSwiped.bind(this);
    this.handleSwipScroll = this.handleSwipScroll.bind(this);

    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);
    this.handleClickSourceInArticle = this.handleClickSourceInArticle.bind(this);

    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);

    this.handleClickUnfollowed = this.handleClickUnfollowed.bind(this);
    this.handleClickShowRetweet = this.handleClickShowRetweet.bind(this);
    this.handleReachLimit = this.handleReachLimit.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleCategoryIndicator = this.handleCategoryIndicator.bind(this);

    this.handleNeedMoreArticles = this.handleNeedMoreArticles.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeBranch = this.handleChangeBranch.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.showAddPostDlg = this.showAddPostDlg.bind(this);
    this.showADlgWeb = this.showADlgWeb.bind(this);
    this.closePostDlg = this.closePostDlg.bind(this);
    this.handleSubmitPost = this.handleSubmitPost.bind(this);
    this.closePostEditDlg = this.closePostEditDlg.bind(this);
    this.handleUpdatePost = this.handleUpdatePost.bind(this);

    this.handleAddSource = this.handleAddSource.bind(this);
    this.handleCancelSuggestSource = this.handleCancelSuggestSource.bind(this);
    this.handleSuggestSource = this.handleSuggestSource.bind(this);
    this.handleSourceSearch = this.handleSourceSearch.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);

    this.handleNeedMoreThreads = this.handleNeedMoreThreads.bind(this);
    this.handleAddThread = this.handleAddThread.bind(this);
    this.handleCloseThread = this.handleCloseThread.bind(this);
    this.handleSubmitThread = this.handleSubmitThread.bind(this);
    this.handleClickThread = this.handleClickThread.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setAuthorizeError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();

    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "left" },
    };
    this.props.history.push(location);
  }

  setWaiting = (waiting) => {
    this.setState({
      ...this.state,
      loading: waiting
    });
  };

  handleWheel = (event) => {
    const height =
      document.documentElement.scrollHeight - window.innerHeight;
    if (height === 0) {
      this.props.showTopNavbar(true);
    }
  }

  componentDidMount = async () => {
      this.setWaiting(true);
    const { loggedIn, followed_feeds, feeds } = this.props;
    let feedData = null
    const { match } = this.props;
    logger.log("Feed match :", match);
    let tabValue = TAB_FEED;
    if (match.path === ROUTES.FEED_POSTS) {
      tabValue = TAB_FEED;
    } else if (match.path === ROUTES.FEED_SOURCES) {
      tabValue = TAB_SOURCES;
    } else if (match.path === ROUTES.FEED_COMMENTS) {
      tabValue = TAB_COMMENTS;
    }

    let accessFromOut = false;
    if (!this.props.selected_feed) {
      accessFromOut = true;
    }

    let feed_slug =
      this.props.selected_feed
        ? this.props.selected_feed.slug
        : match.params.slug;
    if (!feed_slug) {
      return;
    }
    const { authUser } = this.props;

    var params = {
      user: authUser.uid ? authUser.uid : null,
      feed: feed_slug
    }

    await getFeedsInfo(params)
      .then(result => {
        if (result.status === 200) {
          feedData = result.data;
          if (feedData.selectedFeed) {
            this.props.selectFeed(feedData.selectedFeed);
          }
          if ( feedData.articles.length === 0 &&
            feedData.feedPosts.length === 0 &&
            feedData.movetops.length === 0 &&
            feedData.pins.length === 0 &&
            feedData.searchTermSourceArticles.length === 0
            ) {
              this.setState({
                ...this.state,
                isPostsEmpty: false
              });
            }
        }
      })
      .catch(error => {
        console.error("Error fetching feed info:", error);
      });
    
    // await getFeedInfo(feed_slug);
    const { selected_feed } = this.props;
    if(!selected_feed){
      return
    }
    if (selected_feed.category_id === 'trending') {
      this.props.history.push(ROUTES.DISCOVER);
      return;
    } else if (selected_feed.category_id === 'deleted') {
      this.props.history.push(ROUTES.HOME);
      return;
    }
    const properties={id:selected_feed.id,name:selected_feed.name,slug:selected_feed.slug};
    Mixpanel.track(MIXPANEL_EVENTS.FEED_DETAILS, properties); 
    logger.log("Feed selected feed :", selected_feed);

    // go to the moderation page if the user is the moderator of this feed
    if (isFeedModerator(selected_feed)) {
      const route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;
      const location = {
        pathname: route,
        state: { animation: "left" },
      };
      this.props.history.push(location);
      this.setWaiting(false);
      return;
    }

    // non-moderators can't access non-approved feeds
    if (!selected_feed.approved) {
      this.setAuthorizeError("The users can't access non-approved feeds!");
      return;  
    }

    // users without crypto wallet can't access feed if the feed needs it
    if (selected_feed.tg_wallet) {
      if (!loggedIn) {
        this.setAuthorizeError("The anonymous users can't access this feeds");
        return; 
      }


      if (authUser.cryptoAddress === null || authUser.cryptoAddress === "") {
        this.setAuthorizeError("The users without cryptocurrency wallet can't access this feeds");
        return; 
      }

      if (selected_feed.tg_address && selected_feed.token_address !== null && selected_feed.token_address.length > 0) {
        const tokens = await get_crypto_tokens(authUser.cryptoAddress);
        if (!tokens) {
          this.setAuthorizeError(`You haven't token : ${selected_feed.token_address}`);
          return;
        }

        let feedToken = null;
        for (let token of tokens) {
          if (token.tokenInfo.address === selected_feed.token_address) {
            feedToken = token;
            break;
          }
        }

        if (!feedToken) {
          this.setAuthorizeError(`You haven't token : ${selected_feed.token_address}`);
          return;
        }

        if (selected_feed.tg_amount && selected_feed.tg_amount !== null && selected_feed.tg_amount > 0) {
          if (feedToken.balance < selected_feed.tg_amount) {
            this.setAuthorizeError(`You haven't enough numbers of token : ${selected_feed.token_address}`);
            return;
          }
        }
      }
    }

    // conditions for private feeds
    if (selected_feed.private) {
      // can't access from the outside or without logged in
      // the invited users can only access the private feeds (should be implemented!!!)
      if (accessFromOut || !loggedIn || !this._isFollowedFeed(selected_feed)) {
        const invited = await this.isInvited();

        if (!invited) {
          this.setAuthorizeError("Can't access private feeds from the outside");
          return;  
        } else {
          ToastInfo("You're invited to this private feed, you've followed this feed by visit.");
          await followFeed(selected_feed);
          this.setWaiting(false);
        }
      }
    }
    if (feeds.length === 0) {
      await getMainInfo();
    }

    const { categories } = this.props;
    const selectedCategory = categories.find(category => category.id === selected_feed.category_id);
    this.props.selectCategory(selectedCategory);

    // this.props.refreshArticles();
    this.props.refreshThreads();
    // this.props.selectBranch(BRANCH_ALL);
    this.props.showTopNavbar(true);
    this.props.showBottomNavbar(true);
    // load pins & movetops & articles
    // await getPinsAndMovetops([selected_feed.id]);


    if (this.props.feedtab === TAB_FEED) {
      // load articles
      if (feedData.pins.length > 0) {
        this.props.setArticlePins(feedData.pins)
      }
      if (feedData.movetops.length > 0) {
        this.props.setArticleMovetops(feedData.movetops)
      }
      const { articles_on_feed_moderation,articles_on_search_term_source } = this.props;
      // if (feedData.aiSummary.length > 0) {
      //   this.setState({
      //     ...this.state,
      //     aiSummary: feedData.aiSummary
      //   });
      // }
      await this.getArticlesSummary();
 
      if (Object.keys(articles_on_search_term_source).filter((item)=>item.id === selected_feed.id).length === 0) {
        // await this.getSearchTermSourceArticlesOfFirstPage();
        if (feedData.searchTermSourceArticles.length > 0) {
          this.props.setArticlesOnSearchTermSource(feedData.searchTermSourceArticles, feedData.searchTermSourceArticles.length ,selected_feed?.id)
        }
      }
      if (Object.keys(articles_on_feed_moderation).filter((item)=>item.id === selected_feed.id).length === 0) {
        if (feedData.articles.length>0) {
          this.props.setArticlesOnFeedModeration(feedData.articles, feedData.articles.length, selected_feed.id)
        }
        if (feedData.feedPosts.length > 0) {
          this.props.setFeedPosts(feedData.feedPosts);
        }
        // await this.getArticlesOfFirstPage();
        // await this.getFeedPosts();
        this.props.initScrollPos();
      } else {
        window.scrollTo(this.props.scrollPos.x, this.props.scrollPos.y);
      }
      this.props.showBottomNavbar(false);
    }
    else if (
      this.props.feedtab === TAB_COMMENTS && 
      isCommentEnabledFeed(selected_feed) && 
      this.props.threads.length === 0
    ) {
      await this.getThreadsInFirstPage();
    } else {
      // means from external url
      await this.handleChangeFeedTab(tabValue);
    }

    this.registerSubscribers();

    this.setWaiting(false);
  };


  componentDidUpdate = async(prevProps) => { 
    const { selected_feed, authUser, feed_backroute, match, feeds } = this.props;
    if (!selected_feed || !authUser) {
      return;
    }

    if (prevProps.authUser.uid !== authUser.uid) {
      await getMainInfo();
    }
    let feed_slug =selected_feed ? selected_feed.slug : match.params.slug;
    if (!feed_slug) {
      this.setWaiting(false);
      return;
    }
    
	let getFeed = feeds.find((item) => item.id === selected_feed.id);
     if(!getFeed){
      await getFeedInfo(feed_slug);
    }
   
    const feed_moderated = authUser.feeds_moderated.find(item => item.feed_id === selected_feed.id);
    const prev_feed_moderated = prevProps.authUser.feeds_moderated.find(item => item.feed_id === selected_feed.id);
    if (!feed_moderated || !prev_feed_moderated) {
      return;
    }

    if (prev_feed_moderated.approved !== feed_moderated.approved) {
      // go to the previous page if the feed moderation is changed
      if (isFeedModerator(selected_feed)) {
        const location = {
          pathname: feed_backroute,
          state: { animation: "left" },
        };
        this.goTo(location);
      }
    }
  }


  registerSubscribers = () => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      return;
    }

    const userSubscriber = createUserSubscriber(authUser.uid);
    this.userConsumer = userSubscriber.subscribe(data => {
      const user = data.data.users[0];
      logger.log("user updated :", user);
      this.props.setAuthUser(user);
    }, (err) => {
      let msg = "Error subscribing user: " + err.message;
      logger.error(msg);
    });
  };

  unregisterSubscribers = () => {
    if (this.userConsumer) {
      this.userConsumer.unsubscribe();
    }
  }

  goTo = (location) => {
    this.unregisterSubscribers();
    this.props.history.push(location);
  }

  _isFollowedFeed = (feed) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn || !feed) {
      return false;
    }

    // check if the user is the creator of this feed
    let result = authUser.feeds_followed.find(
      (item) => item.feed_id === feed.id
    );
    return result !== undefined;
  }

  isInvited = async () => {
    const { authUser, selected_feed } = this.props;

    const gqlservice = new GraphqlService();
    const invited = await gqlservice
      .invites_by_user(authUser.uid)
      .then(
        (result) => {
          const invites = result.data.user_invites;
          for (let invite of invites) {
            if (
              !invite.is_phone &&
              invite.invitee === authUser.uid &&
              invite.invited_to === selected_feed.id
            ) {
              return true;
            }
          }
          return false;
        },
        (reason) => {
          return false;
        }
      )
      .catch((err) => {
        return false;
      });

    return invited;
  };

  handleInviteMembers = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    const { selected_feed } = this.props;
    const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/inviteuser`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  }

  handleClickMembers = () => {
    if (!this.props.loggedIn) {
      this.handleLogin();
      return;
    }
    
    const { selected_feed } = this.props;
    const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/members`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  }

  handleChangeFeedTab = (tab_value) => {
    this.setState(
      {
        ...this.state,
        searchKey: "",
      },
      async () => {
        window.scroll(0, 0);
        this.props.selectFeedTab(tab_value);
        this.props.initScrollPos();
        this.props.showTopNavbar(true);

        const { selected_feed } = this.props

        if (tab_value === TAB_FEED) {
          await this.getArticlesSummary();
          const { articles_on_feed_moderation } = this.props

          if (Object.keys(articles_on_feed_moderation).filter((item)=>item.id === selected_feed.id).length === 0) {
            await this.getArticlesOfFirstPage();
          }
          this.props.showBottomNavbar(true);
        } else if (tab_value === TAB_SOURCES) {
          this.props.showBottomNavbar(false);
        } else if (tab_value === TAB_COMMENTS) {
          const { selected_feed } = this.props;
          if (isCommentEnabledFeed(selected_feed) && this.props.threads.length === 0) {
            await this.getThreadsInFirstPage();
          }
          this.props.showBottomNavbar(false);
        }
      }
    );
  };

  handleSwiped = (tabIndex) => {
    this.handleChangeFeedTab(tabIndex);
  }

  handleSwipScroll = (event) => {
    event.preventDefault();

    const { feedtab } = this.props;

    let element_id = "feed-swipeable-views-feeds";
    if (feedtab === TAB_SOURCES) {
      element_id = "feed-swipeable-views-sources";
    } else if (feedtab === TAB_COMMENTS) {
      element_id = "feed-swipeable-views-threads";
    }

    const domElement = document.getElementById(element_id).parentNode;
    const scrollTop = domElement.scrollTop;

    if (scrollTop > 50) {
      this.props.showTopNavbar(false);
    } else {
      this.props.showTopNavbar(true);
    }
  }

  handleClickFeed = (feed) => {
    if (feed === this.props.selected_feed) {
      return;
    }

    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setFeedBackRoute(ROUTES.HOME);
    this.props.refreshArticlesOnFeedModeration();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickSource = (source) => {
    const { selected_feed } = this.props;

    this.props.selectSource(source);
    this.props.setSourceBackRoute(`/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`);
    const path = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.refreshArticlesOnFeedModeration();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickSourceInArticle = (source, feed) => {
    this.props.selectSource(source);
    this.props.setSourceBackRoute(`/${ROUTES.FEEDS_PREFIX}/${feed.slug}`);
    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.goTo(location);

    this.props.refreshArticlesOnFeedModeration();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickUpvote = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  }

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  }

  handleClickRepost = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  }

  handleClickUnfollowed = async (source, unfollowed) => {
    const { loggedIn, authUser, selected_feed } = this.props;
    if (!loggedIn) {
      return;
    }

    if (isBanned()) {
      ToastError("You've suspended.");
      return;
    }

    let unfollower = {
      id: uuidv4(),
      feed_id: selected_feed.id,
      source_id: source.id,
      user_id: authUser.uid,
    };

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    if (unfollowed) {
      await gqlservice
        .insert_feed_source_unfollower(unfollower)
        .then(
          (result) => {
            this.props.insertUnfollowingSource(unfollower);
          },
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });

      this.setWaiting(false);
      return;
    }

    await gqlservice
      .delete_feed_source_unfollower(selected_feed.id, source.id, authUser.uid)
      .then(
        (result) => {
          this.props.deleteUnfollowingSource(selected_feed.id, source.id, authUser.uid);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  handleClickShowRetweet = async (source, showrt) => {
    const { loggedIn, authUser, selected_feed } = this.props;
    if (!loggedIn) {
      return;
    }

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    if (showrt) {
      await gqlservice
        .insert_feed_source_showretweet(selected_feed.id, source.id, authUser.uid)
        .then(
          (result) => {
            this.props.insertShowRetweet(selected_feed.id, source.id, authUser.uid);
          },
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });

      this.setWaiting(false);
      return;
    }

    await gqlservice
      .delete_feed_source_showretweet(selected_feed.id, source.id, authUser.uid)
      .then(
        (result) => {
          this.props.deleteShowRetweet(selected_feed.id, source.id, authUser.uid);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };


  handleReachLimit = () => {
    if (!hasPaid()) {
      const location = {
        pathname: ROUTES.MEMBERSHIP,
        state: { animation: "bottom" },
      };
      this.goTo(location);
    } else {
      this.setState({
        ...this.state,
        alertDlg: true,
        alertTitle: "Follow Limit",
        alertMsg:
          "You've already reached the limit to follow. Unfollow some feeds to follow another feeds.",
      });
    }
  };

  handleSearchChange = (searchKey) => {
    // this.setState({
    //   ...this.state,
    //   searchKey: searchKey
    // });
  };

  handleSearchEnter = (searchKey) => {
    this.props.updateSearchKey(searchKey);
    const location = {
      pathname: ROUTES.SEARCH_RESULT,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleNavBack = () => {
    const { feed_backroute } = this.props;
    const location = {
      pathname: feed_backroute,
      state: { animation: "right" },
    };
    this.goTo(location);

    this.props.refreshArticlesOnFeedModeration();
    this.props.clsArticlePins();
    this.props.clsArticleMovetops();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
    this.props.selectFeed(null);
    this.props.selectSource(null);
  };

  handleClickBackButton = () => {
    window.scrollTo(0, 0);
    this.props.initScrollPos();
  };

  getArticlesOfFirstPage = async () => {
    const { branch, country } = this.props;

    if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      if (country === ALL) {
        await this.getArticlesOfBranchInFirstPage(branch);
      } else {
        await this.getArticlesOfCountryInFirstPage(country);
      }
    } else {
      if (branch === BRANCH_ALL) {
        await this.getArticlesInFirstPage();
      } else {
        await this.getArticlesOfBranchInFirstPage(branch);
      }
    }
  };

  handleNeedMoreArticles = async () => {
    const { feedtab, country, branch, requesting, articles_on_search_term_source,selected_feed,articles_on_feed_moderation } = this.props;
    let last_offset_on_feed_moderation = articles_on_feed_moderation[selected_feed?.id]?.last_offset_on_feed_moderation ?articles_on_feed_moderation[selected_feed?.id]?.last_offset_on_feed_moderation :0
    let last_offset_on_search_term_source_articles = articles_on_search_term_source[selected_feed?.id]?.last_offset_on_search_term_source_articles ?articles_on_search_term_source[selected_feed?.id]?.last_offset_on_search_term_source_articles :0
    
    if (feedtab !== TAB_FEED) {
      return;
    }

    if (last_offset_on_feed_moderation === 0) {
      this.props.showBottomNavbar(false);
      return;
    }

    if (requesting) {
      return;
    }

    this.setWaiting(true);
    if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      if (country === ALL) {
        await this.getArticlesOfBranchInNextPage(branch, last_offset_on_feed_moderation);
      } else {
        await this.getArticlesOfCountryInNextPage(country, last_offset_on_feed_moderation);
      }
    } else {
      if (branch === BRANCH_ALL) {
        await this.getArticlesInNextPage(last_offset_on_feed_moderation);
        // await this.getSearchTermSourceArticlesInNextPage(last_offset_on_search_term_source_articles);
      } else {
        await this.getArticlesOfBranchInNextPage(branch, last_offset_on_feed_moderation);
      }
    }
    this.setWaiting(false);
  };

  handleSelectArticle = (article) => {
    if(article.article) {
      article = article.article
    }
    this.props.selectArticle(article);
    const { selected_feed } = this.props;

    if (article.source_id !== `${selected_feed.id}-userpost`) {
      if (
        article.branch === ARTICLE_BRANCH_YOUTUBE_SUMMARY ||
        article.branch === ARTICLE_BRANCH_PODCAST_SUMMARY
      ) {
        // console.log("article youtube branch", article.branch, article.nid);
        var pathName = `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
        const location = {
          pathname: pathName,
          state: { animation: "left" },
        };
        this.goTo(location);
        // this.props.setArticleBackRoute(
        //   `/${ROUTES.MODERATION_PREFIX}/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`
        // );
      }
    }

    let path = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.SOURCE_PREFIX}/${article.source_id}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
        path += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
        const location = {
          pathname: path,
        state: { animation: "left" },
      };
      this.goTo(location);
      this.props.setArticleBackRoute(`/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`);
    } else if (article.source_id === `${selected_feed.id}-userpost`) {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
      const location = {
        pathname: path,
        state: { animation: "left" },
      };
      this.goTo(location);
      this.props.setArticleBackRoute(`/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`);
    } else if (
      article.branch !== ARTICLE_BRANCH_YOUTUBE_SUMMARY &&
      article.branch !== ARTICLE_BRANCH_PODCAST_SUMMARY
    ) {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
      const location = {
        pathname: path,
        state: { animation: "left" },
      };
      this.goTo(location);
      this.props.setArticleBackRoute(`/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`);
    }
  };

  handleSelectGroupArticle = async (nid) => {
    this.setWaiting(true);
    await getArticle(nid);
    this.setWaiting(false);

    const location = {
      pathname: `/${ROUTES.ARTICLE_NEWS_PREFIX}/${nid}`,
      state: { animation: "left" },
    };
    this.goTo(location);
    this.props.setArticleBackRoute(
      `/${ROUTES.FEEDS_PREFIX}/${this.props.selected_feed.slug}`
    );
  };

  handleChangeCountry = async (country) => {
    this.props.selectCountry(country);
    this.props.initScrollPos();
    this.props.showTopNavbar(true);

    const { branch } = this.props;

    this.setWaiting(true);
    // get articles of the country in first page
    if (country === ALL) {
      await this.getArticlesOfBranchInFirstPage(branch);
    } else {
      await this.getArticlesOfCountryInFirstPage(country);
    }
    this.setWaiting(false);
    window.scrollTo(0, 0);
  };

  handleChangeBranch = async (branch) => {
    this.props.selectCountry(ALL);
    this.props.selectBranch(branch);
    this.props.initScrollPos();
    this.props.showTopNavbar(true);

    this.setWaiting(true);
    // get articles of the country in first page
    if (branch === BRANCH_ALL) {
      await this.getArticlesInFirstPage();
    } else {
      await this.getArticlesOfBranchInFirstPage(branch);
    }
    this.setWaiting(false);
    window.scrollTo(0, 0);
  };

  getSourceIdsToShow = () => {
    const {
      loggedIn,
      authUser,
      selected_feed,
    } = this.props;

    let source_ids = selected_feed.feed_sources
      .filter((feed_source) => 
        feed_source.approved && 
        is_source_alive(feed_source.source)
      )
      .map((feed_source) => feed_source.source_id);
    if (loggedIn) {
      const unfollowed = authUser.feed_sources_unfollowed.filter(
        (item) => item.feed_id === selected_feed.id
      );
      source_ids = source_ids.filter(
        (source_id) =>
          unfollowed.findIndex((item) => item.source_id === source_id) === -1
      );
    }

    return source_ids;
  };

  getArticlesInFirstPage = async () => {
    const { selected_feed } = this.props;
    if(!selected_feed){
      return
    }
    this.props.setRequestGetArticles(true);

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles(selected_feed.id, 0)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("articles in feed(first page) :", feed_articles);
          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.setArticlesOnFeedModeration(articles, articles.length ,selected_feed?.id)
            this.props.showBottomNavbar(true);
          }else{
            this.props.refreshArticlesOnFeedModeration()
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.props.setRequestGetArticles(false);
        this.setError(JSON.stringify(err));
      });
      this.props.setRequestGetArticles(false);
  };

  getArticlesInNextPage = async (last_offset) => {

    const { selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles(selected_feed.id, last_offset)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("articles in sources(next page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.appendArticlesOnFeedModeration(articles, last_offset + articles.length , selected_feed?.id);
          } else {
            this.props.appendArticlesOnFeedModeration([], last_offset ,selected_feed?.id);
          }

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesOfBranchInFirstPage = async (branch) => {

    const { selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    this.props.setRequestGetArticlesOfBranch(true);
    await gqlservice
      .feed_articles_of_branch(selected_feed.id, branch, 0)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("feed articles of branch(first page) :", feed_articles);
          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.setArticlesOnFeedModeration(articles, articles.length ,selected_feed?.id)
            this.props.showBottomNavbar(true);
          }else{
            this.props.refreshArticlesOnFeedModeration()
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.props.setRequestGetArticlesOfBranch(false);
        this.setError(JSON.stringify(err));
      });
      this.props.setRequestGetArticlesOfBranch(false);
  };

  getArticlesOfBranchInNextPage = async (branch, last_offset) => {

    const { selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_branch(selected_feed.id, branch, last_offset)
      .then((result) => {
          const feed_articles = result.data;
          logger.log("feed articles of branch(next page) :", feed_articles);
          
          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.appendArticlesOnFeedModeration(articles, last_offset + articles.length , selected_feed?.id);
          } else {
            this.props.appendArticlesOnFeedModeration([], last_offset,selected_feed?.id);
          }

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesOfCountryInFirstPage = async (country) => {

    const { selected_feed } = this.props;
    this.props.setRequestGetArticlesOfCountry(true);
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_country(selected_feed.id, country, 0)
      .then((result) => {
          const feed_articles = result.data;
          logger.log("feed articles of country(first page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.setArticlesOnFeedModeration(articles, articles.length , selected_feed?.id)
            this.props.showBottomNavbar(true);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.props.setRequestGetArticlesOfCountry(false);
        this.setError(JSON.stringify(err));
      });
      this.props.setRequestGetArticlesOfCountry(false);
  };

  getArticlesOfCountryInNextPage = async (country, last_offset) => {

    const { selected_feed } = this.props;
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles_of_country(selected_feed.id, country, last_offset)
      .then((result) => {
          const feed_articles = result.data;
          logger.log("feed articles of country(next page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.appendArticlesOnFeedModeration(articles, last_offset + articles.length , selected_feed?.id) ;
          } else {
            this.props.appendArticlesOnFeedModeration([], last_offset , selected_feed?.id);
          }

          this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getFeedPosts = async () => {
    const { selected_feed } = this.props;
    if(!selected_feed){
      return
    }
    this.props.setRequestGetFeedPosts(true);
    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_posts(selected_feed.id)
      .then(
        (result) => {
          let posts = result.data.feed_posts;
          if (posts.length > 0) {
            posts = posts.map((post) =>{ 
              post.article['feed_id']=post.feed_id
            return post.article});
            logger.log("get feed posts :", posts);
            this.props.setFeedPosts(posts);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.props.setRequestGetFeedPosts(false);
        this.setError(JSON.stringify(err));
      });
      this.props.setRequestGetFeedPosts(false);
  };

  getThreadsInFirstPage = async () => {
    const { selected_feed } = this.props;

    let source_ids = selected_feed.feed_sources
      .filter((feed_source) => feed_source.approved)
      .map((feed_source) => feed_source.source_id);

    let approved = true;
    if (selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    this.setWaiting(true);

    const gqlservice = new GraphqlService();
    await gqlservice
      .threads_by_feeds(selected_feed.id, source_ids, approved, 0)
      .then(
        (result) => {
          const threads = result.data.threads;
          logger.log("Feed => threads(first page) :", threads);

          this.props.setThreads(threads, threads.length);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });

    this.setWaiting(false);
  };

  getThreadsInNextPage = async (last_offset) => {
    const { selected_feed } = this.props;

    let source_ids = selected_feed.feed_sources
      .filter((feed_source) => feed_source.approved)
      .map((feed_source) => feed_source.source_id);

    let approved = true;
    if (selected_feed.comment_conf === FEED_COMMENT_UNMODERATED) {
      approved = null;
    }

    const gqlservice = new GraphqlService();
    await gqlservice
      .threads_by_feeds(selected_feed.id, source_ids, approved, last_offset)
      .then(
        (result) => {
          const threads = result.data.threads;
          logger.log("Feed => threads(next page) :", threads);

          if (threads.length > 0) {
            this.props.appendThreads(threads, last_offset + threads.length);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  searchSource = (sources, searchKey) => {
    var result = [];
    sources.forEach((source) => {
      if (
        source.name.toLowerCase().includes(searchKey) ||
        source.description.toLowerCase().includes(searchKey)
      ) {
        result.push(source);
      }
    });
    return result;
  };

  handleCopyLink = () => {
    copy2clipboard(window.location.href);
    ToastSuccess("Copied to clipboard");
  };

  __isValidFeedId = (feed_id) => {
    const { feeds } = this.props;
    return feeds.find(feed => feed.id === feed_id) === undefined;
  }

  handleDuplicate = async () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    if (isBannedFeedCreation()) {
      ToastError("You've suspended for feed creation.");
      return;
    }

    const { authUser, selected_feed, selected_category } = this.props;
    // make id & name of the duplicate feed
    let rand_id = gen_random_int(1000);
    let new_feed_id = `${selected_feed.id}-${rand_id.toString()}`;
    while (this.__isValidFeedId(new_feed_id) === false) {
      rand_id = gen_random_int(1000);
      new_feed_id = `${selected_feed.id}-${rand_id.toString()}`;
    }

    // duplicate feed
    const timestamp = Math.floor(Date.now() / 1000);
    const duplicate_feed = {
      id          : new_feed_id,
      name        : `${selected_feed.name} (copy)`,
      category_id : selected_feed.category_id,
      description : selected_feed.description,
      slug        : `${selected_feed.slug}-${rand_id.toString()}`,
      image       : selected_feed.image,
      tags        : selected_feed.tags,
      created_by  : authUser.uid,
      created_at  : timestamp,
      private     : true,
      approved    : false
    };

    // feed moderator
    const feed_moderator = {
      id: uuidv4(),
      feed_id: duplicate_feed.id,
      user_id: authUser.uid,
      approved: true,
      approved_by: authUser.uid,
      owner: true
    };

    // userpost source
    const userpost_source = {
      id          : `${duplicate_feed.id}-userpost`,
      category_id : duplicate_feed.category_id,
      name        : `User posts on ${duplicate_feed.name}`,
      description : `User posts on ${duplicate_feed.name}`,
      image       : duplicate_feed.image,
      branch      : ARTICLE_BRANCH_USERPOST,
      translateLang: "English",
      translateAPI: "",
      translate   : false,
      frequency   : -1,
      disableFullText: false,
      followers   : 0,
      created_by  : duplicate_feed.created_by,
      created_at  : duplicate_feed.created_at,
      approved    : true
    };

    // feed sources
    const userpost_source_id = `${selected_feed.id}-userpost`;
    let feed_source_ids = selected_feed.feed_sources
      .filter(feed_source => feed_source.source_id !== userpost_source_id)
      .map(feed_source => feed_source.source_id);
    feed_source_ids.push(`${duplicate_feed.id}-userpost`);

    const currentTime = new Date().toISOString();
    const feed_sources = feed_source_ids.map(feed_source_id => {
      let feed_source = {
        id: uuidv4(),
        feed_id: duplicate_feed.id,
        source_id: feed_source_id,
        created_by: authUser.uid,
        approved: true,
        approved_by: authUser.uid,
        approved_at: currentTime
      }
      return feed_source;
    });

    // log this activity
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_CATEGORY,
      type_id: selected_category.id,
      action: ACTIVITY_COPY,
      object: `the feed ${selected_feed.name}`,
      fromto: `to the category ${selected_category.name}`,
      reason: ''
    };

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token);

    this.setWaiting(true);

    await gqlservice
    .insert_source(userpost_source)
    .then((result) => {
      return gqlservice.source_by_id(userpost_source.id);
    })
    .then((result) => {
      const sources = result.data.sources;
      if (sources.length > 0) {
        this.props.addSource(sources[0]);
      }
      return gqlservice.insert_feeds(duplicate_feed, feed_sources)
    })
    .then((result) => {
      return gqlservice.insert_feed_moderator(feed_moderator);
    })
    .then((result) => {
      return gqlservice.feed_by_id(duplicate_feed.id);
    })
    .then((result) => {
      const feeds = result.data.feeds;
      if (feeds.length > 0) {
        this.props.addFeed(feeds[0]);
        this.props.addFeedModerated(feeds[0]);
      }
      return gqlservice.insert_activitylog(activity)
    })
    .then((result) => {
      
      this.setState({
        ...this.state,
        error: false
      });
    }, (reason) => {
      this.setError(reason.msg);
    })
    .catch(err => {
      this.setError(JSON.stringify(err));
    });

    this.setWaiting(false);

    const route = `/${ROUTES.FEEDS_PREFIX}/${duplicate_feed.slug}/edit`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  }

  handleCategoryIndicator = (category) => {
    const route = `/${ROUTES.CATEGORY_PREFIX}/${category.id}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  }

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.goTo(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
  };

  showAddPostDlg = () => {
    const { loggedIn, selected_feed } = this.props;

    if (loggedIn || selected_feed.op_anonymity) {
      if (isBannedPosts()) {
        ToastError("You've suspended for post operation.");
        return;
      }

      this.setState({
        ...this.state,
        postDlg: true
      });
    } else {
      this.setState({
        ...this.state,
        loginDlg: true
      });
    }
  };
  showADlgWeb=()=>{
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }
    this.setState({
      ...this.state,
      webDlg: true
    });
  }

  closePostDlg = () => {
    this.setState({
      ...this.state,
      postDlg: false,
      webDlg: false
    });
  };
  //For ai_moderation post approvel
  handleApprovePosts = async (
    imageUpload,
    videoUpload,
    description,
    postlink
  ) => {
    
    const { authUser, selected_feed } = this.props;
    const feeds = JSON.parse(selected_feed.ai_moderation) == null ? [] : JSON.parse(selected_feed.ai_moderation);
    if (feeds.includes(APPROVING_POSTS)) {
      const params = {
        name: selected_feed.name,
        description: selected_feed.description,
        userName: authUser.name,
        userBio: authUser.biography,
        userId:authUser.uid,
        userKarmaScore: authUser?.articles_voted?.length,
        postText: description,
        triggersContentFilter: false
      };
      const token = await getAuthToken();
      if (!token) {
        this.handleLogin();
        return;
      }
      
      await approveAiPosts(params, token)
      .then(
        (result) => {
          if (result.status === 200) {
            if ((result.message.decision.toLowerCase()) === "denied") {
              this.setState({
                ...this.state,
                postDlg: false
              });
              this.setError(`This post is not approved by AI beacuse ${JSON.stringify(result.message.reason)}`);
            } else {
              this.handleSubmitPost(imageUpload, videoUpload, description, postlink);
            }
          } else {
            this.setError("Failed to generate ai approvement.");
            return;
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
  }
  else {
      this.handleSubmitPost(imageUpload, videoUpload, description, postlink);
    }
  };

  handleSubmitPost = async (imageUpload, videoUpload, description, postlink) => {
    this.setState({
      ...this.state,
      postDlg: false
    });

    this.setWaiting(true);

    let new_image = "";
    if (imageUpload) {
      const resized_image = await resizeImageFile(imageUpload);
      const result = await this.props.firebase.uploadImage(resized_image, "posts");
      if (result.error) {
        this.setError("Failed to upload image.");
        return;
      }
      new_image = result.url;
      const modresult = moderate_image(new_image);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError("Image not allowed, because it contains adults or racy content.");
        await this.props.firebase.deleteImage(new_image);
        return;
      }
    }

    let video = "";
    if (videoUpload) {
      const result = await this.props.firebase.uploadVideo(videoUpload, "videocasts");
      if (result.error) {
        this.setError("Failed to upload video.");
        return;
      }
      video = result.url;
    }

    let preview = null;
    if (postlink) {
      preview = await get_link_source(postlink);
    }

    const { loggedIn, authUser, selected_feed,token } = this.props;

    let title = "Anonymous User";
    if (loggedIn) {
      title = authUser.biography;
      if (!title) {
        title = authUser.name;
      }
    }

    let nid = "";
    const uid = loggedIn ? authUser.uid : "anonymous";
    if (postlink) {
      nid = get_hash(uid + postlink);
    } else {
      nid = get_hash(uid + description);
    }

    const gqlservice = new GraphqlService();

    // check if there is already article
    const result = await gqlservice.article_by_nid(nid);
    if (result.status_code === GRAPHQL_SUCCESS && result.data.articles.length > 0) {
      this.setError("This post was already posted");
      return;
    }

    const summary = summarize_text(description, 150);
    const source_id = `${selected_feed.id}-userpost`;
    const published = new Date().getTime() / 1000;
    const userpost = {
      nid: nid,
      source_id: source_id,
      author: loggedIn ? authUser.username : "Anonymous User",
      title: title,
      summary: summary,
      text: description,
      image: new_image,
      media_url: video,
      branch: ARTICLE_BRANCH_USERPOST,
      url: "",
      author_image: loggedIn ? authUser.image : '',
      published: Math.floor(published),
      param1: selected_feed.private ? 0 : 1,      // approved
      param2: 0,                                  // moderator
      param3: -1,
      txt_param1: loggedIn ? authUser.uid : null, // user id
      link_preview: preview,
    }

    if (loggedIn) {
      const token = await getAuthToken();
      if (!token) {
        this.setWaiting(false);
        this.handleLogin();
        return;
      }
      gqlservice.set_auth_jwt(token);
    }

    await gqlservice
      .insert_userpost(userpost)
      .then(
        (result) => {
          logger.log("insert userpost result :", result);
          const articles = result.data.insert_articles.returning;
          if (articles.length > 0) {
            this.props.addUserPost(articles[0]);
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    if (loggedIn && videoUpload) {
      const feed_videocast = {
        feed_id: selected_feed.id,
        article_id: userpost.nid,
        posted_by: authUser.uid
      }

      await gqlservice
        .insert_feed_videocast(feed_videocast)
        .then(
          (result) => {
            const feed_videocasts = result.data.insert_feed_videocasts.returning;
            if (feed_videocasts.length > 0) {
              
            }
          },
          (reason) => {
            this.setError(reason.msg);
            return;
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
          return;
        });
    }

    const curTs = Math.floor(Date.now() / 1000);
    const feed_article = {
      feed_id: selected_feed.id,
      article_id: userpost.nid,
      branch: ARTICLE_BRANCH_USERPOST,
      published: curTs,
      crawled_at: curTs
    }

    await gqlservice
      .insert_feed_article(feed_article)
      .then((result) => {
        ToastInfo("Posted successfully");
      }, (reason) => {
        this.setError(reason.msg);
        return;
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    const now = new Date();
    await gqlservice
      .update_source_lastupdated(source_id, now.toISOString())
      .then((result) => {
        return gqlservice.feed_by_id(selected_feed.id);
      })
      .then((result) => {
        const feeds = result.data.feeds;
        if (feeds.length > 0) {
          const selected_feed = feeds[0];
          this.props.selectFeed(selected_feed);
        }
      }, (reason) => {
        this.setError(reason.msg);
        return;
      })
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });

    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_FEED,
      type_id: selected_feed.id,
      action: ACTIVITY_ADD,
      object: `the post ${userpost.nid}`,
      fromto: `to the feed ${selected_feed.name}`,
      reason: "",
    };

    await gqlservice
      .insert_activitylog(activity)
      .then(
        (result) => {},
        (reason) => { this.setError(reason.msg); }
      )
      .catch(
        (err) => { this.setError(JSON.stringify(err)); }
      );
    updateFeedCache(selected_feed.slug)
    this.setWaiting(false);
    let userpostToBluesky;
    let userpostToMastodon;
    try {
      const promises = [];
    
      if (authUser.is_mastodon_enabled === true && authUser.mastodon_username) {
        promises.push(postToMastodon(userpost, token, authUser.mastodon_access_token));
      }
    
      if (authUser.is_bluesky_enabled === true) {
        const { authUser } = this.props;
        let params = {
          text: description,
          accessJwt:authUser.bluesky_access_token
        };
    
        promises.push(postToBluesky(params, token));
      }
    
      const [userpostToMastodon, userpostToBluesky] = await Promise.all(promises);
    
    } catch (error) {
      console.error('Error posting to Mastodon or Bluesky:', error);
    }
  }

  closePostEditDlg = () => {
    this.setState({
      ...this.state,
      postEditDlg: false,
      article_edit: null
    });
  };

  handleUpdatePost = async (description, postlink) => {
    this.setState({
      ...this.state,
      postEditDlg: false
    });

    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    if (isBannedPosts()) {
      ToastError("You've suspended for post operations.");
      return;
    }

    const { article_edit } = this.state;

    this.setWaiting(true);
    await updatePost(description, postlink, article_edit);
    this.setWaiting(false);

    this.setState({
      ...this.state,
      article_edit: null,
    });
  }

  handleAddSource = () => {
    const { loggedIn, selected_feed } = this.props;
    if (loggedIn || selected_feed.op_anonymity) {
      if (isBanned()) {
        ToastError("You've suspended.");
        return;
      }

      this.setState({
        ...this.state,
        suggestSourceDlg: true,
      });
    } else {
      this.setState({
        ...this.state,
        loginDlg: true
      });
    }
  };

  handleCancelSuggestSource = () => {
    this.setState({
      ...this.state,
      suggestSourceDlg: false,
    });
  };

  handleSuggestSource = () => {
    this.setState({
      ...this.state,
      suggestSourceDlg: false,
    });

    const { loggedIn, selected_feed } = this.props;
    if (loggedIn || selected_feed.op_anonymity) {
      if (isBanned()) {
        ToastError("You've suspended.");
        return;
      }

      const location = {
        pathname: ROUTES.SOURCE_ADD,
        state: { animation: "left" },
      };
      this.goTo(location);
    } else {
      this.setState({
        ...this.state,
        loginDlg: true
      });
    }
  };

  handleSourceSearch = () => {
    this.setState({
      ...this.state,
      suggestSourceDlg: false,
    });
    const location = {
      pathname: ROUTES.SOURCE_SEARCH,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  handleReportArticle = async (article, reportMsg) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };

  handleEditArticle = (article) => {
    this.setState({
      ...this.state,
      postEditDlg: true,
      article_edit: article
    });
  }

  handleDeleteArticle = async (article) => {
    const { loggedIn, selected_feed } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }
    
    if (isBannedPosts()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await deleteArticle(article);
    updateFeedCache(selected_feed.slug)
    this.setWaiting(false);
  }

  handleSaveArticle = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  }

  // empty funciton
  handleDeleteSavedArticle = (article) => {}
  // empty function

  handleNeedMoreThreads = async () => {
    const { selected_feed } = this.props;
    if (!isCommentEnabledFeed(selected_feed)) {
      return;
    }
    const { threads_last_offset, requesting } = this.props;
    if (requesting) {
      return;
    }

    this.setWaiting(true);
    await this.getThreadsInNextPage(threads_last_offset);
    this.setWaiting(false);
  }

  handleAddThread = () => {
    const { loggedIn, selected_feed } = this.props;
    if (loggedIn) {
      if (hasPaid() || selected_feed.op_payment) {
        if (isBannedComments()) {
          ToastError("You've suspended for comment operations.");
          return;
        }

        this.setState({
          ...this.state,
          threadDlg: true,
        });
      } else {
        const location = {
          pathname: ROUTES.MEMBERSHIP,
          state: { animation: "bottom" },
        };
        this.goTo(location);
      }
    } else {
      this.setState({
        ...this.state,
        loginDlg: true
      });
    }
  };

  handleCloseThread = () => {
    this.setState({
      ...this.state,
      threadDlg: false,
    });
  };

  handleSubmitThread = async (title, content) => {
    const { authUser, selected_feed } = this.props;
    
    // check if the user is pre-approved for this selected feed
    const approved_user = authUser.preapproved.find(
      (item) => item.feed_id === selected_feed.id
    );
    const approved = approved_user !== undefined;

    this.setWaiting(true);
    await addThread(title, content, selected_feed, approved);
    this.setWaiting(false);

    this.handleCloseThread();
  };

  handleClickThread = (thread) => {
    const { selected_feed } = this.props;
    this.props.selectThread(thread);
    const route = `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}/${ROUTES.THREAD_PREFIX}/${thread.id}`;
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    this.goTo(location);
  };

  getSourceThrottles2show = () => {
    const {
      loggedIn,
      authUser,
      selected_feed,
    } = this.props;

    let throttles = [];
    let feed_sources = selected_feed.feed_sources.filter(
      (feed_source) => feed_source.approved
    );
    if (loggedIn) {
      const unfollowed = authUser.feed_sources_unfollowed.filter(
        (item) => item.feed_id === selected_feed.id
      );
      feed_sources = feed_sources.filter(
        (feed_source) =>
          unfollowed.findIndex(
            (item) => item.source_id === feed_source.source_id
          ) === -1
      );
    }

    throttles = feed_sources.map((feed_source) => {
      return {
        source_id: feed_source.source_id,
        throttle: feed_source.source.throttle,
        article_count: 0,
      };
    });

    return throttles;
  };

  removeArticlesByThrottle = (articles) => {
    let new_articles = [];
    if (articles.length === 0) {
      return new_articles;
    }

    let throttles = this.getSourceThrottles2show();
    if (throttles.length === 0) {
      return new_articles;
    }

    for (let article of articles) {
      let throttle = throttles.find(
        (throttle) => throttle.source_id === article.source_id
      );
      if (throttle === undefined) {
        new_articles.push(article);
        continue;
      }

      throttle.article_count++;
      switch (throttle.throttle) {
        case 100:
          new_articles.push(article);
          break;

        case 75:
          if (throttle.article_count % 4 !== 0) {
            new_articles.push(article);
          }
          break;

        case 50:
          if (throttle.article_count % 2 === 0) {
            new_articles.push(article);
          }
          break;

        case 25:
          if (throttle.article_count % 4 === 0) {
            new_articles.push(article);
          }
          break;

        default:
          break;
      }
    }

    return new_articles;
  };

  renderPostWarning = (classes, theme) => {
    return (
      <div className={classes.warningcontainer}>
        <div className={classes.warningimg}>
          <img
            className={classes.warningicon}
            alt="warning"
            src={`/static/images/icons/${theme}/warning.png`}
          />
        </div>
        <Typography className={classes.warningtitle}>
          No posts in the feed yet.
        </Typography>
        <Typography className={classes.warningtext}>
          Press
          <img
            className={classes.indicatoricon}
            alt="plus"
            src={`/static/images/icons/${theme}/add.png`}
          />
          button to post an entry or go to the <strong>sources</strong> tab and add a social media source
        </Typography>
      </div>
    );
  };

  renderSourcesWarning = (classes, theme) => {
    return (
      <div className={classes.warningcontainer}>
        <div className={classes.warningimg}>
          <img
            className={classes.warningicon}
            alt="warning"
            src={`/static/images/icons/${theme}/warning.png`}
          />
        </div>
        <Typography className={classes.warningtitle}>
          No Sources added yet.
        </Typography>
        <Typography className={classes.warningtext}>
          Press
          <img
            className={classes.indicatoricon}
            alt="plus"
            src={`/static/images/icons/${theme}/add.png`}
          />
          button to suggest a social media source that you think might be useful in the feed
        </Typography>
      </div>
    );
  };

  getArticlesSummary= async () => {
    const { selected_feed } = this.props;
    if(!selected_feed){
      return
    }
    if(selected_feed.ai_summary_conf === 0){
     return;
    }
    this.props.setRequestGetArticleSummary(true);

   const source_ids = this.getSourceIdsToShow();
   if (source_ids.length === 0) {
    this.props.refreshArticlesOnFeedModeration();
    this.props.setRequestGetArticleSummary(false);
    return;
   }
   const gqlservice = new GraphqlService();
   await gqlservice
     .articles_summary(source_ids)
     .then(
       (result) => {
         const summary = result.data;
         this.setState({
           ...this.state,
           aiSummary:summary
         });

         // this.props.setArticles(articles, articles.length);
         // this.props.showBottomNavbar(true);
       },
       (reason) => {
         this.setError(reason.msg);
       }
     )
     .catch((err) => {
      this.props.setRequestGetArticleSummary(false);
       this.setError(JSON.stringify(err));
     });
     this.props.setRequestGetArticleSummary(false);
 };

 getSearchTermSourceArticlesOfFirstPage = async () => {
  const { selected_feed } = this.props;
  if(!selected_feed){
    return
  }
  const searchTermSourceIds =selected_feed.feed_sources.filter(
    (feed_source) => {return true
  }).map(function(item) {
    return item.source_id;
  });
  this.props.setRequestGetSearchTermSourceArticles(true);

  const gqlservice = new GraphqlService();
  await gqlservice
    .searchTermSourceArticles(searchTermSourceIds,0)
    .then(
      (result) => {
        let articles = result.data.ai_source_articles;
        if (articles.length > 0) {
          const updatedDataArray = articles.map((parsedData) => {
             _.set(parsedData, 'article.source_id', parsedData.source_id);
             _.set(parsedData,'article.source', parsedData.source);
             return parsedData;
          });
          
          articles = updatedDataArray.map((article) => {  
            article.article['feed_id'] = "edison-central"; 
              return article.article;
          });
           
          this.props.setArticlesOnSearchTermSource(articles, articles.length ,selected_feed?.id)
          this.props.showBottomNavbar(true);
        }
      },
      (reason) => {
        this.setError(reason);
      }
    )
    .catch((err) => {
      this.props.setRequestGetSearchTermSourceArticles(false);
      this.setError(err);
    });
    this.props.setRequestGetSearchTermSourceArticles(false);
};


// getSearchTermSourceArticlesInNextPage = async (last_offset) => {
//   const { selected_feed } = this.props;
//   if(!selected_feed){
//     return
//   }
//   const searchTermSourceIds =selected_feed.feed_sources.filter(
//     (feed_source) => {return true
//   }).map(function(item) {
//     return item.source_id;
//   });

//   const gqlservice = new GraphqlService();
//   await gqlservice
//     .searchTermSourceArticles(searchTermSourceIds,last_offset)
//     .then(
//       (result) => {
//         let articles = result.data.ai_source_articles;
//         if (articles.length > 0) {
//           const updatedDataArray = articles.map((parsedData) => {
//              _.set(parsedData, 'article.source_id', parsedData.source_id);
//              _.set(parsedData,'article.source', parsedData.source);
//              return parsedData;
//           });
          
//           articles = updatedDataArray.map((article) => {  
//             article.article['feed_id'] = "edison-central"; 
//               return article.article;
//           });
//           this.props.appendArticlesOnSearchTermSource(articles, last_offset + articles.length , selected_feed?.id);
//         } else {
//           this.props.appendArticlesOnSearchTermSource([], last_offset ,selected_feed?.id);
//         }
//       },
//       (reason) => {
//         this.setError(reason);
//       }
//     )
//     .catch((err) => {
//       this.setError(err);
//     });
// };
 
  render() {
    const {
      classes,
      loggedIn,
      authUser,
      newssites,
      selected_feed,
      sources,
      branch,
      country,
      articles_on_feed_moderation,
      articles,
      feed_posts,
      pins,
      movetops,
      threads,
      topNavbar,
      bottomNavbar,
      feedtab,
      theme_mode,
      requesting,
      articles_on_search_term_source,
      get_feed_info_requesting,
      get_article_pins_requesting,
      get_article_summary_requesting,
      get_search_term_source_articles_requesting,
      get_feed_articles_of_branch_requesting,
      get_feed_articles_of_country_requesting,
      get_feed_articles_requesting,
      get_feed_posts_requesting,
      get_main_info_requesting 
    } = this.props;
    const {
      // topFeeds,
      searchKey,
      suggestSourceDlg,
      postDlg,
      webDlg,
      postEditDlg,
      article_edit,
      loginDlg,
      threadDlg,
      aiSummary,
      isPostsEmpty,
      loading
    } = this.state;

    if (newssites.length === 0 || selected_feed === null) {
      return <div style={{display: "none"}}></div>;
    }

    // members
    let feed_members = selected_feed?.feed_moderators.filter(moderator => moderator.approved);
    for (let feed_follower of selected_feed.feed_followers) {
      if (feed_members.find(member => member.user_id === feed_follower.user_id) === undefined) {
        feed_members.push(feed_follower);
      }
    }

    // get sources to show
    const source_ids = selected_feed.feed_sources
      .filter((feed_source) => feed_source.approved)
      .map((feed_source) => feed_source.source_id);
    let sources2show = sources
      .filter((source) => source.branch !== ARTICLE_BRANCH_USERPOST)
      .filter((source) => source_ids.includes(source.id));
    if (searchKey) {
      sources2show = this.searchSource(sources2show, searchKey);
    }

    // get pins and movetops to show
    let pins2show = [];
    let movetops2show = [];
    let selectedFeedSearchTermSourceArticles =[]
    
    if (branch === ARTICLE_BRANCH_NEWSPAPER) {
      if (country === ALL) {
        pins2show = pins.filter((pin) => pin.article.branch === branch && pin.feed_id === selected_feed.id);
        movetops2show = movetops.filter(
          (movetop) => movetop.article.branch === branch && movetop.feed_id === selected_feed.id
        );
      } else {
        pins2show = pins.filter(
          (pin) =>
          pin.article.branch === branch && pin.article.country === country && pin.feed_id === selected_feed.id
          );
        movetops2show = movetops.filter(
          (movetop) =>
            movetop.article.branch === branch &&
            movetop.article.country === country && movetop.feed_id === selected_feed.id
        );
      }
    } else {
      if (branch === BRANCH_ALL) {
        pins2show = pins.filter((pin) =>  pin.feed_id === selected_feed.id);
        movetops2show = movetops.filter((movetop) => movetop.feed_id === selected_feed.id);
        selectedFeedSearchTermSourceArticles = articles_on_search_term_source[selected_feed?.id]?.articles_on_search_term_source !== undefined ? articles_on_search_term_source[selected_feed?.id].articles_on_search_term_source:[]

      } else {
        pins2show = pins.filter((pin) => pin.article.branch === branch && pin.feed_id === selected_feed.id);
        movetops2show = movetops.filter(
          (movetop) => movetop.article.branch === branch && movetop.feed_id === selected_feed.id
        );
      }
    }
    let selectedFeedArticles =[]
    selectedFeedArticles = articles_on_feed_moderation[selected_feed?.id]?.articles_on_feed_moderation !== undefined ? articles_on_feed_moderation[selected_feed?.id].articles_on_feed_moderation:[]
    let filtered_feed_post = feed_posts.filter((feed_post)=>feed_post.feed_id === selected_feed?.id)
   
    const all_articles = [...filtered_feed_post ,...selectedFeedArticles,...selectedFeedSearchTermSourceArticles];
 
    const sorted_articles = all_articles.sort((a, b) => 
      b.published - a.published
    );

    logger.log("Feed : sorted articles :", sorted_articles);

    const real_articles = 
      sorted_articles
      .filter(
        (article) => ((sources.find((item) => item.id === article.source_id)!== undefined)||(article.branch===103||article.branch===104))
      )
        .filter(article =>
          // exclude non-approved user post
          article.branch !== ARTICLE_BRANCH_USERPOST || (
            article.branch === ARTICLE_BRANCH_USERPOST && article.param1 === 1
          )
        )
        .filter(article => {
          // remove articles with no keyword filter if the source has keyword filter
          let feed_source = selected_feed.feed_sources.find(item => item.source_id === article.source_id);
          if (
            feed_source === undefined || // feed posts
            feed_source.keyword_filter === null || feed_source.keyword_filter.trim() === "" // source with no keyword filter
          ) {
            return true;
          } else {
            let keyword = feed_source.keyword_filter.trim().toLowerCase();
            if (article.title?.toLowerCase().includes(keyword) || 
              article.summary?.toLowerCase().includes(keyword) ||
              article.text?.toLowerCase().includes(keyword)) {
              return true;
            } else {
              return false;
            };
          }
        });

    // get articles to show(delete pins & movetops from the articles)
    let articles2show = [];
    const throttled_articles = this.removeArticlesByThrottle(real_articles);
    for (let article of throttled_articles) {
      if (
        pins2show.find((pin) => pin.article.nid === article.nid) !==
          undefined ||
        movetops2show.find((movetop) => movetop.article.nid === article.nid) !==
          undefined
      ) {
        continue;
      }
      articles2show.push(article);
    }

    // moderation pending
    let pending = false;
    if (loggedIn) {
      const feed = authUser.feeds_moderated.find(feed_moderated => feed_moderated.feed_id === selected_feed.id);
      if (feed) {
        pending = feed.approved === false;
      }
    }

    // make appbar title
    const name = summarize_text(selected_feed.name, 30);
    const title = `Feeds: ${name}`;

    let shareUrl = "";
    if (typeof window !== "undefined") {
      shareUrl = window.location.protocol + "//" + window.location.host;
    }
    shareUrl += `/${ROUTES.FEEDS_PREFIX}/${selected_feed.slug}`;

    let shareInfo = {
      title: "Raven Feed: " + selected_feed.name,
      description: selected_feed.description,
      image: selected_feed.image || RAVEN_PLACEHOLDER_IMAGE,
      hashtag: selected_feed.tags.join(),
      url: shareUrl,
    };

    // layout variables
    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const height = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight;
    const isDesktop = width >= MAX_WINDOW_WIDTH;
    const isTablet =
      width >= MIN_TABLET_WIDTH &&
      width < MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;
    const innerWidth =
      width > MAX_WINDOW_WIDTH
        ? MAX_WINDOW_WIDTH
        : width;
    let topFeedsWidth = width - MAX_CARD_WIDTH;
    if (topFeedsWidth > MAX_CARD_WIDTH) {
      topFeedsWidth = MAX_CARD_WIDTH;
    }

    // card width = 414 - 16, grid space
    const masonryWidth = Math.floor(innerWidth / 402) * 402 + 16;

    let addbuttonPos = 0;
    if (isDesktop) {
      addbuttonPos = MAX_WINDOW_WIDTH - 96 + (width - MAX_WINDOW_WIDTH) / 2;
    } else if (innerWidth > MAX_CARD_WIDTH) {
      addbuttonPos = (innerWidth + MAX_CARD_WIDTH) / 2 - 96;
    } else {
      addbuttonPos = innerWidth - 96;
    }

    // const slideContainer = {
    //   height: height,
    //   WebkitOverflowScrolling: 'touch',
    // }

    const requestCompleteStatus =  !loading && !get_feed_info_requesting && !get_article_pins_requesting  && !get_article_summary_requesting  && !get_search_term_source_articles_requesting  && !get_feed_articles_of_branch_requesting  && !get_feed_articles_of_country_requesting  && !get_feed_articles_requesting  && !get_feed_posts_requesting && !get_main_info_requesting ? true : false;
    return (
      <div className={classes.root} onWheel={this.handleWheel}>
        <div className="wrapper">
          <MetaTags>
            <title>{`Raven: ${selected_feed.name}`}</title>
            <meta name="description" content={selected_feed.description} />
            <meta property="og:title" content={`Raven: ${selected_feed.name}`} />
            <meta property="og:description" content={selected_feed.description} />
            <meta property="og:image" content={selected_feed.image || RAVEN_PLACEHOLDER_IMAGE} />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={shareUrl} />
            <meta property="twitter:title" content={`Raven: ${selected_feed.name}`} />
            <meta property="twitter:site" content="Raven App" />
            <meta property="twitter:description" content={selected_feed.description} />
            <meta property="twitter:image:src" content={selected_feed.image || RAVEN_PLACEHOLDER_IMAGE} />
            <meta property="twitter:image:alt" content={selected_feed.name} />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>
        <div className={classes.appbar}>
          <MainAppBar
            show={topNavbar}
            title={title}
            share_info={shareInfo}
            onNavBack={this.handleNavBack}
          />
          <FeedsAppBar
            show={!topNavbar}
            image={selected_feed.thumbnail || selected_feed.image || RAVEN_PLACEHOLDER_IMAGE}
            title={selected_feed.name}
            onClickBackButton={this.handleClickBackButton}
          />
        </div>
        {topNavbar && (
          <Fade in={topNavbar} timeout={{ enter: 300, exit: 300 }}>
            <div className={classes.maincontainer}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <FeedDetail
                    title={selected_feed.name}
                    description={selected_feed.description}
                    image={selected_feed.thumbnail || selected_feed.image || RAVEN_PLACEHOLDER_IMAGE}
                    pending={pending}
                    onCopyLink={this.handleCopyLink}
                    onDuplicate={this.handleDuplicate}
                    onNeedLogin={this.handleLogin}
                    onReachLimit={this.handleReachLimit}
                    onClickCategoryIndicator={this.handleCategoryIndicator}
                  />
                </Grid>
              </Grid>
              <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <InviteControl
                    theme_mode={theme_mode}
                    privatefeed={selected_feed.private}
                    showmembers={selected_feed.op_members}
                    members={feed_members}
                    publicOnly={true}
                    onClickMembers={this.handleClickMembers}
                    onInviteMembers={this.handleInviteMembers}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <FeedTabs
                    theme_mode={theme_mode}
                    onChangeFeedTab={this.handleChangeFeedTab}
                  />
                </Grid>
              </Grid>
            </div>
          </Fade>
        )}
        <SwipeableViews
          // containerStyle={feedtab !== TAB_FEED ? slideContainer : null}
          index={feedtab}
          onChangeIndex={this.handleSwiped} 
          onScroll={this.handleSwipScroll}
          enableMouseEvents
        >
          <div 
            className={classes.tabcontainer} 
            id="feed-swipeable-views-feeds"
          >
            {feedtab === TAB_FEED &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                {(isDesktop || isTablet) && (
                  <Grid item>
                    {articles2show.length === 0 && requestCompleteStatus && !isPostsEmpty &&
                      this.renderPostWarning(classes, theme_mode)}
                    {articles2show.length > 0 && (
                      <ArticleMasonry
                        width={masonryWidth}
                        articles={articles2show}
                        aiSummary={aiSummary}
                        pins={pins2show}
                        movetops={movetops2show}
                        onNeedMore={this.handleNeedMoreArticles}
                        onSelectArticle={this.handleSelectArticle}
                        onSelectGroupArticle={this.handleSelectGroupArticle}
                        onNeedLogin={this.handleLogin}
                        onReport={this.handleReportArticle}
                        onEdit={this.handleEditArticle}
                        onDelete={this.handleDeleteArticle}
                        onSave={this.handleSaveArticle}
                        onDeleteSaved={this.handleDeleteSavedArticle}
                        onClickSource={this.handleClickSourceInArticle}
                        onClickFeed={this.handleClickFeed}
                        onClickUpvote={this.handleClickUpvote}
                        onClickComment={this.handleClickComment}
                        onClickRepost={this.handleClickRepost}
                        onClickArticle={this.handleSelectArticle} 
                      />
                    )}
                  </Grid>
                )}
                {isMobile && (
                  <Grid item>
                    {articles2show.length === 0 && requestCompleteStatus && !isPostsEmpty &&
                      this.renderPostWarning(classes, theme_mode)}
                    {articles2show.length > 0 && (
                      <ArticleList
                        articles={articles2show}
                        aiSummary={aiSummary}
                        pins={pins2show}
                        movetops={movetops2show}
                        onNeedMore={this.handleNeedMoreArticles}
                        onSelectArticle={this.handleSelectArticle}
                        onSelectGroupArticle={this.handleSelectGroupArticle}
                        onNeedLogin={this.handleLogin}
                        onReport={this.handleReportArticle}
                        onEdit={this.handleEditArticle}
                        onDelete={this.handleDeleteArticle}
                        onSave={this.handleSaveArticle}
                        onDeleteSaved={this.handleDeleteSavedArticle}
                        onClickSource={this.handleClickSourceInArticle}
                        onClickFeed={this.handleClickFeed}
                        onClickUpvote={this.handleClickUpvote}
                        onClickComment={this.handleClickComment}
                        onClickRepost={this.handleClickRepost}
                        onClickArticle={this.handleSelectArticle} 
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            }
            <div className={classes.bottomspace}></div>
          </div>

          <div 
            className={classes.tabcontainer} 
            id="feed-swipeable-views-sources"
          >
            {feedtab === TAB_SOURCES &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                {isDesktop && (
                  <Grid item>
                    {sources2show.length === 0 && !requesting &&
                      this.renderSourcesWarning(classes, theme_mode)}
                    {sources2show.length > 0 &&
                      <SourceList
                        width={840}
                        sources={sources2show}
                        onClicked={this.handleClickSource}
                        onUnfollowed={this.handleClickUnfollowed}
                        onShowRetweet={this.handleClickShowRetweet}
                        onNeedLogin={this.handleLogin}
                      />
                    }
                  </Grid>
                )}
                {isTablet && (
                  <Grid item>
                    {sources2show.length === 0 && !requesting &&
                      this.renderSourcesWarning(classes, theme_mode)}
                    {sources2show.length > 0 &&
                      <SourceList
                        width={MAX_CARD_WIDTH}
                        sources={sources2show}
                        onClicked={this.handleClickSource}
                        onUnfollowed={this.handleClickUnfollowed}
                        onShowRetweet={this.handleClickShowRetweet}
                        onNeedLogin={this.handleLogin}
                      />
                    }
                  </Grid>
                )}
                {isMobile && (
                  <Grid item>
                    {sources2show.length === 0 && !requesting &&
                      this.renderSourcesWarning(classes, theme_mode)}
                    {sources2show.length > 0 &&
                      <SourceList
                        width={
                          innerWidth > MAX_CARD_WIDTH
                            ? MAX_CARD_WIDTH
                            : innerWidth
                        }
                        sources={sources2show}
                        onClicked={this.handleClickSource}
                        onUnfollowed={this.handleClickUnfollowed}
                        onShowRetweet={this.handleClickShowRetweet}
                        onNeedLogin={this.handleLogin}
                      />
                    }
                  </Grid>
                )}
              </Grid>
            }
            <div className={classes.bottomspace}></div>
          </div>

          <div 
            className={classes.tabcontainer} 
            id="feed-swipeable-views-threads"
          >
            {feedtab === TAB_COMMENTS &&
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <ThreadList
                    maxWidth={MAX_CARD_WIDTH}
                    feed={selected_feed}
                    threads={threads}
                    onLogin={this.handleLogin}
                    onClickThread={this.handleClickThread}
                    onClickSource={this.handleClickSource}
                    onNeedMore={this.handleNeedMoreThreads}
                  />
                </Grid>
              </Grid>
            }
            <div className={classes.bottomspace}></div>
          </div>
        </SwipeableViews>

        <BottomNavBar
          show={bottomNavbar && feedtab === TAB_FEED}
          selected_feeds={[selected_feed]}
          onChangeCountry={this.handleChangeCountry}
          onChangeBranch={this.handleChangeBranch}
        />
        {feedtab === TAB_FEED && selected_feed.op_posts && (
          <Fragment>
            <div onClick={this.showADlgWeb}>
              <img
                className={classes.addbutton}
                style={{ backgroundColor: "#10a37f", marginLeft: -65, left: addbuttonPos, bottom: 80, borderRadius: 30, padding: 6 }}
                alt={"addlink"}
                src={`/static/images/summary.png`}

              />
            </div>
            <DlgWeblink
              selected_feed={selected_feed}
              open={webDlg}
              onClose={this.closePostDlg}
            />
            <DlgPost 
              open={postDlg}
              onSubmit={this.handleApprovePosts}
              onClose={this.closePostDlg}
            />
            {postEditDlg &&
              <DlgPostEdit
                open={postEditDlg}
                theme={theme_mode}
                article={article_edit}
                onSubmit={this.handleUpdatePost}
                onClose={this.closePostEditDlg}
              />
            }
            <div onClick={this.showAddPostDlg}>
              <img
                className={classes.addbutton}
                style={{ left: addbuttonPos, bottom: 80 }}
                alt={"addpost"}
                src={`/static/images/icons/${theme_mode}/add.png`}
              />
            </div>
          </Fragment>
        )}
        {feedtab === TAB_SOURCES && (
          <div>
            <div onClick={this.handleAddSource}>
              <img
                className={classes.addbutton}
                style={{ left: addbuttonPos, bottom: 16 }}
                alt={"addsource"}
                src={`/static/images/icons/${theme_mode}/add.png`}
              />
            </div>
            <DlgSuggestSource
              open={suggestSourceDlg}
              theme={theme_mode}
              onCancel={this.handleCancelSuggestSource}
              onSourceAdd={this.handleSuggestSource}
              onSourceSearch={this.handleSourceSearch}
            />
          </div>
        )}
        {feedtab === TAB_COMMENTS && isCommentEnabledFeed(selected_feed) && (
            <Fragment>
              <DlgThread
                open={threadDlg}
                theme={theme_mode}
                onClose={this.handleCloseThread}
                onSubmit={this.handleSubmitThread}
              />
              <div onClick={this.handleAddThread}>
                <img
                  className={classes.addbutton}
                  style={{ left: addbuttonPos, bottom: 16 }}
                  alt={"addthread"}
                  src={`/static/images/icons/${theme_mode}/add.png`}
                />
              </div>
            </Fragment>
        )}
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <WaitingSpinner open={!requestCompleteStatus} />
        <ToastContainer />
      </div>
    );
  }
}

Feed.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  newssites: state.dataState.newssites,
  categories: state.dataState.categories,
  selected_category: state.dataState.selected_category,
  feeds: state.dataState.feeds,
  followed_feeds: state.dataState.followed_feeds,
  selected_feed: state.dataState.selected_feed,
  articles_on_feed_moderation: state.dataState.articles_on_feed_moderation,
  articles: state.dataState.articles,
  feed_posts: state.dataState.feed_posts,
  pins: state.dataState.pins,
  movetops: state.dataState.movetops,
  last_offset: state.dataState.last_offset,
  sources: state.dataState.sources,
  feed_backroute: state.uiState.feed_backroute,
  country: state.dataState.country,
  branch: state.dataState.branch,
  threads: state.dataState.threads,
  threads_last_offset: state.dataState.threads_last_offset,
  topNavbar: state.uiState.topNavbar,
  bottomNavbar: state.uiState.bottomNavbar,
  scrollPos: state.uiState.scrollPos,
  theme_mode: state.uiState.theme_mode,
  feedtab: state.uiState.feedtab,
  requesting: state.uiState.requesting,
  articles_on_search_term_source: state.dataState.articles_on_search_term_source,
  get_feed_info_requesting: state.uiState.get_feed_info_requesting,
  get_article_pins_requesting : state.uiState.get_article_pins_requesting,
  get_article_summary_requesting : state.uiState.get_article_summary_requesting,
  get_search_term_source_articles_requesting : state.uiState.get_search_term_source_articles_requesting,
  get_feed_articles_of_branch_requesting : state.uiState.get_feed_articles_of_branch_requesting,
  get_feed_articles_of_country_requesting : state.uiState.get_feed_articles_of_country_requesting,
  get_feed_articles_requesting : state.uiState.get_feed_articles_requesting,
  get_feed_posts_requesting : state.uiState.get_feed_posts_requesting,
  get_main_info_requesting: state.uiState.get_main_info_requesting,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Feed);
