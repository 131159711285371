export const QUERY_USER_REPORTS_BY_USER = `
query user_reports_by_user(
  $user_id: String!
) {
  user_reports(
    where: { user_id: {_eq: $user_id} }
  ) {
    id
    user_id
    report
    reported_at
    reported_by
    approved
    approved_at
    approved_by
    reported_user {
      username
    }
  }
}`;

export const MUTATION_INSERT_USER_REPORT = `
mutation insert_user_report(
    $user_id: String!,
    $report: String!,
    $reported_by: String!,
    $approved: Boolean
) {
  insert_user_reports(
    objects: {
      user_id: $user_id, 
      report: $report,
      reported_by: $reported_by,
      approved: $approved
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_DELETE_USER_REPORTS = `
mutation delete_moderator_report(
  $user_id: String!
) {
  delete_user_reports(
    where: {
      user_id: {_eq: $user_id}
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_DELETE_USER_REPORT = `
mutation delete_user_report(
  $id: Int!
) {
  delete_user_reports(
    where: {
      id: {_eq: $id}
    }
  ) {
    affected_rows
  }
}`;

export const MUTATION_UPDATE_USER_REPORT = `
mutation update_user_report(
  $id: Int!,
  $approved: Boolean!,
  $approved_by: String!,
  $approved_at: timestamptz!
) {
  update_user_reports(
    where: {
      id: {_eq: $id}
    }, 
    _set: {
      approved: $approved,
      approved_by: $approved_by,
      approved_at: $approved_at
    }
  ) {
    affected_rows
  }
}`;
