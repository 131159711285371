import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { 
  Grid, 
  Typography,
  List,
  ListItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MetaTags from "react-meta-tags";
import { ToastContainer } from "react-toastify";
import { geolocated } from "react-geolocated";
import { withFirebase } from "services";
import { withAuthentication } from "session";
import {
  CleanairAppBar,
  Location,
  MapUserpost,
  MapBottomNavBar,
  DlgPost,
  DlgLoginConfirm,
  WaitingSpinner,
  ArticleList,
} from "components";
import { 
  MapTabs, 
  MapView,
} from "./components";
import * as ROUTES from "constants/routes";
import { GraphqlService, Mixpanel } from "services";
import {
  CONF_LOCATION_TYPES,
  LOCATION_TYPE_ALL,
  MASK_ALL,
  AIR_QUALITY_BAD,
  AIR_QUALITY_GOOD,
  AIR_QUALITY_MEDIUM,
  MAP_VIEW_ALL,
  MAP_VIEW_90D,
  AIR_QUALITY_NORMAL,
} from "constants/maplocation";
import { 
  MAX_ARTICLE_WIDTH,
  MAX_CARD_WIDTH,
  TAB_MAP_FEED,
  TAB_MAP_READINGS,
  TAB_MAP_NEWS,
  TAB_MAP_SCHOOLS,
  TAB_MAP_SPORTS,
  GRAPHQL_ERROR,
  TAB_FEED,
} from "constants/types";
import {
  closeThread,
  deleteAllComments,
  deleteArticle,
  getArticle,
  getAuthToken,
  getMainInfo,
  isBannedPosts,
  isLocationModerator,
  isModeratedLocation,
  moderateLocation,
  moderateRegion,
  reportArticle,
  reportLocation,
  repostArticle,
  resignLocation,
  saveArticle,
  suspendUser,
  upvoteArticle,
} from "dataapis";
import { ToastSuccess, ToastInfo, ToastError } from "utility/toast";
import { get_air_quality } from "utility/cleanair";
import { logger } from "utility/logging";
import * as MIXPANEL_EVENTS from "constants/mixpanel";
import { resizeImageFile } from "utility/resizeimage";
import { get_link_source, moderate_image } from "utility/ravenapi";
import { get_hash, summarize_text } from "utility/utils";
import { ARTICLE_BRANCH_MAPPOST, ARTICLE_BRANCH_NEWSPAPER, BRANCH_ALL } from "constants/branches";
import { ACTIVITY_ADD, ACTIVITY_DELETE, ACTIVITY_TYPE_CLEANAIRMAP } from "constants/activity";
import { 
  SUSPENSION_ALL_1D,
  SUSPENSION_ALL_7D,
  SUSPENSION_ALL_PERM 
} from "constants/user";
import { ALL } from "constants/country";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 1100,
  },
  loctypelabel: {
    position: "absolute",
    top: 130,
    left: `calc(50% - 44px)`,
    padding: 4,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: "black",
    borderRadius: 16,
    zIndex: 1,
  },
  loctypetxt: {
    fontSize: "16px",
    fontWeight: 200,
    lineHeight: "16px",
    color: "white",
  },
  mapview: {},
  tabcontainer: {
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  listitem: {
    padding: 0,
  },
  spacing: {
    height: "80px",
    minHeight: "80px",
  },
  addbutton: {
    position: "fixed",
    cursor: "pointer",
    bottom: theme.spacing(5),
    width: 60,
    height: 60,
    zIndex: 1200,
  },
});

class CleanAirMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      map_view_mode: MAP_VIEW_ALL,
      notifications: 0,
      loginDlg: false,
      postDlg: false,
      postEditDlg: false,
      article_edit: null,
      mask: MASK_ALL,
      locationtype: LOCATION_TYPE_ALL,
      maptags: [],
      selectedtags: [],
      qualities: [AIR_QUALITY_GOOD, AIR_QUALITY_MEDIUM, AIR_QUALITY_BAD,AIR_QUALITY_NORMAL],
      selected_locations: [],
      searched_location: null,
      mapposts: [],
      showLoctype: true, // true: Fade In, false: Fade Out
      searchedLocation: [],
    };
    this.map_change_timeout = 0;   // update locations if user doesn't change map for 3 seconds

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleHelp = this.handleHelp.bind(this);
    this.handleLeaderboards = this.handleLeaderboards.bind(this);
    this.handleComments = this.handleComments.bind(this);
    this.handleProfileMenu = this.handleProfileMenu.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleChangeMapTab = this.handleChangeMapTab.bind(this);

    this.handleNeedMore = this.handleNeedMore.bind(this);
    this.handleReportLocation = this.handleReportLocation.bind(this);
    this.handleModerateLocation = this.handleModerateLocation.bind(this);
    this.handleModerateRegion = this.handleModerateRegion.bind(this);
    this.handleResignLocation = this.handleResignLocation.bind(this);

    this.handleClickLocation = this.handleClickLocation.bind(this);
    this.handleClickSearchResult = this.handleClickSearchResult.bind(this);
    this.handleClickMap = this.handleClickMap.bind(this);
    this.handleChangeMap = this.handleChangeMap.bind(this);
    this.handleChangeMapViewMode = this.handleChangeMapViewMode.bind(this);
    this.handleEditLocation = this.handleEditLocation.bind(this);

    this.handleChangeMask = this.handleChangeMask.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeTag = this.handleChangeTag.bind(this);
    this.handleSelectSearchResult = this.handleSelectSearchResult.bind(this);

    this.showAddPostDlg = this.showAddPostDlg.bind(this);
    this.closePostDlg = this.closePostDlg.bind(this);
    this.handleSubmitPost = this.handleSubmitPost.bind(this);

    this.handleReportPost = this.handleReportPost.bind(this);
    this.handleClickPost = this.handleClickPost.bind(this);
    this.handleClickUpvotePost = this.handleClickUpvotePost.bind(this);
    this.handleClickCommentPost = this.handleClickCommentPost.bind(this);
    this.handleClickRepostPost = this.handleClickRepostPost.bind(this);

    this.handleDeletePost = this.handleDeletePost.bind(this);
    this.handleSuspend1d = this.handleSuspend1d.bind(this);
    this.handleSuspend7d = this.handleSuspend7d.bind(this);
    this.handleSuspendPerm = this.handleSuspendPerm.bind(this);
    this.handleCloseComments = this.handleCloseComments.bind(this);
    this.handleDeleteComments = this.handleDeleteComments.bind(this);

    this.handleNeedMoreArticles = this.handleNeedMoreArticles.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handleSelectGroupArticle = this.handleSelectGroupArticle.bind(this);
    this.handleReportArticle = this.handleReportArticle.bind(this);
    this.handleEditArticle = this.handleEditArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleSaveArticle = this.handleSaveArticle.bind(this);
    this.handleDeleteSavedArticle = this.handleDeleteSavedArticle.bind(this);
    this.handleClickSource = this.handleClickSource.bind(this);
    this.handleClickFeed = this.handleClickFeed.bind(this);
    this.handleClickUpvote = this.handleClickUpvote.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleClickRepost = this.handleClickRepost.bind(this);
  }

  setError = (message) => {
    ToastError(message);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const { map } = this.props;

    window.scroll(0, 0);

    await getMainInfo();

    if (this.props.maptab === TAB_MAP_READINGS) {
      await this.getLocations(map.center_lng, map.center_lat, map.bounds);
    } else if (this.props.maptab === TAB_MAP_FEED) {
      await this.getArticlesOfFirstPage();
    }

    // get notifications if there are moderated locations
    const notifications = this.getNotifications();
    this.setState({
      ...this.state,
      notifications: notifications,
    });
  };

  handleLogin = () => {
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  getLocations = async (center_lng, center_lat, bounds) => {
    const { map_view_mode } = this.state;

    let lng_min = null;
    let lng_max = null;
    let lat_min = null;
    let lat_max = null;
    if (!bounds) {
      lng_min = center_lng - 0.05;
      lng_max = center_lng + 0.05;
      lat_min = center_lat - 0.05;
      lat_max = center_lat + 0.05;
    } else {
      lng_min = bounds[0];
      lat_min = bounds[1];
      lng_max = bounds[2];
      lat_max = bounds[3];
    }
    logger.log("box :", lng_min, lng_max, lat_min, lat_max);

    const gqlservice = new GraphqlService();
    if (map_view_mode === MAP_VIEW_ALL) {
      await gqlservice
        .map_locations_by_box(lng_min, lng_max, lat_min, lat_max)
        .then(
          (result) => {
            const locations = result.data.locations;
            const regions = locations
              .map((location) => location.region)
              .filter(
                (location, index, a) =>
                  a.findIndex((location2) => location2.id === location.id) ===
                  index
              );
            logger.log("regions & locations :", regions, locations);
            if (regions.length > 0) {
              this.props.setMapRegions(regions);
            }
            if (locations.length > 0) {
              this.props.setMapLocations(locations);
              this.setState({
                ...this.state,
                selected_locations: locations,
              });
            }
          },
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });
    } else {
      // show only previous 90 day's log
      const timestamp = new Date() - 86400 * 90 * 1000;
      const dday = new Date(timestamp).toISOString();
      await gqlservice
        .map_locations_by_box_dday(lng_min, lng_max, lat_min, lat_max, dday)
        .then(
          (result) => {
            const locations = result.data.locations;
            const regions = locations
              .map((location) => location.region)
              .filter(
                (location, index, a) =>
                  a.findIndex((location2) => location2.id === location.id) ===
                  index
              );
            logger.log("regions & locations :", regions, locations);
            if (regions.length > 0) {
              this.props.setMapRegions(regions);
            }
            if (locations.length > 0) {
              this.props.setMapLocations(locations);
              this.setState({
                ...this.state,
                selected_locations: locations,
              });
            }
          },
          (reason) => {
            this.setError(reason.msg);
          }
        )
        .catch((err) => {
          this.setError(JSON.stringify(err));
        });
    }
  };

  getNotifications = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      return 0;
    }
    const { selected_locations } = this.state;
    if (selected_locations.length === 0) {
      return;
    }

    let notifications = 0;
    for (let location of selected_locations) {
      if (isModeratedLocation(location)) {
        notifications += location.notifications;
      } 
    }
    return notifications;
  };

  getMapPosts = async () => {
    const { regions } = this.props;
    if (regions.length === 0) {
      return;
    }

    logger.log("getMapPosts regions :", regions);
    const gqlservice = new GraphqlService();
    let posts = [];
    let result = null;
    for (let region of regions) {
      result = await gqlservice.map_posts_by_region(region.id);
      if (result.status_code === GRAPHQL_ERROR) {
        this.setError(result.msg);
        break;
      }
      if (result.data?.articles?.length > 0) {
        logger.log("region posts :", region, result.data.articles);
        posts = posts.concat(result.data.articles);
      }
    }

    this.setState({
      ...this.state,
      mapposts: posts
    });
  }

  handleNavBack = () => {
    // this.props.history.goBack();
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleHelp = () => {
    const location = {
      pathname: ROUTES.CLEANAIRMAP_TUTORIAL,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleLeaderboards = () => {
    Mixpanel.track(MIXPANEL_EVENTS.MAP_LEADER_BOARDS);
    const location = {
      pathname: ROUTES.CLEANAIRMAP_LEADERBOARDS,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleComments = () => {
    Mixpanel.track(MIXPANEL_EVENTS.MAP_COMMENTS);
    const location = {
      pathname: ROUTES.CLEANAIRMAP_COMMENTS,
      state: { animation: "right" },
    };
    this.props.history.push(location);
  };

  handleProfileMenu = (route) => {
    Mixpanel.track(MIXPANEL_EVENTS.APP_ICONS, { type: "Profile" });
    const location = {
      pathname: route,
      state: { animation: "left" },
    };
    if (route === ROUTES.SIGN_IN) {
      this.props.setLoginBackRoute(ROUTES.CLEANAIRMAP);
    }
    this.props.history.push(location);
  };

  handleSignOut = async () => {
    await this.props.firebase.doSignOut();
    await this.props.signOut();
  };

  handleChangeMapTab = async (tab_value) => {
    const { map } = this.props;

    this.props.selectMapTab(tab_value);
    this.props.initScrollPos();

    if (tab_value === TAB_MAP_READINGS) {
      await this.getLocations(map.center_lng, map.center_lat, map.bounds);
    } else if (tab_value === TAB_MAP_FEED) {
      if (this.props.map_articles.length === 0) {
        await this.getArticlesOfFirstPage();
      }
    } else if (tab_value === TAB_MAP_NEWS) {
    } else if (tab_value === TAB_MAP_SCHOOLS) {
    } else if (tab_value === TAB_MAP_SPORTS) {
    } else {
    }
  };

  handleClickLocation = (location) => {
    this.props.selectMapLocation(location);
    Mixpanel.track(MIXPANEL_EVENTS.MAP_LOCATION, location);
    let route = `/${ROUTES.CLEANAIRMAP_PREFIX}/${location.slug}-${location.id}`;
    if (isLocationModerator(location)) {
      route = `/${ROUTES.MODERATION_PREFIX}/${ROUTES.CLEANAIRMAP_PREFIX}/${location.slug}-${location.id}`;
    }
    this.props.history.push({
      pathname: route,
      state: { animation: "left" },
    });
  };

  handleClickSearchResult = (position) => {
    const { locations } = this.props;
    const exist_location = locations.find(
      (item) => item.name === position.name && item.address === position.address
    );

    if (position.latitude && position.longitude) {
      this.props.setMapCenterPos(position.longitude, position.latitude);
    }

    if (exist_location) {
      this.handleClickLocation(exist_location);
    } else {
      this.handleClickMap({
        lat: position.latitude,
        lng: position.longitude,
      });
    }
  };

  handleClickMap = (coordinate) => {
    const { loggedIn } = this.props;

    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.props.setLocationGeoPos(coordinate.lng, coordinate.lat);

    this.props.history.push({
      pathname: ROUTES.CLEANAIRMAP_ADD,
      state: { animation: "left" },
    });
  };

  handleChangeMap = async (lng, lat, zoom, bounds) => {
    this.props.setMapParams(lng, lat, zoom, bounds);
    // const mapParams = {
    //   center_lng: lng,
    //   center_lat: lat,
    //   zoom: zoom,
    //   bounds: bounds
    // };
    // localStorage.setItem('mapParams', JSON.stringify(mapParams));

    if (this.map_change_timeout) {
      clearTimeout(this.map_change_timeout);
    }

    this.map_change_timeout = setTimeout(async () => {
      await this.getLocations(lng, lat, bounds);
      await this.getMapPosts();      
    }, 3000);
  };

  handleChangeMapViewMode = async () => {
    const { map_view_mode } = this.props;
    let new_mode = map_view_mode === MAP_VIEW_ALL ? MAP_VIEW_90D : MAP_VIEW_ALL;
    logger.log("new map view mode :", new_mode);
    this.setState(
      {
        ...this.state,
        map_view_mode: new_mode,
      },
      this.forceUpdate()
    );
    this.props.setMapViewMode(new_mode);
  };

  handleEditLocation = (location) => {
    this.props.selectMapLocation(location);

    let route = `/${ROUTES.CLEANAIRMAP_PREFIX}/${location.slug}-${location.id}/edit`;
    this.props.history.push({
      pathname: route,
      state: { animation: "left" },
    });
  };

  handleNeedMore = () => {};

  handleSelectSearchResult = async (position) => {
    const { locations } = this.props;
    const exist_location = locations.find(
      (item) => item.name === position.name && item.address === position.address
    );

    this.setState({
      ...this.state,
      searched_location: exist_location !== undefined ? exist_location : null
    });
  };

  handleReportLocation = async (location, reportMsg) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }

    this.setWaiting(true);
    await reportLocation(location, reportMsg);
    this.setWaiting(false);
    ToastSuccess("The Report was added");
  };

  handleModerateLocation = async (location) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }

    let moderator = authUser.locations_moderated.find(
      (locaiton_moderator) => locaiton_moderator.location_id === location.id
    );

    if (moderator) {
      if (moderator.approved) {
        ToastInfo("This location has already moderated");
      } else {
        ToastInfo(
          "You've already registered to moderate this location, wait for approve from location moderator."
        );
      }
      return;
    }
    this.setWaiting(true);
    await moderateLocation(location);
    this.setWaiting(false);
    ToastSuccess("Moderation requested, wait for approve");
  };

  handleModerateRegion = async (region) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn) {
      this.handleLogin();
      return;
    }

    // check if the user is the moderator of this region
    let moderator = authUser.regions_moderated.find(
      (region_moderator) => region_moderator.region_id === region.id
    );
    if (moderator) {
      if (moderator.approved) {
        ToastInfo("This region has already moderated");
      } else {
        ToastInfo(
          "You've already registered to moderate this region, wait for approve from region moderator."
        );
      }
      return;
    }

    this.setWaiting(true);
    await moderateRegion(region);
    this.setWaiting(false);
    ToastSuccess("Moderation requested, wait for approve");
  };

  handleResignLocation = async (location) => {
    this.setWaiting(true);
    await resignLocation(location);
    this.setWaiting(false);
    ToastSuccess("Moderation resigned");
  };

  handleChangeQualities = (qualities) => {
    this.setState({
      ...this.state,
      qualities: qualities,
    });
  };

  handleChangeMask = (mask) => {
    if (mask !== this.state.mask) {
      this.setState({
        ...this.state,
        mask: mask,
      });
    }
  };

  handleChangeType = async (locationtype) => {
    if (locationtype === this.state.locationtype) {
      return;
    }

    window.clearInterval(window.mapInterval);

    await this._getMaptags(locationtype);

    this.setState(
      {
        ...this.state,
        locationtype: locationtype,
        showLoctype: true,
      },
      () => {
        window.mapInterval = setInterval(() => {
          this.setState({
            ...this.state,
            showLoctype: false,
          });
        }, 3000);
        return () => clearInterval(window.mapInterval);
      }
    );
  };

  _getMaptags = async (loctype) => {
    const gqlservice = new GraphqlService();
    gqlservice
      .maptags_by_category(loctype)
      .then(
        (result) => {
          const maptags_category = result.data.maptag_category;
          if (maptags_category.length > 0) {
            // const maptags = maptags_category.map(item => item.tag_name);
            this.setState({
              ...this.state,
              maptags: maptags_category,
              selectedtags: [],
            });
          } else {
            this.setState({
              ...this.state,
              maptags: [],
              selectedtags: [],
            });
          }
        },
        (reason) => {
          this.setError(reason.msg);
          return;
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
        return;
      });
  };

  handleChangeTag = (tag) => {
    const { selectedtags } = this.state;
    let new_selectedtags = selectedtags.slice();
    let selected = selectedtags.find((item) => item.id === tag.id);
    if (!selected) {
      // select
      new_selectedtags.push(tag);
    } else {
      // unselect
      new_selectedtags = selectedtags.filter((item) => item.id !== tag.id);
    }

    this.setState({
      ...this.state,
      selectedtags: new_selectedtags,
    });
  };

  showAddPostDlg = () => {
    const { loggedIn } = this.props;

    if (loggedIn) {
      if (isBannedPosts()) {
        ToastError("You've suspended for post operation.");
        return;
      }

      this.setState({
        ...this.state,
        postDlg: true
      });
    } else {
      this.setState({
        ...this.state,
        loginDlg: true
      });
    }
  };

  closePostDlg = () => {
    this.setState({
      ...this.state,
      postDlg: false
    });
  };

  handleSubmitPost = async (imageUpload, videoUpload, description, postlink) => {
    this.setState({
      ...this.state,
      postDlg: false
    });

    const { regions } = this.props;
    if (regions.length === 0) {
      this.setError("There isn't any locations in this map.");
      return;
    }

    const selected_region = regions[0];

    this.setWaiting(true);

    let new_image = "";
    if (imageUpload) {
      const resized_image = await resizeImageFile(imageUpload);
      const result = await this.props.firebase.uploadImage(resized_image, "posts");
      if (result.error) {
        this.setError("Failed to upload image.");
        return;
      }
      new_image = result.url;
      const modresult = moderate_image(new_image);
      logger.log("image moderation result :", modresult);
      if (modresult && modresult.result) {
        this.setError("Image not allowed, because it contains adults or racy content.");
        await this.props.firebase.deleteImage(new_image);
        return;
      }
    }

    let video = "";
    if (videoUpload) {
      const result = await this.props.firebase.uploadVideo(videoUpload, "videocasts");
      if (result.error) {
        this.setError("Failed to upload video.");
        return;
      }
      video = result.url;
    }

    let preview = null;
    if (postlink) {
      preview = await get_link_source(postlink);
    }

    const { loggedIn, authUser } = this.props;

    let title = "Anonymous User";
    if (loggedIn) {
      title = authUser.biography;
      if (!title) {
        title = authUser.name;
      }
    }

    let nid = "";
    const uid = loggedIn ? authUser.uid : "anonymous";
    if (postlink) {
      nid = get_hash(selected_region.id.toString() + uid + postlink);
    } else {
      nid = get_hash(selected_region.id.toString() + uid + description);
    }

    const gqlservice = new GraphqlService();

    // check if there is already article
    let result = await gqlservice.article_by_nid(nid);
    if (result.status_code !== GRAPHQL_ERROR && result.data.articles.length > 0) {
      this.setError("This post was already posted");
      return;
    }

    const summary = summarize_text(description, 150);
    const published = new Date().getTime() / 1000;
    const mappost = {
      nid: nid,
      source_id: null,
      author: loggedIn ? authUser.username : "Anonymous User",
      title: title,
      summary: summary,
      text: description,
      image: new_image,
      media_url: video,
      branch: ARTICLE_BRANCH_MAPPOST,
      url: "",
      author_image: loggedIn ? authUser.image : '',
      published: Math.floor(published),
      param1: 1,                    // approved
      param2: 0,                    // moderator
      param3: selected_region.id,   // region id
      txt_param1: loggedIn ? authUser.uid : null, // user id
      link_preview: preview,
    }

    if (loggedIn) {
      const token = await getAuthToken();
      if (!token) {
        this.setWaiting(false);
        this.handleLogin();
        return;
      }
      gqlservice.set_auth_jwt(token);
    }

    result = await gqlservice.insert_userpost(mappost);
    if (result.status_code === GRAPHQL_ERROR) {
      this.setError(result.msg);
      return;
    }
    logger.log("insert map post result :", result);
    const articles = result.data.insert_articles.returning;
    if (articles.length > 0) {
      let posts = this.state.mapposts.slice();
      posts.push(articles[0]);
      this.setState({
        ...this.state,
        mapposts: posts
      });
    }

    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_CLEANAIRMAP,
      type_id: `${selected_region.id}`,
      action: ACTIVITY_ADD,
      object: `the post ${mappost.nid}`,
      fromto: `to the region ${selected_region.slug}`,
      reason: "",
    };

    result = await gqlservice.insert_activitylog(activity);
    if (result.status_code === GRAPHQL_ERROR) {
      this.setError(result.msg);
      return;
    }

    this.setWaiting(false);
  }

  _getLocations2show = (locations, view_mode, qualities, mask, locationtype, tags) => {
    let locations2show = locations.slice();

    // filter by mode
    if (view_mode !== MAP_VIEW_ALL) {
      const dt = new Date() - 86400 * 90 * 1000;
      locations2show = locations.filter(
        (location) => Date.parse(location.created_at) >= dt
      );
    }

    // filter by air quality
    locations2show = locations2show.filter((location) => {
      const air_quality = get_air_quality(location);
      if (air_quality < 0) {
        return false;
      }
      return qualities.find((quality) => quality === air_quality) !== undefined;
    });

    // filter by mask
    if (mask !== MASK_ALL) {
      locations2show = locations2show.filter((location) => {
        try {
          const reading = location.readings.reduce((prev, current) => {
            return prev.id > current.id ? prev : current;
          });
          return reading.mask === mask;
        } catch (err) {
          return false;
        }
      });
    }

    // filter by location type
    if (locationtype !== LOCATION_TYPE_ALL) {
      locations2show = locations2show.filter(
        (location) => location.type === locationtype
      );
    }

    // filter by tags
    if (tags.length > 0) {
      const tag_ids = tags.map((tag) => tag.id);
      locations2show = locations2show.filter((location) =>
        tag_ids.includes(location.tag_id)
      );
    }

    return locations2show;
  }

  _sortLocationAndMapposts = (locations, mapposts) => {
    let items = locations.concat(mapposts);
    for (let item of items) {
      if (item.nid === undefined) {
        item.ts = Date.parse(item.created_at);
        item.ts = isNaN(item.ts) ? 0 : Math.floor(item.ts / 1000);
      } else {
        item.ts = item.published;
      }
    }
    items.sort((a, b) => b.ts - a.ts);
    return items;
  }

  handleClickPost = (post) => {
    this.props.selectMapPost(post);

    const location = {
      pathname: `/${ROUTES.CLEANAIRMAP_PREFIX}/${ROUTES.ARTICLE_PREFIX}/${post.nid}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(
      ROUTES.CLEANAIRMAP
    );
  }

  handleReportPost = async (post, reportMsg) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await reportArticle(post, reportMsg);
    this.setWaiting(false);
  }

  handleClickUpvotePost = async (post) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await upvoteArticle(post);
    this.setWaiting(false);
  }

  handleClickCommentPost = (post) => {
    this.handleClickPost(post);
  }

  handleClickRepostPost = async (post) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await repostArticle(post);
    this.setWaiting(false);
  }

  handleDeletePost = async (post) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }
    
    if (isBannedPosts()) {
      this.setError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    const postAfterDelete = this.state.mapposts.filter((mappost)=>mappost.nid !== post.nid ) 
    this.setState({
      ...this.state,
      mapposts: postAfterDelete
    });
    await deleteArticle(post);
    this.setWaiting(false);
  }

  handleDeleteLocation = async (location) =>{
    console.log("location inside :",location)
    const { authUser } = this.props;

    const gqlservice = new GraphqlService();
    const token = await getAuthToken();
    if (!token) {
      this.handleLogin();
      return;
    }
    gqlservice.set_auth_jwt(token, true);

    // this.setWaiting(true);

    await gqlservice
      .delete_map_location(location.id)
      .then((result) => {
        if (result.data.delete_locations.affected_rows > 0) {
          const newLocationAfterDelete = this.state.selected_locations.filter((item)=>item.id!==location.id)
          this.props.deleteMapLocation(location.id)
          this.setState({
            ...this.state,
            selected_locations: newLocationAfterDelete,
          });
          ToastSuccess("Location deleted");
        } else {
          this.setError("Failed to delete location");
          return;
        }
      })
      .then((result) => {
        // if (result.data.locations.length > 0) {
        //   // this.props.selectMapLocation(result.data.locations[0]);
        // }
        // ToastSuccess("Location updated successfully");
      }, (reason) => {
        this.setError(reason.msg);
        return;
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
        return;
      });

    // log this activity
    gqlservice.set_auth_jwt(token, false);
    const activity = {
      user_id: authUser.uid,
      type: ACTIVITY_TYPE_CLEANAIRMAP,
      type_id: location.id.toString(),
      action: ACTIVITY_DELETE,
      object: `the location ${location.name}`,
      fromto: `of cleanairmap`,
      reason: ''
    };
    await gqlservice
      .insert_activitylog(activity)
      .then(result => {}, reason => {
        this.setError(reason.msg);
      })
      .catch(err => {
        this.setError(JSON.stringify(err));
      }); 

    this.setWaiting(false);

  }

  handleSuspend1d = async (post) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await deleteArticle(post);
    if (post.txt_param1) {
      await suspendUser(post.txt_param1, SUSPENSION_ALL_1D, true);
    }
    this.setWaiting(false);
  }

  handleSuspend7d = async (post) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await deleteArticle(post);
    if (post.txt_param1) {
      await suspendUser(post.txt_param1, SUSPENSION_ALL_7D, true);
    }
    this.setWaiting(false);
  }

  handleSuspendPerm = async (post) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await deleteArticle(post);
    if (post.txt_param1) {
      await suspendUser(post.txt_param1, SUSPENSION_ALL_PERM, true);
    }
    this.setWaiting(false);
  }

  handleCloseComments = async (post) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    if (post.threads.length > 0) {
      this.setWaiting(true);
      await closeThread(post.threads[0]);
      this.setWaiting(false);
    }
  }

  handleDeleteComments = async (post) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    if (post.threads.length > 0) {
      this.setWaiting(true);
      await closeThread(post.threads[0]);
      await deleteAllComments(post.threads[0]);
      this.setWaiting(false);
    }
  }

  
  getArticlesOfFirstPage = async () => {
    // const { branch, country } = this.props;

    // if (branch === ARTICLE_BRANCH_NEWSPAPER) {
    //   if (country === ALL) {
    //     await this.getArticlesOfBranchInFirstPage(branch);
    //   } else {
    //     await this.getArticlesOfCountryInFirstPage(country);
    //   }
    // } else {
    //   if (branch === BRANCH_ALL) {
    await this.getArticlesInFirstPage();
    //   } else {
    //     await this.getArticlesOfBranchInFirstPage(branch);
    //   }
    // }
  };

  handleNeedMoreArticles = async () => {
    const { 
      maptab, 
      // country, branch, 
      map_articles_last_offset, requesting 
    } = this.props;
    if (maptab !== TAB_MAP_FEED) {
      return;
    }

    logger.log("need more articles :", map_articles_last_offset);

    // if (map_articles_last_offset === 0) {
    //   this.props.showBottomNavbar(false);
    //   return;
    // }

    if (requesting) {
      return;
    }

    this.setWaiting(true);
    // if (branch === ARTICLE_BRANCH_NEWSPAPER) {
    //   if (country === ALL) {
    //     await this.getArticlesOfBranchInNextPage(branch, map_articles_last_offset);
    //   } else {
    //     await this.getArticlesOfCountryInNextPage(country, map_articles_last_offset);
    //   }
    // } else {
    //   if (branch === BRANCH_ALL) {
    await this.getArticlesInNextPage(map_articles_last_offset);
    //   } else {
    //     await this.getArticlesOfBranchInNextPage(branch, map_articles_last_offset);
    //   }
    // }
    this.setWaiting(false);
  };

  getArticlesInFirstPage = async () => {
    const feed_id = "coronavirus-news-and-updates";

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles(feed_id, 0)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("articles in feed(first page) :", feed_articles);
          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.setMapArticles(articles, articles.length);
            // this.props.showBottomNavbar(true);
          }
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  getArticlesInNextPage = async (last_offset) => {
    const feed_id = "coronavirus-news-and-updates";

    const gqlservice = new GraphqlService();
    await gqlservice
      .feed_articles(feed_id, last_offset)
      .then(
        (result) => {
          const feed_articles = result.data;
          logger.log("articles in sources(next page) :", feed_articles);

          if (feed_articles.length > 0) {
            const articles = feed_articles.map(item => item.article);
            this.props.appendMapArticles(articles, last_offset + articles.length);
          } else {
            this.props.appendMapArticles([], last_offset);
          }

          // this.props.showBottomNavbar(true);
        },
        (reason) => {
          this.setError(reason.msg);
        }
      )
      .catch((err) => {
        this.setError(JSON.stringify(err));
      });
  };

  handleSelectArticle = (article) => {
    if(article.article) {
      article = article.article
    }
    this.props.selectArticle(article);

    const feed_slug = "covid-19-news-and-updates";

    let path = `/${ROUTES.FEEDS_PREFIX}/${feed_slug}/${ROUTES.SOURCE_PREFIX}/${article.source_id}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path += `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    } else {
      path += `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    }

    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.CLEANAIRMAP);
  };

  handleSelectGroupArticle = async (nid) => {
    this.setWaiting(true);
    await getArticle(nid);
    this.setWaiting(false);

    const location = {
      pathname: `/${ROUTES.ARTICLE_NEWS_PREFIX}/${nid}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.CLEANAIRMAP);
  };

  handleReportArticle = async (article, reportMsg) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };

  handleEditArticle = (article) => {}

  handleDeleteArticle = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }
    
    if (isBannedPosts()) {
      ToastError("You've suspended.");
      return;
    }

    this.setWaiting(true);
    await deleteArticle(article);
    this.setWaiting(false);
  }

  handleSaveArticle = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  }

  // empty funciton
  handleDeleteSavedArticle = (article) => {}
  // empty function

  handleClickSource = (source, feed) => {
    this.props.selectSource(source);
    this.props.setSourceBackRoute(`/${ROUTES.FEEDS_PREFIX}/${feed.slug}`);
    const path = `/${ROUTES.FEEDS_PREFIX}/${feed.slug}/${ROUTES.SOURCE_PREFIX}/${source.slug}`;
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);

    this.props.refreshArticles();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickFeed = (feed) => {
    this.props.selectFeed(feed);
    this.props.selectFeedTab(TAB_FEED);
    const location = {
      pathname: `/${ROUTES.FEEDS_PREFIX}/${feed.slug}`,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setFeedBackRoute(ROUTES.CLEANAIRMAP);
    this.props.refreshArticles();
    this.props.refreshThreads();
    this.props.initScrollPos();
    this.props.selectCountry(ALL);
    this.props.selectBranch(BRANCH_ALL);
  };

  handleClickUpvote = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  }

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  }

  handleClickRepost = async (article) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.setState({
        ...this.state,
        loginDlg: true,
      });
      return;
    }

    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  }

  render() {
    const { 
      classes, 
      theme_mode, 
      loggedIn,
      authUser,
      map,
      maptab, 
      locations, 
      map_articles,
      showLoctype,
      requesting 
    } = this.props;
    const {
      loginDlg,
      postDlg,
      mask,
      notifications,
      map_view_mode,
      locationtype,
      maptags,
      selectedtags,
      qualities,
      selected_locations,
      searched_location,
      mapposts,
    } = this.state;

    const locationTypeOfTab = Object.keys(CONF_LOCATION_TYPES).find((key) => {
      return CONF_LOCATION_TYPES[key].value === locationtype;
    });

    let url = "";
    if (typeof window !== "undefined") {
      url = window.location.protocol + "//" + window.location.host;
    }
    url += `${ROUTES.CLEANAIRMAP}`;

    const title = "Raven: CleanAir Map";
    const description =
      "The Clean Air Map is a module to crowdsource air quality measurements to ensure indoor spaces are safe from spread of Covid-19";
    const image = "";

    const locations2show = this._getLocations2show(selected_locations, map_view_mode, qualities, mask, locationtype, selectedtags);
    // const items2show = this._sortLocationAndMapposts(locations2show, mapposts);

    logger.log("Map Params :", map.center_lng, map.center_lat, map.zoom);
    logger.log("Selected locations :", selected_locations);

    const loctypeLabelStyle = showLoctype
      ? { transition: "opacity 0.5s ease" }
      : { opacity: 0, transition: "opacity 1s ease" };

    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const innerWidth =
      width > MAX_ARTICLE_WIDTH
        ? MAX_ARTICLE_WIDTH
        : width;
  
    let addbuttonPos = 0;
    if (width > MAX_ARTICLE_WIDTH) {
      addbuttonPos = MAX_ARTICLE_WIDTH - 96 + (width - MAX_ARTICLE_WIDTH) / 2;
    } else if (innerWidth > MAX_CARD_WIDTH) {
      addbuttonPos = (innerWidth + MAX_CARD_WIDTH) / 2 - 96;
    } else {
      addbuttonPos = innerWidth - 96;
    }

    logger.log("render map articles :", map_articles);

    return (
      <div className={classes.root}>
        <div className="wrapper">
          <MetaTags>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content="Raven App" />
            <meta property="og:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:site" content="Raven App" />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image:src" content={image} />
            <meta property="twitter:image:alt" content={"cleanairmap"} />
            <meta property="twitter:domain" content="ravenapp.org" />
          </MetaTags>
        </div>

        <div className={classes.appbar}>
          <CleanairAppBar
            onNavBack={this.handleNavBack}
            onHelp={this.handleHelp}
            onLeaderboards={this.handleLeaderboards}
            onComments={this.handleComments}
            onProfileMenu={this.handleProfileMenu}
            onSignOut={this.handleSignOut}
          />
        </div>

        <MapTabs
          theme_mode={theme_mode}
          onChangeMaptab={this.handleChangeMapTab}
        />

        {locationtype !== LOCATION_TYPE_ALL && (
          <div className={classes.loctypelabel} style={loctypeLabelStyle}>
            {locationTypeOfTab ? (
              <Typography className={classes.loctypetxt}>
                {CONF_LOCATION_TYPES[locationTypeOfTab].name}
              </Typography>
            ):(
              <></>
            )}
          </div>
        )}

        <div className={classes.mapview}>
          <MapView
            theme_mode={theme_mode}
            center_pos={[map.center_lng, map.center_lat]}
            zoom={map.zoom}
            map_view_mode={map_view_mode}
            locations={locations}
            notifications={notifications}
            mask={mask}
            locationtype={locationtype}
            tags={selectedtags}
            onClick={this.handleClickMap}
            onChangeMap={this.handleChangeMap}
            onChangeMapViewMode={this.handleChangeMapViewMode}
            onChangeQualities={this.handleChangeQualities}
            // onClickLocation={this.handleEditLocation}
            onClickLocation={this.handleClickLocation}
            onSelectSearchResult={this.handleSelectSearchResult}
            onClickSearchResult={this.handleClickSearchResult}
          />
        </div>


        {maptab === TAB_MAP_READINGS && (
          <div className={classes.tabcontainer}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                {locations2show.length > 0 &&
                  <List component="location-list" aria-label="location list">
                    {locations2show.map((item, index) => (
                      <ListItem className={classes.listitem} key={`item-${index}`}>
                        <Location
                          location={item}
                          selected={searched_location && searched_location.id === item.id}
                          onLogin={this.handleLogin}
                          onReport={this.handleReportLocation}
                          onModerate={this.handleModerateLocation}
                          onModerateRegion={this.handleModerateRegion}
                          onEdit={this.handleEditLocation}
                          onResign={this.handleResignLocation}
                          onReadMore={this.handleClickLocation}
                          onDelete={this.handleDeleteLocation}
                        />
                      </ListItem>
                    ))}
                  </List>
                }
              </Grid>
            </Grid>
          </div>
        )}

        {maptab === TAB_MAP_FEED && (
          <div className={classes.tabcontainer}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                {mapposts.length > 0 &&
                  <List component="location-list" aria-label="location list">
                    {mapposts.map((item, index) => (
                      <ListItem className={classes.listitem} key={`item-${index}`}>
                        <MapUserpost
                          theme={theme_mode}
                          loggedIn={loggedIn}
                          authUser={authUser}
                          article={item}
                          onReport={this.handleReportPost}
                          onLogin={this.handleLogin}
                          onClickPost={this.handleClickPost}
                          onClickUpvote={this.handleClickUpvotePost}
                          onClickComment={this.handleClickCommentPost}
                          onClickRepost={this.handleClickRepostPost}
                          onDelete={this.handleDeletePost}
                          onSuspned1d={this.handleSuspend1d}
                          onSuspend7d={this.handleSuspend7d}
                          onSuspendPerm={this.handleSuspendPerm}
                          onCloseComments={this.handleCloseComments}
                          onDeleteComments={this.handleDeleteComments}
                        />
                      </ListItem>
                    ))}
                  </List>
                }
              </Grid>

              <Grid item>
                {map_articles && map_articles.length > 0 && (
                  <ArticleList
                    articles={map_articles}
                    aiSummary={[]}
                    pins={[]}
                    movetops={[]}
                    onNeedMore={this.handleNeedMoreArticles}
                    onSelectArticle={this.handleSelectArticle}
                    onSelectGroupArticle={this.handleSelectGroupArticle}
                    onNeedLogin={this.handleLogin}
                    onReport={this.handleReportArticle}
                    onEdit={this.handleEditArticle}
                    onDelete={this.handleDeleteArticle}
                    onSave={this.handleSaveArticle}
                    onDeleteSaved={this.handleDeleteSavedArticle}
                    onClickSource={this.handleClickSource}
                    onClickFeed={this.handleClickFeed}
                    onClickUpvote={this.handleClickUpvote}
                    onClickComment={this.handleClickComment}
                    onClickRepost={this.handleClickRepost}
                    onClickArticle={this.handleSelectArticle} 
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}

        {maptab === TAB_MAP_NEWS && (
          <div className={classes.tabcontainer}>
          </div>
        )}

        {maptab === TAB_MAP_SCHOOLS && (
          <div className={classes.tabcontainer}>
          </div>
        )}

        {maptab === TAB_MAP_SPORTS && (
          <div className={classes.tabcontainer}>
          </div>
        )}

        <div className={classes.spacing} />

        {maptab === TAB_MAP_FEED && (
          <div>
            <DlgPost 
              open={postDlg}
              onSubmit={this.handleSubmitPost}
              onClose={this.closePostDlg}
            />
            <div onClick={this.showAddPostDlg}>
              <img
                className={classes.addbutton}
                style={{ left: addbuttonPos, bottom: 96 }}
                alt={"addpost"}
                src={`/static/images/icons/${theme_mode}/add.png`}
              />
            </div>
          </div>
        )}

        <MapBottomNavBar
          show={true}
          mask={mask}
          locationtype={locationtype}
          maptags={maptags}
          selectedtags={selectedtags}
          onChangeMask={this.handleChangeMask}
          onChangeType={this.handleChangeType}
          onChangeTag={this.handleChangeTag}
        />

        <ToastContainer />
        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        <WaitingSpinner open={requesting} />
      </div>
    );
  }
}

CleanAirMap.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  map: state.sessionState.map,
  theme_mode: state.uiState.theme_mode,
  requesting: state.uiState.requesting,
  maptab: state.mapState.maptab,
  map_view_mode: state.mapState.map_view_mode,
  locations: state.mapState.locations,
  regions: state.mapState.regions,
  map_articles: state.mapState.map_articles,
  map_articles_last_offset: state.mapState.map_articles_last_offset,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  geolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CleanAirMap);
