export const EMOJI_FLAGS = [
    { emoji: "🇦🇩", image: "ad.png", country: "Andorra" },
    { emoji: "🇦🇪", image: "ae.png", country: "United Arab Emirates" },
    { emoji: "🇦🇫", image: "af.png", country: "Afghanistan" },
    { emoji: "🇦🇬", image: "ag.png", country: "Antigua & Barbuda" },
    { emoji: "🇦🇮", image: "ai.png", country: "Anguilla" },
    { emoji: "🇦🇱", image: "al.png", country: "Albania" },
    { emoji: "🇦🇲", image: "am.png", country: "Armenia" },
    { emoji: "🇦🇴", image: "ao.png", country: "Angola" },
    { emoji: "🇦🇶", image: "aq.png", country: "Antarctica" },
    { emoji: "🇦🇷", image: "ar.png", country: "Argentina" },
    { emoji: "🇦🇸", image: "as.png", country: "American Samoa" },
    { emoji: "🇦🇹", image: "at.png", country: "Austria" },
    { emoji: "🇦🇺", image: "au.png", country: "Australia" },
    { emoji: "🇦🇼", image: "aw.png", country: "Aruba" },
    { emoji: "🇦🇽", image: "ax.png", country: "Åland Islands" },
    { emoji: "🇦🇿", image: "az.png", country: "Azerbaijan" },
    { emoji: "🇧🇦", image: "ba.png", country: "Bosnia & Herzegovina" },
    { emoji: "🇧🇧", image: "bb.png", country: "Barbados" },
    { emoji: "🇧🇩", image: "bd.png", country: "Bangladesh" },
    { emoji: "🇧🇪", image: "be.png", country: "Belgium" },
    { emoji: "🇧🇫", image: "bf.png", country: "Burkina Faso" },
    { emoji: "🇧🇬", image: "bg.png", country: "Bulgaria" },
    { emoji: "🇧🇭", image: "bh.png", country: "Bahrain" },
    { emoji: "🇧🇮", image: "bi.png", country: "Burundi" },
    { emoji: "🇧🇯", image: "bj.png", country: "Benin" },
    { emoji: "🇧🇱", image: "bl.png", country: "St. Barthélemy" },
    { emoji: "🇧🇲", image: "bm.png", country: "Bermuda" },
    { emoji: "🇧🇳", image: "bn.png", country: "Brunei" },
    { emoji: "🇧🇴", image: "bo.png", country: "Bolivia" },
    { emoji: "🇧🇶", image: "bq.png", country: "Caribbean Netherlands" },
    { emoji: "🇧🇷", image: "br.png", country: "Brazil" },
    { emoji: "🇧🇸", image: "bs.png", country: "Bahamas" },
    { emoji: "🇧🇹", image: "bt.png", country: "Bhutan" },
    { emoji: "🇧🇻", image: "bv.png", country: "Bouvet Island" },
    { emoji: "🇧🇼", image: "bw.png", country: "Botswana" },
    { emoji: "🇧🇾", image: "by.png", country: "Belarus" },
    { emoji: "🇧🇿", image: "bz.png", country: "Belize" },
    { emoji: "🇨🇦", image: "ca.png", country: "Canada" },
    { emoji: "🇨🇨", image: "cc.png", country: "Cocos (Keeling) Islands" },
    { emoji: "🇨🇩", image: "cd.png", country: "Congo - Kinshasa" },
    { emoji: "🇨🇫", image: "cf.png", country: "Central African Republic" },
    { emoji: "🇨🇬", image: "cg.png", country: "Congo - Brazzaville" },
    { emoji: "🇨🇭", image: "ch.png", country: "Switzerland" },
    { emoji: "🇨🇮", image: "ci.png", country: "Côte d’Ivoire" },
    { emoji: "🇨🇰", image: "ck.png", country: "Cook Islands" },
    { emoji: "🇨🇱", image: "cl.png", country: "Chile" },
    { emoji: "🇨🇲", image: "cm.png", country: "Cameroon" },
    { emoji: "🇨🇳", image: "cn.png", country: "China" },
    { emoji: "🇨🇴", image: "co.png", country: "Colombia" },
    { emoji: "🇨🇷", image: "cr.png", country: "Costa Rica" },
    { emoji: "🇨🇺", image: "cu.png", country: "Cuba" },
    { emoji: "🇨🇻", image: "cv.png", country: "Cape Verde" },
    { emoji: "🇨🇼", image: "cw.png", country: "Curaçao" },
    { emoji: "🇨🇽", image: "cx.png", country: "Christmas Island" },
    { emoji: "🇨🇾", image: "cy.png", country: "Cyprus" },
    { emoji: "🇨🇿", image: "cz.png", country: "Czechia" },
    { emoji: "🇩🇪", image: "de.png", country: "Germany" },
    { emoji: "🇩🇯", image: "dj.png", country: "Djibouti" },
    { emoji: "🇩🇰", image: "dk.png", country: "Denmark" },
    { emoji: "🇩🇲", image: "dm.png", country: "Dominica" },
    { emoji: "🇩🇴", image: "do.png", country: "Dominican Republic" },
    { emoji: "🇩🇿", image: "dz.png", country: "Algeria" },
    { emoji: "🇪🇨", image: "ec.png", country: "Ecuador" },
    { emoji: "🇪🇪", image: "ee.png", country: "Estonia" },
    { emoji: "🇪🇬", image: "eg.png", country: "Egypt" },
    { emoji: "🇪🇭", image: "eh.png", country: "Western Sahara" },
    { emoji: "🇪🇷", image: "er.png", country: "Eritrea" },
    { emoji: "🇪🇸", image: "es.png", country: "Spain" },
    { emoji: "🇪🇹", image: "et.png", country: "Ethiopia" },
    { emoji: "🇫🇮", image: "fi.png", country: "Finland" },
    { emoji: "🇫🇯", image: "fj.png", country: "Fiji" },
    { emoji: "🇫🇰", image: "fk.png", country: "Falkland Islands" },
    { emoji: "🇫🇲", image: "fm.png", country: "Micronesia" },
    { emoji: "🇫🇴", image: "fo.png", country: "Faroe Islands" },
    { emoji: "🇫🇷", image: "fr.png", country: "France" },
    { emoji: "🇬🇦", image: "ga.png", country: "Gabon" },
    { emoji: "🇬🇧", image: "gb.png", country: "United Kingdom" },
    { emoji: "🇬🇩", image: "gd.png", country: "Grenada" },
    { emoji: "🇬🇪", image: "ge.png", country: "Georgia" },
    { emoji: "🇬🇫", image: "gf.png", country: "French Guiana" },
    { emoji: "🇬🇬", image: "gg.png", country: "Guernsey" },
    { emoji: "🇬🇭", image: "gh.png", country: "Ghana" },
    { emoji: "🇬🇮", image: "gi.png", country: "Gibraltar" },
    { emoji: "🇬🇱", image: "gl.png", country: "Greenland" },
    { emoji: "🇬🇲", image: "gm.png", country: "Gambia" },
    { emoji: "🇬🇳", image: "gn.png", country: "Guinea" },
    { emoji: "🇬🇵", image: "gp.png", country: "Guadeloupe" },
    { emoji: "🇬🇶", image: "gq.png", country: "Equatorial Guinea" },
    { emoji: "🇬🇷", image: "gr.png", country: "Greece" },
    { emoji: "🇬🇸", image: "gs.png", country: "South Georgia & South Sandwich Islands" },
    { emoji: "🇬🇹", image: "gt.png", country: "Guatemala" },
    { emoji: "🇬🇺", image: "gu.png", country: "Guam" },
    { emoji: "🇬🇼", image: "gw.png", country: "Guinea-Bissau" },
    { emoji: "🇬🇾", image: "gy.png", country: "Guyana" },
    { emoji: "🇭🇰", image: "hk.png", country: "Hong Kong SAR China" },
    { emoji: "🇭🇲", image: "hm.png", country: "Heard & McDonald Islands" },
    { emoji: "🇭🇳", image: "hn.png", country: "Honduras" },
    { emoji: "🇭🇷", image: "hr.png", country: "Croatia" },
    { emoji: "🇭🇹", image: "ht.png", country: "Haiti" },
    { emoji: "🇭🇺", image: "hu.png", country: "Hungary" },
    { emoji: "🇮🇩", image: "id.png", country: "Indonesia" },
    { emoji: "🇮🇪", image: "ie.png", country: "Ireland" },
    { emoji: "🇮🇱", image: "il.png", country: "Israel" },
    { emoji: "🇮🇲", image: "im.png", country: "Isle of Man" },
    { emoji: "🇮🇳", image: "in.png", country: "India" },
    { emoji: "🇮🇴", image: "io.png", country: "British Indian Ocean Territory" },
    { emoji: "🇮🇶", image: "iq.png", country: "Iraq" },
    { emoji: "🇮🇷", image: "ir.png", country: "Iran" },
    { emoji: "🇮🇸", image: "is.png", country: "Iceland" },
    { emoji: "🇮🇹", image: "it.png", country: "Italy" },
    { emoji: "🇯🇪", image: "je.png", country: "Jersey" },
    { emoji: "🇯🇲", image: "jm.png", country: "Jamaica" },
    { emoji: "🇯🇴", image: "jo.png", country: "Jordan" },
    { emoji: "🇯🇵", image: "jp.png", country: "Japan" },
    { emoji: "🇰🇪", image: "ke.png", country: "Kenya" },
    { emoji: "🇰🇬", image: "kg.png", country: "Kyrgyzstan" },
    { emoji: "🇰🇭", image: "kh.png", country: "Cambodia" },
    { emoji: "🇰🇮", image: "ki.png", country: "Kiribati" },
    { emoji: "🇰🇲", image: "km.png", country: "Comoros" },
    { emoji: "🇰🇳", image: "kn.png", country: "St. Kitts & Nevis" },
    { emoji: "🇰🇵", image: "kp.png", country: "North Korea" },
    { emoji: "🇰🇷", image: "kr.png", country: "South Korea" },
    { emoji: "🇰🇼", image: "kw.png", country: "Kuwait" },
    { emoji: "🇰🇾", image: "ky.png", country: "Cayman Islands" },
    { emoji: "🇰🇿", image: "kz.png", country: "Kazakhstan" },
    { emoji: "🇱🇦", image: "la.png", country: "Laos" },
    { emoji: "🇱🇧", image: "lb.png", country: "Lebanon" },
    { emoji: "🇱🇨", image: "lc.png", country: "St. Lucia" },
    { emoji: "🇱🇮", image: "li.png", country: "Liechtenstein" },
    { emoji: "🇱🇰", image: "lk.png", country: "Sri Lanka" },
    { emoji: "🇱🇷", image: "lr.png", country: "Liberia" },
    { emoji: "🇱🇸", image: "ls.png", country: "Lesotho" },
    { emoji: "🇱🇹", image: "lt.png", country: "Lithuania" },
    { emoji: "🇱🇺", image: "lu.png", country: "Luxembourg" },
    { emoji: "🇱🇻", image: "lv.png", country: "Latvia" },
    { emoji: "🇱🇾", image: "ly.png", country: "Libya" },
    { emoji: "🇲🇦", image: "ma.png", country: "Morocco" },
    { emoji: "🇲🇨", image: "mc.png", country: "Monaco" },
    { emoji: "🇲🇩", image: "md.png", country: "Moldova" },
    { emoji: "🇲🇪", image: "me.png", country: "Montenegro" },
    { emoji: "🇲🇫", image: "mf.png", country: "St. Martin" },
    { emoji: "🇲🇬", image: "mg.png", country: "Madagascar" },
    { emoji: "🇲🇭", image: "mh.png", country: "Marshall Islands" },
    { emoji: "🇲🇰", image: "mk.png", country: "North Macedonia" },
    { emoji: "🇲🇱", image: "ml.png", country: "Mali" },
    { emoji: "🇲🇲", image: "mm.png", country: "Myanmar (Burma)" },
    { emoji: "🇲🇳", image: "mn.png", country: "Mongolia" },
    { emoji: "🇲🇴", image: "mo.png", country: "Macao Sar China" },
    { emoji: "🇲🇵", image: "mp.png", country: "Northern Mariana Islands" },
    { emoji: "🇲🇶", image: "mq.png", country: "Martinique" },
    { emoji: "🇲🇷", image: "mr.png", country: "Mauritania" },
    { emoji: "🇲🇸", image: "ms.png", country: "Montserrat" },
    { emoji: "🇲🇹", image: "mt.png", country: "Malta" },
    { emoji: "🇲🇺", image: "mu.png", country: "Mauritius" },
    { emoji: "🇲🇻", image: "mv.png", country: "Maldives" },
    { emoji: "🇲🇼", image: "mw.png", country: "Malawi" },
    { emoji: "🇲🇽", image: "mx.png", country: "Mexico" },
    { emoji: "🇲🇾", image: "my.png", country: "Malaysia" },
    { emoji: "🇲🇿", image: "mz.png", country: "Mozambique" },
    { emoji: "🇳🇦", image: "na.png", country: "Namibia" },
    { emoji: "🇳🇨", image: "nc.png", country: "New Caledonia" },
    { emoji: "🇳🇪", image: "ne.png", country: "Niger" },
    { emoji: "🇳🇫", image: "nf.png", country: "Norfolk Island" },
    { emoji: "🇳🇬", image: "ng.png", country: "Nigeria" },
    { emoji: "🇳🇮", image: "ni.png", country: "Nicaragua" },
    { emoji: "🇳🇱", image: "nl.png", country: "Netherlands" },
    { emoji: "🇳🇴", image: "no.png", country: "Norway" },
    { emoji: "🇳🇵", image: "np.png", country: "Nepal" },
    { emoji: "🇳🇷", image: "nr.png", country: "Nauru" },
    { emoji: "🇳🇺", image: "nu.png", country: "Niue" },
    { emoji: "🇳🇿", image: "nz.png", country: "New Zealand" },
    { emoji: "🇴🇲", image: "om.png", country: "Oman" },
    { emoji: "🇵🇦", image: "pa.png", country: "Panama" },
    { emoji: "🇵🇪", image: "pe.png", country: "Peru" },
    { emoji: "🇵🇫", image: "pf.png", country: "French Polynesia" },
    { emoji: "🇵🇬", image: "pg.png", country: "Papua New Guinea" },
    { emoji: "🇵🇭", image: "ph.png", country: "Philippines" },
    { emoji: "🇵🇰", image: "pk.png", country: "Pakistan" },
    { emoji: "🇵🇱", image: "pl.png", country: "Poland" },
    { emoji: "🇵🇲", image: "pm.png", country: "St. Pierre & Miquelon" },
    { emoji: "🇵🇳", image: "pn.png", country: "Pitcairn Islands" },
    { emoji: "🇵🇷", image: "pr.png", country: "Puerto Rico" },
    { emoji: "🇵🇸", image: "ps.png", country: "Palestinian Territories" },
    { emoji: "🇵🇹", image: "pt.png", country: "Portugal" },
    { emoji: "🇵🇼", image: "pw.png", country: "Palau" },
    { emoji: "🇵🇾", image: "py.png", country: "Paraguay" },
    { emoji: "🇶🇦", image: "qa.png", country: "Qatar" },
    { emoji: "🇷🇪", image: "re.png", country: "Réunion" },
    { emoji: "🇷🇴", image: "ro.png", country: "Romania" },
    { emoji: "🇷🇸", image: "rs.png", country: "Serbia" },
    { emoji: "🇷🇺", image: "ru.png", country: "Russia" },
    { emoji: "🇷🇼", image: "rw.png", country: "Rwanda" },
    { emoji: "🇸🇦", image: "sa.png", country: "Saudi Arabia" },
    { emoji: "🇸🇧", image: "sb.png", country: "Solomon Islands" },
    { emoji: "🇸🇨", image: "sc.png", country: "Seychelles" },
    { emoji: "🇸🇩", image: "sd.png", country: "Sudan" },
    { emoji: "🇸🇪", image: "se.png", country: "Sweden" },
    { emoji: "🇸🇬", image: "sg.png", country: "Singapore" },
    { emoji: "🇸🇭", image: "sh.png", country: "St. Helena" },
    { emoji: "🇸🇮", image: "si.png", country: "Slovenia" },
    { emoji: "🇸🇯", image: "sj.png", country: "Svalbard & Jan Mayen" },
    { emoji: "🇸🇰", image: "sk.png", country: "Slovakia" },
    { emoji: "🇸🇱", image: "sl.png", country: "Sierra Leone" },
    { emoji: "🇸🇲", image: "sm.png", country: "San Marino" },
    { emoji: "🇸🇳", image: "sn.png", country: "Senegal" },
    { emoji: "🇸🇴", image: "so.png", country: "Somalia" },
    { emoji: "🇸🇷", image: "sr.png", country: "Suriname" },
    { emoji: "🇸🇸", image: "ss.png", country: "South Sudan" },
    { emoji: "🇸🇹", image: "st.png", country: "São Tomé & Príncipe" },
    { emoji: "🇸🇻", image: "sv.png", country: "El Salvador" },
    { emoji: "🇸🇽", image: "sx.png", country: "Sint Maarten" },
    { emoji: "🇸🇾", image: "sy.png", country: "Syria" },
    { emoji: "🇸🇿", image: "sz.png", country: "Eswatini" },
    { emoji: "🇹🇨", image: "tc.png", country: "Turks & Caicos Islands" },
    { emoji: "🇹🇩", image: "td.png", country: "Chad" },
    { emoji: "🇹🇫", image: "tf.png", country: "French Southern Territories" },
    { emoji: "🇹🇬", image: "tg.png", country: "Togo" },
    { emoji: "🇹🇭", image: "th.png", country: "Thailand" },
    { emoji: "🇹🇯", image: "tj.png", country: "Tajikistan" },
    { emoji: "🇹🇰", image: "tk.png", country: "Tokelau" },
    { emoji: "🇹🇱", image: "tl.png", country: "Timor-Leste" },
    { emoji: "🇹🇲", image: "tm.png", country: "Turkmenistan" },
    { emoji: "🇹🇳", image: "tn.png", country: "Tunisia" },
    { emoji: "🇹🇴", image: "to.png", country: "Tonga" },
    { emoji: "🇹🇷", image: "tr.png", country: "Turkey" },
    { emoji: "🇹🇹", image: "tt.png", country: "Trinidad & Tobago" },
    { emoji: "🇹🇻", image: "tv.png", country: "Tuvalu" },
    { emoji: "🇹🇼", image: "tw.png", country: "Taiwan" },
    { emoji: "🇹🇿", image: "tz.png", country: "Tanzania" },
    { emoji: "🇺🇦", image: "ua.png", country: "Ukraine" },
    { emoji: "🇺🇬", image: "ug.png", country: "Uganda" },
    { emoji: "🇺🇲", image: "um.png", country: "U.S. Outlying Islands" },
    { emoji: "🇺🇸", image: "us.png", country: "United States" },
    { emoji: "🇺🇾", image: "uy.png", country: "Uruguay" },
    { emoji: "🇺🇿", image: "uz.png", country: "Uzbekistan" },
    { emoji: "🇻🇦", image: "va.png", country: "Vatican City" },
    { emoji: "🇻🇨", image: "vc.png", country: "St. Vincent & Grenadines" },
    { emoji: "🇻🇪", image: "ve.png", country: "Venezuela" },
    { emoji: "🇻🇬", image: "vg.png", country: "British Virgin Islands" },
    { emoji: "🇻🇮", image: "vi.png", country: "U.S. Virgin Islands" },
    { emoji: "🇻🇳", image: "vn.png", country: "Vietnam" },
    { emoji: "🇻🇺", image: "vu.png", country: "Vanuatu" },
    { emoji: "🇼🇫", image: "wf.png", country: "Wallis & Futuna" },
    { emoji: "🇼🇸", image: "ws.png", country: "Samoa" },
    { emoji: "🇽🇰", image: "xk.png", country: "Kosovo" },
    { emoji: "🇾🇪", image: "ye.png", country: "Yemen" },
    { emoji: "🇾🇹", image: "yt.png", country: "Mayotte" },
    { emoji: "🇿🇦", image: "za.png", country: "South Africa" },
    { emoji: "🇿🇲", image: "zm.png", country: "Zambia" },
    { emoji: "🇿🇼", image: "zw.png", country: "Zimbabwe" }
];


export const EMOJI_MARK_500_READINGS = 0;
export const EMOJI_MARK_400_READINGS = 1;
export const EMOJI_MARK_200_READINGS = 2;
export const EMOJI_MARK_100_READINGS = 3;
export const EMOJI_MARK_75_READINGS = 4;
export const EMOJI_MARK_50_READINGS = 5;
export const EMOJI_MARK_25_READINGS = 6;
export const EMOJI_MARK_10_READINGS = 7;
export const EMOJI_MARK_5_READINGS = 8;

export const EMOJI_MARKS_READINGS = [
    { value: EMOJI_MARK_500_READINGS, emoji: "🌟", image: "1F31F.png", count: 500, description: "500 readings" },
    { value: EMOJI_MARK_400_READINGS, emoji: "⭐️", image: "2B50.png", count: 400, description: "400 readings" },
    { value: EMOJI_MARK_200_READINGS, emoji: "⚡️", image: "26A1.png", count: 200, description: "200 readings" },
    { value: EMOJI_MARK_100_READINGS, emoji: "💥", image: "1F320.png", count: 100, description: "100 readings" },
    { value: EMOJI_MARK_75_READINGS, emoji: "☀️", image: "2600.png", count: 75, description: "75 readings" },
    { value: EMOJI_MARK_50_READINGS, emoji: "🔥", image: "1F525.png", count: 50, description: "50 readings" },
    { value: EMOJI_MARK_25_READINGS, emoji: "🪄", image: "1FA84.png", count: 25, description: "25 readings" },
    { value: EMOJI_MARK_10_READINGS, emoji: "✨", image: "2728.png", count: 10, description: "10 readings" },
    { value: EMOJI_MARK_5_READINGS, emoji: "🧨", image: "1F9E8.png", count: 5, description: "5 readings" }
];


export const EMOJI_MARK_1ST_IN_REGION = 0;
export const EMOJI_MARK_2ND_IN_REGION = 1;
export const EMOJI_MARK_3RD_IN_REGION = 2;
export const EMOJI_MARK_1ST_IN_COUNTRY = 3;
export const EMOJI_MARK_1ST_IN_WORLD = 4;

export const EMOJI_MARKS_MOST = [
    { value: EMOJI_MARK_1ST_IN_REGION, emoji: "🥇", image: "1F947.png", description: "Most in region" },
    { value: EMOJI_MARK_2ND_IN_REGION, emoji: "🥈", image: "1F948.png", description: "2nd most in region" },
    { value: EMOJI_MARK_3RD_IN_REGION, emoji: "🥉", image: "1F949.png", description: "3rd most in region" },
    { value: EMOJI_MARK_1ST_IN_COUNTRY, emoji: "🎖", image: "1F396.png", description: "Most in country" },
    { value: EMOJI_MARK_1ST_IN_WORLD, emoji: "🏆", image: "1F3C6.png", description: "Most in the world" }
];
