import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { 
  BasicAppBar,
  ArticleList,
  ArticleMasonry,
  ArticleModList,
  ArticleModMasonry,
  DlgLoginConfirm,
  DlgPostEdit,
  WaitingSpinner, 
} from "components";
import { 
  withAuthentication, 
  withAuthorization, 
} from "session";
import { 
  GraphqlService,
  withFirebase,
} from "services";
import * as ROUTES from "constants/routes";
import { 
  MAX_WINDOW_WIDTH,
  MIN_TABLET_WIDTH,
} from "constants/types";
import { 
  deleteArticle, 
  getAuthToken, 
  getMainInfo, 
  repostArticle, 
  saveArticle, 
  updatePost, 
  upvoteArticle,
  getFeeds2show,
  reportArticle
} from "dataapis";
import { ToastError } from "utility/toast";
import { logger } from "utility/logging";
import { ARTICLE_BRANCH_NEWSPAPER } from "constants/branches";



const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: "100%",
    height: "56px",
    [theme.breakpoints.up("sm")]: {
      height: "64px",
    },
    zIndex: 10,
  },
  articlecontainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.default,
  },
  bottomdiv: {
    paddingBottom: theme.spacing(2),
  },
});


class Popular extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginDlg: false,
      postDlg: false,
      article_edit: null,
      articleReposts: [],
      articlePosts: [],
      postcounts: null,
      finalpostcount: null,
      repostCount: null,
      summaries: [],
      articles: [],
      popular: true,
    };

    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);
    this.handlePinArticle = this.handlePinArticle.bind(this);
    this.handleMoveTopArticle = this.handleMoveTopArticle.bind(this);
    this.handleCommentArticle = this.handleCommentArticle.bind(this);
    this.handleAICommentArticle = this.handleAICommentArticle.bind(this);
    this.handleNeedMore = this.handleNeedMore.bind(this);
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
  }

  setError = (errMsg) => {
    ToastError(errMsg);
    this.props.requestDataFinished();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  componentDidMount = async () => {
    const { my_posts, authUser } = this.props;

    try {
      const gqlservice = new GraphqlService();
      const feeds2show = getFeeds2show();
      const feed_ids = feeds2show.map((feed) => feed.id);

      const currentDate = new Date();
      const sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 7);

      const currentTimestamp = Math.floor(currentDate.getTime() / 1000);
      const sevenDaysAgoTimestamp = Math.floor(sevenDaysAgo.getTime() / 1000);

      console.log("Current Date Timestamp:", currentTimestamp);
      console.log("7 days ago Timestamp:", sevenDaysAgoTimestamp);

      const summaries = await gqlservice.summary_articles_in_feeds(
        sevenDaysAgoTimestamp,
        currentTimestamp
      );

      // Extracting articles from each summary object and flattening the array

      this.setState({ articles: summaries.data });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setError("Failed to fetch summaries.");
    } finally {
      this.setWaiting(false);
    }
  };

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "right" },
    };
    this.props.history.push(location);
    // this.props.history.goBack();
  };

  handleLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false
    });
    const location = {
      pathname: ROUTES.SIGN_IN,
      state: { animation: "bottom" },
    };
    this.props.history.push(location);
    this.props.setLoginBackRoute(this.props.location.pathname);
  };

  handleCancelLogin = () => {
    this.setState({
      ...this.state,
      loginDlg: false,
    });
  };

  handlePinArticle = (article) => {};
  handleMoveTopArticle = (article) => {};
  handleCommentArticle = (article) => {};
  handleAICommentArticle = (article) => {};

  handleNeedMore = async () => {};

  handleSelectArticle = (article) => {
    const { popular } = this.state;
    this.props.selectArticle(article);

    let path = `/${ROUTES.ARTICLE_PREFIX}/${article.nid}`;
    if (article.branch === ARTICLE_BRANCH_NEWSPAPER) {
      path = `/${ROUTES.ARTICLE_NEWS_PREFIX}/${article.nid}`;
    }
    const location = {
      pathname: path,
      state: { animation: "left" },
    };
    this.props.history.push(location);
    this.props.setArticleBackRoute(ROUTES.POPULAR);
  };

  handleDeleteArticle = async (article) => {
    const { loggedIn, authUser } = this.props;
    if (!loggedIn || authUser.uid !== article.txt_param1) {
      return;
    }
    this.setWaiting(true);
    await deleteArticle(article);
    this.setWaiting(false);
  };

  handleSaveArticle = async (article) => {
    this.setWaiting(true);
    await saveArticle(article);
    this.setWaiting(false);
  };

  handleDeleteSavedArticle = (article) => {};

  handleClickSource = (source, feed) => {}

  handleClickUpvote = async (article) => {
    this.setWaiting(true);
    await upvoteArticle(article);
    this.setWaiting(false);
  };

  handleClickComment = (article) => {
    this.handleSelectArticle(article);
  };

  handleClickRepost = async (article) => {
    this.setWaiting(true);
    await repostArticle(article);
    this.setWaiting(false);
  };
  handleReportArticle = async (article, reportMsg) => {
    this.setWaiting(true);
    await reportArticle(article, reportMsg);
    this.setWaiting(false);
  };
  render() {
    const {
      classes,
      theme_mode,
      selected_feed,
      my_posts,
      requesting,
      summaries,
    } = this.props;
    const { loginDlg, postDlg, article_edit, articles, popular } = this.state;
    let width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;

    // layout variables
    const isDesktop = width > MAX_WINDOW_WIDTH;
    const isTablet = width >= MIN_TABLET_WIDTH && width <= MAX_WINDOW_WIDTH;
    const isMobile = width < MIN_TABLET_WIDTH;
    const innerWidth = width > MAX_WINDOW_WIDTH ? MAX_WINDOW_WIDTH : width;

    // card width = 414 - 16, grid space
    const masonryWidth = Math.floor(innerWidth / 402) * 402 + 16;

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_WINDOW_WIDTH}
            title={"Popular"}
            onNavBack={this.handleNavBack}
          />
        </div>
        <div className={classes.articlecontainer}>
          {(isDesktop || isTablet) && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                {articles?.length > 0 && (
                  <ArticleMasonry
                    userProfile={true}
                    width={masonryWidth}
                      articles={articles}
                      pins={[]}
                      movetops={[]}
                      onNeedMore={this.handleNeedMore}
                      onSelectArticle={this.handleSelectArticle}
                      onSelectGroupArticle={this.handleSelectGroupArticle}
                      onNeedLogin={this.handleLogin}
                      onReport={this.handleReportArticle}
                      onClickSource={this.handleClickSourceInArticle}
                      onClickFeed={this.handleClickFeed}
                      onClickComment={this.handleClickComment}
                      onClickUpvote={this.handleClickUpvote}
                      onClickRepost={this.handleClickRepost}
                      onSave={this.handleSaveArticle}
                      popular={popular}
                  />
                )}
               
              </Grid>
            </Grid>
          )}
          {isMobile && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                {articles.length > 0 && (
                  <ArticleList
                    userProfile={true}
                    articles={articles}
                    aiSummary={[]}
                    pins={[]}
                    movetops={[]}
                    onNeedMore={this.handleNeedMoreArticles}
                    onSelectArticle={this.handleSelectArticle}
                    onSelectGroupArticle={this.handleSelectGroupArticle}
                    onNeedLogin={this.handleLogin}
                    onReport={this.handleReportArticle}
                    onEdit={this.handleEditArticle}
                    onDelete={this.handleDeleteArticle}
                    onSave={this.handleSaveArticle}
                    onDeleteSaved={this.handleDeleteSavedArticle}
                    onClickSource={this.handleClickSource}
                    onClickFeed={this.handleClickFeed}
                    onClickUpvote={this.handleClickUpvote}
                    onClickComment={this.handleClickComment}
                    onClickRepost={this.handleClickRepost}
                    onClickArticle={this.handleSelectArticle}
                    popular={popular}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </div>

        <div className={classes.bottomdiv}></div>

        <DlgLoginConfirm
          open={loginDlg}
          onLogin={this.handleLogin}
          onCancel={this.handleCancelLogin}
        />
        {postDlg && (
          <DlgPostEdit
            open={postDlg}
            theme={theme_mode}
            article={article_edit}
            onSubmit={this.handleUpdatePost}
            onClose={this.closePostEditDlg}
          />
        )}
        <WaitingSpinner open={requesting} />
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.sessionState.loggedIn,
  authUser: state.sessionState.authUser,
  theme_mode: state.uiState.theme_mode,
  posts_backroute: state.uiState.posts_backroute,
  requesting: state.uiState.requesting
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withFirebase,
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Popular);
