import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { MAX_ARTICLE_WIDTH, TAB_MASTODONALL_POSTS, TAB_NEWSRAVEN_POSTS,TAB_BLUESKY_POSTS } from "constants/types";

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    padding: 0,
  },
  tabs: {
    margin: 0,
    paddingRight: theme.spacing(2),
    minHeight: 18,
  },
  indicator: {
    backgroundColor: "#000000",
    width: '80px',
    left: '80px'
  },
  tab: {
    padding: 0,
    minWidth: 80,
    fontWeight: 600,
    fontSize: 14,
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    marginRight: theme.spacing(4), // Add space between tabs
  }
});

class PostTab extends React.Component {
  constructor(props) {
    super(props);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(event, newValue) {
    const { profiletab } = this.props;
    if (newValue === null) return;
    if (profiletab === newValue) return;
    this.props.onChangeTab(newValue);
  }

  render() {
    const { classes, profiletab,authUser,loggedIn } = this.props;

    const postsTab = { value: TAB_NEWSRAVEN_POSTS, label: "NewsRaven " };
    const feedsTab = { value: TAB_MASTODONALL_POSTS, label: "Mastodon " };
    const blueskyTab = { value: TAB_BLUESKY_POSTS, label: "Bluesky " };

    const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const tabsWidth = width > MAX_ARTICLE_WIDTH ? MAX_ARTICLE_WIDTH - 32 : width - 32;

    return (
      <div className={classes.root}>
        <Tabs
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
          value={profiletab}
          onChange={this.handleTabChange}
          variant={width > MAX_ARTICLE_WIDTH ? "fullWidth" : "scrollable"}
          scrollButtons="auto"
          style={{ width: tabsWidth }}
        >
          <Tab
            value={postsTab.value}
            key={postsTab.value}
            label={postsTab.label}
            className={classes.tab}
          />
          { loggedIn && authUser.is_mastodon_enabled &&  (
            <Tab
              value={feedsTab.value}
              key={feedsTab.value}
              label={feedsTab.label}
              className={classes.tab}
            />
          )}
          {loggedIn && authUser.is_bluesky_enabled &&  (
            <Tab
              value={blueskyTab.value}
              key={blueskyTab.value}
              label={blueskyTab.label}
              className={classes.tab}
            />
          )}
        </Tabs>
      </div>
    );
  }
}

PostTab.propTypes = {
  classes: PropTypes.object,
  onChangeTab: PropTypes.func,
};

const mapStateToProps = (state) => ({
  profiletab: state.uiState.profiletab,
  authUser: state.sessionState.authUser,
  loggedIn: state.sessionState.loggedIn,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PostTab);
