import axios from "axios";
import { logger } from "./logging";

const AI_RAVEN_NODE_API = process.env.REACT_APP_AI_RAVEN_NODE_API;

export const request_crawlsource = (source) => {
  // only request for crawling, no need to get response
  const BACKEND_API_URL = "https://backendapi.raventalk.org/api/v1/source";

  const backend_api = `${BACKEND_API_URL}?source_id=${source.id}`;
  axios.get(backend_api, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const get_link_image = async (source_link) => {
  const LINKIMAGE_API_URL = "https://searchapi.raventalk.org/link-preview";

  try {
    const res = await axios({
      method: 'post',
      url: LINKIMAGE_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: { url: source_link },
      timeout: 5000
    });

    if (res.status !== 200) {
      logger.error("Failed to get link image :", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }
    
    const images = data.data.images;
    if (images.length === 0) {
      return null;
    }
    return images[0];

  } catch(err) {
    logger.error("Failed to get link image :", err);
    return null;
  }
};

export const get_link_source = async (link) => {
  const LINKSOURCE_API_URL =
    "https://searchapi.raventalk.org/link-preview";

  try {
    const res = await axios({
      method: 'post',
      url: LINKSOURCE_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: { url: link },
      timeout: 30000
    });

    if (res.status !== 200) {
      logger.error("Failed to get link source :", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }
    
    return {
      title: data.data.title,
      description: data.data.description,
      image: data.data.image,
      domain: data.data.siteName,
      url: data.data.url,
      channel: data.data.channel ? data.data.channel : null
    }

  } catch(err) {
    logger.error("Failed to get link source :", err);
    return null;
  }
};

// don't use this api due to low performance
export const get_link_preview = async (link) => {
  const LINKPREVIEW_API_URL =
    "https://backendapi.raventalk.org/api/v1/linkpreview";

  try {
    const res = await axios({
      method: 'post',
      url: LINKPREVIEW_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: { url: link },
      timeout: 10000
    });

    if (res.status !== 200) {
      logger.error("Failed to get link preview :", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }
    
    return {
      title: data.linkpreview.title,
      description: data.linkpreview.description,
      image: data.linkpreview.image,
      domain: data.linkpreview.domain,
      url: data.linkpreview.url
    }

  } catch(err) {
    logger.error("Failed to get link preview :", err);
    return null;
  }
};

export const search_articles = async (parameters) => {
  //const SEARCH_API_URL = "https://searchapi.raventalk.org/search";
  const SEARCH_API_URL = `${AI_RAVEN_NODE_API}/search/search-articles`;
  try {
    const res = await axios({
      method: 'post',
      url: SEARCH_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: parameters,
      //timeout: 5000
    });

    if (res.status !== 200) {
      logger.error("Failed to get search articles :", res.status);
      return null;
    }

    const data = res.data.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }
    
    //const articles = data.hits.map(hit => hit._source);
    //return articles;
     return data;

  } catch(err) {
    logger.error("Failed to get search articles :", err);
    return null;
  }
};

export const generateSummary = async (parameters, token) => {
  const GENERATE_API_URL = `${AI_RAVEN_NODE_API}/summary/link-summarize`;
  try {
    const res = await axios({
      method: 'post',
      url: GENERATE_API_URL,
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+token
      },
      data: parameters,
    });

    if (res.status !== 200) {
      logger.error("Failed to generate summary :", res.status);
      return null;
    }

    const data = res.data.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }
     return data;

  } catch(err) {
    logger.error("Failed to generate summary :", err);
    return null;
  }
};

export const send_invitemsg = async (msgToken, invite_username, invite_url) => {
  const USER_INVITE_URL = "https://messaging.raventalk.org/invite2feed";

  try {
    const res = await axios({
      method: 'post',
      url: USER_INVITE_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: { 
        token: msgToken,
        invite_url: invite_url,
        username: invite_username
      },
      timeout: 5000
    });

    if (res.status !== 200) {
      logger.error("Failed to send invite message :", res.status);
      return false;
    }
    return true;

  } catch(err) {
    logger.error("Failed to send invite message :", err);
    return false;
  }
};
  
export const auth_response = async () => {
  const AUTH_REDIRECT_URL = "https://authapi.raventalk.org/";

  try {
    const res = await axios({
      method: 'get',
      url: AUTH_REDIRECT_URL,
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      },
      timeout: 5000
    });

    if (res.status !== 200) {
      logger.error("Failed to get auth response :", res.status);
      return {
        error: true,
        msg: "Failed to authenticate user"
      };
    }
    return {
      error: false,
      user: res.data.user
    };

  } catch(err) {
    logger.error("Failed to get auth response :", err);
    return {
      error: true,
      msg: "Failed to authenticate user"
    };
  }
};

export const contact_us = async (name, email, message) => {
  const CONTACTUS_MAIL_API_URL =
    "https://mailapi.raventalk.org/contact";

  try {
    const res = await axios({
      method: 'post',
      url: CONTACTUS_MAIL_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: { 
        name: name,
        email: email,
        message: message
      },
      timeout: 5000
    });

    if (res.status !== 200) {
      logger.error("Failed to send contact email :", res.status);
      return {
        error: true,
        msg: "Failed to send contact email"
      };
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return {
        error: true,
        msg: data.message
      };
    }

    return {
      error: false,
      msg: data.message
    };

  } catch(err) {
    logger.error("Failed to send contact email :", err);
    return {
      error: true,
      msg: "Failed to send contact email"
    };
  }
};

export const moderate_image = async (image_url) => {
  const MODERATE_IMAGE_API = "https://searchapi.raventalk.org/image-moderate";

  try {
    const res = await axios({
      method: 'post',
      url: MODERATE_IMAGE_API,
      headers: {
        "Content-Type": "application/json",
      },
      data: { url: image_url },
      // timeout: 5000
    });

    if (res.status !== 200) {
      logger.error("Failed to moderate image :", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data.data;

  } catch(err) {
    logger.error("Failed to moderate image :", err);
    return null;
  }
};

export const moderate_text = async (text) => {
  const MODERATE_TEXT_API = "https://searchapi.raventalk.org/text-moderate";

  try {
    const res = await axios({
      method: 'post',
      url: MODERATE_TEXT_API,
      headers: {
        "Content-Type": "application/json",
      },
      data: { text: text },
      timeout: 5000
    });

    if (res.status !== 200) {
      logger.error("Failed to moderate text :", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data.data;

  } catch(err) {
    logger.error("Failed to moderate text :", err);
    return null;
  }
};

export const scistarter_participate_record = async(uid, parameters) => {
  const SCISTARTER_API = "https://projectapi.raventalk.org/scistarter";

  const record = {
    uid: uid,
    parameters: {
      email: parameters.email,
      where: {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [parameters.longitude, parameters.latitude]
        },
        properties: {
          name: parameters.location_name
        }
      },
      when: new Date().toISOString().slice(0,19)
    }
  }

  try {
    const res = await axios({
      method: 'post',
      url: SCISTARTER_API,
      headers: {
        "Content-Type": "application/json",
      },
      data: record,
      timeout: 10000
    });

    if (res.status !== 200) {
      logger.error("Failed to record scistarter participation :", res.status);
      return false;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return false;
    }

    return true;

  } catch(err) {
    logger.error("Failed to record scistarter participation :", err);
    return false;
  }
}

export const birdtok_upload_file = async (uid, fileUpload, filename, isBlob=false) => {
  const BIRDTOK_UPLOAD_API = "https://birdtok.raventalk.org/upload";
  // const BIRDTOK_UPLOAD_API = "http://localhost:5000/upload";

  try {
    logger.log("upload file :", fileUpload, filename);

    let data = new FormData();
    data.append('uid', uid);
    if (isBlob) {
      let file = new File([fileUpload], filename);
      data.append('file', file);
    } else {
      data.append("file", fileUpload);
    }
    data.append('filename', filename);

    const res = await axios({
      method: 'post',
      url: BIRDTOK_UPLOAD_API,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
      timeout: 60000
    });
    
    if (res.status !== 200) {
      logger.error("Failed to upload video :", res.status);
      return null;
    }

    const resData = res.data;
    if (!resData || resData.error) {
      logger.error("API error:", resData.errorMsg);
      return null;
    }

    logger.log("birdtok upload response :", resData);

    return resData.url;

  } catch(err) {
    logger.error("Failed to upload video :", err);
    return null;
  }
}

export const birdtok_compose_video = async (composeParams) => {
  const BIRDTOK_COMPOSE_API = "https://birdtok.raventalk.org/compose";
  // const BIRDTOK_COMPOSE_API = "http://localhost:5000/compose";

  try {
    const res = await axios({
      method: 'post',
      url: BIRDTOK_COMPOSE_API,
      headers: {
        "Content-Type": "application/json",
      },
      data: composeParams,
      timeout: 600000
    });

    if (res.status !== 200) {
      logger.error("Failed to compose video :", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }

    return data;

  } catch(err) {
    logger.error("Failed to compose video :", err);
    return null;
  }
};

export const isFlaggedModerationByAi = async(params,token) => {
  const AI_FLAGGED_MODERATION = `${AI_RAVEN_NODE_API}/moderation/user-flagged-approval`;
  try{
    const res = await axios({
      method: 'post',
      url: AI_FLAGGED_MODERATION,
      headers: {
        hasuraauthkey: 'xzngk5axw7',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      data: params 
    });
    if (res.status !== 200) {
      logger.error("Failed", res.status);
      return null;
    }
    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }
    return data;

  } catch (error) {
    logger.error("Failed :", error);
  }
}

export const isSourceApprovedbyAI = async(params,token) => { 
  const AI_APPROVE_POSTS = `${AI_RAVEN_NODE_API}/moderation/user-source-approval`;
 try {
    const res = await axios({
      method: 'post',
      url: AI_APPROVE_POSTS,
      headers: {
        hasuraauthkey: 'xzngk5axw7',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      data: params 
    });
    if (res.status !== 200) {
      logger.error("Failed", res.status);
      return null;
    }
    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }
    return data;

  } catch (error) {
    logger.error("Failed :", error);
  }
}
export const approveAiPosts = async(params,token) => {
  const AI_APPROVE_POSTS = `${AI_RAVEN_NODE_API}/moderation/user-post-approval`;
  try {
    const res = await axios({
      method: 'post',
      url: AI_APPROVE_POSTS,
      headers: {
        hasuraauthkey: 'xzngk5axw7',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      data: params 
    });

    if (res.status !== 200) {
      logger.error("Failed", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }

    return data;

  } catch(err) {
    logger.error("Failed :", err);
    return null;
  }
}

export const createAiComments = async (params,token) => {
  const AI_COMMENT_API = `${AI_RAVEN_NODE_API}/comment/request-ai-comment`;  
  try {
    const res = await axios({
      method: 'post',
      url: AI_COMMENT_API,
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+token
      },
      data: params 
    });

    if (res.status !== 200) {
      logger.error("Failed", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }

    return data;

  } catch(err) {
    logger.error("Failed :", err);
    return null;
  }
};

export const createAiSummary = async (params,token) => {
  const AI_SUMMARY_API = `${AI_RAVEN_NODE_API}/transcriptions/create-tr-summary` ;
  try {
    const res = await axios({
      method: 'post',
      url: AI_SUMMARY_API,
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+token
      },
      data: params 
    });

    if (res.status !== 200) {
      logger.error("Failed", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }

    return data;

  } catch(err) {
    logger.error("Failed :", err);
    return null;
  }
};

/**
 * Function for calling api for creating FeedName and image
 */

export const getFeednameFromAI = async (parameters) => {
  const SEARCH_API_URL = `${AI_RAVEN_NODE_API}/feed/get-feed-name` ;
  

  try {
    const res = await axios({
      method: 'post',
      url: SEARCH_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: parameters,
      // timeout: 10000
    });

    if (res.status !== 200) {
      logger.error("Failed to get search articles :", res.status);
      return null;
    }
    

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }
    
    // const articles = data.hits.map(hit => hit._source);
    return data;

  } catch(err) {
    logger.error("Failed to get search articles :", err);
    return null;
  }
}

/**
 * Function for calling api for creating an AI image
 */

export const createImageByAI = async (parameters) => {
  const SEARCH_API_URL = `${AI_RAVEN_NODE_API}/feed/get-feed-image` ;
  

  try {
    const res = await axios({
      method: 'post',
      url: SEARCH_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: parameters,
      // timeout: 10000
    });

    if (res.status !== 200) {
      logger.error("Failed to get search articles :", res.status);
      return null;
    }
    

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }
    
    // const articles = data.hits.map(hit => hit._source);
    return data;

  } catch(err) {
    logger.error("Failed to Create AI images :", err);
    return null;
  }
}

export const aiQueryTranscript = async (request) => {
  const AI_QUERY_API = `${AI_RAVEN_NODE_API}/transcriptions/query-transcription`;
 
  try {
    const res = await axios({
      method: 'post',
      url: AI_QUERY_API,
      headers: {
        "Content-Type": "application/json",
      },
      data: request
    });

    if (res.status !== 200) {
      console.error("Failed", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      console.error("API error:", data);
      return null;
    }

    return data;

  } catch (err) {
    console.error("Failed :", err);
    return null;
  }
};

export const downloadImage = async (imageUrl) => {
  try {
    const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });
    console.log("Successfully downloaded");
    return Buffer.from(response.data, 'binary');
  } catch (error) {
    console.error('Error downloading image:', error);
    throw error;
  }
};

export const getInfo = async (params) => {
  const AI_COMMENT_API = `${AI_RAVEN_NODE_API}/cache/get-info`;  
  try {
    const res = await axios({
      method: 'post',
      url: AI_COMMENT_API,
      headers: {
        "hasuraauthkey": 'xzngk5axw7',
        "Content-Type": "application/json" 
      },
      data: params 
    });

    if (res.status !== 200) {
      logger.error("Failed", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }

    return data;

  } catch(err) {
    logger.error("Failed :", err);
    return null;
  }
};

export const upDateNodeCache = async (params) => {
  const AI_COMMENT_API = `${AI_RAVEN_NODE_API}/cache/update`;  
  try {
    const res = await axios({
      method: 'post',
      url: AI_COMMENT_API,
      headers: {
        "hasuraauthkey": 'xzngk5axw7',
        "Content-Type": "application/json" 
      },
      data: params 
    });

    if (res.status !== 200) {
      logger.error("Failed", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }

    return data;

  } catch(err) {
    logger.error("Failed :", err);
    return null;
  }
};

export const getFeedsInfo = async (params) => {
  const AI_COMMENT_API = `${AI_RAVEN_NODE_API}/cache/get-feed-info`;  
  try {
    const res = await axios({
      method: 'post',
      url: AI_COMMENT_API,
      headers: {
        "hasuraauthkey": 'xzngk5axw7',
        "Content-Type": "application/json" 
      },
      data: params 
    });

    if (res.status !== 200) {
      logger.error("Failed", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }

    return data;

  } catch(err) {
    logger.error("Failed :", err);
    return null;
  }
};

export const updateNodeCacheOfFeed = async (params) => {
  const AI_COMMENT_API = `${AI_RAVEN_NODE_API}/cache/update-feed`;  
  try {
    const res = await axios({
      method: 'post',
      url: AI_COMMENT_API,
      headers: {
        "hasuraauthkey": 'xzngk5axw7',
        "Content-Type": "application/json" 
      },
      data: params 
    });

    if (res.status !== 200) {
      logger.error("Failed", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data);
      return null;
    }

    return data;

  } catch(err) {
    logger.error("Failed :", err);
    return null;
  }
};
export const postToMastodon = async (parameters, token,mastodonToken) => {
  const POST_MASTODON_API_URL = `${AI_RAVEN_NODE_API}/mastodon/userpost-mastodon`;
  try {
    const res = await axios({
      method: 'post',
      url: POST_MASTODON_API_URL,
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+token
      },
      data: {parameters,mastodonToken}
    });

    if (res.status !== 200) {
      logger.error("Failed to post userpost to mastodon :", res.status);
      return null;
    }

    const data = res.data.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }
     return data;

  } catch(err) {
    logger.error("Failed to post userpost to mastodon :", err);
    return null;
  }
};


export const fetchMastodonSimilarPosts = async (q,limit) => {
  const FETCH_MASTODON_API_URL = `${AI_RAVEN_NODE_API}/mastodon/mastodon-similar-posts`;

  try {
    const res = await axios({
      method: 'post',
      url: FETCH_MASTODON_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: { q,limit }
    });

    if (res.status !== 200) {
      logger.error("Failed to fetch details from mastodon :", res.status);
      return null;
    }

    const data = res.data;
    console.log("dataaa", res.data); // Log the response data

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to fetch details from mastodon :", err);
    return null;
  }
};


export const createMastodonAccount = async (params) => {
  const FETCH_MASTODON_API_URL = `${AI_RAVEN_NODE_API}/mastodon/mastodon-account`;

  try {
    const res = await axios({
      method: 'post',
      url: FETCH_MASTODON_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: params
    });

    if (res.status !== 200) {
      logger.error("Failed to create mastodon account :", res.status);
      return null;
    }

    const data = res.data;
    console.log("dataaa", res.data); // Log the response data

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to create mastodon account :", err);
    return err;
  }
};


export const retrieveFollowdata = async (username) => {
  const FOLLOW_MASTODON_API_URL = `${AI_RAVEN_NODE_API}/mastodon/mastodon-retrieve-data`;

  try {
    // Make a POST request to the Mastodon API
    const response = await axios.post(FOLLOW_MASTODON_API_URL, { "username": username }, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check if the request was successful
    if (response.status !== 200) {
      console.error("Failed to retrieve Mastodon account following data. Status:", response.status);
      return null;
    }

    // Extract the data from the response
    const responseData = response.data;

    // Log the response data (optional)
    console.log("Response data:", responseData);

    // Check for errors in the response data
    if (!responseData || responseData.error) {
      console.error("API error:", responseData.errorMsg);
      return null;
    }

    // Return the data
    return responseData;
  } catch (error) {
    // Log and handle errors
    console.error("Failed to retrieve Mastodon account following data:", error);
    return null;
  }
};

export const followRequest = async (id) => {
  const FOLLOW_MASTODON_API_URL = `${AI_RAVEN_NODE_API}/mastodon/mastodon-follow-request`;

  try {
    // Make a POST request to the Mastodon API
    const response = await axios.post(FOLLOW_MASTODON_API_URL, { "id": id }, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check if the request was successful
    if (response.status !== 200) {
      console.error("Failed to follow. Status:", response.status);
      return null;
    }

    // Extract the data from the response
    const responseData = response.data;

    // Log the response data (optional)
    console.log("Response data:", responseData);

    // Check for errors in the response data
    if (!responseData || responseData.error) {
      console.error("API error:", responseData.errorMsg);
      return null;
    }

    // Return the data
    return responseData;
  } catch (error) {
    // Log and handle errors
    console.error("Failed to follow:", error);
    return null;
  }
};
export const unFollowRequest = async (id) => {
  const UNFOLLOW_MASTODON_API_URL = `${AI_RAVEN_NODE_API}/mastodon/mastodon-unfollow-request`;

  try {
    // Make a POST request to the Mastodon API
    const response = await axios.post(UNFOLLOW_MASTODON_API_URL, { "id": id }, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check if the request was successful
    if (response.status !== 200) {
      console.error("Failed to Unfollow. Status:", response.status);
      return null;
    }

    // Extract the data from the response
    const responseData = response.data;

    // Log the response data (optional)
    console.log("Response data:", responseData);

    // Check for errors in the response data
    if (!responseData || responseData.error) {
      console.error("API error:", responseData.errorMsg);
      return null;
    }

    // Return the data
    return responseData;
  } catch (error) {
    // Log and handle errors
    console.error("Failed to Unfollow:", error);
    return null;
  }
};


export const postToBluesky = async (parameters, token) => {
  const POST_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/post-bluesky`;
  try {
    const res = await axios({
      method: 'post',
      url: POST_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+token
      },
      data: {parameters}
    });

    if (res.status !== 200) {
      logger.error("Failed to post userpost to bluesky :", res.status);
      return null;
    }

    const data = res.data.data;
    console.log("posted to bluesky",data)
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }
     return data;

  } catch(err) {
    logger.error("Failed to post userpost to bluesky :", err);
    return null;
  }
};

export const searchPosts = async (params) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/search-posts`;

  try {
    const res = await axios({
      method: 'post',
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: params
    });

    if (res.status !== 200) {
      logger.error("Failed to fetch details from bluesky :", res.status);
      return null;
    }

    const data = res.data;
    console.log("dataaa", res.data); // Log the response data

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to fetch details from bluesky :", err);
    return null;
  }
};

export const searchFeeds = async(params) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/search-feeds`;

  try {
    const res = await axios({
      method: 'post',
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: params
    });

    if (res.status !== 200) {
      logger.error("Failed to fetch details from bluesky :", res.status);
      return null;
    }

    const data = res.data;
    console.log("dataaa", res.data); // Log the response data

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to fetch details from bluesky :", err);
    return null;
  }
};
export const searchSources = async (params) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/search-sources`;

  try {
    const res = await axios({
      method: 'post',
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data:  params 
    });

    if (res.status !== 200) {
      logger.error("Failed to fetch details from bluesky :", res.status);
      return null;
    }

    const data = res.data;
    console.log("dataaa", res.data); // Log the response data

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to fetch details from bluesky :", err);
    return null;
  }
};


export const verificationPhone = async (phoneNumber) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/phone-verification`;

  try {
    const res = await axios({
      method: 'post',
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: { phoneNumber}
    });

    if (res.status !== 200) {
      logger.error("Failed to send verification code :", res.status);
      return null;
    }

    const data = res.data;
    console.log("dataaa", res.data); // Log the response data

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to send verification code  :", err);
    return err;
  }
};

export const createBlueskyAccount = async (params) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/account-creation`;

  try {
    const res = await axios({
      method: 'post',
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data:  params
    });

    if (res.status !== 200) {
      logger.error("Failed to send verification code :", res.status);
      return null;
    }

    const data = res.data;
    console.log("dataaa", res.data); // Log the response data

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to send verification code  :", err);
    return err;
  }
};


export const blueskyGetFollows = async (params) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/get-follow`;

  try {
    const res = await axios({
      method: 'post',
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data:params
      
    });

    if (res.status !== 200) {
      logger.error("Failed to fetch details from bluesky :", res.status);
      return null;
    }

    const data = res.data;
    console.log("dataaa", res.data); // Log the response data

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to fetch details from bluesky :", err);
    return null;
  }
};



export const blueskyFollows = async (subject,accessJwt,did) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/account-follow`;

  try {
    console.log("Subject:", subject);
const res = await axios.post(FETCH_BLUESKY_API_URL, {
  subject,
  accessJwt,
  did
}, {
  headers: {
    "Content-Type": "application/json",
  }
});


    console.log("Response:", res.data); // Log the response data

    if (res.status !== 200) {
      logger.error("Failed to follow Bluesky account:", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to follow Bluesky account:", err);
    return null;
  }
}

export const blueskyUnfollows = async (rkey,identifier,password)=>{

  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/account-unfollow`;

  try {
    console.log("rkey:", rkey);
const res = await axios.post(FETCH_BLUESKY_API_URL, {
  rkey,
  identifier,
  password
}, {
  headers: {
    "Content-Type": "application/json",
  }
});


    console.log("Response:", res.data); // Log the response data

    if (res.status !== 200) {
      logger.error("Failed to follow unBluesky account:", res.status);
      return null;
    }

    const data = res.data;
    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to unfollow Bluesky account:", err);
    return null;
  }

}

export const blueskyGetFollowed = async (params) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/get-followed`;

  try {
    const res = await axios({
      method: 'post',
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data :params
    });

    if (res.status !== 200) {
      logger.error("Failed to fetch details from bluesky :", res.status);
      return null;
    }

    const data = res.data;
    console.log("dataaa", res.data); // Log the response data

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to fetch details from bluesky :", err);
    return null;
  }
};

export const blueskyPutFollowed = async (data,accessJwt) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/put-followed`;
  try {
    const res = await axios({
      method: 'post',
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ...data,
        accessJwt: accessJwt
      }
    });

    console.log("Response:", res.data); 

    if (res.status !== 200) {
      console.error("Failed to fetch details from bluesky :", res.status);
      return null;
    }

    const responseData = res.data;

    if (!responseData || responseData.error) {
      console.error("API error:", responseData.errorMsg);
      return null;
    }

    return responseData;
  } catch (err) {
    console.error("Failed to fetch details from bluesky :", err);
    return null;
  }
};

export const createSession = async (params) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/create-session`;

  try {
    const res = await axios({
      method: "post",
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    });

    if (res.status !== 200) {
      logger.error("Failed to fetch details from bluesky :", res.status);
      return null;
    }

    const data = res.data;

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to create session :", err);
    return err;
  }
};

export const refreshSession = async (params) => {
  const FETCH_BLUESKY_API_URL = `${AI_RAVEN_NODE_API}/bluesky/refresh-session`;
  try {
    const res = await axios({
      method: "post",
      url: FETCH_BLUESKY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    });

    if (res.status !== 200) {
      logger.error("Failed to refresh session :", res.status);
      return null;
    }

    const data = res.data;

    if (!data || data.error) {
      logger.error("API error:", data.errorMsg);
      return null;
    }

    return data;
  } catch (err) {
    logger.error("Failed to refresh session :", err);
    return err;
  }
};